import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  articleItem,
  articleLists,
  FontFamily,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";

export class BlogHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    const {
      home,
      homepage,
      data,
      userCredits,
      archive,
      exclusiveHome,
      darkMode,
      getAuthorClick,
    } = this.props;
    const webView = helpers.isWeb();

    return (
      <>
        <View
          style={[
            Flex.alignCenter,
            Padding.pt_5,
            Padding.pb_5,
            { backgroundColor: darkMode ? Colors.darkBlackColor : "#F6F6F6" },
          ]}
        >
          <View>
            <Text
              style={[
                Helpers.textUppercase,
                {
                  backgroundColor: "#907CFF",
                  borderWidth: 0.75,
                  borderColor: "#907CFF",
                  paddingHorizontal: 18,
                  paddingVertical: 6,
                  color: "white",
                  fontFamily: FontFamily.regular,
                  fontSize: helpers.isDesktop() ? 16 : 10,
                  fontWeight: "600",
                },
              ]}
            >
              On the record
            </Text>
          </View>
          <View
            style={[
              Flex.fill,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1000,
            ]}
          >
            <View>
              <View
                style={[
                  Padding.pr_2,
                  Padding.pl_2,
                  Flex.fill,
                  Flex.alignCenter,
                ]}
              >
                <Text
                  accessibilityRole="header"
                  aria-level="1"
                  style={[
                    articleItemHorizontal.articleTitle,
                    Margin.mb_2,
                    !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                    {
                      marginVertical: 15,
                      fontSize: helpers.isDesktop() ? 60 : 34,
                      lineHeight: helpers.isDesktop() ? 72 : 45,
                      fontFamily: FontFamily.abrilFatface,
                      textAlign: "center",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {data.postTitle}
                </Text>

                <View
                  style={[
                    {
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                    },
                    Margin.mb_1,
                  ]}
                >
                  <View>
                    {data.author &&
                      data.author.map((item, index) => {
                        let authorBtnClick;
                        if (webView) {
                          authorBtnClick = {
                            to: "/writers/" + item.slug,
                          };
                        } else {
                          authorBtnClick = {
                            onPress: () => getAuthorClick(item.slug),
                          };
                        }
                        return (
                          <View
                            key={`postSumm_${index}`}
                            style={{ alignItems: "center", display: "flex" }}
                          >
                            <View
                              style={[
                                postSingle.authorDetailsWrap,
                                helpers.isDesktop() && Margin.mr_1,
                                !helpers.isDesktop() && Margin.mb_1,
                              ]}
                            >
                              <View
                                key={`author_${index}`}
                                style={[postSingle.authorPicWrap]}
                              >
                                <TouchableOpacityLink {...authorBtnClick}>
                                  <CustomImage
                                    webStyle={{
                                      width: 38,
                                      height: 38,
                                      borderRadius: 100,
                                      marginTop: 4,
                                    }}
                                    resizeMode={"contain"}
                                    style={postSingle.authorThumb}
                                    altText={item.name}
                                    source={item.profile_image}
                                  />
                                </TouchableOpacityLink>
                              </View>
                              <View style={[Padding.pl_1]}>
                                <TouchableOpacityLink {...authorBtnClick}>
                                  <Text
                                    style={[
                                      articleItemHorizontal.authorTitle,
                                      helpers.isDesktop() &&
                                        articleItemHorizontal.authorTitleDesktop,
                                      !helpers.isWeb() &&
                                        articleItemHorizontal.authorTitleApp,
                                      { fontWeight: "500" },
                                      darkMode && { color: Colors.lightWhite },
                                    ]}
                                  >
                                    {item.name}
                                  </Text>
                                </TouchableOpacityLink>
                                {!helpers.isDesktop() && (
                                  <Text
                                    style={[
                                      articleItemHorizontal.articleDate,
                                      helpers.isDesktop() &&
                                        articleItemHorizontal.articleDateDesktop,
                                      !helpers.isWeb() &&
                                        articleItemHorizontal.articleDateApp,
                                      { fontWeight: "300", color: "#292929" },
                                      !helpers.isDesktop() && { fontSize: 12 },
                                      darkMode && { color: Colors.lightWhite },
                                      helpers.isDesktop() && Margin.ml_1,
                                    ]}
                                  >
                                    {moment(data?.story?.published_date).add(5, 'hours').add(30, 'minutes').format(
                                      "DD MMMM, YYYY"
                                    )}
                                  </Text>
                                )}
                              </View>
                            </View>
                            {/* {helpers.isDesktop() &&
                                                <View style={[postSingle.dotSeprator, { marginLeft: 0, marginRight: 15 }, !helpers.isDesktop() && {marginRight: 0}]} />
                                            } */}
                          </View>
                        );
                      })}
                  </View>

                  {helpers.isDesktop() && (
                    <View
                      style={[
                        articleItemHorizontal.dot,
                        helpers.isDesktop() && articleItemHorizontal.dotDesktop,
                        { position: "relative", top: -3 },
                      ]}
                    ></View>
                  )}
                  {helpers.isDesktop() && (
                    <Text
                      style={[
                        articleItemHorizontal.articleDate,
                        helpers.isDesktop() &&
                          articleItemHorizontal.articleDateDesktop,
                        !helpers.isWeb() &&
                          articleItemHorizontal.articleDateApp,
                        { fontWeight: "300", color: "#292929" },
                        !helpers.isDesktop() && {
                          fontSize: 12,
                          marginBottom: 6,
                        },
                        darkMode && { color: Colors.lightWhite },
                        helpers.isDesktop() && Margin.ml_1,
                      ]}
                    >
                      {moment(data?.story?.published_date).add(5, 'hours').add(30, 'minutes').format(
                        "DD MMMM, YYYY"
                      )}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
}

export default BlogHeader;
