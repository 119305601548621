import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  newsletter,
  FontFamily,
  Colors,
  Typo,
  featuredArticle,
  button,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import ButtonGradient from "../button-gradient";
import ButtonGradientView from "../button-gradient/index-view.js";

import apiHelpers from "../../utils/api-helpers";

const appLogo = getImagePath("icons/tmc-logo.png");
export class ActiveNewsletterListing extends Component {
  constructor(props) {
    super(props);
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");
    this.state = {
      res: "",
      Loading: "SUBSCRIBE",
      featuredWriter: false,
    };
  }

  componentDidMount = () => {
    let { token } = this.props;
    let endpointUrl = this.props.newsLetter.name.replace(/[^a-zA-Z ]/g, "");
    let endpoint = `/newsletter/${endpointUrl
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
    let body = {};

    apiHelpers
      .apiRequest(endpoint, body, "GET", true, token)
      .then((res) => {
        if (res.success) {
          this.setState({ featuredWriter: res.data.featured_writer }, () => {
          });
        } else {
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  navigateTo = (screen) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(screen);
    } else {
      this.props.navigation.navigate("/pricing");
    }
  };
  updateSubscription = () => {
    let { token } = this.props;
    let endpoint = `/save-newsletter-type`;
    let body = {
      type: this.props.newsLetter.name.replace(/\s+/g, "-").toLowerCase(),
      value: true,
    };

    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          this.setState({ res: res });
        } else {
          this.setState({ res: res });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  render() {
    let { newsLetter, darkMode } = this.props;
    let webView = helpers.isWeb();
    let viewBtnProps, authorBtnClick;
    if (webView) {
      viewBtnProps = {
        to: "/newsletters/" + newsLetter.slug,
      };
      if (this.state.featuredWriter) {
        authorBtnClick = {
          to: "/writers/" + this.state.featuredWriter.slug,
        };
      }
    } else {
      viewBtnProps = {
        onPress: () => this.props.getNewsletterClick(newsLetter.slug),
      };
    }

    return (
      <>
        {helpers.isDesktop() && (
          <TouchableOpacityLink {...viewBtnProps}>
            <View
              style={[
                helpers.isDesktop() && Flex.column,
                helpers.isDesktop() && Margin.mb_9,
                !helpers.isDesktop() && Margin.mb_3,
                !helpers.isWeb() && newsletter.appWrapper,
                darkMode && {
                  backgroundColor: Colors.darkBlackColor,
                  shadowColor: "rgba(0, 0, 0, 0.80)",
                },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Flex.row,
                  helpers.isDesktop() && Flex.alignCenter,
                  helpers.isDesktop() && Margin.mb_2,
                  !helpers.isDesktop() && Margin.mb_3,
                  !helpers.isWeb() && newsletter.appWrapper,
                  darkMode && {
                    backgroundColor: Colors.darkBlackColor,
                    shadowColor: "rgba(0, 0, 0, 0.80)",
                  },
                ]}
              >
                <View
                  style={[
                    !helpers.isDesktop() && { margin: "auto" },
                    helpers.isDesktop() && {
                      margin: "auto",
                      alignItems: "center",
                    },
                    !helpers.isWeb() && { alignItems: "center" },
                  ]}
                >
                  <CustomImage
                    source={newsLetter.logo}
                    require={false}
                    style={{
                      width: 250,
                      height: 130,
                      resizeMode: "contain",
                      margin: "auto",
                    }}
                    webStyle={{
                      height: helpers.isDesktop() ? "auto" : "auto",
                      objectFit: "contain",
                      margin: "auto",
                      maxWidth: '192px'
                    }}
                    altText={newsLetter.name}
                  />
                  {darkMode && (
                    <View
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(18, 18, 18, 0.05)",
                      }}
                    ></View>
                  )}
                  {/* {this.isUserSubscriptionActive == "0" ||
                    this.isUserSubscriptionActive == null ? (
                    <>
                      {(this.props.newsLetter.name
                        .replace(/\s+/g, "-")
                        .toLowerCase() == "friction" ||
                        this.props.newsLetter.name
                          .replace(/\s+/g, "-")
                          .toLowerCase() == "one-less-thing-to-worry-about" ||
                        this.props.newsLetter.name
                          .replace(/\s+/g, "-")
                          .toLowerCase() == "oversize" ||
                        this.props.newsLetter.name
                          .replace(/\s+/g, "-")
                          .toLowerCase() == "things-change") && (
                          <View
                            style={{
                              position: "absolute",
                              top: helpers.isDesktop() ? 10 : 50,
                              right: 0,
                              width: helpers.isDesktop() ? "41%" : "50%",
                              backgroundColor: "#0077C8",
                              padding: 3,
                              backgroundColor: "#0077C8",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: FontFamily.abrilFatface,
                                color: "#ffffff",
                                textAlign: "center",
                                letterSpacing: 1,
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                            >
                              Premium
                            </Text>
                          </View>
                        )}
                    </>
                  ) : null} */}
                </View>
                <View
                  style={[
                    { flex: 1 },
                    helpers.isDesktop() && {
                      paddingLeft: 15,
                      alignSelf: "baseline",
                    },
                    !helpers.isDesktop() && { marginTop: 20 },
                  ]}
                >
                  <View style={[!helpers.isDesktop() && Padding.pb_2]}>
                    {/* <Text style={[featuredArticle.newslettermain, helpers.isDesktop() &&  featuredArticle.titleDesktop,  {fontFamily: FontFamily.medium, letterSpacing: 0.3, fontWeight: '600', fontSize: 20, lineHeight: 42 },  {textAlign:'left', lineHeight: 35}, (!helpers.isWeb() ) && {fontFamily: FontFamily.bold}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{newsLetter.name}</Text>
                     */}
                    <Text
                      style={[
                        featuredArticle.newslettertitle,
                        helpers.isDesktop() && featuredArticle.titleDesktop,
                        { textAlign: "left", lineHeight: 35 },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        { fontFamily: FontFamily.abrilFatface },
                        {
                          fontSize: helpers.isDesktop() ? 25 : 20,
                          lineHeight: helpers.isDesktop() ? 30 : 22,
                          color: "#000",
                        },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                      ]}
                    >
                      {newsLetter.name}
                    </Text>

                    {/* <Text style={[newsletter.title, !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 }, helpers.isDesktop() && { fontSize: 18, lineHeight: 30 }, darkMode && { color: Colors.lightWhite }]}>{newsLetter.description}</Text> */}
                    <View
                      style={[
                        newsletter.lineBreak,
                        Margin.mt_0,
                        Margin.mb_1,
                        helpers.isDesktop() && [Margin.mb_2, Margin.mt_1],
                      ]}
                    ></View>
                  </View>

                  <View>
                    <View
                      style={[
                        postSingle.authorDetailsnewsWrap,
                        Flex.column,
                        helpers.isDesktop() && Flex.justfiyCenter,
                        helpers.isDesktop() && Flex.justifyStart,
                        Flex.FlexWrap,
                        { alignSelf: "flex-start" },
                      ]}
                    >
                      {
                        <TouchableOpacityLink {...authorBtnClick}>
                          <View
                            style={[
                              postSingle.authorDetailsWrap,
                              helpers.isDesktop() && Flex.alignCenter,
                              Flex.justfiyCenter,
                              helpers.isDesktop() && Flex.justifyStart,
                            ]}
                          >
                            {this.state.featuredWriter && (
                              <View
                                style={[
                                  postSingle.authorPicWrap,
                                  { width: 25, height: 25 },
                                ]}
                              >
                                <CustomImage
                                  source={
                                    this.state.featuredWriter.profile_image
                                  }
                                  require={false}
                                  webStyle={{
                                    width: 25,
                                    height: 25,
                                    borderRadius: 100,
                                  }}
                                  resizeMode={"contain"}
                                  style={{
                                    width: 25,
                                    height: 25,
                                    borderRadius: 100,
                                  }}
                                  altText={"name"}
                                />
                              </View>
                            )}
                            {/* {!this.state.featuredWriter && <View style={[postSingle.authorPicWrap, { width: 25, height: 25 }]}>
                                                <CustomImage
                                                    source={appLogo}
                                                    require={false}
                                                    webStyle={{
                                                        width: 25,
                                                        height: 25,
                                                        borderRadius: 100,
                                                    }}
                                                    resizeMode={'contain'}
                                                    style={{ width: 25, height: 25, borderRadius: 100 }}
                                                    altText={'name'}
                                                />
                                            </View>} */}
                            <View style={[Padding.pl_1]}>
                              {this.state.featuredWriter && (
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    !helpers.isDesktop() && { fontSize: 15 },
                                    helpers.isDesktop() && {
                                      fontWeight: "400",
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >
                                  {this.state.featuredWriter.name}
                                </Text>
                              )}
                              {!this.state.featuredWriter && (
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    !helpers.isDesktop() && { fontSize: 15 },
                                    helpers.isDesktop() && {
                                      fontWeight: "400",
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >
                                  {"Team TMC"}
                                </Text>
                              )}
                            </View>
                          </View>
                        </TouchableOpacityLink>
                      }
                      {
                        <>
                          {/* <View style={[featuredArticle.dotSeprator,{marginBottom: 0}]} /> */}
                          <TouchableOpacityLink>
                            <View
                              style={[
                                postSingle.authorDetailsWrap,
                                Flex.alignCenter,
                                Flex.justfiyCenter,
                                helpers.isDesktop() && Flex.justifyStart,
                                helpers.isDesktop() && { marginTop: 10 },
                              ]}
                            >
                              <View
                                style={[
                                  { width: 25, height: 25, marginTop: 5 },
                                ]}
                              >
                                <CustomImage
                                  source={
                                    "https://user-images.githubusercontent.com/62637513/130725363-0568a05b-784f-48b1-ae89-e4f10c0c143c.png"
                                  }
                                  require={false}
                                  webStyle={{
                                    width: 25,
                                    height: 25,
                                  }}
                                  resizeMode={"contain"}
                                  style={{
                                    width: 25,
                                    height: 25,
                                    borderRadius: 100,
                                  }}
                                  altText={"name"}
                                />
                              </View>
                              <View style={[Padding.pl_1]}>
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    !helpers.isDesktop() && { fontSize: 15 },
                                    helpers.isDesktop() && {
                                      fontWeight: "400",
                                      marginTop: 5,
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >{`Published ${newsLetter.available}`}</Text>
                              </View>
                            </View>
                          </TouchableOpacityLink>
                        </>
                      }
                      {/* { ( helpers.isDesktop()) && <View style={[featuredArticle.dotSeprator, {marginTop: 0}]} />} */}
                    </View>
                  </View>
                </View>
              </View>

              <Text
                style={[
                  newsletter.title,
                  !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 },
                  helpers.isDesktop() && { fontSize: 18, lineHeight: 30 },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {newsLetter.description}
              </Text>
            </View>
          </TouchableOpacityLink>
        )}
        {!helpers.isDesktop() && (
          <TouchableOpacityLink {...viewBtnProps}>
            <View
              style={[
                helpers.isDesktop() && Flex.column,
                helpers.isDesktop() && Margin.mb_9,
                !helpers.isDesktop() && { marginBottom: 3 },
                !helpers.isDesktop() && { paddingHorizontal: "5%" },
                !helpers.isWeb() && newsletter.appWrapper,
                darkMode && {
                  backgroundColor: Colors.darkBlackColor,
                  shadowColor: "rgba(0, 0, 0, 0.80)",
                },
              ]}
            >
              <View
                style={[
                  { flex: 1 },
                  helpers.isDesktop() && {
                    paddingLeft: 15,
                    alignSelf: "baseline",
                  },
                  !helpers.isDesktop() && { marginTop: 20 },
                ]}
              >
                <View
                  style={[
                    !helpers.isDesktop() && Flex.row,
                    helpers.isDesktop() && Flex.alignCenter,
                    helpers.isDesktop() && Margin.mb_2,
                    !helpers.isDesktop() && Margin.mb_3,
                    { alignItems: "flex-start", alignSelf: "baseline" },
                    ,
                    !helpers.isWeb() && newsletter.appWrapper,
                    darkMode && {
                      backgroundColor: Colors.darkBlackColor,
                      shadowColor: "rgba(0, 0, 0, 0.80)",
                    },
                  ]}
                >
                  <View
                    style={[
                      !helpers.isDesktop() && {
                        width: 100,
                        alignItems: "center",
                        marginRight: 25,
                      },
                      !helpers.isWeb() && { alignItems: "center" },
                    ]}
                  >
                    <CustomImage
                      source={newsLetter.logo}
                      require={false}
                      style={{ width: "100%", height: "100%" }}
                      webStyle={{ width: "100px", height: "100px" }}
                      altText={newsLetter.name}
                    />
                    {darkMode && (
                      <View
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(18, 18, 18, 0.05)",
                        }}
                      ></View>
                    )}
                    {/* {this.isUserSubscriptionActive == "0" ||
                      this.isUserSubscriptionActive == null ? (
                      <>
                        {(this.props.newsLetter.name
                          .replace(/\s+/g, "-")
                          .toLowerCase() == "friction" ||
                          this.props.newsLetter.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() == "one-less-thing-to-worry-about" ||
                          this.props.newsLetter.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() == "oversize" ||
                          this.props.newsLetter.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() == "things-change") && (
                            <View
                              style={{
                                position: "absolute",
                                top: helpers.isDesktop() ? 10 : 10,
                                right: helpers.isDesktop() ? 30 : 0,
                                width: helpers.isDesktop() ? "41%" : 73,
                                backgroundColor: "#0077C8",
                                padding: 3,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: FontFamily.abrilFatface,
                                  color: "#ffffff",
                                  textAlign: "center",
                                  letterSpacing: 1,
                                  fontSize: 12,
                                  textTransform: "uppercase",
                                }}
                              >
                                Premium
                              </Text>
                            </View>
                          )}
                      </>
                    ) : null} */}
                  </View>

                  <View>
                    <View
                      style={[
                        postSingle.authorDetailsnewsWrap,
                        Flex.column,
                        helpers.isDesktop() && Flex.justfiyCenter,
                        helpers.isDesktop() && Flex.justifyStart,
                        Flex.FlexWrap,
                        {
                          alignSelf: "baseline",
                          marginLeft: helpers.isDesktop() ? 0 : 0,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          featuredArticle.newslettertitle,
                          helpers.isDesktop() && featuredArticle.titleDesktop,
                          { textAlign: "left", lineHeight: 35 },
                          darkMode && { color: "rgba(255,255,255,0.87)" },
                          { fontFamily: FontFamily.abrilFatface },
                          {
                            fontSize: helpers.isDesktop() ? 25 : 20,
                            lineHeight: helpers.isDesktop() ? 30 : 22,
                            color: "#000",
                          },
                          darkMode && { color: "rgba(255,255,255,0.87)" },
                        ]}
                      >
                        {newsLetter.name}
                      </Text>

                      {
                        <TouchableOpacityLink style={{ alignSelf: "baseline" }}>
                          <View
                            style={[
                              postSingle.authorDetailsWrap,
                              helpers.isDesktop() && Flex.alignCenter,
                              Flex.justfiyCenter,
                              helpers.isDesktop() && Flex.justifyStart,
                            ]}
                          >
                            {this.state.featuredWriter && (
                              <View
                                style={[
                                  postSingle.authorPicWrap,
                                  { width: 20, height: 20, marginRight: 10 },
                                ]}
                              >
                                <CustomImage
                                  source={
                                    this.state.featuredWriter.profile_image
                                  }
                                  require={false}
                                  webStyle={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 100,
                                  }}
                                  resizeMode={"contain"}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 100,
                                  }}
                                  altText={"name"}
                                />
                              </View>
                            )}
                            {/* {!this.state.featuredWriter && <View style={[postSingle.authorPicWrap, { width: 20, height: 20 }]}>
                                                        <CustomImage
                                                            source={appLogo}
                                                            require={false}
                                                            webStyle={{
                                                                width: 20,
                                                                height: 20,
                                                                borderRadius: 100,
                                                            }}
                                                            resizeMode={'contain'}
                                                            style={{ width: 20, height: 20, borderRadius: 100 }}
                                                            altText={'name'}
                                                        />
                                                    </View>} */}
                            <View>
                              {this.state.featuredWriter && (
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    !helpers.isDesktop() && { fontSize: 15 },
                                    helpers.isDesktop() && {
                                      fontWeight: "400",
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >
                                  {this.state.featuredWriter.name}
                                </Text>
                              )}
                              {!this.state.featuredWriter && (
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    !helpers.isDesktop() && { fontSize: 15 },
                                    helpers.isDesktop() && {
                                      fontWeight: "400",
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >
                                  {"Team TMC"}
                                </Text>
                              )}
                            </View>
                          </View>
                        </TouchableOpacityLink>
                      }
                      {
                        <>
                          {/* <View style={[featuredArticle.dotSeprator,{marginBottom: 0}]} /> */}
                          <TouchableOpacityLink>
                            <View
                              style={[
                                postSingle.authorDetailsWrap,
                                Flex.alignCenter,
                                helpers.isDesktop() && Flex.justifyStart,
                                !helpers.isDesktop() && { marginTop: 10 },
                              ]}
                            >
                              <View
                                style={[
                                  { width: 20, height: 20, marginTop: 5 },
                                ]}
                              >
                                <CustomImage
                                  source={
                                    "https://user-images.githubusercontent.com/62637513/130725363-0568a05b-784f-48b1-ae89-e4f10c0c143c.png"
                                  }
                                  require={false}
                                  webStyle={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  resizeMode={"contain"}
                                  style={{
                                    width: 15,
                                    height: 15,
                                    borderRadius: 100,
                                  }}
                                  altText={"name"}
                                />
                              </View>
                              <View style={[Padding.pl_1]}>
                                <Text
                                  style={[
                                    postSingle.authorName,
                                    helpers.isDesktop() &&
                                    postSingle.authorNameDesktop,
                                    !helpers.isDesktop() && {
                                      fontSize: 16,
                                      fontWeight: "500",
                                    },
                                    helpers.isDesktop() && {
                                      fontWeight: "400",
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >{`Published ${newsLetter.available}`}</Text>
                              </View>
                            </View>
                          </TouchableOpacityLink>
                        </>
                      }
                      {/* { ( helpers.isDesktop()) && <View style={[featuredArticle.dotSeprator, {marginTop: 0}]} />} */}
                    </View>
                  </View>
                </View>
              </View>
              <Text
                style={[
                  newsletter.title,
                  !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 },
                  helpers.isDesktop() && { fontSize: 18, lineHeight: 30 },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {newsLetter.description}
              </Text>
              <View
                style={[
                  helpers.isDesktop() && Flex.row,
                  helpers.isDesktop() && Flex.alignCenter,
                  helpers.isDesktop() && Margin.mb_2,
                  !helpers.isDesktop() && Margin.mb_3,
                  ,
                  !helpers.isWeb() && newsletter.appWrapper,
                  darkMode && {
                    backgroundColor: Colors.darkBlackColor,
                    shadowColor: "rgba(0, 0, 0, 0.80)",
                  },
                ]}
              >
                {helpers.isDesktop() && <View style={{ width: "50%" }}></View>}
                <View style={[{ width: 20, height: 20, marginTop: 5 }]}>
                  <CustomImage
                    source={
                      "https://user-images.githubusercontent.com/62637513/132447060-3503cabb-4683-41e1-b90f-f5eadf853039.png"
                    }
                    require={false}
                    webStyle={{
                      width: 20,
                      height: 10,
                    }}
                    resizeMode={"contain"}
                    style={{ width: 15, height: 10, borderRadius: 100 }}
                    altText={"name"}
                  />
                </View>
              </View>
            </View>
          </TouchableOpacityLink>
        )}
      </>
    );
  }
}

export default ActiveNewsletterListing;
