import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Category from '../../native/screens/category';
import ReactGA from 'react-ga';

export class CategoryScreen extends Component {
    constructor(props) {
        if(process.browser) {
            window.history.scrollRestoration = "manual";
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
        }
        super(props);
        let postData = (process.browser) ? document.getElementById("postData") : null;
        let footerData = (process.browser) ? document.getElementById("footerData") : null;
        this.state = {
            searchQuery: "",
            locationHash: "",
            categorySlug: this.props.match.params.category_slug,
            categoryData: (postData) ? JSON.parse(postData.getAttribute('data')) : ((JSON.stringify(this.props.postData) != '{}') ? this.props.postData : null),
            token: this.props.token,
            footerData: (footerData) ? JSON.parse(footerData.getAttribute('data')) : ((JSON.stringify(this.props.footerData) != '{}') ? this.props.footerData : null),
            scrollTop: false
        }
        if(process.browser) {
            if(postData) {
                postData.parentNode.removeChild(postData);
            }
            if(footerData) {
                footerData.parentNode.removeChild(footerData);
            }
            try {
                ReactGA.pageview(this.props.history.location.pathname);
            }
            catch(error) {
                console.log("GA tracking error =>", error);
            }
        }
    }

    componentDidMount = () => {
        setTimeout(function () {
            window.history.scrollRestoration = "manual";
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            setTimeout(function () {
                document.body.style.overflow = 'initial';
                document.body.style.position = 'initial';
            }, 20);
        }, 20);
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }

    componentDidUpdate = () => {
        window.history.scrollRestoration = "manual";
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
        if(this.state.categorySlug != this.props.match.params.category_slug) {
            this.setState({
                categorySlug: this.props.match.params.category_slug,
                categoryData: null,
                footerData: null,
                scrollTop: true
            })
        }
        if(this.state.scrollTop) {
            this.setState({
                scrollTop: false
            })
        }
    }

    navigateToScreen = (route, params) => {
        let routeArr = route.split('?')
        const { history } = this.props;
        history.push({
          pathname: routeArr[0],
          search: routeArr[1] ? ('?' + routeArr[1]) : '',
          state: params
        });
    }

    render() {
        const { categorySlug, categoryData, footerData, searchQuery, locationHash, token, scrollTop } = this.state;

        return (
            <Category history={this.props.history} searchQuery={searchQuery} locationHash={locationHash} key={categorySlug} navigateToScreen={this.navigateToScreen} categoryData={categoryData} categorySlug={categorySlug} token={token} footerData={footerData} scrollTop={scrollTop} />
        );
    }
}

export default withRouter(CategoryScreen);