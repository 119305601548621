import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  TextInput,
  TouchableHighlight,
  StyleSheet,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  aboutAuthor,
  pageContent,
  input,
  Colors,
  bannerStyles,
  Messages,
  FontFamily,
  FontWeight,
  button,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import ScrollContainerView from "../scroll-container-view";
import { CardView } from "react-native-simple-card-view";
import moment, { relativeTimeRounding } from "moment";
import Swiper from "react-native-web-swiper";

const slideArrowLeft = getImagePath("icons/arrow-left.svg");
const slideArrowRight = getImagePath("icons/arrow-right.svg");

export class BreakingNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollViewWidth: 0,
      currentXOffset: 0,
      eachItemOffset: 0,
    };
    this.scrollListReftop = React.createRef();
  }

  componentDidMount = () => {};

  _handleScroll = (event) => {
    // console.log('currentXOffset =', event.nativeEvent.contentOffset.x);
    newXOffset = event.nativeEvent.contentOffset.x;
    this.setState({ currentXOffset: newXOffset });
  };

  leftArrow = () => {
    let eachItemOffset = this.state.scrollViewWidth / 8; // Divide by 10 because I have 10 <View> items
    // this.setState({ eachItemOffset: this.state.eachItemOffset - eachItemOffset }, () => {
    let _currentXOffset = this.state.currentXOffset - eachItemOffset;
    this.scrollListReftop.scrollTo({
      x: _currentXOffset,
      y: 0,
      animated: true,
    });
    // })

    // window.scrollTo(0, this.myRef.current.offsetTop)
  };

  rightArrow = () => {
    let eachItemOffset = this.state.scrollViewWidth / 8; // Divide by 10 because I have 10 <View> items
    this.setState(
      { eachItemOffset: this.state.eachItemOffset + eachItemOffset },
      () => {
        let _currentXOffset =
          this.state.currentXOffset + this.state.eachItemOffset;
        this.scrollListReftop.scrollTo({
          x: _currentXOffset,
          y: 0,
          animated: true,
        });
      }
    );
  };

  isLatest = (publishedAt) => {
    let startDatetime = moment(new Date()).subtract(4, "hours")
    let publishedDatetime = moment.utc(publishedAt)

    if (publishedDatetime < startDatetime) {
      return false
    }
    return true

  }

  addSlideContent = (item, index) => {
    if (item?.types.filter((e) => e.slug === "short").length > 0) {
      item.is_short = true;
    }

    const miniCardStyle = {
      // shadowColor: "#000000",
      // shadowOffsetWidth: 2,
      // shadowOffsetHeight: 2,
      // shadowOpacity: 0.5,
      // shadowRadius: 5,
      backgroundColor: "#ffffff",
      padding: 16,
      marginLeft: helpers.isDesktop() ? "16px" : "12px",
      marginRight: helpers.isDesktop() ? "16px" : "12px",
      borderRadius: 0,
      elevation: 3,
      width: 280,
      minHeight: 109,
    };
    let btnClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: {
          pathname:
            item?.is_short == true
              ? "/" + item.category.slug + "/" + item.slug + "?type=short"
              : "/" + item.category.slug + "/" + item.slug,
          postData: item,
        },
      };
    } else {
      btnClick = {
        onPress: () =>
          this.props.viewArticle(item.category.slug, item.slug, item),
      };
    }

    return (
      <View
        style={[miniCardStyle, index == 0 && { marginLeft: 50 }]}
        key={`content_${index}`}
      >
        <TouchableOpacityLink {...btnClick}>
          <View
            style={{
              flexDirection: "column",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                marginRight: 0,
                display: "block",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "8px",
                }}
              >
                {item.published_date && this.isLatest(item.published_date) ? (
                  <View
                    style={{
                      background: "#D8361E",
                      paddingHorizontal: 4,
                      paddingVertical: 2,
                      marginRight: "14px",
                    }}
                  >
                    <Text
                      style={{
                        textTransform: "uppercase",
                        fontWeight: 600,
                        fontSize: "11px",
                        color: "#ffffff",
                        fontFamily: FontFamily.medium,
                      }}
                    >
                      {"BREAKING NEWS"}
                    </Text>
                  </View>
                ) : null}
                <Text
                  style={[
                    {
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#676C83",
                    },
                    { fontFamily: FontFamily.regular },
                  ]}
                >
                  {helpers.getCalculatedTime(item.published_date)}
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={[
                    {
                      fontWeight: 600,
                      fontSize: 14,
                      color: "#292929",
                      marginBottom: 5,
                    },
                    { fontFamily: FontFamily.regular },
                  ]}
                >
                  {item.title}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacityLink>
      </View>
    );
  };

  renderLeftIcon = () => {
    return (
      <TouchableHighlight
        style={{
          position: "relative",
          display: "inline-block",
          left: "0",
          top: "50%",
          // transform: "translateY(-50%)",
        }}
        onPress={() => this.leftArrow()}
      >
        <CustomImage
          source={slideArrowLeft}
          require={true}
          webStyle={{ width: "auto", height: "12px" }}
        />
      </TouchableHighlight>
    );
  };

  render() {
    let webView = helpers.isWeb();
    console.log("databreak:", this.props.data);
    let { data } = this.props;
    let btnClickNewsletter;
    let slide_num = 1;
    data = data.length > 8 ? data.slice(0, 8) : data;
    if (data.length >= 4) {
      slide_num = parseInt(data.length / 4 + (data.length % 4)); // Find the number of slides
    }
    let slide_num_arr = [...Array(slide_num).keys()];
    const miniCardStyle = {
      // shadowColor: "#000000",
      // shadowOffsetWidth: 2,
      // shadowOffsetHeight: 2,
      // shadowOpacity: 0.5,
      // shadowRadius: 5,
      backgroundColor: "#ffffff",
      padding: 16,
      marginLeft: helpers.isDesktop() ? "16px" : "12px",
      marginRight: helpers.isDesktop() ? "16px" : "12px",
      borderRadius: 0,
      elevation: 3,
      width: 280,
      minHeight: 109,
    };

    return (
      <View
        style={{
          position: "relative",
        }}
      >
        <View
          style={{
            maxWidth: "1328px",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {helpers.isDesktop() ? (
            <View style={{ height: 150, paddingTop: 25, paddingBottom: 15 }}>
              <Swiper
                loop
                // timeout={3}
                controlsProps={
                  data.length > 4
                    ? {
                        dotsPos: "bottom",
                        nextTitle: "→",
                        nextTitleStyle: {
                          position: "absolute",
                          display: "inline-block",
                          right: "-10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          fontSize: "33px",
                          width: "20px",
                          height: "11px",
                        },
                        prevTitleStyle: {
                          position: "absolute",
                          display: "inline-block",
                          left: "-15px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          fontSize: "33px",
                          width: "20px",
                          height: "11px",
                        },
                        nextPos: "top-right",
                        prevPos: "top-left",
                        prevTitle: "←",
                        // dotActiveStyle: { backgroundColor: "#907CFF" },
                        // dotsWrapperStyle: { marginBottom: "-2px", marginTop: 30 },
                        // dotsTouchable: true,
                      }
                    : false
                }
                autoplayTimeout={-2.5}
                overRangeButtonsOpacity={0.3}
                controlsEnabled={data.length > 4 ? true : false}
                minDistanceForAction={0.15}
                springConfig={{ speed: 100 }}
              >
                {slide_num_arr.map((d, i) => {
                  return (
                    <View
                      key={`bnews_${i}`}
                      style={[
                        Flex.row,
                        Flex.alignStart,
                        // Flex.justfiyCenter,
                        !process.browser && { overflow: "hidden" },
                        !webView && { width: "100%" },
                      ]}
                    >
                      {data &&
                        data
                          .slice(d * 4, d * 4 + 4)
                          .map((item, index) =>
                            this.addSlideContent(item, index)
                          )}
                    </View>
                  );
                })}
              </Swiper>
            </View>
          ) : (
            <ScrollContainerView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              // ref={(ref) => {
              //   this.scrollListReftop = ref;
              // }}
              // pagingEnabled={true}
              // onContentSizeChange={(w, h) =>
              //   this.setState({ scrollViewWidth: w })
              // }
              // scrollEventThrottle={16}
              // onScroll={this._handleScroll}
            >
              <View
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  !process.browser && { overflow: "hidden" },
                  !webView && { width: "100%", paddingHorizontal: 56 },
                ]}
              >
                {data &&
                  data.map((item, index) => {
                    if (
                      item?.types.filter((e) => e.slug === "short").length > 0
                    ) {
                      item.is_short = true;
                    }
                    let btnClick;
                    if (helpers.isWeb()) {
                      btnClick = {
                        to: {
                          pathname:
                            item?.is_short == true
                              ? "/" +
                                item.category.slug +
                                "/" +
                                item.slug +
                                "?type=short"
                              : "/" + item.category.slug + "/" + item.slug,
                          postData: item,
                        },
                      };
                    } else {
                      btnClick = {
                        onPress: () =>
                          this.props.viewArticle(
                            item.category.slug,
                            item.slug,
                            item
                          ),
                      };
                    }
                    return (
                      <View style={miniCardStyle}>
                        <TouchableOpacityLink
                          {...btnClick}
                          // handleClick={() => {
                          //   this.trackNewsletterClick(item, index);
                          // }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                marginRight: 0,
                                display: "block",
                              }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  marginBottom: "8px",
                                }}
                              >
                                {item.published_date && this.isLatest(item.published_date) ? (
                                  <View
                                    style={{
                                      background: "#D8361E",
                                      paddingHorizontal: 4,
                                      paddingVertical: 2,
                                      marginRight: "14px",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        textTransform: "uppercase",
                                        fontWeight: 600,
                                        fontSize: "11px",
                                        color: "#ffffff",
                                        fontFamily: FontFamily.medium,
                                      }}
                                    >
                                      {"BREAKING NEWS"}
                                    </Text>
                                  </View>
                                ) : null}

                                <Text
                                  style={[
                                    {
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#676C83",
                                    },
                                    { fontFamily: FontFamily.regular },
                                  ]}
                                >
                                  {helpers.getCalculatedTime(
                                    item.published_date
                                  )}
                                </Text>
                              </View>
                              <View style={{}}>
                                <Text
                                  style={[
                                    {
                                      fontWeight: 600,
                                      fontSize: 14,
                                      color: "#292929",
                                      marginBottom: 5,
                                    },
                                    { fontFamily: FontFamily.regular },
                                  ]}
                                >
                                  {item.title}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </TouchableOpacityLink>
                      </View>
                    );
                  })}
              </View>
            </ScrollContainerView>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slideContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  slide1: {
    backgroundColor: "rgba(20,20,200,0.3)",
  },
  slide2: {
    backgroundColor: "rgba(20,200,20,0.3)",
  },
  slide3: {
    backgroundColor: "rgba(200,20,20,0.3)",
  },
});

export default BreakingNews;
