import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, TextInput } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, button, formStyle, unlockBox, signUpPage, Colors, modalStyle } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import Button from '../button';
import SignInSocial from '../sign-in-social';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageBreak from '../page-break';
import Modal from '../modal';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import TouchableOpacityLink from '../touchable-opacity-link';
import { Mixpanel } from '../../utils/mixpanel';
import { GoogleAnalytics } from '../../utils/google-analytics';
import { saveDarkMode, darkModeToggle } from '../../../redux/App/app.actions';
import { setUserToken } from '../../../redux/User/user.actions';

const cookies = new Cookies();
const eyeIcon = getImagePath('icons/showPassowrd.png');
const closeIcon = getImagePath('icons/grey-close-in.png');
const universityIcon = getImagePath('icons/university.png');

export class SignUpForm extends Component {
    constructor(props) {
        super(props);
        let redirectUrl = null;
        let verifyRedirectUrl = null;
        let email = null;
        let allParams = null;
        let webView = helpers.isWeb();
        let redirectParams = null;
        if (webView && process.browser) {
            if (window.location.search.includes("redirect=") || window.location.search.includes("email=")) {
                let searchArr = window.location.search.replace("?", "").split("&");
                let search = null;
                searchArr.map((searchParam) => {
                    if (searchParam.includes("redirect=")) {
                        search = searchParam.replace("redirect=", "");
                    }
                    if (searchParam.includes("email=")) {
                        email = searchParam.replace("email=", "");
                    }
                    if (searchParam.includes("redirect-params=")) {
                        redirectParams = searchParam;
                    }
                    return searchParam;
                });

                redirectUrl = (search && search != '') ? search : null;

                if (redirectUrl && redirectParams) {
                    redirectUrl = redirectUrl + '?' + redirectParams
                }

                verifyRedirectUrl = redirectUrl;
            }
        } else if (!webView && this.props.route.params) {
            redirectUrl = (this.props.route.params.redirect) ? this.props.route.params.redirect : null;
            verifyRedirectUrl = (this.props.route.params.verifyRedirect) ? this.props.route.params.verifyRedirect : null;
            email = (this.props.route.params.email) ? this.props.route.params.email : null;
            allParams = this.props.route.params ? this.props.route.params : {};
        }
        this.state = {
            name: '',
            email: email ? email : '',
            password: '',
            passwordToggle: true,
            enableBtnLoader: false,
            error: {
                social: false,
                name: false,
                email: false,
                password: false
            },
            redirectUrl: redirectUrl,
            verifyRedirectUrl: verifyRedirectUrl,
            showVerifyEmailModal: false,
            allParams: allParams,
        }
    }

    setDarkMode = (darkMode, toggle) => {
        this.props.saveDarkMode(darkMode);
        this.props.darkModeToggle(toggle ? true : false);
        if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            cookies.set('dark_mode', darkMode, { path: '/', expires: cookieDate });
        }
    }

    submitSignUp = () => {
        const { name, email, password } = this.state;

        if(name && email && password) {
            this.setState({
                error: {
                    social: false,
                    name: false,
                    email: false,
                    password: false
                },
                enableBtnLoader: 'email',
            });
    
            let emailID = helpers.validateEmail(email);
    
            if (name != '' && emailID && password != '' && password.length >= 6) {
                this.emailSignUp();
            } else {
                this.setState({
                    error: {
                        social: false,
                        name: (name == '') ? "Please enter a valid name" : false,
                        email: (!emailID) ? "Please enter a valid email" : false,
                        password: (password == '' || password.length < 6) ? "Please enter minimum 6 digit password" : false
                    },
                    enableBtnLoader: false,
                })
            }
        }
    }

    emailSignUp = () => {
        const { name, email, password, redirectUrl, verifyRedirectUrl } = this.state;
        let type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/sign-up`;
        let body = {
            full_name: name,
            account_email: email.toLowerCase(),
            password: password,
            redirect_url: verifyRedirectUrl ? verifyRedirectUrl : null,
            type: type,
            device_data: {}
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'acc_created') {
                    try {
                        Mixpanel.identify(res.data.reference_id);
                        Mixpanel.people.set({
                            name: res.data.full_name,
                            "$email": res.data.account_email
                        });
                        Mixpanel.track('signup', { method: 'email' }, true);
                    }
                    catch(error) {
                        console.log("mix panel error ==>", error);
                    }
                    try {
                        GoogleAnalytics.event('Account', 'signup', 'email');
                    }
                    catch (error) {
                        console.log("google analytics error ==>", error);
                    }

                    this.setState({ enableBtnLoader: false});

                    if (helpers.isWeb()) {
                        this.props.navigateToScreen('/sign-up-success', { email: email, redirect_url: verifyRedirectUrl })
                    } else {
                        this.props.navigation.replace('/sign-up-success', { email: email, redirect_url: verifyRedirectUrl });
                    }
                }
                else {
                    this.setState({
                        enableBtnLoader: false,
                        error: {
                            social: false,
                            name: (res.code == 'error' && res.data.error.full_name) ? res.data.error.full_name : false,
                            email: (res.code == 'error' && res.data.error.account_email) ? res.data.error.account_email : false,
                            password: (res.code == 'error' && res.data.error.password) ? res.data.error.password : false
                        },
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    enableBtnLoader: false,
                    error: {
                        social: "Network Error",
                        name: false,
                        email: false,
                        password: false
                    },
                });
                console.log('Error=>>', error);
            });
    }

    handleSocialSetState = (stateObj) => {
        this.setState(stateObj);
    }

    handleSocialRedirect = (tokenData) => {
        let { redirectUrl, verifyRedirectUrl, allParams } = this.state;
        if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            cookies.set('token', tokenData.token, { path: '/', expires: cookieDate });
            if (tokenData.dark_mode) {
                this.setDarkMode((tokenData.dark_mode ? tokenData.dark_mode.web : 'default'), (tokenData.dark_mode?.webSaved != true));
            }
            if (tokenData.is_new_user) {
                this.props.navigateToScreen('/save-interests?redirect=' + (redirectUrl ? redirectUrl : '/'), { pageRedirect: true });
            } else {
                this.props.navigateToScreen(redirectUrl ? redirectUrl : '/');
            }
        } else {
            const userdata = {
                data: tokenData
            };
            this.props.setUserToken(userdata);
            if (tokenData.dark_mode) {
                this.setDarkMode((tokenData.dark_mode ? tokenData.dark_mode.app : 'default'), (tokenData.dark_mode?.appSaved != true));
            }
            if (tokenData.is_new_user) {
                this.props.navigation.replace('/save-interests', { ...allParams, pageRedirect: true });
            } else {
                this.props.navigation.navigate((redirectUrl ? redirectUrl : '/'), allParams ? allParams : {});
            }
        }
    }

    navigateToScreen = () => {
        let { redirectUrl, allParams, verifyRedirectUrl, email } = this.state;
        if (helpers.isWeb()) {
            if(redirectUrl && redirectUrl.includes('?')) {
                redirectUrl = redirectUrl.replace('?', '&')
            }
            this.props.navigateToScreen(redirectUrl ? ('/sign-in?redirect=' + redirectUrl + (email != '' ? ('&email=' + email) : '')) : ('/sign-in' + (email != '' ? ('?email=' + email) : '')));
        } else {
            this.props.navigation.replace('/sign-in', allParams ? {...allParams, email: email} : {email: email});
        }
    }

    navigateToInstitution = () => {
        let { redirectUrl, allParams, verifyRedirectUrl, email } = this.state;
        if (helpers.isWeb()) {
            if (redirectUrl && redirectUrl.includes('?')) {
                redirectUrl = redirectUrl.replace('?', '&')
            }
            this.props.navigateToScreen(redirectUrl ? ('/sign-up-institution?redirect=' + redirectUrl + (email != '' ? ('&email=' + email) : '')) : ('/sign-up' + (email != '' ? ('?email=' + email) : '')));
        } else {
            this.props.navigation.replace('/sign-up-institution', allParams ? { ...allParams, email: email } : { email: email });
        }
    }

    navigateBack = () => {
        let { redirectUrl } = this.state;
        if (helpers.isWeb()) {
            this.props.history.replace(redirectUrl ? redirectUrl : '/')
        }
        else {
            this.props.navigation.navigate(redirectUrl ? redirectUrl : '/');
        }
    }

    renderPageContent() {
        const { name, email, password, error, passwordToggle, enableBtnLoader } = this.state;
        const webView = helpers.isWeb();
        let btnClickTerms, btnClickPrivacy;
        const { navigation } = this.props;
        if (webView) {
            btnClickTerms = {
                to: "/terms-and-conditions"
            }
            btnClickPrivacy = {
                to: "/privacy-policy"
            }
        } else {
            btnClickTerms = {
                onPress: () => navigation.navigate('/terms-and-conditions')
            }
            btnClickPrivacy = {
                onPress: () => navigation.navigate('/privacy-policy')
            }
        }

        let navigateBackProp = {};
        navigateBackProp = {
            onPress: this.navigateBack
        }

        return (
            <View style={[helpers.isDesktop() && Margin.mt_3, helpers.isDesktop() && Margin.mb_3, { paddingHorizontal: helpers.isDesktop() ? 50 : 0 }]}>
                {helpers.isWeb() ?
                    <View style={[Padding.ph_2, Flex.fill, !helpers.isDesktop() && Padding.pt_5, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        {!helpers.isDesktop() && <TouchableOpacity {...navigateBackProp}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28, position: 'absolute', right: 5, bottom: 6, zIndex: 1 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 5, bottom: 6, zIndex: 1 }}
                        /></TouchableOpacity>}
                        <Text accessibilityRole="header" aria-level="1" style={[signUpPage.pageTitle, { fontSize: helpers.isDesktop() ? 30 : 26 }]}>Sign up</Text>
                    </View>
                    :
                    <View style={[Padding.ph_2, Flex.fill, Padding.pt_2, { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        <TouchableOpacity {...navigateBackProp}><View style={[Flex.alignEnd]}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28, position: 'relative', right: 5, bottom: 6, zIndex: 1 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 5, bottom: 6, zIndex: 1 }}
                        /></View></TouchableOpacity>
                        <Text accessibilityRole="header" aria-level="1" style={[signUpPage.pageTitle, { fontSize: 26 }]}>Sign up</Text>
                    </View>
                }
                <View style={[Padding.pt_3, { paddingHorizontal: 18 }]}>
                    {/* <PageBreak style={[Margin.mt_1, Margin.mb_3]} size={'small'} /> */}

                    <SignInSocial
                        handleSocialSetState={(stateObj) => this.handleSocialSetState(stateObj)}
                        handleSocialRedirect={(token) => this.handleSocialRedirect(token)}
                        enableBtnLoader={enableBtnLoader}
                        btnText={'Sign up with '}
                    />
                    {error.social && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>*{error.social}</Text>
                    </View>}

                    <View style={[signUpPage.orWrapper, Margin.mt_4, Margin.mb_3]}>
                        <View style={[signUpPage.sepratorLine]} />
                        <Text style={[signUpPage.orText, { lineHeight: 22 }]}>OR</Text>
                    </View>

                    <View style={[Margin.mt_2]}>
                        <TextInput
                            placeholder={'Name'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[formStyle.inputBoxStyle, error.name && formStyle.inputBoxError, {fontSize: helpers.isDesktop() ? 18 : 16}]}
                            value={name}
                            onChangeText={(name) => this.setState({ name: name })}
                            onSubmitEditing={this.submitSignUp}
                        />
                    </View>
                    {error.name && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>*{error.name}</Text>
                    </View>}

                    <View style={[Margin.mt_4]}>
                        <TextInput
                            placeholder={'Email Id'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[formStyle.inputBoxStyle, error.email && formStyle.inputBoxError, {fontSize: helpers.isDesktop() ? 18 : 16}]}
                            value={email}
                            onChangeText={(email) => this.setState({ email: email })}
                            onSubmitEditing={this.submitSignUp}
                        />
                    </View>
                    {error.email && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>{error.email}</Text>
                    </View>}

                    <View style={[Margin.mt_4]}>
                        <View style={[formStyle.inputIconWrap]}>
                            <TextInput
                                placeholder={'Password'}
                                placeholderTextColor={Colors.placeholderTextColor}
                                style={[formStyle.inputBoxStyle, { paddingRight: 57 }, error.password && formStyle.inputBoxError, {fontSize: helpers.isDesktop() ? 18 : 16}]}
                                secureTextEntry={passwordToggle}
                                onChangeText={(password) => this.setState({ password: password })}
                                onSubmitEditing={this.submitSignUp}
                            />
                            <TouchableOpacity activeOpacity={0.8} onPress={() => this.setState({ passwordToggle: !passwordToggle })} style={[formStyle.showPassBtn]}>
                                <Text style={[formStyle.showPassBtnText, { marginTop: -4, paddingRight: 10 }]}>{passwordToggle ? 'SHOW' : 'HIDE'}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {error.password && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>{error.password}</Text>
                    </View>}
                </View>

                <View style={[Margin.mt_4, Padding.ph_2, Padding.pt_2]}>
                    <ButtonGradient
                        title="Sign Up"
                        rootStyle={{
                            btnWrapper: button.primaryGradient,
                            btnText: [button.primaryGradientText,,{fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400'}],
                        }}
                        loader={(enableBtnLoader && enableBtnLoader == 'email') ? true : false}
                        disable={((enableBtnLoader && enableBtnLoader != 'email') || (name == '' || email == '' || password == '')) ? true : false}
                        onClick={() => this.submitSignUp()}
                        fullWidth={true}
                    />
                    {helpers.isWeb() && 
                    <View style={[Margin.mt_2, Padding.ph_2, Padding.pv_0, Margin.mb_2]}>
                    <Button
                                title={'Access Through Your Institution'}
                                icon={universityIcon}
                                rootStyle={{
                                    image: { width: 19, height: 19, paddingLeft: 20 },
                                    btnWrapper: [button.whiteButton, { borderColor: button.btnGoogleText, width: helpers.isWeb() ? 'fit-content' : 100, backgroundColor: '#ffffff', marginTop: 0, width: '100% !important', height: '46px' }],
                                    btnText: [button.btnGoogleText, {  lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13,  fontWeight: '400', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }],
                                }}
                                fullWidth={true}
                                onClick={() => this.navigateToInstitution()}
                    >
                    </Button>
                    </View>
                    }
                    <View style={[Flex.justfiyCenter, Flex.row, Padding.pt_2]}>
                        <Text style={[formStyle.footerText1]}>Already have an account? </Text><TouchableOpacity onPress={() => this.navigateToScreen()} activeOpacity={0.8}><Text style={[formStyle.primayLinkBtn]}>Sign in</Text></TouchableOpacity>
                    </View>
                    <View style={[Margin.mt_5, !helpers.isDesktop() && Margin.mb_5, Padding.ph_2]}>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'baseline' }}>
                            <Text style={[formStyle.footerText2]}>By signing up, you agree to The Morning Context</Text>
                            <TouchableOpacityLink {...btnClickTerms}>
                                <Text style={[formStyle.linkBtn]}> Terms of Use </Text>
                            </TouchableOpacityLink>
                            <Text style={[formStyle.footerText2]}> and </Text>
                            <TouchableOpacityLink {...btnClickPrivacy}>
                                <Text style={[formStyle.linkBtn]}> Privacy Policy. </Text>
                            </TouchableOpacityLink>
                        </View>
                    </View>
                </View>
            </View>
        );
    }

    closeVerifyModal = () => {
        this.setState({
            showVerifyEmailModal: false,
        })
    }

    renderModalContent = () => {
        return (
            <View>
                <View style={[Flex.alignCenter]}>
                    {/*<CustomImage
                        source={emailVerifyIcon}
                        require={true}
                        style={{ width: 135, height: 136 }}
                        webStyle={{ width: 135, height: 136 }}
                    />*/}
                </View>
                <View>
                    <Text style={[Typo.h3, Helpers.textCenter, Helpers.textUppercase]}>Verify email</Text>
                    <PageBreak size={'small'} style={[Margin.mb_2]} />
                    <Text style={[Typo.text16, Helpers.textCenter]}>To continue to <Text style={[Helpers.textBold]}>The Morning Context</Text></Text>
                    <Text style={[Typo.infoText, Helpers.textCenter, Margin.mt_3]}>Sorry the account associated with this email id has not yet been verified. Please check your email or click on the button below to complete verification</Text>
                </View>
                <View style={[Margin.mt_3]}>
                    <ButtonGradient
                        title={'RESEND VERIFICATION EMAIL'}
                        rootStyle={{
                            btnWrapper: button.primaryGradient,
                            btnText: button.primaryGradientText,
                        }}
                    />
                </View>
            </View>
        )
    }

    render() {
        const { showVerifyEmailModal, redirectUrl } = this.state;
        const webView = helpers.isWeb();
        return (
            <View style={[{ flex: webView ? 1 : 0, backgroundColor: 'white', height: webView ? 'auto' : '100%' }]}>
                {(webView && process.browser && cookies.get("token") != undefined) && <Redirect to={redirectUrl ? redirectUrl : "/"} />}
                {webView
                    ? this.renderPageContent()
                    : <ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView>
                }
                {showVerifyEmailModal && <Modal renderContent={() => this.renderModalContent()} update={showVerifyEmailModal} closeModal={() => this.closeVerifyModal()} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUserToken, saveDarkMode, darkModeToggle }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);