import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  signUpPage,
  appStyles,
  Typo,
  Helpers,
  article,
  FontFamily,
  FontWeight,
  button,
  subscribePlans,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import Seo from "../../components/seo-meta";
import { setUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import CustomImage from "../../components/custom-image";
import PageBreak from "../../components/page-break";
import getImagePath from "../../utils/image-helper";
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from "../../components/button-gradient";
import EmailLink from "../../components/email-link";
import TouchableOpacityLinkExternal from "../../components/touchable-opacity-link-external";
import { Mixpanel } from "../../utils/mixpanel";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const cookies = new Cookies();

const failureIcon = getImagePath("icons/failure-icon.png");
const successIcon = getImagePath("icons/tick-icon.png");

export class PaymentRedirect extends Component {
  constructor(props) {
    super(props);
    let orderId = null;
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    if (webView && process.browser && this.props.orderId) {
      orderId = this.props.orderId;
    }

    this.state = {
      hostedPageId: this.props.hostedPageId,
      token: process.browser ? (isAuthenticated ? fetchToken : false) : null,
      orderId: orderId,
      postPaymentText: "Your transaction was completed successfully!",
      enableLoader: true,
      secondaryPaymentText: "",
      custom_user_id: false
    };
  }

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false,
        });
      } else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id,
        });
      }
    });
  };


  componentDidMount = () => {
    const { orderId, hostedPageId } = this.state;

    if (orderId) {
      this.pollingSubscriptionResponse(orderId, "juspay", 3000, 0);
    }
    if (hostedPageId) {
      this.getCheckoutStatus();
    }
    if (!this.state.custom_user_id) {
      this.setAuthToken();
    }
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getCheckoutStatus = () => {
    const { token, hostedPageId } = this.state;
    let endpoint = `/cb-hosted-object`;
    let body = {
      type: "retrieve",
      hostedpage_id: hostedPageId,
    };
    let time = 9000;
    setTimeout(() => {
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          this.setState({
            orderStatus: res.state == "succeeded" ? "success" : "failed",
            enableLoader: false,
            postPaymentText: `Thank you for subscribing to The Morning Context. Your subscription will be activated in just a few minutes. For any support, please write to support@themorningcontext.com.`,
          });
        })
        .catch((error) => {
          console.log("getCheckoutStatus:Error=>>", error);
        });
    }, time);
  };

  pushPaymentStatusEventToDataLayer = (planName, paymentStatus) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "payment_status",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      plan_name: planName,
      plan_price: "NA",
      article_name: "NA",
      payment_status: paymentStatus
    });
  }

  pollingSubscriptionResponse = (order_id, channel, time, fails) => {
    const { token } = this.state;
    let endpoint = `/order-status`;
    let body = {
      order_id: order_id,
    };
    setTimeout(() => {
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.code == "order_status" && res.data.status == "success") {
            try {
              Mixpanel.track("Subscription success", {
                plan: res.data.plan_name ? res.data.plan_name : "",
                channel: channel,
              });
            } catch (error) {
              console.log("mix panel error ==>", error);
            }
            let redirectUrl = JSON.parse(res.data.redirect_url);
            if (redirectUrl && redirectUrl.giftData) {
              this.refs.header.setGiftSuccess(redirectUrl.giftData);
            }
            if (res.data.plan_name === "Pay Per Story") {
              this.setState({
                secondaryPaymentText:
                  'You have received two privilege credits. Navigate to the paywalled story you would like to read and simply click the "Unlock" button.',
              });
            }
            this.setState({
              orderStatus: "success",
              redirect_url: JSON.parse(res.data.redirect_url),
              enableLoader: false,
            });
            this.pushPaymentStatusEventToDataLayer(res.data.plan_name, "success")
          } else if (
            res.code == "order_status" &&
            res.data.status == "failed"
          ) {
            try {
              Mixpanel.track("Subscription failed", {
                plan: res.data.plan_name ? res.data.plan_name : "",
                channel: channel,
              });
            } catch (error) {
              console.log("mix panel error ==>", error);
            }
            this.setState({
              orderStatus: "failed",
              redirect_url: JSON.parse(res.data.redirect_url),
              enableLoader: false,
            });
            this.pushPaymentStatusEventToDataLayer(res.data.plan_name, "failure")
          } else if (res.code == "no_data") {
            //
          } else {
            this.pollingSubscriptionResponse(order_id, channel, time, fails);
          }
        })
        .catch((error) => {
          console.log("Error=>>", error);
          // if(fails < 5) {
          //     this.pollingSubscriptionResponse(order_id, channel, time, fails + 1);
          // } else {
          //     //Manual
          //     console.log("Manual===>>>")
          // }
          this.pollingSubscriptionResponse(order_id, channel, time, fails);
        });
    }, time);
  };

  navigateToScreen = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(slug);
    } else {
      //TODO
    }
  };

  renderContent = () => {
    const { orderStatus, redirect_url, postPaymentText } = this.state;
    let success_redirect = this.props.success_redirect_url
      ? this.props.success_redirect_url
      : "/";
    return (
      <View>
        {orderStatus == "success" ? (
          <View
            style={[
              Margin.mb_4,
              { marginTop: helpers.isDesktop() ? 150 : 100 },
              {
                paddingVertical: helpers.isDesktop() ? 70 : 35,
                paddingHorizontal: 20,
              },
              { marginHorizontal: 15 },
            ]}
          >
            <View style={[Flex.alignCenter, Margin.mb_4]}>
              <CustomImage
                source={successIcon}
                require={true}
                style={{
                  width: "auto",
                  height: helpers.isDesktop() ? 186 : 140,
                }}
                webStyle={{
                  width: "auto",
                  height: helpers.isDesktop() ? 186 : 140,
                }}
              />
            </View>
            <View
              style={{
                maxWidth: helpers.isDesktop() ? 700 : "100%",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: 60,
              }}
            >
              <Text
                style={{
                  fontFamily: FontFamily.regular,
                  fontWeight: FontWeight.semiBold,
                  fontSize: 28,
                  lineHeight: 50,
                  letterSpacing: 0.3,
                  textAlign: "center",
                  color: "#55C79A",
                }}
              >
                Success!
              </Text>
              <Text
                style={{
                  fontFamily: FontFamily.regular,
                  fontWeight: FontWeight.regular,
                  fontSize: 16,
                  lineHeight: helpers.isDesktop() ? 28 : 26,
                  letterSpacing: 0.3,
                  textAlign: "center",
                }}
              >
                {postPaymentText}
              </Text>
              {this.state.secondaryPaymentText && (
                <Text
                  style={{
                    fontFamily: FontFamily.regular,
                    fontWeight: FontWeight.regular,
                    padding: 10,
                    fontSize: 16,
                    lineHeight: helpers.isDesktop() ? 28 : 26,
                    letterSpacing: 0.3,
                    textAlign: "center",
                  }}
                >
                  {this.state.secondaryPaymentText}
                </Text>
              )}
            </View>

            <View style={[{ marginBottom: 20 }]}>
              <ButtonGradient
                title="CONTINUE"
                rootStyle={{
                  btnWrapper: button.primaryGradient,
                  btnText: button.primaryGradientText,
                }}
                loader={false}
                disable={false}
                onClick={() => {
                  this.navigateToScreen(success_redirect);
                  window.location.reload();
                }}
              />
            </View>
          </View>
        ) : (
          <View
            style={[
              Margin.mb_4,
              { marginTop: helpers.isDesktop() ? 150 : 100 },
              {
                paddingVertical: helpers.isDesktop() ? 70 : 35,
                paddingHorizontal: 20,
              },
              { marginHorizontal: 15 },
            ]}
          >
            <View style={[Flex.alignCenter, Margin.mb_3]}>
              <CustomImage
                source={failureIcon}
                require={true}
                style={{
                  width: "auto",
                  height: helpers.isDesktop() ? 186 : 140,
                }}
                webStyle={{
                  width: "auto",
                  height: helpers.isDesktop() ? 186 : 140,
                }}
              />
            </View>
            <View>
              <Text
                style={[
                  {
                    textAlign: "center",
                    color: "#FF7F7F",
                    fontSize: 28,
                    lineHeight: 50,
                    fontFamily: FontFamily.regular,
                    fontWeight: FontWeight.semiBold,
                  },
                ]}
              >
                Payment Unsuccessful
              </Text>
            </View>
            <View
              style={{
                maxWidth: helpers.isDesktop() ? 530 : "100%",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: 60,
              }}
            >
              <Text
                style={{
                  fontFamily: FontFamily.regular,
                  fontWeight: FontWeight.regular,
                  fontSize: 16,
                  lineHeight: 22,
                  letterSpacing: 0.3,
                  textAlign: "center",
                  color: "#6A6A6A",
                }}
              >
                We’re having trouble completing your transaction. Please try
                again after some time. If your account was debited contact us at{" "}
                <TouchableOpacityLinkExternal href="mailto:support@themorningcontext.com">
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontWeight: FontWeight.regular,
                      fontSize: 16,
                      color: "#6A6A6A",
                      textDecorationLine: "underline",
                    }}
                  >
                    support@themorningcontext.com
                  </Text>
                </TouchableOpacityLinkExternal>
              </Text>
            </View>

            <View style={[{ marginBottom: 30 }]}>
              <ButtonGradient
                title="TRY AGAIN"
                rootStyle={{
                  btnWrapper: button.primaryGradient,
                  btnText: button.primaryGradientText,
                }}
                loader={false}
                disable={false}
                onClick={() =>
                  this.navigateToScreen(redirect_url ? redirect_url.retry : "/")
                }
              />
            </View>
            <View
              style={[
                Flex.row,
                Flex.justfiyCenter,
                { marginBottom: helpers.isDesktop() ? 100 : 30 },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: 14,
                    fontFamily: FontFamily.regular,
                    fontWeight: FontWeight.regular,
                    lineHeight: 18,
                    borderBottomWidth: 1,
                    borderBottomColor: "#000000",
                    color: "#121212",
                  },
                  !helpers.isDesktop() && {
                    maxWidth: "70%",
                    textAlign: "center",
                  },
                ]}
                onPress={() =>
                  this.navigateToScreen(
                    redirect_url ? redirect_url.success : "/"
                  )
                }
              >
                BACK TO WHERE I WAS
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  render() {
    const { enableLoader } = this.state;
    const webView = helpers.isWeb();
    const desktopView = webView && helpers.isDesktop();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/",
      native: { redirect: "/", verifyRedirect: "/" },
    };

    let data = {
      pageName: "Payment Status",
    };

    return (
      <>
        <Seo
          data={seoObj.getDefaultSeo(data)}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
        />

        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />

        {process.browser && (
          <ScrollView
            contentContainerStyle={
              ([
                appStyles.appContainer,
                helpers.isDesktop() && appStyles.appContainerDesktop,
              ],
              { backgroundColor: "#FFF" })
            }
          >
            {enableLoader ? (
              <View
                style={[
                  Flex.row,
                  {
                    maxWidth: helpers.isDesktop() ? "1400px" : "100%",
                    height: helpers.isDesktop() ? 530 : "80vh",
                    justfiyCenter: "center",
                    backgroundColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    marginHorizontal: "auto",
                  },
                  Helpers.bg_white,
                ]}
              >
                <View>
                  <ActivityIndicator size={75} color={"#907CFF"} />

                  <Text
                    style={[
                      subscribePlans.loaderTitle,
                      Margin.mt_3,
                      { textAlign: "center" },
                    ]}
                  >
                    Hold On!
                  </Text>
                  <Text
                    style={[
                      Margin.mt_3,
                      subscribePlans.loaderSubTitle,
                      {
                        fontFamily: FontFamily.regular,
                        fontSize: helpers.isDesktop() ? 24 : 18,
                        lineHeight: helpers.isDesktop() ? 28.8 : 24,
                        color: "#907CFF",
                      },
                    ]}
                  >
                    We are verifying your payment status
                  </Text>
                </View>
              </View>
            ) : (
              this.renderContent()
            )}
            <AppFooter
              navigateWeb={(slug) => this.props.navigateToScreen(slug)}
              navigateApp={(slug, params) =>
                this.props.navigation.navigate(slug, params)
              }
            />
          </ScrollView>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(PaymentRedirect));
