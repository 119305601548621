import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, pageCover, button, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import TermsConditions from '../../components/terms-and-conditions';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";

const cookies = new Cookies();

export class TermsnConditions extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        this.state = {
            pageLoader: false,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false,
        }
    }

    componentDidMount = () => {

    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[helpers.isDesktop() ? Margin.mb_7 : Margin.mb_5, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, Flex.alignCenter, { marginTop: helpers.isDesktop() ? 80 : 30, paddingHorizontal: helpers.isDesktop() ? 20 : 20 }]}>
                <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, !helpers.isDesktop() && { fontSize: 34 }, darkMode && { color: Colors.lightWhite }]}>Terms & Conditions</Text>
            </View>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }


    renderPageContent = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb900]}>
                    <TermsConditions darkMode={darkMode} />
                </View>

            </>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {

        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/terms-and-conditions',
            native: { redirect: '/terms-and-conditions', verifyRedirect: '/terms-and-conditions' }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        let data = {
            pageName: 'Terms And Conditions'
        }

        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/terms-and-conditions',
            native: { redirect: '/terms-and-conditions', verifyRedirect: '/terms-and-conditions' }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        let data = {
            pageName: 'Terms And Conditions'
        }

        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/terms-and-conditions" />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }
                    {showUparrow &&
                        <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                            <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                <CustomImage
                                    source={upArrowIcon}
                                    require={true}
                                    style={{ width: 60, height: 60 }}
                                    webStyle={{ width: 60, height: 60 }}
                                />
                            </TouchableOpacity>
                        </View>
                    }</>
                }

                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsnConditions);

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Terms & Conditions'
    }
]