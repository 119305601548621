import mixpanel from 'mixpanel-browser';
import { helpers } from '../helpers';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// mixpanel.init(process.env.MIX_PANEL_TOKEN);
// mixpanel.init('cf0b9c29629a36aed3b40aaa75ba383c');

let actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },

    alias: (id) => {
        mixpanel.alias(id);
    },

    time_event: (name) => {
        mixpanel.time_event(name);
    },

    track: (name, props, ignoreToken) => {
        let token = cookies.get('token');
        if (ignoreToken || token) {
            mixpanel.track(name, props);
        }
    },

    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },

    reset: () => {
        mixpanel.reset();
    }
};

export let Mixpanel = actions;