import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");
const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");

export class ArticleItemVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.data.title);
    }
  };

  render() {
    const {
      single,
      home,
      data: receivedData,
      viewArticle,
      viewAuthor,
      userStoryAction,
      applyStoryActions,
      userCredits,
      writers,
      archive,
      hometrendingstory,
      hideDate,
      darkMode,
    } = this.props;

    let data = receivedData

    if (Array.isArray(receivedData.posts) && receivedData.posts.length > 0) {
      data = { ...receivedData, category: { slug: 'yesterday' } };
    }
    console.log('debug: data', data)

    let btnClick,
      authorBtnClick,
      author2BtnClick,
      btnClickBookmark,
      btnClickLike;
    if (helpers.isWeb()) {
      btnClick = {
        to: {
          pathname: "/" + data.category.slug + "/" + data.slug,
          postData: data,
        },
      };
      authorBtnClick = {
        to: data.author ? "/writers/" + data.author[0].slug : "/writers/" + data.authors[0].slug,
      };
      if (data.author && data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      } else if (data.authors && data.authors[1]) {
        author2BtnClick = {
          to: "/writers/" + data.authors[1].slug,
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
    } else {
      btnClick = {
        onPress: () => viewArticle(data.category.slug, data.slug, data),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author ? data.author[0].slug : data.authors[0].slug);
        },
      };
      if (data.author && data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      } else if (data.authors && data.authors[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.authors[1].slug);
          },
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
    }
    return (
      <TouchableOpacityLink
        {...btnClick}
        handleClick={() => this.handleClick()}
      >
        <View style={[Flex.fill, writers && helpers.isDesktop() && Margin.mb_2]}>
          {userCredits && (
            <View style={[articleItemHorizontal.wrapAbove]}>
              <Text
                style={[
                  articleItemHorizontal.dateTop,
                  darkMode && { color: "rgba(255,255,255,0.6)" },
                ]}
              >
                {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
              </Text>
            </View>
          )}
          <View
            style={[
              articleItemHorizontal.wrap,
              Flex.fill,
              userCredits && { paddingTop: 0 },
              home && { paddingTop: 0 },
              !helpers.isDesktop() && {flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '95%'}
            ]}
          >
            <View style={[articleItemHorizontal.colLeft]}>
              {!writers ? (
                <View style={[Margin.mb_0, !helpers.isDesktop() && { width: '80vw'}]}>
                  <CustomImage
                    source={data.featured_image.url ? data.featured_image.url : data.featured_image}
                    require={false}
                    style={{ width: 150, height: 100 }}
                    webStyle={{
                      width: helpers.isDesktop() ? 292 : 150,
                      height: home
                        ? helpers.isDesktop()
                          ? 164
                          : 100
                        : helpers.isDesktop()
                          ? 180
                          : 71,
                      objectFit: "cover",
                    }}
                    resizeMode={"cover"}
                    altText={
                      data.featured_image.alt && data.featured_image.alt != ""
                        ? data.featured_image.alt
                        : data.title
                          ? data.title
                          : data.story?.title
                            ? data.story.title
                            : data.featured_image.title
                    }
                  />
                </View>
              ) : (
                <View style={[Margin.mb_0, !helpers.isDesktop() && {width: "80vw"}]}>
                  <CustomImage
                    source={data.featured_image.url ? data.featured_image.url : data.featured_image}
                    require={false}
                    style={{ width: 150, height: 100 }}
                    webStyle={{
                      width: helpers.isDesktop() ? 300 : 300,
                      height: helpers.isDesktop() ? 168 : 168,
                      objectFit: "cover"
                    }}
                    resizeMode={"cover"}
                    altText={
                      data.featured_image.alt && data.featured_image.alt != ""
                        ? data.featured_image.alt
                        : data.title
                          ? data.title
                          : data.story?.title
                            ? data.story.title
                            : data.featured_image.title
                    }
                  />
                </View>
              )}
              {userStoryAction && (
                <View style={[Flex.row, Margin.mt_1]}>
                  <TouchableOpacity
                    {...btnClickLike}
                    style={[postActionBar.actionBtn, { marginRight: 3 }]}
                  >
                    <CustomImage
                      source={
                        data.user_story_action && data.user_story_action.like
                          ? likeIconSelected
                          : likeIcon
                      }
                      require={true}
                      style={{ width: 15, height: 13 }}
                      webStyle={{ width: 15, height: 13 }}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    {...btnClickBookmark}
                    style={[postActionBar.actionBtn]}
                  >
                    <CustomImage
                      source={
                        data.user_story_action &&
                          data.user_story_action.bookmark
                          ? bookmarkIconSelected
                          : bookmarkIcon
                      }
                      require={true}
                      style={{ width: 20, height: 20 }}
                      webStyle={{
                        width: helpers.isDesktop() ? 26 : 21,
                        height: helpers.isDesktop() ? 25 : "auto",
                        marginLeft: -5,
                        marginRight: -5,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <View
              style={[
                articleItemHorizontal.colRight,
                helpers.isDesktop() ? Padding.pl_2 : {},
                hometrendingstory && { maxWidth: "50%" },
                !helpers.isDesktop() && { paddingRight: 0, marginVertical: '2vh', paddingLeft: 10, width: '100%' },
              ]}
            >
              <Text
                style={[
                  articleItemHorizontal.articleTitle,
                  Margin.mb_1,
                  !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                  home && {
                    fontWeight: "600",
                    fontSize: helpers.isDesktop() ? 28 : 16,
                    lineHeight: helpers.isDesktop() ? 39 : 22,
                  },
                  !helpers.isDesktop() && { fontSize: 16 },
                  !helpers.isDesktop() && { lineHeight: 22 },
                  writers && {
                    fontSize: helpers.isDesktop() ? 26 : 16,
                    lineHeight: helpers.isDesktop() ? 34 : 22,
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.bold },
                  darkMode && { color: "rgba(255,255,255,0.87)" },
                ]}
              >
                {data.title}
              </Text>
              <Text
                style={[articleItemHorizontal.desc,
                helpers.isDesktop() &&
                articleItemHorizontal.descDesktop,
                writers && { fontSize: 14, lineHeight: 18 },
                darkMode && { color: "rgba(255,255,255,0.87)" },
                !helpers.isDesktop() && { marginBottom: '2vh', width: '100%' }
              ]}
              >
                {data.strapline}
              </Text>
              {userCredits ? (
                <>
                  {data.credits && (
                    <View style={[Flex.row]}>
                      <Text style={postSingle.creditBox}>
                        {data.credits.unlock} Credits
                      </Text>
                    </View>
                  )}
                </>
              ) : (
                <>
                  {!home && helpers.isDesktop() && (
                    <Text
                      style={[
                        articleItemHorizontal.desc,
                        helpers.isDesktop() &&
                        articleItemHorizontal.descDesktop,
                        writers && { fontSize: 14, lineHeight: 18 },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                      ]}
                    >
                      {data.strapline != ""
                        ? data.strapline
                        : helpers.trimWord(data.excerpt)}
                    </Text>
                  )}
                  <View
                    style={[
                      articleItemHorizontal.lineBreak,
                      Margin.mt_0,
                      Margin.mb_1,
                      helpers.isDesktop() && [Margin.mb_2, Margin.mt_1],
                    ]}
                  ></View>
                  <View
                    style={[
                      { flexDirection: "row", flexWrap: "wrap", marginTop: 0 },
                      Platform.OS == "ios" && { alignItems: "center" },
                    ]}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        flexShrink: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      <TouchableOpacityLink {...authorBtnClick}>
                        <Text
                          style={[
                            articleItemHorizontal.authorTitle,
                            helpers.isDesktop() &&
                            articleItemHorizontal.authorTitleDesktop,
                            !helpers.isWeb() &&
                            articleItemHorizontal.authorTitleApp,
                            writers && {
                              fontSize: helpers.isDesktop() ? 14 : 10,
                              lineHeight: helpers.isDesktop() ? 19 : 12,
                            },
                            home &&
                            !helpers.isDesktop() && {
                              fontSize: 11,
                              lineHeight: 13,
                            },
                            !helpers.isWeb() && { marginRight: 0 },
                            darkMode && { color: "rgba(255,255,255,0.87)" },
                          ]}
                        >
                          {data.author ? data.author[0].name : data.authors[0].name}
                        </Text>
                      </TouchableOpacityLink>
                      {((data.author && data.author[1]) || (data.authors && data.authors[1])) && (
                        <>
                          <Text
                            style={[
                              articleItemHorizontal.authorTitle,
                              helpers.isDesktop() &&
                              articleItemHorizontal.authorTitleDesktop,
                              !helpers.isWeb() &&
                              articleItemHorizontal.authorTitleApp,
                              articleItemHorizontal.date,
                              writers && {
                                fontSize: helpers.isDesktop() ? 14 : 10,
                                lineHeight: helpers.isDesktop() ? 19 : 12,
                                fontWeight: "300",
                                fontFamily: FontFamily.light,
                              },
                              home &&
                              !helpers.isDesktop() && {
                                fontSize: 11,
                                lineHeight: 13,
                                marginTop: 5
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                              !helpers.isWeb() && { marginRight: 0 },
                            ]}
                          >
                            {" "}
                            and
                          </Text>
                          <TouchableOpacityLink {...author2BtnClick}>
                            <Text
                              style={[
                                articleItemHorizontal.authorTitle,
                                helpers.isDesktop() &&
                                articleItemHorizontal.authorTitleDesktop,
                                !helpers.isWeb() &&
                                articleItemHorizontal.authorTitleApp,
                                writers && {
                                  fontSize: helpers.isDesktop() ? 14 : 10,
                                  lineHeight: helpers.isDesktop() ? 19 : 12,
                                },
                                home &&
                                !helpers.isDesktop() && {
                                  fontSize: 11,
                                  lineHeight: 13,
                                },
                                !helpers.isWeb() && {
                                  marginRight: 0,
                                  marginLeft: 0,
                                },
                                darkMode && { color: "rgba(255,255,255,0.87)" },
                              ]}
                            >
                              {" "}
                              {data.author ? data.author[1].name : data.authors[1].name}
                            </Text>
                          </TouchableOpacityLink>
                        </>
                      )}
                    </View>
                    {!hideDate && (
                      <View
                        style={[
                          articleItemHorizontal.dot,
                          helpers.isDesktop() &&
                          articleItemHorizontal.dotDesktop,
                          home && {
                            marginTop: !helpers.isDesktop() ? 5 : 2,
                            position: "relative",
                            top: helpers.isDesktop() ? -2.7 : 0,
                          },
                          !helpers.isWeb() && { top: -4, left: 0, top: -5 },
                        ]}
                      ></View>
                    )}
                    {!hideDate && (
                      <Text
                        style={[
                          articleItemHorizontal.articleDate,
                          helpers.isDesktop() &&
                          articleItemHorizontal.articleDateDesktop,
                          !helpers.isWeb() &&
                          articleItemHorizontal.articleDateApp,
                          writers && {
                            fontSize: helpers.isDesktop() ? 14 : 10,
                            lineHeight: helpers.isDesktop() ? 19 : 12,
                          },
                          home &&
                          !helpers.isDesktop() && {
                            fontSize: 11,
                            lineHeight: 13,
                            marginTop: 5
                          },
                          darkMode && { color: "rgba(255,255,255,0.6)" },
                        ]}
                      >
                        {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                      </Text>
                    )}
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
      </TouchableOpacityLink>
    );
  }
}

export default ArticleItemVertical;
