import React, { Component } from "react";
import { Text, View, TextInput, Linking } from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  FontFamily,
  input,
  Colors,
  postSingle,
  button,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import Button from "../button";
import ButtonGradient from "../button-gradient";

const storyLocked = getImagePath("icons/storyLocked.png");
const storyLockedWhite = getImagePath("icons/storyLockedWhite.png");
const goldCoin = getImagePath("icons/goldCoin.png");
const silverCoin = getImagePath("icons/silverCoinSmall.png");

export class UnlockStoryNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { darkMode, privilege, data, privCredits, starterCredits } = this.props;
    return (
      <View
        style={{
          backgroundColor: "rgb(13, 118, 128)",
          paddingBottom: 40,
          paddingTop: 40,
          paddingLeft: 20,
          paddingRight: 20,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: "rgb(13, 118, 128)",
        }}
      >
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1000,
            helpers.isDesktop() && Padding.ph_2,
            {
              flexDirection: helpers.isDesktop() ? "column" : "column",
              alignItems: "center",
              justifyContent: "space-between",
            },
          ]}
        >
          <View
            style={{
              flexDirection: helpers.isDesktop() ? "row" : "column",
              alignItems: "center",
            }}
          >
            {/* <CustomImage
                            source={darkMode ? storyLockedWhite : storyLocked}
                            require={true}
                            style={{ width: 40, height: 40, resizeMode: 'contain' }}
                            webStyle={{ width: helpers.isDesktop() ? 60 : 40, height: helpers.isDesktop() ? 60 : 40, objectFit: 'contain', marginRight: helpers.isDesktop() ? 30 : 0 }}
                        /> */}
            <View style={{ marginTop: helpers.isDesktop() ? 0 : 15 }}>
              <Text
                style={[
                  {
                    color: "#fff",
                    fontFamily: "PlayfairDisplay-Bold",
                    fontSize: helpers.isDesktop() ? 18 : 18,
                    lineHeight: helpers.isDesktop() ? 25 : 18,
                    fontWeight: helpers.isDesktop() ? ' ' : 600,
                    marginBottom: 0,
                    color: darkMode ? Colors.lightWhite : "#ffffff",
                  },
                  !helpers.isDesktop() && { textAlign: "center" },
                  helpers.isDesktop() && { letterSpacing: "1px" },
                ]}
              >
                {privilege
                  ? `Unlock this ${data.newsletter ? "Newsletter" : "Story"
                  } with Privilege Credits`
                  : starterCredits?.available ? `Read this ${data.newsletter ? "Newsletter" : "Story"
                  } For Free` : null}
                  </Text>
                  <Text
                    style={[
                      {
                        color: "#fff",
                        fontFamily: "PlayfairDisplay-Bold",
                        fontSize: helpers.isDesktop() ? 21 : 21,
                        lineHeight: helpers.isDesktop() ? 25 : 18,
                        fontWeight: helpers.isDesktop() ? ' ' : 600,
                        marginBottom: 0,
                        color: darkMode ? Colors.lightWhite : "#ffffff",
                      },
                      !helpers.isDesktop() && { textAlign: "center" },
                      helpers.isDesktop() && { letterSpacing: "1px" },
                    ]}
                  >
                  {
                    starterCredits?.available === 0 ? 'You have run out of free credits. Subscribe to keep reading.' : null
                  }
              </Text>
              {/* {!privilege &&
                                <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 14, fontWeight: '400', fontFamily: FontFamily.regular, marginBottom: 0, color: darkMode ? Colors.lightWhite : '#000000' }, !helpers.isDesktop() && { textAlign: 'center', marginTop: 5 }]}>If insufficient starter credits then privilege credits will be utilized.</Text>
                            } */}
              {starterCredits?.available === 0 ? null : <View
                style={{
                  backgroundColor: "#ffffff",
                  width: "90%",
                  height: "1px",
                  marginBottom: 10,
                  alignSelf: "center",
                }}
              ></View>}
            </View>
          </View>
          {(!privilege && !starterCredits?.available) ?  <View style={{marginTop: helpers.isDesktop() ? 15 : 10}}>
              <ButtonGradient
                title={'Subscribe'}
                onClick={() => {
                  Linking.openURL('/pricing')
                }}
                rootStyle={{
                    btnWrapper: {...button.primaryGradient, paddingHorizontal: 10, paddingVertical: 10},
                    btnText: button.primaryGradientText,

                }}
            />
            </View> : 
            <View style={{ marginTop: helpers.isDesktop() ? 0 : 20 }}>
            <View style={[Flex.row]}>
              <Button
                title={"UNLOCK FOR"}
                rootStyle={{
                  btnWrapper: [
                    {
                      backgroundColor: "white",
                      paddingHorizontal: 0,
                    },
                    Flex.alignCenter,
                    Flex.justfiyCenter,
                    { width: 120, cursor: "pointer" },
                    { height: 46 },
                    !helpers.isWeb() && { width: 180 },
                  ],
                  btnText: {
                    fontSize: 14,
                    color: "black",
                    lineHeight: 14,
                    letterSpacing: 0.05,
                    fontFamily: FontFamily.regular,
                    fontWeight: "400",
                  },
                }}
                onClick={() => this.props.unlockPost()}
                loader={false}
                disable={false}
                indicatorColor={"#ffffff"}
              />
              <View
                style={[
                  {
                    borderColor: "white",
                    borderWidth: 1,
                    paddingRight: 15,
                    paddingVertical: 10,
                  },
                  Flex.row,
                  Flex.alignCenter,
                  {
                    backgroundColor: "white",
                    paddingHorizontal: 0,
                  },
                  { backgroundColor: "white" },
                ]}
              >
                <CustomImage
                  webStyle={{ width: 23, height: 23, marginRight: 5 }}
                  style={{ width: 23, height: 23, marginRight: 5 }}
                  source={privilege ? goldCoin : silverCoin}
                  require={true}
                  resizeMode="contain"
                  altText={"Credits"}
                />
                <Text
                  style={[
                    postSingle.creditText,
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {data.story.credits.unlock}
                </Text>
              </View>
            </View>
          </View>}
          <View
            style={[
              Flex.row,
              Flex.alignCenter,
              { marginTop: helpers.isDesktop() ? 0 : 15 },
            ]}
          >
            <Text
              style={[
                {
                  marginRight: 10,
                  fontSize: helpers.isDesktop() ? 14 : 16,
                  fontWeight: "100",
                  fontFamily: FontFamily.abrilFatface,
                  lineHeight: helpers.isDesktop() ? 44 : 24,
                  marginBottom: 0,
                  color: darkMode ? Colors.lightWhite : "#ffffff",
                },
                !helpers.isDesktop() && { textAlign: "center" },
                helpers.isDesktop() && { letterSpacing: "1px" },
              ]}
            >
              {`Your ${privCredits ? "Privilege" : "Starter"} Credit Balance`}:
            </Text>
            {/* <View style={[postSingle.creditBoxBlueNews, Flex.row, Flex.alignCenter]}> */}
            <CustomImage
              webStyle={{ width: 23, height: 23, marginRight: 5, marginTop: 0 }}
              style={{ width: 23, height: 23, marginRight: 5 }}
              source={privilege ? goldCoin : silverCoin}
              require={true}
              resizeMode="contain"
              altText={"Credits"}
            />
            {/* </View>  */}
            <Text
              style={[
                {
                  fontSize: helpers.isDesktop() ? 19 : 18,
                  fontWeight: "100",
                  fontFamily: "PlayfairDisplay-Bold",
                  lineHeight: helpers.isDesktop() ? 44 : 24,
                  marginBottom: 0,
                  color: darkMode ? Colors.lightWhite : "#ffffff",
                },
                !helpers.isDesktop() && { textAlign: "center" },
                helpers.isDesktop() && { letterSpacing: "1px" },
              ]}
            >
              {privCredits ? privCredits : starterCredits?.available}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default UnlockStoryNew;
