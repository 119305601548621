import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, formStyle, referralSection, button, Messages, Colors, FontFamily, FontWeight } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';
import { helper, helpers } from '../../utils/helpers';


export class ReferralBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referralMsg: {
                enable: false,
                type: '', // error / success
                msg: ''
            },
            btnDisable: true,
            btnLoader: false,
            email: ''
        }
    }

    componentDidMount = () => {
    }

    validateInput = (value) => {        
        if (value) {            
            this.setState({
                btnDisable: false,
                email: value,
            });
        } else {
            this.setState({
                email: value,
                btnDisable: true,
                referralMsg: {
                    enable: false,
                    type: '',
                    msg: ''
                }
            })
        }
    }

    sendReferralLink = () => {
        const { email } = this.state;
        this.setState({
            btnLoader: true,
            referralMsg: {
                enable: false,
                type: '',
                msg: ''
            }
        });

        if(helpers.validateEmail(email)){
            this.setState({
                btnLoader: false,
                referralMsg: {
                    enable: false,
                    type: 'success',
                    msg: 'referral code sent'
                }
            });
        } else {
            this.setState({
                btnLoader: false,
                referralMsg: {
                    enable: false,
                    type: 'error',
                    msg: 'Invalid Email ID'
                }
            });
        }
    }

    render() {
        const { referralMsg, btnDisable, btnLoader, email } = this.state;
        return (
            <View>
                {/* <Text style={[Typo.h3, Helpers.textCenter, Helpers.textUppercase]}>Want to read more stories for free?</Text> */}
                <Text style={[Typo.h3_M, helpers.isDesktop() && Typo.h3_MDesktop, Helpers.textCenter, Helpers.textUppercase, Margin.mb_1]}>Want to read more stories for free?</Text>
                <PageBreak style={[Margin.mt_1, Margin.mb_2]} size={'small'} />
                <Text style={[referralSection.subTitle, Helpers.textCenter, Margin.mt_2]}>Refer a friend and get <Text style={{fontWeight: FontWeight.bold, fontFamily: FontFamily.bold}}>Free Credits</Text></Text>

                <View style={[Margin.mt_3]}>
                    <TextInput
                        style={[formStyle.input]}
                        placeholder={'Enter your E-mail Address'}
                        placeholderTextColor={Colors.PlaceholderColor}
                        onChangeText={this.validateInput}
                        value={email}
                    />
                    {referralMsg.enable &&
                        <View style={[{ marginTop: 5 }]}>
                            <Text style={[ referralMsg.type == 'success' ? Messages.errorText : Messages.successText ]}>{referralMsg.msg}</Text>
                        </View>
                    }

                    <View style={[Margin.mt_15]}>
                        <ButtonGradient
                            title={'Share'}
                            onClick={() => this.sendReferralLink()}
                            rootStyle={{
                                btnWrapper: button.primaryGradient,
                                btnText: button.primaryGradientText,
                            }}
                            loader={btnLoader}
                            disable={btnDisable}
                        />
                    </View>
                </View>
                <Text style={[referralSection.desc, Helpers.textCenter]}>Get 5 free credits when your friend signs up wih the code sent to you. Max free credit limit is 20 credits.</Text>

            </View>
        );
    }
}

export default ReferralBox;