import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableOpacity, Platform, ActivityIndicator } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Ripple from 'react-native-material-ripple';

const buttonRightArrow = getImagePath('icons/buttonRightArrow.png');
const rightBlueIcon = getImagePath('icons/rightBlueIcon.png');

export class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() {
        const { title, rootStyle, onClick, icon, loader, disable, indicatorColor, textWhite, darkMode, smallText, topIcon, rightIcon, blueIcon, fullWidth } = this.props;
        let btnClick;

        if (helpers.isWeb()) {
            btnClick = {
                onPress: () => onClick()
            }
        } else {
            btnClick = {
                onPress: () => onClick()
            }
        }
        let btnDisabled = disable ? disable : false;
        let btnLoader = loader ? loader : false;
        btnClick = (btnDisabled || btnLoader) ? '' : btnClick;
        return (
            <Ripple rippleCentered key={rootStyle?.btnWrapper} {...btnClick} style={[rootStyle?.btnWrapper, fullWidth && { maxWidth: '100%' }, {}]}>
                <TouchableOpacity activeOpacity={0.8}>
                    {!btnLoader && <View style={[icon && Flex.row, icon && Flex.alignCenter, icon && Flex.justfiyCenter, icon && { margin: 'auto' }, rightIcon && Flex.row, rightIcon && Flex.alignCenter, rightIcon && Flex.justfiyCenter]}>

                        {icon &&
                            <View>
                                <CustomImage
                                    source={icon}
                                    style={topIcon ? { width: 16, height: 18 } : rootStyle.image}
                                    webStyle={topIcon ? { width: 16, height: 18 } : rootStyle.image}
                                    require={true}
                                    resizeMode={'contain'}
                                />
                            </View>
                        }
                        <Text style={[rootStyle?.btnText, btnDisabled && button.disableBtnText, icon && { paddingLeft: 20 }, textWhite && { color: '#ffffff' }, (darkMode && btnDisabled) && { color: 'rgba(255,255,255,0.3)' }, smallText && { fontSize: 14, letterSpacing: 1 }]}>{title}</Text>
                        {
                            rightIcon &&
                            <View style={{ marginLeft: 15 }}>
                                <CustomImage
                                    source={blueIcon ? rightBlueIcon : buttonRightArrow}
                                    style={{ width: blueIcon ? 20 : 16, height: 14, marginTop: 3 }}
                                    webStyle={{ width: blueIcon ? 20 : 16, height: 14, marginTop: 3 }}
                                    require={true}
                                    resizeMode={'contain'}
                                />
                            </View>
                        }

                    </View>}
                    {btnLoader && <ActivityIndicator size={25} color={indicatorColor ? indicatorColor : '#787676'} />}
                </TouchableOpacity>
            </Ripple>
        );
    }
}

export default Button;