import React, { Component } from "react";
import SidebarModal from "../sidebar-modal";
import UserProfile from "../user-profile";
import SubscriptionPricingPlan from "../subscription-pricing-plan";
import EditProfile from "../edit-profile";
import PaymentInfo from "../payment-info";
import PastOrders from "../past-orders";
import CancelSubscription from "../cancel-subscription";
import PrivilegedCredits from "../privileged-credits";
import StarterCredits from "../starter-credits";
import NewsLetterConfig from "../newsletters-config";
import BuyCredits from "../buy-credits";
import LightDarkMode from "../light-dark-mode";
import Modal from "../modal";
import {
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  FlatList,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  article,
  FontFamily,
  pageContent,
  FontWeight,
  Colors,
  banner,
} from "../../styles/appStyles";
import GiftSubscriptions from "../gift-subscriptions";

const screens = {
  accounts: {
    title: "The Morning Context",
    titleMain: true,
  },
  "accounts/subscriptions": {
    title: "",
    titleMain: false,
  },
  "accounts/cancel-subscription": {
    title: "Cancel Subscription",
    titleMain: false,
  },
  "accounts/cancel-team-subscription": {
    title: "Cancel Subscription",
    titleMain: false,
  },
  "accounts/edit-profile": {
    title: "Edit Profile",
    titleMain: false,
  },
  "accounts/payment-info": {
    title: "Payment Info",
    titleMain: false,
  },
  "accounts/orders": {
    title: "Past Orders",
    titleMain: false,
  },
  "accounts/privileged-credits": {
    title: "Privilege Credits",
    titleMain: false,
  },
  "accounts/starter-credits": {
    title: "Starter Credits",
  },
  "accounts/newsletters": {
    title: "Newsletters",
    titleMain: false,
  },
  "accounts/buy-credits": {
    title: "Buy Credits",
    titleMain: false,
  },
  "accounts/dark-mode": {
    title: "View Options",
    titleMain: false,
  },
  "accounts/manage-subscription": {
    title: "Manage Subscription",
    titleMain: false,
  },
  "accounts/referral": {
    title: "Gift Subscriptions",
    titleMain: false
  }
};

class SidebarCheck extends Component {
  editProfileRef;
  constructor(props) {
    super(props);
    this.state = {
      hash: "",
      show: false,
      screen: false,
      passProps: false,
      propsFor: false,
      showBack: false,
      showClose: false,
      title: false,
      titleMain: false,
      refreshKey: false,
      notAuthorized: false,
    };
    this.userAccountRef = React.createRef();
  }

  handleClickOutside = (event) => {
    //alert(this.userAccountRef.current.contains(event.target))
    if (
      !this.userAccountRef.current ||
      this.userAccountRef.current.contains(event.target)
    ) {
      return;
    }
  };

  componentDidMount = () => {
    this.checkScreen();
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("touchstart", this.handleClickOutside);
  };

  componentDidUpdate = () => {
    this.checkScreen();
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("touchstart", this.handleClickOutside);
  };

  refreshPlans = () => {
    if (
      this.state.hash == "accounts" ||
      this.state.hash == "accounts/subscriptions" ||
      this.state.hash == "accounts/privileged-credits"
    ) {
      this.setState({
        refreshKey: !this.state.refreshKey,
      });

    }};

    checkScreen = () => {
        if(this.props.hash != this.state.hash) {
            if(screens[this.props.hash] || (this.props.hash.startsWith('accounts/manage-subscription/') && (this.props.hash.length > 29))) {
                this.setState({
                    hash: this.props.hash,
                    show: true,
                    screen: this.props.hash,
                    title: screens[this.props.hash]? screens[this.props.hash].title: 'The Morning Context',
                    titleMain: screens[this.props.hash]? screens[this.props.hash].titleMain: 'The Morning Context',
                    showBack: true, // this.state.screen != "",
                    showClose: false // this.state.screen == ""
                })
            } else {
                this.setState({
                    hash: this.props.hash,
                    show: false,
                    screen: false,
                    title: false,
                    titleMain: false,
                    showBack: false,
                    showClose: false,
                    passProps: false,
                    propsFor: false
                })
            }
        }
    }

  navigateScreen = (screen, prop) => {
    if (prop) {
      this.setState(
        {
          passProps: prop,
          propsFor: screen,
        },
        () => this.props.changeHash(screen)
      );
    } else {
      this.setState(
        {
          passProps: false,
          propsFor: false,
        },
        () => this.props.changeHash(screen)
      );
    }
  };

  navigatePage = (screen) => {
    this.props.navigateToScreen(screen);
  };

  closeModal = () => {
    this.setState({ show: false });
    this.props.closeSideBar();
  };

  setModalTitle = (title, hideBack) => {
    this.setState({
      title: title,
      showBack: hideBack ? false : this.state.showBack,
    });
  };

  renderContent = () => {
    const { screen, refreshKey, passProps, propsFor, notAuthorized } =
      this.state;
    const { isAdmin, referenceId, darkMode } = this.props;
    if (this.props?.loginRequired()) {
      return null;
    }

    return (
      <>
        {notAuthorized && (
          <View style={{ zIndex: 9999 }}>
            <Modal
              renderContent={() =>
                this.renderSubscriptionModalContent(darkMode)
              }
              closeModal={() => {}}
              paddingHorizontal={true}
              topPadding={false}
              verifyModal={true}
              subscription={true}
              darkMode={darkMode}
              hideBottomPadding={true}
            />
          </View>
        )}

        {screen == "accounts" && (
          <UserProfile
            key={refreshKey}
            showCBPortal={(
              portal_section,
              existingSubscriptionId,
              subscribtionID,
              quantity
            ) =>
              this.props.showCBPortal(
                portal_section,
                existingSubscriptionId,
                subscribtionID,
                quantity
              )
            }
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            logout={this.props.logout}
            history={this.props.history}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {(screen == "accounts/manage-subscription") && (this.props.showCBPortal(null, null, null, null) || true) && (this.navigateScreen('accounts'))}

        {(screen.startsWith("accounts/manage-subscription/")) && (screen.length > 29) && (this.props.showCBPortal(screen.split('accounts/manage-subscription/')[1], null, null, null) || true) && (this.navigateScreen('accounts'))}

        {screen == "accounts/subscriptions" && (
          <SubscriptionPricingPlan
            key={refreshKey}
            sidePanel={true}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            navigatePage={(screen) => this.navigatePage(screen)}
            goBack={() => this.props.goBack()}
            showSubscriptionPlan={(subscribtionId, subscribtion, country) =>
              this.props.showSubscriptionPlan(
                subscribtionId,
                subscribtion,
                country
              )
            }
            setModalTitle={(title) => this.setModalTitle(title)}
            history={this.props.history}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
            setCheckoutLoaderState={(loaderState) => this.props.setCheckoutLoaderState(loaderState)}
            getCheckoutLoaderState={this.props.getCheckoutLoaderState}
          />
        )}

        {screen == "accounts/cancel-subscription" && (
          <CancelSubscription
            params={
              propsFor == "accounts/cancel-subscription" ? passProps : false
            }
            key={screen + refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title, hideBack) =>
              this.setModalTitle(title, hideBack)
            }
            isTeam={false}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/cancel-team-subscription" && (
          <CancelSubscription
            params={
              propsFor == "accounts/cancel-team-subscription"
                ? passProps
                : false
            }
            key={screen + refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title, hideBack) =>
              this.setModalTitle(title, hideBack)
            }
            isTeam={true}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/edit-profile" && (
          <EditProfile
            key={refreshKey}
            ref={(editProfileRef) => (this.editProfileRef = editProfileRef)}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            setModalTitle={(title) => this.setModalTitle(title)}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/payment-info" && (
          <PaymentInfo
            params={propsFor == "accounts/payment-info" ? passProps : false}
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            setModalTitle={(title) => this.setModalTitle(title)}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/orders" && (
          <PastOrders
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title) => this.setModalTitle(title)}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {
          screen == "accounts/referral" && (
            <GiftSubscriptions />
          ) 
        }

        {screen == "accounts/privileged-credits" && (
          <PrivilegedCredits
            params={
              propsFor == "accounts/privileged-credits" ? passProps : false
            }
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title) => this.setModalTitle(title)}
            showSubscriptionPlan={(subscribtionId, subscribtion, country) =>
              this.props.showSubscriptionPlan(
                subscribtionId,
                subscribtion,
                country
              )
            }
            openGiftCardModal={(
              subscribtionID,
              selectedSubscribtion,
              country
            ) =>
              this.props.openGiftCardModal(
                subscribtionID,
                selectedSubscribtion,
                country
              )
            }
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/starter-credits" && (
          <StarterCredits
            params={propsFor == "accounts/starter-credits" ? passProps : false}
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title) => this.setModalTitle(title)}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/newsletters" && (
          <NewsLetterConfig
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title) => this.setModalTitle(title)}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/buy-credits" && (
          <BuyCredits
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.goBack()}
            setModalTitle={(title) => this.setModalTitle(title)}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}

        {screen == "accounts/dark-mode" && (
          <LightDarkMode
            key={refreshKey}
            navigateScreen={(screen, prop) => this.navigateScreen(screen, prop)}
            goBack={() => this.props.navigation.goBack()}
            setModalTitle={(title) => this.setModalTitle(title)}
            modeOptions={true}
            isAdmin={isAdmin}
            referenceId={referenceId}
            setNotAuthorized={() => this.setState({ notAuthorized: true })}
          />
        )}
      </>
    );
  };

  renderSubscriptionModalContent = (darkMode) => {
    return (
      <View style={{ padding: 25 }}>
        <Text
          style={[
            Margin.mb_1,
            {
              fontSize: 26,
              fontFamily: FontFamily.abrilFatface,
              color: darkMode ? Colors.lightWhite : "rgba(0,0,0)",
              textAlign: "center",
            },
          ]}
        >
          Error
        </Text>
        <View style={{ marginBottom: 0, marginTop: 20 }}>
          <Text
            style={{
              fontSize: 16,
              fontFamily: FontFamily.merriweather,
              color: "#FF6B75",
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#FF6B75",
              borderRadius: 4,
              paddingHorizontal: 20,
              paddingVertical: 15,
            }}
          >
            You are not authorized to view this page.
          </Text>
        </View>
      </View>
    );
  };

  render() {
    const { show, showBack, showClose, title, titleMain, screen } = this.state;
    const { isAdmin, referenceId } = this.props;
    return (
      show && (
        <SidebarModal
          showBack={screen == "accounts" && isAdmin ? false : showBack}
          showClose={showClose}
          title={title}
          titleMain={titleMain}
          closeModal={() => this.closeModal()}
          renderContent={() => this.renderContent()}
          goBack={() => {
            this.editProfileRef && this.editProfileRef.showPasswordViewSet()
              ? this.editProfileRef.hidePasswordView()
              : this.props.goBack();
          }}
          isAdmin={isAdmin}
          referenceId={referenceId}
          setCheckoutLoaderState={(loaderState) => this.props.setCheckoutLoaderState(loaderState)}
          getCheckoutLoaderState={this.props.getCheckoutLoaderState}
        />
      )
    );
  }
}

export default SidebarCheck;
