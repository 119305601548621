import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, button, appStyles, article, FontFamily, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ButtonGradient from '../../components/button-gradient';
import CustomImage from '../../components/custom-image';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import getImagePath from '../../utils/image-helper';
import Seo from "../../components/seo-meta";
import { setUserToken } from '../../../redux/User/user.actions';
import PageBreak from '../../components/page-break';
import { Mixpanel } from '../../utils/mixpanel';
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const cookies = new Cookies();
const invitationVerified = getImagePath('icons/invitation-verified.png');
const emailWarningIcon = getImagePath('icons/email-warning.png');

export class VerifyMemberInvite extends Component {
    constructor(props) {
        super(props);
        let inviteCode = null, email=null;
        let searchArr, type;
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth;

        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;

        if (webView && process.browser && window.location.search.includes("token=")) {
            searchArr = window.location.search.replace("?", "").split("&");
        } else if (!webView && this.props.route.params.query) {
            searchArr = this.props.route.params.query.split("&");
        }

        if (searchArr) {
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("token=");
            });
            if (foundSearch) {
                let search = foundSearch.replace("token=", "");
                search = search.replace("redirect-params=", "");
                inviteCode = (search != '') ? search : null;
                console.log("check inviteCode =>", inviteCode);
            }

            searchArr.map((searchParam) => {                
                if (searchParam.includes("email=")) {
                    email = searchParam.replace("email=", "");
                }
                return searchParam;
            });
        }

        this.state = {
            token: token,
            enableBtnLoader: true,
            error: false,
            buttonCode: false,
            redirectUrl: '/',
            email: email,
            type: type,
            inviteCode: inviteCode
        }
    }

    componentDidMount() {

        let encodedRedirectURL = `${window.location.pathname}${window.location.search}${window.location.hash}`;
        encodedRedirectURL = encodeURIComponent(encodedRedirectURL);
        const { isAuthenticated } = this.props.auth

        if(!isAuthenticated){
            this.props.navigateToScreen(`/sign-in?encoded=1&redirect=${encodedRedirectURL}`);
            return
        }
        const { token, inviteCode, email } = this.state;
        let app_type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/accept-team-invitation`;
        let body = {
            invite_code: inviteCode,
            invited_email: email
        }

        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    if (helpers.isWeb()) {
                        this.props.navigateToScreen(`/sign-in?encoded=1&redirect=${encodedRedirectURL}`);
                    } else {
                        this.props.navigation.navigate('/sign-in', { redirect: '/accept-team-invitation', verifyRedirect: '/accept-team-invitation' + `?redirect-params=token=${inviteCode}`, email: email, 'redirect-params': `token=${inviteCode}` });
                    }
                    return;
                }
                if (res.success && res.code == 'team_member_added') {
                    this.setState({ enableBtnLoader: false, buttonCode: res.code });
                }
                else {
                    this.setState({
                        enableBtnLoader: false,
                        buttonCode: res.code,
                        errorMessage: res?.error?.account_email
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    enableBtnLoader: false,
                    error: '',
                });
                console.log('Error=>>', error);
            });
    }

    goToNextPage = () => {
        this.navigateToScreen('/')
    }


    navigateToScreen = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen(slug);
        } else {
            this.props.navigation.navigate(slug);
        }
    }

    render() {
        const { enableBtnLoader, error, buttonCode, email, errorMessage, companyName } = this.state;
        const webView = helpers.isWeb();
        const desktopView = webView && helpers.isDesktop();
        let redirectSlugs = {
            web: '/',
            native: { redirect: 'homescreen', verifyRedirect: '/' }
        }

        let data = {
            pageName : 'Verify Member Invite'
        }

        return (
            <>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()}  showFullHeader={true}/>
                <ScrollView contentContainerStyle={[appStyles.appContainer, helpers.isDesktop() && appStyles.appContainerDesktop, { backgroundColor: '#F6F6F6' }]}>
                    {enableBtnLoader ?
                        <View style={[Flex.row, { width: helpers.isDesktop() ? '1400px' : '100%', margin: desktopView ? 'auto' : 0, height: helpers.isDesktop() ? 530 : '100%', justfiyCenter: 'center', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }]}>
                            <View>
                                <ActivityIndicator size={75} color={'#907CFF'} />
                                <Text style={[Margin.mt_3, { fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 28.8 : 24, color: '#907CFF' }]}>Veryfying your invitation</Text>
                            </View>
                        </View>
                        :
                        <View style={{ maxWidth: helpers.isDesktop() ? 880 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                            <View style={[Helpers.bg_white, Margin.mb_4, { marginTop: helpers.isDesktop() ? 150 : 100 }, { paddingVertical: helpers.isDesktop() ? 70 : 35, paddingHorizontal: 20 }, { marginHorizontal: 15, marginBottom: helpers.isDesktop() ? 50 : 40 }]}>
                                <View style={[Margin.mt_0]}>
                                    {(buttonCode == 'team_member_added') ?
                                        <View style={[Padding.ph_2, Margin.mt_0]}>
                                            <View style={[article.headerWrapper]}>
                                                <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_4]}>
                                                    <View style={[Padding.ph_2, Margin.mb_1, Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18, textTransform: 'regular' }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 18 }]}>Your invitation is verified</Text>
                                                    </View>
                                                    <PageBreak size={'small'} />
                                                </View>
                                            </View>
                                            <View style={[Margin.mb_3, Flex.alignCenter]}>
                                                <CustomImage
                                                    source={invitationVerified}
                                                    require={true}
                                                    style={{ width: 112, height: 93 }}
                                                    webStyle={{ width: 112, height: 93 }}
                                                />
                                            </View>
                                            <View>
                                                <Text style={{ fontFamily: FontFamily.light, fontWeight: FontWeight.light, fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 28 : 26, letterSpacing: 0.3, textAlign: 'center' }}>You are now part of {companyName} team on TMC.</Text>
                                            </View>
                                        </View>
                                        :
                                        <View style={[Padding.ph_2, Margin.mt_0]}>
                                            <View style={[article.headerWrapper]}>
                                                <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_4]}>
                                                    <View style={[Padding.ph_2, Margin.mb_1, Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18, textTransform: 'regular' }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 18 }]}>{errorMessage == 'Already subscribed user.' ? 'You are already subscribed!' : errorMessage == 'Invitation expired.' ? 'Invitation Expired!' : 'Invalid invitation!'}</Text>
                                                    </View>
                                                    <PageBreak size={'small'} />
                                                </View>
                                            </View>
                                            <View style={{ maxWidth: helpers.isDesktop() ? 450 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>

                                                <Text style={{ fontFamily: FontFamily.light, fontWeight: FontWeight.light, fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 28 : 26, letterSpacing: 0.3, textAlign: 'center' }}>{errorMessage == 'Already subscribed user.' ? 'Sorry, but you could not be added to this team as you are already have a TMC subscription plan.' : errorMessage == 'Invitation expired.' ? 'Your invitation has expired. Please ask the owner to re-send the invitation link to you to join their team.' : errorMessage}</Text>

                                            </View>
                                        </View>
                                    }
                                </View>
                                {
                                    buttonCode == 'team_member_added' &&
                                    <View style={[Padding.ph_2, Padding.pt_4]}>
                                        <ButtonGradient
                                            title="CONTINUE"
                                            rootStyle={{
                                                btnWrapper: button.primaryGradient,
                                                btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular, fontSize: 16 }],
                                            }}
                                            loader={false}
                                            disable={false}
                                            onClick={() => this.goToNextPage()}
                                        />
                                    </View>
                                }
                            </View>
                            {buttonCode !== 'team_member_added' &&
                                <View style={[Flex.row, Flex.justfiyCenter, { marginBottom: helpers.isDesktop() ? 100 : 30 }]}>
                                    <Text style={{ fontSize: 14, fontFamily: FontFamily.regular, fontWeight: FontWeight.regular, lineHeight: 18, borderBottomWidth: 1, borderBottomColor: '#000000' }} onPress={() => this.navigateToScreen('/')}>{`Continue to the The Morning Context homepage >`}</Text>
                                </View>
                            }
                        </View>
                    }
             <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
                </ScrollView>

   
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(VerifyMemberInvite));
