import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  banner,
  pageContent,
  pageCover,
  Colors,
  button,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import PageCover from "../page-cover";
import ButtonGradient from "../button-gradient";

const angleIcon = getImagePath("icons/angle-icon.png");
const blackDots = getImagePath("icons/black-dots.png");
const pinkDots = getImagePath("icons/pink-dots.png");

class GiftingMini extends Component {

  pushBannerInteractionEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "banner_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      category_type: "NA",
      banner_name: "Gift Subscription",
      banner_position: "bottom",
      CTA_text: "Learn more",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  renderInlineBanner() {
    const { data, darkMode, style } = this.props;
    let bgColor = "#FFF4F1";
    if (style && style.backgroundColor) {
      bgColor = style.backgroundColor;
    }
    return (
      <View
        style={[
          banner.Wrapper,
          { paddingVertical: 50, backgroundColor: bgColor },
          darkMode && { backgroundColor: Colors.darkBlackColor },
        ]}
      >
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && {
              maxWidth: "1216px",
              marginHorizontal: "auto",
            },
            { paddingHorizontal: 16 },
            helpers.isDesktop() && { flexDirection: "row" },
            Flex.alignCenter,
          ]}
        >
          {!helpers.isDesktop() ? (
            <View
              style={{
                width: helpers.isDesktop() ? "50%" : "100%",
                alignItems: helpers.isDesktop() ? "flex-end" : "center",
              }}
            >
              <CustomImage
                source={data.page_image.url}
                style={{ width: 250, height: 150 }}
                webStyle={{
                  width: "auto",
                  height: helpers.isDesktop() ? 250 : 150,
                  maxWidth: "100%",
                }}
                altText={
                  data.page_image.alt
                    ? data.page_image.alt
                    : data.page_image.title
                }
                resizeMode="contain"
              />
            </View>
          ) : null}
          <View
            style={[
              { width: helpers.isDesktop() ? "50%" : "100%", marginTop: 10 },
              helpers.isDesktop() && { paddingRight: "66px" },
              !helpers.isDesktop() && Flex.alignCenter,
            ]}
          >
            <View
              style={[
                Margin.mb_15,
                Flex.row,
                ,
                !helpers.isDesktop() && { marginBottom: 8 },
              ]}
            >
              <View
                style={[
                  !helpers.isDesktop() && { flex: 1, textAlign: "center" },
                ]}
              >
                <Text
                  accessibilityRole="header"
                  aria-level="1"
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    {
                      fontSize: helpers.isDesktop() ? 34 : 34,
                      lineHeight: helpers.isDesktop() ? 51 : 51,
                    },
                    darkMode && { color: Colors.lightWhite },
                    !helpers.isWeb() && { textAlign: "center" },
                  ]}
                >
                  {data.title}
                </Text>
              </View>
            </View>
            <View
              style={{
                height: 2,
                backgroundColor: darkMode ? Colors.lineColor : "#000000",
                width: helpers.isDesktop() ? 160 : 50,
                marginBottom: helpers.isDesktop() ? 20 : 16,
              }}
            ></View>
            <Text
              style={[
                pageContent.text,
                helpers.isDesktop() && pageContent.textDesktop,
                { marginBottom: helpers.isDesktop() ? 0 : 0, fontSize: 19 },
                !helpers.isDesktop() && Helpers.textCenter,
                helpers.isDesktop() && { fontSize: 19 },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              {data.strapline}
            </Text>
            <View style={{ width: helpers.isDesktop() ? 164 : "100%" }}>
              <ButtonGradient
                title={"Learn More"}
                rounded={"true"}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradient,
                    {
                      maxWidth: helpers.isDesktop() ? "164px" : "100%",
                      width: "100%",
                      marginTop: 32,
                      borderRadius: 0,
                    },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    { fontSize: 17, fontWeight: "600" },
                  ],
                }}
                onClick={() => 
                  {
                    this.pushBannerInteractionEventToDataLayer()
                    this.props.navigateToScreen(`/gifting`)
                  }
                }
                darkMode={darkMode}
              />
            </View>
          </View>
          {helpers.isDesktop() ? (
            <View
              style={{
                width: helpers.isDesktop() ? "50%" : "100%",
                alignItems: helpers.isDesktop() ? "flex-end" : "center",
              }}
            >
              <CustomImage
                source={data.page_image.url}
                style={{ width: 250, height: 150 }}
                webStyle={{
                  width: "auto",
                  height: helpers.isDesktop() ? 250 : 150,
                  maxWidth: "100%",
                }}
                altText={
                  data.page_image.alt
                    ? data.page_image.alt
                    : data.page_image.title
                }
                resizeMode="contain"
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  }

  renderNormalBanner = (blank, noCover) => {
    const { data } = this.props;
    return (
      <View
        style={[
          Margin.mt_4,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
        ]}
      >
        <PageCover
          title={data.title}
          coverImage={noCover ? false : data.page_image.url}
          pageCoverOverlay={false}
          content={blank ? "" : data.strapline}
        />
      </View>
    );
  };

  renderStraplineBanner = () => {
    const { data, darkMode } = this.props;
    let bgColor = "#FFF4F1";
    if (data.color_hash && data.color_hash != "") {
      bgColor = "#" + data.color_hash;
    }
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { paddingHorizontal: 20 },
        ]}
      >
        <View
          style={[
            helpers.isDesktop() && Margin.mb_7,
            Helpers.textCenter,
            {
              backgroundColor: bgColor,
              marginTop: helpers.isDesktop() ? 130 : 75,
              paddingHorizontal: helpers.isDesktop() ? 20 : 0,
              width: "100%",
            },
          ]}
        >
          <Text
            style={[
              Margin.mt_0,
              Margin.mb_0,
              pageCover.title,
              helpers.isDesktop() && pageCover.titleDesktop,
              Helpers.textCenter,
              { position: "relative", top: helpers.isDesktop() ? -40 : -21 },
              !helpers.isDesktop() && { fontSize: 30, lineHeight: 39 },
            ]}
          >
            {data.title}
          </Text>
          <Text
            style={[
              { marginBottom: helpers.isDesktop() ? 90 : 50 },
              pageContent.subTitle,
              helpers.isDesktop() && pageContent.subTitleDesktop,
              { maxWidth: helpers.isDesktop() ? 600 : "100%" },
              !helpers.isWeb() && { textAlign: "center" },
            ]}
          >
            {data.strapline}
          </Text>
          <CustomImage
            source={angleIcon}
            require={true}
            style={{
              width: helpers.isDesktop() ? 50 : 30,
              height: helpers.isDesktop() ? 50 : 30,
              position: "absolute",
              left: 0,
              bottom: 0,
            }}
            webStyle={{
              width: helpers.isDesktop() ? 50 : 30,
              height: helpers.isDesktop() ? 50 : 30,
              position: "absolute",
              left: 0,
              bottom: 0,
            }}
          />
          <CustomImage
            source={darkMode ? pinkDots : blackDots}
            require={true}
            style={{
              width: 50,
              height: 20,
              position: "absolute",
              right: helpers.isDesktop() ? 30 : 20,
              bottom: 25,
            }}
            webStyle={{
              width: helpers.isDesktop() ? 60 : 45,
              height: helpers.isDesktop() ? 25 : 20,
              position: "absolute",
              right: helpers.isDesktop() ? 30 : 20,
              bottom: 25,
            }}
          />
        </View>
      </View>
    );
  };

  render() {
    const { data, darkMode } = this.props;
    return (
      <>
        {this.renderInlineBanner()}
        {/* {(data.image_inline && data.strapline && (data.page_image && !data.image_hidden)) &&
        }
        {(!data.image_inline && data.strapline && (data.page_image && !data.image_hidden)) &&
          this.renderNormalBanner()
        }
        {(data.strapline && (data.page_image == false || data.image_hidden)) &&
          this.renderStraplineBanner()
        }
        {(data.strapline == '' && (data.page_image && !data.image_hidden)) &&
          this.renderNormalBanner(true)
        }
        {(data.strapline == '' && (data.page_image == false || data.image_hidden)) &&
          this.renderNormalBanner(true, true)
        } */}
      </>
    );
  }
}

export default GiftingMini;
