import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";

import Button from "../button";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";

import getImagePath from "../../utils/image-helper";
const mockup = getImagePath("img/mockups.png");

const CorporateSubscriptionMini = (props) => {
  let { darkMode, navigateToScreen } = props;

  const pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: props.custom_user_id || "NA",
      user_status: props.custom_user_id ? "logged in" : "guest",
      article_section: "corporate subscription",
      newsletter_section: "NA",
      CTA_text: "learn more",
      CTA_position: "middle",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
    dataLayer.push({
      event: "banner_interaction",
      custom_user_id: props.custom_user_id || "NA",
      user_status: props.custom_user_id ? "logged in" : "guest",
      category_type: "NA",
      banner_name: "Corporate Subscription",
      banner_position: "middle",
      CTA_text: "Learn more",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  return (
    <View
      style={{
        backgroundColor: "#F3F5FF",
        paddingVertical: helpers.isDesktop() ? 56 : 40,
      }}
    >
      <View
        style={{
          maxWidth: "1216px",
          width: "100%",
          marginHorizontal: "auto",
        }}
      >
        <View
          style={{
            flexDirection: helpers.isDesktop() ? "row" : "column-reverse",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: helpers.isDesktop() ? "50%" : "100%",
              paddingRight: helpers.isDesktop() ? 66 : 20,
              paddingLeft: helpers.isDesktop() ? 0 : 20,
            }}
          >
            <Text
              style={[
                {
                  position: "relative",
                  color: "#292929",
                  fontSize: "34px",
                  fontFamily: "PlayfairDisplay-Bold",
                  fontWeight: "700",
                  lineHeight: 51,
                  marginBottom: 16,
                },
                !helpers.isDesktop() && { textAlign: "center" },
              ]}
            >
              Corporate Subscription
            </Text>
            <Text
              style={[
                {
                  color: "#292929",
                  fontFamily: FontFamily.regular,
                  lineHeight: 32.3,
                  fontSize: 19,
                  letterSpacing: 0.3,
                },
                !helpers.isDesktop() && { textAlign: "center" },
              ]}
            >
              Give your team the knowledge of independent, deeply researched and
              well written stories with our unique team subscription plans.
            </Text>
            <View style={{ width: helpers.isDesktop() ? 164 : "100%" }}>
              <ButtonGradient
                title={"Learn More"}
                rounded={"true"}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradient,
                    {
                      maxWidth: helpers.isDesktop() ? "164px" : "100%",
                      width: "100%",
                      marginTop: 32,
                      borderRadius: 0,
                    },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    { fontSize: 17, fontWeight: "600" },
                  ],
                }}
                onClick={() => 
                  {
                    pushInformationToDataLayer()
                    navigateToScreen(`/pricing?idx=1`)
                  }
                }
                darkMode={darkMode}
              />
            </View>
          </View>
          <View
            style={{
              width: helpers.isDesktop() ? "50%" : "100%",
              paddingRight: helpers.isDesktop() ? 0 : 20,
              paddingLeft: helpers.isDesktop() ? 16 : 20,
            }}
          >
            <View style={[!helpers.isDesktop() && [{ marginBottom: 32 }]]}>
              <CustomImage
                source={mockup}
                require={true}
                resizeMode={"cover"}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "auto",
                }}
                webStyle={{
                  maxWidth: "100%",
                  height: "auto",
                  margin: 0,
                }}
              // resizeMode="stretch"
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default CorporateSubscriptionMini;
