import getImagePath from "../../utils/image-helper";

const business = getImagePath("icons/business_n.png");
const covid = getImagePath("icons/covid-19_n.png");
const environment = getImagePath("icons/envoroment_n.png");
const markets = getImagePath("icons/markets_n.png");
const sports = getImagePath("icons/sports_n.png");
const world = getImagePath("icons/world_n.png");
const economy = getImagePath("icons/economy_n.png");
const chaos = getImagePath("icons/chaos_n.png");
const politics = getImagePath("icons/politics_n.png");
const healthcare = getImagePath("icons/healthcare_n.png");
// Newly added icons
const internet = getImagePath("icons/internet_n.png");
const entertainment = getImagePath("icons/entertainment_n.png");
const tech = getImagePath("icons/tech_n.png");
const crypto = getImagePath("icons/crypto_n.png");
const ipo = getImagePath("icons/ipo_n.png");
const nation = getImagePath("icons/nation_n.png");
const policy = getImagePath("icons/policy_n.png");
const education = getImagePath("icons/education_n.png");
const india = getImagePath("icons/india_n.png");
const delhi = getImagePath("icons/delhi_n.png");

export function renderBorderColor(colorVal) {
  let color;
  colorVal = colorVal ? colorVal.toLowerCase() : '';
  if (colorVal === "business") return (color = "#A97DF1");
  if (colorVal === "choas" || colorVal === "chaos") return (color = "#55C79A");
  if (colorVal === "internet") return (color = "#F7A34B");
  if (colorVal === "politics") return (color = "#DE0046");
  if (colorVal === "health") return (color = "#ECB731");
  if (colorVal === "environment") return (color = "#B62C87");
  if (colorVal === "entertainment") return (color = "#EC7431");
  if (colorVal === "economy") return (color = "#31BFEC");
  if (colorVal === "markets") return (color = "#EC31C3");
  if (colorVal === "covid-19") return (color = "#EC3131");
  if (colorVal === "world") return (color = "#3531EC");
  if (colorVal === "sports") return (color = "#5DC5BE");
  if (colorVal === "tech") return (color = "#450B69");
  if (colorVal === "crypto") return (color = "#000000");
  if (colorVal === "ipo") return (color = "#72D310");
  if (colorVal === "nation") return (color = "#A999FF");
  if (colorVal === "policy") return (color = "#25568B");
  if (colorVal === "education") return (color = "#5AC8FA");
  if (colorVal === "india") return (color = "#F49AAA");
  if (colorVal === "delhi") return (color = "#D31056");

  return (color = "#6C7AF6");
}

export function categoryImage(category) {
  let image;
  category = category ? category.toLowerCase() : '';
  if (category === "business") return (image = business);
  if (category === "choas" || category === "chaos") return (image = chaos);
  if (category === "internet") return (image = internet);
  if (category === "politics") return (image = politics);
  if (category === "health") return (image = healthcare);
  if (category === "environment") return (image = environment);
  if (category === "entertainment") return (image = entertainment);
  if (category === "economy") return (image = economy);
  if (category === "markets") return (image = markets);
  if (category === "covid-19") return (image = covid);
  if (category === "world") return (image = world);
  if (category === "sports") return (image = sports);
  if (category === "tech") return (image = tech);
  if (category === "crypto") return (image = crypto);
  if (category === "ipo") return (image = ipo);
  if (category === "nation") return (image = nation);
  if (category === "policy") return (image = policy);
  if (category === "education") return (image = education);
  if (category === "india") return (image = india);
  if (category === "delhi") return (image = delhi);
  return (image = delhi);
}

export function paddingCategory(category) {
  category = category ? category.toLowerCase() : '';
  if (category === "business")
    return {
      paddingTop: 10.61,
      paddingBottom: 22.36,
      paddingLeft: 14.62,
      width: 117,
    };
  if (category === "choas" || category === "chaos")
    return {
      paddingTop: 21,
      paddingBottom: 4.36,
      paddingLeft: 10.6,
      width: 119,
    };
  if (category === "internet")
    return {
      paddingTop: 12.61,
      paddingBottom: 12.36,
      paddingLeft: 25.17,
      width: 105,
    };
  if (category === "politics")
    return {
      paddingTop: 15.1,
      paddingBottom: 10.86,
      paddingLeft: 30.55,
      width: 104,
    };
  if (category === "health")
    return {
      paddingTop: 10,
      paddingBottom: 15.5,
      paddingLeft: 43.6,
      width: 117,
    };
  if (category === "environment")
    return { paddingTop: 21, paddingBottom: 10, paddingLeft: 18.4, width: 146 };
  if (category === "entertainment")
    return { paddingTop: 10.5, paddingBottom: 19, paddingLeft: 14, width: 158 };
  if (category === "economy")
    return { paddingTop: 20, paddingBottom: 8, paddingLeft: 17.2, width: 116 };
  if (category === "markets")
    return {
      paddingTop: 11,
      paddingBottom: 15.86,
      paddingLeft: 12.2,
      width: 104,
    };
  if (category === "covid-19")
    return { paddingTop: 19, paddingBottom: 6, paddingLeft: 13.5, width: 96 };
  if (category === "world")
    return { paddingTop: 20, paddingBottom: 7, paddingLeft: 18.92, width: 116 };
  if (category === "sports")
    return {
      paddingTop: 12,
      paddingBottom: 17,
      paddingLeft: 30.46,
      width: 104,
    };
  if (category === "tech")
    return {
      paddingTop: 12.2,
      paddingBottom: 15,
      paddingLeft: 18.5,
      width: 79,
    };
  if (category === "crypto")
    return { paddingTop: 13, paddingBottom: 19, paddingLeft: 22, width: 106 };
  if (category === "ipo")
    return { paddingTop: 9, paddingBottom: 14, paddingLeft: 21, width: 90 };
  if (category === "nation")
    return { paddingTop: 12, paddingBottom: 15, paddingLeft: 19.5, width: 106 };
  if (category === "policy")
    return { paddingTop: 9, paddingBottom: 14, paddingLeft: 18, width: 123 };
  if (category === "education")
    return { paddingTop: 14, paddingBottom: 7, paddingLeft: 14, width: 117 };
  if (category === "india")
    return {
      paddingTop: 8.5,
      paddingBottom: 11.5,
      paddingLeft: 54,
      width: 117,
    };
  if (category === "delhi")
    return { paddingTop: 9, paddingBottom: 14, paddingLeft: 21, width: 90 };

  return { paddingTop: 9, paddingBottom: 14, paddingLeft: 21, width: 90 };
}
