import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Image
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  Typo,
  Colors,
  button,
  FontFamily,
  FontWeight
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import Modal from "../modal";
import ButtonGradient from "../../components/button-gradient";
// import { Picker } from "@react-native-picker/picker";
import { SelectCountry as Dropdown } from "react-native-element-dropdown";
import getImagePath from "../../utils/image-helper";
const creditcardIcon = getImagePath("icons/ico_card_512.png");
const upiIcon = getImagePath("icons/ico_upi_512.png");
const walletIcon = getImagePath("icons/ico_wallet_512.png");
const bankIcon = getImagePath("icons/ico_bank_512.png");
const hdfcbankIcon = getImagePath("icons/ico_hdfc_512.png");
const icicibankIcon = getImagePath("icons/ico_icici_512.png");
const citibankIcon = getImagePath("icons/ico_citbank_512.png");
const sbibankIcon = getImagePath("icons/ico_sbi_512.png");
const kotakbankIcon = getImagePath("icons/ico_kotak_512.png");
const axisbankIcon = getImagePath("icons/ico_axis_512.png");
const standrdbankIcon = getImagePath("icons/ico_standard_512.png");

const paymentmethod_data = [
  {
    slug: "card",
    name: "Credit or Debit cards",
    image: creditcardIcon,
  },
  {
    slug: "upi",
    name: "UPI",
    image: upiIcon,
  },
  {
    slug: "netbanking",
    name: "Netbanking",
    image: bankIcon,
  },
  {
    slug: "wallets",
    name: "Wallets",
    image: walletIcon,
  },
];
const paymentbanks_data = [
  {
    slug: "sbi",
    name: "SBI",
    image: sbibankIcon,
  },
  {
    slug: "hdfc",
    name: "HDFC",
    image: hdfcbankIcon,
  },
  {
    slug: "icici",
    name: "ICICI",
    image: icicibankIcon,
  },
  {
    slug: "citi_bank",
    name: "CITIBANK",
    image: citibankIcon,
  },
  {
    slug: "kotak_mahindra",
    name: "KOTAK MAHINDRA",
    image: kotakbankIcon,
  },
  {
    slug: "axis_bank",
    name: "AXIS BANK",
    image: axisbankIcon,
  },
  {
    slug: "standard_chartered",
    name: "STANDARD CHARTERED",
    image: standrdbankIcon,
  },
  {
    slug: "other",
    name: "OTHER BANKS",
    image: {
      uri: " ",
    },
  },
];

export class CheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMode: "",
      bankName: "",
      showBankOptions: false,
      showPaymentModeError: false,
      showBankNameError: false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    this.myRef && this.myRef.current && this.myRef.current.open();
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  onProceedToCheckout = () => {
    const { proceedToCheckoutScreen } = this.props;
    console.log("Proceeding to checkout ---->>>>")
    let isValidOptionSelected = true;
    if (!this.state.paymentMode) {
      this.setState({ showPaymentModeError: true });
      isValidOptionSelected = false;
    }
    if (isValidOptionSelected) {
      if (this.state.paymentMode === "card") {
        // Until we have a fix in the backend to remove the bank name, using a dummy bank name for now
        proceedToCheckoutScreen(this.state.paymentMode, "hdfc");
      }
      else {
        console.log("Here ---->>>>")
        proceedToCheckoutScreen(this.state.paymentMode, this.state.bankName);
      }
      
    }
  };

  renderTopLine = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.bgLightOrange,
          darkMode && { backgroundColor: Colors.darkBlackColor },
        ]}
      >
        <Text
          style={[
            Typo.text20,
            helpers.isDesktop() && Helpers.textLeft,
            { paddingVertical: 15, paddingLeft: 25 },
            { textTransform: "capitalize" },
            darkMode && { color: "#ffffff" },
          ]}
        >
          Payment Details
        </Text>
      </View>
    );
  };

  renderPaymentMethodItem = ({ item }) => {
    const isSelected = item.slug === this.state.paymentMode;
    let icon = null;
    switch (item.slug) {
      case 'card':
        icon = creditcardIcon;
        break;
      case 'upi':
        icon = upiIcon;
        break;
      case 'netbanking':
        icon = bankIcon;
        break;
      case 'wallets':
        icon = walletIcon;
        break;
      default:
        break;
    }
    return (
      <TouchableOpacity
        style={{
          padding: 16,
          backgroundColor: isSelected ? "lightgrey" : "white",
          borderBottomWidth: 1,
          borderBottomColor: "#bbbbbb",
          flexDirection: "row",
          alignItems: "center",
        }}
        onPress={() => {
          console.log("Clicked here with item: ", item)
          this.setState({ paymentMode: item.slug, showPaymentModeError: false }, () =>  this.onProceedToCheckout());
        }}
      >
        {icon && (
          <Image
            source={icon}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
        )}
        <Text style={{ fontWeight: isSelected ? "bold" : "normal" }}>
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  renderCheckoutModalContent = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={{
          position: "relative",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
          zIndex: 999,
        }}
      >
        {this.renderTopLine()}
        <View
          style={[
            Flex.justfiyCenter,
            Flex.alignCenter,
            {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
              paddingLeft: 25,
              paddingRight: 25,
            },
          ]}
        >
          <View
            style={{
              flex: 1,
              direction: "row",
              marginTop: 10,
              marginBottom: 20,
              width: "100%",
            }}
          >
            <View style={{ flex: 1 }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#bbbbbb",
                  borderRadius: 4,
                  paddingHorizontal: 16,
                }}
              >
                <Text
                  style={{
                    padding: 16,
                    fontWeight: 'bold'
                  }}
                >
                  Choose your payment method:
                </Text>
                <View style={{ marginHorizontal: -16, borderBottomWidth: 1, borderBottomColor: 'grey' }} />
                <FlatList
                  data={paymentmethod_data}
                  keyExtractor={(item) => item.slug}
                  renderItem={this.renderPaymentMethodItem}
                  style={{ maxHeight: 250 }}
                  showsVerticalScrollIndicator={false}
                />
              </View>
            </View>
            {this.state.showPaymentModeError && (
              <View style={styles.errorCard}>
                <Text style={styles.errorText}>
                  * Please select a payment method of choice
                </Text>
              </View>
            )}
          </View>
          <View style={[styles.notCard, styles.elevation]}>
            <View>
              <Text style={styles.cardTitle}>Note:</Text>
            </View>
            <View>
              <Text style={styles.cardText}>
                1. To provide a seamless reading experience, our subscriptions
                are set to be recurring if your card provider supports it. Turn
                off this feature from your My Account section anytime.
              </Text>
              <Text style={styles.cardText}>
                2. All payment details are secure and we do not store your card
                details. Under RBI guidelines, card details can only be saved
                with your authorized card networks.
              </Text>
              <Text style={styles.cardText}>
                3. Auto-renewals can be easily turned off by accessing your
                account.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  render = () => {
    return (
      <View style={{ zIndex: 9999 }}>
        <Modal
          ref="child"
          renderContent={() => this.renderCheckoutModalContent()}
          closeModal={() => this.closeModal()}
          paddingHorizontal={true}
          topPadding={false}
          subscription={false}
        />
      </View>
    );
  };
}

export default CheckoutModal;
const styles = StyleSheet.create({
  dropdown: {
    marginVertical: 16,
    marginHorizontal: 0,
    height: 40,
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 3,
    fontSize: 14,
    paddingVertical: 10,
    paddingHorizontal: 8,
  },
  errorDropdown: {
    borderColor: "#ff0000",
  },
  placeholderStyle: {
    fontSize: 14,
  },
  selectedTextStyle: {
    fontSize: 16,
    marginLeft: 8,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  notCard: {
    backgroundColor: "white",
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 10,
    width: "100%",
    marginTop: 20,
  },
  cardTitle: {
    fontFamily: FontFamily.regular,
    fontWeight: "700",
    color: "#000000",
    fontSize: 13,
    lineHeight: 16,
  },
  cardText: {
    fontFamily: FontFamily.regular,
    fontWeight: "400",
    color: "#000000",
    fontSize: 12,
    lineHeight: 16,
  },
  elevation: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  checkoutButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  errorCard: {
    marginTop: -12,
  },
  errorText: {
    color: "#ff0000",
    fontFamily: FontFamily.regular,
    fontWeight: "600",
    fontSize: 13,
    lineHeight: 16,
  },
});
