import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button, Typo, FontFamily, Colors } from '../../styles/appStyles';
import PageBreak from '../page-break';
import { helpers } from '../../utils/helpers';
import { Mixpanel } from '../../utils/mixpanel';

export class IosLockedView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    componentDidMount = () => {
    }

    render() {
        const { darkMode } = this.props;


        return (
            <View style={{backgroundColor: darkMode ? Colors.darkBlackColorBg : '#FFFFFF', borderWidth: 1, borderColor: '#907CFF', margin: 10, padding: 15}}>
               	<Text style={{fontFamily: FontFamily.merriweatherB, fontSize: 18, lineHeight: 28, color: darkMode ? Colors.lightWhite : Colors.black, textAlign: 'center', letterSpacing: 0.1 }}>This story is only available to our subscribers or members who have privilege credits </Text>
            </View>
        );
    }
}

export default IosLockedView;

