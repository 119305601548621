import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
  TextInput,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
  input,
  Colors,
  Messages,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import ArticleItemHorizontal from "../article-item-horizontal";
import ScrollContainerView from "../scroll-container-view";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import ArticleItem from "../article-item";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../button-gradient";
import Cookies from "universal-cookie";
import { Mixpanel } from "../../utils/mixpanel";
import AuthenticationWrapper from "../authentication-wrapper";
import YoutubeVideo from "../vimeo-video/youtube-video";

const viewMore = getImagePath("icons/view-more.png");
const checkIcon = getImagePath("icons/check.png");
const cookies = new Cookies();
const windowWidth = Dimensions.get("window").width;

export class HomeArchievedContent extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      btnDisable: true,
      email: "",
      referralMsg: {
        enable: false,
        type: "", // error / success
        msg: "",
      },
      showToken: !helpers.isWeb() || process.browser ? true : false,
    };
  }

  componentDidMount = () => { };

  viewMoreArticles = () => {
    const { viewAllArticles } = this.props;
    viewAllArticles();
  };

  viewArticle = (catSlug, slug, postData) => {
    const { viewArticle } = this.props;
    viewArticle(catSlug, slug, postData);
  };

  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };
  viewCategory = (slug) => {
    const { viewCategory } = this.props;
    viewCategory(slug);
  };

  signUp = () => {
    const { email } = this.state;
    this.setState({
      btnLoader: true,
      referralMsg: {
        enable: false,
        type: "",
        msg: "",
      },
    });

    // if (helpers.validateEmail(email)) {
    this.setState({
      btnLoader: false,
    });
    try {
      if (this.props.trackSignUp) {
        this.props.trackSignUp(email, "mid banner");
      }
    } catch (error) {
      console.log("mix panel track error ==>", error);
    }
    if (helpers.isWeb()) {
      this.props.navigateWeb(
        `/sign-up?redirect=/free-reads&email=${email}`
      );
    } else {
      this.props.navigateApp("/sign-up", {
        redirect: "/free-reads",
        verifyRedirect: "/free-reads",
        email: email,
      });
    }
    // }
    // else {
    //     this.setState({
    //         btnLoader: false,
    //         referralMsg: {
    //             enable: true,
    //             type: 'error',
    //             msg: 'Please enter a valid email'
    //         }
    //     });
    // }
  };

  // validateInput = (value) => {
  //     if (value) {
  //         this.setState({ btnDisable: false, email: value });
  //     } else {
  //         this.setState({ email: value, btnDisable: true })
  //     }
  // }

  handleClick = (title, data, index) => {
    try {
      if (this.props.trackStoryClick) {
        this.props.trackStoryClick(title, data, index);
      }
    } catch (error) {
      console.log("check mix panel track error ==>", error);
    }
  };

  trackCateoryClick = (item) => {
    // try {
    //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: 'home_starter_collection', section_value: { title: item.name, slug: item.slug } });
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  render() {
    let { email, btnDisable, referralMsg } = this.state;
    const { data, darkMode } = this.props;
    const videoIdUrl = "https://youtu.be/ExJ7-6qIq9o";
    const videoId = "ExJ7-6qIq9o";

    let webView = helpers.isWeb();
    let btnClick, btnClickCategory;

    if (helpers.isWeb()) {
      btnClick = {
        to: "/archive",
      };
    } else {
      btnClick = {
        onPress: () => this.viewMoreArticles(),
      };
    }
    return (
      <View
        style={{
          backgroundColor: darkMode
            ? Colors.darkBlackColor
            : this.props.show
              ? "#ffffff"
              : "#FFF9F1",
          paddingBottom: helpers.isDesktop() ? 100 : 20,
        }}
      >
        <View
          style={[
            Padding.ph_2,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWebFull,
          ]}
        >
          <View
            style={[
              helpers.isDesktop() && Padding.pt_4,
              helpers.isDesktop() && Padding.pb_4,
              !helpers.isDesktop() && { marginTop: -20 },
            ]}
          >
            <View
              style={{
                width: helpers.isWeb() ? "fit-content" : "auto",
                alignSelf: "center",
              }}
            >
              {/* <View style={Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }}> */}
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 30 : 24,
                    lineHeight: helpers.isDesktop() ? 40 : 28,
                    fontFamily: FontFamily.abrilFatface,
                    textAlign: "center",
                    paddingBottom: 5,
                  },
                  {
                    paddingBottom: 4,
                    color: helpers.isDesktop() ? "#000" : "#051462",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Sign up now to access the Starter Collection
              </Text>
              {helpers.isDesktop() && (
                <View
                  style={{
                    position: "relative",
                    height: 2,
                    width: "100%",
                    borderTopWidth: 2,
                    borderTopColor: "#000000",
                    marginTop: 0,
                    // alignItems: 'center',
                    // justifyContent: 'center'
                  }}
                >
                  {/* <View style={[styles.smallBox, this.props.active ? { width : 70 } : { width : 50 }, darkMode && { backgroundColor: 'rgba(255, 255, 255, 0.6)' }]}></View> */}
                </View>
              )}
              {/* </View> */}
              {/* {helpers.isDesktop() &&  <PageBreak style={{width: helpers.isDesktop() ? '85%': '100%', marginLeft:'auto', marginRight: 'auto'}}/> } */}
            </View>

            <View
              style={{ flexDirection: helpers.isDesktop() ? "row" : "column" }}
            >
              <View
                style={[
                  helpers.isDesktop() && { flex: 1 },
                  Margin.mt_5,
                  !helpers.isDesktop() && Margin.mt_3,
                  Padding.pb_2,
                  {
                    paddingLeft: helpers.isDesktop() ? 40 : 18,
                    borderWidth: 2,
                    borderColor: "#6C7AF6",
                    backgroundColor: darkMode
                      ? Colors.darkBlackColor1
                      : "white",
                    width: 700,
                  },
                  !helpers.isDesktop() && {
                    width: "100%",
                    marginHorizontal: "auto",
                  },
                ]}
              >
                <Text
                  style={[
                    Padding.pb_2,
                    Padding.pt_15,
                    {
                      fontSize: helpers.isDesktop() ? 36 : 22,
                      lineHeight: helpers.isDesktop() ? 54 : 33,
                      fontFamily: FontFamily.abrilFatface,
                      color: "#051462",
                    },
                    darkMode && {
                      fontWeight: FontWeight.medium,
                      letterSpacing: 0.7,
                    },
                  ]}
                >
                  Free 30 Starter Credits
                </Text>
                <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                  <View style={{ minWidth: 14 }}>
                    <CustomImage
                      source={checkIcon}
                      require={true}
                      style={{
                        width: 20,
                        height: 19,
                        resizeMode: "contain",
                        marginTop: 3,
                        marginRight: 15,
                      }}
                      webStyle={{
                        width: 20,
                        height: 19,
                        objectFit: "contain",
                        marginTop: 3,
                        marginRight: 15,
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 18 : 16,
                      lineHeight: 27,
                      marginBottom: helpers.isDesktop() ? 10 : 20,
                      letterSpacing: 0.3,
                      flex: 1,
                      color: darkMode ? Colors.lightWhite : "#000",
                    }}
                  >
                    Get FREE 100 Starter Credits
                  </Text>
                </View>
                <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                  <View style={{ minWidth: 14 }}>
                    <CustomImage
                      source={checkIcon}
                      require={true}
                      style={{
                        width: 20,
                        height: 19,
                        resizeMode: "contain",
                        marginTop: 3,
                        marginRight: 15,
                      }}
                      webStyle={{
                        width: 20,
                        height: 19,
                        objectFit: "contain",
                        marginTop: 3,
                        marginRight: 15,
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 18 : 16,
                      lineHeight: 27,
                      marginBottom: helpers.isDesktop() ? 10 : 20,
                      letterSpacing: 0.3,
                      flex: 1,
                      color: darkMode ? Colors.lightWhite : "#000",
                    }}
                  >
                    Use Starter Credits to unlock some of the best free longreads and newsletters from our Starter Collection
                  </Text>
                </View>
                <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                  <View style={{ minWidth: 14 }}>
                    <CustomImage
                      source={checkIcon}
                      require={true}
                      style={{
                        width: 20,
                        height: 19,
                        resizeMode: "contain",
                        marginTop: 3,
                        marginRight: 15,
                      }}
                      webStyle={{
                        width: 20,
                        height: 19,
                        objectFit: "contain",
                        marginTop: 3,
                        marginRight: 15,
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 18 : 16,
                      lineHeight: 27,
                      marginBottom: helpers.isDesktop() ? 10 : 20,
                      letterSpacing: 0.3,
                      flex: 1,
                      color: darkMode ? Colors.lightWhite : "#000",
                    }}
                  >
                    Starter Credits{" "}
                    <Text
                      style={{
                        fontFamily: FontFamily.bold,
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: 27,
                        marginBottom: helpers.isDesktop() ? 10 : 0,
                        letterSpacing: 0.3,
                        flex: 1,
                        color: darkMode ? Colors.lightWhite : "#000",
                      }}
                    >
                      do not expire
                    </Text>{" "}
                    {/* – use them as on stories of your choice */}
                  </Text>
                </View>
                <View>
                  <View
                    style={[
                      helpers.isDesktop() && Flex.row,
                      !helpers.isDesktop() && { width: "100%" },
                      {
                        alignSelf: "flex-start",
                        marginTop: !helpers.isDesktop() ? 20 : 0,
                      },
                    ]}
                  >
                    {/* <TextInput
                                    placeholder={'Email Id'}
                                    placeholderTextColor={Colors.placeholderTextColor}
                                    style={[input.Text, Padding.pt_1, Padding.pl_15, !helpers.isDesktop() && Padding.pl_1, Padding.pb_1, !helpers.isDesktop() && Margin.mb_1, helpers.isDesktop() && { marginBottom: 6 }, { backgroundColor: 'white', height: 46, borderColor: '#6C7AF6', borderWidth: 1, width: helpers.isDesktop() ? '75%' : '100%', color: '#000', marginRight: helpers.isDesktop() ? 8 : 0 }, !helpers.isDesktop() && { fontSize: 13 }]}
                                    onChangeText={this.validateInput}
                                    value={email}
                                /> */}
                    <ButtonGradient
                      title={"SIGN UP FOR FREE"}
                      rounded={true}
                      rootStyle={{
                        btnWrapper: [
                          button.primaryGradientBtn,
                          {
                            height: 46,
                            borderRadius: 0,
                            flex: 1,
                            position: "relative",
                            right: helpers.isDesktop() ? 1.5 : 0,
                          },
                        ],
                        btnText: [
                          button.primaryGradientText,
                          {
                            fontSize: helpers.isDesktop() ? 17 : 16,
                            fontWeight: "400",
                            paddingLeft: 35,
                            paddingRight: 35,
                            paddingTop: helpers.isDesktop() ? 0 : 15,
                            paddingBottom: helpers.isDesktop() ? 0 : 15,
                          },
                        ],
                      }}
                      onClick={this.signUp}
                      disable={false}
                      darkMode={darkMode}
                    />
                  </View>
                </View>
                {referralMsg.enable && (
                  <View style={[{ marginTop: 5 }]}>
                    <Text
                      style={[
                        referralMsg.type == "success"
                          ? Messages.successText
                          : Messages.errorText,
                      ]}
                    >
                      {referralMsg.msg}
                    </Text>
                  </View>
                )}
              </View>

              <View
                style={[
                  Flex.FlexWrap,
                  Flex.fill,
                  { top: 23 },
                  {
                    alignSelf: "center",
                    paddingHorizontal: helpers.isDesktop() ? 0 : 18,
                    alignItems: helpers.isDesktop() ? "flex-end" : "center",
                    backgroundColor: darkMode ? Colors.darkBlackColor1 : "",
                    width: 600,
                  },
                  !helpers.isDesktop() && {
                    width: "100%",
                    marginHorizontal: "auto",
                  },
                ]}
              >
                {helpers.isWeb() ? (
                  <YoutubeVideo videoId={videoIdUrl} home={true} />
                ) : (
                  <YoutubeVideo
                    videoId={videoId}
                    videoIdUrl={videoIdUrl}
                    home={true}
                  />
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default AuthenticationWrapper(HomeArchievedContent);
