
import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
// import { Colors } from 'react-native/Libraries/NewAppScreen';

const admin_dashboard = getImagePath('icons/admin-dashboard.png');
const unrestricted_access = getImagePath('icons/unrestricted-access.png');
const support_line = getImagePath('icons/support-line.png');
const Benefits = getImagePath('icons/benefits.png');


export class BenefitsPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }


    benefitsPlan = () => {
        let { darkMode } = this.props;
        return (
            <View style={[Padding.pl_2, Padding.pr_2]}>
                <View style={[!helpers.isDesktop() && Margin.mb_4, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Flex.alignCenter, Margin.mb_4, Margin.mt_2]}>
                        <Text style={[{ fontSize: helpers.isDesktop() ? 34 : 30, lineHeight: helpers.isDesktop() ? 43 : 38, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, color: '#000' }, !helpers.isWeb() && Helpers.textCenter, darkMode && { color: Colors.lightWhite }]}>Other benefits of Corporate plan</Text>
                        <PageBreak style={[Margin.mt_3, { width: helpers.isDesktop() ? '12%' : '50%' }]} darkMode={darkMode} />
                    </View>
                    <View style={[Flex.row, Flex.FlexWrap, Padding.pb_5, !helpers.isDesktop() && Padding.pb_3, { paddingHorizontal: helpers.isDesktop() ? '12%' : 20, margin: 'auto' }]}>
                        <View style={[Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, alignItems: 'center' }]}>
                            <View style={{ position: 'relative' }}>
                                <CustomImage
                                    source={admin_dashboard}
                                    require={true}
                                    style={{ width: 95, height: 80 }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 88 : 80 }}
                                    altText={'Admin Dashboard'}
                                />
                                <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>
                                </View>
                            </View>
                            <Text style={[Padding.pt_2, Padding.pb_2, { fontSize: 24, lineHeight: 33, letterSpacing: 0.3, fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Admin dashboard</Text>
                            <Text style={[{ fontSize: 18, lineHeight: 25, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }, !helpers.isWeb() && Helpers.textCenter, darkMode && { color: Colors.lightWhite }]}>Manage all your members in a single place! Invite members from your organization or remove them.</Text>
                        </View>
                        <View style={[Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, alignItems: 'center' }]}>
                            <View style={{ position: 'relative' }}>
                                <CustomImage
                                    source={unrestricted_access}
                                    require={true}
                                    style={{ width: 95, height: 80 }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 88 : 80 }}
                                    altText={'Full access'}
                                />
                                <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}></View>
                            </View>
                            <Text style={[Padding.pt_2, Padding.pb_2, { fontSize: 24, lineHeight: 33, letterSpacing: 0.3, fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Unrestricted access</Text>
                            <Text style={[{ fontSize: 18, lineHeight: 25, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }, !helpers.isWeb() && Helpers.textCenter, darkMode && { color: Colors.lightWhite }]}>Get full access to our stories and newsletters. Your team will never miss out on important happenings around them.</Text>
                        </View>
                        <View style={[Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, alignItems: 'center' }]}>
                            <View style={{ position: 'relative' }}>
                                <CustomImage
                                    source={support_line}
                                    require={true}
                                    style={{ width: 95, height: 80 }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 88 : 80 }}
                                    altText={'Support'}
                                />
                                <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}></View>
                            </View>
                            <Text style={[Padding.pt_2, Padding.pb_2, { fontSize: 24, lineHeight: 33, letterSpacing: 0.3, fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Dedicated support line</Text>
                            <Text style={[{ fontSize: 18, lineHeight: 25, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }, !helpers.isWeb() && Helpers.textCenter, darkMode && { color: Colors.lightWhite }]}>Providing you with as much support as you need, to help make the most out of your subscription.</Text>
                        </View>
                        <View style={[Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, alignItems: 'center' }]}>
                            <View style={{ position: 'relative' }}>
                                <CustomImage
                                    source={Benefits}
                                    require={true}
                                    style={{ width: 95, height: 80 }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 88 : 80 }}
                                    altText={'Benefits'}
                                />
                                <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}></View>
                            </View>
                            <Text style={[Padding.pt_2, Padding.pb_2, { fontSize: 24, lineHeight: 33, letterSpacing: 0.3, fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, !helpers.isWeb() && Helpers.textCenter, darkMode && { color: Colors.lightWhite }]}>Exclusive events and benefits </Text>
                            <Text style={[{ fontSize: 18, lineHeight: 25, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }, !helpers.isWeb() && Helpers.textCenter, darkMode && { color: Colors.lightWhite }]}>Access to TMC dialogues and more exclusive events made available for you!</Text>
                        </View>
                    </View>
                </View>
            </View>
        );
    }

    render() {
        let webView = helpers.isWeb();
        return (
            <>
                {this.benefitsPlan()}
            </>
        )
    }
}

export default BenefitsPlan;