import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
} from "../../../styles/appStyles";
import { helpers } from "../../../utils/helpers";
import ReactPlayer from "react-player";

export class YoutubeVideo extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => { };

  render() {
    let { faq, videoId, home } = this.props;
    return (
      <View
        style={[
          !helpers.isDesktop() && { width: "100%", height: "100%" },
          faq && { marginBottom: 0 },
        ]}
      >
        <ReactPlayer
          url={videoId}
          controls
          // playbackRate = {2}
          width={home ? (helpers.isDesktop() ? 580 : "100%") : "auto"}
          height={helpers.isDesktop() ? 302 : 210}
        />
      </View>
    );
  }
}

export default YoutubeVideo;
