import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, button, orderData, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import Cookies from "universal-cookie";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import apiHelpers from "../../utils/api-helpers";
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';
import OrderView from '../order-view';
import ContentLoader from '../content-loader';
import AuthenticationWrapper from '../authentication-wrapper';
import moment from 'moment';

const cookies = new Cookies();

const sCreditsIcon = getImagePath('icons/sCredits.png');
const sCreditsIconDark = getImagePath('icons/sCreditsDark.png');
const silverCoin = getImagePath('icons/silver-coin.png');
const giftImage = getImagePath('img/gift-monthly.svg');

export class GiftSubscriptions extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            enableBtnLoader: false,
            credits: this.props.params ? this.props.params.credits : false,
            ordersData: false,// []
            pageLoader: this.props.params ? false : true,
            creditsLoader: false,
            giftTransactions: []
        }
    }

    componentDidMount = () => {
        this.getSubTransactions()
    }

    getSubTransactions = () => {
        const { token } = this.state;
        let endpoint = '/get-sub-transactions';
        apiHelpers.apiRequest(endpoint, {}, "POST", true, token).then((res)=>{
            console.log(res)
            if(res.success){
                console.log("Transaction data --->", res.data)
                this.setState({giftTransactions: res.data})
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    renderPageContent = () => {
        let { ordersData, enableBtnLoader, credits, token, creditsLoader } = this.state;
        const { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={{ marginBottom: helpers.isDesktop() ? 150 : 50 }}>
                <View style={{ marginBottom: helpers.isDesktop() ? 40 : 30, position: 'relative', overflow: 'hidden' }}>
                    <View style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
                    <CustomImage
                        source={giftImage}
                        style={{ width: 111, height: 111 }}
                        webStyle={{ width: 111, height: 111, position: 'relative', marginBottom: -60, zIndex: 1 }}
                        className={'gift-sub-img'}
                        altText={name}
                    /> 
                    </View>
                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, borderWidth: 1, borderColor: '#6C7AF6', marginHorizontal: 20, zIndex: 0}}>
                        <View style={{backgroundColor: '#EEE5FC', height: 100, width: '100%'}} />
                        <View style={{display: 'flex', marginVertical: 20}}>
                            <Text style={{fontSize: 26, fontWeight: 700, fontFamily: FontFamily.abrilFatface}}>Gift Monthly Subscription</Text>
                        </View>
                        <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%'}}>
                            <View style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                <Text style={{fontSize: 24, color: '#55C79A', marginRight: 10}}>✓</Text>
                                <Text style={{textAlign: 'left', fontSize: 20, fontFamily: 'Matteo'}}>You can gift two 30-day free subscriptions</Text>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                                <Text style={{fontSize: 24, color: '#55C79A', marginRight: 10}}>✓</Text>
                                <Text style={{textAlign: 'left', fontSize: 20, fontFamily: 'Matteo'}}>One month gets added to your account once the two giftees claim their gift.</Text>
                            </View>
                        </View>
                        <View style={{backgroundColor: '#EEE5FC', height: 150, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <View style={{marginVertical: 10}}>
                                <Text style={{fontSize: 14}}>Gift subscriptions available</Text>
                            </View>
                            <View style={{marginBottom: 10}}>
                                <Text style={{fontSize: 24, fontWeight: 700}}>{2 - this.state.giftTransactions.length}/2</Text>
                            </View>
                            <View style={{width: 200}}>
                            <ButtonGradient
                                title={"GIFT NOW"}
                                rootStyle={{
                                    btnWrapper: button.primaryGradient,
                                    btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '400' }]
                                }}
                                onClick={() => window.location.href='/?show_gift_modal=true'}
                            />
                            </View>
                        </View>
                    </View>
                    <View style={{marginHorizontal: 20, display: 'flex', marginTop: 20}}>
                        <Text style={{fontFamily: 'Matteo', fontSize: 20}}>RECENT GIFTING HISTORY</Text>
                        {this.state.giftTransactions.map((subGift)=>{
                            return( 
                            <View style={{display: 'flex', width: '100%'}}>
                                <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                    <Text style={{textAlign: 'left', width: '100%', marginTop: 20, marginBottom: 10}}>Gift monthly subscription</Text>
                                    <Text style={{textAlign: 'right', width: '100%', marginTop: 20, marginBottom: 10, fontSize: 20, fontWeight: 600}}>GIFTED</Text>
                                </View>
                                <View style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                    <Text style={{textAlign: 'left', width: '100%'}}>{moment(subGift.createAt).format('DD MMM, YYYY')}</Text>
                                    <Text style={{textAlign: 'right', width: '100%'}} numberOfLines={1}>to: {subGift.description}</Text>
                                </View>
                        </View>);
                        })}
                    </View>
                </View>
            </View>
        )
    }


    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ContentLoader darkMode={darkMode} />
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {
                    false ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }

}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(GiftSubscriptions));
