import React, { Component } from 'react';

export class TouchableOpacityLinkExternal extends Component {
    render() {
    	const { newTab } = this.props;
        return (
        	<>
        		{newTab ?
        			<a target="_blank" {...this.props} onClick={this.props.handleClick}></a>
        			:
        			<a {...this.props} onClick={this.props.handleClick}></a>
        		}
    		</>
        );
    }
}

export default TouchableOpacityLinkExternal;