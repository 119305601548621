import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  TouchableOpacity,
  TouchableHighlight,
  ActivityIndicator,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  button,
  Typo,
  subscribePlans,
  appStyles,
  Colors,
  FontFamily,
} from "../../styles/appStyles";
import ButtonGradient from "../button-gradient";
import Button from "../button";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";
import moment from "moment";
import { ChargebeeController } from "../../utils/chargebee";

const checkIcon = getImagePath("icons/check.png");
const crossIcon = getImagePath("icons/cross.png");
const windowWidth = Dimensions.get("window").width;
const btnBG = getImagePath("icons/buttongradient.png");

export class SubscriptionPlanBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonClicked: false,
      cb_handle: null,
      showButtonLoader: false,
    };
  }

  componentDidMount = () => {
    ChargebeeController.activatedController().then((cb_handle) => {
      this.setState({ cb_handle: cb_handle });
    });
  };

  isSubscriptionUnderDunning() {
    let { userSubscription } = this.props;
    if (userSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  pushSignupEventToDataLayer = (subscription) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up now",
      header_title: "choose your subscription",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "NA",
    });
  };

  subscribeToPlans = (isUserLoggedIn) => {
    if (isUserLoggedIn) {
      this.props.subscribeToPlan();
    } else {
      this.pushSignupEventToDataLayer();
      this.props.navigateScreen("/sign-up?redirect=&/");
    }
  };

  cancelPlan = () => {
    this.props.navigateScreen("#accounts/cancel-subscription", {
      plan:
        this.props.userSubscription &&
        this.props.userSubscription.is_cancelled == false
          ? true
          : false,
    });
  };

  render() {
    const {
      item,
      index,
      subscribeToPlan,
      btnText,
      fullWidth,
      sidePanel,
      subscriptionPage = false,
      multiplePlans,
      navigateToTeamSubscription,
      darkMode,
      userSubscription,
      numberOfDatas,
      auth,
    } = this.props;

    let isUserLoggedIn = auth.isAuthenticated;
    let checkDesktop = sidePanel ? false : helpers.isDesktop() ? true : false;

    let webView = helpers.isWeb();
    return item.is_corporate ? (
      <View
        key={`sub_${index}`}
        style={[
          subscribePlans.planItemWrap,
          helpers.isDesktop() && subscribePlans.planItemWrapDesktop,
          Margin.mb_2,
          { maxWidth: checkDesktop ? "33.33%" : "100%", width: "100%" },
          subscriptionPage && { maxWidth: "100%" },
          multiplePlans && { flex: 1 },
        ]}
      >
        <View
          style={[
            subscribePlans.planItem,
            appStyles.appBg,
            darkMode && {
              backgroundColor: Colors.darkBlackColor,
              borderColor: Colors.purpleBorder,
            },
            !webView && { paddingTop: 20 },
          ]}
        >
          <View style={[Flex.alignCenter]}>
            <Text
              style={[
                subscribePlans.mostViwed,
                !helpers.isDesktop() && { top: -70 },
                { backgroundColor: "#000000" },
                darkMode && {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  color: "#121212",
                },
                !webView && { top: -40 },
              ]}
            >
              Corporate Plan
            </Text>
          </View>
          <Text
            style={[
              subscribePlans.planType,
              helpers.isDesktop() && subscribePlans.planTypeDesktop,
              sidePanel && subscribePlans.planTypePanel,
              {
                fontSize: helpers.isDesktop() ? 28 : 20,
                color:
                  !isUserLoggedIn && item.tmc_group == "free" ? "#FFF" : "#000", // need to add free plan id
              },
              darkMode && { color: "rgba(255, 255, 255, 0.89)" },
            ]}
          >
            {item.invoice_name}
          </Text>
          <View style={{ flex: 1 }}>
            {item.description.access_desc &&
              item.description.access_desc.map((itemElement, index) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                      </View>
                      <Text
                        style={[
                          subscribePlans.planDesc,
                          helpers.isDesktop() && subscribePlans.planDescDesktop,
                          sidePanel && subscribePlans.planDescPanel,
                          helpers.isDesktop() &&
                            subscribePlans.planDescPanelDesktop,
                          Margin.ml_1,
                          {
                            textAlign: "left",
                            marginBottom: 0,
                            color:
                              !isUserLoggedIn && item.tmc_group == "free"
                                ? "#FFF"
                                : "#000", //
                            letterSpacing: 0.3,
                          },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                          !webView && Margin.mr_2,
                        ]}
                      >
                        {itemElement}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
          {btnText && Platform.OS != "ios" && (
            <View style={{ marginTop: helpers.isDesktop() ? 50 : 30 }}>
              <ButtonGradient
                title={"View Plans"}
                onClick={() => navigateToTeamSubscription()}
                rootStyle={{
                  btnWrapper: button.primaryGradient,
                  btnText: [
                    button.primaryGradientText,
                    { fontWeight: "400", fontSize: 16 },
                  ],
                }}
                loader={false}
                disable={false}
                darkMode={darkMode}
              />
            </View>
          )}
        </View>
      </View>
    ) : (
      <View
        key={`sub_${index}`}
        style={[
          subscribePlans.planItemWrap,
          helpers.isDesktop() && subscribePlans.planItemWrapDesktop,
          Margin.mb_2,
          {
            width:
              item.tmc_group == "student"
                ? helpers.isDesktop()
                  ? "35%"
                  : "100%"
                : "100%",
            alignItems: "center",
            maxWidth: checkDesktop ? `${90 / numberOfDatas}%` : "90%",
          },
          subscriptionPage && { maxWidth: "100%" },
          multiplePlans && { flex: 1 },
          helpers.isDesktop() &&
            !sidePanel && {
              transform: [
                {
                  scale: item.is_popular ? 1.1 : 0.92,
                },
              ],
            },
        ]}
      >
        <View
          style={[
            subscribePlans.planItem,
            appStyles.appBg,
            ((userSubscription === undefined && item.tmc_group == "free") ||
              userSubscription?.plan?.id === item.id) &&
              !darkMode && {
                backgroundImage: `url(${btnBG})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: checkDesktop
                  ? numberOfDatas == 1 && !item.is_popular
                    ? "25%"
                    : "100%"
                  : !item.is_popular
                  ? "90%"
                  : 320,
              },

            darkMode && {
              backgroundColor: Colors.darkBlackColor,
              borderColor: Colors.purpleBorder,
            },
            { paddingTop: 60 },
            !webView && { paddingTop: 20 },
          ]}
        >
          {item.discount.value && (
            <View
              style={[
                Flex.row,
                {
                  position: "absolute",
                  top: -33,
                  right: -20,
                  backgroundColor: "#FC876D",
                  paddingHorizontal: 13,
                  paddingVertical: 16,
                  borderRadius: 50,
                  alignItems: "center",
                },
              ]}
            >
              <Text style={[subscribePlans.badgeAmount]}>
                {item.discount.value}
              </Text>
              <View style={{ paddingLeft: 5 }}>
                <Text style={[subscribePlans.badgeText]}>
                  {item.discount.type == "percentage" ? "%" : ""}
                </Text>
                <Text style={[subscribePlans.badgeText]}>OFF</Text>
              </View>
            </View>
          )}

          {item.is_popular ? (
            <View style={[Flex.alignCenter]}>
              <Text
                style={[
                  subscribePlans.mostViwed,
                  !helpers.isDesktop() && { top: -70 },
                  !webView && { top: -40 },
                  darkMode && { color: Colors.black },
                ]}
              >
                Most Popular
              </Text>
            </View>
          ) : (
            <View style={[Flex.alignCenter]}>
              <Text
                style={[
                  subscribePlans.mostViwed,
                  { backgroundColor: "transparent" },
                ]}
              >
                &nbsp;
              </Text>
            </View>
          )}

          {item.tmc_group == "credit" && (
            <View
              style={[
                helpers.isWeb()
                  ? { position: "absolute", top: 0, width: "100%", left: 0 }
                  : {
                      position: "absolute",
                      top: 0,
                      width: Dimensions.get("window").width - 44,
                      left: 0,
                    },
                userSubscription && { top: 20 },
              ]}
            >
              {/* {!userSubscription && (
                <View
                  style={{
                    backgroundColor: "#FFCC00",
                    paddingTop: 6,
                    paddingBottom: 6,
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      lineHeight: 17,
                      fontFamily: FontFamily.regular,
                      fontWeight: helpers.isWeb() ? "600" : "700",
                      color: "#000000",
                    }}
                  >
                    NOT READY TO SUBSCRIBE YET?
                  </Text>
                </View>
              )} */}

              {/* <View style={[{ marginTop: 10 }]}>
                                    <Text style={{ textAlign: 'center', fontSize: 13, fontFamily: FontFamily.regular, lineHeight: 16, fontWeight: helpers.isWeb() ? '600' : '700', color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.9)' }}>Get</Text>
                                </View> */}
            </View>
          )}

          <Text
            style={[
              subscribePlans.planType,
              helpers.isDesktop() && subscribePlans.planTypeDesktop,
              sidePanel && subscribePlans.planTypePanel,
              {
                fontSize: helpers.isDesktop() ? 28 : 20,
                color:
                  (userSubscription === undefined &&
                    item.tmc_group == "free") ||
                  userSubscription?.plan?.id === item.id
                    ? "#FFF"
                    : "#000",
              },
              darkMode && { color: "rgba(255, 255, 255, 0.89)" },
            ]}
          >
            {item.invoice_name}
          </Text>
          {item.description.product_desc ? (
            <>
              <View
                style={{
                  borderTopWidth: 1,
                  maxWidth: 75,
                  width: "100%",
                  borderTopColor: "#000000",
                  opacity: 0.85,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 2,
                  marginBottom: 7,
                }}
              />
              <View
                style={{
                  borderTopWidth: 1,
                  maxWidth: 40,
                  width: "100%",
                  borderTopColor: "#000000",
                  opacity: 0.85,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 22,
                }}
              />
              <Text style={[subscribePlans.creditText, Helpers.textCenter]}>
                {item.description.product_desc}
              </Text>
            </>
          ) : (
            <View
              style={[
                subscribePlans.textUnderline,
                {
                  marginTop: 0,
                  marginBottom: 10,
                  borderTopColor:
                    (userSubscription === undefined &&
                      item.tmc_group == "free") ||
                    userSubscription?.plan?.id === item.id
                      ? "#FFF"
                      : "#000",
                },
                darkMode && { borderTopColor: "rgba(255, 255, 255, 0.6)" },
              ]}
            />
          )}
          {Platform.OS != "ios" && (
            <>
              {checkDesktop ? (
                <>
                  {item.tmc_group !== "free" ? (
                    <Text
                      style={
                        !item.priceHelperText
                          ? [
                              subscribePlans.planPrice,
                              {
                                marginTop: 0,
                                color:
                                  userSubscription?.plan?.id === item.id
                                    ? "#FFF"
                                    : "#FC876D",
                              },
                            ]
                          : [
                              subscribePlans.trialPlanPrice,
                              {
                                marginTop: 0,
                                color:
                                  userSubscription?.plan?.id === item.id
                                    ? "#FFF"
                                    : "#FC876D",
                              },
                            ]
                      }
                    >
                      <Text
                        style={[
                          subscribePlans.symbol,
                          {
                            color:
                              userSubscription?.plan?.id === item.id
                                ? "#FFF"
                                : "#FC876D",
                          },
                        ]}
                      >
                        {item.currency_code == "INR" ? "₹" : "$"}
                      </Text>
                      {item.discount.offer_price
                        ? item.discount.offer_price
                        : item.price}
                    </Text>
                    
                  ) : (
                    <Text
                      style={[
                        {
                          marginTop: 0,
                          color:
                            (userSubscription === undefined &&
                              item.tmc_group == "free") ||
                            userSubscription?.plan?.id === item.id
                              ? "#FFF"
                              : "#FC876D",
                          fontFamily: FontFamily.regular,
                          fontSize: 20,
                          lineHeight: 24,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Credit Card {"\n"} Not Required
                    </Text>
                  )}
                </>
              ) : (
                <>
                  {item.tmc_group !== "free" &&
                  (!userSubscription ||
                    userSubscription?.plan?.id !== item.id) ? (
                    <Text
                      style={[
                        subscribePlans.planPrice,
                        {
                          marginTop: 0,
                          color:
                            userSubscription?.plan?.id === item.id
                              ? "#FFF"
                              : "#FC876D",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          subscribePlans.symbol,
                          {
                            color:
                              userSubscription?.plan?.id === item.id
                                ? "#FFF"
                                : "#FC876D",
                          },
                        ]}
                      >
                        {item.currency_code == "INR" ? "₹" : "$"}
                      </Text>
                      {item.discount.offer_price
                        ? item.discount.offer_price
                        : item.price}
                    </Text>
                  ) : item.tmc_group == "free" ? (
                    <Text
                      style={[
                        {
                          marginTop: 0,
                          color:
                            (userSubscription === undefined &&
                              item.tmc_group == "free") ||
                            userSubscription?.plan?.id === item.id
                              ? "#FFF"
                              : "#FC876D",
                          fontFamily: FontFamily.regular,
                          fontSize: 20,
                          lineHeight: 24,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Credit Card {"\n"} Not Required
                    </Text>
                  ) : null}
                </>
              )}

              {item.description.disccount_desc && (
                <View style={[Flex.alignCenter]}>
                  <Text style={postSingle.creditBox}>
                    {item.description.disccount_desc}
                  </Text>
                </View>
              )}
            </>
          )}

          {checkDesktop ? (
            item.description?.monthly_price && item.name != "Monthly Plan" ? (
              <View
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                  marginBottom: 25,
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    backgroundColor:
                      userSubscription?.plan?.id === item.id
                        ? "#FFF"
                        : "rgba(144, 124, 255, 0.1)",
                    borderRadius: 3,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      fontFamily: FontFamily.regular,
                      color: "#907CFF",
                    }}
                  >
                    at {item.currency_code == "INR" ? "₹" : "$"}{" "}
                    {item.description.monthly_price}/month
                  </Text>
                </View>
              </View>
            ) : null
          ) : item.description?.monthly_price &&
            item.name == "Monthly Plan" &&
            (!userSubscription || userSubscription?.plan?.id !== item.id) ? (
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                marginBottom: 25,
                marginTop: 10,
              }}
            >
              <View
                style={{
                  backgroundColor: "rgba(144, 124, 255, 0.1)",
                  borderRadius: 3,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "500",
                    fontFamily: FontFamily.regular,
                    color: "#907CFF",
                  }}
                >
                  at {item.currency_code == "INR" ? "₹" : "$"}{" "}
                  {item.description.monthly_price}/month
                </Text>
              </View>
            </View>
          ) : null}

          {checkDesktop && item.priceHelperText && (
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                marginBottom: 25,
                marginTop: 10,
              }}
            >
              <View
                style={{
                  backgroundColor:
                    userSubscription?.plan?.id === item.id
                      ? "#FFF"
                      : "rgba(144, 124, 255, 0.1)",
                  borderRadius: 3,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "500",
                    fontFamily: FontFamily.regular,
                    color: "#907CFF",
                  }}
                >
                  {item.priceHelperText}
                </Text>
              </View>
            </View>
          )}

          {checkDesktop && item.description.discount_desc && (
            <View
              style={[
                Flex.row,
                Flex.justfiyCenter,
                { alignItems: "baseline", paddingTop: 20, paddingBottom: 20 },
              ]}
            >
              <Text
                style={{
                  fontFamily: FontFamily.medium,
                  // color: "#bbbbbc",
                  textDecorationLine: "line-through",
                }}
              >
                {item.currency_code == "INR" ? "₹" : "$"}{" "}
                <Text style={{ fontSize: 20 }}>
                  {item.description.discount_desc.net_price}
                </Text>
              </Text>
            </View>
          )}

          {item.description.product_validity && (
            <Text
              style={[
                subscribePlans.planDesc,
                helpers.isDesktop() && subscribePlans.planDescDesktop,
                sidePanel && subscribePlans.planDescPanel,
                {
                  fontStyle: "italic",
                  marginBottom: 0,
                  marginTop: 7,
                  color: "rgba(0, 0, 0, 0.7)",
                },
              ]}
            >
              {item.description.product_validity}
            </Text>
          )}

          {/* {item.tmc_group == 'individual' && item.id == 'annual-plan-in' &&
                            <View style={[Margin.mt_1, Margin.mb_0]}>
                                <View style={[Flex.row, { alignItems: 'flex-start' }]}>
                                    <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop, sidePanel && subscribePlans.planDescPanel, helpers.isDesktop() && subscribePlans.planDescPanelDesktop, { textAlign: 'left', marginBottom: 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && Margin.mr_2]}>Everything in <Text style={[{ fontWeight: '600' }]}>Quarterly</Text> plus:</Text>
                                </View>
                            </View>
                        } */}

          <View style={{ flex: 1 }}>
            {item.description.access_desc &&
              item.description.access_desc.map((itemElement, i) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                      </View>
                      <Text
                        style={[
                          subscribePlans.planDesc,
                          helpers.isDesktop() && subscribePlans.planDescDesktop,
                          sidePanel && subscribePlans.planDescPanel,
                          helpers.isDesktop() &&
                            subscribePlans.planDescPanelDesktop,
                          Margin.ml_1,
                          {
                            textAlign: "left",
                            marginBottom: 0,
                            letterSpacing: 0.3,
                            color:
                              (userSubscription === undefined &&
                                item.tmc_group == "free") ||
                              userSubscription?.plan?.id === item.id
                                ? "#FFF"
                                : "#000",
                          },
                          darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                          !webView && Margin.mr_2,
                        ]}
                      >
                        {itemElement}
                      </Text>
                    </View>
                  </View>
                );
              })}

            {item.description?.limitations_desc?.map((itemElement, i) => {
              return (
                <View style={[Margin.mt_1, Margin.mb_0]}>
                  <View style={[Flex.row, { alignItems: "flex-start" }]}>
                    <View style={{ minWidth: 14 }}>
                      <CustomImage
                        source={crossIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 14,
                          resizeMode: "contain",
                          marginTop: 3,
                        }}
                        webStyle={{
                          width: 20,
                          height: 14,
                          objectFit: "contain",
                          marginTop: 3,
                        }}
                      />
                    </View>
                    <Text
                      style={[
                        subscribePlans.planDesc,
                        helpers.isDesktop() && subscribePlans.planDescDesktop,
                        sidePanel && subscribePlans.planDescPanel,
                        helpers.isDesktop() &&
                          subscribePlans.planDescPanelDesktop,
                        Margin.ml_1,
                        {
                          textAlign: "left",
                          marginBottom: 0,
                          letterSpacing: 0.3,
                          color:
                            (userSubscription === undefined &&
                              item.tmc_group == "free") ||
                            userSubscription?.plan?.id === item.id
                              ? "#FFF"
                              : "#000",
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        !webView && Margin.mr_2,
                      ]}
                    >
                      {itemElement}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
          {btnText && Platform.OS != "ios" && (
            <View
              style={{
                marginTop: helpers.isDesktop() ? 50 : 30,
                width: "101%",
              }}
            >
              {/* {checkDesktop ? ( */}
              <>
                {item.is_popular ? (
                  checkDesktop ? (
                    !userSubscription ||
                    (userSubscription?.plan?.id !== item.id &&
                      this.isSubscriptionUnderDunning()) ? (
                      <ButtonGradient
                        title={
                          this.state.isButtonClicked &&
                          this.state.cb_handle?.instance?.siteConfig
                            ?.enableRedirectMode == true ? (
                            <>
                              <ActivityIndicator size={25} color={"#FFFFFF"} />
                            </>
                          ) : item?.tmc_group == "credit" ? (
                            "Buy Now"
                          ) : userSubscription === undefined &&
                            item.tmc_group == "free" ? (
                            !isUserLoggedIn ? (
                              "Sign Up Now"
                            ) : (
                              "Your Current Plan"
                            )
                          ) : (
                            btnText
                          )
                        }
                        onClick={() => {
                          this.setState({ isButtonClicked: true });
                          subscribeToPlan();
                        }}
                        rootStyle={{
                          btnWrapper: button.primaryGradient,
                          btnText: [
                            button.primaryGradientText,
                            { fontWeight: "400", fontSize: 17 },
                          ],
                        }}
                        loader={false}
                        disable={false}
                        darkMode={darkMode}
                      />
                    ) : (
                      <Button
                        title={
                          this.state.isButtonClicked &&
                          this.state.cb_handle?.instance?.siteConfig
                            ?.enableRedirectMode == true ? (
                            <>
                              <ActivityIndicator size={25} color={"#FFFFFF"} />
                            </>
                          ) : item?.tmc_group == "credit" ? (
                            "Buy Now"
                          ) : userSubscription?.plan?.id === item.id ? (
                            "Your Current Plan"
                          ) : (
                            btnText
                          )
                        }
                        onClick={() => {
                          this.setState({ isButtonClicked: true });
                          subscribeToPlan();
                        }}
                        rootStyle={{
                          btnWrapper: [
                            button.primaryOutline,
                            !helpers.isWeb() && {
                              justifyContent: "center",
                              alignItems: "center",
                            },
                            {
                              cursor:
                                userSubscription?.plan?.id === item.id
                                  ? "not-allowed"
                                  : "pointer",
                            },
                            ((userSubscription === undefined &&
                              item.tmc_group == "free") ||
                              userSubscription?.plan?.id === item.id) && {
                              backgroundColor: "#FFF",
                              // color: "#907CFF",
                            },
                          ],
                          btnText: [
                            button.primaryOutlineText,
                            ((userSubscription === undefined &&
                              item.tmc_group == "free") ||
                              userSubscription?.plan?.id === item.id) && {
                              backgroundColor: "#FFF",
                              color: "#907CFF",
                              fontFamily: FontFamily.semiBold,
                            },
                            {
                              fontWeight: "400",
                              fontSize: 17,
                              paddingVertical: 5,
                              cursor:
                                userSubscription?.plan?.id === item.id
                                  ? "not-allowed"
                                  : "pointer",
                            },
                            !helpers.isWeb() && { top: 3 },
                          ],
                        }}
                        loader={false}
                        disable={
                          userSubscription?.plan?.id === item.id ? true : false
                        }
                        darkMode={darkMode}
                      />
                    )
                  ) : userSubscription?.plan?.id === item.id ? (
                    <View
                      style={{
                        lineHeight: 5,
                      }}
                    >
                      <Text
                        style={{
                          color: darkMode ? "#FFF" : "#000",
                          fontWeight: 500,
                          fontFamily: "Matteo-Medium",
                          textAlign: "center",
                        }}
                      >
                        {this.isSubscriptionUnderDunning() &&
                        userSubscription?.due_since !== undefined
                          ? `Your plan expired on ${moment(
                              userSubscription.due_since
                            ).format(
                              "Do MMMM YYYY"
                            )}. Renew to continue subscription.`
                          : `Your current plan will expire on ${moment(
                              userSubscription.end_date
                            ).format("Do MMMM YYYY")}`}
                      </Text>

                      <TouchableOpacity
                        style={{
                          lineHeight: 5,
                          paddingTop: 13,
                          paddingBottom: 13,
                          paddingLeft: 45,
                          paddingRight: 45,
                          textDecorationLine: "underline",
                          color: darkMode ? "#FFF" : "#000",
                          fontWeight: 500,
                          fontFamily: "Matteo-Medium",
                          cursor: "pointer",
                        }}
                        onPress={() => this.cancelPlan()}
                      >
                        <Text
                          style={{
                            color: darkMode ? "#FFF" : "#000",
                            fontWeight: 500,
                            fontFamily: "Matteo-Medium",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          Cancel Subscription
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <ButtonGradient
                      title={
                        this.state.isButtonClicked &&
                        this.state.cb_handle?.instance?.siteConfig
                          ?.enableRedirectMode == true ? (
                          <>
                            <ActivityIndicator size={25} color={"#FFFFFF"} />
                          </>
                        ) : item?.tmc_group == "credit" ? (
                          "Buy Now"
                        ) : userSubscription === undefined &&
                          item.tmc_group == "free" ? (
                          !isUserLoggedIn ? (
                            "Sign Up Now"
                          ) : (
                            "Your Current Plan"
                          )
                        ) : (
                          btnText
                        )
                      }
                      onClick={() => {
                        this.setState({ isButtonClicked: true });
                        subscribeToPlan();
                      }}
                      rootStyle={{
                        btnWrapper: button.primaryGradient,
                        btnText: [
                          button.primaryGradientText,
                          { fontWeight: "400", fontSize: 17 },
                        ],
                      }}
                      loader={false}
                      disable={false}
                      darkMode={darkMode}
                    />
                  )
                ) : (
                  <>
                    {item.tmc_group == "free" ? (
                      userSubscription === undefined ? (
                        <Button
                          title={
                            userSubscription === undefined
                              ? !isUserLoggedIn
                                ? "Sign Up Now"
                                : "Your Current Plan"
                              : null

                            // !isUserLoggedIn && index == 0 ? "Sign Up Now" : btnText
                          }
                          onClick={() => this.subscribeToPlans(isUserLoggedIn)}
                          rootStyle={{
                            btnWrapper: [
                              button.primaryOutline,
                              !helpers.isWeb() && {
                                justifyContent: "center",
                                alignItems: "center",
                              },
                              {
                                cursor:
                                  userSubscription === undefined &&
                                  isUserLoggedIn
                                    ? "not-allowed"
                                    : "pointer",
                              },
                              ((userSubscription === undefined &&
                                item.tmc_group == "free") ||
                                userSubscription?.plan?.id === item.id) && {
                                backgroundColor: "#FFF",
                                // color: "#907CFF",
                              },
                            ],
                            btnText: [
                              button.primaryOutlineText,
                              ((userSubscription === undefined &&
                                item.tmc_group == "free") ||
                                userSubscription?.plan?.id === item.id) && {
                                backgroundColor: "#FFF",
                                color: "#907CFF",
                                fontFamily: FontFamily.semiBold,
                              },
                              {
                                fontWeight: "400",
                                fontSize: 17,
                                paddingVertical: 5,
                                cursor:
                                  userSubscription === undefined &&
                                  isUserLoggedIn
                                    ? "not-allowed"
                                    : "pointer",
                              },
                              !helpers.isWeb() && { top: 3 },
                            ],
                          }}
                          loader={false}
                          disable={
                            userSubscription === undefined && isUserLoggedIn
                              ? true
                              : false
                          }
                          darkMode={darkMode}
                        />
                      ) : null
                    ) : (checkDesktop ||
                        !userSubscription ||
                        userSubscription?.plan?.id !== item.id) &&
                      !(
                        userSubscription?.plan?.id == item.id &&
                        this.isSubscriptionUnderDunning()
                      ) ? (
                      <Button
                        title={
                          this.state.isButtonClicked &&
                          this.state.cb_handle?.instance?.siteConfig
                            ?.enableRedirectMode == true ? (
                            <>
                              <ActivityIndicator size={25} color={"#FFFFFF"} />
                            </>
                          ) : item?.tmc_group == "credit" ? (
                            "Buy Now"
                          ) : userSubscription?.plan?.id === item.id ? (
                            "Your Current Plan"
                          ) : (
                            btnText
                          )
                        }
                        onClick={() => {
                          this.setState({ isButtonClicked: true });
                          subscribeToPlan();
                        }}
                        rootStyle={{
                          btnWrapper: [
                            button.primaryOutline,
                            !helpers.isWeb() && {
                              justifyContent: "center",
                              alignItems: "center",
                            },
                            {
                              cursor:
                                userSubscription?.plan?.id === item.id
                                  ? "not-allowed"
                                  : "pointer",
                            },
                            ((userSubscription === undefined &&
                              item.tmc_group == "free") ||
                              userSubscription?.plan?.id === item.id) && {
                              backgroundColor: "#FFF",
                              // color: "#907CFF",
                            },
                          ],
                          btnText: [
                            button.primaryOutlineText,
                            ((userSubscription === undefined &&
                              item.tmc_group == "free") ||
                              userSubscription?.plan?.id === item.id) && {
                              backgroundColor: "#FFF",
                              color: "#907CFF",
                              fontFamily: FontFamily.semiBold,
                            },
                            {
                              fontWeight: "400",
                              fontSize: 17,
                              paddingVertical: 5,
                              cursor:
                                userSubscription?.plan?.id === item.id
                                  ? "not-allowed"
                                  : "pointer",
                            },
                            !helpers.isWeb() && { top: 3 },
                          ],
                        }}
                        loader={false}
                        disable={
                          userSubscription?.plan?.id === item.id ? true : false
                        }
                        darkMode={darkMode}
                      />
                    ) : null}
                    {userSubscription?.plan?.id === item.id ? (
                      <View
                        style={{
                          lineHeight: 5,
                        }}
                      >
                        {this.isSubscriptionUnderDunning() ? (
                          <Button
                            title={
                              this.state.isButtonClicked &&
                              (this.state.showButtonLoader ||
                                this.state.cb_handle?.instance?.siteConfig
                                  ?.enableRedirectMode) == true ? (
                                <>
                                  <ActivityIndicator
                                    size={25}
                                    color={
                                      this.state.showButtonLoader
                                        ? "#000000"
                                        : "#FFFFFF"
                                    }
                                  />
                                </>
                              ) : item?.tmc_group == "credit" ? (
                                "Buy Now"
                              ) : userSubscription?.plan?.id === item.id ? (
                                "Renew"
                              ) : (
                                btnText
                              )
                            }
                            onClick={() => {
                              this.setState({ isButtonClicked: true });
                              if (this.isSubscriptionUnderDunning()) {
                                ChargebeeController.activatedController().then(
                                  (cb_handle) => {
                                    cb_handle.hosted_page.collect_now();
                                    this.setState({ showButtonLoader: true });
                                  }
                                );
                              } else {
                                subscribeToPlan();
                              }
                            }}
                            rootStyle={{
                              btnWrapper: [
                                button.primaryOutline,
                                !helpers.isWeb() && {
                                  justifyContent: "center",
                                  alignItems: "center",
                                },
                                {
                                  cursor:
                                    userSubscription?.plan?.id === item.id
                                      ? this.isSubscriptionUnderDunning()
                                        ? "pointer"
                                        : "not-allowed"
                                      : "pointer",
                                },
                                ((userSubscription === undefined &&
                                  item.tmc_group == "free") ||
                                  userSubscription?.plan?.id === item.id) && {
                                  backgroundColor: "#FFF",
                                  // color: "#907CFF",
                                },
                              ],
                              btnText: [
                                button.primaryOutlineText,
                                ((userSubscription === undefined &&
                                  item.tmc_group == "free") ||
                                  userSubscription?.plan?.id === item.id) && {
                                  backgroundColor: "#FFF",
                                  color: "#907CFF",
                                  fontFamily: FontFamily.semiBold,
                                },
                                {
                                  fontWeight: "400",
                                  fontSize: 17,
                                  paddingVertical: 5,
                                  cursor:
                                    userSubscription?.plan?.id === item.id
                                      ? this.isSubscriptionUnderDunning()
                                        ? "pointer"
                                        : "not-allowed"
                                      : "pointer",
                                },
                                !helpers.isWeb() && { top: 3 },
                              ],
                            }}
                            loader={false}
                            disable={
                              userSubscription?.plan?.id === item.id
                                ? !this.state.showButtonLoader &&
                                  this.isSubscriptionUnderDunning()
                                  ? false
                                  : true
                                : false
                            }
                            darkMode={darkMode}
                          />
                        ) : null}
                        <Text
                          style={{
                            color: darkMode ? "#FFF" : "#000",
                            fontWeight: 500,
                            fontFamily: "Matteo-Medium",
                            textAlign: "center",
                          }}
                        >
                          {this.isSubscriptionUnderDunning() &&
                          userSubscription?.due_since !== undefined
                            ? `Your plan expired on ${moment(
                                userSubscription.due_since
                              ).format(
                                "Do MMMM YYYY"
                              )}. Renew to continue subscription.`
                            : `Your current plan will expire on ${moment(
                                userSubscription.end_date
                              ).format("Do MMMM YYYY")}`}
                        </Text>

                        {sidePanel === true && (
                          <TouchableOpacity
                            style={{
                              lineHeight: 5,
                              paddingTop: 13,
                              paddingBottom: 13,
                              paddingLeft: 45,
                              paddingRight: 45,
                              textDecorationLine: "underline",
                              color: darkMode ? "#FFF" : "#000",
                              fontWeight: 500,
                              fontFamily: "Matteo-Medium",
                              cursor: "pointer",
                            }}
                            onPress={() => this.cancelPlan()}
                          >
                            <Text
                              style={{
                                color: darkMode ? "#FFF" : "#000",
                                fontWeight: 500,
                                fontFamily: "Matteo-Medium",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                            >
                              Cancel Subscription
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    ) : null}
                  </>
                )}
              </>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default SubscriptionPlanBox;
