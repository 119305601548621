import React, { Component } from 'react';
import Button from '../button';
import getImagePath from '../../utils/image-helper';

const fbIcon = getImagePath('icons/fb.png');

class FacebookButton extends Component {
 
    render() {
        return (
        	<Button
                title={this.props.title}
                icon={fbIcon}
                onClick={this.props.triggerLogin}
                {...this.props}
                loader={this.props.loader}
                disable={this.props.disable}
                indicatorColor={this.props.indicatorColor}
            />
        );
    }
}
 
export default FacebookButton;