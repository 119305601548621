import React, { Component } from 'react';
import { helpers } from '../../native/utils/helpers';
import HomeExclusiveContent from '../../native/components/home-exclusive-content';
import apiHelpers from '../../native/utils/api-helpers';
import HomeChooseSubscription from '../../native/components/home-choose-subscription';
import AppHeader from '../../native/components/app-header';
import AppFooter from '../../native/components/app-footer';
import { connect } from 'react-redux';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../native/utils/mixpanel';
import CustomImage from '../../native/components/custom-image';
import { Text, View, Image, ScrollView, Dimensions, Platform, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, FontFamily, FontWeight, Colors, article, Typo } from '../../native/styles/appStyles';
import getImagePath from '../../native/utils/image-helper';
import TouchableOpacityLinkExternal from '../../native/components/touchable-opacity-link-external';

const cookies = new Cookies();
const tmcAppMobile = getImagePath('img/tmcAppMobile.png');
const iOSlogo = getImagePath('img/BadgeiOS.png')
const androidlogo = getImagePath('img/BadgeAndroid.png')

export class DownloadApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userSubscriptionDetails: false,
            subscriptionRefreshKey: false,
            planCheckoutLoaderState: false,

        }
    }

    navigateScreen = (pushProp) => {
        if (helpers.isWeb()) {
            this.props.history.push(pushProp)
        }
        else {
            if (pushProp == '/') {
                this.getData()
            }
            else {
                this.props.navigation.navigate(pushProp)
            }
        }
    }
    handleUserSubscriptionDetails = (userSubscriptionDetailsValue) => {
        this.setState({ userSubscriptionDetails: userSubscriptionDetailsValue });
    }

    setPlanButtonLoaderState = (state) => {
        this.setState({
            planCheckoutLoaderState: state
        })
      }
    
    getPlanButtonLoaderState = () => {
        return this.state.planCheckoutLoaderState
    }

    render() {
        const { showErrorModal, userSubscriptionDetails } = this.state
        let { exclusiveContent, homeContent } = this.props
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }
        let iOSApp, androidApp;
        let webView = helpers.isWeb();
        if (webView) {
            iOSApp = {
                href: "https://apps.apple.com/in/app/the-morning-context/id1551672701"
            }
        } else {
            iOSApp = {
                onPress: () => navigateApp('/')
            }
        }

        if (webView) {
            androidApp = {
                href: "https://play.google.com/store/apps/details?id=com.app.themorningcontext"
            }
        } else {
            androidApp = {
                onPress: () => navigateApp('/')
            }
        }
        return (
            <View>
                <AppHeader
                    ref="header"
                    showSubscribeApp={true}
                    history={this.props.history}
                    searchQuery={this.props.searchQuery}
                    locationHash={this.props.locationHash}
                    navigateWeb={(slug) => this.props.navigateToScreen(slug)}
                    navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)}
                    redirectSlugs={redirectSlugs}
                    refreshPage={() => this.setState({ subscriptionRefreshKey: !this.state.subscriptionRefreshKey })}
                    setCredits={(credits) => this.setState({ credits: credits })}
                    navigateToSubscriptionScreen
                    darkMode={darkMode}
                    showFullHeader={true}
                    onLoadData={this.handleUserSubscriptionDetails}
                    setCheckoutLoaderState={(loaderState) => this.setPlanButtonLoaderState(loaderState)}
                    getCheckoutLoaderState={this.getPlanButtonLoaderState}
                />
                <View style={[Margin.mb_5, Margin.mb_5, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginTop: helpers.isDesktop() ? 50 : 20, paddingHorizontal: 20, width: helpers.isDesktop() ? '85%' : '100%' }]}>
                    <View style={[helpers.isDesktop() && Margin.mb_5, { display: 'flex', alignItems: 'center' }]}>
                        <Text style={{ fontFamily: FontFamily.abrilFatface, fontWeight: '500', fontSize: helpers.isDesktop() ? 50 : 24, lineHeight: 38, color: darkMode ? Colors.lightWhite : '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3, marginTop: 70 }}>Download the TMC App</Text>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '500', width: helpers.isDesktop() ? '50%' : '100%', textAlign: 'center', fontSize: helpers.isDesktop() ? 20 : 18, lineHeight: 28, color: darkMode ? Colors.lightWhite : '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3, marginTop: 50 }}>Subscribe and get direct access to our subscriber app.</Text>
                        <View style={{ flexDirection: 'row', marginTop: 50 }}>
                            <TouchableOpacityLinkExternal {...iOSApp} newTab={true}>
                                <View>
                                    <CustomImage
                                        source={iOSlogo}
                                        require={true}
                                        style={{ width: 120, height: 40, resizeMode: 'contain' }}
                                        webStyle={{ width: helpers.isDesktop() ? 185 : '100%', height: 50, objectFit: 'contain', marginRight: 30 }}
                                        altText={'iOS'}
                                    />
                                </View>
                            </TouchableOpacityLinkExternal>
                            <TouchableOpacityLinkExternal {...androidApp} newTab={true}>
                                <View>
                                    <CustomImage
                                        source={androidlogo}
                                        require={true}
                                        style={{ width: 120, height: 40, resizeMode: 'contain' }}
                                        webStyle={{ width: helpers.isDesktop() ? 175 : '100%', height: 50, objectFit: 'contain' }}
                                        altText={'Android'}
                                    />
                                </View>
                            </TouchableOpacityLinkExternal>

                        </View>
                    </View>
                    <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, !helpers.isDesktop() && Margin.mt_2, !helpers.isWeb() && { marginTop: 0 }]}>
                        {
                            helpers.isDesktop() ?
                                <CustomImage
                                    source={tmcAppMobile}
                                    require={true}
                                    style={{ width: 'auto', height: 300, resizeMode: 'contain' }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 500, objectFit: 'contain' }}
                                    altText={'TMC dialogues'}
                                />
                                :
                                <CustomImage
                                    source={tmcAppMobile}
                                    require={true}
                                    style={{ width: 'auto', height: 300, resizeMode: 'contain' }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', objectFit: 'contain' }}
                                    altText={'TMC dialogues'}
                                />
                        }
                    </View>
                </View>
                {!userSubscriptionDetails &&
                    exclusiveContent && homeContent ?

                    <View style={{ height: helpers.isWeb() ? 850 : 500 }} >
                        <HomeExclusiveContent
                            data={exclusiveContent ? exclusiveContent : false}
                            newsletterData={homeContent ? homeContent.newsletters : false}
                            viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)}
                            viewAuthor={(slug) => this.viewAuthor(slug)}
                            viewCategory={(slug) => this.viewCategory(slug)}
                            darkMode={darkMode}
                            token={this.props.token} />
                    </View> : ""
                }

                {/* subscription section */}
                {!userSubscriptionDetails &&
                    <HomeChooseSubscription
                        style={{ marginTop: "100px" }}
                        key={!this.state.subscriptionRefreshKey}
                        navigateScreenHistory={(pushProp) => this.navigateScreen(pushProp)}
                        showSubscriptionPlan={(subscribtionId, subscribtion, country) => {
                            this.refs.header.showSubscriptionPlan(subscribtionId, subscribtion, country);
                            // this.trackSubscriptionClick(subscribtion)
                        }}
                        navigation={this.props.navigation}
                        darkMode={darkMode}
                        showExploreMore={false}
                        setCheckoutLoaderState={(loaderState) => this.setPlanButtonLoaderState(loaderState)}
                        getCheckoutLoaderState={this.getPlanButtonLoaderState}
                    />
                }
            </View>
        )
    }
}

export default DownloadApp;