import webengage_init from "./webengage-init";

const appendScript = (licenseCode) => {
  const script = document.createElement("script");
  script.id = "_webengage_script_tag";
  script.type = "text/javascript";
  script.innerHTML = webengage_init(licenseCode);
  script.async = true;
  document.body.appendChild(script);
};

export default appendScript;
