import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const twitterIcon = getImagePath('icons/twitterIcon.png');
const linkedIcon = getImagePath('icons/linkedin_icon.png');
const emailIcon = getImagePath('icons/email.png');

export class AboutAuthor extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    renderAuthorImage = (image, name) => {
        return (
            image != '' ?
                <CustomImage
                    source={image}
                    style={{ width: 111, height: 111 }}
                    webStyle={{ width: 111, height: 111 }}
                    className={'about-author-img'}
                    altText={name}
                />
                :
                <View className={'about-author-img'} style={{ width: 111, height: 111, backgroundColor: '#eeeeee' }} />
        )
    }

    render() {
        const { data, openSocialMedia, getAuthorClick, darkMode } = this.props;
        let webView = helpers.isWeb();
        return (
            data && data.map((item, index) => {
                let btnClickAuthor, btnClickTwitter, btnClickLinkdin;
                if (webView) {
                    btnClickAuthor = {
                        to: "/writers/" + item.slug
                    }
                    btnClickTwitter = {
                        href: item.social_links.twitter
                    }
                    btnClickLinkdin = {
                        href: item.social_links.linkedin
                    }
                } else {
                    btnClickAuthor = {
                        onPress: () => getAuthorClick(item.slug)
                    }
                    btnClickTwitter = {
                        onPress: () => openSocialMedia(item.social_links.twitter)
                    }
                    btnClickLinkdin = {
                        onPress: () => openSocialMedia(item.social_links.linkedin)
                    }
                }
                return (
                    item.primary &&
                    <>
                        <View style={[aboutAuthor.titleWrap]}>
                            <Text style={[aboutAuthor.wrapperTitle, helpers.isDesktop() && aboutAuthor.wrapperTitleDesktop]}>About the author</Text>
                        </View>
                        <View key={`author_${index}`} style={[aboutAuthor.wrapper, { marginTop: 13 }]}>
                            <View style={{ flexDirection: helpers.isDesktop() ? 'row' : 'column' }}>
                                {/* <View style={[ !webView && {flexDirection: 'row'} ]}>
                                {!webView && <View>
                                    {this.renderAuthorImage(item.profile_image, item.name)}
                                </View>
                                }
                                <View style={{ flex: 1 }}>                                   
                                <Text style={[aboutAuthor.desc, !webView && Padding.pl_1]}>
                                        {webView && this.renderAuthorImage(item.profile_image, item.name)}
                                        <View className={'author-title-class'}>
                                            <Text style={[aboutAuthor.title, helpers.isDesktop() && aboutAuthor.titleDesktop]}>{item.name}</Text>
                                        </View>
                                        {item.biography}
                                    </Text>
                                </View>
                            </View> */}

                                <View style={{ flexDirection: 'row', alignItems: helpers.isDesktop() ? 'flex-start' : 'center' }}>
                                    <TouchableOpacityLink {...btnClickAuthor} style={{marginRight: 28, marginBottom: 15}}>
                                        {this.renderAuthorImage(item.profile_image, item.name)}
                                    </TouchableOpacityLink>
                                    {!helpers.isDesktop() &&
                                        <View className={'author-title-class'}>
                                            <Text style={[aboutAuthor.title, helpers.isDesktop() && aboutAuthor.titleDesktop,{color: '#000'},!helpers.isDesktop()&&{fontSize:20}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>{item.name}</Text>
                                        </View>
                                    }
                                </View>

                                <View style={[Flex.fill, Margin.mb_2]}>
                                    <Text style={[aboutAuthor.desc]}>
                                        {helpers.isDesktop() &&
                                            <TouchableOpacityLink {...btnClickAuthor} style={{marginRight: 0, marginBottom: 15}}>
                                                <View className={'author-title-class topAligned'}>
                                                    <Text style={[aboutAuthor.title, helpers.isDesktop() && aboutAuthor.titleDesktop,{color: '#000'}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>{item.name}</Text>
                                                </View>
                                            </TouchableOpacityLink>
                                        }
                                    </Text>
                                    <Text style={[aboutAuthor.desc,{color: '#000'}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>
                                        {item.biography}
                                    </Text>
                                    <View style={[Flex.fill, Flex.alignCenter, Flex.row, Margin.mt_2,{}]}>
                                        {item.social_links.twitter != '' &&
                                            <View style={[Margin.mr_1]}>
                                                <TouchableOpacityLinkExternal {...btnClickTwitter} newTab={true}>
                                                    <View>
                                                        <CustomImage
                                                            require={true}
                                                            source={twitterIcon}
                                                            style={{ width: 24, height: 20 }}
                                                            webStyle={{ width: 'auto', height: 22, marginRight: 10 }}
                                                            altText={'Twitter'}
                                                            resizeMode={'contain'}
                                                        />
                                                    </View>
                                                </TouchableOpacityLinkExternal>
                                            </View>
                                        }
                                        {item.social_links.linkedin != '' &&
                                            <View>
                                                <TouchableOpacityLinkExternal {...btnClickLinkdin} newTab={true}>
                                                    <View>
                                                        <CustomImage
                                                            require={true}
                                                            source={linkedIcon}
                                                            style={{ width: 24, height: 20 }}
                                                            webStyle={{ width: 'auto', height: 22, marginRight: 10 }}
                                                            altText={'Linkedin'}
                                                            resizeMode={'contain'}
                                                        />
                                                    </View>
                                                </TouchableOpacityLinkExternal>
                                            </View>
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>
                    </>
                )
            })


        );
    }
}

export default AboutAuthor;