/**
 * @param {int} monthId The id of the month in range [0, 12)
 * 
 * The function returns a string of given month name:
    example => January
 */
function getMonthName(monthId) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const dayNames = ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur']

  return monthNames[monthId];
}

/**
 * @param {Date} startDate The date object of the starting day
 * @param {Date} endDate The date object of the ending day
 * 
 * The function returns a string of given date range:
    example => 17 Oct - 21 Oct
 */
function getTabName(startDate, endDate) {
  let tabPart1 = "";
  let tabPart2 = "";
  if (startDate) {
    tabPart1 = `${startDate.getDate()} ${getMonthName(
      startDate.getMonth()
    ).substring(0, 3)}`;
  }
  if (endDate) {
    tabPart2 = `${endDate.getDate()} ${getMonthName(
      endDate.getMonth()
    ).substring(0, 3)}`;
  }
  if (tabPart1 == tabPart2) return tabPart2;
  else return `${tabPart1} - ${tabPart2}`;
}

/**
 * @param {Date} currentDate The date object of the referenced day
 * @param {int} tabsCount The number of weeks from the given date
 *
 * The function returns a JSON object of attributes:
 *  {
 *    display_month: month of the given currentDate param
 *    tab_list: a list of "tabsCount" number JSON objects
 *               which are distributed based on weeks
 *  }
 */
export function getWeeks(currentDate, tabsCount) {
  let monthOfYear = currentDate.getMonth();

  let response = {
    display_month: getMonthName(monthOfYear),
    tab_list: [],
  };

  for (let tab_i = 0; tab_i < tabsCount; tab_i++) {
    let endDate = new Date(currentDate);
    let startDate = undefined;

    // if the current date is the beginning of the week then do not consider end_date
    if (endDate.getDay() === 0) {
      startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - endDate.getDay());
      currentDate.setDate(currentDate.getDate() - 1);
    } else {
      // shift the startdate to the beginning of its respective week
      startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - endDate.getDay());
      currentDate = new Date(startDate);

      // offset the currentDate pointer to the end of the next week interval
      currentDate.setDate(startDate.getDate() - 1);
    }
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    let tabObject = {
      id: tab_i,
      name: getTabName(startDate, endDate),
      start_date: startDate,
      end_date: endDate,
    };
    response.tab_list.push(tabObject);
  }

  return response;
}

// usage
// console.log(getWeeks(new Date(), 10));
