import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TextInput, Button } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors, YesterdayModule, articleItemHorizontal, articleItem, article, Typo, button, articleLists } from '../../styles/appStyles';
import apiHelpers from '../../utils/api-helpers';
import { helpers } from '../../utils/helpers';
import ArticleItemHorizontal from '../article-item-horizontal';
import ButtonGradient from '../button-gradient';
import HomeExclusiveContent from '../home-exclusive-content';
import TouchableOpacityLink from '../touchable-opacity-link';
import YesterdayArticleorizontal from '../yesterday-article-horizontal';


export class DontStopReading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exclusiveContent: this.props.homeData && this.props.homeData.exclusiveContent ? this.props.homeData.exclusiveContent : false,
        }
    }

    componentDidMount = () => {
        if (!this.state.exclusiveContent) {
            this.getExclusiveContent()
        }
        
    }
    getExclusiveContent = () => {
        this.setState({ showErrorModal: false, pageLoader: true, });
        let endpoint = `/stories`;
        let body = {
            limit: 1,
            filters: [{ key: "is_featured", value: true }, { key: "single_category", value: true }]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, false)
            .then((res) => {
                if (res.success && res.code == 'story_found') {
                    this.setState({
                        exclusiveContent: res.data.story,
                        pageLoader: false,
                        archive_days: 0,
                    });
                    this.getArchivedStories(res.data.story);
                } else {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }
    viewArticle = (catSlug, slug, postData) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.navigate('post-single', { categorySlug: catSlug, postSlug: slug, postData: postData });
        }
    }
    viewAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    viewCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    handleClick = (title, data, index) => {
        try {
            if (this.props.trackStoryClick) {
                this.props.trackStoryClick(title, data, index);
            }
        }
        catch (error) {
            console.log("check mix panel track error ==>", error);
        }
    }
    
    renderExlusiveContent() {
        let { exclusiveContent } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let btnClickCategory;
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && {marginTop: 90 }, helpers.isDesktop() && {marginBottom: 90}]}>

            
                <View style={[article.headerWrapper,  helpers.isDesktop() &&  Padding.ph_2]}>
                    <View style={[Flex.alignStart, Margin.mb_2, !helpers.isDesktop() && Margin.mb_0]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && {backgroundColor: Colors.darkBlackColorBg }]}>
                            <View >
                                <Text accessibilityRole="header" aria-level="2" style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, {fontSize: 42}, {fontFamily:FontFamily.abrilFatface, color: '#051462'} ,{fontSize: helpers.isDesktop() ? 34 : 20}, darkMode && {color: Colors.lightWhite}]}>Don’t stop reading </Text>
                            </View>
                        </View>
                    
                        <View style={[!helpers.isDesktop() && {marginTop: 20},Padding.ph_2]}>                  
                                <Text style={{fontFamily:FontFamily.Matteo, fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: 36, color: darkMode ? 'rgba(255,255,255,0.87)' : '#051462', textAlign: 'left', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 }}>Visit The Morning Context to get more detailed stories that matter </Text>
                        </View>
                    </View>
                </View>
            <View style={[Margin.mt_9, Margin.mb_3, Padding.ph_2]}>
                    { helpers.isDesktop() ?
                    <View style={[Flex.row, Flex.justifyBetween, Flex.alignStart]}>
                        {exclusiveContent && exclusiveContent.map((item, index) => {
                            if (helpers.isWeb()) {
                                btnClickCategory = {
                                    to: "/category/" + item.category.slug
                                }
                            } else {
                                btnClickCategory={
                                    onPress: () => this.viewCategory(item.category.slug)
                                }
                            }
                            return (
                                <View 
                                    style={[articleItem.col4, helpers.isDesktop() && articleItem.col4Desktop,{marginRight: 25}, 
                                    (index == 1) && {width: helpers.isDesktop() ? '34%' : '100%'}, 
                                    !(index == 1) && {width: helpers.isDesktop() ? '27%' : '100%'} ]}>
                                        <YesterdayArticleorizontal 
                                        viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} 
                                        viewAuthor={(slug) => this.viewAuthor(slug)} 
                                        data={item} 
                                        single={index == 0 ? true : false} 
                                        exclusiveHome={true} handleClick={(title) => {this.handleClick(title, item, index)}} 
                                        hideDate={true} darkMode={darkMode} />
                                </View>
                            )
                        })}
                    </View>
                    :
                    <View style={[Flex.column, Margin.mb_4]}>
                        {exclusiveContent && exclusiveContent.map((item, index) => {
                            if (helpers.isWeb()) {
                                btnClickCategory = {
                                    to: "/category/" + item.category.slug
                                }
                            } else {
                                btnClickCategory={
                                    onPress: () => this.viewCategory(item.category.slug)
                                }
                            }
                            return (
                                <View style={[!helpers.isDesktop() && { marginTop: 30}]}>
                                    <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)}>
                                        <View>
                                            <Text style={[articleLists.badge, { width: 150, marginBottom: 10, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff' }, !helpers.isWeb() && {fontFamily: FontFamily.semiBold}]}>{item.category.name}</Text>
                                        </View>
                                    </TouchableOpacityLink>
                                    <ArticleItemHorizontal viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} data={item} single={index == 0 ? true : false} exclusiveHome={true}  handleClick={(title) => {this.handleClick(title, item, index)}} hideDate={true}  darkMode={darkMode}  />
                                </View>
                            )
                        })}
                    </View>
                }
                </View>
            
                <View style={{ }}>
                            <ButtonGradient
                                title={"Load More"}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradient, { width: "100%" }],
                                    btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '600', }]
                                }}
                                onClick={() => null}
                                darkMode={darkMode}
                            />
                </View>
            </View>

            
        )
    }

    render() {
        let webView = helpers.isWeb();
        let {exclusiveContent}=this.state
        return (
            <View style={[Margin.mt_15, YesterdayModule.box]}>
                    {exclusiveContent && this.renderExlusiveContent()}
            </View >
        );
    }
}

export default DontStopReading;