import { ManagementAPI__I } from "../interface";
import { SubscriptionAPI__I } from "../subscription";
import { SubscriptionAPI__Factory__v1 } from "../subscription/factory";




export type Constructor = new (...args: any[]) => ManagementAPI__I;


export function Subscription<TBase extends Constructor>(Base: TBase) {
    return class PS extends Base{
        subscription: SubscriptionAPI__I
        constructor(...args: any[]){
            super(...args)
            this.subscription = SubscriptionAPI__Factory__v1.createInstance()
        }
        setToken(tmcAuthToken: any) {
            super.setToken(tmcAuthToken)
            this.subscription.tmcAuthToken = tmcAuthToken
        }
    }
}

export const ManagementAPIMixins_v1 = [
    Subscription,
]

