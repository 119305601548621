import React, { Component } from 'react';
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  FontFamily,
  FontWeight,
  pageCover,
  pageContent,
  investorCard,
  Colors,
} from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from '../../utils/api-helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageCover from '../../components/page-cover';
import AuthorItem from '../../components/author-item';
import PageBreak from '../../components/page-break';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import InvestorCard from '../../components/investor-card';
import InstitutionalInvestorCard from '../../components/institutional-investor-card';
import ContentLoader from 'react-native-easy-content-loader';
import Seo from '../../components/seo-meta';
import seoObj from '../../utils/seo-helper';
import AuthenticationWrapper from '../../components/authentication-wrapper';

const upArrowIcon = getImagePath('icons/uparrow.png');

const zigZagIcon = getImagePath('icons/zig-zag.png');
const dualCircleIcon = getImagePath('icons/dual-circle.png');
const googleInitiative = getImagePath('icons/google-news-initiative.jpg');
const investorsleftIcon = getImagePath('icons/investorsleftIcon.png');
const whiteBoardCapitals = getImagePath('icons/whiteBoard-capitals.png');
const rainmatterFoundation = getImagePath('icons/rainmatter-foundation.png');
const mdif = getImagePath("icons/mdif-investor-logo.jpeg");
const cookies = new Cookies();

export class Investors extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;;
    let token = webView
      ? process.browser
        ? (isAuthenticated ? fetchToken : false)
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let investors;
    if (this.props.postData) {
      investors = this.props.postData.investors;
    }
    this.state = {
      pageLoader: investors ? false : true,
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      token: token == undefined || token == '' ? false : token,
      investors: investors ? investors : null,
    };
  }

  componentDidMount = () => {
    if (!this.state.investors) {
      this.getInvestors();
    }
  };

  getInvestors = () => {
    this.setState({ showErrorModal: false, pageLoader: true });
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: ['investors'],
    };
    apiHelpers
      .apiRequest(endpoint, body, 'POST', true)
      .then((res) => {
        console.log(res);
        if (res.success && res.code == 'content_found') {
          this.setState({ investors: res.data.investors, pageLoader: false });
        } else {
          this.setState({ showErrorModal: true, pageLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ showErrorModal: true, pageLoader: false });
        console.log('Error=>> down', JSON.stringify(error));
      });
  };

  renderPageHeader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && { paddingHorizontal: 20 },
        ]}
      >
        <View
          style={[
            helpers.isDesktop() && Margin.mb_7,
            Helpers.textCenter,
            {
              backgroundColor: darkMode ? Colors.darkBlackColor : '#ECFFED',
              marginTop: helpers.isDesktop() ? 130 : 75,
              paddingHorizontal: helpers.isDesktop() ? 20 : 0,
              width: '100%',
            },
          ]}
        >
          <Text
            accessibilityRole="header"
            aria-level="1"
            style={[
              Margin.mt_0,
              Margin.mb_0,
              pageCover.title,
              helpers.isDesktop() && pageCover.titleDesktop,
              Helpers.textCenter,
              {
                position: 'relative',
                top: helpers.isDesktop() ? -42 : -19,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
              !helpers.isWeb() && { top: -15 },
            ]}
          >
            Investors
          </Text>
          <Text
            style={[
              { marginBottom: helpers.isDesktop() ? 90 : 50 },
              pageContent.subTitle,
              helpers.isDesktop() && pageContent.subTitleDesktop,
              {
                maxWidth: helpers.isDesktop() ? 900 : '100%',
                lineHeight: helpers.isDesktop() ? 40 : 30,
              },
              !helpers.isDesktop() && { marginHorizontal: 20 },
              { fontSize: helpers.isDesktop() ? 24 : 16 },
              { textAlign: 'center' },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            Our investors include several noteworthy individuals and
            institutions. They are helping us build a subscription-led digital
            media company which becomes the chronicler of the most important,
            original and timely stories on tech, business and chaos.
          </Text>
          <CustomImage
            source={dualCircleIcon}
            require={true}
            style={{
              width: 40,
              height: helpers.isDesktop() ? 60 : 30,
              position: 'absolute',
              left: helpers.isDesktop() ? -30 : 15,
              top: helpers.isDesktop() ? 30 : -17,
            }}
            webStyle={{
              width: helpers.isDesktop() ? 90 : 50,
              height: helpers.isDesktop() ? 85 : 46,
              position: 'absolute',
              left: helpers.isDesktop() ? -45 : 0,
              top: helpers.isDesktop() ? 30 : -17,
            }}
          />
          <CustomImage
            source={zigZagIcon}
            require={true}
            style={{
              width: 60,
              height: helpers.isDesktop() ? 50 : 30,
              position: 'absolute',
              right: 0,
              bottom: helpers.isDesktop() ? 70 : 20,
            }}
            webStyle={{
              width: helpers.isDesktop() ? 100 : 60,
              height: helpers.isDesktop() ? 50 : 30,
              position: 'absolute',
              right: helpers.isDesktop() ? -33 : 0,
              bottom: helpers.isDesktop() ? 70 : 20,
            }}
          />
        </View>
      </View>
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={['100%']}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
          }
          secondaryColor={
            darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={['100%']}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
          }
          secondaryColor={
            darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={['100%']}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
          }
          secondaryColor={
            darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={['100%']}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
          }
          secondaryColor={
            darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={['100%']}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
            }
            secondaryColor={
              darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={['100%']}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'
              }
              secondaryColor={
                darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderPageContent = () => {
    let { investors } = this.state;
    let institutionalInvestors = [
      {
        name: 'Media Development Investment Fund',
        image: mdif,
        intro: '',
        details: 'MDIF invests in young media companies with positive social impact, serving audiences in countries where access to free and independent news and information is under threat. From digital startups to national multi-platform broadcasters, MDIF clients are vital institutions that underpin open, vibrant societies. MDIF invests in companies who continue to hold the powerful to account, protect the rights of the individual and provide a platform for debate. \nRead more here: <a href="https://www.mdif.org" target="_blank">https://www.mdif.org</a>',
      },
      {
        name: 'WhiteBoard Capitals',
        image: whiteBoardCapitals,
        intro: '',
        details:
          'Whiteboard Capital is an early-stage investment fund established by the founder of Freecharge, Sandeep Tandon. Some of the companies Whiteboard Capital has invested in are Dealshare, Pocket Aces, Wysh, Leap Club, Damensch and Generico.',
      },
      {
        name: 'Google news initiative',
        image: googleInitiative,
        intro:
          'The Morning Context was one of the recipients of the 2020 Google News Initiative Innovation Challenge for the Asia-Pacific region.',
        details:
          'The GNI Innovation Challenge funds projects that bring new ideas to help journalism thrive in the digital age. For the 2020 challenge, participants were asked for ideas to increase reader engagement, leading to greater loyalty and willingness to pay for content. Out of the 255 proposals received from the Asia-Pacific region, 18 were chosen for funding, including four from India.',
      },
      {
        name: 'Rainwater Foundation',
        image: rainmatterFoundation,
        intro:
          'The Morning Context received a grant from the Rainmatter Foundation to bolster it’s environment and climate change coverage.',
        details:
          'The Rainmatter Foundation’s mission is to fund climate entrepreneurs and support individuals and organizations working on climate change solutions through direct action, research, climate advocacy, policy, including journalism and storytelling so that the problem of messaging can be addressed for various stakeholders in the mainstream.',
      },
    ];
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View style={[!helpers.isDesktop() && { marginBottom: 40 }]}>
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              helpers.isDesktop() ? Padding.ph_2 : Padding.ph_0,
            ]}
          >
            <View
              style={[
                Flex.alignCenter,
                Margin.mb_4,
                { marginTop: helpers.isDesktop() ? 70 : 40 },
              ]}
            >
              <View
                style={[
                  Padding.ph_2,
                  Helpers.titleBgWrap,
                  darkMode && { backgroundColor: Colors.darkBlackColorBg },
                ]}
              >
                <View
                  style={[
                    Helpers.borderBottom,
                    darkMode && { borderBottomColor: Colors.lineColor },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      {
                        fontSize: helpers.isDesktop() ? 34 : 20,
                        color: '#000',
                      },
                      { fontFamily: FontFamily.abrilFatface },
                      !helpers.isWeb() && { marginBottom: 1 },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Individuals
                  </Text>
                </View>
              </View>
              <View
                style={[
                  Helpers.titleLine,
                  {
                    backgroundColor: darkMode
                      ? Colors.lineColor
                      : 'rgba(0, 0, 0, 0.6)',
                  },
                ]}
              ></View>
            </View>
          </View>
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              Padding.ph_2,
            ]}
          >
            <Text
              style={[
                pageContent.text,
                Margin.mb_4,
                helpers.isDesktop() && pageContent.textDesktop,
                { textAlign: 'center' },
                !helpers.isDesktop() && {
                  fontSize: helpers.isDesktop() ? 24 : 16,
                },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              We’re lucky to have the backing of a set of angel investors, all
              of whom have invested in their personal capacity.
            </Text>

            <View
              style={[
                investorCard.row,
                {
                  marginLeft: helpers.isDesktop() ? -20 : 0,
                  marginRight: helpers.isDesktop() ? -20 : 0,
                  paddingTop: helpers.isDesktop() ? 40 : 0,
                },
                { marginBottom: helpers.isDesktop() ? 150 : 25 },
              ]}
            >
              {investors.map((investor) => {
                return <InvestorCard investor={investor} darkMode={darkMode} />;
              })}
            </View>
          </View>
        </View>
        <View style={{ position: 'relative' }}>
          {helpers.isDesktop() && (
            <CustomImage
              source={investorsleftIcon}
              require={true}
              style={{
                width: helpers.isDesktop() ? 180 : 80,
                height: helpers.isDesktop() ? 247 : 110,
                position: 'absolute',
                top: helpers.isDesktop() ? -90 : -40,
              }}
              webStyle={{
                width: helpers.isDesktop() ? 180 : 80,
                height: helpers.isDesktop() ? 247 : 110,
                position: 'absolute',
                top: helpers.isDesktop() ? -90 : -40,
              }}
            />
          )}
          <View style={[!helpers.isDesktop() && { marginBottom: 40 }]}>
            <View
              style={[
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWeb1200,
                helpers.isDesktop() ? Padding.ph_2 : Padding.ph_0,
              ]}
            >
              <View
                style={[
                  Flex.alignCenter,
                  { marginBottom: helpers.isDesktop() ? 90 : 35 },
                ]}
              >
                <View
                  style={[
                    Padding.ph_2,
                    Helpers.titleBgWrap,
                    darkMode && { backgroundColor: Colors.darkBlackColorBg },
                  ]}
                >
                  <View
                    style={[
                      Helpers.borderBottom,
                      darkMode && { borderBottomColor: Colors.lineColor },
                    ]}
                  >
                    <Text
                      style={[
                        Helpers.textCenter,
                        Typo.h3_R,
                        helpers.isDesktop() && Typo.h3_RDesktop,
                        Margin.mb_0,
                        { fontSize: helpers.isDesktop() ? 34 : 20 },
                        { fontFamily: FontFamily.abrilFatface, color: '#000' },
                        !helpers.isWeb() && { marginBottom: 2 },
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      Institutional investors
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    Helpers.titleLine,
                    {
                      backgroundColor: darkMode
                        ? Colors.lineColor
                        : 'rgba(0, 0, 0, 0.6)',
                    },
                  ]}
                ></View>
              </View>
            </View>
            <View
              style={[
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWeb1200,
                Padding.ph_2,
                { marginBottom: helpers.isDesktop() ? 120 : 75 },
              ]}
            >
              {institutionalInvestors.map((investor) => {
                return (
                  <InstitutionalInvestorCard
                    investor={investor}
                    darkMode={darkMode}
                  />
                );
              })}
            </View>
          </View>
        </View>
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: '/investors',
      native: { redirect: '/investors', verifyRedirect: '/investors' },
    };
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: '/investors',
      native: { redirect: '/investors', verifyRedirect: '/investors' },
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj['investors']}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ''
          }
          page="/investors"
        />
        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
                ? this.renderWebPage()
                : this.renderAppPage()}
            {showUparrow && (
              <View
                style={{
                  position: webView ? 'fixed' : 'absolute',
                  bottom: 20,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.scrollToTop()}
                  activeOpacity={0.8}
                  style={{ paddingHorizontal: 10, paddingVertical: 10 }}
                >
                  <CustomImage
                    source={upArrowIcon}
                    require={true}
                    style={{ width: 60, height: 60 }}
                    webStyle={{ width: 60, height: 60 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </>
        )}

        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate('/')}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(Investors));

let Breadcrumbs = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Investors',
  },
];
