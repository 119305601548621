import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  Button,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from "react-native";
import {
  PostList,
  outlineButton,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  FontFamily,
  FontWeight,
  pageCover,
  pageContent,
  articleItem,
  articleLists,
  Colors,
  button,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from "../../components/button-gradient";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import PageBreak from "../../components/page-break";
import ButtonGradientView from "../../components/button-gradient/index-view.js";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import FeaturedArticle from "../../components/featured-article";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import ArticleItemHorizontal from "../../components/article-item-horizontal";
import InfiniteScrollComponent from "../../components/infinite-scroll";
import NewsletterSlider from "../../components/newsletter-slider";
import ContentLoader from "react-native-easy-content-loader";
import { Mixpanel } from "../../utils/mixpanel";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const cookies = new Cookies();
const newsletterImg = getImagePath("img/things-change.png");
const authorImg = getImagePath("img/userpic.png");
const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconLight = getImagePath("icons/bookmark-light.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");
import PremiumSlidernews from "../../components/home-news-letters/PremiumSlidernews";
const homeSeoImage = getImagePath("img/extras/app-seo.png");

export class SingleNewsletter extends Component {
  controller;
  signal;
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let newsletterSlug = "",
      redirectParams;
    if (webView && this.props.newsletterSlug) {
      newsletterSlug = this.props.newsletterSlug;
    } else if (!webView) {
      newsletterSlug = this.props.route.params.newsletterSlug;
    }
    if (webView && process.browser && window.location.search) {
      let searchArr = window.location.search.replace("?", "").split("&");
      redirectParams = searchArr.find((searchParam) => {
        return searchParam.includes("redirect-params=");
      });
      if (redirectParams) {
        redirectParams = redirectParams.replace("redirect-params=", "");
      }
    }

    let newsLetterDetails,
      storiesData,
      storyList = [];
    if (this.props.postData) {
      newsLetterDetails = this.props.postData.newsletter_details;
      storiesData = this.props.postData.stories;
      storyList = this.props.postData.stories.story;
    }
    this.state = {
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      token: token == undefined || token == "" ? false : token,
      newsLetterDetails: newsLetterDetails,
      storiesData: storiesData,
      storyList: storyList,
      newsletters: [],
      Loading: "SUBSCRIBE",
      newsLetters: [],
      newsletterSlug: newsletterSlug,
      newsletterSubscriptionStatus: false,
      pageLoader: newsLetterDetails ? false : true,
      redirectSlugs: {
        web: "/newsletters/" + newsletterSlug,
        native: {
          redirect: "single-newsletter",
          verifyRedirect: "/newsletters/" + newsletterSlug,
          newsletterSlug: newsletterSlug,
        },
      },
      redirectParams: redirectParams,
      readingList: [],
      userPlanDetails: false,
      newsletterList: [
        { name: "Startups", id: 1 },
        { name: "Public Markets", id: 2 },
        { name: "World Affairs", id: 3 },
        { name: "Love & Family", id: 4 },
        { name: "Technology", id: 5 },
        { name: "Agriculture", id: 6 },
        { name: "Economy", id: 7 },
        { name: "Science", id: 8 },
        { name: "Public Policy", id: 9 },
        { name: "Environment", id: 10 },
        { name: "Sports", id: 11 },
        { name: "Personal Finance", id: 12 },
        { name: "Conflict", id: 13 },
      ],
    };
    if (redirectParams) {
      let reaplaceProp = JSON.parse(JSON.stringify(window.location));
      reaplaceProp.search = "";
      this.props.history.replace(reaplaceProp);
    }
  }

  methodProps = (i) => {
    let agricultureProp,
      economyProp,
      sportsProp,
      scienceProp,
      startupprops,
      publicMarketProps,
      worldProps,
      loveProps,
      technologyProps,
      policyProps,
      environmentProp,
      financeProps,
      conflictProps;

    if (helpers.isWeb()) {
      agricultureProp = {
        to: "/newsletters/from-fork-to-farm",
        // to: "/tag/agriculture?newsletter=true&newsletter_slug=from-fork-to-farm",
      };
      sportsProp = {
        to: "/newsletters/game-point",
      };
      economyProp = {
        to: "/newsletters/peoples-economics",
      };
      environmentProp = {
        to: "/newsletters/the-crucial-years",
      };
      scienceProp = {
        to: "/newsletters/the-world-revealed",
      };
      startupprops = {
        to: "/newsletters/things-change",
      };
      publicMarketProps = {
        to: "/newsletters/street-smart",
      };
      worldProps = {
        to: "/newsletters/antiloquy",
      };
      loveProps = {
        to: "/newsletters/notes-from-natasha",
      };
      technologyProps = {
        to: "/newsletters/oversize",
      };
      policyProps = {
        to: "/newsletters/creative-destruction",
      };
      financeProps = {
        to: "/newsletters/bookkeeping",
      };
      conflictProps = {
        to: "/newsletters/thirty-six",
      };
    } else {
      agricultureProp = {
        onPress: () => getTagClick("agriculture"),
      };
      sportsProp = {
        onPress: () => getTagClick("sports"),
      };
      economyProp = {
        onPress: () => getTagClick("economy"),
      };
      environmentProp = {
        onPress: () => getTagClick("environment"),
      };
      scienceProp = {
        onPress: () => getTagClick("science"),
      };
      startupprops = {
        onPress: () => getTagClick("startups"),
      };
      publicMarketProps = {
        onPress: () => getTagClick("public-markets"),
      };
      worldProps = {
        onPress: () => getTagClick("world-affairs"),
      };
      loveProps = {
        onPress: () => getTagClick("love-family"),
      };
      technologyProps = {
        onPress: () => getTagClick("technology"),
      };
      policyProps = {
        onPress: () => getTagClick("public-policy"),
      };
      financeProps = {
        onPress: () => getTagClick("bookkeeping"),
      };
      conflictProps = {
        onPress: () => getTagClick("thirty-six"),
      };
    }

    if (i == 1) {
      return startupprops;
    } else if (i == 2) {
      return publicMarketProps;
    } else if (i == 3) {
      return worldProps;
    } else if (i == 4) {
      return loveProps;
    } else if (i == 5) {
      return technologyProps;
    } else if (i == 6) {
      return agricultureProp;
    } else if (i == 7) {
      return economyProp;
    } else if (i == 8) {
      return scienceProp;
    } else if (i == 9) {
      return policyProps;
    } else if (i == 10) {
      return environmentProp;
    } else if (i == 11) {
      return sportsProp;
    } else if (i == 12) {
      return financeProps;
    } else if (i == 13) {
      return conflictProps;
    }
  };

  componentDidMount = async () => {
    let { newsLetterDetails, storiesData, redirectParams, token } = this.state;
    this.getStaticContent();
    this.getUserReadingList();
    await this.getUserInfo(token);
    if (!newsLetterDetails) {
      this.getNewsletterDetails();
    } else if (!storiesData) {
      this.getNewsletterStories(1);
    }
    if (redirectParams && token) {
      this.splitParamsAndAppyAction(redirectParams);
    }

    if (newsLetterDetails) {
      this.setBreadcrumbs(newsLetterDetails);
    }
  };

  componentDidUpdate = () => {
    let webView = helpers.isWeb();
    let newsletterSlug = webView
      ? this.props.newsletterSlug
      : this.props.route.params.newsletterSlug;
    console.log(
      "componentDidUpdate ==>",
      newsletterSlug,
      this.state.newsletterSlug
    );
    if (newsletterSlug && newsletterSlug != this.state.newsletterSlug) {
      this.setState(
        {
          newsletterSlug: newsletterSlug,
        },
        () => {
          this.getNewsletterDetails(1);
          this.getUserInfo(this.state.token);
        }
      );
    }
  };
  getUserReadingList = () => {
    this.setState({
      showErrorModal: false,
    });
    const { token } = this.state;
    let endpoint = `/user-action-story-list`;
    let body = {
      type: "bookmark",
      limit: 10,
      page: 1,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let readingList = res.data.story;
          console.log("ugyfh");
          var readingListId = [];
          for (let index = 0; index < readingList.length; index++) {
            const element = readingList[index];
            readingListId.push(element.id);
            console.log(readingListId, "readingListid");
          }
          this.setState(
            {
              readingList: readingListId,
            },
            () => {
              console.log(this.state.readingList, "readinglist");
            }
          );
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  renderCarousal = () => {
    let { newsLetters } = this.state;

    let data = newsLetters;
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [];
    let data5 = [];
    Array.from(data)
      .slice(0, 2)
      .map((item, index) => {
        data1.push(item);
      });
    Array.from(data)
      .slice(2, 4)
      .map((item, index) => {
        data2.push(item);
      });
    Array.from(data)
      .slice(4, 6)
      .map((item, index) => {
        data3.push(item);
      });
    Array.from(data)
      .slice(6, 8)
      .map((item, index) => {
        data4.push(item);
      });
    Array.from(data)
      .slice(8, 9)
      .map((item, index) => {
        data5.push(item);
      });
    console.log(newsLetters, data1, data2, data3, "hfhj");
    return (
      <PremiumSlidernews
        categorySlug={"newsletter"}
        token={this.state.token}
        data={this.state.newsLetters}
        data1={data1}
        data2={data2}
        data3={data3}
        data4={data4}
        data5={data5}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
        home={true}
      />
    );
  };
  getStaticContent = () => {
    return new Promise((resolve, reject) => {
      const { token } = this.state;
      this.setState({ pageLoader: true });
      let endpoint = `/static-content`;
      let body = {
        content: ["newsletters"],
      };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, false)
        .then((res) => {
          if (res.success && res.code == "content_found") {
            this.setState({ newsLetters: res.data.newsletters });
            this.setState({ pageLoader: false });
            resolve(res.data);
          } else {
            this.setState({ pageLoader: false, showErrorModal: true });
            resolve(false);
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          this.setState({ pageLoader: false, showErrorModal: true });
          resolve(false);
        });
    });
  };
  setBreadcrumbs = (data) => {
    Breadcrumbs = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "Newsletters",
        link: `/newsletters`,
      },
      {
        name: data.newsletter.name,
      },
    ];
  };

  splitParamsAndAppyAction = (redirectParams) => {
    let paramsArr = redirectParams.split("+");
    let id = paramsArr.find((searchParam) => {
      return searchParam.includes("id=");
    });
    let action = paramsArr.find((searchParam) => {
      return searchParam.includes("action=");
    });
    let value = paramsArr.find((searchParam) => {
      return searchParam.includes("value=");
    });

    if (id && action && value) {
      id = id.replace("id=", "");
      id = parseInt(id);
      action = action.replace("action=", "");
      value = value.replace("value=", "");
      value = value === "true" ? true : false;
      this.applyStoryActions(id, action, value);
    }
  };

  getSeoObject = () => {
    let { newsLetterDetails } = this.state;
    if (newsLetterDetails && newsLetterDetails.newsletter) {
      return seoObj.getNewsletterSingleSeo(newsLetterDetails);
    }
    return {};
  };
  getUserInfo = (token) => {
    console.log("newsletterSubscriptionStatus");
    let endpoint = `/profile`;
    let body = {
      request_group: ["subscription", "newsletters"],
    };
    apiHelpers
      .apiRequest(endpoint, {}, "POST", true, this.state.token)
      .then((res) => {
        if (res.success) {
          console.log("newsletterSubscriptionStatus");
          this.setState({ newsletterSubscriptionStatus: res.data.newsletters });
          // resolve(res.data);
          this.setState({
            userPlanDetails: res.data.subscription,
          });
        } else {
          console.log("here");
        }
        // else {
        //     resolve(false);
        // }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        resolve(false);
      });
  };
  getNewsletterDetails = () => {
    this.setState({ pageLoader: true }, () => this.scrollToTop(true));
    let { token, newsletterSlug } = this.state;
    let endpoint = `/newsletter/${newsletterSlug}`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success && res.code == "newsletter_found") {
          this.setState(
            { newsLetterDetails: res.data, pageLoader: false },
            () => {
              this.getNewsletterStories(1);
            }
          );
          this.setBreadcrumbs(res.data);
        } else {
          this.setState({ showErrorModal: true, pageLoader: false });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ showErrorModal: true, pageLoader: false });
      });
  };

  getNewsletterStories = (page, append) => {
    let { token, newsletterSlug, newsLetterDetails } = this.state;
    console.log(token, newsletterSlug, newsLetterDetails, page);
    let endpoint = `/stories`;
    let exclude_arr = [newsLetterDetails.featured_post.id];
    let body = {
      limit: 6,
      page: page,
      sort_on: "date",
      sort_by: "desc",
      exclude: exclude_arr,
      filters: [
        {
          key: "newsletter",
          value: [newsletterSlug],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState(
            {
              storiesData: res.data,
              storyList: append
                ? this.state.storyList.concat(res.data.story)
                : res.data.story.length > 0
                  ? res.data.story
                  : false,
              pageLoader: false,
            },
            () => {
              console.log(this.state.storyList, "sparsh");
            }
          );
        } else if (res.code == "no_story_found") {
          this.setState({
            storiesData: null,
            storyList: [],
            pageLoader: false,
          });
        } else {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  navigateToCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  signUp = () => {
    // if (helpers.validateEmail(email)) {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(`/sign-up?redirect=/free-reads`);
    } else {
      this.props.navigateToScreen("/sign-up", {
        redirect: "/free-reads",
        verifyRedirect: "/free-reads",
        email: "",
      });
    }
    // }
    // else {
    //     this.setState({
    //         btnLoader: false,
    //         referralMsg: {
    //             enable: true,
    //             type: 'error',
    //             msg: 'Please enter a valid email'
    //         }
    //     });
    // }
  };
  navigatetoaccount = () => {
    if (this.state.token) {
      this.props.navigateToScreen("/pricing");
    } else {
      this.signUp();
    }
  };

  navigateToNewsletter = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/newsletters/" + slug);
    } else {
      this.props.navigation.navigate("single-newsletter", {
        newsletterSlug: slug,
      });
    }
  };

  getMoreArticles = () => {
    this.getNewsletterStories(this.state.storiesData.page.current + 1, true);
  };
  updateSubscription = (value) => {
    let { token } = this.state;
    if (!token) {
      console.log(this.props);
      if (helpers.isWeb()) {
        this.props.navigateToScreen(`/sign-up?redirect=/`);
      } else {
        this.props.navigation.navigate("/sign-up", {
          redirect: "/",
          verifyRedirect: "/",
          email: email,
        });
      }
    }

    let endpoint = `/save-newsletter-type`;
    let body = {
      type: value.replace(/\s+/g, "-").toLowerCase(),
      value: true,
    };

    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          this.setState({ res: res });
          this.getUserInfo();
        } else {
          this.setState({ res: res });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  renderPageHeader = () => {
    let { newsLetterDetails, newsletterSubscriptionStatus } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let btnClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: "#accounts/newsletters",
      };
    } else {
      btnClick = {
        onPress: () => navigateApp("#accounts/newsletters"),
      };
    }
    if (newsLetterDetails) {
      return (
        <View
          style={[
            Helpers.textCenter,
            Helpers.conatinerWeb,
            Flex.alignCenter,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            {
              marginTop: helpers.isDesktop() ? 60 : 55,
              marginBottom: helpers.isDesktop() ? 120 : 55,
              paddingHorizontal: helpers.isDesktop() ? 20 : 20,
              width: helpers.isDesktop() ? "85%" : "100%",
            },
          ]}
        >
          <View style={{ position: "relative" }}>
            <CustomImage
              source={newsLetterDetails.newsletter.image.url}
              require={false}
              style={{
                width: helpers.isDesktop() ? "auto" : 200,
                height: 100,
                resizeMode: "contain",
              }}
              webStyle={{
                width: helpers.isDesktop() ? "auto" : 200,
                height: "auto",
                objectFit: "contain",
                maxWidth: 250,
              }}
              altText={newsLetterDetails.newsletter.name}
            />
            {darkMode && (
              <View
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(18, 18, 18, 0.05)",
                }}
              ></View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 24 : 24,
                lineHeight: helpers.isDesktop() ? 31 : 32.2,
                fontFamily: "Matteo-medium",
                fontWeight: helpers.isDesktop() ? "400" : "400",
                paddingTop: 20,
                color: helpers.isDesktop() ? "#000" : "#051462",
              },
              !helpers.isWeb() && { textAlign: "center" },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            {newsLetterDetails.newsletter.description}
          </Text>

          <Text
            style={[
              {
                color: darkMode ? Colors.darkBlue : "#000000",
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 34 : 24,
                fontFamily: FontFamily.regular,
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
                paddingTop: 40,
              },
              Platform.OS == "ios" && { fontFamily: FontFamily.bold },
            ]}
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 3.5H5C2.79086 3.5 1 5.29086 1 7.5V17.5C1 19.7091 2.79086 21.5 5 21.5H17C19.2091 21.5 21 19.7091 21 17.5V7.5C21 5.29086 19.2091 3.5 17 3.5Z"
                stroke="#ECB731"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 1.5V5.5"
                stroke="#ECB731"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 1.5V5.5"
                stroke="#ECB731"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 9.5H21"
                stroke="#ECB731"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span style={{ marginLeft: "10px" }}>
              Published: {newsLetterDetails.newsletter.available}
            </span>
          </Text>
          {/* <ButtonGradient
                        title={`${this.state.token?'MANAGE YOUR SUBSCRIPTIONS':'GET STARTED'}`}
                        rootStyle={{
                            btnWrapper: [button.primaryGradient,{maxWidth:250, width:"100%"}],
                            btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular, fontSize: 16 }],
                        }}
                        onClick={() =>{
                            this.navigatetoaccount();}}
                        /> */}
        </View>
      );
    }
  };

  renderAboutAuthor() {
    let { newsLetterDetails } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (newsLetterDetails && newsLetterDetails.featured_writer) {
      return (
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            {helpers.isDesktop() && (
              <View style={{ width: "80%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : "#FDF8EA",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() ? Helpers.borderBottom : "",
                  helpers.isDesktop() && { marginRight: 25, marginLeft: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    !helpers.isDesktop()
                      ? { fontSize: 35, lineHeight: 51, color: "#051462" }
                      : "",
                    helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    { marginBottom: 1 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  About the Author
                </Text>
              </View>
            </View>

            {helpers.isDesktop() && (
              <View style={{ width: "80%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <View style={[Flex.row, Padding.ph_2]}>
            <View style={[!helpers.isDesktop() && Margin.mb_2]}>
              <CustomImage
                source={newsLetterDetails.featured_writer.profile_image}
                require={false}
                style={{
                  width: helpers.isDesktop() ? 180 : 130,
                  height: helpers.isDesktop() ? 180 : 130,
                  marginRight: 20,
                }}
                webStyle={{
                  width: helpers.isDesktop() ? 180 : 130,
                  height: helpers.isDesktop() ? 180 : 130,
                  marginRight: 20,
                }}
                altText={newsLetterDetails.featured_writer.name}
              />
            </View>
            <View style={[Flex.fill]}>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 30 : 20,
                    lineHeight: helpers.isDesktop() ? 44 : 32,
                    fontFamily: FontFamily.medium,
                    fontWeight: "700",
                    color: "#000",
                  },
                  darkMode && { color: Colors.lightWhite },
                  { fontFamily: FontFamily.abrilFatface, fontWeight: "400" },
                ]}
              >
                {newsLetterDetails.featured_writer.name}
              </Text>
              {helpers.isDesktop() && (
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 20 : 16,
                      lineHeight: helpers.isDesktop() ? 28 : 24,
                      fontFamily: FontFamily.regular,
                      fontWeight: "400",
                      letterSpacing: 0.1,
                      paddingTop: 15,
                      color: "#000",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {newsLetterDetails.featured_writer.biography}
                </Text>
              )}
            </View>
          </View>

          {!helpers.isDesktop() && (
            <View style={[Flex.fill]}>
              <Text
                style={[
                  Padding.pl_2,
                  Padding.pr_2,
                  {
                    fontSize: helpers.isDesktop() ? 20 : 14,
                    lineHeight: helpers.isDesktop() ? 28 : 22,
                    fontFamily: FontFamily.regular,
                    fontWeight: "400",
                    letterSpacing: 0.3,
                    paddingTop: 15,
                    color: "#000",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {newsLetterDetails.featured_writer.biography}
              </Text>
            </View>
          )}
        </View>
      );
    }
  }

  renderFeaturedArticle() {
    let { newsLetterDetails } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (newsLetterDetails && newsLetterDetails.featured_post) {
      return (
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            { marginBottom: helpers.isDesktop() ? 0 : 0 },
          ]}
        >
          <View
            style={[
              Margin.mb_2,
              !helpers.isDesktop() && Margin.mb_3,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColorBg : "white",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginLeft: 25, marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    { marginBottom: 2, color: "#000" },
                    !helpers.isDesktop() && {
                      fontSize: 35,
                      lineHeight: 51,
                      color: "#051462",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Featured Newsletter
                </Text>
              </View>
            </View>

            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <FeaturedArticle
            data={newsLetterDetails.featured_post}
            category={newsLetterDetails.featured_post.credits.category}
            viewArticle={(catSlug, slug, postData) =>
              this.viewArticle(catSlug, slug, postData)
            }
            singleNewsletter={true}
            darkMode={darkMode}
            Premium={newsLetterDetails}
          />
        </View>
      );
    }
  }

  trackCateoryClick = (item, source_section_type) => {
    // try {
    //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: source_section_type, section_value: { title: item.name, slug: item.slug } });
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  abortRequest = () => {
    if (this.controller) {
      this.controller.abort();
    }
  };

  applyStoryActions = (id, type, value, index) => {
    const { token } = this.state;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let storyList = this.state.storyList;
      let index = storyList.findIndex((story) => {
        return story.id == id;
      });
      if (index >= 0) {
        storyList[index].is_bookmarked = !storyList[index].is_bookmarked;
      }
      this.setState({ storyList: storyList });

      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let storyList = this.state.storyList;
            let index = storyList.findIndex((story) => {
              return story.id == id;
            });
            if (index >= 0) {
              storyList[index].is_bookmarked = res.data.bookmark;
            }
            this.setState({ storyList: storyList });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }
            if (type == "bookmark" && value == false) {
              const result = this.state.readingList.filter(
                (remove) => remove != id
              );
              this.setState({
                readingList: result,
              });
            }
            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: storyList[index].title,
                  story_slug: storyList[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: storyList[index].title,
                  story_slug: storyList[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let storyList = this.state.storyList;
            storyList[index].is_bookmarked = !storyList[index].is_bookmarked;
            this.setState({ storyList: storyList });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let storyList = this.state.storyList;
            storyList[index].is_bookmarked = !storyList[index].is_bookmarked;
            this.setState({ storyList: storyList });
          }
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/sign-in?redirect=${this.state.redirectSlugs.web
          }&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`
        );
      } else {
        this.props.navigation.navigate("/sign-in", {
          ...this.state.redirectSlugs.native,
          verifyRedirect:
            this.state.redirectSlugs.native.verifyRedirect +
            `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`,
          "redirect-params": `id=${id}+action=bookmark+value=${true}+index=${index}`,
        });
      }
    }
  };

  renderNewEdition() {
    let { storyList } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (storyList && storyList.length > 0) {
      let storyListCopy = JSON.parse(JSON.stringify(storyList));
      let btnClickCategory, btnClickBookmark;
      return (
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            { marginBottom: helpers.isDesktop() ? 0 : 0 },
          ]}
        >
          <View
            style={[
              Margin.mb_5,
              !helpers.isDesktop() && Margin.mb_3,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}

            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColorBg : "white",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginLeft: 25, marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    { marginBottom: 2, color: "#000" },
                    !helpers.isDesktop() && {
                      fontSize: 35,
                      lineHeight: 51,
                      color: "#051462",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Recent Editions
                </Text>
              </View>
            </View>
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          {storyListCopy.slice(0, 4).map((item, index) => {
            if (helpers.isWeb()) {
              btnClickCategory = {
                to: "/category/" + item.category.slug,
              };
              btnClickBookmark = {
                onPress: (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.applyStoryActions(
                    Number(item.id),
                    "bookmark",
                    item.is_bookmarked ||
                      (this.state.readingList &&
                        this.state.readingList.includes(item.id))
                      ? false
                      : true,
                    index
                  );
                },
              };
            } else {
              btnClickCategory = {
                onPress: () => this.viewCategory(item.category.slug),
              };
              btnClickBookmark = {
                onPress: (event) => {
                  event.stopPropagation();
                  this.applyStoryActions(
                    Number(item.id),
                    "bookmark",
                    item.is_bookmarked ||
                      (this.state.readingList &&
                        this.state.readingList.includes(item.id))
                      ? false
                      : true,
                    index
                  );
                },
              };
            }
            return (
              <View
                style={[
                  articleItem.col6,
                  Flex.row,
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb1200,
                  !helpers.isDesktop() && Padding.ph_2,
                ]}
              >
                <View style={Flex.fill}>
                  {/* <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item, 'newsletter recent editions')}>
                                            <View>
                                                <Text style={[articleLists.badge, { width: 150, marginBottom: 8, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff', fontSize: helpers.isDesktop() ? 16 : 14 }]}>{item.category.name}</Text>
                                            </View>
                                        </TouchableOpacityLink> */}
                  <ArticleItemHorizontal
                    viewArticle={(catSlug, slug, postData) =>
                      this.viewArticle(catSlug, slug, postData)
                    }
                    viewAuthor={(slug) => this.viewAuthor(slug)}
                    data={item}
                    single={index == 0 ? true : false}
                    archive={true}
                    singleNewsletter={true}
                    listingStory={true}
                    darkMode={darkMode}
                    sectionName="Recent Editions"
                  />
                </View>
                {helpers.isDesktop() && (
                  <View
                    style={[
                      Margin.mt_3,
                      Platform.OS == "ios" && { marginTop: 25 },
                    ]}
                  >
                    <TouchableOpacity {...btnClickBookmark}>
                      <View
                        style={[
                          Platform.OS == "ios" && {
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                          },
                        ]}
                      >
                        <CustomImage
                          source={
                            item.is_bookmarked ||
                              (this.state.readingList &&
                                this.state.readingList.includes(item.id))
                              ? bookmarkIconSelected
                              : darkMode
                                ? bookmarkIconLight
                                : bookmarkIcon
                          }
                          require={true}
                          style={{ width: 20, height: 20 }}
                          webStyle={{
                            width: helpers.isDesktop() ? 26 : 21,
                            height: helpers.isDesktop() ? 25 : "auto",
                            marginLeft: -5,
                            marginRight: -5,
                          }}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            );
          })}
        </View>
      );
    }
  }

  renderOldEdition(token) {
    let { storyList, storiesData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let btnClickCategory, btnClickBookmark;
    if (storyList && storyList.length > 4) {
      let storyListCopy = JSON.parse(JSON.stringify(storyList));
      storyListCopy.splice(0, 4);
      let items = storyListCopy.map((item, index) => {
        if (helpers.isWeb()) {
          btnClickCategory = {
            to: "/category/" + item.category.slug,
          };
          btnClickBookmark = {
            onPress: (event) => {
              event.stopPropagation();
              event.preventDefault();
              this.getUserReadingList();
              let readinglistid = this.state.readingList.indexOf(item.id);
              this.state.readingList.slice(readinglistid, 1);
              console.log(this.state.readingList, "tiol");
              this.applyStoryActions(
                Number(item.id),
                "bookmark",
                item.is_bookmarked ||
                  (this.state.readingList &&
                    this.state.readingList.includes(item.id))
                  ? false
                  : true,
                index + 4
              );
            },
          };
        } else {
          btnClickCategory = {
            onPress: () => this.viewCategory(item.category.slug),
          };
          btnClickBookmark = {
            onPress: (event) => {
              event.stopPropagation();
              this.applyStoryActions(
                Number(item.id),
                "bookmark",
                item.is_bookmarked ||
                  (this.state.readingList &&
                    this.state.readingList.includes(item.id))
                  ? false
                  : true,
                index + 4
              );
            },
          };
        }
        return (
          <View
            style={[
              articleItem.col6,
              Flex.row,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
            ]}
          >
            <View style={Flex.fill}>
              {/* <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item, 'newsletter old editions')}>
                                <View>
                                    <Text style={[articleLists.badge, { width: 150, marginBottom: 8, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff', fontSize: helpers.isDesktop() ? 16 : 14 }]}>{item.category.name}</Text>
                                </View>
                            </TouchableOpacityLink> */}
              <ArticleItemHorizontal
                viewArticle={(catSlug, slug, postData) =>
                  this.viewArticle(catSlug, slug, postData)
                }
                viewAuthor={(slug) => this.viewAuthor(slug)}
                data={item}
                single={index == 0 ? true : false}
                archive={true}
                singleNewsletter={true}
                listingStory={true}
                darkMode={darkMode}
                token={token}
                sectionName="Recent Editions"
              />
            </View>
            {helpers.isDesktop() && (
              <View
                style={[Margin.mt_3, Platform.OS == "ios" && { marginTop: 25 }]}
              >
                <TouchableOpacity {...btnClickBookmark}>
                  <View
                    style={[
                      Platform.OS == "ios" && {
                        paddingHorizontal: 5,
                        paddingVertical: 5,
                      },
                    ]}
                  >
                    <CustomImage
                      source={
                        item.is_bookmarked ||
                          (this.state.readingList &&
                            this.state.readingList.includes(item.id))
                          ? bookmarkIconSelected
                          : darkMode
                            ? bookmarkIconLight
                            : bookmarkIcon
                      }
                      require={true}
                      style={{ width: 20, height: 20 }}
                      webStyle={{
                        width: helpers.isDesktop() ? 26 : 21,
                        height: helpers.isDesktop() ? 25 : "auto",
                        marginLeft: -5,
                        marginRight: -5,
                      }}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </View>
        );
      });
      return (
        <View style={[helpers.isDesktop() && Padding.ph_2, Margin.mb_3]}>
          <View
            style={[
              Margin.mb_5,
              !helpers.isDesktop() && Margin.mb_3,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColorBg : "white",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginLeft: 25, marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    { marginBottom: 2, color: "#000" },
                    !helpers.isDesktop() && {
                      fontSize: 35,
                      lineHeight: 51,
                      color: "#051462",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Older Editions
                </Text>
              </View>
            </View>
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <View style={[!helpers.isDesktop() && Padding.ph_2]}>
            <InfiniteScrollComponent
              pageStart={storiesData.page.current}
              loadMore={() => this.getMoreArticles()}
              hasMore={storiesData.page.has_next}
              items={items}
            />
          </View>
        </View>
      );
    }
  }

  renderOtherNewsletter() {
    let { newsLetterDetails } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (
      newsLetterDetails &&
      newsLetterDetails.other_newsletters &&
      newsLetterDetails.other_newsletters.length > 0
    ) {
      let grouped_array = this.getGroupedLetterArray(
        newsLetterDetails.other_newsletters
      );
      return (
        <>
          <View
            style={[
              !helpers.isDesktop() && Margin.mb_3,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : "#FAFAFA",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  helpers.isDesktop() && { marginLeft: 25, marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    { marginBottom: 2, color: "#000" },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Other Newsletters
                </Text>
              </View>
            </View>
            {!helpers.isDesktop() && (
              <View
                style={[
                  Helpers.titleLine,
                  darkMode && { backgroundColor: Colors.lineColor },
                ]}
              ></View>
            )}
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <View
            style={[
              { marginBottom: helpers.isDesktop() ? 60 : 40 },
              !helpers.isWeb() && {
                width: "100%",
                marginRight: "auto",
                marginLeft: "auto",
              },
            ]}
          >
            <NewsletterSlider
              data={grouped_array}
              getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
              darkMode={darkMode}
            />
          </View>
        </>
      );
    }
  }

  getGroupedLetterArray = (array) => {
    let grouped_array = [];
    if (helpers.isDesktop()) {
      for (let i = 0; i < array.length; i += 3) {
        grouped_array.push([array[i], array[i + 1], array[i + 2]]);
      }
    } else {
      for (let i = 0; i < array.length; i += 2) {
        grouped_array.push([array[i], array[i + 1]]);
      }
    }
    return grouped_array;
    //return grouped_array.concat(grouped_array);
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderPageContent = () => {
    let { userPlanDetails } = this.state;
    let webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        {this.state.newsLetterDetails?.featured_writer ? (
          <View
            style={[
              helpers.isDesktop()
                ? { paddingTop: 60, paddingBottom: 60 }
                : { paddingTop: 30, paddingBottom: 30 },
              {
                backgroundColor: darkMode
                  ? Colors.darkBlackColorBg
                  : userPlanDetails.quantity
                    ? "#FAFAFA"
                    : "#FDF8EA",
              },
            ]}
          >
            {this.renderAboutAuthor()}
          </View>
        ) : (
          ""
        )}

        <View
          style={[
            {
              paddingTop: 60,
              paddingBottom: 0,
              backgroundColor: darkMode ? "black" : "#fafafa",
            },
          ]}
        >
          {this.renderFeaturedArticle()}
        </View>
        <View style={[{ paddingTop: 60, paddingBottom: 60 }]}>
          {this.renderNewEdition()}
        </View>
        <View
          style={[
            helpers.isDesktop() && {
              backgroundColor: darkMode ? Colors.darkBlackColorBg : "#FAFAFA",
              paddingTop: 60,
              paddingBottom: 60,
            },
          ]}
        >
          {this.renderOldEdition(this.state.token)}
        </View>
        {/* <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, {paddingTop:60, paddingBottom:60}]}>
                {this.renderOtherNewsletter()}
            </View> */}

        <View style={{ height: helpers.isWeb() ? 600 : 500 }}>
          {this.state.newsLetters.length > 0 && this.renderCarousal()}

          {/* {this.renderCarousal()} */}
        </View>

        <View
          style={[
            Helpers.conatinerWeb,
            // helpers.isDesktop() && Helpers.conatinerWeb1200,
            {
              maxWidth: "1100px",
              marginHorizontal: "auto",
              width: "100%",
              paddingTop: 60,
              paddingBottom: 60,
            },
          ]}
        >
          <View
            style={[
              Margin.mb_2,
              !helpers.isDesktop() && Margin.mb_15,
              Flex.row,
              Flex.justfiyCenter,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColorBg : "white",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && { marginLeft: 25, marginRight: 25 },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    !helpers.isDesktop() && { fontSize: 24, color: "#051462" },
                    helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    { marginBottom: 2, color: "#000" },
                    darkMode && { color: Colors.lightWhite },
                    {
                      fontSize: 30,
                      lineHeight: 26,
                    },
                  ]}
                >
                  Explore newsletters on
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              Flex.row,
              Flex.justfiyCenter,
              Flex.FlexWrap,
              helpers.isWeb() && { paddingHorizontal: 20 },
            ]}
          >
            {this.state.newsletterList &&
              this.state.newsletterList.map((d, i) => {
                return (
                  <TouchableOpacityLink {...this.methodProps(d.id)}>
                    <View
                      style={[
                        {
                          // borderWidth: 0.75,
                          // borderColor: "#7B8FFE",
                          // borderRadius: 40,
                          backgroundColor: "#fff",
                        },
                        !helpers.isDesktop() && {
                          paddingBottom: 0,
                          marginLeft: 0,
                          marginRight: 0,
                        },
                        !process.browser && { width: 300 },
                        !helpers.isWeb() && { width: "100%" },
                        darkMode && { backgroundColor: "#1E1E1E" },
                      ]}
                    >
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          Flex.justifyBetween,
                          { alignItems: "baseline" },
                          !webView && { alignItems: "center" },
                        ]}
                      >
                        <Text
                          style={[
                            button.outlineButton,
                            !helpers.isDesktop() && {
                              fontSize: 16,
                              lineHeight: 35,
                              margin: 8,
                              paddingHorizontal: 16,
                            },
                          ]}
                        >
                          {d.name}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacityLink>
                );
              })}
          </View>
        </View>
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </ScrollView>
    );
  };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      } else {
        if (this.appscrollview) {
          this.appscrollview.scrollTo({ y: 0, animated: true });
        }
        if (this.contentscrollview) {
          this.contentscrollview.scrollTo({ y: 0, animated: true });
        }
      }
    }
  };

  render() {
    console.log(this.props);
    const {
      pageLoader,
      showErrorModal,
      showUparrow,
      footerData,
      redirectSlugs,
      newsLetterDetails,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
          { backgroundColor: darkMode ? "black" : "#FFFFFF" },
        ]}
      >
        <Seo
          data={this.getSeoObject()}
          page="/newsletters/:letter_slug"
          pageData={newsLetterDetails}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
        />

        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
                ? this.renderWebPage()
                : this.renderAppPage()}
            {showUparrow && (
              <View
                style={{
                  position: webView ? "fixed" : "absolute",
                  bottom: 20,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.scrollToTop()}
                  activeOpacity={0.8}
                  style={{ paddingHorizontal: 10, paddingVertical: 10 }}
                >
                  <CustomImage
                    source={upArrowIcon}
                    require={true}
                    style={{ width: 60, height: 60 }}
                    webStyle={{ width: 60, height: 60 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </>
        )}

        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />

        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigateToScreen("/")}
            tryAgain={() => this.componentDidMount()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(SingleNewsletter));

let Breadcrumbs = [];
