import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, appStyles, FontFamily, FontWeight, pageCover, pageContent, investorCard } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from "react-native-easy-content-loader";


export class PageContentLoader extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {

    }

    showPageLoader = () => {
        let {darkMode} = this.props;
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }, { paddingHorizontal: 5 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[150]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 30, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View style={[Margin.mt_2]}>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[25]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[40]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[40]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[40]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[40]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[40]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Margin.mt_2]}>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[25]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />

                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                        active={true}
                        pHeight={[40]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[40]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 20 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
            </View>
        )
    }



    render() {

        return (
            <View style={[Flex.fill]}>
                {this.showPageLoader()}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}


export default PageContentLoader;