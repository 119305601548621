import { NEW_APP, NEW_APP_VERSION, DARK_MODE, DARK_MODE_TOGGLE, REFRESH_SCREEN, FCM_TOKEN, REMOVE_FCM_TOKEN } from './app.types';


const initialState = {
    AppVersion: '',
    NewApp: true,
    DarkMode: 'off',
    DarkModeToggle: false,
    refreshScreen: true,
    fcmToken: ''
};

const AppReducer = (state = initialState, action) => {
    let data;
    let user;
    let error;
    switch (action.type) {
        case NEW_APP:
            return { ...state, NewApp: false }
        case NEW_APP_VERSION:
            return {
                ...state,
                AppVersion: action.data
            }
        case DARK_MODE:
            return {
                ...state,
                DarkMode: action.data
            }
        case DARK_MODE_TOGGLE:
            return {
                ...state,
                DarkModeToggle: action.data
            }
        case REFRESH_SCREEN:
            return {
                ...state,
                refreshScreen: action.data
            }
        case FCM_TOKEN:
            return {
                ...state,
                fcmToken: action.data
            }
        case REMOVE_FCM_TOKEN:
            return {
                ...state,
                fcmToken: ''
            }
        default:
            return state;
    }
};

export default AppReducer;