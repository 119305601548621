import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, button, orderData, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import Cookies from "universal-cookie";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import apiHelpers from "../../utils/api-helpers";
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';
import OrderView from '../order-view';
import ContentLoader from '../content-loader';
import AuthenticationWrapper from '../authentication-wrapper';

const cookies = new Cookies();

const sCreditsIcon = getImagePath('icons/sCredits.png');
const sCreditsIconDark = getImagePath('icons/sCreditsDark.png');
const silverCoin = getImagePath('icons/silver-coin.png');

export class StarterCredits extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            enableBtnLoader: false,
            credits: this.props.params ? this.props.params.credits : false,
            ordersData: false,// []
            pageLoader: this.props.params ? false : true,
            creditsLoader: true
        }
    }

    componentDidMount = () => {
        this.getUserProfile();
        this.getOrdersData()
    }

    getUserProfile = () => {
        const { token } = this.state;
        let endpoint = `/profile`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            request_group: ["credits"]
        }
        if (isAdmin) {
            body = {
                request_group: ["credits"],
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'profile_info') {
                    this.setState({
                        credits: res.data?.credits?.non_privileged_credits ? res.data.credits.non_privileged_credits.available : 0,
                        pageLoader: false,
                        creditsLoader: false
                    });
                    //get orders
                    //set blank array if no orders
                } else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({
                            credits: 0,
                            pageLoader: false,
                            creditsLoader: false
                        });
                    }
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    credits: 0,
                    pageLoader: false,
                    creditsLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getOrdersData = () => {
        const { token } = this.state;
        let endpoint = `/transactions-list`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            limit: 3,
            sort: {
                on: "createdAt",
                by: "desc"
            },
            filters: {
                type: ["credits"],
                order_action: "free_credits"
            }
        }
        if (isAdmin) {
            body = {
                limit: 3,
                sort: {
                    on: "createdAt",
                    by: "desc"
                },
                filters: {
                    type: ["credits"],
                    order_action: "free_credits"
                },
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'transactions') {
                    let orders_data = Array.isArray(res.data) ? res.data : []
                    this.setState({ ordersData: orders_data });
                }
                else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({ showErrorModal: true });
                    }
                }
            })
            .catch((error) => {
                this.setState({ showErrorModal: true });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    renderPageContent = () => {
        let { ordersData, enableBtnLoader, credits, token, creditsLoader } = this.state;
        const { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={{ marginBottom: helpers.isDesktop() ? 150 : 50 }}>
                <View style={{ backgroundColor: darkMode ? '#B8B7B7' : '#f9f9f9', marginBottom: helpers.isDesktop() ? 40 : 30, position: 'relative', overflow: 'hidden' }}>
                    <CustomImage
                        source={darkMode ? sCreditsIconDark : sCreditsIcon}
                        require={true}
                        style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%' }}
                        webStyle={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%' }}
                        altText={'Credits'}
                    />
                    <View style={[Padding.ph_2]}>
                        <Text style={[Margin.mt_3, Margin.mb_15, { textAlign: 'center', fontSize: 22, lineHeight: 33, fontFamily: FontFamily.abrilFatface, letterSpacing: 0.3 }]}>Starter Credits</Text>
                        {
                            creditsLoader ?
                                <View style={{ height: 102 }}></View>
                                :
                                <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, Margin.mb_3]}>
                                    <CustomImage
                                        source={silverCoin}
                                        require={true}
                                        style={{ width: 40, height: 40 }}
                                        webStyle={{ width: 46, height: 47 }}
                                        altText={'Credits'}
                                    />
                                    <Text style={{ fontSize: 60, lineHeight: 72, color: 'ABABAB', fontWeight: FontWeight.medium, fontFamily: FontFamily.medium, marginLeft: 15 }}>{credits}</Text>
                                </View>
                        }
                        {/* <Text style={{ fontSize: 14, lineHeight: 21, letterSpacing: 0.3, fontFamily: FontFamily.regular, color: 'rgba(0, 0, 0, 0.7)', textAlign: 'center', marginBottom: 30 }}>Refer a friend to earn free Starter Credits</Text>
                        <ButtonGradient
                            title={'REFER A FRIEND'}
                            onClick={() => this.gotoSignUp()}
                            rounded={false}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, Margin.mb_3],
                                btnText: button.primaryGradientText,
                            }}
                        /> */}
                    </View>
                </View>

                <View style={[Padding.ph_2]}>
                    {(ordersData) &&
                        <>
                            <Text style={[{ fontSize: 18, lineHeight: 21, fontFamily: FontFamily.medium, fontWeight: FontWeight.medium, textTransform: 'uppercase', marginBottom: helpers.isDesktop() ? 20 : 15 }, darkMode && { color: Colors.lightWhite }]}>Recent ORDER DETAILS</Text>
                            {(ordersData.length == 0) ?
                                <Text style={[orderData.payMethod, darkMode && { color: Colors.lightWhite }]}>You have no past transactions. Check out our offers to get more starter credits.</Text>
                                :
                                ordersData.map((order, index) => {
                                    return (
                                        <OrderView order={order} token={token} index={index} key={index} darkMode={darkMode} isAdmin={isAdmin} referenceId={referenceId} />
                                    )
                                })
                            }
                        </>
                    }
                </View>
            </View>
        )
    }


    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ContentLoader darkMode={darkMode} />
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {
                    pageLoader ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }

}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(StarterCredits));
