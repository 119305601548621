import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TextInput, Button } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors, YesterdayModule, articleItemHorizontal } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';


export class SmartReaders extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {
        let webView = helpers.isWeb();
        return (
            <View style={[YesterdayModule.block]}>                
                <Text style={[YesterdayModule.readSmarter]}>
                    Read Smarter
                </Text>
                <Text style={[YesterdayModule.readSmarterSignup]}>
                    Sign up for Yesterday and let it help you catch up with the news
                </Text>
                <View style={[YesterdayModule.inputBox]} >
                    <TextInput 
                        style={[YesterdayModule.inputBoxPlaceholder]}
                        placeholder="Enter your email address"
                        placeholderTextColor="#FFFFFF" 
                    />         
                  
                </View>                
            </View >
        );
    }
}

export default SmartReaders;