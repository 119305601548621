import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  TextInput,
  Platform,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  FontFamily,
  FontWeight,
  pageCover,
  pageContent,
  articleItem,
  articleLists,
  Colors,
  formStyle,
} from "../../styles/appStyles";
const searchBox = getImagePath("icons/searchbox.png");
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import InfiniteScrollComponent from "../../components/infinite-scroll";
import ArticleItemHorizontal from "../../components/article-item-horizontal";
import ArticleItemHorizontalLayout from "../../components/article-item-layout-horizontal";
import ScrollContainerView from "../../components/scroll-container-view";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import { Mixpanel } from "../../utils/mixpanel";
import NewsletterCategory from "../../components/newsletter-category";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const upArrowIcon = getImagePath("icons/uparrow.png");
const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconLight = getImagePath("icons/bookmark-light.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");
const starterDots = getImagePath("icons/starter-dots.png");
const starterSemiCircle = getImagePath("icons/starter-semi-circle.png");
const starterCollection = getImagePath("icons/starterCollection.png");
const premiumCollection = getImagePath("icons/goldenIllustration.png");
const windowWidth = Dimensions.get("window").width;
const cookies = new Cookies();
let resetTimeout = 0;
const noArticles = getImagePath("img/no-articles.png");

export class UnlockCollection extends Component {
  controller;
  signal;
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let collectionType = "",
      redirectParams;
    if (webView) {
      collectionType = this.props.params.collectionType;
    } else if (!webView) {
      collectionType = this.props.collectionType;
    }
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    if (webView && process.browser && window.location.search) {
      let searchArr = window.location.search.replace("?", "").split("&");
      redirectParams = searchArr.find((searchParam) => {
        return searchParam.includes("redirect-params=");
      });
      if (redirectParams) {
        redirectParams = redirectParams.replace("redirect-params=", "");
      }
    }

    this.state = {
      token: token == undefined || token == "" ? false : token,
      collectionType: collectionType,
      credits: false,
      recomendedStories: false,
      otherStories: false,
      otherStoriesPage: false,
      pageLoader: false,
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      redirectParams: redirectParams,
      searchText: "",
    };

    if (redirectParams) {
      let reaplaceProp = JSON.parse(JSON.stringify(window.location));
      reaplaceProp.search = "";
      this.props.history.replace(reaplaceProp);
    }
  }

  componentDidMount = () => {
    this.getUserStories();
    let { redirectParams, token, collectionType } = this.state;
    if (redirectParams && token) {
      this.splitParamsAndAppyAction(redirectParams);
    }
    this.setBreadcrumbs(collectionType);
  };

  setBreadcrumbs = (data) => {
    Breadcrumbs = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: data == "starter" ? "Starter Collection" : "Premium Collecton",
      },
    ];
  };

  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  viewAllArticles = () => {
    let categorySlug = "business";
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + categorySlug + "/all");
    } else {
      this.props.navigation.navigate("category-archive", {
        archiveSlug: categorySlug,
      });
    }
  };

  viewAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };

  viewCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  splitParamsAndAppyAction = (redirectParams) => {
    let paramsArr = redirectParams.split("+");
    let id = paramsArr.find((searchParam) => {
      return searchParam.includes("id=");
    });
    let action = paramsArr.find((searchParam) => {
      return searchParam.includes("action=");
    });
    let value = paramsArr.find((searchParam) => {
      return searchParam.includes("value=");
    });

    if (id && action && value) {
      id = id.replace("id=", "");
      id = parseInt(id);
      action = action.replace("action=", "");
      value = value.replace("value=", "");
      value = value === "true" ? true : false;
      this.applyStoryActions(id, action, value);
    }
  };

  getUserStories = () => {
    const { token } = this.state;
    let endpoint = `/stories/popular`;
    let body = {
      limit: 3,
      days_before: 100,
      archive_days: 0,
      filters: [
        {
          key: "is_privileged",
          value: this.state.collectionType == "starter" ? false : true,
        },
        {
          key:"type",
          value: ["regular", "newsletter", "yesterday"]
        }
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let recomendedStories = res.data.story ? res.data.story : [];
          this.setState({
            recomendedStories: recomendedStories,
          });
          this.getOtherStories(recomendedStories, 1);
        } else {
          console.log("Error=>>", JSON.stringify(res));
          this.getOtherStories([], 1);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getOtherStories = (storyList, page, append) => {
    const { token, searchText } = this.state;
    let excludeList = [];
    if (storyList) {
      excludeList = storyList.map((story) => {
        return story.id;
      });
    }

    let endpoint = `/stories`;
    let body = {
      limit: 10,
      exclude: excludeList,
      page: page,
      filters: [
        {
          key: "newsletter",
          value: ["include"],
        },
        {
          key: "is_privileged",
          value: this.state.collectionType == "starter" ? false : true,
        },
      ],
    };
    if (searchText) {
      body.search_string = searchText;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            otherStories: append
              ? this.state.otherStories.concat(res.data.story)
              : res.data.story,
            otherStoriesPage: res.data.page,
          });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getMoreArticles = () => {
    this.getOtherStories(
      this.state.recomendedStories,
      this.state.otherStoriesPage.current + 1,
      true
    );
  };

  getCredits = (collectionType, credits) => {
    if (collectionType == "starter") {
      return credits.non_privileged_credits
        ? credits.non_privileged_credits.available
        : 0;
    } else {
      return credits.privileged_credits
        ? credits.privileged_credits.available
        : 0;
    }
  };

  abortRequest = () => {
    if (this.controller) {
      this.controller.abort();
    }
  };

  applyStoryActions = (id, type, value) => {
    const { token } = this.state;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let otherStories = this.state.otherStories;
      let index = otherStories.findIndex((story) => {
        return story.id == id;
      });
      if (index >= 0) {
        otherStories[index].is_bookmarked = !otherStories[index].is_bookmarked;
      }
      this.setState({ otherStories: otherStories });

      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let otherStories = this.state.otherStories;
            let index = otherStories.findIndex((story) => {
              return story.id == id;
            });
            if (index >= 0) {
              otherStories[index].is_bookmarked = res.data.bookmark;
            }
            this.setState({ otherStories: otherStories });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }

            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: otherStories[index].title,
                  story_slug: otherStories[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: otherStories[index].title,
                  story_slug: otherStories[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let otherStories = this.state.otherStories;
            let index = otherStories.findIndex((story) => {
              return story.id == id;
            });
            if (index >= 0) {
              otherStories[index].is_bookmarked =
                !otherStories[index].is_bookmarked;
            }
            this.setState({ otherStories: otherStories });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let otherStories = this.state.otherStories;
            let index = otherStories.findIndex((story) => {
              return story.id == id;
            });
            if (index >= 0) {
              otherStories[index].is_bookmarked =
                !otherStories[index].is_bookmarked;
            }
            this.setState({ otherStories: otherStories });
          }
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/sign-in?redirect=/${this.state.collectionType
          }-collection&redirect-params=id=${id}+action=bookmark+value=${true}`
        );
      } else {
        this.props.navigation.navigate("/sign-in", {
          redirect: this.state.collectionType + "-collection",
          verifyRedirect:
            "/" +
            collectionType +
            "-collection" +
            `?redirect-params=id=${id}+action=bookmark+value=${true}`,
          "redirect-params": `id=${id}+action=bookmark+value=${true}`,
        });
      }
    }
  };

  trackCateoryClick = (item, source_section_type) => {
    // try {
    //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: source_section_type, section_value: { title: item.name, slug: item.slug } });
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  renderPageHeader = () => {
    const { collectionType } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          helpers.isDesktop() && Margin.mb_7,
          Helpers.textCenter,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          {
            backgroundColor:
              collectionType == "starter"
                ? darkMode
                  ? Colors.darkBlackColor
                  : "#F9F8FF"
                : darkMode
                  ? Colors.darkBlackColor
                  : "#FFF8D6",
            marginTop: helpers.isDesktop() ? 130 : 75,
            paddingHorizontal: helpers.isDesktop() ? 20 : 0,
            width: helpers.isDesktop() ? "85%" : "100%",
          },
        ]}
      >
        <Text
          accessibilityRole="header"
          aria-level="1"
          style={[
            Margin.mt_0,
            Margin.mb_0,
            pageCover.title,
            helpers.isDesktop() && pageCover.titleDesktop,
            Helpers.textCenter,
            { position: "relative", top: helpers.isDesktop() ? -42 : -25 },
            !helpers.isDesktop() && { fontSize: 31 },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          {collectionType == "starter" ? "Free Reads" : "Your Premium Collection"}
        </Text>
        <Text
          style={[
            { marginBottom: helpers.isDesktop() ? 90 : 50 },
            pageContent.subTitle,
            helpers.isDesktop() && pageContent.subTitleDesktop,
            {
              maxWidth: helpers.isDesktop() ? 900 : "100%",
              textAlign: "center",
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          Check out our hand-picked collection below which you can unlock using
          your {collectionType == "starter" ? "free starter" : "premium"}{" "}
          credits{" "}
        </Text>
        <CustomImage
          source={starterDots}
          require={true}
          style={{
            width: helpers.isDesktop() ? 54 : 54,
            height: helpers.isDesktop() ? 39 : 39,
            position: "absolute",
            left: helpers.isDesktop() ? -18 : 0,
            bottom: helpers.isDesktop() ? 45 : 6,
          }}
          webStyle={{
            width: helpers.isDesktop() ? 52 : 52,
            height: helpers.isDesktop() ? 38 : 38,
            position: "absolute",
            left: helpers.isDesktop() ? -18 : 0,
            bottom: helpers.isDesktop() ? 50 : 20,
          }}
        />
        <CustomImage
          source={starterSemiCircle}
          require={true}
          style={{
            width: helpers.isDesktop() ? 41 : 41,
            height: helpers.isDesktop() ? 76 : 76,
            position: "absolute",
            right: 0,
            bottom: 70,
          }}
          webStyle={{
            width: helpers.isDesktop() ? 41 : 41,
            height: helpers.isDesktop() ? 76 : 76,
            position: "absolute",
            right: 0,
            bottom: 70,
          }}
        />
      </View>
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    return (
      <View
        style={[
          Flex.justfiyCenter,
          Flex.alignCenter,
          {
            position: webView ? "fixed" : "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "#ffffff",
          },
        ]}
      >
        <ActivityIndicator size={25} color={"#000000"} />
      </View>
    );
  };

  renderCredits() {
    const { collectionType, credits } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={{
          flexDirection: helpers.isDesktop() ? "row" : "column",
          paddingTop: helpers.isDesktop() ? 50 : 40,
          marginBottom: helpers.isDesktop() ? 120 : 50,
        }}
      >
        <View style={{ width: helpers.isDesktop() ? "70%" : "100%" }}>
          <View
            style={[
              Margin.mb_4,
              !helpers.isDesktop() && Margin.mb_3,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColorBg : "white",
                  zIndex: 1,
                },
                !helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  What are {collectionType == "starter" ? "starter" : "premium"}{" "}
                  credits?
                </Text>
              </View>
              {!helpers.isDesktop() && (
                <View
                  style={{
                    height: 18,
                    position: "absolute",
                    top: 18,
                    right: 0,
                    left: 0,
                    width: "100%",
                    zIndex: -1,
                  }}
                ></View>
              )}
            </View>
            {!helpers.isDesktop() && (
              <View
                style={[
                  Helpers.titleLine,
                  darkMode && { backgroundColor: Colors.lineColor },
                ]}
              ></View>
            )}
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLine,
                    { width: "45%" },
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              pageContent.text,
              Margin.mb_2,
              helpers.isDesktop() && Padding.pr_3,
              !helpers.isDesktop() && { paddingHorizontal: 20 },
              { maxWidth: helpers.isDesktop() ? 460 : "100%" },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            {collectionType == "starter"
              ? "These are free credits that are given to you when you first sign up to TMC."
              : "These are credits that are purchased by you."}{" "}
            You can use these credits to unlock any of the stories, newsletters
            or long reads provided below{" "}
          </Text>
        </View>
        <View
          style={[
            Flex.justfiyCenter,
            !helpers.isDesktop() && Padding.pb_4,
            {
              width: helpers.isDesktop() ? "30%" : "100%",
              alignItems: "center",
            },
          ]}
        >
          <CustomImage
            source={
              collectionType == "starter"
                ? starterCollection
                : premiumCollection
            }
            require={true}
            style={{
              width: helpers.isDesktop() ? 295 : 295,
              height: helpers.isDesktop() ? 243 : 243,
            }}
            webStyle={{
              width: helpers.isDesktop() ? 295 : 295,
              height: helpers.isDesktop() ? 243 : 243,
            }}
            altText={
              collectionType == "starter"
                ? "Starter Collection"
                : "Premium Collection"
            }
          />
          <Text
            style={{
              fontSize: 18,
              lineHeight: 21,
              color: darkMode ? Colors.lightWhite : "#000000",
              fontWeight: FontWeight.medium,
              fontFamily: FontFamily.medium,
              marginTop: helpers.isDesktop() ? 35 : 20,
              marginBottom: helpers.isDesktop() ? 0 : 35,
              textAlign: helpers.isDesktop() ? "right" : "center",
              borderBottomWidth: 1,
              borderBottomColor: "#3EBE8C",
              paddingBottom: 5,
            }}
          >
            {collectionType == "starter" ? "Starter" : "Premium"} Credit
            Balance:{" "}
            <Text style={{ color: "#3EBE8C" }}>
              {credits ? this.getCredits(collectionType, credits) : 0}
            </Text>
          </Text>
        </View>
      </View>
    );
  }

  getArticles = (page, append) => {
    let {
      readingList,
      checkBoxChecked,
      userLoggedin,
      searchText,
      otherStories,
      archiveSlug,
      archiveType,
      token,
      collectionType,
    } = this.state;
    let excludeArr = [];
    if (
      archiveSlug == "game-point" ||
      archiveSlug == "peoples-economics" ||
      archiveSlug == "legal" ||
      archiveSlug == "the-world-revealed" ||
      archiveSlug == "govt"
    ) {
      archiveType = "newsletter";
    }
    if (archiveSlug == "govt") {
      archiveSlug = "creative-destruction";
    }
    if (
      readingList &&
      readingList.length > 0 &&
      !checkBoxChecked &&
      userLoggedin
    ) {
      readingList.filter((post) => {
        excludeArr.push(post.id);
      });
    }

    let endpoint = `/stories`;
    let body = {
      limit: 10,
      page: page,
      sort_on: "date",
      sort_by: "desc",
      search_string: searchText,
      exclude: excludeArr,
      filters: [
        {
          key: "",
          value: ["include"],
        },
      ],
    };
    if (archiveType == "category" || archiveType == "category-archive") {
      body["filters"] = [
        {
          key: "category",
          value: [archiveSlug],
        },
      ];
    } else if (archiveType == "newsletter") {
      body["filters"] = [
        {
          key: "newsletter",
          value: [archiveSlug],
        },
      ];
    } else if (archiveType == "company") {
      body["filters"] = [
        {
          key: "company",
          value: [archiveSlug],
        },
      ];
    } else if (archiveType == "tag") {
      body["filters"] = [
        {
          key: "storyplay",
          value: [archiveSlug],
        },
        { key: "newsletter", value: ["include"] },
      ];
    } else if (archiveType == "theme") {
      body["filters"] = [
        {
          key: "storyplay",
          value: [archiveSlug],
        },
      ];
    }
    if (archiveType == "category-archive" || archiveType == "archive") {
      body["is_archive"] = true;
    }
    body["is_archive"] = true;
    if (collectionType == "starter") {
      body.filters.push({
        key: "is_privileged",
        value: false,
      });
    } else {
      body.filters.push({
        key: "is_privileged",
        value: true,
      });
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            archiveData: res.data,
            otherStories: append
              ? otherStories.concat(res.data.story)
              : res.data.story,
            pageLoader: false,
            otherStoriesPage: res.data.page,
          });
        } else {
          this.setState({
            otherStories: append ? otherStories : [],
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        if (!error.aborted) {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  searchFieldOnChange = (value) => {
    this.setState(
      {
        searchText: value,
      },
      () => {
        if (resetTimeout != 0) {
          clearTimeout(resetTimeout);
        }
        let th = this;
        resetTimeout = setTimeout(() => {
          this.getArticles(1);
          if (helpers.isWeb()) {
            let pushProp = JSON.parse(JSON.stringify(window.location));

            if (value != "")
              pushProp.search =
                "?search=" +
                value +
                (!this.state.checkBoxChecked && this.state.token
                  ? "&exclude_read=true"
                  : "");
            else
              pushProp.search =
                !this.state.checkBoxChecked && this.state.token
                  ? "?exclude_read=true"
                  : "";

            this.props.history.replace(pushProp);
          }
        }, 500);
      }
    );
  };

  renderSearchField = () => {
    let { searchText, checkBoxChecked, userLoggedin } = this.state;
    let webView = helpers.isWeb();
    let btnClickCheckBox;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (helpers.isWeb()) {
      btnClickCheckBox = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.setState(
            {
              checkBoxChecked: !checkBoxChecked,
            },
            () => {
              this.getArticles(1);
              let pushProp = JSON.parse(JSON.stringify(window.location));
              let searchArr = window.location.search
                .replace("?", "")
                .split("&");
              if (
                searchArr.length > 0 &&
                searchArr[0] != "" &&
                searchArr[0].startsWith("?search")
              ) {
                pushProp.search += "&search=" + value;
              } else {
                if (!this.state.checkBoxChecked) {
                  pushProp.search = this.state.searchText
                    ? "?search=" + this.state.searchText + "&exclude_read=true"
                    : "?exclude_read=true";
                } else {
                  pushProp.search = this.state.searchText
                    ? "?search=" + this.state.searchText
                    : "";
                }
              }
              this.props.history.push(pushProp);
            }
          );
        },
      };
    } else {
      btnClickCheckBox = {
        onPress: (event) => {
          event.stopPropagation();
          this.setState(
            {
              checkBoxChecked: !checkBoxChecked,
            },
            () => this.getArticles(1)
          );
        },
      };
    }

    return (
      <View
        style={[
          helpers.isDesktop() && Flex.row,
          !helpers.isDesktop() && Flex.column,
          Flex.justfiyCenter,
          Margin.mb_7,
          { alignItems: helpers.isDesktop() ? "baseline" : "center" },
          !webView && { width: windowWidth },
        ]}
      >
        <View
          style={[
            { position: "relative" },
            !helpers.isDesktop() && { width: "100%", paddingHorizontal: 20 },
            !webView && { flexDirection: "row" },
          ]}
        >
          <TextInput
            placeholder={"Search for stories"}
            placeholderTextColor={Colors.placeholderTextColor}
            value={searchText}
            onChangeText={(search) => {
              this.searchFieldOnChange(search);
            }}
            style={[
              helpers.isDesktop() && Padding.pl_2,
              !helpers.isDesktop() && Padding.pl_1,
              {
                color: "#000",
                borderWidth: 1,
                borderColor: "#907CFF",
                height: helpers.isDesktop() ? 46 : 40,
                width: helpers.isDesktop() ? 400 : "100%",
                paddingRight: 80,
                fontFamily: FontFamily.regular,
              },
              !webView && { width: windowWidth - 90 },
              darkMode && { color: Colors.lightWhite },
            ]}
          />
          <View style={[formStyle.searchBox]}>
            <CustomImage
              source={searchBox}
              require={true}
              style={{
                width: 45,
                height: helpers.isDesktop() ? 46 : 40,
                position: "relative",
              }}
              webStyle={{
                width: helpers.isDesktop() ? 50 : 43,
                height: helpers.isDesktop() ? 46 : 40,
                position: "absolute",
                right: 0,
                top: helpers.isDesktop() ? -46 : -40,
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  renderStories(otherStories) {
    let btnClickCategory, btnClickBookmark;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let items = otherStories.map((item, index) => {
      if (helpers.isWeb()) {
        btnClickCategory = {
          to: "/category/" + item.category.slug,
        };
        btnClickBookmark = {
          onPress: (event) => {
            event.stopPropagation();
            event.preventDefault();
            this.applyStoryActions(
              Number(item.id),
              "bookmark",
              item.is_bookmarked ? false : true
            );
          },
        };
      } else {
        btnClickCategory = {
          onPress: () => this.viewCategory(item.category.slug),
        };
        btnClickBookmark = {
          onPress: (event) => {
            event.stopPropagation();
            this.applyStoryActions(
              Number(item.id),
              "bookmark",
              item.is_bookmarked ? false : true
            );
          },
        };
      }
      return (
        <View
          style={[
            articleItem.col6,
            Flex.row,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          <View style={Flex.fill}>
            {item.newsletter ? (
              <NewsletterCategory
                newsletter={item.newsletter}
                darkMode={darkMode}
                title={"startercollection"}
              />
            ) : (
              <></>
            )}
            <ArticleItemHorizontal
              viewArticle={(catSlug, slug, postData) =>
                this.viewArticle(catSlug, slug, postData)
              }
              newsletter={item.newsletter}
              viewAuthor={(slug) => this.viewAuthor(slug)}
              viewCategory={(slug) => this.viewCategory(slug)}
              getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
              data={item}
              single={index == 0 ? true : false}
              archive={true}
              listingStory={true}
              darkMode={darkMode}
              title={"startercollection"}
              hideBookMark={helpers.isWeb() ? true : false}
              sectionName="Starter collection"
            />
          </View>
          <View
            style={[
              Margin.mt_3,
              !helpers.isWeb() && { marginTop: 20, paddingTop: 10 },
              Platform.OS == "ios" && { marginTop: 15 },
              helpers.isWeb() && { marginLeft: -10, marginTop: -2 },
            ]}
          >
            <TouchableOpacity {...btnClickBookmark}>
              <View
                style={[
                  Platform.OS == "ios" && {
                    paddingHorizontal: 5,
                    paddingVertical: 5,
                  },
                ]}
              >
                <CustomImage
                  source={
                    item.is_bookmarked ||
                      (this.state.bookmarkList &&
                        this.state.bookmarkList.includes(item.id))
                      ? bookmarkIconSelected
                      : darkMode
                        ? bookmarkIconLight
                        : bookmarkIcon
                  }
                  require={true}
                  style={{ width: 20, height: 20 }}
                  webStyle={{
                    width: helpers.isDesktop() ? 26 : 21,
                    height: helpers.isDesktop() ? 25 : "auto",
                    marginLeft: -5,
                    marginRight: -5,
                  }}
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    });
    return (
      <>
        <View
          style={[
            Flex.alignCenter,
            Margin.mb_4,
            { marginTop: helpers.isDesktop() ? 70 : 40 },
          ]}
        >
          <View
            style={[
              Padding.ph_2,
              Helpers.titleBgWrap,
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            <View
              style={[
                Helpers.borderBottom,
                darkMode && { borderBottomColor: Colors.lineColor },
              ]}
            >
              <Text
                style={[
                  Helpers.textCenter,
                  Typo.h3_R,
                  helpers.isDesktop() && Typo.h3_RDesktop,
                  Margin.mb_0,
                  { fontSize: helpers.isDesktop() ? 34 : 18 },
                  { fontFamily: FontFamily.abrilFatface, marginBottom: 1 },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Starter Content
              </Text>
            </View>
          </View>
          <View
            style={[
              Helpers.titleLine,
              {
                backgroundColor: darkMode
                  ? Colors.lineColor
                  : "rgba(0, 0, 0, 0.6)",
              },
            ]}
          ></View>
        </View>
        {this.renderSearchField()}
        <View style={[helpers.isDesktop() ? Padding.ph_0 : Padding.ph_2]}>
          {otherStories.length > 0 ? (
            <InfiniteScrollComponent
              pageStart={this.state.otherStoriesPage.current}
              loadMore={() => this.getMoreArticles()}
              hasMore={this.state.otherStoriesPage.has_next}
              items={items}
              darkMode={darkMode}
            />
          ) : (
            <View style={[Flex.alignCenter, Margin.mb_6]}>
              <CustomImage
                source={noArticles}
                require={true}
                style={{
                  width: helpers.isDesktop() ? 360 : 200,
                  height: helpers.isDesktop() ? 360 : 200,
                }}
                webStyle={{
                  width: helpers.isDesktop() ? 360 : 200,
                  height: helpers.isDesktop() ? 360 : 200,
                }}
                altText={"Match not found"}
              />
              <Text
                style={{
                  fontSize: helpers.isDesktop() ? 36 : 24,
                  lineHeight: 43,
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center",
                  fontFamily: FontFamily.medium,
                  color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.9)",
                }}
              >
                Whoops!
              </Text>
              <Text
                style={{
                  fontSize: helpers.isDesktop() ? 24 : 16,
                  lineHeight: helpers.isDesktop() ? 30 : 24,
                  marginBottom: 10,
                  textAlign: "center",
                  fontFamily: FontFamily.regular,
                  color: darkMode ? Colors.lineColor : "rgba(0, 0, 0, 0.7)",
                }}
              >
                We couldn’t find a match for "{this.state.searchText}"
              </Text>
              <Text
                style={{
                  fontSize: helpers.isDesktop() ? 24 : 16,
                  lineHeight: helpers.isDesktop() ? 30 : 24,
                  marginBottom: 10,
                  textAlign: "center",
                  fontFamily: FontFamily.regular,
                  color: darkMode ? Colors.lineColor : "rgba(0, 0, 0, 0.7)",
                }}
              >
                Please try another search.
              </Text>
            </View>
          )}
        </View>
      </>
    );
  }

  renderNewsletterEditions() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View
          style={[
            Flex.alignCenter,
            Margin.mb_4,
            { marginTop: helpers.isDesktop() ? 70 : 40 },
          ]}
        >
          <View
            style={[
              Padding.ph_2,
              Helpers.titleBgWrap,
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            <View
              style={[
                Helpers.borderBottom,
                darkMode && { borderBottomColor: Colors.lineColor },
              ]}
            >
              <Text
                style={[
                  Helpers.textCenter,
                  Typo.h3_R,
                  helpers.isDesktop() && Typo.h3_RDesktop,
                  Margin.mb_0,
                  { fontSize: helpers.isDesktop() ? 34 : 18 },
                  { fontFamily: FontFamily.abrilFatface },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Newsletter Editions
              </Text>
            </View>
          </View>
          <View
            style={[
              Helpers.titleLine,
              {
                backgroundColor: darkBlue
                  ? Colors.lineColor
                  : "rgba(0, 0, 0, 0.6)",
              },
            ]}
          ></View>
        </View>
      </>
    );
  }

  renderRecommendedReading(recomendedStories) {
    let btnClickCategory;
    let webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (recomendedStories && recomendedStories.length > 0) {
      return (
        <View style={[helpers.isDesktop() ? Padding.ph_0 : Padding.ph_2]}>
          <View
            style={[
              Flex.alignCenter,
              Margin.mb_4,
              { marginTop: helpers.isDesktop() ? 70 : 40 },
              !helpers.isDesktop() && { marginLeft: -20, marginRight: -20 },
            ]}
          >
            <View
              style={[
                Padding.ph_2,
                Helpers.titleBgWrap,
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  {
                    borderBottomWidth: helpers.isDesktop() ? 2 : 0,
                  },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontSize: 34, lineHeight: 51, paddingBottom: 4 },
                    { fontFamily: FontFamily.abrilFatface },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Recommended Reading
                </Text>
              </View>
            </View>
            <View
              style={[
                helpers.isDesktop() && Helpers.titleLine,
                {
                  backgroundColor: darkMode
                    ? Colors.lineColor
                    : "rgba(0, 0, 0, 0.6)",
                },
              ]}
            ></View>
          </View>
          {helpers.isDesktop() ? (
            <View style={[Flex.row, Flex.justfiyStart]}>
              {recomendedStories.map((item, index) => {
                if (helpers.isWeb()) {
                  btnClickCategory = {
                    to: "/category/" + item.category.slug,
                  };
                } else {
                  btnClickCategory = {
                    onPress: () => this.viewCategory(item.category.slug),
                  };
                }
                return (
                  <View
                    style={[
                      articleItem.col3,
                      helpers.isDesktop() && articleItem.col3Desktop,
                      //   { marginRight: 20 },
                      { width: helpers.isDesktop() ? "33.3%" : "100%" },
                    ]}
                  >
                    <TouchableOpacityLink
                      {...btnClickCategory}
                      handleClick={() =>
                        this.trackCateoryClick(
                          item,
                          "starter collection recommended stories"
                        )
                      }
                    >
                      <View>
                        <Text
                          style={[
                            articleLists.badge,
                            {
                              width: 150,
                              marginBottom: 8,
                              textAlign: "left",
                              fontSize: helpers.isDesktop() ? 19 : 13,
                              lineHeight: helpers.isDesktop() ? 22.8 : 15.6,
                              letterSpacing: 1,
                              fontFamily: FontFamily.regular,
                              color:
                                item?.category?.name === "Business"
                                  ? "#2CB680"
                                  : item?.category?.name === "Internet"
                                    ? "#FC876D"
                                    : "#907cff",
                                fontFamily: "HaasGrotesk-Medium",
                                fontWeight: 500,
                            },
                            !helpers.isWeb() && {
                              fontFamily: FontFamily.semiBold,
                            },
                          ]}
                        >
                          {item.category.name}
                        </Text>
                      </View>
                    </TouchableOpacityLink>
                    <ArticleItemHorizontalLayout
                      viewArticle={(catSlug, slug, postData) =>
                        this.viewArticle(catSlug, slug, postData)
                      }
                      viewAuthor={(slug) => this.viewAuthor(slug)}
                      data={item}
                      single={index == 0 ? true : false}
                      homepage={true}
                      darkMode={darkMode}
                      sectionName="Starter collection"
                    />
                  </View>
                );
              })}
            </View>
          ) : (
            <ScrollContainerView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
            >
              <View
                style={[
                  Flex.row,
                  Margin.mb_4,
                  !process.browser && { overflow: "hidden" },
                ]}
              >
                {recomendedStories.map((item, index) => {
                  if (helpers.isWeb()) {
                    btnClickCategory = {
                      to: "/category/" + item.category.slug,
                    };
                  } else {
                    btnClickCategory = {
                      onPress: () => this.viewCategory(item.category.slug),
                    };
                  }
                  return (
                    <View
                      style={[
                        articleItem.col3,
                        helpers.isDesktop() && articleItem.col3Desktop,
                        !helpers.isDesktop() && { marginTop: 50 },
                        !webView && { width: windowWidth - 100 },
                      ]}
                    >
                      <TouchableOpacityLink
                        {...btnClickCategory}
                        handleClick={() =>
                          this.trackCateoryClick(
                            item,
                            "starter collection recommended stories"
                          )
                        }
                      >
                        <View>
                          <Text
                            style={[
                              articleLists.badge,
                              {
                                width: 150,
                                marginBottom: 15,
                                textAlign: "left",
                                fontFamily: FontFamily.regular,
                                color: darkMode ? Colors.darkBlue : "#907cff",
                              },
                              !helpers.isWeb() && {
                                fontFamily: FontFamily.semiBold,
                              },
                            ]}
                          >
                            {item.category.name}
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                      <ArticleItemHorizontalLayout
                        viewArticle={(catSlug, slug, postData) =>
                          this.viewArticle(catSlug, slug, postData)
                        }
                        viewAuthor={(slug) => this.viewAuthor(slug)}
                        data={item}
                        single={index == 0 ? true : false}
                        homepage={true}
                        darkMode={darkMode}
                        sectionName="Starter collection"
                      />
                    </View>
                  );
                })}
              </View>
            </ScrollContainerView>
          )}
        </View>
      );
    }
  }

  render() {
    return <View></View>;
  }

  renderPageContent = () => {
    const { recomendedStories, otherStories } = this.state;
    return (
      <>
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          {this.renderCredits()}
          {recomendedStories && recomendedStories.length > 0 && (
            <View style={{ marginBottom: helpers.isDesktop() ? 120 : 50 }}>
              {this.renderRecommendedReading(recomendedStories)}
            </View>
          )}
          {otherStories !== false && (
            <View style={{ marginBottom: helpers.isDesktop() ? 120 : 50 }}>
              {this.renderStories(otherStories)}
            </View>
          )}
          {/*<View style={{ marginBottom: helpers.isDesktop() ? 120 : 50 }}>
                        {this.renderNewsletterEditions()}
                    </View>*/}
        </View>
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const {
      pageLoader,
      showErrorModal,
      showUparrow,
      footerData,
      collectionType,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/" + collectionType + "-collection",
      native: {
        redirect: collectionType + "-collection",
        verifyRedirect: "/" + collectionType + "-collection",
      },
    };
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const {
      pageLoader,
      showErrorModal,
      showUparrow,
      footerData,
      collectionType,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: collectionType === "starter" ? "free-reads" : "/" + collectionType + "-collection",
      native: {
        redirect: collectionType + "-collection",
        verifyRedirect: "/" + collectionType + "-collection",
      },
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj[collectionType + "collection"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page={"/" + collectionType + "-collection"}
        />
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          setCredits={(credits) => this.setState({ credits: credits })}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
                ? this.renderWebPage()
                : this.renderAppPage()}
            {showUparrow && (
              <View
                style={{
                  position: webView ? "fixed" : "absolute",
                  bottom: 20,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.scrollToTop()}
                  activeOpacity={0.8}
                  style={{ paddingHorizontal: 10, paddingVertical: 10 }}
                >
                  <CustomImage
                    source={upArrowIcon}
                    require={true}
                    style={{ width: 60, height: 60 }}
                    webStyle={{ width: 60, height: 60 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </>
        )}

        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(UnlockCollection));

let Breadcrumbs = [];
