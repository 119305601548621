import React, { Component } from 'react';
import { Text, View, TextInput, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, FontFamily, pageContent, subscribePlans, button, FontWeight, input, Colors, Messages, Typo } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';

const checkIcon = getImagePath('icons/check.png');

class ReadyToSubscribe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailError: false
        }
    }

    signUp = () => {
        const { email } = this.state;
        if (helpers.validateEmail(email)) {
            this.props.goToSignUp(email)
        }
        else {
            this.setState({ emailError: "Please enter a valid email" });
        }
    }

    render() {
        const { plan, subscribeToPlan, country, showSignUp, singlePost, darkMode } = this.props;
        let { email, emailError } = this.state
        return (
            <View style={[!helpers.isDesktop() && Padding.ph_0, !helpers.isDesktop() && { marginVertical: 25 }]}>
                {
                    helpers.isDesktop() ?
                        <View style={[Margin.mb_2, Flex.row, Flex.justfiyCenter, { width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                            <View>
                                <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, Helpers.textCenter, { fontSize: helpers.isDesktop() ? 34 : 20, paddingBottom: 8, marginRight: 0 }, darkMode && { color: Colors.lightWhite }]}>Not Ready to subscribe yet?</Text>
                                <PageBreak style={{ width: '91%', marginHorizontal: 'auto' }} darkMode={darkMode} />
                            </View>
                        </View>
                        :
                        <View style={[Flex.alignCenter, Margin.mb_3, singlePost && { marginHorizontal: -20 }]}>
                            <View style={[Padding.ph_2, Helpers.titleBgWrap, singlePost && { backgroundColor: '#fafafa' }, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: 'rgba(255, 255, 255, 0.6)' }]}>
                                    <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 20 }, { fontFamily: FontFamily.abrilFatface, marginBottom: 1, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Not Ready to subscribe yet?</Text>
                                </View>
                            </View>
                            <View style={[Helpers.titleLine, { backgroundColor: 'rgba(0, 0, 0, 0.6)' }, darkMode && { backgroundColor: 'rgba(255, 255, 255, 0.6)' }]}></View>
                        </View>
                }


                <Text style={[pageContent.text, helpers.isDesktop() && Margin.mb_4, Helpers.textCenter, { fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: helpers.isDesktop() ? 24 : 20, color: '#000' }, !singlePost && !helpers.isDesktop() && { paddingHorizontal: 18 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Buy privilege credits and unlock stories you want to read</Text>

                <View style={[{ borderWidth: 2, borderColor: '#FF9302', backgroundColor: '#ffffff', paddingHorizontal: helpers.isDesktop() ? 30 : 25, paddingVertical: 35, maxWidth: helpers.isDesktop() ? 800 : '100%', marginLeft: helpers.isDesktop() ? 'auto' : 20, marginRight: helpers.isDesktop() ? 'auto' : 20 }, singlePost && { marginLeft: helpers.isDesktop() ? 'auto' : 0, marginRight: helpers.isDesktop() ? 'auto' : 0 }, darkMode && { backgroundColor: Colors.darkBlackColor }]}>
                    {Platform.OS != 'ios' &&
                        <View style={[helpers.isDesktop() && Flex.row, Margin.mb_3]}>
                            <Text style={[{ fontSize: helpers.isDesktop() ? 30 : 20, lineHeight: helpers.isDesktop() ? 45 : 25, fontFamily: FontFamily.abrilFatface, textAlign: helpers.isDesktop() ? 'left' : 'center', color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>{plan[0].invoice_name} for</Text>
                            <Text style={[subscribePlans.planPrice, { marginTop: 0, textAlign: helpers.isDesktop() ? 'left' : 'center', fontWeight: '600', fontSize: helpers.isDesktop() ? 42 : 36 }, !helpers.isWeb() && {fontFamily: FontFamily.bold}]}>
                                <Text style={[subscribePlans.symbol, { fontSize: helpers.isDesktop() ? 42 : 36, lineHeight: helpers.isDesktop() ? 50 : 43, marginLeft: helpers.isDesktop() ? 15 : 0, fontWeight: '400' }]}>{plan[0].currency_code == 'INR' ? '₹' : '$'}</Text>{plan[0].discount.offer_price ? plan[0].discount.offer_price : plan[0].price}</Text>
                        </View>
                    }
                    <View style={{ paddingRight: helpers.isDesktop() ? 200 : 0 }}>
                        {plan[0].description.access_desc.map((item, index) => {
                            return (
                                <View style={[Margin.mt_1, Margin.mb_0, (index == 0) && { marginTop: 0 }]}>
                                    <View style={[Flex.row, { alignItems: 'flex-start' }]}>
                                        <CustomImage
                                            source={checkIcon}
                                            require={true}
                                            style={{ width: 20, height: 14, resizeMode: 'contain', marginTop: 3 }}
                                            webStyle={{ width: 20, height: 14, objectFit: 'contain', marginTop: 3 }}
                                        /><Text style={[subscribePlans.planDesc, helpers.isDesktop() && { fontSize: 18, lineHeight: 27 }, Margin.ml_1, { textAlign: 'left', marginBottom: 0, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>{item}</Text>
                                    </View>
                                </View>
                            )
                        })
                        }
                    </View>
                    {showSignUp ?
                        <View style={{ marginTop: helpers.isDesktop() ? 30 : 25, maxWidth: 700, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <View style={[Margin.mt_3, helpers.isDesktop() && Flex.row, !helpers.isDesktop() && { width: '100%' }]}>
                                <TextInput
                                    placeholder={'Email Id'}
                                    placeholderTextColor={Colors.placeholderTextColor}
                                    style={[input.Text, Padding.pt_1, Padding.pl_15, Padding.pb_1, !helpers.isDesktop() && Margin.mb_2, helpers.isDesktop() && { marginBottom: 6 }, { backgroundColor: 'white', height: 46, borderColor: '#6C7AF6', borderWidth: 1, width: helpers.isDesktop() ? 400 : '100%', flex: 1, fontSize: helpers.isDesktop() ? 18 : 13 }, !helpers.isDesktop() && { paddingTop: 12, paddingBottom: 12 }]}
                                    onChangeText={(value) => this.setState({ email: value, emailError: false })}
                                    value={email}
                                />
                                <ButtonGradient
                                    title={'SIGN UP'}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradientBtn, { height: helpers.isDesktop() ? 46 : 45, borderRadius: 0, flex: 1, position: 'relative', right: helpers.isDesktop() ? 1.5 : 0 }],
                                        btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 0 : 11.5, paddingBottom: helpers.isDesktop() ? 0 : 11.5, fontWeight: '400', fontFamily: FontFamily.regular }]
                                    }}
                                    smallBtn={true}
                                    onClick={this.signUp}
                                    darkMode={darkMode}
                                // disable={!email}
                                />
                            </View>
                            {emailError &&
                                <View style={[{ marginTop: 5 }]}>
                                    <Text style={[Messages.errorText]}>{emailError}</Text>
                                </View>
                            }
                        </View>
                        :
                        <>
                            {Platform.OS != 'ios' &&
                                <View style={[Margin.mt_4, { width: helpers.isDesktop() ? 202.4 : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                                    <ButtonGradient
                                        title={'BUY NOW'}
                                        disable={false}
                                        loader={false}
                                        rounded={true}
                                        fullWidth={true}
                                        rootStyle={{
                                            btnWrapper: [button.primaryGradient, { height: helpers.isDesktop() ? 55 : 46 }],
                                            btnText: [button.primaryGradientText, { fontWeight: '400', fontSize: helpers.isDesktop() ? 17 : 16 }],
                                        }}
                                        onClick={() => subscribeToPlan('credits', plan[0], country)}
                                        darkMode={darkMode}
                                    />
                                </View>
                            }
                        </>
                    }
                </View>
            </View>
        )
    }
}

export default ReadyToSubscribe;
