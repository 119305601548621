import React, { useState, useEffect } from "react";
import { withRouter} from "react-router-dom";
import Loader from "../../native/components/loader/index.js";
import { View } from "react-native";


class LogoutUserSession extends React.Component {
    componentDidMount(){
      const logoutURL = process.env.OATH2_LOGOUT_URL
      window.location.assign(`${logoutURL}?next=${window.location.origin}`)
    }

    render(){
      return <>
            <View style={{ flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%' }}>
                <Loader title={"logging out ..."}/>
            </ View>
      </>
    }
  }

export default withRouter(LogoutUserSession)