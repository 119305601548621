import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, FontFamily, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import { Mixpanel } from '../../utils/mixpanel';

const editorial = getImagePath('img/editorial-code.png');
const leadership = getImagePath('img/leadership.png');
const investor = getImagePath('img/investor.png');
const editorialMobile = getImagePath('img/editorial-mob.png');
const leadershipMobile = getImagePath('img/leadership-mob.png');
const investorMobile = getImagePath('img/investor-mob.png');

const editorialDark = getImagePath('img/editorialCodeDarkDesktop.png');
const editorialDarkMobile = getImagePath('img/editorialCodeDarkMobile.png');
const leadershipDark = getImagePath('img/leadershipDarkDesktop.png');
const leadershipDarkMobile = getImagePath('img/leadershipDarkMobile.png');
const investorDark = getImagePath('img/investorsDarkDesktop.png');
const investorDarkMobile = getImagePath('img/investorsDarkMobile.png');

const windowWidth = Dimensions.get('window').width;
export class ExploreAboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    exploreMoreClick = (from_page, to_page) => {
        // try {
        //     Mixpanel.track('click_aboutus_explore_more', {'sub_pagename' : to_page});
        // }
        // catch(error) {
        //     console.log("mix pannel error ==>", error);
        // }
    }

    render() {
        const { darkMode } = this.props;
        let webView = helpers.isWeb();
        let leadershipProp, investorsProp, editorialProp;
        if (webView) {
            leadershipProp = {
                to: '/leadership'
            }

            investorsProp = {
                to: '/investors'
            }

            editorialProp = {
                to: '/editorial-code-of-conduct'
            }
        }
        else {
            leadershipProp = {
                onPress: () => this.props.navigateTo('/leadership')
            }

            investorsProp = {
                onPress: () => this.props.navigateTo('/investors')
            }

            editorialProp = {
                onPress: () => this.props.navigateTo('/editorial-code-of-conduct')
            }
        }
        return (
            <>
                <View style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter]}>
                    <View style={[!helpers.isDesktop() && { backgroundColor: 'white', zIndex: 1, paddingHorizontal: 20 }, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                        <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }, darkMode && { borderBottomColor: Colors.lineColor }]}>
                            <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, { paddingBottom: 5 }, helpers.isDesktop() && { marginRight: 0 }, darkMode && { color: Colors.lightWhite}]}>Explore more about us</Text>
                        </View>
                    </View>
                    {!helpers.isDesktop() && <View style={[Helpers.titleLine, darkMode && {backgroundColor: Colors.lineColor}]}></View>}
                    {helpers.isDesktop() && <View style={{ width: '100%', flex: 1 }}>
                        <View style={[Helpers.titleLineLight, darkMode && {backgroundColor: Colors.lineColor}]}></View>
                    </View>}
                </View>
                <View style={[Flex.column, helpers.isDesktop() && Flex.row, Margin.mb_6, !helpers.isDesktop() && { alignItems: 'center', }]}>
                    <TouchableOpacityLink style={{ paddingLeft: helpers.isDesktop() ? 0 : 18, paddingRight: helpers.isDesktop() ? 0 : 18, position: 'relative', marginRight: helpers.isDesktop() ? 40 : 0 }} {...leadershipProp} handleClick={(from, to) => this.exploreMoreClick(from, to)}>
                        <Text style={[{ position: 'absolute', top: helpers.isDesktop() ? 20 : 36, left: helpers.isDesktop() ? 24 : 36, lineHeight: helpers.isDesktop() ? 36 : 30, fontSize: helpers.isDesktop() ? 24 : 18, fontFamily: FontFamily.medium, fontWeight: '500', letterSpacing: 0.3 }, !webView && { zIndex: 9, left: 60 }]}>Leadership</Text>
                        {helpers.isDesktop() ? <CustomImage
                            source={darkMode ? leadershipDark :  leadership}
                            require={true}
                            style={{ width: '100%', height: 'auto', resizeMode: 'contain', marginBottom: 30 }}
                            webStyle={{ width: '100%', height: 'auto', objectFit: 'contain', marginBottom: 30 }}
                            altText={'Leadership'}
                        />
                            :
                            <CustomImage
                                source={darkMode ? leadershipDarkMobile : leadershipMobile}
                                require={true}
                                style={{ width: windowWidth-30, height: 250, resizeMode: 'contain', marginBottom: 30 }}
                                webStyle={{ width: '100%', height: '100%', objectFit: 'contain', marginBottom: 20 }}
                                altText={'Leadership'}
                            />
                        }
                    </TouchableOpacityLink>
                    <TouchableOpacityLink style={{ paddingLeft: helpers.isDesktop() ? 0 : 18, paddingRight: helpers.isDesktop() ? 0 : 18, position: 'relative', marginRight: helpers.isDesktop() ? 40 : 0 }} {...investorsProp} handleClick={(from, to) => this.exploreMoreClick(from, to)}>
                        <Text style={[{ position: 'absolute', top: helpers.isDesktop() ? 20 : 36, left: helpers.isDesktop() ? 24 : 36, fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 36 : 30, fontFamily: FontFamily.medium, fontWeight: '500' }, !webView && { zIndex: 9, left: 60 }]}>Investors</Text>
                        {helpers.isDesktop() ? <CustomImage
                            source={darkMode ? investorDark : investor}
                            require={true}
                            style={{ width: '100%', height: 'auto', resizeMode: 'contain', marginBottom: 30 }}
                            webStyle={{ width: '100%', height: 'auto', objectFit: 'contain', marginBottom: 30 }}
                            altText={'Investors'}
                        />
                            :
                            <CustomImage
                                source={darkMode ? investorDarkMobile : investorMobile}
                                require={true}
                                style={{ width: windowWidth-30, height: 250, resizeMode: 'contain', marginBottom: 30 }}
                                webStyle={{ width: '100%', height: '100%', objectFit: 'contain', marginBottom: 20 }}
                                altText={'Investors'}
                            />
                        }
                    </TouchableOpacityLink>
                    <TouchableOpacityLink style={{ paddingLeft: helpers.isDesktop() ? 0 : 18, paddingRight: helpers.isDesktop() ? 0 : 18, position: 'relative' }} {...editorialProp} handleClick={(from, to) => this.exploreMoreClick(from, to)}>
                        <Text style={[{ position: 'absolute', top: helpers.isDesktop() ? 20 : 36, left: helpers.isDesktop() ? 24 : 36, fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 36 : 30, fontFamily: FontFamily.medium, fontWeight: '500' }, !webView && { zIndex: 9, left: 60 }]}>Editorial code</Text>
                        {helpers.isDesktop() ? <CustomImage
                            source={darkMode ? editorialDark : editorial}
                            require={true}
                            style={{ width: '100%', height: 'auto', resizeMode: 'contain', marginBottom: 30 }}
                            webStyle={{ width: '100%', height: 'auto', objectFit: 'contain', marginBottom: 30 }}
                            altText={'Editorial code'}
                        />
                            :
                            <CustomImage
                                source={darkMode ? editorialDarkMobile : editorialMobile}
                                require={true}
                                style={{ width: windowWidth-30, height: 250, resizeMode: 'contain', marginBottom: 30 }}
                                webStyle={{ width: '100%', height: '100%', objectFit: 'contain', marginBottom: 20 }}
                                altText={'Editorial code'}
                            />
                        }
                    </TouchableOpacityLink>
                </View>

            </>
        );
    }
}

export default ExploreAboutUs;