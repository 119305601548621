import { hostedPageParameterManager__Interface, hostedPageSettings, customhostedPageSettings, chargebeeHostedPage__Interface, hostedPageHandleSettings, HostedPageCallbacks } from "./interface";
import { CHARGEBEE_SETTINGS } from '../settings'
import { chargebeeInstance__Interface } from '../cb-instance'
import { deepCopyObject, syncObject } from '../helper';
import { HTTP_Fetch } from '../api_fetch'
import _ from 'lodash'
import { EventEmitter } from "events"
import { Dictionary } from "../global-types";


export declare const Chargebee, window;




export abstract class hostedPageParameterManager implements hostedPageParameterManager__Interface{
    settingsMap: any
    status: "initialized" | "not-initialized" = 'not-initialized'
    constructor(params: any){
        this.settingsMap = params
        this.status = 'initialized'
    }
    getParam(param: string): any{
        return _.get(this.settingsMap, param)
    }

    customSettings(newSettings: any): boolean {
        try{
            this.settingsMap = syncObject(this.settingsMap || {}, newSettings)
        }
        catch(err){
            console.log('chargebeeHandler:hostedPageParameterManager:customSettings:Error: ', err)
            return false
        }
        return true
    }
}


export abstract class chargebeeHostedPage extends EventEmitter implements chargebeeHostedPage__Interface {

    chargebeeInstance: chargebeeInstance__Interface
    settingsMap: hostedPageHandleSettings
    parameterHandle?: hostedPageParameterManager__Interface


    constructor(chargebeeInstance: chargebeeInstance__Interface){
        super();
        this.chargebeeInstance = chargebeeInstance
        this.settingsMap = {
            HOSTED_OBJECT_ENDPOINT: CHARGEBEE_SETTINGS.HOSTED_OBJECT_ENDPOINT,
            SUCCESS_REDIRECT_PATH: CHARGEBEE_SETTINGS.HOSTED_OBJECT_REDIRECT,
            REDIRECT_PARAM: CHARGEBEE_SETTINGS.HOSTED_OBJECT_REDIRECT_PARAMETER,
        }

    }

    initParam(params: any): this{
        return this
    }
   
   setParam(params: any): this {
       if(!this.parameterHandle){
        throw new Error('Error: attribute chargebeeHostedPage::parameterHandle not initialized')
       }
       this.parameterHandle.customSettings(params)
       return this
   }

   fetchHostedObject(params: any): Promise<any> {
        return HTTP_Fetch(this.settingsMap.HOSTED_OBJECT_ENDPOINT, params, 'POST', true, this.chargebeeInstance.tmcAuthToken)
   }

   _getWindowUpdateCallback(requestParams: any): HostedPageCallbacks {
        let _updateWindow:HostedPageCallbacks = {
            hostedPage: [
                () => {
                    try {
                        let plan_id = requestParams.params.subscription.plan_id
                        let quantity = requestParams.params.subscription.plan_quantity
                        let newurl = `${window.location.pathname}?subscribe=${plan_id}&quantity=${quantity}`
                        window.history.pushState({path: newurl}, '', newurl)

                    }
                    catch(err){
                        console.log('CB hosted page err(URL params could not be removed): ', err)
                    }
                }
            ],
            close: [
                () => {
                    console.log('chargebee: close:updateWindowCB')
                    let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
                    window.history.pushState({path: newurl}, '', newurl)
                }
            ]
        }

        return _updateWindow
   }

    _defaultCallbacks: HostedPageCallbacks = {
        success: [
            (hostedPageId) => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                this.chargebeeInstance.instance.closeAll()
                window.location.href = `${this.settingsMap.SUCCESS_REDIRECT_PATH}?${this.settingsMap.REDIRECT_PARAM}=${hostedPageId}`
            }
        ]
    }

    _mergeCallbacks(cb1: HostedPageCallbacks, cb2:HostedPageCallbacks): HostedPageCallbacks{
        let customCallbacks: HostedPageCallbacks= {
            ...cb1
        }
        for(const [ action, callbacks ] of Object.entries(cb2)){
            if(customCallbacks[action]){
                customCallbacks[action] = [...customCallbacks[action], ...callbacks]
            }
            else{
                customCallbacks[action] = callbacks
            }
        }

        return customCallbacks
    }

    _updateWindowCallbacks(requestParams: any, callbacks: HostedPageCallbacks): HostedPageCallbacks {
        let customCallbacks: HostedPageCallbacks= {
            ...callbacks
        }
        for(const [ action, callbacks ] of Object.entries(this._getWindowUpdateCallback(requestParams))){
            if(customCallbacks[action]){
                customCallbacks[action] = [...customCallbacks[action], ...callbacks]
            }
            else{
                customCallbacks[action] = callbacks
            }
        }

        return customCallbacks
    }

   handleCallbacks(params: any, customCallBacks: HostedPageCallbacks = {}): any {

        let cb = {}
        for(const [ action, callbacks ] of Object.entries(this._mergeCallbacks(customCallBacks, this._defaultCallbacks))){
            cb[action] = (...args: any[]) => {
                this.emit(action, ...args)
                callbacks.forEach(CB => {
                    console.group('chargebee: callbacks started')
                    try{
                        CB(...args)
                    }
                    catch(err){
                        console.log(`chargebeeController:hosted_page:${action}:callbackError: `, err)
                    }
                });
            }
        }
        let cb2 = {}
        cb2 = deepCopyObject(cb, cb2)

        let cbH = (...args: any[]) => {
            if(cb['hostedPage']){
                cb['hostedPage'](...args)
            }
            return this.fetchHostedObject(params)
        }
        cb2['hostedPage'] = cbH
        return cb2
   }

   close(): boolean {
        this.emit('close')
        this.chargebeeInstance.instance.closeAll()
        return true
   }
}
