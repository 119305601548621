import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableOpacity } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers,  article,  articleItem, articleLists, popularStories, featuredArticle, Typo, FontFamily, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';

const readMore = getImagePath('icons/arrow-right-orange.png');

export class FeaturedJournalist extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        let {data, viewAuthor, darkMode} = this.props;
       
        return (

            <View style={{ paddingTop: helpers.isDesktop() ? 125 : 10, paddingBottom: 40, width: '100%' }}>
                <View style={[article.headerWrapper,]}>
                    <View style={[!helpers.isDesktop() && Flex.alignCenter, !helpers.isDesktop() && Margin.mb_5, helpers.isDesktop() && Margin.mb_4]}>
                        <View style={[Padding.ph_2, !helpers.isDesktop() && Helpers.titleBgWrap, darkMode && {backgroundColor: Colors.darkBlackColorBg},{backgroundColor:this.props.darkMode?'#121212':"white"}]}>
                            <View style={[darkMode && {borderBottomColor: Colors.lineColor}]}>
                                <Text style={[!helpers.isDesktop() && Helpers.textCenter, helpers.isDesktop() && Helpers.textUppercase, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0,  {fontSize: 18, color: '#000'}, {fontSize: helpers.isDesktop() ? 26 : 20}, !helpers.isDesktop() && {fontFamily: FontFamily.abrilFatface} , !helpers.isWeb() && {marginBottom: 1,}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>Featured Journalists</Text>
                            </View>
                        </View>
                        {/* <View style={[!helpers.isDesktop() && Helpers.titleLine, darkMode && {backgroundColor: Colors.lineColor}]}></View> */}
                    </View>                
                </View>
                {data && data.map((author)=>{
                    let btnClickFeaturedAuthor;
                    if (helpers.isWeb()) {
                        btnClickFeaturedAuthor = {
                            to: "/writers/" + author.slug
                        }
                    } else {
                        btnClickFeaturedAuthor = {
                            onPress: () => viewAuthor(author.slug)
                        }
                    }
                    return <TouchableOpacityLink {...btnClickFeaturedAuthor}>
                    <View style={[ Margin.mt_2, Margin.mb_2, Margin.ml_2, Margin.mr_2 ]}>
                        <View style={[Flex.row, Flex.FlexWrap, !helpers.isDesktop() && Flex.alignCenter]}>
                            <View style={[Flex.row, {width: '100%', alignItems: helpers.isDesktop() ? 'center' : 'center'} ]}>
                                <CustomImage
                                    style={{width: 107, height: 107, objectFit:'contain'}}
                                    webStyle={{ width: '107px', height: '107px', objectFit: 'cover' }}
                                    className={'about-author-img max-100'}
                                    source={author.profile_image}
                                    altText={author.name}
                                />
                                <View style={[!helpers.isDesktop() && Flex.column,  Padding.pl_1, !helpers.isDesktop() && Flex.fill]}>
                                    <Text style={[popularStories.heading, helpers.isDesktop() && popularStories.headingDesktop, {fontWeight: '500', textTransform: 'capitalize'}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{author.name}</Text>
                                    {(author.designation!=''||author.location != '') && <View style={[Flex.row, Flex.FlexWrap, {alignItems:'center'}]}>
                                        <Text style={[popularStories.text,{fontSize: helpers.isDesktop() ? 16 : 15, color: '#000'}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{author.designation}</Text>
                                        <View style={[popularStories.dot]}></View>
                                        <Text style={[popularStories.text, {color: '#6a6a6a'},{fontSize: helpers.isDesktop() ? 16 : 15}, darkMode && {color: 'rgba(255,255,255,0.6)'}]}>{author.location}</Text>
                                    </View>}
                                </View>
                            </View>
                            <View style={[Flex.row, {width: '100%'} ]}>
                                <Text style={[popularStories.excerpt, helpers.isDesktop() && popularStories.excerptDesktop, {width: helpers.isDesktop() ? 300 : '100%', fontSize: helpers.isDesktop() ? 16 : 14, textAlign: 'left', flex: 1, flexWrap: 'wrap'}, darkMode && {color: 'rgba(255,255,255,0.87)'}]} numberOfLines={5}>
                                    {author.biography}
                                </Text>
                            </View>
                        </View>
                        {/* <CustomImage
                            source={readMore}
                            style={{ width: 30, height: 17, resizeMode: 'cover', marginTop:10 }}
                            webStyle={{ width: 30, height: 17, objectFit: 'cover', marginTop:10 }}
                        /> */}
                    </View>
                    </TouchableOpacityLink>
                    })
                }
            </View>
        );
    }
}

export default FeaturedJournalist;
