import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, signUpPage, appStyles, Typo, Helpers, article, FontFamily, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";

import Seo from "../../components/seo-meta";
import { setUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import PageBreak from '../../components/page-break';
import getImagePath from '../../utils/image-helper';
import apiHelpers from "../../utils/api-helpers";
import seoObj from "../../utils/seo-helper";

const cookies = new Cookies();

const inboxIcon = getImagePath('icons/emailInbox.png');
const silverIcon = getImagePath('icons/silver-coin.png');

export class SignUpSuccess extends Component {
    constructor(props) {
        super(props);
        let email = null, redirectUrl = '/';
        let webView = helpers.isWeb();
        if (webView && process.browser && this.props.email) {
            email = this.props.email;
            redirectUrl = this.props.redirect_url
        } 
        else if (!webView) {
           email = (this.props.route.params.email) ? this.props.route.params.email : null;
           redirectUrl = (this.props.route.params.redirect_url) ? this.props.route.params.redirect_url : null;
        }

        this.state = {
            email: email,
            redirectUrl : redirectUrl
        }
    }

    navigateToScreen = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen(slug);
        } else {
            this.props.navigation.replace(slug);
        }
    }
    
    resendVerificationLink = () => {
        this.setState({
            enableBtnLoader: true,
            error: '',
        });
        const { email, redirectUrl } = this.state;
        let endpoint = `/resend-verification-link`;
        let body = {
            account_email: email,
            redirect_url: redirectUrl
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'verification_token') {
                    this.setState({ enableBtnLoader: false });
                } 
                else {
                    this.setState({
                        enableBtnLoader: false,
                        error: res.message,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    enableBtnLoader: false,
                    error: '',
                });
                console.log('Error=>>', error);
            });
    }

    render() {
        const { email, enableBtnLoader } = this.state;
        let webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }

        let data = {
            pageName : 'Sign Up Suceess'
        }

        return (
            <>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />
                {(webView && process.browser && (cookies.get("token") != undefined || email == undefined)) && <Redirect to="/" />}
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} showFullHeader={true}  />
                {(!webView || process.browser) && <ScrollView contentContainerStyle={[appStyles.appContainer, helpers.isDesktop() && appStyles.appContainerDesktop], { backgroundColor: '#F6F6F6' }}>
                    <View style={{ maxWidth: helpers.isDesktop() ? 880 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                        <View style={[Helpers.bg_white, Margin.mb_4, { marginTop: helpers.isDesktop() ? 150 : 100 }, { paddingVertical: helpers.isDesktop() ? 70 : 35, paddingHorizontal: 20 }, {marginHorizontal: 15}]}>
                            <View style={[article.headerWrapper]}>
                                <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_4, !helpers.isWeb() && Margin.mb_2]}>
                                    <View style={[Padding.ph_2, Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)', paddingBottom: 4 }]}>
                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 20 }, webView && { textTransform: 'regular' }]}>Please verify your email</Text>
                                    </View>
                                    <PageBreak size={'small'} />
                                </View>
                            </View>
                            <View style={[Flex.alignCenter, Margin.mb_3]}>
                                <CustomImage
                                    source={inboxIcon}
                                    require={true}
                                    style={{ width: 95, height: 86, resizeMode: 'contain' }}
                                    webStyle={{ width: 'auto', height: 86 }}
                                />
                            </View>
                            <View style={{ maxWidth: helpers.isDesktop() ? 300 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 40 }}>
                                <Text style={{ fontFamily: FontFamily.light, fontWeight: FontWeight.light, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 28 : 26, letterSpacing: 0.3, textAlign:  helpers.isDesktop() ?  'left' : 'center' }}>Click on the <Text style={{ fontWeight: FontWeight.bold, fontFamily: FontFamily.bold }}>“verify”</Text> button in the email we’ve sent you to get started.</Text>
                            </View>
                            <View style={{ maxWidth: helpers.isDesktop() ? 300 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 40, borderWidth: 1, borderColor: '#D8D8D8', paddingHorizontal: 15, paddingVertical: 15, borderRadius: 10 }}>
                                <View style={[Flex.row, Flex.alignCenter]}>
                                    <CustomImage
                                        source={silverIcon}
                                        require={true}
                                        style={{ width: 60, height: 60, marginRight: 13 }}
                                        webStyle={{ width: 60, height: 60, marginRight: 13 }}
                                        altText={'Credits'}
                                    />
                                    <Text style={{ fontSize: 14, lineHeight: 18, fontFamily: FontFamily.regular, letterSpacing: 0.5, flex: 1 }}>Earn {helpers.starterCredits} Starter credits when you verify your email!</Text>
                                </View>
                            </View>
                            <View style={[Margin.mb_1]}>
                                <Text style={{ textAlign: 'center', fontSize: 14, lineHeight: 22, fontFamily: FontFamily.regular, letterSpacing: 0.3 }}>Did not recieve an email?</Text>
                                {/* <Text style={{ textAlign: 'center', fontSize: 14, lineHeight: 22, fontFamily: FontFamily.regular, letterSpacing: 0.3 }}>Try resending in <Text style={{ fontWeight: FontWeight.bold, marginBottom: 10 }}>60</Text> Seconds</Text> */}
                            </View>
                            {enableBtnLoader ? 
                                <View style={[Flex.row, Flex.justfiyCenter]}>
                                    <ActivityIndicator size={25} color={'#000000'} />
                                </View>
                            :
                            <View style={[Flex.row, Flex.justfiyCenter]}>
                                <Text style={{ textTransform: 'uppercase', fontSize: 14, fontFamily: FontFamily.regular, fontWeight: FontWeight.regular, lineHeight: 18, borderBottomWidth: 1, borderBottomColor: '#000000' }} onPress={this.resendVerificationLink}>Resend email</Text>
                            </View>
                            }
                        </View>
                        <View style={[Flex.row, Flex.justfiyCenter, {marginBottom: helpers.isDesktop() ? 100 : 30}]}>
                            <Text style={[{fontSize: 14, fontFamily: FontFamily.regular, fontWeight: FontWeight.regular, lineHeight: 18, borderBottomWidth: 1, borderBottomColor: '#000000'}, !helpers.isDesktop() && {maxWidth: '70%', textAlign: 'center'}]} onPress={() => this.navigateToScreen('/')}>Skip and go to TMC homepage</Text>
                        </View>
                    </View>
                    <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
                </ScrollView>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpSuccess);