import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, noteDiscloures, postContentTags, postContentClasses, postTags, Typo } from '../../styles/appStyles';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { helpers } from '../../utils/helpers';

export class NoteDisclosures extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
    }

    render() {
        const { data, title, darkMode } = this.props;
        console.log((data).slice(0))
        return (
            <View>
                <View>
                     <div className={"post-note-discloures" + (darkMode ? " dark-mode" : "")}>
                        {title && <div className="ndTitle">{title}:</div>} {ReactHtmlParser(data)[0]}
                    </div>
                    {/* <div className={"post-note-discloures" + (darkMode ? " dark-mode" : "")}>
                        <Text>
                            {title && <div className="ndTitle">{title}:</div>} {ReactHtmlParser(data)}
                        </Text>
                    </div> */}
                </View>
            </View>
        );
    }
}

export default NoteDisclosures;
