import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, article, articleItem, authorItem, FontFamily, investorCard, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import ReactHtmlParser from 'react-html-parser';

const investorDotsIcon = getImagePath('icons/investorsDots.png');

export class InstitutionalInvestorCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    onClick = () => {

    }

    render() {
        let { investor, darkMode } = this.props;

        return (
            <View style={{ flexDirection: 'row', marginBottom: helpers.isDesktop() ? 120 : 75, flexWrap: 'wrap', textAlign: helpers.isDesktop() ? 'left' : 'center', justifyContent: 'center' }}>
                <View style={[helpers.isDesktop() && Helpers.centerAlign, helpers.isDesktop() && { left: '1%', zIndex: 2 }, { alignItems: 'center' }, { borderWidth: 2, borderColor: '#F9EAEB', marginBottom: helpers.isDesktop() ? 0 : 20 }]}>
                    <CustomImage
                        source={investor.image}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 300 : 199, height: helpers.isDesktop() ? 300 : 199 }}
                        webStyle={{ width: helpers.isDesktop() ? 300 : 199, height: helpers.isDesktop() ? 300 : 199 }}
                        altText={investor.name}
                    />
                </View>
                <View style={{ backgroundColor: helpers.isDesktop() ? (darkMode ? Colors.darkBlackColor : '#F9EAEB') : 'transparent', width: helpers.isDesktop() ? '82%' : '100%', position: 'relative', paddingTop: helpers.isDesktop() ? 100 : 20, paddingBottom: helpers.isDesktop() ? 100 : 20, paddingRight: helpers.isDesktop() ? 40 : 0, paddingLeft: helpers.isDesktop() ? 200 : 0, marginLeft: helpers.isDesktop() ? 70 : 0 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 40 : 30, color: darkMode ? Colors.lightWhite : '#272727', marginBottom: 25, fontFamily: FontFamily.merriweather, textAlign: 'center' }}>{investor.intro}</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 40 : 30, color: darkMode ? Colors.lightWhite : '#272727', marginBottom: 0, fontFamily: FontFamily.merriweather, textAlign: 'center' }}>
                        {ReactHtmlParser(investor.details)}
                    </Text>
                    <CustomImage
                        source={investorDotsIcon}
                        require={true}
                        style={{ width: 80, height: 80, position: "absolute", bottom: -5, right: -5 }}
                        webStyle={{ width: helpers.isDesktop() ? 160 : 80, height: helpers.isDesktop() ? 160 : 80, position: "absolute", bottom: helpers.isDesktop() ? -90 : 0, right: helpers.isDesktop() ? -80 : 0 }}
                    />
                </View>
            </View>
        );
    }
}

export default InstitutionalInvestorCard;