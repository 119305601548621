import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  TextInput,
  TouchableHighlight,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import ScrollContainerView from "../scroll-container-view";
import { CardView } from "react-native-simple-card-view";
import { relativeTimeRounding } from "moment";
import Swiper from "react-native-web-swiper";

// const banner = getImagePath('img/banner-slide.png');
const bannerDark = getImagePath("img/banner-slide-dark.png");
const circleLeft = getImagePath("img/banner-left-circle.png");
const circleRight = getImagePath("img/banner-right-circle.png");
const circleRightDark = getImagePath("img/banner-right-circle-dark.png");
const slideArrowLeft = getImagePath("icons/arrow-left.svg");
const slideArrowRight = getImagePath("icons/arrow-right.svg");

export class SectionTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  render() {
    let webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let { style } = this.props;
    return (
      <>
        <View
          style={[
            article.headerWrapper,
            {
              backgroundColor: this.props?.style?.backgroundColor,
            },
            !helpers.isDesktop() && { left: 0 },
            !helpers.isDesktop() && { right: 0 },
            !helpers.isDesktop() && { top: 0 },
          ]}
        >
          <View
            style={[
              Flex.alignCenter,
              {
                marginBottom: helpers.isDesktop()
                  ? style?.marginBottom
                    ? style.marginBottom
                    : 40
                  : 9,
              },
            ]}
          >
            <View
              style={[
                Padding.ph_2,
                Helpers.titleBgWrap,
                helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColor
                    : this.props?.style?.backgroundColor,
                },
                !helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : this.props?.style?.backgroundColor,
                },
                { marginTop: helpers.isDesktop() ? 20 : 0 },
              ]}
            >
              <View>
                <Text
                  style={[
                    Margin.mt_0,
                    Margin.mb_0,
                    pageCover.title,
                    helpers.isDesktop() && pageCover.titleDesktop,
                    helpers.isDesktop() && { bottom: 15 },
                    Helpers.textCenter,
                    {
                      position: "relative",
                      color: "#292929",
                      fontSize: "34px",
                      lineHeight: "51px",
                      fontFamily: "PlayfairDisplay-Bold",
                      fontWeight: "700",
                      borderBottomWidth: helpers.isDesktop() ? "2px" : 0,
                      borderBottomColor: "#292929",
                      borderStyle: "solid",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {this.props.title}
                </Text>
              </View>
            </View>
            {helpers.isDesktop() && (
              <View
                style={[
                  Helpers.titleLine,
                  darkMode && { backgroundColor: "#F3F5FF" },
                  {
                    maxWidth: "calc(1216px - 32px)",
                    width: "100%",
                  },
                ]}
              ></View>
            )}
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({});

export default SectionTitle;
