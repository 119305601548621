import React, { Component } from 'react'
import { View, ActivityIndicator, StyleSheet, Image, BackHandler, TouchableOpacity } from 'react-native';
import getImagePath from '../../utils/image-helper';
import { modalStyle } from '../../styles/appStyles';
import CustomImage from '../custom-image';

const closeIcon = getImagePath('icons/close-round.png');
export class ImageViewer extends Component {
    backHandler;
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = async () => {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }

    componentWillUnmount() {
        if (this.backHandler) {
            this.backHandler.remove();
        }
    }

    handleBackPress = () => {
        this.props.closeModalNativeModal();
        return true;
    }

    render() {
        let { base64, showCloseBtn } = this.props;
        return (
            <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%', backgroundColor: '#ffffff' }}>
                {/* <ActivityIndicator color='#039b40' size={50} style={{ zIndex: 9 }} />
                <View style={[styles.darkBackground]}></View> */}
                <Image
                    style={styles.imagePreview}
                    source={{ uri: base64 }}

                />

                {showCloseBtn &&
                    <View style={[{ position: 'absolute', right: 0, top: 0, zIndex: 1 }]}>
                        <TouchableOpacity
                            activeOpacity={0.8}
                            style={[modalStyle.closeButton]}
                            onPress={() => this.props.closeModalNativeModal()}
                        >
                            <CustomImage
                                source={closeIcon}
                                style={{ width: 28, height: 28 }}
                                webStyle={{ width: 28, height: 28 }}
                                require={true}
                            />
                        </TouchableOpacity>
                    </View>
                }
            </View>
        )
    }
}


const styles = StyleSheet.create({
    lightBackground: {
        backgroundColor: 'white',
    },
    darkBackground: {
        backgroundColor: 'rgba(0,0, 0, 0.4)',
    },
    imagePreview: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain'
    },
});

export default ImageViewer;