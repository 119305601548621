import React, { Component } from "react";
import { Text, View } from "react-native";
import {
  articleItem,
  articleLists,
  button,
  Flex,
  FontFamily,
  Margin,
  Padding,
  YesterdayModule,
} from "../../styles/appStyles";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import TouchableOpacityLink from "../touchable-opacity-link";
import YesterdayArticleorizontal from "../yesterday-article-horizontal";
import ScrollContainerView from "../scroll-container-view";
const logo = getImagePath("img/logo.png");
const footerLogo = getImagePath("icons/tmc-logo.png");

export class YesterdayDontStopReading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exclusiveContent:
        this.props.homeData && this.props.homeData.exclusiveContent
          ? this.props.homeData.exclusiveContent
          : false,
    };
  }

  componentDidMount = () => {
    if (!this.state.exclusiveContent) {
      this.getExclusiveContent();
    }
  };

  getArchivedStories = (excludeData = []) => {
    this.setState({
      // showErrorModal: false,
      pageLoaderStarter: true,
    });
    let excludeList = excludeData.map((td) => {
      return td.id;
    });
    let endpoint = `/stories`;
    let body = {
      limit: 3,
      // exclude: excludeList,
      // sort_on: "date",
      // sort_by: "desc",
      // filters: [{ key: "single_category", value: true }]
      filters: [
        { key: "is_privileged", value: false },
        // { key: "single_category", value: true },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        console.log("dara:", res.data);
        if (res.success && res.code == "story_found") {
          this.setState({
            archivedStories: res.data.story,
            pageLoader: false,
            pageLoaderStarter: false,
          });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  
  getExclusiveContent = () => {
    this.setState({ showErrorModal: false, pageLoader: true });
    let endpoint = `/stories`;
    let body = {
      limit: 1,
      filters: [
        { key: "is_featured", value: true },
        { key: "single_category", value: true },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            exclusiveContent: res.data.story,
            pageLoader: false,
            archive_days: 0,
          });
          this.getArchivedStories(res.data.story);
        } else {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };
  viewAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };

  viewCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  handleClick = (title, data, index) => {
    try {
      if (this.props.trackStoryClick) {
        this.props.trackStoryClick(title, data, index);
      }
    } catch (error) {
      console.log("check mix panel track error ==>", error);
    }
  };

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "yesterday",
      newsletter_section: "NA",
      CTA_text: "learn more",
      CTA_position: "bottom",
      category_type: "yesterday",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  renderExlusiveContent() {
    let { exclusiveContent } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let btnClickCategory;
    return (
      <View style={{ backgroundColor: "#ffffff" }}>
        <View
          style={[
            helpers.isDesktop() ? Flex.row : Flex.column,
            Flex.alignStart,
            Flex.FlexWrap,
            Flex.justfiyCenter,
            {
              maxWidth: helpers.isDesktop() ? "1216px" : "100%",
              width: "100%",
              margin: "auto",
              marginTop: 30,
              marginBottom: helpers.isDesktop() ? 0 : 2,
            },
          ]}
        >
          <View
            style={[
              helpers.isDesktop() ? Flex.row : Flex.flexColumnReverse,
              Flex.alignStart,
              Flex.justfiyCenter,
              {
                maxWidth: helpers.isDesktop() ? "100%" : "100%",
                width: "100%",
                margin: "auto",
                marginTop: 0,
                marginBottom: 0,
                padding: helpers.isDesktop() ? 16 : 20,
                paddingTop: 0,
                paddingBottom: 0,
              },
            ]}
          >
            {!helpers.isDesktop() && (
              <View
                style={[
                  Flex.row,
                  helpers.isDesktop() ? Flex.justifyEnd : Flex.justifyStart,
                  { width: helpers.isDesktop() ? "20%" : "50%" },
                ]}
              >
                <View
                  style={{
                    width: helpers.isDesktop() ? 167 : 100,
                    marginBottom: 10,
                  }}
                >
                  <CustomImage
                    source={logo}
                    require={true}
                    style={[
                      helpers.isWeb()
                        ? {
                            width: helpers.isDesktop() ? 116 : 96,
                            height: "auto",
                          }
                        : { width: helpers.isDesktop() ? 116 : 96, height: 30 },
                    ]}
                    altText={"The Morning Context"}
                  />
                </View>
              </View>
            )}
            <View style={{ width: helpers.isDesktop() ? "80%" : "90%" }}>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 42 : 24,
                    lineHeight: helpers.isDesktop() ? 50.4 : 31,
                    fontFamily: FontFamily.abrilFatface,
                    color: "#051462",
                    paddingBottom: 15,
                    textAlign: "left",
                  },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                Don’t stop reading
              </Text>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 24 : 16,
                    lineHeight: helpers.isDesktop() ? 35 : 23.5,
                    fontFamily: FontFamily.light,
                    color: "#051462",
                  },
                  {
                    textAlign: "left",
                    marginBottom: helpers.isDesktop() ? 15 : 10,
                  },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                Visit The Morning Context to get more detailed stories that
                matter
              </Text>
            </View>

            {helpers.isDesktop() && (
              <View
                style={[
                  Flex.row,
                  helpers.isDesktop() ? Flex.justifyEnd : Flex.justifyStart,
                  { width: "20%" },
                ]}
              >
                <View
                  style={{
                    width: helpers.isDesktop() ? 167 : 95.4,
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: helpers.isDesktop() ? 50 : 30,
                    }}
                  >
                    <CustomImage
                      source={footerLogo}
                      require={true}
                      style={{
                        width: 25,
                        height: 25,
                        resizeMode: "contain",
                      }}
                      webStyle={{
                        width: 40,
                        height: 40,
                        objectFit: "contain",
                        marginRight: 10,
                      }}
                      altText={"The Morning Context"}
                    />
                    <View style={{ flexDirection: "column", marginLeft: 0 }}>
                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: FontFamily.regular,
                          color: "#0D256C",
                          letterSpacing: "0.05em",
                          lineHeight: "12px",
                        }}
                      >
                        THE
                      </Text>
                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: FontFamily.regular,
                          color: "#0D256C",
                          letterSpacing: "0.05em",
                          lineHeight: "12px",
                        }}
                      >
                        MORNING
                      </Text>
                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: FontFamily.regular,
                          color: "#0D256C",
                          letterSpacing: "0.05em",
                          lineHeight: "12px",
                        }}
                      >
                        CONTEXT
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
        <View style={[Margin.mt_2]}>
          <View
            style={[
              Flex.row,
              Flex.justifyBetween,
              Flex.alignStart,
              {
                maxWidth: helpers.isDesktop() ? "1216px" : "100%",
                width: "100%",
                margin: "auto",
                marginTop: 0,
                marginBottom: 0,
              },
            ]}
          >
            <View
              style={[
                Flex.row,
                Flex.justifyBetween,
                Flex.alignStart,
                {
                  maxWidth: helpers.isDesktop() ? "1216" : "100%",
                  width: "100%",
                  margin: "auto",
                  marginTop: 0,
                  marginBottom: 0,
                },
              ]}
            >
              <ScrollContainerView horizontal={true}>
                <View
                  style={[
                    Flex.row,
                    Margin.mb_4,
                    !process.browser && { overflow: "hidden" },
                  ]}
                >
                  {exclusiveContent &&
                    exclusiveContent.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickCategory = {
                          to: "/category/" + item.category.slug,
                        };
                      } else {
                        btnClickCategory = {
                          onPress: () => this.viewCategory(item.category.slug),
                        };
                      }
                      return (
                        <YesterdayArticleorizontal
                          viewArticle={(catSlug, slug, postData) =>
                            this.viewArticle(catSlug, slug, postData)
                          }
                          viewAuthor={(slug) => this.viewAuthor(slug)}
                          data={item}
                          single={index == 0 ? true : false}
                          exclusiveHome={true}
                          handleClick={(title) => {
                            this.handleClick(title, item, index);
                          }}
                          hideDate={true}
                          darkMode={darkMode}
                          custom_user_id={this.props.custom_user_id}
                        />
                      );
                    })}
                </View>
              </ScrollContainerView>
            </View>
          </View>
        </View>

        <View
          style={[
            Flex.row,
            Flex.alignStart,
            Flex.justfiyCenter,
            { width: "100%" },
          ]}
        >
          <View
            style={[
              {
                paddingLeft: helpers.isDesktop() ? 20 : 0,
                paddingRight: helpers.isDesktop() ? 20 : 0,
                paddingTop: helpers.isDesktop() ? 20 : 0,
                paddingBottom: 20,
                marginTop: helpers.isDesktop() ? "auto" : -40,
                width: helpers.isDesktop() ? 200 : "calc(100% - 40px)",
              },
            ]}
          >
            <ButtonGradient
              title={"LEARN MORE"}
              rootStyle={{
                btnWrapper: [
                  button.primaryGradient,
                  { width: "100%", height: helpers.isDesktop() ? 60 : 40 },
                ],
                btnText: [
                  button.primaryGradientText,
                  { fontSize: 16, fontWeight: "400" },
                ],
              }}
              onClick={() => 
                {
                  this.pushInformationToDataLayer()
                  this.props.navigateWeb("/all-stories")
                }
              }
              darkMode={darkMode}
            />
          </View>
        </View>
      </View>
    );
  }

  render() {
    let webView = helpers.isWeb();
    let { exclusiveContent } = this.state;
    return (
      <View style={[Margin.mt_15, YesterdayModule.boxAuto]}>
        {exclusiveContent && this.renderExlusiveContent()}
      </View>
    );
  }
}

export default YesterdayDontStopReading;
