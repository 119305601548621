import React, { Component } from 'react';
import { Text, View, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, FontFamily, pageContent, subscribePlans, button, FontWeight, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';

const checkIcon = getImagePath('icons/check.png');
const goldenIllustrationIcon = getImagePath('icons/goldenIllustration.png');

class PostSingleNotReady extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { subscribeToPlan, country, plan, credits, subtitle, title, darkMode } = this.props;
        return (
            <View style={[!helpers.isDesktop() && Padding.ph_2]}>
                <View style={[Margin.mb_2, Flex.row, Flex.justfiyCenter]}>
                    <View>
                    <View style={[Helpers.borderBottom, darkMode && {borderBottomColor: Colors.lineColor}]}>
                        <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, Helpers.textCenter, !helpers.isDesktop() && Padding.pb_1,{marginRight: 0, color: '#000'}, darkMode && {color: Colors.lightWhite}]}>{title ? title : `Not ready to subscribe yet?`}</Text>
                    </View>
                    </View>
                </View>
                <Text style={[pageContent.text, helpers.isDesktop() && Margin.mb_6, Helpers.textCenter, { fontSize: helpers.isDesktop() ? 18 : 16 }, {color: darkMode ? Colors.lightWhite : Colors.black}]}>{subtitle ? subtitle : 'Buy privilege credits and unlock stories you want to read'}</Text>

                <View style={{ borderWidth: 2, borderColor: '#FF9302', backgroundColor: darkMode ? Colors.darkBlackColor : '#FFFEF9', paddingHorizontal: helpers.isDesktop() ? 30 : 15, paddingVertical: helpers.isDesktop() ? 50 : 35, maxWidth: 900, marginLeft: 'auto', marginRight: 'auto', width: '100%', flexDirection: helpers.isDesktop() ? 'row' : 'column' }}>
                    <View style={{ alignItems: 'center' }}>
                        <CustomImage
                            source={goldenIllustrationIcon}
                            require={true}
                            style={{ width: helpers.isDesktop() ? 199 : 150, height: helpers.isDesktop() ? 199 : 150, resizeMode: 'contain' }}
                            webStyle={{ width: helpers.isDesktop() ? 199 : 150, height: helpers.isDesktop() ? 199 : 150, objectFit: 'contain' }}
                            altText={'Credits'}
                        />
                        <Text style={{ fontSize: 18, lineHeight: 21, color: darkMode ? Colors.lightWhite : '#000000', fontWeight: FontWeight.medium, fontFamily: FontFamily.medium, marginTop: helpers.isDesktop() ? 25 : 20, marginBottom: helpers.isDesktop() ? 0 : 35, textAlign: helpers.isDesktop() ? 'left' : 'center', borderBottomWidth: 1, borderBottomColor: '#FF9302', paddingBottom: 5 }}>Need <Text style={{ color: '#FFCC00' }}>{credits}</Text> credits to unlock this story</Text>
                    </View>
                    <View style={{ paddingLeft: helpers.isDesktop() ? 60 : 0 }}>
                        {Platform.OS != 'ios' && 
                            <View style={[helpers.isDesktop() && Flex.row, Margin.mb_3]}>
                                <Text style={{ fontSize: helpers.isDesktop() ? 30 : 26, lineHeight: helpers.isDesktop() ? 45 : 35, fontFamily: FontFamily.abrilFatface, textAlign: helpers.isDesktop() ? 'left' : 'center', color: darkMode ? Colors.lightWhite : Colors.black }}>{plan[0].invoice_name} for</Text>
                                <Text style={[subscribePlans.planPrice, { marginTop: 0, textAlign: helpers.isDesktop() ? 'left' : 'center', fontWeight: '400', fontFamily: FontFamily.bold }, !helpers.isDesktop() && subscribePlans.planPriceMobile]}>
                                    <Text style={[subscribePlans.symbol, { marginLeft: helpers.isDesktop() ? 15 : 0, fontWeight: '700' }]}>{plan[0].currency_code == 'INR' ? '₹' : '$'}</Text>{plan[0].discount.offer_price ? plan[0].discount.offer_price : plan[0].price}</Text>
                            </View>
                        }
                        <View style={{ paddingRight: helpers.isDesktop() ? 200 : 0 }}>
                            {plan[0].description.access_desc.map((item, index) => {
                                return (
                                    <View style={[Margin.mt_1, Margin.mb_0, (index == 0) && { marginTop: 0 }]}>
                                        <View style={[Flex.row, { alignItems: 'flex-start' }]}>
                                            <CustomImage
                                                source={checkIcon}
                                                require={true}
                                                style={{ width: 20, height: 14, resizeMode: 'contain', marginTop: 3 }}
                                                webStyle={{ width: 20, height: 14, objectFit: 'contain', marginTop: 3 }}
                                            /><Text style={[subscribePlans.planDesc, helpers.isDesktop() && { fontSize: 18 }, Margin.ml_1, { textAlign: 'left', marginBottom: 0, color: darkMode ? Colors.lightWhite : Colors.black }]}>{item}</Text>
                                        </View>
                                    </View>
                                )
                            })
                            }
                        </View>
                        {Platform.OS != 'ios' && 
                            <View style={[Margin.mt_3]}>
                                <ButtonGradient
                                    title={'BUY NOW'}
                                    disable={false}
                                    loader={false}
                                    rounded={true}
                                    rootStyle={{
                                        btnWrapper: button.primaryGradient,
                                        btnText: [button.primaryGradientText, { fontSize: 16, paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 0 : 15, paddingBottom: helpers.isDesktop() ? 0 : 15, fontWeight: '400', fontFamily: FontFamily.regular }],
                                    }}
                                    leftAligned={true}
                                    onClick={() => subscribeToPlan('credits', plan[0], country)}
                                    darkMode={darkMode}
                                />
                            </View>
                        }
                    </View>
                </View>
            </View>
        )
    }
}

export default PostSingleNotReady;
