import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Dimensions,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  pageCover,
  article,
  FontFamily,
  pageContent,
  tweetsContainer,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import AuthorItem from "../../components/author-item";
import AboutStory from "../../components/about-story";
import ExploreAboutUs from "../../components/explore-aboutus";
import TwitterFeeds from "../../components/twitter-feeds";
import Cookies from "universal-cookie";
import ContentLoader from "react-native-easy-content-loader";
import Seo from "../../components/seo-meta";
import { connect } from "react-redux";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const twitterIcon = getImagePath("icons/twitterIcon.png");
const angleIcon = getImagePath("icons/angle-icon.png");
const cross = getImagePath("icons/cross.png");
const blackDots = getImagePath("icons/black-dots.png");
const pinkDots = getImagePath("icons/pink-dots.png");
const blueDots = getImagePath("icons/blue-dots.png");
const cookies = new Cookies();

const priyaPhoto = getImagePath("img/priya-profile.jpg");
const harveenPhoto = getImagePath("img/harveen-profile.jpg");
const pranavPhoto = getImagePath("img/pranav-profile.jpg");
const pradipPhoto = getImagePath("img/pradip-profile.jpg");
const saifPhoto = getImagePath("img/saif-profile.jpg");
const irshadPhoto = getImagePath("img/irshad-profile.jpg");

const writerData = [
  {
    name: "Priya Bubna",
    biography:
      "Priya is the promoter of Slowform Holdings Ltd, the publisher of The Morning Context. She is a chartered accountant and a graduate of the Shri Ram College of Commerce. Priya specializes in international taxation and transfer pricing, where she has assisted clients on transfer pricing planning, complex advisories, litigation and representation across a broad range of industries. She has previously worked at EY and BMR Advisors.",
    email: "priya@mailtmc.com",
    social_links: {
      twitter: "https://twitter.com/Meanderingways",
      linkedin: "https://www.linkedin.com/in/priya-bubna-b6601bb",
    },
    profile_image: priyaPhoto,
    slug: "priya",
  },
  {
    name: "Harveen Ahluwalia",
    biography:
      "Harveen is a writer and co-founder at The Morning Context. She is a graduate of the Shri Ram College of Commerce, the batch of 2015. Harveen has previously worked at The Ken and Mint. At The Morning Context, Harveen stays on top of all things media: traditional, digital and social. From the workings of The Viral Fever and Netflix India to the chaos in newsrooms and social media across the country, most of her writing is focused on decoding the media business. Occasionally, she also dabbles in the food and beverage sector and retail.",
    email: "harveen@mailtmc.com",
    social_links: {
      twitter: "https://twitter.com/Harveen_A",
      linkedin: "https://www.linkedin.com/in/harveen-ahluwalia",
    },
    profile_image: harveenPhoto,
    slug: "harveen",
  },
  {
    name: "Pranav S",
    biography:
      "Pranav is a commissioning editor and co-founder at The Morning Context. He has previously worked as a writer and editor at The Ken and Mint, and is a graduate of the Asian College of Journalism and the Vellore Institute of Technology. At The Morning Context, he helps manage editorial operations, edits and commissions long-form stories, and writes at the intersections of technology, finance and culture.",
    email: "pranav@mailtmc.com",
    social_links: {
      twitter: "@pranavenstein",
      linkedin: "https://www.linkedin.com/in/pranav-srivilasan",
    },
    profile_image: pranavPhoto,
    slug: "pranav",
  },
  {
    name: "Pradip K. Saha",
    biography:
      "Pradip is a writer and co-founder at The Morning Context. He is a graduate of the Indian Institute of Mass Communication, the batch of 2007. Pradip has previously worked at The Ken as a staff writer, at Mint as an assistant features editor and the Deccan Chronicle as a copy editor. Most of his writing is about making sense of chaos, while he occasionally dabbles in writing on agriculture and education. Some of his best stories have come at the intersection of technology and human endeavour. His subjects include gig economy workers, farmers caught in the crossfire of technology, global warming and parents trapped in the edtech wave.",
    email: "pradip@mailtmc.com",
    social_links: {
      twitter: "@sahaprd",
      linkedin: "https://www.linkedin.com/in/pradip-saha-94980267",
    },
    profile_image: pradipPhoto,
    slug: "pradip",
  },
  {
    name: "Saif Iqbal",
    biography:
      "Saif is a writer and co-founder at The Morning Context. A graduate of IIT Roorkee, Rutgers University and the Indian School of Business, he has worked across technology, strategy and business functions during his career. He has extensive experience in e-commerce, working with companies such as Snapdeal and LG Electronics in India. He currently works at an edtech startup in Canada. At The Morning Context, he focuses on e-commerce and retail and writes the stories that matter.",
    email: "saif@mailtmc.com",
    social_links: {
      twitter: "@theunsaif",
      linkedin: "https://www.linkedin.com/in/saifiqbal",
    },
    profile_image: saifPhoto,
    slug: "saif",
  },
  {
    name: "Irshad Daftari",
    biography:
      "Irshad is growth adviser and co-founder at The Morning Context, working with the team to build subscriptions and execute growth strategies. Having cut his teeth in journalism, he has subsequently worked in digital marketing and product functions with Star, HT Media and NDTV, growing and nurturing audiences upwards of 30 million cumulatively.  He is a graduate of the Indian School of Business.",
    email: null,
    social_links: {
      twitter: "@daftari",
      linkedin: "https://www.linkedin.com/in/irshad-daftari/",
    },
    profile_image: irshadPhoto,
    slug: "irshad",
  },
];
export class About extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let writerData;
    // if(this.props.postData) {
    //     let allWriters = this.segregateWriters(this.props.postData.writerData);
    //     writerData = allWriters['writers'];
    // }
    this.state = {
      // writerData: writerData ? writerData : false,
      // writerDataSSR: writerData ? true : false,
      // pageLoader: writerData ? false : true,
      pageLoader: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      token: token == undefined || token == "" ? false : token,
    };
  }

  componentDidMount = () => {
    // if (!this.state.writerDataSSR) {
    //     // this.getWriters();
    // }
  };

  componentWillUnmount = () => { };

  getScrollOffset = (event) => {
    let scrollCheck;
    if (helpers.isWeb()) {
      scrollCheck = window.pageYOffset;
    } else {
      scrollCheck = event.nativeEvent.contentOffset.y;
    }
    if (scrollCheck > 500) {
      this.setState({
        showUparrow: true,
      });
    } else {
      this.setState({
        showUparrow: false,
      });
    }
  };

  applyRef = (ref) => {
    if (helpers.isWeb()) {
      this.scrollRef = window.addEventListener("scroll", this.getScrollOffset);
    }
  };

  scrollToTop = () => {
    if (helpers.isWeb()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(0);
    }
  };

  scrollToSection = (element) => {
    let offsetPosition =
      this.alphFilterOffset[element] +
      this.alphFilterOffsetMain +
      this.alphFilterOffsetSub -
      this.alphFilterOffsetTitle;

    if (helpers.isWeb()) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(offsetPosition);
    }
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
            />
          </View>
        </View>
      </View>
    );
  };

  getWriters = () => {
    this.setState({
      showErrorModal: false,
      pageLoader: true,
    });
    const { token } = this.state;
    let endpoint = `/authors`;
    let body = {
      sort_on: "name",
      sort_by: "asc",
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          // let writerData = this.sortWriter(res.data.author);
          let allWriters = this.segregateWriters(res.data.author);
          this.setState({
            writerData: allWriters["writers"],
            contributorData: allWriters["contributors"],
            pageLoader: false,
          });
        } else {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  segregateWriters = (writerList) => {
    let writers = [],
      contributors = [];
    writerList.map((writer) => {
      if (writer.roles.includes("contributor")) {
        contributors.push(writer);
      } else {
        writers.push(writer);
      }
    });
    return { writers: writers, contributors: contributors };
  };

  sortWriter = (writerList) => {
    let writers = {};
    writerList.map((writer) => {
      let firstLetter = writer.name.substr(0, 1).toUpperCase();
      if (writers[firstLetter]) {
        writers[firstLetter].push(writer);
      } else {
        writers[firstLetter] = [writer];
      }
    });
    return writers;
  };

  viewAuthorDetails = (Slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + Slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: Slug });
    }
  };

  navigateTo = (slug) => {
    this.props.navigation.navigate(slug);
  };

  renderPageHeader = () => {
    let webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && { paddingHorizontal: 20 },
        ]}
      >
        <View
          style={[
            helpers.isDesktop() && Margin.mb_7,
            Helpers.textCenter,
            {
              backgroundColor: darkMode ? Colors.darkBlackColor : "#FFF4F1",
              marginTop: helpers.isDesktop() ? 130 : 75,
              paddingHorizontal: helpers.isDesktop() ? 20 : 0,
              width: "100%",
            },
          ]}
        >
          {/* <Text
            accessibilityRole="header"
            aria-level="1"
            style={[
              Margin.mt_0,
              Margin.mb_0,
              pageCover.title,
              helpers.isDesktop() && pageCover.titleDesktop,
              Helpers.textCenter,
              { position: "relative", top: helpers.isDesktop() ? -40 : -21 },
              !helpers.isDesktop() && { fontSize: 30, lineHeight: 39 },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            About us
          </Text> */}
          <Text
            style={[
              {
                marginBottom: helpers.isDesktop() ? 90 : 50,
                marginTop: helpers.isDesktop() ? 90 : 50,
              },
              pageContent.subTitle,
              helpers.isDesktop() && pageContent.subTitleDesktop,
              { maxWidth: helpers.isDesktop() ? 600 : "100%" },
              !webView && { textAlign: "center" },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            Financial journalism and beyond. Paid for by subscribers, not
            advertisers.
          </Text>
          {/*<CustomImage
                        source={cross}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 30 : 20 , height: helpers.isDesktop() ? 30 : 20 , position:'absolute', right: helpers.isDesktop() ? -15 : 20, top: helpers.isDesktop() ? 10 : -10 }}
                        webStyle={{ width: helpers.isDesktop() ? 30 : 20 , height: helpers.isDesktop() ? 30 : 20 , position:'absolute', right: helpers.isDesktop() ? -15 : 20, top: helpers.isDesktop() ? 10 : -10 }}
                    /> */}
          <CustomImage
            source={angleIcon}
            require={true}
            style={{
              width: helpers.isDesktop() ? 50 : 30,
              height: helpers.isDesktop() ? 50 : 30,
              position: "absolute",
              left: 0,
              bottom: 0,
            }}
            webStyle={{
              width: helpers.isDesktop() ? 50 : 30,
              height: helpers.isDesktop() ? 50 : 30,
              position: "absolute",
              left: 0,
              bottom: 0,
            }}
          />
          <CustomImage
            source={darkMode ? pinkDots : blackDots}
            require={true}
            style={{
              width: helpers.isDesktop() ? 60 : 50,
              height: helpers.isDesktop() ? 25 : 20,
              position: "absolute",
              right: helpers.isDesktop() ? 30 : 20,
              bottom: 25,
              resizeMode: "contain",
            }}
            webStyle={{
              width: helpers.isDesktop() ? 60 : 45,
              height: helpers.isDesktop() ? 25 : 20,
              position: "absolute",
              right: helpers.isDesktop() ? 30 : 20,
              bottom: 25,
            }}
          />
        </View>
      </View>
    );
  };

  renderOurMisson() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && { paddingHorizontal: 20 },
        ]}
      >
        <View
          style={[
            Margin.mb_4,
            Flex.row,
            !helpers.isDesktop() && Flex.justfiyCenter,
          ]}
        >
          <View
            style={[
              !helpers.isDesktop() && {
                backgroundColor: "white",
                zIndex: 1,
                paddingHorizontal: 20,
              },
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            <View
              style={[
                Helpers.borderBottom,
                helpers.isDesktop() && { marginRight: 25 },
                darkMode && { borderBottomColor: Colors.lineColor },
              ]}
            >
              <Text
                style={[
                  pageContent.sectionTitle,
                  helpers.isDesktop() && pageContent.sectionTitleDesktop,
                  helpers.isDesktop() && { marginRight: 0 },
                  { paddingBottom: 5 },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Mission
              </Text>
            </View>
          </View>
          {!helpers.isDesktop() && (
            <View
              style={[
                Helpers.titleLine,
                darkMode && { backgroundColor: Colors.lineColor },
              ]}
            ></View>
          )}
          {helpers.isDesktop() && (
            <View style={{ width: "100%", flex: 1 }}>
              <View
                style={[
                  Helpers.titleLineLight,
                  darkMode && { backgroundColor: Colors.lineColor },
                ]}
              ></View>
            </View>
          )}
        </View>
        <Text
          style={[
            pageContent.text,
            helpers.isDesktop() && pageContent.textDesktop,
            !helpers.isDesktop() && {
              paddingHorizontal: 20,
              textAlign: "center",
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          The Morning Context helps you make sense of the world with
          deeply-reported, impactful and ambitious stories.
        </Text>
      </View>
    );
  }

  renderInNumbers() {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[Flex.row, Margin.mt_5, { marginBottom: 74 }]}>
        {helpers.isDesktop() && (
          <View>
            <CustomImage
              source={blueDots}
              require={true}
              style={{
                width: helpers.isDesktop() ? 150 : 100,
                height: helpers.isDesktop() ? 120 : 85,
                position: "absolute",
                left: 0,
                top: 130,
              }}
              webStyle={{
                width: helpers.isDesktop() ? 150 : 100,
                height: helpers.isDesktop() ? 120 : 85,
                position: "absolute",
                left: 0,
                top: 130,
              }}
            />
          </View>
        )}
        <View
          style={[
            helpers.isDesktop() && Padding.pt_3,
            Padding.pb_2,
            helpers.isDesktop() && Flex.row,
            helpers.isDesktop() && Flex.alignCenter,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            {
              paddingHorizontal: helpers.isDesktop() ? 20 : 0,
              width: helpers.isDesktop() ? "65%" : "100%",
            },
          ]}
        >
          <View style={[Margin.mb_4, !helpers.isDesktop() && Margin.mb_2]}>
            {helpers.isDesktop() ? (
              <View
                style={[
                  Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  In numbers
                </Text>
              </View>
            ) : (
              <View
                style={[
                  Flex.row,
                  Flex.justfiyCenter,
                  { position: "relative", top: -18 },
                ]}
              >
                <View
                  style={[
                    {
                      backgroundColor: "white",
                      zIndex: 1,
                      paddingHorizontal: 20,
                    },
                    darkMode && { backgroundColor: Colors.darkBlackColorBg },
                  ]}
                >
                  <View
                    style={[
                      Helpers.borderBottom,
                      darkMode && { borderBottomColor: Colors.lineColor },
                    ]}
                  >
                    <Text
                      style={[
                        pageContent.sectionTitle,
                        helpers.isDesktop() && pageContent.sectionTitleDesktop,
                        { paddingBottom: 5 },
                        darkMode && { color: Colors.lineColor },
                      ]}
                    >
                      In numbers
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        backgroundColor: darkMode
                          ? Colors.darkBlackColor
                          : "#f8fcff",
                        height: 22,
                        position: "absolute",
                        top: 20,
                        right: 0,
                        left: 0,
                        width: "100%",
                        zIndex: -1,
                      },
                      !webView && { width: 200 },
                    ]}
                  ></View>
                </View>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <View
            style={[
              Flex.row,
              Flex.FlexWrap,
              Flex.fill,
              { paddingLeft: helpers.isDesktop() ? 80 : 20 },
            ]}
          >
            <View
              style={{
                paddingRight: 30,
                order: 1,
                width: "65%",
                marginBottom: helpers.isDesktop() ? 30 : 15,
              }}
            >
              <Text
                style={[
                  pageContent.number,
                  helpers.isDesktop() && pageContent.numberDesktop,
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                75+
              </Text>
              <Text
                style={[
                  pageContent.ListText,
                  helpers.isDesktop() && pageContent.ListTextDesktop,
                  {
                    fontSize: helpers.isDesktop() ? 20 : 12,
                    height: helpers.isDesktop() ? 60 : "auto",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                years of combined journalism experience
              </Text>
            </View>
            <View
              style={{
                paddingRight: 30,
                order: 1,
                width: "35%",
                marginBottom: helpers.isDesktop() ? 30 : 15,
              }}
            >
              <Text
                style={[
                  pageContent.number,
                  helpers.isDesktop() && pageContent.numberDesktop,
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                20
              </Text>
              <Text
                style={[
                  pageContent.ListText,
                  helpers.isDesktop() && pageContent.ListTextDesktop,
                  {
                    fontSize: helpers.isDesktop() ? 20 : 12,
                    whiteSpace: "nowrap",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Journalists
              </Text>
            </View>
            <View
              style={{
                paddingRight: 30,
                order: 1,
                width: "65%",
                marginBottom: helpers.isDesktop() ? 30 : 15,
              }}
            >
              <Text
                style={[
                  pageContent.number,
                  helpers.isDesktop() && pageContent.numberDesktop,
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                100,000+
              </Text>
              <Text
                style={[
                  pageContent.ListText,
                  helpers.isDesktop() && pageContent.ListTextDesktop,
                  { fontSize: helpers.isDesktop() ? 20 : 12 },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Readers
              </Text>
            </View>
            <View
              style={{
                paddingRight: 30,
                order: 1,
                width: "35%",
                marginBottom: helpers.isDesktop() ? 30 : 15,
              }}
            >
              <Text
                style={[
                  pageContent.number,
                  helpers.isDesktop() && pageContent.numberDesktop,
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                50+
              </Text>
              <Text
                style={[
                  pageContent.ListText,
                  helpers.isDesktop() && pageContent.ListTextDesktop,
                  {
                    fontSize: helpers.isDesktop() ? 20 : 12,
                    // whiteSpace: "nowrap",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Institutions subscribe to us
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: darkMode ? Colors.darkBlackColor : "#F8FCFF",
            width: helpers.isDesktop() ? "72%" : "100%",
            height: "100%",
            position: "absolute",
            right: 0,
            zIndex: -1,
          }}
        />
      </View>
    );
  }

  renderPageContent = () => {
    // const { writerData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[Margin.mt_3, Margin.mb_3]}>
        {this.renderOurMisson()}

        <View
          style={[
            helpers.isDesktop() && Margin.mt_6,
            !helpers.isDesktop() && Margin.mt_3,
            Margin.mb_3,
          ]}
        >
          <AboutStory darkMode={darkMode} />
        </View>

        {this.renderInNumbers()}

        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            helpers.isDesktop() && { paddingHorizontal: 20 },
          ]}
        >
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: "white",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Meet the founding team
                </Text>
              </View>
            </View>
            {!helpers.isDesktop() && (
              <View
                style={[
                  Helpers.titleLine,
                  darkMode && { backgroundColor: Colors.lineColor },
                ]}
              ></View>
            )}
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <View style={[Flex.row, Flex.FlexWrap]}>
            {writerData &&
              writerData.map((citem, index) => {
                return (
                  <AuthorItem
                    viewAuthor={(slug) => this.viewAuthorDetails(slug)}
                    single={index == 0 ? true : false}
                    key={`authorItem${index}`}
                    data={citem}
                    about={true}
                    showArrow={true}
                    itemsInRow={3}
                    require={true}
                    darkMode={darkMode}
                  />
                );
              })}
          </View>
        </View>

        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            helpers.isDesktop() && { paddingHorizontal: 20 },
            !helpers.isDesktop() && Margin.mt_5,
          ]}
        >
          <ExploreAboutUs
            navigateTo={(slug) => this.navigateTo(slug)}
            darkMode={darkMode}
          />
        </View>
      </View>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/about",
      native: { redirect: "/about", verifyRedirect: "/about" },
    };
    return (
      <ScrollView
        onScroll={this.getScrollOffset}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        ref={(ref) => (this.appscrollview = ref)}
      >
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/about-us",
      native: { redirect: "/about", verifyRedirect: "/about" },
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj["about"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/about-us"
        />
        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />
        {pageLoader
          ? this.showPageLoader()
          : webView
            ? this.renderWebPage()
            : this.renderAppPage()}

        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
        <AppFooter />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

export default connect(mapStateToProps, null)(AuthenticationWrapper(About));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
  },
];
