import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Dimensions,
  TextInput,
  Modal,
  StyleSheet,
} from "react-native";
import { FontFamily, button } from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import CommonNudgeMobile  from "../common-nudge-mobile"

const lockIcon = getImagePath("icons/lock_icon.png");
const twitterIcon = getImagePath("icons/story-twitter.png");
const linkedinIcon = getImagePath("icons/story-linkedin.png");
const googleIcon = getImagePath("icons/story-google.png");
const appleIcon = getImagePath("icons/story-apple.png");


function YesterdayModalMobile(props) {
  const [email, setEmail] = useState("");

  const goToLoginPricing = (encodedRedirectURL) => {
    if (props.postData?.story?.type == "non_privileged") {
      props.goToLogin();
    } else {
      props.navigateToScreen(
        "/pricing?header=true&success_redirect=" + encodedRedirectURL
      );
    }
  };

  const goToSubscribe = (encodedRedirectURL) => {
    props.navigateToScreen(
        "/pricing?subscribe=monthly-plan-usd&quantity=1" + encodedRedirectURL
      );
  }

  let darkMode = props.darkMode;
  let encodedRedirectURL = `${window.location.pathname}`;
  encodedRedirectURL = encodeURIComponent(encodedRedirectURL);

  let isOverseasUser = false;

  // logged in condition
  if (props.locationInfo?.countryInfo?.code !== "IN") {
    if (!props.isLoggedin) {
      isOverseasUser = true;
    }
    // If a logged in user has no  subscription
    else if (!props.userSubscriptionExpired) {
      isOverseasUser = true;
    }
  }

  const modalText = isOverseasUser
    ? "Get 30 days of unlimited access for $9.99."
    : `Clutter-free news with a quick, to-the-point perspective.`;

  const getModalHeaderText = () => {
    return props.postData?.story?.type == "non_privileged"
      ? "Sign up to read for free"
      : `Subscribe for unlimited access`;
  };

  const getModalContentHeader = (modalText, isOverseasUser) => {
    if (props.postData?.access_type == "non_privileged") {
      return (
        <Text
          style={{
            fontSize: "22px",
            lineHeight: "25px",
            color: "#000",
            fontWeight: 700,
            fontFamily: "PlayfairDisplay-Bold",
            textAlign: "center",
          }}
        >
          {"Trusted Financial News"}
        </Text>
      );
    } else {
      return (
        <View>
          <Text
            style={{
              fontSize: "22px",
              lineHeight: "25px",
              color: "#000",
              fontWeight: 700,
              fontFamily: "PlayfairDisplay-Bold",
              textAlign: "center",
            }}
          >
            {modalText}
          </Text>
          {isOverseasUser && (
            <Text
              style={{
                fontSize: "22px",
                lineHeight: "25px",
                color: "#000",
                fontFamily: "PlayfairDisplay",
                textAlign: "center",
                marginTop: 10,
              }}
            >
               {props.isLoggedin
                ? "Clutter-free news with a quick, to-the-point perspective."
                : "Exclusive coverage on the Indian technology and business landscape."}
            </Text>
          )}
        </View>
      );
    }
  };

  const getModalSubText = () => {
    if (props.postData?.access_type == "non_privileged") {
      return (
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontSize: "18px",
              lineHeight: "20px",
              color: "#000",
              fontWeight: 400,
              fontFamily: "PlayfairDisplay",
              textAlign: "center",
              marginTop: 10,
            }}
          >
            {
              "100,000 people start their day with The Morning Context online newspaper."
            }
          </Text>
          <Text
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              color: "#000",
              fontWeight: 400,
              fontFamily: "PlayfairDisplay",
              textAlign: "center",
              marginTop: 15,
            }}
          >
            {
              "Read our Daily Edition and free stories every morning in your inbox."
            }
          </Text>
          {props.isLoggedin && (
            <View
              style={{
                alignItems: "flex-start",
                marginTop: 20,
                width: 310,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ButtonGradient
                title={"Get Started"}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradient,
                    {
                      width: "100%",
                      paddingHorizontal: "16px",
                      paddingTop: "13px",
                      paddingBottom: "13px",
                      height: "auto",
                      backgroundColor: "transparent",
                    },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: "18px",
                      textTransform: "capitalize",
                      fontFamily: "PlayfairDisplay-Regular",
                      fontWeight: 600,
                    },
                  ],
                }}
                onClick={() => {
                  props.pushStoryNudgeInteractionToDataLayer(props.postData, 'get started', "NA", "NA")
                  goToLoginPricing(encodedRedirectURL);
                }}
                fullWidth={false}
                darkMode={darkMode}
                leftAligned={true}
                isNewGradiant={true}
              />
            </View>
          )}
        </View>
      );
    } else if (!isOverseasUser) {
      return (
        <View>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "25px",
              color: "#000",
              fontWeight: 700,
              fontFamily: "PlayfairDisplay-Bold",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 20,
            }}
          >
            {`At ${props.locationInfo?.countryInfo?.code !== "IN" ? "$" : "INR "}${
              props.locationInfo?.countryInfo?.code == "IN" ? "240" : "8"
            } per month`}
          </Text>
          <View
            style={{
              alignItems: "flex-start",
              marginTop: 20,
              width: 310,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <ButtonGradient
              title={"Explore subscription plans"}
              rootStyle={{
                btnWrapper: [
                  button.primaryGradient,
                  {
                    width: "100%",
                    paddingHorizontal: "16px",
                    paddingTop: "13px",
                    paddingBottom: "13px",
                    height: "auto",
                    backgroundColor: "transparent",
                  },
                ],
                btnText: [
                  button.primaryGradientText,
                  {
                    fontSize: "18px",
                    textTransform: "capitalize",
                    fontFamily: "PlayfairDisplay-Regular",
                    fontWeight: 600,
                  },
                ],
              }}
              onClick={() => {
                props.pushStoryNudgeInteractionToDataLayer(props.postData, 'explore subscription plans', "monthly plan",  props.locationInfo?.countryInfo?.code == "IN" ? 240 : 8)
                goToLoginPricing(encodedRedirectURL);
              }}
              fullWidth={false}
              darkMode={darkMode}
              leftAligned={true}
              isNewGradiant={true}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={{
            alignItems: "flex-start",
            marginTop: 20,
            width: 310,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ButtonGradient
            title={"SUBSCRIBE NOW"}
            rootStyle={{
              btnWrapper: [
                button.primaryGradient,
                {
                  width: "100%",
                  paddingHorizontal: "16px",
                  paddingTop: "13px",
                  paddingBottom: "13px",
                  height: "auto",
                  backgroundColor: "transparent",
                },
              ],
              btnText: [
                button.primaryGradientText,
                {
                  fontSize: "18px",
                  textTransform: "capitalize",
                  fontFamily: "PlayfairDisplay-Regular",
                  fontWeight: 600,
                },
              ],
            }}
            onClick={() => {
              props.pushStoryNudgeInteractionToDataLayer(props.postData, 'subscribe now', "Monthly Plan", 3)
              goToSubscribe(encodedRedirectURL);
            }}
            fullWidth={false}
            darkMode={darkMode}
            leftAligned={true}
            isNewGradiant={true}
          />
        </View>
      );
    }
  };

  const getSubscribeComponent = () => {
    if (props.postData?.access_type == "non_privileged") {
      return (
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginVertical: 15,
            }}
          >
            <TextInput
              style={{
                borderColor: "#47ccc2",
                borderWidth: 1,
                width: "80%",
                height: 36,
                outline: "none",
                paddingHorizontal: 10,
                fontFamily: FontFamily.regular,
                fontSize: 15,
              }}
              onChangeText={(value) => setEmail(value)}
              placeholder="Enter your email"
            />
            <View
              style={{
                width: "20%",
                display: "block",
              }}
            >
              <ButtonGradient
                title={">>"}
                style={{
                  borderRadius: "0px !important",
                  outLine: "none",
                }}
                rootStyle={{
                  btnWrapper: [{ height: 36, borderRadius: 0, flex: 1 }],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: 20,
                      lineHeight: 36,
                      paddingHorizontal: 15,
                      fontWeight: "600",
                      fontFamily: FontFamily.regular,
                      borderRadius: 0,
                      textTransform: "uppecase",
                    },
                  ],
                }}
                paddingOverride={true}
                onClick={() => props.goToSignUp(email)}
                darkMode={darkMode}
                isNewGradiant={true}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: FontFamily.bold,
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#000000",
                  marginBottom: 10,
                }}
              >
                Start your free trial with
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity onPress={() => props.goToGoogleLoginMobile()}>
                <CustomImage
                  source={googleIcon}
                  require={true}
                  style={{ width: 25, height: 25 }}
                  webStyle={{
                    width: 30,
                    height: 30,
                    marginTop: 0,
                    verticalAlign: "bottom",
                    marginBottom: 4,
                    marginRight: 6,
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => props.goToAppleLoginMobile()}>
                <CustomImage
                  source={appleIcon}
                  require={true}
                  style={{ width: 25, height: 25 }}
                  webStyle={{
                    width: 30,
                    height: 30,
                    marginTop: 0,
                    verticalAlign: "bottom",
                    marginBottom: 4,
                    marginRight: 6,
                  }}
                />
              </TouchableOpacity>
             {/*  <TouchableOpacity onPress={() => props.goToTwitterLoginMobile()}>
                <CustomImage
                  source={twitterIcon}
                  require={true}
                  style={{ width: 25, height: 25 }}
                  webStyle={{
                    width: 30,
                    height: 30,
                    marginTop: 0,
                    verticalAlign: "bottom",
                    marginBottom: 4,
                    marginRight: 6,
                  }}
                />
              </TouchableOpacity> */}
              <TouchableOpacity onPress={() => props.goToLinkedInLoginMobile()}>
                <CustomImage
                  source={linkedinIcon}
                  require={true}
                  style={{ width: 25, height: 25 }}
                  webStyle={{
                    width: 30,
                    height: 30,
                    marginTop: 0,
                    verticalAlign: "bottom",
                    marginBottom: 4,
                    marginRight: 6,
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    } else {
      return <View></View>;
    }
  };

  return (
    <View>
      <CommonNudgeMobile
        {...props}
        getModalHeaderText={getModalHeaderText}
        getModalContentHeader={getModalContentHeader}
        type={props.postData?.access_type}
        getModalSubText={getModalSubText}
        modalText={modalText}
        getSubscribeComponent={getSubscribeComponent}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#98B3B7",
    justifyContent: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerText: {
    color: "black",
    fontSize: 18,
    padding: 26,
  },
  noteHeader: {
    backgroundColor: "#42f5aa",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
  },
  footer: {
    flex: 1,
    backgroundColor: "#ddd",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  textInput: {
    alignSelf: "stretch",
    color: "black",
    padding: 20,
    backgroundColor: "#ddd",
    borderTopWidth: 2,
    borderTopColor: "#ddd",
  },
  addButton: {
    position: "absolute",
    zIndex: 11,
    right: 10,
    top: 8,
    backgroundColor: "#98B3B7",
    width: 25,
    height: 25,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    elevation: 8,
  },
  addButtonText: {
    color: "#fff",
    fontSize: 11,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    flex: 1,
    justifyContent: "flex-end",
  },
  centeredView: {
    position: "absolute",
    top: 100,
  },
});

export default YesterdayModalMobile;
