import React, { Component } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";

const closeIcon = getImagePath('icons/close-icon.png');
export class ThankYouComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <View
        style={{
          margin: "0 auto",
          height: helpers.isDesktop() ? "100%" : "100%",
          width: helpers.isDesktop() ? "100%" : "100%",
          padding: 20,
          backgroundColor: "#ffffff",
          boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >

        <TouchableOpacity onPress={this.props.closeThankYouModal} style={{ position: 'relative', left: '45%', top: helpers.isDesktop() ? 5 : null, bottom: helpers.isDesktop() ? null : '35%' }}>
          <CustomImage
            source={closeIcon}
            style={{ width: 10, height: 10, objectFit: 'contain' }}
            webStyle={{ width: 15, height: 15, objectFit: 'contain', }}
            className={'close-icon'}
            altText={name}
          />
        </TouchableOpacity>
        <View
          style={{
            display: "flex",

            alignItems: "center",

            justifyContent: "center",

            height: helpers.isDesktop() ? "100%" : "auto",
          }}
        >
          <View>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="70px"
              height="70px"
            >
              <path
                fill="#b25dee"
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              />
              <path
                fill="#ffffff"
                d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
              />
            </svg>
          </View>

          <View
            style={{
              disply: "flex",

              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "PlayfairDisplay-Bold",

                fontSize: helpers.isDesktop() ? "34px" : "30px",

                textTransform: "uppercase",

                letterSpacing: "3px",

                fontWeight: "600",
              }}
            >
              Thank you
            </Text>

            <Text
              style={{
                fontFamily: "Merriweather-Regular",

                fontSize: "18px",

                fontWeight: "400",

                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              You will be hearing from us shortly.
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default ThankYouComponent;
