import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableOpacity, Platform, Animated, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button, appHeader, Typo, FontWeight, FontFamily, Colors, formStyle } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import SubscriptionPlanModal from '../subscription-plan-modal';
import SidebarCheck from '../sidebar-check';
import CustomImage from '../custom-image';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import ButtonGradient from '../button-gradient';
import Button from '../button';

const cookies = new Cookies();

const appLogo = getImagePath('icons/tmc-logo.png');


const windowWidth = Dimensions.get('window').width;
export class AppHeaderView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillUnmount = () => {
    }

    componentDidUpdate = () => {

    }

    render() {
        const webView = helpers.isWeb();
        let btnClick;
        if (webView) {
            btnClick = {
                 to: "/"
            }
        } else {
            btnClick = {
                onPress: () => navigateApp('/')
            }
        }
        return (
            <>
                <TouchableOpacityLink {...btnClick}>
                    <View style={[appHeader.wrapper, { position: 'absolute', left: 0, right: 0, top: 0, zIndex: 999, backgroundColor: 'white', height: 68, alignItems:'center', justifyContent: 'center' }]}>
                        <View style={[Flex.row, Flex.alignCenter]}>
                            <CustomImage
                                require={true}
                                source={appLogo}
                                altText={'The Morning Context'}
                                webStyle={{
                                    width: 40,
                                    height: 40,
                                    position: 'relative',
                                    zIndex: 99
                                }}
                                style={[appHeader.logo]} />
                            <Text style={[Helpers.textUppercase, { fontSize: 22, lineHeight: 30, fontFamily: FontFamily.regular, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, whiteSpace: 'nowrap', marginLeft: 15, fontWeight: FontWeight.regular }]}>The Morning Context</Text>
                        </View>
                    </View>
                </TouchableOpacityLink>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeaderView);