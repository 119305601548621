import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, Helpers, appStyles, pageCover, pageContent, FontFamily, button, FontWeight, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import ButtonGradient from '../../components/button-gradient';

const noInternet = getImagePath('img/no-internet.png');
const noInternetDark = getImagePath('img/no-internet-dark.png');

const cookies = new Cookies();

export class NoInternetConnection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount = () => {
        if(helpers.isWeb()) {
            this.props.resetOfflineScreenPath();
        }
    }


    render() {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[appStyles.appBg,  Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb500, darkMode && appStyles.appBgDark]}>

                {/* <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} hideMenu={true} hideAccountIcon={true}/> */}

                <View style={[Flex.justfiyCenter, Flex.alignCenter, {width: '100%', height: '100%'}]}>
                    <CustomImage
                        source={darkMode ? noInternetDark : noInternet}
                        require={true}
                        webStyle={{ width: helpers.isDesktop() ? 340 : 240, height: helpers.isDesktop() ? 250 : 'auto', marginBottom: 40, marginTop: 50, objectFit:'cover'}}
                        style={{ width:  290, height:  300, marginBottom: 40, resizeMode: 'contain' }}
                    />
                    <Text style={[Flex.alignCenter, {fontfamily: FontFamily.semiBold ,color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.9)',fontSize: helpers.isDesktop() ? 36 : 28, lineHeight: helpers.isDesktop() ? 43 : 34, marginBottom: 15}]}>Whoops!</Text>
                    <Text style={{fontfamily: FontFamily.regular, color: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.7)',fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: 29, textAlign:'center'}}>Slow or no internet connection.</Text>
                    <Text style={{fontfamily: FontFamily.regular, color: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.7)',fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: 29, textAlign:'center'}}>Please check your internet connction and try again.</Text>
                    {/* <View style={{width: 340}}>
                        <ButtonGradient
                            title={'TRY AGAIN'}
                            fullWidth={true}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, { height: 46 }],
                                btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, paddingHorizontal: 80, fontWeight: '400' }]
                            }}
                            paddingOverride={true}
                            onClick={() => {this.props.navigateToScreen('/')}}
                        />
                    </View> */}
                    {/* <TouchableOpacity>
                        <Text style={{fontfamily: FontFamily.regular, fontWeight: FontWeight.regular, color: ' #121212', fontSize: 14, lineHeight: 17, textDecorationLine: 'underline', marginTop: 15}}>Back to where I was</Text>
                    </TouchableOpacity> */}
                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NoInternetConnection);