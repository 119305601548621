import SectionTitle from "../../components/section-title";
import React, { useState, useEffect } from "react";
import Timer from "./timer";
import _ from "lodash";
import apiHelpers from "../../utils/api-helpers";
import GoogleIcon from "../../../../assets/icons/google-small-icon.webp";
import "./styles.module.css";

const SignUpAcquisition = ({
  app,
  navigateWeb,
  redirectSlugs,
  navigateToScreen,
}) => {
  const [spotlight, setSpotlight] = useState({});
  const [isSuccessful, setIsSuccessful] = useState(false);

  useEffect(() => {
    fetchFreeSpotlight();
  }, []);

  const fetchFreeSpotlight = async () => {
    const endpoint = `/stories`;
    const body = {
      limit: 5,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      filters: [
        {
          key: "featured_free",
          value: "enable",
        },
        {
          key: "type",
          value: ["regular", "newsletter", "yesterday"],
        },
      ],
    };
    try {
      const response = await apiHelpers.apiRequest(
        endpoint,
        body,
        "POST",
        true
      );
      const data = _.get(response, ["data", "story"], []);
      const isSuccessful = _.get(response, ["success"], false);
      setSpotlight(_.head(data)); // return first element of an array
      setIsSuccessful(isSuccessful);
    } catch (error) {
      console.log("Error==> Down", JSON.stringify(error));
    }
  };

  const {
    strapline,
    title,
    featured_image,
    limited_access_stop_time = "",
    slug,
    category,
  } = spotlight || {};

  const categorySlug = _.get(category, "slug", "");
  const imageUrl = _.get(featured_image, "url", "");
  const types = _.get(spotlight, "types", []);
  const isYesterdayStory = _.head(types)?.slug === "yesterday";

  const handleUsingEmailLogin = () => {
    navigateWeb(
      `/sign-up?redirect=${redirectSlugs?.web}${
        isYesterdayStory ? "yesterday" : categorySlug
      }/${slug}`
    );
  };

  const handleUsingGoogleLogin = () => {
    navigateToScreen(
      "/sign-in?redirect=" +
        `${isYesterdayStory ? "/yesterday" : `/${categorySlug}`}/${slug}` +
        "&providerHint=google"
    );
  };

  const handleStoryRedirection = () => {
    navigateWeb(
      `${isYesterdayStory ? "/yesterday" : `/${categorySlug}`}/${slug}`
    );
  };

  return (
    <>
      {isSuccessful ? (
        <>
          <div className="main-container">
            <div className="container">
              <>
                <div className="desktop-heading">
                  <SectionTitle
                    app={app}
                    title={"Premium Story Unlocked For You"}
                    style={{ backgroundColor: "rgb(243, 245, 255)" }}
                  />
                </div>
                <div className="mobile-heading">
                  <p className="section-title main-title">
                    Story Unlocked For You
                  </p>
                </div>
              </>

              <section className="section-container">
                <img
                  src={imageUrl}
                  alt="image"
                  className="section-img"
                  onClick={handleStoryRedirection}
                />
                <div>
                  <h1
                    className="section-title"
                    onClick={handleStoryRedirection}
                  >
                    {title}
                  </h1>
                  <p
                    className="section-description"
                    onClick={handleStoryRedirection}
                  >
                    {strapline}
                  </p>
                </div>
              </section>

              <section className="sign-up-card-container">
                <div className="sign-up-card">
                  <div className="heading">
                    This subscriber-only story been temporarily unlocked. Sign
                    up for free with just your email address
                  </div>
                  <div>
                    <Timer endTime={limited_access_stop_time} />
                  </div>

                  <div className="button-container">
                    <button
                      className="button first"
                      onClick={handleUsingEmailLogin}
                    >
                      Login using Email
                    </button>
                    <button
                      className="button second"
                      onClick={handleUsingGoogleLogin}
                    >
                      <img
                        src={GoogleIcon}
                        alt="google-icon"
                        className="google-icon-img"
                      />
                      Login with Google
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SignUpAcquisition;
