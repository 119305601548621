import React, { Component } from 'react';
import { Text, View, ScrollView, TextInput, ActivityIndicator, Linking } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, input, button, Colors, FontFamily } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import CustomImage from '../custom-image';
import GoogleSocialButton from '../google-social-button';
import FacebookSocialButton from '../facebook-social-button';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import GoogleLogout from '../google-logout';
import FacebookLogoutButton from '../facebook-logout';
import Cookies from "universal-cookie";
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from '../button-gradient';
import ContentLoader from '../content-loader';
import config from "react-native-config";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refreshScreen } from '../../../redux/App/app.actions';
// import { GoogleSignin, statusCodes } from 'react-native-google-signin';
// import { LoginManager, AccessToken, GraphRequest, GraphRequestManager } from 'react-native-fbsdk';
import AuthenticationWrapper from '../authentication-wrapper';

const cookies = new Cookies();

const googleIcon = getImagePath('icons/google-icon-new.png');
const editProfile = getImagePath('icons/editProfile.png');
const fbIcon = getImagePath('icons/fb.png');
const fbIconDark = getImagePath('icons/facebook-light.png');
const appleIcon = getImagePath('icons/apple-icon.png');

export class EditProfile extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            enableBtnLoader: '',
            profileInfo: {},
            pageLoader: true,
            nameEditable: false,
            emailEditable: false,
            phoneEditable: false,
            isTeam: false,
            userStatus: false
        }
        if (!helpers.isWeb()) {
            GoogleSignin.configure();
        }
    }

    componentDidMount = () => {
        this.getUserProfile();
    }

    getUserProfile = () => {
        const { token } = this.state;
        let endpoint = `/profile`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            request_group: ["profile", "user_logins", "subscription"]
        }
        if (isAdmin) {
            body = {
                request_group: ["profile", "user_logins", "subscription"],
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'profile_info') {
                    let googleConnect = res.data.user_logins.find((login) => login.provider == 'google');
                    let fbConnect = res.data.user_logins.find((login) => login.provider == 'facebook');
                    let appleConnect = res.data.user_logins.find((login) => login.provider == 'apple');
                    let formConnect = res.data.user_logins.find((login) => login.provider == 'form');
                    this.setState({
                        profileInfo: res.data,
                        userStatus: res.data.user_status,
                        isTeam: JSON.stringify(res.data.team_subscription) != '{}',
                        pageLoader: false,
                        googleConnect,
                        appleConnect,
                        fbConnect,
                        formConnect
                    });
                } else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({
                            showErrorModal: true,
                            pageLoader: false
                        });
                    }
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    renderPageContent = () => {
        let { googleConnect, appleConnect, fbConnect, formConnect, profileInfo, nameEditable, emailEditable, phoneEditable, emailError, phoneError, googleConnectError, fbConnectError, enableBtnLoader, emailUpdateRequest, isTeam, userStatus } = this.state;
        const { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View>
                <View style={[Margin.mt_3, Padding.ph_2]}>
                    <Text style={[Typo.h2Ab, Margin.mb_1, darkMode && { color: Colors.lightWhite }]}>Profile</Text>
                </View>
                <View style={[Padding.ph_2]}>
                    <View style={[input.Wrapper]}>
                        <Text style={[input.LabelLight, Helpers.textUppercase, darkMode && { color: Colors.lightWhite }]}>Full Name</Text>
                        <TextInput
                            placeholder={'Add name'}
                            placeholderTextColor={[Colors.placeholderTextColor1, darkMode && { color: Colors.lineColor }]}
                            style={[input.Text, input.borderBottom, Padding.pt_1, Padding.pb_1, darkMode && { color: Colors.lineColor }]}
                            value={profileInfo.name}
                            onChangeText={(value) => this.onChange(value, 'name')}
                            editable={nameEditable}
                        />
                        {(((nameEditable && profileInfo.name) || (!nameEditable)) && !isAdmin) &&
                            <Button
                                title={nameEditable && profileInfo.name ? 'Save' : profileInfo.name ? 'Edit name' : 'Set name'}
                                rootStyle={{
                                    btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0, position: 'absolute', right: 0, bottom: 30 }],
                                    btnText: [button.whiteButtonText, { color: '#FC876D', lineHeight: 14, paddingHorizontal: 0, fontSize: 12, fontFamily: FontFamily.regular, fontWeight: '500' }],
                                }}
                                onClick={() => !nameEditable ? this.setEditable('nameEditable') : this.saveProfileData('name')}
                            />
                        }
                    </View>
                    <View style={[input.Wrapper]}>
                        <View style={[{ position: 'relative' }]}>
                            <Text style={[input.LabelLight, Helpers.textUppercase, darkMode && { color: Colors.lightWhite }]}>Mobile No.</Text>
                            <TextInput
                                placeholder={'Add mobile number'}
                                placeholderTextColor={Colors.placeholderTextColor1}
                                style={[input.Text, input.borderBottom, Padding.pt_1, Padding.pb_1, darkMode && { color: Colors.lineColor }]}
                                value={profileInfo.phone}
                                onChangeText={(value) => this.onChange(value, 'phone')}
                                maxLength={10}
                                editable={phoneEditable}
                                keyboardType={'numeric'}
                            />
                            {(((phoneEditable && profileInfo.phone) || (!phoneEditable)) && !isAdmin) &&
                                <Button
                                    title={phoneEditable && profileInfo.phone ? 'Save' : profileInfo.phone ? 'Edit mobile' : 'Set mobile'}
                                    rootStyle={{
                                        btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0, position: 'absolute', right: 0, bottom: 5 }],
                                        btnText: [button.whiteButtonText, { color: '#FC876D', lineHeight: 14, paddingHorizontal: 0, fontSize: 12, fontFamily: FontFamily.regular, fontWeight: '500' }],
                                    }}
                                    onClick={() => !phoneEditable ? this.setEditable('phoneEditable') : this.saveProfileData('phone')}
                                />
                            }
                        </View>
                        {phoneError &&
                            <View>
                                <Text style={[{ color: '#F02121', fontSize: 12, lineHeight: 14, fontFamily: FontFamily.regular, marginTop: 8 }]}>{phoneError}</Text>
                            </View>
                        }
                    </View>
                </View>


                <View style={[Margin.mt_3, Padding.ph_2, Margin.mb_3]}>
                    <Text style={[Typo.h2Ab, Margin.mb_1, darkMode && { color: Colors.lightWhite }]}>Connect your accounts</Text>
                </View>
                <View style={[Padding.ph_2]}>
                    <View style={[Margin.mb_4]}>
                        <View style={[Flex.row, Flex.justifyBetween, Flex.alignCenter]}>
                            <View style={[Flex.row, Flex.alignCenter]}>
                                <CustomImage
                                    source={googleIcon}
                                    require={true}
                                    style={{ width: 26, height: 26 }}
                                    webStyle={{ width: 26, height: 26 }}
                                />
                                <Text style={[Margin.ml_2, { fontSize: 18, lineHeight: 24, fontFamily: FontFamily.regular }, darkMode && { color: Colors.lightWhite }]}>Google</Text>
                            </View>

                            {!isAdmin ?
                                <>
                                    {googleConnect ?
                                        <Button
                                            title={'Disconnect'}
                                            rootStyle={{
                                                btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }, darkMode && { border: 0 }],
                                                btnText: [button.whiteButtonText, { fontFamily: FontFamily.regular, color: '#6A6A6A', lineHeight: 19, paddingRight: 0, fontSize: 16 }],
                                            }}
                                            onClick={() => Linking.openURL(this.props.auth.socialConnectionURL('google', 'disconnect'))}
                                        />
                                        :
                                        <Button
                                            title={'Connect'}
                                            rootStyle={{
                                                btnWrapper: [button.whiteButton, { borderColor: '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }, darkMode && { border: 0 }],
                                                btnText: [button.whiteButtonText, { fontFamily: FontFamily.regular, color: '#6A6A6A', lineHeight: 19, paddingRight: 0, fontSize: 16 }],
                                            }}
                                            onClick={() => Linking.openURL(this.props.auth.socialConnectionURL('google', 'connect'))}
                                        />
                                    }
                                </>
                                :
                                <>
                                    {googleConnect ?
                                        <Text style={[button.whiteButtonText, { color: '#4285F4', lineHeight: 19, paddingHorizontal: 0, fontSize: 16, fontFamily: FontFamily.regular, fontWeight: '500', width: 'auto' }]}>Connected</Text>
                                        :
                                        <Text style={[button.whiteButtonText, { color: '#6A6A6A', lineHeight: 19, paddingHorizontal: 0, fontSize: 16, fontFamily: FontFamily.regular, fontWeight: '500', width: 'auto' }]}>Disconnected</Text>
                                    }
                                </>
                            }
                        </View>
                        {googleConnectError &&
                            <Text style={[{ color: '#FC876D', marginTop: 10 }]}>{googleConnectError}</Text>
                        }
                    </View>

                    <View style={[Margin.mb_4]}>
                        <View style={[Flex.row, Flex.justifyBetween, Flex.alignCenter]}>
                            <View style={[Flex.row, Flex.alignCenter]}>
                                <CustomImage
                                    source={darkMode ? appleIcon : appleIcon}
                                    require={true}
                                    style={{ width: 26, height: 26 }}
                                    webStyle={{ width: 26, height: 26 }}
                                />
                                <Text style={[Margin.ml_2, { fontSize: 18, lineHeight: 24, fontFamily: FontFamily.regular }, darkMode && { color: Colors.lightWhite }]}>Apple</Text>
                            </View>

                            {!isAdmin ?
                                <>
                                    {appleConnect ?
                                        <Button
                                            title={'Disconnect'}
                                            rootStyle={{
                                                btnWrapper: [button.whiteButton, { borderColor: '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }, darkMode && { border: 0 }],
                                                btnText: [button.whiteButtonText, { fontFamily: FontFamily.regular, color: '#6A6A6A', lineHeight: 19, paddingRight: 0, fontSize: 16 }],
                                            }}
                                            onClick={() => Linking.openURL(this.props.auth.socialConnectionURL('apple', 'disconnect'))}
                                        />
                                        :

                                        <Button
                                            title={'Connect'}
                                            rootStyle={{
                                                btnWrapper: [button.whiteButton, { borderColor: '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }, darkMode && { border: 0 }],
                                                btnText: [button.whiteButtonText, { fontFamily: FontFamily.regular, color: '#6A6A6A', lineHeight: 19, paddingRight: 0, fontSize: 16 }],
                                            }}
                                            onClick={() => Linking.openURL(this.props.auth.socialConnectionURL('apple', 'connect'))}
                                        />
                                    }
                                </>
                                :
                                <>
                                    {appleConnect ?
                                        <Text style={[button.whiteButtonText, { color: '#4285F4', lineHeight: 19, paddingHorizontal: 0, fontSize: 16, fontFamily: FontFamily.regular, fontWeight: '500', width: 'auto' }]}>Connected</Text>
                                        :
                                        <Text style={[button.whiteButtonText, { color: '#6A6A6A', lineHeight: 19, paddingHorizontal: 0, fontSize: 16, fontFamily: FontFamily.regular, fontWeight: '500', width: 'auto' }]}>Disconnected</Text>
                                    }
                                </>
                            }
                        </View>
                        {fbConnectError &&
                            <Text style={[{ color: '#FC876D', marginTop: 10 }]}>{fbConnectError}</Text>
                        }
                    </View>
                </View>

                {isAdmin && <>
                    <View style={[Margin.mt_3, Padding.ph_2, Margin.mb_3]}>
                        <Text style={[Typo.h2Ab, Margin.mb_1, darkMode && { color: Colors.lightWhite }]}>Account status</Text>
                    </View>
                    <View style={[Padding.ph_2]}>
                        <View style={[Margin.mb_4]}>
                            <View style={[Flex.row, Flex.justifyBetween]}>
                                <View style={[Flex.row, Flex.alignCenter]}>
                                    <Text style={[{ fontSize: 18, lineHeight: 22, fontFamily: FontFamily.regular }, darkMode && { color: Colors.lightWhite }]}>Account is {userStatus}</Text>
                                </View>

                                {(userStatus == 'active') ?
                                    <Button
                                        title={'Deactivate'}
                                        rootStyle={{
                                            btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }, darkMode && { border: 0 }],
                                            btnText: [button.whiteButtonText, {fontFamily: FontFamily.regular , color: '#FC876D', lineHeight: 19, padding: 0, fontSize: 16, fontWeight: '600', textTransform: 'uppercase', borderBottomWidth: 1, borderColor: '#FC876D' }],
                                        }}
                                        onClick={() => this.activateProfile('inactive')}
                                    />
                                    :
                                    <Button
                                        title={'Activate'}
                                        rootStyle={{
                                            btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }, darkMode && { border: 0 }],
                                            btnText: [button.whiteButtonText, {fontFamily: FontFamily.regular , color: '#FC876D', lineHeight: 19, padding: 0, fontSize: 16, fontWeight: '600', textTransform: 'uppercase', borderBottomWidth: 1, borderColor: '#FC876D' }],
                                        }}
                                        onClick={() => this.activateProfile('active')}
                                    />
                                }
                            </View>
                        </View>
                    </View>
                </>}
            </View>
        )
    }

    renderPasswordView = () => {
        let { changePassword, setPassword, profileInfo, passwordError, newPasswordError, confirmPasswordError, enableBtnLoader, passwordChangeSuccess } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View>
                <View style={[Padding.ph_2]}>
                    <View style={[input.Wrapper]}>
                        <Text style={[input.LabelLight, Helpers.textUppercase, darkMode && { color: Colors.lightWhite }]}>{changePassword ? 'Current Password' : 'Enter Password'}</Text>
                        <TextInput
                            placeholder={changePassword ? 'Add Current Password' : 'Enter Password'}
                            placeholderTextColor={Colors.placeholderTextColor1}
                            style={[input.Text, input.borderBottom, Padding.pt_1, Padding.pb_1, { color: darkMode ? Colors.lightWhite : Colors.black }]}
                            value={profileInfo.password}
                            onChangeText={(value) => this.onChange(value, 'password')}
                            secureTextEntry={true}
                        />

                        {passwordError &&
                            <View>
                                <Text style={[{ color: '#FC876D', }]}>{passwordError}</Text>
                            </View>
                        }
                    </View>
                    {changePassword &&
                        <View style={[input.Wrapper]}>
                            <Text style={[input.LabelLight, Helpers.textUppercase, darkMode && { color: Colors.lightWhite }]}>New Password</Text>
                            <TextInput
                                placeholder={'Add New Password'}
                                placeholderTextColor={Colors.placeholderTextColor1}
                                style={[input.Text, input.borderBottom, Padding.pt_1, Padding.pb_1, { color: darkMode ? Colors.lightWhite : Colors.black }]}
                                value={profileInfo.new_password}
                                onChangeText={(value) => this.onChange(value, 'new_password')}
                                secureTextEntry={true}
                            />

                            {newPasswordError &&
                                <View>
                                    <Text style={[{ color: '#FC876D', }]}>{newPasswordError}</Text>
                                </View>
                            }
                        </View>
                    }
                    <View style={[input.Wrapper]}>
                        <Text style={[input.LabelLight, Helpers.textUppercase, darkMode && { color: Colors.lightWhite }]}>Confirm Password</Text>
                        <TextInput
                            placeholder={'Confirm Password'}
                            placeholderTextColor={Colors.placeholderTextColor1}
                            style={[input.Text, input.borderBottom, Padding.pt_1, Padding.pb_1, { color: darkMode ? Colors.lightWhite : Colors.black }]}
                            value={profileInfo.confirm_password}
                            onChangeText={(value) => this.onChange(value, 'confirm_password')}
                            secureTextEntry={true}
                        />
                        {confirmPasswordError &&
                            <View>
                                <Text style={[{ color: '#FC876D', }]}>{confirmPasswordError}</Text>
                            </View>
                        }
                    </View>

                    <View>
                        <ButtonGradient
                            title={changePassword ? 'UPDATE PASSWORD' : 'SET PASSWORD'}
                            rootStyle={{
                                btnWrapper: button.primaryGradient,
                                btnText: button.primaryGradientText,
                            }}
                            onClick={() => this.savePassword()}
                            loader={(enableBtnLoader && enableBtnLoader == 'password')}
                            disable={((enableBtnLoader && enableBtnLoader == 'password') || this.checkPasswords(changePassword))}
                            darkMode={darkMode}
                        />

                        {passwordChangeSuccess &&
                            <Text style={[Margin.mv_2]}>{changePassword ? 'Password changed successfully' : 'Password set successfully'}</Text>
                        }
                    </View>
                </View>
            </View>
        )
    }

    checkPasswords = (changePassword) => {
        let { profileInfo } = this.state;
        if (changePassword) {
            return ((!profileInfo.password || !profileInfo.new_password || !profileInfo.confirm_password))
        }
        else {
            return ((!profileInfo.password || !profileInfo.confirm_password))
        }
    }


    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ContentLoader darkMode={darkMode} />
        )
    }

    renderWebPage = () => {
        let { showPasswordView } = this.state;
        return (
            <>
                {showPasswordView ?
                    this.renderPasswordView()
                    :
                    this.renderPageContent()
                }
            </>
        )
    }

    renderAppPage = () => {
        let { showPasswordView } = this.state;
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {showPasswordView ?
                        this.renderPasswordView()
                        :
                        this.renderPageContent()
                    }
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {
                    pageLoader ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }

    setEditable = (key) => {
        this.setState({ [key]: true });
    }

    onChange = (value, field_key) => {
        if (field_key == 'phone') {
            let key = value.split('');
            let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
            for (var i = key.length - 1; i >= 0; i--) {
                if (keyList.indexOf(key[i]) == -1) {
                    return false;
                }
            }
        }
        let { profileInfo } = this.state;
        profileInfo[field_key] = value
        this.setState({ profileInfo, passwordChangeSuccess: false, emailError: false, phoneError: false });
    }

    saveProfileData = (key) => {
        const { token, profileInfo } = this.state;

        if (key == 'phone' && profileInfo.phone.length != 10) {
            this.setState({ phoneError: 'Please enter valid Mobile No.' })
            return;
        }
        else if (key == 'email' && !helpers.validateEmail(profileInfo.email)) {
            this.setState({ emailError: 'Please enter valid Email Id' })
            return;
        }
        let endpoint = `/edit-profile`;
        let body = {}
        if (key == 'name') {
            body.full_name = profileInfo.name
        }
        else if (key == 'email') {
            body.account_email = profileInfo.email
        }
        else {
            body[key] = profileInfo[key]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    let editableKey = `${key}Editable`;
                    let emailUpdateRequest = false;
                    if (key == 'email' && res.data.change_email_request) {
                        emailUpdateRequest = true
                    }

                    this.setState({ [editableKey]: false, emailUpdateRequest });
                    this.props.refreshScreen(!this.props.app.refreshScreen);
                }
                else {
                    this.setState({ emailError: JSON.stringify(res.data.error.account_email) })
//                     console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    activateProfile = (status) => {
        const { token, profileInfo } = this.state;

        let endpoint = `/admin-edit-profile`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            user_status: status,
            is_admin: true,
            reference_id: referenceId
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    this.setState({ userStatus: status });
                }
                else {
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    setPassword = (formConnect) => {
        if (formConnect) {
            this.setState({ changePassword: true, showPasswordView: true })
            this.props.setModalTitle('Change Password')
        }
        else {
            this.setState({ setPassword: true, showPasswordView: true })
            this.props.setModalTitle('Set Password')
        }
    }

    hidePasswordView = () => {
        let { profileInfo } = this.state;
        profileInfo.password = '';
        profileInfo.new_password = '';
        profileInfo.confirm_password = '';
        this.setState({ showPasswordView: false, profileInfo })
    }

    showPasswordViewSet = () => {
        return this.state.showPasswordView;
    }

    savePassword = () => {
        let { profileInfo, changePassword, setPassword, token } = this.state;

        if (profileInfo.password && profileInfo.password.length < 6) {
            this.setState({ passwordError: 'Password should contain atleast 6 letters', newPasswordError: null, confirmPasswordError: null })
            return;
        }
        else if (profileInfo.new_password && profileInfo.new_password.length < 6) {
            this.setState({ newPasswordError: 'Password should contain atleast 6 letters', passwordError: null, confirmPasswordError: null })
            return;
        }

        else if (changePassword && profileInfo.new_password !== profileInfo.confirm_password) {
            this.setState({ confirmPasswordError: 'Password do not match', passwordError: null, newPasswordError: null });
            return;
        }
        else if (setPassword && profileInfo.password !== profileInfo.confirm_password) {
            this.setState({ confirmPasswordError: 'Password do not match', passwordError: null, newPasswordError: null });
            return;
        }

        this.setState({ confirmPasswordError: null, passwordError: null, newPasswordError: null, enableBtnLoader: 'password' });
        let endpoint = `/change-password`;
        let body = {}
        if (changePassword) {
            body.old_password = profileInfo.password;
            body.new_password = profileInfo.new_password;
            body.confirm_password = profileInfo.confirm_password;
        }
        else {
            body.new_password = profileInfo.password;
            body.confirm_password = profileInfo.confirm_password;
            body.old_password = '';
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    profileInfo.password = '';
                    profileInfo.new_password = '';
                    profileInfo.confirm_password = '';
                    this.setState({ enableBtnLoader: false, showPasswordView: false, formConnect: true, profileInfo })
                }
                else {
                    let msg = res.data && res.data.error ? res.data.error : 'Something went wrong. Please try again.'
                    this.setState({ enableBtnLoader: false, passwordError: msg, newPasswordError: null, confirmPasswordError: null });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    enableBtnLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    handleGoogleLogin = (user) => {
        let { token } = this.state;
        this.setState({ enableBtnLoader: 'google', googleConnectError: false });
        let type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/link-social-account`;
        let body;
        if (helpers.isWeb()) {
            body = {
                provider: 'google',
                provider_id: user.profileObj.googleId,
                full_name: user.profileObj.name,
                account_email: user.profileObj.email,
                picture: user.profileObj.imageUrl,
                provider_data: JSON.parse(JSON.stringify(user)),
                type: type,
                device_data: {}
            }
            this.refs.child.triggerLogout();
        }
        else {
            body = user;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'account_linked') {
                    this.setState({ googleConnect: true, enableBtnLoader: false })
                }
                else {
                    let googleConnectError = res.data && res.data.error && res.data.error.account_email ? res.data.error.account_email : 'Something went wrong. Please try again';
                    this.setState({ enableBtnLoader: false, googleConnectError });
                }
            })
            .catch((error) => {
                let googleConnectError = 'Something went wrong. Please try again';
                this.setState({ enableBtnLoader: false, googleConnectError });
                console.log('Error=>>', error);
            });
    }

    handleFacebookLogin = (user) => {
        let { token } = this.state;
        this.setState({ enableBtnLoader: 'facebook', fbConnectError: false });
        let type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/link-social-account`;
        let body;
        if (helpers.isWeb()) {
            body = {
                provider: 'facebook',
                provider_id: user.userID,
                full_name: user.name,
                account_email: user.email,
                picture: user.picture.data.url,
                provider_data: JSON.parse(JSON.stringify(user)),
                type: type,
                device_data: {}
            }
            window.FB.logout();
        }
        else {
            body = user;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'account_linked') {
                    this.setState({ fbConnect: true, enableBtnLoader: false })
                }
                else {
                    let fbConnectError = res.data && res.data.error && res.data.error.account_email ? res.data.error.account_email : 'Something went wrong. Please try again';
                    this.setState({ enableBtnLoader: false, fbConnectError });
                }
            })
            .catch((error) => {
                let fbConnectError = 'Something went wrong. Please try again';
                this.setState({ enableBtnLoader: false, googleConnectError });
                console.log('Error=>>', error);
            });
    }

    handleFailure = (error = null) => {
        console.log("inside handleFailure", error)
    }

    handleGoogleLogout = () => {
        console.log("inside handleGoogleLogout")
    }

    handleFbLogout = () => {
        console.log("inside handleFbLogout")
    }

    unlinkSocialAccount = (provider) => {
        let { token } = this.state;
        // this.setState({ enableBtnLoader: provider });
        let endpoint = `/unlink-social-account`;
        let body = {
            provider: provider
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'account_unlinked') {
                    let { fbConnect, googleConnect, fbConnectError, googleConnectError } = this.state;
                    if (provider == 'google') {
                        googleConnect = false;
                        googleConnectError = null
                    }
                    else if (provider == 'facebook') {
                        fbConnect = false
                        fbConnectError = null;
                    }
                    this.setState({ googleConnect, fbConnect, enableBtnLoader: false, googleConnectError, fbConnectError })
                }
                else {
                    let { fbConnectError, googleConnectError } = this.state;
                    if (provider == 'google') {
                        googleConnectError = res.data && res.data.error && res.data.error.account_email ? res.data.error.account_email : res.data && res.data.error && res.data.error.provider ? res.data.error.provider : 'Something went wrong. Please try again';
                    }
                    else if (provider == 'facebook') {
                        fbConnectError = res.data && res.data.error && res.data.error.account_email ? res.data.error.account_email : res.data && res.data.error && res.data.error.provider ? res.data.error.provider : 'Something went wrong. Please try again';
                    }
                    this.setState({ enableBtnLoader: false, googleConnectError, fbConnectError });
                }
            })
            .catch((error) => {
                this.setState({ enableBtnLoader: false });
                console.log('Error=>>', error);
            });
    }

    triggerGoogleLogin = async () => {
        try {
            await GoogleSignin.hasPlayServices({ showPlayServicesUpdateDialog: true });
            const userInfo = await GoogleSignin.signIn();
            let body = {
                provider: 'google',
                provider_id: userInfo.user.id,
                full_name: userInfo.user.name,
                account_email: userInfo.user.email,
                picture: userInfo.user.photo,
                provider_data: JSON.parse(JSON.stringify(userInfo)),
                type: 'app',
                device_data: {}
            }
            this.handleGoogleLogin(body);
            await GoogleSignin.revokeAccess();
            await GoogleSignin.signOut();

        } catch (error) {
            console.log("error>>>")
            console.log(JSON.stringify(error))
        }
    }

    triggerFacebookLogin = async () => {
        let result = await LoginManager.logInWithPermissions([
            "email", "public_profile",
        ]);
        if (result.isCancelled) {
            console.log('Login cancelled');
        } else {
            let data = await AccessToken.getCurrentAccessToken();
            const accessToken = data.accessToken;
            const responseInfoCallback = async (error, userInfo) => {
                if (error) {
                    console.log("error>>>")
                    console.log(JSON.stringify(error))
                    await LoginManager.logOut();
                } else {
                    data.user = userInfo;
                    let body = {
                        provider: 'facebook',
                        provider_id: userInfo.id,
                        full_name: userInfo.name,
                        account_email: userInfo.email,
                        picture: userInfo.picture.data.url,
                        provider_data: JSON.parse(JSON.stringify(data)),
                        type: 'app',
                        device_data: {}
                    }
                    await LoginManager.logOut();
                    this.handleFacebookLogin(body);
                }
            };
            const infoRequest = new GraphRequest(
                '/me',
                {
                    accessToken,
                    parameters: {
                        fields: {
                            string: 'email,name,first_name,middle_name,last_name,picture'
                        }
                    }
                },
                responseInfoCallback
            );
            new GraphRequestManager().addRequest(infoRequest).start();
        }
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ refreshScreen }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(EditProfile));
