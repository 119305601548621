import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform, Button, FlatList } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, articleItem, FontFamily, articleLists, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import ArticleItemHorizontal from '../article-item-horizontal';
import ScrollContainerView from '../scroll-container-view';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ArticleItem from '../article-item';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import Swiper from "react-native-web-swiper";
import {AutoScrollFlatList} from "react-native-autoscroll-flatlist";
import PremiumSlider from './PremiumSlider';
import Slider from '../slider';
import AuthenticationWrapper from '../authentication-wrapper';

const viewMore = getImagePath('icons/view-more.png');

const cookies = new Cookies();

export class HomeNewsLetters extends Component {
    constructor(props) {
        super(props);
        this.flatlistRef = React.createRef()
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.state = {
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
            selectedIndex: 0
        }
        this.state={ count: 1}
    }

    componentDidMount = () => {
        console.log(this.state.selectedIndex,"index")
    }

    viewMoreArticles = () => {
        const { viewAllArticles } = this.props;
        viewAllArticles()
    }

    viewArticle = (catSlug, slug, postData) => {
        const { viewArticle } = this.props;
        viewArticle(catSlug, slug, postData)
    }

    viewAuthor = (slug) => {
        const { viewAuthor } = this.props;
        viewAuthor(slug)
    }
    viewCategory = (slug) => {
        const { viewCategory } = this.props;
        viewCategory(slug)
    }

    trackNewsletterClick = (data, index) => {
        // try {
        //     Mixpanel.track('click_newsletter', { title : data.name, position: index });
        // }
        // catch(error) {
        //     console.log("check mix panel track error ==>", error);
        // }
    }



    render() {
        const { data, categorySlug, home, darkMode } = this.props;
        let webView = helpers.isWeb();
        let btnClickNewsletter;
        return (

            <View style={[helpers.isDesktop() && { marginTop: 10, marginBottom: helpers.isDesktop() ? 0 : 60 }]}>
                <View style={[article.headerWrapper, helpers.isDesktop() && Margin.mb_4]}>
                    {/* <View style={[Flex.alignCenter, Margin.mb_5, !helpers.isDesktop() && Margin.mb_4]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap]}>
                            <Text style={[Helpers.textCenter,Helpers.textUppercase, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, Helpers.borderBottom, {fontSize: 18}, {fontFamily:FontFamily.regular} ,{fontSize: helpers.isDesktop() ? 30 : 18} ]}>Premium Newsletters 11</Text>
                        </View>
                        <View style={[Helpers.titleLine]}></View>
                    </View>  */}
                    <View style={[Flex.alignCenter, Margin.mb_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000, { marginTop: helpers.isDesktop() ? 30 : 40 }]}>
                        <View style={[Helpers.titleBgWrap, Padding.pl_2, Padding.pr_2, darkMode && { backgroundColor: Colors.darkBlackColorBg },{backgroundColor:'white'}]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: 'rgba(255,255,255,0.6)' }]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 24 }, { fontFamily: FontFamily.abrilFatface }, home && { fontSize: helpers.isDesktop() ? 34 : 20, lineHeight: helpers.isDesktop() ? 51 : 22, color: '#000' }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>Free{"&"}Premium Newsletters</Text>
                            </View>
                        </View>
                        <View style={[Helpers.titleLine, { backgroundColor: darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(102, 102, 102, 0.6)' }]}></View>
                    </View>
                    <View style={[!helpers.isDesktop() && Padding.pb_2, !helpers.isDesktop() && Padding.ph_2]}>
                        <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 20, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>Sharp analysis by our writers and expert contributors, from big tech to corp governance</Text>
                    </View>
                </View>
                <View style={[Padding.ph_2, Margin.mt_2, Margin.mb_4, Helpers.conatinerWeb]}>
                    {helpers.isDesktop() ?

                        <View style={[Flex.row, Flex.justfiyCenter, Flex.alignCenter]}>
                            {/* <Button title="<-" onPress={() => {
                                console.log("left button pressed")
                                // this.flatlistRef.current.scrollTo(1)
                                // this.refs.flatListRef.scrollToIndex({animated: true,index:4});
                                // this.flatList_Ref.scrollToIndex({animated: true,index:4});
                                this.flatList_Ref.scrollToIndex({animated:true,index:this.state.count,viewPosition:0.5,viewOffset:0.5})
                            }}>
                            
                            </Button> */}
                            {/* <PremiumSlider data={data} /> */}
                           {/* <PremiumSlider data={data} darkMode ={darkMode} >
            
                                {data && data.map((item, index) => {
                                    if (helpers.isWeb()) {
                                        btnClickNewsletter = {
                                            to: "/newsletters/" + item.slug
                                        }
                                    } else {
                                        btnClickNewsletter = {
                                            onPress: () => this.props.getNewsletterClick(item.slug)
                                        }
                                    }
                                    return (
                                        <View style={[articleItem.col4, helpers.isDesktop() && articleItem.col4Desktop, Flex.justfiyCenter, !(index == 0) && { borderLeftWidth: darkMode ? 0 : 0.5, borderColor: '#999999', height: 180, paddingHorizontal: 10 }, (darkMode && (index == 0)) && { paddingLeft: 10, paddingRight: 10 }]}>
                                            <TouchableOpacityLink {...btnClickNewsletter} handleClick={() => { this.trackNewsletterClick(item, index) }} >
                                                <View style={{ alignItems: 'center', position: 'relative' }}>
                                                    <CustomImage
                                                        source={item.logo}
                                                        webStyle={{ width: 'auto', height: 'auto', maxWidth: '100%', objectFit: 'contain', marginHorizontal: helpers.isDesktop() ? 35 : 0 }}
                                                        style={{ width: 180, height: 180, resizeMode: 'contain' }}
                                                        require={false}
                                                        className={''}
                                                        altText={item.name}
                                                        resizeMode={'contain'}
                                                    />
                                                    {
                                                        darkMode &&
                                                        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>
                                                        </View>
                                                    }
                                                </View>
                                            </TouchableOpacityLink>
                                        </View>
                                    )})}
                           </PremiumSlider> */}
                             <FlatList 
                            // ref={this.flatlistRef} 
                            // ref={(ref) => { this.flatListRef = ref; }}
                            ref={ref => {
                                this.flatList_Ref = ref;  // <------ ADD Ref for the Flatlist 
                              }}
                            onScroll={(e)=>{
                                let offset = e.nativeEvent.contentOffset
                                let index = Math.floor(offset.x / 180);
                                console.log("scrolling list",index)
                                this.setState({count: index})
                            }} data={data}
                            horizontal
                            pagingEnabled
                            showsHorizontalScrollIndicator={false} 
                            ItemSeparatorComponent={
                                () => <View style={{width:15}}/>
                            }
                            renderItem={({item,index }) => {
                                if (helpers.isWeb()) {
                                    btnClickNewsletter = {
                                        to: "/newsletters/" + item.slug
                                    }
                                } else {
                                    btnClickNewsletter = {
                                        onPress: () => this.props.getNewsletterClick(item.slug)
                                    }
                                }
                                return (
                                    <>
                                    <View style={[ (darkMode && (index == 0)) && { paddingLeft: 10, paddingRight: 10 },{width:'25%'}]}>
                                        
                                        <TouchableOpacityLink {...btnClickNewsletter} handleClick={() => { this.trackNewsletterClick(item, index) }} >
                                            <View style={{flexDirection:'column',width:300,backgroundColor:'white',
                                                // shadowColor: '#000',
                                                // shadowOffset: { width: 0, height: 1 },
                                                // shadowOpacity: 0.8,
                                                // shadowRadius: 1, 
                                                borderColor:'#F3F5FF', // if you need 
                                                borderWidth:2,
                                                // overflow: 'hidden',
                                                shadowColor: '#000',
                                                // shadowRadius: 1,
                                                // shadowOffset: { width: 0, height: 1 },
                                                // shadowOpacity: 0.8,
                                                // elevation:5
                                                }}>
                                            <View style={{flexDirection:'row',alignSelf:'center'}}>
                                            <View style={{ position: 'relative' ,marginLeft:16,}}>
                                                <Image
                                                    source={item.logo}
                                                    webStyle={{ width: 180, height: 180, maxWidth: '100%', objectFit: 'contain', marginHorizontal: helpers.isDesktop() ? 35 : 0 }}
                                                    style={{ width: 180, height: 180, resizeMode: 'contain' }}
                                                    require={false}
                                                    className={''}
                                                    altText={item.name}
                                                    resizeMode={'contain'}
                                                />
                                                </View>
                                                {/* {this.state.count === index && */}
                                            {/* <View style={{flexDirection:'column'}}>
                                            <Text style={[{ marginLeft:16,fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 27 : 20, color: '#000000', marginBottom: helpers.isDesktop() ? 0 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{item.name}</Text>
                                            <View style={{backgroundColor:'#907CFF',width:100,height:1,marginLeft:16,marginTop:15,marginBottom:16}}></View>
                                            <View style={{flexDirection:'row'}} >
                                            <Image source={require("../../../../assets/icons/calendarIcon.png")} style={{width:20,height:20,marginRight:16,marginLeft:16,alignSelf:'center'}} />
                                            <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 20, color: '#000000', marginBottom: helpers.isDesktop() ? 0 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{item.available}</Text>
                                            </View>
                                            </View>  */}
                                            {/* } */}
                                                {
                                                    darkMode &&
                                                    <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>

                                                    </View>
                                                }
                                           
                                            </View>
                                            <View style={{flexDirection:'column'}}>
                                            {/* <Text style={[{ marginLeft:16,fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 27 : 20, color: '#000000', marginBottom: helpers.isDesktop() ? 0 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{item.name}</Text>
                                            <View style={{backgroundColor:'#907CFF',width:100,height:1,marginLeft:16,marginTop:15,marginBottom:16}}></View> */}
                                             {/* <View style={{flexDirection:'row'}} >
                                            <Image source={} style={{width:20,height:20,marginRight:16,marginLeft:16,alignSelf:'center'}} />
                                            <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 20, color: '#000000', marginBottom: helpers.isDesktop() ? 0 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{item.available}</Text>
                                            </View> */}
                                            <View style={{flexDirection:'row'}} >
                                            <Image source={require("../../../../assets/icons/calendarIcon.png")} style={{width:20,height:20,marginRight:16,marginLeft:16,alignSelf:'center'}} />
                                            <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 20, color: '#000000', marginBottom: helpers.isDesktop() ? 0 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>Published {item.available}</Text>
                                            </View>
                                            </View> 
                                            <Text style={[{ marginLeft:16,fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, color: '#292929', marginBottom: helpers.isDesktop() ? 16 : 0, letterSpacing: 0.3 ,marginTop:16}, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{item.description}</Text>

                                             </View>
                                        </TouchableOpacityLink>
                                    </View>
                                    </>
                                )
                            }} keyExtractor={(index) => index.toString()} />
                             {/* <Button title="->" onPress={() => {
                                    this.flatList_Ref.scrollToIndex({animated:true,index:this.state.count,viewPosition:1,viewOffset:1})

                                console.log("right button pressed")

                            }}>
                            
                            </Button> */}

                        </View>
                        :
                        <ScrollContainerView horizontal={true} showsHorizontalScrollIndicator={false}>
                            <View style={[Flex.row, Margin.mb_4, Flex.alignCenter, !process.browser && { overflow: 'hidden' }, !webView && { width: '100%' }]}>
                                {data && data.map((item, index) => {
                                    if (helpers.isWeb()) {
                                        btnClickNewsletter = {
                                            to: "/newsletters/" + item.slug
                                        }
                                    } else {
                                        btnClickNewsletter = {
                                            onPress: () => this.props.getNewsletterClick(item.slug)
                                        }
                                    }
                                    return (
                                        <View style={[articleItem.col3, helpers.isDesktop() && articleItem.col3Desktop, { width: '40%' }, !(index == 0) && { borderLeftWidth: darkMode ? 0 : 0.5, borderColor: 'rgba(153, 153, 153, 0.6)', height: 'auto' }, !helpers.isDesktop() && { marginTop: 0, marginRight: 0 }, !webView && { width: 'auto', marginTop: 20 }]}>
                                            <TouchableOpacityLink {...btnClickNewsletter} handleClick={() => { this.trackNewsletterClick(item, index) }} >
                                                <View style={{ alignItems: 'center', position: 'relative' }}>
                                                    <CustomImage
                                                        source={item.logo}
                                                        webStyle={{ width: '100%', height: 77, objectFit: 'contain' }}
                                                        style={{ width: 200, height: 110 }}
                                                        require={false}
                                                        className={''}
                                                        altText={item.name}
                                                        resizeMode={'contain'}
                                                    />
                                                    {
                                                        darkMode &&
                                                        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>

                                                        </View>
                                                    }
                                                </View>
                                            </TouchableOpacityLink>
                                        </View>
                                    )
                                })}
                            </View>
                        </ScrollContainerView>
                    }
                </View>
            </View>
        );
    }
}

export default AuthenticationWrapper(HomeNewsLetters);
