import { ChargebeeInstance } from './abstract'
import { chargebeeInstanceConfig, chargebeeInstance__Interface, chargebeeInstanceFactory__Interface} from './interface'

export const chargebeeInstance__v1__Factory:chargebeeInstanceFactory__Interface = {
    createInstance: (): chargebeeInstance__Interface => {
        return new ChargebeeInstance__v1()
    }
}
/**
 * A Chargebee Instance which implements {@link chargebeeInstance__Interface}.
 */
export class ChargebeeInstance__v1 extends ChargebeeInstance{
    constructor(){
        super()
    }
}