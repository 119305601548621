import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button, Typo, subscribePlans, actionBox, Colors, FontFamily } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import Cookies from "universal-cookie";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { ProgressCircle } from 'react-native-material-indicators';
import AuthenticationWrapper from '../authentication-wrapper';

const cookies = new Cookies();

const cancelImg = getImagePath('img/cancel-complete.png');

export class CancelSubscription extends Component {
    pollingTimeout;
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            pageLoader: (this.props.params || this.props.isAdmin) ? false : true,
            noPlan: this.props.params ? (this.props.params.plan ? false : true) : false,
            cancelComplete: false,
            cancelDone: false
        }
    }

    componentDidMount = () => {
        const { isAdmin, referenceId } = this.props;
        if (this.state.pageLoader && isAdmin != true) {
            this.getUserProfile();
        }
    }

    componentWillUnmount() {
        this.clearPollingTimeout();
    }

    clearPollingTimeout() {
        if (this.pollingTimeout) {
            clearTimeout(this.pollingTimeout);
        }
    }

    getUserProfile = () => {
        const { token } = this.state;
        const { isTeam } = this.props;
        let endpoint = `/profile`;
        let body = {
            request_group: ["subscription"]
        }
        apiHelpers
            .apiRequest(endpoint, {}, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'profile_info') {
                    let noPlan;
                    if (isTeam) {
                        noPlan = (!res.data.team_subscription || (JSON.stringify(res.data.team_subscription) == '{}') || res.data.team_subscription.is_cancelled || !res.data.team_subscription.can_cancel || !res.data.team_subscription.is_team_owner) ? true : false;
                    } else {
                        noPlan = (!res.data.subscription || (JSON.stringify(res.data.subscription) == '{}') || res.data.subscription.is_cancelled || !res.data.subscription.can_cancel) ? true : false;
                    }
                    this.setState({
                        noPlan: noPlan,
                        pageLoader: false
                    });
                } else {
                    this.setState({
                        noPlan: true,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    noPlan: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    cancelPlan = () => {
        this.setState({
            pageLoader: true
        });
        const { token } = this.state;
        const { isTeam } = this.props;
        let endpoint = `/cancel-subscription`;
        let body = {}
        if (isTeam) {
            body.is_team_subscription = true;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'cancel_subscription') {
                    this.setState({
                        cancelDone: true,
                        cancelComplete: false,
                        pageLoader: false
                    });
                    this.props.setModalTitle('', true);
                    this.pollingCancel(0);
                } else {
                    this.setState({
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    pollingCancel = (fails) => {
        const { token } = this.state;
        const { isTeam } = this.props;
        let endpoint = `/profile`;
        let body = {
            request_group: ["subscription"]
        }
        this.pollingTimeout = setTimeout(() => {
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token)
                .then((res) => {
                    let checkSubscription;
                    if (res.success && res.code == 'profile_info') {
                        if (isTeam) {
                            checkSubscription = res.data.team_subscription;
                        } else {
                            checkSubscription = res.data.subscription;
                        }
                    }
                    if (res.success && res.code == 'profile_info' && (!checkSubscription || (JSON.stringify(checkSubscription) == '{}') || checkSubscription.is_cancelled)) {
                        this.setState({
                            cancelComplete: true
                        });
                    } else {
                        this.pollingCancel(fails);
                    }
                })
                .catch((error) => {
                    console.log('Error=>>', error);
                    // if(fails < 5) {
                    //     this.pollingCancel(fails + 1);
                    // } else {
                    //     //Manual
                    //     console.log("Manual===>>>")
                    // }
                    this.pollingCancel(fails);
                });
        }, 3000);
    }

    renderPage = () => {
        let { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        if (isAdmin) {
            return (
                <View style={[Padding.ph_2, Margin.mt_1]}>
                    <View style={[Flex.justfiyCenter]}>
                        <Text style={[Padding.pt_2, { fontSize: 14, lineHeight: 22, color: '#666', fontFamily: FontFamily.regular }, Margin.mb_3, darkMode && { color: Colors.lightWhite }]}>No Access</Text>
                    </View>
                </View>
            )
        }
        return (
            <View style={[Padding.pv_3, Padding.ph_2]}>
                {this.state.cancelDone ?
                    <>
                        {this.state.cancelComplete ?
                            <>
                                <View style={[{ alignItems: 'center' }]}>
                                    <CustomImage
                                        source={cancelImg}
                                        style={{
                                            width: 216,
                                            height: 193
                                        }}
                                        webStyle={{
                                            width: 216,
                                            height: 193
                                        }}
                                        require={true}
                                        altText={'Subscription Cancelled'}
                                    />
                                    <Text style={[Margin.mb_2, subscribePlans.planCancelTextTitle, darkMode && {color: Colors.lightWhite}]}>We’re sorry to see you go</Text>
                                </View>
                                <Text style={[Margin.mb_5, subscribePlans.planCancelText, darkMode && {color: Colors.lightWhite}]}>We hope you decide to come back soon and enjoy all the various stories and newsletter we have to offer. If you like to subscribe with us again, you can subscribe to our plans at any time.</Text>
                                <View>
                                    <ButtonGradient
                                        title={'Continue'}
                                        onClick={() => this.props.goBack()}
                                        rootStyle={{
                                            btnWrapper: button.primaryGradient,
                                            btnText: button.primaryGradientText,
                                        }}
                                        loader={false}
                                        disable={false}
                                        fullWidth={true}
                                    />
                                </View>
                            </>
                            :
                            <View style={[Flex.justfiyCenter, Flex.alignCenter]}
                            >
                                <View style={Margin.mb_5}>
                                    <ProgressCircle color={'#907CFF'} size={186} widthOfBorder={25} />
                                </View>
                                <Text style={[subscribePlans.loaderTitle, darkMode && {color: Colors.lightWhite}]}>Hold On!</Text>
                                <Text style={[subscribePlans.loaderSubTitle, darkMode && {color: Colors.lightWhite}]}>We are cancelling your subscription.</Text>
                                <Text style={[subscribePlans.loaderDesc, Margin.mt_2, darkMode && {color: Colors.lightWhite}]}>Please do not hit back.</Text>
                            </View>
                        }
                    </>
                    :
                    <>
                        <Text style={[Margin.mb_2, subscribePlans.planCancelText, darkMode && { color: Colors.lightWhite }]}>When you cancel a subscription, you cancel only future charges associated with your subscription. The cancellation of your current plan will become effective at the end of your current billing period.</Text>
                        <Text style={[Margin.mb_5, subscribePlans.planCancelText, darkMode && { color: Colors.lightWhite }]}>Are you sure you want to cancel your current subscription? <Text style={subscribePlans.planCancelTextBold}>This action cannot be undone.</Text></Text>
                        <View>
                            <ButtonGradient
                                title={'Keep Plan'}
                                onClick={() => this.props.goBack()}
                                rootStyle={{
                                    btnWrapper: button.primaryGradient,
                                    btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
                                }}
                                loader={false}
                                disable={false}
                                fullWidth={true}
                                darkMode={darkMode}
                            />
                        </View>
                        <View style={[Margin.mt_2, { alignItems: 'center' }]}>
                            <Text onPress={() => this.cancelPlan()} style={[actionBox.footerBtnTextCancel, helpers.isDesktop() && actionBox.footerBtnTextCancelDesktop, helpers.isDesktop() && actionBox.footerBtnTextDesktop]}>No thanks, I want to cancel my subscription</Text>
                        </View>
                    </>
                }
            </View>
        );
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }

    render() {
        const { pageLoader, noPlan } = this.state;
        return (
            <View>
                {pageLoader ?
                    this.showPageLoader()
                    :
                    noPlan ?
                        <View style={[Padding.pv_3, Padding.ph_2]}>
                            <View style={[{ alignItems: 'center' }]}>
                                <CustomImage
                                    source={cancelImg}
                                    style={{
                                        width: 216,
                                        height: 193
                                    }}
                                    webStyle={{
                                        width: 216,
                                        height: 193
                                    }}
                                    require={true}
                                    altText={'No Subscription'}
                                />
                                <Text style={[Margin.mb_5, subscribePlans.planCancelText]}>Looks like you do not have an active plan.</Text>
                            </View>
                            <View>
                                <ButtonGradient
                                    title={'Continue'}
                                    onClick={() => this.props.goBack()}
                                    rootStyle={{
                                        btnWrapper: button.primaryGradient,
                                        btnText: button.primaryGradientText,
                                    }}
                                    loader={false}
                                    disable={false}
                                    fullWidth={true}
                                />
                            </View>
                        </View>
                        :
                        this.renderPage()
                }
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(CancelSubscription));
