import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  aboutAuthor,
  pageContent,
  FontFamily,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";

const aboutBg = getImagePath("img/aboutBg.png");
const checkBoxIcon = getImagePath("icons/checkedBox.png");
const checkBoxIconDark = getImagePath("icons/checkedBox-dark.png");
const windowWidth = Dimensions.get("window").width;

export class AboutStory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let webView = helpers.isWeb();
    let { darkMode } = this.props;
    return (
      <>
        <View
          style={[
            Margin.mb_4,
            Flex.row,
            !helpers.isDesktop() && Flex.justfiyCenter,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            helpers.isDesktop() && { paddingLeft: 20 },
          ]}
        >
          <View
            style={[
              !helpers.isDesktop() && { backgroundColor: "white", zIndex: 1 },
              !helpers.isDesktop() && { paddingHorizontal: 20 },
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            <View
              style={[
                Helpers.borderBottom,
                helpers.isDesktop() && { marginRight: 25 },
                darkMode && { borderBottomColor: Colors.lineColor },
              ]}
            >
              <Text
                style={[
                  pageContent.sectionTitle,
                  helpers.isDesktop() && pageContent.sectionTitleDesktop,
                  { paddingBottom: 5 },
                  helpers.isDesktop() && { marginRight: 0 },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Editorial pillars
              </Text>
            </View>
            {!helpers.isDesktop() && (
              <View
                style={[
                  {
                    backgroundColor: darkMode
                      ? Colors.darkBlackColor
                      : "#fafafa",
                    height: 22,
                    position: "absolute",
                    top: 20,
                    right: 0,
                    left: 0,
                    width: "100%",
                    zIndex: -1,
                  },
                  !webView && { width: 150 },
                ]}
              ></View>
            )}
          </View>
          {!helpers.isDesktop() && (
            <View
              style={[
                Helpers.titleLine,
                darkMode && { backgroundColor: Colors.lineColor },
              ]}
            ></View>
          )}
          {helpers.isDesktop() && (
            <View style={{ width: "100%", flex: 1 }}>
              <View
                style={[
                  Helpers.titleLineLight,
                  { width: "45%" },
                  darkMode && { backgroundColor: Colors.lineColor },
                ]}
              ></View>
            </View>
          )}
        </View>

        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            { flexDirection: helpers.isDesktop() ? "row" : "column-reverse" },
            helpers.isDesktop() && { paddingHorizontal: 20 },
          ]}
        >
          <View
            style={[
              { width: helpers.isDesktop() ? "50%" : "100%" },
              !webView && { alignItems: "center" },
            ]}
          >
            <Text
              style={[
                pageContent.text,
                Margin.mb_2,
                helpers.isDesktop() && Padding.pr_3,
                {
                  fontFamily: FontFamily.regular,
                  fontWeight: "500",
                  fontSize: 24,
                  lineHeight: 34,
                },
                !helpers.isDesktop() && {
                  textAlign: "center",
                  width: 250,
                  margin: "auto",
                  paddingBottom: 35,
                  fontSize: helpers.isDesktop() ? 17 : 16,
                  lineHeight: 24,
                },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              Most of our stories check one of the following boxes:
            </Text>
            <View
              style={[
                helpers.isDesktop() && Padding.pr_3,
                !helpers.isDesktop() && { paddingHorizontal: 18 },
                !webView && { paddingLeft: 40, paddingRight: 40 },
              ]}
            >
              <View style={[Flex.row, helpers.isDesktop() && Flex.alignStart]}>
                <CustomImage
                  source={darkMode ? checkBoxIconDark : checkBoxIcon}
                  require={true}
                  style={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                  webStyle={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                />
                <Text
                  style={[
                    pageContent.ListText,
                    !helpers.isDesktop() && { marginTop: 6 },
                    {
                      fontSize: helpers.isDesktop() ? 22 : 16,
                      lineHeight: helpers.isDesktop() ? 35 : 26,
                    },
                    !webView && { width: windowWidth - 80 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  The story has an original point of view
                </Text>
              </View>
              <View style={[Flex.row, helpers.isDesktop() && Flex.alignStart]}>
                <CustomImage
                  source={darkMode ? checkBoxIconDark : checkBoxIcon}
                  require={true}
                  style={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                  webStyle={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                />
                <Text
                  style={[
                    pageContent.ListText,
                    !helpers.isDesktop() && { marginTop: 6 },
                    {
                      fontSize: helpers.isDesktop() ? 22 : 16,
                      lineHeight: helpers.isDesktop() ? 35 : 26,
                    },
                    !webView && { width: windowWidth - 80 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  The story covers new ground in critical analysis of the
                  business
                </Text>
              </View>
              <View style={[Flex.row, helpers.isDesktop() && Flex.alignStart]}>
                <CustomImage
                  source={darkMode ? checkBoxIconDark : checkBoxIcon}
                  require={true}
                  style={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                  webStyle={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                />
                <Text
                  style={[
                    pageContent.ListText,
                    !helpers.isDesktop() && { marginTop: 6 },
                    {
                      fontSize: helpers.isDesktop() ? 22 : 16,
                      lineHeight: helpers.isDesktop() ? 35 : 26,
                    },
                    !webView && { width: windowWidth - 80 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  The story is an investigation into why something happened
                </Text>
              </View>
              <View style={[Flex.row, helpers.isDesktop() && Flex.alignStart]}>
                <CustomImage
                  source={darkMode ? checkBoxIconDark : checkBoxIcon}
                  require={true}
                  style={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                  webStyle={{
                    width: 28,
                    height: 26,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                />
                <Text
                  style={[
                    pageContent.ListText,
                    !helpers.isDesktop() && { marginTop: 6 },
                    {
                      fontSize: helpers.isDesktop() ? 22 : 16,
                      lineHeight: helpers.isDesktop() ? 35 : 26,
                    },
                    !webView && { width: windowWidth - 80 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  The story makes sense of the complex world around us
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              Flex.justfiyCenter,
              !helpers.isDesktop() && { paddingBottom: 15 },
              helpers.isDesktop() && { marginBottom: 40 },
              {
                width: helpers.isDesktop() ? "50%" : "100%",
                alignItems: "center",
              },
            ]}
          >
            <CustomImage
              source={aboutBg}
              require={true}
              altText={"About Us"}
              style={{
                width: helpers.isDesktop() ? 440 : 250,
                height: helpers.isDesktop() ? 330 : 200,
              }}
              webStyle={{
                width: helpers.isDesktop() ? 440 : 250,
                height: helpers.isDesktop() ? 330 : 200,
              }}
            />
          </View>
        </View>
        <View
          style={[
            {
              backgroundColor: darkMode ? Colors.darkBlackColor : "#FAFAFA",
              height: helpers.isDesktop() ? 525 : "25%",
              width: helpers.isDesktop() ? "35%" : "100%",
              position: "absolute",
              top: helpers.isDesktop() ? 24 : 0,
              right: 0,
              zIndex: -1,
            },
            !helpers.isDesktop() && { left: 0, top: 18 },
          ]}
        ></View>
      </>
    );
  }
}

export default AboutStory;
