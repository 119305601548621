import React from "react";
import { helpers } from "../../utils/helpers";

import YesterdayModalWeb from "../yesterday-modal-web";
import YesterdayModalMobile from "../yesterday-modal-mobile";
import StoryModalWeb from "../story-modal-web";
import StoryModalMobile from "../story-modal-mobile";

function YesterdayModal(props) {
  return helpers.isDesktop() ? (
    <StoryModalWeb {...props} />
  ) : (
    <StoryModalMobile {...props} />
  );
}

export default YesterdayModal;
