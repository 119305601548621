import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, sliderStyle, FontFamily, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import AuthenticationWrapper from '../authentication-wrapper';

const arrowLeft = getImagePath('icons/arrow-left-dark.png');
const arrowRight = getImagePath('icons/arrow-right-dark.png');

const quoteLeft = getImagePath('icons/quotegreen.png');
const quoteRight = getImagePath('icons/quoteImageR.png');

const cookies = new Cookies();

export class Slider extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.appIntroSlider = React.createRef();
        this.checkScroll = true;
        const windowWidth = Dimensions.get('window').width;
        this.state = {
            activeSlide: 0,
            PlatformWeb: helpers.isDesktop(),
            slideItemWidth: windowWidth - 40,
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount() {
        if (helpers.isWeb()) {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        if (helpers.isWeb()) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize = () => {
        setTimeout(() => {
            const windowWidth = Dimensions.get('window').width
            if (windowWidth < 992) {
                this.setState({
                    PlatformWeb: false,
                    slideItemWidth: windowWidth - 40,
                })
            } else {
                this.setState({
                    PlatformWeb: true,
                    slideItemWidth: windowWidth - 40,
                })
            }
        }, 200)
    }

    getSlideIndex = (offset, width) => {
        const { data } = this.props;
        console.log(data);
        let intervals = data?.length;

        for (let i = 1; i <= intervals; i++) {
            let index = Math.round((width / intervals) * i);
            if (Math.round(offset) < index) {
                return i - 1;
            }
            if (i == intervals) {
                return i - 1;
            }
        }
    }

    getSlideOffset = (offset, width) => {
        if (this.checkScroll) {
            let slideIndex = this.getSlideIndex(offset, width);

            this.setState({
                activeSlide: slideIndex
            })
            // if(this.props.subscriptionQuote) {
            //     if(helpers.isWeb()) {
            //         Mixpanel.track('swipe_reader_quotes', { position: slideIndex });
            //     }
            // }
        }
    }

    prevSlide = () => {
        const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = data.length;


        let totalSlideWidth = slideItemWidth - 80

        let totalWidth = Math.round(slideLength * totalSlideWidth);


        let scrollIndex;

        if (activeSlide > 2) {
            scrollIndex = Math.round(slideItemWidth * (activeSlide - 2));
        } else {
            scrollIndex = 0;
        }

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
    }

    nextSlide = () => {
        const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = data.length;

        let scrollIndex = Math.round(slideItemWidth * (activeSlide));

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })

    }

    slideTo = (index) => {
        // index = index === 0 ? 0 : index+1;
        this.checkScroll = false;
        const { data } = this.props;
        const { slideItemWidth } = this.state;
        let slideLength = data.length;

        let scrollIndex = Math.round(slideItemWidth * (index));
        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
        this.setState({ activeSlide: index }, () => {
            setTimeout(() => {
                this.checkScroll = true;
            }, 2000)
        })
    }

    render() {
        const { data, subscriptionQuote, teamSubscribers, teamSubscribersMob, darkMode } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
         console.log(data);
        let intervals = data.length;
       
        let isWeb = helpers.isWeb();

        let dotClick;


        return (
            <View style={[sliderStyle.wrapper, helpers.isDesktop() && { maxWidth: 980, marginLeft: 'auto', marginRight: 'auto' }, { backgroundColor: 'transparent' }, teamSubscribers && { maxWidth: helpers.isDesktop() ? 520 : '100%' }, !isWeb && { width: 'auto' }]}>
                <ScrollView
                    contentContainerStyle={{ width: `${100 * intervals}%` }}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled={true}
                    onScroll={data => {
                        this.getSlideOffset(data.nativeEvent.contentOffset.x, data.nativeEvent.contentSize.width)
                    }}
                    scrollEventThrottle={200}
                    decelerationRate="fast"
                    ref={ref => this.scrollView = ref}
                >
                    {data && data.map((item, index) => {
                        return (
                            <View key={`slide_${index}`} style={[sliderStyle.sliderOuterItem, helpers.isDesktop() && sliderStyle.sliderOuterItemDesktop, { width: slideItemWidth, paddingTop: 25 }, !helpers.isDesktop() && !teamSubscribers && { marginRight: 20 }, !isWeb && { marginRight: 0, paddingHorizontal: 5 }]}>
                                <View style={[{ maxWidth: helpers.isDesktop() ? 980 : '100%' },helpers.isDesktop() && {  borderWidth: 1, borderColor: '#FFFBF1' }, {backgroundColor: '#FFFBF1'},subscriptionQuote && { borderWidth: 0 }, !helpers.isDesktop() && { height: 402 }, teamSubscribers && { borderWidth: 0, maxWidth: helpers.isDesktop() ? 520 : '100%' }, teamSubscribers && !helpers.isDesktop() && { height: 'auto' }, darkMode && { backgroundColor: Colors.darkBlackColor1, borderColor: Colors.darkBlackColor1 }]}>
                                    <View style={[!teamSubscribersMob && sliderStyle.sliderItem, helpers.isDesktop() && sliderStyle.sliderItemDesktop, teamSubscribers && helpers.isDesktop() && { minHeight: 385, paddingTop: 60 }]}>
                                        {!teamSubscribersMob && <View style={[helpers.isDesktop() ? Padding.pl_0 : Padding.pl_15, { position: 'absolute', left: helpers.isDesktop() ? 50 : 0, top: helpers.isDesktop() ? -20 : -10 }]}>
                                            <CustomImage
                                                source={quoteLeft}
                                                require={true}
                                                style={{ width: 30, height: 25, resizeMode: 'contain' }}
                                                webStyle={helpers.isDesktop() ? { width: 70, height: 57, objectFit: 'contain' } : { width: 30, height: 25, objectFit: 'contain' }}
                                            />
                                        </View>}
                                        {!teamSubscribers ?
                                            <View style={[{ paddingHorizontal: helpers.isDesktop() ? 120 : 25 }]}>
                                                <Text style={[sliderStyle.desc, helpers.isDesktop() && sliderStyle.descDesktop, darkMode && { color: Colors.lightWhite }]}>{item.text}</Text>
                                                <Text>
                                                    <Text style={[sliderStyle.name, helpers.isDesktop() && sliderStyle.nameDesktop, darkMode && { color: Colors.green }, !helpers.isWeb() && { fontFamily: FontFamily.medium, lineHeight: 24 }]}>{item.by},</Text>
                                                    <Text style={[sliderStyle.position, helpers.isDesktop() && sliderStyle.positionDesktop, { fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 20 : 13, lineHeight: helpers.isDesktop() ? 32 : 24,width:'80%', alignItems: 'baseline', marginLeft: 0 }, !helpers.isWeb() && { fontFamily: FontFamily.medium, lineHeight: 24,width:'80%' }, darkMode && { color: Colors.green }]}> {item.designation}</Text>
                                                </Text>
                                            </View>
                                            :
                                            <View style={[helpers.isDesktop() && Flex.row, !helpers.isDesktop() && { flexDirection: 'column-reverse' }]}>
                                                <View style={[{ paddingHorizontal: helpers.isDesktop() ? 25 : 0, textAlign: 'left', width: '100%' }]}>
                                                    <Text style={[sliderStyle.desc, helpers.isDesktop() && sliderStyle.descDesktop, !helpers.isDesktop() && { paddingTop: 0, paddingBottom: 0, fontSize: 18, lineHeight: 24,width:'80%' }, darkMode && { color: Colors.lightWhite }]}>{item.text}</Text>
                                                    <Text>

                                                        <Text style={[sliderStyle.name, helpers.isDesktop() && sliderStyle.nameDesktop, !helpers.isDesktop() && { fontFamily: FontFamily.regular, fontWeight: '600', fontSize: 18, lineHeight: 24, color: 'rgba(0,0,0,0.9)',width:'80%' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.green }]}>{item.by},</Text>
                                                        <Text style={[sliderStyle.position, helpers.isDesktop() && sliderStyle.positionDesktop, { fontFamily: FontFamily.regular, fontWeight: helpers.isDesktop() ? '400' : '600', fontSize: helpers.isDesktop() ? 20 : 18, lineHeight: helpers.isDesktop() ? 32 : 24,width:'80%', alignItems: 'baseline', marginLeft: 0 }, !helpers.isDesktop() && { color: 'rgba(0, 0, 0, 0.9)' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.green }]}> {item.designation}</Text>
                                                    </Text>
                                                </View>
                                            </View>
                                        }
                                    </View>
                                </View>
                            </View>
                        )
                    })}
                </ScrollView>
                {/* { helpers.isDesktop() &&
                    <View style={[sliderStyle.arrowLeft, { transform: [{ translateY: isWeb ? '-50%' : -30 }] }, activeSlide == 1 && { opacity: 1 }]}>
                        <TouchableOpacity
                            style={[sliderStyle.arrowBtn]}
                            onPress={() => this.prevSlide()}
                            disable={activeSlide == 1 ? true : false}
                        >
                            <CustomImage
                                source={arrowLeft}
                                require={true}
                                style={{ width: 9, height: 15 }}
                                webStyle={{ width: 9, height: 15 }}
                            />
                        </TouchableOpacity>
                    </View>
                }
                { helpers.isDesktop() &&
                    <View style={[sliderStyle.arrowRight, { transform: [{ translateY: isWeb ? '-50%' : -30 }] }, activeSlide == data.length && { opacity: 1 }]}>
                        <TouchableOpacity
                            style={[sliderStyle.arrowBtn]}
                            onPress={() => this.nextSlide()}
                            disable={activeSlide == data.length ? true : false}
                        >
                            <CustomImage
                                source={arrowRight}
                                require={true}
                                style={{ width: 9, height: 15 }}
                                webStyle={{ width: 9, height: 15 }}
                            />
                        </TouchableOpacity>
                    </View>
                } */}
                <View style={[sliderStyle.dotWrapper, { bottom: teamSubscribers ? (helpers.isDesktop() ? 60 : -10) : helpers.isDesktop() ? 60 : 40 }, teamSubscribers && { justifyContent: 'flex-start', paddingLeft: helpers.isDesktop() ? 50 : 0 }]}>
                    {data && data.map((item, index) => {
                        if (isWeb) {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        } else {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        }
                        return (
                            <TouchableOpacity {...dotClick}>
                                {!darkMode ?
                                    <View key={`dot_${index}`} style={[sliderStyle.dotNews, (index) == activeSlide && sliderStyle.activeDotNews]} />
                                    :
                                    <View key={`dot_${index}`} style={[sliderStyle.dotDark, (index) == activeSlide && sliderStyle.activeDotDark]} />
                                }
                            </TouchableOpacity>
                        )
                    })}
                </View>
            </View>
        );
    }
}

export default AuthenticationWrapper(Slider);
