import { helpers } from './helpers';
import { store } from '../../redux/store-native';
import Auth0 from 'react-native-auth0';
import { setUserToken } from '../../redux/User/user.actions';
import jwt_decode from "jwt-decode";

const auth0 = new Auth0({
    domain: process.env.AUTH0_DOMAIN,
    clientId: process.env.AUTH0_CLIENT_ID,
    audience: 'https://api.themorningcontext.com/api/v1',
});

/**
 * Refresh the tokens if they are expired and save the new tokens
 * 
 * Note: only for react-native mobile app
 */

class TokenRefresh {
    constructor(){
        this.existingtoken = null;
        this.refreshing = false;
        this.liveToken = null;

    }

    assertAlive (decoded) {
        const now = Date.now().valueOf() / 1000
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
            return false
        }
        if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
            return false
        }
        return true
    }

    /**
     * @param token : the token to be checked for expiry and refresh if expired 
     */
    async getRefreshedToken(token){
        const isTokenAlive = this.assertAlive(jwt_decode(token.idToken));
        if(isTokenAlive){
            return token;
        }
        if(this.refreshing){
            return this.liveToken;
        }
        this.existingToken = token;
        this.liveToken = new Promise((resolve, reject) => {
            auth0.auth.refreshToken({
                refreshToken: this.existingToken.refreshToken
            }).then(credentials => {
                const userData = {
                    data: {
                        token: {
                            accessToken: credentials.accessToken,
                            idToken: credentials.idToken,
                            refreshToken: credentials.refreshToken?credentials.refreshToken:this.existingToken.refreshToken,
                            expiresAt: new Date(Date.now() + Number(credentials.expiresIn) * 1000),
                        }
                    }
                }
                store.dispatch(setUserToken(userData));
                this.liveToken = userData.data.token
                this.refreshing = false
                resolve(userData.data.token)
            }).catch(err => {
                reject(err)
            })
        });
        this.refreshing = true;
        return this.liveToken;

    }
}

export default TokenRefresh;