import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, FontFamily } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';

const closeIcon = getImagePath('icons/close-round.png');
const emailVerifyIcon = getImagePath('icons/email-verify.png');
const warningIcon = getImagePath('icons/unsuccessful.png');
const goldCoin = getImagePath('icons/coin.png');

const windowHeight = Dimensions.get('window').height;

export class InsufficientCreditPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowHeight * 2)),
            modalOpacity: new Animated.Value(0),
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: (windowHeight * 2),
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 0,
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();

        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
        const { closeModal } = this.props;
        setTimeout(function () {
            closeModal();
        }, 300);
    }

    sendReqEmail = () => {
        const { token } = this.props;
        this.setState({
            emailLoader: true,
        });

        let endpoint = `/member-credits-mail`;
        apiHelpers
            .apiRequest(endpoint, {}, "POST", true, token)
            .then((res) => {
                this.setState({
                    emailLoader: false,
                });
                this.closeModal();
            })
            .catch((error) => {
                this.setState({
                    emailLoader: false,
                });
                console.log('Error=>>', error);
                this.closeModal();
            });
    }

    render() {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity } = this.state;
        const { renderContent, paddingHorizontal, topPadding, plan, country, teamSubscription, emailLoader } = this.props;

        return (
            <>
                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }]}>
                </Animated.View>
                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { transform: [{ translateY: showModal }], backgroundColor: 'transparent' }]}>
                    <View style={[modalStyle.modalContainer, { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, { paddingBottom: 40 }, !helpers.isDesktop() && { flex: 0.7, padding: 16, width: '100%', backgroundColor: 'transparent' }]}>
                        <View style={{ position: 'absolute', right: helpers.isDesktop() ? -25 : 0, top: helpers.isDesktop() ? -25 : 0, zIndex: 1 }}>
                            <TouchableOpacity
                                activeOpacity={0.8}
                                style={[modalStyle.closeButton]}
                                onPress={() => this.closeModal()}
                            >
                                <CustomImage
                                    source={closeIcon}
                                    style={{ width: 34, height: 34 }}
                                    webStyle={{ width: 34, height: 34 }}
                                    require={true}
                                />
                            </TouchableOpacity>
                        </View>
                        <View style={[!helpers.isDesktop() && { backgroundColor: 'white', paddingBottom: 40 }]}>
                            <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: '#FF8080', height: 55 }]}>
                                <CustomImage
                                    source={warningIcon}
                                    style={{ width: 22, height: 20, marginRight: 8, marginBottom: 5 }}
                                    webStyle={{ width: 22, height: 20, marginRight: 8, marginBottom: 5 }}
                                    require={true}
                                />
                                <Text style={{ fontFamily: FontFamily.regular, fontSize: 16, lineHeight: 19.2, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, color: 'white', fontWeight: '600' }}>INSUFFICIENT CREDITS</Text>
                            </View>
                            {(teamSubscription && teamSubscription.plan.tmc_group == 'team_credit') ?
                                <>
                                    <Text style={[Helpers.textCenter, Padding.pt_3, Padding.pb_3, { fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0, 0, 0, 0.8)', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28, paddingLeft: helpers.isDesktop() ? 30 : 18, paddingRight: helpers.isDesktop() ? 30 : 18 }]}>{teamSubscription.is_team_owner ? 'You do not have sufficient credits to read this story. You can assign additional credits via the “My team” section.' : 'You do not have sufficient credits to read this story. Please request the owner of your team to assign additional credits to you'}</Text>
                                    <View style={[Margin.ml_4, Margin.mr_5]}>
                                        <ButtonGradient
                                            title={teamSubscription.is_team_owner ? 'MY TEAM' : 'SEND REQUEST MAIL'}
                                            fullWidth={true}
                                            rootStyle={{
                                                btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                                                btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '400', paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 17 : 15, paddingBottom: helpers.isDesktop() ? 17 : 15 }]
                                            }}
                                            onClick={() => {
                                                if (teamSubscription.is_team_owner) {
                                                    return this.props.navigateToTeamPage();
                                                } else {
                                                    return this.sendReqEmail();
                                                }
                                            }
                                            }
                                            disable={emailLoader}
                                        />
                                    </View>
                                </>
                                :
                                <>
                                    <Text style={[Helpers.textCenter, Padding.pt_3, Padding.pb_3, { fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0, 0, 0, 0.8)', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28, paddingLeft: helpers.isDesktop() ? 30 : 18, paddingRight: helpers.isDesktop() ? 30 : 18 }]}>{Platform.OS == 'ios' ? 'You do not have enough credits to unlock this story.' : 'You do not have enough credits to unlock this story. Buy more to continue reading.'}</Text>
                                    {Platform.OS != 'ios' &&
                                        <View style={[Margin.mb_3, Flex.row, Flex.alignCenter, Flex.justfiyCenter]}>
                                            <CustomImage
                                                source={goldCoin}
                                                style={{ width: helpers.isDesktop() ? 55 : 32, height: helpers.isDesktop() ? 55 : 32 }}
                                                webStyle={{ width: helpers.isDesktop() ? 55 : 32, height: helpers.isDesktop() ? 55 : 32 }}
                                                require={true}
                                                altText={'Credits'}
                                            />
                                            <Text style={{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 20 : 20, lineHeight: helpers.isDesktop() ? 54 : 45, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, color: '#000', paddingLeft: helpers.isDesktop() ? 20 : 10 }}><Text style={{ fontWeight: '600' }}>{plan[0].description.unlock_desc}</Text> Credits</Text>
                                        </View>
                                    }
                                    {Platform.OS != 'ios' &&
                                        <View style={[Margin.ml_5, Margin.mr_5, Flex.row, Flex.alignCenter, Flex.justfiyCenter]}>
                                            <ButtonGradient
                                                title={"Buy for: " + `${plan[0].currency_code == 'INR' ? '₹' : '$'}${plan[0].price}`}
                                                fullWidth={true}
                                                rootStyle={{
                                                    btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                                                    btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '400', paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 17 : 15, paddingBottom: helpers.isDesktop() ? 17 : 15 }]
                                                }}
                                                onClick={() => { this.closeModal(); this.props.subscribeToPlan('credits', plan[0], country) }}
                                            // disable={btnDisable}
                                            />
                                        </View>
                                    }
                                </>
                            }
                        </View>
                    </View>
                </Animated.View>
            </>
        );
    }
}

export default InsufficientCreditPopup;