import { Dictionary } from './global-types'

export { helpers } from '../helpers'

/**
    Check if a variable is of type Dictionary
    @param a - A Dictionary object
*/

export function isDictionary(a): boolean{
    if(!a){
        return false
    }
    if (a.constructor == Object) {
        return true
    }
    return false
}


/**
 * Recursivly update a dictionary given an updated version
 * @param old - the dictionary which needs to be updated.
 * @param updated - the dictionary which hold updated values for the common keys in old and can contain new keys.
 * @returns - An updated dictionary: {@link Dictionary}
*/
export function syncObject(old, updated): any{
    for(const [ key, value ] of Object.entries(updated)){
        if((key in old) && (isDictionary(old[key]) && isDictionary(value))){
            old[key] = syncObject(old[key], value)
        }
        else{
            old[key] = value
        }
    }
    return old
}

/**
 * Deep copy a dictionary object
 * @param original - The source object
 * @param copy - The target object
 * @returns {@link Dictionary}
 */
export function deepCopyObject(original, copy): any{
    for(const [ key, value ] of Object.entries(original)){
        if(isDictionary(value)){
            copy[key] = deepCopyObject(value, {})
        }else{
            copy[key] = value
        }
    }
    return copy
}