import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, articleItem, FontFamily, articleLists, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import ArticleItemHorizontal from '../article-item-horizontal';
import ScrollContainerView from '../scroll-container-view';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ArticleItem from '../article-item';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Slider from '../slider';
const viewMore = getImagePath('icons/view-more.png');

export class HomeReadersQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { data, subscriptionQuotes, teamSubscribers, darkMode,isPostSingel } = this.props;

        return (

            <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && {  marginBottom: 100 }, !helpers.isDesktop() && {  marginBottom: 40 },this.props.pricing&&!helpers.isDesktop() && { marginTop: 0, marginBottom: 40 }, { zIndex: 9 }, teamSubscribers && { marginTop: helpers.isDesktop() ? 10 : 0, marginBottom: 0, paddingHorizontal: helpers.isDesktop() ? 20 : 0 }]}>
                {!teamSubscribers && <View style={[Flex.alignCenter, helpers.isDesktop() ? Padding.pt_4 : 0, Padding.pb_2, { width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                  <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginBottom: 10 }]}>
                 <View style={[Flex.alignCenter,helpers.isDesktop() && Margin.mb_3,!helpers.isDesktop() &&Margin.mb_0, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginTop: helpers.isDesktop() ? 30 : 40 }]}>
                        <View style={[{backgroundColor:this.state.token?'#FFFFFF':'#FFFFFF',zIndex:1}, Padding.pl_2, Padding.pr_2, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                            <View style={[helpers.isDesktop() &&Helpers.borderBottom, helpers.isDesktop() &&darkMode && { borderBottomColor: 'rgba(255,255,255,0.6)' }]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 24 }, { fontFamily: FontFamily.abrilFatface },  { fontSize: helpers.isDesktop() ? 34 : 22, lineHeight: helpers.isDesktop() ? 51 : 24, color:helpers.isDesktop() ?'#000000': '#051462' }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>What our readers say</Text>
                            </View>
                        </View>
                        {
                           isPostSingel && <View style={[helpers.isDesktop() &&Helpers.titleLine, {marginTop:30},helpers.isDesktop() &&{ backgroundColor: darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(102, 102, 102, 0.6)' }]}>
                        <Text style={[Helpers.textCenter, Typo.descText, helpers.isDesktop() ? Margin.mb_4 : Margin.mb_2, { fontSize: helpers.isDesktop() ? 18 : 16 }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>What our reader’s love about TMC.</Text>
                        </View>
                        }
                    </View>
                </View>
                </View>}
                {!teamSubscribers ? <View style={[{ marginTop: helpers.isDesktop() ? 30 : 0 }, !helpers.isDesktop() && { backgroundColor: 'transparent' }]}>
                    {!subscriptionQuotes ?
                        <Slider data={data} homeQuote={true} darkMode={darkMode} isFromHome={true} />
                        :
                        <Slider data={data} subscriptionQuote={true} darkMode={darkMode} isFromHome={true} />
                    }
                </View>
                :
                <View style={[{marginTop: helpers.isDesktop() ? 0 : 40}, !helpers.isDesktop() && {backgroundColor: 'transparent'}]}>
                    {helpers.isDesktop() ?
                        <Slider data={data} teamSubscribers={true} darkMode={darkMode} isFromHome={true}/>
                        :
                        <Slider data={data} teamSubscribers={true} teamSubscribersMob={true} darkMode={darkMode} isFromHome={true} />
                    }
                </View>
                }
            </View>
        );
    }
}

export default HomeReadersQuote;