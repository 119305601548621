import { portalParameterManager, chargebeePortal } from './abstract'
import { chargebeeInstance__Interface, ChargebeeInstance } from '../cb-instance'
import { portalParameterManager__Factory, portalParameterSettings, portalParameterManager__Interface, chargebeePortalFactory__Interface } from './interface'

export class portalParameterManager__v1 extends portalParameterManager{
    constructor(params: portalParameterSettings){
        super(params);
    }
}

export const portalParameterManager__v1__Factory:portalParameterManager__Factory = {
    createHandle: (params: portalParameterSettings):portalParameterManager__Interface => {
        return new portalParameterManager__v1(params)
    }
}

export class chargebeePortal__v1 extends chargebeePortal{
    parameterHandleFactory: portalParameterManager__Factory = portalParameterManager__v1__Factory
    constructor(chargebeeInstance: chargebeeInstance__Interface){
        super(chargebeeInstance);
    }
}

export const chargebeePortal__v1__Factory:chargebeePortalFactory__Interface = {
    createInstance: (cb_instance: chargebeeInstance__Interface) => {
        return new chargebeePortal__v1(cb_instance)
    }
}