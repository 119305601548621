import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  articleItem,
  articleLists,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");

export class ArticleItemLayoutHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.data.title);
    }
  };

  render() {
    const {
      single,
      home,
      homepage,
      data,
      viewArticle,
      viewAuthor,
      userStoryAction,
      applyStoryActions,
      userCredits,
      category,
      archive,
      exclusiveHome,
      elmsecond,
      hideDate,
      darkMode,
    } = this.props;

    let btnClick,
      authorBtnClick,
      author2BtnClick,
      btnClickBookmark,
      btnClickLike,
      btnCategoryClick;
      if (helpers.isWeb()) {
        btnClick = {
          to: {
            pathname: data.types.some(type => type.name === "Yesterday") 
              ? `/yesterday/${data.slug}` 
              : `/${data.category.slug}/${data.slug}`, // Updated path based on condition
            postData: data,
          },
        };
      authorBtnClick = {
        to: "/writers/" + data.author[0].slug,
      };
      if (data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
      btnCategoryClick = {
        to: "/category/" + data.category.slug,
      };
    } else {
      btnClick = {
        onPress: () => viewArticle(data.category.slug, data.slug, data),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author[0].slug);
        },
      };
      if (data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
      btnCategoryClick = {
        onPress: () => viewCategory(data.category.slug),
      };
    }
    return (
      <>
        <TouchableOpacityLink
          {...btnClick}
          handleClick={() => this.handleClick()}
        >
          <View style={[!archive && Flex.fill]}>
            {userCredits && (
              <View style={[articleItemHorizontal.wrapAbove]}>
                <Text
                  style={[
                    articleItemHorizontal.dateTop,
                    darkMode && { color: "rgba(255,255,255,0.6)" },
                  ]}
                >
                  {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </View>
            )}
            {category && (
              <TouchableOpacityLink {...btnCategoryClick}>
                <View>
                  {!exclusiveHome && (
                    <Text
                      style={[
                        articleLists.badge,
                        {
                          width: 150,
                          marginBottom: 15,
                          textAlign: "left",
                          fontFamily: FontFamily.regular,
                          color: "#907cff",
                        },
                        !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                        darkMode && { color: "#5263F4" },
                      ]}
                    >
                      {data.category.name}
                    </Text>
                  )}
                </View>
              </TouchableOpacityLink>
            )}
            <View
              style={[
                Flex.column,
                archive && Flex.row,
                archive && Margin.mb_4,
                userCredits && { paddingTop: 0 },
              ]}
            >
              <View style={[exclusiveHome && Margin.mb_3]}>
                {!exclusiveHome && (
                  <View style={[Margin.mb_0]}>
                    {!archive ? (
                      <CustomImage
                        source={data.featured_image.url}
                        require={false}
                        style={{ width: "100%", height: 200 }}
                        webStyle={{
                          width: "100%",
                          height: home ? 160 : helpers.isDesktop() ? 180 : 200,
                          objectFit: "cover",
                        }}
                        resizeMode={"cover"}
                        altText={
                          data.featured_image.alt &&
                            data.featured_image.alt != ""
                            ? data.featured_image.alt
                            : data.title
                              ? data.title
                              : data.story?.title
                                ? data.story.title
                                : data.featured_image.title
                        }
                      />
                    ) : (
                      <CustomImage
                        source={data.featured_image.url}
                        require={false}
                        style={{ width: 125, height: 71 }}
                        webStyle={{
                          width: helpers.isDesktop() ? 300 : 125,
                          height: helpers.isDesktop() ? 230 : 80,
                          objectFit: "cover",
                        }}
                        resizeMode={"cover"}
                        altText={
                          data.featured_image.alt &&
                            data.featured_image.alt != ""
                            ? data.featured_image.alt
                            : data.title
                              ? data.title
                              : data.story?.title
                                ? data.story.title
                                : data.featured_image.title
                        }
                      />
                    )}
                  </View>
                )}
                {exclusiveHome && (
                  <CustomImage
                    source={data.featured_image.url}
                    require={false}
                    style={{ width: 125, height: 71 }}
                    webStyle={{
                      width: "100%",
                      height: elmsecond
                        ? 300
                        : helpers.isDesktop()
                          ? 190
                          : "auto",
                      objectFit: "cover",
                    }}
                    resizeMode={"cover"}
                    altText={
                      data.featured_image.alt && data.featured_image.alt != ""
                        ? data.featured_image.alt
                        : data.title
                          ? data.title
                          : data.story?.title
                            ? data.story.title
                            : data.featured_image.title
                    }
                  />
                )}
                {userStoryAction && (
                  <View style={[Flex.row, Margin.mt_1]}>
                    <TouchableOpacity
                      {...btnClickLike}
                      style={[postActionBar.actionBtn, { marginRight: 3 }]}
                    >
                      <CustomImage
                        source={
                          data.user_story_action && data.user_story_action.like
                            ? likeIconSelected
                            : likeIcon
                        }
                        require={true}
                        style={{ width: 15, height: 13 }}
                        webStyle={{ width: 15, height: 13 }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      {...btnClickBookmark}
                      style={[postActionBar.actionBtn]}
                    >
                      <CustomImage
                        source={
                          data.user_story_action &&
                            data.user_story_action.bookmark
                            ? bookmarkIconSelected
                            : bookmarkIcon
                        }
                        require={true}
                        style={{ width: 9, height: 14 }}
                        webStyle={{ width: 9, height: 14 }}
                      />
                    </TouchableOpacity>
                  </View>
                )}
                {exclusiveHome && helpers.isDesktop() && (
                  <TouchableOpacityLink {...btnCategoryClick}>
                    <View
                      style={[
                        {
                          position: "absolute",
                          left: 0,
                          right: 0,
                          alignItems: "center",
                        },
                        !elmsecond && { bottom: -30 },
                        elmsecond && { top: -15 },
                      ]}
                    >
                      <Text
                        style={[
                          articleLists.badge,
                          {
                            marginBottom: 15,
                            textAlign: "center",
                            fontFamily: FontFamily.regular,
                            backgroundColor: "#907cff",
                            color: "white",
                            borderWidth: 1,
                            borderColor: "white",
                            paddingVertical: 5,
                          },
                          elmsecond && {
                            backgroundColor: "white",
                            borderColor: "#907cff",
                            color: "#907cff",
                          },
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.semiBold,
                          },
                          darkMode && { color: "#5263F4" },
                        ]}
                      >
                        {data.category.name}
                      </Text>
                    </View>
                  </TouchableOpacityLink>
                )}
              </View>
              <View
                style={[
                  archive && Padding.pl_2,
                  // Padding.pr_2,
                  archive && Flex.fill,
                ]}
              >
                <Text
                  style={[
                    home && { fontSize: 26 },
                    articleItemHorizontal.articleTitle,
                    !archive && Margin.mt_1,
                    archive && Margin.mb_2,
                    { height: 84 },
                    !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                    home && {
                      fontWeight: "600",
                      fontFamily: helpers.isDesktop()
                        ? "PlayfairDisplay-Bold"
                        : "PlayfairDisplay-Bold",
                    },
                    homepage && {
                      fontWeight: "600",
                      fontFamily: helpers.isDesktop()
                        ? "PlayfairDisplay-Bold"
                        : "PlayfairDisplay-Bold",
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: helpers.isDesktop() ? 45 : 39,
                    },
                    exclusiveHome && { fontSize: 24, fontWeight: "600" },
                    !helpers.isWeb() && { fontFamily: "PlayfairDisplay-Bold" },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                  ]}
                  numberOfLines={2}
                >
                  {data.title}
                </Text>

                {archive && (
                  <Text
                    style={[
                      articleItemHorizontal.desc,
                      helpers.isDesktop() && articleItemHorizontal.descDesktop,
                      { marginBottom: helpers.isDesktop() ? 0 : 10 },
                    ]}
                  >
                    {data.strapline != ""
                      ? data.strapline
                      : helpers.trimWord(data.excerpt)}
                  </Text>
                )}
                {exclusiveHome && (
                  <Text
                    style={[
                      articleItemHorizontal.desc,
                      helpers.isDesktop() && articleItemHorizontal.descDesktop,
                      { marginBottom: helpers.isDesktop() ? 0 : 10 },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {data.strapline != ""
                      ? data.strapline
                      : helpers.trimWord(data.excerpt)}
                  </Text>
                )}
                {userCredits ? (
                  <>
                    {data.credits && (
                      <View style={[Flex.row]}>
                        <Text style={postSingle.creditBox}>
                          {data.credits.unlock} Credits
                        </Text>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {!homepage && (
                      <View
                        style={[
                          articleItemHorizontal.lineBreak,
                          Margin.mt_1,
                          Margin.mb_1,
                          helpers.isDesktop() && [Margin.mb_1, Margin.mt_1],
                        ]}
                      ></View>
                    )}
                    <View
                      style={[
                        {
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: 0,
                        },
                        homepage && { height: 46, marginTop: 10 },
                        !helpers.isDesktop() && home && {},
                      ]}
                    >
                      <TouchableOpacityLink {...authorBtnClick}>
                        <Text
                          style={[
                            articleItemHorizontal.authorTitle,
                            helpers.isDesktop() &&
                            articleItemHorizontal.authorTitleDesktop,
                            !helpers.isWeb() &&
                            articleItemHorizontal.authorTitleApp,
                            { fontWeight: "500" },
                            homepage &&
                            helpers.isDesktop() && {
                              fontSize: 18.5,
                              lineHeight: 22.2,
                            },
                            darkMode && { color: "rgba(255,255,255,0.87)" },
                          ]}
                        >
                          {data.author[0].name}
                        </Text>
                      </TouchableOpacityLink>
                      {data.author[1] && (
                        <>
                          <Text
                            style={[
                              articleItemHorizontal.authorTitle,
                              helpers.isDesktop() &&
                              articleItemHorizontal.authorTitleDesktop,
                              !helpers.isWeb() &&
                              articleItemHorizontal.authorTitleApp,
                              articleItemHorizontal.date,
                              { marginTop: 2, fontFamily: FontFamily.light },
                              homepage &&
                              helpers.isDesktop() && {
                                fontSize: 18.5,
                                lineHeight: 22.2,
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                            ]}
                          >
                            {" "}
                            and{" "}
                          </Text>
                          <TouchableOpacityLink {...author2BtnClick}>
                            <Text
                              style={[
                                articleItemHorizontal.authorTitle,
                                helpers.isDesktop() &&
                                articleItemHorizontal.authorTitleDesktop,
                                !helpers.isWeb() &&
                                articleItemHorizontal.authorTitleApp,
                                { fontWeight: "500" },
                                homepage &&
                                helpers.isDesktop() && {
                                  fontSize: 18.5,
                                  lineHeight: 22.2,
                                },
                                darkMode && { color: "rgba(255,255,255,0.87)" },
                              ]}
                            >
                              {data.author[1].name}
                            </Text>
                          </TouchableOpacityLink>
                        </>
                      )}
                      {!hideDate && (
                        <View
                          style={[
                            articleItemHorizontal.dot,
                            helpers.isDesktop() &&
                            articleItemHorizontal.dotDesktop,
                            homepage && {
                              position: "relative",
                              top: helpers.isDesktop() ? -3 : -2,
                            },
                          ]}
                        ></View>
                      )}
                      {!hideDate && (
                        <Text
                          style={[
                            articleItemHorizontal.articleDate,
                            helpers.isDesktop() &&
                            articleItemHorizontal.articleDateDesktop,
                            !helpers.isWeb() &&
                            articleItemHorizontal.articleDateApp,
                            { fontWeight: "300" },
                            !helpers.isDesktop() && {
                              fontSize: 14,
                              lineHeight: 23,
                            },
                            homepage && { color: "#292929" },
                            homepage &&
                            helpers.isDesktop() && {
                              fontSize: 18.5,
                              lineHeight: 22.2,
                            },
                            darkMode && { color: "rgba(255,255,255,0.6)" },
                          ]}
                        >
                          {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                        </Text>
                      )}
                    </View>
                    {homepage && (
                      <View
                        style={[
                          articleItemHorizontal.lineBreak,
                          Margin.mt_0,
                          Margin.mb_1,
                          helpers.isDesktop() && [Margin.mb_2, Margin.mt_1],
                        ]}
                      ></View>
                    )}
                    {homepage && (
                      <Text
                        style={[
                          articleItemHorizontal.desc,
                          helpers.isDesktop() &&
                          articleItemHorizontal.descDesktop,
                          {
                            fontSize: helpers.isDesktop() ? 18 : 14,
                            marginBottom: helpers.isDesktop() ? 0 : 10,
                            fontFamily: FontFamily.regular,
                            color: "#292929",
                          },
                          darkMode && { color: "rgba(255,255,255,0.87)" },
                        ]}
                      >
                        {data.strapline != ""
                          ? data.strapline
                          : helpers.trimWord(data.excerpt)}
                      </Text>
                    )}
                  </>
                )}
              </View>
            </View>
          </View>
        </TouchableOpacityLink>
      </>
    );
  }
}

export default ArticleItemLayoutHorizontal;
