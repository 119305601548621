import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, sliderStyle, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import AuthenticationWrapper from '../authentication-wrapper';


const buyCredits = getImagePath('icons/buy-plan.png');
const assignCredits = getImagePath('icons/invite-members.png');
const unlockContent = getImagePath('icons/unlock-content.png');

const cookies = new Cookies();

const sliderData = [
    {
        img: buyCredits,
        title: 'Invite Members',
        content: 'Add members to your team from the Admin panel',
        count: 1,
    },
    {
        img: unlockContent,
        title: 'Start Reading' ,
        content: 'Member can sign in and start accessing all the stories & newsletters on TMC',
        count: 2,
    },
    {
        img: assignCredits,
        title: 'Assign Credits',
        content: 'Distribute privilege credits to your team',
        count: 3,
    },
];

export class CreditWorkFlow extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.appIntroSlider = React.createRef();
        this.checkScroll = true;
        const windowWidth = Dimensions.get('window').width;
        this.state = {
            activeSlide: 0,
            PlatformWeb: helpers.isDesktop(),
            slideItemWidth: windowWidth - 40,
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount() {
        if (helpers.isWeb()) {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        if (helpers.isWeb()) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize = () => {
        setTimeout(() => {
            const windowWidth = Dimensions.get('window').width
            if (windowWidth < 992) {
                this.setState({
                    PlatformWeb: false,
                    slideItemWidth: windowWidth - 40,
                })
            } else {
                this.setState({
                    PlatformWeb: true,
                    slideItemWidth: windowWidth - 40,
                })
            }
        }, 200)
    }

    getSlideIndex = (offset, width) => {
        let intervals = sliderData.length;

        for (let i = 1; i <= intervals; i++) {
            let index = Math.round((width / intervals) * i);
            if (Math.round(offset) < index) {
                return i-1;
            }
            if (i == intervals) {
                return i-1;
            }
        }
    }

    getSlideOffset = (offset, width) => {
        if(this.checkScroll) {
            let slideIndex = this.getSlideIndex(offset, width);
            this.setState({
                activeSlide: slideIndex
            })
            // if(this.props.subscriptionQuote) {
            //     if(helpers.isWeb()) {
            //         Mixpanel.track('swipe_reader_quotes', { position: slideIndex });
            //     }
            // }
        }
    }

    prevSlide = () => {
        // const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = sliderData.length;


        let totalSlideWidth = slideItemWidth - 80

        let totalWidth = Math.round(slideLength * totalSlideWidth);


        let scrollIndex;

        if (activeSlide > 2) {
            scrollIndex = Math.round(slideItemWidth * (activeSlide - 2));
        } else {
            scrollIndex = 0;
        }

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
    }

    nextSlide = () => {
        // const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = sliderData.length;

        let scrollIndex = Math.round(slideItemWidth * (activeSlide));

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })

    }

    slideTo = (index) => {
        // index = index === 0 ? 0 : index+1;
        this.checkScroll = false;
        // const { data } = this.props;
        const { slideItemWidth } = this.state;
        let slideLength = sliderData.length;

        let scrollIndex = Math.round(slideItemWidth * (index));
        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
        this.setState({ activeSlide: index }, () => {
            setTimeout(() => {
                this.checkScroll = true;
            },2000)
        })
    }

    render() {
        const {subscriptionQuote, teamSubscribers, teamSubscribersMob, darkMode } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let intervals = sliderData.length;
        let isWeb = helpers.isWeb();

        let dotClick;


        return (
            <View style={[sliderStyle.wrapper, {backgroundColor: 'transparent', marginLeft: 20, marginRight: 20 },!isWeb && {width: 'auto'}]}>
                <View style={[sliderStyle.dotWrapper, { bottom: 0 }]}>
                    {sliderData && sliderData.map((item, index) => {
                        if (isWeb) {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        } else {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        }
                         return (
                            <TouchableOpacity {...dotClick} activeOpacity={1}>
                                <View key={`dot_${index}`} style={{marginLeft: 12, marginRight: 12 }}>
                                    <Text style={[ {borderWidth: 1, borderColor: '#5869EA', color: '#5869EA', paddingHorizontal: helpers.isWeb() ? 12 : 14, paddingVertical: 8, borderRadius: 30, backgroundColor:  darkMode ? Colors.darkPurple : '#fafafa'}, (activeSlide == index) && { backgroundColor: '#5869EA', color: 'white'}]}>{item.count}</Text>
                                        {(index != 2 ) && <View style={{backgroundColor: '#5869EA', width: '100%', height: 1, position: 'absolute' , left: 0, right: 0, top: 16, width: 75, zIndex: -1}} />}
                                </View>
                            </TouchableOpacity>
                        )
                     })}
                </View>
                <ScrollView
                    contentContainerStyle={{ width: `${100 * intervals}%` }}
                    style={{ width: '100%' }}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled={true}
                    onScroll={data => {
                        this.getSlideOffset(data.nativeEvent.contentOffset.x, data.nativeEvent.contentSize.width)
                    }}
                    scrollEventThrottle={200}
                    decelerationRate="fast"
                    ref={ref => this.scrollView = ref}
                >

                {sliderData && sliderData.map((item, index) => {
                    return (
                        <View key={`slide_${index}`} style={[sliderStyle.sliderOuterItem, { width: slideItemWidth, paddingTop: 25 }, {marginRight: 20},!isWeb && {marginRight: 0, paddingHorizontal: 5}]}>
                            <View style={[{ maxWidth: '100%' , borderWidth: 1.4, borderColor: '#737BF6', backgroundColor: 'white' }, {height: 240}, darkMode && {backgroundColor: Colors.darkPurple}]}>
                                <View style={[sliderStyle.sliderItem1, Flex.alignCenter, {paddingLeft: 20, paddingRight: 20}]}>
                                    <CustomImage
                                        source={item.img}
                                        require={true}
                                        style={{ width: 40, height: 40, resizeMode: 'contain' }}
                                        webStyle={{ width: 'auto', height: 45 }}
                                        altText={item.title}
                                    />
                                    <Text style={[Padding.pt_2,Padding.pb_1,{ fontSize: 20, lineHeight: 24, fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && {color: Colors.lightWhite}]}>{item.title}</Text>
                                    <Text style={{textAlign: 'center', fontSize: 18, lineHeight: 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.9)', opacity: 0.85 }}>{item.content}</Text>
                                </View>
                            </View>
                        </View>
                    )
                })}
                </ScrollView>
                
            </View>
        );
    }
}

export default AuthenticationWrapper(CreditWorkFlow);
