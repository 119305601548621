import React, { Component } from "react";
import { View } from "react-native";
import ReactHtmlParser from "react-html-parser";
import RelatedStoryNudge from "../related-story-nudge";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { TouchableOpacity } from "react-native";

const corporateSubscribeBanner = getImagePath("img/corporate-subscribe-banner.png")


export class PostContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { data, darkMode, dynamicPage, relatedNudgeData, storyType } = this.props;
    const briefPostData = relatedNudgeData && relatedNudgeData["short_posts"] ? relatedNudgeData["short_posts"][relatedNudgeData["short_posts"].length - 1] : null
    const yesterdayPostData = relatedNudgeData && relatedNudgeData["yesterday_posts"] ? relatedNudgeData["yesterday_posts"][relatedNudgeData["yesterday_posts"].length - 1] : null
    const longreadPostData = relatedNudgeData && relatedNudgeData["regular_posts"] ? relatedNudgeData["regular_posts"][relatedNudgeData["regular_posts"].length - 1] : null
    const newsletterPostData = relatedNudgeData && relatedNudgeData["newsletter_posts"] ? relatedNudgeData["newsletter_posts"][relatedNudgeData["newsletter_posts"].length - 1] : null

    // Split the content by paragraphs
    let paragraphs = data.split(/(<p[^>]*>.*?<\/p>)/g);

    // Insert RelatedStoryNudge after the 2nd and 4th paragraphs
    if (paragraphs.length > 8) {
      paragraphs.splice(8, 0, '<div id="nudge4"></div>');
    }
    if (paragraphs.length > 4) {
      paragraphs.splice(4, 0, '<div id="nudge2"></div>');
    }

    // Combine the paragraphs back into a single HTML string
    // const modifiedData = paragraphs.join('');
    // console.log("Paragraph array before: >", data, "\n\n", modifiedData)
    // console.log("Paragraph array: >", paragraphs)
    let nudgeCount = 0;
    const modifiedData = data.replace(/(<\/p>)/g, (match) => {
      nudgeCount++;
      if (nudgeCount === 4) {
        return match + '<div id="nudge2"></div>';
      } else if (nudgeCount === 8) {
        return match + '<div id="nudge4"></div>';
      }
      return match;
    });


    return (
      <View style={{ flex: 1 }}>
        <div
          className={
            "postsingle-content postsingle-bodyfont" +
            (darkMode ? " dark-mode" : "") +
            (dynamicPage ? " postsingle-dynamic-page-content" : "")
          }
        >
          {ReactHtmlParser(modifiedData, {
            transform: (node) => {
              if (node.name === "div" && node.attribs && node.attribs.id === "nudge2") {
                console.log("Debugging post-content explainer: ", yesterdayPostData)
                return (storyType === "regular") && (yesterdayPostData || newsletterPostData) ? <RelatedStoryNudge {...(yesterdayPostData || newsletterPostData)} type={yesterdayPostData ? "NEWS EXPLAINER" : "OPINION"} key="nudge2" />
                  :
                  (storyType === "explainer" || storyType === "newsletter") && longreadPostData ? <RelatedStoryNudge {...longreadPostData} type={"LONGREAD"} key="nudge2" /> :
                    storyType === "short" ?
                      <TouchableOpacity onPress={() => {
                        window.location.href = '/pricing/corporate'
                      }} style={{ position: 'relative', width: '100vw', left: '50%', right: '50%', marginLeft: '-50vw', marginRight: '-50vw' }}>
                        <CustomImage
                          require={true}
                          source={corporateSubscribeBanner}
                          style={{ width: 24, height: 20 }}
                          webStyle={{ width: '100%', height: 'auto' }}
                          altText={'Corporate subscribe banner'}
                          resizeMode={'contain'}
                        />
                      </TouchableOpacity>
                      : null
                  ;
              }
              if (node.name === "div" && node.attribs && node.attribs.id === "nudge4") {
                return storyType === "short" ?
                  null
                  : briefPostData ? <RelatedStoryNudge {...briefPostData} type={"NEWS BRIEF"} key="nudge4" /> : null;
              }
              return undefined;
            },
          })}
        </div>
      </View>
    );
  }
}

export default PostContent;

