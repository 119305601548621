import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator, Dimensions, Linking, Platform } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, pageContent, pageCover, button, editor, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import TouchableOpacityLinkExternal from '../../components/touchable-opacity-link-external';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";

const emailIcon = getImagePath('icons/email.png');
const cookies = new Cookies();
const windowWidth = Dimensions.get('window').width;

const himanshuPhoto = getImagePath('img/himanshu-profile.jpg');
export class EditorialCodeOfConduct extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        this.state = {
            pageLoader: false,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false,
        }
    }

    componentDidMount = () => {

    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[helpers.isDesktop() ? Margin.mb_7 : Margin.mb_3, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, Flex.alignCenter, { marginTop: helpers.isDesktop() ? 80 : 55, paddingHorizontal: helpers.isDesktop() ? 20 : 20 }]}>
                <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_2, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, !helpers.isDesktop() && { fontSize: 34 }, darkMode && { color: Colors.lightWhite }]}>TMC Editorial Code</Text>
            </View>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }


    renderPageContent = () => {
        let btnClickEmail;
        if (helpers.isWeb()) {
            btnClickEmail = {
                href: `mailto:grievances@themorningcontext.com`
            }
        }
        else {
            btnClickEmail = {
                onPress: () => Linking.openURL(`mailto:grievances@themorningcontext.com`)
            }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                {/* <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Flex.alignCenter, Margin.mb_7, { marginTop: 0 }]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap]}>
                            <View style={[Helpers.borderBottom]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 24 }, { fontFamily: FontFamily.abrilFatface }]}>Our code of Conduct</Text>
                            </View>
                        </View>
                        <View style={[Helpers.titleLine, { backgroundColor: 'rgba(0, 0, 0, 0.6)' }]}></View>
                    </View>
                </View> */}
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb900]}>
                    <View style={[Margin.mb_4, Padding.ph_2]}>
                        <View style={[Margin.mt_2, Margin.mb_4, Flex.row, Flex.alignCenter]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[{ fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 22 }, { width: helpers.isWeb() ? 'fit-content' : 'auto', color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Our code of Conduct </Text>
                            </View>
                            {helpers.isDesktop() && <View style={{ height: 0.3, backgroundColor: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)', flex: 1, marginHorizontal: 15 }}></View>}
                        </View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>To ensure accuracy, integrity and credibility across The Morning Context newsroom, we hold ourselves (and everyone we work with) accountable to certain standards and journalistic practices. We call it the TMC Editorial Code.
                        </Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>The fundamental purpose of this code is to ensure that our writers and contributors follow the highest standards of ethical journalism. For us, this code is the bible that guides us whenever we are stuck. And for our readers, it is a peek into how we operate and conduct ourselves.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>The Morning Context’s investors and advertisers have no bearing on the editorial practices. And, to that end, we try to be as transparent as possible.</Text>
                    </View>
                    <View style={[Margin.mb_4, Padding.ph_2]}>
                        <View style={[Margin.mt_2, Margin.mb_4, Flex.row, Flex.alignCenter]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[{ fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 22 }, { width: helpers.isWeb() ? 'fit-content' : 'auto', color: '#000' }, darkMode && { color: Colors.lightWhite }]}>TMC Editorial Code </Text>
                            </View>
                            {helpers.isDesktop() && <View style={{ height: 0.3, backgroundColor: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)', flex: 1, marginHorizontal: 15 }}></View>}
                        </View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>The Morning Context editorial code applies to all reporters, editors and external contributors. All of them are expected to be familiar with this code; a violation may lead to disciplinary action. In case a particular situation is not covered by this document, writers and editors can consult with the editor-in-chief of The Morning Context.
                        </Text>
                    </View>
                    <View style={[Margin.mb_4, Padding.ph_2]}>
                        <View style={[Margin.mt_2, Margin.mb_4, Flex.row, Flex.alignCenter]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[{ fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 22 }, { width: helpers.isWeb() ? 'fit-content' : 'auto', color: '#000' }, darkMode && { color: Colors.lightWhite }]}>How we report </Text>
                            </View>
                            {helpers.isDesktop() && <View style={{ height: 0.3, backgroundColor: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)', flex: 1, marginHorizontal: 15 }}></View>}
                        </View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Conduct:</Text> While pursuing a story, our journalists should abide by the law. No information should be obtained illegally. The journalists should not misrepresent their identities and not pose as anyone else when gathering news. They should neither pay anyone to seek information or extract documents nor intimidate any individual or corporation to cooperate with them.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Collaborations:</Text> Twice a year, The Morning Context’s staff journalists are free to collaborate with any writer or organization, with the consent of the editor-in-chief to write, edit or undertake such activities as deemed fit. Such work, however, should not be undertaken within the official working hours as assigned by TMC or by the use of the organization’s resources. It shall be the duty of the employee to ensure that such activities are restricted to only two in number in a single year.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Sourcing:</Text> Journalists are as good as their sources. The foundation of these relationships is almost always trust and, just as easily, these relationships can get personal. While it is understandable that things can’t always be black-and-white in such relationships, journalists are expected to exercise good judgement at all times. They should ensure that their connections with sources are free of bias and will in no way hamper their objectivity. If, at any point, the relationship gets personal to the extent that bias is likely to creep in and reportage can end up being influenced in any way, journalists should reach out to the editor-in-chief and disclose such information.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>While speaking to a source for a story, journalists must clarify if the conversation is on the record (where you can attribute the information to the source), anonymous (where you can use the information without identifying the source) or off the record (where you can’t publish the information at all). They should respect the decision of the source and not try to influence it.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>The journalists must neither promise any favourable coverage nor kill an unfavourable story in exchange for personal favours. They should avoid any hospitality including gifts, meals, drinks, transportation and accommodation, extended by corporations or individuals. A lunch or dinner at a press event or a nominal tab picked up by a regular source every once in while is acceptable. Journalists should not accept any trips paid for by corporations or individuals. All such expenses are taken care of by The Morning Context. In case of any confusion, consult the editor-in-chief. These standards apply equally to all external contributors when they are on The Morning Context assignments.</Text>
                    </View>
                    <View style={[Margin.mb_4, Padding.ph_2]}>
                        <View style={[Margin.mt_2, Margin.mb_4, Flex.row, Flex.alignCenter]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[{ fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 22 }, { width: helpers.isWeb() ? 'fit-content' : 'auto', color: '#000' }, darkMode && { color: Colors.lightWhite }]}>How we write </Text>
                            </View>
                            {helpers.isDesktop() && <View style={{ height: 0.3, backgroundColor: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)', flex: 1, marginHorizontal: 15 }}></View>}
                        </View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>The other side:</Text> While writing stories that accuse or criticize the actions of individuals and corporations, writers are obligated to reach out to all the parties involved and get their side of the story. Ideally, give at least 48 hours to the parties to respond. An exception can be made, and a shorter response window given, at the discretion of the editor-in-chief, if the news story is urgent. Writers should incorporate the responses of the individuals and corporations in a way that conveys the latter’s point of view accurately.</Text>

                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Quotations and attribution:</Text> Writers should use the quotes of the speaker verbatim; the latter’s intent should be accurately conveyed in the story and must not be distorted to suit the writer’s own narrative in any way. Under no circumstances should a story be shared with anyone outside of The Morning Context before it is published; quotes, however, can be played back to speakers to verify their accuracy. Writers should have explicit consent from speakers to quote them.</Text>

                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>Writers also have a moral obligation to protect confidential sources. While quoting anonymous sources, however, they should try and establish the credibility and intent of such sources as much as they possibly can; this ensures that the authenticity of the story is not compromised and the trust of our readers is intact.</Text>

                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}>Writers must not plagiarize or fabricate any part of a story. Quotations from other works should be clearly attributed.</Text>

                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Corrections:</Text> No matter how rigorous our publishing standards are, we do slip up. And when we do, our priority is to fix our errors as quickly as we can. Writers are supposed to inform the editors about any material discrepancy in their published work as soon as they find out. Any factual error or misleading statement, no matter how small, will be rectified and details of the correction will be appended at the end of the story.</Text>

                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Transparency:</Text> The Morning Context staff must not use any financial information they acquire during reporting on a story for their personal gain. They must not disclose such information to their family members and friends either. If writers have any financial interest in a corporation or business that they are covering, they must disclose that to the editor-in-chief; it is then the responsibility of The Morning Context’s editors to appropriately disclose it to the readers. If the writer has any conflict of interest in covering a corporation, business, or individual—for instance, writing about people one is related to or has a personal relationship with—they should disclose such information to the editor-in-chief. At the company level, every story of The Morning Context must carry a statement that directs the reader to a detailed list of all its investors. The Morning Context strongly believes in keeping the editorial operations separate from its advertisers and sponsors; the latter must not have any influence on the stories we pursue and the stories we don’t.</Text>

                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Images and graphics:</Text> Images and infographics should be an accurate representation and should not in any way be fabricated. All the images, illustrations and graphics should be appropriately attributed.</Text>

                    </View>
                    <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb900, { paddingHorizontal: 20, marginBottom: '10vh' }]}>
                        {Platform.OS == 'web' &&
                            <Text style={[editor.description, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 30 : 26, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>If you need to reach out to our grievance redressal officer, visit our grievance redressal page <TouchableOpacityLinkExternal href={`/grievance-redressal`}>here</TouchableOpacityLinkExternal>.</Text>
                        }
                </View>
                </View>
                <View>
                    <AppFooter />
                </View>


            </>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/editorial-code-of-conduct',
            native: { redirect: '/editorial-code-of-conduct', verifyRedirect: '/editorial-code-of-conduct' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/editorial-code-of-conduct',
            native: { redirect: '/editorial-code-of-conduct', verifyRedirect: '/editorial-code-of-conduct' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={seoObj['editorial-code-of-conduct']} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/editorial-code-of-conduct" />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }
                    {showUparrow &&
                        <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                            <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                <CustomImage
                                    source={upArrowIcon}
                                    require={true}
                                    style={{ width: 60, height: 60 }}
                                    webStyle={{ width: 60, height: 60 }}
                                />
                            </TouchableOpacity>
                        </View>
                    }</>
                }

                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorialCodeOfConduct);


let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'TMC Editorial Code'
    }
]