import { Platform } from "react-native";
import getImagePath from "../utils/image-helper";

const staticData = {
    menu: [
        {
            name: "Discover TMC",
            "sub-menu": [
                {
                    name: "Internet",
                    link: "/category/internet",
                    description:
                        "Unravelling the world at the intersection of technology, companies and human need",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Business",
                    link: "/category/business",
                    description:
                        "Timely stories on some of the biggest and most important Indian corporations",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Chaos",
                    link: "/category/chaos",
                    description:
                        "Deep dives into the heart of the chaos—from jobs to the environment—that define our reality",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Newsletters",
                    link: "/newsletters",
                    description: "Learn more about our newsletters",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Company",
                    link: "/company",
                    description: "Check out or stories across various companies",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Free Reads",
                    link: "/free-reads",
                    description: "Read these stories for free! No subscription required",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "All Stories",
                    link: "/all-stories",
                    description: "View all our stories and newsletters",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Bookmarks",
                    link: "/bookmarks",
                    description:
                        "View all the stories or newsletters you have bookmarked to be read for later",
                    type: "internal",
                    access: "private",
                },
                {
                    name: "Unlocked stories",
                    link: "/unlocked-stories",
                    description:
                        "View all stories that you have unlocked using Privilege or Starter credits",
                    type: "internal",
                    access: "private",
                },
            ],
        },
        {
            name: "More about TMC",
            "sub-menu": [
                {
                    name: "About",
                    link: "/about-us",
                    description: "Learn more about our mission, goals and staff",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Writers",
                    link: "/writers",
                    description: "Know the writers who write the content",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Investors",
                    link: "/investors",
                    description: "Know more about our investors",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Team",
                    link: "/team",
                    description: "See the team behind TMC",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "Leadership",
                    link: "/leadership",
                    description: "Know our Leadership team",
                    type: "internal",
                    access: "public",
                },
                {
                    name: "TMC Editorial Code",
                    link: "/editorial-code-of-conduct",
                    description: "Know about our editorial code ethics",
                    type: "internal",
                    access: "public",
                },
            ],
        },
        {
            name: "Other useful sections",
            "sub-menu":
                Platform.OS != "ios"
                    ? [
                        {
                            name: "Subscriptions",
                            link: "/pricing",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Gifting",
                            link: "/gifting",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "FAQ",
                            link: "/faq",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Privacy Policy",
                            link: "/privacy-policy",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Terms and Conditions",
                            link: "/terms-and-conditions",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Contact us",
                            link: "support@themorningcontext.com",
                            type: "email",
                            access: "public",
                        },
                    ]
                    : [
                        {
                            name: "Gifting",
                            link: "/gifting",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "FAQ",
                            link: "/faq",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Privacy Policy",
                            link: "/privacy-policy",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Terms and Conditions",
                            link: "/terms-and-conditions",
                            description: "",
                            type: "internal",
                            access: "public",
                        },
                        {
                            name: "Contact us",
                            link: "support@themorningcontext.com",
                            type: "email",
                            access: "public",
                        },
                    ],
        },
        {
            name: "Blog",
            "sub-menu": [
                {
                    name: "The Morning Context - on the record",
                    link: "/blog",
                    type: "internal",
                    access: "public",
                },
            ],
        },
    ],
    reader_quotes: [
        {
            order: 1,
            by: "Nikhil Kamath",
            designation: "Co-founder",
            org: "Zerodha",
            text: "With most traditional media biased in one direction or another, The Morning Context has the courage to state hard hitting facts, which openly stands out from the crowd. Their in-depth reporting often covers both sides of a story, which is rare today.",
            image: getImagePath("img/Nikhil-Kamath.png"),
        },
        {
            order: 2,
            by: "Abhay Pandey",
            designation: "Managing partner",
            org: "A91 Partners",
            text: "High quality, independent journalism is rare to find. The Morning Context set really high standards that make me wait for each story.",
            image: getImagePath("img/Abhay-Pandey.png"),
        },
        {
            order: 3,
            by: "Sanjay Bakshi",
            designation: "Managing Partner",
            org: "ValueQuest Capital LLP",
            text: "I have been a paid subscriber of The Morning Context since 2020. It's one of the few publications that fearlessly publishes well-researched investigative stories that are not found in many other publications. I am a fan.",
            image: getImagePath("img/Sanjay-Bakshi.png"),
        },
        {
            order: 4,
            by: "Nilesh Shah ",
            designation: "MD,Kotak Mahindra Asset Management and Member",
            org: "Economic Advisory Council to Prime Minister",
            text: "I read The Morning Context for in-depth analysis on interesting aspects of financial world. Their unbiased analysis presents 360 degree view of subject.",
            image: getImagePath("img/Nilesh-Shah.png"),
        },
        {
            order: 5,
            by: "Harsh Mariwala ",
            designation: "Chairman",
            org: "Marico Ltd",
            text: "I enjoy reading The Morning Context. Its  matter-of-fact reporting is refreshing and the deep analysis is informative.  I also enjoy reading the newsletters that touch upon several topics.",
            image: getImagePath("img/Harsh-Mariwala.png"),
        },
        {
            order: 6,
            by: "Sherbir Panag ",
            designation: "Managing Partner",
            org: " Panag & Babu",
            text: "The Morning Context has earned its place among India’s leading financial news institutions and sets benchmarks every day in investigative journalism.",
            image: getImagePath("img/Sherbir-Panag.png"),
        },
        {
            order: 7,
            by: "Amod Malviya",
            designation: "Co-founder",
            org: "Udaan",
            text: "Going through a week's coverage from The Morning Context is all it would take for any new reader to be convinced about the worth of their subscription. I've found them to be earnest & unafraid in their reporting, and that's always worth backing.",
            image: getImagePath("img/Amod-Malviya.png"),
        },
        {
            order: 8,
            by: "Uday Shankar",
            designation: "Director",
            org: "Bodhi Tree Systems and media entrepreneur",
            text: "Journalism in india has a crisis—crisis of credibility and crisis of capability. It bothers me both as a citizen and as a former journalist. I read the The Morning Context because it’s journalism as it should be—thoughtful, credible and provocative.",
            image: getImagePath("img/Uday-Shankar.png"),
        },
        {
            order: 9,
            by: "Amajit Batra",
            designation: "Managing Director",
            org: "Spotify India",
            text: "As one of the early subscribers, I've had the opportunity to witness how quickly the team has grown, the variety of angles covered, and the research in every in-depth story and op-ed.",
            image: getImagePath("img/Amarjit-Batra.png"),
        },
        {
            order: 10,
            by: "Rohit Chandra",
            designation: "Assistant Professor",
            org: "School of Public Policy (IIT Delhi)",
            text: "TMC consistently makes me think about things in different ways, and I've frequently shared TMC articles with collaborators and students. Staying at the cutting edge is hard enough; TMC makes it much easier.",
            image: getImagePath("img/Rohit-Chandra.png"),
        },
    ],
};

export { staticData };
