import React, { useState } from "react";
import {
  View,
  TextInput,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Colors,
  FontFamily,
  FontWeight,
  button,
  modalStyle,
  Typo,
  formStyle,
  unlockBox,
} from "../../styles/appStyles";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
const googleIcon = getImagePath("icons/popup-google.png");

const SignupFormTextComponentWeb = (props) => {
  const [email, setEmail] = useState("");

  return (
    <View style={styles.container}>
      <TextInput
        style={props.textInputStyle || styles.textInput}
        placeholder="Enter your email"
        placeholderTextColor={props.textInputStyleTextColor}
        value={email}
        onChangeText={setEmail}
      />
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.signupButton}
          onPress={() => props.goToSignUp(email)}
        >
          <Text style={styles.signupButtonText}>Sign me up</Text>
        </TouchableOpacity>
        <Text style={props.orText || styles.orText}>OR</Text>
        <TouchableOpacity
          style={styles.googleButton}
          onPress={props.goToGoogleLoginWeb}
        >
          <CustomImage
            source={googleIcon}
            require={true}
            webStyle={{
              width: 30,
              height: 30,
              marginRight: 10,
              marginLeft: 6,
            }}
            style={{ width: 30, height: 30, marginRight: 10, marginLeft: 6 }}
          />
          <Text style={styles.googleButtonText}>Sign up with Google</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: 600,
  },
  textInput: {
    borderBottomWidth: 2,
    marginBottom: 20,
    width: "100%",
    marginRight: 0,
    paddingTop: 8,
    paddingBottom: 8, 
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
    height: 50,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  signupButton: {
    backgroundColor: "#43CEBD",
    padding: 10,
    width: 150,
    display: 'flex',
    textAlign: 'cenetr',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  signupButtonText: {
    textTransform: 'uppercase',
    fontWeight: 900,
    fontSize: 18,
    lineHeight: 30,
    color: '#ffffff',
    fontFamily: FontFamily.regular,
    textAlign: 'cenetr',
  },
  orText: {
    color: '#051462',
    marginHorizontal: 10,
    padding: 8,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
  },
  googleButtonContainer: {
    flexDirection: "row",
    marginBottom: 40,
  },
  googleButton: {
    backgroundColor: "white",
    borderRadius: 0,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  googleButtonText: {
    color: "#292929",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
  },
});

export default SignupFormTextComponentWeb;
