import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
  TextInput,
  TouchableOpacity,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
  input,
  Colors,
  Messages,
  pageCover,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import ArticleItemHorizontal from "../article-item-horizontal";
import ScrollContainerView from "../scroll-container-view";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import ArticleItem from "../article-item";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../button-gradient";
import Cookies from "universal-cookie";
import { Mixpanel } from "../../utils/mixpanel";
import AuthenticationWrapper from "../authentication-wrapper";
import YoutubeVideo from "../vimeo-video/youtube-video";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";

const viewMore = getImagePath("icons/view-more.png");
const checkIcon = getImagePath("icons/check.png");
const cookies = new Cookies();
const windowWidth = Dimensions.get("window").width;
const healthcare = getImagePath("img/m-fintech-img.png");
const yesterdayLogo = getImagePath("icons/yesterdayLogo.png");

export class HomeArchievedContent extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      btnDisable: true,
      email: "",
      token: token,
      referralMsg: {
        enable: false,
        type: "", // error / success
        msg: "",
      },
      showToken: !helpers.isWeb() || process.browser ? true : false,
      homesection: this.props?.homesection ? this.props.homesection : false,
    };
  }

  componentDidMount = () => {};

  viewMoreArticles = () => {
    const { viewAllArticles } = this.props;
    viewAllArticles();
  };

  viewArticle = (catSlug, slug, postData) => {
    const { viewArticle } = this.props;
    viewArticle(catSlug, slug, postData);
  };

  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };
  viewCategory = (slug) => {
    const { viewCategory } = this.props;
    viewCategory(slug);
  };

  signUp = () => {
    const { email } = this.state;
    this.setState({
      btnLoader: true,
      referralMsg: {
        enable: false,
        type: "",
        msg: "",
      },
    });

    // if (helpers.validateEmail(email)) {
    this.setState({
      btnLoader: false,
    });
    try {
      if (this.props.trackSignUp) {
        this.props.trackSignUp(email, "mid banner");
      }
    } catch (error) {
      console.log("mix panel track error ==>", error);
    }
    if (helpers.isWeb()) {
      this.props.navigateWeb(`/sign-up?redirect=/free-reads&email=${email}`);
    } else {
      this.props.navigateApp("/sign-up", {
        redirect: "/free-reads",
        verifyRedirect: "/free-reads",
        email: email,
      });
    }
    // }
    // else {
    //     this.setState({
    //         btnLoader: false,
    //         referralMsg: {
    //             enable: true,
    //             type: 'error',
    //             msg: 'Please enter a valid email'
    //         }
    //     });
    // }
  };

  goToFreeReads = () => {
    if (helpers.isWeb()) {
      this.props.navigateWeb(`/free-reads`);
    } else {
      this.props.navigateApp("/free-reads");
    }
  };

  // validateInput = (value) => {
  //     if (value) {
  //         this.setState({ btnDisable: false, email: value });
  //     } else {
  //         this.setState({ email: value, btnDisable: true })
  //     }
  // }

  handleClick = (title, data, index) => {
    try {
      if (this.props.trackStoryClick) {
        this.props.trackStoryClick(title, data, index);
      }
    } catch (error) {
      console.log("check mix panel track error ==>", error);
    }
  };

  trackCateoryClick = (item) => {
    // try {
    //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: 'home_starter_collection', section_value: { title: item.name, slug: item.slug } });
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  render() {
    let { email, btnDisable, referralMsg, token } = this.state;
    const { data, darkMode, freeUser } = this.props;
    const videoIdUrl = "https://youtu.be/ExJ7-6qIq9o";
    const videoId = "ExJ7-6qIq9o";

    let webView = helpers.isWeb();
    let btnClick, btnClickCategory, btnClickFreeStories;

    if (helpers.isWeb()) {
      btnClick = {
        to: "/archive",
      };
      btnClickFreeStories = {
        href: "/free-reads",
      };
    } else {
      btnClick = {
        onPress: () => this.viewMoreArticles(),
      };
      btnClickFreeStories = {
        onPress: () => this.props.navigateApp("/free-reads"),
      };
    }
    return (
      <View
        style={{
          backgroundColor: darkMode
            ? Colors.darkBlackColor
            : this.props.show
            ? "#ffffff"
            : "#ffffff",
          // marginTop: helpers.isDesktop() ? 80 : 40,
          // paddingBottom: helpers.isDesktop() ? 100 : 20,
        }}
      >
        {/* <View
          style={[
            article.headerWrapper,
            !helpers.isDesktop() && { left: 0 },
            !helpers.isDesktop() && { right: 0 },
            !helpers.isDesktop() && { top: 0 },
          ]}
        >
          <View
            style={[
              Flex.alignCenter,
              {
                backgroundColor: "#FFFFFF",
              },
            ]}
          >
            <View
              style={[
                Padding.ph_2,
                Helpers.titleBgWrap,
                helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColor : "#FFFFFF",
                },
                !helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : "#FFFFFF",
                },
                { marginTop: helpers.isDesktop() ? 20 : 0, marginBottom: 9 },
              ]}
            >
              <View>
                <Text
                  style={[
                    Margin.mt_0,
                    pageCover.title,
                    helpers.isDesktop() && pageCover.titleDesktop,
                    helpers.isDesktop() && { bottom: 24 },
                    Helpers.textCenter,
                    {
                      position: "relative",
                      color: "#292929",
                      fontSize: "34px",
                      lineHeight: "55px",
                      fontFamily: "PlayfairDisplay-Bold",
                      fontWeight: "700",
                      borderBottomWidth: helpers.isDesktop() ? "2px" : 0,
                      borderBottomColor: "#292929",
                      borderStyle: "solid",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Starter Collection
                </Text>
              </View>
            </View>
            {helpers.isDesktop() && (
              <View
                style={[
                  Helpers.titleLine,
                  darkMode && { backgroundColor: "#F3F5FF" },
                  {
                    maxWidth: "calc(1216px - 30px)",
                  },
                ]}
              ></View>
            )}
          </View>
        </View>
        <View
          style={{
            maxWidth: "1216px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            marginBottom: 40,
            paddingHorizontal: helpers.isDesktop() ? "15px" : "20px",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: helpers.isDesktop() ? 18 : 14,
              lineHeight: helpers.isDesktop() ? "27px" : "21px",
              letterSpacing: "0.3px",
              color: "#292929",
              fontFamily: FontFamily.regular,
              fontWeight: 400,
            }}
          >
            Get access to our Starter Collection by signing up for FREE
          </Text>
        </View> */}

        <View
          style={[
            article.headerWrapper,
            !helpers.isDesktop() && { left: 0 },
            !helpers.isDesktop() && { right: 0 },
            !helpers.isDesktop() && { top: 0 },
          ]}
        >
          <View
            style={[
              Flex.alignCenter,
              {
                backgroundColor: "#ffffff",
                marginTop: helpers.isDesktop() ? "80px" : "59px",
                marginBottom: helpers.isDesktop() ? "11px" : "16px",
              },
            ]}
          >
            <View
              style={[
                Padding.ph_2,
                Helpers.titleBgWrap,
                helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColor : "#ffffff",
                },
                !helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : "#ffffff",
                },
                { marginTop: helpers.isDesktop() ? 20 : 0 },
              ]}
            >
              <View>
                <Text
                  style={[
                    Margin.mt_0,
                    Margin.mb_0,
                    pageCover.title,
                    helpers.isDesktop() && pageCover.titleDesktop,
                    helpers.isDesktop() && { bottom: 15 },
                    Helpers.textCenter,
                    {
                      position: "relative",
                      color: "#292929",
                      fontSize: "34px",
                      lineHeight: "51px",
                      fontFamily: "PlayfairDisplay-Bold",
                      fontWeight: "700",
                      borderBottomWidth: helpers.isDesktop() ? "2px" : 0,
                      borderBottomColor: "#292929",
                      borderStyle: "solid",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Free Reads
                </Text>
              </View>
            </View>
            {helpers.isDesktop() && (
              <View
                style={[
                  Helpers.titleLine,
                  darkMode && { backgroundColor: "#F3F5FF" },
                  {
                    maxWidth: "calc(1216px - 32px)",
                  },
                ]}
              ></View>
            )}
          </View>
        </View>
        {!this.state.token || freeUser ? (
          <View
            style={{
              maxWidth: "calc(1216px - 32px)",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: helpers.isDesktop() ? 32 : 24,
              paddingHorizontal: helpers.isDesktop() ? "15px" : "20px",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: helpers.isDesktop() ? 18 : 14,
                lineHeight: helpers.isDesktop() ? "27px" : "21px",
                letterSpacing: "0.3px",
                color: "#292929",
                fontFamily: FontFamily.regular,
                fontWeight: 400,
              }}
            >
              Get access to our Starter Collection by signing up for FREE
            </Text>
          </View>
        ) : null}

        <View
          style={[
            Helpers.conatinerWeb,
            // helpers.isDesktop() && Helpers.conatinerWebFull,
            {
              maxWidth: "1216px",
            },
          ]}
        >
          <View
            style={[
              article.headerWrapper,
              !helpers.isDesktop() && { position: "absolute" },
              !helpers.isDesktop() && { left: 0 },
              !helpers.isDesktop() && { right: 0 },
              !helpers.isDesktop() && { top: 0 },
            ]}
          >
            <View
              style={[
                Flex.alignCenter,
                // helpers.isDesktop() && Helpers.conatinerWeb1200,
                !helpers.isDesktop() && Padding.pb_2,
                {
                  backgroundColor: this.state.token
                    ? "#FAFAFA"
                    : this.props.show
                    ? "#ffffff"
                    : "#ffffff",
                  alignSelf: "center",
                  width: "100%",
                  maxWidth: "1216px",
                  marginLeft: "auto",
                  marginRight: "auto",
                },
              ]}
            ></View>
          </View>

          <View style={[Margin.mt_0, Margin.mb_4]}>
            {helpers.isDesktop() ? (
              <View style={[Flex.row, Flex.justfiyCenter]}>
                {data &&
                  data.map((item, index) => {
                    if (helpers.isWeb()) {
                      btnClickCategory = {
                        to: "/category/" + item.category.slug,
                      };
                    } else {
                      btnClickCategory = {
                        onPress: () => this.viewCategory(item.category.slug),
                      };
                    }
                    return (
                      <View
                        style={[
                          articleItem.col4,
                          helpers.isDesktop() && articleItem.col4Desktop,
                          { paddingHorizontal: 16 },
                          { width: helpers.isDesktop() ? "33.33%" : "100%" },
                          index == 2 && { marginRight: 0 },
                        ]}
                      >
                        <ArticleItemHorizontal
                          viewArticle={(catSlug, slug, postData) =>
                            this.viewArticle(catSlug, slug, postData)
                          }
                          viewAuthor={(slug) => this.viewAuthor(slug)}
                          data={item}
                          single={index == 0 ? true : false}
                          homepage={true}
                          handleClick={(title) => {
                            this.handleClick(title, item, index);
                          }}
                          hideDate={false}
                          darkMode={darkMode}
                          homesection={this.state.homesection}
                          sectionName="Free Reads"
                          token={this.state.token}
                        />
                      </View>
                    );
                  })}
              </View>
            ) : (
              <View>
                {helpers.isWeb() ? (
                  <View
                    style={{
                      maxWidth: "1216px",
                      width: "100%",
                      marginHorizontal: "auto",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: helpers.isDesktop() ? "row" : "column",
                      }}
                    >
                      {data &&
                        data.map((item, index) => {
                          return (
                            <View>
                              <ArticleItemHorizontal
                                viewArticle={(catSlug, slug, postData) =>
                                  this.viewArticle(catSlug, slug, postData)
                                }
                                viewAuthor={(slug) => this.viewAuthor(slug)}
                                data={item}
                                single={index == 0 ? true : false}
                                homepage={true}
                                handleClick={(title) => {
                                  this.handleClick(title, item, index);
                                }}
                                hideDate={false}
                                darkMode={darkMode}
                                homesection={this.state.homesection}
                                sectionName="Free Reads"
                                token={this.state.token}
                              />
                            </View>
                          );
                        })}
                    </View>
                  </View>
                ) : (
                  <ScrollContainerView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                  >
                    <View
                      style={[
                        Flex.row,
                        Padding.pt_4,
                        Margin.mb_4,
                        !process.browser && { overflow: "hidden" },
                        !helpers.isDesktop() && { marginTop: 100 },
                      ]}
                    >
                      {data &&
                        data.map((item, index) => {
                          if (helpers.isWeb()) {
                            btnClickCategory = {
                              to: "/category/" + item.category.slug,
                            };
                          } else {
                            btnClickCategory = {
                              onPress: () =>
                                this.viewCategory(item.category.slug),
                            };
                          }
                          return (
                            <View
                              style={[
                                articleItem.col3,
                                helpers.isDesktop() && articleItem.col3Desktop,
                                !helpers.isDesktop() && { marginTop: 80 },
                                !webView && {
                                  width: windowWidth - 100,
                                  marginRight: -20,
                                },
                                !webView && index != 0 && { marginLeft: 40 },
                              ]}
                            >
                              <ArticleItemHorizontal
                                viewArticle={(catSlug, slug, postData) =>
                                  this.viewArticle(catSlug, slug, postData)
                                }
                                viewAuthor={(slug) => this.viewAuthor(slug)}
                                data={item}
                                single={index == 0 ? true : false}
                                homepage={true}
                                handleClick={(title) => {
                                  this.handleClick(title, item, index);
                                }}
                                hideDate={false}
                                darkMode={darkMode}
                                homesection={this.state.homesection}
                                sectionName="Free Reads"
                                token={this.state.token}
                              />
                            </View>
                          );
                        })}
                      <View style={{ marginRight: 20, width: 1 }}></View>
                    </View>
                  </ScrollContainerView>
                )}
              </View>
            )}
          </View>
          <View
            style={[
              helpers.isDesktop() && Padding.pt_4,
              {
                paddingBottom: helpers.isDesktop() ? "128px" : 20,
              },
            ]}
          >
            {!this.state.token || freeUser ? (
              <>
                <View
                  style={{
                    width: helpers.isWeb() ? "fit-content" : "auto",
                    alignSelf: "center",
                    paddingHorizontal: helpers.isWeb() ? "16px" : "20px",
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 24,
                        lineHeight: 28.8,
                        fontFamily: "PLayfairDisplay-Bold",
                        fontWeight: 700,
                        textAlign: "center",
                        paddingBottom: helpers.isDesktop() ? 40 : 24,
                        color: "#292929",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Sign up now to access the Starter Collection
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                  }}
                >
                  <View
                    style={[
                      helpers.isDesktop() && { flex: 1 },
                      Padding.pb_2,
                      {
                        paddingHorizontal: helpers.isDesktop() ? 40 : 20,
                        paddingLeft: helpers.isDesktop() ? 16 : 20,
                        // borderWidth: 2,
                        // borderColor: "#6C7AF6",
                        backgroundColor: darkMode
                          ? Colors.darkBlackColor1
                          : "white",
                        width: helpers.isDesktop() ? "50%" : "100%",
                      },
                      !helpers.isDesktop() && {
                        width: "100%",
                        marginHorizontal: "auto",
                      },
                    ]}
                  >
                    {/* <Text
                  style={[
                    Padding.pb_2,
                    Padding.pt_15,
                    {
                      fontSize: helpers.isDesktop() ? 36 : 22,
                      lineHeight: helpers.isDesktop() ? 54 : 33,
                      fontFamily: FontFamily.abrilFatface,
                      color: "#051462",
                    },
                    darkMode && {
                      fontWeight: FontWeight.medium,
                      letterSpacing: 0.7,
                    },
                  ]}
                >
                  Free 100 Starter Credits
                </Text> */}
                    <View
                      style={[
                        Flex.row,
                        Flex.alignStart,
                        { marginBottom: helpers.isDesktop() ? 16 : 12 },
                      ]}
                    >
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 19,
                            height: 13,
                            resizeMode: "contain",
                            marginTop: 9,
                            marginRight: 12,
                          }}
                          webStyle={{
                            width: 19,
                            height: 13,
                            objectFit: "scale-down",
                            marginTop: 9,
                            marginRight: 12,
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontFamily: FontFamily.semiBold,
                          fontWeight: 600,
                          fontSize: helpers.isDesktop() ? 19 : 16,
                          lineHeight: helpers.isDesktop() ? 32.3 : 27.2,
                          letterSpacing: 0.3,
                          flex: 1,
                          color: darkMode ? Colors.lightWhite : "#292929",
                        }}
                      >
                        Get FREE 30 Starter Credits
                      </Text>
                    </View>
                    <View
                      style={[
                        Flex.row,
                        Flex.alignStart,
                        { marginBottom: helpers.isDesktop() ? 16 : 12 },
                      ]}
                    >
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 19,
                            height: 13,
                            resizeMode: "contain",
                            marginTop: 9,
                            marginRight: 12,
                          }}
                          webStyle={{
                            width: 19,
                            height: 13,
                            objectFit: "scale-down",
                            marginTop: 9,
                            marginRight: 12,
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontFamily: FontFamily.regular,
                          fontWeight: "400",
                          fontSize: helpers.isDesktop() ? 19 : 16,
                          lineHeight: helpers.isDesktop() ? 32.3 : 27.2,
                          letterSpacing: 0.3,
                          flex: 1,
                          color: darkMode ? Colors.lightWhite : "#292929",
                        }}
                      >
                        Use Starter Credits to unlock some of the best free
                        longreads and newsletters from our Starter Collection
                        {/* Use Starter Credits to unlock some of the best free longreads and newsletters and long reads from our Starter Collection */}
                      </Text>
                    </View>
                    <View
                      style={[
                        Flex.row,
                        Flex.alignStart,
                        { marginBottom: helpers.isDesktop() ? 24 : 4 },
                      ]}
                    >
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 19,
                            height: 13,
                            resizeMode: "contain",
                            marginTop: 9,
                            marginRight: 12,
                          }}
                          webStyle={{
                            width: 19,
                            height: 13,
                            objectFit: "scale-down",
                            marginTop: 9,
                            marginRight: 12,
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontFamily: FontFamily.regular,
                          fontWeight: "400",
                          fontSize: helpers.isDesktop() ? 19 : 16,
                          lineHeight: helpers.isDesktop() ? 32.3 : 27.2,
                          letterSpacing: 0.3,
                          flex: 1,
                          color: darkMode ? Colors.lightWhite : "#292929",
                        }}
                      >
                        Starter Credits{" "}
                        <Text
                          style={{
                            fontFamily: FontFamily.semiBold,
                            fontWeight: 600,
                            fontSize: helpers.isDesktop() ? 19 : 16,
                            lineHeight: helpers.isDesktop() ? 32.3 : 27.2,
                            letterSpacing: 0.3,
                            flex: 1,
                            color: darkMode ? Colors.lightWhite : "#292929",
                          }}
                        >
                          do not expire
                        </Text>
                        {/* {" "} */}
                        {/* – use them on stories and newsletters of your choice */}
                      </Text>
                    </View>
                    {!this.state.token ? (
                      <View>
                        <View
                          style={[
                            helpers.isDesktop() && Flex.row,
                            { width: !helpers.isDesktop() ? "100%" : 244 },
                            {
                              alignSelf: "flex-start",
                              marginTop: !helpers.isDesktop() ? 20 : 0,
                            },
                          ]}
                        >
                          <ButtonGradient
                            title={"GET STARTED"}
                            rounded={true}
                            rootStyle={{
                              btnWrapper: [
                                button.primaryGradientBtn,
                                {
                                  height: 46,
                                  borderRadius: 0,
                                  flex: 1,
                                  position: "relative",
                                  right: helpers.isDesktop() ? 1.5 : 0,
                                },
                              ],
                              btnText: [
                                button.primaryGradientText,
                                {
                                  fontSize: helpers.isDesktop() ? 17 : 16,
                                  fontWeight: "600",
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingTop: helpers.isDesktop() ? 0 : 11,
                                  paddingBottom: helpers.isDesktop() ? 0 : 11,
                                },
                              ],
                            }}
                            onClick={this.signUp}
                            disable={false}
                            darkMode={darkMode}
                          />
                        </View>
                      </View>
                    ) : null}
                    {/* {
                        this.state.token && freeUser ? (
                          <View>
                            <View
                              style={[
                                helpers.isDesktop() && Flex.row,
                                !helpers.isDesktop() && { width: "100%" },
                                {
                                  alignSelf: "flex-start",
                                  marginTop: !helpers.isDesktop() ? 20 : 0,
                                },
                              ]}
                            >
                              <TouchableOpacityLinkExternal {...btnClickFreeStories} newTab={true}>
                                <ButtonGradient
                                  title={"VIEW ALL FREE STORIES"}
                                  rootStyle={{
                                    btnWrapper: [button.primaryGradient, { height: 55 }],
                                    btnText: [
                                      button.primaryGradientText,
                                      {
                                        fontSize: 17,
                                        paddingHorizontal: 35,
                                        fontWeight: "600",
                                        fontFamily: FontFamily.regular,
                                      },
                                    ],
                                  }}
                                  paddingOverride={true}
                                  onClick={() => { }}
                                  darkMode={darkMode}
                                  isNewGradiant={true}
                                />
                              </TouchableOpacityLinkExternal>
                            </View>
                          </View>
                        ) : null
                      } */}

                    {referralMsg.enable && (
                      <View style={[{ marginTop: 5 }]}>
                        <Text
                          style={[
                            referralMsg.type == "success"
                              ? Messages.successText
                              : Messages.errorText,
                          ]}
                        >
                          {referralMsg.msg}
                        </Text>
                      </View>
                    )}
                    <View
                      style={[
                        !helpers.isDesktop() && { alignSelf: "center" },
                        {
                          display: "flex",
                          marginTop: helpers.isDesktop() ? 20 : 20,
                          // marginBottom: helpers.isDesktop() ? 0 : 59,
                          justifyContent: "flex-start",
                          width: helpers.isDesktop() ? 244 : "100%",
                          height: helpers.isDesktop() ? 50 : 40,
                          paddingLeft: helpers.isDesktop() ? 0 : 0,
                        },
                      ]}
                    >
                      <ButtonGradient
                        title={"View free stories"}
                        rounded={true}
                        rootStyle={{
                          btnWrapper: [
                            button.primaryGradientBtn,
                            {
                              height: 46,
                              borderRadius: 0,
                              flex: 1,
                              position: "relative",
                              right: helpers.isDesktop() ? 1.5 : 0,
                            },
                          ],
                          btnText: [
                            button.primaryGradientText,
                            {
                              fontSize: helpers.isDesktop() ? 17 : 16,
                              fontWeight: "600",
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: helpers.isDesktop() ? 0 : 11,
                              paddingBottom: helpers.isDesktop() ? 0 : 11,
                            },
                          ],
                        }}
                        onClick={this.goToFreeReads}
                        disable={false}
                        darkMode={darkMode}
                        isNewGradiant={true}
                      />
                    </View>
                  </View>
                  <View
                    style={[
                      Flex.FlexWrap,
                      Flex.fill,
                      {
                        alignSelf: "center",
                        alignItems: helpers.isDesktop() ? "flex-end" : "center",
                        backgroundColor: darkMode ? Colors.darkBlackColor1 : "",
                        width: helpers.isDesktop() ? "50%" : "100%",
                      },
                      !helpers.isDesktop() && {
                        width: "100%",
                        height: 209,
                        marginBottom: 80,
                        paddingTop: 20,
                        paddingHorizontal: 20,
                      },
                    ]}
                  >
                    {helpers.isWeb() ? (
                      <YoutubeVideo videoId={videoIdUrl} home={true} />
                    ) : (
                      <YoutubeVideo
                        videoId={videoId}
                        videoIdUrl={videoIdUrl}
                        home={true}
                      />
                    )}
                  </View>
                </View>
              </>
            ) : null}
            {/* <View style={[
              !helpers.isDesktop() && { alignSelf: 'center', },
              {
                display: "flex",
                marginTop: helpers.isDesktop() ? 0 : 20,
                // marginBottom: helpers.isDesktop() ? 0 : 59,
                justifyContent: "flex-start",
                width: helpers.isDesktop() ? 260 : "90%",
                height: 50,
                paddingLeft: helpers.isDesktop() ? 16 : 0
              }]}>
              <ButtonGradient
                title={"View free stories"}
                rounded={true}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradientBtn,
                    {
                      height: 46,
                      borderRadius: 0,
                      flex: 1,
                      position: "relative",
                      right: helpers.isDesktop() ? 1.5 : 0,
                    },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: helpers.isDesktop() ? 17 : 16,
                      fontWeight: "400",
                      paddingLeft: 35,
                      paddingRight: 35,
                      paddingTop: helpers.isDesktop() ? 0 : 11,
                      paddingBottom: helpers.isDesktop() ? 0 : 11,
                    },
                  ],
                }}
                onClick={this.goToFreeReads}
                disable={false}
                darkMode={darkMode}
                isNewGradiant={true}
              />
            </View> */}
          </View>
        </View>
      </View>
    );
  }
}

export default AuthenticationWrapper(HomeArchievedContent);
