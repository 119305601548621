import React, { Component } from 'react';
import { Text, View, } from 'react-native';
import { Flex, Padding, Margin } from '../../styles/appStyles';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const infoIcon = getImagePath('icons/info-red.png');
const successIcon = getImagePath('icons/success.png');
export default class NetworkDetector extends Component {
    toastTimeout;
    constructor(props) {
        super(props);
        this.state = {
            isDisconnected: false,
            showToast: false
        }
    }

    componentDidMount() {
        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    clearToastTimeout = () => {
        if (this.toastTimeout) {
            clearTimeout(this.toastTimeout);
        }
    }


    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        this.clearToastTimeout();
        if (condition === 'online') {
            this.setState({ isDisconnected: false, showToast: true });
            this.toastTimeout = setTimeout(() => {
                this.setState({ showToast: false });
            }, 4000);
            this.props.isDisconnected(false);
            // const webPing = setInterval(
            //     () => {
            //         fetch('//google.com', {
            //             mode: 'no-cors',
            //         })
            //             .then(() => {
            //                 this.setState({ isDisconnected: false }, () => {
            //                     return clearInterval(webPing)
            //                 });
            //             })
            //             .catch(() => this.setState({ isDisconnected: true }))
            //     }, 2000);
            return;
        }

        this.setState({ isDisconnected: true, showToast: true });
        this.toastTimeout = setTimeout(() => {
            this.setState({ showToast: false });
        }, 4000);
        this.props.isDisconnected(true);
    }

    render() {
        let { isDisconnected, showToast } = this.state;

        return (
            <View style={[{ position: 'relative', width: 'auto' }]}>
                {isDisconnected && showToast &&
                    <View style={[Flex.row, Flex.justfiyCenter, Flex.alignCenter, Padding.pv_1, Padding.ph_2, { position: 'fixed', left: helpers.isDesktop() ? 20 : 0, bottom: helpers.isDesktop() ? 30 : 20, zIndex: 2, marginHorizontal: helpers.isDesktop() ? 'auto' : 20, backgroundColor: '#333', width: helpers.isDesktop() ? 500 : 'auto'}, !helpers.isDesktop() && { right : 0}]}>
                        {/* <CustomImage
                            source={isDisconnected ? infoIcon : successIcon}
                            require={true}
                            style={{ width: 20, height: 20, objectFit: 'contain', marginTop: 2 }}
                            webStyle={{ width: 20, height: 20, objectFit: 'contain', marginTop: 2 }}
                        /> */}
                        <Text style={[Padding.pl_2, { color: '#FFFFFF' }]}>{isDisconnected ? 'No internet connection' : 'You are back online!'}</Text>
                    </View>
                }
            </View>
        );
    }
}