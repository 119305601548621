import React, { Component } from 'react';
import { View } from 'react-native';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import GoogleButton from './google-button';
import Button from '../button';

const inputRef = React.createRef(null);

class GoogleSocialButton extends Component {
    constructor(props) {
        super(props);
    }

    triggerLogout = () => {
        inputRef.current.props.onClick();
    }

    render() {
        return (
            <>
                <GoogleLogin
                    clientId={this.props.appId}
                    render={this.renderComponent}
                    onSuccess={this.props.onLoginSuccess}
                    onFailure={this.props.onFailure}
                />
                <View style={[{ display: 'none' }]}>
                    <GoogleLogout
                        clientId={this.props.appId}
                        buttonText="Logout"
                        onLogoutSuccess={this.props.onLogoutSuccess}
                        onFailure={this.props.onFailure}
                        render={renderProps => (
                            <GoogleButton
                                ref={inputRef}
                                onClick={renderProps.onClick}
                                {...this.props}
                            />
                        )}
                    />
                </View>
            </>
        );
    }

    renderComponent = (renderProps) => {
        let { custom } = this.props;
        if (custom) {
            return <Button
                onClick={renderProps.onClick}
                {...this.props}
                loader={this.props.loader}
                disable={this.props.disable}
            />
        }
        else {
            return (
                <GoogleButton
                    title={this.props.children + "Google"}
                    onClick={renderProps.onClick}
                    {...this.props}
                    loader={this.props.loader}
                    disable={renderProps.disabled || this.props.disable}
                    indicatorColor={this.props.indicatorColor}
                />
            )
        }
    }
}

export default GoogleSocialButton;