import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, input, button, Colors, orderData, FontFamily, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import apiHelpers from "../../utils/api-helpers";
import Button from '../button';
import moment from 'moment';
import { Mixpanel } from '../../utils/mixpanel';

const laptop = getImagePath('img/laptop.png');

class OrderView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            enableBtnLoader: false,
        }
    }


    render() {
        const { order, index, darkMode } = this.props;
        let { enableBtnLoader } = this.state;

        let gifted = order.order_details.order_action == 'received_gift_credits' || order.order_details.order_action == 'received_gift_plan' || order.order_details.order_action == 'send_free_credits' || order.order_details.order_action == 'received_free_credits' || order.order_details.order_action == 'buy_gift_credits' || order.order_details.order_action == 'gift_subscription_created';

        let received = order.order_details.order_action == 'received_gift_credits' || order.order_details.order_action == 'received_gift_plan' || order.order_details.order_action == 'received_free_credits';

        let story_title = order.order_details.details.story_title ? order.order_details.details.story_title : false;
        if (story_title) {
            story_title = story_title.length < 35 ? story_title : `${story_title.substring(0, 35)}...`;
        }
        return (
            <View style={[Padding.pt_2, { borderTopWidth: 1, borderTopColor: darkMode ? Colors.lineColor : '#DDDDDD' }, (index == 0) && { borderTopWidth: 0, paddingTop: 0 }]}>
                <View style={[Flex.row, Flex.justifyBetween, Margin.mb_15]}>
                    <Text style={[orderData.name, { fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 22 : 20 }, darkMode && { color: Colors.lightWhite }, story_title && { fontSize: 12 }]}>{order.plan.name ? order.plan.name : story_title ? story_title : (`${order.order_details.details.credits ? order.order_details.details.credits : ''} Credit${order.order_details.details.credits == 1 ? '' : 's'} `)}</Text>

                    <Text style={[orderData.price, { fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 22 : 20 }, darkMode && { color: Colors.lightWhite }]}>
                        {(gifted) ? 'GIFTED' : order.order_details.order_entity_type == 'credits' && order.order_details.is_free ? `FREE` : `${order.plan.currency_code == 'INR' ? '₹' : '$'}${order.order_details.amount}`}
                    </Text>
                </View>

                <View style={[Flex.row, Flex.justifyBetween, Margin.mb_15, Flex.alignCenter]}>
                    {/* <Text style={[orderData.payMethod]}>{order.order_details.pg_aggregator == 'Chargebee' ? `Paid using card` : `Paid using Justpay`}</Text> */}
                    <Text style={[orderData.date, darkMode && { color: Colors.lightWhite }]}>{moment(order.order_details.date).format('DD MMM, YYYY')} {story_title ? `(${order.order_details.details.credits ? order.order_details.details.credits : ''} Credit${order.order_details.details.credits == 1 ? '' : 's'})` : ''}</Text>

                    {!order.order_details.is_free && !gifted &&
                        <Button
                            title={'Download Receipt'}
                            rootStyle={{
                                btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 'auto', backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0, textAlign: 'right' }],
                                btnText: [button.whiteButtonText, { fontSize: 14, fontWeight: FontWeight.regular, color: '#FC876D', lineHeight: 19, paddingHorizontal: 0, paddingTop: 2, fontFamily: FontFamily.regular }],
                            }}
                            onClick={() => this.downloadReceipt(order)}
                            loader={enableBtnLoader}
                            disable={enableBtnLoader}
                        />
                    }

                    {(gifted) &&
                        <Text style={[orderData.date, darkMode && { color: Colors.lightWhite }]}>
                            {received ? 'from' : 'to'}: {order.order_details.details.email}
                        </Text>
                    }
                </View>

                {order.order_details.order_entity_type == 'credits' && order.order_details.is_free &&
                    <Text style={[orderData.payMethod, darkMode && { color: Colors.lineColor }]}>{order.plan.reward_activiy[0]}</Text>
                }
            </View>
        )
    }

    downloadReceipt = (order) => {
        let { token } = this.props;
        let endpoint = `/transaction-invoice/${order.transaction_details.id}`;
        const { isAdmin, referenceId } = this.props;
        let body = {}
        if (isAdmin) {
            body = {
                is_admin: true,
                reference_id: referenceId
            }
        }
        this.setState({ enableBtnLoader: true, });
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {

                if (res.success && res.code == 'transaction_invoice') {
                    // try {
                    //     Mixpanel.track('download_receipt', {'title' : order.plan.name, 'date': order.order_details.date, payment_method: order.order_details.pg_aggregator});
                    // }
                    // catch(error) {
                    //     console.log("mix panel error =>", error);
                    // }
                    this.setState({ enableBtnLoader: false });
                    //TODO : separate component for download
                    const link = document.createElement('a');
                    link.href = `${res.data.invoice_link}`;
                    link.style = "display: none";
                    link.download = `${order.transaction_details.txn_id}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else {
                    this.setState({ enableBtnLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ enableBtnLoader: false });
                console.log('Error=>>', error);
            });
    }
}

export default OrderView;
