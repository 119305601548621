import { Mix_v1 } from "../mixin-builder";
import { ManagementAPI } from "./abstract";
import { ManagementAPI__I } from "./interface";
import { ManagementAPIMixins_v1 } from "./mixins";



/**
 * The {@link ManagementAPI} follows mixin pattern where the ManagementAPI object is open for extension by adding mixins {@link ManagementAPIMixins_v1}.
 */
export class ManagementAPI__v1 extends Mix_v1(ManagementAPI).with(...ManagementAPIMixins_v1){

}

export interface ManagementAPI__Factory__I {
    createInstance(...args: any[]): ManagementAPI__I
}

export const ManagementAPI__Factory_v1 = {
    createInstance: (...args: any[]) => {
        return new ManagementAPI__v1()
    }
}