import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { helpers } from '../../utils/helpers';
import moment from 'moment';
import getImagePath from '../../utils/image-helper';

const appLogo = getImagePath('icons/tmc-logo.png');

export class Seo extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    getBreadCrumbSchema = (data, page) => {
        try {
            let schemaBreadCrumbData;
            let schemaArticleData;
            let schemaAuthorData;
            switch(page) {
                case "/:category_slug/:post_slug":
                    if (data) {
                        schemaBreadCrumbData = [{
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": data.category.name,
                                "item": helpers.siteURL+"/category/"+data.category.slug
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.story.title
                            }]
                        }]
                        if (data.newsletter) {
                            schemaBreadCrumbData.push({
                                "@context": "https://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Newsletters",
                                    "item": helpers.siteURL+"/newsletters"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": data.newsletter.name,
                                    "item": helpers.siteURL+"/newsletters/"+data.newsletter.slug
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": data.story.title
                                }]
                            })
                        }
                        if (data.company) {
                            for (var i = 0; i < data.company.length; i++) {
                                schemaBreadCrumbData.push({
                                    "@context": "https://schema.org",
                                    "@type": "BreadcrumbList",
                                    "itemListElement": [{
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Company",
                                        "item": helpers.siteURL+"/company"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": data.company[i].name,
                                        "item": helpers.siteURL+"/company/"+data.company[i].slug
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": data.story.title
                                    }]
                                })
                            }
                        }
                        if (data.tag) {
                            for (var i = 0; i < data.tag.length; i++) {
                                schemaBreadCrumbData.push({
                                    "@context": "https://schema.org",
                                    "@type": "BreadcrumbList",
                                    "itemListElement": [{
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": data.tag[i].name,
                                        "item": helpers.siteURL+"/tag/"+data.tag[i].slug
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": data.story.title
                                    }]
                                })
                            }
                        }
                        if (data.storyplay) {
                            schemaBreadCrumbData.push({
                                "@context": "https://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": data.storyplay.name,
                                    "item": helpers.siteURL+"/theme/"+data.storyplay.slug
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": data.story.title
                                }]
                            })
                        }
                        if (data.author) {
                            for (var i = 0; i < data.author.length; i++) {
                                schemaBreadCrumbData.push({
                                    "@context": "https://schema.org",
                                    "@type": "BreadcrumbList",
                                    "itemListElement": [{
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Writers",
                                        "item": helpers.siteURL+"/writers"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": data.author[i].name,
                                        "item": helpers.siteURL+"/writers/"+data.author[i].slug
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": data.story.title
                                    }]
                                })
                            }
                        }

                        schemaArticleData = {
                            "@context": "https://schema.org",
                            "@type": "Article",
                            "name": data.story.title,
                            "headline": data.story.title,
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": helpers.siteURL+"/"+data.category.slug+"/"+data.story.slug
                            },
                            "author": {
                                "@type": "Person",
                                "name": data.author[0].name
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "The Morning Context",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": appLogo
                                }
                            },
                            "datePublished": moment(data.story.published_date).add(5, 'hours').add(30, 'minutes').toISOString(),
                            "dateModified": moment(data.story.published_date).add(5, 'hours').add(30, 'minutes').toISOString()
                        }
                        if (data.featured_image?.sizes) {
                            schemaArticleData['image'] = Object.values(data.featured_image.sizes);
                        }
                    }
                    break;
                case "/category/:category_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": data.category.name
                            }]
                        }
                    }
                    break;
                case "/:page_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": data.page.title
                            }]
                        }
                    }
                    break;
                case "/writers/:writer_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Writers",
                                "item": helpers.siteURL+"/writers"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.name
                            }]
                        }

                        schemaAuthorData = {
                            "@context": "https://schema.org",
                            "@type": "Person",
                            "email": data.email,
                            "image": data.profile_image,
                            "name": data.name,
                            "url": helpers.siteURL+"/writers"+data.slug
                        }
                        if (data.designation != '') {
                            schemaArticleData['jobTitle'] = data.designation;
                        }
                        if (data.location != '') {
                            schemaArticleData['address'] = {
                                "@type": "PostalAddress",
                                "addressLocality": data.location
                            };
                        }
                    }
                    break;
                case "/newsletters/:letter_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Newsletters",
                                "item": helpers.siteURL+"/newsletters"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.newsletter.name
                            }]
                        }
                    }
                    break;
                case "/blog/:blog_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Blogs",
                                "item": helpers.siteURL+"/blog"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.title
                            }]
                        }
                    }
                    break;
                case "/category/:archive_slug/all":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Blogs",
                                "item": helpers.siteURL+"/category/"+data.pageSlug
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.pageTitle
                            }]
                        }
                    }
                    break;
                case "/category/:archive_slug/archive":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Blogs",
                                "item": helpers.siteURL+"/category/"+data.pageSlug
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.pageTitle
                            }]
                        }
                    }
                    break;
                case "/company/:archive_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Blogs",
                                "item": helpers.siteURL+"/company"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": data.pageTitle
                            }]
                        }
                    }
                    break;
                case "/tag/:archive_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": data.pageTitle
                            }]
                        }
                    }
                    break;
                case "/theme/:archive_slug":
                    if (data) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": data.pageTitle
                            }]
                        }
                    }
                    break;
                default:
                    let pageName = helpers.getBreadCrumbName(page);
                    if (pageName) {
                        schemaBreadCrumbData = {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": pageName
                            }]
                        }
                    }
            }
            if (schemaBreadCrumbData || schemaArticleData || schemaAuthorData) {
                return <>
                        {schemaBreadCrumbData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaBreadCrumbData) }}
                            />
                        }
                        {schemaArticleData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaArticleData) }}
                            />
                        }
                        {schemaAuthorData && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaAuthorData) }}
                            />
                        }
                </>
            }
            // <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: '{
            //               "@context": "https://schema.org/",
            //               "@type": "Article",
            //               "name": "'+ schemaArticleData.name +'",
            //               "image": '+ schemaArticleData.images +'
            //             }' }}
            //         />
            //         {
            //                     name: res.data.title,
            //                     images: Object.values(res.data.featured_image.sizes)
            //                 }
        } catch (e) {}
    }

    render() {
        let { data, url, server, page, pageData } = this.props;
        let seoData = <>
            <title>{(data && data.title) ? data.title : "The Morning Context"}</title>
            <meta name="description" content={(data && data.description) ? data.description : "The Morning Context publishes the best reportage and analysis on technology, business and chaos in India. Subscribe to get timely, in-depth stories every day."}/>
            <meta name="keywords" content={(data && data.keywords) ? data.keywords : "TMC, The Morning Context"}/>
            {(url != undefined && url != '') ? <link rel="canonical" href={url}/> : <link rel="canonical" href={helpers.siteURL}/>}
            <meta property="og:site_name" content="The Morning Context"/>
            <meta property="og:type" content="website" />
            {(data && data.og) && Object.keys(data.og).map((property) => {
                return (
                    <>{data.og[property] && <meta key={"og:" + property} property={"og:" + property} content={(property == 'url' && url != undefined && url != '') ? url : data.og[property]}/>}</>
                )
            })}
            {(data && data.twitter) &&
                <meta name="twitter:card" content="summary_large_image" />
            }
            {(data && data.twitter) && Object.keys(data.twitter).map((name) => {
                return (
                    <>{data.twitter[name] && <meta key={"twitter:" + name} name={"twitter:" + name} content={(name == 'url' && url != undefined && url != '') ? url : data.twitter[name]}/>}</>
                )
            })}
            {page && this.getBreadCrumbSchema(pageData, page)}
        </>
        return (
            <>
                {server ? seoData
                    :
                    <MetaTags>
                        {seoData}
                    </MetaTags>
                }
            </>
        );
    }
}

export default Seo;