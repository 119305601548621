import React, { Component } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TextInput,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  formStyle,
  Colors,
  button,
  subscribePlans,
  appHeader,
} from "../../native/styles/appStyles";
import apiHelpers from "../../native/utils/api-helpers";
import { ProgressCircle } from "react-native-material-indicators";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";
import PaymentRedirectScreen from "../../native/screens/payment-redirect";
import AuthenticationWrapper from "../../native/components/authentication-wrapper";

const cookies = new Cookies();

export class PaymentRedirect extends Component {
  constructor(props) {
    super(props);
    let orderId = null;
    let hostedpage_id = null;
    let success_redirect_url = null;
    if (process.browser) {
      let fetchStatus = true;
      // if(window.opener && window.opener.paymentInitWindow) {
      //     window.opener.paymentWindowComplete = "Set";
      //     window.opener.paymentInitWindow.close();
      //     if(!window.closed) {
      //         fetchStatus = true;
      //     }
      // } else {
      //     fetchStatus = true;
      // }

      if (window.location.search.includes("success_redirect=")) {
        let searchArr = window.location.search.replace("?", "").split("&");
        let foundSearch = searchArr.find((searchParam) => {
          return searchParam.includes("success_redirect=");
        });
        if (foundSearch) {
          let search = foundSearch.replace("success_redirect=", "");
          success_redirect_url = search != "" ? search : null;
          success_redirect_url = decodeURIComponent(success_redirect_url);
        }
      }

      if (fetchStatus && window.location.search.includes("order_id=")) {
        let searchArr = window.location.search.replace("?", "").split("&");
        let foundSearch = searchArr.find((searchParam) => {
          return searchParam.includes("order_id=");
        });
        if (foundSearch) {
          let search = foundSearch.replace("order_id=", "");
          orderId = search != "" ? search : null;
        }
      } else if (
        fetchStatus &&
        window.location.search.includes("hostedpage_id=")
      ) {
        let searchArr = window.location.search.replace("?", "").split("&");
        let foundSearch = searchArr.find((searchParam) => {
          return searchParam.includes("hostedpage_id=");
        });
        if (foundSearch) {
          let search = foundSearch.replace("hostedpage_id=", "");
          hostedpage_id = search != "" ? search : null;
        }
      } else if (fetchStatus && window.location.search.includes("id=")) {
        let searchArr = window.location.search.replace("?", "").split("&");
        let foundSearch = searchArr.find((searchParam) => {
          return searchParam.includes("id=");
        });
        if (foundSearch) {
          let search = foundSearch.replace("id=", "");
          hostedpage_id = search != "" ? search : null;
        }
      }

      if (fetchStatus && orderId == null && hostedpage_id == null) {
        const { history } = this.props;
        history.push({
          pathname: "/",
        });
      }
    }
    const { isAuthenticated, fetchToken } = this.props.auth;
    this.state = {
      token: process.browser ? (isAuthenticated ? fetchToken : null) : null,
      orderId: orderId,
      hostedPageId: hostedpage_id,
      orderStatus: false,
      redirect_url: false,
      success_redirect_url: success_redirect_url,
      seconds: 5,
    };
    this.timer = 0;

    if (process.browser) {
      try {
        ReactGA.pageview(this.props.history.location.pathname);
      } catch (error) {
        console.log("GA tracking error =>", error);
      }
    }
  }

  renderUI = () => {
    const { orderStatus, seconds } = this.state;
    return (
      <>
        {orderStatus ? (
          <View style={[Flex.justfiyCenter, Flex.alignCenter, Margin.mt_5]}>
            {orderStatus == "success" && (
              <Text style={appHeader.successBoxText}>
                Subscription Successful
              </Text>
            )}
            {orderStatus == "failed" && (
              <Text style={appHeader.errorBoxText}>Subscription Failed</Text>
            )}
            <Text
              style={[subscribePlans.loaderDesc, Margin.mt_2, { fontSize: 14 }]}
            >
              Will be redirected in {seconds}.
            </Text>
          </View>
        ) : (
          <View style={[Flex.justfiyCenter, Flex.alignCenter, Margin.mt_5]}>
            <View style={Margin.mb_5}>
              <ProgressCircle color={"#907CFF"} size={186} widthOfBorder={25} />
            </View>
            <Text style={subscribePlans.loaderTitle}>Hold On!</Text>
            <Text style={subscribePlans.loaderSubTitle}>
              We are verifying your payment status.
            </Text>
          </View>
        )}
      </>
    );
  };

  navigateToScreen = (route, params) => {
    let routeArr = route.split("?");
    const { history } = this.props;
    history.push({
      pathname: routeArr[0],
      search: routeArr[1] ? "?" + routeArr[1] : "",
      state: params,
    });
  };

  render() {
    const { orderId, searchQuery, locationHash, hostedPageId } = this.state;
    return (
      <>
        {orderId || hostedPageId ? (
          <PaymentRedirectScreen
            history={this.props.history}
            searchQuery={searchQuery}
            locationHash={locationHash}
            navigateToScreen={this.navigateToScreen}
            orderId={orderId}
            hostedPageId={hostedPageId}
            success_redirect_url={this.state.success_redirect_url}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(AuthenticationWrapper(PaymentRedirect));
