import { chargebeeHostedPage } from './abstract';
import { chargebeeHostedPage__Interface, HostedPageCallbacks } from './interface'
import {deepCopyObject, syncObject } from '../helper'
import { Mixins_v1 } from '../controller/mixins';
import { CHARGEBEE_SETTINGS } from '../settings'
declare const window

export type Constructor = new (...args: any[]) => chargebeeHostedPage;

export interface CheckoutExistingMixin__I {
    /**
     * Open checkout_existing hosted page.
     * @param params - The parameters for the checkout API
     * @param customCallBacks - Custom callbacks for various events of checkout process.
     * @param updateUrl - An option to update window URI on particular checkout events
     */
     checkout_existing(params?: any, customCallBacks?: HostedPageCallbacks, updateUrl?: boolean): any
}
export function CheckoutExistingMixin<TBase extends Constructor>(Base: TBase): TBase | CheckoutExistingMixin__I {
    return class CE extends Base{
        constructor(...args: any[]){
            super(...args)
        }

        #parameters(params: any): any{
            let updatedParams = {}
            updatedParams = deepCopyObject(this.parameterHandle.settingsMap, updatedParams)
            let hostedParam = {
                type: 'checkout_existing',
                params: syncObject(updatedParams, params)
            }
            return hostedParam
        }

        checkout_existing(params?: any, customCallBacks?: HostedPageCallbacks, updateUrl?: boolean): any {
            this.emit('checkout_existing')
            let requestParams = this.#parameters(params)
            let customCB = {
                ...customCallBacks,
            }
            if(updateUrl){
                customCB = this._updateWindowCallbacks(requestParams, customCB)
            }
            let cb2 = this.handleCallbacks(requestParams,customCB)
            this.chargebeeInstance.instance.openCheckout(cb2)
            return true
        }
    }
}


export interface CheckoutNewMixin__I {
    /**
     * Open checkout_new hosted page.
     * 
     * **Note**: This method is different from the general methods like {@link CheckoutExistingMixin__I.checkout_existing} because the backend API accepts a little different format of input parameters.
     * @param params - The parameters for the checkout API
     * @param customCallBacks - Custom callbacks for various events of checkout process.
     * @param updateUrl - An option to update window URI on particular checkout events
     */
     checkout_new(params?: any, customCallBacks?: HostedPageCallbacks, updateUrl?: boolean): any
}
export function CheckoutNewMixin<TBase extends Constructor>(Base: TBase): TBase | CheckoutNewMixin__I {
    return class CN extends Base{
        constructor(...args: any[]){
            super(...args)
        }

        #parameters(params: any | null = {}): any {
            let defaults = {}
            if(this.settingsMap && this.settingsMap["subscription"]){
                defaults["subscription_id"] = this.settingsMap["subscription"]["id"]
                defaults["plan_id"] = this.settingsMap["subscription"]["plan_id"]
                defaults["plan_quantity"] = this.settingsMap["subscription"]["plan_quantity"]
    
                if(!CHARGEBEE_SETTINGS.DEBUG){
                    defaults['redirectUrl'] = `${CHARGEBEE_SETTINGS.SITE_URL}/payment-redirect`
                }
            }
            let hostedParam = {
                type: 'checkout_new',
                ...defaults,
                ...params
            }
            console.log('chargebee: checkout_new: ', hostedParam)
            return hostedParam
        }

        checkout_new(params?: any, customCallBacks?: HostedPageCallbacks, updateUrl?: boolean): any {
            this.emit('checkout_new')
            let requestParams = this.#parameters(params)
            let callBacks = {
                ...customCallBacks,
            }
            if(updateUrl){
                callBacks = this._updateWindowCallbacks(requestParams, callBacks)
            }
    
            if("hostedPage" in callBacks){
                callBacks['hostedPage'] = [
                    ...callBacks['hostedPage'],
                    (...args: any[]) => {
                        try{
                            let plan_id = requestParams.plan_id
                            let quantity = requestParams.plan_quantity
                            let newurl = `${window.location.pathname}?subscribe=${plan_id}&quantity=${quantity}`
                            window.history.pushState({path: newurl}, '', newurl)
    
                        }
                        catch(err){
                            console.log('CB hosted page err(URL params could not be removed)')
                        }
    
                    }
                ]
            }
            let cb2 = this.handleCallbacks(requestParams,callBacks)
    
            this.chargebeeInstance.instance.openCheckout(cb2)
            return true
        }
    }
}


export function CollectNowMixin<TBase extends Constructor>(Base: TBase) {
    return class CollectNow extends Base{
        constructor(...args: any[]){
            super(...args)
        }

        #parameters(params: any): any{
            let defaults = {}
            if(this.settingsMap && this.settingsMap["subscription"]){
                defaults["subscription_id"] = this.settingsMap["subscription"]["id"]
                defaults["plan_id"] = this.settingsMap["subscription"]["plan_id"]
                defaults["plan_quantity"] = this.settingsMap["subscription"]["plan_quantity"]
            }
            if(!params){
                params = {}
            }
    
            let hostedParam = {
                type: 'collect_now',
                ...defaults,
                ...params
            }
            console.log('chargebee: collect_now: ', hostedParam)
            return hostedParam
        }

        collect_now(params?: any, customCallBacks?: HostedPageCallbacks, updateUrl?: boolean): any {
            this.emit('collect_now')
            let requestParams = this.#parameters(params)
            return new Promise((resolve, reject) => {
                this.fetchHostedObject(requestParams).then(res => {
                    if(res.type == 'collect_now'){
                        window.location.href = res.url;
                        return resolve(true)
                    }
                    return resolve(false)
                }).catch(err => {
                    console.log('Error: chargebeeHostedPage:collect_now: ', err)
                    return reject(err)
                })
            })
        }
    }
}


export const HostedPageMixins = [
    CheckoutExistingMixin,
    CheckoutNewMixin,
    CollectNowMixin,
]