import { Mix_v1 } from "../../mixin-builder";
import { ManagementAPI } from "../abstract";
import { SubscriptionAPI__I } from "./interface";
import { SubscriptionAPIMixins_v1 } from "./mixins";
import { HTTP_Fetch } from "../../api_fetch";
import { CHARGEBEE_SETTINGS } from "../../settings";


export abstract class SubscriptionAPI implements SubscriptionAPI__I{
    tmcAuthToken?: any
    constructor(tmcAuthToken?: any){
        this.tmcAuthToken = tmcAuthToken
    }
}
