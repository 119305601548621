import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, articleItem, FontFamily, articleLists, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import ArticleItemHorizontal from '../article-item-horizontal';
import ScrollContainerView from '../scroll-container-view';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ArticleItem from '../article-item';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';

const viewMore = getImagePath('icons/view-more.png');

const cookies = new Cookies();

export class PremiumNewsletters extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        let token = webView ? (process.browser ? cookies.get('token') : this.props.token) : this.props.user?.userData?.data?.token;
        this.state = {
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount = () => {
        // console.log(cookies.get('token'),'poli');
    }

    viewMoreArticles = () => {
        const { viewAllArticles } = this.props;
        viewAllArticles()
    }

    viewArticle = (catSlug, slug, postData) => {
        const { viewArticle } = this.props;
        viewArticle(catSlug, slug, postData)
    }

    viewAuthor = (slug) => {
        const { viewAuthor } = this.props;
        viewAuthor(slug)
    }
    viewCategory = (slug) => {
        const { viewCategory } = this.props;
        viewCategory(slug)
    }

    trackNewsletterClick = (data, index) => {
        // try {
        //     Mixpanel.track('click_newsletter', { title : data.name, position: index });
        // }
        // catch(error) {
        //     console.log("check mix panel track error ==>", error);
        // }
    }

    render() {
        const { data, categorySlug, home, darkMode, logodata } = this.props;
        let btnClickNewsletter;

        return (
            <View style={[helpers.isDesktop() && { marginTop: 50, marginBottom: helpers.isDesktop() ? 0 : 60 }, !darkMode && { backgroundColor: '#F3F5FF' }]}>
                <View style={[article.headerWrapper]}>
                    <View style={[!helpers.isDesktop() && Padding.pb_2, !helpers.isDesktop() && Padding.ph_1]}>
                        <Text style={[{ fontFamily: FontFamily.regular, fontWeight: '500', fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 38, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 12 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>Get access to Premium Newsletters</Text>
                        <Text style={[{ fontFamily: FontFamily.regular, fontWeight: '500', fontSize: helpers.isDesktop() ? 18 : 18, lineHeight: helpers.isDesktop() ? 33 : 28, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255,255,255,0.87)' }, { marginTop: helpers.isDesktop() ? -5 : 0 }]}>Subscribe and get full access to all newsletters.</Text>
                    </View>
                </View>
                <View style={[Padding.ph_2, Margin.mb_4, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000]}>
                    {helpers.isDesktop() ? <View style={[Flex.row, Flex.justfiyCenter, Flex.alignCenter, { display: 'flex', flexWrap: 'wrap' }]}>
                        {logodata && logodata.slice(0, 4).map((item, index) => {
                            if (helpers.isWeb()) {
                                btnClickNewsletter = {
                                    to: "/newsletters/" + item.slug
                                }
                            } else {
                                btnClickNewsletter = {
                                    onPress: () => this.props.navigateToNewsletter(item.slug)
                                }
                            }
                            return (
                                <View style={[articleItem.col4, articleItem.col4Desktop, Flex.justfiyCenter, { borderColor: '#999999', height: 180, width: helpers.isDesktop() ? '50%' : '100%', marginTop: 50 }, (darkMode && (index == 0)) && { paddingLeft: 10, paddingRight: 10 }, !helpers.isDesktop() && { flexDirection: 'row' }]}>
                                    <TouchableOpacityLink {...btnClickNewsletter} handleClick={() => { this.trackNewsletterClick(item, index) }} >
                                        <View style={{ alignItems: 'center', position: 'relative' }}>
                                            <View style={{ backgroundColor: "white", width: 180, height: 180, borderRadius: 90 }}>
                                                <CustomImage
                                                    source={item.logo}
                                                    webStyle={{ width: 180, height: 180, borderRadius: 90, objectFit: 'contain', marginHorizontal: helpers.isDesktop() ? 0 : 0, marginRight: 150 }}
                                                    style={{ width: 180, height: 180, resizeMode: 'contain' }}
                                                    require={false}
                                                    className={''}
                                                    altText={item.name}
                                                    resizeMode={'contain'}
                                                />
                                            </View>
                                            {
                                                darkMode &&
                                                <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>

                                                </View>
                                            }
                                        </View>
                                    </TouchableOpacityLink>
                                </View>
                            )
                        })}
                    </View>
                        :
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                            <View horizontal={true} style={[Flex.row]}>
                                {logodata && logodata.slice(0, 4).map((item, index) => {
                                    if (helpers.isWeb()) {
                                        btnClickNewsletter = {
                                            to: "/newsletters/" + item.slug
                                        }
                                    } else {
                                        btnClickNewsletter = {
                                            onPress: () => this.props.navigateToNewsletter(item.slug)
                                        }
                                    }
                                    return (
                                        <View style={[Flex.row, { borderColor: '#999999', height: 180, width: helpers.isDesktop() ? '50%' : 210, marginTop: 50 }, !helpers.isDesktop() && { flexDirection: 'row' }]}>
                                            <TouchableOpacityLink {...btnClickNewsletter} handleClick={() => { this.trackNewsletterClick(item, index) }} >
                                                <View style={{ alignItems: 'center', position: 'relative' }}>
                                                    <View style={{ backgroundColor: "white", width: 180, height: 180, borderRadius: 90 }}>
                                                        <CustomImage
                                                            source={item.logo}
                                                            webStyle={{ width: 180, height: 180, borderRadius: 90, objectFit: 'contain', marginHorizontal: helpers.isDesktop() ? 0 : 0 }}
                                                            style={{ width: 180, height: 180, borderRadius: 90, resizeMode: 'contain' }}
                                                            require={false}
                                                            className={''}
                                                            altText={item.name}
                                                            resizeMode={'contain'}
                                                        />
                                                    </View>
                                                    {
                                                        darkMode &&
                                                        <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>

                                                        </View>
                                                    }
                                                </View>
                                            </TouchableOpacityLink>
                                        </View>
                                    )
                                })}
                            </View>
                        </ScrollView>
                    }
                </View>
            </View>
        );
    }
}

export default PremiumNewsletters;