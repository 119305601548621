import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, joinUsInfo, button } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';

const joinusList = [
    {
        icon: getImagePath('icons/long_read.png'),
        text: '200+ long form stories a year'
    },
    {
        icon: getImagePath('icons/newsletter.png'),
        text: 'Premium Newsletters'
    },
    {
        icon: getImagePath('icons/archive.png'),
        text: 'Full access to the archives'
    },
    {
        icon: getImagePath('icons/chat.png'),
        text: 'Join our Slack community (annual only)'
    },
]

export class JoinUsInfoBox extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    subscribeNow = () => {
        console.log('subscribe now section')
    }

    render() {
        const { title } = this.props;
        let iconSize, iconSizeMobile;
        iconSize = { width: 70, height: 70 };
        iconSizeMobile = { width: 70, height: 70, resizeMode: 'contain' };
        if (!helpers.isDesktop()) {
            iconSize = { width: 70, height: 70, objectFit: 'contain' };
        }

        return (
            <View>
                <Text style={[Typo.h3_M, helpers.isDesktop() &&  Typo.h3_MDesktop,  Helpers.textCenter, Helpers.textUppercase, Margin.mb_1]}>{title}</Text>

                <PageBreak size={'small'} style={[Margin.mb_5]} />

                <View>
                    <View style={[Flex.row, Flex.FlexWrap]}>
                        {joinusList && joinusList.map((item, index) => {
                            return (
                                <View key={`joinus_${index}`} style={[Flex.column, Flex.alignCenter, {width: '50%', paddingHorizontal: 10, marginBottom: 50}, helpers.isDesktop() && {width: '25%'}]}>
                                    <View>
                                        <CustomImage
                                            source={item.icon}
                                            require={true}
                                            style={iconSizeMobile}
                                            webStyle={iconSize}
                                            altText={item.text}
                                        />
                                    </View>
                                    <View style={[Flex.col_1, Margin.mt_15]}>
                                        <Text style={[joinUsInfo.infoText, Helpers.textCenter]}>{item.text}</Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    {/* <View style={[Margin.mt_0]}>
                        <ButtonGradient
                            title={'SUBSCRIBE NOW'}
                            disable={false}
                            loader={false}
                            rootStyle={{
                                btnWrapper: button.primaryGradient,
                                btnText: button.primaryGradientText,
                            }}
                            onClick={() => this.subscribeNow()}
                        />
                    </View> */}
                </View>
            </View>
        );
    }
}

export default JoinUsInfoBox;