import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, postContentTags, postContentClasses, postExcerptTags, postExcerptClasses } from '../../styles/appStyles';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';

const overlayImage = getImagePath('img/excerpt_overlay.png');


export class PostExcerpt extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        let { data, darkMode } = this.props;
        // let fristChar
        // if(firstLetter) {
        //     fristChar = `<span class="firstletter">${firstLetter}</span>`
        //     data = `${fristChar} <div class="cont-remaining">${data}</div>`
        // }

        let overlayStyle = { width: '100%', position: 'absolute', bottom: 0, height: helpers.isDesktop() ? '18%' : '8%' }
        return (
            <>
                <View style={postSingle.postExcerpt, darkMode && { color: 'white' }}>
                    <div className={"postsingle-content postsingle-bodyfont" + (darkMode ? " dark-mode" : "")}>{ReactHtmlParser(data)}</div>
                    {/* <View style={{height: 3, width: '100%', borderTopWidth: 2, borderTopColor: '#907CFF', opacity: 1, marginTop: 25, marginBottom: 30}} /> */}
                    {/* <CustomImage 
                        source={overlayImage}
                        style={overlayStyle}
                        webStyle={overlayStyle}
                        require={true}
                    /> */}
                </View>
            </>
        );
    }
}

export default PostExcerpt;