const trackStoryOrNewsletter = (postData, screenName) => {
  try {
    if (postData.newsletter) {
      console.log("Click Newsletter");
      global.webengage.track("Click Newsletter", {
        "Title of story": postData.story.title,
        "Type of Newsletter": postData.newsletter.name,
        "Page URL": screenName,
        "No of clicks": 1,
      });
      console.log({
        "Title of story": postData.story.title,
        "Type of Newsletter": postData.newsletter.name,
        "Page URL": screenName,
        "No of clicks": 1,
      });
    } else {
      console.log("click story");

      global.webengage.track("Click story", {
        "Longform category": postData.category.name,
        "Title of story": postData.story.title,
        "Author name": postData.author.map((item) => item.name),
        "Page URL": screenName,
        "Yesterday Edition": "",
        "No of clicks": 1,
      });
      console.log({
        "Longform category": postData.category.name,
        "Title of story": postData.story.title,
        "Author name": postData.author.map((item) => item.name),
        "Page URL": screenName,
        "Yesterday Edition": "",
        "No of clicks": 1,
      });
    }
  } catch (error) {
    console.log("error while sending story event", error);
  }
};

export { trackStoryOrNewsletter };
