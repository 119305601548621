import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, TextInput, ActivityIndicator, KeyboardAvoidingView } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, button, formStyle, unlockBox, signUpPage, Colors } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import SignInSocial from '../sign-in-social';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import CustomImage from '../custom-image';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserToken } from '../../../redux/User/user.actions';
import PageBreak from '../page-break';
import getImagePath from '../../utils/image-helper';
import TouchableOpacityLink from '../touchable-opacity-link';
import { Mixpanel } from '../../utils/mixpanel';
import { GoogleAnalytics } from '../../utils/google-analytics';
import { saveDarkMode, darkModeToggle } from '../../../redux/App/app.actions';

const cookies = new Cookies();
const eyeIcon = getImagePath('icons/showPassowrd.png');
const closeIcon = getImagePath('icons/grey-close-in.png');

export class SignInForm extends Component {
    constructor(props) {
        super(props);
        let redirectUrl = null;
        let verifyRedirectUrl = null;
        let email = null;
        let allParams = null;
        let webView = helpers.isWeb();
        let redirectParams = null;
        if (webView && process.browser) {
            if (window.location.search.includes("redirect=") || window.location.search.includes("email=")) {
                let searchArr = window.location.search.replace("?", "").split("&");
                let search = null;
                searchArr.map((searchParam) => {
                    if (searchParam.includes("redirect=")) {
                        search = searchParam.replace("redirect=", "");
                    }
                    if (searchParam.includes("email=")) {
                        email = searchParam.replace("email=", "");
                    }
                    if (searchParam.includes("redirect-params=")) {
                        redirectParams = searchParam;
                    }
                    return searchParam;
                });

                redirectUrl = (search && search != '') ? search : null;

                if (redirectUrl && redirectParams) {
                    redirectUrl = redirectUrl + '?' + redirectParams
                }

                verifyRedirectUrl = redirectUrl;
            }
        } else if (!webView && this.props.route.params) {
            redirectUrl = (this.props.route.params.redirect) ? this.props.route.params.redirect : null;
            verifyRedirectUrl = (this.props.route.params.verifyRedirect) ? this.props.route.params.verifyRedirect : null;
            email = (this.props.route.params.email) ? this.props.route.params.email : null;
            allParams = this.props.route.params ? this.props.route.params : {};
        }
        this.state = {
            email: email ? email : '',
            password: '',
            passwordToggle: true,
            enableBtnLoader: false,
            error: {
                social: false,
                email: false,
                password: false,
                otherError: false
            },
            redirectUrl: redirectUrl,
            verifyRedirectUrl: verifyRedirectUrl,
            allParams: allParams
        }
    }

    setDarkMode = (darkMode, toggle) => {
        this.props.saveDarkMode(darkMode);
        this.props.darkModeToggle(toggle ? true : false);
        if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            cookies.set('dark_mode', darkMode, { path: '/', expires: cookieDate });
        }
    }

    submitLogin = () => {
        const { email, password } = this.state;

        if (email && password) {
            this.setState({
                error: {
                    social: false,
                    email: false,
                    password: false,
                    otherError: false
                },
                enableBtnLoader: 'email',
            });

            if (email != '' && password != '') {
                this.loginUser();
            } else {
                this.setState({
                    error: {
                        social: false,
                        email: (!email) ? "Please enter a valid email" : false,
                        password: (password == '') ? "Please enter password" : false,
                        otherError: false
                    },
                    enableBtnLoader: false,
                })
            }
        }
        else if(email) {
            this.passwordField.focus();
        }
    }

    loginUser = () => {
        let { email, password, redirectUrl, allParams } = this.state;
        let type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/sign-in-institution`;
        let body = {
            account_email: email.toLowerCase(),
            password: password,
            type: type,
            device_data: {},
            institution: true
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'login_success') {
                    try {
                        Mixpanel.identify(res.data.reference_id);
                        Mixpanel.people.set({
                            name: res.data.full_name,
                            "$email": res.data.account_email
                        });
                        Mixpanel.track('login', { method: 'email' }, true);
                    }
                    catch (error) {
                        console.log("mix panel error ==>", error);
                    }
                    try {
                        GoogleAnalytics.event('Account', 'login', 'email');
                    }
                    catch (error) {
                        console.log("google analytics error ==>", error);
                    }

                    this.setState({
                        enableBtnLoader: false,
                    });

                    if (helpers.isWeb()) {
                        let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
                        cookies.set('token', res.data.token, { path: '/', expires: cookieDate });
                        // if(redirectUrl && redirectUrl.includes('&')) {
                        //     redirectUrl = redirectUrl.replace('&', '?')
                        // }
                        if (res.data.dark_mode) {
                            this.setDarkMode((res.data.dark_mode ? res.data.dark_mode.web : 'default'), (res.data.dark_mode?.webSaved != true));
                        }
                        this.props.navigateToScreen(redirectUrl ? redirectUrl : '/');
                    } else {
                        const userdata = {
                            data: res.data
                        };
                        this.props.setUserToken(userdata);
                        if (res.data.dark_mode) {
                            this.setDarkMode((res.data.dark_mode ? res.data.dark_mode.app : 'default'), (res.data.dark_mode?.appSaved != true));
                        }
                        if(Platform.OS == 'ios' && redirectUrl == 'intro-screen') {
                            this.props.navigation.reset({
                                index: 0,
                                routes: [{ name: '/' }]
                            })
                        }
                        else {
                            this.props.navigation.replace((redirectUrl ? redirectUrl : '/'), allParams ? allParams : {});
                        }
                    }
                } else {
                    this.setState({
                        enableBtnLoader: false,
                        error: {
                            social: false,
                            email: (res.code == 'error' && res.data.error.account_email) ? res.data.error.account_email : false,
                            password: (res.code == 'error' && res.data.error.password) ? res.data.error.password : false,
                            otherError: false
                        },
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    enableBtnLoader: false,
                    error: {
                        social: false,
                        email: false,
                        password: false,
                        otherError: "Something unexpected happened. Please try again"
                    },
                });
                console.log('Error=>>', error);
            });
    }

    handleSocialSetState = (stateObj) => {
        this.setState(stateObj);
    }

    handleSocialRedirect = (tokenData) => {
        let { redirectUrl, verifyRedirectUrl, allParams } = this.state;
        if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            cookies.set('token', tokenData.token, { path: '/', expires: cookieDate });
            if (tokenData.dark_mode) {
                this.setDarkMode((tokenData.dark_mode ? tokenData.dark_mode.web : 'default'), (tokenData.dark_mode?.webSaved != true));
            }
            if (tokenData.is_new_user) {
                this.props.navigateToScreen('/save-interests?redirect=' + (redirectUrl ? redirectUrl : '/'), { pageRedirect: true });
            } else {
                this.props.navigateToScreen(redirectUrl ? redirectUrl : '/');
            }
        } else {
            const userdata = {
                data: tokenData
            };
            this.props.setUserToken(userdata);
            if (tokenData.dark_mode) {
                this.setDarkMode((tokenData.dark_mode ? tokenData.dark_mode.app : 'default'), (tokenData.dark_mode?.appSaved != true));
            }
            if (tokenData.is_new_user) {
                this.props.navigation.replace('/save-interests', { ...allParams, pageRedirect: true });
            } else {
                if(Platform.OS == 'ios' && redirectUrl == 'intro-screen') {
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: '/' }]
                    })
                }
                else {
                    this.props.navigation.replace((redirectUrl ? redirectUrl : '/'), allParams ? allParams : {});
                }
            }
        }
    }

    navigateToScreen = () => {
        let { redirectUrl, allParams, verifyRedirectUrl, email } = this.state;
        if (helpers.isWeb()) {
            if (redirectUrl && redirectUrl.includes('?')) {
                redirectUrl = redirectUrl.replace('?', '&')
            }
            this.props.navigateToScreen(redirectUrl ? ('/sign-up?redirect=' + redirectUrl + (email != '' ? ('&email=' + email) : '')) : ('/sign-up' + (email != '' ? ('?email=' + email) : '')));
        } else {
            this.props.navigation.replace('/sign-up', allParams ? { ...allParams, email: email } : { email: email });
        }
    }

    navigateToInstitution = () => {
        let { redirectUrl, allParams, verifyRedirectUrl, email } = this.state;
        if (helpers.isWeb()) {
            if (redirectUrl && redirectUrl.includes('?')) {
                redirectUrl = redirectUrl.replace('?', '&')
            }
            this.props.navigateToScreen(redirectUrl ? ('/sign-up-institution?redirect=' + redirectUrl + (email != '' ? ('&email=' + email) : '')) : ('/sign-up' + (email != '' ? ('?email=' + email) : '')));
        } else {
            this.props.navigation.replace('/sign-up-institution', allParams ? { ...allParams, email: email } : { email: email });
        }
    }

    navigateToForgotPassword = () => {
        let { allParams, email, redirectUrl } = this.state;
        if (helpers.isWeb()) {
            let link = `/forgot-password?redirect=${redirectUrl}`
            this.props.navigateToScreen(email ? `${link}&email=${email}` : link);
        } else {
            this.props.navigation.navigate('/forgot-password', allParams ? { ...allParams, email: email } : { email: email });
        }
    }

    navigateBack = () => {
        let { redirectUrl } = this.state;
        if (helpers.isWeb()) {
            this.props.history.replace(redirectUrl ? redirectUrl : '/')
        }
        else {
            if(Platform.OS == 'ios') {
                this.props.navigation.navigate('intro-screen');
            }
            else {
                this.props.navigation.navigate(redirectUrl ? redirectUrl : '/');
            }
        }
    }

    renderPageContent() {
        const { email, password, error, passwordToggle, enableBtnLoader } = this.state;
        let forgotPwdProp = {};
        if (helpers.isWeb()) {
            forgotPwdProp = {
                onPress: this.navigateToForgotPassword
            }
        }
        else {
            forgotPwdProp = {
                onPress: this.navigateToForgotPassword
            }
        }

        let navigateBackProp = {};
        navigateBackProp = {
            onPress: this.navigateBack
        }
        return (

            <View style={[helpers.isDesktop() && Margin.mt_3, helpers.isDesktop() && Margin.mb_3, { paddingHorizontal: helpers.isDesktop() ? 50 : 0 }]}>
                {helpers.isWeb() ?
                    <View style={[Padding.ph_2, Flex.fill, !helpers.isDesktop() && Padding.pt_5, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        {!helpers.isDesktop() && <TouchableOpacity {...navigateBackProp}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28, position: 'absolute', right: 5, bottom: 6, zIndex: 1 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 5, bottom: 6, zIndex: 1 }}
                        /></TouchableOpacity>}
                        <Text style={[signUpPage.pageTitle, { fontSize: helpers.isDesktop() ? 30 : 26 }]}>Sign in</Text>
                    </View>
                    :
                    <View style={[Padding.ph_2, Flex.fill, Padding.pt_2, { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        <TouchableOpacity {...navigateBackProp}><View style={[Flex.alignEnd]}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28, position: 'relative', right: 5, bottom: 6, zIndex: 1 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 5, bottom: 6, zIndex: 1 }}
                        /></View></TouchableOpacity>
                        <Text style={[signUpPage.pageTitle, { fontSize: 26 }]}>Sign in</Text>
                    </View>
                }
                <View style={[Padding.pt_3, { paddingHorizontal: 18 }]}>
                    {/* <PageBreak style={[Margin.mt_1, Margin.mb_3]} size={'small'} /> */}
                    <View style={[Margin.mb_2]}>
                    <Text style={[formStyle.footerText1]}>Sign Up From A Institute? <Text onPress={() => this.navigateToInstitution()} style={[formStyle.primayLinkBtn]}>Click Here</Text></Text>
                    </View>
                    <SignInSocial
                        handleSocialSetState={(stateObj) => this.handleSocialSetState(stateObj)}
                        handleSocialRedirect={(token) => this.handleSocialRedirect(token)}
                        enableBtnLoader={enableBtnLoader}
                        btnText={'Sign in with '}
                    />
                    {error.social && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>{error.social}</Text>
                    </View>}

                    <View style={[signUpPage.orWrapper, { marginTop: 40, marginBottom: 20 }]}>
                        <View style={[signUpPage.sepratorLine]} />
                        <Text style={[signUpPage.orText, { lineHeight: 23 }]}>OR</Text>
                    </View>

                    <View style={[Margin.mt_2]}>
                        <TextInput
                            placeholder={'Email id'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[formStyle.inputBoxStyle, error.email && formStyle.inputBoxError, { fontSize: helpers.isDesktop() ? 18 : 16 }]}
                            value={email}
                            onChangeText={(email) => this.setState({ email: email })}
                            onSubmitEditing={this.submitLogin}
                        />
                    </View>
                    {error.email && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>{error.email}</Text>
                    </View>}

                    <View style={[Margin.mt_4]}>
                        <View style={[formStyle.inputIconWrap]}>
                            <TextInput
                                ref={ref => this.passwordField = ref}
                                placeholder={'Password'}
                                placeholderTextColor={Colors.placeholderTextColor}
                                style={[formStyle.inputBoxStyle, { paddingRight: 57 }, error.password && formStyle.inputBoxError, { fontSize: helpers.isDesktop() ? 18 : 16 }]}
                                secureTextEntry={passwordToggle}
                                onChangeText={(password) => this.setState({ password: password })}
                                onSubmitEditing={this.submitLogin}
                            />
                            <TouchableOpacity onPress={() => this.setState({ passwordToggle: !passwordToggle })} style={[formStyle.showPassBtn]}>
                                <Text style={[formStyle.showPassBtnText, { marginTop: -4, paddingRight: 10 }]}>{passwordToggle ? 'SHOW' : 'HIDE'}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {error.password && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>{error.password}</Text>
                    </View>}
                    <View style={[Margin.mt_1, Flex.alignEnd]}>
                        <TouchableOpacity activeOpacity={0.8} {...forgotPwdProp}>
                            <Text style={[button.btnDefaultText, { color: '#979797' }]}>Forgot password?</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={[Margin.mt_15, Padding.ph_2, Padding.pv_2, Margin.mb_2]}>
                    <ButtonGradient
                        title="Sign in"
                        rootStyle={{
                            btnWrapper: [button.primaryGradient],
                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
                        }}
                        loader={(enableBtnLoader && enableBtnLoader == 'email') ? true : false}
                        disable={((enableBtnLoader && enableBtnLoader != 'email') || (email == '' || password == '')) ? true : false}
                        onClick={() => this.submitLogin()}
                        fullWidth={true}
                    />
                    {error.otherError && <View>
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}>{error.otherError}</Text>
                    </View>}
                    {/*<View style={[Margin.mt_1, {flexDirection: 'row', justifyContent: 'center'}]}>
                        <Text style={[formStyle.footerText2]}>Need help signing in? </Text><TouchableOpacity style={[formStyle.linkBtnBorder]} activeOpacity={0.8}><Text style={[formStyle.linkBtn]}>Contact us</Text></TouchableOpacity>
                    </View>*/}
                    {Platform.OS != 'ios' &&
                        <View style={[Margin.mb_1, Margin.mt_2, { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
                            <Text style={[formStyle.footerText1]}>Don’t have an account? <Text onPress={() => this.navigateToScreen()} style={[formStyle.primayLinkBtn]}>Become a member</Text></Text>
                        </View>
                    }
                </View>
            </View>
        );
    }

    render() {
        const { redirectUrl } = this.state;
        const webView = helpers.isWeb();
        return (
            <View style={[{ flex: webView ? 1 : 0, backgroundColor: 'white', height: webView ? 'auto' : '100%' }]}>
                {(webView && process.browser && cookies.get("token") != undefined) && <Redirect to={redirectUrl ? redirectUrl : "/"} />}
                {webView
                    ? this.renderPageContent()
                    : <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : null}  style={{ flex: 1 }}><ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView></KeyboardAvoidingView>
                }
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUserToken, saveDarkMode, darkModeToggle }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);