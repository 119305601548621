import React, { Component } from 'react';
import { Text, View, ScrollView, TouchableOpacity, TextInput, Dimensions, Platform } from 'react-native';
import { Colors, Flex, Padding, Margin, Helpers, FontFamily, appStyles, articleLists, article, articleItem, formStyle, FontWeight, button, pageCover, pageContent, input, bannerStyles, Messages } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageCover from '../../components/page-cover';
import InfiniteScrollComponent from '../../components/infinite-scroll';
import ArticleItemHorizontal from '../../components/article-item-horizontal';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import ButtonGradient from '../../components/button-gradient';
import { Mixpanel } from '../../utils/mixpanel';
import NewsletterCategory from '../../components/newsletter-category';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import Button from '../../components/button';
import AuthenticationWrapper from '../../components/authentication-wrapper';

const checkBoxSelected = getImagePath('icons/checkedIconSq.png');
const checkBoxUnSelected = getImagePath('icons/unCheckedIconSq.png');
const searchBox = getImagePath('icons/searchbox.png');

const bookmarkIcon = getImagePath('icons/bookmark.png');
const bookmarkIconLight = getImagePath('icons/bookmark-light.png');
const bookmarkIconSelected = getImagePath('icons/bookmark-selected.png');

const noArticles = getImagePath('img/no-articles.png');
const noUnlockedStories = getImagePath('img/no-unlocked-stories.png');
const bookmark = getImagePath('icons/bookmark.png');
const windowWidth = Dimensions.get('window').width;

const signinIcon = getImagePath('icons/sign-in-icon.png');

const cookies = new Cookies();
let resetTimeout = 0;

export class UnlockedStories extends Component {
    controller;
    signal;
    constructor(props) {
        super(props);
        let search = '';
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let unlockType = '', redirectParams;
        if (webView) {
            unlockType = this.props.params.unlockType;
        } else if (!webView) {
            unlockType = this.props.unlockType;
        }
        let token = webView ? (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        if (webView && process.browser && window.location.search) {
            let searchArr = window.location.search.replace("?", "").split("&");
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("search=");
            });
            if (foundSearch) {
                search = foundSearch.replace("search=", "");
                search = search.replace(/%20/g, ' ');
            }
            redirectParams = searchArr.find((searchParam) => {
                return searchParam.includes("redirect-params=");
            });
            if (redirectParams) {
                redirectParams = redirectParams.replace("redirect-params=", "");
            }
        } else if (!webView && this.props.route.params) {
            search = (this.props.route.params.redirect) ? this.props.route.params.search : '';
        }

        this.state = {
            token: (token == undefined || token == '') ? false : token,
            unlockType: unlockType,
            pageLoader: true,
            postPage: false,
            postLists: [],
            searchText: search,
            redirectParams: redirectParams,
            emailError: {
                enable: false,
                type: '', // error / success
                msg: ''
            },
            email: ''
        }
        if (redirectParams) {
            let reaplaceProp = JSON.parse(JSON.stringify(window.location));
            reaplaceProp.search = '';
            this.props.history.replace(reaplaceProp)
        }
    }

    componentDidMount = () => {
        const { unlockType, token, redirectParams } = this.state;
        if ((unlockType == 'credit' && token) || unlockType == 'free') {
            this.getArticles(1);
        } else {
            this.setState({ pageLoader: false, postLists: [] })
        }
        if (redirectParams && token) {
            this.splitParamsAndAppyAction(redirectParams);
        }

        this.setBreadcrumbs(unlockType);
        if(this.state.token){
            this.getUserInfo();
        }
    }

    getUserInfo = () => {
        const { token } = this.state;
        let endpoint = `/profile`;
        let body = { request_group: ["profile"] };
        apiHelpers
          .apiRequest(endpoint, body, "POST", true, token)
          .then((res) => {
            if (res.code == "access_denied") {
              // this.authTokenExpired();
            } else if (res.success) {
              this.setState({ userProfile: res.data });
            }
          })
          .catch((error) => {
            console.log("Error=>>", error);
            resolve(false);
            // this.setState({ showErrorModal: true, pageLoader: false });
          });
      };

    componentDidUpdate = () => {

    }

    setBreadcrumbs = (data) => {
        Breadcrumbs = [
            {
                name: 'Home',
                link: '/'
            },
            {
                name: data == 'credit' ? 'Unlocked Stories' : 'Free Reads'
            }
        ]
    }

    splitParamsAndAppyAction = (redirectParams) => {
        let paramsArr = redirectParams.split("+");
        let id = paramsArr.find((searchParam) => {
            return searchParam.includes("id=");
        });
        let action = paramsArr.find((searchParam) => {
            return searchParam.includes("action=");
        });
        let value = paramsArr.find((searchParam) => {
            return searchParam.includes("value=");
        });

        if (id && action && value) {
            id = id.replace("id=", "");
            id = parseInt(id);
            action = action.replace("action=", "");
            value = value.replace("value=", "");
            value = value === "true" ? true : false;
            this.applyStoryActions(id, action, value);
        }
    }

    getArticles = (page, append) => {
        const { unlockType, token, searchText } = this.state;
        let endpoint = (unlockType == 'free') ? `/stories` : `/unlock-story-list`;
        let body = {
            limit: 10,
            page: page
        }
        if (unlockType == 'free') {
            body['filters'] = [
                {
                    key: "newsletter",
                    value: ["include"]
                },
                {
                    key: "is_free",
                    value: true
                }
            ]
        }
        if (searchText != '') {
            body['search_string'] = searchText;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    if (res.code == ((unlockType == 'free') ? 'story_found' : 'unlock_story_list')) {
                        let storyList = (unlockType == 'free') ? res.data.story : res.data.stories;
                        this.setState({
                            postPage: res.data.page,
                            postLists: append ? this.state.postLists.concat(storyList) : storyList,
                            pageLoader: false
                        });
                    } else {
                        if (page == 1) {
                            this.setState({
                                postPage: false,
                                postLists: [],
                                pageLoader: false
                            });
                        } else {
                            let postPage = this.state.postPage;
                            postPage.has_next = false;
                            this.setState({
                                postPage: postPage,
                                pageLoader: false
                            });
                        }
                    }
                } else {
                    if (page == 1) {
                        this.setState({
                            postPage: false,
                            postLists: [],
                            pageLoader: false
                        });
                    } else {
                        let postPage = this.state.postPage;
                        postPage.has_next = false;
                        this.setState({
                            postPage: postPage,
                            pageLoader: false
                        });
                    }
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }


    getMoreArticles = () => {
        this.getArticles(this.state.postPage.current + 1, true)
    }

    abortRequest = () => {
        if (this.controller) {
            this.controller.abort();
        }
    }

    applyStoryActions = (id, type, value, index) => {
        const { unlockType, token } = this.state;
        if (token) {
            this.abortRequest();
            this.controller = new AbortController();
            this.signal = this.controller.signal;
            let signal = this.signal;

            let endpoint = `/user-story-action`;
            let body = {
                story_id: id,
                type: type,
                value: value
            }

            let postLists = this.state.postLists;
            let index = postLists.findIndex((story) => {
                return story.id == id;
            })
            if (index >= 0) {
                postLists[index].is_bookmarked = !postLists[index].is_bookmarked
            }
            this.setState({ postLists: postLists });
            if (type == 'bookmark') {
                this.refs.header.animateReadingList();
            }
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token, signal)
                .then((res) => {
                    if (res.success && res.code == 'user_story_action') {
                        let postLists = this.state.postLists;
                        let index = postLists.findIndex((story) => {
                            return story.id == id;
                        })
                        if (index >= 0) {
                            postLists[index].is_bookmarked = res.data.bookmark;
                        }
                        this.setState({ postLists: postLists });

                        try {
                            if (type == 'bookmark' && value === true) {
                                Mixpanel.track('clicked_bookmark', { story_title: postLists[index].title, story_slug: postLists[index].slug });
                            }
                            else if (type == 'like' && value === true) {
                                Mixpanel.track('clicked_like', { story_title: postLists[index].title, story_slug: postLists[index].slug });
                            }
                        }
                        catch (error) {
                            console.log("mix panel error =>", error);
                        }

                    }
                    else {
                        console.log('Error=>>', JSON.stringify(res));
                        let postLists = this.state.postLists;
                        postLists[index].is_bookmarked = !postLists[index].is_bookmarked;
                        this.setState({ postLists: postLists });
                    }
                })
                .catch((error) => {
                    console.log('Error=>> down', JSON.stringify(error));
                    if (error && error.aborted) {
                        // Do nothing
                    }
                    else {
                        let postLists = this.state.postLists;
                        postLists[index].is_bookmarked = !postLists[index].is_bookmarked;
                        this.setState({ postLists: postLists });
                    }
                });
        }
        else {
            let redirectSlugs = {
                web: (unlockType == 'free') ? '/free-reads' : '/unlocked-stories',
                native: { redirect: (unlockType == 'free') ? '/free-reads' : '/unlocked-stories', verifyRedirect: (unlockType == 'free') ? '/free-reads' : '/unlocked-stories' }
            }
            if (helpers.isWeb()) {
                this.props.navigateToScreen(`/sign-in?redirect=${redirectSlugs.web}&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`)
            } else {
                this.props.navigation.navigate('/sign-in', redirectSlugs.native);
                this.props.navigation.navigate('/sign-in', { ...redirectSlugs.native, verifyRedirect: redirectSlugs.native.verifyRedirect + `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`, 'redirect-params': `id=${id}+action=bookmark+value=${true}+index=${index}` });
            }
        }
    }

    searchFieldOnChange = (value) => {
        this.setState({
            searchText: value
        }, () => {
            if (resetTimeout != 0) {
                clearTimeout(resetTimeout);
            }
            let th = this;
            resetTimeout = setTimeout(() => {
                this.getArticles(1)
                if (helpers.isWeb()) {
                    let pushProp = JSON.parse(JSON.stringify(window.location));

                    if (value != '')
                        pushProp.search = "?search=" + value;
                    else
                        pushProp.search = '';

                    this.props.history.replace(pushProp)
                }
            }, 500);
        })


    }

    showPageLoader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[20]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 50 }}
                    active={true}
                    pHeight={[100]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 10, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    signUp = () => {
        const { email } = this.state;
        this.setState({
            btnLoader: true,
            emailError: {
                enable: false,
                type: '',
                msg: ''
            }
        });

        if (helpers.validateEmail(email)) {
            this.setState({
                btnLoader: false
            });
            if (helpers.isWeb()) {
                this.props.navigateToScreen(`/sign-up?redirect=/free-reads&email=${email}`);
            } else {
                this.props.navigation.navigate('/sign-up', { redirect: '/free-reads', verifyRedirect: '/free-reads', email: email });
            }
        }
        else {
            this.setState({
                btnLoader: false,
                emailError: {
                    enable: true,
                    type: 'error',
                    msg: 'Please enter a valid email'
                }
            });
        }
    }

    validateInput = (value) => {
        if (value) {
            this.setState({
                btnDisable: false,
                email: value,
                emailError: {
                    enable: false,
                    type: '',
                    msg: ''
                }
            });
        } else {
            this.setState({ email: value, btnDisable: true })
        }
    }

    renderPageCoverRightSection = () => {
        const { postPage } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                {postPage ?
                    <View style={[article.countWrap, { flexDirection: 'row' }]}>
                        <Text style={[article.count, helpers.isDesktop() && article.countDesktop, helpers.isDesktop() && Margin.mr_1]}>{postPage.total_count}</Text>
                        <Text style={[article.countLabel, helpers.isDesktop() && article.countLabelDesktop, { paddingLeft: 5 }]}>{postPage && postPage.total_count == 1 ? 'Article' : 'Articles'}</Text>
                    </View>
                    :
                    <></>
                }
            </>
        )
    }

    renderPageHeader = () => {
        const { unlockType, token, email, btnDisable, emailError } = this.state;
        let webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[article.headerWrapper]}>
                <View style={[Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { backgroundColor: darkMode ? (helpers.isDesktop() ? Colors.darkBlackColor : Colors.darkBlackColorBg) : '#F9F8FF', marginTop: helpers.isDesktop() ? 130 : 75, paddingHorizontal: helpers.isDesktop() ? 20 : 0, width: helpers.isDesktop() ? '85%' : '100%', marginBottom: helpers.isDesktop() ? 70 : 40 }]}>
                    <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, { position: 'relative', top: helpers.isDesktop() ? -42 : -25 }, !helpers.isDesktop() && { fontSize: 31 }, darkMode && { color: Colors.lightWhite }]}>{(unlockType == 'free') ? 'Free Reads' : 'Your Unlocked Content'}</Text>
                    {unlockType == 'free' ?
                        <>
                            {token ?
                                <Text style={[pageContent.subTitle, helpers.isDesktop() && pageContent.subTitleDesktop, { maxWidth: helpers.isDesktop() ? 900 : '100%', paddingBottom: 40 }, !webView && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>
                                    Read these stories for free. No subscription required.
                                </Text>
                                :
                                <View style={[{ alignItems: 'center', justifyContent: 'center', marginBottom: 20 }]}>
                                    <View style={[{ alignItems: 'flex-start' }]}>
                                        <Text style={[pageContent.subTitle, helpers.isDesktop() && pageContent.subTitleDesktop, { maxWidth: helpers.isDesktop() ? 900 : '100%', paddingBottom: 40 }, !webView && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>
                                            <Text style={[{ fontFamily: FontFamily.merriweatherB }]}>Sign up</Text> to read these stories for free. No subscription required.
                                        </Text>
                                        {/* <View style={[Margin.mt_2, Flex.row, { paddingHorizontal: helpers.isDesktop() ? 45 : 20 }, !helpers.isDesktop() && { width: '100%' }]}>
                                            <TextInput
                                                placeholder={'Enter your email address'}
                                                placeholderTextColor={Colors.placeholderTextColor}
                                                style={[input.Text, Padding.pt_1, Padding.pl_15, !helpers.isDesktop() && Padding.pl_1, Padding.pb_1, !helpers.isDesktop() && Margin.mb_1, helpers.isDesktop() && { marginBottom: 6, height: 46, fontSize: 16 }, { borderWidth: 2, borderColor: '#6C7AF6', backgroundColor: 'white', width: helpers.isDesktop() ? 440 : '75%' }, !helpers.isDesktop() && { fontSize: 13, height: 42 }, (emailError.enable) && { borderWidth: 2, borderColor: darkMode ? '#F4A886' : '#FF525E' }]}
                                                onChangeText={this.validateInput}
                                                value={email}
                                            />
                                            <Button
                                                title={'SIGN UP'}
                                                rootStyle={{
                                                    btnWrapper: [{ backgroundColor: '#3B58A7', paddingLeft: helpers.isDesktop() ? 20 : 10, paddingRight: helpers.isDesktop() ? 20 : 10, paddingTop: helpers.isDesktop() ? 14 : 12, paddingBottom: 10, height: helpers.isDesktop() ? 46 : 42 }, darkMode && { backgroundColor: Colors.brandColor1 }, { marginLeft: helpers.isDesktop() ? 8 : 0 }],
                                                    btnText: [bannerStyles.text, Helpers.textUppercase, { fontWeight: '600', letterSpacing: 0.3, fontSize: helpers.isDesktop() ? 18 : 16, textAlign: helpers.isDesktop() ? 'left' : 'center', paddingLeft: helpers.isDesktop() ? 25 : 0, paddingRight: helpers.isDesktop() ? 25 : 0, color: 'white' }]
                                                }}
                                                onClick={this.signUp}
                                                disable={btnDisable}
                                                textWhite={true}
                                            /> */}
                                        {/* </View> */}
                                        {emailError.enable &&
                                            <View style={[{ marginTop: 5, paddingHorizontal: helpers.isDesktop() ? 45 : 20 }]}>
                                                <Text style={[emailError.type == 'success' ? Messages.successText : Messages.errorText, { color: darkMode ? '#F4A886' : '#FF525E' }]}>{emailError.msg}</Text>
                                            </View>
                                        }
                                    </View>
                                </View>
                            }
                        </>
                        :
                        <Text style={[pageContent.subTitle, helpers.isDesktop() && pageContent.subTitleDesktop, { maxWidth: helpers.isDesktop() ? 900 : '100%', paddingBottom: 40 }, !webView && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>
                            Here you can see all the stories and newsletters that you have unlocked using credits.
                        </Text>
                    }
                </View>
                {this.renderSearchField()}
            </View >
        )
    }

    renderSearchField = () => {
        let { searchText, checkBoxChecked, userLoggedin } = this.state;
        let webView = helpers.isWeb();
        let btnClickCheckBox;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        if (helpers.isWeb()) {
            btnClickCheckBox = {
                onPress: (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                        checkBoxChecked: !checkBoxChecked
                    }, () => {
                        this.getArticles(1)
                        let pushProp = JSON.parse(JSON.stringify(window.location));
                        let searchArr = window.location.search.replace("?", "").split("&");
                        if (searchArr.length > 0 && searchArr[0] != "" && searchArr[0].startsWith('?search')) {
                            pushProp.search += "&search=" + value;
                        } else {
                            if (!this.state.checkBoxChecked) {
                                pushProp.search = this.state.searchText ? "?search=" + this.state.searchText + "&exclude_read=true" : "?exclude_read=true";
                            }
                            else {
                                pushProp.search = this.state.searchText ? "?search=" + this.state.searchText : "";

                            }
                        }
                        this.props.history.push(pushProp)
                    })
                }
            }
        } else {

            btnClickCheckBox = {
                onPress: (event) => {
                    event.stopPropagation();
                    this.setState({
                        checkBoxChecked: !checkBoxChecked
                    }, () => this.getArticles(1))
                }
            }
        }

        return (
            <View style={[helpers.isDesktop() && Flex.row, !helpers.isDesktop() && Flex.column, Flex.justfiyCenter, Margin.mb_7, { alignItems: helpers.isDesktop() ? 'baseline' : 'center' }, !webView && { width: windowWidth }]}>
                <View style={[{ position: 'relative' }, !helpers.isDesktop() && { width: '100%', paddingHorizontal: 20 }, !webView && { flexDirection: 'row' }]}>
                    <TextInput
                        placeholder={'Search for stories'}
                        placeholderTextColor={Colors.placeholderTextColor}
                        value={searchText}
                        onChangeText={(search) => { this.searchFieldOnChange(search) }}
                        style={[helpers.isDesktop() && Padding.pl_2, !helpers.isDesktop() && Padding.pl_1, { color: '#000', borderWidth: 1, borderColor: '#907CFF', height: helpers.isDesktop() ? 46 : 40, width: helpers.isDesktop() ? 400 : '100%', paddingRight: 80, fontFamily: FontFamily.regular }, !webView && { width: windowWidth - 90 }, darkMode && { color: Colors.lightWhite }]}
                    />
                    <View style={[formStyle.searchBox]}>
                        <CustomImage
                            source={searchBox}
                            require={true}
                            style={{ width: 45, height: helpers.isDesktop() ? 46 : 40, position: 'relative' }}
                            webStyle={{ width: helpers.isDesktop() ? 50 : 43, height: helpers.isDesktop() ? 46 : 40, position: 'absolute', right: 0, top: helpers.isDesktop() ? -46 : -40 }}
                        />
                    </View>
                </View>
            </View>
        )
    }

    pushInformationToDataLayer = () => {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "more_info_interaction",
          custom_user_id: this.state.custom_user_id || "NA",
          user_status: this.state.custom_user_id ? "logged in" : "guest",
          article_section: "your unlocked content",
          newsletter_section: "NA",
          CTA_text: "view all plans",
          CTA_position: "bottom",
          category_type: "NA",
          scroll_depth: helpers.getScrollDepth(),
        });
      }


    renderEmptyList = () => {
        const { unlockType, searchText } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
          <View
            style={[
              Flex.alignCenter,
              { marginBottom: 200 },
              { paddingHorizontal: 20 },
            ]}
          >
            <CustomImage
              source={noUnlockedStories}
              require={true}
              style={{
                width: (windowWidth * 80) / 100,
                height: 300,
                resizeMode: "contain",
              }}
              webStyle={{ maxWidth: 600, width: "100%", height: "auto" }}
              altText={
                searchText == ""
                  ? unlockType == "free"
                    ? "No free stories"
                    : "No unlocked articles"
                  : "Match not found"
              }
            />
            <Text
              style={{
                fontSize: helpers.isDesktop() ? 36 : 20,
                lineHeight: helpers.isDesktop() ? 42 : 26,
                color: darkMode ? Colors.lightWhite : "#000000",
                fontWeight: FontWeight.medium,
                fontFamily: FontFamily.medium,
                marginBottom: 25,
                textAlign: "center",
              }}
            >
              {searchText == ""
                ? unlockType == "free"
                  ? "There are no free stories yet."
                  : "You have not unlocked any articles yet."
                : 'We couldn’t find a match for "' + searchText + '"'}
            </Text>

            <Text
              style={{
                fontSize: helpers.isDesktop() ? 24 : 16,
                lineHeight: helpers.isDesktop() ? 28 : 22,
                color: darkMode ? Colors.lineColor : "rgba(0,0,0,0.7)",
                fontWeight: FontWeight.regular,
                fontFamily: FontFamily.regular,
                marginBottom: 35,
                textAlign: "center",
              }}
            >
              {unlockType == "free"
                ? "We are constantly updating our free stories list. Do visit this page again for an update."
                : "Use your Privilege or Starter credits to unlock stories or subscribe to get access to all stories."}
            </Text>

            {Platform.OS != "ios" && (
              <ButtonGradient
                title={"VIEW ALL PLANS"}
                rootStyle={{
                  btnWrapper: button.primaryGradient,
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: helpers.isDesktop() ? 17 : 16,
                      fontWeight: "400",
                      lineHeight: 17,
                      paddingLeft: 40,
                      paddingRight: 40,
                    },
                  ],
                }}
                onClick={() => {
                  this.pushInformationToDataLayer();
                  this.navigateToScreen("/pricing");
                }}
                darkMode={darkMode}
              />
            )}
          </View>
        );
    }

    navigateToScreen = (link) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen(link);
        }
        else {
            this.props.navigation.navigate('/pricing');
        }
    }

    trackCateoryClick = (item) => {
        // try {
        //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: `reading list`, section_value: { title: item.name, slug: item.slug } });
        // }
        // catch (error) {
        //     console.log("mix panel error =>", error);
        // }
    }

    viewArticle = (catSlug, slug, postData) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.navigate('post-single', { categorySlug: catSlug, postSlug: slug, postData: postData });
        }
    }

    viewAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    viewCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    navigateToNewsletter = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/newsletters/' + slug);
        } else {
            this.props.navigation.navigate('single-newsletter', { newsletterSlug: slug });
        }
    }

    renderPageContent = () => {

        let { bookmarkChecked, data, searchText, postPage, postLists, unlockType } = this.state;
        const { userStoryAction } = this.props;
        let btnClickCategory, btnClickBookmark;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let items = postLists.map((item, index) => {
            if (helpers.isWeb()) {
                btnClickCategory = {
                    to: "/category/" + item.category.slug
                }
                btnClickBookmark = {
                    onPress: (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.applyStoryActions(Number(item.id), "bookmark", item.is_bookmarked ? false : true, index)
                    }
                }
            } else {
                btnClickCategory = {
                    onPress: () => this.viewCategory(item.category.slug)
                }
                btnClickBookmark = {
                    onPress: (event) => {
                        event.stopPropagation();
                        this.applyStoryActions(Number(item.id), "bookmark", item.is_bookmarked ? false : true, index)
                    }
                }
            }
            return (
                <View style={[articleItem.col6, Flex.row, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={Flex.fill}>
                        {item.newsletter
                            ?
                            <NewsletterCategory newsletter={item.newsletter} getNewsletterClick={(slug) => this.navigateToNewsletter(slug)} />
                            :
                            // <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)}>
                            //     <View>
                            //         <Text style={[articleLists.badge, { width: 150, marginBottom: 8, textAlign: 'left', fontFamily: FontFamily.regular, color: darkMode ? Colors.darkBlue : '#907cff' }, !helpers.isDesktop() && { fontSize: 14, marginBottom: 5 }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }]}>{item.category.name}</Text>
                            //     </View>
                            // </TouchableOpacityLink>
                            <View />
                        }
                        <ArticleItemHorizontal viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} data={item} single={index == 0 ? true : false} archive={true} listingStory={true} darkMode={darkMode} hideBookMark={helpers.isWeb() ? true : false} sectionName={(unlockType == 'free') ? "/free-reads" : "/unlocked-stories"}/>
                    </View>
                    <View style={[Margin.mt_3, Platform.OS == 'ios' && { marginTop: 25 }, helpers.isWeb() && { marginLeft: -10 }]}>
                        <TouchableOpacity {...btnClickBookmark}>
                            <View style={[Platform.OS == 'ios' && { paddingHorizontal: 5, paddingVertical: 5 }]}>
                                <CustomImage
                                    source={(item.is_bookmarked) ? bookmarkIconSelected : (darkMode ? bookmarkIconLight : bookmarkIcon)}
                                    require={true}
                                    style={{ width: 20, height: 20 }}
                                    webStyle={{ width: helpers.isDesktop() ? 26 : 21, height: helpers.isDesktop() ? 25 : 'auto', marginLeft: -5, marginRight: -5 }}
                                />
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        })
        return (
            <View style={[Padding.ph_2, Margin.mb_3]}>

                {postLists.length > 0 ? <InfiniteScrollComponent
                    pageStart={postPage.current}
                    loadMore={() => this.getMoreArticles()}
                    hasMore={postPage.has_next}
                    items={items}
                /> :
                    <View>
                        {this.renderEmptyList()}
                    </View>
                }

            </View>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </ScrollView>
        )
    }

    render() {
        const { unlockType, pageLoader, showErrorModal, footerData } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: (unlockType == 'free') ? '/free-reads' : '/',
            native: { redirect: (unlockType == 'free') ? '/free-reads' : '/', verifyRedirect: (unlockType == 'free') ? '/free-reads' : '/' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={(unlockType == 'free') ? seoObj['free-reads'] : seoObj['unlocked-stories']} url={process.browser ? window.location.origin + window.location.pathname : ''} page={(unlockType == 'free') ? "/free-reads" : "/unlocked-stories"} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }</>
                }
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
                {showErrorModal && <ErrorModal goToHome={() => this.props.navigateToScreen('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(UnlockedStories));

let Breadcrumbs = [];