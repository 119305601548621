import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Colors, FontFamily, button } from '../../styles/appStyles';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import ButtonGradient from '../../components/button-gradient';
import { helpers } from '../../utils/helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const notificationClose = getImagePath('icons/fileRemoveIcon.png');
const notificationCloseDark = getImagePath('icons/fileRemoveIconDark.png');

class NotificationAlert extends Component {
    toastTimeout;
    constructor(props) {
        super(props);
        this.state = {
            showUseAppSticky: true
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    openAppLink = () => {
        let os = helpers.getMobileOperatingSystem();
        if(os == 'Android' || os == 'iOS') {
            window.location.href = 'https://tmcstageapp.page.link/open-app';
        }
        else {
            console.log("OS not supported for dynamic linking")
        }
    }


    render() {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let { showUseAppSticky } = this.state;
        if (showUseAppSticky && !helpers.isDesktop() && window && !window.ReactNativeWebView) {
            return (

                <View style={{ flexDirection: 'row', paddingVertical: 15, paddingHorizontal: 20, alignItems: 'center', justifyContent: 'space-between', backgroundColor: darkMode ? Colors.darkBlackColor1 : '#ffffff', borderTopWidth: 3, borderTopColor: darkMode ? '#9819C0' : '#907cff', position: 'fixed', bottom: 0, width: '100%', zIndex: 10 }}>
                    <View style={{ width: '65%' }}>
                        <Text style={{ fontSize: 14, fontWeight: '700', fontFamily: FontFamily.bold, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 3 }}>The Morning Context App</Text>
                        <Text style={{ fontSize: 12, fontWeight: '400', fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000000' }}>Use the app to get the full experience.</Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <ButtonGradient
                            title={'Use App'}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, { height: 30 }],
                                btnText: [button.primaryGradientText, { fontSize: 14, paddingHorizontal: 7, fontWeight: '700', fontFamily: FontFamily.bold }]
                            }}
                            paddingOverride={true}
                            onClick={() => { this.openAppLink()}}
                            darkMode={darkMode}
                        />
                        <TouchableOpacity
                            activeOpacity={0.8}
                            style={{ marginLeft: 15 }}
                            onPress={() => { this.setState({ showUseAppSticky: false }) }}
                        >
                            <CustomImage
                                source={darkMode ? notificationCloseDark : notificationClose}
                                style={{ width: 12, height: 12 }}
                                webStyle={{ width: 12, height: 12 }}
                                require={true}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
        else {
            return (
                <></>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAlert);