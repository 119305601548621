import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, subscribePlans, Typo, button, exclusiveContent, FontWeight, FontFamily } from '../../styles/appStyles';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';

export class ExclusiveContent extends Component {
    constructor(props) {
        super(props);
        let images = this.sortImages(this.props.data);
        this.state = {
            images1: images.images1,
            images2: images.images2
        }
    }

    componentDidMount = () => {

    }

    gotoSignUp = () => {

    }

    sortImages = (images) => {
        return {
            images1: images.slice(0, 2),
            images2: images.slice(2, 4)
        }
    }

    render() {
        const { images1, images2 } = this.state;
        return (
            <View>
                <Text style={[Helpers.textCenter, Helpers.textUppercase, Typo.h3_M, helpers.isDesktop() &&  Typo.h3_MDesktop, Margin.mb_1]}>Get access to exclusive content</Text>
                <PageBreak size={'small'} style={[Margin.mb_2]} />
                <Text style={[Helpers.textCenter, Typo.descTextL, helpers.isDesktop() && {fontSize: 18, lineHeight: 27, letterSpacing: 0.3}, {fontWeight: FontWeight.regular, fontFamily: FontFamily.regular}]}>Read by thousands of industry professionals and companies, Our stories are for subscribers only.</Text>

                <View >
                    {helpers.isDesktop() ?
                        <View style={[Flex.row, Margin.mt_3]}>
                            {images1 &&
                                images1.map((item, index) => {
                                    return (
                                        <View key={`image_${index}`} style={[exclusiveContent.borderRight, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_3, Padding.pb_3, Flex.col_5, { width: '50%' }]}>
                                            <CustomImage
                                                source={item.image}
                                                require={false}
                                                style={{ width: 110, height: 35 }}
                                                webStyle={{ width: 110, height: 35 }}
                                                altText={item.name}
                                            />
                                        </View>
                                    )
                                })

                            }
                            {images2 &&
                                images2.map((item, index) => {
                                    return (
                                        <View key={`image_${index}`} style={[index == 0 && exclusiveContent.borderRight, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_3, Padding.pb_3, Flex.col_5, { width: '50%' }]}>
                                            <CustomImage
                                                source={item.image}
                                                require={false}
                                                style={{ width: 110, height: 35 }}
                                                webStyle={{ width: 110, height: 35 }}
                                                altText={item.name}
                                            />
                                        </View>
                                    )
                                })
                            }
                        </View>
                        :
                        <View>
                            <View style={[Flex.row, Margin.mt_3]}>
                                {images1 &&
                                    images1.map((item, index) => {
                                        return (
                                            <View key={`image_${index}`} style={[index == 0 && exclusiveContent.borderRight, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_4, Padding.pb_4, Flex.col_5, { width: '50%' }]}>
                                                <CustomImage
                                                    source={item.image}
                                                    require={false}
                                                    style={{ width: 110, height: 35 }}
                                                    webStyle={{ width: 110, height: 35 }}
                                                    altText={item.name}
                                                />
                                            </View>
                                        )
                                    })
                                }
                            </View>
                            <View style={[Flex.row]}>
                                {images2 &&
                                    images2.map((item, index) => {
                                        return (
                                            <View key={`image_${index}`} style={[exclusiveContent.borderTop, index == 0 && exclusiveContent.borderRight, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_4, Padding.pb_4, Flex.col_5, { width: '50%' }]}>
                                                <CustomImage
                                                    source={item.image}
                                                    require={false}
                                                    style={{ width: 110, height: 35 }}
                                                    webStyle={{ width: 110, height: 35 }}
                                                    altText={item.name}
                                                />
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    }
                </View>
            </View>
        );
    }
}


export default ExclusiveContent;