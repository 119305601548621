import React, { Component } from 'react';
import { Text, View, ScrollView, Platform, ActivityIndicator, Image, Modal, Linking, Dimensions } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, FontFamily, appStyles, Colors } from '../../styles/appStyles';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import PostContent from '../../components/post-content';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import ErrorModal from '../../components/error-modal';
import Seo from "../../components/seo-meta";
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from '../../utils/image-helper';
import DynamicPageHeader from '../../components/dynamic-page-header';
import NotFound from "../../components/not-found";
import GiftSubscriptionBox from '../../components/gift-subscription-box';
import PageBreak from '../../components/page-break';
import ReadFromArchives from '../../components/read-from-the-archives';
import SubscriptionInfoBox from '../../components/subscription-info-box';
import AuthenticationWrapper from '../../components/authentication-wrapper';

const gift_box_one = getImagePath('icons/gift_pl_one.png');
const gift_annual_sub = getImagePath('icons/gift_annual_sub.png');
const gift_box_two = getImagePath('icons/gift_pl_two.png');

const cookies = new Cookies();

const PrivilegeCreditGift = {
    "id": "privilege_credits",
    "name": "Privilege Credits",
    "tmc_group": "",
    "billing_enitity_type": "adhoc",
    "invoice_name": "Privilege Credits",
    "description": {
        "access_desc": [
            "Can gift upto 3 privilege credits a month for free",
            "Can be used to unlock any long form story or newsletter of your choice"
        ],
    },
    "is_popular": false,
    "recommended_plan": false,
    "discount": {},
    "is_free_privilege_credits": true
}

export class GiftingPage extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        let tokenSet = (token == undefined || token == '') ? false : true;
        let postSlug = 'gifting';

        this.state = {
            token: tokenSet ? token : false,
            pageLoader: true,
            postSlug: postSlug,
            userLoggedin: tokenSet,
            messageBox: {
                type: '',
                title: '',
                subTitle: '',
            },
            showErrorModal: false,
            footerData: this.props.footerData ? this.props.footerData : false,
            country: false,
            redirectSlugs: {
                web: '/' + postSlug,
                native: { redirect: '/' + postSlug, verifyRedirect: '/' + postSlug }
            },
            planCheckoutLoaderState: false,
        }

        this.subscriptionLayout = 0;
    }

    
  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }


    componentDidMount = () => {
        this.getData();
        if(!this.state.custom_user_id){
            this.setAuthToken()
          }
    }

    componentDidUpdate = () => {
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ?
            (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        if (!(this.props.searchQuery && (this.props.searchQuery.includes("subscribe=") || this.props.searchQuery.includes("buy-credits=true"))) && (((token && token != this.state.token) || ((token == undefined || token == '') && this.state.token != false)))) {
            let categorySlug = webView ? this.props.categorySlug : this.props.route.params.categorySlug;
            this.setState({
                token: (token == undefined || token == '') ? false : token,
                userLoggedin: (token == undefined || token == '') ? false : true,
                categorySlug: categorySlug
            }, () => this.refreshPage());
        }
    }

    setPlanButtonLoaderState = (state) => {
        this.setState({
            planCheckoutLoaderState: state
        })
      }
    
      getPlanButtonLoaderState = () => {
          return this.state.planCheckoutLoaderState
      }

    getData = () => {
        Promise.all([
            this.getPost(this.props.scrollTop ? true : false),
            this.getUserInfo(),
            this.getSubscriptionPlans()
        ]).then((response) => {
            console.log("check response ==>", response);
            if (response[0] && response[1] && response[2]) {
                if (response[0].code == 'page_found') {
                    this.setState({ postData: response[0].data, pageLoader: false, plansData: response[2], country: response[2].country, userSubscription: response[1].userSubscription, userProfile: response[1].userProfile, activeSubscription: response[1].activeSubscription });
                }
                else {
                    this.setState({ noPageFound: true });
                }
            }
            else {
                this.setState({ showErrorModal: true, pageLoader: false });
            }
        })

    }


    getPost = (scroll) => {
        return new Promise((resolve, reject) => {
            this.setState({ showErrorModal: false, pageLoader: true }, () => this.scrollToTop(scroll));
            const { token } = this.state;
            let endpoint = `/page/${this.state.postSlug}`;
            apiHelpers
                .apiRequest(endpoint, {}, "GET", true, token)
                .then((res) => {
                    if (res.success && (res.code == 'page_found' || res.code == 'no_page_found')) {
                        if (res.data) {
                            res.data.story = res.data.page
                        }
                        resolve(res);
                    }
                    else {
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log('Error=>> down', error);
                    resolve(false);
                });
        })
    }

    refreshPage = () => {
        if (this.state.token) {
            this.getUserInfo().then((response) => {
                if (response) {
                    this.setState({ userSubscription: response.userSubscription, userProfile: response.userProfile, activeSubscription: response.activeSubscription });
                }
            });
        }
    }

    getUserInfo = () => {
        return new Promise((resolve, reject) => {
            const { token } = this.state;

            if (token) {
                let endpoint = `/profile`;
                let body = {
                    request_group: ["profile", "subscription"]
                }
                apiHelpers
                    .apiRequest(endpoint, body, "POST", true, token)
                    .then((res) => {
                        if (res.code == 'access_denied') {
                            resolve(false);
                        }
                        let userSubscription = (!res.data.subscription || JSON.stringify(res.data.subscription) == '{}') ? false : res.data.subscription;
                        let teamSubscription = (!res.data.team_subscription || JSON.stringify(res.data.team_subscription) == '{}') ? false : res.data.team_subscription;
                        let activeSubscription = ((userSubscription && userSubscription.status != 'expired') || (teamSubscription && teamSubscription.is_team_member && teamSubscription.status != 'expired')) ? true : false;
                        if (res.success) {
                            resolve({
                                userSubscription: userSubscription,
                                teamSubscription: teamSubscription,
                                activeSubscription: activeSubscription,
                                userProfile: res.data
                            });
                        } else {
                            console.log('Error=>>', res);
                            resolve(false);
                        }
                    })
                    .catch((error) => {
                        console.log('Error=>>', error);
                        resolve(false);
                    });
            }
            else {
                resolve({
                    userSubscription: null,
                    teamSubscription: null,
                    activeSubscription: false
                });
            }
        })
    }

    getSubscriptionPlans = () => {
        return new Promise((resolve, reject) => {
            const { token } = this.state;
            let endpoint = `/plans/default`;
            let body = {
                filters: {
                    tmc_group: ["gift_plan", "gift_credit"]
                }
            }
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token)
                .then((res) => {
                    if (res.success) {
                        resolve(res.data);
                    } else {
                        console.log('Error=>>', res);
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log('Error=>>', error);
                    resolve(false);
                });
        })
    }

    scrollToTop = (scroll) => {
        if (scroll) {
            if (helpers.isWeb()) {
                setTimeout(function () {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }, 2);
            } else {
                if (this.appscrollview) {
                    this.appscrollview.scrollTo({ y: 0, animated: true })
                }
                if (this.contentscrollview) {
                    this.contentscrollview.scrollTo({ y: 0, animated: true })
                }
            }
        }
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    getModalClickEvent = () => {
        this.getData();
    }

    goToSignUp = (email) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-up?redirect=/' + this.state.categorySlug + '/' + this.state.postSlug + (email ? ('&email=' + email) : ''));
        } else {
            this.props.navigation.push('sign-up', { redirect: 'post-single', categorySlug: this.state.categorySlug, postSlug: this.state.postSlug, verifyRedirect: '/' + this.state.categorySlug + '/' + this.state.postSlug, email: email });
        }
    }

    goToLogin = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-in?redirect=/' + this.state.categorySlug + '/' + this.state.postSlug);
        } else {
            this.props.navigation.push('sign-in', { redirect: 'post-single', categorySlug: this.state.categorySlug, postSlug: this.state.postSlug, verifyRedirect: '/' + this.state.categorySlug + '/' + this.state.postSlug });
        }
    }

    changeFontSize = () => {
        console.log('FontSize Changed')
    }

    navigateToHome = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/');
        } else {
            this.props.navigation.navigate('homescreen');
        }
    }

    navigateToPost = (catSlug, slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.push('post-single', { categorySlug: catSlug, postSlug: slug });
        }
    }

    navigateToTag = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/tags/' + slug);
        } else {
            this.props.navigation.push('tags-archive', { tagSlug: slug });
        }
    }

    navigateToCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.push('category', { categorySlug: slug });
        }
    }

    navigateToAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.push('writer-single', { writerSlug: slug });
        }
    }

    openSocialMedia = (link) => {
        if (helpers.isWeb()) {
            window.open(link, '_blank');
        } else {
            Linking.openURL(link);
        }
    }

    scrollToSection = (position) => {
        console.log("scrollToSection ==>", position)
        let offsetPosition = position
        if (helpers.isWeb()) {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(offsetPosition)
        }

    }

    updateFreeCreditAvailable = (credits) => {
        let { userProfile } = this.state;
        userProfile.free_credits_available = userProfile.free_credits_available - parseInt(credits);
        this.setState({ userProfile });
    }

    goToSignUp = (email) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-up?redirect=/gifting' + (email ? ('&email=' + email) : ''));
        } else {
            this.props.navigation.navigate('/sign-up', { redirect: '/gifting', verifyRedirect: '/gifting', email: email });
        }
    }

    pushSubscribeEventToDataLayer = () => {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "subscribe_initiate",
          custom_user_id: this.state.custom_user_id || "NA",
          user_status: this.state.custom_user_id ? "logged in" : "guest",
          header_title: "stay updated with our latest stories",
          banner_name: "subscribe to TMC now",
          CTA_text: "subscribe",
          CTA_position: "bottom",
          scroll_depth: helpers.getScrollDepth(),
          category_type: "gift subscription",
        });
      }

    handleInfoBoxClick = () => {
        this.pushSubscribeEventToDataLayer()
        let { activeSubscription } = this.state;
        if (activeSubscription) {
            if (helpers.isWeb()) {
                this.props.navigateToScreen('/all-stories')
            }
            else {
                this.props.navigation.navigate('/all-stories', { categorySlug: 'all' });
            }
        }
        else {
            if (helpers.isWeb()) {
                this.props.navigateToScreen('/pricing')
            }
            else {
                this.props.navigation.navigate('/pricing', {});
            }
        }
    }

    openGiftCardModal = (plan_id, plan, country) => {
        let { token } = this.state;
        if (helpers.isWeb()) {
            this.refs.header.openGiftCardModal(plan_id, plan, country)
        }
        else {
            if (token) {
                this.refs.header.openGiftCardModal(plan_id, plan, country)
            }
            else {
                this.props.navigation.navigate('/sign-in', this.state.redirectSlugs.native);
            }
        }
    }

    openGiftSubModal = (plan_id, plan, country) => {
        let { token } = this.state;
        if (helpers.isWeb()) {
            this.refs.header.openGiftSubModal(plan_id, plan, country)
        }
        else {
            if (token) {
                this.refs.header.openGiftSubModal(plan_id, plan, country)
            }
            else {
                this.props.navigation.navigate('/sign-in', this.state.redirectSlugs.native);
            }
        }
    }

    renderPageContent = () => {
        const { postData, postDataSSR, pageLoader, userLoggedin, creditPlan, plansData, postUnlocked, userSubscriptionActive, userCredit, enableMessageBox, messageBox, userSubscriptionStatus, storyActions, storyStats, userLoader, plansLoader, unlockLoader, insufficientCredits, userSubscription, country, writerData, userProfile, activeSubscription, token } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let postSummary;
        if (postData && postData.story) {
            postSummary = {
                postTitle: postData.story.title,
                author: postData.story.author,
                story: postData.story,
                lazyLoad: !postDataSSR
            }
        }

        let subInfoBox = {
            title: 'Stay updated with our latest stories',
            subtitle: 'Subscribe to TMC now',
            btnText: 'SUBSCRIBE'
        }
        if (activeSubscription || Platform.OS == 'ios') {
            subInfoBox.subtitle = null;
            subInfoBox.btnText = 'VIEW LATEST STORIES'
        }


        return (
            <>
                <View style={{ marginBottom: helpers.isDesktop() ? 0 : 0 }}>
                    <DynamicPageHeader data={postData.story} darkMode={darkMode} />
                </View>
                <>
                    <View style={[!helpers.isDesktop() && { marginBottom: 0 }]}>
                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() ? Padding.ph_2 : Padding.ph_0]}>
                            <View style={{ marginTop: 60 }}>
                                {
                                    helpers.isDesktop() ? <View>
                                        <Text style={[Helpers.textCenter, Typo.h3_M, helpers.isDesktop() && Typo.h3_MDesktop, Margin.mb_1, { fontSize: helpers.isDesktop() ? 34 : 18, color: '#000' }, { fontFamily: FontFamily.abrilFatface }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Choose your gift</Text>
                                        <PageBreak size={'small'} style={[Margin.mb_2]} darkMode={darkMode} />
                                    </View>
                                        :
                                        <View style={[Flex.alignCenter, Margin.mb_3]}>
                                            <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                                <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: 'rgba(255, 255, 255, 0.6)' }]}>
                                                    <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 20, color: '#000' }, { fontFamily: FontFamily.abrilFatface, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)', borderColor: 'rgba(255, 255, 255, 0.6)' }]}>Choose your gift</Text>
                                                </View>
                                            </View>
                                            <View style={[Helpers.titleLine, { backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.89)' : 'rgba(0, 0, 0, 0.6)' }]}></View>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                    <View style={{ position: 'relative', marginTop: helpers.isDesktop() ? 120 : 60 }}>
                        <View style={[!helpers.isDesktop() && { marginBottom: 0 }]}>
                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() ? Padding.ph_2 : Padding.ph_2]}>
                                <View style={{ flexDirection: helpers.isDesktop() ? 'row' : 'column', justifyContent: 'center' }}>
                                    {/* {plansData.plans.gift_credit.map((plan) => {
                                        return (<GiftSubscriptionBox
                                            darkMode={darkMode}
                                            icon={gift_box_one}
                                            plan={plan}
                                            subscribeToPlan={() => this.openGiftCardModal(plan.id, plan, this.state.country)}
                                            openSocialMedia={this.openSocialMedia}
                                        />
                                        )
                                    })} */}
                                    {plansData.plans.gift_plan.map((plan) => {
                                        return (<GiftSubscriptionBox
                                            darkMode={darkMode}
                                            icon={gift_annual_sub}
                                            plan={plan}
                                            annualSub={true}
                                            subscribeToPlan={() => this.openGiftCardModal(plan.id, plan, this.state.country)}
                                            openSocialMedia={this.openSocialMedia}
                                        />
                                        )
                                    })}
                                    {/* <GiftSubscriptionBox
                                        darkMode={darkMode}
                                        icon={gift_box_two}
                                        plan={PrivilegeCreditGift}
                                        subscribeToPlan={(giftCredits) => this.refs.header.openGiftCardModal(PrivilegeCreditGift.id, PrivilegeCreditGift, giftCredits)}
                                        userSubscription={userSubscription}
                                        freeCreditsAvailable={userProfile?.free_credits_available}
                                        plCredits={true}
                                    /> */}
                                </View>
                            </View>
                        </View>
                    </View>
                </>

                <View style={[appStyles.appBg, darkMode && appStyles.appBgDark]}>
                    {postData.story.content &&
                        <View style={Padding.ph_2}>
                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull]}>
                                <PostContent data={postData.story.content} dynamicPage={true} darkMode={darkMode} openLink={this.openSocialMedia} />
                            </View>
                        </View>
                    }
                </View>

                {/* {!token && <View style={{ marginBottom: helpers.isDesktop() ? 140 : 50 }}>
                    <ReadFromArchives goToSignUp={(email) => this.goToSignUp(email)} subscription={true} darkMode={darkMode} />
                </View>} */}

                <View style={{ marginBottom: helpers.isDesktop() ? 190 : 50 }}>
                    <SubscriptionInfoBox data={subInfoBox} handleClick={() => this.handleInfoBoxClick()} darkMode={darkMode} />
                </View>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const { pageLoader, postData, showErrorModal, token, footerData, redirectSlugs, showInsufficientCreditsPopup, creditPlan, country, noPageFound } = this.state;

        return (
            <View style={[webView && null, { flex: 1, backgroundColor: 'white' }, darkMode && appStyles.appBgDark]}>
                <Seo data={{}} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.push(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.refreshPage()} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} updateFreeCreditAvailable={this.updateFreeCreditAvailable} showFullHeader={true} 
                setCheckoutLoaderState={(loaderState) => this.setPlanButtonLoaderState(loaderState)}
                getCheckoutLoaderState={this.getPlanButtonLoaderState}
                />
                {noPageFound ?
                    <NotFound navigateToScreen={() => { this.props.navigateToScreen('/') }} darkMode={darkMode} />
                    :
                    <>{!showErrorModal && <>
                        {pageLoader ?
                            (webView
                                ? this.showPageLoader()
                                : <ScrollView ref={ref => this.contentscrollview = ref} style={{ flex: 1 }}>{this.showPageLoader()}</ScrollView>
                            )
                            :
                            (webView
                                ? this.renderPageContent()
                                : <ScrollView ref={ref => this.appscrollview = ref} style={{ flex: 1 }}>{this.renderPageContent()}
                                    <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.push(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
                                </ScrollView>
                            )
                        }
                    </>
                    }</>
                }


                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(GiftingPage));


let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Gifting'
    }
]