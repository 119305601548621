import { chargebeeHostedPageFactory__Interface, chargebeeHostedPage__Interface, chargebeeHostedPage__v1, chargebeeHostedPage__v1__Factory } from "../hosted-page";
import { chargbeeController__Factory__I, chargebeeController__I, ControllerStatusType } from "./interface";
import { chargebeePortalFactory__Interface, chargebeePortal__Interface, chargebeePortal__v1, chargebeePortal__v1__Factory } from "../portal";
import { chargebeeInstanceFactory__Interface, chargebeeInstance__Interface, ChargebeeInstance__v1, chargebeeInstance__v1__Factory } from "../cb-instance";
import { Dictionary } from "../global-types";
import { chargbeeController } from './abstract'

import { Mixins_v1 } from './mixins'
import { Mix_v1 } from "../mixin-builder";

/**
 * The Factory class of chargebeeController {@link ChargebeeController__v1} class
 */
export const chargbeeController__v1__Factory: chargbeeController__Factory__I = {
    createInstance: (): ChargebeeController__v1 => {
        return ChargebeeController__v1.getController(chargebeeInstance__v1__Factory)
    }
}


/**
 * The base {@link chargbeeController} attached with sub objects using mixin pattern. The resulting object is of structure containing all the applied mixins ({@link Mixins_v1}) properties
 */
export class ChargebeeController__v1 extends Mix_v1(chargbeeController).with(...Mixins_v1){
    static singletonInstance?: ChargebeeController__v1
    static getController(instanceFactory: chargebeeInstanceFactory__Interface, tmcAuthToken?: any | null): ChargebeeController__v1 {

        if(ChargebeeController__v1.singletonInstance){
            return ChargebeeController__v1.singletonInstance
        }
        ChargebeeController__v1.singletonInstance =  new ChargebeeController__v1(instanceFactory, tmcAuthToken)

        return ChargebeeController__v1.singletonInstance
    }
}
