import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  ontWeight,
  Colors,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import ArticleItemHorizontal from "../article-item-horizontal";
import ScrollContainerView from "../scroll-container-view";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import ArticleItem from "../article-item";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import Cookies from "universal-cookie";
import { Mixpanel } from "../../utils/mixpanel";
import AuthenticationWrapper from "../authentication-wrapper";

const viewMore = getImagePath("icons/view-more.png");
const cookies = new Cookies();

export class HomeStarterCollection extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let tok = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      showToken: !helpers.isWeb() || process.browser ? true : false,
      token: tok,
    };
  }

  componentDidMount = () => {};

  applyStoryActions = (id, type, value, index) => {
    const { token } = this.state;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let freeStoryList = this.props.data;
      freeStoryList[index].is_bookmarked = !freeStoryList[index].is_bookmarked;
      this.setState({ freeStoryList: freeStoryList });
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let freeStoryList = this.props.data;
            freeStoryList[index].is_bookmarked = res.data.bookmark;
            this.setState({ freeStoryList: freeStoryList });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }

            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: freeStoryList[index].title,
                  story_slug: freeStoryList[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: freeStoryList[index].title,
                  story_slug: freeStoryList[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }

            this.props.refreshScreen(!this.props.app.refreshScreen);
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let freeStoryList = this.state.freeStoryList;
            freeStoryList[index].is_bookmarked =
              !freeStoryList[index].is_bookmarked;
            this.setState({ freeStoryList: freeStoryList });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let freeStoryList = this.state.freeStoryList;
            freeStoryList[index].is_bookmarked =
              !freeStoryList[index].is_bookmarked;
            this.setState({ freeStoryList: freeStoryList });
          }
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/sign-in?redirect=${
            this.state.redirectSlugs.web
          }&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`
        );
      } else {
        this.props.navigation.navigate("/sign-in", {
          ...this.state.redirectSlugs.native,
          verifyRedirect:
            this.state.redirectSlugs.native.verifyRedirect +
            `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`,
          "redirect-params": `id=${id}+action=bookmark+value=${true}+index=${index}`,
        });
      }
    }
  };

  viewMoreArticles = () => {
    const { viewAllArticles } = this.props;
    viewAllArticles();
  };

  viewArticle = (catSlug, slug, postData) => {
    const { viewArticle } = this.props;
    viewArticle(catSlug, slug, postData);
  };

  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };
  viewCategory = (slug) => {
    const { viewCategory } = this.props;
    viewCategory(slug);
  };

  handleClick = (title, data, index) => {
    try {
      if (this.props.trackStoryClick) {
        this.props.trackStoryClick(title, data, index);
      }
    } catch (error) {
      console.log("check mix panel track error ==>", error);
    }
  };

  trackViewAll = () => {
    try {
      Mixpanel.track("click_view_more_latest");
    } catch (error) {}
  };

  trackCateoryClick = (item) => {
    // try {
    //     Mixpanel.track('category', {category_slug: item.category.slug, source_section_type: 'home_latest_stories', section_value: { title : item.name, slug: item.slug } });
    // }
    // catch(error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  render() {
    const { data, credits, darkMode } = this.props;
    console.log("starter", data);
    const SliverCoinIcon = getImagePath("icons/silver-coin.png");
    let btnClick, btnClickCategory;
    let webView = helpers.isWeb();
    if (helpers.isWeb()) {
      btnClick = {
        to: "/free-reads",
      };
    } else {
      btnClick = {
        onPress: () => this.viewMoreArticles(),
      };
    }
    return (
      <View
        style={[
          helpers.isDesktop() && Padding.ph_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginTop: 70, marginBottom: 30 },
          !helpers.isDesktop() && { paddingBottom: 0 },
          darkMode && { backgroundColor: Colors.darkBlackColorBg },
        ]}
      >
        <View
          style={[
            article.headerWrapper,
            {
              width: "100%",
              paddingLeft: helpers.isDesktop() ? "7.5%" : 0,
              marginTop: helpers.isDesktop() ? "-67px" : "-20px",
            },
          ]}
        >
          <View
            style={[
              helpers.isDesktop() ? Flex.alignStart : Flex.alignCenter,
              !helpers.isDesktop() && Margin.mb_2,
              Margin.mt_3,
              !helpers.isDesktop() && Margin.mt_5,
            ]}
          >
            <View
              style={[
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
                { width: "100%" },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && {
                    flexDirection: "row",
                    justifyContent: "space-between",
                  },
                ]}
              >
                <View
                  style={[
                    helpers.isDesktop() && Helpers.borderBottom,
                    darkMode && {
                      borderBottomColor: "rgba(255, 255, 255, 0.6)",
                    },
                  ]}
                >
                  <Text
                    style={[
                      !helpers.isDesktop() && { textAlign: "center" },
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      {
                        fontFamily: FontFamily.abrilFatface,
                        color: helpers.isDesktop() ? "#000" : "#051462",
                      },
                      { fontSize: helpers.isDesktop() ? 34 : 34 },
                      !helpers.isWeb() && { marginBottom: 1 },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Free Stories for you
                  </Text>
                </View>
                <View
                  style={[
                    appHeader.trendingWrapper,
                    { marginLeft: 0, paddingHorizontal: 0 },
                  ]}
                >
                  {helpers.isDesktop() && (
                    <TouchableOpacityLink {...btnClick}>
                      <Text
                        style={[
                          Helpers.textUppercase,
                          {
                            color: "#907CFF",
                            fontSize: 16,
                            fontFamily: FontFamily.medium,
                            fontWeight: FontWeight.semiBold,
                            whiteSpace: "nowrap",
                            borderWidth: 1,
                            borderColor: "#907CFF",
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                          },
                        ]}
                        onPress={() => {
                          try {
                            console.log("try");
                            window.webengage.track(
                              "Click View All Free Stories",
                              {
                                "Type of User": this.state?.token
                                  ? this.props?.freeUser
                                    ? "Free"
                                    : "Paid"
                                  : "non-logged in",
                                "Component Name": "HomeStarterCollection",
                              }
                            );
                            console.log("success");
                          } catch (err) {}
                        }}
                      >
                        VIEW ALL FREE STORIES
                      </Text>
                    </TouchableOpacityLink>
                  )}
                </View>
                {!helpers.isDesktop() && (
                  <View
                    style={{
                      backgroundColor: "#f8f8f8",
                      height: 2,
                      position: "absolute",
                      top: 13,
                      right: 0,
                      left: 0,
                      width: "100%",
                      zIndex: -1,
                      backgroundColor: "transparent",
                    }}
                  ></View>
                )}
              </View>
            </View>
            <View
              style={[
                helpers.isDesktop() ? Padding.pv_2 : { paddingVertical: 0 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <Text
                style={[
                  Helpers.textCenter,
                  Typo.h3_R,
                  helpers.isDesktop() && Typo.h3_RDesktop,
                  !helpers.isDesktop() && { paddingHorizontal: 10 },
                  Margin.mb_0,
                  { fontSize: 14 },
                  { color: "#000" },
                  { fontSize: 18 },
                  !helpers.isWeb() && { marginBottom: 1 },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                Unlock stories from your starter collection using the Free
                Starter Credits.
              </Text>
            </View>
          </View>
          <View
            style={[
              helpers.isDesktop() ? Flex.row : Flex.row,
              helpers.isDesktop() ? Flex.alignStart : { alignSelf: "center" },
            ]}
          >
            <CustomImage
              source={SliverCoinIcon}
              require={true}
              style={{
                width: helpers.isDesktop() ? 30 : 30,
                height: helpers.isDesktop() ? 30 : 30,
              }}
              webStyle={{
                width: helpers.isDesktop() ? 30 : 30,
                height: helpers.isDesktop() ? 30 : 30,
              }}
              altText={"Starter collection"}
            />
            <Text
              style={[
                Padding.pl_1,
                {
                  fontFamily: FontFamily.regular,
                  fontSize: helpers.isDesktop() ? 20 : 16,
                  lineHeight: 34,
                  color: "#292929",
                },
                darkMode && { color: "rgba(255,255,255,0.87)" },
              ]}
            >
              Starter Credit Balance:{" "}
            </Text>
            <Text
              style={[
                {
                  color: "#f02121",
                  fontFamily: FontFamily.regular,
                  fontSize: helpers.isDesktop() ? 20 : 16,
                  lineHeight: 34,
                },
                credits &&
                  credits.non_privileged_credits &&
                  credits.non_privileged_credits.available > 2 && {
                    color: "#55C79A",
                  },
              ]}
            >
              {credits && credits.non_privileged_credits
                ? credits.non_privileged_credits.available
                : 0}
            </Text>
          </View>
        </View>
        <View
          style={[
            Margin.mt_1,
            Margin.mb_4,
            helpers.isDesktop() && Margin.mb_7,
            !helpers.isDesktop() && Padding.ph_2,
            !helpers.isDesktop() && { marginBottom: 60 },
          ]}
        >
          {helpers.isDesktop() ? (
            <View style={[Flex.row, Flex.justfiyCenter]}>
              {data &&
                data.map((item, index) => {
                  if (helpers.isWeb()) {
                    btnClickCategory = {
                      to: "/category/" + item.category.slug,
                    };
                  } else {
                    btnClickCategory = {
                      onPress: () => this.viewCategory(item.category.slug),
                    };
                  }
                  return (
                    <View
                      style={[
                        articleItem.col4,
                        helpers.isDesktop() && articleItem.col4Desktop,
                        { marginRight: 25 },
                        {
                          width: helpers.isDesktop() ? "27%" : "100%",
                          marginTop: helpers.isDesktop() ? 50 : 0,
                        },
                        index == 2 && { marginRight: 0 },
                      ]}
                    >
                      <TouchableOpacityLink
                        {...btnClickCategory}
                        handleClick={() => this.trackCateoryClick(item)}
                      >
                        <View>
                          <Text
                            style={[
                              articleLists.badge,
                              {
                                width: 150,
                                marginBottom: -4,
                                textAlign: "left",
                                fontFamily: FontFamily.regular,
                                color:
                                  item.category.name === "Business"
                                    ? "#2CB680"
                                    : item.category.name === "Chaos"
                                    ? "#907cff"
                                    : item.category.name === "Internet"
                                    ? "#FC876D"
                                    : "#ECB731",
                                letterSpacing: 1,
                                fontSize: 18,
                                fontFamily: "HaasGrotesk-Medium",
                                fontWeight: 500,
                              },
                            ]}
                          >
                            {item.category.name}
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                      <ArticleItemHorizontal
                        viewArticle={(catSlug, slug, postData) =>
                          this.viewArticle(catSlug, slug, postData)
                        }
                        applyStoryActions={(id, type, value, index) =>
                          this.applyStoryActions(id, type, value, index)
                        }
                        index={index}
                        freeStoryList={this.props.data}
                        token={this.state.token}
                        viewAuthor={(slug) => this.viewAuthor(slug)}
                        data={item}
                        single={index == 0 ? true : false}
                        elmsecond={index == 1 ? true : false}
                        exclusiveHome={true}
                        handleClick={(title) => {
                          this.handleClick(title, item, index);
                        }}
                        hideDate={true}
                        darkMode={darkMode}
                        sectionName="Free Stories for you"
                      />
                    </View>
                  );
                })}
            </View>
          ) : (
            <View
              style={[Margin.mb_4, !process.browser && { overflow: "hidden" }]}
            >
              {data &&
                data.map((item, index) => {
                  if (helpers.isWeb()) {
                    btnClickCategory = {
                      to: "/category/" + item.category.slug,
                    };
                  } else {
                    btnClickCategory = {
                      onPress: () => this.viewCategory(item.category.slug),
                    };
                  }
                  return (
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                      <TouchableOpacityLink
                        {...btnClickCategory}
                        handleClick={() => this.trackCateoryClick(item)}
                      >
                        <View>
                          <Text
                            style={[
                              articleLists.badge,
                              {
                                width: 150,
                                marginBottom: -15,
                                textAlign: "left",
                                fontFamily: FontFamily.regular,
                                color:
                                  item.category.name === "Business"
                                    ? "#2CB680"
                                    : item.category.name === "Chaos"
                                    ? "#907cff"
                                    : item.category.name === "Internet"
                                    ? "#FC876D"
                                    : "#ECB731",
                                fontSize: 13,
                                lineHeight: 16,
                                letterSpacing: 0.5,
                                fontFamily: "HaasGrotesk-Medium",
                                fontWeight: 500,
                              },
                              !helpers.isWeb() && {
                                fontFamily: FontFamily.semiBold,
                              },
                            ]}
                          >
                            {item.category.name}
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                      <ArticleItemHorizontal
                        viewArticle={(catSlug, slug, postData) =>
                          this.viewArticle(catSlug, slug, postData)
                        }
                        viewAuthor={(slug) => this.viewAuthor(slug)}
                        freeStoryList={this.props.data}
                        data={item}
                        token={this.state.token}
                        applyStoryActions={(id, type, value, index) =>
                          this.applyStoryActions(id, type, value, index)
                        }
                        single={index == 0 ? true : false}
                        exclusiveHome={true}
                        handleClick={(title) => {
                          this.handleClick(title, item, index);
                        }}
                        hideDate={true}
                        darkMode={darkMode}
                        sectionName="Free Stories for you"
                      />
                    </View>
                  );
                })}
              {!helpers.isDesktop() && (
                <View
                  style={{
                    margin: 30,
                    alignSelf: "center",
                  }}
                >
                  <TouchableOpacityLink {...btnClick}>
                    <Text
                      style={[
                        Helpers.textUppercase,
                        {
                          color: "#907CFF",
                          fontSize: 18,
                          fontFamily: FontFamily.medium,
                          fontWeight: FontWeight.semiBold,
                          whiteSpace: "nowrap",
                          borderWidth: 1,
                          borderColor: "#907CFF",
                          paddingHorizontal: 20,
                          paddingVertical: 10,
                        },
                      ]}
                      onPress={() => {
                        try {
                          console.log("try");
                          window.webengage.track(
                            "Click View All Free Stories",
                            {
                              "Type of User": this.state?.token
                                ? this.props?.freeUser
                                  ? "Free"
                                  : "Paid"
                                : "non-logged in",
                              "Component Name": "HomeStarterCollection",
                            }
                          );
                          console.log("success");
                        } catch (err) {}
                      }}
                    >
                      VIEW ALL FREE STORIES
                    </Text>
                  </TouchableOpacityLink>
                </View>
              )}
              {/* <TouchableOpacityLink
                            {...btnClick}  handleClick={(title) => {this.trackViewAll()}}
                        >
                            <View style={[Flex.column, Flex.alignCenter,{width: 155, position:'absolute', top: 100, marginLeft: -15}]}>
                                <CustomImage
                                    source={viewMore}
                                    require={true}
                                    style={{ width: 36, height: 36, margin: 'auto' }}
                                    webStyle={{ width: 36, height: 36, margin: 'auto'}}
                                />
                                <Text style={[button.secondaryOutlineText, Margin.mr_0, {fontWeight: '500'}]}>View all articles</Text>
                            </View>
                        </TouchableOpacityLink> */}
            </View>
          )}
          {/* {helpers.isDesktop() &&
                        <View style={[Flex.alignCenter, Margin.mt_4, { marginTop: helpers.isDesktop() ? 50 : 40 }, helpers.isDesktop() && { marginBottom: 30}]}>
                            <TouchableOpacityLink
                                {...btnClick}  handleClick={(title) => {this.trackViewAll()}}
                            >
                                <View style={[Flex.row, Flex.alignCenter]}>
                                    <Text style={[button.secondaryOutline, helpers.isDesktop() && button.secondaryOutlineDesktop ,Margin.mr_0, {fontWeight: FontWeight.semiBold}]}>View all articles</Text>
                                </View>
                            </TouchableOpacityLink>
                        </View>
                    }*/}
        </View>
      </View>
    );
  }
}

export default AuthenticationWrapper(HomeStarterCollection);
