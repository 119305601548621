'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.default = generatePropsFromAttributes;

var _htmlAttributesToReact = require('./htmlAttributesToReact');

var _htmlAttributesToReact2 = _interopRequireDefault(_htmlAttributesToReact);

var _inlineStyleToObject = require('./inlineStyleToObject');

var _inlineStyleToObject2 = _interopRequireDefault(_inlineStyleToObject);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Generates props for a React element from an object of HTML attributes
 *
 * @param {Object} attributes The HTML attributes
 * @param {String} key The key to give the react element
 */
function generatePropsFromAttributes(attributes, key) {

  // generate props
  var props = _extends({}, (0, _htmlAttributesToReact2.default)(attributes), { key: key });

  // if there is an inline/string style prop then convert it to a React style object
  // otherwise, it is invalid and omitted
  if (typeof props.style === 'string' || props.style instanceof String) {
    props.style = (0, _inlineStyleToObject2.default)(props.style);
  } else {
    delete props.style;
  }

  return props;
}