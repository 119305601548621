import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  button,
  appStyles,
  article,
  FontFamily,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ButtonGradient from "../../components/button-gradient";
import CustomImage from "../../components/custom-image";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import getImagePath from "../../utils/image-helper";
import Seo from "../../components/seo-meta";
import { setUserToken } from "../../../redux/User/user.actions";
import PageBreak from "../../components/page-break";
import { Mixpanel } from "../../utils/mixpanel";
import { GoogleAnalytics } from "../../utils/google-analytics";
import { removeUserToken } from "../../../redux/User/user.actions";
import { removeFcmToken } from "../../../redux/App/app.actions";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const cookies = new Cookies();
const invitationVerified = getImagePath("icons/invitation-verified.png");
const emailWarningIcon = getImagePath("icons/email-warning.png");
const annualGiftSub = getImagePath("icons/annualGiftSub.png");
const creditGiftIcon = getImagePath("icons/gift_pl_one.png");

export class ClaimGift extends Component {
  constructor(props) {
    super(props);
    let giftCode = null,
      email = null,
      reset_password_token = null;
    let searchArr, type;
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;

    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;

    if (
      webView &&
      process.browser &&
      window.location.search.includes("token=")
    ) {
      searchArr = window.location.search.replace("?", "").split("&");
    } else if (!webView && this.props.route?.params?.query) {
      searchArr = this.props.route.params.query.split("&");
    } else if (
      !webView &&
      this.props.route?.params &&
      this.props.route?.params["redirect-params"]
    ) {
      searchArr = this.props.route.params["redirect-params"].split("&");
    }

    if (searchArr) {
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("token=");
      });
      if (foundSearch) {
        let search = foundSearch.replace("token=", "");
        search = search.replace("redirect-params=", "");
        giftCode = search != "" ? search : null;
      }

      searchArr.map((searchParam) => {
        if (searchParam.includes("email=")) {
          email = searchParam.replace("email=", "");
        }
        return searchParam;
      });

      searchArr.map((searchParam) => {
        if (searchParam.includes("reset-password-token=")) {
          reset_password_token = searchParam.replace(
            "reset-password-token=",
            ""
          );
        }
        return searchParam;
      });
    }

    this.state = {
      token: token,
      enableBtnLoader: true,
      error: false,
      buttonCode: false,
      redirectUrl: "/",
      email: email,
      type: type,
      giftCode: giftCode,
      giftData: {},
      resetPasswordToken: reset_password_token,
    };
  }

  componentDidMount() {
    const { token, giftCode, email, resetPasswordToken } = this.state;

    if (resetPasswordToken && !token) {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/reset-password?token=${resetPasswordToken}&gift-token=${giftCode}`
        );
      } else {
        this.props.navigation.replace("/reset-password", {
          query: `token=${resetPasswordToken}&gift-token=${giftCode}`,
        });
      }
    } else {
      let endpoint = `/verify-gift-claim`;
      let body = {
        gift_code: giftCode,
      };

      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.code == "access_denied") {
            if (helpers.isWeb()) {
              this.props.navigateToScreen(
                `/sign-in?redirect=claim-gift&redirect-params=token=${giftCode}`
              );
            } else {
              this.props.navigation.replace("/sign-in", {
                redirect: "/claim-gift",
                verifyRedirect:
                  "/claim-gift" + `?redirect-params=token=${giftCode}`,
                "redirect-params": `token=${giftCode}`,
              });
            }
            return;
          }
          if (
            res.code == "gift_claimed" ||
            res.code == "gift_already_claimed"
          ) {
            if (res.code == "gift_already_claimed") {
              res.data.already_claimed = true;
            }
            if (res.data.story) {
              this.trackGiftClaimed();
              globalThis.giftStoryData = res.data;
              if (helpers.isWeb()) {
                this.props.history.replace(
                  "/" + res.data?.story?.category + "/" + res.data?.story?.slug
                );
              } else {
                this.props.navigation.replace("post-single", {
                  categorySlug: res.data?.story?.category,
                  postSlug: res.data?.story?.slug,
                  postData: null,
                });
              }
            } else {
              this.setState({
                enableBtnLoader: false,
                buttonCode: res.code,
                giftData: res.data,
              });
            }
          } else if (res.code == "wrong_user") {
            this.setState({
              enableBtnLoader: false,
              buttonCode: res.code,
              errorMessage: "Gift not for this user",
              userEmailId: res.data.email_id,
            });
          } else {
            this.setState({
              enableBtnLoader: false,
              buttonCode: res.code,
              errorMessage: res?.error?.account_email,
            });
          }
        })
        .catch((error) => {
          this.setState({
            enableBtnLoader: false,
            error: "",
          });
          console.log("Error=>>", error);
        });
    }
  }

  trackGiftClaimed = async () => {
    try {
      const { fetchToken } = this.props.auth;
      if (typeof fetchToken != "function") {
        return;
      }
      const { idToken } = await fetchToken();
      const email = idToken["email"];
      console.log("Gift Claimed", {
        Email: email,
      });
      window.webengage.track("Gift Claimed", {
        Email: email,
      });
    } catch (error) {
      console.log("track gift claimed error", error);
    }
  };

  goToNextPage = () => {
    let { giftData } = this.state;
    if (giftData.gift_type == "gift_credit") {
      if (helpers.isWeb()) {
        this.navigateToScreen("/");
        setTimeout(() => {
          let pushProp = JSON.parse(JSON.stringify(window.location));
          pushProp.hash = "accounts/privileged-credits";
          this.props.history.push(pushProp);
        }, 100);
      } else {
        this.props.navigation.replace("accounts/privileged-credits");
      }
    } else {
      if (helpers.isWeb()) {
        this.navigateToScreen("/");
        setTimeout(() => {
          let pushProp = JSON.parse(JSON.stringify(window.location));
          pushProp.hash = "accounts/subscriptions";
          this.props.history.push(pushProp);
        }, 100);
      } else {
        this.props.navigation.replace("accounts/subscriptions");
      }
    }
  };

  navigateToScreen = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(slug);
    } else {
      this.props.navigation.navigate(slug);
    }
  };

  signOut = () => {
    let { giftCode, resetPasswordToken } = this.state;
    this.props.removeUserToken();

    if (resetPasswordToken) {
      if (helpers.isWeb()) {
        let cookieDate = new Date(Date.now() - 1);
        cookies.set("token", "", { path: "/", expires: cookieDate });
        setTimeout(() => {
          this.props.navigateToScreen(
            `/reset-password?token=${resetPasswordToken}&gift-token=${giftCode}`
          );
        }, 100);
      } else {
        this.removeFcmTokenFromServer();
        this.props.removeFcmToken();
        this.props.navigation.replace("/reset-password", {
          query: `token=${resetPasswordToken}&gift-token=${giftCode}`,
        });
      }
    } else {
      if (helpers.isWeb()) {
        let cookieDate = new Date(Date.now() - 1);
        cookies.set("token", "", { path: "/", expires: cookieDate });
        this.props.navigateToScreen(
          `/sign-in?redirect=claim-gift&redirect-params=token=${giftCode}`
        );
      } else {
        this.removeFcmTokenFromServer();
        this.props.removeFcmToken();
        this.props.navigation.navigate("/sign-in", {
          redirect: "/claim-gift",
          verifyRedirect: "/claim-gift" + `?redirect-params=token=${giftCode}`,
          "redirect-params": `token=${giftCode}`,
        });
      }
    }

    try {
      Mixpanel.reset();
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    try {
      GoogleAnalytics.event("Account", "logout");
    } catch (error) {
      console.log("google analytics error ==>", error);
    }
  };

  removeFcmTokenFromServer = () => {
    let { fcmToken } = this.props.app;
    let { token } = this.state;
    let endpoint = `/edit-profile`;
    let body = {
      fcm_token: fcmToken,
      fcm_token_action: "remove",
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        console.log("remove device token notification response ==>", res);
      })
      .catch((error) => {
        console.log("remove device token notification error=>", error);
        if (error.status == 401) {
        }
      });
  };

  render() {
    let {
      enableBtnLoader,
      error,
      email,
      errorMessage,
      companyName,
      buttonCode,
      giftData,
      userEmailId,
    } = this.state;
    const webView = helpers.isWeb();
    const desktopView = webView && helpers.isDesktop();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/",
      native: { redirect: "homescreen", verifyRedirect: "/" },
    };
    // buttonCode = 'gift_claimed';
    // errorMessage = 'Gift not for this user';
    // giftData = {
    //     gift_type: "credits",
    //     from: "XYZ",
    //     gift_title: "50 Credits"
    // }
    return (
      <>
        <Seo data={{}} />
        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />
        <ScrollView
          contentContainerStyle={[
            appStyles.appContainer,
            helpers.isDesktop() && appStyles.appContainerDesktop,
            { backgroundColor: darkMode ? Colors.darkBlackColorBg : "#F6F6F6" },
          ]}
        >
          {enableBtnLoader ? (
            <View
              style={[
                Flex.row,
                {
                  width: helpers.isDesktop() ? "1400px" : "100%",
                  margin: desktopView ? "auto" : 0,
                  height: helpers.isDesktop() ? 530 : 400,
                  justfiyCenter: "center",
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg1
                    : "white",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <View>
                <ActivityIndicator size={75} color={"#907CFF"} />
                <Text
                  style={[
                    Margin.mt_3,
                    {
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 24 : 18,
                      lineHeight: helpers.isDesktop() ? 28.8 : 24,
                      color: "#907CFF",
                    },
                  ]}
                >
                  Veryfying your Gift Claim
                </Text>
              </View>
            </View>
          ) : (
            <View
              style={{
                maxWidth: helpers.isDesktop() ? 880 : "100%",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
            >
              <View
                style={[
                  Helpers.bg_white,
                  Margin.mb_4,
                  { marginTop: helpers.isDesktop() ? 150 : 50 },
                  {
                    paddingVertical: helpers.isDesktop() ? 70 : 35,
                    paddingHorizontal: 20,
                  },
                  {
                    marginHorizontal: 15,
                    marginBottom: helpers.isDesktop() ? 50 : 40,
                  },
                  darkMode && { backgroundColor: Colors.darkBlackColor },
                ]}
              >
                <View style={[Margin.mt_0]}>
                  {buttonCode == "gift_claimed" ||
                  buttonCode == "gift_already_claimed" ? (
                    <View style={[Padding.ph_2, Margin.mt_0]}>
                      <View style={[article.headerWrapper]}>
                        <View
                          style={[
                            Flex.alignCenter,
                            helpers.isDesktop() && Margin.mb_9,
                            !helpers.isDesktop() && Margin.mb_4,
                          ]}
                        >
                          <View
                            style={[
                              Padding.ph_2,
                              Margin.mb_1,
                              Helpers.titleBgWrap,
                              { backgroundColor: "rgba(250 250 250,1)" },
                            ]}
                          >
                            <Text
                              style={[
                                Helpers.textCenter,
                                Typo.h3_R,
                                helpers.isDesktop() && Typo.h3_RDesktop,
                                Margin.mb_0,
                                { fontSize: 18 },
                                { fontFamily: FontFamily.abrilFatface },
                                { fontSize: helpers.isDesktop() ? 30 : 18 },
                                darkMode && { color: Colors.lightWhite },
                              ]}
                            >
                              {giftData?.already_claimed
                                ? "You have already claimed this gift"
                                : "You have claimed this gift"}
                            </Text>
                          </View>
                          <PageBreak size={"small"} darkMode={true} />
                        </View>
                      </View>
                      <View
                        style={{
                          maxWidth: 450,
                          width: "100%",
                          borderWidth: 2,
                          borderColor: "#FF9302",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "rgba(255, 147, 2, 0.14)",
                            height: 108,
                            position: "relative",
                            alignItems: "center",
                          }}
                        >
                          <CustomImage
                            source={
                              giftData.gift_type == "gift_credit"
                                ? creditGiftIcon
                                : annualGiftSub
                            }
                            require={true}
                            style={{ width: 112, height: 93 }}
                            webStyle={{
                              width: 217,
                              height: 148,
                              position: "relative",
                              top: -35,
                            }}
                          />
                        </View>
                        <View style={{ paddingBottom: 40, paddingTop: 30 }}>
                          <Text
                            style={[
                              Helpers.textCenter,
                              Typo.h3_R,
                              helpers.isDesktop() && Typo.h3_RDesktop,
                              Margin.mb_0,
                              { fontSize: 18 },
                              { fontFamily: FontFamily.abrilFatface },
                              { fontSize: helpers.isDesktop() ? 30 : 18 },
                              darkMode && { color: Colors.lightWhite },
                            ]}
                          >
                            {giftData.gift_title}
                          </Text>
                          <View
                            style={{
                              height: 1,
                              width: 185,
                              marginRight: "auto",
                              marginLeft: "auto",
                              backgroundColor: darkMode ? "#bb26c8" : "#907CFF",
                              marginBottom: 15,
                              marginTop: 15,
                            }}
                          ></View>
                          <Text
                            style={{
                              fontSize: 14,
                              fontFamily: FontFamily.regular,
                              color: darkMode ? Colors.lightWhite : "#000000",
                              textAlign: "center",
                            }}
                          >
                            From:{" "}
                            <Text style={{ fontFamily: FontFamily.medium }}>
                              {giftData.from}
                            </Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <View style={[Padding.ph_2, Margin.mt_0]}>
                      <View style={[article.headerWrapper]}>
                        <View
                          style={[
                            Flex.alignCenter,
                            helpers.isDesktop() && Margin.mb_3,
                            !helpers.isDesktop() && Margin.mb_4,
                          ]}
                        >
                          <View
                            style={[
                              Padding.ph_2,
                              Margin.mb_1,
                              Helpers.titleBgWrap,
                              { backgroundColor: "rgba(250 250 250,1)" },
                            ]}
                          >
                            <Text
                              style={[
                                Helpers.textCenter,
                                Typo.h3_R,
                                helpers.isDesktop() && Typo.h3_RDesktop,
                                Margin.mb_0,
                                { fontSize: 18 },
                                { fontFamily: FontFamily.abrilFatface },
                                { fontSize: helpers.isDesktop() ? 30 : 18 },
                                darkMode && { color: Colors.lightWhite },
                              ]}
                            >
                              {errorMessage == "Gift not for this user"
                                ? "Gift not for this user"
                                : errorMessage == "Invitation expired."
                                ? "Link Expired!"
                                : "Invalid link!"}
                            </Text>
                          </View>
                          <PageBreak size={"small"} darkMode={darkMode} />
                        </View>
                      </View>
                      <View
                        style={{
                          maxWidth: helpers.isDesktop() ? 450 : "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                        }}
                      >
                        {buttonCode == "wrong_user" ? (
                          <Text
                            style={[
                              {
                                fontFamily: FontFamily.light,
                                fontWeight: FontWeight.light,
                                fontSize: helpers.isDesktop() ? 20 : 16,
                                lineHeight: helpers.isDesktop() ? 28 : 26,
                                letterSpacing: 0.3,
                                textAlign: "center",
                              },
                              darkMode && { color: Colors.lightWhite },
                            ]}
                          >
                            The gift you are trying to claim is not for{" "}
                            <Text style={[{ fontFamily: FontFamily.medium }]}>
                              {userEmailId}
                            </Text>
                            . Please sign in to TMC using the email id under
                            which the gift was sent to.
                          </Text>
                        ) : (
                          <Text
                            style={[
                              {
                                fontFamily: FontFamily.light,
                                fontWeight: FontWeight.light,
                                fontSize: helpers.isDesktop() ? 20 : 16,
                                lineHeight: helpers.isDesktop() ? 28 : 26,
                                letterSpacing: 0.3,
                                textAlign: "center",
                              },
                              darkMode && { color: Colors.lightWhite },
                            ]}
                          >
                            {errorMessage == "Gift not for this user"
                              ? `The gift you are trying to claim is not for ${userEmailId} user. Please sign in to TMC using the email id under which the gift was sent to.`
                              : errorMessage == "Invitation expired."
                              ? "Your gift link has expired. Please ask the sender to re-send the gift."
                              : "This gift link is already used or was meant for another email id."}
                          </Text>
                        )}
                      </View>
                    </View>
                  )}
                </View>
                {(buttonCode == "gift_claimed" ||
                  buttonCode == "gift_already_claimed") && (
                  <View style={[Padding.ph_2, Padding.pt_4]}>
                    <ButtonGradient
                      title={
                        giftData.gift_type == "gift_credit"
                          ? "VIEW PRIVILEGE CREDITS"
                          : "VIEW SUBSCRIPTION"
                      }
                      rootStyle={{
                        btnWrapper: button.primaryGradient,
                        btnText: [
                          button.primaryGradientText,
                          {
                            fontWeight: "400",
                            fontFamily: FontFamily.regular,
                            fontSize: 16,
                          },
                        ],
                      }}
                      loader={false}
                      disable={false}
                      onClick={() => this.goToNextPage()}
                      darkMode={darkMode}
                    />
                  </View>
                )}
                {errorMessage == "Gift not for this user" && (
                  <View style={[Padding.ph_2, Padding.pt_4]}>
                    <ButtonGradient
                      title="SIGN OUT"
                      rootStyle={{
                        btnWrapper: button.primaryGradient,
                        btnText: [
                          button.primaryGradientText,
                          {
                            fontWeight: "400",
                            fontFamily: FontFamily.regular,
                            fontSize: 16,
                          },
                        ],
                      }}
                      loader={false}
                      disable={false}
                      onClick={() => this.signOut()}
                      darkMode={darkMode}
                    />
                  </View>
                )}
              </View>

              <View
                style={[
                  Flex.row,
                  Flex.justfiyCenter,
                  { marginBottom: helpers.isDesktop() ? 100 : 30 },
                ]}
              >
                <Text
                  style={[
                    {
                      fontSize: 14,
                      fontFamily: FontFamily.regular,
                      fontWeight: FontWeight.regular,
                      lineHeight: 18,
                      borderBottomWidth: 1,
                      borderBottomColor: darkMode ? "#ffffff" : "#000000",
                    },
                    darkMode && { color: "#ffffff" },
                  ]}
                  onPress={() => this.navigateToScreen("/")}
                >{`Continue to the The Morning Context homepage >`}</Text>
              </View>
            </View>
          )}
          <AppFooter
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
          />
        </ScrollView>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setUserToken, removeUserToken, removeFcmToken },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(ClaimGift));
