import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  ImageBackground,
  StyleSheet,
  
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  banner,
  pageContent,
  Colors,
  FontFamily,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import SignupFormTextComponentWeb from "../sign-up-form-text-component-web";
// import { Colors } from 'react-native/Libraries/NewAppScreen';

const SuscriptionBg = getImagePath("img/suscription-top-section-bg.png");
const Mobilebanner = getImagePath("img/Mobilebanner.png");

class Banner extends Component {
  render() {
    const { bgColor, title, darkMode } = this.props;
    const banner = helpers.isDesktop()
      ? require("../../../../assets/img/banner-new.png")
      : require("../../../../assets/img/banner-mobile.png");
    let webView = helpers.isWeb();
    return (
      <View
        // source={banner}
        style={[
          // banner.Wrapper,
          Flex.row,
          helpers.isDesktop() ? Flex.alignCenter : Flex.alignEnd,
          {
            position: "relative",
            paddingVertical: 50,
            // backgroundPosition: helpers.isDesktop() ? "center" : "right top",
            // backgroundRepeat: "no-repeat",
            minHeight: helpers.isDesktop() ? 376 : 258,
            background: "linear-gradient(90.97deg, #6C7AF7 0%, #A97DF1 99.9%)",
            // backgroundImage: helpers.isDesktop()
            //   ? "url(" + banner + ")"
            //   : "url(" + banner + ")",
            // backgroundSize: "cover",
          },
        ]}
      >
        <CustomImage
          source={banner}
          require={true}
          style={[Utility.banner, { width: "100%", height: "100%" }]}
          webStyle={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            paddingTop: helpers.isDesktop ? 0 : 0,
          }}
          altText={"The Morning Context"}
        />
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            { paddingHorizontal: 20 },
            helpers.isDesktop() && { flexDirection: "row" },
            helpers.isDesktop() ? Flex.alignCenter : Flex.alignEnd,
          ]}
        >
          <View style={[{ width: helpers.isDesktop() ? "80%" : "100%" }]}>
            <View
              style={[
                Margin.mb_15,
                Flex.row,
                ,
                !helpers.isDesktop() && { marginBottom: 8 },
              ]}
            >
              <View
                accessibilityRole="header"
                aria-level="1"
                style={[
                  !helpers.isDesktop() && {
                    flex: 1,
                    textAlign: helpers.isDesktop() ? "center" : "left",
                  },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    {
                      fontSize: helpers.isDesktop() ? 64 : 32,
                      lineHeight: helpers.isDesktop() ? 84 : 41,
                      color: "#ffffff",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {title}
                </Text>
              </View>
            </View>
            <Text
              style={[
                pageContent.text,
                helpers.isDesktop() && pageContent.textDesktop,
                { marginBottom: helpers.isDesktop() ? 0 : 0, color: "#ffffff" },
                ,
                !helpers.isDesktop() && Helpers.textCenter,
                helpers.isDesktop() && { fontSize: 30 },
                !helpers.isDesktop() && { fontSize: 18 },
                darkMode && { color: Colors.lightWhite },
                { textAlign: helpers.isDesktop() ? "left" : "left" },
              ]}
            >
              Financial journalism and beyond. Paid for by subscribers, not
              advertisers.
            </Text>

            {this.props.showSignUpForm && (
              <SignupFormTextComponentWeb
                textInputStyle={styles.textInputStyle}
                textInputStyleTextColor={"#fafafa"}
                orText={styles.orText}
                goToSignUp={this.props.goToSignUp}
                goToGoogleLoginWeb={this.props.goToGoogleLoginWeb}
              />
            )}
          </View>
          <View
            style={{
              width: helpers.isDesktop() ? "30%" : "100%",
              alignItems: helpers.isDesktop() ? "flex-end" : "center",
            }}
          ></View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({

  textInputStyle: {
    borderBottomColor: "#fafafa",
    color: "#fafafa",
    borderBottomWidth: 2,
    marginBottom: 20,
    width: "100%",
    marginRight: 0,
    paddingTop: 8,
    paddingBottom: 8, 
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
    height: 50,
  },
  orText: {
    color: "#fafafa",
    marginHorizontal: 10,
    padding: 8,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
  },
});

export default Banner;
