import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, postContentTags, postContentClasses, postTags } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import TouchableOpacityLink from '../touchable-opacity-link';

export class PostTags extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    renderListItem = (item, index) => {
        let { getLinkClick, darkMode } = this.props;

        let btnClick;
        if (helpers.isWeb()) {
            btnClick = {
                to: "/tag/" + item.name
            }
        } else {
            btnClick = {
                onPress: () => getLinkClick(item.name)
            }
        }

        return (
            <View key={`tag_${index}`} style={[postTags.wrapper, darkMode && { backgroundColor: '#2E2E2E' }, !helpers.isWeb() && { paddingTop: 8, paddingBottom: 8 }]}>
                <TouchableOpacityLink {...btnClick}>
                    <Text style={[postTags.text, darkMode && {color: '#A885EA'}]}>{item.name}</Text>
                </TouchableOpacityLink>
            </View>

        )
    }

    render() {
        const { post_content } = this.state;
        const { data } = this.props;
        return (
            <>
                {data &&
                    <View style={[Margin.mb_2, Flex.alignCenter, Flex.row, Flex.FlexWrap]}>
                        {
                            data.map((item, index) => {
                                return (
                                    this.renderListItem(item, index)
                                )
                            })
                        }
                    </View>
                }
            </>
        );
    }
}

export default PostTags;