import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Modal, TouchableOpacity } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';

const markIcon = getImagePath('icons/risk.png');

export class ErrorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { data, show, tryAgain, goToHome } = this.props;
        
        return (
            <Modal
                presentationStyle={'overFullScreen'}
                visible={show}
                style={[{ backgroundColor: '#ffffff', borderWidth: 0, }, helpers.isWeb() && { position: helpers.isWeb() ? 'fixed' : 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 999, }]}
            >
                <View style={[Flex.fill, Flex.alignCenter, Flex.justfiyCenter]}>
                    <View style={[Flex.alignCenter, Padding.ph_2]}>
                        <CustomImage style={{ width: 50, height: 50 }} webStyle={{ width: 50, height: 50 }} require={true} source={markIcon} altText={'Error'} />
                        <Text style={[Typo.text, Margin.mt_1, Margin.mb_2]}>Something went wrong, Please try again later.</Text>
                        <TouchableOpacity onPress={() => tryAgain()} activeOpacity={0.8} style={[button.primaryOutlineRound]}>
                            <Text style={[button.primaryOutlineRoundText]}>Try Again</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => goToHome()} activeOpacity={0.8} style={[Margin.mt_2]}>
                            <Text style={[Typo.text, Margin.mt_1, Margin.mb_2]}>Go to Home</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        );
    }
}

export default ErrorModal;