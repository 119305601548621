import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity,Button } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, sliderStyle } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import { article, button, articleItem, FontFamily, articleLists, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import Swiper from "react-native-web-swiper";
import TmcDialogue from '../tmc-dialogue';
import TmcAppSlide from '../tmc-app-slide';
import HomeExclusiveContent from '.';

const { height, width } = Dimensions.get("window");

const arrowLeft = getImagePath('icons/arrow-left-dark.png');
const arrowRight = getImagePath('icons/arrow-right-dark.png');

const quoteLeft = getImagePath('icons/quotegreen.png');
const quoteRight = getImagePath('icons/quoteImageR.png');

const cookies = new Cookies();

const SwiperItem = ({}) => (
    <View>
      <Image
        draggable={false}
        ondragstart={() => false}
        style={{ height: height / 2, width: width }}
        source={{
          uri: "https://avatars3.githubusercontent.com/u/39907210?s=400&v=4"
        }}
      />
    </View>
  );

export class ExclusiveSlider extends React.Component {

    render() {
        return (
              <Swiper index={1}>
                <HomeExclusiveContent />
               <TmcDialogue  darkMode={this.props.darkMode}/>
               <TmcAppSlide darkMode={this.props.darkMode}/>
              </Swiper>
        );
      }
}

export default ExclusiveSlider;