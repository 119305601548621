import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  aboutAuthor,
  pageContent,
  pageCover,
  FontFamily,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";

const Analysis = getImagePath("icons/analysis.png");
const Journalism = getImagePath("icons/journalism.png");
const Newsletters = getImagePath("icons/premium-newsltetters.png");
const Dialogues = getImagePath("icons/dialogues.png");
const communityIcon = getImagePath("icons/communityIcon.png");

const AnalysisDark = getImagePath("icons/analysis-dark.png");
const JournalismDark = getImagePath("icons/journalism-dark.png");
const NewslettersDark = getImagePath("icons/premium-newsltetters-dark.png");
const DialoguesDark = getImagePath("icons/dialogues-dark.png");
const communityIconDark = getImagePath("icons/communityIconDark.png");

export class WhyTMCSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  render() {
    let webView = helpers.isWeb();
    let {
      fulWidth,
      subscription,
      lightBlueBg,
      home,
      postSingle,
      darkMode,
      studentSubscription,
      whiteBg,
    } = this.props;
    return (
      <View
        style={[
          Padding.pl_2,
          Padding.pr_2,
          postSingle && {
            paddingLeft: helpers.isDesktop() ? 20 : 0,
            paddingRight: helpers.isDesktop() ? 20 : 0,
          },
        ]}
      >
        <View
          style={[
            helpers.isDesktop() && Margin.mb_2,
            Margin.mb_2,
            !helpers.isDesktop() && Margin.mb_2,
            Helpers.textCenter,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            home && helpers.isDesktop() && Helpers.conatinerWeb1200,
            fulWidth && { maxWidth: "100%" },
            lightBlueBg && { backgroundColor: "#e5e5e5" },
            darkMode && { backgroundColor: Colors.darkPurple },
            whiteBg && { backgroundColor: "#ffffff" },
            darkMode && { backgroundColor: Colors.darkPurple },
          ]}
        >
          {/* <View
            style={[
              Flex.alignCenter,
              helpers.isDesktop() && Padding.pb_5,
              helpers.isDesktop() && Padding.pt_7,
              {
                width: helpers.isWeb() ? "fit-content" : "100%",
                marginLeft: "auto",
                marginRight: "auto",
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: helpers.isDesktop() ? 34 : 34,
                  fontFamily: FontFamily.abrilFatface,
                  color: "#051462",
                },
                subscription && { fontSize: helpers.isDesktop() ? 34 : 34 },
                { paddingBottom: 3 },
                home && {
                  fontSize: helpers.isDesktop() ? 34 : 28,
                  lineHeight: helpers.isDesktop() ? 51 : 33,
                },
                darkMode && { color: "white" },
              ]}
            >
              Why choose{" "}
              <Text accessibilityRole="header" aria-level="2">
                TMC?
              </Text>
            </Text>
          </View> */}

          <View
            style={[
              Flex.row,
              Flex.FlexWrap,
              Flex.alignStretch,
              Padding.pb_0,
              !helpers.isDesktop() && Padding.pb_0,
              {
                paddingHorizontal: helpers.isDesktop() ? 0 : 0,
                backgroundColor: this.props.darkMode ? "black" : "white",
              },
            ]}
          >
            <View
              style={[
                helpers.isDesktop() && Padding.pl_3,
                helpers.isDesktop() && { height: 200 },
                { width: helpers.isDesktop() ? "50%" : "100%" },
                {
                  order: 1,
                  backgroundColor: this.props.darkMode ? "black" : "white",
                  padding: helpers.isDesktop() ? 35 : 0,
                  textAlign: "left",
                },
                !helpers.isDesktop() && { marginTop: 20 },
              ]}
            >
              <View
                style={{
                  alignItems: "flex-start",
                  flexDirection: "row",
                  alignContent: helpers.isDesktop() ? "center" : "auto",
                  backgroundColor: this.props.darkMode ? "black" : "#F3F5FF",
                  padding: 25,
                  height: 166,
                  alignItems: helpers.isDesktop() ? "center" : "auto",
                }}
              >
                <View
                  style={[
                    helpers.isDesktop() && { alignSelf: "center" },
                    { marginLeft: helpers.isDesktop() ? 0 : -10 },
                  ]}
                >
                  <CustomImage
                    resizeMode={"contain"}
                    source={darkMode ? AnalysisDark : Analysis}
                    require={true}
                    style={{ width: 35, height: helpers.isDesktop() ? 50 : 35 }}
                    webStyle={{
                      width: helpers.isDesktop() ? "auto" : "auto",
                      height: helpers.isDesktop() ? 50 : 35,
                    }}
                  />
                </View>
                <View
                  style={{ flexDirection: "column", flex: 1, flexWrap: "wrap" }}
                >
                  <Text
                    style={[
                      {
                        flexShrink: 1,
                        fontSize: helpers.isDesktop() ? 24 : 18,
                        marginLeft: 20,
                        lineHeight: helpers.isDesktop() ? 33 : 24,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        color: "#000",
                        width: "90%",
                      },
                      helpers.isWeb() && { fontWeight: "600" },
                      !helpers.isWeb() && { fontFamily: FontFamily.bold },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Longreads
                  </Text>

                  <Text
                    accessibilityRole="header"
                    aria-level="3"
                    style={[
                      {
                        flexShrink: 1,
                        marginLeft: helpers.isDesktop() ? 20 : -40,
                        width: helpers.isDesktop() ? "100%" : "120%",
                        fontSize: helpers.isDesktop() ? 17 : 15,
                        lineHeight: helpers.isDesktop() ? 25 : 20,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.9)",
                        opacity: 0.85,
                      },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      !helpers.isDesktop() && { marginTop: 15 },
                    ]}
                  >
                    Coverage of tech companies, public markets, environment and
                    conflict
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[
                helpers.isDesktop() && Padding.pl_3,
                helpers.isDesktop() && { height: 200 },
                { width: helpers.isDesktop() ? "50%" : "100%" },
                {
                  order: 1,
                  backgroundColor: this.props.darkMode ? "black" : "white",
                  padding: helpers.isDesktop() ? 35 : 0,
                  textAlign: "left",
                },
                !helpers.isDesktop() && { marginTop: 20 },
              ]}
            >
              <View
                style={{
                  alignItems: "flex-start",
                  flexDirection: "row",
                  alignContent: helpers.isDesktop() ? "center" : "auto",
                  backgroundColor: this.props.darkMode ? "black" : "#F3F5FF",
                  padding: 25,
                  height: 166,
                  alignItems: helpers.isDesktop() ? "center" : "auto",
                }}
              >
                <View style={[helpers.isDesktop() && { alignSelf: "center" }]}>
                  <CustomImage
                    resizeMode={"contain"}
                    source={darkMode ? JournalismDark : Journalism}
                    require={true}
                    style={{ width: 35, height: helpers.isDesktop() ? 50 : 35 }}
                    webStyle={{
                      width: helpers.isDesktop() ? "auto" : "auto",
                      height: helpers.isDesktop() ? 50 : 35,
                    }}
                  />
                </View>

                <View
                  style={{ flexDirection: "column", flex: 1, flexWrap: "wrap" }}
                >
                  <Text
                    style={[
                      {
                        flexShrink: 1,
                        fontSize: helpers.isDesktop() ? 24 : 18,
                        marginLeft: 20,
                        lineHeight: helpers.isDesktop() ? 33 : 24,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        color: "#000",
                        width: "100%",
                      },
                      helpers.isWeb() && { fontWeight: "600" },
                      !helpers.isWeb() && { fontFamily: FontFamily.bold },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Catchup news
                  </Text>
                  <Text
                    accessibilityRole="header"
                    aria-level="3"
                    style={[
                      {
                        flexShrink: 1,
                        marginLeft: helpers.isDesktop() ? 20 : -40,
                        width: helpers.isDesktop() ? "100%" : "120%",
                        fontSize: helpers.isDesktop() ? 17 : 15,
                        lineHeight: helpers.isDesktop() ? 25 : 20,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.9)",
                        opacity: 0.85,
                      },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      !helpers.isDesktop() && { marginTop: 15 },
                    ]}
                  >
                    Clutter-free with a quick, to-the-point perspective
                  </Text>

                  {/* <Text accessibilityRole="header" aria-level="3" style={[{ flexShrink: 1, marginLeft: 20, width: helpers.isDesktop() ? "100%" : "100%", fontSize: helpers.isDesktop() ? 17 : 15, lineHeight: helpers.isDesktop() ? 25 : 20, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>We publish the best writing in India across three categories—internet, business and chaos.</Text> */}
                </View>
              </View>
            </View>

            <View
              style={[
                { width: helpers.isDesktop() ? "50%" : "100%" },
                helpers.isDesktop() && { height: 200 },
                {
                  order: 1,
                  backgroundColor: this.props.darkMode ? "black" : "white",
                  padding: helpers.isDesktop() ? 35 : 0,
                  textAlign: "left",
                },
                !helpers.isDesktop() && { marginTop: 20 },
              ]}
            >
              <View
                style={{
                  alignItems: "flex-start",
                  flexDirection: "row",
                  alignContent: helpers.isDesktop() ? "center" : "auto",
                  backgroundColor: this.props.darkMode ? "black" : "#F3F5FF",
                  padding: 25,
                  height: 166,
                  alignItems: helpers.isDesktop() ? "center" : "auto",
                }}
              >
                <View style={[helpers.isDesktop() && { alignSelf: "center" }]}>
                  <CustomImage
                    resizeMode={"contain"}
                    source={darkMode ? NewslettersDark : Newsletters}
                    require={true}
                    style={{ width: 35, height: helpers.isDesktop() ? 50 : 35 }}
                    webStyle={{
                      width: helpers.isDesktop() ? "auto" : "auto",
                      height: helpers.isDesktop() ? 50 : 35,
                    }}
                  />
                </View>
                <View
                  style={{ flexDirection: "column", flex: 1, flexWrap: "wrap" }}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 24 : 18,
                        marginLeft: 20,
                        lineHeight: helpers.isDesktop() ? 33 : 24,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        color: "#000",
                        width: helpers.isDesktop() ? "100%" : "100%",
                      },
                      helpers.isWeb() && { fontWeight: "600" },
                      !helpers.isWeb() && { fontFamily: FontFamily.bold },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Newsletters{" "}
                  </Text>
                  <Text
                    accessibilityRole="header"
                    aria-level="3"
                    style={[
                      {
                        marginLeft: helpers.isDesktop() ? 20 : -40,
                        width: helpers.isDesktop() ? "100%" : "120%",
                        fontSize: helpers.isDesktop() ? 17 : 15,
                        lineHeight: helpers.isDesktop() ? 25 : 20,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.9)",
                        opacity: 0.85,
                      },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      !helpers.isDesktop() && { marginTop: 25 },
                    ]}
                  >
                    From some of the best writers in the country
                  </Text>
                </View>
              </View>
            </View>

            {!studentSubscription && (
              <View
                style={[
                  helpers.isDesktop() && Padding.pl_3,
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  {
                    order: 1,
                    backgroundColor: this.props.darkMode ? "black" : "white",
                    padding: helpers.isDesktop() ? 35 : 0,
                  },
                  !helpers.isDesktop() && { marginTop: 20 },
                  { textAlign: "left" },
                ]}
              >
                <View
                  style={{
                    alignItems: "flex-start",
                    flexDirection: "row",
                    alignContent: helpers.isDesktop() ? "center" : "auto",
                    backgroundColor: this.props.darkMode ? "black" : "#F3F5FF",
                    padding: 25,
                    height: 166,
                    alignItems: helpers.isDesktop() ? "center" : "auto",
                  }}
                >
                  <View
                    style={[helpers.isDesktop() && { alignSelf: "center" }]}
                  >
                    <CustomImage
                      resizeMode={"contain"}
                      source={darkMode ? DialoguesDark : Dialogues}
                      require={true}
                      style={{
                        width: 35,
                        height: helpers.isDesktop() ? 50 : 35,
                      }}
                      webStyle={{
                        width: helpers.isDesktop() ? "auto" : "auto",
                        height: helpers.isDesktop() ? 50 : 35,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      flex: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    <Text
                      style={[
                        {
                          fontSize: helpers.isDesktop() ? 24 : 18,
                          marginLeft: 20,
                          lineHeight: helpers.isDesktop() ? 33 : 24,
                          letterSpacing: 0.3,
                          fontFamily: FontFamily.regular,
                          color: "#000",
                        },
                        helpers.isWeb() && { fontWeight: "600" },
                        !helpers.isWeb() && { fontFamily: FontFamily.bold },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      TMC Dialogues
                    </Text>
                    <Text
                      accessibilityRole="header"
                      aria-level="3"
                      style={[
                        {
                          marginLeft: helpers.isDesktop() ? 20 : -40,
                          width: helpers.isDesktop() ? "100%" : "120%",
                          fontSize: helpers.isDesktop() ? 17 : 15,
                          lineHeight: helpers.isDesktop() ? 25 : 20,
                          letterSpacing: 0.3,
                          fontFamily: FontFamily.regular,
                          fontWeight: "400",
                          color: "rgba(0,0,0,0.9)",
                          opacity: 0.85,
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        !helpers.isDesktop() && { marginTop: 25 },
                      ]}
                    >
                      Exclusive subscriber community and discussions
                    </Text>
                  </View>
                </View>
              </View>
            )}

            {studentSubscription && (
              <View
                style={[
                  helpers.isDesktop() && Padding.pl_3,
                  helpers.isDesktop() && { height: 200 },
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  {
                    order: 1,
                    backgroundColor: this.props.darkMode ? "black" : "white",
                    padding: helpers.isDesktop() ? 35 : 0,
                    textAlign: "left",
                  },
                  !helpers.isDesktop() && { marginTop: 20 },
                ]}
              >
                <View
                  style={{
                    alignItems: "flex-start",
                    flexDirection: "row",
                    alignContent: "center",
                    backgroundColor: this.props.darkMode ? "black" : "#F3F5FF",
                    padding: 25,
                  }}
                >
                  <View style={{ alignSelf: "center" }}>
                    <CustomImage
                      resizeMode={"contain"}
                      source={darkMode ? AnalysisDark : Analysis}
                      require={true}
                      style={{
                        width: 35,
                        height: helpers.isDesktop() ? 50 : 35,
                      }}
                      webStyle={{
                        width: helpers.isDesktop() ? "auto" : "auto",
                        height: helpers.isDesktop() ? 50 : 35,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      flex: 1,
                      flexWrap: "wrap",
                    }}
                  >
                    <Text
                      style={[
                        {
                          flexShrink: 1,
                          fontSize: helpers.isDesktop() ? 24 : 18,
                          marginLeft: 20,
                          lineHeight: helpers.isDesktop() ? 33 : 24,
                          letterSpacing: 0.3,
                          fontFamily: FontFamily.regular,
                          color: "#000",
                          width: helpers.isDesktop() ? "100%" : "100%",
                        },
                        helpers.isWeb() && { fontWeight: "600" },
                        !helpers.isWeb() && { fontFamily: FontFamily.bold },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      Student community and resources (coming soon)
                    </Text>

                    <Text
                      accessibilityRole="header"
                      aria-level="3"
                      style={[
                        {
                          flexShrink: 1,
                          marginLeft: 20,
                          width: helpers.isDesktop() ? "100%" : "100%",
                          fontSize: helpers.isDesktop() ? 17 : 15,
                          lineHeight: helpers.isDesktop() ? 25 : 20,
                          letterSpacing: 0.3,
                          fontFamily: FontFamily.regular,
                          fontWeight: "400",
                          color: "rgba(0,0,0,0.9)",
                          opacity: 0.85,
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      An educated space for discussions and shared resources.
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

export default WhyTMCSection;
