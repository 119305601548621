import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, input, Colors, bannerStyles, Messages, FontFamily, FontWeight, button, Typo, appHeader } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import Button from '../button';
import ButtonGradient from '../button-gradient';

// const banner = getImagePath('img/banner-slide.png');
const bannerDark = getImagePath('img/banner-slide-dark.png');
const circleLeft = getImagePath('img/banner-left-circle.png');
const circleRight = getImagePath('img/banner-right-circle.png');
const circleRightDark = getImagePath('img/banner-right-circle-dark.png');

export class CreditExpiryBand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnDisable: true,
            email: '',
            referralMsg: {
                enable: false,
                type: '', // error / success
                msg: ''
            },
        }
    }

    componentDidMount = () => {

    }

    signUp = () => {
        const { email } = this.state;
        this.setState({
            btnLoader: true,
            referralMsg: {
                enable: false,
                type: '',
                msg: ''
            }
        });

        // if (helpers.validateEmail(email)) {
        //     this.setState({
        //         btnLoader: false
        //     });
        //     try {
        //         if (this.props.trackSignUp) {
        //             this.props.trackSignUp(email, 'top banner');
        //         }
        //     }
        //     catch (error) {
        //         console.log("mix panel track error ==>", error);
        //     }
        if (helpers.isWeb()) {
            this.props.navigateWeb(`/sign-up?redirect=/&email=${email}`)
        } else {
            this.props.navigateApp('/sign-up', { redirect: '/', verifyRedirect: '/', email: email });
        }
        // }
        // else {
        //     this.setState({
        //         btnLoader: false,
        //         referralMsg: {
        //             enable: true,
        //             type: 'error',
        //             msg: 'Please enter a valid email'
        //         }
        //     });
        // }
    }

    validateInput = (value) => {
        if (value) {
            this.setState({ btnDisable: false, email: value });
        } else {
            this.setState({ email: value, btnDisable: true })
        }
    }


    render() {
        let { email, btnDisable, referralMsg } = this.state;
        let webView = helpers.isWeb();
        let { darkMode, textColor } = this.props;

        const banner = helpers.isDesktop() ? require("../../../../assets/img/banner-slide.png") : require("../../../../assets/img/Mobilebanner.png")
        let btnClick;
        if (webView) {
            btnClick = {
                to: '/pricing'
            }
        } else {
            btnClick = {
                onPress: () => this.props.navigateApp('/pricing')
            }
        }

        return (
            <View style={{ backgroundColor: '#000', paddingVertical: helpers.isDesktop() ? 10 : 10, flexDirection: helpers.isDesktop() ? 'row' : 'column', alignItems: 'center' }}>
                <View style={{ marginLeft: 'auto', marginRight: helpers.isDesktop() ? 10 : 'auto' }}>
                    <Text
                        style={[
                            Helpers.textCenter,
                            helpers.isDesktop() && Typo.h3_RDesktop,
                            !helpers.isDesktop() && { paddingHorizontal: 10 },
                            Margin.mb_0,
                            { color: "#fff" },
                            { fontSize: helpers.isDesktop() ? 20 : 12 },
                            !helpers.isWeb() && { marginBottom: 1 },
                            darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                        ]}
                    >
                        Your subscription to The Morning Context has ended.
                    </Text>
                </View>
                {helpers.isDesktop() ?
                    <View style={{ marginRight: 'auto' }}>
                        <TouchableOpacityLink {...btnClick}>
                            <Text
                                style={[
                                    Helpers.textUppercase,
                                    {
                                        color: "#907CFF",
                                        fontSize: 15,
                                        fontFamily: FontFamily.regular,
                                        fontWeight: '600',
                                        whiteSpace: "nowrap",
                                        backgroundColor: '#fff',
                                        // borderWidth: 1,
                                        // borderColor: "#907CFF",
                                        paddingHorizontal: helpers.isDesktop() ? 10 : 20,
                                        paddingVertical: helpers.isDesktop() ? 5 : 10,
                                        textTransform: 'uppercase'
                                    },
                                ]}
                            >
                                Restart your membership
                            </Text>
                        </TouchableOpacityLink>
                    </View>
                    :
                    <View style={{ marginVertical: 10, marginHorizontal: 'auto' }}>
                        <TouchableOpacityLink {...btnClick}>
                            <Text
                                style={[
                                    Helpers.textUppercase,
                                    {
                                        color: "#907CFF",
                                        fontSize: 10,
                                        fontFamily: FontFamily.medium,
                                        fontWeight: FontWeight.regular,
                                        whiteSpace: "nowrap",
                                        backgroundColor: '#fff',
                                        borderWidth: 1,
                                        borderColor: "#907CFF",
                                        paddingHorizontal: 20,
                                        paddingVertical: 10,
                                    },
                                ]}
                            >
                                RENEW SUBSCRIPTION
                            </Text>
                        </TouchableOpacityLink>
                    </View>
                }
            </View >
        );
    }
}

export default CreditExpiryBand;