import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, article, articleItem, authorItem, FontFamily, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';

import getImagePath from '../../utils/image-helper';
const readMore = getImagePath('icons/arrow-right-orange.png');

export class AuthorItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    onClick = () => {

    }

    render() {
        const { single, data, viewAuthor, hideImage, contributor, about, showArrow, writersPage, subscription, team, teamContributor, itemsInRow, contributorPage, disableLink, teamWriters, darkMode, require, singlePost } = this.props;
        let hideContributorData = hideImage ? false : true;
        let hideAboutData = about ? false : true;
        let webView = helpers.isWeb();
        let btnClick;
        if (helpers.isWeb()) {
            btnClick = {
                to: "/writers/" + data.slug
            }
        } else {
            btnClick = {
                onPress: () => viewAuthor(data.slug)
            }
        }

        if (disableLink) {
            btnClick = {}
        }

        return (
            <View style={{ width: contributor || subscription || itemsInRow === 3 ? (helpers.isDesktop() ? '33.33%' : '100%') : (helpers.isDesktop() ? '50%' : '100%') }}>
                <View style={[about && { paddingLeft: helpers.isDesktop() ? 0 : 18, paddingRight: helpers.isDesktop() ? 30 : 18 }, team && { paddingLeft: helpers.isDesktop() ? 0 : 20, paddingRight: helpers.isDesktop() ? 40 : 0 }, { marginBottom: helpers.isDesktop() ? 65 : 30 }, contributorPage && { padding: 0 }]}>
                    <View style={[{ flexDirection: about ? (helpers.isDesktop() ? 'column' : 'row') : 'row' }, { alignItems: about ? (helpers.isDesktop() ? 'flex-start' : 'center') : 'center' }, authorItem.wrap, contributor && { paddingTop: 0 }, about && { paddingBottom: 6 }, subscription && { alignItems: helpers.isDesktop() ? 'flex-start' : 'center' }, team && { paddingBottom: 10 }]}>
                        <TouchableOpacityLink {...btnClick}>
                            {(hideContributorData && !teamContributor) &&
                                <View style={[!contributor && Padding.pr_2, teamContributor && { paddingRight: 0 }, subscription && { paddingRight: helpers.isDesktop() ? 0 : 20 }, about && { paddingRight: helpers.isDesktop() ? 0 : 20 }]}>
                                    <CustomImage
                                        source={data.profile_image}
                                        webStyle={{ width: writersPage ? (helpers.isDesktop() ? 130 : 110) : 110, height: writersPage ? (helpers.isDesktop() ? 130 : 110) : 110 }}
                                        style={[{ width: writersPage ? (helpers.isDesktop() ? 130 : 110) : 110, height: writersPage ? (helpers.isDesktop() ? 130 : 110) : 110 }]}
                                        require={require}
                                        className={'authorItemPic'}
                                        altText={data.name}
                                    />
                                </View>
                            }
                        </TouchableOpacityLink>
                        <View style={[!writersPage && Flex.fill, about && { paddingTop: helpers.isDesktop() ? 20 : 10 }, subscription && { paddingTop: helpers.isDesktop() ? 21 : 0 }, !about && !writersPage && { minHeight: helpers.isDesktop() ? 91 : 'auto' }]}>
                            <TouchableOpacityLink {...btnClick}><Text style={[authorItem.name, writersPage && { fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 28 }, about && { fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 38 : 28, marginBottom: 5 }, subscription && { marginBottom: 0 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && { marginBottom: 0 }]}>{data.name}</Text></TouchableOpacityLink>
                            {(data.designation != '' || data.location != '') && <View style={[{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }, !contributor && Margin.mb_1, !webView && { marginBottom: 0 }]}>
                                {hideContributorData && <Text style={[authorItem.position, writersPage && { fontSize: helpers.isDesktop() ? 16 : 14, lineHeight: helpers.isDesktop() ? 22 : 18, color: '#000' }, about && { fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: helpers.isDesktop() ? 22 : 19, paddingRight: 4 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && { paddingRight: 4 }]}>{data.designation}</Text>}
                                {(teamWriters && (data.designation != '') && (data.location != '')) && <View style={[authorItem.dot, { position: 'relative', top: helpers.isDesktop() ? -3 : -3 }, about && { top: helpers.isDesktop() ? 4 : 1.8 }]} />}
                                {(hideContributorData && (data.designation != '') && !about) && <View style={[authorItem.dot, { position: 'relative', top: helpers.isDesktop() ? -3 : -3 }, about && { top: helpers.isDesktop() ? 4 : 1.8 }]} />}
                                {
                                    singlePost &&
                                    <View style={{ width: 3, height: 3, borderRadius: 50, backgroundColor: '#6A6A6A', marginLeft: 4, marginRight: 8 }}></View>
                                }
                                <Text style={[authorItem.location, { color: '#FC876D' }, writersPage && { fontSize: helpers.isDesktop() ? 16 : 14, lineHeight: helpers.isDesktop() ? 22 : 18, color: '#6A6A6A' }, about && { fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: helpers.isDesktop() ? 22 : 19 }]}>{data.location}</Text>
                            </View>}
                            {hideContributorData && hideAboutData && <Text style={[authorItem.articleCount, !webView && { paddingTop: 5 }, darkMode && { color: Colors.lightWhite }]}>{data.story_count}
                                {(data.story_count == 1) && <Text style={[authorItem.articleCountLabel, darkMode && { color: Colors.lightWhite }]}> Article</Text>}
                                {(data.story_count > 1) && <Text style={[authorItem.articleCountLabel, darkMode && { color: Colors.lightWhite }]}> Articles</Text>}
                            </Text>}
                        </View>
                    </View>
                    {(!hideAboutData && helpers.isDesktop()) && <View style={[Flex.row, { width: '100%' }, !helpers.isDesktop() && { paddingTop: 20 }]}>
                        <Text style={[{ maxWidth: helpers.isDesktop() ? 320 : '100%', fontSize: 16, lineHeight: 28, textAlign: 'left', flex: 1, flexWrap: 'wrap', fontFamily: FontFamily.regular }, (about && !helpers.isDesktop()) && { fontSize: 14, lineHeight: 24, letterSpacing: 0.1 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]} numberOfLines={3}>
                            {data.biography}
                        </Text>
                    </View>}

                    {(!hideAboutData && !helpers.isDesktop()) && <View style={[Flex.row, { width: '100%' }, !helpers.isDesktop() && { paddingTop: 10, paddingRight: 20 }]}>
                        <Text style={[{ maxWidth: helpers.isDesktop() ? 320 : '100%', fontSize: 16, lineHeight: 28, textAlign: 'left', flex: 1, flexWrap: 'wrap', fontFamily: FontFamily.regular, color: '#000' }, (about && !helpers.isDesktop()) && { fontSize: 14, lineHeight: 24, letterSpacing: 0.1 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                            {data.biography}
                        </Text>
                    </View>}

                    {
                        (showArrow && helpers.isDesktop()) &&
                        <TouchableOpacityLink {...btnClick}><CustomImage
                            source={readMore}
                            style={{ width: 22, height: 13, resizeMode: 'cover', marginTop: 15 }}
                            webStyle={{ width: 22, height: 13, objectFit: 'cover', marginTop: 15 }}
                        /></TouchableOpacityLink>
                    }

                </View>
            </View>
        );
    }
}

export default AuthorItem;