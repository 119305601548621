import React, { Component } from 'react';
import { Text, View, Dimensions } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, button, article, FontFamily } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';

const windowWidth = Dimensions.get('window').width;

class SubscriptionInfoBox extends Component {
    render() {
        let { data, darkMode } = this.props;
        let webView = helpers.isWeb();
        return (
            <>
                
                    <View style={[article.headerWrapper]}>
                        <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_15, !helpers.isDesktop() && Margin.mb_3, {width:'auto', marginLeft: 'auto', marginRight: 'auto'}]}>
                            <View style={[!helpers.isDesktop() && Padding.ph_2, Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18, color: '#000' }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 20 }, {fontWeight: '400'}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}, webView && { textTransform: 'regular' }]}>{data.title}</Text>
                            </View>
                            <PageBreak style={[{width: '92%'}, !webView && { width: windowWidth - 100 }]} darkMode={darkMode}/>
                        </View>
                    </View>
                    {/*}
                    <View style={[article.headerWrapper, Margin.mt_2]}>
                        <View style={[Flex.alignCenter, Margin.mb_3, !helpers.isDesktop() && Margin.mb_3]}>
                            <View style={[Padding.ph_2, Helpers.titleBgWrap, { backgroundColor: '#f8f8f8' }]}>
                                <View style={[Helpers.borderBottom]}>
                                    <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 18 },  darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>{data.title}</Text>
                                </View>
                            </View>
                            <View style={[Helpers.titleLine]}></View>
                        </View>
                    </View>
                */}
                <View style={[{ paddingHorizontal: 20}, !data.subtitle && {marginTop : 20} ]}>
                    {data.subtitle && 
                        <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 27, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 35 : 35, letterSpacing: 0.3, maxWidth: 550, marginLeft:'auto', marginRight: 'auto', paddingHorizontal: helpers.isDesktop() ? 30 : 0}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>{data.subtitle}</Text>
                    }
                    <ButtonGradient
                        title={data.btnText}
                        rootStyle={{
                            btnWrapper: button.primaryGradient,
                            btnText: [button.primaryGradientText,{fontWeight: '400', fontSize: helpers.isDesktop() ? 17 : 16}],
                        }}
                        onClick={this.props.handleClick}
                        darkMode={darkMode}
                    />
                </View>
            </>
        )
    }
}

export default SubscriptionInfoBox;
