import React, { Component } from "react";
import { Text, View } from "react-native";
import {
  Flex,
  Padding,
  Margin,
  articleItem,
  popularStories,
  FontFamily,
  FontWeight,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import { helpers } from "../../utils/helpers";
import moment from "moment";

import CreditEmailForm from "../credits-email-form";

export class PopularStories extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  gotoSignUp = (email) => {
    this.props.gotoSignUp(email);
  };

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "popular stories from the archive",
      newsletter_section: "NA",
      CTA_text: "view all articles",
      CTA_position: "bottom",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    const {
      data,
      viewArticle,
      viewAuthor,
      viewAllArticles,
      userCredits,
      categorySlug,
      token,
      darkMode,
    } = this.props;

    let btnClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: "/category/" + categorySlug + "/archive",
        handleClick: () => this.pushInformationToDataLayer()
      };
    } else {
      btnClick = {
        onPress: () => viewAllArticles(),
      };
    }
    return (
      <View
        style={[
          popularStories.container,
          { width: "100%", height: helpers.isWeb() ? "fit-content" : "100%" },
          darkMode && { borderColor: "rgba(255, 255, 255, 0.6)" },
        ]}
      >
        <View
          style={{
            paddingVertical: 16,
            paddingHorizontal: 20,
            borderBottomWidth: 2,
            borderColor: darkMode ? "rgba(255, 255, 255, 0.6)" : "#000",
          }}
        >
          <Text
            style={[
              popularStories.heading,
              helpers.isDesktop() && popularStories.headingDesktop,
              darkMode && { color: "rgba(255,255,255,0.87)" },
            ]}
          >
            Popular Stories From The Archive
          </Text>
        </View>
        <View style={[Flex.column, userCredits && { paddingTop: 0 }]}>
          <View>
            {data &&
              data.map((story) => {
                let btnClickStory, author1BtnClick, author2BtnClick;
                if (helpers.isWeb()) {
                  btnClickStory = {
                    to: {
                      pathname: "/" + story.category.slug + "/" + story.slug,
                      postData: story,
                    },
                  };
                  author1BtnClick = {
                    to: "/writers/" + story.author[0].slug,
                  };
                  if (story.author[1]) {
                    author2BtnClick = {
                      to: "/writers/" + story.author[1].slug,
                    };
                  }
                } else {
                  btnClickStory = {
                    onPress: () =>
                      viewArticle(story.category.slug, story.slug, story),
                  };
                  author1BtnClick = {
                    onPress: () => viewAuthor(story.author[0].slug),
                  };
                  if (story.author[1]) {
                    author2BtnClick = {
                      onPress: () => viewAuthor(story.author[1].slug),
                    };
                  }
                }

                return (
                  <TouchableOpacityLink {...btnClickStory}>
                    <View
                      style={[
                        Margin.mt_2,
                        helpers.isDesktop() && [
                          Flex.row,
                          Flex.FlexWrap,
                          Margin.mt_4,
                        ],
                      ]}
                    >
                      <View
                        style={[
                          Margin.mt_1,
                          helpers.isDesktop() && {
                            width: "100%",
                            paddingLeft: 5,
                            marginTop: 0,
                          },
                        ]}
                      >
                        <View style={[Padding.pl_2, Padding.pr_2]}>
                          <Text
                            style={[
                              articleItem.title,
                              helpers.isDesktop() && articleItem.titleDesktop,
                              {
                                fontFamily: FontFamily.regular,
                                fontWeight: FontWeight.semiBold,
                                fontSize: helpers.isDesktop() ? 28 : 24,
                                lineHeight: helpers.isDesktop() ? 39 : 33,
                              },
                              !helpers.isWeb() && {
                                fontFamily: FontFamily.semiBold,
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                            ]}
                          >
                            {story.title}
                          </Text>
                          <View
                            style={[articleItem.lineBreak, Margin.mb_1]}
                          ></View>
                        </View>
                        <View
                          style={[
                            Flex.row,
                            Flex.FlexWrap,
                            Flex.justifyStart,
                            Padding.pl_2,
                            Padding.pr_2,
                            helpers.isWeb() && { alignItems: "baseline" },
                            !helpers.isWeb() && { alignItems: "center" },
                          ]}
                        >
                          <View
                            style={[
                              Flex.row,
                              Flex.FlexWrap,
                              !helpers.isWeb() && { alignItems: "center" },
                            ]}
                          >
                            <TouchableOpacityLink {...author1BtnClick}>
                              <Text
                                style={[
                                  articleItem.author,
                                  {
                                    fontSize: helpers.isDesktop() ? 16 : 14,
                                    marginRight: 0,
                                  },
                                  darkMode && {
                                    color: "rgba(255,255,255,0.87)",
                                  },
                                ]}
                              >
                                {story.author[0].name}
                              </Text>
                            </TouchableOpacityLink>
                            {story.author[1] && (
                              <>
                                <Text
                                  style={[
                                    articleItem.authorTitle,
                                    helpers.isDesktop() &&
                                      articleItem.authorTitleDesktop,
                                    articleItem.date,
                                    {
                                      fontSize: helpers.isDesktop() ? 16 : 14,
                                      marginRight: 3,
                                      marginTop: 3,
                                    },
                                    !helpers.isWeb() && {
                                      marginLeft: 2,
                                      lineHeight: 17,
                                    },
                                    darkMode && {
                                      color: "rgba(255,255,255,0.87)",
                                    },
                                  ]}
                                >
                                  {" "}
                                  and{" "}
                                </Text>
                                <TouchableOpacityLink {...author2BtnClick}>
                                  <Text
                                    style={[
                                      articleItem.authorTitle,
                                      helpers.isDesktop() &&
                                        articleItem.authorTitleDesktop,
                                      {
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                      },
                                      !helpers.isWeb() && { marginRight: 2 },
                                      darkMode && {
                                        color: "rgba(255,255,255,0.87)",
                                      },
                                    ]}
                                  >
                                    {story.author[1].name}
                                  </Text>
                                </TouchableOpacityLink>
                              </>
                            )}
                          </View>
                          <View
                            style={[
                              articleItem.dot,
                              helpers.isWeb() && { top: -3 },
                            ]}
                          />
                          <Text
                            style={[
                              articleItem.postDate,
                              { fontSize: helpers.isDesktop() ? 16 : 13 },
                              darkMode && { color: "rgba(255,255,255,0.6)" },
                            ]}
                          >
                            {moment(story.published_date).add(5, 'hours').add(30, 'minutes').format(
                              "DD MMMM, YYYY"
                            )}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacityLink>
                );
              })}
          </View>
        </View>
        <TouchableOpacityLink {...btnClick}>
          <View
            style={{
              textAlign: "center",
              paddingVertical: 30,
              paddingHorizontal: 20,
              marginHorizontal: "auto",
              width: helpers.isWeb() ? "fit-content" : "100%",
              alignItems: "center",
            }}
          >
            <View
              style={[
                {
                  borderBottomWidth: 1,
                  borderColor: "rgb(252, 135, 109)",
                  marginTop: 10,
                  marginBottom: 10,
                },
              ]}
            >
              <Text
                style={[
                  popularStories.viewAllLink,
                  helpers.isDesktop() && popularStories.viewAllLinkDesktop,
                  { width: helpers.isWeb() ? "auto" : 128 },
                ]}
              >
                View all articles
              </Text>
            </View>
          </View>
        </TouchableOpacityLink>
        {!token && (
          <CreditEmailForm
            gotoSignUp={(email) => {
              this.gotoSignUp(email);
            }}
            darkMode={darkMode}
          />
        )}
      </View>
    );
  }
}

export default PopularStories;
