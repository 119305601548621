import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, postSingle, FontFamily, FontWeight, modalStyle } from '../../styles/appStyles';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import Button from '../button';

const bgImage = getImagePath('icons/buttonBG.png');
const appLogo = getImagePath('icons/tmc-logo.png');
const closeIcon = getImagePath('icons/grey-close-in.png');
export default class AddToHomescreen extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        let { showAddToHomeScreen } = this.props;
        return (
            <View style={[{ position: 'relative', width: 'auto' }]}>
                {showAddToHomeScreen &&
                    <>
                        {helpers.isDesktop() ?
                            <View style={[Flex.row, Flex.justfiyCenter, Flex.alignCenter, Padding.pv_1, { position: 'fixed', left: 20, bottom: helpers.isDesktop() ? 15 : 20, zIndex: 2, marginHorizontal: helpers.isDesktop() ? 'auto' : 20, width: helpers.isDesktop() ? 500 : 'auto' }]}>
                                <View style={{ position: 'absolute', right: -15, top: -25, zIndex: 1 }}>
                                    <TouchableOpacity
                                        activeOpacity={0.8}
                                        style={[modalStyle.closeButton]}
                                        onPress={() => this.props.closeAddToHomeScreen()}
                                    >
                                        <CustomImage
                                            source={closeIcon}
                                            style={{ width: 28, height: 28 }}
                                            webStyle={{ width: 28, height: 28 }}
                                            require={true}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <CustomImage
                                    source={bgImage}
                                    style={{}}
                                    webStyle={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                                    require={true}
                                />
                                <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, { position: 'absolute', left: 0, right: 0, marginHorizontal: 'auto', paddingHorizontal: 10 }]}>
                                    <View style={[Flex.column, Flex.alignStart, { width: '80%', paddingVertical: 14, paddingRight: 20 }]}>
                                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '600', fontSize: 16, color: 'white', letterSpacing: 0.1, marginBottom: 6 }}>Install TMC on your system</Text>
                                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: 12, letterSpacing: 0.5, color: 'white' }}>Enjoy a cleaner reading experience and stay up to date with the latest stories.</Text>
                                    </View>
                                    <Button
                                        title={'Install'}
                                        rootStyle={{
                                            btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: 'white', paddingHorizontal: 15, paddingVertical: 6, borderRadius: 50, height: 30 }],
                                            btnText: [postSingle.creditButtonText, { fontWeight: '600', fontSize: 14, color: '#907cff', lineHeight: 14 }],
                                        }}
                                        onClick={() => this.props.showInstallPrompt()}
                                    />
                                </View>
                            </View>
                            :

                            <View style={[Flex.row, Flex.justifyStart, Flex.alignCenter, postSingle.addToHomeScreenWrapper, { position: helpers.isWeb() ? 'fixed' : 'absolute' }]}>
                                <CustomImage
                                    source={appLogo}
                                    style={{}}
                                    webStyle={{ width: 30, height: 30, objectFit: 'contain' }}
                                    require={true}
                                />
                                <TouchableOpacity onPress={() => this.props.showInstallPrompt()}>
                                    <Text style={{ paddingLeft: 10, fontWeight: '600', fontSize: 16, color: '#907cff', lineHeight: 15 }}>Add TMC to Home Screen</Text>
                                </TouchableOpacity>
                                <View style={[{ marginLeft: 20 }]}>
                                    <TouchableOpacity onPress={() => this.props.closeAddToHomeScreen()}>
                                        <CustomImage
                                            source={closeIcon}
                                            style={{ width: 18, height: 18, resizeMode: 'contain' }}
                                            webStyle={{ width: 24, height: 24, objectFit: 'contain' }}
                                            require={true}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>

                        }
                    </>
                }
            </View>
        );
    }
}