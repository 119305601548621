import { chargebeePortal__Interface, chargebeePortal__v1__Factory } from '../portal'
import { chargebeeController__I } from './interface'
import { chargebeeHostedPage__Interface, chargebeeHostedPage__v1__Factory } from '../hosted-page'
import { chargbeeController } from './abstract'
import { Mix_v1 } from '../mixin-builder'
import { ManagementAPI__Factory_v1, ManagementAPI__v1 } from '../api'


export type Constructor = new (...args: any[]) => chargbeeController;


/**
 * 
 * Mixin to add `portal`({@link chargebeePortal__Interface}) object to the ChargbeeController object
 */
export function PortalMixin<TBase extends Constructor>(Base: TBase) {
    class CP extends Base{
        portal: chargebeePortal__Interface;
        constructor(...args: any[]){
            super(...args)
            this.portal = chargebeePortal__v1__Factory.createInstance(this.instance)
        }
        initParam(params: any) {
            super.initParam(params)
            this.portal.initParam(params)
        }
        setParam(params: any) {
            super.setParam(params)
            this.portal.setParam(params)
        }
    }
    return CP
  
  }

/**
 * 
 * Mixin to add `hosted_page`({@link chargebeeHostedPage__Interface}) object to the ChargbeeController object
 */
export function HostedPageMixin<TBase extends Constructor>(Base: TBase) {
    return class CH extends Base{
        hosted_page: chargebeeHostedPage__Interface;
        constructor(...args: any[]){
            super(...args)
            this.hosted_page = chargebeeHostedPage__v1__Factory.createInstance(this.instance)
        }
        initParam(params: any) {
            super.initParam(params)
            this.hosted_page.initParam(params)
        }
        setParam(params: any) {
            super.setParam(params)
            this.hosted_page.setParam(params)
        }
    }

}

/**
 * 
 * Mixin to add `API`({@link ManagementAPI__v1}) object to the ChargbeeController object
 */
export function API_Mixin<TBase extends Constructor>(Base: TBase) {
    return class S_PS extends Base{
        API: ManagementAPI__v1
        constructor(...args: any[]){
            super(...args)
            this.API = ManagementAPI__Factory_v1.createInstance()
        }
        setToken(tmcAuthToken: any): boolean {
            super.setToken(tmcAuthToken)
            return this.API.setToken(tmcAuthToken)
        }
        initParam(params: any) {
            super.initParam(params)
            this.API.init(params)
        }
        setParam(params: any) {
            super.setParam(params)
            this.API.update(params)
        }
    }

}


export const Mixins_v1 = [
    PortalMixin,
    HostedPageMixin,
    API_Mixin,
]