import React, { Component } from 'react';
import { Text, View, TextInput } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, pageContent, FontFamily, postSingle, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';
import Button from '../button';

const goldCoin = getImagePath('icons/goldCoin.png');
const silverCoin = getImagePath('icons/silverCoin.png');

export class UnlockThisStory extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let { credits, unlockLoader, teamCredits, userCredit, isPrivileged, darkMode, starterCredits } = this.props;
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000]}>
                <View style={[{ borderWidth: 2, borderColor: '#907CFF', paddingVertical: helpers.isDesktop() ? 60 : 40, backgroundColor: '#ffffff' }, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                    <View style={[Flex.alignCenter, Padding.pb_2]}>
                        <Text style={[{ fontSize: helpers.isDesktop() ? 34 : 24, lineHeight: 40, fontFamily: FontFamily.abrilFatface, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Unlock this story</Text>
                        <PageBreak size={'small'} darkMode={darkMode} />
                    </View>
                    <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb700]}>
                        <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 27, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3 }, !helpers.isDesktop() && { paddingHorizontal: 20 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>
                            You can unlock this story for {credits} {isPrivileged ? 'premium' : 'starter'} credits</Text>
                    </View>
                    {teamCredits && <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter]}>
                        <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.regular, fontWeight: '600', color: darkMode ? 'rgba(255, 255, 255, 0.89)' : '#000' }}>{isPrivileged ? 'Premium credits available: ' : 'Credits available: '}<Text style={{ fontSize: helpers.isDesktop() ? 36 : 24, lineHeight: helpers.isDesktop() ? 45 : 34, fontFamily: FontFamily.regular, fontWeight: '600', color: '#E1A235' }}>{isPrivileged ? userCredit : starterCredits.available}</Text></Text>
                    </View>
                    }
                    <View style={[Margin.mt_2, Margin.mb_2]}>
                        <View style={[Flex.row, Flex.justfiyCenter]}>
                            <Button
                                title={'UNLOCK FOR'}
                                rootStyle={{
                                    btnWrapper: [darkMode ? postSingle.creditButtonPurlple : postSingle.creditButtonNews, Flex.alignCenter, Flex.justfiyCenter, { width: 120 }, { height: 46 }, !helpers.isWeb() && { width: 180 }],
                                    btnText: [postSingle.creditButtonText],
                                }}
                                onClick={() => this.props.unlockPost()}
                                loader={false}
                                disable={false}
                                indicatorColor={'#ffffff'}
                            />
                            <View style={[postSingle.creditBoxBlue, Flex.row, Flex.alignCenter]}>
                                <CustomImage
                                    webStyle={{ width: 23, height: 23, marginRight: 5 }}
                                    style={{ width: 23, height: 23, marginRight: 5 }}
                                    source={isPrivileged ? goldCoin : silverCoin}
                                    require={true}
                                    resizeMode="contain"
                                    altText={'Unlock'}
                                />
                                <Text style={[postSingle.creditText]}>{credits}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

export default UnlockThisStory;