import React, { Component } from 'react';
import { Text, View, TextInput } from 'react-native';
import {  Margin, Helpers,  Colors, FontFamily, formStyle, button } from '../../styles/appStyles';
import Button from '../button';
import ButtonGradient from '../../components/button-gradient';
import { helpers } from '../../utils/helpers';

export class CreditEmailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:''
        }
    }

    componentDidMount = () => {

    }

    gotoSignUp = () => {
        const { gotoSignUp } = this.props;
        if(this.state.email)
            gotoSignUp(this.state.email);
    }

    render() {
        let { darkMode } = this.props;
        return (
            <View style={[{ backgroundColor: '#FFF4F1', paddingVertical: 35, paddingHorizontal: 20 }, darkMode && {backgroundColor: Colors.pinkColor}]}>
                <Text style={[Helpers.textLeft, Helpers.textUppercase, Margin.mb_2, {fontFamily: FontFamily.regular, fontWeight:'400', fontSize: helpers.isDesktop() ? 24 : 18 , lineHeight: helpers.isDesktop() ? 34 : 28 } ]}>Sign up to get free credits and read stories from our archives</Text>
                <TextInput
                    placeholder={'Enter your email address'}
                    placeholderTextColor={Colors.placeholderTextColor}
                    style={[formStyle.inputBox, { paddingLeft: 10, paddingRight: 8, height: helpers.isDesktop() ? 54 : 45, backgroundColor:'white', fontSize: helpers.isDesktop() ? 16 : 13, borderColor: darkMode ? '#A7A7A7' : 'rgba(0, 0, 0, 0.25)' }]}
                    onChangeText={(emailvalue) => this.setState({ email: emailvalue })}
                />
                {darkMode ?
                    <View style={{marginTop: helpers.isDesktop() ? 15 : 10}}>
                        <ButtonGradient
                            title={'SUBSCRIBE'}
                            onClick={() => this.gotoSignUp()}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient,{height: helpers.isDesktop() ? 54 : 45, borderRadius: 0}],
                                btnText: [button.primaryGradientText, { fontWeight:'400' },{fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 18 }],
                            }}
                            darkMode={darkMode}
                        />
                    </View>
                    :
                    <View style={{marginTop: helpers.isDesktop() ? 15 : 10}}>
                        <Button
                            title={'SUBSCRIBE'}
                            onClick={() => this.gotoSignUp()}
                            rootStyle={{
                                btnWrapper: [button.primaryWhiteBack,{height: helpers.isDesktop() ? 54 : 45, borderRadius: 0}],
                                btnText: [button.primaryWhiteText, { fontWeight:'400' },{fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 18 }],
                            }}
                        />
                    </View>
                }
            </View>
        );
    }
}

export default CreditEmailForm;