import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  TouchableOpacity,
  Button,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  sliderStyle,
  bannerStyles,
} from "../../styles/appStyles";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import Cookies from "universal-cookie";
import {
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import Swiper from "react-native-web-swiper";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import ButtonGradient from "../button-gradient";

const cookies = new Cookies();

export class WritersSlider extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    let token = webView
      ? process.browser
        ? cookies.get("token")
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.appIntroSlider = React.createRef();
    this.checkScroll = true;
    const windowWidth = Dimensions.get("window").width;
    this.state = {
      activeSlide: 0,
      PlatformWeb: helpers.isDesktop(),
      slideItemWidth: 180 - 40,
      showToken: !helpers.isWeb() || process.browser ? true : false,
    };
  }
  handleResize = () => {
    setTimeout(() => {
      const windowWidth = Dimensions.get("window").width;
      if (windowWidth < 992) {
        this.setState({
          PlatformWeb: false,
          slideItemWidth: windowWidth - 40,
        });
      } else {
        this.setState({
          PlatformWeb: true,
          slideItemWidth: windowWidth - 40,
        });
      }
    }, 200);
  };
  trackNewsletterClick = (data, index) => {};
  componentDidMount() {
    if (helpers.isWeb()) {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    }
  }

  componentWillUnmount() {
    if (helpers.isWeb()) {
      window.removeEventListener("resize", this.handleResize);
    }
  }

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "our writers",
      newsletter_section: "NA",
      CTA_text: "view all writers",
      CTA_position: "bottom",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    const {
      wdata,
      subscriptionQuote,
      teamSubscribers,
      teamSubscribersMob,
      darkMode,
    } = this.props;
    const { activeSlide, slideItemWidth } = this.state;
    const { height, width } = Dimensions.get("window");
    const { data } = this.props;
    // let intervals = data.length;
    let webView = helpers.isWeb();
    let isWeb = helpers.isWeb();
    let btnClickNewsletter;
    let btnClickAllWriters;

    if (helpers.isWeb()) {
      btnClickAllWriters = {
        href: "/writers",
      };
    } else {
      btnClickAllWriters = {
        onPress: () => this.props.navigateApp("/writers"),
      };
    }

    let dotClick;

    const FirstArrayItem = ({}) =>
      this.props.data &&
      this.props.data.map((item, index) => {
        <View
          style={[
            { marginLeft: 100, marginRight: 100 },
            {
              borderColor: "#F3F5FF", // if you need
              borderWidth: 2,
              shadowOpacity: 0.7,
              shadowColor: "#000",
              backgroundColor: "white",
              flex: 1,
            },
          ]}
        >
          <TouchableOpacityLink
            {...btnClickNewsletter}
            handleClick={() => {
              this.trackNewsletterClick(item, index);
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ margin: 35, justifyContent: "center" }}>
                <CustomImage
                  source={item.profile_image}
                  webStyle={{
                    width: 120,
                    height: 120,
                    maxWidth: "100%",
                    objectFit: "contain",
                    marginHorizontal: helpers.isDesktop() ? 0 : 0,
                  }}
                  style={{ width: 180, height: 180, resizeMode: "contain" }}
                  require={false}
                  className={""}
                  altText={item.name}
                  resizeMode={"contain"}
                />
                {darkMode && (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                    }}
                  ></View>
                )}
              </View>
              <View style={{ marginTop: 40 }}>
                <Text
                  style={[
                    { fontSize: 36 },
                    { fontFamily: FontFamily.abrilFatface },
                  ]}
                >
                  {item.name}
                </Text>
                <View
                  style={{
                    backgroundColor: "#907CFF",
                    width: "50%",
                    height: 1,
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                ></View>
                <View style={{ flexDirection: "row" }}>
                  <Image
                    source={require("../../../../assets/icons/calendarIcon.png")}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 16,
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={[
                      {
                        fontFamily: FontFamily.regular,
                        fontSize: helpers.isDesktop() ? 22 : 14,
                        lineHeight: helpers.isDesktop() ? 27 : 20,
                        color: "#000000",
                        marginBottom: helpers.isDesktop() ? 0 : 0,
                        letterSpacing: 0.3,
                      },
                      darkMode && { color: "black" },
                    ]}
                  >
                    Published {item.available}
                  </Text>
                </View>
              </View>
            </View>
          </TouchableOpacityLink>
        </View>;
      });

    const SwiperItem = ({ item, index }) => (
      <View
        style={[
          { marginLeft: 100, marginRight: 100 },
          {
            borderColor: "#F3F5FF", // if you need
            borderWidth: 2,
            shadowOpacity: 0.7,
            shadowColor: "#000",
            backgroundColor: "white",
            flex: 1,
          },
        ]}
      >
        <TouchableOpacityLink
          {...btnClickNewsletter}
          handleClick={() => {
            this.trackNewsletterClick(item, index);
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ margin: 35, justifyContent: "center" }}>
              <CustomImage
                source={item.profile_image}
                webStyle={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                  marginHorizontal: helpers.isDesktop() ? 0 : 0,
                }}
                style={{ width: 180, height: 180, resizeMode: "contain" }}
                require={false}
                className={""}
                altText={item.name}
                resizeMode={"contain"}
              />
              {darkMode && (
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(18, 18, 18, 0.05)",
                  }}
                ></View>
              )}
            </View>
          </View>
        </TouchableOpacityLink>
      </View>
    );

    return (
      <View
        style={[
          { flex: 1 },
          !darkMode && {
            backgroundColor: this.props.backgroundColor
              ? this.props.backgroundColor
              : "#F3F5FF",
          },
        ]}
      >
        <View
          style={[article.headerWrapper, helpers.isDesktop() && Margin.mb_0]}
        >
          <View
            style={[
              Flex.alignCenter,
              Margin.mb_2,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1000,
              { marginTop: helpers.isDesktop() ? 30 : 40 },
            ]}
          >
            <View
              style={[
                Helpers.newslettertitleBgWrap,
                Padding.pl_2,
                Padding.pr_2,
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
                { backgroundColor: "#F3F5FF" },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontSize: helpers.isDesktop() ? 34 : 24 },
                    { fontFamily: FontFamily.abrilFatface },
                    {
                      fontSize: 34,
                      fontWeight: 600,
                      lineHeight: helpers.isDesktop() ? 51 : 22,
                      color: "#000",
                    },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                  ]}
                >
                  Our Writers
                </Text>
              </View>
            </View>
            <View
              style={[
                helpers.isDesktop() && Helpers.titleLine,
                {
                  backgroundColor: darkMode
                    ? "rgba(255,255,255,0.6)"
                    : "rgba(102, 102, 102, 0.6)",
                },
              ]}
            ></View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {helpers.isDesktop() ? (
            <>
              <Swiper
                loop // to scroll first item after last
                timeout={3} // auto scroll time
                controlsProps={{
                  dotsPos: "bottom",
                  // nextTitle: "",
                  // prevTitle: "",
                  nextTitleStyle: {
                    marginBottom: 0,
                    marginRight: 25,
                    fontSize: 25,
                    color: "#FC876D",
                  },
                  prevTitleStyle: {
                    marginBottom: 0,
                    marginLeft: 25,
                    fontSize: 25,
                    color: "#FC876D",
                  },
                  // nextPos:'top-right',
                  // prevPos:'top-left',
                  nextTitle: "→",
                  prevTitle: "←",
                  dotActiveStyle: { backgroundColor: "#907CFF" },
                  dotsTouchable: true,

                  // DotComponent: ({ index, isActive, onPress}) => <Text onPress={onPress}>{index+1}</Text>
                }}
                controlsEnabled={true}
                minDistanceForAction={0.15}
                springConfig={{ speed: 100 }}
              >
                {/* 1st Slide */}
                <View style={{ flexDirection: "row" }}>
                  {this.props.data1 &&
                    this.props.data1.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { marginLeft: 20, marginRight: 20 },
                            {
                              // borderColor: '#F3F5FF', // if you need
                              // borderWidth: 2,
                              // shadowOpacity: 0.7,
                              shadowColor: darkMode ? "white" : "#000",
                              flex: 1,
                              marginTop: 50,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item);
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View
                                style={{
                                  marginLeft: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <CustomImage
                                  source={item.profile_image}
                                  webStyle={{
                                    width: 180,
                                    height: 180,
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    marginHorizontal: helpers.isDesktop()
                                      ? 0
                                      : 0,
                                    marginRight: 20,
                                    marginLeft: -20,
                                  }}
                                  style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: "contain",
                                  }}
                                  require={false}
                                  className={""}
                                  altText={item.name}
                                  resizeMode={"contain"}
                                />
                                {this.props.darkMode && (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                                    }}
                                  ></View>
                                )}
                              </View>
                              <View style={{ marginTop: 0, marginLeft: 0 }}>
                                <Text
                                  style={[
                                    { fontSize: 20 },
                                    {
                                      fontFamily: FontFamily.abrilFatface,
                                      color: darkMode ? "white" : "black",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.designation}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: darkMode
                                        ? "white"
                                        : "#6A6A6A",
                                      width: 3,
                                      height: 3,
                                      borderRadius: 1.5,
                                      marginLeft: 8,
                                      marginRight: 8,
                                      alignSelf: "center",
                                    }}
                                  ></View>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#FC876D",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && {
                                        color: "#6A6A6A",
                                      },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: 200,
                                    marginTop: 20,
                                  }}
                                >
                                  <Text
                                    numberOfLines={6}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 14 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 24
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.biography}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>

                {/* 2nd Slide */}
                <View style={{ flexDirection: "row" }}>
                  {this.props.data2 &&
                    this.props.data2.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { marginLeft: 20, marginRight: 20 },
                            {
                              shadowColor: "#000",
                              flex: 1,
                              marginTop: 50,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item);
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View
                                style={{
                                  marginLeft: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <CustomImage
                                  source={item.profile_image}
                                  webStyle={{
                                    width: 180,
                                    height: 180,
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    marginHorizontal: helpers.isDesktop()
                                      ? 0
                                      : 0,
                                    marginRight: 20,
                                    marginLeft: -20,
                                  }}
                                  style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: "contain",
                                  }}
                                  require={false}
                                  className={""}
                                  altText={item.name}
                                  resizeMode={"contain"}
                                />
                                {this.props.darkMode && (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                                    }}
                                  ></View>
                                )}
                              </View>
                              <View style={{ marginTop: 0, marginLeft: 0 }}>
                                <Text
                                  style={[
                                    { fontSize: 20 },
                                    {
                                      fontFamily: FontFamily.abrilFatface,
                                      color: darkMode ? "white" : "black",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.designation}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: "#6A6A6A",
                                      width: 3,
                                      height: 3,
                                      borderRadius: 1.5,
                                      marginLeft: 8,
                                      marginRight: 8,
                                      alignSelf: "center",
                                    }}
                                  ></View>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#FC876D",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: 200,
                                    marginTop: 20,
                                  }}
                                >
                                  <Text
                                    numberOfLines={6}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 14 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 24
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.biography}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>

                {/* 3rd Slid */}

                <View style={{ flexDirection: "row" }}>
                  {this.props.data3 &&
                    this.props.data3.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { marginLeft: 20, marginRight: 20 },
                            {
                              // borderColor: '#F3F5FF', // if you need
                              // borderWidth: 2,
                              // shadowOpacity: 0.7,
                              shadowColor: "#000",
                              flex: 1,
                              marginTop: 50,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item);
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View
                                style={{
                                  marginLeft: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <CustomImage
                                  source={item.profile_image}
                                  webStyle={{
                                    width: 180,
                                    height: 180,
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    marginHorizontal: helpers.isDesktop()
                                      ? 0
                                      : 0,
                                    marginRight: 20,
                                    marginLeft: -20,
                                  }}
                                  style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: "contain",
                                  }}
                                  require={false}
                                  className={""}
                                  altText={item.name}
                                  resizeMode={"contain"}
                                />
                                {this.props.darkMode && (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                                    }}
                                  ></View>
                                )}
                              </View>
                              <View style={{ marginTop: 0, marginLeft: 0 }}>
                                <Text
                                  style={[
                                    { fontSize: 20 },
                                    {
                                      fontFamily: FontFamily.abrilFatface,
                                      color: darkMode ? "white" : "black",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                                {/* <View style={{ backgroundColor: '#907CFF', width: '50%', height: 1, marginTop: 16, marginBottom: 16 }}></View> */}
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    numberOfLines={2}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.designation}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: "#6A6A6A",
                                      width: 3,
                                      height: 3,
                                      borderRadius: 1.5,
                                      marginLeft: 8,
                                      marginRight: 8,
                                      alignSelf: "center",
                                    }}
                                  ></View>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#FC876D",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: 200,
                                    marginTop: 20,
                                  }}
                                >
                                  <Text
                                    numberOfLines={6}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 14 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 24
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.biography}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* <View style={{flexDirection:'column',marginLeft:35,marginRight:35,justifyContent:'space-between',}}>
                        <Text numberOfLines={0} style={[{ marginLeft:16,fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 25 : 14, color: '#292929', marginBottom: helpers.isDesktop() ? 16 : 0, letterSpacing: 0.3 ,marginTop:0},{flexWrap:'wrap'}, darkMode && { color: '#292929' }]}>{item.description}</Text>
            </View> */}
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>

                {/* 4th Slide */}

                <View style={{ flexDirection: "row" }}>
                  {this.props.data4 &&
                    this.props.data4.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { marginLeft: 20, marginRight: 20 },
                            {
                              // borderColor: '#F3F5FF', // if you need
                              // borderWidth: 2,
                              // shadowOpacity: 0.7,
                              shadowColor: "#000",
                              flex: 1,
                              marginTop: 50,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item);
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View
                                style={{
                                  marginLeft: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <CustomImage
                                  source={item.profile_image}
                                  webStyle={{
                                    width: 180,
                                    height: 180,
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    marginHorizontal: helpers.isDesktop()
                                      ? 0
                                      : 0,
                                    marginRight: 20,
                                    marginLeft: -20,
                                  }}
                                  style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: "contain",
                                  }}
                                  require={false}
                                  className={""}
                                  altText={item.name}
                                  resizeMode={"contain"}
                                />
                                {this.props.darkMode && (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                                    }}
                                  ></View>
                                )}
                              </View>
                              <View style={{ marginTop: 0, marginLeft: 0 }}>
                                <Text
                                  style={[
                                    { fontSize: 20 },
                                    {
                                      fontFamily: FontFamily.abrilFatface,
                                      color: darkMode ? "white" : "black",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                                {/* <View style={{ backgroundColor: '#907CFF', width: '50%', height: 1, marginTop: 16, marginBottom: 16 }}></View> */}
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.designation}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: "#6A6A6A",
                                      width: 3,
                                      height: 3,
                                      borderRadius: 1.5,
                                      marginLeft: 8,
                                      marginRight: 8,
                                      alignSelf: "center",
                                    }}
                                  ></View>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#FC876D",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: 200,
                                    marginTop: 20,
                                  }}
                                >
                                  <Text
                                    numberOfLines={6}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 14 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 24
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.biography}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* <View style={{flexDirection:'column',marginLeft:35,marginRight:35,justifyContent:'space-between',}}>
                        <Text numberOfLines={0} style={[{ marginLeft:16,fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 25 : 14, color: '#292929', marginBottom: helpers.isDesktop() ? 16 : 0, letterSpacing: 0.3 ,marginTop:0},{flexWrap:'wrap'}, darkMode && { color: '#292929' }]}>{item.description}</Text>
            </View> */}
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>

                {/* 5th Slide */}

                <View style={{ flexDirection: "row" }}>
                  {this.props.data5 &&
                    this.props.data5.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { marginLeft: 20, marginRight: 20 },
                            {
                              // borderColor: '#F3F5FF', // if you need
                              // borderWidth: 2,
                              // shadowOpacity: 0.7,
                              shadowColor: "#000",
                              flex: 1,
                              marginTop: 50,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item);
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View
                                style={{
                                  marginLeft: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <CustomImage
                                  source={item.profile_image}
                                  webStyle={{
                                    width: 180,
                                    height: 180,
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    marginHorizontal: helpers.isDesktop()
                                      ? 0
                                      : 0,
                                    marginRight: 20,
                                    marginLeft: -20,
                                  }}
                                  style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: "contain",
                                  }}
                                  require={false}
                                  className={""}
                                  altText={item.name}
                                  resizeMode={"contain"}
                                />
                                {this.props.darkMode && (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                                    }}
                                  ></View>
                                )}
                              </View>
                              <View style={{ marginTop: 0, marginLeft: 0 }}>
                                <Text
                                  style={[
                                    { fontSize: 20 },
                                    {
                                      fontFamily: FontFamily.abrilFatface,
                                      color: darkMode ? "white" : "black",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                                {/* <View style={{ backgroundColor: '#907CFF', width: '50%', height: 1, marginTop: 16, marginBottom: 16 }}></View> */}
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.designation}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: "#6A6A6A",
                                      width: 3,
                                      height: 3,
                                      borderRadius: 1.5,
                                      marginLeft: 8,
                                      marginRight: 8,
                                      alignSelf: "center",
                                    }}
                                  ></View>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#FC876D",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: 200,
                                    marginTop: 20,
                                  }}
                                >
                                  <Text
                                    numberOfLines={6}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 14 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 24
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.biography}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* <View style={{flexDirection:'column',marginLeft:35,marginRight:35,justifyContent:'space-between',}}>
                        <Text numberOfLines={0} style={[{ marginLeft:16,fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 25 : 14, color: '#292929', marginBottom: helpers.isDesktop() ? 16 : 0, letterSpacing: 0.3 ,marginTop:0},{flexWrap:'wrap'}, darkMode && { color: '#292929' }]}>{item.description}</Text>
            </View> */}
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>
                {/* 6th Slide */}

                <View style={{ flexDirection: "row" }}>
                  {this.props.data6 &&
                    this.props.data6.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/writers/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { marginLeft: 20, marginRight: 20 },
                            {
                              // borderColor: '#F3F5FF', // if you need
                              // borderWidth: 2,
                              // shadowOpacity: 0.7,
                              shadowColor: "#000",
                              flex: 1,
                              marginTop: 50,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item);
                            }}
                          >
                            <View style={{ flexDirection: "row" }}>
                              <View
                                style={{
                                  marginLeft: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <CustomImage
                                  source={item.profile_image}
                                  webStyle={{
                                    width: 180,
                                    height: 180,
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    marginHorizontal: helpers.isDesktop()
                                      ? 0
                                      : 0,
                                    marginRight: 20,
                                    marginLeft: -20,
                                  }}
                                  style={{
                                    width: 180,
                                    height: 180,
                                    resizeMode: "contain",
                                  }}
                                  require={false}
                                  className={""}
                                  altText={item.name}
                                  resizeMode={"contain"}
                                />
                                {this.props.darkMode && (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                                    }}
                                  ></View>
                                )}
                              </View>
                              <View style={{ marginTop: 0, marginLeft: 0 }}>
                                <Text
                                  style={[
                                    { fontSize: 20 },
                                    {
                                      fontFamily: FontFamily.abrilFatface,
                                      color: darkMode ? "white" : "black",
                                    },
                                  ]}
                                >
                                  {item.name}
                                </Text>
                                {/* <View style={{ backgroundColor: '#907CFF', width: '50%', height: 1, marginTop: 16, marginBottom: 16 }}></View> */}
                                <View style={{ flexDirection: "row" }}>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.designation}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: "#6A6A6A",
                                      width: 3,
                                      height: 3,
                                      borderRadius: 1.5,
                                      marginLeft: 8,
                                      marginRight: 8,
                                      alignSelf: "center",
                                    }}
                                  ></View>
                                  <Text
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 27
                                          : 20,
                                        color: "#FC876D",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.location}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: 200,
                                    marginTop: 20,
                                  }}
                                >
                                  <Text
                                    numberOfLines={6}
                                    style={[
                                      {
                                        fontFamily: FontFamily.regular,
                                        flexWrap: "wrap",
                                        fontSize: helpers.isDesktop() ? 14 : 14,
                                        lineHeight: helpers.isDesktop()
                                          ? 24
                                          : 20,
                                        color: "#000000",
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        letterSpacing: 0.3,
                                      },
                                      this.props.darkMode && { color: "white" },
                                    ]}
                                  >
                                    {item.biography}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* <View style={{flexDirection:'column',marginLeft:35,marginRight:35,justifyContent:'space-between',}}>
                        <Text numberOfLines={0} style={[{ marginLeft:16,fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 25 : 14, color: '#292929', marginBottom: helpers.isDesktop() ? 16 : 0, letterSpacing: 0.3 ,marginTop:0},{flexWrap:'wrap'}, darkMode && { color: '#292929' }]}>{item.description}</Text>
            </View> */}
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>
              </Swiper>
            </>
          ) : (
            <>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
              >
                <View
                  style={[
                    Flex.row,
                    ,
                    Flex.alignCenter,
                    !process.browser && { overflow: "hidden" },
                    !webView && { width: "100%" },
                  ]}
                >
                  {data &&
                    data.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickNewsletter = {
                          to: "/authors/" + item.slug,
                        };
                      } else {
                        btnClickNewsletter = {
                          onPress: () =>
                            this.props.getNewsletterClick(item.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            !helpers.isDesktop() && {
                              marginTop: 40,
                              marginRight: 20,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickNewsletter}
                            handleClick={() => {
                              this.trackNewsletterClick(item, index);
                            }}
                          >
                            <View style={{ flexDirection: "column" }}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginRight: 20,
                                }}
                              >
                                <View
                                  style={{
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <CustomImage
                                    source={item.profile_image}
                                    webStyle={{
                                      width: 90,
                                      height: 90,
                                      objectFit: "contain",
                                    }}
                                    style={{ width: 100, height: 100 }}
                                    require={false}
                                    className={""}
                                    altText={item.name}
                                    resizeMode={"contain"}
                                  />
                                  {darkMode && (
                                    <View
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor:
                                          "rgba(18, 18, 18, 0.05)",
                                      }}
                                    ></View>
                                  )}
                                </View>

                                <View
                                  style={{
                                    marginTop: 0,
                                    marginLeft: 15,
                                    alignSelf: "center",
                                  }}
                                >
                                  <Text
                                    style={[
                                      { fontSize: 20 },
                                      {
                                        fontFamily: FontFamily.regular,
                                        color: darkMode ? "white" : "black",
                                      },
                                    ]}
                                  >
                                    {item.name}
                                  </Text>
                                  <View style={{ flexDirection: "row" }}>
                                    <Text
                                      style={[
                                        {
                                          fontFamily: FontFamily.regular,
                                          flexWrap: "wrap",
                                          fontSize: helpers.isDesktop()
                                            ? 16
                                            : 14,
                                          lineHeight: helpers.isDesktop()
                                            ? 27
                                            : 20,
                                          color: "#000000",
                                          marginBottom: helpers.isDesktop()
                                            ? 0
                                            : 0,
                                          letterSpacing: 0.3,
                                        },
                                        this.props.darkMode && {
                                          color: "white",
                                        },
                                      ]}
                                    >
                                      {item.designation}
                                    </Text>
                                    <View
                                      style={{
                                        backgroundColor: "#6A6A6A",
                                        width: 3,
                                        height: 3,
                                        borderRadius: 1.5,
                                        marginLeft: 8,
                                        marginRight: 8,
                                        alignSelf: "center",
                                      }}
                                    ></View>
                                    <Text
                                      style={[
                                        {
                                          fontFamily: FontFamily.regular,
                                          flexWrap: "wrap",
                                          fontSize: helpers.isDesktop()
                                            ? 16
                                            : 14,
                                          lineHeight: helpers.isDesktop()
                                            ? 27
                                            : 20,
                                          color: "#6A6A6A",
                                          marginBottom: helpers.isDesktop()
                                            ? 0
                                            : 0,
                                          letterSpacing: 0.3,
                                        },
                                        this.props.darkMode && {
                                          color: "white",
                                        },
                                      ]}
                                    >
                                      {item.location}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: 200,
                                  marginTop: 20,
                                }}
                              >
                                <Text
                                  numberOfLines={6}
                                  style={[
                                    {
                                      fontFamily: FontFamily.regular,
                                      flexWrap: "wrap",
                                      fontSize: helpers.isDesktop() ? 14 : 14,
                                      lineHeight: helpers.isDesktop() ? 24 : 20,
                                      color: "#000000",
                                      marginBottom: helpers.isDesktop() ? 0 : 0,
                                      letterSpacing: 0.3,
                                      fontWeight: "400",
                                    },
                                    this.props.darkMode && { color: "white" },
                                  ]}
                                >
                                  {item.biography}
                                </Text>
                              </View>
                              <Text
                                style={{
                                  marginLeft: 0,
                                  fontSize: 20,
                                  color: "#FC876D",
                                  marginTop: 20,
                                  marginBottom: 20,
                                }}
                              >
                                →
                              </Text>
                            </View>
                          </TouchableOpacityLink>
                        </View>
                      );
                    })}
                </View>
              </ScrollView>
            </>
          )}
        </View>
        <View
          style={
            ([helpers.isDesktop() && Flex.row, Margin.mh_5],
            { alignSelf: "center", marginTop: 20, marginBottom: 20 })
          }
        >
          <TouchableOpacityLinkExternal {...btnClickAllWriters} newTab={true}>
            <ButtonGradient
              title={"VIEW ALL WRITERS"}
              rootStyle={{
                btnWrapper: [button.primaryGradient, { height: 50 }],
                btnText: [
                  button.primaryGradientText,
                  {
                    fontSize: 16,
                    paddingHorizontal: 45,
                    fontWeight: "600",
                    fontFamily: FontFamily.regular,
                  },
                ],
              }}
              paddingOverride={true}
              onClick={() => {
                this.pushInformationToDataLayer()
                this.props.navigateApp("/writers");
              }}
              darkMode={darkMode}
            />
          </TouchableOpacityLinkExternal>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    flex: 1,
    width: 200,
    height: 200,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#dbf3fa",
  },
});

export default WritersSlider;
