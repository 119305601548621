import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, sliderStyle, FontFamily, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import AuthenticationWrapper from '../authentication-wrapper';

const arrowLeft = getImagePath('icons/arrow-left-dark.png');
const arrowRight = getImagePath('icons/arrow-right-dark.png');

const quoteLeft = getImagePath('icons/quotegreen.png');
const quoteLeft1 = getImagePath('icons/quotegreen1.png');
const quoteRight = getImagePath('icons/quoteImageR.png');
const quoteBlack = getImagePath('icons/black-quote.png');
const readerimg = getImagePath('img/amod-img.png');

const cookies = new Cookies();

export class Slider extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.appIntroSlider = React.createRef();
        this.checkScroll = true;
        const windowWidth = Dimensions.get('window').width;
        this.state = {
            activeSlide: 0,
            PlatformWeb: helpers.isDesktop(),
            slideItemWidth: windowWidth - 40,
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount() {
        if (helpers.isWeb()) {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        if (helpers.isWeb()) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize = () => {
        setTimeout(() => {
            const windowWidth = Dimensions.get('window').width
            if (windowWidth < 992) {
                this.setState({
                    PlatformWeb: false,
                    slideItemWidth: windowWidth - 40,
                })
            } else {
                this.setState({
                    PlatformWeb: true,
                    slideItemWidth: windowWidth - 40,
                })
            }
        }, 200)
    }

    getSlideIndex = (offset, width) => {
        const { data } = this.props;
        console.log(data);
        let intervals = data?.length;

        for (let i = 1; i <= intervals; i++) {
            let index = Math.round((width / intervals) * i);
            if (Math.round(offset) < index) {
                return i - 1;
            }
            if (i == intervals) {
                return i - 1;
            }
        }
    }

    getSlideOffset = (offset, width) => {
        if (this.checkScroll) {
            let slideIndex = this.getSlideIndex(offset, width);
            console.log("offset", slideIndex)

            this.setState({
                activeSlide: slideIndex
            })
            // if(this.props.subscriptionQuote) {
            //     if(helpers.isWeb()) {
            //         Mixpanel.track('swipe_reader_quotes', { position: slideIndex });
            //     }
            // }
        }
    }

    prevSlide = () => {
        const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = data.length;


        let totalSlideWidth = slideItemWidth - 80

        let totalWidth = Math.round(slideLength * totalSlideWidth);


        let scrollIndex;

        if (activeSlide > 2) {
            scrollIndex = Math.round(slideItemWidth * (activeSlide - 2));
        } else {
            scrollIndex = 0;
        }

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
    }

    nextSlide = () => {
        const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = data.length;

        let scrollIndex = Math.round(slideItemWidth * (activeSlide));

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })

    }

    pushCarouselClickEventToDataLayer = () => {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "carousel_click",
          custom_user_id: this.props.custom_user_id || "NA",
          user_status: this.props.custom_user_id ? "logged in" : "guest",
          carousel_name: "why I read tmc",
          scroll_depth: helpers.getScrollDepth(),
        });
      };

    slideTo = (index) => {
        // index = index === 0 ? 0 : index+1;
        this.checkScroll = false;
        const { data } = this.props;
        const { slideItemWidth } = this.state;
        let slideLength = data.length;

        let scrollIndex = Math.round(slideItemWidth * (index));
        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
        this.setState({ activeSlide: index }, () => {
            setTimeout(() => {
                this.checkScroll = true;
            }, 2000)
        })
        this.pushCarouselClickEventToDataLayer();
    }

    render() {
        const { data, subscriptionQuote, teamSubscribers, teamSubscribersMob, darkMode } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
      
        console.log(data);
        let intervals = data.length;

        let isWeb = helpers.isWeb();

        let dotClick;


        return (
            <View style={[sliderStyle.wrapper, helpers.isDesktop() && { maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }, { backgroundColor: 'transparent' }, teamSubscribers && { maxWidth: helpers.isDesktop() ? 520 : '100%' }, !isWeb && { width: 'auto' }]}>
                <ScrollView
                    contentContainerStyle={{ width: `${100 * intervals}%` }}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled={true}
                    onScroll={data => {
                        this.getSlideOffset(data.nativeEvent.contentOffset.x, data.nativeEvent.contentSize.width)
                    }}
                    scrollEventThrottle={200}
                    decelerationRate="fast"
                    ref={ref => this.scrollView = ref}
                >
                    {data && data.map((item, index) => {
                        return (
                            <View key={`slide_${index}`} style={[sliderStyle.sliderOuterItem, helpers.isDesktop() && sliderStyle.sliderOuterItemDesktop, { width: slideItemWidth, }, !helpers.isDesktop() && !teamSubscribers && { marginRight: 20, marginLeft: helpers.isDesktop() ? 0 : 20 }, !isWeb && { marginRight: 0, paddingHorizontal: 5 }]}>
                                <View style={[{ maxWidth: helpers.isDesktop() ? 593 : '100%', minHeight: helpers.isDesktop() ? 350 : '100%', marginHorizontal: helpers.isDesktop() ? 10 : 0, }, { borderWidth: 1, borderColor: '#FFFFFF', borderRadius: 20, backgroundColor: '#FFFFFF', }, !helpers.isDesktop() && { height: 355 }, darkMode && { backgroundColor: Colors.darkBlackColor1, borderColor: Colors.darkBlackColor1 }]}>

                                    <View style={[helpers.isDesktop() ? Padding.pl_0 : Padding.pl_15, { position: 'relative', marginHorizontal: "auto", width: helpers.isDesktop() ? '75%' : '78%', flexDirection: "row", marginTop: helpers.isDesktop() ? 0 : 10 }]}>
                                        <CustomImage
                                            source={this.props.isFromHome ? quoteBlack : quoteBlack}
                                            require={true}
                                            style={{ width: 30, height: 25, resizeMode: 'contain' }}
                                            webStyle={helpers.isDesktop() ? { width: 54, height: 44, objectFit: 'contain' } : { width: 30, height: 25, objectFit: 'contain' }}
                                        />
                                        <View style={{
                                            backgroundColor: '#ffffff',
                                            zIndex: 1,
                                            fontFamily: FontFamily.mistralfont,
                                            paddingVertical: 0,
                                            paddingLeft: helpers.isDesktop() ? 35 : 10,
                                            paddingRight: helpers.isDesktop() ? 20 : 10,
                                        }} >
                                            <Text
                                                style={{
                                                    fontFamily: FontFamily.mistralfont,
                                                    fontSize: helpers.isDesktop() ? 38 : 25,
                                                    lineHeight: helpers.isDesktop() ? 62 : 28,

                                                }}>Why I read TMC</Text>
                                        </View>

                                        <View
                                            style={[
                                                Helpers.titleLine,
                                                {
                                                    backgroundColor: darkMode
                                                        ? "rgba(255,255,255,0.6)"
                                                        : "rgba(102, 102, 102, 0.6)",
                                                    width: "70%",
                                                    right: 0,
                                                },

                                            ]}
                                        ></View>
                                    </View>

                                    <View style={[{ paddingHorizontal: helpers.isDesktop() ? 55 : 25, marginTop: 9, marginBottom: 8, }]}>
                                        <Text style={{ color: "#000000", fontFamily: FontFamily.regular, lineHeight: helpers.isDesktop() ? 25 : 21, fontSize: helpers.isDesktop() ? 18 : 14, marginBottom: 27, textAlign: 'center', }}>{item.text}</Text>
                                        <View style={{ display: "flex", flexDirection: 'row', width: helpers.isDesktop() ? 480 : '100%', marginHorizontal: "auto", justifyContent: 'center', alignItems: 'center', }} >
                                            <View
                                                style={{
                                                    width: helpers.isDesktop() ? 104 : 90,
                                                    height: helpers.isDesktop() ? 104 : 90,
                                                    overflow: "hidden",
                                                    marginRight: 10,
                                                    borderRadius: "50%",
                                                    padding: 1,
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    borderColor: "#D9D9D9",
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        width: helpers.isDesktop() ? 100 : 90,
                                                        height: helpers.isDesktop() ? 100 : 90,
                                                        overflow: "hidden",
                                                        borderRadius: "100%",
                                                    }}
                                                >
                                                    <CustomImage
                                                        source={item.image}
                                                        require={true}
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                        }}
                                                        webStyle={{
                                                            width: "100%",
                                                            height: "auto",
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                            <Text style={{ lineHeight: helpers.isDesktop() ? 21 : 17, color: "#000000", fontSize: helpers.isDesktop() ? 17 : 14, fontFamily: FontFamily.regular, }}>{item.by}, <br />{item.designation}, <br />{item.org}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )
                    })
                    }
                </ScrollView >
                {/* { helpers.isDesktop() &&
                    <View style={[sliderStyle.arrowLeft, { transform: [{ translateY: isWeb ? '-50%' : -30 }] }, activeSlide == 1 && { opacity: 1 }]}>
                        <TouchableOpacity
                            style={[sliderStyle.arrowBtn]}
                            onPress={() => this.prevSlide()}
                            disable={activeSlide == 1 ? true : false}
                        >
                            <CustomImage
                                source={arrowLeft}
                                require={true}
                                style={{ width: 9, height: 15 }}
                                webStyle={{ width: 9, height: 15 }}
                            />
                        </TouchableOpacity>
                    </View>
                }
                { helpers.isDesktop() &&
                    <View style={[sliderStyle.arrowRight, { transform: [{ translateY: isWeb ? '-50%' : -30 }] }, activeSlide == data.length && { opacity: 1 }]}>
                        <TouchableOpacity
                            style={[sliderStyle.arrowBtn]}
                            onPress={() => this.nextSlide()}
                            disable={activeSlide == data.length ? true : false}
                        >
                            <CustomImage
                                source={arrowRight}
                                require={true}
                                style={{ width: 9, height: 15 }}
                                webStyle={{ width: 9, height: 15 }}
                            />
                        </TouchableOpacity>
                    </View>
                } */}
                < View style={[sliderStyle.dotWrapper, { bottom: helpers.isDesktop() ? 15 : 40 }, teamSubscribers && { justifyContent: 'flex-start', paddingLeft: helpers.isDesktop() ? 50 : 0 }]} >
                    {data && data.map((item, index) => {
                        if (isWeb) {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        } else {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        }
                        return (
                            <TouchableOpacity {...dotClick}>
                                {!darkMode ?
                                    <View key={`dot_${index}`} style={[sliderStyle.dot, (index) == activeSlide && sliderStyle.activeDot]} />
                                    :
                                    <View key={`dot_${index}`} style={[sliderStyle.dotDark, (index) == activeSlide && sliderStyle.activeDotDark]} />
                                }
                            </TouchableOpacity>
                        )
                    })}
                </View >
            </View >
        );
    }
}

export default AuthenticationWrapper(Slider);
