import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button, Typo, subscribePlans, appStyles, Colors, FontFamily, FontWeight } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';

const checkIcon = getImagePath('icons/check.png');

const gift_box_one = getImagePath('icons/gift_pl_one.png');

export class GiftSubscriptionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            giftCredits: this.props.freeCreditsAvailable ? this.props.freeCreditsAvailable : 0
        }
    }

    componentDidMount = () => {
    }

    setGiftCredits = (value) => {
        let key = value.split('');
        let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        for (var i = key.length - 1; i >= 0; i--) {
            if (keyList.indexOf(key[i]) == -1) {
                return false;
            }
        }
        this.setState({ giftCredits: value });

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.freeCreditsAvailable != prevProps.freeCreditsAvailable) {
            this.setState({ giftCredits: this.props.freeCreditsAvailable });
        }
    }

    render() {
        const { plan, index, subscribeToPlan, fullWidth, sidePanel, subscriptionPage = false, multiplePlans, navigateToTeamSubscription, darkMode, icon, title, annualSub, userSubscription, freeCreditsAvailable, plCredits, sidebar, resetData } = this.props;
        let { giftCredits } = this.state;
        let checkDesktop = sidePanel ? false : (helpers.isDesktop() ? true : false);

        let webView = helpers.isWeb();
        return (
            <View style={[subscribePlans.planItemWrap, helpers.isDesktop() && subscribePlans.planItemWrapDesktop, helpers.isDesktop() ? Margin.mb_3 : Margin.mb_5, { maxWidth: checkDesktop ? '33.33%' : '100%', width: '100%' }, subscriptionPage && { maxWidth: '100%' }, (multiplePlans) && { flex: 1 }, sidebar && { maxWidth: '100%', paddingLeft: 0, paddingRight: 0 }]}>
                <View style={{ backgroundColor: annualSub ? 'rgba(255, 147, 2, 0.14)' : 'rgba(169, 125, 241, 0.2)', borderWidth: 2, borderColor: annualSub ? '#FF9302' : '#6C7AF7', borderBottomWidth: 0, alignItems: 'center', height: 108, zIndex: 1 }}>
                    <CustomImage
                        source={icon}
                        require={true}
                        style={{ width: 177, height: 135, resizeMode: 'contain', marginTop: -30 }}
                        webStyle={{ width: annualSub ? 190 : 177, height: annualSub ? 166 : (plCredits ? 155 : 135), objectFit: 'contain', marginTop: annualSub ? -49 : (plCredits ? -42 : -30) }}
                    />
                </View>
                <View style={[subscribePlans.planItem, appStyles.appBg, darkMode && { backgroundColor: Colors.darkBlackColor }, !webView && { paddingTop: 20 }, { borderTopWidth: 0, paddingBottom: 20, paddingTop: 20 }, { borderColor: annualSub ? '#FF9302' : '#6C7AF7' }]}>

                    <Text style={[subscribePlans.planType, Margin.mb_2, helpers.isDesktop() && subscribePlans.planTypeDesktop, sidePanel && subscribePlans.planTypePanel, { fontSize: helpers.isDesktop() ? 28 : 20, color: '#000', fontFamily: FontFamily.abrilFatface }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>{plan.invoice_name}</Text>

                    <View style={{ flex: 1, marginBottom: 30 }}>
                        {plan.description.access_desc_new ?
                            <>
                                {plan.description.access_desc_new.map((itemElement) => {
                                    return (
                                        <View style={[Margin.mt_1, Margin.mb_0]}>
                                            <View style={[Flex.row, { alignItems: 'flex-start' }]}>
                                                <View style={{ minWidth: 14 }}>
                                                    <CustomImage
                                                        source={checkIcon}
                                                        require={true}
                                                        style={{ width: 20, height: 14, resizeMode: 'contain', marginTop: 3 }}
                                                        webStyle={{ width: 20, height: 14, objectFit: 'contain', marginTop: 3 }}
                                                    />
                                                </View>
                                                <View style={[{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }]}>
                                                    {Object.keys(itemElement).map(key => {
                                                        if (key.includes('text')) {
                                                            return (
                                                                <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop, sidePanel && subscribePlans.planDescPanel, helpers.isDesktop() && subscribePlans.planDescPanelDesktop, Margin.ml_1, { textAlign: 'left', marginBottom: 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && Margin.mr_2]}>
                                                                    {itemElement[key]}
                                                                </Text>
                                                            )
                                                        }
                                                        else {
                                                            let linkClick;
                                                            if (helpers.isWeb()) {
                                                                linkClick = {
                                                                    href: itemElement[key]['href']
                                                                }
                                                            }
                                                            else {
                                                                linkClick = {
                                                                    onPress: () => this.props.openSocialMedia(itemElement[key]['href'])
                                                                }
                                                            }
                                                            return (
                                                                <TouchableOpacityLinkExternal {...linkClick} newTab={true}>
                                                                    <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop, sidePanel && subscribePlans.planDescPanel, helpers.isDesktop() && subscribePlans.planDescPanelDesktop, Margin.ml_1, { textAlign: 'left', marginBottom: 0, letterSpacing: 0.3, textDecorationLine: 'underline' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && Margin.mr_2]}>
                                                                        {itemElement[key]['title']}
                                                                    </Text>
                                                                </TouchableOpacityLinkExternal>
                                                            )
                                                        }
                                                    })}
                                                </View>
                                            </View>
                                        </View>
                                    )
                                })
                                }
                            </>
                            :
                            <>
                                {plan.description.access_desc &&
                                    plan.description.access_desc.map((itemElement, index) => {
                                        return (
                                            <View style={[Margin.mt_1, Margin.mb_0]} key={itemElement}>
                                                <View style={[Flex.row, { alignItems: 'flex-start' }]}>
                                                    <View style={{ minWidth: 14 }}>
                                                        <CustomImage
                                                            source={checkIcon}
                                                            require={true}
                                                            style={{ width: 20, height: 14, resizeMode: 'contain', marginTop: 3 }}
                                                            webStyle={{ width: 20, height: 14, objectFit: 'contain', marginTop: 3 }}
                                                        />
                                                    </View>
                                                    <View style={[{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }]}>
                                                        <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop, sidePanel && subscribePlans.planDescPanel, helpers.isDesktop() && subscribePlans.planDescPanelDesktop, Margin.ml_1, { textAlign: 'left', marginBottom: 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && Margin.mr_2]}>{itemElement}</Text>
                                                        {plan.is_free_privilege_credits && index == 1 &&
                                                            <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop, sidePanel && subscribePlans.planDescPanel, helpers.isDesktop() && subscribePlans.planDescPanelDesktop, { textAlign: 'left', marginBottom: 0, letterSpacing: 0.3 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }, !webView && Margin.mr_2, { fontFamily: FontFamily.bold }]}>{resetData}</Text>
                                                        }
                                                    </View>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </>
                        }
                    </View>

                    {!plan.is_free_privilege_credits &&
                        <>
                            <Text style={[subscribePlans.planPrice, { marginTop: 0 }]}><Text style={[subscribePlans.symbol]}>{plan.currency_code == 'INR' ? '₹' : '$'}</Text>{plan.discount.offer_price ? plan.discount.offer_price : plan.price}</Text>

                            {
                                plan.description.discount_desc &&
                                <View style={[Flex.row, Flex.justfiyCenter, { alignItems: 'baseline', paddingTop: 0, paddingBottom: 0 }]}>
                                    <Text style={{ fontFamily: FontFamily.medium, color: '#bbbbbc', textDecorationLine: 'line-through' }}>{plan.currency_code == 'INR' ? '₹' : '$'} <Text style={{ fontSize: 20 }}>{plan.description.discount_desc.net_price}</Text></Text>{plan.description.discount_desc.discount_percentage && <Text style={{ color: Colors.green, fontSize: 24, fontFamily: FontFamily.medium }}> ({plan.description.discount_desc.discount_percentage}% OFF)</Text>}
                                </View>
                            }
                        </>
                    }

                    {plan.is_free_privilege_credits && userSubscription && userSubscription.plan && (freeCreditsAvailable != null && freeCreditsAvailable != undefined) &&
                        <View style={sidebar && { marginRight: -25, marginLeft: -25, marginBottom: -20 }}>
                            <View style={{ alignItems: 'center', paddingTop: 10, backgroundColor: 'rgba(238,229,252,0.4)', width: '100%' }}>
                                <Text style={{ fontSize: 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.6)', marginBottom: 5 }}>
                                    Credits Available this month
                            </Text>

                                <Text style={{ fontSize: 36, fontFamily: FontFamily.medium, fontWeight: FontWeight.semiBold, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 5, textAlign: 'center' }}>
                                    {freeCreditsAvailable}/3
                            </Text>

                                {/* <Text style={{ fontSize: 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.6)' }}>(Available this month : <Text style={{ color: Colors.green, fontWeight: '600' }}>{freeCreditsAvailable}</Text>)</Text> */}

                                {/* <TextInput
                                placeholderTextColor={Colors.placeholderTextColor}
                                style={[{ fontSize: 36, lineHeight: 43, fontFamily: FontFamily.medium, fontWeight: FontWeight.semiBold, borderWidth: 1, borderColor: '#6031E2', backgroundColor: darkMode ? '#1e1e1e' : '#F5F1FF', width: 100, marginTop: 20, marginBottom: 0, textAlign: 'center', color: '#000', borderRadius: 3 }, !helpers.isWeb() && { height: 50 }, darkMode && { color: Colors.lightWhite }]}
                                onChangeText={(value) => { this.setGiftCredits(value) }}
                                value={giftCredits.toString()}
                                keyboardType={'numeric'}
                                editable={parseInt(freeCreditsAvailable) > 0}
                            /> */}
                            </View>
                        </View>
                    }

                    {!plan.is_free_privilege_credits &&

                        <View style={{ marginTop: helpers.isDesktop() ? 50 : 30 }}>
                            <ButtonGradient
                                title={'Gift Now'}
                                onClick={() => { this.props.subscribeToPlan(giftCredits) }}
                                rootStyle={{
                                    btnWrapper: button.primaryGradient,
                                    btnText: [button.primaryGradientText, { fontWeight: '400', fontSize: 16 }],
                                }}
                                loader={false}
                                disable={(plan.is_free_privilege_credits && (!giftCredits || parseInt(giftCredits) < 1 || parseInt(giftCredits) > parseInt(freeCreditsAvailable))) || !plan.is_free_privilege_credits && Platform.OS == 'ios'}
                                darkMode={darkMode}
                            />
                            {!plan.is_free_privilege_credits && Platform.OS == 'ios' &&
                                <View style={[{ marginTop: 10 }]}>
                                    <Text style={{ fontSize: 16, fontFamily: FontFamily.medium, color: '#F02121', textAlign: 'center' }}>Gifting {plan.invoice_name} is not available on iOS app.</Text>
                                </View>
                            }
                        </View>

                        // <View style={{ marginTop: 110 }}>
                        //     <Text style={{ fontSize: 16, fontFamily: FontFamily.medium, color: '#F02121', textAlign: 'center' }}>Available only to Annual Subscribers</Text>
                        // </View>
                    }



                </View>
            </View>
        );
    }
}

export default GiftSubscriptionBox;