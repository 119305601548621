import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers } from '../../styles/appStyles';
import ImageBlurLoading from 'react-native-image-blur-loading';

export class CustomImage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() {
        const { style, source, webStyle, resizeMode, require, className, lazyLoad } = this.props;

        let frame = {};
        frame = style;

        let imgSource;
        if (require) {
            imgSource = source
        } else {
            imgSource = { uri: source }
        }

        return (
            <>
                {
                    lazyLoad ?
                        <ImageBlurLoading
                            withIndicator={false}
                            thumbnailSource={imgSource}
                            source={imgSource}
                            style={frame}
                            
                        />
                        :
                        <Image resizeMode={resizeMode} source={imgSource} style={frame} />
                }
            </>
        );
    }
}

export default CustomImage;