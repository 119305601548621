import React, { Component } from 'react';
import { Text, View, ScrollView, Platform, ActivityIndicator, Image, Modal, Linking, Dimensions, Share } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, FontFamily, appStyles, actionBox, accessContent, exclusiveContent, textBlock, articleLoader, articleLists, FontWeight, button, Colors } from '../../styles/appStyles';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import PostContent from '../../components/post-content';
import PostTags from '../../components/post-tags';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import ErrorModal from '../../components/error-modal';
import Seo from "../../components/seo-meta";
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import AboutAuthor from '../../components/about-author';
import PageBreak from '../../components/page-break';
import ArticleList from '../../components/articles-list';
import NoteDisclosures from '../../components/note-disclosure';
import PostActionBar from '../../components/post-actionbar'
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from '../../utils/image-helper';
import PostHeader from '../../components/blog-header';
import AuthenticationWrapper from '../../components/authentication-wrapper';
const overlayImage = getImagePath('img/excerpt_overlay.png');

const cookies = new Cookies();

export class BlogSingle extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        let tokenSet = (token == undefined || token == '') ? false : true;
        let postSlug = '';
        if (webView && this.props.postSlug) {
            postSlug = this.props.postSlug;
        }
        else if (!webView) {
            postSlug = this.props.route.params.postSlug;
        }
        this.state = {
            token: tokenSet ? token : false,
            postData: this.props.postData ? this.props.postData : '',
            postDataSSR: this.props.postData ? true : false,
            pageLoader: this.props.postData ? false : true,
            articlesLoader: false,
            postLists: this.props.postData ? this.props.postData.postLists : false,
            postSlug: postSlug,
            userLoggedin: tokenSet,
            messageBox: {
                type: '',
                title: '',
                subTitle: '',
            },
            showErrorModal: (this.props.postData && this.props.postData.error) ? true : false,
            postUnlockError: false,
            footerData: this.props.footerData ? this.props.footerData : false,
            country: false,
            redirectSlugs: {
                web: '/blog/' + postSlug,
                native: { redirect: 'blog-single', postSlug: postSlug, verifyRedirect: '/blog/' + postSlug }
            }
        }

        this.subscriptionLayout = 0;
    }

    componentDidMount = () => {
        if (!this.state.postDataSSR) {
            this.getPost(this.props.scrollTop ? true : false);
        }
        else {
            this.setBreadcrumbs(this.state.postData);
        }
    }

    componentDidUpdate = () => {
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        let postSlug = webView ? this.props.postSlug : this.props.route.params.postSlug;
        if (!(this.props.searchQuery && (this.props.searchQuery.includes("subscribe=") || this.props.searchQuery.includes("buy-credits=true"))) && (((token && token != this.state.token) || ((token == undefined || token == '') && this.state.token != false)) || (postSlug != this.state.postSlug))) {
            this.setState({
                token: (token == undefined || token == '') ? false : token,
                userLoggedin: (token == undefined || token == '') ? false : true,
                postSlug: postSlug
            }, () => this.getPost(true));
        }
    }

    setBreadcrumbs = (data) => {
        Breadcrumbs = [
            {
                name: 'Home',
                link: '/'
            },
            {
                name: 'Blog',
                link: `/blog`
            },
            {
                name: data.story.title
            }
        ]
    }

    getPost = (scroll) => {
        this.setState({ showErrorModal: false, postUnlockError: false, pageLoader: true }, () => this.scrollToTop(scroll));
        const { token } = this.state;
        let endpoint = `/blog/${this.state.postSlug}`;
        apiHelpers
            .apiRequest(endpoint, {}, "GET", true, token)
            .then((res) => {
                if (res.success && res.code == 'blog_found') {
                    res.data.story = res.data.blog;
                    this.setState({
                        postData: res.data,
                        postLists: false,
                        pageLoader: false,
                        articlesLoader: true,
                        statsLoader: true,
                    });

                    this.getOtherArticles(res.data, true);
                    this.setBreadcrumbs(res.data);
                }
                else {
                    this.setState({ showErrorModal: true, pageLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ showErrorModal: true, pageLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getOtherArticles = async (postData) => {
        let endpoint = `/blogs`;
        let body = {
            limit: 3,
            page: 1,
            sort_on: 'date',
            sort_by: 'desc',
            exclude: [postData.story.id]
        }

        body['filters'] = [
            {
                key: "related_blogs",
                value: postData.story.id
            }
        ]

        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'blog_found') {
                    this.setState({
                        postLists:
                        {
                            title: 'Related Post',
                            posts: res.data.story
                        },
                        articlesLoader: false
                    });
                }
                else {
                    this.setState({ articlesLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ articlesLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    sharePost = (slug) => {
        if (helpers.isWeb()) {
            let path = window.location.origin + window.location.pathname;
            if (slug == 'fb') {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${this.state.postData.story.title}`, '_blank');
            } else if (slug == 'twitter') {
                window.open(`https://twitter.com/intent/tweet?text=${this.state.postData.story.title}&url=${path}`, '_blank');
            } else if (slug == 'linkedin') {
                window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${this.state.postData.story.title}`, '_blank');
            } else {
                navigator.share({
                    title: this.state.postData.story.title,
                    text: this.state.postData.story.title,
                    url: path,
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
            }
        } else {
            let { postData } = this.state;
            Share.share({
                message: `${helpers.siteURL}/blog/${postData.story.slug}`,
            });

        }
    }

    scrollToTop = (scroll) => {
        if (scroll) {
            if (helpers.isWeb()) {
                setTimeout(function () {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }, 2);
            } else {
                if (this.appscrollview) {
                    this.appscrollview.scrollTo({ y: 0, animated: true })
                }
                if (this.contentscrollview) {
                    this.contentscrollview.scrollTo({ y: 0, animated: true })
                }
            }
        }
    }

    articleListsLoader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let contentBg = darkMode ? '#121212' : '#ffffff';
        return (
            <View style={[Flex.fill, { marginBottom: 40 }, { backgroundColor: contentBg }]}>
                <View style={[articleLists.listWrap]}>
                    <View style={{ width: 100 }}>
                        <ContentLoader
                            containerStyles={{ marginBottom: 0, paddingLeft: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View>
                        <ContentLoader
                            containerStyles={{ marginBottom: 0, paddingLeft: 0, marginTop: 0 }}
                            active={true}
                            pHeight={[300]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={{ width: 200, marginTop: 10 }}>
                        <ContentLoader
                            containerStyles={{ marginBottom: 0, paddingLeft: 0 }}
                            active={true}
                            pHeight={[40]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[articleLists.lineBreak, Margin.mt_1, Margin.mb_15]}></View>
                    <View style={{ marginTop: 0 }}>
                        <ContentLoader
                            containerStyles={{ marginBottom: 0, paddingLeft: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    articleListsWrapLoader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let contentBg = darkMode ? '#121212' : '#ffffff';
        return (
            <>
                {helpers.isDesktop() ?
                    <View style={{ flexDirection: 'row', backgroundColor: contentBg }}>
                        {this.articleListsLoader()}
                        {this.articleListsLoader()}
                        {this.articleListsLoader()}
                    </View>
                    :
                    <View style={{ backgroundColor: contentBg }}>
                        {this.articleListsLoader()}
                    </View>
                }
            </>
        )
    }

    listsLoader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let contentBg = darkMode ? '#121212' : '#ffffff';
        return (
            <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000, articleLoader.LoaderWrapper, { backgroundColor: contentBg }]}>
                <View style={{ alignItems: 'center', justifyContent: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <ContentLoader
                        containerStyles={{ marginBottom: 40 }}
                        active={true}
                        pHeight={[30]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                {this.articleListsWrapLoader()}

            </View>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let contentBg = darkMode ? '#121212' : '#ffffff';
        return (
            <View style={{ position: 'relative', zIndex: 2, backgroundColor: contentBg }}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View style={[postSingle.summaryWrapper, helpers.isDesktop() && postSingle.summaryWrapperDesktop, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000, { marginBottom: 0, paddingRight: 0, paddingLeft: 0 }]}>
                    <View style={{ paddingTop: 20, backgroundColor: contentBg, maxWidth: helpers.isDesktop() ? '100%' : '90%', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                        {!helpers.isDesktop() &&
                            <View style={{ alignItems: 'center', justifyContent: 'center', width: helpers.isDesktop() ? '140px' : '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 10 }}
                                    active={true}
                                    pHeight={[10]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                        }

                        <View style={{ alignItems: 'center', justifyContent: 'center', width: helpers.isDesktop() ? '100px' : '50%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 15 }}
                                active={true}
                                pHeight={[16]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 15 }}
                                active={true}
                                pHeight={[50]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 15 }}
                                active={true}
                                pHeight={[20]}
                                title={false}
                                pRows={3}
                                pHeight={[20]}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, { flexDirection: 'row' }]}>
                            <View style={{ flex: 0.2 }}>
                                <ContentLoader active avatar pRows={0}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                            <View style={{ flex: 0.6 }}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 0 }}
                                    active={true}
                                    pHeight={[20]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                                <ContentLoader
                                    containerStyles={{ marginBottom: 15 }}
                                    active={true}
                                    pHeight={[10]}
                                    title={false}
                                    pRows={1}
                                    pWidth={[30]}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                            <View style={{ flex: 0.2 }}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 0 }}
                                    active={true}
                                    pHeight={[30]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                        </View>
                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 40 }}
                                active={true}
                                pHeight={[20]}
                                title={false}
                                pRows={1}
                                pWidth={[50]}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                    </View>

                    <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                        <ContentLoader
                            containerStyles={{ marginBottom: 50, paddingLeft: 0, paddingRight: 0 }}
                            active={true}
                            pHeight={[10]}
                            title={false}
                            pRows={15}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>

                    <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, accessContent.LoaderWrapper]}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 10 }}
                                active={true}
                                pHeight={[20]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        <ContentLoader
                            containerStyles={{ marginBottom: 10 }}
                            active={true}
                            pHeight={[8]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <View style={[Flex.row, Margin.mt_0]}>
                            <View style={[exclusiveContent.borderRight, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_3, Padding.pb_3, Flex.col_5, { width: '50%', alignItems: 'center' }]}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 0 }}
                                    active={true}
                                    pHeight={[40]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                            <View style={[Flex.alignCenter, Flex.justfiyCenter, Padding.pt_3, Padding.pb_3, Flex.col_5, { width: '50%', alignItems: 'center' }]}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 0 }}
                                    active={true}
                                    pHeight={[40]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                        </View>
                        <View style={[Flex.row]}>
                            <View style={[exclusiveContent.borderTop, exclusiveContent.borderRight, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_3, Padding.pb_3, Flex.col_5, { width: '50%' }]}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 0 }}
                                    active={true}
                                    pHeight={[40]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                            <View style={[exclusiveContent.borderTop, Flex.alignCenter, Flex.justfiyCenter, Padding.pt_3, Padding.pb_3, Flex.col_5, { width: '50%' }]}>
                                <ContentLoader
                                    containerStyles={{ marginBottom: 0 }}
                                    active={true}
                                    pHeight={[40]}
                                    title={false}
                                    pRows={1}
                                    pWidth={['100%']}
                                    animationDuration={400}
                                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                                />
                            </View>
                        </View>
                    </View>
                    <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb500, textBlock.LoaderWrapper]}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 10 }}
                                active={true}
                                pHeight={[20]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center', width: '30%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 10 }}
                                active={true}
                                pHeight={[30]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        <ContentLoader
                            containerStyles={{ marginBottom: 5 }}
                            active={true}
                            pHeight={[8]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <View style={{ marginTop: 20 }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 0 }}
                                active={true}
                                pHeight={[40]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                    </View>
                    <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000, articleLoader.LoaderWrapper]}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContentLoader
                                containerStyles={{ marginBottom: 40 }}
                                active={true}
                                pHeight={[30]}
                                title={false}
                                pRows={1}
                                pWidth={['100%']}
                                animationDuration={400}
                                primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                                secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                            />
                        </View>
                        {this.articleListsWrapLoader()}

                    </View>
                </View>

                {/* <ActivityIndicator size={25} color={'#000000'} /> */}
            </View>
        )
    }

    getModalClickEvent = () => {
        this.getPost();
    }

    goToSignUp = (email) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-up?redirect=/' + this.state.redirectSlugs.web + (email ? ('&email=' + email) : ''));
        } else {
            this.props.navigation.navigate('/sign-up', { ...this.state.redirectSlugs.native, email: email });
        }
    }

    goToLogin = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-in?redirect=/' + this.state.redirectSlugs.web);
        } else {
            this.props.navigation.navigate('/sign-in', this.state.redirectSlugs.native);
        }
    }

    changeFontSize = () => {
        console.log('FontSize Changed')
    }

    navigateToHome = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/');
        } else {
            this.props.navigation.navigate('/');
        }
    }

    navigateToPost = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/blog' + '/' + slug);
        } else {
            this.props.navigation.navigate('blog-single', { postSlug: slug });
        }
    }

    navigateToTag = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/tags/' + slug);
        } else {
            this.props.navigation.navigate('tags-archive', { tagSlug: slug });
        }
    }

    navigateToCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    navigateToAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    scrollToSection = (position) => {
        let offsetPosition = position
        if (helpers.isWeb()) {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(offsetPosition)
        }

    }

    openSocialMedia = (link) => {
        if (helpers.isWeb()) {
            window.open(link, '_blank');
        } else {
            if (link == '/investors') {
                this.props.navigation.navigate('/investors');
            }
            else {
                // Linking.openURL(link);
                // this.props.navigation.navigate('in-app-browser', { url: link });
                this.refs.header.openLink(link);
            }
        }
    }

    renderPageContent = () => {
        const { postData, postDataSSR, postLists, pageLoader, userLoggedin, creditPlan, plansData, postUnlocked, userSubscriptionActive, userCredit, enableMessageBox, messageBox, userSubscriptionStatus, storyActions, storyStats, postUnlockError, userLoader, articlesLoader, statsLoader, plansLoader, unlockLoader, insufficientCredits, userSubscription, country, writerData } = this.state;

        let postSummary;
        if (postData && postData.story) {
            postSummary = {
                postTitle: postData.story.title,
                author: postData.story.author,
                story: postData.story,
                lazyLoad: !postDataSSR
            }
        }

        let overlayStyle = { width: '100%', position: 'absolute', bottom: 0, height: helpers.isDesktop() ? '18%' : '9%' }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <View style={[Margin.mb_5, { backgroundColor: darkMode ? Colors.darkBlackColor : '#fafafa' }]}>
                    <PostHeader data={postSummary} getAuthorClick={(slug) => this.navigateToAuthor(slug)} darkMode={darkMode} />
                </View>
                <View style={[appStyles.appBg, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                    {/* {postSummary && <PostSummary data={postSummary} getCategoryClick={(slug) => this.navigateToCategory(slug)} getAuthorClick={(slug) => this.navigateToAuthor(slug)} unlockPost={this.unlockPost} unlockLoader={unlockLoader} postUnlocked={postUnlocked} insufficientCredits={insufficientCredits} />} */}

                    {!helpers.isWeb() &&
                        <View style={{ marginTop: 30 }}></View>
                    }

                    {
                        !helpers.isDesktop() && <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                            <PageBreak style={[Margin.mt_3, helpers.isWeb() && Margin.mb_2]} darkMode={darkMode} />
                        </View>
                    }

                    {
                        helpers.isDesktop() &&
                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                            <PageBreak style={[Margin.mt_5, Margin.mb_2]} darkMode={darkMode} />
                        </View>
                    }

                    {postData.story.content &&
                        <View style={Padding.ph_2}>
                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                                {postData?.story?.content && <PostContent data={postData.story.content} darkMode={darkMode} openLink={(link) => this.openSocialMedia(link)} />}
                            </View>

                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, Margin.mt_0, Margin.mb_6]}>
                                {postData.story && <NoteDisclosures data={postData.story.note} darkMode={darkMode} openLink={(link) => this.openSocialMedia(link)} />}
                            </View>

                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                                {postData.tag && postData.tag.length != 0 && <PostTags data={postData.tag} getLinkClick={(slug) => this.navigateToTag(slug)} darkMode={darkMode} />}
                            </View>

                            <View style={[Margin.mt_1, Margin.mb_3, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                                <PostActionBar updateFontSize={() => this.changeFontSize()} storyActions={storyActions} applyStoryActions={(type, value) => this.applyStoryActions(type, value)} sharePost={(slug) => this.sharePost(slug)} shareTitle={postData.story.title} hideStoryActions={true} hideBorder={true} darkMode={darkMode} />
                            </View>

                            {/* <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                                {postData.story.author && postData.story.author.length != 0 && <AboutAuthor data={postData.story.author} openSocialMedia={(link) => this.openSocialMedia(link)} getAuthorClick={(slug) => this.navigateToAuthor(slug)} />}
                            </View> */}

                            {articlesLoader ?
                                this.listsLoader()
                                :
                                <>
                                    {postLists && <View style={[Margin.mb_5, Margin.mt_5]}>
                                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000]}>
                                            <ArticleList
                                                title={'Related Posts'}
                                                post={postLists.posts}
                                                getLinkClick={(slug) => this.navigateToPost(slug)}
                                                getCategoryClick={(slug) => this.navigateToCategory(slug)}
                                                getAuthorClick={(slug) => this.navigateToAuthor(slug)}
                                                blog={true}
                                                darkMode={darkMode}
                                            />
                                        </View>
                                    </View>
                                    }
                                </>
                            }

                        </View>
                    }

                </View>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        const { pageLoader, postData, showErrorModal, token, footerData, redirectSlugs, showInsufficientCreditsPopup, creditPlan, country } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[webView && null, { flex: 1, backgroundColor: 'white' }, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                <Seo data={(postData.seo_metadata) ? postData.seo_metadata : {}} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/blog/:blog_slug" pageData={postData} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.getPost()} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>
                    {pageLoader ?
                        (webView
                            ? this.showPageLoader()
                            : <ScrollView ref={ref => this.contentscrollview = ref} style={{ flex: 1 }}>{this.showPageLoader()}</ScrollView>
                        )
                        :
                        (webView
                            ? this.renderPageContent()
                            : <ScrollView ref={ref => this.appscrollview = ref} style={{ flex: 1 }}>{
                                this.renderPageContent()}
                                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
                            </ScrollView>
                        )
                    }
                </>
                }


                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(BlogSingle));

let Breadcrumbs = [];