import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, article, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageCover from '../../components/page-cover';
import AuthorItem from '../../components/author-item';
import PageBreak from '../../components/page-break';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import ContentLoader from "react-native-easy-content-loader";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";

const coverimage = getImagePath('img/writersBg.png');
const coverimageDark = getImagePath('img/writersBgDark.png');
const cookies = new Cookies();

const upArrowIcon = getImagePath('icons/uparrow.png');

const alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

export class Writers extends Component {
    constructor(props) {
        super(props);
        let writerData, contributorData;
        if (this.props.writerData) {
            // writerData = this.sortWriter(this.props.writerData.author)
            let allWriters = this.segregateWriters(this.props.writerData.author);
            writerData = allWriters['writers'];
            contributorData = allWriters['contributors'];
        }
        this.state = {
            writerData: writerData ? writerData : false,
            contributorData: contributorData ? contributorData : false,
            writerDataSSR: writerData ? true : false,
            pageLoader: writerData ? false : true,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false
        }

        this.alphFilterOffset = [];
        this.alphFilterOffsetMain = 0;
        this.alphFilterOffsetSub = 0;
        this.alphFilterOffsetTitle = 0;
    }

    componentDidMount = () => {
        if (!this.state.writerDataSSR) {
            this.getWriters();
        }
    }

    componentWillUnmount = () => {
        if (this.scrollRef) {
            this.scrollRef();
        }
    }

    getWriters = () => {
        this.setState({
            showErrorModal: false,
            pageLoader: true,
        });
        const { token } = this.state;
        let endpoint = `/authors`;
        let body = {
            sort_on: 'name',
            sort_by: 'asc'
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'author_found') {
                    // let writerData = this.sortWriter(res.data.author);
                    let allWriters = this.segregateWriters(res.data.author);
                    this.setState({
                        writerData: allWriters['writers'],
                        contributorData: allWriters['contributors'],
                        pageLoader: false
                    });
                } else {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    segregateWriters = (writerList) => {
        let writers = [], contributors = [];
        writerList.map((writer) => {
            if (parseInt(writer.story_count) >= 1) {
                if (writer.roles.includes("contributor")) {
                    contributors.push(writer);
                }
                else if (writer.roles.includes("editor")) {
                    writers.push(writer);
                }
            }
        })
        return { writers: writers, contributors: contributors };
    }

    sortWriter = (writerList) => {
        let writers = {}
        writerList.map((writer) => {
            let firstLetter = writer.name.substr(0, 1).toUpperCase();
            if (writers[firstLetter]) {
                writers[firstLetter].push(writer);
            } else {
                writers[firstLetter] = [writer];
            }
        })
        return writers;
    }

    getModalClickEvent = () => {

    }

    getScrollOffset = (event) => {
        let scrollCheck;
        if (helpers.isWeb()) {
            scrollCheck = window.pageYOffset;
        } else {
            scrollCheck = event.nativeEvent.contentOffset.y;
        }
        if (scrollCheck > 500) {
            this.setState({
                showUparrow: true,
            })
        } else {
            this.setState({
                showUparrow: false,
            })
        }
    }

    applyRef = (ref) => {
        if (helpers.isWeb()) {
            this.scrollRef = window.addEventListener('scroll', this.getScrollOffset);
        }
    }

    scrollToTop = () => {
        if (helpers.isWeb()) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(0)
        }
    }

    scrollToSection = (element) => {
        let offsetPosition = this.alphFilterOffset[element] + this.alphFilterOffsetMain + this.alphFilterOffsetSub - this.alphFilterOffsetTitle;

        if (helpers.isWeb()) {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(offsetPosition)
        }

    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Margin.mt_4, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                <PageCover
                    title={'Writers'}
                    coverImage={darkMode ? coverimageDark : coverimage}
                    require={true}
                    pageCoverOverlay={false}
                    content={`Read articles by some of the best writers in house and external.`}
                    darkMode={darkMode}
                />
            </View>
        )
    }

    viewAuthorDetails = (Slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + Slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: Slug });
        }
    }

    renderPageContent = () => {
        const { writerData, contributorData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                {/*<View ref={this.applyRef} onLayout={event => {
                        const layout = event.nativeEvent.layout;
                        this.alphFilterOffsetMain = layout.y;
                    }
                }
            >*/}
                {/*<View onLayout={event => {
                            const layout = event.nativeEvent.layout;
                            this.alphFilterOffsetTitle = layout.y;
                        }
                    }
                    style={[Padding.ph_2, Margin.mt_3, Margin.mb_4]}
                >
                    <View style={[Helpers.alphFilterWrap]}>
                        {alphabets.map((item, index) => {
                            return (
                                <TouchableOpacity
                                    onPress={() => this.scrollToSection(item)}
                                    key={`aplph_${index}`}
                                    activeOpacity={0.8}>
                                    <Text style={[Helpers.alphText]}>{item}</Text>
                                </TouchableOpacity>
                            )
                        })}                      
                    </View>
                </View>*/}
                {/*<View onLayout={event => {
                            const layout = event.nativeEvent.layout;
                            this.alphFilterOffsetSub = layout.y;
                        }
                    }
                    style={[Padding.ph_2]}
                >*/}
                <View style={[Padding.ph_2, helpers.isDesktop() && Flex.row, Flex.FlexWrap, helpers.isDesktop() && Margin.mt_3]}>
                    {/*{writerData && alphabets.map((item, index) => {
                        return (
                            <View
                                onLayout={event => {
                                        const layout = event.nativeEvent.layout;
                                        this.alphFilterOffset[item] = layout.y;
                                    }
                                }
                                key={`authorItem${index}`} style={[Margin.mb_5]}
                            >
                                <View style={[Helpers.capitalLetterWrap]}>
                                    <Text style={[Helpers.captialLetter]}>{item}</Text>
                                </View>
                                {(writerData[item] != undefined) &&
                                    <View key={`author${index}`}>
                                        {
                                            writerData[item].map((citem, index) => {
                                                return (
                                                    <AuthorItem viewAuthor={(slug) => this.viewAuthorDetails(slug)} single={index == 0 ? true : false} key={`authorItem${index}`} data={citem} require={false} />
                                                )
                                            })
                                        }
                                    </View>
                                }
                            </View>
                        )
                    })}*/}
                    {writerData && writerData.map((citem, index) => {
                        return (
                            <AuthorItem viewAuthor={(slug) => this.viewAuthorDetails(slug)} single={index == 0 ? true : false} key={`authorItem${index}`} data={citem} writersPage={true} require={false} darkMode={darkMode} />
                        )
                    })
                    }
                </View>
                <View style={[helpers.isDesktop() && Padding.ph_2, helpers.isDesktop() && Flex.row, Flex.FlexWrap, helpers.isDesktop() && Margin.mt_3]}>
                    <>
                        {/* <PageBreak style={[Margin.mb_2]} /> */}
                        <View style={[Margin.mb_5, { width: '100%' }]}>
                            {/* <Text style={[Helpers.textUppercase, Margin.mb_4, {fontFamily: FontFamily.regular, fontWeight:  FontWeight.regular, fontSize: helpers.isDesktop() ? 30 : 24, textAlign:'center'}]}> contributors </Text> */}
                            <View style={[article.headerWrapper]}>
                                <View style={[Flex.alignCenter, Margin.mb_5, !helpers.isDesktop() && Margin.mb_4]}>
                                    <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: '#121212' }]}>
                                        <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                            <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 24, fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 34 : 24, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Contributors</Text>
                                        </View>
                                    </View>
                                    <View style={[Helpers.titleLine]}></View>
                                </View>
                            </View>
                            <View style={[Flex.row, Flex.FlexWrap]}>
                                {contributorData && contributorData.map((cdata, index) => {
                                    return (
                                        <AuthorItem viewAuthor={(slug) => this.viewAuthorDetails(slug)} key={`authorItem${index}`} single={index == 0 ? true : false} data={cdata} contributor={true} about={true} showArrow={false} team={false} teamContributor={true} contributorPage={true} require={false} darkMode={darkMode} />
                                    )
                                })
                                }
                            </View>
                        </View>
                    </>
                </View>
            </View>
        )
    }

    // renderPageFooter = () => {
    //     return (
    //         <></>
    //     )
    // }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: '/writers',
            native: { redirect: '/writers', verifyRedirect: '/writers' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
        // return (
        //     <ScrollView
        //     onScroll={this.getScrollOffset}
        //     scrollEventThrottle={16}
        //     ref={ref => this.appscrollview = ref}>
        //         {this.renderPageHeader()}
        //         {this.renderPageContent()}
        //     </ScrollView>
        // )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: '/writers',
            native: { redirect: '/writers', verifyRedirect: '/writers' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                <Seo data={seoObj[`writers`]} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/writers" />

                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }
                    {showUparrow &&
                        <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                            <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                <CustomImage
                                    source={upArrowIcon}
                                    require={true}
                                    style={{ width: 60, height: 60 }}
                                    webStyle={{ width: 60, height: 60 }}
                                />
                            </TouchableOpacity>
                        </View>
                    }</>
                }



                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Writers);

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Writers'
    }
]