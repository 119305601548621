import React, { Component } from 'react';
import { Text, View, TouchableOpacity, TextInput } from 'react-native';
import { Flex, Padding, Margin, Helpers, Colors, FontFamily, FontWeight, button } from '../../styles/appStyles';
import Modal from '../modal';
import FileCard from '../../components/file-card';
import { helpers } from '../../utils/helpers';
import Button from '../../components/button';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import apiHelpers from "../../utils/api-helpers";
import moment from 'moment';


const stepOneActive = getImagePath('icons/adminStepOne.png');
const stepOneFailed = getImagePath('icons/stepOneFailed.png');
const stepTwo = getImagePath('icons/steptwo.png');
const stepTwoActive = getImagePath('icons/adminSteptwoActive.png');
const stepThree = getImagePath('icons/stepThree.png');
const stepComplete = getImagePath('icons/stepComplete.png');



class UploadAdminDocsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorText: '',
            selectedFiles: [],
            base64: '',
            btnDisabled: true,
            accepted: false,
            rejected: false,
            documentSlug: this.props.documentSlug,
            documentFiles: [],
            token: this.props.token,
            approvalStatus: '',
            approvalStatusReason: '',
            docStatus: '',
            fieldError: true,
            userData: {},
            verifiedOn: '',
            errorInvalid: false,
            erroMsg: ''
        }
    }

    componentDidMount = () => {
        this.fetchDocumentForVerification();
    }

    componentDidUpdate = () => {

    }

    componentWillUnmount() {

    }

    closeModal = () => {
        const { closeModal } = this.props;
        closeModal();
    }

    handleAcceptDocs = () => {
        this.setState({
            approvalStatus: 'approved',
            accepted: true
        });
    }

    handleRejectDocs = (reason) => {
        this.setState({
            approvalStatus: 'rejected',
            approvalStatusReason: '',
            accepted: false,
            rejected: true
        });
    }

    handleCancelApproval = () => {
        this.setState({
            approvalStatus: ''
        });
    }

    goToSignIn = () => {
        console.log('inside go to sign up');
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-in?redirect=/student-documents/' + this.state.documentSlug );
        } else {
            this.props.navigation.navigate('/sign-in', { redirect: 'student-documents', documentSlug: this.state.documentSlug, verifyRedirect: '/student-documents/' + this.state.documentSlug});
        }
    }

    fetchDocumentForVerification = () => {

        console.log('inside fetch verification');
        const { token } = this.props;
        let endpoint = `/user-documents-info`;
        let body = {
            reference_id: this.state.documentSlug
        }
        
        if(token){
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token)
                .then((res) => {
                    if (res.code == 'access_denied') {
                        this.setState({
                            errorInvalid: true,
                            erroMsg: 'You are not authorized to view this page.'
                        })
                        return false
                    }
                    if (res.success) {
                        this.setState({
                            docStatus: res.data.document_status,
                            documentFiles: res.data.documents,
                            userData: res.data,
                            verifiedOn: res.data.document_verified_on,
                            approvalStatusReason: res.data.document_reject_reason,
                            errorInvalid: false                        
                        })
                        this.props.hideLoader();
                    } else {
                        console.log('Error=>>', res);
                        this.setState({
                            errorInvalid: true,
                            erroMsg: 'Link is invalid'
                        })
                    }
                })
                .catch((error) => {
                    console.log('Error=>>', error);
                });
        }
        else{
            this.goToSignIn();
        }

    }

    approveRejectDocument = () => {
        const { token } = this.props;
        let endpoint = `/user-documents-approval`;
        let body = {
            status: this.state.approvalStatus,
            status_reason: this.state.approvalStatusReason,
            reference_id: this.state.documentSlug,
        }

        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return false;
                }
                if (res.success) {
                    this.setState({
                        docStatus: this.state.approvalStatus,
                        verifiedOn: res.data.verified_on
                    })
                } else {
                    console.log('Error=>>', res);
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
            });
    }


    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    downloadFile = (name) => {
        const { token } = this.props;
        let endpoint = `/fetch-document-admin`;
        let body = {
            file_name: name,
            reference_id: this.state.documentSlug
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return false;
                }
                if (res.success) {

                    console.log(res);
                    const blob = this.b64toBlob(res.data.file_string, res.data.type);

                    var url = URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = `${res.data.name}`;
                    a.click();

                } else {
                    console.log('Error=>>', res);
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
            });
    }


    setRejectReason = (value) => {
        value = value.trim();

        this.setState({
            approvalStatusReason: value,
            fieldError: value == ''
        });
    }

    renderTopSteps = () => {

        let { docStatus } = this.state;

        let darkMode = this.props.darkMode;
        return (
            <View>
                {
                    !helpers.isDesktop() &&
                    <View style={[Margin.mb_2, Flex.justfiyCenter]}>
                        {
                            ((!docStatus || docStatus == 'rejected')) &&
                            <Text style={{ fontSize: 16, fontWeight: '700', color: (docStatus == 'rejected' ? '#FF6B75' : '#F7931E'), textAlign: 'center', fontFamily: FontFamily.merriweather }}>Upload documents</Text>
                        }
                        {
                            (docStatus == 'submitted') &&
                            <Text style={{ fontSize: 16, fontWeight: '700', color: '#F7931E', textAlign: 'center', fontFamily: FontFamily.merriweather }}>Documents in Review</Text>
                        }
                        {
                            (docStatus == 'approved' || docStatus == 'success') &&
                            <Text style={{ fontSize: 16, fontWeight: '700', color: '#F7931E', textAlign: 'center', fontFamily: FontFamily.merriweather }}>Documents Verified</Text>
                        }
                    </View>
                }

                <View style={[Flex.row, Flex.alignCenter, { justifyContent: helpers.isDesktop() ? 'flex-start' : 'center' }]}>
                    <View style={{ marginRight: 10 }}>
                        <CustomImage
                            require={true}
                            source={(!docStatus || (docStatus == 'rejected')) ? ((docStatus == 'rejected') ? stepOneFailed : stepOneActive) : stepComplete}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                            webStyle={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </View>

                    {
                        helpers.isDesktop() &&
                        <Text style={{ fontSize: 14, fontWeight: (docStatus == 'rejected') ? '700' : '400', fontFamily: FontFamily.merriweather, color: (!docStatus || (docStatus == 'rejected')) ? ((docStatus == 'rejected') ? '#FF6B75' : '#F7931E') : darkMode ? Colors.lightWhite : '#000000' }}>Upload documents</Text>
                    }

                    <View style={{ width: 40, backgroundColor: '#BABABA', height: 1, marginLeft: 5, marginRight: 5 }}></View>

                    <View style={{ marginRight: 10 }}>
                        <CustomImage
                            require={true}
                            source={(docStatus == 'submitted' || docStatus == 'success' || docStatus == 'approved') ? (docStatus == 'submitted' ? stepTwoActive : stepComplete) : stepTwo}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                            webStyle={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </View>

                    {helpers.isDesktop() &&
                        <Text style={{ fontSize: 14, fontFamily: FontFamily.merriweather, color: (docStatus == 'submitted' || docStatus == 'approved' || docStatus == 'success') ? (docStatus == 'submitted' ? '#F7931E' : darkMode ? Colors.lightWhite : '#000000') : '#9E9E9E' }}>Documents in Review</Text>
                    }

                    <View style={{ width: 40, backgroundColor: '#BABABA', height: 1, marginLeft: 5, marginRight: 5 }}></View>

                    <View style={{ marginRight: 10 }}>
                        <CustomImage
                            require={true}
                            source={(docStatus == 'approved' || docStatus == 'success') ? stepComplete : stepThree}
                            style={{
                                width: 20,
                                height: 20,
                            }}
                            webStyle={{
                                width: 20,
                                height: 20,
                            }}
                        />
                    </View>

                    {helpers.isDesktop() &&
                        <Text style={{ fontSize: 14, fontFamily: FontFamily.merriweather, color: (docStatus == 'approved' || docStatus == 'success') ? (darkMode ? Colors.lightWhite : '#000000') : '#9E9E9E' }}>Documents Verified</Text>
                    }
                </View>
            </View>
        )
    }

    removeCard = (name) => {
        let { selectedFiles, btnDisabled } = this.state;
        let tempFiles = selectedFiles;
        tempFiles = tempFiles.filter((file) => {
            if (file.detail.name !== name) {
                return file
            }
        })

        this.setState({
            selectedFiles: tempFiles,
            errorText: '',
            btnDisabled: tempFiles.length == 0
        });
    }

    renderDocPending = () => {
        let { userData } = this.state;
        let darkMode = this.props.darkMode;
        return (
            <View style={[Padding.pt_4, Padding.pb_4]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>Documents pending <Text style={{ color: '#907CFF', fontSize: 12, fontFamily: FontFamily.regular, fontWeight: '600' }}>(ADMIN VIEW)</Text></Text>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>User still needs to upload their documents for verification. Please check this page again after a while.</Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 30 }]}>User needs to upload the below document as proof (any one).</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : '#000000', lineHeight: 30 }}>ID proof or Admission letter</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold, marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userData.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userData.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweather, fontWeight: '700' }}>{moment(userData.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>

                <View style={{ borderWidth: 2, borderColor: '#C4C4C4', backgroundColor: 'rgba(196, 196, 196, 0.1)', paddingHorizontal: 15, paddingVertical: 40 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.75)', fontFamily: FontFamily.merriweather, textAlign: 'center' }}>User is still yet to upload their documents</Text>
                </View>

            </View>
        )
    }

    renderDocsInReview = () => {
        let { documentFiles, approvalStatus, userData, fieldError, verifiedOn } = this.state;
        let darkMode = this.props.darkMode;

        return (
            <View style={[Padding.pt_4, Padding.pb_4]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>Documents in review <Text style={{ color: '#907CFF', fontSize: 12, fontFamily: FontFamily.regular, fontWeight: '600' }}>(ADMIN VIEW)</Text></Text>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 22 }}>Please review the student proof submitted by the user. You can either accept or deny the proof in the admin panel at the bottom</Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 30 }]}>Documents submitted for verification on {moment(userData.document_uploaded_on).format('DD MMM YYYY')}</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold, marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userData.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userData.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweather, fontWeight: '700' }}>{moment(userData.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>
                <View style={[Margin.mb_4]}>
                    {
                        documentFiles.length > 0 &&
                        <View style={[Margin.mt_3]}>
                            {documentFiles.map((file) => {
                                return (
                                    <FileCard name={file.name} removeCard={(name) => { this.removeCard(name) }} darkMode={darkMode} downloadFile={(name) => { this.downloadFile(name) }} showDownload={true} />
                                )
                            })}
                        </View>
                    }
                </View>
                <View style={{ borderWidth: 2, borderColor: '#C4C4C4', borderRadius: 4 }}>

                    <View style={{ backgroundColor: 'rgba(144, 124, 255, 0.05)', paddingHorizontal: 30, paddingVertical: 10 }}>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, fontWeight: '700', color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.7)' }}>Admin panel</Text>
                    </View>
                    {(approvalStatus == '') &&
                        <View style={{ paddingHorizontal: 30, paddingVertical: 20 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.7)', fontFamily: FontFamily.merriweather, marginBottom: 20 }}>Is the above student proof provided valid?</Text>
                            <View style={[Flex.row]}>
                                <Button
                                    title={'ACCEPT'}
                                    rootStyle={{
                                        btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: '#55C79A', paddingHorizontal: 15, paddingVertical: 6, height: 35, borderRadius: 2, width: helpers.isDesktop() ? 150 : 100, marginRight: helpers.isDesktop() ? 25 : 10 }],
                                        btnText: [{ fontWeight: '600', fontSize: 14, color: '#ffffff', lineHeight: 14 }],
                                    }}
                                    onClick={() => { this.handleAcceptDocs() }}
                                />
                                <Button
                                    title={'REJECT'}
                                    rootStyle={{
                                        btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: '#FF6B75', paddingHorizontal: 15, paddingVertical: 6, height: 35, borderRadius: 2, width: helpers.isDesktop() ? 150 : 100 }],
                                        btnText: [{ fontWeight: '600', fontSize: 14, color: '#ffffff', lineHeight: 14 }],
                                    }}
                                    onClick={() => { this.handleRejectDocs() }}
                                />
                            </View>
                            <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 30, marginBottom: 30, marginHorizontal: -30 }}></View>
                            <View>
                                <Text style={{ fontSize: 13, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.7)', marginBottom: 5 }}><Text style={{ fontWeight: FontWeight.bold, color: '#55C79A' }}>Accept</Text> - You will validate this user as a student and will get full access to TMC </Text>
                                <Text style={{ fontSize: 13, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.7)' }}><Text style={{ fontWeight: FontWeight.bold, color: '#FF6B75' }}>Reject</Text> - Proof is incorrect and user will be requested to re-upload the documents again</Text>
                            </View>
                        </View>
                    }
                    {approvalStatus == 'approved' &&
                        <View style={{ paddingHorizontal: 30, paddingVertical: 30 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, fontFamily: FontFamily.merriweather, fontWeight: FontWeight.bold, color: '#55C79A', marginBottom: 10 }}>Accept proof?</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 10, lineHeight: helpers.isDesktop() ? 29 : 22 }}>Are you sure you want to accept the proof submitted by {userData.name}? By doing so {userData.name} will verified as a student.</Text>
                            <View style={[Flex.row, { justifyContent: helpers.isDesktop() ? 'flex-end' : 'flex-start' }]}>
                                <Button
                                    title={'CANCEL'}
                                    rootStyle={{
                                        btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: 'transparent', paddingHorizontal: 15, paddingVertical: 6, height: 35, borderRadius: 2, width: helpers.isDesktop() ? 150 : 100, marginRight: helpers.isDesktop() ? 25 : 10 }],
                                        btnText: [{ fontWeight: '600', fontSize: 14, color: '#A97DF1', lineHeight: 14 }],
                                    }}
                                    onClick={() => { this.handleCancelApproval() }}
                                />
                                <Button
                                    title={'ACCEPT'}
                                    rootStyle={{
                                        btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: '#55C79A', paddingHorizontal: 15, paddingVertical: 6, height: 35, borderRadius: 2, width: helpers.isDesktop() ? 150 : 100, marginRight: 0 }],
                                        btnText: [{ fontWeight: '600', fontSize: 14, color: '#ffffff', lineHeight: 14 }],
                                    }}
                                    onClick={() => { this.approveRejectDocument() }}
                                />
                            </View>
                        </View>
                    }
                    {approvalStatus == 'rejected' &&
                        <View style={{ paddingHorizontal: 30, paddingVertical: 30 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, fontFamily: FontFamily.merriweather, fontWeight: FontWeight.bold, color: '#FF6B75', marginBottom: 10 }}>Reject proof?</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 10, lineHeight: helpers.isDesktop() ? 29 : 22 }}>Are you sure you want to reject the proof submitted by {userData.name}? If yes, enter the reason for rejection below</Text>
                            <View style={[Margin.mt_2, Margin.mb_3]}>
                                <TextInput
                                    placeholder={'Enter rejection reason'}
                                    placeholderTextColor={darkMode ? Colors.lightWhite : Colors.placeholderTextColor}
                                    style={[{ fontSize: helpers.isDesktop() ? 16 : 16, paddingVertical: 8, borderWidth: 2, color: darkMode ? Colors.lightWhite : '#000', borderColor: '#C4C4C4', borderRadius: 4, height: 85, paddingHorizontal: 15 }]}
                                    onChangeText={(value) => { this.setRejectReason(value) }}
                                />
                            </View>
                            <View style={[Flex.row, { justifyContent: helpers.isDesktop() ? 'flex-end' : 'flex-start' }]}>
                                <Button
                                    title={'CANCEL'}
                                    rootStyle={{
                                        btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: 'transparent', paddingHorizontal: 15, paddingVertical: 6, height: 35, borderRadius: 2, width: helpers.isDesktop() ? 150 : 100, marginRight: helpers.isDesktop() ? 25 : 10 }],
                                        btnText: [{ fontWeight: '600', fontSize: 14, color: '#A97DF1', lineHeight: 14 }],
                                    }}
                                    onClick={() => { this.handleCancelApproval() }}
                                />
                                <Button
                                    title={'REJECT'}
                                    rootStyle={{
                                        btnWrapper: [Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: fieldError ? '#EAEAEA' : '#FF6B75', paddingHorizontal: 15, paddingVertical: 6, height: 35, borderRadius: 2, width: helpers.isDesktop() ? 150 : 100, marginRight: 0 }, fieldError && { opacity: 0.8 }],
                                        btnText: [{ fontWeight: '600', fontSize: 14, color: '#ffffff', lineHeight: 14 },],
                                    }}
                                    onClick={() => { fieldError ? {} : this.approveRejectDocument() }}
                                    disable={fieldError}
                                    smallText={true}
                                />
                            </View>
                        </View>
                    }
                </View>
            </View >
        )
    }


    renderDocsVerified = () => {
        let { errorText, documentFiles, userData, verifiedOn } = this.state;
        let darkMode = this.props.darkMode;

        return (
            <View style={[Padding.pt_4, Padding.pb_4]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>User verified <Text style={{ color: '#907CFF', fontSize: 12, fontFamily: FontFamily.regular, fontWeight: '600' }}>(ADMIN VIEW)</Text> </Text>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 22 }}>You have verified the user’s documents and have activated their account.</Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: 30 }]}>Documents verified on {moment(verifiedOn).format('DD MMM YYYY')}</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold,  marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userData.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userData.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweather, fontWeight: '700' }}>{moment(userData.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Valid till: <Text style={{ fontFamily: FontFamily.merriweather, fontWeight: '700' }}>{moment(userData.subscription.end_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>
                <View style={[Margin.mb_4]}>
                    {
                        documentFiles.length > 0 &&
                        <View style={[Margin.mt_3]}>
                            {documentFiles.map((file) => {
                                return (
                                    <FileCard name={file.name} removeCard={(name) => { this.removeCard(name) }} darkMode={darkMode} downloadFile={(name) => { this.downloadFile(name) }} showDownload={true} />
                                )
                            })}
                        </View>
                    }
                </View>
            </View>
        )
    }

    renderdocumentsRejected = () => {
        let { errorText, approvalStatusReason, userData, verifiedOn } = this.state;
        let darkMode = this.props.darkMode;

        return (
            <View style={[Padding.pt_4, Padding.pb_4]}>
                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)' }]}>Documents rejected <Text style={{ color: '#907CFF', fontSize: 12, fontFamily: FontFamily.regular, fontWeight: '600' }}>(ADMIN VIEW)</Text></Text>
                <View style={{ borderWidth: 1, borderColor: '#FF6B75', borderRadius: 4, paddingHorizontal: 20, paddingVertical: 15, marginBottom: 40, marginTop: 20 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', marginBottom: 8 }}>Documents were rejected on {moment(verifiedOn).format('DD MMM YYYY')}</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : '#000000' }}>Reason: {approvalStatusReason}</Text>
                </View>
                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>User will have to re-upload their documents as it was rejected.</Text>
                <View style={{ paddingLeft: 15, borderLeftWidth: 7, borderLeftColor: '#dab650', marginTop: 40 }}>
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', lineHeight: helpers.isDesktop() ? 30 : 22 }]}>User needs to upload the below document as proof (any one).</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : '#000000', lineHeight: helpers.isDesktop() ? 30 : 22 }}>ID proof or Admission letter</Text>
                </View>

                <View style={{ height: 1, backgroundColor: '#EAEBEF', marginTop: 40, marginBottom: 40 }}></View>

                <View style={{ marginBottom: 40 }}>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: '#979797', fontFamily: FontFamily.bold,  marginBottom: 10 }}>Account details</Text>
                    </View>
                    <View style={[{ marginBottom: 10 }, helpers.isDesktop() && { flexDirection: 'row' }]}>
                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Name: <Text style={{ fontFamily: FontFamily.merriweatherB, }}>{userData.name}</Text></Text>
                        </View>
                        <View style={{ marginTop: helpers.isDesktop() ? 0 : 10 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>{helpers.isDesktop() && <Text> • </Text>}Email: <Text style={{ fontFamily: FontFamily.merriweatherB }} >{userData.email}</Text></Text>
                        </View>
                    </View>
                    <View>
                        <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, color: darkMode ? Colors.lightWhite : '#2D2D2D', fontFamily: FontFamily.merriweather }}>Subscription date: <Text style={{ fontFamily: FontFamily.merriweather, fontWeight: '700' }}>{moment(userData.subscription.start_date).format('Do MMMM YYYY')}</Text></Text>
                    </View>
                </View>

                <View style={{ borderWidth: 2, borderColor: '#C4C4C4', backgroundColor: 'rgba(196, 196, 196, 0.1)', paddingHorizontal: 15, paddingVertical: 40 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.75)', fontFamily: FontFamily.merriweather, textAlign: 'center' }}>User is still yet to upload their documents</Text>
                </View>
            </View>
        )
    }

    renderSubscriptionModalContent = () => {

        let { userData, docStatus, errorInvalid, erroMsg } = this.state;

        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        return (
            <>
                <View style={{ padding: 25 }}>
                    {
                        errorInvalid ?
                            <>
                                <Text style={[Margin.mb_1, { fontSize: 26, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)', textAlign: 'center' }]}>Error</Text>
                                < View style={{ borderWidth: 1, borderColor: '#FF6B75', borderRadius: 4, paddingHorizontal: 20, paddingVertical: 15, marginBottom: 0, marginTop: 20 }}>
                                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.merriweather, color: '#FF6B75', textAlign: 'center' }}>{erroMsg}</Text>
                                </View>
                            </>
                            :
                            (
                                <>
                                    {this.renderTopSteps()}
                                    {docStatus == false && userData && this.renderDocPending()}
                                    {docStatus == 'rejected' && this.renderdocumentsRejected()}
                                    {(docStatus == 'approved' || docStatus == 'success') && this.renderDocsVerified()}
                                    {docStatus == 'submitted' && this.renderDocsInReview()}
                                </>
                            )
                    }
                </View>
            </>
        )
    }

    render() {
        let { userData, errorInvalid } = this.state;

        return (
            <>
                {(Object.keys(userData).length > 0 || errorInvalid) &&
                    <View style={{ zIndex: 9999 }}>
                        <Modal renderContent={() => this.renderSubscriptionModalContent()} closeModal={() => this.closeModal()} paddingHorizontal={true} topPadding={false} verifyModal={true} subscription={true} />
                    </View>
                }
            </>
        );
    }
}

export default UploadAdminDocsModal;