import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, postSingle, FontFamily, FontWeight } from '../../styles/appStyles';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import Button from '../button';

const closeIcon = getImagePath('icons/grey-close-in.png');
const homescreenIcon = getImagePath('icons/homescreen-icon.png');

export default class AddToHomescreenIos extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        
        return (
            <>
                <View style={[Padding.pv_2, Padding.ph_2, { position: helpers.isWeb() ? 'fixed' : 'absolute', left: 0, right: 0, bottom: 0, zIndex: 2, marginHorizontal: 'auto', width: 'auto', backgroundColor: 'white' }]}>
                    <View style={[Flex.row, Flex.alignCenter, Flex.justifyBetween,{marginBottom: 10}]}>
                        <Text style={{fontFamily: FontFamily.abrilFatface, fontWeight: '600', fontSize: 14, letterSpacing: 0.3, color: '#000'}}>Add TMC to Home Screen</Text>    
                        <TouchableOpacity onPress={() => this.props.closeAddToHomeScreen()}>
                            <CustomImage
                                source={closeIcon}
                                style={{width: 18, height: 18, resizeMode: 'contain' }}
                                webStyle={{ width: 18, height: 18, objectFit: 'contain' }}
                                require={true} 
                            />
                        </TouchableOpacity>
                    </View>
                    <View>
                        <Text style={{fontFamily: FontFamily.regular, fontWeight: '400', fontSize: 12, color: '#343434', letterSpacing: 0.1, marginBottom: 6, lineHeight: 18}}>Get quick and easy access when you are on the go</Text>
                        <View style={[Flex.row, Flex.FlexWrap, Flex.alignCenter]}>
                            <Text style={{fontFamily: FontFamily.regular, fontWeight: '400', fontSize: 12, letterSpacing: 0.5, color: '#343434'}}>Just tap</Text>
                            <TouchableOpacity>
                                <CustomImage
                                    source={homescreenIcon}
                                    style={{width: 18, height: 18, resizeMode: 'contain' }}
                                    webStyle={{ width: 18, height: 18, objectFit: 'contain' }}
                                    require={true} 
                                />
                            </TouchableOpacity>
                            <Text style={{fontFamily: FontFamily.regular, fontWeight: '400', fontSize: 12, letterSpacing: 0.5, color: '#343434'}}>then “Add to Home Screen”</Text>
                        </View>
                    </View>
                </View>
            </>
        );
    }
}