import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import ArticleItemHorizontal from "../article-item-horizontal";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import TmcAppSlide from "../tmc-app-slide";
import TmcDialogue from "../tmc-dialogue";
import Swiper from "react-native-web-swiper";
import PremiumNewsletters from "../premium-newsletters";

const viewMore = getImagePath("icons/view-more.png");
const exclusivePlusicon = getImagePath("icons/exclusivePlusicon.png");

export class HomeExclusiveContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  viewMoreArticles = () => {
    const { viewAllArticles } = this.props;
    viewAllArticles();
  };

  viewArticle = (catSlug, slug, postData) => {
    const { viewArticle } = this.props;
    viewArticle(catSlug, slug, postData);
  };

  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };
  viewCategory = (slug) => {
    const { viewCategory } = this.props;
    viewCategory(slug);
  };

  handleClick = (title, data, index) => {
    try {
      if (this.props.trackStoryClick) {
        this.props.trackStoryClick(title, data, index);
      }
    } catch (error) {
      console.log("check mix panel track error ==>", error);
    }
  };

  navigateToNewsletter = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/newsletters/" + slug);
    } else {
      this.props.navigateApp("single-newsletter", { newsletterSlug: slug });
    }
  };

  trackCateoryClick = (item) => {};

  render() {
    const { data, darkMode, newsletterData, enjoyText } = this.props;
    let btnClickCategory;
    return (
      <View style={[!darkMode && { backgroundColor: "#F3F5FF" }, { flex: 1 }]}>
        <View
          style={[
            Margin.mb_2,
            !helpers.isDesktop() && Margin.mb_0,
            { marginTop: 50 },
          ]}
        >
          <View style={([Padding.ph_2], !darkMode && Helpers.titleBgWrap)}>
            {enjoyText && (
              <View
                style={
                  (helpers.isDesktop && Helpers.borderBottom,
                  darkMode && { borderBottomColor: "rgba(255, 255, 255, 0.6)" })
                }
              >
                <Text
                  accessibilityRole="header"
                  aria-level="2"
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    {
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 24 : 18,
                      lineHeight: helpers.isDesktop() ? 27 : 22,
                      color: "#000000",
                      marginBottom: helpers.isDesktop() ? 24 : 0,
                      letterSpacing: 0.3,
                    },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                  ]}
                >
                  {enjoyText}
                </Text>
              </View>
            )}
            <View
              style={
                (helpers.isDesktop && Helpers.borderBottom,
                darkMode && { borderBottomColor: "rgba(255, 255, 255, 0.6)" })
              }
            >
              <Text
                accessibilityRole="header"
                aria-level="2"
                style={[
                  Helpers.textCenter,
                  Typo.h3_R,
                  helpers.isDesktop() && Typo.h3_RDesktop,
                  Margin.mb_0,
                  {
                    fontSize: helpers.isDesktop() ? 34 : 32,
                    fontWeight: "400",
                    lineHeight: 51,
                  },
                  {
                    fontFamily: FontFamily.abrilFatface,
                    color: !helpers.isDesktop() ? "#051462" : "#000",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {this.props.title
                  ? this.props.title
                  : "Subscribe and get exclusive benefits"}
              </Text>
              {helpers.isDesktop() && !enjoyText && (
                <View
                  style={{
                    position: "relative",
                    height: 2,
                    width: "40%",
                    borderTopWidth: 2,
                    borderTopColor: "#000000",
                    marginTop: 0,
                    alignSelf: "center",
                  }}
                ></View>
              )}
            </View>
          </View>
          {/* {helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
        </View>
        {helpers.isDesktop() ? (
          <Swiper
            loop // to scroll first item after last
            timeout={3} // auto scroll time
            controlsProps={{
              dotsPos: "top",
              nextTitle: "",
              prevTitle: "",
              dotActiveStyle: { backgroundColor: "#907CFF" },
              dotsTouchable: true,

              // DotComponent: ({ index, isActive, onPress}) => <Text onPress={onPress}>{index+1}</Text>
            }}
            containerStyle={{}}
            controlsEnabled={true}
            minDistanceForAction={0.15}
            springConfig={{ speed: 100 }}
            // scrollEventThrottle={200}
            // decelerationRate="fast"
            index={1}
          >
            <View style={{ alignItems: "center" }}>
              {/* <View style={[Flex.alignCenter, Margin.mb_2, !helpers.isDesktop() && Margin.mb_0, helpers.isDesktop() && { marginTop: 90 }]}>
                    <View style={[Padding.ph_2, Helpers.titleBgWrap]}>
                        <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: 'rgba(255, 255, 255, 0.6)' }]}>
                            <Text accessibilityRole="header" aria-level="2" style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 24 }, { fontFamily: FontFamily.abrilFatface, color: '#000' }, { fontSize: helpers.isDesktop() ? 34 : 20 }, darkMode && { color: Colors.lightWhite }]}>Subscribe and get exclusive benefits</Text>
                        </View>
                    </View>
                    <View style={[Helpers.titleLine]}></View>
                </View> */}

              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                scrollEventThrottle={200}
                decelerationRate="fast"
              >
                <View
                  style={[
                    Helpers.conatinerWeb,
                    helpers.isDesktop() && Helpers.conatinerWebFull,
                    helpers.isDesktop() && { marginTop: 50 },
                    helpers.isDesktop() && { marginBottom: 90 },
                  ]}
                >
                  <View
                    style={[
                      article.headerWrapper,
                      helpers.isDesktop() && Padding.ph_05,
                    ]}
                  >
                    <View
                      style={[
                        !helpers.isDesktop() && Padding.pt_4,
                        !helpers.isDesktop() && { paddingBottom: 15 },
                      ]}
                    >
                      <Text
                        accessibilityRole="header"
                        aria-level="2"
                        style={[
                          Helpers.textCenter,
                          Typo.h3_R,
                          {
                            fontFamily: FontFamily.regular,
                            fontSize: helpers.isDesktop() ? 30 : 20,
                            lineHeight: helpers.isDesktop() ? 27 : 22,
                            color: "#000000",
                            marginBottom: helpers.isDesktop() ? 24 : 0,
                            letterSpacing: 0.3,
                          },
                          darkMode && { color: "rgba(255,255,255,0.87)" },
                        ]}
                      >
                        Get access to exclusive stories
                      </Text>
                    </View>
                    <View
                      style={[
                        !helpers.isDesktop() && Padding.pt_4,
                        !helpers.isDesktop() && { paddingBottom: 15 },
                        { alignItems: "center" },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            width: "50%",
                            fontFamily: FontFamily.regular,
                            fontSize: helpers.isDesktop() ? 18 : 14,
                            lineHeight: helpers.isDesktop() ? 27 : 22,
                            color: "#000000",
                            textAlign: "center",
                            marginBottom: helpers.isDesktop() ? 24 : 0,
                            letterSpacing: 0.3,
                          },
                          darkMode && { color: "rgba(255,255,255,0.87)" },
                          { marginTop: helpers.isDesktop() ? -5 : 0 },
                        ]}
                      >
                        Subscribe and get full access to all stories across our
                        three pillars: Business, Internet and Chaos.
                      </Text>
                    </View>
                  </View>
                  <View style={[Margin.mt_3, Margin.mb_3, Padding.ph_2]}>
                    <View
                      style={[Flex.row, Flex.justifyBetween, Flex.alignStart]}
                    >
                      {data &&
                        data.map((item, index) => {
                          if (helpers.isWeb()) {
                            btnClickCategory = {
                              to: "/category/" + item.category.slug,
                            };
                          } else {
                            btnClickCategory = {
                              onPress: () =>
                                this.viewCategory(item.category.slug),
                            };
                          }
                          return (
                            <View
                              style={[
                                articleItem.col4,
                                helpers.isDesktop() && articleItem.col4Desktop,
                                { marginRight: 25 },
                                index == 1 && {
                                  width: helpers.isDesktop() ? "27%" : "100%",
                                },
                                !(index == 1) && {
                                  width: helpers.isDesktop() ? "27%" : "100%",
                                  marginTop: 0,
                                },
                                index == 2 && { marginRight: 0 },
                              ]}
                            >
                              <TouchableOpacityLink
                                {...btnClickCategory}
                                handleClick={() => this.trackCateoryClick(item)}
                              >
                                <View>
                                  <Text
                                    style={[
                                      articleLists.badge,
                                      { textAlign: "left" },
                                      {
                                        marginBottom: helpers.isDesktop()
                                          ? 0
                                          : 0,
                                        paddingVertical: 5,
                                        fontFamily: helpers.isWeb()
                                          ? FontFamily.regular
                                          : FontFamily.semiBold,
                                        color: darkMode
                                          ? "rgba(255,255,255,0.87)"
                                          : "#2CB680",
                                      },
                                      index === 0
                                        ? {
                                            color: darkMode
                                              ? "white"
                                              : "#FC876D",
                                          }
                                        : index === 1
                                        ? {
                                            color: darkMode
                                              ? "white"
                                              : "#2CB680",
                                          }
                                        : {
                                            color: darkMode
                                              ? "white"
                                              : "#907CFF",
                                          },
                                    ]}
                                  >
                                    {item.category.name}
                                  </Text>
                                  {/* <Text style={[articleLists.badge, { width: 150, marginBottom: 10, textAlign: 'left', fontFamily: FontFamily.regular, color: '#907cff' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }]}>{item.category.name}</Text> */}
                                </View>
                              </TouchableOpacityLink>
                              <ArticleItemHorizontal
                                slider={true}
                                viewArticle={(catSlug, slug, postData) =>
                                  this.viewArticle(catSlug, slug, postData)
                                }
                                viewAuthor={(slug) => this.viewAuthor(slug)}
                                data={item}
                                single={index == 0 ? true : false}
                                elmsecond={index == 1 ? true : false}
                                exclusiveHome={true}
                                handleClick={(title) => {
                                  this.handleClick(title, item, index);
                                }}
                                hideDate={false}
                                darkMode={darkMode}
                                isRound={true}
                                sectionName={enjoyText}
                                token={this.props.token}
                              />
                            </View>
                          );
                        })}
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
            <TmcDialogue darkMode={darkMode} />
            <PremiumNewsletters
              logodata={newsletterData}
              categorySlug={"newsletter"}
              navigateToNewsletter={(value) => this.navigateToNewsletter(value)}
              darkMode={darkMode}
            />
            <TmcAppSlide darkMode={darkMode} />
          </Swiper>
        ) : (
          <View
          // style={{ flex: 1 }}
          // showsVerticalScrollIndicator={false}
          // scrollEventThrottle={200}
          // decelerationRate="fast"
          >
            <View
              style={[
                helpers.isDesktop() && Helpers.conatinerWeb,
                !helpers.isDesktop() && {
                  maxWidth: Dimensions.get("screen").width,
                },
                helpers.isDesktop() && Helpers.conatinerWebFull,
                helpers.isDesktop() && { marginTop: 90 },
                helpers.isDesktop() && { marginBottom: 90 },
              ]}
            >
              <View
                style={[
                  article.headerWrapper,
                  helpers.isDesktop() && Padding.ph_2,
                ]}
              >
                <View
                  style={[
                    !helpers.isDesktop() && Padding.pt_4,
                    !helpers.isDesktop() && { paddingBottom: 15 },
                  ]}
                >
                  <Text
                    accessibilityRole="header"
                    aria-level="2"
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      {
                        fontFamily: FontFamily.regular,
                        fontWeight: "500",
                        fontSize: helpers.isDesktop() ? 30 : 24,
                        lineHeight: helpers.isDesktop() ? 27 : 38.4,
                        color: "#000000",
                        marginBottom: helpers.isDesktop() ? 24 : 0,
                        letterSpacing: 0.3,
                      },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    Get access to exclusive stories
                  </Text>
                </View>
                <View
                  style={[
                    !helpers.isDesktop() && Padding.pt_1,
                    !helpers.isDesktop() && { paddingBottom: 15 },
                    { alignItems: "center" },
                  ]}
                >
                  <Text
                    numberOfLines={0}
                    style={[
                      {
                        width: helpers.isDesktop() ? "50%" : "90%",
                        fontFamily: FontFamily.regular,
                        fontWeight: "500",
                        fontSize: helpers.isDesktop() ? 18 : 18,
                        lineHeight: helpers.isDesktop() ? 27 : 22,
                        color: "#000000",
                        textAlign: "center",
                        marginBottom: helpers.isDesktop() ? 24 : 0,
                        letterSpacing: 0.3,
                      },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    Subscribe and get full access to all stories across our
                    three pillars: Business, Internet and Chaos.
                  </Text>
                </View>
              </View>
              <View style={[Margin.mt_3, Margin.mb_3, { paddingLeft: 20 }]}>
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  horizontal={true}
                  contentContainerStyle={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  {data &&
                    data.map((item, index) => {
                      if (helpers.isWeb()) {
                        btnClickCategory = {
                          to: "/category/" + item.category.slug,
                        };
                      } else {
                        btnClickCategory = {
                          onPress: () => this.viewCategory(item.category.slug),
                        };
                      }
                      return (
                        <View
                          style={[
                            { width: Dimensions.get("window").width - 70 },
                            index != 0 && { marginLeft: 20 },
                          ]}
                        >
                          <TouchableOpacityLink
                            {...btnClickCategory}
                            handleClick={() => this.trackCateoryClick(item)}
                          >
                            <View>
                              <Text
                                style={[
                                  articleLists.badge,
                                  { textAlign: "left" },
                                  {
                                    marginBottom: helpers.isDesktop() ? 15 : 0,
                                    paddingVertical: 10,
                                    fontFamily: helpers.isWeb()
                                      ? FontFamily.regular
                                      : FontFamily.semiBold,
                                    color: darkMode
                                      ? "rgba(255,255,255,0.87)"
                                      : "#2CB680",
                                  },
                                  index === 0
                                    ? { color: darkMode ? "white" : "#FC876D" }
                                    : index === 1
                                    ? { color: darkMode ? "white" : "#2CB680" }
                                    : { color: darkMode ? "white" : "#907CFF" },
                                ]}
                              >
                                {item.category.name}
                              </Text>
                            </View>
                          </TouchableOpacityLink>
                          <ArticleItemHorizontal
                            slider={true}
                            viewArticle={(catSlug, slug, postData) =>
                              this.viewArticle(catSlug, slug, postData)
                            }
                            viewAuthor={(slug) => this.viewAuthor(slug)}
                            data={item}
                            single={index == 0 ? true : false}
                            homepage={true}
                            handleClick={(title) => {
                              this.handleClick(title, item, index);
                            }}
                            hideDate={false}
                            darkMode={darkMode}
                            sectionName={enjoyText}
                            token={this.props.token}
                          />
                          {/* <View style={{ position: 'absolute', top: helpers.isDesktop() ? 10 : 40, right: 0 }}>
                                                    <Image style={{ width: 85, height: 25 }} source={require("../../../../assets/img/premium-tag.png")} />
                                                </View> */}
                        </View>
                      );
                    })}
                </ScrollView>
                <View style={{ alignSelf: "center" }}>
                  <CustomImage
                    source={exclusivePlusicon}
                    require={true}
                    style={{
                      width: 40,
                      height: 40,
                      resizeMode: "contain",
                      marginRight: 12,
                      alignSelf: "center",
                    }}
                    webStyle={{
                      width: 40,
                      height: 40,
                      objectFit: "contain",
                      marginRight: 0,
                      minWidth: 30,
                      marginLeft: 12,
                      alignSelf: "center",
                    }}
                  />
                </View>
                <TmcDialogue darkMode={darkMode} />
                <View style={{ alignSelf: "center", marginBottom: 20 }}>
                  <CustomImage
                    source={exclusivePlusicon}
                    require={true}
                    style={{
                      width: 40,
                      height: 40,
                      resizeMode: "contain",
                      marginRight: 12,
                      alignSelf: "center",
                    }}
                    webStyle={{
                      width: 40,
                      height: 40,
                      objectFit: "contain",
                      marginRight: 0,
                      minWidth: 30,
                      marginLeft: 12,
                      alignSelf: "center",
                    }}
                  />
                </View>
                <PremiumNewsletters
                  logodata={newsletterData}
                  categorySlug={"newsletter"}
                  navigateToNewsletter={(value) =>
                    this.navigateToNewsletter(value)
                  }
                  isRounded={true}
                  darkMode={darkMode}
                />
                <View style={{ alignSelf: "center", marginBottom: 20 }}>
                  <CustomImage
                    source={exclusivePlusicon}
                    require={true}
                    style={{
                      width: 40,
                      height: 40,
                      resizeMode: "contain",
                      marginRight: 12,
                      alignSelf: "center",
                    }}
                    webStyle={{
                      width: 40,
                      height: 40,
                      objectFit: "contain",
                      marginRight: 0,
                      minWidth: 30,
                      marginLeft: 12,
                      alignSelf: "center",
                    }}
                  />
                </View>
                <TmcAppSlide darkMode={this.props.darkMode} />
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default HomeExclusiveContent;
