import { View, StyleSheet, TextInput } from "react-native";
import countryList from "country-list";

import React, { useState } from "react";
import DropDownPicker from "react-native-dropdown-picker";

const countryData = countryList.getData();

const countryOptions = countryData.map((country) => ({
  label: country.name,
  value: country.code,
}));

const sortedCountryOptions = countryOptions.sort((a, b) =>
  a.label > b.label ? 1 : -1
);

const styles = StyleSheet.create({
  formContainer: {
    margin: 20,
  },
  formInput: {
    borderWidth: 1,
    borderColor: "gray",
    padding: 10,
    marginVertical: 10,
  },
  dropdown: {
    fontSize: "16px",
    fontFamily: "Matteo-Regular",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  input: {
    fontSize: "16px",
    lineHeight: "32px",
    fontFamily: "Matteo-Regular",
    color: "gray",
    height: 32,
    outLine: 'none',
  },
  inputSearchStyle: {
    height: 30,
    fontSize: 16,
    fontFamily: "Matteo-Regular",
    color: "#bbbbbb",
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 5,
    borderBottomColor: "#bbbbbb",
  },
  selectedTextStyle: {
    fontSize: 16,
    fontFamily: "Matteo-Regular",
  },
  iconSet: {
    display: "none",
  },
  itemContainerStyle: {
    marginLeft: "-15px",
  },
  "itemContainerStyle:last-child": {
    marginLeft: "-15px",
  },
});

const CustomCountryPicker = ({ field, selectedValue, onChange }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(selectedValue);
  const [items, setItems] = useState(sortedCountryOptions);

  return (
    <View
      style={{
        flex: 1,
        borderBottom: "1px solid rgb(187, 187, 187)",
        position: "relative",
        zIndex: 3,
      }}
    >
      <DropDownPicker
        {...field}
        value={value}
        open={open}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        items={items}
        placeholder="Country*"
        searchable={true}
        searchPlaceholder="Search"
        multiple={false}
        mode="SIMPLE"
        style={styles.dropdown}
        dropDownStyle={{
          backgroundColor: "white",
          elevation: 999,
          padding: 10,
          lineHeight: 26,
        }}
        showTickIcon={false}
        selectedItemContainerStyle={{
          backgroundColor: "grey",
          lineHeight: 32,
        }}
        selectedItemLableStyle={{
          backgroundColor: "grey",
          lineHeight: 32,
        }}
        searchContainerStyle={{
          marginBottom: 10,
        }}
        labelStyle={{
          paddingVertical: 8,
          fontFamily: "Matteo-Regular",
          fontSize: "16px",
        }}
        searchTextInputStyle={{
          borderBottomColor: "gray",
          borderBottomWidth: 1,
          borderRadius: 0,
          borderWidth: 0,
          outLine: 'none',
        }}
        dropDownContainerStyle={{
          padding: 10,
          boxShadow: "rgb(0 0 0 / 20%) 0px 1px 1.41px",
          backgroundColor: "#ffffff",
          border: 0,
          borderRadius: 0,
        }}
        listItemContainerStyle={{ padding: 10 }}
        listItemLabelStyle={{ fontSize: "16px", fontFamily: "Matteo-Regular" }}
        containerStyle={{
          backgroundColor: "#ffffff",
        }}
        arrowIconStyle={{
          width: 15,
          height: 15,
          color: "rgb(187, 187, 187)",
        }}
        onSelectItem={(item) => {
          onChange(item.value);
        }}
        zIndex={2000}
        dropDownDirection="BOTTOM"
        placeholderStyle={styles.input}
      />
    </View>
  );
};
export default CustomCountryPicker;
