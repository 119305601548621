import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  FontWeight,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import ArticleItemHorizontal from "../article-item-horizontal";
import ScrollContainerView from "../scroll-container-view";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import Cookies from "universal-cookie";
import ButtonGradient from "../button-gradient";
import AuthenticationWrapper from "../authentication-wrapper";

const viewMore = getImagePath("icons/view-more.png");
const cookies = new Cookies();
const windowWidth = Dimensions.get("window").width;

export class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custom_user_id: false
    };
  }

  componentDidMount = () => {
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };

  sortArticles = (slug) => {
    const { sortArticles } = this.props;
    sortArticles(slug);
  };

  viewArticle = (catSlug, slug, postData) => {
    const { viewArticle } = this.props;
    viewArticle(catSlug, slug, postData);
  };

  viewAuthor = (slug) => {
    const { viewAuthor } = this.props;
    viewAuthor(slug);
  };

  viewCategory = (slug) => {
    const { viewCategory } = this.props;
    viewCategory(slug);
  };

  applyStoryActions = (id, type, value, index) => {
    const { token } = this.state;
    if (token) {
      this.abortRequest();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      let signal = this.signal;

      let endpoint = `/user-story-action`;
      let body = {
        story_id: id,
        type: type,
        value: value,
      };

      let storyList = this.state.storyList;
      let index = storyList.findIndex((story) => {
        return story.id == id;
      });
      if (index >= 0) {
        storyList[index].is_bookmarked = !storyList[index].is_bookmarked;
      }
      this.setState({ storyList: storyList });

      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token, signal)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            let storyList = this.state.storyList;
            let index = storyList.findIndex((story) => {
              return story.id == id;
            });
            if (index >= 0) {
              storyList[index].is_bookmarked = res.data.bookmark;
            }
            this.setState({ storyList: storyList });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }
            try {
              if (type == "bookmark" && value === true) {
                Mixpanel.track("clicked_bookmark", {
                  story_title: storyList[index].title,
                  story_slug: storyList[index].slug,
                });
              } else if (type == "like" && value === true) {
                Mixpanel.track("clicked_like", {
                  story_title: storyList[index].title,
                  story_slug: storyList[index].slug,
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }
          } else {
            console.log("Error=>>", JSON.stringify(res));
            let storyList = this.state.storyList;
            storyList[index].is_bookmarked = !storyList[index].is_bookmarked;
            this.setState({ storyList: storyList });
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
          if (error && error.aborted) {
            // Do nothing
          } else {
            let storyList = this.state.storyList;
            storyList[index].is_bookmarked = !storyList[index].is_bookmarked;
            this.setState({ storyList: storyList });
          }
        });
    } else {
      if (helpers.isWeb()) {
        this.navigateToScreen(
          `/sign-in?redirect=${this.props.redirectSlugs.web
          }&redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`
        );
      } else {
        this.props.navigation.navigate("/sign-in", {
          ...this.props.redirectSlugs.native,
          verifyRedirect:
            this.props.redirectSlugs.native.verifyRedirect +
            `?redirect-params=id=${id}+action=bookmark+value=${true}+index=${index}`,
          "redirect-params": `id=${id}+action=bookmark+value=${true}+index=${index}`,
        });
      }
    }
  };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_section: "latest stories",
      newsletter_section: "NA",
      CTA_text: "view all articles",
      CTA_position: "bottom",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    const {
      data,
      sortValue,
      categorySlug,
      category,
      viewAllCatArticles,
      darkMode,
    } = this.props;
    let checkDesktop = this.props.hidebtn
      ? false
      : helpers.isDesktop()
        ? true
        : false;
    let webView = helpers.isWeb();
    let btnClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: "/category/" + categorySlug + "/all",
        handleClick: () => this.pushInformationToDataLayer()
      };
    } else {
      btnClick = {
        onPress: () => viewAllCatArticles(),
      };
    }
    return (
      <>
        <View
          style={[article.headerWrapper, helpers.isDesktop() && Padding.ph_2]}
        >
          <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3]}>
            <View
              style={[
                Padding.ph_2,
                Helpers.titleBgWrap,
                darkMode && { backgroundColor: "#ffffff" },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  {
                    borderBottomWidth: helpers.isDesktop() ? 2 : 0,
                  },
                  darkMode && { borderBottomColor: "rgba(255, 255, 255, 0.6)" },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    // Typo.h3_R,
                    // helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    {
                      fontSize: 34,
                      lineHeight: 51,
                      paddingBottom: 4,
                    },
                    {
                      fontFamily: FontFamily.abrilFatface,
                      fontWeight: FontWeight.bold,
                      color: "#000",
                    },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  Latest Stories
                </Text>
              </View>
            </View>
            <View
              style={[
                Helpers.titleLine,
                {
                  height: helpers.isDesktop() ? 1 : 0,
                },
                darkMode && { backgroundColor: "rgba(255, 255, 255, 0.6)" },
              ]}
            ></View>
          </View>
        </View>
        <View style={[Padding.ph_2]}>
          <View
            style={[
              Margin.mt_3,
              Margin.mb_4,
              category && {
                flexDirection: helpers.isDesktop() ? "column" : "row",
              },
              category && {
                alignItems: helpers.isDesktop() ? "center" : "flex-start",
              },
            ]}
          >
            {helpers.isDesktop() ? (
              <ScrollContainerView
                horizontal={false}
                vertical={false}
                showsHorizontalScrollIndicator={false}
              >
                <View
                  style={[Flex.row, !process.browser && { overflow: "hidden" }]}
                >
                  {data &&
                    data.map((item, index) => {
                      return (
                        <View
                          style={[
                            articleItem.col3,
                            helpers.isDesktop() && articleItem.col3Desktop,
                          ]}
                        >
                          <ArticleItemHorizontal
                            viewArticle={(catSlug, slug, postData) =>
                              this.viewArticle(catSlug, slug, postData)
                            }
                            viewAuthor={(slug) => this.viewAuthor(slug)}
                            data={item}
                            btnClickBookmark={() => {
                              this.applyStoryActions(
                                Number(item.id),
                                "bookmark",
                                item.is_bookmarked ? false : true,
                                index
                              );
                            }}
                            single={index == 0 ? true : false}
                            category={true}
                            handleClick={(title) =>
                              this.props.trackStoryClick(title)
                            }
                            darkMode={darkMode}
                            sectionName="Latest stories"
                            token={this.state.token}
                          />
                        </View>
                      );
                    })}
                </View>
              </ScrollContainerView>
            ) : (
              <ScrollContainerView
                horizontal={true}
                vertical={false}
                showsHorizontalScrollIndicator={false}
              >
                <View
                  style={[
                    Flex.row,
                    !helpers.isDesktop() && Flex.alignStart,
                    !process.browser && { overflow: "hidden" },
                    { width: "auto" },
                  ]}
                >
                  {data &&
                    data.map((item, index) => {
                      return (
                        <View
                          style={[
                            articleItem.col3,
                            helpers.isDesktop() && articleItem.col3Desktop,
                            !webView && { width: windowWidth - 90 },
                          ]}
                        >
                          <ArticleItemHorizontal
                            viewArticle={(catSlug, slug, postData) =>
                              this.viewArticle(catSlug, slug, postData)
                            }
                            viewAuthor={(slug) => this.viewAuthor(slug)}
                            viewCategory={(catSlug, slug) =>
                              this.viewCategory(catSlug, slug)
                            }
                            viewMoreArticles={(catSlug, slug) =>
                              this.viewMoreArticles(catSlug, slug)
                            }
                            btnClickBookmark={() => {
                              this.applyStoryActions(
                                Number(item.id),
                                "bookmark",
                                item.is_bookmarked ? false : true,
                                index
                              );
                            }}
                            data={item}
                            single={index == 0 ? true : false}
                            category={true}
                            darkMode={darkMode}
                            token={this.state.token}
                            sectionName="Latest stories"
                          />
                        </View>
                      );
                    })}

                  {helpers.isWeb() ? (
                    <>
                      {
                        helpers.isDesktop() ? (
                          <>
                            {category && !checkDesktop && (
                              <TouchableOpacityLink {...btnClick}>
                                <View
                                  style={[
                                    Flex.column,
                                    Flex.alignCenter,
                                    {
                                      width: 155,
                                      position: "relative",
                                      top: 155,
                                      marginLeft: -15,
                                    },
                                  ]}
                                >
                                  <CustomImage
                                    source={viewMore}
                                    require={true}
                                    style={{ width: 36, height: 36, margin: "auto" }}
                                    webStyle={{
                                      width: 36,
                                      height: 36,
                                      margin: "auto",
                                    }}
                                  />
                                  <Text
                                    style={[
                                      button.secondaryOutlineText,
                                      Margin.mr_0,
                                      { fontWeight: "500" },
                                    ]}
                                  >
                                    View all articles
                                  </Text>
                                </View>
                              </TouchableOpacityLink>
                            )}
                          </>
                        ) : null
                      }
                    </>
                  ) : (
                    <View style={{ position: "relative", top: 155 }}>
                      <TouchableOpacityLink {...btnClick}>
                        <View
                          style={[
                            Flex.column,
                            Flex.alignCenter,
                            { width: 155, marginLeft: -15 },
                          ]}
                        >
                          <CustomImage
                            source={viewMore}
                            require={true}
                            style={{ width: 36, height: 36, margin: "auto" }}
                            webStyle={{ width: 36, height: 36, margin: "auto" }}
                          />
                          <Text
                            style={[
                              button.secondaryOutlineText,
                              Margin.mr_0,
                              { fontWeight: "500" },
                            ]}
                          >
                            View all articles
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                    </View>
                  )}
                </View>
              </ScrollContainerView>
            )}
            <View
              style={[
                Flex.alignCenter,
                Margin.mt_4,
                { marginTop: helpers.isDesktop() ? 50 : 40 },
                helpers.isDesktop() && { marginBottom: 50 },
              ]}
            >
              {!category && (
                <TouchableOpacityLink {...btnClick}>
                  <View style={[Flex.row, Flex.alignCenter]}>
                    <Text
                      style={[
                        button.secondaryOutline,
                        helpers.isDesktop() && button.secondaryOutlineDesktop,
                        Margin.mr_0,
                        {
                          fontWeight: "600",
                          letterSpacing: helpers.isWeb() ? "0.05em" : 0.05,
                        },
                      ]}
                    >
                      View all articles
                    </Text>
                  </View>
                </TouchableOpacityLink>
              )}

              {category && checkDesktop && (
                <TouchableOpacityLink {...btnClick}>
                  <View style={[Flex.row, Flex.alignCenter]}>
                    <Text
                      style={[
                        button.secondaryOutline,
                        helpers.isDesktop() && button.secondaryOutlineDesktop,
                        Margin.mr_0,
                        {
                          fontWeight: "600",
                          letterSpacing: helpers.isWeb() ? "0.05em" : 0.05,
                        },
                      ]}
                    >
                      View all articles
                    </Text>
                  </View>
                </TouchableOpacityLink>
              )}
            </View>
          </View>
          {
            helpers.isWeb() && !helpers.isDesktop() ? (
              <TouchableOpacityLink {...btnClick}>
                <ButtonGradient
                  title={'View all articles'}
                  rootStyle={{
                    btnWrapper: [button.primaryGradient, { height: 40, marginBottom: 30 }],
                    btnText: [button.primaryGradientText, { fontSize: 14, paddingHorizontal: 7, fontWeight: '700', fontFamily: FontFamily.bold }]
                  }}
                  paddingOverride={true}
                  // onClick={() => { this.btnClick() }}
                  darkMode={darkMode}
                  isNewGradiant={true}
                />
              </TouchableOpacityLink>
            ) : null
          }
        </View>
      </>
    );
  }
}

export default (AuthenticationWrapper(Articles));
