import React, { Component } from "react";
import { Dimensions, Text, View } from "react-native";
import { button, Colors, Flex, FontFamily } from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";

const emailYesterDayOne = getImagePath("img/emailYesterDayOne.png");
const emailYesterDayTwo = getImagePath("img/emailYesterDayTwo.png");
const yesterdayImage = getImagePath("img/yesterdayImage.png");
const yesterdayImageMobile = getImagePath("img/yesterdayImageMobile.png");

const yesterdayImage2 = getImagePath("img/yesterdayImage2.png");
const yesterdayImage2ForArchive = getImagePath(
  "img/yesterdayImage2ForArchive.png"
);

class YesterdayStoryBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  renderButtonComponent = () => {
    let { isStoryFromPage, darkMode } = this.props;
    if (isStoryFromPage) {
      return (
        <View style={{ boxSizing: "border-box" }}>
          <View
            style={[
              helpers.isDesktop()
                ? { width: 241, height: 54, left: -20, top: 0 }
                : { height: 54 },
            ]}
          >
            <ButtonGradient
              title={"GET STARTED FOR FREE"}
              rootStyle={{
                btnWrapper: [button.primaryGradient, { width: "100%" }],
                btnText: [
                  button.primaryGradientText,
                  { fontSize: 16, fontWeight: "600" },
                ],
              }}
              onClick={() => 
                {
                  const dataLayer = window.dataLayer || [];
                  dataLayer.push({
                    event: "signup_initiate",
                    custom_user_id: "NA",
                    user_status: "guest",
                    CTA_text: "get started for free",
                    header_title: "NA",
                    CTA_position: "top",
                    article_name: "NA",
                    signup_method: "NA",
                  });
                  this.props.navigateWeb(`/sign-up?redirect=/&`);
                }
              }
              darkMode={darkMode}
            />
          </View>
          <View
            style={[
              helpers.isDesktop()
                ? { width: 241, height: 54, left: -20, top: 0 }
                : { width: 300, height: 54 },
            ]}
          >
            <ButtonGradient
              title={"LEARN MORE"}
              rootStyle={{
                btnWrapper: [button.primaryGradient, { width: "100%" }],
                btnText: [
                  button.primaryGradientText,
                  { fontSize: 16, fontWeight: "600" },
                ],
              }}
              onClick={() => this.props.navigateWeb(`/yesterday`)}
              darkMode={darkMode}
            />
          </View>
        </View>
      );
    } else {
      return (
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 24 : 16,
              lineHeight: helpers.isDesktop() ? 36 : 23.5,
              fontFamily: FontFamily.regular,
              color: "#051462",
            },
            {
              textAlign: helpers.isDesktop() ? "left" : "center",
              marginTop: helpers.isDesktop() ? 34 : 21,
              marginBottom: helpers.isDesktop() ? 0 : 10,
            },
            darkMode && { color: "rgba(255, 255, 255, 0.89)" },
          ]}
        >
          We’ve bookmarked some of the most important news from yesterday to
          make sure you don’t miss out on the latest happenings. We drop in your
          inbox every weekday at 8 am IST.
        </Text>
      );
    }
  };

  render() {
    let webView = helpers.isWeb();
    let { isStoryFromPage, darkMode } = this.props;
    return (
      <View
        style={[
          helpers.isDesktop() && {
            background: isStoryFromPage ? "#FFFFFF" : "#F3F5FF",
          },
          { padding: helpers.isDesktop() ? 40 : 20 },
          darkMode && { backgroundColor: Colors.darkPurple },
        ]}
      >
        <View
          style={[
            helpers.isDesktop() ? Flex.row : Flex.column,
            Flex.alignCenter,
            Flex.justfiyCenter,
            {
              maxWidth: helpers.isDesktop() ? "95%" : "100%",
              width: "100%",
              margin: "auto",
            },
          ]}
        >
          <View
            style={[
              {
                width: helpers.isDesktop() ? "47%" : "100%",
                padding: helpers.isDesktop() ? 10 : 0,
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: helpers.isDesktop() ? 60 : 27,
                  lineHeight: helpers.isDesktop() ? 80 : 35,
                  fontFamily: FontFamily.abrilFatface,
                  color: "#051462",
                  marginTop: helpers.isDesktop() ? 0 : 20,
                },
                {
                  paddingBottom: 3,
                  textAlign: helpers.isDesktop() ? "left" : "center",
                },
                darkMode && { color: "rgba(255, 255, 255, 0.89)" },
              ]}
            >
              Have only 5 mins? That’s{!helpers.isDesktop() ? "\n" : " "}enough
              to stay updated
            </Text>
            {helpers.isDesktop() ? this.renderButtonComponent() : null}
          </View>
          {!helpers.isDesktop() ? this.renderButtonComponent() : null}
          <View
            style={[
              {
                width: helpers.isDesktop() ? "52%" : "100%",
                padding: 10,
                marginTop: helpers.isDesktop() ? 0 : 46,
              },
            ]}
          >
            <CustomImage
              resizeMode={"cover"}
              source={
                helpers.isDesktop()
                  ? yesterdayImage
                  : yesterdayImageMobile
              }
              require={true}
              style={[
                helpers.isWeb()
                  ? { width: "100%", height: "auto" }
                  : { width: "100%", height: "auto" },
              ]}
              webStyle={{
                width: helpers.isDesktop() ? "100%" : "auto",
                height: helpers.isDesktop() ? "auto" : "auto",
              }}
              altText={""}
            />
          </View>
        </View>
      </View>
    );
  }
}

export default YesterdayStoryBanner;
