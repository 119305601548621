import React, { Component } from 'react';
import { Text, View, ImageBackground, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, articleItem, FontFamily, articleLists, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import ArticleItemHorizontal from '../article-item-horizontal';
import ScrollContainerView from '../scroll-container-view';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ArticleItem from '../article-item';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Slider from '../slider/index-homequote';
const viewMore = getImagePath('icons/view-more.png');
const quoteBg = getImagePath('icons/quote-homebg.png');

export class HomeNewReadersQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { data, subscriptionQuotes, teamSubscribers, darkMode, isPostSingel } = this.props;
        return (
            <View>
                <View teamSubscribers={true} style={{ backgroundColor: '#f3f5ff' }}>
                    <View style={{ width: helpers.isDesktop() ? 650 : '100%', marginHorizontal: 'auto' }}>
                        <ImageBackground source={quoteBg} resizeMode="cover" >
                            <View style={{ backgroundColor: helpers.isDesktop() ? 'none' : '#f3f5ff', }}>
                                <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 0 }, !helpers.isDesktop() && { marginBottom: 0 }, this.props.pricing && !helpers.isDesktop() && { marginTop: 0, marginBottom: 40 }, { zIndex: 9 }, teamSubscribers && { marginTop: helpers.isDesktop() ? 0 : 0, marginBottom: 0, paddingHorizontal: helpers.isDesktop() ? 20 : 0 }]}>
                                    <View style={[{ marginTop: helpers.isDesktop() ? 0 : 25, marginBottom: helpers.isDesktop() ? 0 : 25 }, !helpers.isDesktop() && { backgroundColor: 'transparent' }]}>
                                        <View style={{ backgroundColor: '#ffffff', width: '70%', height: helpers.isDesktop() ? 32 : 0, marginHorizontal: 'auto' }}></View>
                                        {!subscriptionQuotes ?
                                            <Slider data={data} homeQuote={true} darkMode={darkMode} isFromHome={true} custom_user_id={this.props.custom_user_id} />
                                            :
                                            <Slider data={data} subscriptionQuote={true} darkMode={darkMode} isFromHome={true} custom_user_id={this.props.custom_user_id} />
                                        }
                                        <View style={{ backgroundColor: '#ffffff', width: '70%', height: helpers.isDesktop() ? 32 : 0, marginHorizontal: 'auto', marginTop: -14 }}></View>
                                    </View>

                                </View >
                            </View>
                        </ImageBackground>
                    </View>
                </View>
            </View>
        );
    }
}

export default HomeNewReadersQuote;