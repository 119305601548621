import App from './App';
import ReactDOM from 'react-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from './src/native/components/auth0provider-with-history/index.js';


ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <App />
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('__next')
);
