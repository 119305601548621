import React, { Component } from 'react';
import { View } from 'react-native';
import { GoogleLogout } from 'react-google-login';
import Button from '../button';

const inputRef = React.createRef(null);

class GoogleLogoutButton extends Component {
    constructor(props) {
        super(props);
    }

    triggerLogout = () => {
        inputRef.current.props.onClick();
    }

    render() {
        return (
            <>
                <GoogleLogout
                    clientId={this.props.appId}
                    buttonText="Logout"
                    onLogoutSuccess={this.props.onLogoutSuccess}
                    onFailure={this.props.onFailure}
                    render={this.renderComponent}
                />

            </>
        );
    }

    renderComponent = (renderProps) => {
        return <Button
            onClick={renderProps.onClick}
            {...this.props}
        />

    }
}

export default GoogleLogoutButton;