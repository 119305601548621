export let Mix_v1 = <T>(superclass:T) => new MixinBuilder<T>(superclass);

export class MixinBuilder<T> {
    superclass: T
    constructor(superclass: T) {
        this.superclass = superclass;
    }

    with(...mixins: any[]):   T {
        return mixins.reduce((c, mixin) => mixin(c), this.superclass);
    }
}
