import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  articleItem,
  articleLists,
  FontFamily,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");

export class BlogItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    const {
      home,
      homepage,
      data,
      userCredits,
      archive,
      exclusiveHome,
      viewAuthor,
      viewArticle,
      darkMode,
    } = this.props;

    let btnClick, authorBtnClick, author2BtnClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: "/blog" + "/" + data.slug,
      };
      authorBtnClick = {
        to: "/writers/" + data.author[0].slug,
      };
      if (data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      }
    } else {
      btnClick = {
        onPress: () => viewArticle(data.slug),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author[0].slug);
        },
      };
      if (data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
    }
    return (
      <>
        <TouchableOpacityLink {...btnClick}>
          <View style={[Flex.fill]}>
            <View style={[Flex.column, Flex.row, Margin.mb_6]}>
              <View style={[Padding.pr_2, Flex.fill]}>
                <Text
                  style={[
                    articleItemHorizontal.articleTitle,
                    Margin.mb_2,
                    !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                    {
                      height: "auto",
                      marginBottom: 6,
                      fontWeight: "600",
                      fontSize: helpers.isDesktop() ? 26 : 18,
                      lineHeight: helpers.isDesktop() ? 36 : 24,
                    },
                    !helpers.isWeb() && { fontFamily: FontFamily.bold },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                  numberOfLines={2}
                >
                  {data.title}
                </Text>

                {data.excerpt && (
                  <Text
                    style={[
                      articleItemHorizontal.desc,
                      helpers.isDesktop() && articleItemHorizontal.descDesktop,
                      { marginBottom: helpers.isDesktop() ? 0 : 10 },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    {helpers.trimWord(data.excerpt)}
                  </Text>
                )}

                <View
                  style={[
                    articleItemHorizontal.lineBreak,
                    Margin.mt_1,
                    Margin.mb_1,
                    helpers.isDesktop() && [Margin.mb_1, Margin.mt_1],
                  ]}
                ></View>

                <View
                  style={[
                    {
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                    },
                    Margin.mb_1,
                  ]}
                >
                  <TouchableOpacityLink {...authorBtnClick}>
                    <Text
                      style={[
                        articleItemHorizontal.authorTitle,
                        helpers.isDesktop() &&
                          articleItemHorizontal.authorTitleDesktop,
                        !helpers.isWeb() &&
                          articleItemHorizontal.authorTitleApp,
                        { fontWeight: "500" },
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      {data.author[0].name}
                    </Text>
                  </TouchableOpacityLink>
                  {data.author[1] && (
                    <>
                      <Text
                        style={[
                          articleItemHorizontal.authorTitle,
                          helpers.isDesktop() &&
                            articleItemHorizontal.authorTitleDesktop,
                          !helpers.isWeb() &&
                            articleItemHorizontal.authorTitleApp,
                          articleItemHorizontal.date,
                          { marginTop: 2, fontFamily: FontFamily.light },
                          darkMode && { color: Colors.lightWhite },
                        ]}
                      >
                        {" "}
                        and{" "}
                      </Text>
                      <TouchableOpacityLink {...author2BtnClick}>
                        <Text
                          style={[
                            articleItemHorizontal.authorTitle,
                            helpers.isDesktop() &&
                              articleItemHorizontal.authorTitleDesktop,
                            !helpers.isWeb() &&
                              articleItemHorizontal.authorTitleApp,
                            { fontWeight: "500" },
                            darkMode && { color: Colors.lightWhite },
                          ]}
                        >
                          {data.author[1].name}
                        </Text>
                      </TouchableOpacityLink>
                    </>
                  )}
                </View>

                <Text
                  style={[
                    articleItemHorizontal.articleDate,
                    helpers.isDesktop() &&
                      articleItemHorizontal.articleDateDesktop,
                    !helpers.isWeb() && articleItemHorizontal.articleDateApp,
                    { fontWeight: "300", color: "#292929" },
                    !helpers.isDesktop() && { fontSize: 12 },
                    darkMode && { color: Colors.lineColor },
                  ]}
                >
                  {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacityLink>
      </>
    );
  }
}

export default BlogItem;
