import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, StyleSheet } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, postContentTags, postContentClasses, postTags } from '../../styles/appStyles';

export class PageBreak extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
    }

    render() {
        const { style, size, darkMode } = this.props;
        let elementSize = size == 'small' ? styles.borderSmall : {};
        return (
            <View style={[styles.border, style, elementSize]}>
               <View style={[styles.smallBox, this.props.active ? { width : 70 } : { width : 50 }, darkMode && { backgroundColor: 'rgba(255, 255, 255, 0.6)' }]}></View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    border: {
        position: 'relative',
        height: 0.3,
        width: '100%',
        borderTopWidth: 0.3,
        borderTopColor: 'rgba(102, 102, 102, 0.6)',
        marginVertical: 5,  
        alignItems: 'center',
        justifyContent: 'center'   
    },
    borderSmall: {
        maxWidth: 174,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})

export default PageBreak;