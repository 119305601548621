import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator, FlatList } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, appStyles, article, FontFamily, pageContent, FontWeight, Colors, banner, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import { connect } from 'react-redux';
import Cookies from "universal-cookie";
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import Seo from "../../components/seo-meta";
import Modal from '../../components/modal';
import ButtonGradient from '../../components/button-gradient';

const cookies = new Cookies();

export class UserBlocked extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {

    }

    componentDidUpdate = (prevProps) => {

    }

    onButtonClick = () => {
        this.props.setUserInactive(false);
        this.refs.header.authTokenExpired();
    }

    renderSubscriptionModalContent = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        return (
            <View style={{ paddingHorizontal: helpers.isDesktop() ? 60 : 25, paddingVertical: 25 }}>
                <Text style={[{ fontSize: 30, lineHeight: 36, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0)', textAlign: 'center' }]}>Account Blocked</Text>
                <View style={{ marginBottom: 0, marginTop: 18 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.regular, fontWeight: '500', color: '#F02121', textAlign: 'center', borderWidth: 1, borderColor: '#F02121', borderRadius: 4, paddingHorizontal: 10, paddingVertical: 20 }}>Please contact us at support@themorningcontext.com to activate your account</Text>
                    <View style={[Margin.mt_2]}>
                        <ButtonGradient
                            title={'logout'}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, { height: 38 }],
                                btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 15, lineHeight: helpers.isDesktop() ? 20 : 17, fontWeight: '600', letterSpacing: '0.05em' }]
                            }}
                            onClick={() => this.onButtonClick()}
                            darkMode={darkMode}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderPageContent = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <View style={{ zIndex: 9999 }}>
                    <Modal renderContent={() => this.renderSubscriptionModalContent()} closeModal={() => { }} paddingHorizontal={true} topPadding={false} verifyModal={true} subscription={true} darkMode={darkMode} hideBottomPadding={true} userBlocked={true} />
                </View>
            </>
        )
    }

    navigateToScreen = (route, params) => {
        let routeArr = route.split('?')
        const { history } = this.props;
        history.push({
            pathname: routeArr[0],
            search: routeArr[1] ? ('?' + routeArr[1]) : '',
            state: params
        });
    }

    render() {
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={{}} />
                <AppHeader ref="header" history={this.props.history} searchQuery="" locationHash="" navigateWeb={(slug) => this.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.refreshPage()} isPricing={true} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} hideBackArrow={true} showFullHeader={true} />
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

export default connect(mapStateToProps)(UserBlocked);