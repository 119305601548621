import React, { Component } from 'react';
import { Text, View, TextInput } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, pageContent, FontFamily, input, Colors, Messages } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';

const blueDots = getImagePath('icons/blue-dots.png');
const checkIcon = getImagePath('icons/check.png');

export class ReadFromArchives extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: false
        }
    }

    signUp = () => {
        const { email } = this.state;
        if (helpers.validateEmail(email)) {
            this.props.goToSignUp(email)
        }
        else {
            this.setState({ emailError: "Please enter a valid email" });
        }
    }

    render() {
        let { email, emailError } = this.state;
        const { singlePost, darkMode, subscription } = this.props;
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, Padding.ph_2]}>

                {
                    helpers.isDesktop() ?
                        <View style={[Flex.alignCenter, Padding.pt_4, Padding.pb_2]}>
                            {!subscription ?
                                <Text style={[{ fontSize: helpers.isDesktop() ? 34 : 24, lineHeight: 40, fontFamily: FontFamily.abrilFatface }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>Become a TMC member</Text>
                                :
                                <Text style={[{ fontSize: helpers.isDesktop() ? 34 : 24, lineHeight: 40, fontFamily: FontFamily.abrilFatface }, darkMode && {color: 'rgba(255, 255, 255, 0.89)', paddingBottom: 15}]}>Sign up for free</Text>
                            }
                            <PageBreak size={'small'} darkMode={darkMode} />
                        </View>
                        :
                        <View style={[Padding.pt_4]}>
                            <View style={[Flex.alignCenter, Margin.mb_3, { marginRight: -20, marginLeft: -20 }]}>
                                <View style={[Padding.ph_2, Helpers.titleBgWrap, singlePost && {backgroundColor: '#fafafa'}, darkMode && {backgroundColor: Colors.darkBlackColorBg }]}>
                                    <View style={[Helpers.borderBottom, darkMode && {borderBottomColor: 'rgba(255, 255, 255, 0.6)'}]}>
                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 20 }, { fontFamily: FontFamily.abrilFatface, color: '#000' }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}, !helpers.isWeb() && {marginBottom: 1}]}>Sign up for free</Text>
                                    </View>
                                </View>
                                <View style={[Helpers.titleLine, { backgroundColor: 'rgba(0, 0, 0, 0.6)' }, darkMode && {backgroundColor: 'rgba(255,255,255,0.6)'}]}></View>
                            </View>
                        </View>
                }

                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb700]}>
                    <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 27, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 },darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>Sign up now and get access to our starter collection for free.</Text>
                </View>
                <View style={{ marginTop: helpers.isDesktop() ? (subscription ? 0 : 30) : 0, maxWidth: 700, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <View style={[Margin.mt_3, helpers.isDesktop() && Flex.row, !helpers.isDesktop() && { width: '100%' }]}>
                        <TextInput
                            placeholder={'Email Id'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[input.Text, Padding.pt_1, Padding.pl_15, Padding.pb_1, !helpers.isDesktop() && Margin.mb_2, helpers.isDesktop() && { marginBottom: 6 }, { fontSize: helpers.isDesktop() ? 18 : 13, backgroundColor: 'white', height: 46, borderColor: '#6C7AF6', borderWidth: 1, width: helpers.isDesktop() ? 400 : '100%', flex: 1 }, !helpers.isDesktop() && { paddingTop: 12 , paddingBottom: 12 }]}
                            onChangeText={(value) => this.setState({ email: value, emailError: false })}
                            value={email}
                        />
                        <ButtonGradient
                            title={'SIGN UP'}
                            rootStyle={{
                                btnWrapper: [button.primaryGradientBtn, { height: helpers.isDesktop() ? 46 : 45, borderRadius: 0, flex: 1, position: 'relative', right: helpers.isDesktop() ? 1.5 : 0 }],
                                btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '500', paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 0 : 11.5, paddingBottom: helpers.isDesktop() ? 0 : 11.5, fontWeight: '400', fontFamily: FontFamily.regular }]
                            }}
                            smallBtn={true}
                            onClick={this.signUp}
                            darkMode={darkMode}
                        // disable={!email}
                        />
                    </View>
                    {emailError &&
                        <View style={[{ marginTop: 5 }]}>
                            <Text style={[Messages.errorText]}>{emailError}</Text>
                        </View>
                    }
                </View>
            </View>
        );
    }
}

export default ReadFromArchives;