import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, FontFamily, FontWeight, giftCard, articleLists, formStyle, Colors, button } from '../../styles/appStyles';
import PageBreak from '../page-break';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';

const giftIcon = getImagePath('icons/gift_pl_two.png');

export class SingleGiftStory extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {

    }

    pushInformationToDataLayer = () => {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "banner_interaction",
          custom_user_id: this.props.custom_user_id || "NA",
          user_status: this.props.custom_user_id ? "logged in" : "guest",
          category_type: "NA",
          banner_name: "Gift this story",
          banner_position: "bottom",
          CTA_text: "Gift now",
          scroll_depth: helpers.getScrollDepth(),
        });
      }

    render() {
        const { data, darkMode, freeCreditsAvailable, credits } = this.props;
        const { btnDisable, btnLoader } = this.state;

        return (
            <>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() ? Padding.ph_2 : Padding.ph_0]}>
                    <View style={[Flex.alignCenter, helpers.isDesktop() ? Margin.mb_9 : Margin.mb_5, { marginTop: helpers.isDesktop() ? 70 : 40 }]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 20, color: '#000' }, { fontFamily: FontFamily.abrilFatface }, !helpers.isWeb() && { marginBottom: 1 }, darkMode && { color: Colors.lightWhite }]}>GIFT THIS STORY</Text>
                            </View>
                        </View>
                        <View style={[Helpers.titleLine, { backgroundColor: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.6)' }]}></View>
                    </View>
                </View>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb680]}>
                    <View style={{ borderWidth: 2, borderColor: darkMode ? '#FF9302' : '#6C7AF7', padding: helpers.isDesktop() ? 30 : 20, position: 'relative' }}>
                        {freeCreditsAvailable && parseInt(freeCreditsAvailable) > credits.unlock - 1 ?
                            <>
                                <View style={{ paddingRight: helpers.isDesktop() ? 70 : 70 }}>
                                    <Text style={{ fontSize: 24, lineHeight: 27, fontFamily: FontFamily.regular, fontWeight: '600', color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 15 }}>Great stories deserve to be shared</Text>
                                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.9)', marginBottom: 15, lineHeight: 24 }}>Gift your friend, colleague or a family member this story.</Text>
                                </View>
                                <View>
                                    <ButtonGradient
                                        title={'Gift Now'}
                                        onClick={() => { 
                                            {
                                                this.props.openGiftCardModal(credits.unlock) 
                                            }
                                        }}
                                        rootStyle={{
                                            btnWrapper: button.primaryGradient,
                                            btnText: [button.primaryGradientText, { fontWeight: '400', fontSize: 16 }],
                                        }}
                                        loader={false}
                                        disable={false}
                                        darkMode={darkMode}
                                        leftAligned={true}
                                    />
                                </View>
                            </>
                            :
                            <>
                                <View style={{ paddingRight: helpers.isDesktop() ? 70 : 70 }}>
                                    <Text style={{ fontSize: 24, lineHeight: 27, fontFamily: FontFamily.regular, fontWeight: '600', color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 15 }}>Great stories deserve to be shared</Text>
                                    <Text style={{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.9)', marginBottom: 15, lineHeight: 24 }}>You have insufficient credits to gift this story. However, you can check out our various gifting options available in our gifting page.</Text>
                                </View>
                                <View>
                                    <ButtonGradient
                                        title={'View Gifts'}
                                        onClick={() => { this.props.navigateToGiftingPage('/gifting') }}
                                        rootStyle={{
                                            btnWrapper: button.primaryGradient,
                                            btnText: [button.primaryGradientText, { fontWeight: '400', fontSize: 16 }],
                                        }}
                                        loader={false}
                                        disable={false}
                                        darkMode={darkMode}
                                        leftAligned={true}
                                    />
                                </View>
                            </>

                        }
                        <CustomImage
                            source={giftIcon}
                            require={true}
                            style={{ width: 70, height: 74, resizeMode: 'contain', position: 'absolute', right: helpers.isDesktop() ? -40 : 0, top: 40 }}
                            webStyle={{ width: helpers.isDesktop() ? 93 : 70, height: helpers.isDesktop() ? 99 : 74, objectFit: 'contain', position: 'absolute', right: helpers.isDesktop() ? -40 : 0, top: 40 }}
                        />

                    </View>
                </View>
            </>
        );
    }
}

export default SingleGiftStory;