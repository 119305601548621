import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  StatusBar,
} from "react-native";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Routes from "./src/native/routes";
import { omit } from 'lodash';
import match from "url-pattern-match";
import './src/native/screens/subscription/vimeoStyles.css';

class App extends Component {
  constructor(props) {
    super(props);
  }

  dataLayerMappings = [
    {
      path: "/",
      event: "virtualpageview"
    },
    {
      path: "/logout",
      event: "virtualpageview"
    },
    {
      path: "/payment-redirect",
      event: "virtualpageview"
    },
    {
      path: "/company",
      event: "virtualpageview"
    },
    {
      path: "/writers",
      event: "virtualpageview"
    },
    {
      path: "/writers/:writer_slug",
      event: "virtualpageview"
    },
    {
      path: '/sign-up',
      event: "virtualpageview"
    },
    {
      path: '/sign-in',
      event: "virtualpageview"
    },
    {
      path: '/save-interests',
      event: "virtualpageview"
    },
    {
      path: '/pricing',
      event: "virtualpageview"
    },
    {
      path: '/pricing:advance_renew',
      event: "virtualpageview"
    },
    {
      path: '/about-us',
      event: "virtualpageview"
    },
    {
      path: "/category/:category_slug",
      event: "virtualpageview"
    },
    {
      path: '/category/:archive_slug/all',
      event: "virtualpageview"
    },
    {
      path: '/category/:archive_slug/archive',
      event: "virtualpageview"
    },
    {
      path: '/company/:archive_slug',
      event: "virtualpageview"
    },
    {
      path: '/tag/:archive_slug',
      event: "virtualpageview"
    },
    {
      path: '/all-stories',
      event: "virtualpageview"
    },
    {
      path: "/newsletters",
      event: "virtualpageview"
    },
    {
      path: "/news-briefs",
      event: "virtualpageview"
    },
    {
      path: "/longreads",
      event: "virtualpageview"
    },
    {
      path: "/newsletters-for-you",
      event: "virtualpageview"
    },
    {
      path: "/archive",
      event: "virtualpageview"
    },
    {
      path: "/last-week",
      event: "virtualpageview"
    },
    {
      path: "/investors",
      event: "virtualpageview"
    },
    {
      path: "/team",
      event: "virtualpageview"
    },
    {
      path: "/free-reads",
      event: "virtualpageview"
    },
    {
      path: "/premium-collection",
      event: "virtualpageview"
    },
    {
      path: "/terms-and-conditions",
      event: "virtualpageview"
    },
    {
      path: "/bookmarks",
      event: "virtualpageview"
    },
    {
      path: "/privacy-policy",
      event: "virtualpageview"
    },
    {
      path: "/faq",
      event: "virtualpageview"
    },
    {
      path: "/leadership",
      event: "virtualpageview"
    },
    {
      path: "/editorial-code-of-conduct",
      event: "virtualpageview"
    },
    {
      path: "/newsletters/:letter_slug",
      event: "virtualpageview"
    },
    {
      path: "/blog",
      event: "virtualpageview"
    },
    {
      path: "/blog/:blog_slug",
      event: "virtualpageview"
    },
    {
      path: "/unlocked-stories",
      event: "virtualpageview"
    },
    {
      path: "/yesterday",
      event: "virtualpageview"
    },
    {
      path: "/team-subscription",
      event: "virtualpageview"
    },
    {
      path: "/my-team",
      event: "virtualpageview"
    },
    {
      path: "/no-subscription-plan",
      event: "virtualpageview"
    },
    {
      path: "/student-subscription",
      event: "virtualpageview"
    },
    {
      path: "/gifting",
      event: "virtualpageview"
    },
    {
      path: "/app-download",
      event: "virtualpageview"
    }
  ];


  componentDidMount() {
    const pushState = history.pushState;
    const replaceState = history.replaceState;

    history.pushState = function () {
      pushState.apply(history, arguments);
      window.dispatchEvent(new Event("pushstate"));
      window.dispatchEvent(new Event("locationchange"));
    };

    history.replaceState = function () {
      replaceState.apply(history, arguments);
      window.dispatchEvent(new Event("replacestate"));
      window.dispatchEvent(new Event("locationchange"));
    };

    window.addEventListener("popstate", function () {
      window.dispatchEvent(new Event("locationchange"));
    });

    window.addEventListener("locationchange", this.handleLocationChange);

    // Push initial pageview data
    const dataLayer = window.dataLayer || [];
    const pageViewData = this.getDataLayerProps();
    if (pageViewData) {
      dataLayer.push(pageViewData);
    }
  }


  getDataLayerProps = () => {
    const currentPath = window.location.pathname;

    const currentMapping = this.dataLayerMappings.find((mapping) => {
      const match1 = match(mapping.path, currentPath);
      return match1.state;
    });

    if (currentMapping) {
      const match1 = match("/category/:archive_slug/all", currentPath);
      const match2 = match("/category/:archive_slug/archive", currentPath);
      const match3 = match("/category/:archive_slug", currentPath);
      const match4 = match("/newsletters", currentPath);
      const match5 = match("/newsletters-for-you", currentPath);
      const match6 = match("/newsletters/:letter_slug", currentPath);
      const match7 = match("/news-briefs", currentPath);
      const match8 = match("/yesterday", currentPath);
      const match9 = match("/longreads", currentPath);
      const match10 = match("/blog", currentPath);
      const match11 = match("/blog/:blog_slug", currentPath);

      if (match1.state || match2.state || match3.state) {
        const archive_slug =
          match1.children.archive_slug ||
          match2.children.archive_slug ||
          match3.children.archive_slug;
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = archive_slug;
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "NA";
      } else if (match4.state || match5.state || match6.state) {
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = "NA";
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "newsletter";
      } else if (match7.state) {
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = "NA";
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "newsbrief";
      } else if (match8.state) {
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = "NA";
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "yesterday";
      } else if (match9.state) {
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = "NA";
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "longreads";
      } else if (match10.state || match11.state) {
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = "NA";
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "blog";
      } else {
        currentMapping.page_location = window.location.href;
        currentMapping.page_title = document.title;
        currentMapping.article_category = "NA";
        currentMapping.newsletter_category = "NA";
        currentMapping.article_id = "NA";
        currentMapping.category_type = "NA";
      }
    }

    const pageProperties = omit(currentMapping, "path");
    return pageProperties;
  };

  componentWillUnmount() {
    window.removeEventListener("locationchange", this.handleLocationChange);
  }

  handleLocationChange = () => {
    // Handle location change here
    // Push new pageview data when location changes
    // Need this timeout so that we can access the right page title
    setTimeout(() => {
      const dataLayer = window.dataLayer || [];
      const pageViewData = this.getDataLayerProps();
      if (pageViewData) {
        dataLayer.push(pageViewData);
      }
    }, 15000)
  };

  render() {
    return (
      <>
        <Routes />
      </>
    );
  }
}

export default App;
