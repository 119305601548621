import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, FontFamily } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';

const closeIcon = getImagePath('icons/close-round.png');
const emailVerifyIcon = getImagePath('icons/email-verify.png');
const warningIcon = getImagePath('icons/unsuccessful.png');
const goldCoin = getImagePath('icons/coin.png');

const windowHeight = Dimensions.get('window').height;

export class WorkEmailPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowHeight * 2)),
            modalOpacity: new Animated.Value(0),
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if(helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: (windowHeight * 2),
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 0,
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();

        if(helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
        const { closeModal } = this.props;
        setTimeout(function () {
            closeModal();
        }, 300);
    }

    render() {
        if(helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity } = this.state;
        const { renderContent, paddingHorizontal, topPadding, plan, country } = this.props;

        return (
            <>
                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }]}>
                </Animated.View>
                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { transform: [{ translateY: showModal }], backgroundColor: 'transparent' }]}>
                    <View style={[modalStyle.modalContainer, { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, {paddingBottom: 40}, !helpers.isDesktop() && { flex: 0.7, padding: 16, width: '100%', backgroundColor: 'transparent' }]}>
                        <View style={{ position: 'absolute', right: helpers.isDesktop() ? -25 : 0, top: helpers.isDesktop() ? -25 : 0, zIndex: 1 }}>
                            <TouchableOpacity
                                activeOpacity={0.8}
                                style={[modalStyle.closeButton]}
                                onPress={() => this.closeModal()}
                            >
                                <CustomImage
                                    source={closeIcon}
                                    style={{ width: 34, height: 34 }}
                                    webStyle={{ width: 34, height: 34 }}
                                    require={true}
                                />
                            </TouchableOpacity>
                        </View>
                        <View style={[!helpers.isDesktop() && { backgroundColor: 'white', paddingBottom: 40 }]}>
                            <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, { backgroundColor: '#FF8080', height: 55 }]}>
                                <CustomImage
                                    source={warningIcon}
                                    style={{ width: 22, height: 20, marginRight: 8, marginBottom: 5 }}
                                    webStyle={{ width: 22, height: 20,  marginRight: 8, marginBottom: 5 }}
                                    require={true}
                                />
                                <Text style={{ fontFamily: FontFamily.regular, fontSize: 16, lineHeight: 19.2, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, color: 'white', fontWeight: '600' }}>WORK EMAIL REQUIRED</Text>
                            </View>
                            <Text style={[Helpers.textCenter, Padding.pt_3, Padding.pb_3, { fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0, 0, 0, 0.8)', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28, paddingLeft: helpers.isDesktop() ? 30 : 18, paddingRight: helpers.isDesktop() ? 30 : 18 }]}>To purchase a team subscription you need to signup using your company email id. You will only be able to add members whose email ids are associated to your company domain.</Text>
                            <View style={[Margin.ml_4, Margin.mr_5]}>
                                <ButtonGradient
                                    title={'SIGN UP WITH WORK EMAIL'}
                                    fullWidth={true}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1 }],
                                        btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '400', paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 17 : 15, paddingBottom: helpers.isDesktop() ? 17 : 15 }]
                                    }}
                                    onClick={() => {this.closeModal(); this.props.logout()} }
                                // disable={btnDisable}
                                />
                            </View>
                        </View>
                    </View>
                </Animated.View>
            </>
        );
    }
}

export default WorkEmailPopup;