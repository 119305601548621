import getImagePath from "./image-helper";

const spotifyIndiaLogo = getImagePath("img/spotify-img.png")
const timesInternetLogo = getImagePath("icons/times-internet-logo.png")
const iitDelhiLogo = getImagePath("icons/iit-delhi-logo.png")
const paulWriterLogo = getImagePath("icons/paul-writer-logo.png")
const platoonOneFilmsLogo = getImagePath("icons/platoon-one-films-logo.png")
const a91Logo = getImagePath("icons/a91-logo.png")
const mindtreeLogo = getImagePath("icons/mindtree-logo.png")
const yuvaaLogo = getImagePath("icons/yuvaa-logo.png")


export const PRICING_PLANS = ["INDIVIDUAL", "CORPORATE", "STUDENT", "TEACHER/UNIVERSITY", "FRIENDS OF TMC"];
export const BENEFITS_OF_READING_TMC = [
    {
        title: 'In-Depth Analysis: ',
        description: 'No PR, no plugs. Dive deep into timely, important subjects. We don’t publish commodity stuff.'
    },
    {
        title: 'Exclusive Interviews: ',
        description: 'Gain insights from interviews and discussions with thought leaders, industry experts, and change-makers, giving you access to their unique perspectives on the changing world of startups and business.'
    },
    {
        title: 'Timely Updates: ',
        description: 'Our iOS and Android apps ensure that you never miss a single update from us.'
    },
    {
        title: 'Ad-Free Experience: ',
        description: 'Enjoy a distraction-free reading experience with no annoying ads, ensuring you can focus solely on the content.'
    }
]

export const WHO_READS_TMC = [
    {
        title: 'Business Leaders:',
        description: 'CEOs, entrepreneurs, and executives rely on us for in-depth business insights, market trends, and thought leadership.'
    },
    {
        title: 'Policy Makers:',
        description: 'Policymakers and government officials turn to us for well-researched analysis and informed perspectives on critical issues.'
    },
    {
        title: 'Academics and Researchers:',
        description: 'Scholars and researchers find our content valuable for staying updated on current events and relevant research.'
    },
    {
        title: 'Media Professionals:',
        description: 'Journalists, media professionals, and communication experts use The Morning Context as a source of inspiration and industry knowledge.'
    },
    {
        title: 'Curious Minds:',
        description: 'Anyone with a passion for learning and staying informed about the world turns to us for our unique storytelling and thought-provoking content.'
    }
]

export const COLLEGES_THAT_READ_US = [
    {
        name: 'IIM Ahmedabad'
    },
    {
        name: 'IIM Amritsar'
    },
    {
        name: 'IIM Kozikhode'
    },
    {
        name: 'IIT Delhi'
    },
    {
        name: 'IIT Indore'
    },
    {
        name: 'Indian School of Business'
    },
]

export const WHAT_OUR_READERS_SAY = [
    {
        organization: 'Spotify India',
        review: `“As one of the early subscribers, I've had the opportunity to witness how quickly the team has grown, the variety of angles covered, and the research in every in-depth story and op-ed.”`,
        name: 'Amarjit Batra',
        designation: 'Managing director',
        logo: spotifyIndiaLogo
    },
    {
        organization: 'Times Internet',
        review: `“The Morning Context is my daily opportunity to get smarter about one new company/sector. Discerning analysis, unbiased and approachable”`,
        name: 'Miten Sampat',
        designation: 'Angel investor & former CSO',
        logo: timesInternetLogo
    },
    {
        organization: 'School of Public Policy (IIT Delhi)',
        review: `“Research ideas don't come from a vacuum. The best ideas come from a spark, a contrarian take, an uncommon investigative insight, and one doesn't always get these from traditional media. TMC consistently makes me think about things in different ways, and I've frequently shared TMC articles with collaborators and students. Staying at the cutting edge is hard enough; TMC makes it much easier.”`,
        name: 'Rohit Chandra',
        designation: 'Assistant Professor',
        logo: iitDelhiLogo
    },
    {
        organization: 'Paul Writer',
        review: `“In today's very noisy and cluttered world, I like that the Morning Context goes below the surface of company PR to deliver fact-based stories on Indian business.”`,
        name: 'Jessie Paul',
        designation: 'CEO',
        logo: paulWriterLogo
    },
    {
        organization: 'Platoon One Films',
        review: `“Although it's a paid subscription, the kind of detailed research and quality of reportage makes it worth every rupee. It’s strength surely lies in having some of the finest journalists writing for it.”`,
        name: 'Shiladitya Bora',
        designation: 'Producer, distributor and founder',
        logo: platoonOneFilmsLogo
    },
    {
        organization: 'A91',
        review: `“High quality, independent journalism is rare to find. The Morning Context set really high standards that make me wait for each story.”`,
        name: 'Amarjit Batra',
        designation: 'Managing director',
        logo: a91Logo
    },
    {
        organization: 'Mindtree',
        review: `“In today's polarized world, authentic and unbiased facts are in short supply world over. The Morning Context is a refreshing approach towards truthful, well researched, fearless, and high-quality articles on contemporary topics.”`,
        name: 'N S Parthasarthy',
        designation: 'Co-founder',
        logo: mindtreeLogo
    },
    {
        organization: 'Yuvaa',
        review: `“In a world full of noise, The Morning Context is the calm that stimulates and energises your mind, with stories focused on fact, truth and dogged reportage.”`,
        name: 'Nikhil Taneja',
        designation: 'Co-founder and CEO',
        logo: yuvaaLogo
    },
    {
        review: `“Well-written and researched long form reads on a wide variety of topics and a team of veteran journalists - all of this makes my subscription worth it.`,
        name: 'Anupam Gupta',
        designation: 'Investment research consultant'
    }
]


export const FREQUENTLY_ASKED_QUESTIONS = [
    {
        section: 'Individual',
        FAQs: [
            {
                question: 'What is The Morning Context?',
                answer: [
                    'Business news in India faces three key challenges:',
                    '\t1. Newspapers continue to churn out voluminous, thin, shallow and poorly written articles.',
                    '\t2. Social media has amplified echo chambers and fake news creating harmful misinformation campaigns.',
                    '\t3. Advertising funded business models have created an environment of bias and favoritism in media coverage which has lowered the quality of journalism.',
                    'Launched in September 2019, The Morning Context is the answer to the above trends. With original, deeply reported, fewer stories and dependent on subscriptions or reader revenue, TMC has only the interest of its reader or subscriber in mind. With a Less is more philosophy, TMC has already developed a loyal base of readers across the world.'
                ]
            },
            {
                question: 'Who are your readers?',
                answer: '90% of our readers say they subscribe to The Morning Context because it helps them work better. Whether they are running their own business, consulting, or working with startups or large corporations, in finance or fin-tech, marketing, government policy, venture funds or retail investors, academia and even students, our stories add value to their work. It helps them understand companies and their strategies, what they are getting right or wrong, so that they can bring this knowledge into their own work.'
            },
            {
                question: 'What do you write about?',
                answer: [
                    'Given the increasing importance of India on the global stage, The Morning Context’s critical, unbiased coverage is important and valuable. A close look at the topics The Morning Context writes about:',
                    '1. Startups: 25%',
                    '2. Large conglomerates and public listed companies: 20%',
                    '3. Finance & Banking: 15%',
                    '4. Stock Market: 10%',
                    '5. Government policy: 10%',
                    '6. Environment: 5%',
                    '7. Culture: 5%',
                    '8. Sports, economy, politics, culture etc: 10%'
                ]
            },
            {
                question: 'How many stories do you write?',
                answer: 'We publish more than 500 stories over a calendar year.'
            },
            {
                question: 'Can I read your stories without subscribing?',
                answer: 'No. Every week we make one story free from our archives. You can read those stories plus any other article which we make free after signing up.'
            },
            {
                question: 'Can I download the stories or take a print?',
                answer: 'Piracy is a significant issue for digital businesses, which we take very seriously as a media company. This is why we don\'t allow copy, paste or print or download of our stories.'
            },
            {
                question: 'Do you send email alerts, app notifications when stories are published?',
                answer: 'Subscribers of The Morning Context receive the Daily Edition in their inbox every morning. This is usually shipped at 6 AM, IST. Think of the Daily Edition as a quick snapshot of the daily financial newspaper landing in your inbox. You can read the top news at a glance and choose to dive deeper. When the stories are published, they are also accompanied with notifications on our Android and iOS apps. So please make sure to whitelist our email ID, newsletters@themorningcontext.com so you don’t miss our Daily Edition emails and also download the app.'
            },
            {
                question: 'I am a paying subscriber but I am not getting your daily emails. What can I do?',
                answer: 'Your mail client’s filters may be delivering our emails in your spam or promotions folder. Please check there. Just to be sure that you don’t miss our daily emails, add newsletters@themorningcontext.com to your address book or simply reply to any of our daily emails.'
            },
            {
                question: 'How do I download The Morning Context app?',
                answer: [
                    'Our Android App is here.',
                    'Our iOS App is here.'
                ]
            },
            {
                question: 'How many devices can I read The Morning Context on?',
                answer: 'Your subscription allows you to read The Morning Context on two devices only.'
            },
            {
                question: 'Can I change my email address?',
                answer: 'Your The Morning Context subscription is tied to a particular email ID which we verify in the sign up process. Your subscription is an *Individual Non-transferable subscription*. If you lose access to your email you can write to support@themorningcontext.com and we can see how best we can serve you on a case by case basis.'
            },
            {
                question: 'What are your subscription plans and privileges?',
                answer: 'Our subscription plans, monthly, annual and 2-year are competitively priced. You can check them out here.'
            },
            {
                question: 'Is there an option for a refund?',
                answer: 'We don’t issue refunds. But if there’s something you don’t like we’d certainly like to hear from you. Write to us at support@themorningcontext.com.'
            },
            {
                question: 'Can I cancel my subscription myself?',
                answer: [
                    'Yes. Please follow the below steps to cancel your subscription.',
                    '1. Log in to our website',
                    '2. Click on your account',
                    '3. Click on \'Manage Subscriptions\' and then click on your current plan.',
                    '4. At the bottom, you\'ll see an option that says \'Cancel Subscription\'. Hit that button.',
                    'After canceling the subscription, please do not click on the \'Resume your subscription\' CTA on the website (clicking on it will enable your subscription again).'
                ]
            },
            {
                question: 'I purchased a plan, but my subscription did not get activated.',
                answer: 'This may happen sometimes due to some technical glitch. Write to us at support@themorningcontext.com. We will check your payment status and help you out.'
            },
            {
                question: 'What modes of payment do you accept?',
                answer: 'We accept payment through credit cards, debit cards, UPI, Netbanking and wallets.'
            },
            {
                question: 'Can I transfer my subscription to a friend, colleague or family member?',
                answer: 'No. Your  subscription is an *Individual Non-transferable subscription*'
            },
            {
                question: 'Responsible Login Usage Policy',
                answer: [
                    `Q1: Why does The Morning Context discourage login sharing and what are the consequences?`,
                    `A1: Login sharing undermines the value of our service and violates our terms of use. If we detect login sharing, your subscription may be immediately canceled without a refund. Each subscription is intended for individual use only.`,
                    `Q2: What constitutes login sharing?`,
                    `A2: Login sharing occurs when a subscriber shares their login credentials (username and password) with others, allowing multiple people to access the content under a single subscription.`,
                    `Q3: How can I avoid unintentional login sharing and what should I do if I suspect misuse of my credentials?`,
                    `A3: Keep your login credentials private and log out from shared devices. If you suspect someone else is using your login, contact our support team at support@themorningcontext.com.`,
                    `Q4: What are the options for multiple users needing access to The Morning Context?`,
                    `A4: For multiple users, we offer group, corporate and family subscription plans. Please visit our subscription page or contact our team at support@themorningcontext.com for more information.`
                ]
            },
            {
                question: 'Do you save my card details?',
                answer: 'As per global norms on customer privacy and safety, as service providers, we cannot and do not save card details. These are saved at the end of your issuing bank/ Visa/ Master and our payment gateways have  - is a unique token for the card.'
            },
            {
                question: 'How can I stop the recurring payments?',
                answer: 'You can cancel your recurring payment (e-mandate) through the bank portal. Alternatively, you can also cancel your subscription on our website under the account section.'
            },
            {
                question: 'I’m facing problems with my subscription, whom should I get in touch with?',
                answer: 'Please write to us at support@themorningcontext.com'
            },
            {
                question: 'Can I upgrade my plan?',
                answer: 'Certainly! You can switch to a higher subscription plan. The cost of the new plan will be reduced based on the number of days you have left in your current subscription.'
            },
            {
                question: 'Can I make payments on the app?',
                answer: 'Currently only users on our Android app can make transactions on the App. We will be launching payments on the iOS app soon.'
            },
            {
                question: 'How can I get a GST invoice?',
                answer: 'During checkout, there’s an option to add your GST details. Once the payment is done, you should get your invoice that mentions your GST details.'
            },
        ]
    }, {
        section: 'Corporate',
        FAQs: [
            {
                question: 'What is a corporate subscription?',
                answer: [
                    'Several companies subscribe to The Morning Context with a corporate plan as a paid perk for their employees.',
                    '90% of our readers say they subscribe to The Morning Context because it helps them work better. Whether they are running their own business, consulting, or working with startups or large corporations, in finance or fin-tech, marketing, government policy, venture funds or retail investors, academia and even students, our stories add value to their work. It helps them understand companies and their strategies, what they are getting right or wrong, so that they can bring this knowledge into their own work.',
                    'Forward thinking companies who aspire to become employers of choice invest in a knowledge subscription of The Morning Context for their teams.'
                ]
            },
            {
                question: 'How does the company get billed?',
                answer: 'By a GST invoice for 12 months in advance.'
            },
            {
                question: 'Are there limits on usage?',
                answer: [
                    'Fixed Plan: Depending on the number of licenses bought, only those number of users can access the publication.',
                    'Engaged Plan: This is an enterprise-wide license, and can be used by all staff on any device in any location. There are no limits on the number of users or articles being read.'
                ]
            },
            {
                question: 'How is access implemented with a team or business?',
                answer: 'We will provide you all the tools needed to set up and manage your own subscription where you will be able to add, remove and manage users via self service. We will also offer dedicated customer service for any subscription queries. Once you have a corporate account, access to the websites is granted via your work email address.'
            },
            {
                question: 'Can I add more users to my Corporate Subscription?',
                answer: 'Yes, your subscription is flexible. You can add more users and manage the payment via your dashboard.'
            },
            {
                question: 'What if I have access issues?',
                answer: 'As a Corporate Subscriber you have access to priority customer support, who are available to answer any questions you have throughout your subscription period. You can email us support@themorningcontext.com. Use the subject line: Corporate Subscription: Company: Issue. You can also write to priya@mailtmc.com'
            }
        ]
    },
    {
        section: 'Students',
        FAQs: [
            {
                question: 'How do I qualify for this?',
                answer: `The first question you need to answer is, are you really a student? Not a student for life of course. I am afraid we all fall in that bucket. To purchase a student subscription of The Morning Context, you must be a student with proof like a valid ID card, enrolment letter. We recognise you as a student when you are enrolled in a University or College for an undergraduate or postgraduate course. \n\nYou’ll be asked to upload a scan of your student ID, clearly showing your name, institute, name of the course and the validity of the ID to get institutional access. The subscription must be in the name and institute email address of the student. As per company policy, we do not accept ID cards of students who are less than 18 years of age. \n\nPlease note that if you submit the wrong credentials, you will not be issued a refund because our team will have spent time evaluating your student credentials which is not a free service.
                `
            },
            {
                question: 'I’m graduating this year, am I still eligible?',
                answer: 'Yes, you are.'
            },
            {
                question: 'What if I don’t have a student ID?',
                answer: 'If your institution hasn’t issued an ID card, or the card doesn’t have a validity/start date, you can upload a scan of any other document showing that you’re currently enrolled. Examples: acceptance letters, end-of-term marksheets.'
            },
            {
                question: 'I’m enrolled in a professional course, do I qualify?',
                answer: 'No, the discount is only applicable to full-time students at educational institutions.'
            },
            {
                question: 'Can I subscribe on behalf of a student?',
                answer: `No, you cannot subscribe on behalf of your relative, child, brother, sister, friend, spouse etc. Please note that if you submit the wrong credentials, you will not be issued a refund because our team will have spent time evaluating your student credentials which is not a free service.
                `
            },
            {
                question: 'I am already a student subscriber. Should I prove my student status once again when I renew my subscription?',
                answer: 'Yes, we verify the status once again before granting access to the student subscription application. You have to upload a scan of your student ID, clearly showing your name, institute, name of the course, and the validity of the ID to get institutional access.'
            },
            {
                question: 'Is there an option for a refund?',
                answer: 'We don’t issue refunds. But if there’s something you don’t like we’d certainly like to hear from you. Write to us at support@themorningcontext.com.'
            },
            {
                question: 'Can I transfer my student subscription to another ID?',
                answer: 'No, we verify your student status before granting access to the student subscription plan. Both, during the time of verification as well as post verification, the subscription cannot be transferred to another account.'
            },
        ]
    },
    {
        section: 'Teacher/University',
        FAQs: [
            {
                question: 'How can my educational institution or library subscribe to The Morning Context?',
                answer: 'If you are a faculty member or librarian, you can write to priya@mailtmc.com to get institutional access for all students and faculty members for your department, college or university.'
            },
            {
                question: 'How do you provide access to the entire university?',
                answer: 'We provide domain based and IP address based subscriptions'
            },
            {
                question: 'Is there a trial period available for educational and library subscriptions?',
                answer: 'We understand the importance of trials and we are proud of the quality of our journalism. We can provide a 7-day trial period for your institution.'
            }
        ]
    },
    {
        section: 'Friends Of TMC',
        FAQs: [
            {
                question: 'What does a Lifetime subscription mean?',
                answer: 'A lifetime subscription means your individual subscription, valid for a lifetime.'
            },
            {
                question: 'I want to know more about Friends of TMC, who should I get in touch with?',
                answer: 'You can write to priya@mailtmc.com to get more information.'
            },
            {
                question: 'How does "Friends of The Morning Context" contribute to the success of TMC\'s journalism?',
                answer: 'Reader revenue is the holy grail of independent media publishing. Globally, funding for news media companies continues to dwindle because news publishing, while important to the fabric of building a just, democratic society, is a high risk, low reward business. This is why it has become all the more important for readers to come forward and support independent media companies like The Morning Context. And by support, we are not looking for a handout. Friends of The Morning Context is a lifetime subscription, one which should be the choice of subscription for the most loyal of our readers who see a world where The Morning Context’s journalism adds value.'
            }
        ]
    }
]


export const TMC_PLANS = [
    {
        plan_id: 'annual-plan-in',
        price: 3201
    },
    {
        plan_id: '2-year-plan-inr',
        price: 5601
    },
    {
        plan_id: 'monthly-plan-in',
        price: 399
    },
    {
        plan_id: 'student-plan-inr',
        price: 1799
    },
    {
        plan_id: 'friends-of-tmc',
        price: 149000
    },
    {
        plan_id: 'annual-plan-usd',
        price: 99
    },
    {
        plan_id: '2-year-plan-usd',
        price: 150
    },
    {
        plan_id: 'monthly-plan-usd',
        price: 3
    },
    {
        plan_id: 'friends-of-tmc-usd',
        price: 15000
    },
    {
        plan_id: 'student-plan-inr',
        price: 1799
    },
    {
        plan_id: 'student-plan-usd',
        price: 99
    }
]