import React, { Component } from 'react';
import { ActivityIndicator } from 'react-native';
import InfiniteScroll from 'react-infinite-scroller';

class InfiniteScrollComponent extends Component {
    render() {
        return (
        	<InfiniteScroll
                pageStart={this.props.pageStart}
                loadMore={() => this.props.loadMore()}
                hasMore={this.props.hasMore}
                loader={<ActivityIndicator size={50} color={'#907CFF'} />}
            >
                {this.props.items}
            </InfiniteScroll>
        );
    }
}
 
export default InfiniteScrollComponent;