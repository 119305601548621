import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, StatusBar, TouchableOpacity, SafeAreaView, } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';


const closeIcon = getImagePath('icons/close-round.png');
const emailVerifyIcon = getImagePath('icons/email-verify.png');

const windowHeight = Dimensions.get('screen').height;


export class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowHeight)),
            modalOpacity: new Animated.Value(0),
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 800,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: (windowHeight * 2),
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 0,
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();

        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
        const { closeModal } = this.props;
        setTimeout(function () {
            closeModal();
        }, 300);
    }


    render() {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity } = this.state;

        const { renderContent, paddingHorizontal, topPadding, subscription, imageZoom, imagePadding, verifyModal, giftCardModal, userBlocked } = this.props;

        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }, darkMode && { backgroundColor: 'rgba(0,0,0,0.9)' }]}>
                </Animated.View>

                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { transform: [{ translateY: showModal }], backgroundColor: 'transparent' }, imageZoom && { backgroundColor: '#000' }, giftCardModal && { paddingBottom: 0 }]}>
                    <View style={[verifyModal ? modalStyle.modalContainerMedium : modalStyle.modalContainer, paddingHorizontal && { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, !helpers.isDesktop() && { paddingBottom: 0 }, darkMode && { backgroundColor: Colors.darkBlackColor1 }, { paddingBottom: 0 }, giftCardModal && modalStyle.modalContainerlarge, giftCardModal && { paddingHorizontal: 0, paddingVertical: 0 }, userBlocked && { width: helpers.isDesktop() ? 650 : '100%' }]}>

                        {!subscription && <View style={[{ position: 'absolute', right: helpers.isDesktop() ? -20 : 0, top: helpers.isDesktop() ? -22 : 0, zIndex: 1 }, subscription && !helpers.isDesktop() && { right: 10, top: 6 }]}>
                            <TouchableOpacity
                                activeOpacity={0.8}
                                style={[modalStyle.closeButton]}
                                onPress={() => this.closeModal()}
                            >
                                <CustomImage
                                    source={closeIcon}
                                    style={{ width: 28, height: 28 }}
                                    webStyle={{ width: 28, height: 28 }}
                                    require={true}
                                />
                            </TouchableOpacity>
                        </View>}

                        <ScrollView style={[helpers.isWeb() && { maxHeight: helpers.isDesktop() ? 'calc(100vh - 115px)' : '90vh', userSelect: 'auto', paddingBottom: imagePadding ? 0 : 20, }]}>
                            {renderContent()}
                        </ScrollView>
                    </View>
                </Animated.View>
            </>
        );
    }
}

export default Modal;