import React, { Component } from 'react'
import { Text, View } from 'react-native';
import { Flex, Margin, FontFamily, aboutAuthor, authorItem, Colors } from '../../styles/appStyles';

import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const readMore = getImagePath('icons/arrow-right-orange.png');

export class AuthorListCombo extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { data, darkMode } = this.props;
        return (
            <View style={[helpers.isDesktop() && Flex.row, { marginBottom: helpers.isDesktop() ? 80 : 50 }]}>
                <View style={[{ flexDirection: 'row', alignItems: helpers.isDesktop() ? 'flex-start' : 'center' }]}>
                    <CustomImage
                        source={data.photo}
                        require={true}
                        style={{ width: 85, height: 85, marginRight: 20 }}
                        webStyle={{ width: helpers.isDesktop() ? 180 : 130, height: helpers.isDesktop() ? 180 : 130, marginRight: 20 }}
                        className={'about-author-img'}
                        altText={data.name}
                    />
                    {!helpers.isDesktop() &&
                        <View style={{ flexDirection: 'column' }}>
                            <View className={'author-title-class'}>
                                <Text style={[aboutAuthor.title, helpers.isDesktop() && aboutAuthor.titleDesktop, darkMode && { color: Colors.lightWhite }]}>{data.name}</Text>
                            </View>
                            {/* <View style={[{ flexDirection: 'row', flexWrap: 'wrap' }]}>
                                <Text style={[authorItem.position, { fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: helpers.isDesktop() ? 22 : 19 }]}>Senior writter</Text>
                                <View style={[authorItem.dot, { position: 'relative', top: helpers.isDesktop() ? 3 : 0 }, { top: helpers.isDesktop() ? 4 : 1.8 }]} />
                                <Text style={[authorItem.location, { fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: helpers.isDesktop() ? 22 : 19 }]}>Mumbai</Text>
                            </View> */}
                        </View>
                    }
                </View>
                <View style={[Flex.fill]}>
                    {
                        helpers.isDesktop() && <Text style={[{ fontSize: 30, lineHeight: 44, fontFamily: FontFamily.medium, fontWeight: '500', color: darkMode ? Colors.lightWhite : '#000' }]}>{data.name}</Text>
                    }
                    {
                        helpers.isDesktop() ?
                            <Text style={[{ fontSize: 20, lineHeight: 28, fontFamily: FontFamily.regular, fontWeight: '400', letterSpacing: 0.1, paddingTop: 15, color: darkMode ? Colors.lightWhite : '#000' }]}>{data.description}</Text>
                            :
                            <Text style={[{ fontSize: 14, lineHeight: 24, fontFamily: FontFamily.regular, fontWeight: '400', letterSpacing: 0.1, paddingTop: 15, color: darkMode ? Colors.lightWhite : '#000' }]} >{data.description}</Text>
                    }
                    {/* {
                        !helpers.isDesktop() &&
                        <CustomImage
                            source={readMore}
                            style={{ width: 22, height: 13, resizeMode: 'cover', marginTop: 15 }}
                            webStyle={{ width: 22, height: 13, objectFit: 'cover', marginTop: 15 }}
                        />
                    } */}
                </View>
            </View>
        )
    }
}


export default AuthorListCombo;