import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { Flex, FontFamily, button } from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
const yesterdayBG = getImagePath("img/yesterdayBG.png");

const yesterdayBGmobile = getImagePath("img/yesterdayBGmobile.png");

const arrowDownYesterdayIcon = getImagePath("icons/arrowDownYesterdayIcon.png");

import ButtonGradient from "../../components/button-gradient";
import AuthenticationWrapper from "../authentication-wrapper";

class YesterdayHomeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { darkMode, isUserLoggedIn } = this.props;
    return (
      <>
        {!isUserLoggedIn ? (
          <View
            style={[
              !helpers.isDesktop() && Flex.row,
              !helpers.isDesktop() && Flex.alignCenter,
              {
                height: helpers.isDesktop() ? 800 : 730,
                width: "100%",
                overflow: "hidden",
                position: "relative",
                marginTop: "0px",
              },
            ]}
          >
            <CustomImage
              source={
                helpers.isDesktop()
                  ? yesterdayBG
                  : require("../../../../assets/img/yesterdayBGmobile.png")
              }
              require={true}
              style={[
                helpers.isWeb()
                  ? {
                    maxWidth: helpers.isDesktop() ? "100%" : "auto",
                    height: "auto",
                    width: 1920,
                  }
                  : { width: "100%" },
              ]}
              altText={"The Morning Context"}
              webStyle={{ width: helpers.isDesktop() ? "auto" : "100%" }}
            />
            <View style={{ width: "100%", position: "absolute", top: 27 }}>
              <View
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  {
                    position: "absolute",
                    top: helpers.isDesktop() ? 40 : 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  },
                ]}
              >
                <View
                  style={{
                    clipPath:
                      "polygon(0 0, 100% 0, 100% 100%, 50% 83%, 0 100%)",
                    background:
                      "linear-gradient(17.24deg, #55C79A 9.08%, #907CFF 58.44%, #FC876D 103.84%)",
                    width: helpers.isDesktop() ? 8.57 : 6.4,
                    height: helpers.isDesktop() ? 17.51 : 12.96,
                    alignSelf: "center"
                  }}
                ></View>
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 20 : 14,
                    fontWeight: "700",
                    color: "#ffffff",
                    paddingLeft: helpers.isDesktop() ? 16.79 : 13.25,
                    backgroundRepeat: "no-repeat",
                    lineHeight: helpers.isDesktop() ? 24 : 18,
                    fontFamily: "Matteo-bold",
                    letterSpacing: '2px',
                    whiteSpace: 'nowrap',
                    textAlign: "center"
                   }}
                >
                  NEWS EXPLAINER
                </Text>
              </View>
            </View>

            <View
              style={[
                Flex.row,
                Flex.justfiyCenter,
                { position: "absolute", bottom: 20, left: 0, width: "100%" },
              ]}
            >
              <TouchableOpacity style={{}}>
                <CustomImage
                  source={arrowDownYesterdayIcon}
                  require={true}
                  style={[
                    helpers.isWeb()
                      ? { width: 40, height: 40 }
                      : { width: 20, height: 10 },
                  ]}
                  resizeMode={"contain"}
                  altText={"The Morning Context"}
                />
              </TouchableOpacity>
            </View>
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Flex.justfiyCenter,
                {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                },
              ]}
            >
              <View style={[{ position: "relative", width: "100%" }]}>
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 72 : 40,
                      lineHeight: helpers.isDesktop() ? 80 : 44,
                      fontFamily: FontFamily.abrilFatface,
                      color: "#FFF",
                    },
                    {
                      marginBottom: helpers.isDesktop() ? 29 : 9,
                      textAlign: "center",
                    },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  News Explainers
                </Text>
                <Text
                  style={[
                    {
                      alignSelf: "center",
                      fontSize: helpers.isDesktop() ? 32 : 20,
                      lineHeight: helpers.isDesktop() ? 48 : 30,
                      fontFamily: FontFamily.light,
                      color: "#FFF",
                    },
                    {
                      textAlign: "center",
                      width: helpers.isDesktop() ? 870 : "80%",
                      margin: "auto",
                      marginBottom: helpers.isDesktop() ? 60 : 56,
                    },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  News without context is noise. Understand the most important news developments in technology, business, financial markets and current affairs. Curated and explained by us daily.
                </Text>
                <View style={{ padding: 20 }}>
                  <ButtonGradient
                    title={"SUBSCRIBE NOW"}
                    rootStyle={{
                      btnWrapper: [button.primaryGradient, { width: "100%" }],
                      btnText: [
                        button.primaryGradientText,
                        { fontSize: 16, fontWeight: "600" },
                      ],
                    }}
                    onClick={() =>
                      {
                        const dataLayer = window.dataLayer || [];
                        dataLayer.push({
                          event: "signup_initiate",
                          custom_user_id: "NA",
                          user_status: "guest",
                          CTA_text: "get started for free",
                          header_title: "NA",
                          CTA_position: "top",
                          article_name: "NA",
                          signup_method: "NA",
                        });
                        this.props.navigateWeb(`/sign-up?redirect=/&`);
                      }
                    }
                    darkMode={darkMode}
                  />
                </View>
              </View>
            </View>
          </View>
        ) : (
          <View
            style={[
              !helpers.isDesktop() && Flex.row,
              !helpers.isDesktop() && Flex.alignCenter,
              {
                height: helpers.isDesktop() ? 500 : 500,
                width: "100%",
                overflow: "hidden",
                position: "relative",
                marginTop: "0px",
              },
            ]}
          >
            <CustomImage
              source={yesterdayBG}
              require={true}
              style={[
                helpers.isWeb()
                  ? {
                    maxWidth: helpers.isDesktop() ? "100%" : "auto",
                    height: 424,
                    width: 1440,
                  }
                  : { width: "100%" },
              ]}
              altText={"The Morning Context"}
            />
            <View style={{ width: "100%", position: "absolute", top: 27 }}>
              <View
                 style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  {
                    position: "absolute",
                    top: helpers.isDesktop() ? 40 : 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  },
                ]}
              >
                <View
                  style={{
                    clipPath:
                      "polygon(0 0, 100% 0, 100% 100%, 50% 83%, 0 100%)",
                    background:
                      "linear-gradient(17.24deg, #55C79A 9.08%, #907CFF 58.44%, #FC876D 103.84%)",
                    width: helpers.isDesktop() ? 8.57 : 6.4,
                    height: helpers.isDesktop() ? 17.51 : 12.96,
                    alignSelf: "center"
                  }}
                ></View>
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 24 : 18,
                    fontWeight: "700",
                    color: "#ffffff",
                    paddingLeft: helpers.isDesktop() ? 16.79 : 13.25,
                    backgroundRepeat: "no-repeat",
                    lineHeight: helpers.isDesktop() ? 24 : 18,
                    fontFamily: "Matteo-bold",
                    letterSpacing: '2px',
                    whiteSpace: 'nowrap',
                    textAlign: "center"
                  }}
                >
                  NEWS EXPLAINER
                </Text>
              </View>
            </View>

            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Flex.justfiyCenter,
                {
                  position: "absolute",
                  top: helpers.isDesktop() ? 40 : 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                },
              ]}
            >
              <View style={[{ position: "relative", width: "100%" }]}>
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 72 : 40,
                      lineHeight: helpers.isDesktop() ? 80 : 44,
                      fontFamily: FontFamily.abrilFatface,
                      color: "#FFF",
                    },
                    { marginBottom: 29, textAlign: "center" },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  News Explainers
                </Text>
                <Text
                  style={[
                    {
                      alignSelf: "center",
                      fontSize: helpers.isDesktop() ? 32 : 20,
                      lineHeight: helpers.isDesktop() ? 48 : 30,
                      fontFamily: FontFamily.regular,
                      color: "#FFF",
                    },
                    {
                      textAlign: "center",
                      width: helpers.isDesktop() ? 870 : "80%",
                      margin: "auto",
                      marginBottom: helpers.isDesktop() ? 60 : 10,
                    },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                 News without context is noise. Understand the most important news developments in technology, business, financial markets and current affairs. Curated and explained by us daily.
                </Text>
              </View>
            </View>
          </View>
        )}
      </>
    );
  }
}

export default YesterdayHomeSection;
