import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TextInput,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  formStyle,
  Colors,
  button,
  subscribePlans,
  unlockBox,
  FontFamily,
  modalStyle,
  FontWeight,
} from "../../styles/appStyles";
import PageBreak from "../page-break";
import SubscriptionPlanBox from "../subscription-plan-box";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from "../button-gradient";
import Modal from "../modal";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserToken } from "../../../redux/User/user.actions";
import { CardComponent } from "@chargebee/chargebee-js-react-wrapper";
import config from "react-native-config";
import { ProgressCircle } from "react-native-material-indicators";
import { Mixpanel } from "../../utils/mixpanel";
import TouchableOpacityLink from "../touchable-opacity-link";
import ContentLoader from "react-native-easy-content-loader";
import TouchableOpacityLinkExternal from "../../components/touchable-opacity-link-external";
import AuthenticationWrapper from "../authentication-wrapper";

const iconChecked = getImagePath("icons/checkedIcon.png");
const iconUnChecked = getImagePath("icons/unCheckedIcon.png");
const iconCheckedSq = getImagePath("icons/checkedIconSq.png");
const iconUnCheckedSq = getImagePath("icons/unCheckedIconSq.png");
const lockIcon = getImagePath("icons/lockIcon.png");
const americanexpressIcon = getImagePath("icons/cards/americanexpress.png");
const defaultIcon = getImagePath("icons/cards/default-card.png");
const dinersIcon = getImagePath("icons/cards/diners.png");
const discoverIcon = getImagePath("icons/cards/discover.png");
const jcbIcon = getImagePath("icons/cards/jcb.png");
const mastercardIcon = getImagePath("icons/cards/mastercard.png");
const visaIcon = getImagePath("icons/cards/visa.png");
const closeIcon = getImagePath("icons/close-round.png");
const clock = getImagePath("img/bi_clock.png");
const couponIcon = getImagePath("icons/couponIcon.png");
const couponIconWhite = getImagePath("icons/couponIconWhite.png");

const failureIcon = getImagePath("icons/failure-icon.png");
const successIcon = getImagePath("icons/tick-icon.png");

const cookies = new Cookies();

export class SubscriptionPlanModal extends Component {
  cardRef = React.createRef();
  pollingTimeout;
  constructor(props) {
    super(props);
    if (window.chargebeeInit) {
      //Get instance
      if (
        (window.chargebeeInitCode == "IN" && this.props.country.code != "IN") ||
        (window.chargebeeInitCode != "IN" && this.props.country.code == "IN")
      ) {
        window.location.reload();
        return false;
      }
    } else {
      let chargebeeSiteIn = helpers.isWeb()
        ? process.env.CHARGEBEE_SITE_IN
        : config.CHARGEBEE_SITE_IN;
      let chargebeeApiEndpointIN = helpers.isWeb()
        ? process.env.CHARGEBEE_API_KEY_IN
        : config.CHARGEBEE_API_KEY_IN;
      let chargebeeSiteOt = helpers.isWeb()
        ? process.env.CHARGEBEE_SITE_OT
        : config.CHARGEBEE_SITE_OT;
      let chargebeeApiEndpointOT = helpers.isWeb()
        ? process.env.CHARGEBEE_API_KEY_OT
        : config.CHARGEBEE_API_KEY_OT;

      let CB_Options = {
        site:
          this.props.country.code == "IN" ? chargebeeSiteIn : chargebeeSiteOt,
        publishableKey:
          this.props.country.code == "IN"
            ? chargebeeApiEndpointIN
            : chargebeeApiEndpointOT,
      };
      if (this.props.country.code == "IN" && process.env.IS_PROD === "True") {
        CB_Options.domain = helpers.CB_CustomDomainIndia;
      }
      window.chargebeeInit = Chargebee.init(CB_Options);
      window.chargebeeInitCode = this.props.country.code == "IN" ? "IN" : "OTH";
    }
    /* Load Juspay SDK */
    PIL.load("1");

    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    let skip = false;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (
      webView &&
      process.browser &&
      window.location.search.includes("skip=")
    ) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("skip=");
      });
      if (foundSearch) {
        let search = foundSearch.replace("skip=", "");
        skip = search == "true" ? true : false;
      }
    }
    let hideCloseBtn =
      webView && process.browser && cookies.get("app_web_view") === "true"
        ? true
        : false;
    let isIndia = this.props.country.code == "IN" ? true : false;
    this.state = {
      token: tokenSet ? token : false,
      enableCancelSubscriptionModal: true,
      name: "",
      email: this.props.email ? this.props.email : "",
      password: "",
      pincode:
        this.props.userProfile && this.props.userProfile.pincode
          ? this.props.userProfile.pincode
          : "",
      phone:
        this.props.userProfile && this.props.userProfile.phone
          ? this.props.userProfile.phone
          : "",
      passwordToggle: true,
      paymentConfig: this.props.paymentConfig || {},
      couponCode: this.props.couponId ? this.props.couponId : "",
      paymentType: "",
      //         props.planData.tmc_group == 'credit' && isIndia
      //           ? 'juspay'
      //           : this.props.userProfile &&
      //             this.props.userProfile.payment_source &&
      //             this.props.userProfile.payment_source.length > 0
      //           ? 'saved-card'
      //           : 'chargebee',
      paymentSource:
        this.props.userProfile &&
        this.props.userProfile.payment_source &&
        this.props.userProfile.payment_source.length > 0
          ? this.props.userProfile.payment_source[0]
          : false,
      applyGst:
        this.props.userProfile && this.props.userProfile.GSTIN && isIndia
          ? true
          : false,
      gstCompanyName:
        this.props.userProfile && this.props.userProfile.company_name
          ? this.props.userProfile.company_name
          : "",
      gstin:
        this.props.userProfile && this.props.userProfile.GSTIN
          ? this.props.userProfile.GSTIN
          : "",
      enableLoader: "load",
      paymentIntent: false,
      subscriptionComplete: false,
      currentSubscription:
        tokenSet && !this.props.renewPlan && !this.props.buyCredits
          ? this.props.userProfile &&
            JSON.stringify(this.props.userProfile.subscription) != "{}"
            ? this.props.userProfile.subscription
            : this.props.userProfile &&
              JSON.stringify(this.props.userProfile.team_subscription) != "{}"
            ? this.props.userProfile.team_subscription
            : false
          : false,
      breakupAmount: false,
      breakupDifference: false,
      breakupTax: false,
      showBreakUp: false,
      skip: skip,
      windowHref: false,
      isIndia: isIndia,
      error: {
        name: false,
        email: false,
        password: false,
        phone: false,
        pincode: false,
        card: false,
        card_error: false,
        company: false,
        gstin: false,
      },
      cardComplete: false,
      styles: {
        base: {
          color: darkMode ? "#ffffff" : "#000000",
          fontWeight: "500",
          fontFamily:
            "Lato, BlinkMacSystemFont, Segoe UI, Helvetica Neue, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          borderColor: "#BCBCBC",
          ":focus": {
            color: darkMode ? "#ffffff" : "#000000",
          },
          "::placeholder": {
            color: darkMode ? "#ffffff" : "#8A8A8A",
          },
          ":focus::placeholder": {
            color: darkMode ? "#ffffff" : "#D8D8D8",
          },
        },
      },
      quantity: this.props.quantity ? this.props.quantity : 1,
      showCoupon: false,
      couponValid: false,
      couponLoader: false,
      couponRemoveAllowed: true,
      breakupDiscount: false,
      hideCloseBtn: hideCloseBtn,
    };
  }

  componentDidMount = () => {
    try {
      Mixpanel.time_event("Subscription success");
      Mixpanel.time_event("Subscription failed");
    } catch (error) {
      console.log("mix panel error ==>", error);
    }
    this.setDefaultPaymentMethod();

    if (this.state.couponCode !== "") {
      this.getEstimate(true, true);
    } else {
      this.getEstimate();
    }
  };

  componentDidUpdate = () => {
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    if (
      (token && token != this.state.token) ||
      ((token == undefined || token == "") && this.state.token != false)
    ) {
      let tokenSet = token == undefined || token == "" ? false : true;
      this.setState({
        token: tokenSet ? token : false,
      });
    }
  };

  componentWillUnmount() {
    this.clearPollingTimeout();
  }

  clearPollingTimeout() {
    if (this.pollingTimeout) {
      clearTimeout(this.pollingTimeout);
    }
  }

  /*
    set default selection for applicable payment methods
  */
  setDefaultPaymentMethod = () => {
    const { paymentConfig, token } = this.state;
    const availablePaymentMethods = helpers.activePaymentMethods;

    if (!token) {
      this.setState({
        paymentType: "chargebee",
      });
      return true;
    }
    if (!paymentConfig || !availablePaymentMethods) {
      throw "No payment config in state";
    }
    let paymentMethods = {};
    availablePaymentMethods.forEach((pm) => {
      paymentMethods[pm] = true;
    });

    paymentConfig.excludeGateways.forEach((pm) => {
      delete paymentMethods[pm];
    });

    let defaultPM;
    if (paymentMethods["chargebee"]) {
      defaultPM = "chargebee";
    } else {
      defaultPM = Object.keys(paymentMethods)[0];
    }

    if (!defaultPM) {
      throw "No payment method available(all available payment methods are excluded) for this plan";
    }

    this.setState({
      paymentType: defaultPM,
    });

    return true;
  };

  /*
    given a payment_method check if available in helpers.activePaymentMethods and not in paymentConfig.excludeGateways
  */
  checkPaymentMethod = (payment_method) => {
    const activePaymentMethods = helpers.activePaymentMethods;
    const defaultPaymentMethod = helpers.defaultPaymentMethod;
    const { paymentConfig } = this.state;

    if (!paymentConfig || !activePaymentMethods || !defaultPaymentMethod) {
      return false;
    }

    if (!paymentConfig.excludeGateways) {
      return false;
    }

    if (!activePaymentMethods.includes(payment_method)) {
      return false;
    }

    if (paymentConfig.excludeGateways.includes(payment_method)) {
      return false;
    }

    return true;
  };

  getEstimate = (fetchCoupon, couponApply) => {
    const { token, currentSubscription, couponCode, error } = this.state;
    const { planData, quantity, buyCredits, themeData } = this.props;
    if (fetchCoupon) {
      if (couponCode == "" && couponApply) {
        return false;
      }
      this.setState({
        couponLoader: true,
        error: { ...error, coupon: false },
      });
    }
    let endpoint =
      buyCredits || planData.tmc_group == "gift_credit"
        ? `/credits-estimate`
        : `/subscription-estimate`;
    let body = {
      plan_id: planData.id,
    };
    if (fetchCoupon && couponCode != "") {
      body["coupon"] = [couponCode];
    }
    if (planData.tmc_group == "team") {
      body.quantity =
        parseInt(quantity ? quantity : 1) +
        (currentSubscription?.plan?.tmc_group == "team"
          ? parseInt(currentSubscription.team_details?.seats_purchased)
          : 0);
    }
    if (
      planData.tmc_group == "gift_credit" ||
      planData.tmc_group == "gift_plan"
    ) {
      body.is_gift = true;
      body.gift = themeData;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (
          res.success &&
          res.code ==
            (buyCredits || planData.tmc_group == "gift_credit"
              ? "credits_estimate"
              : "subscription_estimate")
        ) {
          let response = {
            breakupAmount: res.data.amount_due,
            breakupDifference: res.data.credits_applied,
            breakupTax: res.data.total_tax_amount
              ? res.data.total_tax_amount
              : 0,
          };
          if (fetchCoupon) {
            response.couponValid = couponCode != "" ? true : false;
            response.couponLoader = false;
            response.breakupDiscount =
              couponCode != "" ? res.data.discounts[0].amount : false;
            response.enableLoader = false;
          } else {
            response.enableLoader = false;
            if (res.data.discounts && res.data.discounts[0]) {
              response.couponCode = res.data.discounts[0].entity_id;
              response.couponValid = true;
              response.showCoupon = true;
              response.breakupDiscount = res.data.discounts[0].amount;
              // response.couponRemoveAllowed = false;
            }
          }
          this.setState(response);
        } else {
          if (fetchCoupon) {
            this.setState({
              couponLoader: false,
              error: {
                ...error,
                coupon: res?.error?.coupon ? res.error.coupon : false,
              },
            });
          } else {
            this.closePlanModal();
          }
          console.log("ResError=>>", res);
        }
      })
      .catch((e) => {
        if (fetchCoupon) {
          this.setState({
            couponLoader: false,
            error: {
              ...error,
              coupon:
                typeof e === "string" ? e : e?.message ? e.message : false,
            },
          });
        } else {
          this.closePlanModal();
        }
        console.log("Error=>>", e);
      });
  };

  validateEmail = (email) => {
    let { planData, isTeam } = this.props;
    let emailID = helpers.validateEmail(email);
    let freeEmailForTeam = false;
    if (emailID && isTeam) {
      freeEmailForTeam = helpers.isFreeEmailId(
        this.props.userProfile ? this.props.userProfile.email : email
      );
    }
    this.setState({
      email: email,
      error: {
        ...this.state.error,
        email: freeEmailForTeam
          ? "Please provide your company email id as you will only be able to add members whose email ids are associated to your company domain."
          : false,
      },
    });
  };

  validatePincode = (pincode) => {
    const { isIndia } = this.state;
    let key = pincode.split("");
    let keyList = isIndia
      ? ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
      : [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "0",
        ];
    for (var i = key.length - 1; i >= 0; i--) {
      if (keyList.indexOf(key[i]) == -1) {
        return false;
      }
    }
    if (isIndia && key.length > 6) {
      return false;
    }
    this.setState({
      pincode: pincode,
      error: { ...this.state.error, pincode: false },
    });
  };

  validateMobile = (phone) => {
    let key = phone.split("");
    let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    for (var i = key.length - 1; i >= 0; i--) {
      if (keyList.indexOf(key[i]) == -1) {
        return false;
      }
    }
    if (key.length > 10) {
      return false;
    }
    this.setState({
      phone: phone,
      error: { ...this.state.error, phone: false },
    });
  };

  validateGSTIN = (gstin) => {
    let key = gstin.split("");
    if (key.length > 15) {
      return false;
    }
    this.setState({
      gstin: gstin,
      error: { ...this.state.error, gstin: false },
    });
  };

  closeModal = () => {
    // if(this.chargebeeInit) {
    //     this.chargebeeInit.logout();
    // }
    const { closeModal, planData, themeData } = this.props;
    let giftData;
    if (
      planData.tmc_group == "gift_credit" ||
      planData.tmc_group == "gift_plan"
    ) {
      giftData = themeData;
    }
    closeModal(this.state.subscriptionComplete, giftData);
  };

  closePlanModal = () => {
    this.refs.child.closeModal();
  };

  pushPlaceYourOrderEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "place_your_order",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      plan_name: this.props.planData.name,
      plan_price: this.props.planData.price,
      avail_gst_status: this.state.applyGst ? "yes" : "no"
    });
  };

  submitPay = () => {
    let { planData, isTeam } = this.props;

    this.pushPlaceYourOrderEventToDataLayer()

    this.setState({
      error: {
        name: false,
        email: false,
        password: false,
        phone: false,
        pincode: false,
        card: false,
        card_error: false,
        company: false,
        gstin: false,
        email_error: false,
      },
      enableLoader: "initial",
    });

    const {
      name,
      email,
      password,
      pincode,
      phone,
      paymentType,
      token,
      cardComplete,
      applyGst,
      gstCompanyName,
      gstin,
      isIndia,
      couponCode,
      couponValid,
      couponRemoveAllowed,
    } = this.state;
    let emailID = helpers.validateEmail(email);
    let freeEmailForTeam = false;
    if ((this.props.userProfile || emailID) && isTeam) {
      freeEmailForTeam = helpers.isFreeEmailId(
        this.props.userProfile ? this.props.userProfile.email : email
      );
    }

    if (
      (this.props.userProfile ||
        (name != "" && emailID && password.length >= 6)) &&
      (pincode.length == 6 || (!isIndia && pincode != "")) &&
      (!isIndia || phone.length == 10) &&
      (paymentType != "chargebee" || cardComplete) &&
      (!applyGst || (gstCompanyName != "" && gstin != "")) &&
      !freeEmailForTeam
    ) {
      if (token) {
        if (paymentType == "saved-card") {
          this.cardVerify();
        } else if (paymentType == "chargebee") {
          this.getIntent();
        } else {
          this.upiSubmit();
        }
      } else {
        this.emailSignUp();
      }
      try {
        if (helpers.isWeb()) {
          let couponUsed = false;
          if (couponCode != "" && couponValid && couponRemoveAllowed) {
            couponUsed = true;
          }
          if (planData.tmc_group == "individual") {
            Mixpanel.track("click_place_subscription_order", {
              plan: planData.name,
              price: planData.price,
              discount:
                planData.discount && planData.discount.amount ? "yes" : "no",
              payment_platform: paymentType,
              coupon_applied: couponUsed ? "yes" : "no",
            });
          } else if (planData.tmc_group == "credit") {
            Mixpanel.track("click_privilege_credits_order", {
              price: planData.price,
              discount:
                planData.discount && planData.discount.amount ? "yes" : "no",
              payment_platform: paymentType,
              coupon_applied: couponUsed ? "yes" : "no",
            });
          } else {
            Mixpanel.track("click_place_subscription_order", {
              plan: planData.name,
              price: planData.price,
              discount:
                planData.discount && planData.discount.amount ? "yes" : "no",
              payment_platform: paymentType,
              coupon_applied: couponUsed ? "yes" : "no",
            });
          }
        }
      } catch (error) {
        console.log("mix panel error =>", error);
      }
    } else {
      this.setState({
        error: {
          name: name == "" ? "Please enter a valid name" : false,
          email: !emailID
            ? "Please enter a valid email"
            : freeEmailForTeam && token == false
            ? "Please provide your company email id as you will only be able to add members whose email ids are associated to your company domain."
            : false,
          password:
            password.length < 6 ? "Please enter a valid password" : false,
          phone:
            isIndia && phone.length != 10
              ? "Please enter a valid number"
              : false,
          pincode:
            pincode == "" || (isIndia && pincode.length != 6)
              ? "Please enter a valid " + (isIndia ? "pincode" : "zipcode")
              : false,
          card: paymentType == "chargebee" && !cardComplete ? true : false,
          card_error: false,
          email_error:
            freeEmailForTeam && token
              ? "To purchase a team subscription you need to signup using your company email id. You will only be able to add members whose email ids are associated to your company domain."
              : false,
          company:
            applyGst && gstCompanyName == ""
              ? "Please enter a valid name"
              : false,
          gstin: applyGst && gstin == "" ? "Please enter a valid gstin" : false,
        },
        enableLoader: false,
      });
    }
  };

  emailSignUp = () => {
    const {
      name,
      email,
      password,
      paymentType,
      pincode,
      phone,
      applyGst,
      gstCompanyName,
      gstin,
      isIndia,
    } = this.state;
    const { isTeam } = this.props;
    let type = helpers.isWeb() ? "web" : "app";
    let endpoint = `/sign-up`;
    let body = {
      full_name: name,
      account_email: email.toLowerCase(),
      password: password,
      pincode: pincode.toLowerCase(),
      redirect_url: null,
      has_subscription: true,
      type: type,
      device_data: {},
    };
    if (isIndia) {
      body["phone"] = phone;
    }
    if (applyGst) {
      body["company_name"] = gstCompanyName;
      body["GSTIN"] = gstin;
    }
    if (isTeam) {
      body["company_email"] = true;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.code == "acc_created") {
          try {
            Mixpanel.identify(res.data.reference_id);
            Mixpanel.people.set({
              name: res.data.full_name,
              $email: res.data.account_email,
            });
            Mixpanel.track("signup", { method: "subscription" }, true);
          } catch (error) {
            console.log("mix panel error ==>", error);
          }
          try {
            GoogleAnalytics.event("Account", "signup", "subscription");
          } catch (error) {
            console.log("google analytics error ==>", error);
          }

          if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            cookies.set("token", res.data.token, {
              path: "/",
              expires: cookieDate,
            });
          }
          const userdata = {
            data: res.data,
          };
          this.props.setUserToken(userdata);
          this.setState(
            {
              token: res.data.token,
            },
            () =>
              paymentType == "chargebee" ? this.getIntent() : this.upiSubmit()
          );
        } else {
          this.setState({
            enableLoader: false,
            error: {
              name:
                res.code == "error" && res.data.error.full_name
                  ? res.data.error.full_name
                  : false,
              email:
                res.code == "error" && res.data.error.account_email
                  ? res.data.error.account_email
                  : false,
              password:
                res.code == "error" && res.data.error.password
                  ? res.data.error.password
                  : false,
              phone: false,
              pincode: false,
              card: false,
              card_error: false,
              company: false,
              gstin: false,
            },
          });
          console.log("ResError=>>", res);
        }
      })
      .catch((error) => {
        this.setState({
          enableLoader: false,
          error: {
            name: false,
            email: false,
            password: false,
            phone: false,
            pincode: "Network Error",
            card: false,
            card_error: false,
            company: false,
            gstin: false,
          },
        });
        console.log("Error=>>", error);
      });
  };

  getIntent = () => {
    this.setState({
      enableLoader: "process",
    });
    const {
      token,
      pincode,
      isIndia,
      phone,
      quantity,
      currentSubscription,
      couponCode,
      couponValid,
      couponRemoveAllowed,
    } = this.state;
    const { planData, country, themeData } = this.props;
    let endpoint = `/payment-intent`;
    let body = {
      plan_id: planData.id,
      billing_infomation: {
        zip: pincode.toLowerCase(),
        country: country.code,
      },
    };
    if (planData.tmc_group == "team") {
      body.quantity =
        parseInt(quantity ? quantity : 1) +
        (currentSubscription?.plan?.tmc_group == "team"
          ? parseInt(currentSubscription.team_details?.seats_purchased)
          : 0);
    }
    if (isIndia) {
      body.billing_infomation["phone_no"] = phone;
    }
    if (couponCode != "" && couponValid && couponRemoveAllowed) {
      body["coupon"] = [couponCode];
    }
    if (
      planData.tmc_group == "gift_credit" ||
      planData.tmc_group == "gift_plan"
    ) {
      body.is_gift = true;
      body.gift = themeData;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "payment_intent") {
          this.setState(
            {
              paymentIntent: res.data,
            },
            () => this.cardSubmit()
          );
        } else {
          this.setState({
            enableLoader: false,
            error: {
              name: false,
              email: false,
              password: false,
              phone: false,
              pincode: false,
              card: true,
              card_error: false,
              company: false,
              gstin: false,
            },
          });
          console.log("ResError=>>", res);
        }
      })
      .catch((error) => {
        this.setState({
          enableLoader: false,
          error: {
            name: false,
            email: false,
            password: false,
            phone: false,
            pincode: false,
            card: true,
            card_error: false,
            company: false,
            gstin: false,
          },
        });
        console.log("Error=>>", error);
      });
  };

  cardSubmit = () => {
    const {
      token,
      pincode,
      skip,
      paymentType,
      applyGst,
      gstCompanyName,
      gstin,
      isIndia,
      phone,
      quantity,
      currentSubscription,
      couponCode,
      couponValid,
      couponRemoveAllowed,
      paymentConfig,
    } = this.state;
    const { planData, country, renewPlan, buyCredits, isTeam, themeData } =
      this.props;
    const paymentIntent = this.state.paymentIntent;

    const enableMandate = paymentConfig ? paymentConfig.requireMandate : false;

    this.cardRef.current
      .authorizeWith3ds(paymentIntent.payment_intent, {
        billingAddress: {
          ...paymentIntent.billing_infomation
        },
        mandate: {
          requireMandate: enableMandate,
          description: planData.name,
        },
        email: this.props.userProfile?.email,
        phone: phone,
      })
      .then((authorizedPaymentIntent) => {
        if (skip) {
          this.setState({
            enableLoader: false,
          });
          return false;
        }
        // this.setState({
        //     enableLoader: 'capture',
        // });
        let endpoint = renewPlan
          ? `/renew-subscription`
          : buyCredits || planData.tmc_group == "gift_credit"
          ? `/buy-credits`
          : `/subscribe-to-plan/default`;
        let body = {
          plan_id: planData.id,
          payment: {
            payment_intent: {
              id: authorizedPaymentIntent.id,
            },
          },
          payment_collection_channel: paymentType,
          billing_infomation: {
            zip: pincode.toLowerCase(),
            country: country.code,
          },
        };
        if (planData.tmc_group == "team") {
          body.quantity =
            parseInt(quantity ? quantity : 1) +
            (currentSubscription?.plan?.tmc_group == "team"
              ? parseInt(currentSubscription.team_details?.seats_purchased)
              : 0);
        }
        if (isIndia) {
          body.billing_infomation["phone_no"] = phone;
        }
        if (applyGst) {
          body["company_name"] = gstCompanyName;
          body["GSTIN"] = gstin;
        }
        if (isTeam) {
          body["company_email"] = true;
        }
        if (couponCode != "" && couponValid && couponRemoveAllowed) {
          body["coupon"] = [couponCode];
        }
        if (
          planData.tmc_group == "gift_credit" ||
          planData.tmc_group == "gift_plan"
        ) {
          body.is_gift = true;
          body.gift = themeData;
        }
        apiHelpers
          .apiRequest(endpoint, body, "POST", true, token)
          .then((res) => {
            if (res.code == "order_created") {
              this.setState({
                enableLoader: "capture",
              });
              this.pollingSubscriptionResponse(
                res.data.order.order_ref_id,
                "chargebee",
                3000,
                0,
                0
              );
            } else {
              this.setState({
                enableLoader: false,
                error: {
                  name: false,
                  email: false,
                  password: false,
                  phone: false,
                  pincode: res?.error?.pincode ? res.error.pincode : false,
                  card: res?.error?.pincode ? false : true,
                  card_error: res?.error?.pincode
                    ? false
                    : res?.error?.message
                    ? res.error.message
                    : res?.error?.error?.message
                    ? res.error.error.message
                    : false,
                  company: false,
                  gstin: false,
                },
              });
              console.log("ResError=>>", res);
            }
          })
          .catch((error) => {
            this.setState({
              enableLoader: false,
              error: {
                name: false,
                email: false,
                password: false,
                phone: false,
                pincode: false,
                card: true,
                card_error: error?.message ? error.message : false,
                company: false,
                gstin: false,
              },
            });
            console.log("Error=>>", error);
          });
      })
      .catch((error) => {
        this.setState({
          enableLoader: false,
          error: {
            name: false,
            email: false,
            password: false,
            phone: false,
            pincode: false,
            card: true,
            card_error:
              typeof error === "string"
                ? error
                : error?.message
                ? error.message
                : false,
            company: false,
            gstin: false,
          },
        });
        console.log("Error=>>", error);
      });
  };

  cardVerify = () => {
    this.setState({
      enableLoader: "process",
    });
    const {
      token,
      pincode,
      skip,
      paymentType,
      applyGst,
      gstCompanyName,
      gstin,
      paymentSource,
      isIndia,
      phone,
      quantity,
      currentSubscription,
      couponCode,
      couponValid,
      couponRemoveAllowed,
    } = this.state;
    const { planData, country, renewPlan, buyCredits, isTeam, themeData } =
      this.props;

    let endpoint = renewPlan
      ? `/renew-subscription`
      : buyCredits || planData.tmc_group == "gift_credit"
      ? `/buy-credits`
      : `/subscribe-to-plan/default`;
    let body = {
      plan_id: planData.id,
      payment: {
        payment_source_id: paymentSource.payment_source_id,
      },
      payment_collection_channel: "chargebee",
      billing_infomation: {
        zip: pincode.toLowerCase(),
        country: country.code,
      },
    };
    if (planData.tmc_group == "team") {
      body.quantity =
        parseInt(quantity ? quantity : 1) +
        (currentSubscription?.plan?.tmc_group == "team"
          ? parseInt(currentSubscription.team_details?.seats_purchased)
          : 0);
    }
    if (isIndia) {
      body.billing_infomation["phone_no"] = phone;
    }
    if (applyGst) {
      body["company_name"] = gstCompanyName;
      body["GSTIN"] = gstin;
    }
    if (isTeam) {
      body["company_email"] = true;
    }
    if (couponCode != "" && couponValid && couponRemoveAllowed) {
      body["coupon"] = [couponCode];
    }
    if (
      planData.tmc_group == "gift_credit" ||
      planData.tmc_group == "gift_plan"
    ) {
      body.is_gift = true;
      body.gift = themeData;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "order_created") {
          this.setState({
            enableLoader: "capture",
          });
          this.pollingSubscriptionResponse(
            res.data.order.order_ref_id,
            "chargebee",
            3000,
            0,
            0
          );
        } else {
          this.setState({
            enableLoader: false,
            error: {
              name: false,
              email: false,
              password: false,
              phone: false,
              pincode: res?.error?.pincode ? res.error.pincode : false,
              card: res?.error?.pincode ? false : true,
              card_error: res?.error?.pincode
                ? false
                : res?.error?.message
                ? res.error.message
                : res?.error?.error?.message
                ? res.error.error.message
                : false,
              company: false,
              gstin: false,
            },
          });
          console.log("ResError=>>", res);
        }
      })
      .catch((error) => {
        this.setState({
          enableLoader: false,
          error: {
            name: false,
            email: false,
            password: false,
            phone: false,
            pincode: false,
            card: true,
            card_error: error?.message ? error.message : false,
            company: false,
            gstin: false,
          },
        });
        console.log("Error=>>", error);
      });
  };

  upiSubmit = () => {
    this.setState({
      enableLoader: "process",
    });
    const {
      token,
      pincode,
      phone,
      skip,
      paymentType,
      applyGst,
      gstCompanyName,
      gstin,
      quantity,
      currentSubscription,
      couponCode,
      couponValid,
      couponRemoveAllowed,
    } = this.state;
    const { planData, country, renewPlan, buyCredits, isTeam, themeData } =
      this.props;
    let endpoint = renewPlan
      ? `/renew-subscription`
      : buyCredits || planData.tmc_group == "gift_credit"
      ? `/buy-credits`
      : `/subscribe-to-plan/default`;
    console.log("Plan", planData);
    let redirect_url = {
      success: window.location.pathname,
      retry:
        window.location.pathname + window.location.search + "&close-modal=true",
    };
    if (themeData) {
      redirect_url.giftData = themeData;
    }
    redirect_url = JSON.stringify(redirect_url);
    let searchArr = window.location.search.replace("?", "").split("&");
    let success_redirect_url;
    if (searchArr.length > 0 && searchArr[0] != "") {
      if (window.location.search.includes("success_redirect")) {
        success_redirect_url = searchArr[1];
      }
    }
    let payment_redirect_url = window.location.origin + "/payment-redirect";
    if (success_redirect_url) {
      payment_redirect_url =
        window.location.origin + "/payment-redirect?" + success_redirect_url;
    }
    let body = {
      plan_id: planData.id,
      juspay_redirect_url: payment_redirect_url,
      redirect_url: redirect_url,
      payment_collection_channel: paymentType,
      billing_infomation: {
        zip: pincode.toLowerCase(),
        country: country.code,
        phone_no: phone,
      },
    };
    if (planData.tmc_group == "team") {
      body.quantity =
        parseInt(quantity ? quantity : 1) +
        (currentSubscription?.plan?.tmc_group == "team"
          ? parseInt(currentSubscription.team_details?.seats_purchased)
          : 0);
    }
    if (applyGst) {
      body["company_name"] = gstCompanyName;
      body["GSTIN"] = gstin;
    }
    if (isTeam) {
      body["company_email"] = true;
    }
    if (couponCode != "" && couponValid && couponRemoveAllowed) {
      body["coupon"] = [couponCode];
    }
    if (
      planData.tmc_group == "gift_credit" ||
      planData.tmc_group == "gift_plan"
    ) {
      body.is_gift = true;
      body.gift = themeData;
      console.log("body", body);
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        console.log("res", res);
        if (res.code == "order_created") {
          JuspayPaymentsSdk.startApp(res.data.juspay_data);
          // if(helpers.isDesktop()) {
          //     window.paymentInitWindow = window.open(res.data.juspay_data.payment_links.web, '_blank');
          //     if(window.paymentInitWindow == null) {
          //         this.setState({windowHref: res.data.juspay_data.payment_links.web})
          //     }
          // } else {
          //     window.paymentInitWindow = window.open(res.data.juspay_data.payment_links.mobile, '_blank');
          //     if(window.paymentInitWindow == null) {
          //         this.setState({windowHref: res.data.juspay_data.payment_links.mobile})
          //     }
          // }
          // this.setState({
          //     enableLoader: 'capture',
          // });
          // this.pollingSubscriptionResponse(res.data.order.order_ref_id, 'juspay', 10000, 0, 0);
        } else {
          this.setState({
            enableLoader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          enableLoader: false,
        });
        console.log("Error=>>", error);
      });
  };

  pollingSubscriptionResponse = (order_id, channel, time, tries, fails) => {
    if (tries >= 15) {
      this.setState({
        enableLoader: "no-response",
      });
      return false;
    }
    const { token } = this.state;
    let childRef = this.refs.child;
    if (
      window.paymentInitWindow &&
      window.paymentInitWindow.closed &&
      window.paymentWindowComplete == null
    ) {
      try {
        Mixpanel.track("Subscription failed", {
          plan: this.props.planData.name,
          channel: channel,
        });
      } catch (error) {
        console.log("mix panel error ==>", error);
      }
      this.setState({
        subscriptionComplete: "failed",
        enableLoader: false,
      });
      return false;
    }
    let endpoint = `/order-status`;
    let body = {
      order_id: order_id,
    };
    this.pollingTimeout = setTimeout(() => {
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.code == "order_status" && res.data.status == "success") {
            try {
              Mixpanel.track("Subscription success", {
                plan: this.props.planData.name,
                channel: channel,
              });
            } catch (error) {
              console.log("mix panel error ==>", error);
            }
            this.setState({
              subscriptionComplete: "success",
              enableLoader: false,
            });
          } else if (
            res.code == "order_status" &&
            res.data.status == "failed"
          ) {
            try {
              Mixpanel.track("Subscription failed", {
                plan: this.props.planData.name,
                channel: channel,
              });
            } catch (error) {
              console.log("mix panel error ==>", error);
            }
            this.setState({
              subscriptionComplete: "failed",
              enableLoader: false,
            });
          } else {
            let setTime =
              channel == "chargebee"
                ? 3000
                : window.paymentWindowComplete == null
                ? 10000
                : 3000;
            this.pollingSubscriptionResponse(
              order_id,
              channel,
              setTime,
              setTime == 3000 ? tries + 1 : tries,
              fails
            );
          }
        })
        .catch((error) => {
          console.log("Error=>>", error);
          // if(fails < 5) {
          //     let setTime = (channel == 'chargebee') ? 3000 : ((window.paymentWindowComplete == null) ? 10000 : 3000);
          //     this.pollingSubscriptionResponse(order_id, channel, setTime, ((setTime == 3000) ? (tries + 1) : tries), fails + 1);
          // } else {
          //     //Manual
          //     console.log("Manual===>>>")
          // }
          let setTime =
            channel == "chargebee"
              ? 3000
              : window.paymentWindowComplete == null
              ? 10000
              : 3000;
          this.pollingSubscriptionResponse(
            order_id,
            channel,
            setTime,
            setTime == 3000 ? tries + 1 : tries,
            fails
          );
        });
    }, time);
  };

  openPaymentInitWindow = () => {
    window.paymentInitWindow = window.open(this.state.windowHref, "_blank");
    this.setState({ windowHref: false });
  };

  logout = () => {
    this.props.logout();
    this.setState(
      {
        currentSubscription: false,
        pincode: "",
        applyGst: false,
        gstCompanyName: "",
        gstin: "",
        phone: "",
        breakupAmount: false,
        breakupDifference: false,
        breakupTax: false,
        enableLoader: "load",
        error: {
          name: false,
          email: false,
          password: false,
          phone: false,
          pincode: false,
          card: false,
          card_error: false,
          company: false,
          gstin: false,
          email_error: false,
        },
      },
      () => {
        this.getEstimate();
      }
    );
  };

  naviagteToLogin = () => {
    if (helpers.isWeb()) {
      let redirectURL = `/sign-in?redirect=${
        window.location.pathname
      }&redirect-params=${window.location.search.substring(1)}`;
      this.props.navigateWeb(redirectURL);
    } else {
      // TODO
    }
  };

  getCardIcon = (brand) => {
    let cardIcon;
    switch (brand) {
      case "visa":
        cardIcon = visaIcon;
        break;
      case "mastercard":
        cardIcon = mastercardIcon;
        break;
      case "american_express":
        cardIcon = americanexpressIcon;
        break;
      case "discover":
        cardIcon = discoverIcon;
        break;
      case "jcb":
        cardIcon = jcbIcon;
        break;
      case "diners_club":
        cardIcon = dinersIcon;
        break;
      default:
        cardIcon = defaultIcon;
        break;
    }
    return cardIcon;
  };

  renderTopLine = (showMargin, hide) => {
    const { enableLoader, breakupAmount, hideCloseBtn } = this.state;
    const { planData, isTeam, renewPlan, buyCredits } = this.props;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.bgLightOrange,
          showMargin ? Margin.mb_2 : Margin.mb_1,
          hide && { display: "none" },
          darkMode && { backgroundColor: Colors.darkBlackColor },
        ]}
      >
        <Text
          style={[
            Typo.text20,
            helpers.isDesktop() && Helpers.textLeft,
            { paddingVertical: 15, paddingLeft: 25 },
            { textTransform: "capitalize" },
            darkMode && { color: "#ffffff" },
          ]}
        >
          {planData.name} :
          {!(
            isTeam &&
            !renewPlan &&
            !buyCredits &&
            breakupAmount === false
          ) && (
            <>
              <Text
                style={[
                  subscribePlans.symbol,
                  {
                    fontSize: 20,
                    lineHeight: 24,
                    marginRight: 0,
                    paddingRight: 0,
                    fontWeight: "600",
                  },
                ]}
              >
                {" "}
                {planData.currency_code == "INR" ? "₹" : "$"}{" "}
              </Text>
              <Text style={{ color: "#fc876d", fontWeight: "600" }}>
                {breakupAmount !== false
                  ? breakupAmount
                  : planData?.discount?.offer_price
                  ? planData.discount.offer_price
                  : planData.price}
              </Text>
            </>
          )}
        </Text>
        {/* {!hideCloseBtn &&
                    <View style={[{ position: 'absolute', right: helpers.isDesktop() ? 15 : 15, top: helpers.isDesktop() ? 5 : 5, zIndex: 1 }]}>
                        <TouchableOpacity
                            activeOpacity={0.8}
                            style={[modalStyle.closeButton]}
                            onPress={() => this.closeModal()}
                        >
                            <CustomImage
                                source={closeIcon}
                                style={{ width: 28, height: 28 }}
                                webStyle={{ width: 28, height: 28 }}
                                require={true}
                            />
                        </TouchableOpacity>
                    </View>
                } */}
      </View>
    );
  };

  renderOrderDetailsLoader = () => {
    return (
      <View>
        {/*<ContentLoader
                    containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
                    active={true}
                    pHeight={[15]}
                    title={false}
                    pRows={1}
                    pWidth={['100px']}
                    animationDuration={400}
                />*/}
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "190px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[15]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
          <View style={{ width: "60px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[15]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "190px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[15]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
          <View style={{ width: "60px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[15]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "190px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[15]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
          <View style={{ width: "60px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[15]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
        </View>
      </View>
    );
  };

  renderCouponLoader = () => {
    return (
      <View style={[formStyle.inputBorderBox]}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={{ width: "100px" }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingHorizontal: 0 }}
              active={true}
              pHeight={[10]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
            />
          </View>
          <View>
            <ActivityIndicator size={20} color={Colors.purple} />
          </View>
        </View>
      </View>
    );
  };

  renderPaymentRedirectSucess = () => {
    return (
      <View
        style={[
          Margin.mb_4,
          { marginTop: 0 },
          { paddingVertical: 0, paddingHorizontal: 20 },
          { marginHorizontal: 15 },
        ]}
      >
        <View style={[Flex.alignCenter, Margin.mb_4]}>
          <CustomImage
            source={successIcon}
            require={true}
            style={{ width: "auto", height: helpers.isDesktop() ? 186 : 140 }}
            webStyle={{
              width: "auto",
              height: helpers.isDesktop() ? 186 : 140,
            }}
          />
        </View>
        <View
          style={{
            maxWidth: helpers.isDesktop() ? 300 : "100%",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            marginBottom: 60,
          }}
        >
          <Text
            style={{
              fontFamily: FontFamily.regular,
              fontWeight: FontWeight.semiBold,
              fontSize: 28,
              lineHeight: 50,
              letterSpacing: 0.3,
              textAlign: "center",
              color: "#55C79A",
            }}
          >
            Success!
          </Text>
          <Text
            style={{
              fontFamily: FontFamily.regular,
              fontWeight: FontWeight.regular,
              fontSize: 16,
              lineHeight: helpers.isDesktop() ? 28 : 26,
              letterSpacing: 0.3,
              textAlign: "center",
              color: "#6A6A6A",
            }}
          >
            Your transaction was completed successfully!
          </Text>
        </View>

        <View style={[{ marginBottom: 20 }]}>
          <ButtonGradient
            title="CONTINUE"
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: button.primaryGradientText,
            }}
            onClick={() => this.closeModal()}
          />
        </View>
      </View>
    );
  };

  renderPaymentRedirectFail = () => {
    return (
      <View
        style={[
          Margin.mb_4,
          { marginTop: 0 },
          { paddingVertical: 0, paddingHorizontal: 20 },
          { marginHorizontal: 15 },
        ]}
      >
        <View style={[Flex.alignCenter, Margin.mb_3]}>
          <CustomImage
            source={failureIcon}
            require={true}
            style={{ width: "auto", height: helpers.isDesktop() ? 186 : 140 }}
            webStyle={{
              width: "auto",
              height: helpers.isDesktop() ? 186 : 140,
            }}
          />
        </View>
        <View>
          <Text
            style={[
              {
                textAlign: "center",
                color: "#FF7F7F",
                fontSize: 28,
                lineHeight: 50,
                fontFamily: FontFamily.regular,
                fontWeight: FontWeight.semiBold,
              },
            ]}
          >
            Payment Unsuccessful
          </Text>
        </View>
        <View
          style={{
            maxWidth: helpers.isDesktop() ? 530 : "100%",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            marginBottom: 60,
          }}
        >
          <Text
            style={{
              fontFamily: FontFamily.regular,
              fontWeight: FontWeight.regular,
              fontSize: 16,
              lineHeight: 22,
              letterSpacing: 0.3,
              textAlign: "center",
              color: "#6A6A6A",
            }}
          >
            We're having trouble completing your transaction. Please try again
            after some time. If your account was debited contact us at{" "}
            <TouchableOpacityLinkExternal href="mailto:support@themorningcontext.com">
              <Text
                style={{
                  fontFamily: FontFamily.regular,
                  fontWeight: FontWeight.regular,
                  fontSize: 16,
                  color: "#6A6A6A",
                  textDecorationLine: "underline",
                }}
              >
                support@themorningcontext.com
              </Text>
            </TouchableOpacityLinkExternal>
          </Text>
        </View>

        <View style={[{ marginBottom: 30 }]}>
          <ButtonGradient
            title="TRY AGAIN"
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: button.primaryGradientText,
            }}
          />
        </View>
        <View
          style={[
            Flex.row,
            Flex.justfiyCenter,
            { marginBottom: helpers.isDesktop() ? 100 : 30 },
          ]}
        >
          <Text
            style={[
              {
                fontSize: 14,
                fontFamily: FontFamily.regular,
                fontWeight: FontWeight.regular,
                lineHeight: 18,
                borderBottomWidth: 1,
                borderBottomColor: "#000000",
                color: "#121212",
              },
              !helpers.isDesktop() && { maxWidth: "70%", textAlign: "center" },
            ]}
            onPress={() => this.closeModal()}
          >
            BACK TO WHERE I WAS
          </Text>
        </View>
      </View>
    );
  };

  renderSubscriptionModalContent = () => {
    const {
      name,
      email,
      password,
      pincode,
      phone,
      error,
      passwordToggle,
      paymentType,
      enableLoader,
      styles,
      windowHref,
      token,
      applyGst,
      gstCompanyName,
      gstin,
      currentSubscription,
      breakupAmount,
      breakupDifference,
      breakupTax,
      showBreakUp,
      isIndia,
      showCoupon,
      couponCode,
      couponValid,
      couponLoader,
      couponRemoveAllowed,
      breakupDiscount,
      subscriptionComplete,
      paymentConfig,
    } = this.state;
    const {
      userProfile,
      planData,
      country,
      renewPlan,
      isTeam,
      navigation,
      giftPlan,
    } = this.props;
    let webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let btnClickTerms, btnClickPrivacy;
    if (webView) {
      btnClickTerms = {
        to: "/terms-and-conditions",
      };
      btnClickPrivacy = {
        to: "/privacy-policy",
      };
    } else {
      btnClickTerms = {
        onPress: () => navigation.navigate("/terms-and-conditions"),
      };
      btnClickPrivacy = {
        onPress: () => navigation.navigate("/privacy-policy"),
      };
    }

    return (
      <>
        {enableLoader && enableLoader == "load" && (
          <View
            style={{
              position: "relative",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
              zIndex: 999,
            }}
          >
            {this.renderTopLine()}
            <View
              style={[
                Flex.justfiyCenter,
                Flex.alignCenter,
                {
                  height: "calc(100vh - 189px)",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : "#ffffff",
                },
              ]}
            >
              <ActivityIndicator size={25} color={"#000000"} />
            </View>
          </View>
        )}
        {enableLoader &&
          ["initial", "process", "capture"].includes(enableLoader) && (
            <View
              style={{
                position: "relative",
                height: "calc(100vh - 115px)",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
                zIndex: 999,
              }}
            >
              {this.renderTopLine()}
              <View
                style={[
                  Flex.justfiyCenter,
                  Flex.alignCenter,
                  {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: darkMode
                      ? Colors.darkBlackColorBg
                      : "#ffffff",
                  },
                ]}
              >
                {windowHref && (
                  <View style={[Flex.row, Margin.mb_1]}>
                    <Text style={[subscribePlans.loaderDesc]}>Please </Text>
                    <TouchableOpacity
                      onPress={() => this.openPaymentInitWindow()}
                    >
                      <Text
                        style={[
                          subscribePlans.loaderDesc,
                          subscribePlans.loaderClick,
                        ]}
                      >
                        click here
                      </Text>
                    </TouchableOpacity>
                    <Text style={[subscribePlans.loaderDesc]}>
                      {" "}
                      if you are not redirected within a few seconds
                    </Text>
                  </View>
                )}
                <View style={Margin.mb_5}>
                  <ProgressCircle
                    color={"#907CFF"}
                    size={186}
                    widthOfBorder={25}
                  />
                </View>
                <Text style={subscribePlans.loaderTitle}>Hold on!</Text>
                {enableLoader == "initial" && (
                  <Text style={subscribePlans.loaderSubTitle}>
                    We are initiating your payment request.
                  </Text>
                )}
                {enableLoader == "process" && (
                  <Text style={subscribePlans.loaderSubTitle}>
                    Your payment request is being processed.
                  </Text>
                )}
                {enableLoader == "capture" && (
                  <Text style={subscribePlans.loaderSubTitle}>
                    Awaiting payment confirmation.
                  </Text>
                )}
                <Text style={[subscribePlans.loaderDesc, Margin.mt_2]}>
                  Please do not hit back or close this popup.
                </Text>
              </View>
            </View>
          )}
        {enableLoader && enableLoader == "no-response" && (
          <View
            style={{
              position: "relative",
              height: "calc(100vh - 115px)",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
              zIndex: 999,
            }}
          >
            {this.renderTopLine()}
            <View
              style={[
                Flex.justfiyCenter,
                Flex.alignCenter,
                Margin.mh_4,
                Helpers.textCenter,
                {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg
                    : "#ffffff",
                },
              ]}
            >
              <View style={Margin.mt_3}>
                <CustomImage
                  source={clock}
                  style={{ width: 100, height: 100 }}
                  webStyle={{ width: 100, height: 100, objectFit: "contain" }}
                  require={true}
                  resizeMode={"contain"}
                />
              </View>
              <Text style={[subscribePlans.loaderTitle, { color: "#EE9900" }]}>
                Status Pending
              </Text>
              <Text
                style={[
                  subscribePlans.loaderSubTitle,
                  Margin.mb_2,
                  { color: "#6A6A6A" },
                ]}
              >
                Our system is yet to receive a confirmation from the payment
                gateway. We are looking into this. In case your money has been
                deducted and a subscription is not created in the next 2-3
                business days, please contact us.
              </Text>
              <View style={{ width: "80%" }}>
                <ButtonGradient
                  title={"Continue"}
                  rootStyle={{
                    btnWrapper: button.primaryGradient,
                    btnText: button.primaryGradientText,
                  }}
                  onClick={() => this.closePlanModal()}
                  fullWidth={true}
                />
              </View>
            </View>
          </View>
        )}
        {this.renderTopLine(true, enableLoader)}
        <View
          style={[
            { paddingHorizontal: 25, paddingBottom: 0 },
            (enableLoader || subscriptionComplete) && { display: "none" },
          ]}
        >
          <>
            <Text
              style={[
                Typo.textMedium18,
                Helpers.textUppercase,
                Margin.mb_1,
                { color: "#AAAAAA", fontSize: 14, fontWeight: "600" },
                darkMode && { color: "rgba(250, 250, 250, 0.6)" },
              ]}
            >
              Order Total
            </Text>
            {!couponLoader && (
              <>
                <View
                  style={[Flex.row, Flex.justifyBetween, { marginBottom: 10 }]}
                >
                  <View>
                    <Text
                      style={[
                        {
                          fontSize: 16,
                          fontFamily: FontFamily.regular,
                          color: "rgba(0, 0, 0, 0.7)",
                        },
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      {planData.name}
                    </Text>
                    {isIndia && !isTeam && (
                      <Text
                        style={[
                          {
                            fontSize: 12,
                            fontFamily: FontFamily.light,
                            color: "rgba(0, 0, 0, 0.7)",
                          },
                          darkMode && { color: Colors.lightWhite },
                        ]}
                      >
                        (inclusive of 18% GST)
                      </Text>
                    )}
                  </View>
                  <View
                    style={[Flex.column, { position: "relative", top: -5 }]}
                  >
                    <Text
                      style={[
                        Helpers.textRight,
                        subscribePlans.payTitleAmt,
                        {
                          fontSize: 16,
                          fontFamily: FontFamily.regular,
                          color: darkMode
                            ? Colors.lightWhite
                            : "rgba(0, 0, 0, 0.7)",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          subscribePlans.symbol,
                          {
                            fontSize: 16,
                            lineHeight: 24,
                            color: darkMode
                              ? Colors.lightWhite
                              : "rgba(0, 0, 0, 0.7)",
                            paddingRight: 3,
                          },
                        ]}
                      >
                        {planData.currency_code == "INR" ? "₹" : "$"}
                      </Text>
                      <Text>
                        {(isTeam && breakupAmount !== false
                          ? breakupAmount
                          : planData?.discount?.offer_price
                          ? planData.discount.offer_price
                          : planData.price) -
                          (isIndia && isTeam
                            ? breakupTax !== false
                              ? breakupTax
                              : 0
                            : 0)}
                      </Text>
                    </Text>
                    {/* {!showBreakUp &&
                                            <View style={[Margin.mb_2]}>
                                                <TouchableOpacity onPress={() => this.setState({ showBreakUp: true })}>
                                                    <Text style={[Helpers.textUppercase, { color: '#A97DF1' }]}>See Breakup</Text>
                                                </TouchableOpacity>
                                            </View>
                                        } */}
                  </View>
                </View>
                {currentSubscription &&
                  breakupDifference !== false &&
                  !isTeam &&
                  !giftPlan && (
                    <View
                      style={[
                        Flex.row,
                        Flex.justifyBetween,
                        { marginBottom: 10, alignItems: "baseline" },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            fontSize: 16,
                            fontFamily: FontFamily.regular,
                            color: "rgba(0, 0, 0, 0.7)",
                          },
                          darkMode && { color: Colors.lightWhite },
                        ]}
                      >
                        Refund on cancelling previous plan
                      </Text>
                      <View style={Flex.column}>
                        <Text
                          style={[
                            Helpers.textRight,
                            subscribePlans.paySubTitle,
                            {
                              fontSize: 16,
                              fontFamily: FontFamily.regular,
                              color: "rgba(0, 0, 0, 0.7)",
                            },
                          ]}
                        >
                          <Text
                            style={[
                              subscribePlans.symbol,
                              {
                                fontSize: 16,
                                lineHeight: 24,
                                color: "#55C79A",
                                paddingRight: 3,
                              },
                            ]}
                          >
                            -{planData.currency_code == "INR" ? "₹" : "$"}
                          </Text>
                          <Text
                            style={{
                              fontSize: 16,
                              lineHeight: 24,
                              color: "#55C79A",
                            }}
                          >
                            {breakupDifference !== false
                              ? breakupDifference
                              : 0}
                          </Text>
                        </Text>
                      </View>
                    </View>
                  )}
                {isIndia && isTeam && (
                  <View
                    style={[
                      Flex.row,
                      Flex.justifyBetween,
                      { marginBottom: 10, alignItems: "baseline" },
                    ]}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 16,
                          fontFamily: FontFamily.regular,
                          color: "rgba(0, 0, 0, 0.7)",
                        },
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      Taxes (18% GST)
                    </Text>
                    <View style={Flex.column}>
                      <Text
                        style={[
                          Helpers.textRight,
                          subscribePlans.paySubTitle,
                          {
                            fontSize: 16,
                            fontFamily: FontFamily.regular,
                            color: "rgba(0, 0, 0, 0.7)",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            subscribePlans.symbol,
                            {
                              fontSize: 16,
                              lineHeight: 24,
                              color: "rgba(0, 0, 0, 0.7)",
                              paddingRight: 3,
                            },
                            darkMode && { color: Colors.lightWhite },
                          ]}
                        >
                          {planData.currency_code == "INR" ? "₹" : "$"}
                        </Text>
                        <Text
                          style={[
                            { fontSize: 16, lineHeight: 24 },
                            darkMode && { color: Colors.lightWhite },
                          ]}
                        >
                          {breakupTax !== false ? breakupTax : 0}
                        </Text>
                      </Text>
                    </View>
                  </View>
                )}

                {breakupDiscount && (
                  <View
                    style={[
                      Flex.row,
                      Flex.justifyBetween,
                      { marginBottom: 10 },
                    ]}
                  >
                    <View>
                      <Text
                        style={[
                          {
                            fontSize: 16,
                            fontFamily: FontFamily.regular,
                            color: "rgba(0, 0, 0, 0.7)",
                          },
                          darkMode && { color: Colors.lightWhite },
                        ]}
                      >
                        Discount applied
                      </Text>
                    </View>
                    <View
                      style={[Flex.column, { position: "relative", top: -5 }]}
                    >
                      <Text
                        style={[
                          Helpers.textRight,
                          subscribePlans.payTitleAmt,
                          {
                            fontSize: 16,
                            fontFamily: FontFamily.regular,
                            color: "rgba(0, 0, 0, 0.7)",
                          },
                          darkMode && { color: Colors.lightWhite },
                        ]}
                      >
                        <Text
                          style={[
                            subscribePlans.symbol,
                            {
                              fontSize: 16,
                              lineHeight: 24,
                              paddingRight: 3,
                              color: "#55C79A",
                            },
                          ]}
                        >
                          -{planData.currency_code == "INR" ? "₹" : "$"}
                        </Text>
                        <Text
                          style={{
                            fontSize: 16,
                            lineHeight: 24,
                            color: "#55C79A",
                          }}
                        >
                          {breakupDiscount}
                        </Text>
                      </Text>
                    </View>
                  </View>
                )}

                {/* <View style={[Flex.row, Flex.justifyBetween,{marginBottom: 5}]}>
                                    <Text style={{fontSize: 16, fontFamily: FontFamily.regular, color: 'rgba(0, 0, 0, 0.7)'}}>Taxes (18% GST)</Text>
                                    <View style={Flex.column}>
                                        <Text style={[Helpers.textRight, subscribePlans.paySubTitle, { fontSize: 16, fontFamily: FontFamily.regular, color: 'rgba(0, 0, 0, 0.7)'  }]}>
                                            <Text style={[subscribePlans.symbol, { fontSize: 16, lineHeight: 24, color: 'rgba(0, 0, 0, 0.7)', paddingRight: 3 }]}>{planData.currency_code == 'INR' ? '₹' : '$'}</Text>
                                            <Text style={{fontSize: 16, lineHeight: 24, color: 'rgba(0, 0, 0, 0.7)'}}>150</Text>
                                        </Text>
                                    </View>
                                </View> */}
                {/* {showBreakUp &&
                                    <>
                                        <View style={[Flex.row, Flex.justifyBetween]}>
                                            <Text style={[subscribePlans.paySubTitle, { color: 'rgba(0, 0, 0, 0.7)' }]}>{currentSubscription.plan.name}</Text>
                                            <View style={Flex.column}>
                                                <Text style={[Typo.text20, Helpers.textRight, subscribePlans.paySubTitle, { color: '#000000' }]}>
                                                    <Text style={[subscribePlans.symbol, { fontSize: 20, lineHeight: 24, marginRight: 4, color: '#000000' }]}>{planData.currency_code == 'INR' ? '₹' : '$'}</Text>
                                                    <Text>{planData?.discount?.offer_price ? planData.discount.offer_price : planData.price}</Text>
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={[Flex.row, Flex.justifyBetween, Margin.mb_2]}>
                                            <Text style={[subscribePlans.paySubTitle, { color: 'rgba(0, 0, 0, 0.7)' }]}>Refund on cancelling previous plan</Text>
                                            <View style={Flex.column}>
                                                <Text style={[Typo.text20, Helpers.textRight, subscribePlans.paySubTitle, { color: '#55C79A' }]}>
                                                    <Text style={[subscribePlans.symbol, { fontSize: 20, lineHeight: 24, marginRight: 4, color: '#55C79A' }]}>-{planData.currency_code == 'INR' ? '₹' : '$'}</Text>
                                                    <Text>{(breakupDifference !== false) ? breakupDifference : 0}</Text>
                                                </Text>
                                            </View>
                                        </View>
                                    </>
                                } */}
              </>
            )}

            {couponLoader && this.renderOrderDetailsLoader()}

            <View
              style={{
                borderBottomWidth: 0.5,
                borderColor: darkMode ? Colors.lineColor1 : "#F1F3F6",
                marginLeft: -25,
                marginRight: -25,
                marginBottom: 0,
                marginTop: 12,
              }}
            />
          </>

          <>
            {/*<View style={couponValid && !couponLoader ?
              { paddingTop: 20, paddingBottom: 20 } :
              { paddingTop: 0, paddingBottom: 0 }}


            >
              {!showCoupon && (
                <TouchableOpacity
                  onPress={() => this.setState({ showCoupon: true })}
                >
                  <Text
                    style={{
                      color: Colors.purple,
                      fontSize: 18,
                      fontWeight: '500',
                    }}
                  >
                    Have a Coupon Code?
                  </Text>
                </TouchableOpacity>
              )} 
              {showCoupon && !couponValid && !couponLoader && (
                <View
                  style={[
                    formStyle.inputIconWrap,
                    error.name ? Margin.mb_0 : Margin.mb_0, 
                  ]}
                >
                  <TextInput
                    placeholder={'Enter the Coupon Code'}
                    placeholderTextColor={Colors.placeholderTextColor}
                    style={[
                      formStyle.inputBorderBox,
                      { paddingRight: 57 },
                      darkMode && {
                        color: Colors.lightWhite,
                        borderColor: Colors.lineColor1,
                      },
                    ]}
                    value={couponCode}
                    onChangeText={() =>
                      this.setState({
                        couponCode: event.target.value.toUpperCase().trim(),
                        error: { ...error, coupon: false },
                      })
                    }
                  />
                  <TouchableOpacity
                    onPress={() => this.getEstimate(true, true)}
                    style={[formStyle.showPassBtn]}
                  >
                    <Text
                      style={[
                        formStyle.applyCouponBtn,
                        { marginTop: -5, paddingRight: 15 },
                      ]}
                    >
                      APPLY
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

              {couponLoader && this.renderCouponLoader()}

              {error.coupon && (
                <View>
                  <Text
                    style={[
                      Typo.h4,
                      Margin.mt_0,
                      Margin.mb_0,
                      unlockBox.errorText,
                    ]}
                  >
                    {error.coupon}
                  </Text>
                </View>
              )}

              {couponValid && !couponLoader && (
                <View style={[Flex.row, Flex.alignCenter, Flex.justifyBetween]}>
                  <View style={[Flex.row, Flex.alignCenter]}>
                    <CustomImage
                      source={darkMode ? couponIconWhite : couponIcon}
                      style={{ width: 25, height: 25 }}
                      webStyle={{ width: 25, height: 25, objectFit: 'contain' }}
                      require={true}
                      resizeMode={'contain'}
                    />
                    <Text
                      style={[
                        { marginLeft: 10, fontSize: 14, color: Colors.black85 },
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      Code{' '}
                      <Text style={[{ fontWeight: '500' }]}>{couponCode}</Text>{' '}
                      applied!
                    </Text>
                  </View>
                  {couponRemoveAllowed && (
                    <TouchableOpacity
                      onPress={() =>
                        this.setState(
                          {
                            couponValid: false,
                            showCoupon: true,
                            couponCode: '',
                          },
                          () => this.getEstimate(true, false)
                        )
                      }
                    >
                      <CustomImage
                        source={closeIcon}
                        style={{ width: 25, height: 25 }}
                        webStyle={{
                          width: 25,
                          height: 25,
                          objectFit: 'contain',
                        }}
                        require={true}
                        resizeMode={'contain'}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              )}
            </View> */}
          </>

          <View
            style={{
              borderBottomWidth: 1,
              borderColor: darkMode ? Colors.lineColor1 : "#F1F3F6",
              marginLeft: -25,
              marginRight: -25,
              marginBottom: 20,
              marginTop: 0,
            }}
          />

          {token ? (
            <>
              <View
                style={[
                  Flex.row,
                  Flex.justifyBetween,
                  { alignItems: "baseline" },
                ]}
              >
                <Text
                  style={[
                    Typo.textMedium18,
                    Helpers.textUppercase,
                    Margin.mb_1,
                    { color: "#AAAAAA", fontSize: 14, fontWeight: "600" },
                    darkMode && { color: "rgba(250, 250, 250, 0.6)" },
                  ]}
                >
                  PERSONAL DETAILS
                </Text>
                {window && !window.ReactNativeWebView && (
                  <View style={[Flex.row, { alignItems: "baseline" }]}>
                    {helpers.isDesktop() && (
                      <Text
                        style={[
                          {
                            color: "#6A6A6A",
                            fontSize: 11,
                            fontFamily: FontFamily.regular,
                          },
                          darkMode && { color: "rgba(250, 250, 250, 0.6)" },
                        ]}
                      >
                        Not your account?{" "}
                      </Text>
                    )}
                    <TouchableOpacity onPress={() => this.logout()}>
                      <Text
                        style={[
                          {
                            fontSize: 14,
                            fontFamily: FontFamily.regular,
                            fontWeight: "500",
                            textTransform: "uppercase",
                            color: "#ff0000",
                          },
                        ]}
                      >
                        {" "}
                        Logout
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              <Text
                style={[
                  subscribePlans.userName,
                  { fontFamily: FontFamily.medium },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {userProfile ? userProfile.name : ""}
              </Text>
              <View style={[Margin.mb_2]}>
                <Text
                  style={{ color: darkMode ? Colors.lightWhite : "#000000" }}
                >
                  {userProfile ? userProfile.email : ""}
                </Text>
                {window && window.ReactNativeWebView && (
                  <Text
                    style={[
                      {
                        color: "#6A6A6A",
                        fontSize: 14,
                        fontFamily: FontFamily.regular,
                        marginTop: 10,
                      },
                      darkMode && { color: "rgba(250, 250, 250, 0.6)" },
                    ]}
                  >
                    <Text
                      style={[
                        {
                          fontWeight: "600",
                          textDecorationLine: "underline",
                          color: "#000000",
                        },
                      ]}
                    >
                      Note:
                    </Text>{" "}
                    The subscription will be associated to this email.
                  </Text>
                )}
              </View>
            </>
          ) : (
            <></>
          )}
          {token ? (
            <View
              style={[
                error.pincode ? Margin.mb_0 : Margin.mb_2,
                formStyle.inputIconWrap,
              ]}
            >
              <TextInput
                placeholder={isIndia ? "PIN code" : "ZIP code"}
                placeholderTextColor={[Colors.placeholderTextColor]}
                style={[
                  formStyle.inputBorderBox,
                  darkMode && {
                    color: Colors.lightWhite,
                    borderColor: Colors.lineColor1,
                  },
                ]}
                value={pincode}
                onChangeText={(pincode) => this.validatePincode(pincode)}
              />
            </View>
          ) : (
            <></>
          )}
          {token && error.pincode && (
            <View>
              <Text
                style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}
              >
                *{error.pincode}
              </Text>
            </View>
          )}

          {token && isIndia && (
            <>
              <View style={[Margin.mb_1, formStyle.inputIconWrap]}>
                <Text
                  style={[
                    formStyle.prefix,
                    { left: 3, fontSize: 14 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  +91
                </Text>
                <TextInput
                  placeholder={"Phone Number"}
                  placeholderTextColor={Colors.placeholderTextColor}
                  style={[
                    formStyle.inputBorderBox,
                    {
                      paddingLeft: 45,
                      paddingRight: 57,
                      height: 40,
                      fontSize: 14,
                    },
                    darkMode && {
                      color: Colors.lightWhite,
                      borderColor: Colors.lineColor1,
                    },
                  ]}
                  value={phone}
                  onChangeText={(phone) => this.validateMobile(phone)}
                />
              </View>
              {error.phone && (
                <View>
                  <Text
                    style={[
                      Typo.h4,
                      Margin.mt_0,
                      Margin.mb_0,
                      unlockBox.errorText,
                    ]}
                  >
                    *{error.phone}
                  </Text>
                </View>
              )}

              <View style={[Flex.row, Margin.mt_2]}>
                <TouchableOpacity
                  onPress={() => this.setState({ applyGst: !applyGst })}
                >
                  <View>
                    <CustomImage
                      source={applyGst ? iconCheckedSq : iconUnCheckedSq}
                      style={{ width: 18, height: 18 }}
                      webStyle={{ width: 18, height: 18, objectFit: "contain" }}
                      require={true}
                      resizeMode={"contain"}
                    />
                  </View>
                </TouchableOpacity>
                <View style={[Margin.ml_1, Flex.fill]}>
                  <Text
                    style={[
                      Typo.text16,
                      Margin.mb_15,
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Avail GST credit
                  </Text>
                </View>
              </View>
              <View style={[!applyGst && { display: "none" }]}>
                <View
                  style={[
                    error.company ? Margin.mb_0 : Margin.mb_2,
                    formStyle.inputIconWrap,
                  ]}
                >
                  <TextInput
                    placeholder={"Company Name"}
                    placeholderTextColor={Colors.placeholderTextColor}
                    style={[
                      formStyle.inputBorderBox,
                      darkMode && {
                        color: Colors.lightWhite,
                        borderColor: Colors.lineColor1,
                      },
                    ]}
                    value={gstCompanyName}
                    onChangeText={(company) =>
                      this.setState({
                        gstCompanyName: company,
                        error: { ...this.state.error, company: false },
                      })
                    }
                  />
                </View>
                {error.company && (
                  <View>
                    <Text
                      style={[
                        Typo.h4,
                        Margin.mt_0,
                        Margin.mb_0,
                        unlockBox.errorText,
                      ]}
                    >
                      *{error.company}
                    </Text>
                  </View>
                )}
                <View
                  style={[
                    error.gstin ? Margin.mb_0 : Margin.mb_2,
                    formStyle.inputIconWrap,
                  ]}
                >
                  <TextInput
                    placeholder={"GSTIN"}
                    placeholderTextColor={Colors.placeholderTextColor}
                    style={[
                      formStyle.inputBorderBox,
                      darkMode && {
                        color: Colors.lightWhite,
                        borderColor: Colors.lineColor1,
                      },
                    ]}
                    value={gstin}
                    onChangeText={(gstin) => this.validateGSTIN(gstin)}
                  />
                </View>
                {error.gstin && (
                  <View>
                    <Text
                      style={[
                        Typo.h4,
                        Margin.mt_0,
                        Margin.mb_0,
                        unlockBox.errorText,
                      ]}
                    >
                      *{error.gstin}
                    </Text>
                  </View>
                )}
              </View>
            </>
          )}
          <View
            style={{
              borderBottomWidth: 1,
              borderColor: darkMode ? Colors.lineColor1 : "#F1F3F6",
              marginLeft: -25,
              marginRight: -25,
              marginVertical: 5,
            }}
          />

          {token && this.checkPaymentMethod("chargebee") && (
            <>
              <View
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justifyBetween,
                  Margin.mb_2,
                  Margin.mt_15,
                ]}
              >
                <Text
                  style={[
                    Typo.textMedium,
                    Helpers.textUppercase,
                    { color: "#AAAAAA", fontWeight: "600" },
                    darkMode && { color: "rgba(250, 250, 250, 0.6)" },
                  ]}
                >
                  PAYMENT INFORMATION
                </Text>
                <View style={[Flex.row, Flex.alignCenter]}>
                  <Text
                    style={[
                      Typo.infoText,
                      { marginRight: 7, color: "#6a6a6a", fontSize: 12 },
                      darkMode && { color: "rgba(250, 250, 250, 0.6)" },
                    ]}
                  >
                    Secure
                  </Text>
                  <CustomImage
                    source={lockIcon}
                    style={{ width: 8, height: 12 }}
                    webStyle={{ width: 8, height: 12, objectFit: "contain" }}
                    require={true}
                    resizeMode={"contain"}
                  />
                </View>
              </View>

              <View style={[Flex.row, Margin.mb_1]}>
                <TouchableOpacity
                  onPress={() => this.setState({ paymentType: "chargebee" })}
                >
                  <View>
                    <CustomImage
                      source={
                        paymentType == "chargebee" ? iconChecked : iconUnChecked
                      }
                      style={{ width: 18, height: 18 }}
                      webStyle={{ width: 18, height: 18, objectFit: "contain" }}
                      require={true}
                      resizeMode={"contain"}
                    />
                  </View>
                </TouchableOpacity>
                <View style={[Margin.ml_1, Flex.fill]}>
                  <View
                    style={[
                      Flex.row,
                      Flex.alignStart,
                      Flex.justifyBetween,
                      { marginBottom: 5 },
                    ]}
                  >
                    <Text
                      style={[
                        Typo.text16,
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      Credit and Debit Cards
                    </Text>
                  </View>
                </View>
              </View>
              <View style={[Margin.mb_1]}>
                {" "}
                <View
                  style={[
                    Margin.mb_1,
                    {
                      borderWidth: 0.5,
                      borderColor: error.card
                        ? "#c51720"
                        : darkMode
                        ? Colors.lineColor1
                        : "#C4C4C4",
                      paddingVertical: 9.5,
                      paddingHorizontal: 9.5,
                      borderRadius: 3,
                    },
                    paymentType != "chargebee" && { display: "none" },
                  ]}
                >
                  <CardComponent
                    ref={this.cardRef}
                    styles={styles}
                    onChange={(field) =>
                      this.setState({
                        cardComplete: field.complete,
                        error: {
                          ...this.state.error,
                          card: false,
                          card_error: false,
                        },
                      })
                    }
                  />
                </View>
                {error.card_error && (
                  <View style={[Margin.mb_1]}>
                    <Text
                      style={[
                        Typo.h4,
                        Margin.mt_0,
                        Margin.mb_0,
                        unlockBox.errorText,
                      ]}
                    >
                      {error.card_error}
                    </Text>
                  </View>
                )}
              </View>
            </>
          )}
          {token && this.checkPaymentMethod("juspay") && (
            <>
              <View style={[Flex.row, Margin.mb_15, Margin.mt_1]}>
                <TouchableOpacity
                  onPress={() => this.setState({ paymentType: "juspay" })}
                >
                  <View>
                    <CustomImage
                      source={
                        paymentType == "juspay" ? iconChecked : iconUnChecked
                      }
                      style={{ width: 18, height: 18 }}
                      webStyle={{
                        width: 18,
                        height: 18,
                        objectFit: "contain",
                      }}
                      require={true}
                      resizeMode={"contain"}
                    />
                  </View>
                </TouchableOpacity>
                <View style={[Margin.ml_1, Flex.fill]}>
                  <Text
                    style={[
                      {
                        fontSize: 16,
                        fontFamily: FontFamily.regular,
                        lineHeight: 21,
                        letterSpacing: "0.005em",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    All payment methods (Debit and credit cards, American
                    Express cards, net banking, UPI, wallets etc.)
                  </Text>
                </View>
              </View>
            </>
          )}
          {error.email_error && (
            <View style={[Margin.mb_1]}>
              <Text
                style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText]}
              >
                {error.email_error}
              </Text>
            </View>
          )}
          {token ? (
            <View style={[Margin.mb_2, Margin.mt_1]}>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: "400",
                  color: darkMode ? "rgba(250, 250, 250, 0.6)" : "#6A6A6A",
                  lineHeight: 16,
                  fontFamily: FontFamily.regular,
                }}
              >
                By placing the order, you agree to the{" "}
                <TouchableOpacityLink {...btnClickTerms}>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      textDecorationColor: "#6A6A6A",
                      fontSize: 12,
                      fontWeight: "400",
                      color: darkMode ? "rgba(250, 250, 250, 0.6)" : "#6A6A6A",
                    }}
                  >
                    Terms of Service
                  </Text>
                </TouchableOpacityLink>{" "}
                &{" "}
                <TouchableOpacityLink {...btnClickPrivacy}>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      textDecorationColor: "#6A6A6A",
                      fontSize: 12,
                      fontWeight: "400",
                      color: darkMode ? "rgba(250, 250, 250, 0.6)" : "#6A6A6A",
                    }}
                  >
                    Privacy Policy
                  </Text>
                </TouchableOpacityLink>
                .
              </Text>

              {isIndia && (
                <Text
                  style={[
                    {
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#6A6A6A",
                      lineHeight: 16,
                      fontFamily: FontFamily.regular,
                    },
                    Margin.mt_1,
                  ]}
                >
                  NOTE: You will be redirected to the payment website
                </Text>
              )}
            </View>
          ) : (
            <></>
          )}
          {token ? (
            <View
              style={{
                position: helpers.isDesktop() ? "" : "sticky",

                width: helpers.isDesktop() ? "" : "100%",
                bottom: helpers.isDesktop() ? "" : 0,
                backgroundColor: helpers.isDesktop() ? "" : "#ffffff",
                textAlign: helpers.isDesktop() ? "" : "center",
                paddingVertical: helpers.isDesktop() ? "" : 14,
                bottom: helpers.isDesktop() ? "" : -20,
              }}
            >
              <View
                style={{
                  width: helpers.isDesktop() ? "" : "100%",
                  marginHorizontal: helpers.isDesktop() ? "" : "auto",
                }}
              >
                <ButtonGradient
                  title={
                    "Place your order: " +
                    (planData.currency_code == "INR" ? " ₹ " : " $ ") +
                    (breakupAmount !== false
                      ? breakupAmount
                      : planData?.discount?.offer_price
                      ? planData.discount.offer_price
                      : planData.price)
                  }
                  rootStyle={{
                    btnWrapper: button.primaryGradient,
                    btnText: button.primaryGradientText,
                  }}
                  onClick={() => this.submitPay()}
                  disable={couponLoader}
                  fullWidth={true}
                  darkMode={darkMode}
                />
              </View>
            </View>
          ) : (
            <View
              style={{
                position: helpers.isDesktop() ? "" : "sticky",

                width: helpers.isDesktop() ? "" : "100%",
                bottom: helpers.isDesktop() ? "" : 0,
                backgroundColor: helpers.isDesktop() ? "" : "#ffffff",
                textAlign: helpers.isDesktop() ? "" : "center",
                paddingVertical: helpers.isDesktop() ? "" : 14,
                bottom: helpers.isDesktop() ? "" : -20,
              }}
            >
              <View
                style={{
                  width: helpers.isDesktop() ? "" : "100%",
                  marginHorizontal: helpers.isDesktop() ? "" : "auto",
                }}
              >
                <ButtonGradient
                  title={
                    "Sign in and place your order: " +
                    (planData.currency_code == "INR" ? " ₹ " : " $ ") +
                    (breakupAmount !== false
                      ? breakupAmount
                      : planData?.discount?.offer_price
                      ? planData.discount.offer_price
                      : planData.price)
                  }
                  rootStyle={{
                    btnWrapper: button.primaryGradient,
                    btnText: button.primaryGradientText,
                  }}
                  onClick={() => this.naviagteToLogin()}
                  disable={couponLoader}
                  fullWidth={true}
                  darkMode={darkMode}
                />
              </View>
            </View>
          )}
          {/* <TouchableOpacity activeOpacity={0.8}>
                            <Text style={[Helpers.textCenter, {color: '#FC876D', marginTop: 15, textDecorationLine: 'underline', fontFamily: FontFamily.regular, fontWeight: '600'}]}>Retry</Text>
                    </TouchableOpacity> */}
        </View>

        {subscriptionComplete == "success" &&
          this.renderPaymentRedirectSucess()}

        {subscriptionComplete == "failed" && this.renderPaymentRedirectFail()}
      </>
    );
  };

  render() {
    return (
      <View style={{ zIndex: 9999 }}>
        <Modal
          ref="child"
          renderContent={() => this.renderSubscriptionModalContent()}
          closeModal={() => this.closeModal()}
          paddingHorizontal={true}
          topPadding={false}
          subscription={false}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setUserToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AuthenticationWrapper(SubscriptionPlanModal));
