import React, { Component } from 'react';
import { Text, View, ScrollView, TextInput, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, input, button, Colors, orderData, FontFamily, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import CustomImage from '../custom-image';
import GoogleSocialButton from '../google-social-button';
import FacebookSocialButton from '../facebook-social-button';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import GoogleLogout from '../google-logout';
import FacebookLogoutButton from '../facebook-logout';
import Cookies from "universal-cookie";
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from '../button-gradient';
import OrderView from '../order-view';
import { Mixpanel } from '../../utils/mixpanel';
import ContentLoader from '../content-loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthenticationWrapper from '../authentication-wrapper';

const cookies = new Cookies();

export class PastOrders extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            enableBtnLoader: false,
            ordersData: [],
            pageLoader: true,
            currentDownloadList: []
        }
    }

    componentDidMount = () => {
        this.getOrdersData();

        // if(helpers.isWeb()) {
        //     try {
        //         Mixpanel.track('click_past_orders');
        //     }
        //     catch(error) {
        //         console.log("mix panel error =>", error);
        //     }
        // }
    }

    getOrdersData = () => {
        const { token } = this.state;
        let endpoint = `/transactions-list`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            sort: {
                on: "createdAt",
                by: "desc"
            },
            filters: {
                order_action_group: ["advance_renewal", "buy_credits", "free_credits", "subscription_created", "subscription_renewed", "received_gift_plan", "received_gift_credits", "send_free_credits", "received_free_credits", "buy_gift_credits", "buy_team_credits", "subscription_upgrade", "gift_subscription_created"]
            }
        }
        if (isAdmin) {
            body = {
                sort: {
                    on: "createdAt",
                    by: "desc"
                },
                filters: {
                    order_action_group: ["advance_renewal", "buy_credits", "free_credits", "subscription_created", "subscription_renewed", "received_gift_plan", "received_gift_credits", "send_free_credits", "received_free_credits", "buy_gift_credits", "buy_team_credits", "subscription_upgrade", "gift_subscription_created"]
                },
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'transactions') {
                    let orders_data = Array.isArray(res.data) ? res.data : []
                    this.setState({ ordersData: orders_data, pageLoader: false });
                }
                else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({ showErrorModal: true, pageLoader: false });
                    }
                }
            })
            .catch((error) => {
                this.setState({ showErrorModal: true, pageLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    navigateToSubscription = () => {
        this.props.navigateScreen("accounts/subscriptions")
    }


    renderPageContent = () => {
        let { ordersData, token } = this.state;
        const { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View>
                <View style={[Padding.ph_2, Margin.mt_2]}>
                    {ordersData.length > 0 ?
                        ordersData.map((order, index) => {
                            return (
                                <OrderView order={order} token={token} index={index} key={index} darkMode={darkMode} isAdmin={isAdmin} referenceId={referenceId} />
                            )
                        })

                        :
                        <View style={[Flex.alignCenter, Flex.justfiyCenter, Margin.mt_4]}>
                            <Text style={[{ fontSize: 18, lineHeight: 27, fontFamily: FontFamily.medium, textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>No Orders Yet</Text>
                            <Text style={[Padding.pt_2, { fontSize: 14, lineHeight: 22, color: '#666', fontFamily: FontFamily.regular, textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>You have no past orders. Subscribe or buy credits to read our premium stories.</Text>
                            <ButtonGradient
                                title={'VIEW SUBSCRIPTION PLANS'}
                                rounded={false}
                                fullWidth={false}
                                disable={isAdmin ? true : false}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradient, { width: '100%', marginTop: 50 }],
                                    btnText: [button.primaryGradientText, { fontWeight: '400', paddingHorizontal: 10, fontSize: helpers.isDesktop() ? 17 : 16 }],
                                }}
                                onClick={() => this.navigateToSubscription()}
                                darkMode={darkMode}
                            />
                        </View>
                    }
                </View>
            </View>
        )
    }


    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ContentLoader darkMode={darkMode} />
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {
                    pageLoader ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }

}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(PastOrders));
