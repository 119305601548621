import { Image, StyleSheet, View, Text, TouchableOpacity } from "react-native"
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";

const nudgeReadMoreIcon = getImagePath("icons/nudge-read-more-icon.svg")

const RelatedStoryNudge = (props) => {

    const { featured_image_url: featured_image, title, strapline, type, slug, categories__slug: category } = props


    const history = useHistory()

    const handleReadMore = () => {
        if(process.browser){
            return window.location.href = `/${type == "NEWS EXPLAINER" ? 'yesterday' : category}/${slug}`
        }
        history.push(`/${type == "NEWS EXPLAINER" ? 'yesterday' : category}/${slug}`)
    }

    return <View style={styles.wrapper}>
        <View style={styles.imageContainer}>
            <CustomImage
                source={featured_image}
                require={true}
                style={{
                    width: 228,
                    height: 164,
                    resizeMode: "contain",
                }}
                webStyle={{
                    width: helpers.isDesktop() ? 228 : '90vw',
                    height: helpers.isDesktop() ? 164 : '100%',
                    objectFit: "contain",
                }}
                altText={title}
            />
        </View>
        <View style={styles.storyBodyContainer}>
            <Text style={styles.storyTypeTextContainer}>
                {type}
            </Text>
            <Text style={helpers.isDesktop() ? styles.storyTitleContainer : styles.storyTitleContainerMob}>
                {title}
            </Text>
            <Text style={styles.storyDescContainer}>
                <div
                    className={
                        "postsingle-content postsingle-bodyfont"
                    }
                    style={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2, // Limits to 2 lines
                        textOverflow: 'ellipsis', // Ensures ellipsis is shown after 2 lines
                        whiteSpace: 'normal',
                        fontFamily: 'HaasGrotesk-Roman',
                    }}
                >
                    {strapline ? ReactHtmlParser(strapline.substring(0, 80) + "...") : ""}
                </div>
            </Text>
            <TouchableOpacity style={helpers.isDesktop() ? styles.readMoreButtonContainer : styles.readMoreButtonContainerMob} onPress={handleReadMore}>
                <Text style={styles.readMoreButtonText}>
                    EXPLORE {type}
                </Text>
                <CustomImage
                    source={nudgeReadMoreIcon}
                    require={true}
                    style={{
                        width: 20,
                        height: 20,
                        resizeMode: "contain",
                    }}
                    webStyle={{
                        width: helpers.isDesktop() ? 16 : 20,
                        height: helpers.isDesktop() ? 16 : 20,
                        objectFit: "contain",
                    }}
                    altText={title}
                />
            </TouchableOpacity>
        </View>
    </View>
}

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: helpers.isDesktop() ? 'row' : 'column',
        columnGap: '3vw',
        height: helpers.isDesktop() ? null : null,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: 'rgba(0,0,0,0.5)',
        borderBottomColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 40
    },
    imageContainer: {
        display: 'flex',
    },
    storyBodyContainer: {
        height: '100%',
        flex: 1,
        width: helpers.isDesktop() ? null : '100%',
        marginTop: helpers.isDesktop() ? null : 24,
    },
    storyTypeTextContainer: {
        color: '#6C7AF6',
        fontFamily: 'HaasGrotesk-Medium',
        fontSize: 12,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: 4,
        lineHeight: 16,
        fontWeight: 500
    },
    storyTitleContainer: {
        color: '#292929',
        fontFamily: 'HaasGrotesk-Medium',
        fontSize: 16,
        lineHeight: '150%',
        letterSpacing: 0.3,
        fontWeight: 500,
        marginBottom: 16,
        width: helpers.isDesktop() ? '100%' : '80vw'
    },
    storyTitleContainerMob: {
        color: '#292929',
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%', /* 24px */
        letterSpacing: 0.3
    },
    storyDescContainer: {
        fontSize: 14,
        fontFamily: 'HaasGrotesk-Roman',
        lineHeight: 20,
        fontWeight: 400,
        color: '#292929',
        height: 60,
        width: helpers.isDesktop() ? '100%' : '80vw'
    },
    readMoreButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        width: '53%'
    },
    readMoreButtonContainerMob: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
    },
    readMoreButtonText: {
        fontSize: helpers.isDesktop() ? 12 : 14,
        fontFamily: 'HaasGrotesk-Medium',
        color: '#1E1B4B',
        fontWeight: '500',
        lineHeight: helpers.isDesktop() ? 16 : 20,
        paddingVertical: 8,
        paddingHorizontal: 16,
        paddingRight: 5,
        alignSelf: 'flex-start',
    }
})

export default RelatedStoryNudge