import { NEW_APP, NEW_APP_VERSION, DARK_MODE, DARK_MODE_TOGGLE, REFRESH_SCREEN, FCM_TOKEN, REMOVE_FCM_TOKEN } from './app.types';

export const setAppType = (appType) => {
    return {
        type: NEW_APP,
        data: appType
    }
}

export const saveAppVersion = (appVersion) => {
    return {
        type: NEW_APP_VERSION,
        data: appVersion
    }
}

export const saveDarkMode = (darkMode) => {
    return {
        type: DARK_MODE,
        data: darkMode
    }
}

export const darkModeToggle = (darkModeToggle) => {
    return {
        type: DARK_MODE_TOGGLE,
        data: darkModeToggle
    }
}

export const refreshScreen = (value) => {
    return {
        type: REFRESH_SCREEN,
        data: value
    }
}

export const storeFcmToken = (data) => {
    return {
        type: FCM_TOKEN,
        data: data
    }
}

export const removeFcmToken = () => {
    return {
        type: REMOVE_FCM_TOKEN
    }
}