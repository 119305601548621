import React, { Component } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  articleLists,
  Badges,
  Typo,
  FontFamily,
  FontWeight,
  article,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import moment from "moment";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import ScrollContainerView from "../scroll-container-view";
import Cookies from "universal-cookie";
import { Mixpanel } from "../../utils/mixpanel";
import AuthenticationWrapper from "../authentication-wrapper";

const cookies = new Cookies();
const windowWidth = Dimensions.get("window").width;

export class ArticleList extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      showToken: !helpers.isWeb() || process.browser ? true : false,
    };
  }

  componentDidMount = () => { };

  trackCateoryClick = (item) => {
    if (!this.props.blog) {
      // try {
      //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: 'single_post_releted_story', section_value: { title: item.name, slug: item.slug } });
      // }
      // catch (error) {
      //     console.log("mix panel error =>", error);
      // }
    }
  };

  renderListItem = (item, index) => {
    let { getLinkClick, getCategoryClick, getAuthorClick, blog, darkMode } =
      this.props;
    const webView = helpers.isWeb();

    let badgeStyle;
    badgeStyle = [
      Badges.infoBagePurpleText,
      helpers.isDesktop() && Badges.infoBagePurpleTextDesktop,
    ];

    let btnClick, catBtnClick;
    if (webView) {
      if (blog) {
        btnClick = {
          to: "/blog/" + item.slug,
        };
      } else {
        btnClick = {
          to: {
            pathname: "/" + item.category.slug + "/" + item.slug,
            postData: item,
          },
        };
        catBtnClick = {
          to: "/category/" + item.category.slug,
        };
      }
    } else {
      if (blog) {
        btnClick = {
          onPress: () => getLinkClick(item.slug),
        };
      } else {
        btnClick = {
          onPress: () => getLinkClick(item.category.slug, item.slug, item),
        };
        catBtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            getCategoryClick(item.category.slug);
          },
        };
      }
    }

    return (
      <View
        key={`artlice_list_${index}`}
        style={[
          Margin.mb_5,
          articleLists.col33,
          helpers.isDesktop() && articleLists.col33Desktop,
          !webView && { width: windowWidth - 120 },
          !helpers.isDesktop() && {width: '100%', padding: 0}
        ]}
      >
        <TouchableOpacityLink
          {...btnClick}
          activeOpactiy={0.9}
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
          youMayFindInterestingSection={this.props.youMayFindInterestingSection}
        >
          <View style={[Margin.mb_2]}>
            {item.category && (
              <TouchableOpacityLink
                {...catBtnClick}
                style={{ marginBottom: 10 }}
                handleClick={() => this.trackCateoryClick(item)}
                youMayFindInterestingSection={this.props.youMayFindInterestingSection}
              >
                <Text
                  style={[
                    Badges.badegSmall,
                    helpers.isDesktop() && Badges.badegSmallDesktop,
                    badgeStyle,
                    articleLists.badge,
                    { textAlign: "left", fontFamily: FontFamily.regular },
                    !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    {
                      fontFamily: 'HaasGrotesk-Medium',
                      fontSize: 19,
                      fontWeight: '500',
                      letterSpacing: 1,
                      textTransform: 'uppercase',
                    },
                    {
                      color:
                        item.category.name === "Internet"
                          ? "#FC876D"
                          : item.category.name === "Business"
                            ? "#2CB680"
                            : item.category.name === "Newsletters"
                              ? "#ECB731"
                              : item.category.name === "Chaos"
                                ? "#907CFF"
                                : "#ECB731",
                        fontFamily: 'HaasGrotesk-Medium',
                        fontWeight: 500,

                    }
                  ]}
                >
                  {item.category.name}
                </Text>
              </TouchableOpacityLink>
            )}
            {item.featured_image && (
              <CustomImage
                source={item.featured_image.url}
                style={{ width: "auto", height: 200, resizeMode: "cover" }}
                webStyle={{
                  width: helpers.isDesktop() ? "384px" : "335px",
                  height: helpers.isDesktop() ? "243px" : "210px",
                  objectFit: "cover",
                }}
                className={"post-list-thumb max-100"}
                altText={
                  item.featured_image.alt && item.featured_image.alt != ""
                    ? item.featured_image.alt
                    : item.title
                      ? item.title
                      : item.story?.title
                        ? item.story.title
                        : item.featured_image.title
                }
              />
            )}
          </View>
          <View style={[helpers.isDesktop() && Padding.pl_0, Flex.fill]}>
            <Text
              style={[
                articleLists.articleTitle,
                !helpers.isDesktop() && articleLists.articleTitleMobile,
                !helpers.isWeb() && { fontFamily: FontFamily.bold },
                darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                {
                  color: '#292929', // Equivalent to var(--Black, #292929)
                  fontFamily: 'PlayfairDisplay-Bold',
                  fontSize: 24,
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 35.52, // 148% of 24px
                }
              ]}
              numberOfLines={2}
            >
              {item.title}
            </Text>
            <View>
              <Text
                style={[
                  {
                    color: '#292929',
                    fontFamily: 'HaasGrotesk-Roman',
                    fontSize: 18,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 27, // 150% of 18px
                    letterSpacing: 0.3,
                    marginTop: 10,
                  },
                  darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                ]}
                numberOfLines={3}
                ellipsizeMode="tail"
              >
                {item?.story?.strapline || item.excerpt}
                {/* Get the strapline here from the parent component */}
              </Text>
            </View>
            <View
              style={[articleLists.lineBreak, Margin.mt_1, Margin.mb_15]}
            ></View>
            {item.author && item.author.length > 1 ? (
              <>
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyStart,
                    { flexWrap: "wrap", alignItems: "baseline" },
                  ]}
                >
                  {item.author &&
                    item.author.map((author, index) => {
                      let authorBtnClick;
                      if (webView) {
                        authorBtnClick = {
                          to: "/writers/" + author.slug,
                        };
                      } else {
                        authorBtnClick = {
                          onPress: (event) => {
                            event.stopPropagation();
                            getAuthorClick(author.slug);
                          },
                        };
                      }
                      return (
                        <>
                          {helpers.isWeb() ? (
                            <TouchableOpacityLink {...authorBtnClick} 
                              youMayFindInterestingSection={this.props.youMayFindInterestingSection}
                            >
                              <Text
                                style={[
                                  { marginRight: 5 },
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                  {
                                    fontFamily: 'HaasGrotesk-Roman',
                                    fontSize: 16,
                                    fontWeight: '400',
                                    letterSpacing: 0.3,
                                    color: '#292929',
                                  },
                                ]}
                              >
                                {author.name}
                              </Text>
                              {item.author.length != index + 1 && (
                                <Text
                                  style={[
                                    articleLists.articleDate,
                                    helpers.isDesktop() &&
                                    articleLists.articleDateDesktop,
                                    { marginRight: 2 },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  and{" "}
                                </Text>
                              )}
                            </TouchableOpacityLink>
                          ) : (
                            <View>
                              <TouchableOpacityLink {...authorBtnClick}
                                youMayFindInterestingSection={this.props.youMayFindInterestingSection}
                              >
                                <Text
                                  style={[
                                    { marginRight: 5 },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                    {
                                      fontFamily: 'HaasGrotesk-Roman',
                                      fontSize: 16,
                                      fontWeight: '400',
                                      letterSpacing: 0.3,
                                      color: '#292929',
                                    },
                                  ]}
                                >
                                  {author.name}
                                  {item.author.length != index + 1 && (
                                    <Text
                                      style={[
                                        articleLists.articleDate,
                                        helpers.isDesktop() &&
                                        articleLists.articleDateDesktop,
                                        { marginRight: 2 },
                                        darkMode && {
                                          color: "rgba(255, 255, 255, 0.89)",
                                        },
                                      ]}
                                    >
                                      {" "}
                                      and
                                    </Text>
                                  )}
                                </Text>
                              </TouchableOpacityLink>
                            </View>
                          )}
                        </>
                      );
                    })}
                </View>
                <Text
                  style={[
                    articleLists.articleDate,
                    helpers.isDesktop() && articleLists.articleDateDesktop,
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {moment(item.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </>
            ) : (
              <View
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justifyStart,
                  {
                    flexWrap: "wrap",
                    alignItems: helpers.isWeb() ? "baseline" : "center",
                  },
                  !webView && { flexDirection: "row" },
                ]}
              >
                {item.author &&
                  item.author.map((author, index) => {
                    let authorBtnClick;
                    if (webView) {
                      authorBtnClick = {
                        to: "/writers/" + author.slug,
                      };
                    } else {
                      authorBtnClick = {
                        onPress: (event) => {
                          event.stopPropagation();
                          getAuthorClick(author.slug);
                        },
                      };
                    }
                    return (
                      <TouchableOpacityLink {...authorBtnClick}
                        youMayFindInterestingSection={this.props.youMayFindInterestingSection}
                      >
                        <Text
                          style={[
                            darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                            {
                              fontFamily: 'HaasGrotesk-Roman',
                              fontSize: 16,
                              fontWeight: '400',
                              letterSpacing: 0.3,
                              color: '#292929',
                            },
                          ]}
                        >
                          {author.name}
                        </Text>
                        {item.author.length != index + 1 && (
                          <Text
                            style={[
                              articleLists.articleDate,
                              helpers.isDesktop() &&
                              articleLists.articleDateDesktop,
                              Margin.mr_1,
                              { fontFamily: FontFamily.light },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            and{" "}
                          </Text>
                        )}
                      </TouchableOpacityLink>
                    );
                  })}
                <View
                  style={[
                    articleLists.dotSeprator,
                    helpers.isWeb() && { top: -3 },
                    {
                      height: 0,
                      width: 0,
                    }
                  ]}
                />
                <Text
                  style={[
                    articleLists.articleDate,
                    helpers.isDesktop() && articleLists.articleDateDesktop,
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {moment(item.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </View>
            )}
          </View>
        </TouchableOpacityLink>
      </View>
    );
  };

  render() {
    const { title, post, darkMode } = this.props;
    return (
      <View>
        {/* <Text style={[Helpers.textCenter, Helpers.textUppercase, Typo.h3_M, helpers.isDesktop() && Typo.h3_MDesktop, Margin.mb_1]}>{title}</Text>
                <PageBreak size={'small'} style={[Margin.mb_5]} /> */}

        <View
          style={[
            article.headerWrapper,
            helpers.isDesktop() ? { marginBottom: 0 } : Margin.mb_2,
            !helpers.isDesktop() && { marginLeft: -40, marginRight: -40 },
          ]}
        >
          <View style={[Flex.alignCenter, Margin.mb_3]}>
            <View
              style={[
                helpers.isDesktop() && Padding.ph_2,
                Helpers.titleBgWrap,
                { marginBottom: 1 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
                !helpers.isDesktop() && { paddingHorizontal: 0 }
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  darkMode && { borderBottomColor: "rgba(255, 255, 255, 0.6)" },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontSize: helpers.isDesktop() ? 30 : 20, color: "#000" },
                    {
                      fontFamily: FontFamily.abrilFatface,
                      fontWeight: FontWeight.regular,
                    },
                    darkMode && {
                      backgroundColor: Colors.darkBlackColorBg,
                      color: "rgba(255, 255, 255, 0.89)",
                    },
                    {
                      color: '#292929',
                      textAlign: 'center',
                      fontFamily: "PlayfairDisplay-Bold",
                      fontSize: 34,
                      fontWeight: 700,
                      lineHeight: '150%'
                    }
                  ]}
                >
                  {title}
                </Text>
              </View>
            </View>
            <View
              style={[
                Helpers.titleLine,
                darkMode && { backgroundColor: "rgba(255, 255, 255, 0.6)" },
              ]}
            ></View>
          </View>
        </View>

        {helpers.isDesktop() ? (
          <ScrollContainerView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
          >
            <View
              style={[Flex.row, !process.browser && { overflow: "hidden" }]}
            >
              {post &&
                post.map((item, index) => {
                  return this.renderListItem(item, index);
                })}
            </View>
          </ScrollContainerView>
        ) : (
          <ScrollContainerView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
          >
            <View
              style={[Flex.column, !process.browser && { overflow: "hidden" }]}
            >
              {post &&
                post.map((item, index) => {
                  return this.renderListItem(item, index);
                })}
            </View>
          </ScrollContainerView>
        )}
      </View>
    );
  }
}

export default AuthenticationWrapper(ArticleList);
