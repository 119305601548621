import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, FontFamily } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import { Mixpanel } from '../../utils/mixpanel';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';

export class Sponsers extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount() {

    }


    render() {
        let { data } = this.props;
        return (
            <View style={[Margin.mb_4]}>
                <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter]}>
                    <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, alignItems: 'center', letterSpacing: 0.3, color: '#8A8A8A', fontWeight: '400', marginRight: 10}, helpers.isWeb() && { lineHeight: '160%' }]}>Sponsored by:</Text>
                        <CustomImage
                            source={data.logo}
                            require={false}
                            style={{ width: 150, height: 90, resizeMode: 'contain' }}
                            webStyle={{ width: 200, height: 90, objectFit: 'contain' }}
                            altText={data.name}
                        />
                </View>
            </View>
        );
    }
}

export default Sponsers;