import { getPlanFromCurrency, getPlanInfoObject } from "./plansConfig";

const getFormattedDate = (date) => {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const d = new Date(date);
    let year = d.getFullYear();
    // let month = (1 + d.getMonth()).toString().padStart(2, "0");
    let month = d.getMonth();
    let day = d.getDate().toString().padStart(2, "0");

    return day + "/" + monthNames[month] + "/" + year;
}


export const bandCategoryHandle = {

    getBandDetails: (userProfile, subscriptionDetails, bandType) => {
        const planId = subscriptionDetails?.plan.id
        if (!planId) {
            throw new Error("Plan ID does not exist in the given subscription details")
        }
        const planInfoObject = getPlanInfoObject(planId)

        if (!['dunning', 'mandateDoesNotExists', 'mandateExists', 'cancelled', 'twoYearPlanPromotion'].includes(bandType)) {
            throw new Error(`No matching band with ID: ${bandType}`)
        }

        const actions = {
            "dunning": bandCategoryHandle.dunning,
            "mandateDoesNotExists": bandCategoryHandle.mandateDoesNotExists,
            "mandateExists": bandCategoryHandle.mandateExists,
            "cancelled": bandCategoryHandle.cancelled,
            "twoYearPlanPromotion": bandCategoryHandle.twoYearPlanPromotion
        }

        const bandObject = actions[bandType](userProfile, subscriptionDetails)
        if (!planInfoObject) {
            return bandObject.defaultBandDetailsObject
        }

        if (bandObject.bandDetailsObject[planInfoObject.billingFrequency]) {

            return bandObject.bandDetailsObject[planInfoObject.billingFrequency]
        }

        return bandObject.defaultBandDetailsObject

    },
    dunning: (userProfile, subscriptionDetails) => {

        const dueDateString = getFormattedDate(subscriptionDetails.due_since)
        const planInfoObject = getPlanInfoObject(subscriptionDetails?.plan.id)
        const twoYearPlanId = getPlanFromCurrency(subscriptionDetails?.plan?.currency_code, '2-year')
        const annualPlanId = getPlanFromCurrency(subscriptionDetails?.plan?.currency_code, 'annual')

        const bandDetailsObject = {
            "monthly": {
                text: `Like what you read? Our annual plan saves money and builds reading as a daily habit.`,
                ctaRedirection: `/pricing?subscribe=${annualPlanId}&quantity=1`,
                ctaText: 'CONTINUE READING'
            }
        }

        bandDetailsObject = {
            ...bandDetailsObject,
            "annual": {
                text: `You like reading us. We certainly like writing for you. So why don't you save yourself some good money and upgrade to our two-year plan?`,
                ctaRedirection: `/pricing?subscribe=${twoYearPlanId}&quantity=1`,
                ctaText: 'UPGRADE'
            }
        }

        if(planInfoObject?.currency === 'USD'){
            bandDetailsObject["monthly"] = {
                text: `Like what you read? Our annual plan saves money and builds reading as a daily habit.`,
                ctaRedirection: `/pricing?subscribe=${annualPlanId}&quantity=1`,
                ctaText: 'CONTINUE READING'
            }
        }

        const defaultBandDetailsObject = {
            text: `You still fund our independent, unbiased coverage of startups and businesses.`,
            ctaRedirection: `/pricing`,
            ctaText: 'CONTINUE READING'
        }

        return {
            bandDetailsObject: bandDetailsObject,
            defaultBandDetailsObject: defaultBandDetailsObject
        }
    },
    mandateDoesNotExists: (userProfile, subscriptionDetails) => {


        const discountCouponCode = 'TMCDISC10'
        const annualPlanId = getPlanFromCurrency(subscriptionDetails?.plan?.currency_code, 'annual')
        const twoYearPlanId = getPlanFromCurrency(subscriptionDetails?.plan?.currency_code, '2-year')

        const planInfoObject = getPlanInfoObject(subscriptionDetails?.plan.id)

        const bandDetailsObject = {
            "monthly": {
                text: `Like what you read? Our annual plan saves money and builds reading as a daily habit.`,
                ctaRedirection: `/pricing?subscribe=${annualPlanId}&quantity=1`,
                ctaText: 'UPGRADE'
            },
            "annual": {
                text: `You like reading us. We certainly like writing for you. So why don't you save yourself some good money and upgrade to our two-year plan?`,
                ctaRedirection: `/pricing?subscribe=${twoYearPlanId}&quantity=1`,
                ctaText: 'UPGRADE'
            }
        }

        if(planInfoObject?.currency === 'USD'){
            bandDetailsObject["monthly"] = {
                text: `Like what you read? Our annual plan saves money and builds reading as a daily habit.`,
                ctaRedirection: `/pricing?subscribe=${annualPlanId}&quantity=1`,
                ctaText: 'UPGRADE'
            }


            bandDetailsObject["annual"] = {
                text: `You like reading us. We certainly like writing for you. So why don't you save yourself some good money and upgrade to our two-year plan?`,
                ctaRedirection: `/pricing?subscribe=${twoYearPlanId}&quantity=1`,
                ctaText: 'UPGRADE'
            }
        }


        const defaultBandDetailsObject = {
            text: `You still fund our independent, unbiased coverage of startups and businesses.`,
            ctaRedirection: `/pricing?advance-renew=true`,
            ctaText: 'CONTINUE READING'
        }

        return {
            bandDetailsObject: bandDetailsObject,
            defaultBandDetailsObject: defaultBandDetailsObject
        }
    },
    mandateExists: (userProfile, subscriptionDetails) => {
        const discountCouponCode = 'TMCDISC10'
        const twoYearPlanId = getPlanFromCurrency(subscriptionDetails?.plan?.currency_code, '2-year')
        const bandDetailsObject = {
            "annual": {
                text: `You like reading us. We certainly like writing for you. So why don't you save yourself some good money and upgrade to our two-year plan?`,
                ctaRedirection: `/pricing?subscribe=${twoYearPlanId}&quantity=1`,
                ctaText: 'UPGRADE'
            }
        }

        const defaultBandDetailsObject = {
            text: `You still fund our independent, unbiased coverage of startups and businesses.`,
            ctaRedirection: `/pricing?advance-renew=true`,
            ctaText: 'CONTINUE READING'
        }

        return {
            bandDetailsObject: bandDetailsObject,
            defaultBandDetailsObject: defaultBandDetailsObject
        }
    },
    cancelled: (userProfile, subscriptionDetails) => {
        const bandDetailsObject = {
        }

        const defaultBandDetailsObject = {
            text: `Your subscription has been cancelled`,
            ctaRedirection: `/pricing`,
            ctaText: 'Continue Reading'
        }

        return {
            bandDetailsObject: bandDetailsObject,
            defaultBandDetailsObject: defaultBandDetailsObject
        }
    },

    twoYearPlanPromotion: (userProfile, subscriptionDetails) =>{
        const discountCouponCode = 'TMCDISC2YEAR_2'
        const twoYearPlanId = getPlanFromCurrency(subscriptionDetails?.plan?.currency_code, '2-year')
        const bandDetailsObject = {

        }

        const defaultBandDetailsObject = {
            text: `You like reading us. We certainly like writing for you. So why don't you save yourself some good money and upgrade to our two-year plan?`,
            ctaRedirection: `/pricing?subscribe=${twoYearPlanId}&quantity=1`,
            ctaText: 'UPGRADE'
        }

        return {
            bandDetailsObject: bandDetailsObject,
            defaultBandDetailsObject: defaultBandDetailsObject
        }
    }

}