import { SubscriptionAPI__I } from "./interface";
import { Mix_v1 } from '../../mixin-builder'
import { deepCopyObject, syncObject } from "../../helper";
import { HTTP_Fetch } from "../../api_fetch";
import { chargebeeInstance__Interface } from "../../cb-instance";
import { CHARGEBEE_SETTINGS } from "../../settings";
import { ManagementAPI } from "../abstract";
import { ManagementAPI__I } from "../";
import { Mixins_v1 } from "../../controller/mixins";


export type Constructor = new (...args: any[]) => SubscriptionAPI__I;

/**
 * 
 * Mixin to add `payment_source` property to the {@link SubscriptionAPI__I} object.
 * The PI: {@link PI__I}
 */
export interface PaymentSource__I extends SubscriptionAPI__I {
    /**
     * Fetches payment source information of a subscription particular to the user.
     * @param options - The required arguments for the `payment_source` Chargebee API.
     */
    payment_source(options: any): Promise<any>
}
export function PaymentSource<TBase extends Constructor>(Base: TBase): TBase | PaymentSource__I {
    return class PI extends Base{
        constructor(...args: any[]){
            super(...args)
        }
        payment_source(options: any): Promise<any> {
            return this.#execute(options)
        }
        #execute(options: any): Promise<any>{
            return new Promise((resolve, reject) => {
                HTTP_Fetch(CHARGEBEE_SETTINGS.PAYMENT_SOURCE_ENDPOINT, {params: options}, 'POST', true, this.tmcAuthToken).then(res => {
                    if(res.success){
                        return resolve(res.data)
                    }
                    return reject(res.error)
                }).catch(err => {
                    return reject(err)
                })
            })
        }
    }
}


export interface RemoveScheduledCancellation__I extends SubscriptionAPI__I {
    /**
     * Remove the scheduled cancellation of the subscription. Applicable only to cancelled subscriptions before subscription end date.
     * @param options - The required arguments for the `remove_scheduled_cancellation` Chargebee API.
     */
     remove_scheduled_cancellation(options: any): Promise<any>
}
export function RemoveScheduledCancellation<TBase extends Constructor>(Base: TBase): TBase | RemoveScheduledCancellation__I {
    return class RSC extends Base{
        constructor(...args: any[]){
            super(...args)

        }
        remove_scheduled_cancellation(options: any): Promise<any> {
            return this.#execute(options)
        }
        #execute(options: any): Promise<any>{
            return new Promise((resolve, reject) => {
                HTTP_Fetch(CHARGEBEE_SETTINGS.REMOVE_SHEDULED_CANCELLATION_ENDPOINT, {params: options}, 'POST', true, this.tmcAuthToken).then(res => {
                    if(res.success){
                        return resolve(res.data)
                    }
                    return reject(res.error)
                }).catch(err => {
                    return reject(err)
                })
            })
        }
    }
}


export const SubscriptionAPIMixins_v1 = [
    PaymentSource,
    RemoveScheduledCancellation,
]