import React, { Component } from 'react';

export class Seo extends Component {
    render() {
        return (
            <></>
        );
    }
}

export default Seo;