import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TextInput, TouchableOpacity } from 'react-native';
export class EmailLink extends Component {
    render() {
        return(
            <TouchableOpacity onPress={()=>{
                this.props.onPress();
            }}>
                {this.props.children}
            </TouchableOpacity>
        )
    }
}

export default EmailLink;