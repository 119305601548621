import React, { Component } from 'react';
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from 'react-native';
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  newsletter,
  FontFamily,
  pageContent,
  list,
  Colors,
} from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
// import { Colors } from 'react-native/Libraries/NewAppScreen';

export class TermsCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let webView = helpers.isWeb();
    let { darkMode } = this.props;
    return (
      <View
        style={[
          helpers.isDesktop() && Margin.mb_6,
          !helpers.isDesktop() && Margin.mb_3,
        ]}
      >
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 20 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.3,
              fontFamily: FontFamily.merriweather,
              marginBottom: helpers.isDesktop() ? 30 : 20,
              paddingHorizontal: 20,
              color: '#000',
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          PLEASE READ ALL OF THE FOLLOWING TERMS AND CONDITIONS OF THIS WEB SITE
          BEFORE USING THIS SITE.
        </Text>
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 20 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.3,
              fontFamily: FontFamily.merriweather,
              marginBottom: helpers.isDesktop() ? 30 : 20,
              paddingHorizontal: 20,
              color: '#000',
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          The following terms and conditions (the "Terms and Conditions") govern
          your use of this Website provided to you by Slowform Holdings Limited,
          having its registered office at 86-90 Paul Street, London, EC2A 4NE
          ("Slowform" or "we" or "us" or "our" or "Company") and any
          content, features or functionality made available from or through this
          Website, including any sub-domains thereof, or application (the
          "Website"). Through the Website or related mobile application or other
          digital media ("Media"), the Company allows users to access certain
          news content, articles, opinions, data, statistics and current affairs
          only on the payment of a subscription fee ("Services"). If you do not
          accept any of the following Terms, please discontinue access
          immediately. In India, subscription to The Morning Context is provided 
          through Slowform Media Private Limited, having it's registered office at H-16, 
          Mhada Building, Sion Transit Camp Road, Pratiksha Nagar, Mumbai, Maharashtra 400022.{' '}
        </Text>
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 20 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.3,
              fontFamily: FontFamily.merriweather,
              marginBottom: helpers.isDesktop() ? 30 : 20,
              paddingHorizontal: 20,
              color: '#000',
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          This document is an electronic record. This electronic record is
          generated by a computer system and does not require any physical or
          digital signatures. This is a legal agreement between you, the user
          ("User" or "You") of the Service, as defined below, and stating the
          terms that govern your use of the Media, as set out below. If you do
          not agree to these Terms and Conditions, you are not entitled to use
          the Media and any use thereafter shall be unauthorized. Definition of
          "User" or "You" means any person who accesses or avails of this site
          of the Company for the purpose of using, storing, hosting, publishing,
          sharing, transacting, displaying or uploading information or views and
          includes other persons jointly participating in using the Media.{' '}
        </Text>
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 20 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.3,
              fontFamily: FontFamily.merriweather,
              marginBottom: helpers.isDesktop() ? 30 : 20,
              paddingHorizontal: 20,
              color: '#000',
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          By continuing to access, link to, or use this Media, or any service on
          this Media, you signify your acceptance of the Terms and Conditions.
          Slowform Holdings Limited and Slowform Media Private Limited reserve the right to amend, 
          remove, or add to the Terms and Conditions at any time at its sole and 
          absolute discretion. Such modifications shall be effective immediately.
          Accordingly, please continue to review the Terms and Conditions
          whenever accessing, linking to, or using the Media.{' '}
        </Text>
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 20 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.3,
              fontFamily: FontFamily.merriweather,
              marginBottom: helpers.isDesktop() ? 30 : 20,
              paddingHorizontal: 20,
              color: '#000',
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          Your access, link to, or use of the Media, or any service on this
          Media, after the posting of modifications to the Terms and Conditions
          will constitute your acceptance of the Terms and Conditions, as
          modified. Some of the services on the Media may be subject to
          additional posted Terms & Conditions. Your use of those services is
          subject to those conditions, which are incorporated into these Terms
          and Conditions by reference. In the event of an inconsistency between
          these Terms and Conditions and any additional posted conditions, the
          provisions of the additional conditions shall control.{' '}
        </Text>
        <Text
          style={[
            {
              fontSize: helpers.isDesktop() ? 20 : 16,
              lineHeight: helpers.isDesktop() ? 32 : 24,
              letterSpacing: 0.3,
              fontFamily: FontFamily.merriweather,
              marginBottom: helpers.isDesktop() ? 30 : 20,
              paddingHorizontal: 20,
              color: '#000',
            },
            darkMode && { color: Colors.lightWhite },
          ]}
        >
          If, at any time, you do not wish to accept the Terms and Conditions,
          you may not access, link to, or use the Media. Any terms and
          conditions proposed by you which are in addition to or which conflict
          with the Terms and Conditions are expressly rejected by Slowform Pte
          Limited and shall be of no force or effect. In such case, you are
          prohibited from accessing the Media and any such access shall be
          unauthorised.
        </Text>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    { paddingBottom: 5 },
                    helpers.isDesktop() && { marginRight: 0 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  1) ELIGIBILITY:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            You will be eligible to subscribe to the Services on the Media only
            if you are competent to contract under the applicable laws. You
            hereby represent and warrant to the Company that you are at least
            eighteen (18) years of age or above and are capable of entering,
            performing and adhering to these Terms and Conditions and that you
            agree to be bound by the following terms and conditions. While
            individuals under the age of 18 may utilize the Services of the
            Media, they shall do so only with the involvement & guidance of
            their parents and / or legal guardians, under such Parent /Legal
            guardian's registered account. You agree to register prior to
            uploading any content and / or comment and any other use or Services
            of the Media and provide your details including but not limited to
            complete name, age, email address, residential address, contact
            number.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  2) REGISTRATION:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) While you may visit the Website without registration or
            subscription, some portions of the Website may be accessible by
            subscription only. You may subscribe to the Services on the Website
            only after registering with the Website. Additionally, you shall
            have to accept these Terms and Conditions and the Company's Privacy
            Policy.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) The information sought at the time of registration may include
            your username, name, age, email address, place of residence and
            phone number. You are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer or mobile phone, and agree to accept
            responsibility for all activities that occur under your account or
            password. All actions performed by any person using your account and
            password shall be deemed to have been committed by you and you shall
            be liable for the same. The Company reserves the right to terminate
            the subscription at any time if it is found that you have been
            sharing the password with any unauthorised user.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  3) SUBSCRIPTION:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) Subscription services include access to the entire website, its
            content and archives. A preview is available to all viewers of the
            Website but access to opinion pieces, commentaries and exclusive
            features of the Website are available to paid subscribers of the
            Subscription Services alone. The nature of content made available to
            you is subject to change, the final decision of which lies with the
            management of the Website. The Company may vary the access rights of
            users engaged in accessing the Website without a paid subscription
            at any time at the Company's sole discretion. With respect to paid
            subscribers, any revision of access rights will take effect on the
            completion of such user's current subscription term.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) Subscription Services may differ geographically and based on the
            device from which you subscribe. Subscriptions packages and price
            may also vary in time. The Company reserves the right to introduce
            new subscription packages from time to time.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) The Company will try to process your subscription promptly but
            does not guarantee that your subscription will be activated by any
            specified time. By submitting your payment and other subscription
            details, you are making an offer to us to buy a subscription. Your
            offer will only be accepted by us and a contract formed when we have
            successfully verified your payment details and email address, at
            which point we will provide you with access to your subscription.
            The Company reserves the right to reject any offer in its
            discretion, for any or no reason.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  4) PAYMENT TERMS:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) The subscription fees and any other charges due from you in
            connection with your subscription for the Subscription Services
            (including any applicable taxes) shall be as per the rates in effect
            at the time at which it is charged ("Subscription Fees").
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) When you purchase a subscription with the Website, you must
            provide us with complete and accurate payment information. By
            submitting payment details you promise that you are entitled to
            purchase a subscription using those payment details. If we do not
            receive payment authorization or any authorization is subsequently
            cancelled, we may immediately terminate or suspend your access to
            your subscription. In suspicious circumstances we may contact the
            issuing bank/payment provider and/or law enforcement authorities or
            other appropriate third parties. If you are entitled to a refund
            under these terms and conditions we will credit that refund to the
            card or other payment method you used to submit payment, unless it
            has expired in which case we will contact you.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) The Company uses the services of third party payment service
            providers to process your payment of the Subscription Fees.
            Accordingly your usage of such third party services shall be in
            accordance with the terms and conditions of adopted and implemented
            by such service provider, and the Company shall not be responsible
            for any failed or incomplete fulfilment of any payment instructions
            issued by you through such service provider or any actions taken by
            the Company in respect of the same.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            d) Subscription Fees will be billed at the beginning of your
            subscription and any renewal of your subscription. As a general
            matter, all our fees and charges are non-refundable. We reserve the
            right to issue refunds or credits at our sole discretion. If we do
            issue a refund or credit, we are under no obligation to issue the
            same or similar refund in the future. We may change the Subscription
            Fees and charges then in effect, or add new fees or charges which
            will take effect that the end of your subscription period, by giving
            you notice in advance and an opportunity to cancel.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  5) CONTENT:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a)The contents of the Services are intended for your personal,
            non-commercial use. You will use the Service and any content,
            material, or information found on the Website, solely for lawful,
            non-commercial purposes. All materials published and provided on the
            Website ("Content") are protected by copyright, trademark, and other
            applicable intellectual property and proprietary rights laws and is
            owned, controlled, and/or licensed by the Company. ‘The Morning
            Context’ and ‘TMC’ and ‘Things Change’ are the intellectual property
            of the Company. You shall abide by all copyright notices,
            information, or restrictions contained in any Content accessed
            through the Service.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) The Service and its Contents are protected by the intellectual
            property laws pursuant to such international laws as are applicable.
            You are permitted to print or download extracts from these pages for
            your personal non-commercial use only. Any copies of these pages
            saved to disk or to any other storage medium may only be used for
            subsequent viewing purposes or to print extracts for personal use.
            You may not (whether directly or through the use of any software
            program) create a database in electronic or structured manual form
            by regularly or systematically downloading and storing all or any
            part of the pages from this site. No part of Service may be
            reproduced or transmitted to or stored in any other web site, nor
            may any of its pages or part thereof be disseminated in any
            electronic or non-electronic form, nor included in any public or
            private electronic retrieval system or service without prior written
            permission.{' '}
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) Company reserves the right to collect, to use, and to distribute
            demographic data about you and your use of the Service in forms that
            do not identify you individually or reveal your identity.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            d) Company may contain links to other related World Wide Web
            Internet sites, resources, and sponsors of Company.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            e) You agree not to rearrange or modify the Content available
            through the Website. You agree not to display, post, frame, or
            scrape the Content for use on another website, app, blog, product or
            service, except as otherwise expressly permitted by these Terms. You
            agree not to create any derivative work based on or containing the
            Content. The framing or scraping of or in-line linking to the
            Services or any Content contained thereon and/or the use of
            WebCrawler, spidering or other automated means to access, copy,
            index, process and/or store any Content made available on or through
            the Services other than as expressly authorized by us is prohibited.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            f) You agree not to use the Services for any unlawful purpose. We
            reserve the right to terminate or restrict your access to the
            Website if, in our opinion, your use of the Services may violate any
            laws, regulations or rulings, infringe upon another person's rights
            or violate these Terms.
          </Text>

          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            g) If you submit material to any Media, you represent and warrant
            that{' '}
          </Text>

          <View style={[list.Wrap]}>
            <View
              style={[list.item, !helpers.isDesktop() && { marginBottom: 10 }]}
            >
              <View
                style={[
                  list.Icon,
                  darkMode && { backgroundColor: Colors.lightWhite },
                ]}
              ></View>
              <Text
                style={[
                  helpers.isDesktop() ? list.TextDesktop : list.Text,
                  { color: '#000' },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                you shall not upload, post, transmit, distribute or otherwise
                publish through the Media or any service or feature made
                available on or through the Media, any materials which violate
                any terms of these Terms and Conditions.
              </Text>
            </View>
            <View
              style={[list.item, !helpers.isDesktop() && { marginBottom: 10 }]}
            >
              <View
                style={[
                  list.Icon,
                  darkMode && { backgroundColor: Colors.lightWhite },
                ]}
              ></View>
              <Text
                style={[
                  helpers.isDesktop() ? list.TextDesktop : list.Text,
                  { color: '#000' },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                the User Materials are original to you, that no other party has
                any rights thereto, that any "moral rights" in User Materials
                have been waived, that the User Material is truthful and
                accurate; that use of the User Material does not violate these
                Terms And Conditions and will not cause injury to any person or
                entity; and that you will indemnify the Company and its
                Suppliers, agents, directors, officers, employees,
                representatives, successors, and assigns for all claims
                resulting from the said Material.
              </Text>
            </View>
            <View
              style={[list.item, !helpers.isDesktop() && { marginBottom: 10 }]}
            >
              <View
                style={[
                  list.Icon,
                  darkMode && { backgroundColor: Colors.lightWhite },
                ]}
              ></View>
              <Text
                style={[
                  helpers.isDesktop() ? list.TextDesktop : list.Text,
                  { color: '#000' },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                you grant to the Company and its affiliates a perpetual,
                non-exclusive, irrevocable, royalty-free, sub licensable and
                fully transferable worldwide right and license in any and all
                media, now known or later developed, to use, publish, copy,
                reproduce, display, modify, transmit digitally, translate,
                create derivative works based upon, distribute, store and
                otherwise exploit, such content for any purpose whatsoever
                (including, without limitation, advertising, commercial,
                promotional and publicity purposes) in the Company’s discretion
                without additional notice, attribution or consideration to you
                or to any other person or entity.(c) You agree that the Company
                and its Suppliers, agents, directors, officers, employees,
                representatives, successors, and assigns do not have any
                responsibility and assume no liability for any material
                submitted by you or any third party and may delete or destroy
                any such Material at any time.
              </Text>
            </View>
            <View
              style={[list.item, !helpers.isDesktop() && { marginBottom: 10 }]}
            >
              <View
                style={[
                  list.Icon,
                  darkMode && { backgroundColor: Colors.lightWhite },
                ]}
              ></View>
              <Text
                style={[
                  helpers.isDesktop() ? list.TextDesktop : list.Text,
                  { color: '#000' },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Bloggers will be fully responsible for all activities & user
                actions taken in connection with his blog, the details of
                responsibilities being set out in these Terms and Conditions. By
                posting or submitting content on or to our Media (regardless of
                the form or medium with respect to such content, whether text,
                videos, photographs, audio or otherwise), you are giving us, and
                our affiliates, agents and third party contractors the right to
                display or publish such content on our site and its affiliated
                publications or to store & use such content for promotional and
                marketing purposes. Further, with respect to any submissions to
                us made by you from time to time, you understand and agree that
                we may, or may permit users to, compile, re-edit, adapt or
                modify your submission, or create derivative works therefrom,
                either on a stand-alone basis or in combination with other
                submissions, and you shall have no rights with respect thereto
                and we or our licensees shall be free to display and publish the
                same (as so compiled, re-edited, adapted, modified or derived)
                for any period. You agree to register prior to uploading any
                content and / or comment and any other use of Service or the
                Company IPR or the Media and provide your details including but
                not limited to complete name, age, email address, residential
                address, and contact number.
              </Text>
            </View>
          </View>

          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            h) Private or Sensitive Information on Public Forums: It is
            important to remember that comments submitted to a forum may be
            recorded and stored in multiple places, both on the Media and
            elsewhere on the internet, which are likely to be accessible for a
            long time and you have no control over who will read them
            eventually. It is therefore important that you are careful and
            selective about the personal information that you disclose about
            yourself and others, and in particular, you should not disclose
            sensitive, embarrassing, proprietary or confidential information in
            your comments to our public forums.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            i) User Material containing Protected Content: All information,
            data, text, software, music, sound, photographs, graphics, video,
            messages or other materials ("Content"), whether publicly or
            privately transmitted / posted, is the sole responsibility of the
            person from where such content is originated (the "Originator"). By
            Posting any material which contains images, photographs, pictures or
            GIFs that are otherwise graphical in whole or in part ("Images"),
            you warrant and represent that (a) you are the copyright owner of
            such Images, or that the copyright owner of such Images has granted
            you permission to use such Images or any content and / or images
            contained in such Images consistent with the manner and purpose of
            your use and as otherwise permitted by these Terms and Conditions,
            (b) you have the rights necessary to grant the licenses and
            sublicenses described in these Terms and Conditions, and (c) that
            each person depicted in such Images, if any, has provided consent to
            the use of the Images as set forth in these Terms and Conditions,
            including, by way of limitation, the distribution, public display
            and reproduction of such Images.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            j) You represent that you have valid rights and title in any and all
            Content / Images that you submit on the Media, that you have not
            infringed on any intellectual property rights belonging to any party
            and further that you will indemnify Company or its employees,
            directors, agent and affiliates for all claims arising out of any
            content that you post on the Media.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            k) The Company accepts no responsibility for the said Content /
            Images. However, you understand that all Content / Images posted by
            you becomes the property of Company and you agree to grant / assign
            to Company and its affiliates, a non-exclusive, royalty free,
            perpetual, irrevocable and sub-licensable right to use, reproduce,
            modify, adapt, publish, translate, create derivative works from,
            distribute, perform and display such Content / Images (in whole or
            part) worldwide and / or to incorporate it in other works in any
            form, media, or technology now known or later developed throughout
            the world.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            l) You represent that you are a responsible adult (18 years or
            above) and you shall be solely responsible for all Content that you
            upload post or otherwise transmit.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            m) Company also endeavours to provide a web platform for various
            community interactions for persons to interact and exchange views
            with each other. Members of the general public posts content on such
            services, and therefore the accuracy, integrity or quality of such
            content cannot be guaranteed. You understand that by using such
            services, you may be exposed to objectionable matter.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            n) You may not send, submit, post, or otherwise transmit, material
            or messages that contain software virus, or any other files that are
            designed to interrupt, destroy, and negatively affect in any manner
            whatsoever, any electronic equipment in connection with the use of
            any Media, or other user's ability to engage in real time exchanges.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            o) The Company is under no obligation to monitor any bulletin
            boards, chat rooms, or other areas of the Media through which users
            can supply information or material. The Company reserves the right
            at all times to refuse to post or to remove any information or
            materials, in whole or in part, that its sole discretion are
            objectionable or in violation of these Terms and Conditions.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            p) Not Responsible For and Not Necessarily Hold the Opinions of our
            Contributors / Bloggers / Commenters, etc.: Opinions and other
            statements expressed by users and third parties (e.g., bloggers) are
            theirs alone, not opinions of the Company. The Company is not
            responsible for the accuracy and completeness of blogger /
            contributor content, neither does it endorse or guarantee the same,
            nor accept any obligation or liability for the same. The Company and
            its affiliates, successors, assigns, employees, agents, directors,
            officers and shareholders do not undertake or assume any duty to
            monitor its site for inappropriate or unlawful content.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            q) The Company and its affiliates, successors, assigns, employees,
            agents, directors, officers and shareholders assume no
            responsibility or liability which may arise from the content
            thereof, including, but not limited to, claims for defamation,
            libel, slander, infringement, invasion of privacy and publicity
            rights, obscenity, pornography, profanity, fraud, or
            misrepresentation.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            r) Notwithstanding the foregoing, the Company reserves the right to
            block or remove communications, postings or materials at any time in
            its sole discretion.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderWidth: 0 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  6) PROHIBITED CONTRIBUTION:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) The Website includes comments sections, blogs and other
            interactive features that allow interaction among users and between
            users and the Company. We call the information posted by or
            contributed by users "Contributed Content".
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) In the course of availing of the Services or uploading any post
            or comment on the Website, you shall not post any Contributed
            Content that (i) contains nude, semi-nude, sexually suggestive
            photos, (ii) tends or is likely to abuse, harass, threaten,
            impersonate or intimidate other users of the Website and/or
            Services, (iii) is lascivious or appeals to the prurient interest or
            if its effect is such as to tend to deprave and corrupt persons who
            are likely to use or have access to the Website and/or Services, or
            (iv) otherwise violates, is prohibited or restricted by applicable
            law, rule or regulation, is offensive or illegal or violates the
            rights of, harms or threatens the safety of other users of the
            Website and/or Services (collectively "Prohibited Content").
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) The Company reserves the right to cease to provide you with the
            Services or with access to the Website, prevent you from accessing
            the Website and/or Services and terminate your subscription, with
            immediate effect and without notice and liability, for violating
            these Terms, applicable law, rules or regulations and the reserves
            the right to remove Prohibited Content which is in violation of
            these Terms, or is otherwise abusive, illegal or disruptive. The
            determination of whether any content constitutes Prohibited Content,
            violates these Terms, or is otherwise abusive illegal or disruptive,
            is subject to the sole determination of the Company.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  7) INTELLECTUAL PROPERTY RIGHTS:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) Except as otherwise expressly stated herein, all rights, title
            and interest in the Website, its contents and the online services
            therein, are owned by the Company. No part of this Website may be
            reproduced, distributed, republished, retransmitted, disseminated,
            displayed, adapted, altered, hyperlinked or otherwise used in any
            form or by any means without the prior written consent of the
            Company. You also may not, without the prior written consent of the
            Company mirror or frame any part or whole of the contents of the
            Website on any other server wherever situated or as part of any
            other website.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) All proprietary content, trademarks, service marks, brand names,
            logos and other intellectual property displayed in this Website are
            the property of the Company and where applicable, third party
            proprietors identified in any Website. No right or licence is
            granted directly or indirectly to any party accessing any Website to
            use or reproduce any such proprietary content, trademarks, service
            marks, brand names, logos and other intellectual property, and no
            party accessing any Website shall claim any right, title or interest
            therein are of any other website. In addition, you agree that you
            will not use any of the following but not limited to, robot, spider,
            software, programme, algorithm or any other automatic device or
            manual process to monitor or copy our web pages or the content
            contained herein, without the prior written consent of the Company
            (such consent is deemed given for standard search engine technology
            employed by Internet search websites to direct Internet users to any
            Website).
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) The domain name on which the Site is hosted on is the sole
            property of the Company and you may not use or otherwise adopt a
            similar name for your own use.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  8) THIRD PARTY LINKS:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) Any hyperlink included in the Website and resources provided by
            third parties are for information only and are not an endorsement or
            verification of such linked websites. You agree that the Company has
            no control over the contents of these websites or resources and
            assumes no responsibility for the contents of or the consequences or
            for any loss or damage that may arise from accessing such linked
            websites. You agree that your access to and/or the use of any such
            linked website is entirely at your own risk and subject to the terms
            and conditions of access and/or use contained therein. We are not
            responsible for the privacy practices of any such linked website,
            and encourage you to familiarise yourself with the privacy policies
            of such third party websites. Some of these third party websites may
            be co-branded with our logo or trademark, even though they are not
            operated or maintained by us. Once you are directed to any third
            party website, you should check the applicable personal data policy
            of the third party website to determine how they will handle any
            personal data which they may collect from you. The Company is not
            responsible for any third party content (including but not limited
            to any computer viruses or other disabling features), nor does the
            Company have any obligation to monitor such third party content.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  9) DISCLAIMER:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) The information and materials contained in any Website are
            provided on an “as is” and “as available” basis without warranty of
            any kind, either express or implied, including but not limited to
            the warranties of title, merchantability, satisfactory quality,
            non-infringement of third party intellectual property rights and
            freedom from computer virus as well as fitness for a particular
            purpose. You further acknowledge and agree that any use by you of
            any content submitted by any third party is entirely at your own
            risk. The Company shall not bear any liability in connection with
            any use of any content made available on the Website or any
            infringement of any rights arising in connection therewith, and you
            hereby release the Company from any and all claims in connection
            therewith. The Company does not and is not in a position to verify
            any party’s rights to submit any content on the Website, and The
            Company takes no responsibility and assumes no liability for any
            content posted by you or any third party, or for use of the same.
            The Company does not in any way endorse or support the views or
            ideas expressed in any submitted content.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) The Company also does not warrant the truth, accuracy,
            timeliness, adequacy, completeness or reasonableness of such
            information and materials and expressly disclaims liability for any
            errors in, or omissions from such information and materials. The
            material including such material provided by the Company under the
            Subscription model at any time and must not be relied upon in
            connection with any investment decision. You should seek
            professional advice at all times and obtain independent verification
            of the information and materials before making any decision based on
            such information and materials. The information and materials
            contained in any Website shall not be construed as an offer or a
            solicitation to sell, buy, give, take, issue, allot or transfer, or
            as the giving of any advice in respect of shares, stocks, bonds,
            notes, interests, unit trusts, mutual funds or other securities,
            investments, loans, advances, credits or deposits in any
            jurisdiction.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && {
                      borderBottomWidth: 0,
                      fontSize: 22,
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  10) INDEMNITY:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            You (a) represent, warrant and covenant that no materials of any
            kind provided by you will (i) violate, plagiarise, or infringe upon
            the rights of any third party, including copyright, trademark,
            privacy or other personal or proprietary rights; or (ii) contain
            libellous, Prohibited Content or other unlawful material; and (b)
            hereby agree to indemnify, defend and hold harmless the Company and
            all of the Company's officers, directors, owners, agents,
            customers/clients, information providers, affiliates, licensors and
            licensees (collectively, the "Indemnified Parties") from and against
            any and all liability and costs, including, without limitation,
            reasonable advocate's fees, incurred by the Indemnified Parties in
            connection with any claim arising out of any breach by you of these
            Terms or the foregoing representations, warranties and covenants.
            You shall cooperate as fully as reasonably required in the defence
            of any such claim. The Company reserves the right, at its own
            expense, to assume the exclusive defence and control of any matter
            subject to indemnification by you.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  10) GOVERNING LAW:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            You agree that by accessing any Website and/or using the online
            services herein, your access and/or use, as well as these terms and
            conditions shall be governed by and construed in accordance with the
            laws of the Republic of Singapore and you agree to submit to the
            non-exclusive jurisdiction of the courts of the Republic of
            Singapore.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  11) COOKIES:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) By visiting the Website with your browser settings adjusted to
            accept cookies, you consent to our use of cookies and other similar
            technologies to provide our services. You can however modify the
            settings in your browser to notify you when you receive a new cookie
            and disable cookies altogether but doing so may reduce functionality
            of the Website.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) We may use cookies and store these on your computer through your
            web browser to enable our systems to recognise your browser and to
            provide services or advertisements which may be tailored to your
            preferences. Cookies may also identify you when you sign-in to our
            Website, and may be used by us to deliver content, including
            advertisements, relevant to your interests, conducting research and
            diagnostics to improve our content, products and services, prevent
            fraudulent activities and improve security.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  12) TERMINATION AND SUSPENSION:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            The Company will determine, in its discretion, whether there has
            been a breach of the aforesaid terms and conditions of access
            through your use of our website. Failure to comply with terms and
            conditions of access constitutes a material breach, and may result
            in the Company taking actions against you, including but not limited
            to the following:
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) Immediate, temporary or permanent withdrawal of your right to use
            any Website.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) Immediate, temporary or permanent removal of any posting or
            material uploaded by you to any Website.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) Issue a warning to you
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            d) Initiate legal proceedings against you for reimbursement of all
            costs on an indemnity basis (including, but not limited to,
            reasonable administrative and legal costs) resulting from the
            breach.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            e) Further legal action against you.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            f) Disclosure of such information to law enforcement authorities as
            the Company may reasonably opine is necessary.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            The Company excludes liability for actions taken in response to
            breaches of these terms and conditions of access.
          </Text>
        </View>
        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
          <View
            style={[
              Margin.mb_4,
              Flex.row,
              !helpers.isDesktop() && Flex.justifyStart,
              !helpers.isDesktop() && Margin.mb_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: 'white',
                  zIndex: 1,
                  paddingHorizontal: 15,
                  flex: 1,
                },
                helpers.isDesktop() && { paddingHorizontal: 20 },
                darkMode && { backgroundColor: Colors.darkBlackColorBg },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() && Helpers.borderBottom,
                  helpers.isDesktop() && { marginRight: 25 },
                  darkMode && { borderBottomColor: Colors.lineColor },
                ]}
              >
                <Text
                  style={[
                    pageContent.sectionTitle,
                    helpers.isDesktop() && pageContent.sectionTitleDesktop,
                    helpers.isDesktop() && { marginRight: 0 },
                    { paddingBottom: 5 },
                    !helpers.isDesktop() && { borderWidth: 0, fontSize: 22 },
                    !helpers.isWeb() && { borderBottomWidth: 0 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  13) LIMITATION OF LIABILITY:
                </Text>
              </View>
            </View>
            {/* {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>} */}
            {helpers.isDesktop() && (
              <View style={{ width: '100%', flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: Colors.lineColor },
                  ]}
                ></View>
              </View>
            )}
          </View>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            a) YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK.
            NEITHER COMPANY, ITS AFFILIATES, NOR ANY OF ITS EMPLOYEES, AGENTS,
            THIRD PARTY CONTENT PROVIDERS, OR LICENSORS WARRANT THAT THE SERVICE
            WILL BE UNINTERRUPTED OR ERROR FREE. NOR DO THEY MAKE ANY WARRANTY
            AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE,
            OR AS TO THE ACCURACY, RELIABILITY, COMPLETENESS, OR CONTENTS OF ANY
            CONTENT, INFORMATION, MATERIAL, POSTINGS, OR POSTING RESPONSES FOUND
            ON THE SERVICE, ANY MERCHANDISE OR SERVICES PROVIDED THROUGH THE
            SERVICE, OR ANY LINKS TO OTHER SITES MADE AVAILABLE ON THE SERVICE
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            b) THE SERVICE AND ALL CONTENT, MATERIAL, INFORMATION, POSTINGS, OR
            POSTING RESPONSES FOUND ON THE SERVICE ARE PROVIDED ON AN "AS IS"
            BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED
            WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          </Text>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 32 : 24,
                letterSpacing: 0.3,
                fontFamily: FontFamily.merriweather,
                marginBottom: helpers.isDesktop() ? 30 : 20,
                paddingHorizontal: 20,
                color: '#000',
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            c) IN NO EVENT WILL COMPANY (OR ANY OF ITS PARENTS, SUBSIDIARIES,
            AFFILIATES, EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS, OR
            LICENSORS, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND
            AGENTS), BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY
            TO USE, ANY CONTENT, INFORMATION, MATERIAL, POSTINGS, OR POSTING
            RESPONSES ON THE SERVICE OR THE SERVICE ITSELF. THESE LIMITATIONS
            APPLY REGARDLESS OF WHETHER THE PARTY LIABLE OR ALLEGEDLY LIABLE WAS
            ADVISED, HAD OTHER REASON TO KNOW, OR IN FACT KNEW OF THE
            POSSIBILITY OF SUCH DAMAGES. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE
            THAT COMPANY (AND ANY OF ITS PARENTS, SUBSIDIARIES, AFFILIATES,
            EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS, OR LICENSORS, AND
            THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS), IS NOT
            LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY USER,
            INCLUDING YOU.
          </Text>
        </View>
      </View>
    );
  }
}

export default TermsCondition;
