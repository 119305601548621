import React, { Component } from 'react';
import { View, TouchableOpacity, Switch } from 'react-native';
import { Colors } from '../../styles/appStyles';


export class CustomSwitch extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() {
        const { item, index } = this.props;

        return (
            <>
                <TouchableOpacity onPress={() => this.props.handleClick()}>
                    <View>
                        <Switch
                            trackColor={{
                                false: Colors.switchTrackInActive,
                                true: Colors.switchTrackActive,
                            }}
                            thumbColor={
                                item.subscribed
                                    ? Colors.switchThumbInActive
                                    : Colors.switchThumbActive
                            }
                            value={item.subscribed}
                            disabled={this.props.disable}
                        />
                    </View>
                </TouchableOpacity>
            </>
        );
    }
}

export default CustomSwitch;