import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, button, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors, input, bannerStyles, Messages, Typo, } from '../../styles/appStyles';

import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';

export class JoinYoutube extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount = () => {
  }

   pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "catch up on all the past events",
      newsletter_section: "NA",
      CTA_text: "all videos",
      CTA_position: "bottom",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
    dataLayer.push({
      event: "banner_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      category_type: "NA",
      banner_name: "catch up on all the past events",
      banner_position: "bottom",
      CTA_text: "all videos",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  async openLink(url) {
    try {
      if (await InAppBrowser.isAvailable() && !url.includes('mailto:') && url.startsWith('http')) {
        let { darkMode } = this.props;
        const result = await InAppBrowser.open(url, {
          // iOS Properties
          dismissButtonStyle: 'cancel',
          preferredBarTintColor: darkMode ? '#000000' : '#ffffff',
          preferredControlTintColor: darkMode ? '#ffffff' : '#000000',
          readerMode: false,
          animated: true,
          modalPresentationStyle: 'fullScreen',
          modalTransitionStyle: 'coverVertical',
          modalEnabled: true,
          enableBarCollapsing: false,

          toolbarColor: darkMode ? '#000000' : '#ffffff',

          animations: {
            startEnter: 'slide_in_right',
            startExit: 'slide_out_left',
            endEnter: 'slide_in_left',
            endExit: 'slide_out_right'
          }
        })
      }
      else {
        Linking.openURL(url)
      }
    } catch (error) {
      console.log("check error =>", error);
      // Alert.alert(error.message)
    }
  }

  render() {
    const { catchUp } = this.props;
    let webView = helpers.isWeb();
    let btnClickYoutube;
    if (webView) {
      btnClickYoutube = {
        href: "https://www.youtube.com/channel/UCYAZQvp_LMnL_IAVB8L-rOQ",
        handleClick: () => {
          this.pushInformationToDataLayer()
        }
      }
    } else {
      btnClickYoutube = {
        onPress: () => this.openLink('https://www.youtube.com/channel/UCYAZQvp_LMnL_IAVB8L-rOQ')
      }
    }
    // const joinyoutubebanner = helpers.isDesktop()? require("../../../../assets/img/JoinBanner.png") : require("../../../../assets/img/JoinBannerMobile.png") 
    const joinyoutubebannerweb = getImagePath("img/footerlogo.png")
    const joinyoutubebannermobile = getImagePath("img/illustration.png")

    let { fulWidth, subscription, lightBlueBg, home, postSingle, darkMode, studentSubscription, whiteBg } = this.props;
    return (
      <View style={[{ flexDirection: helpers.isDesktop() ? 'row' : 'column', justifyContent: 'space-evenly', alignContent: 'center', padding: helpers.isDesktop() ? 70 : 20, width: '100%' }, !darkMode && { backgroundColor: '#F3F5FF' }, !helpers.isDesktop() && { paddingTop: 48, paddingBottom: 48 }]}>
        {helpers.isDesktop() ?
          <>
            <View style={[{ zIndex: 2, width: '50%', marginLeft: 50 }]}>
              {/* <Text style={[bannerStyles.heading, { fontSize: helpers.isDesktop() ? 70 : 30, marginBottom: 10 }, helpers.isWeb() && { fontWeight: FontWeight.bold}, !helpers.isWeb() && {fontFamily: FontFamily.semiBold}]}>Context is everything.</Text>
                      <Text accessibilityRole="header" aria-level="1" style={[bannerStyles.heading, { fontSize: helpers.isDesktop() ? 45 : 20, marginBottom: 10 }, helpers.isWeb() && { fontWeight: FontWeight.bold}, !helpers.isWeb() && {fontFamily: FontFamily.semiBold}]}>Only read stories that matter.</Text> */}

              {!catchUp && <Text accessibilityRole="header" aria-level="1" style={[bannerStyles.heading, { color: darkMode ? 'white' : '#000000', fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 44 : 16, marginTop: 30, top: 5 }]}>Join live video Q&A’s</Text>}
              {catchUp && <Text accessibilityRole="header" aria-level="1" style={[bannerStyles.heading, { color: darkMode ? 'white' : '#000000', fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 44 : 16, marginTop: 30, top: 5 }]}>{catchUp}</Text>}
              <View style={{ borderWidth: helpers.isDesktop() ? 1 : 0, borderLeftColor: 'black', width: 120 }} />

              <Text style={[bannerStyles.heading, { color: darkMode ? 'white' : '#000000', width: helpers.isDesktop ? "80%" : 'auto', fontSize: helpers.isDesktop() ? 24 : 17, marginTop: 30, top: 5 }]}>
                Every fortnight, TMC holds a by-invitation only discussion with founders and experts from various fields.
              </Text>

              <View style={[helpers.isDesktop() && Flex.row, Margin.mt_2], { alignSelf: 'flex-start', marginTop: 20 }}>
                <TouchableOpacityLinkExternal {...btnClickYoutube} newTab={true}>
                  <ButtonGradient
                    title={'ALL VIDEOS'}
                    rootStyle={{
                      btnWrapper: [button.primaryGradient, { height: 55 }],
                      btnText: [button.primaryGradientText, { fontSize: 17, letterSpacing: 0.05, paddingHorizontal: 35, fontWeight: '600', fontFamily: FontFamily.regular }]
                    }}
                    paddingOverride={true}
                    onClick={() => {
                      this.pushInformationToDataLayer()
                     }}
                    darkMode={darkMode}
                  />
                </TouchableOpacityLinkExternal>
              </View>
            </View>
            <View style={{ marginRight: 100 }}>
              <CustomImage
                source={darkMode ? joinyoutubebannerweb : joinyoutubebannerweb}
                require={true}
                //   style={{ width: 500, height: helpers.isDesktop() ? 200 : 300 }}
                webStyle={{ width: 475, height: 250 }}
                altText={'The Morning Context'}
              />
            </View>
          </>
          :
          <>
            <View style={[{ zIndex: 2 }]}>

              {!catchUp && <Text accessibilityRole="header" aria-level="1" style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_3, { fontFamily: FontFamily.abrilFatface }, { fontSize: 32, lineHeight: 47, marginBotton: 20, color: '#051462', fontWeight: 400 }, darkMode && { color: Colors.lightWhite }]}>Join live video Q&A’s</Text>}
              {catchUp && <Text accessibilityRole="header" aria-level="1" style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontFamily: FontFamily.abrilFatface }, { fontSize: 32, lineHeight: 47, color: '#051462', paddingBottom: 40 }, darkMode && { color: Colors.lightWhite }]}>{catchUp}</Text>}
              <View style={{ borderWidth: helpers.isDesktop() ? 1 : 0, borderLeftColor: 'black', width: 50, alignSelf: 'center' }} />

              <Text style={[bannerStyles.heading, { color: '#000000', alignSelf: 'center', textAlign: 'center', fontSize: 18, lineHeight: 28.4 }, !helpers.isDesktop() && { paddingBottom: 40 }]}>
                Every fortnight, TMC holds a by-invitation only discussion with founders and experts from various fields.
              </Text>
              {catchUp && !helpers.isDesktop() &&
                <CustomImage
                  source={darkMode ? joinyoutubebannermobile : joinyoutubebannermobile}
                  require={true}
                  style={{ height: helpers.isDesktop() ? 200 : 300 }}
                  webStyle={{ width: 'auto', height: 'auto' }, !helpers.isDesktop() && { paddingBottom: 20 }}
                  altText={'The Morning Context'}
                />
              }
              <View style={{ alignSelf: 'center', padding: 20 }}>
                <TouchableOpacityLinkExternal {...btnClickYoutube} newTab={true}>
                  <ButtonGradient
                    title={'ALL VIDEOS'}
                    rootStyle={{
                      btnWrapper: [button.primaryGradient, { height: 55 }],
                      btnText: [button.primaryGradientText, { fontSize: 17, paddingHorizontal: 35, fontWeight: '600', fontFamily: FontFamily.regular }]
                    }}
                    paddingOverride={true}
                    onClick={() => { this.openLink('https://www.youtube.com/channel/UCYAZQvp_LMnL_IAVB8L-rOQ') }}
                    darkMode={darkMode}
                  />
                </TouchableOpacityLinkExternal>
              </View>
            </View>


          </>
        }
      </View >
    );
  }
}

export default JoinYoutube;