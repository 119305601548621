

const planIdToBillingFrequency = {
    'monthly-plan-in': {
        billingFrequency: 'monthly',
        currency: 'INR'
    },

    'monthly-plan-usd': {
        billingFrequency: 'monthly',
        currency: 'USD'
    },

    'annual-plan-in': {
        billingFrequency: 'annual',
        currency: 'INR'
    },

    'annual-plan-special-offer-in': {
        billingFrequency: 'annual',
        currency: 'INR'
    },

    'annual-plan-discounted-inr': {
        billingFrequency: 'annual',
        currency: 'INR'
    },

    'annual-plan-usd': {
        billingFrequency: 'annual',
        currency: 'USD'
    },

    '2-year-plan-inr': {
        billingFrequency: '2-year',
        currency: 'INR'
    },

    '2-year-plan-usd': {
        billingFrequency: '2-year',
        currency: 'USD'
    },
}

const billingFrequencyToPlan = {
    'INR': {
        'monthly': 'monthly-plan-in',
        'annual': 'annual-plan-in',
        '2-year': '2-year-plan-inr'
    },
    'USD': {
        'monthly': 'monthly-plan-usd',
        'annual': 'annual-plan-usd',
        '2-year': '2-year-plan-usd'
    }
}


export const getPlanInfoObject = (planId) => {
    return planIdToBillingFrequency[planId]
}

export const getPlanFromCurrency = (currency, billingFrequency) => {

    return billingFrequencyToPlan[currency]?.[billingFrequency]
}