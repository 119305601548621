import React, { Component } from 'react';
import { Flex, Padding, Margin, Helpers, Typo, appStyles, FontFamily, Colors } from '../../styles/appStyles';
import { View, Text, TouchableOpacity, Platform } from "react-native";
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
const uploadIcon = getImagePath('icons/fileuploadicon.png');
class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: ''
        }

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        let inputStyles = {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            fontSize: 0,
            cursor: 'pointer',
            opacity: 0
        }
        let { btnText, clickHandler, btnContainerStyles, disabled = false, showUploadLoader, selectedFile, darkMode } = this.props;
        let { fileName } = this.state;

        let removeBtnProps;
        if (Platform.OS == 'web') {
            removeBtnProps = {
                onClick: () => this.props.uploadDoc('')
            }
        } else {
            removeBtnProps = {
                onPress: () => this.props.uploadDoc('')
            }
        }

        return (
            <>
                <Text style={{ fontSize: 13, fontFamily: FontFamily.merriweatherB, textAlign: helpers.isDesktop() ? 'right' : 'left', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.6)', marginBottom: 10 }}>.PDF .JPG .JPEG .PNG upto 5MB | Max 3 files</Text>
                <View style={{ backgroundColor: 'rgba(108, 122, 246, 0.05)', borderRadius: 4, borderWidth: 2, borderColor: '#C4C4C4', borderStyle: 'dotted', position: 'relative', paddingTop: 40, paddingBottom: 40, paddingLeft: 10, paddingRight: 10 }}>
                    <View style={{ alignItems: 'center' }}>
                        <input id="fileInput" style={inputStyles} type="file" accept="" onChange={(e) => this.handleChange(e.target.files[0])} />
                        <CustomImage
                            source={uploadIcon}
                            require={true}
                            style={{ width: 95, height: 60, resizeMode: 'contain', marginBottom: 10 }}
                            webStyle={{ width: 95, height: 60, objectFit: 'contain', marginBottom: 10 }}
                        />
                        <Text style={{ fontSize: 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)', marginBottom: 10 }}>Drag files here to Upload</Text>
                        <Text style={{ fontSize: 16, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.7)' }}>Or <Text style={{ fontFamily: FontFamily.merriweatherB, color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.8)', textDecorationLine: 'underline' }}>Click to Browse</Text></Text>
                    </View>
                </View>
            </>
        )
    }

    handleChange = (file) => {
        let fileReader = new FileReader();
        let base64 = fileReader.readAsDataURL(file);

        fileReader.onload = (event) => {
            let base64File = event.target.result;
            this.props.uploadDoc(file, base64File);
            this.setState({ fileName: file.name });
        };
    }

    viewPdf = () => {
        let { selectedFile } = this.props;
        let fileReader = new FileReader();
        let base64 = fileReader.readAsDataURL(selectedFile);

        fileReader.onload = (event) => {
            let base64File = event.target.result;
            this.props.showPreview(base64File);
        };

    }

}

export default UploadFile;                       