import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Platform,
  StatusBar,
  Image,
} from "react-native";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "../../redux/store-web";
import { routes } from "./web-routes.js";
import { helpers } from "../utils/helpers";
import NotFoundScreen from "../../web/not-found-screen";
import ReactGA from "react-ga";
import mixpanel from "mixpanel-browser";
import NetworkDetector from "../components/newtwork-detector";
import AddToHomescreen from "../components/add-to-homescreen";
import AddToHomescreenIos from "../components/add-to-homescreen-ios";
import NoInternetConnection from "../components/no-internet";
import getImagePath from "../utils/image-helper";
import NotificationAlert from "../components/notification-alert";
import UserBlocked from "../screens/user-blocked";
import UserInactiveCheck from "../components/user-inactive-check";

import AuthenticationWrapper from "../components/authentication-wrapper";
import { ChargebeeController } from "../utils/chargebee";

import Cookies from "universal-cookie";
import apiHelpers from "../utils/api-helpers";
import appendScript from "../utils/webengage/appendScript";

const webstore = configureStore();
const cookies = new Cookies();
const noInternet = getImagePath("img/no-internet.png");

const trackingId = process.env.GA_TRACKING_ID;
const mixpanelToken = process.env.MIX_PANEL_TOKEN;

class Routes extends Component {
  deferredPrompt;
  installPromptListner;
  installPromptSeenExpiryHours = 2;
  constructor(props) {
    super(props);
    this.state = {
      isUserInactive: false,
      webType: helpers.isDesktop(),
      isDisconnected: false,
      showAddToHomeForIos: false,
    };
    this.yesterdayScreen = [];
    this.isYesterdayScreenEnable = false;
    if (process.browser) {
      ReactGA.initialize(trackingId);
      mixpanel.init(mixpanelToken);

      // this.clearAuth0LoginSession()
      this.setupChargebeeHandle();
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", () => {
      setTimeout(() => {
        this.setState({
          webType: helpers.isDesktop(),
        });
      }, 20);
    });

    document.body.style = "font-family: Merriweather-Regular;";
    let nodeALL = document.createElement("style");
    let textnodeAll = document.createTextNode(
      "body *{font-variant-numeric: lining-nums !important;}"
    );
    nodeALL.appendChild(textnodeAll);
    document.body.appendChild(nodeALL);

    if (window.location.search.includes("fontOverride=")) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("fontOverride=");
      });
      if (foundSearch) {
        let search = foundSearch.replace("fontOverride=", "");
        if (search != "") {
          let node = document.createElement("style");
          let textnode = document.createTextNode(
            ".postsingle-bodyfont *{font-family: " + search + " !important;}"
          );
          node.appendChild(textnode);
          document.body.appendChild(node);
        }
      }
    }

    document.body.oncopy = function () {
      var body_element = document.getElementsByTagName("body")[0];
      var selection = window.getSelection();
      var selection_text = selection.toString();
      var copytext = "";
      if (selection_text.length > 400) {
        var trimmed_text = selection_text.substring(0, 400);
        trimmed_text = trimmed_text.substr(
          0,
          Math.min(trimmed_text.length, trimmed_text.lastIndexOf(" "))
        );
        if (trimmed_text != "") {
          copytext = trimmed_text + " ...<br/>";
        }
      } else {
        var copytext = selection_text + "<br/>";
      }
      var pagelink =
        "Read more at: <a href='" +
        document.location.href +
        "'>" +
        document.location.href +
        "</a>";
      copytext = copytext + pagelink + "<br/>Copyright © The Morning Context";
      var newdiv = document.createElement("div");
      newdiv.setAttribute("style", "position: fixed; opacity: 0;");
      body_element.appendChild(newdiv);
      newdiv.innerHTML = copytext;
      selection.selectAllChildren(newdiv);
      window.setTimeout(function () {
        body_element.removeChild(newdiv);
      }, 0);
    };

    let print_nodeALL = document.createElement("style");
    print_nodeALL.setAttribute("media", "print");
    let print_textnodeAll = document.createTextNode(
      "body {visibility: hidden; display: none;}"
    );
    print_nodeALL.appendChild(print_textnodeAll);
    document.body.appendChild(print_nodeALL);

    this.addToHomeScreen();
    // this.addToHomeScreenForiOS();
    if (Platform.OS == "web") {
      appendScript(process.env.WEBENGAGE_LICENSE_CODE);
    }
  };


  clearAuth0LoginSession = () => {

    const { auth0Logout } = this.props.auth;

    const allCookies = cookies.getAll()

    console.log('OAUTH2DEBUG:ALL:', allCookies)

    const requiredKey = `@@auth0spajs@@::${process.env.LEGACY_AUTH0_CLIENT_ID}::https://api.themorningcontext.com/api/v1::openid profile email offline_access`;

    const auth0Data = localStorage.getItem(requiredKey)
    const isLegacySession = auth0Data? true: false
    if(!isLegacySession){
      return
    }
    console.log('OAUTH2DEBUG:old-session', auth0Data)
    // 1. Remove Auth0 localstorage dta
    localStorage.removeItem(requiredKey)
    // 2. Clear Auth0 cookies
    const auth0Cookies = [
      'auth0.is.authenticated',
      '_legacy_auth0.is.authenticated',
      `auth0.${process.env.AUTH0_CLIENT_ID}.is.authenticated`,
      `_legacy_auth0.${process.env.AUTH0_CLIENT_ID}.is.authenticated`,
    ]
    console.log('OAUTH2DEBUG:KEYS', auth0Cookies)
    auth0Cookies.forEach((cookieName) => {
      try{
        cookies.remove(cookieName)
      }catch(err){
        console.log('OAUTH2DEBUG:cookieDelete:error: ', err)
      }
    })

    // 3. Logout from Auth0
    auth0Logout()
  }

  chargebeeControllerActivator = (controllerInstance) => {
    const { fetchToken } = this.props.auth;
    let endpoint = `/profile`;
    let body = {
      request_group: ["profile", "subscription"],
    };

    return new Promise((resolve, reject) => {
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, fetchToken)
        .then((res) => {
          if (res.success != true) {
            console.log("chargebeeControllerActivator:Error: ", res);
            reject(
              `chargebeeControllerActivator:Error: request to ${endpoint} failed`
            );
            return false;
          }
          controllerInstance.setCustomerInfo(res.data);
          let activeSubscription = res.data.subscription;
          if (!helpers.isEmptyDict(res.data.team_subscription)) {
            if (res.data.team_subscription.is_team_owner) {
              activeSubscription = res.data.team_subscription;
            }
          }
          controllerInstance.activeSubscription = activeSubscription;
          resolve("active");
        }).catch(err => {
          console.log("chargebee:chargebeeControllerActivator:Error: ", err)
          return
        })
    });
  };

  setupChargebeeHandle = () => {
    const { fetchToken } = this.props.auth;
    if (!fetchToken) {
      return;
    }
    ChargebeeController.setToken(fetchToken);

    if (ChargebeeController.status == "active") {
      return;
    }
    ChargebeeController.activateController(
      this.chargebeeControllerActivator(ChargebeeController)
    );
    ChargebeeController.activatedController().then((cb_handle) => {
      let activeSubscription = cb_handle.activeSubscription
      if (activeSubscription && activeSubscription.subscription_id) {
        let cb_context = {
          subscription: {
            id: activeSubscription.subscription_id,
          },
        };
        cb_handle.setParam(cb_context);
      }
    })
  };

  addToHomeScreen = () => {
    this.installPromptListner = window.addEventListener(
      "beforeinstallprompt",
      this.beforeinstallprompt
    );
  };

  beforeinstallprompt = (e) => {
    e.preventDefault();
    // Stash the event so it can be triggered later.
    // this.deferredPrompt = e;
    // if (this.checkLastSeenPrompt()) {
    //     setTimeout(() => {
    //         this.setState({ showAddToHomeScreen: true });
    //         this.setInstallPromptSeenDate();
    //     }, 3000)
    // }
  };

  setInstallPromptSeenDate = () => {
    let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
    let timestamp = new Date().getTime();
    cookies.set("installPromptSeenDate", timestamp.toString(), {
      path: "/",
      expires: cookieDate,
    });
  };

  checkLastSeenPrompt = () => {
    if (
      !cookies.get("installPromptSeenDate") ||
      (new Date().getTime() - parseInt(cookies.get("installPromptSeenDate"))) /
        (1000 * 60) >
        this.installPromptSeenExpiryHours
    ) {
      return true;
    }
    return false;
  };

  closeAddToHomeScreen = () => {
    this.setState({ showAddToHomeScreen: false, showAddToHomeForIos: false });
    this.setInstallPromptSeenDate();
  };

  showInstallPrompt = () => {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
    }
    this.setState({ showAddToHomeScreen: false });
    window.removeEventListener("beforeinstallprompt", this.beforeinstallprompt);
  };

  addToHomeScreenForiOS() {
    if (
      helpers.isAppleDevice() &&
      helpers.isSafari() &&
      window.location.search != "?standalone=true" &&
      this.checkLastSeenPrompt()
    ) {
      setTimeout(() => {
        this.setState({ showAddToHomeForIos: true });
      }, 5000);
    }
  }

  setIsDisconnected = (value) => {
    let offlineScreenPath = null;
    if (value) {
      offlineScreenPath = window.location.href;
    }
    this.setState({
      isDisconnected: value,
      offlineScreenPath: offlineScreenPath,
    });
  };

  resetOfflineScreenPath = () => {
    this.setState({ offlineScreenPath: "no-connection" });
  };

  render() {
    let {
      isDisconnected,
      showAddToHomeScreen,
      showAddToHomeForIos,
      offlineScreenPath,
      isUserInactive,
    } = this.state;
    return (
      <Provider store={webstore}>
        <View
          style={[
            (localStorage.getItem("isYesterdayScreenEnable") == "0" ||
              !helpers.isDesktop()) && { overflowX: "hidden" },
            {
              width: "100%",
              // overflowX:"hidden"
            },
            {
              width: "100%",
            },
            isDisconnected &&
              offlineScreenPath == window.location.href && {
                pointerEvents: "none",
                filter: "grayscale(0.9)",
              },
          ]}
          key={this.state.webType}
        >
          <Router>
            <Switch>
              {routes.map(({ path, component: Component, params }, index) => {
                return (
                  <Route
                    key={path}
                    exact={true}
                    path={path}
                    render={(props) =>
                      isDisconnected &&
                      offlineScreenPath != window.location.href ? (
                        <NoInternetConnection
                          resetOfflineScreenPath={this.resetOfflineScreenPath}
                        />
                      ) : isUserInactive ? (
                        <UserBlocked
                          {...props}
                          setUserInactive={(value) =>
                            this.setState({ isUserInactive: value })
                          }
                        />
                      ) : (
                        <Component
                          {...props}
                          postData={this.props.data}
                          params={params ? params : false}
                        />
                      )
                    }
                  />
                );
              })}
              <Route component={NotFoundScreen}></Route>
            </Switch>
          </Router>
        </View>
        <UserInactiveCheck
          setUserInactive={(value) => this.setState({ isUserInactive: value })}
        />
        <NetworkDetector
          isDisconnected={(value) => this.setIsDisconnected(value)}
        />
        <AddToHomescreen
          showAddToHomeScreen={showAddToHomeScreen}
          showInstallPrompt={this.showInstallPrompt}
          closeAddToHomeScreen={this.closeAddToHomeScreen}
        />
        {showAddToHomeForIos && (
          <AddToHomescreenIos
            closeAddToHomeScreen={this.closeAddToHomeScreen}
          />
        )}
        {/* <NotificationAlert /> */}
        <View style={{ position: "absolute", zIndex: -9999, opacity: 0 }}>
          <Image source={noInternet} resizeMode={"cover"} />
        </View>
      </Provider>
    );
  }
}

export default AuthenticationWrapper(Routes);
