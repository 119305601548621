import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PostSingle from "../../native/screens/post-single";
import ReactGA from "react-ga";

const he_parser = require("he");

export class PostSingleScreen extends Component {
  constructor(props) {
    if (process.browser) {
      window.history.scrollRestoration = "manual";
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    }
    super(props);
    let postData = process.browser ? document.getElementById("postData") : null;
    let footerData = process.browser
      ? document.getElementById("footerData")
      : null;
    let screenName = this.props.location.prevPath;
    this.state = {
      searchQuery: "",
      locationHash: "",
      categorySlug: this.props.match.params.category_slug,
      postSlug: this.props.match.params.post_slug,
      screenName: screenName,
      postData: postData
        ? JSON.parse(postData.getAttribute("data"))
        : JSON.stringify(this.props.postData) != "{}"
        ? this.props.postData
        : null,
      token: this.props.token,
      footerData: footerData
        ? JSON.parse(footerData.getAttribute("data"))
        : JSON.stringify(this.props.footerData) != "{}"
        ? this.props.footerData
        : null,
      scrollTop: false,
    };
    if (process.browser) {
      if (postData) {
        postData.parentNode.removeChild(postData);
      }
      if (footerData) {
        footerData.parentNode.removeChild(footerData);
      }

      try {
        ReactGA.pageview(this.props.history.location.pathname);
      } catch (error) {
        console.log("GA tracking error =>", error);
      }
    }
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.history.scrollRestoration = "manual";
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      setTimeout(function () {
        document.body.style.overflow = "initial";
        document.body.style.position = "initial";
      }, 20);
    }, 20);
    if (window.location.search != this.state.searchQuery) {
      this.setState({
        searchQuery: window.location.search,
      });
    }
    if (window.location.hash != this.state.locationHash) {
      this.setState({
        locationHash: window.location.hash,
      });
    }
  };

  componentDidUpdate = () => {
    window.history.scrollRestoration = "manual";
    if (window.location.search != this.state.searchQuery) {
      this.setState({
        searchQuery: window.location.search,
      });
    }
    if (window.location.hash != this.state.locationHash) {
      this.setState({
        locationHash: window.location.hash,
      });
    }
    if (this.state.postSlug != this.props.match.params.post_slug) {
      this.setState({
        categorySlug: this.props.match.params.category_slug,
        postSlug: this.props.match.params.post_slug,
        postData: null,
        footerData: null,
        scrollTop: true,
      });
    }
    if (this.state.scrollTop) {
      this.setState({
        scrollTop: false,
      });
    }
  };

  decodeHtml = (html) => {
    const decodedString = he_parser.decode(html);
    return decodedString;
  };

  navigateToScreen = (route, params) => {
    let routeArr = route.split("?");
    const { history } = this.props;
    history.push({
      pathname: routeArr[0],
      search: routeArr[1] ? "?" + routeArr[1] : "",
      state: params,
    });
  };

  render() {
    const {
      categorySlug,
      postSlug,
      postData,
      token,
      footerData,
      scrollTop,
      searchQuery,
      locationHash,
      screenName,
    } = this.state;

    if (process.browser) {
      return (
        <PostSingle
          history={this.props.history}
          searchQuery={searchQuery}
          locationHash={locationHash}
          key={postSlug}
          navigateToScreen={this.navigateToScreen}
          postData={postData}
          categorySlug={categorySlug}
          postSlug={postSlug}
          token={token}
          footerData={footerData}
          scrollTop={scrollTop}
          linkPostData={this.props.location?.postData}
          linkScreenName={screenName}
        />
      );
    } else {
      // SEO Code if it's not first paint, we just want to add html tags
      // so that it can show in page source
      const authors = postData?.author?.map((obj, index) => {
        return (
          <div key={index}>
            <h2>Author</h2>
            <a href={"https://themorningcontext.com/writers" + obj.slug}>
              {obj.name}
            </a>
            <p>{obj.biography}</p>
            <p>{obj.designation}</p>
            <p>{obj.email}</p>
            <p>{obj.location}</p>
          </div>
        );
      });
      const encodedString = postData.story?.locked_content;
      const decodedString = this.decodeHtml(encodedString);

      return (
        <div style={{color: 'white'}}>
          <h1>{postData.story?.title}</h1>
          <p>{postData.story?.strapline}</p>
          <p dangerouslySetInnerHTML={{ __html: decodedString }}></p>
          <div>{authors}</div>
        </div>
      );
    }
  }
}

export default withRouter(PostSingleScreen);
