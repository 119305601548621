import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button, Typo, subscribePlans } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import { helpers } from '../../utils/helpers';

export class CreditPlanBox extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        const { item, index, subscribeToPlan } = this.props;

        return (
            <View key={`sub_${index}`} style={[subscribePlans.planItemWrap, helpers.isDesktop() && subscribePlans.planItemWrapDesktop]}>
                <View style={[subscribePlans.planItem]}>
                    {item.mostPopular &&
                        <View style={[Flex.alignCenter]}>
                            <Text style={[subscribePlans.mostViwed]}>Most Popular</Text>
                        </View>
                    }
                    <Text style={[subscribePlans.planType, helpers.isDesktop() && subscribePlans.planTypeDesktop]}>{item.type}</Text>
                    <View style={{ borderTopWidth: 1, maxWidth: 75, width: '100%', borderTopColor: '#000000', opacity: 0.85, marginLeft: 'auto', marginRight: 'auto', marginTop: 11, marginBottom: 7, }} />
                    <View style={{ borderTopWidth: 1, maxWidth: 40, width: '100%', borderTopColor: '#000000', opacity: 0.85, marginLeft: 'auto', marginRight: 'auto', marginBottom: 22 }} />

                    <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop]}>₹{item.offerPrice} for {item.credits} Credits</Text>
                    <Text style={[subscribePlans.planPrice]}><Text style={[subscribePlans.symbol]}>₹</Text> {item.price}</Text>
                    <View style={[Flex.alignCenter]}>
                        <Text style={postSingle.creditBox}>{item.credits} Credits</Text>
                    </View>
                    <Text style={[subscribePlans.planDesc, helpers.isDesktop() && subscribePlans.planDescDesktop]}>{item.desc}</Text>
                    <View>
                        <ButtonGradient
                            title={item.btnText}
                            onClick={() => subscribeToPlan()}
                            rootStyle={{
                                btnWrapper: button.primaryGradient,
                                btnText: button.primaryGradientText,
                            }}
                            loader={false}
                            disable={false}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

export default CreditPlanBox;