import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { Flex, FontFamily, Helpers, Margin } from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import PageBreak from "../../components/page-break";
import moment from "moment";

const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");

class PostBookmarkedContainer extends Component {
  render() {
    const { bookmarkedDate, applyStoryActions, goToBookmarkList } = this.props;
    let btnClickBookmark,
      btnClickAllBookmarkList = false;

    if (helpers.isWeb()) {
      btnClickBookmark = {
        onPress: () => applyStoryActions("bookmark", false),
      };
      btnClickAllBookmarkList = {
        onPress: () => goToBookmarkList(),
      };
    } else {
      btnClickBookmark = {
        onPress: () => applyStoryActions("bookmark", false),
      };
      btnClickAllBookmarkList = {
        onPress: () => goToBookmarkList(),
      };
    }
    return (
      <>
        <View style={{ marginBottom: 40 }}>
          <Text
            style={{
              fontSize: 20,
              fontFamily: FontFamily.merriweather,
              paddingBottom: 10,
            }}
          >
            You had bookmarked this article
            {bookmarkedDate
              ? " on " + moment(bookmarkedDate).format("DD MMMM, YYYY")
              : ""}
            .
          </Text>
          <View
            style={[
              helpers.isDesktop() && Flex.row,
              helpers.isDesktop() && Flex.alignCenter,
            ]}
          >
            <TouchableOpacity {...btnClickBookmark}>
              <View
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  {
                    width: 110,
                    height: 38,
                    backgroundColor: "#F3F3F4",
                    borderColor: "#F3F3F4",
                    borderWidth: 1,
                    borderRadius: 1,
                  },
                ]}
              >
                <Text
                  style={{
                    fontSize: 14,
                    lineHeight: 32,
                    fontFamily: FontFamily.merriweather,
                    letterSpacing: 0.5,
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  Remove
                </Text>
                <CustomImage
                  source={bookmarkIconSelected}
                  require={true}
                  style={{
                    width: 21,
                    height: 21,
                    resizeMode: "contain",
                    paddingLeft: 7,
                    marginLeft: -5,
                    marginRight: -5,
                  }}
                  webStyle={{
                    width: 21,
                    height: 21,
                    objectFit: "contain",
                    paddingLeft: 7,
                    marginLeft: -5,
                    marginRight: -5,
                  }}
                />
              </View>
            </TouchableOpacity>
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                !helpers.isDesktop() && Margin.mt_1,
              ]}
            >
              <View
                style={{
                  width: 6,
                  height: 6,
                  backgroundColor: "black",
                  borderRadius: 5,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              ></View>
              <TouchableOpacity {...btnClickAllBookmarkList}>
                <Text
                  style={{
                    textDecorationLine: "underline",
                    fontSize: 18,
                    fontFamily: FontFamily.medium,
                  }}
                >
                  View my bookmarked stories
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </>
    );
  }
}

export default PostBookmarkedContainer;
