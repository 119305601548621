import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  newsletter,
  FontFamily,
  Colors,
  Typo,
  featuredArticle,
  article,
  button,
} from "../../styles/appStyles";

import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import ButtonGradient from "../button-gradient";
import ButtonGradientView from "../button-gradient/index-view.js";
import moment from "moment";
import apiHelpers from "../../utils/api-helpers";
import Swiper from "react-native-web-swiper";
import ScrollContainerView from "../scroll-container-view";
const appLogo = getImagePath("icons/tmc-logo.png");
const newsletterImg = getImagePath("img/things-change.png");
const arrowRight = getImagePath("icons/arrow-right-orange.png");

export class PastNewsletterListing extends Component {
  constructor(props) {
    super(props);
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");

    this.notPremiumTagsDict = {
      Techlawlogy: true,
      "Notes from Natasha": true,
      Antiloquy: true,
      "The Crucial Years": true,
    };

    this.state = {
      featuredWriter: false,
    };

    this.addSlideContent = this.addSlideContent.bind(this);
  }

  componentDidMount = () => {
    let { token } = this.props;
  };

  navigateTo = (screen) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(screen);
    } else {
      this.props.navigation.navigate("/pricing");
    }
  };

  /**
   * Slider card board : Dynamic contents will add it here
   * @param {Object} item : Slide wise data
   * @param {Number} index : data index
   * @returns
   */
  addSlideContent = (item, index) => {
    let endpoint = `/newsletter/${item.name
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
    let body = {};

    // apiHelpers
    //   .apiRequest(endpoint, body, "GET", true, this.props.token)
    //   .then((res) => {
    //     if (res.success) {
    //       this.setState({ featuredWriter: res.data.featured_writer }, () => {
    //         console.log(this.state.featuredWriter);
    //       });
    //       console.log("here", res, "ashish");
    //     } else {
    //       console.log("here");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("Error=>> down", JSON.stringify(error));
    //   });

    let { darkMode, pastNewletters } = this.props;
    let viewBtnProps, authorBtnClick;
    let webView = helpers.isWeb();
    if (webView) {
      viewBtnProps = {
        to: "/newsletters/" + item.slug,
      };

      // authorBtnClick = {
      //   to: "/writers/" + this.state.featuredWriter.slug,
      // };
    } else {
      viewBtnProps = {
        onPress: () => this.props.getNewsletterClick(item.slug),
      };
    }
    return (
      <View
        key={item.id}
        style={[
          { marginLeft: 20, marginRight: 20 },
          {
            backgroundColor: this.props.token ? "#FAFAFA" : "#FFFFFF",
            borderColor: this.props.token ? "#FAFAFA" : "#FFFFFF", // if you need
            borderWidth: 2,
            shadowOpacity: 0.7,
            shadowColor: "#000",
            flex: 1,
          },
        ]}
      >
        <TouchableOpacityLink {...viewBtnProps}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                margin: helpers.isDesktop ? 35 : 0,
                justifyContent: "center",
                marginBottom: 0,
              }}
            >
              <CustomImage
                source={item.logo}
                webStyle={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                  marginHorizontal: helpers.isDesktop() ? 0 : 0,
                }}
                style={{ width: 180, height: 180, resizeMode: "contain" }}
                require={false}
                className={""}
                altText={item.name}
                resizeMode={"contain"}
              />
              {darkMode && (
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(18, 18, 18, 0.05)",
                  }}
                ></View>
              )}
            </View>
            {/* {this.isUserSubscriptionActive == "0" ||
            this.isUserSubscriptionActive == null ? (
              <>
                {this.notPremiumTagsDict[item.name] === true ? null : (
                  <View
                    style={{
                      position: "absolute",
                      top: helpers.isDesktop() ? 10 : 50,
                      right: 0,
                    }}
                  >
                    <Image
                      style={{ width: 85, height: 25 }}
                      source={require("../../../../assets/img/premium-tag.png")}
                    />
                  </View>
                )}
              </>
            ) : null} */}

            <View style={{ marginTop: 40 }}>
              <View style={{ width: "99%" }}>
                <Text
                  // numberOfLines={2}
                  style={[
                    { fontSize: 28 },
                    { fontFamily: FontFamily.abrilFatface, flexWrap: "wrap" },
                  ]}
                >
                  {item.name}
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: "#907CFF",
                  width: "50%",
                  height: 1,
                  marginTop: 16,
                  marginBottom: 16,
                }}
              ></View>
              <View style={{ flexDirection: "row" }}>
                <Image
                  source={require("../../../../assets/icons/calendarIcon.png")}
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 16,
                    alignSelf: "center",
                  }}
                />
                <View>
                  <Text
                    style={[
                      {
                        fontFamily: FontFamily.regular,
                        fontSize: helpers.isDesktop() ? 16 : 14,
                        lineHeight: helpers.isDesktop() ? 27 : 20,
                        color: "#000000",
                        marginBottom: helpers.isDesktop() ? 0 : 0,
                        letterSpacing: 0.3,
                      },
                      darkMode && { color: "black" },
                    ]}
                  >
                    {item.available === "Completed"
                      ? item.available
                      : `Published ${item.available}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              marginLeft: 15,
              marginRight: 35,
              justifyContent: "space-between",
            }}
          >
            <Text
              numberOfLines={0}
              style={[
                {
                  marginLeft: 16,
                  fontFamily: FontFamily.regular,
                  fontSize: helpers.isDesktop() ? 18 : 14,
                  color: "#292929",
                  marginBottom: helpers.isDesktop() ? 16 : 0,
                  letterSpacing: 0.3,
                  marginTop: 0,
                },
                { flexWrap: "wrap" },
                darkMode && { color: "#292929" },
              ]}
            >
              {item.description}
            </Text>
          </View>
        </TouchableOpacityLink>
      </View>
    );
  };

  render() {
    let { darkMode, pastNewletters } = this.props;
    let webView = helpers.isWeb();
    let btnClickNewsletter;

    let slide_num = parseInt(
      pastNewletters.length / 2 + (pastNewletters.length % 2)
    ); // Find the number of slides
    let slide_num_arr = [...Array(slide_num).keys()];

    return (
      <>
        {/* Web browser */}
        {helpers.isDesktop() && (
          <View
            // key={item.id}
            style={[
              {
                backgroundColor: this.props.token ? "#FAFAFA" : "#FFFFFF",
                borderColor: this.props.token ? "#FAFAFA" : "#FFFFFF", // if you need
                borderWidth: 2,
                shadowOpacity: 0.7,
                shadowColor: "#000",
                flex: 1,
                width: "100%",
                height: "100%",
              },
            ]}
          >
            <View
              style={{
                // flexDirection: "row",
                marginRight: 60,
                marginBottom: 50,
                width: "100%",
                height: "88%",
              }}
            >
              <Swiper
                loop
                timeout={3}
                controlsProps={{
                  dotsPos: "bottom",
                  nextTitle: "→",
                  nextTitleStyle: {
                    marginTop: 300,
                    marginRight: 20,
                    fontSize: 25,
                    color: "#FC876D",
                  },
                  prevTitleStyle: {
                    marginTop: 300,
                    marginLeft: 20,
                    fontSize: 25,
                    color: "#FC876D",
                  },
                  nextPos: "top-right",
                  prevPos: "top-left",
                  prevTitle: "←",
                  dotActiveStyle: { backgroundColor: "#907CFF" },
                  dotsWrapperStyle: { marginBottom: "-2px", marginTop: 30 },
                  dotsTouchable: true,
                }}
                autoplayTimeout={-2.5}
                overRangeButtonsOpacity={0.3}
                controlsEnabled={true}
                minDistanceForAction={0.15}
                springConfig={{ speed: 100 }}
              >
                {slide_num_arr.map((d, i) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        marginLeft: 60,
                        marginRight: 60,
                        marginBottom: 50,
                      }}
                      key={`pastNewsSlider_${i}`}
                    >
                      {pastNewletters &&
                        pastNewletters
                          .slice(d * 2, d * 2 + 2)
                          .map((item, index) =>
                            this.addSlideContent(item, index)
                          )}
                    </View>
                  );
                })}
              </Swiper>
            </View>
          </View>
        )}

        {/* Mobile browser */}
        {!helpers.isDesktop() && (
          <ScrollContainerView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            style={{
              backgroundColor: this.props.token ? "#FAFAFA" : "#FFFFFF",
            }}
          >
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                !process.browser && { overflow: "hidden" },
                !webView && { width: "100%" },
              ]}
            >
              {pastNewletters &&
                pastNewletters.map((item, index) => {
                  if (helpers.isWeb()) {
                    btnClickNewsletter = {
                      to: "/newsletters/" + item.slug,
                    };
                  } else {
                    btnClickNewsletter = {
                      onPress: () => this.props.getNewsletterClick(item.slug),
                    };
                  }
                  return (
                    <View
                      style={[
                        !helpers.isDesktop() && {
                          marginTop: 40,
                          marginRight: 80,
                        },
                      ]}
                    >
                      <TouchableOpacityLink {...btnClickNewsletter}>
                        <View style={{ flexDirection: "column" }}>
                          <View
                            style={{ flexDirection: "row", marginRight: 40 }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <CustomImage
                                source={item.logo}
                                webStyle={{
                                  width: 110,
                                  height: 77,
                                  objectFit: "contain",
                                }}
                                style={{ width: 110, height: 110 }}
                                require={false}
                                className={""}
                                altText={item.name}
                                resizeMode={"contain"}
                              />
                              {darkMode && (
                                <View
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(18, 18, 18, 0.05)",
                                  }}
                                ></View>
                              )}
                            </View>

                            {/* !this.state.isfree[index]&& */}
                            {/* {this.isUserSubscriptionActive == "0" ||
                            this.isUserSubscriptionActive == null ? (
                              <>
                                {this.notPremiumTagsDict[item.name] ===
                                true ? null : (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: helpers.isDesktop() ? 10 : 0,
                                      right: 0,
                                    }}
                                  >
                                    <Image
                                      style={{ width: 85, height: 25 }}
                                      source={require("../../../../assets/img/premium-tag.png")}
                                    />
                                  </View>
                                )}
                              </>
                            ) : null} */}

                            <View style={{ marginTop: 20, marginLeft: 20 }}>
                              <Text
                                style={[
                                  { fontSize: 18 },
                                  { fontFamily: FontFamily.abrilFatface },
                                ]}
                              >
                                {item.name}
                              </Text>
                              <View
                                style={{
                                  backgroundColor: "#907CFF",
                                  width: "50%",
                                  height: 1,
                                  marginTop: 16,
                                  marginBottom: 16,
                                }}
                              ></View>
                              <View style={{ flexDirection: "row" }}>
                                <Image
                                  source={require("../../../../assets/icons/calendarIcon.png")}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 16,
                                    alignSelf: "center",
                                  }}
                                />
                                <Text
                                  style={[
                                    {
                                      fontFamily: FontFamily.regular,
                                      fontSize: helpers.isDesktop() ? 22 : 16,
                                      lineHeight: helpers.isDesktop() ? 27 : 20,
                                      color: "#000000",
                                      marginBottom: helpers.isDesktop() ? 0 : 0,
                                      letterSpacing: 0.3,
                                    },
                                    darkMode && { color: "black" },
                                  ]}
                                >
                                  {item.available === "Completed"
                                    ? item.available
                                    : `Published ${item.available}`}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              marginTop: 20,
                              width: 300,
                            }}
                          >
                            <Text
                              style={[
                                {
                                  marginLeft: 14,
                                  fontFamily: FontFamily.regular,
                                  fontSize: helpers.isDesktop() ? 25 : 14,
                                  color: "#292929",
                                  flex: 1,
                                  flexWrap: "wrap",
                                  marginBottom: helpers.isDesktop() ? 16 : 0,
                                  letterSpacing: 0.3,
                                  marginTop: 0,
                                },
                                { flexWrap: "wrap" },
                                darkMode && { color: "#292929" },
                              ]}
                            >
                              {item.description}
                            </Text>
                          </View>
                          <Text
                            style={{
                              marginLeft: 15,
                              fontSize: 30,
                              color: "#FC876D",
                              marginTop: 10,
                            }}
                          >
                            →
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                    </View>
                  );
                })}
            </View>
          </ScrollContainerView>

          // <TouchableOpacityLink {...viewBtnProps}>
          //   {" "}
          //   <View
          //     style={[
          //       helpers.isDesktop() && Flex.column,
          //       helpers.isDesktop() && Margin.mb_9,
          //       !helpers.isDesktop() && { paddingHorizontal: "5%" },
          //       !helpers.isWeb() && newsletter.appWrapper,
          //       darkMode && {
          //         backgroundColor: Colors.darkBlackColor,
          //         shadowColor: "rgba(0, 0, 0, 0.80)",
          //       },
          //     ]}
          //   >
          //     <View
          //       style={[
          //         { flex: 1 },
          //         helpers.isDesktop() && {
          //           paddingLeft: 15,
          //           alignSelf: "baseline",
          //         },
          //         !helpers.isDesktop() && { marginTop: 10 },
          //       ]}
          //     >
          //       <View style={[!helpers.isDesktop() && Padding.pb_2]}>
          //       </View>
          //       <View
          //         style={[
          //           !helpers.isDesktop() && Flex.row,
          //           helpers.isDesktop() && Flex.alignCenter,
          //           helpers.isDesktop() && Margin.mb_2,
          //           !helpers.isDesktop() && Margin.mb_3,
          //           { paddingHorizontal: 20, alignItems: "center" },
          //           ,
          //           !helpers.isWeb() && newsletter.appWrapper,
          //           darkMode && {
          //             backgroundColor: Colors.darkBlackColor,
          //             shadowColor: "rgba(0, 0, 0, 0.80)",
          //           },
          //         ]}
          //       >
          //         <View
          //           style={[
          //             !helpers.isDesktop() && {
          //               width: "50%",
          //               alignItems: "center",
          //               display: "unset",
          //             },
          //             !helpers.isWeb() && { alignItems: "center" },
          //           ]}
          //         >
          //           <CustomImage
          //             source={newsLetter.logo}
          //             require={false}
          //             style={{ width: "100%", height: "100%" }}
          //             webStyle={{ width: "100px", height: "100px" }}
          //             altText={newsLetter.name}
          //           />
          //           {darkMode && (
          //             <View
          //               style={{
          //                 position: "absolute",
          //                 top: 0,
          //                 left: 0,
          //                 right: 0,
          //                 bottom: 0,
          //                 backgroundColor: "rgba(18, 18, 18, 0.05)",
          //               }}
          //             ></View>
          //           )}
          //           {(this.props.newsLetter.name
          //             .replace(/\s+/g, "-")
          //             .toLowerCase() == "friction" ||
          //             this.props.newsLetter.name
          //               .replace(/\s+/g, "-")
          //               .toLowerCase() == "one-less-thing-to-worry-about" ||
          //             this.props.newsLetter.name
          //               .replace(/\s+/g, "-")
          //               .toLowerCase() == "oversize" ||
          //             this.props.newsLetter.name
          //               .replace(/\s+/g, "-")
          //               .toLowerCase() == "things-change") && (
          //             <View
          //               style={{
          //                 position: "absolute",
          //                 top: helpers.isDesktop() ? 10 : 10,
          //                 right: 30,
          //                 width: helpers.isDesktop() ? "41%" : "50%",
          //                 backgroundColor: "#0077C8",
          //                 padding: 3,
          //               }}
          //             >
          //               <Text
          //                 style={{
          //                   fontFamily: FontFamily.abrilFatface,
          //                   color: "#ffffff",
          //                   textAlign: "center",
          //                   letterSpacing: 1,
          //                   fontSize: "12px",
          //                   textTransform: "uppercase",
          //                 }}
          //               >
          //                 Premium
          //               </Text>
          //             </View>
          //           )}
          //         </View>

          //         <View>
          //           <View
          //             style={[
          //               postSingle.authorDetailsnewsWrap,
          //               Flex.column,
          //               helpers.isDesktop() && Flex.justfiyCenter,
          //               helpers.isDesktop() && Flex.justifyStart,
          //               Flex.FlexWrap,
          //               {
          //                 alignSelf: "baseline",
          //                 marginLeft: helpers.isDesktop() ? 0 : 0,
          //               },
          //             ]}
          //           >
          //             <Text
          //               style={[
          //                 featuredArticle.newslettertitle,
          //                 helpers.isDesktop() && featuredArticle.titleDesktop,
          //                 { textAlign: "left", lineHeight: 35 },
          //                 darkMode && { color: "rgba(255,255,255,0.87)" },
          //                 { fontFamily: FontFamily.abrilFatface },
          //                 {
          //                   fontSize: helpers.isDesktop()
          //                     ? 25
          //                     : newsLetter.name.length > 10
          //                     ? 12
          //                     : 20,
          //                   lineHeight: helpers.isDesktop() ? 30 : 22,
          //                   color: "#000",
          //                 },
          //                 darkMode && { color: "rgba(255,255,255,0.87)" },
          //               ]}
          //             >
          //               {newsLetter.name}
          //             </Text>

          //             {
          //               <TouchableOpacityLink style={{ alignSelf: "baseline" }}>
          //                 {this.state.featuredWriter && (
          //                   <View
          //                     style={[
          //                       postSingle.authorDetailsWrap,
          //                       helpers.isDesktop() && Flex.alignCenter,
          //                       Flex.justfiyCenter,
          //                       helpers.isDesktop() && Flex.justifyStart,
          //                       helpers.isDesktop() && { marginBottom: "10px" },
          //                     ]}
          //                   >
          //                     <View
          //                       style={[
          //                         postSingle.authorPicWrap,
          //                         { width: 20, height: 20 },
          //                       ]}
          //                     >
          //                       <CustomImage
          //                         source={
          //                           this.state.featuredWriter.profile_image
          //                         }
          //                         require={false}
          //                         webStyle={{
          //                           width: 20,
          //                           height: 20,
          //                           borderRadius: 100,
          //                         }}
          //                         resizeMode={"contain"}
          //                         style={{
          //                           width: 20,
          //                           height: 20,
          //                           borderRadius: 100,
          //                         }}
          //                         altText={"name"}
          //                       />
          //                     </View>
          //                     <View style={[Padding.pl_1]}>
          //                       <Text
          //                         style={[
          //                           postSingle.authorName,
          //                           helpers.isDesktop() &&
          //                             postSingle.authorNameDesktop,
          //                           !helpers.isDesktop() && { fontSize: 16 },
          //                           helpers.isDesktop() && {
          //                             fontWeight: "400",
          //                           },
          //                           darkMode && {
          //                             color: "rgba(255,255,255,0.87)",
          //                           },
          //                         ]}
          //                       >
          //                         {this.state.featuredWriter.name}
          //                       </Text>
          //                     </View>
          //                   </View>
          //                 )}
          //                 {!this.state.featuredWriter && (
          //                   <View
          //                     style={[
          //                       postSingle.authorDetailsWrap,
          //                       helpers.isDesktop() && Flex.alignCenter,
          //                       Flex.justfiyCenter,
          //                       helpers.isDesktop() && Flex.justifyStart,
          //                       !helpers.isDesktop() && {
          //                         marginBottom: "10px",
          //                       },
          //                     ]}
          //                   >
          //                     <View
          //                       style={[
          //                         postSingle.authorPicWrap,
          //                         { width: 20, height: 20 },
          //                       ]}
          //                     >
          //                       <CustomImage
          //                         source={appLogo}
          //                         require={false}
          //                         webStyle={{
          //                           width: 20,
          //                           height: 20,
          //                           borderRadius: 100,
          //                         }}
          //                         resizeMode={"contain"}
          //                         style={{
          //                           width: 20,
          //                           height: 20,
          //                           borderRadius: 100,
          //                         }}
          //                         altText={"name"}
          //                       />
          //                     </View>
          //                     <View style={[Padding.pl_1]}>
          //                       <Text
          //                         style={[
          //                           postSingle.authorName,
          //                           helpers.isDesktop() &&
          //                             postSingle.authorNameDesktop,
          //                           !helpers.isDesktop() && { fontSize: 16 },
          //                           helpers.isDesktop() && {
          //                             fontWeight: "400",
          //                           },
          //                           darkMode && {
          //                             color: "rgba(255,255,255,0.87)",
          //                           },
          //                         ]}
          //                       >
          //                         {"Team TMC"}
          //                       </Text>
          //                     </View>
          //                   </View>
          //                 )}
          //               </TouchableOpacityLink>
          //             }
          //             {
          //               <>
          //                 <TouchableOpacityLink>
          //                   <View
          //                     style={[
          //                       postSingle.authorDetailsWrap,
          //                       Flex.alignCenter,
          //                       helpers.isDesktop() && Flex.justifyStart,
          //                     ]}
          //                   >
          //                     <View
          //                       style={[
          //                         { width: 20, height: 20, marginTop: 5 },
          //                       ]}
          //                     >
          //                       <CustomImage
          //                         source={
          //                           "https://user-images.githubusercontent.com/62637513/130725363-0568a05b-784f-48b1-ae89-e4f10c0c143c.png"
          //                         }
          //                         require={false}
          //                         webStyle={{
          //                           width: 20,
          //                           height: 20,
          //                         }}
          //                         resizeMode={"contain"}
          //                         style={{
          //                           width: 15,
          //                           height: 15,
          //                           borderRadius: 100,
          //                         }}
          //                         altText={"name"}
          //                       />
          //                     </View>
          //                     <View style={[Padding.pl_1]}>
          //                       <Text
          //                         style={[
          //                           postSingle.authorName,
          //                           helpers.isDesktop() &&
          //                             postSingle.authorNameDesktop,
          //                           !helpers.isDesktop() && {
          //                             fontSize: 16,
          //                             fontWeight: 500,
          //                           },
          //                           helpers.isDesktop() && {
          //                             fontWeight: "400",
          //                           },
          //                           darkMode && {
          //                             color: "rgba(255,255,255,0.87)",
          //                           },
          //                         ]}
          //                       >{`${newsLetter.available}`}</Text>
          //                     </View>
          //                   </View>
          //                 </TouchableOpacityLink>
          //               </>
          //             }
          //           </View>
          //         </View>
          //       </View>
          //     </View>

          //     <Text
          //       style={[
          //         newsletter.title,
          //         !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 },
          //         helpers.isDesktop() && { fontSize: 18, lineHeight: 30 },
          //         darkMode && { color: Colors.lightWhite },
          //       ]}
          //     >
          //       {newsLetter.description}
          //     </Text>
          //     <View
          //       style={[
          //         helpers.isDesktop() && Flex.row,
          //         helpers.isDesktop() && Flex.alignCenter,
          //         helpers.isDesktop() && Margin.mb_2,
          //         !helpers.isDesktop() && Margin.mb_3,
          //         ,
          //         !helpers.isWeb() && newsletter.appWrapper,
          //         darkMode && {
          //           backgroundColor: Colors.darkBlackColor,
          //           shadowColor: "rgba(0, 0, 0, 0.80)",
          //         },
          //       ]}
          //     >
          //       <View style={[{ width: "60%" }]}>
          //         {" "}
          //         <View style={[Margin.mt_15]}>
          //           {this.props.token ? (
          //             <TouchableOpacityLink {...viewBtnProps}>
          //               <ButtonGradientView
          //                 title={"View"}
          //                 // onClick={() =>  this.props.navigation.navigate('/pricing')}
          //                 rootStyle={{
          //                   btnWrapper: button.primaryGradientView,
          //                   btnText: button.primaryGradientTextView,
          //                 }}
          //                 // loader={btnLoader}
          //                 // disable={btnDisable}
          //               />
          //             </TouchableOpacityLink>
          //           ) : null}
          //         </View>
          //       </View>
          //       {helpers.isDesktop() && <View style={{ width: "50%" }}></View>}
          //       <View style={[{ width: 20, height: 20, marginTop: 5 }]}>
          //         <CustomImage
          //           source={
          //             "https://user-images.githubusercontent.com/62637513/132447060-3503cabb-4683-41e1-b90f-f5eadf853039.png"
          //           }
          //           require={false}
          //           webStyle={{
          //             width: 20,
          //             height: 10,
          //           }}
          //           resizeMode={"contain"}
          //           style={{ width: 15, height: 10, borderRadius: 100 }}
          //           altText={"name"}
          //         />
          //       </View>
          //     </View>
          //   </View>{" "}
          // </TouchableOpacityLink>
        )}
      </>
    );
  }
}

export default PastNewsletterListing;
