import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  Switch,
  Animated,
  Dimensions,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  teamSubscription,
  FontFamily,
  FontWeight,
  Colors,
  darkModeSwitch,
} from "../../styles/appStyles";
import Cookies from "universal-cookie";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import { saveDarkMode, darkModeToggle } from "../../../redux/App/app.actions";
import { Mixpanel } from "../../utils/mixpanel";
import AuthenticationWrapper from "../authentication-wrapper";

const cookies = new Cookies();

const darkmodeImg = getImagePath("img/darkmode.png");
const cross = getImagePath("icons/close-icon-white.png");
const crossDark = getImagePath("icons/close-icon.png");
const moon = getImagePath("icons/moon.png");
const moonDark = getImagePath("icons/moon-dark.png");

export class DarkModeSwitch extends Component {
  notificationTimeout;
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    this.state = {
      token: token == undefined || token == "" ? false : token,
      notificationValue: new Animated.Value(0),
    };
  }

  componentDidMount = () => {};

  clearNotificationTimeout = () => {
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }
  };

  showNotification = () => {
    Animated.timing(this.state.notificationValue, {
      toValue: 100,
      duration: 400,
      useNativeDriver: true,
    }).start();
  };

  hideNotification = () => {
    Animated.timing(this.state.notificationValue, {
      toValue: 0,
      duration: 400,
      useNativeDriver: true,
    }).start();
  };

  setDarkMode = (darkMode, saveProfile, switchClick) => {
    try {
      if (darkMode == "on")
        window.webengage.track("Click Dark Mode", {
          Count: 1,
        });
      else
        window.webengage.track("Click Light Mode", {
          Count: 1,
        });
    } catch (error) {}
    this.props.saveDarkMode(darkMode);
    this.props.darkModeToggle(false);
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
      cookies.set("dark_mode", darkMode, { path: "/", expires: cookieDate });
    }
    this.showNotification();
    this.notificationTimeout = setTimeout(() => {
      this.hideNotification();
    }, 5400);
    const { token } = this.state;
    if (saveProfile && token) {
      let endpoint = `/edit-profile`;
      let body = helpers.isWeb()
        ? {
            web_dark_mode: darkMode,
          }
        : {
            app_dark_mode: darkMode,
          };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.success) {
            try {
              if (switchClick) {
                Mixpanel.track("clicked_dark_mode_switch", {
                  page: this.props.singlePost ? "story" : "home",
                });
              } else {
                Mixpanel.track("clicked_dark_mode_cross", {
                  page: this.props.singlePost ? "story" : "home",
                });
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }
          } else {
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
        });
    }
  };

  render() {
    let { darkMode, singlePost } = this.props;
    const { token, notificationValue } = this.state;
    let darkModeValue = helpers.isWeb()
      ? cookies.get("dark_mode")
      : this.props?.app?.DarkMode;
    return (
      <>
        {this.props?.app?.DarkModeToggle && token && (
          <View
            style={[
              Padding.ph_2,
              {
                backgroundColor: !helpers.isDesktop()
                  ? "#f3f5ff"
                  : "transparent",
              },
              { paddingTop: singlePost ? 0 : 50 },
              singlePost && {
                maxWidth: "680px",
                marginLeft: "auto",
                marginRight: "auto",
              },
            ]}
          >
            <View
              style={[
                darkModeSwitch.blockWrapper,
                Flex.row,
                Flex.justifyBetween,
                Flex.alignCenter,
              ]}
            >
              <View
                style={[
                  Flex.row,
                  helpers.isDesktop() && Flex.alignCenter,
                  !helpers.isDesktop() && Flex.alignStart,
                  { flex: 0.8 },
                ]}
              >
                <CustomImage
                  source={darkmodeImg}
                  require={true}
                  style={{ width: "auto", height: 54 }}
                  webStyle={{
                    width: "auto",
                    height: helpers.isDesktop() ? 75 : 54,
                  }}
                  altText={"Dark Mode"}
                />
                <View
                  style={[
                    helpers.isDesktop() && Padding.pl_4,
                    !helpers.isDesktop() && Padding.ph_2,
                    { width: helpers.isWeb() ? "fit-content" : "100%" },
                  ]}
                >
                  <Text
                    style={[
                      darkModeSwitch.blockText,
                      {
                        fontSize: helpers.isDesktop() ? 20 : 16,
                        lineHeight: helpers.isDesktop() ? 32 : 26,
                      },
                    ]}
                  >
                    Want to switch to Dark Mode?
                  </Text>
                  <Text
                    style={[
                      darkModeSwitch.blockSubText,
                      {
                        fontSize: helpers.isDesktop() ? 16 : 12,
                        lineHeight: helpers.isDesktop() ? 26 : 19,
                      },
                    ]}
                  >
                    Dark mode can reduce eye strain in low-light conditions and
                    also improve battery life.
                  </Text>
                  {!helpers.isDesktop() && (
                    <View style={[Margin.mt_3, Flex.row, Flex.alignCenter]}>
                      <TouchableOpacity
                        onPress={() => this.setDarkMode(darkModeValue, true)}
                        style={{ marginRight: 20 }}
                      >
                        <Text
                          style={[
                            darkModeSwitch.close,
                            { fontSize: 16, lineHeight: 26 },
                          ]}
                        >
                          Close
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() =>
                          this.setDarkMode(
                            darkModeValue == "on" ? "off" : "on",
                            true,
                            true
                          )
                        }
                      >
                        <Text
                          style={[
                            darkModeSwitch.enable,
                            { fontSize: 16, lineHeight: 26 },
                          ]}
                        >
                          Enable
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
              {helpers.isDesktop() && (
                <View style={{ paddingRight: helpers.isDesktop() ? 70 : 0 }}>
                  <Switch
                    trackColor={{
                      false: Colors.switchTrackInActive,
                      true: Colors.switchTrackActive,
                    }}
                    thumbColor={Colors.switchThumbActive}
                    onValueChange={() =>
                      this.setDarkMode(
                        darkModeValue == "on" ? "off" : "on",
                        true,
                        true
                      )
                    }
                    value={darkModeValue == "on" ? true : false}
                  />
                </View>
              )}
              {helpers.isDesktop() && (
                <TouchableOpacity
                  onPress={() => this.setDarkMode(darkModeValue, true)}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: helpers.isDesktop() ? 15 : 20,
                  }}
                >
                  <CustomImage
                    source={cross}
                    require={true}
                    style={{ width: 12, height: 12 }}
                    webStyle={{ width: 12, height: 12 }}
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
        <Animated.View
          style={[
            Padding.ph_2,
            helpers.isWeb() && { position: "fixed" },
            {
              bottom: notificationValue.interpolate({
                inputRange: [0, 100],
                outputRange: [-60, 60],
              }),
              opacity: notificationValue.interpolate({
                inputRange: [0, 1, 99, 100],
                outputRange: [0, 0, 1, 1],
              }),
            },
          ]}
        >
          <View
            style={[
              Flex.row,
              Flex.alignCenter,
              {
                background: darkMode ? "#E2E2E2" : "#292929",
                width: 330,
                padding: 12,
                borderRadius: 2,
              },
            ]}
          >
            <CustomImage
              source={darkMode ? moonDark : moon}
              require={true}
              style={{ width: 30, height: 30 }}
              webStyle={{ width: 30, height: 30 }}
              altText={"Dark Mode"}
            />
            <View style={{ flex: 1 }}>
              {darkMode && (
                <Text
                  style={[
                    darkModeSwitch.textBlock,
                    {
                      fontSize: 12,
                      lineHeight: 19,
                      paddingLeft: 15,
                      fontWeight: "600",
                    },
                    darkMode && { color: "#292929" },
                  ]}
                >
                  Dark Mode Set
                </Text>
              )}
              <Text
                style={[
                  darkModeSwitch.textBlock,
                  { fontSize: 12, lineHeight: 19, paddingLeft: 15 },
                  darkMode && { color: "#292929" },
                ]}
              >
                You can always update your display preferences in Settings menu
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                this.clearNotificationTimeout();
                this.hideNotification();
              }}
            >
              <CustomImage
                source={darkMode ? crossDark : cross}
                require={true}
                style={{ width: 10, height: 10 }}
                webStyle={{ width: 10, height: 10 }}
              />
            </TouchableOpacity>
          </View>
        </Animated.View>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ saveDarkMode, darkModeToggle }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(DarkModeSwitch));
