import { helpers } from "./helpers";
import { store } from "../../redux/store-native";
import TokenRefresh from "./authTokenRefresh.js";

/**
 *  Use a TokenRefresh instance to optimise concurrent token refresh requests
 */
const tokenRefresher = new TokenRefresh();

const apiHelpers = {
  /**
   * Vaildate an auth token is expired and
   * return a live token using a refresh token
   */
  validatedNativeAuthToken: async () => {
    const existingToken = store.getState().user?.userData?.data?.token;
    console.log('debug:existingToken',existingToken)
    if (!existingToken) {
      return false;
    }
    const presentDate = new Date();
    if (existingToken.expiresAt <= presentDate) {
      return false;
    }
    try {
      const liveToken = await tokenRefresher.getRefreshedToken(existingToken);
      console.log('debug:liveToken',liveToken)
      return liveToken;
    } catch (err) {
      console.log("AuthToken refresh failed: ", err);
    }
    console.log('debug:existingToken',existingToken)
    return existingToken;
  },
  apiRequest: async (
    endpoint,
    body,
    method = "GET",
    form_url = true,
    authToken = false,
    signal = null
  ) => {
    let url = form_url ? `${helpers.apiEndpoint}/api/v1${endpoint}` : endpoint;
    let options = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-auth-version": "2.0",
      },
      signal: signal,
    };

    if (authToken) {
      if (helpers.isWeb()) {
        const compoundToken = await authToken();
        options.headers["Authorization"] =
          "Bearer " + compoundToken.idToken.__raw;
      } else {
        const validToken = await apiHelpers.validatedNativeAuthToken();
        if (!validToken) {
          throw new Error("Token validation failed");
        }
        authToken = validToken;
        options.headers["Authorization"] = "Bearer " + authToken.accessToken;
        options.headers["IdToken"] = authToken.idToken;
      }
    }
    if (method == "POST") {
      options.body = JSON.stringify(body);
    }

    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then((response) => {
          if (response.status < 300) {
            response
              .json()
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          } else {
            response
              .json()
              .then((res) => {
                console.log("Error message from API: ", res);
                reject(res);
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          if (signal && signal.aborted) {
            reject({ aborted: true });
          }
          reject(error);
        });
    });
  },
  // linkedInShare: async (body) => {
  //     let url = "https://api.linkedin.com/v2/ugcPosts";
  //     let options = {
  //         method: "POST",
  //         headers: {
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',
  //             'api-auth-version': '1.1'
  //         },
  //         body: JSON.stringify(body)
  //     }
  //     return new Promise((resolve, reject) => {
  //         fetch(url, options).then((response) => {
  //             console.log('shareresponse:', response);
  //             // if (response.status == 200) {
  //             //     response.json()
  //             //         .then((res) => {
  //             //             resolve(res)
  //             //         })
  //             //         .catch((error) => {
  //             //             console.error(error);
  //             //             reject(error);
  //             //         });
  //             // }
  //             // else {
  //             //     reject(response)
  //             // }
  //         }).
  //             catch((error) => {
  //                 console.log(error);
  //                 reject(error);
  //             })
  //     })

  // }
};

export default apiHelpers;
