import { Image, View } from "react-native";
import React from 'react'
import { helpers } from "../../utils/helpers";

export default function PremiumTag(){
    return(
        <View style={{ position: 'absolute', top: helpers.isDesktop()? 10:10,  right: 0}}>
            <Image style={{width:85,height:25}} source={require("../../../../assets/img/premium-tag.png")} />
        </View> 
    )
}