import React, { Component } from "react";
import {
    Text,
    View,
    ScrollView,
    TextInput,
    ActivityIndicator,
    Switch,
    TouchableOpacity,
} from "react-native";
import {
    Flex,
    Padding,
    Margin,
    Helpers,
    Typo,
    input,
    button,
    Colors,
    orderData,
    FontFamily,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import CustomImage from "../custom-image";
import GoogleSocialButton from "../google-social-button";
import FacebookSocialButton from "../facebook-social-button";
import getImagePath from "../../utils/image-helper";
import Button from "../button";
import GoogleLogout from "../google-logout";
import FacebookLogoutButton from "../facebook-logout";
import Cookies from "universal-cookie";
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from "../button-gradient";
import ContentLoader from "../content-loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomSwitch from "../custom-switch";
import AuthenticationWrapper from "../authentication-wrapper";

const Clock = getImagePath("icons/clock.png");

const cookies = new Cookies();

const NEWSLETTER_INFO = {
    "antiloquy": {
        "author": "Kapil Komireddi",
        "subject": "On Politics"
    },
    "bookkeeping": {
        "author": "Lavanya Mohan",
        "subject": "On Personal Finance"
    },

    // "friction": {
    //     "author": "Karan Manral",
    //     "subject": "On Personal Finance 2"
    // },
    "from-fork-to-farm": {
        "author": "Karan Manral",
        "subject": "On Agriculture"
    },
    "game-point": {
        "author": "Prem Panicker",
        "subject": "On Sports"
    },
    "notes-from-natasha": {
        "author": "Natasha Badhwar",
        "subject": "On Culture"
    },
    // "one-less-thing-to-worry-about": {
    //     "author": "Lavanya Mohan",
    //     "subject": "On Personal Finance 7"
    // },
    "oversize": {
        "author": "Abhishek Baxi",
        "subject": "On Big Tech"
    },
    "peoples-economics": {
        "author": "R. Ramakumar",
        "subject": "On Economics"
    },
    "street-smart": {
        "author": "Team TMC",
        "subject": "On Public Markets"
    },
    "the-crucial-years": {
        "author": "Nihar Gokhale",
        "subject": "On Environment"
    },
    "the-world-revealed": {
        "author": "Anirban Mahapatra",
        "subject": "On Science and Tech"
    },
    "things-change": {
        "author": "Team TMC",
        "subject": "On Start-Ups and Internet Economy"
    },
    // "thirty-six": {
    //     "author": "Lavanya Mohan",
    //     "subject": "On Personal Finance 16"
    // },
    "creative-destruction": {
        "author": "Rohit Chandra",
        "subject": "On Public Policy"
    },
}
export class NewsLetterConfig extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView
            ? isAuthenticated
                ? fetchToken
                : false
            : this.props.user?.userData?.data?.token;
        this.state = {
            token: token == undefined || token == "" ? false : token,
            enableBtnLoader: false,
            newsLetters: [],
            pageLoader: true,
            currentDownloadList: [],
        };
    }

    componentDidMount = () => {
        Promise.all([this.getNewLetterData(), this.getUserInfo()]).then(
            (response) => {
                console.log(response[0], response[1]);
                if (response[0] && response[1]) {
                    let subscription = response[1].subscription;
                    let teamSubscription = response[1].team_subscription;
                    let newsletters_sub = response[1].newsletters
                        ? response[1].newsletters
                        : {};
                    let newsletters = response[0];
                    if (
                        (!subscription && !teamSubscription) ||
                        (subscription &&
                            Object.keys(subscription).length == 0 &&
                            teamSubscription &&
                            Object.keys(teamSubscription).length == 0)
                    ) {
                        this.setState({ noSubscriptionView: true, pageLoader: false });
                    } else {
                        for (let item of newsletters) {
                            item.subscribed = true;
                            item.subscribed_old = true;
                            if (newsletters_sub[item.slug] === false) {
                                item.subscribed = false;
                                item.subscribed_old = false;
                            }
                            try{
                                if(!item.author || item.author == ''){
                                    item.author = NEWSLETTER_INFO[item.slug] ?  NEWSLETTER_INFO[item.slug]["author"]: undefined

                                }
                                if(!item.subject || item.subject == ''){
                                    item.subject = NEWSLETTER_INFO[item.slug] ?  NEWSLETTER_INFO[item.slug]["subject"]: undefined

                                }
                            }
                            catch(config_err){
                                console.error('accounts:NewsLetterConfig:Error: ', config_err)
                            }
                        }
                        this.setState({ newsLetters: newsletters, pageLoader: false });
                    }
                } else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({ showErrorModal: true, pageLoader: false });
                    }
                }
            }
        );
    };

    getNewLetterData = () => {
        return new Promise((resolve, reject) => {
            const { token } = this.state;
            let endpoint = `/static-content`;
            let body = {
                content: ["newsletters"],
            };
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token)
                .then((res) => {
                    if (res.success && res.code == "content_found") {
                        resolve(res.data.newsletters);
                        // this.setState({ newsLetters: res.data.newsletters, pageLoader: false });
                    } else {
                        resolve(false);
                        // this.setState({ showErrorModal: true, pageLoader: false });
                    }
                })
                .catch((error) => {
                    resolve(false);
                    // this.setState({ showErrorModal: true, pageLoader: false });
                    // console.log('Error=>> down', JSON.stringify(error));
                });
        });
    };

    authTokenExpired = () => {
        if (helpers.isWeb()) {
            // let cookieDate = new Date(Date.now() - 1);
            // cookies.set('token', '', { path: '/', expires: cookieDate });
        } else {
            this.props.removeUserToken();
        }
        this.setState(
            {
                token: false,
                userLoggedin: false,
            },
            () => this.getSubscriptionPlans()
        );
    };

    getUserInfo = () => {
        return new Promise((resolve, reject) => {
            const { token } = this.state;
            let endpoint = `/profile`;
            const { isAdmin, referenceId } = this.props;
            let body = { request_group: ["subscription", "newsletters"] };
            if (isAdmin) {
                body = {
                    request_group: ["subscription", "newsletters"],
                    is_admin: true,
                    reference_id: referenceId,
                };
            }
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token)
                .then((res) => {
                    if (res.code == "access_denied") {
                        // this.authTokenExpired();
                        resolve(false);
                    }
                    if (res.success) {
                        resolve(res.data);
                        // this.setState({ userSubscription: (JSON.stringify(res.data.subscription) == '{}') ? false : res.data.subscription });
                    } else {
                        resolve(false);
                        // this.setState({ showErrorModal: true, pageLoader: false });
                    }
                })
                .catch((error) => {
                    console.log("Error=>>", error);
                    resolve(false);
                    // this.setState({ showErrorModal: true, pageLoader: false });
                });
        });
    };

    navigateToSubscription = () => {
        this.props.navigateScreen("accounts/subscriptions");
    };

    renderPageContent = () => {
        let {
            newsLetters,
            enableBtnLoader,
            currentDownloadList,
            noSubscriptionView,
        } = this.state;
        const { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View>
                <View style={[Padding.ph_3, Margin.mt_2]}>
                    {noSubscriptionView ? (
                        <View style={[Flex.alignCenter, Flex.justfiyCenter]}>
                            <Text
                                style={[
                                    Padding.pt_2,
                                    {
                                        fontSize: 14,
                                        lineHeight: 22,
                                        color: "#666",
                                        fontFamily: FontFamily.regular,
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                ]}
                            >
                                Newsletters are only available to subscribed users. Subscribe to
                                any of our plans to get these newsletters straight to your inbox
                            </Text>
                            <ButtonGradient
                                title={"VIEW SUBSCRIPTION PLANS"}
                                rounded={false}
                                fullWidth={true}
                                disable={isAdmin ? true : false}
                                rootStyle={{
                                    btnWrapper: [
                                        button.primaryGradient,
                                        { width: "100%", marginTop: 50 },
                                    ],
                                    btnText: [
                                        button.primaryGradientText,
                                        { fontSize: 16, fontWeight: "400" },
                                    ],
                                }}
                                onClick={() => this.navigateToSubscription()}
                                darkMode={darkMode}
                            />
                        </View>
                    ) : (
                        <>
                            <Text
                                style={[
                                    Margin.mb_2,
                                    {
                                        fontSize: 14,
                                        lineHeight: 22,
                                        fontFamily: FontFamily.regular,
                                        color: "rgba(0, 0, 0, 0.7)",
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                ]}
                            >
                                Manage your Newsletter subscriptions
                            </Text>
                            <View>
                                {newsLetters &&
                                    newsLetters.length != 0 &&
                                    newsLetters.map((item, index) => {
                                        return (
                                            item.available !== "Completed" && (
                                                <View
                                                    style={[
                                                        Flex.row,
                                                        Flex.justifyBetween,
                                                        Flex.alignCenter,
                                                        Margin.mb_2,
                                                        index != 0 && {
                                                            borderTopWidth: 1,
                                                            borderColor: "rgba(0, 0, 0, 0.3)",
                                                            paddingTop: 20,
                                                        },
                                                        darkMode && { borderColor: Colors.lineColor },
                                                    ]}
                                                >
                                                    <View>
                                                        <View
                                                            style={[
                                                                Flex.row,
                                                                Flex.justifyStart,
                                                                Flex.alignCenter,
                                                            ]}
                                                        >
                                                            <View>
                                                                <CustomImage
                                                                    source={item.logo}
                                                                    webStyle={{
                                                                        width: 80,
                                                                        height: 80,
                                                                        maxWidth: "100%",
                                                                        objectFit: "contain",
                                                                        marginRight: 10,
                                                                    }}
                                                                    style={{ width: 80, height: 80 }}
                                                                    require={false}
                                                                    className={""}
                                                                    resizeMode={"contain"}
                                                                    altText={item.name}
                                                                />
                                                            </View>
                                                            <View>
                                                                <Text
                                                                    style={{
                                                                        fontSize: 17,
                                                                        lineHeight: 27,
                                                                        fontWeight: "bold",
                                                                        fontFamily: "PlayfairDisplay-Bold",
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </Text>

                                                                {item.author && (<Text
                                                                    style={{
                                                                        color: "#bea107",
                                                                        fontSize: 14,
                                                                        lineHeight: 17,
                                                                        fontFamily: FontFamily.regular,
                                                                    }}
                                                                >
                                                                    {item.author}
                                                                </Text>)}

                                                                {item.subject && (<Text
                                                                    style={{
                                                                        color: "#000",
                                                                        fontSize: 14,
                                                                        lineHeight: 17,
                                                                        fontFamily: FontFamily.regular,
                                                                    }}
                                                                >
                                                                    {item.subject}
                                                                </Text>)}
                                                            </View>
                                                        </View>
                                                        <View
                                                            style={[
                                                                Flex.row,
                                                                Flex.justifyStart,
                                                                Flex.alignCenter,
                                                                {
                                                                    marginTop: 5,
                                                                    paddingLeft: 5,
                                                                },
                                                            ]}
                                                        >
                                                            <CustomImage
                                                                source={Clock}
                                                                webStyle={{
                                                                    width: 15,
                                                                    height: 15,
                                                                    maxWidth: "100%",
                                                                    objectFit: "scale-down",
                                                                    marginRight: 5,
                                                                }}
                                                                style={{ width: 15, height: 15 }}
                                                                require={false}
                                                                className={""}
                                                                resizeMode={"contain"}
                                                                altText={item.name}
                                                            />
                                                            <Text
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontFamily: FontFamily.regular,
                                                                }}
                                                            >
                                                                {item.available}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <CustomSwitch
                                                        item={item}
                                                        index={index}
                                                        handleClick={() =>
                                                            this.updateSubscription(item, index)
                                                        }
                                                        disable={isAdmin ? true : false}
                                                    />
                                                </View>
                                            )
                                        );
                                    })}
                            </View>
                        </>
                    )}
                </View>
            </View>
        );
    };

    updateSubscription = (item, index) => {
        let { token, newsLetters } = this.state;
        let endpoint = `/save-newsletter-type`;
        let body = {
            type: item.slug,
            value: !item.subscribed,
        };

        newsLetters[index].subscribed = !item.subscribed;
        this.setState({ newsLetters: newsLetters, pageLoader: false });

        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    let { newsLetters } = this.state;
                    newsLetters[index].subscribed_old = !item.subscribed;
                    this.setState({ newsLetters: newsLetters, pageLoader: false });
                } else {
                    let { newsLetters } = this.state;
                    newsLetters[index].subscribed = item.subscribed_old;
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false,
                        newsLetters,
                    });
                }
            })
            .catch((error) => {
                let { newsLetters } = this.state;
                newsLetters[index].subscribed = item.subscribed_old;
                this.setState({ showErrorModal: true, pageLoader: false, newsLetters });
                console.log("Error=>> down", JSON.stringify(error));
            });
    };

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return <ContentLoader darkMode={darkMode} />;
    };

    renderWebPage = () => {
        return <>{this.renderPageContent()}</>;
    };

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView>
            </>
        );
    };

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {pageLoader
                    ? this.showPageLoader()
                    : webView
                        ? this.renderWebPage()
                        : this.renderAppPage()}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticationWrapper(NewsLetterConfig));
