import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform, TextInput } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, pageContent, FontFamily, articleLists, FontWeight, input, Colors, Messages, formStyle } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import ArticleItemHorizontal from '../article-item-horizontal';
import ScrollContainerView from '../scroll-container-view';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ArticleItem from '../article-item';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import ButtonGradient from '../button-gradient';
import Button from '../button';


const blueDots = getImagePath('icons/blue-dots.png');
const checkIcon = getImagePath('icons/check.png');
const windowWidth = Dimensions.get('window').width;

export class SignUpBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnDisable: false,
            email: '',
            referralMsg: {
                enable: false,
                type: '', // error / success
                msg: ''
            },
        }
    }

    // signUp = () => {
    //     const { email } = this.state;
    //     this.setState({
    //         btnLoader: true,
    //         referralMsg: {
    //             enable: false,
    //             type: '',
    //             msg: ''
    //         }
    //     });

    //     if (helpers.validateEmail(email)) {
    //         this.setState({
    //             btnLoader: false
    //         });
    //         if (helpers.isWeb()) {
    //             this.props.navigateWeb(`/sign-up?redirect=/&email=${email}`)
    //         } else {
    //             this.props.navigateApp('/sign-up', { redirect: '/', verifyRedirect: '/', email: email });
    //         }
    //     }
    //     else {
    //         this.setState({
    //             btnLoader: false,
    //             referralMsg: {
    //                 enable: true,
    //                 type: 'error',
    //                 msg: 'Please enter a valid email'
    //             }
    //         });
    //     }
    // }

    validateInput = (value) => {
        if (value) {
            this.setState({ btnDisable: false, email: value });
        } else {
            this.setState({ email: value, btnDisable: true })
        }
    }

    render() {
        let { email, btnDisable, referralMsg } = this.state;
        let { darkMode, isFree } = this.props;
        let webView = helpers.isWeb();
        return (
            <View style={[Flex.row, Margin.mt_5, helpers.isDesktop() && Margin.mb_7]}>
                <View style={[helpers.isDesktop() && Padding.pt_5, Padding.pb_5, Flex.alignStart, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { paddingHorizontal: 20 }]}>
                    <View style={[{ paddingHorizontal: helpers.isDesktop() ? 100 : 15, borderWidth: 0, borderColor: '#907CFF', backgroundColor: 'white', width: '100%' }, !helpers.isDesktop() && { marginHorizontal: 'auto' }, darkMode && { backgroundColor: Colors.darkBlackColorBg }, { paddingBottom: helpers.isDesktop() ? 50 : 20, paddingTop: helpers.isDesktop() ? 50 : 20 }]}>
                        <View style={[article.headerWrapper]}>
                            <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_2]}>
                                <View style={[Padding.ph_2, helpers.isDesktop() ? Margin.mb_2 : { marginBottom: 5 }, Helpers.titleBgWrap, { backgroundColor: darkMode ? Colors.darkBlackColorBg : 'rgba(250 250 250,1)' }]}>
                                    <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 15 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>{isFree ? 'To Read this free story, Sign up to TMC' : 'To Read this story, Sign up to TMC'}</Text>
                                </View>
                                <PageBreak size={'small'} darkMode={darkMode} />
                            </View>
                        </View>
                        <Text style={[Helpers.textCenter, { marginBottom: helpers.isDesktop() ? 40 : 10 }, pageContent.subTitle, { maxWidth: helpers.isDesktop() ? 620 : '100%', fontFamily: FontFamily.regular, color: '#000', marginLeft: 'auto', marginRight: 'auto' }, !helpers.isDesktop() && { marginHorizontal: 0 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>{isFree ? 'Also, on sign up to The Morning Context, you will get 10 free starter credits, which you can use to unlock any story from our starter collection.' : 'On creating an account with The Morning Context, you will get 10 free starter credits, which can then be used to unlock this story and all the other stories from our starter collection.'}</Text>

                        <View style={[{ maxWidth: 712, marginLeft: 'auto', marginRight: 'auto', flexDirection: helpers.isDesktop() ? 'row' : 'column', width: '100%' }, !webView && { maxWidth: '100%', width: windowWidth }]}>
                            {/* <TextInput
                                style={[formStyle.input, helpers.isDesktop() && Margin.mb_2, { borderColor: '#707AF6', flex: 1 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}
                                placeholder={'Enter your email Address'}
                                placeholderTextColor={Colors.placeholderTextColor}
                                onChangeText={this.validateInput}
                                value={email}
                            /> */}
                            <ButtonGradient
                                title={'SIGN UP'}
                                onClick={() => this.subscribeToPlan()}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradient, !helpers.isDesktop() && { marginTop: 15 }],
                                    btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular }, !helpers.isDesktop() && { fontSize: 16 }],
                                }}
                                smallBtn={true}
                                rounded={false}
                                onClick={this.props.goToSignUp}
                                disable={btnDisable}
                                darkMode={darkMode}
                            />
                        </View>
                        <View style={[Padding.ph_2, Flex.alignCenter, Flex.row, { justifyContent: 'center', paddingBottom: 0, paddingTop: 20 }]}>
                            <Text style={[{ fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, fontFamily: FontFamily.regular, fontWeight: FontWeight.regular, marginBottom: 0, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Already a member?</Text>
                            <Button
                                title={'Sign in'}
                                rootStyle={{
                                    btnWrapper: [{ marginLeft: 5 }],
                                    btnText: [{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 20 : 18, textDecorationLine: 'underline', color: darkMode ? Colors.lineColor : Colors.brandColor1 }]
                                }}
                                onClick={() => this.props.goToLogin()}
                            />
                        </View>
                        {referralMsg.enable &&
                            <View style={[{ marginTop: 5 }]}>
                                <Text style={[referralMsg.type == 'success' ? Messages.successText : Messages.errorText]}>{referralMsg.msg}</Text>
                            </View>
                        }
                        <View style={{ position: 'absolute', bottom: -1, left: '46%', zIndex: 2, backgroundColor: '#ffffff', paddingLeft: 15, paddingRight: 15 }}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 34 : 20, fontFamily: FontFamily.abrilFatface, lineHeight: helpers.isDesktop() ? 40 : 24, color: darkMode ? Colors.lightWhite : '#000000', position: 'relative', top: helpers.isDesktop() ? 15 : 10 }}>or</Text>
                        </View>
                    </View>
                    <View style={{ height: 1, backgroundColor: '#000000', width: '100%', maxWidth: helpers.isDesktop() ? 870 : 160, marginLeft: 'auto', marginRight: 'auto', position: 'relative', zIndex: -1 }}>

                    </View>
                </View>
            </View>
        );
    }
}

export default SignUpBlock;