import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList, TextInput } from 'react-native';
import { Colors, Flex, Padding, Margin, Helpers, FontFamily, appStyles, articleLists, article, articleItem, postActionBar, formStyle } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageCover from '../../components/page-cover';
import InfiniteScrollComponent from '../../components/infinite-scroll';
import ArticleItemHorizontal from '../../components/article-item-horizontal';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import BlogItem from '../../components/blog-item';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const checkBoxSelected = getImagePath('icons/checkedIconSq.png');
const checkBoxUnSelected = getImagePath('icons/unCheckedIconSq.png');
const searchBox = getImagePath('icons/searchbox.png');

const noArticles = getImagePath('img/no-articles.png');
const allStories = getImagePath('img/all-stories.png');

const cookies = new Cookies();
let resetTimeout = 0;

export class Blog extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        let search = '', excludeRead;

        if (webView && process.browser && window.location.search) {
            let searchArr = window.location.search.replace("?", "").split("&");
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("search=");
            });
            excludeRead = searchArr.find((searchParam) => {
                return searchParam.includes("exclude_read=true");
            });
            if (foundSearch) {
                search = foundSearch.replace("search=", "");
            }

        }
        else if (!webView && this.props.route.params) {
            search = (this.props.route.params.redirect) ? this.props.route.params.search : null;
        }
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.state = {
            archiveData: this.props.archiveData ? this.props.archiveData : '',
            archiveDataList: this.props.archiveData ? this.props.archiveData.story : '',
            archiveDataSSR: this.props.archiveData ? true : false,
            pageLoader: this.props.archiveData ? false : true,
            showErrorModal: false,
            searchText: search,
            checkBoxChecked: token ? (excludeRead ? false : true) : true,
            footerData: this.props.footerData ? this.props.footerData : false,
            token: (token == undefined || token == '') ? false : token,
            userLoggedin: (token == undefined || token == '') ? false : true,
            readingList: this.props.archiveData ? (this.props.archiveData.readingList ? this.props.archiveData.readingList : '') : '',
            redirectSlugs: {
                web: '/blog',
                native: { redirect: '/blog', verifyRedirect: '/blog' }
            }
        }
    }

    componentDidMount = () => {
        if (!this.state.archiveDataSSR) {
            this.getArticles(1);
        }
        if (this.state.token && !this.state.readingList) {
            this.getExcludeListForUser();
        }
    }

    componentDidUpdate = () => {

    }

    getArticles = (page, append) => {
        const { readingList, checkBoxChecked, userLoggedin, searchText, archiveDataList, token } = this.state;
        let excludeArr = [];
        if (readingList && readingList.length > 0 && !checkBoxChecked && userLoggedin) {
            readingList.filter((post) => {
                excludeArr.push(post.id);
            })
        }

        let endpoint = `/blogs`;
        let body = {
            limit: 10,
            page: page,
            sort_on: 'date',
            sort_by: 'desc',
            search_string: searchText,
            exclude: excludeArr
        }

        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'blog_found') {
                    this.setState({
                        archiveData: res.data,
                        archiveDataList: append ? archiveDataList.concat(res.data.story) : res.data.story,
                        pageLoader: false
                    });
                } else {
                    this.setState({
                        archiveDataList: append ? archiveDataList : [],
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                if (!error.aborted) {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                }
                console.log('Error=>> down', JSON.stringify(error));
            });
    }


    getMoreArticles = () => {
        this.getArticles(this.state.archiveData.page.current + 1, true)
    }

    viewArticle = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/blog' + '/' + slug);
        } else {
            this.props.navigation.navigate('blog-single', { postSlug: slug });
        }
    }

    viewAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    searchFieldOnChange = (value) => {
        this.setState({
            searchText: value
        }, () => {
            if (resetTimeout != 0) {
                clearTimeout(resetTimeout);
            }
            let th = this;
            resetTimeout = setTimeout(() => {
                this.getArticles(1)
                if (helpers.isWeb()) {
                    let pushProp = JSON.parse(JSON.stringify(window.location));

                    if (value != '')
                        pushProp.search = "?search=" + value + (!this.state.checkBoxChecked && this.state.token ? '&exclude_read=true' : '');
                    else
                        pushProp.search = !this.state.checkBoxChecked && this.state.token ? "?exclude_read=true" : '';

                    this.props.history.replace(pushProp)
                }
            }, 500);
        })


    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderPageCoverRightSection = () => {
        const { archiveData, archiveDataList } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[article.countWrap, { flexDirection: 'row' }]}>
                <Text style={[article.count, helpers.isDesktop() && article.countDesktop, darkMode && { color: Colors.lightWhite }]}>{archiveDataList && archiveDataList.length > 0 ? archiveData.page.total_count : 0}</Text>
                <Text style={[article.countLabel, helpers.isDesktop() && article.countLabelDesktop, { paddingLeft: 5 }, darkMode && { color: Colors.lightWhite }]}>{archiveDataList && archiveDataList.length == 1 ? 'Article' : 'Articles'}</Text>
            </View>
        )
    }

    getExcludeListForUser = () => {
        this.setState({
            showErrorModal: false,
        });
        const { token } = this.state;
        let endpoint = `/user-action-story-list`;
        let body = {
            "type": "read",
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'story_found') {
                    this.setState({
                        readingList: res.data.story,
                    });
                } else {
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let category = darkMode ? getImagePath('img/category-dark.png') : getImagePath('img/category.png');
        return (
            <View style={[article.headerWrapper, Margin.mt_4, !helpers.isWeb() && { marginTop: 100 }]}>
                <PageCover
                    title='On the record'
                    pageCoverOverlay={true}
                    topRightContent={this.renderPageCoverRightSection()}
                    blog={true}
                    darkMode={darkMode}
                />
                {this.renderSearchField()}
                <CustomImage
                    source={category}
                    require={true}
                    style={{ width: 160, height: 100, position: 'absolute', left: '52%', top: -20, zIndex: -1, marginBottom: 30, resizeMode: 'contain' }}
                    webStyle={{ width: 'auto', height: helpers.isDesktop() ? 200 : 110, position: 'absolute', left: helpers.isDesktop() ? '44.7%' : '45%', top: -35, zIndex: -1, marginBottom: 30 }}
                />
            </View>
        )
    }

    renderSearchField = () => {
        let { searchText, checkBoxChecked, userLoggedin } = this.state;
        let btnClickCheckBox;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        if (helpers.isWeb()) {
            btnClickCheckBox = {
                onPress: (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                        checkBoxChecked: !checkBoxChecked
                    }, () => {
                        this.getArticles(1)
                        let pushProp = JSON.parse(JSON.stringify(window.location));
                        let searchArr = window.location.search.replace("?", "").split("&");
                        if (searchArr.length > 0 && searchArr[0] != "" && searchArr[0].startsWith('?search')) {
                            pushProp.search += "&search=" + value;
                        } else {
                            if (!this.state.checkBoxChecked) {
                                pushProp.search = this.state.searchText ? "?search=" + this.state.searchText + "&exclude_read=true" : "?exclude_read=true";
                            }
                            else {
                                pushProp.search = this.state.searchText ? "?search=" + this.state.searchText : "";

                            }
                        }
                        this.props.history.replace(pushProp)
                    })
                }
            }
        } else {

            btnClickCheckBox = {
                onPress: (event) => {
                    event.stopPropagation();
                    this.setState({
                        checkBoxChecked: !checkBoxChecked
                    }, () => this.getArticles(1))
                }
            }
        }

        return (
            <View style={[helpers.isDesktop() && Flex.row, !helpers.isDesktop() && Flex.column, Flex.justfiyCenter, Margin.mb_7, { alignItems: helpers.isDesktop() ? 'baseline' : 'center' }]}>
                <View style={[{ position: 'relative' }, !helpers.isDesktop() && { width: '90%' }]}>
                    <TextInput
                        placeholder={`Search for blogs`}
                        placeholderTextColor={Colors.placeholderTextColor}
                        value={searchText}
                        onChangeText={(search) => { this.searchFieldOnChange(search) }}
                        style={[Padding.pl_2, { color: '#000', borderWidth: 1, borderColor: '#907CFF', height: 46, width: helpers.isDesktop() ? 500 : '100%', paddingRight: 60, fontFamily: FontFamily.regular }, darkMode && { color: Colors.lightWhite }]}
                    />
                    <View style={[formStyle.searchBox]}>
                        <CustomImage
                            source={searchBox}
                            require={true}
                            style={{ width: 50, height: 46, position: 'absolute', right: 0, top: -46 }}
                            webStyle={{ width: 50, height: 46, position: 'absolute', right: 0, top: -46 }}
                        />
                    </View>
                </View>
                {userLoggedin && <View style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Margin.mt_2]}>
                    <TouchableOpacity {...btnClickCheckBox}
                        style={[helpers.isDesktop() && Padding.pl_4, Padding.pr_1]}>
                        <CustomImage
                            source={(checkBoxChecked) ? checkBoxSelected : checkBoxUnSelected}
                            require={true}
                            style={{ width: 16, height: 16 }}
                            webStyle={{ width: 16, height: 16 }}
                        />
                    </TouchableOpacity>
                    <Text style={[{ color: darkMode ? Colors.lightWhite : '#000000', fontFamily: FontFamily.regular }]}>{"Include Read Stories"}</Text>
                </View>}
            </View>
        )
    }

    renderPageContent = () => {
        let { archiveDataList, archiveData, bookmarkChecked, data, searchText } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let items = archiveDataList.map((item, index) => {
            return (
                <View style={[articleItem.col6, Flex.row, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={Flex.fill}>
                        <BlogItem data={item} viewArticle={(slug) => this.viewArticle(slug)} viewAuthor={(slug) => this.viewAuthor(slug)} darkMode={darkMode} />
                    </View>
                </View>
            )
        })
        return (
            <View style={[Padding.ph_2, Margin.mb_3]}>
                {archiveDataList.length > 0 ? <InfiniteScrollComponent
                    pageStart={archiveData.page.current}
                    loadMore={() => this.getMoreArticles()}
                    hasMore={archiveData.page.has_next}
                    items={items}
                /> :
                    <View style={[Flex.alignCenter, Margin.mb_6]}>
                        <CustomImage
                            source={noArticles}
                            require={true}
                            style={{ width: helpers.isDesktop() ? 360 : 200, height: helpers.isDesktop() ? 360 : 200 }}
                            webStyle={{ width: helpers.isDesktop() ? 360 : 200, height: helpers.isDesktop() ? 360 : 200 }}
                            altText={'Match not found'}
                        />
                        <Text style={{ fontSize: helpers.isDesktop() ? 36 : 24, lineHeight: 43, marginTop: 20, marginBottom: 20, textAlign: 'center', fontFamily: FontFamily.medium, color: darkMode ? Colors.lightWhite : 'rgba(0, 0, 0, 0.9)' }}>Whoops!</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 30 : 24, marginBottom: 10, textAlign: 'center', fontFamily: FontFamily.regular, color: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.7)' }}>
                            We couldn’t find a match for "{this.state.searchText}"</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 30 : 24, marginBottom: 10, textAlign: 'center', fontFamily: FontFamily.regular, color: darkMode ? Colors.lineColor : 'rgba(0, 0, 0, 0.7)' }}>
                            Please try another search.</Text>
                    </View>
                }
            </View>
        )
    }

    renderWebPage = () => {
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, Margin.mt_7]}>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </View>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, footerData, redirectSlugs } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, footerData, redirectSlugs } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                <Seo data={seoObj[`blog`]} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/blog" />

                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }</>
                }



                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(Blog));

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Blog'
    }
]