import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, TextInput } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, button, formStyle, unlockBox, signUpPage, Colors, modalStyle, FontFamily } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import SignInForm from '../../components/sign-in-form';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import PageBreak from '../../components/page-break';
import AppHeaderView from '../../components/app-header-view';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import Auth0 from 'react-native-auth0';
import { bindActionCreators } from 'redux';
import { setUserToken, removeUserToken } from '../../../redux/User/user.actions';
import { connect } from 'react-redux';
import Loader from '../../components/loader/index.js';
import { Mixpanel } from '../../utils/mixpanel';
import jwt_decode from "jwt-decode";
import { GoogleAnalytics } from '../../utils/google-analytics';

const welcomeBack = getImagePath('img/welcomeBack.png');
const closeIcon = getImagePath('icons/grey-close-in.png');


export class SignIn extends Component {
    constructor(props) {
        super(props);

        let redirectUrl = null;
        let verifyRedirectUrl = null;
        let allParams = null;
        let webView = helpers.isWeb();
        let redirectParams = null;
        if (webView && process.browser && window.location.search.includes("redirect=")) {
            let searchArr = window.location.search.replace("?", "").split("&");
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("redirect=");
            });
            redirectParams = searchArr.find((searchParam) => {
                return searchParam.includes("redirect-params=");
            });
            if (foundSearch) {
                let search = foundSearch.replace("redirect=", "");
                redirectUrl = (search != '') ? search : null;
                verifyRedirectUrl = redirectUrl;
            }
        } else if (!webView && this.props.route.params) {
            redirectUrl = (this.props.route.params.redirect) ? this.props.route.params.redirect : null;
            verifyRedirectUrl = (this.props.route.params.verifyRedirect) ? this.props.route.params.verifyRedirect : null;
            allParams = this.props.route.params;
        }
        if (redirectParams) {
            redirectUrl = redirectUrl + '?' + redirectParams
        }
        console.log('red: ', redirectUrl)
        const auth0 = new Auth0({
            domain: process.env.AUTH0_DOMAIN,
            clientId: process.env.AUTH0_CLIENT_ID,
            audience: 'https://api.themorningcontext.com/api/v1',
        });

        this.state = {
            auth0: auth0,
            enableBtnLoader: false,
            redirectUrl: redirectUrl,
            verifyRedirectUrl: verifyRedirectUrl,
            allParams: allParams
        }

        this._mobileLogin();
    }


    navigateBack = () => {
        let { redirectUrl } = this.state;
        if (helpers.isWeb()) {
            this.props.history.replace(redirectUrl ? redirectUrl : '/')
        }
        else {
            this.props.navigation.navigate(redirectUrl ? redirectUrl : '/');
        }
    }

    _mobileLogin = () => {
        const authorizeOptions = (Platform.OS == 'ios')?{ephemeralSession: true}:{}
        this.state.auth0.webAuth
            .authorize({
                scope: 'openid profile email offline_access',
                prompt: 'login',
                mobile: {platform: Platform.OS},
            }, authorizeOptions)
            .then(credentials => {
                console.log('creds: ', credentials)
                const userData = {
                    data: {
                        token: {
                            accessToken: credentials.accessToken,
                            idToken: credentials.idToken,
                            refreshToken: credentials.refreshToken,
                            expiresAt: new Date(Date.now() + Number(credentials.expiresIn)*1000),
                        }
                    }
                }
                this.props.setUserToken(userData);
                this._signInHooks(credentials.idToken);
                this.props.navigation.navigate('/');
            })
            .catch(error => {
                console.log('login err: ', error);
                this.props.navigation.navigate('/');

            });
    };

    _signInHooks = (idToken) => {
        const idTokenData = jwt_decode(idToken);
        const userInfo = idTokenData["https://themorningcontext.com"]["userInfo"]
        Mixpanel.identify(userInfo.reference_id);
        Mixpanel.people.set({
            name: userInfo.full_name,
            "$email": userInfo.account_email
        });
        // if (userInfo.is_new_user) {
        //     Mixpanel.track('signup', { method: 'google' }, true);
        // }
        // else {
        //     Mixpanel.track('login', { method: 'google' }, true);
        // }
    }

    render() {
        return (
            <View style={{ flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%' }}>
                <Loader title={"Please wait while we authenticate you."}/>
            </ View>
        )
        // const webView = helpers.isWeb();
        // const desktopView = webView && helpers.isDesktop();

        // let navigateBackProp = {};
        // navigateBackProp = {
        //     onPress: this.navigateBack
        // }

        // let data = {
        //     pageName : 'Sign In'
        // }

        // return (
        //     <View style={{height: '100%'}}>
        //         {helpers.isDesktop() && <AppHeaderView />}
        //         <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/sign-in" />
        //         <View style={[signUpPage.wrapper, helpers.isDesktop() && Flex.justfiyCenter, {backgroundColor: desktopView ? '#E5E5E5' : 'white'}, helpers.isWeb() && { height: '100vh' }, helpers.isDesktop() && {paddingLeft: 25, paddingRight: 25, paddingRight: 25, paddingTop: 90, paddingBottom: 20, flex: 1}]}>
        //             <View style={[Flex.row, helpers.isWeb() && { maxWidth: '1150px', margin: desktopView ? 'auto' : 0, width: '100%' }]}>
        //                 <View style={{width: desktopView ? '50%' : '100%'}}>
        //                     <SignInForm navigateToScreen={this.props.navigateToScreen} navigation={this.props.navigation} goBack={() => this.props.history.goBack()} history={this.props.history} route={this.props.route}/>
        //                 </View>
        //                 {desktopView &&
        //                     <>
        //                         <View style={{backgroundColor: 'white', paddingTop: 10, paddingBottom: 3}}><View style={[Margin.mt_2, Margin.mb_2, {borderLeftWidth: 1, borderColor: '#DEDEDE', height: '94%'}]}/></View>
        //                         <View style={[{ width: '50%', backgroundColor: 'white', paddingHorizontal: helpers.isDesktop() ? 45 : 0}, Flex.justfiyCenter, Flex.alignCenter]}>
        //                             {/* <View style={[Flex.alignCenter, Padding.pt_4, Padding.pb_2]}>
        //                                 <Text style={{fontSize:  26 , lineHeight: 36, fontFamily: FontFamily.abrilFatface}}>Welcome Back</Text>
        //                                 <PageBreak size={'small'}/>
        //                             </View> */}
        //                             <View style={[Flex.row,{alignItems:'end'}]}>
        //                                 <CustomImage
        //                                     source={welcomeBack}
        //                                     require={true}
        //                                     style={{ width: 270, height: 150, resizeMode: 'contain' }}
        //                                     webStyle={{ width: 320, height: 177, objectFit: 'contain' }}
        //                                     altText={'Welcome Back'}
        //                                 />
        //                             </View>                                
        //                             <View style={[Padding.pt_4]}>
        //                                 <View style={[Flex.row,{alignItems:'center'}]}>  
        //                                     <Text style={[signUpPage.infoText, Padding.pl_3, Padding.pr_3,{fontFamily: FontFamily.merriweather, textAlign: 'center'}]}>Context is everything. Only read stories that matter.</Text>
        //                                 </View>
        //                             </View>
        //                         </View>
        //                         <TouchableOpacity {...navigateBackProp}>
        //                             <CustomImage
        //                                 source={closeIcon}
        //                                 require={true}
        //                                 style={{ width: 28, height: 28, position: 'absolute', right: 35, top: 30, zIndex: 1 }}
        //                                 webStyle={{ width: 28, height: 28, position: 'absolute', right: 35, top: 30, zIndex: 1 }}
        //                             />
        //                         </TouchableOpacity>
        //                     </>
        //                 }
        //             </View>
        //         </View>
        //     </View>
        // );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken, setUserToken }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
