import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  pageCover,
  FontFamily,
  popularStories,
  Colors,
  button,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import TouchableOpacityLink from "../touchable-opacity-link";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";
import Cookies from "universal-cookie";
import AuthenticationWrapper from "../authentication-wrapper";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import ButtonGradient from "../button-gradient";

const Startup = getImagePath("img/startup-img.png");
const Fintech = getImagePath("img/fintech-img.png");
const Retail = getImagePath("img/retail-img.png");
const eccomerce = getImagePath("img/eccomerce-img.png");
const media = getImagePath("img/media-img.png");

//mobile
const StartupMobImg = getImagePath("img/m-startup-img.png");
const FintechMobImg = getImagePath("img/m-fintech-img.png");
const RetailMobImg = getImagePath("img/m-retail-img.png");
const eccomerceMobImg = getImagePath("img/m-ecomerce-img.png");
const mediaMobImg = getImagePath("img/m-media-img.png");

const cookies = new Cookies();

export class HomeNewsletterCategories extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    // const {isAuthenticated, fetchToken} = this.props.auth
    // let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
    this.state = {
      showToken: !helpers.isWeb() || process.browser ? true : false,
      newsletterList: [
        { name: "Startups", id: 1, category: "things-change" },
        { name: "Public Markets", id: 2, category: "street-smart" },
        { name: "World Affairs", id: 3, category: "antiloquy" },
        { name: "Love & Family", id: 4, category: "notes-from-natasha" },
        { name: "Technology", id: 5, category: "oversize" },
        { name: "Agriculture", id: 6, category: "from-fork-to-farm" },
        { name: "Economy", id: 7, category: "peoples-economics" },
        { name: "Science", id: 8, category: "the-world-revealed" },
        { name: "Public Policy", id: 9, category: "creative-destruction" },
        { name: "Environment", id: 10, category: "the-crucial-years" },
        { name: "Sports", id: 11, category: "game-point" },
        { name: "Personal Finance", id: 12, category: "bookkeeping" },
        { name: "Conflict", id: 13, category: "thirty-six" },
      ],
    };
  }

  componentDidMount = () => {};

  trackThemeClick = (theme, category) => {
    console.log("theme");
    this.pushThemeClickToDataLayer(theme, category)
    if (this.props.trackThemeClick) {
      this.props.trackThemeClick(theme);
    }
  };

  pushThemeClickToDataLayer = (themeName, newsletter_category) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "CTA_theme_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      newsletter_category: newsletter_category,
      newsletter_section:'NA',
      author_name:'NA',
      theme_name: themeName.toLowerCase(),
      article_section:'explore newsletters on',
      article_category:'NA',
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "explore newsletters on",
      newsletter_section: "NA",
      CTA_text: "view all newsletters",
      CTA_position: "bottom",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  trackCTAClick = () => {
    this.pushInformationToDataLayer()
    try {
      console.log("Click View All Newsletters");
      window.webengage.track("Click View All Newsletters", {
        "Type of User": this.props.loggedOut
          ? "non-logged in"
          : this.props.freeUser
          ? "Free"
          : "paid",
        "Component Name": "HomeNewsletterCategories",
      });
      console.log({
        "Type of User": this.props.loggedOut
          ? "non-logged in"
          : this.props.freeUser
          ? "Free"
          : "paid",
        "Component Name": "HomeNewsletterCategories",
      });
    } catch (error) {}
  };

  methodProps = (i) => {
    let agricultureProp,
      economyProp,
      sportsProp,
      scienceProp,
      startupprops,
      publicMarketProps,
      worldProps,
      loveProps,
      technologyProps,
      policyProps,
      environmentProp,
      financeProps,
      conflictProps;

    if (helpers.isWeb()) {
      agricultureProp = {
        to: "/newsletters/from-fork-to-farm"
      };
      sportsProp = {
        to: "/newsletters/game-point",
      };
      economyProp = {
        to: "/newsletters/peoples-economics",
      };
      environmentProp = {
        to: "/newsletters/the-crucial-years",
      };
      scienceProp = {
        to: "/newsletters/the-world-revealed",
      };
      startupprops = {
        to: "/newsletters/things-change",
      };
      publicMarketProps = {
        to: "/newsletters/street-smart",
      };
      worldProps = {
        to: "/newsletters/antiloquy",
      };
      loveProps = {
        to: "/newsletters/notes-from-natasha",
      };
      technologyProps = {
        to: "/newsletters/oversize",
      };
      policyProps = {
        to: "/newsletters/creative-destruction",
      };
      financeProps = {
        to: "/newsletters/bookkeeping",
      };
      conflictProps = {
        to: "/newsletters/thirty-six",
      };
    } else {
      agricultureProp = {
        onPress: () => getTagClick("agriculture"),
      };
      sportsProp = {
        onPress: () => getTagClick("sports"),
      };
      economyProp = {
        onPress: () => getTagClick("economy"),
      };
      environmentProp = {
        onPress: () => getTagClick("environment"),
      };
      scienceProp = {
        onPress: () => getTagClick("science"),
      };
      startupprops = {
        onPress: () => getTagClick("startups"),
      };
      publicMarketProps = {
        onPress: () => getTagClick("public-markets"),
      };
      worldProps = {
        onPress: () => getTagClick("world-affairs"),
      };
      loveProps = {
        onPress: () => getTagClick("love-family"),
      };
      technologyProps = {
        onPress: () => getTagClick("technology"),
      };
      policyProps = {
        onPress: () => getTagClick("public-policy"),
      };
      financeProps = {
        onPress: () => getTagClick("bookkeeping"),
      };
      conflictProps = {
        onPress: () => getTagClick("thirty-six"),
      };
    }

    if (i == 1) {
      return startupprops;
    } else if (i == 2) {
      return publicMarketProps;
    } else if (i == 3) {
      return worldProps;
    } else if (i == 4) {
      return loveProps;
    } else if (i == 5) {
      return technologyProps;
    } else if (i == 6) {
      return agricultureProp;
    } else if (i == 7) {
      return economyProp;
    } else if (i == 8) {
      return scienceProp;
    } else if (i == 9) {
      return policyProps;
    } else if (i == 10) {
      return environmentProp;
    } else if (i == 11) {
      return sportsProp;
    } else if (i == 12) {
      return financeProps;
    } else if (i == 13) {
      return conflictProps;
    }
  };

  render() {
    const {
      transparentBg,
      data,
      subscription,
      loggedOut,
      getTagClick,
      darkMode,
    } = this.props;
    let btnClickNewsletter, btnClickSignUp;
    let webView = helpers.isWeb();
    if (helpers.isWeb()) {
      btnClickSignUp = {
        href: "/sign-up",
      };
    } else {
      btnClickSignUp = {
        onPress: () => navigateApp("/sign-up"),
      };
    }
    if (helpers.isWeb()) {
      btnClickNewsletter = {
        href: "/newsletters",
      };
    } else {
      btnClickNewsletter = {
        onPress: () => navigateApp("/newsletters"),
      };
    }
    return (
      <View
        style={[
          Margin.mb_3,
          helpers.isDesktop() && { alignSelf: "center" },
          !helpers.isDesktop() && { alignItems: "center" },
          {
            backgroundColor: transparentBg
              ? "transparent"
              : darkMode
              ? Colors.darkBlackColorBg
              : "#FFF",
          },
        ]}
      >
        <View
          style={[
            helpers.isDesktop() && {
              marginLeft: "auto",
              marginRight: "auto",
            },
            {
              paddingTop: "36px",
            },
          ]}
        >
          {!subscription && (
            <View style={[Flex.alignCenter]}>
              <View
                style={[
                  Helpers.titleBgWrap,
                  { backgroundColor: "transparent" },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontFamily: "PlayfairDisplay-Bold", fontWeight: 700 },
                    {
                      fontSize: 30,
                      lineHeight: 26,
                      color: "#292929",
                    },
                    !helpers.isDesktop() && {
                      lineHeight: 28.8,
                      paddingBottom: 6,
                    },
                    {
                      color: darkMode
                        ? "rgba(255,255,255,0.87)"
                        : helpers.isDesktop()
                        ? "000"
                        : "#051462",
                    },
                  ]}
                >
                  {data.title}
                </Text>
              </View>
            </View>
          )}
        </View>
        <View
          style={[
            helpers.isDesktop() && Padding.ph_2,
            Helpers.conatinerWeb,
            { flexDirection: "row", flexWrap: "wrap", alignItems: "center" },
            helpers.isDesktop() && Helpers.conatinerWebFull,
            helpers.isDesktop() && Margin.mt_4,
            helpers.isDesktop() && { justifyContent: "center", maxWidth: 1100 },
            !helpers.isDesktop() && {
              justifyContent: "center",
              paddingHorizontal: 20,
              paddingTop: 10,
            },
          ]}
        >
          {subscription && (
            <View
              style={[
                {
                  borderWidth: 0.75,
                  borderColor: "#7B8FFE",
                  borderRadius: 40,
                  backgroundColor: "#fff",
                },
                helpers.isDesktop() && { margin: helpers.isDesktop() ? 12 : 8 },
              ]}
            >
              <View
                style={[
                  Flex.alignCenter,
                  helpers.isDesktop() && Margin.mb_7,
                  !helpers.isDesktop() && Margin.mb_4,
                  {
                    width: helpers.isWeb() ? "fit-content" : "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                ]}
              >
                <View
                  style={[
                    Padding.ph_3,
                    Helpers.titleBgWrap,
                    { backgroundColor: "transparent" },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      { fontFamily: FontFamily.regular, fontWeight: 400 },
                      {
                        fontSize: helpers.isDesktop() ? 20 : 18,
                        lineHeight: helpers.isDesktop() ? 40 : 35,
                        color: "#7B8FFE",
                      },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                      { paddingHorizontal: 10, paddingVertical: 0 },
                    ]}
                  >
                    {data.title}
                  </Text>
                </View>
              </View>
            </View>
          )}

          {this.state.newsletterList &&
            this.state.newsletterList.map((d, i) => {
              return (
                <View
                  style={[
                    !helpers.isDesktop() && { padding: 8 },
                    helpers.isDesktop() && { margin: 12 },
                  ]}
                  key={`newsletter_home_${i}`}
                >
                  <TouchableOpacityLink
                    {...this.methodProps(d.id)}
                    handleClick={() =>
                      this.trackThemeClick(
                        d.name, d.category
                      )
                    }
                  >
                    <View
                      style={[
                        {
                          borderWidth: 0.75,
                          borderColor: "#7B8FFE",
                          borderRadius: 40,
                          backgroundColor: "#fff",
                        },
                        !helpers.isDesktop() && {
                          paddingBottom: 0,
                          marginLeft: 0,
                          marginRight: 0,
                        },
                        !process.browser && { width: 300 },
                        !helpers.isWeb() && { width: "100%" },
                        darkMode && { backgroundColor: "#1E1E1E" },
                      ]}
                    >
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          Flex.justifyBetween,
                          { alignItems: "baseline" },
                          !webView && { alignItems: "center" },
                        ]}
                      >
                        <Text
                          style={[
                            // popularStories.imageTitle,
                            // helpers.isDesktop() &&
                            //   popularStories.imageTitleDesktop,
                            {
                              color: "#7B8FFE",
                              fontSize: 20,
                              lineHeight: 41,
                              fontFamily: FontFamily.regular,
                              fontWeight: 500,
                            },
                            darkMode && { color: "rgba(255,255,255,0.87)" },
                            {
                              paddingHorizontal: helpers.isDesktop() ? 25 : 16,
                              paddingVertical: 0,
                            },
                            !helpers.isDesktop() && {
                              fontSize: 16,
                              lineHeight: 35,
                              fontWeight: 500,
                            },
                          ]}
                        >
                          {d.name}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacityLink>
                </View>
              );
            })}

          {/* <View
            style={[
              !helpers.isDesktop() && { padding: 10 },
              helpers.isDesktop() && { margin: 10 },
            ]}
          >
            <TouchableOpacityLink
              {...internetProp}
              handleClick={() => this.trackThemeClick("internet")}
            >
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#7B8FFE",
                    borderRadius: 30,
                    backgroundColor: "#fff",
                  },
                  !helpers.isDesktop() && {
                    paddingBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                  !process.browser && { width: 300 },
                  !helpers.isWeb() && { width: "100%" },
                  darkMode && { backgroundColor: "#1E1E1E" },
                ]}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    { alignItems: "baseline" },
                    !webView && { alignItems: "center" },
                  ]}
                >
                  <Text
                    style={[
                      popularStories.imageTitle,
                      helpers.isDesktop() && popularStories.imageTitleDesktop,
                      { color: "#7B8FFE" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                      { paddingHorizontal: 20, paddingVertical: 0 },
                      !helpers.isDesktop() && {
                        fontSize: 14,
                        lineHeight: 25,
                        fontWeight: 400,
                      },
                    ]}
                  >
                    Agriculture
                  </Text>
                </View>
              </View>
            </TouchableOpacityLink>
          </View>

          <View
            style={[
              !helpers.isDesktop() && { padding: 10 },
              helpers.isDesktop() && { margin: 10 },
            ]}
          >
            <TouchableOpacityLink
              {...businessProp}
              handleClick={() => this.trackThemeClick("business")}
            >
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#7B8FFE",
                    borderRadius: 30,
                    backgroundColor: "#fff",
                  },
                  !helpers.isDesktop() && {
                    paddingBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                  !process.browser && { width: 300 },
                  !helpers.isWeb() && { width: "100%" },
                  darkMode && { backgroundColor: "#1E1E1E" },
                ]}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    { alignItems: "baseline" },
                    !webView && { alignItems: "center" },
                  ]}
                >
                  <Text
                    style={[
                      popularStories.imageTitle,
                      helpers.isDesktop() && popularStories.imageTitleDesktop,
                      { color: "#7B8FFE" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                      { paddingHorizontal: 20, paddingVertical: 0 },
                      !helpers.isDesktop() && {
                        fontSize: 14,
                        lineHeight: 25,
                        fontWeight: 400,
                      },
                    ]}
                  >
                    Sports
                  </Text>
                </View>
              </View>
            </TouchableOpacityLink>
          </View>
          <View
            style={[
              !helpers.isDesktop() && { padding: 10 },
              helpers.isDesktop() && { margin: 10 },
            ]}
          >
            <TouchableOpacityLink
              {...chaosProp}
              handleClick={() => this.trackThemeClick("chaos")}
            >
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#7B8FFE",
                    borderRadius: 30,
                    backgroundColor: "#fff",
                  },
                  !helpers.isDesktop() && {
                    paddingBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                  !process.browser && { width: 300 },
                  !helpers.isWeb() && { width: "100%" },
                  darkMode && { backgroundColor: "#1E1E1E" },
                ]}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    { alignItems: "baseline" },
                    !webView && { alignItems: "center" },
                  ]}
                >
                  <Text
                    style={[
                      popularStories.imageTitle,
                      helpers.isDesktop() && popularStories.imageTitleDesktop,
                      { color: "#7B8FFE" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                      { paddingHorizontal: 20, paddingVertical: 0 },
                      !helpers.isDesktop() && {
                        fontSize: 14,
                        lineHeight: 25,
                        fontWeight: 400,
                      },
                    ]}
                  >
                    Economy
                  </Text>
                </View>
              </View>
            </TouchableOpacityLink>
          </View>
          <View
            style={[
              !helpers.isDesktop() && { padding: 10 },
              helpers.isDesktop() && { margin: 10 },
            ]}
          >
            <TouchableOpacityLink
              {...scienceProp}
              handleClick={() => this.trackThemeClick("sports")}
            >
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#7B8FFE",
                    borderRadius: 30,
                    backgroundColor: "#fff",
                  },
                  !helpers.isDesktop() && {
                    paddingBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                  !process.browser && { width: 300 },
                  !helpers.isWeb() && { width: "100%" },
                  darkMode && { backgroundColor: "#1E1E1E" },
                ]}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    { alignItems: "baseline" },
                    !webView && { alignItems: "center" },
                  ]}
                >
                  <Text
                    style={[
                      popularStories.imageTitle,
                      helpers.isDesktop() && popularStories.imageTitleDesktop,
                      { color: "#7B8FFE" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                      { paddingHorizontal: 20, paddingVertical: 0 },
                      !helpers.isDesktop() && {
                        fontSize: 14,
                        lineHeight: 25,
                        fontWeight: 400,
                      },
                    ]}
                  >
                    Science
                  </Text>
                </View>
              </View>
            </TouchableOpacityLink>
          </View>
          <View
            style={[
              !helpers.isDesktop() && { padding: 10 },
              helpers.isDesktop() && { margin: 10 },
            ]}
          >
            <TouchableOpacityLink
              {...environmentProp}
              handleClick={() => this.trackThemeClick("economics")}
            >
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#7B8FFE",
                    borderRadius: 30,
                    backgroundColor: "#fff",
                  },
                  !helpers.isDesktop() && {
                    paddingBottom: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                  !process.browser && { width: 300 },
                  !helpers.isWeb() && { width: "100%" },
                  darkMode && { backgroundColor: "#1E1E1E" },
                ]}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    { alignItems: "baseline" },
                    !webView && { alignItems: "center" },
                  ]}
                >
                  <Text
                    style={[
                      popularStories.imageTitle,
                      helpers.isDesktop() && popularStories.imageTitleDesktop,
                      { color: "#7B8FFE" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                      { paddingHorizontal: 20, paddingVertical: 0 },
                      !helpers.isDesktop() && {
                        fontSize: 14,
                        lineHeight: 25,
                        fontWeight: 400,
                      },
                    ]}
                  >
                    Environment
                  </Text>
                </View>
              </View>
            </TouchableOpacityLink>
          </View> */}
        </View>
        {loggedOut ? (
          <View
            style={
              ([helpers.isDesktop() && Flex.row, Margin.mh_5],
              { alignSelf: "center", marginTop: 28 })
            }
          >
            <TouchableOpacityLinkExternal {...btnClickSignUp} newTab={true}>
              <ButtonGradient
                rounded={true}
                title={"GET STARTED"}
                rootStyle={{
                  btnWrapper: [button.primaryGradient, { height: 50 }],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: 16,
                      paddingHorizontal: 45,
                      fontWeight: "600",
                      fontFamily: FontFamily.regular,
                    },
                  ],
                }}
                paddingOverride={true}
                onClick={() => {}}
                darkMode={darkMode}
                isNewGradiant={true}
              />
            </TouchableOpacityLinkExternal>
          </View>
        ) : (
          <View
            style={
              ([helpers.isDesktop() && Flex.row, Margin.mh_3],
              { alignSelf: "center", marginTop: 20 })
            }
          >
            <TouchableOpacityLinkExternal {...btnClickNewsletter} newTab={true}>
              <ButtonGradient
                title={"VIEW ALL NEWSLETTERS"}
                rootStyle={{
                  btnWrapper: [button.primaryGradient, { height: 55 }],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: 17,
                      paddingHorizontal: 35,
                      fontWeight: "600",
                      fontFamily: FontFamily.regular,
                    },
                  ],
                }}
                paddingOverride={true}
                onClick={this.trackCTAClick}
                darkMode={darkMode}
                isNewGradiant={true}
              />
            </TouchableOpacityLinkExternal>
          </View>
        )}
      </View>
    );
  }
}

export default AuthenticationWrapper(HomeNewsletterCategories);
