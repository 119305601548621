import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, subscribePlans, Typo, button, FontWeight, FontFamily } from '../../styles/appStyles';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import { helpers } from '../../utils/helpers';

export class CreditSignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    gotoSignUp = () => {
        const { onButtonClick } = this.props;
        onButtonClick()
    }

    render() {
        const { credits, offerPrice, price } = this.props;
        return (
            <View>
                <Text style={[Helpers.textCenter, Helpers.textUppercase, Typo.h3_M, helpers.isDesktop() &&  Typo.h3_MDesktop]}>Sign up and get {credits} credits for just</Text>
                <Text style={[subscribePlans.planPrice]}>
                    <Text style={[subscribePlans.symbol]}>₹</Text> 49
                </Text>
                <View style={[Margin.mt_15, Margin.mb_15]}>
                    <PageBreak size={'small'} />
                </View>
                <Text style={[Typo.descTextL, Helpers.textCenter, helpers.isDesktop() && {fontSize: 18, lineHeight: 27, letterSpacing: 0.3}, {fontWeight: FontWeight.regular, fontFamily: FontFamily.regular}]}>
                    Get <Text style={[Typo.textMedium, helpers.isDesktop() && {fontSize: 18}]}> {credits} free credits </Text>
                    for just
                    {offerPrice && <><Text style={[Typo.textMedium, helpers.isDesktop() && {fontSize: 18}]}> {offerPrice}</Text> instead of</>}
                    <Text style={[Typo.textMedium, helpers.isDesktop() && {fontSize: 18}]}> {price}</Text> on your first purchase and start reading our stories.
                </Text>
                <View style={[Margin.mt_15]}>
                    <ButtonGradient
                        title={'SIGN UP'}
                        onClick={() => this.gotoSignUp()}
                        rootStyle={{
                            btnWrapper: button.primaryGradient,
                            btnText: button.primaryGradientText,
                        }}
                    />
                </View>
            </View>
        );
    }
}

export default CreditSignUp;