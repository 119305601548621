import React, { Component } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import Yesterday from "../../native/screens/yesterday";
import YesterdayStoryScreen from "../../native/screens/yesterday-story-screen";

const PrivilegeCreditGift = {
  "id": "privilege_credits",
  "name": "Gift a story",
  "tmc_group": "",
  "billing_enitity_type": "adhoc",
  "invoice_name": "Gift a story",
  "description": {
      "access_desc": [
          "Can gift upto 3 privilege credits a month for free",
          "Can be used to unlock any long form story or newsletter of your choice"
      ],
  },
  "is_popular": false,
  "recommended_plan": false,
  "discount": {},
  "is_story_gift": true
}

export class YesterdayStoryPage extends Component {
  constructor(props) {
    super(props);
    let postData = process.browser ? document.getElementById("postData") : null;
    let footerData = process.browser
      ? document.getElementById("footerData")
      : null;
    this.state = {
      searchQuery: "",
      locationHash: "",
      footerData: footerData
        ? JSON.parse(footerData.getAttribute("data"))
        : JSON.stringify(this.props.footerData) != "{}"
        ? this.props.footerData
        : null,
      postData: postData
        ? JSON.parse(postData.getAttribute("data"))
        : JSON.stringify(this.props.postData) != "{}"
        ? this.props.postData
        : null,
      token: this.props.token,
      postSlug: this.props.match.params.slug,
    };

    if (process.browser) {
      if (postData) {
        postData.parentNode.removeChild(postData);
      }
      if (footerData) {
        footerData.parentNode.removeChild(footerData);
      }

      try {
        ReactGA.pageview(this.props.history.location.pathname);
      } catch (error) {
        console.log("GA tracking error =>", error);
      }
    }
  }

  componentDidMount = () => {
    setTimeout(function () {
      window.history.scrollRestoration = "manual";
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      setTimeout(function () {
        document.body.style.overflow = "initial";
        document.body.style.position = "initial";
      }, 20);
    }, 20);
    if (window.location.search != this.state.searchQuery) {
      this.setState({
        searchQuery: window.location.search,
      });
    }
    if (window.location.hash != this.state.locationHash) {
      this.setState({
        locationHash: window.location.hash,
      });
    }
  };

  componentDidUpdate = () => {
    if (window.location.search != this.state.searchQuery) {
      this.setState({
        searchQuery: window.location.search,
      });
    }
    if (window.location.hash != this.state.locationHash) {
      this.setState({
        locationHash: window.location.hash,
      });
    }
  };

  navigateToScreen = (route, params) => {
    let routeArr = route.split("?");
    const { history } = this.props;
    history.push({
      pathname: routeArr[0],
      search: routeArr[1] ? "?" + routeArr[1] : "",
      state: params,
    });
    console.log("params:", params);
  };

  render() {
    const { searchQuery, locationHash, postData, footerData, token, postSlug } =
      this.state;
    return (
      <YesterdayStoryScreen
        history={this.props.history}
        searchQuery={searchQuery}
        locationHash={locationHash}
        navigateToScreen={this.navigateToScreen}
        postData={postData}
        token={token}
        footerData={footerData}
        postSlug={postSlug}
        openGiftCardModal={() => this.refs.header.openGiftCardModal(PrivilegeCreditGift.id, PrivilegeCreditGift, null, null, postData, postData.story?.credits?.unlock)}
      />
    );
  }
}

export default withRouter(YesterdayStoryPage);
