import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, ActivityIndicator, Picker, Platform } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, articleItem, FontFamily, articleLists, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import Vimeo from 'react-vimeo';


export class VimeoVideo extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
    }


    render() {
        let { faq, videoId, home } = this.props;
        return (
            <View style={[!helpers.isDesktop() && { marginBottom: 30}, { width: home ? (helpers.isDesktop() ? 500 : '100%') : 'auto', height: helpers.isDesktop() ? 250 : 'auto'}, !helpers.isDesktop() && {paddingHorizontal: 20}, faq && {marginBottom: 0}]}>
                <Vimeo
                    ref='video'
                    className = 'vimeo'
                    videoId={videoId}
                    autoplay={true}
                    onPlay={() => console.log('Video is playing')}
                />
            </View>
        );
    }
}

export default VimeoVideo;