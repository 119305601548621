import React from "react";
import { helpers } from "../../utils/helpers";

import StoryModalWeb from "../story-modal-web";
import StoryModalMobile from "../story-modal-mobile";

function StoryModal(props) {
  return helpers.isDesktop() ? (
    <StoryModalWeb {...props} />
  ) : (
    <StoryModalMobile {...props} />
  );
}

export default StoryModal;
