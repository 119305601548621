import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity, Linking } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, sliderStyle, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../utils/mixpanel';
import AuthenticationWrapper from '../authentication-wrapper';
// import { Colors } from 'react-native/Libraries/NewAppScreen';

const chooseCredits = getImagePath('icons/choose-credits.png');

const buyCredits = getImagePath('icons/buy-plan.png');
const inviteMember = getImagePath('icons/invite-members.png');
const assignCredits = getImagePath('icons/assign-credits.png');
const unlockContent = getImagePath('icons/unlock-content.png');

const cookies = new Cookies();

const sliderData = [
    {
        img: buyCredits,
        title: 'Pick a plan',
        content: 'Choose a privilege credit pack suited to the size of your team',
        count: 1,
    },
    {
        img: inviteMember,
        title: 'Invite Members',
        content: 'Add members to your team from the Admin panel',
        count: 2,
    },
    {
        img: assignCredits,
        title: 'Assign Credits',
        content: 'Distribute privilege credits to your team',
        count: 3,
    },
    {
        img: unlockContent,
        title: 'Unlock stories',
        content: 'The team can now use privilege credits to unlock and read stories',
        count: 4,
    },
];


export class CreditsInfo extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;        this.appIntroSlider = React.createRef();
        this.checkScroll = true;
        const windowWidth = Dimensions.get('window').width;
        this.state = {
            activeSlide: 0,
            PlatformWeb: helpers.isDesktop(),
            slideItemWidth: windowWidth - 40,
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount() {
        if (helpers.isWeb()) {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        if (helpers.isWeb()) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize = () => {
        setTimeout(() => {
            const windowWidth = Dimensions.get('window').width
            if (windowWidth < 992) {
                this.setState({
                    PlatformWeb: false,
                    slideItemWidth: windowWidth - 40,
                })
            } else {
                this.setState({
                    PlatformWeb: true,
                    slideItemWidth: windowWidth - 40,
                })
            }
        }, 200)
    }

    getSlideIndex = (offset, width) => {
        let intervals = sliderData.length;

        for (let i = 1; i <= intervals; i++) {
            let index = Math.round((width / intervals) * i);
            if (Math.round(offset) < index) {
                return i - 1;
            }
            if (i == intervals) {
                return i - 1;
            }
        }
    }

    getSlideOffset = (offset, width) => {
        if (this.checkScroll) {
            let slideIndex = this.getSlideIndex(offset, width);
            this.setState({
                activeSlide: slideIndex
            })
            // if(this.props.subscriptionQuote) {
            //     if(helpers.isWeb()) {
            //         Mixpanel.track('swipe_reader_quotes', { position: slideIndex });
            //     }
            // }
        }
    }

    prevSlide = () => {
        // const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = sliderData.length;


        let totalSlideWidth = slideItemWidth - 80

        let totalWidth = Math.round(slideLength * totalSlideWidth);


        let scrollIndex;

        if (activeSlide > 2) {
            scrollIndex = Math.round(slideItemWidth * (activeSlide - 2));
        } else {
            scrollIndex = 0;
        }

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
    }

    nextSlide = () => {
        // const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        let slideLength = sliderData.length;

        let scrollIndex = Math.round(slideItemWidth * (activeSlide));

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })

    }

    slideTo = (index) => {
        // index = index === 0 ? 0 : index+1;
        this.checkScroll = false;
        // const { data } = this.props;
        const { slideItemWidth } = this.state;
        let slideLength = sliderData.length;

        let scrollIndex = Math.round(slideItemWidth * (index));
        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
        this.setState({ activeSlide: index }, () => {
            setTimeout(() => {
                this.checkScroll = true;
            }, 2000)
        })
    }


    whychoosecredits = () => {
        return (
            <View style={[helpers.isDesktop() && [Padding.pl_2, Padding.pr_2]]}>
                <View style={[Margin.mt_3, Margin.mb_2, !helpers.isDesktop() && Margin.mb_4, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000]}>
                    <View style={[Flex.alignCenter, Margin.mb_4]}>
                        {helpers.isDesktop() ?
                            <Text style={[{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: 29, fontFamily: FontFamily.medium, fontWeight: FontWeight.semiBold }]}>WHY CHOOSE CREDITS?</Text>
                            :
                            <Text style={[{ fontSize: 24, lineHeight: 29, fontFamily: FontFamily.medium, fontWeight: FontWeight.semiBold }]}>BENEFITS</Text>
                        }
                    </View>
                    <View style={[Flex.row, Flex.FlexWrap, Flex.justfiyCenter, !helpers.isDesktop() && Padding.pb_15, { paddingHorizontal: 20, margin: 'auto' }]}>
                        <View style={[Flex.row, Flex.alignStart, Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, textAlign: 'left', alignItems: 'flex-start' }]}>
                            <CustomImage
                                source={chooseCredits}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                            />
                            <View style={{ paddingLeft: 20, flex: 1 }}>
                                <Text style={[Padding.pb_1, { fontSize: helpers.isDesktop() ? 20 : 20, lineHeight: helpers.isDesktop() ? 24 : 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }]}>Pay only for what you read</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 18, lineHeight: helpers.isDesktop() ? 27 : 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }}>Tailor your reading experience according to your reading need</Text>
                            </View>
                        </View>
                        <View style={[Flex.row, Flex.alignStart, Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, textAlign: 'left', alignItems: 'flex-start' }]}>
                            <CustomImage
                                source={chooseCredits}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                            />
                            <View style={{ paddingLeft: 20, flex: 1 }}>
                                <Text style={[Padding.pb_1, { fontSize: helpers.isDesktop() ? 20 : 20, lineHeight: helpers.isDesktop() ? 24 : 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }]}>Monthly reading summary</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 18, lineHeight: helpers.isDesktop() ? 27 : 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }}>Get your monthly reading summary to know the effectiveness of your purchase</Text>
                            </View>
                        </View>
                        <View style={[Flex.row, Flex.alignStart, Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, textAlign: 'left', alignItems: 'flex-start' }]}>
                            <CustomImage
                                source={chooseCredits}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                            />
                            <View style={{ paddingLeft: 20, flex: 1 }}>
                                <Text style={[Padding.pb_1, { fontSize: helpers.isDesktop() ? 20 : 20, lineHeight: helpers.isDesktop() ? 24 : 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }]}>Large team size</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 18, lineHeight: helpers.isDesktop() ? 27 : 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }}>Add as many as 50 people to your team irrespective of whichever plan you purchase</Text>
                            </View>
                        </View>
                        <View style={[Flex.row, Flex.alignStart, Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, textAlign: 'left', alignItems: 'flex-start' }]}>
                            <CustomImage
                                source={chooseCredits}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 30 : 30, height: helpers.isDesktop() ? 30 : 30 }}
                            />
                            <View style={{ paddingLeft: 20, flex: 1 }}>
                                <Text style={[Padding.pb_1, { fontSize: helpers.isDesktop() ? 20 : 20, lineHeight: helpers.isDesktop() ? 24 : 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }]}>Zero renewal hassle</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 18, lineHeight: helpers.isDesktop() ? 27 : 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }}>When you run out of credits simply recharge and continue reading</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    }


    howcreditworks = () => {
        const { activeSlide, slideItemWidth } = this.state;
        let intervals = sliderData.length;
        let isWeb = helpers.isWeb();

        let dotClick;
        let { darkMode } = this.props;
        return (
            <View style={[helpers.isDesktop() && [Padding.pl_2, Padding.pr_2]]}>
                <View style={[Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Flex.alignCenter, Margin.mb_4]}>
                        <Text style={[{ fontSize: 24, lineHeight: 29, fontFamily: helpers.isWeb() ? FontFamily.medium : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>How credits works?</Text>
                    </View>
                    {helpers.isDesktop() ? <View style={[helpers.isDesktop() && [Flex.row, Flex.justfiyCenter], !helpers.isDesktop() && Padding.pb_3, { paddingHorizontal: 20, margin: 'auto' }]}>
                        {sliderData && sliderData.map((item, index) => {
                            return (
                                <View style={[Flex.alignCenter, Margin.mb_4, helpers.isDesktop() && { marginHorizontal: 10 }, { paddingHorizontal: 10, paddingVertical: helpers.isDesktop() ? 30 : 20 }, { width: helpers.isDesktop() ? '23%' : '100%', backgroundColor: darkMode ? Colors.darkPurple : 'white' }]}>
                                    <CustomImage
                                        source={item.img}
                                        require={true}
                                        style={{ width: 30, height: 30 }}
                                        webStyle={{ width: 'auto', height: 45 }}
                                        altText={item.title}
                                    />
                                    <Text style={[Padding.pt_2, Padding.pb_1, { fontSize: 18, lineHeight: 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && {color: Colors.lightWhite}]}>{item.title}</Text>
                                    <Text style={{ textAlign: 'center', fontSize: 16, lineHeight: 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.9)', opacity: 0.85 }}>{item.content}</Text>
                                </View>
                            )
                        })}
                    </View>
                        :
                        <View style={[sliderStyle.wrapper, { backgroundColor: 'transparent', marginLeft: 20, marginRight: 20 }, !isWeb && { width: 'auto' }]}>
                            <View style={[sliderStyle.dotWrapper, { bottom: 0 }]}>
                                {sliderData && sliderData.map((item, index) => {
                                    if (isWeb) {
                                        dotClick = {
                                            onPress: (event) => {
                                                this.slideTo(index)
                                            }
                                        }
                                    } else {
                                        dotClick = {
                                            onPress: (event) => {
                                                this.slideTo(index)
                                            }
                                        }
                                    }
                                    return (
                                        <TouchableOpacity {...dotClick} activeOpacity={1}>
                                            <View key={`dot_${index}`} style={{ marginLeft: 12, marginRight: 12 }}>
                                                <Text style={[{ borderWidth: 1, borderColor: '#5869EA', color: '#5869EA', paddingHorizontal: helpers.isWeb() ? 12 : 14, paddingVertical: 8, borderRadius: 30, backgroundColor: darkMode ? Colors.darkPurple : '#fafafa' }, (activeSlide == index) && { backgroundColor: '#5869EA', color: 'white' }]}>{item.count}</Text>
                                                {(index != 3) && <View style={[{ backgroundColor: '#5869EA', width: '100%', height: 1, position: 'absolute', left: 0, right: 0, top: 16, width: 75, zIndex: -1 }, darkMode && {color: Colors.darkBlackColor}]} />}
                                            </View>
                                        </TouchableOpacity>
                                    )
                                })}
                            </View>
                            <ScrollView
                                contentContainerStyle={{ width: `${100 * intervals}%` }}
                                style={{ width: '100%' }}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                pagingEnabled={true}
                                onScroll={data => {
                                    this.getSlideOffset(data.nativeEvent.contentOffset.x, data.nativeEvent.contentSize.width)
                                }}
                                scrollEventThrottle={200}
                                decelerationRate="fast"
                                ref={ref => this.scrollView = ref}
                            >

                                {sliderData && sliderData.map((item, index) => {
                                    return (
                                        <View key={`slide_${index}`} style={[sliderStyle.sliderOuterItem, { width: slideItemWidth, paddingTop: 25 }, { marginRight: 20 }, !isWeb && { marginRight: 0, paddingHorizontal: 5 }]}>
                                            <View style={[{ maxWidth: '100%', borderWidth: 1.4, borderColor: '#737BF6', backgroundColor: 'white' }, { height: 240 }, darkMode && {backgroundColor: Colors.darkPurple}]}>
                                                <View style={[sliderStyle.sliderItem1, Flex.alignCenter, { paddingLeft: 20, paddingRight: 20 }]}>
                                                    <CustomImage
                                                        source={item.img}
                                                        require={true}
                                                        style={{ width: 40, height: 40, resizeMode: 'contain' }}
                                                        webStyle={{ width: 'auto', height: 45 }}
                                                        altText={item.title}
                                                    />
                                                    <Text style={[Padding.pt_2, Padding.pb_1, { fontSize: 18, lineHeight: 24, fontFamily: helpers.isWeb() ? FontFamily.regular : FontFamily.semiBold, fontWeight: FontWeight.semiBold, color: '#000' }, darkMode && {color: Colors.lightWhite}]}>{item.title}</Text>
                                                    <Text style={{ textAlign: 'center', fontSize: 16, lineHeight: 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.9)', opacity: 0.85 }}>{item.content}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    )
                                })}
                            </ScrollView>

                        </View>
                    }
                </View>
            </View>
        );
    }

    render() {
        let webView = helpers.isWeb();
        let btnClickEmail;
        let { darkMode } = this.props;
        if (helpers.isWeb()) {
            btnClickEmail = {
                href: `mailto:priya@mailtmc.com`
            }
        }
        else {
            btnClickEmail = {
                onPress : () => Linking.openURL(`mailto:priya@mailtmc.com`)
            }
        }
        return (
            <>
                {/*{this.whychoosecredits()} */}
                {this.howcreditworks()}
                <View style={[Flex.alignCenter, !helpers.isDesktop() && { marginTop: 15, textAlign: 'center', paddingRight: 10 }]}>
                    <TouchableOpacityLinkExternal {...btnClickEmail}
                        activeOpacity={0.8}>
                        <Text style={[Padding.pl_2, Padding.pr_2, Margin.mb_3, !helpers.isDesktop() && { marginTop: 20 }, { textAlign: 'center', fontSize: 18, lineHeight: 22, letterSpacing: 0.3, color: 'rgba(0, 0, 0, 0.9)', opacity: 0.85, fontFamily: FontFamily.regular, fontWeight: '400' },!helpers.isWeb() && {marginTop: 0}, darkMode && { color: Colors.lightWhite }]}>Want to buy a unique pack? Let us design one for you. Reach out here -  <Text style={[{ fontWeight: '600' }, !helpers.isWeb() && {fontFamily: FontFamily.bold}]}>priya@mailtmc.com</Text></Text>
                    </TouchableOpacityLinkExternal>
                </View>
            </>
        )
    }
}

export default AuthenticationWrapper(CreditsInfo);
