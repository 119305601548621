import React, { Component } from "react";
import { Text, View, TextInput } from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  pageContent,
  FontFamily,
  input,
  Colors,
  Messages,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import Button from "../button";
import PageBreak from "../page-break";
import ButtonGradient from "../button-gradient";
import Svg, {
  Circle,
  Ellipse,
  G,
  Text as SvgText,
  TSpan,
  TextPath,
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Image,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from "react-native-svg";

const blueDots = getImagePath("icons/blue-dots.png");
const checkIcon = getImagePath("icons/check.png");

const sharpLogin = getImagePath("icons/sharp-login.png");

export class FreeReads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  signUp = () => {
    const { email } = this.state;
    this.setState({
      btnLoader: true,
      referralMsg: {
        enable: false,
        type: "",
        msg: "",
      },
    });

    // if (helpers.validateEmail(email)) {
    this.setState({
      btnLoader: false,
    });
    try {
      if (this.props.trackSignUp) {
        this.props.trackSignUp(email, "mid banner");
      }
    } catch (error) {
      console.log("mix panel track error ==>", error);
    }
    if (helpers.isWeb()) {
      this.props.navigateScreen(
        `/sign-up?redirect=${window.location.pathname}`,
        {
          redirect: "post-single",
          categorySlug: this.state.categorySlug,
          postSlug: this.state.postSlug,
          verifyRedirect:
            "/" + this.state.categorySlug + "/" + this.state.postSlug,
          email: email,
        }
      );
    } else {
      this.props.navigateScreen(
        `/sign-up?redirect=${window.location.pathname}`,
        {
          redirect: "post-single",
          categorySlug: this.state.categorySlug,
          postSlug: this.state.postSlug,
          verifyRedirect:
            "/" + this.state.categorySlug + "/" + this.state.postSlug,
          email: email,
        }
      );
    }
  };
  render() {
    let { darkMode, privilege, data } = this.props;
    return (
      <View
        style={[
          {
            paddingBottom: 40,
            paddingTop: 40,
            paddingLeft: 20,
            paddingRight: 20,
            borderColor: "#55C79A",
          },
          privilege && {
            marginRight: helpers.isDesktop() ? 0 : -20,
            marginLeft: helpers.isDesktop() ? 0 : -20,
          },
        ]}
      >
        <View style={[Flex.alignCenter]}>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 34 : 32,
                lineHeight: helpers.isDesktop() ? 40 : 47,
                fontFamily: FontFamily.abrilFatface,
                marginBottom: helpers.isDesktop() ? 10 : 5,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              },
              !helpers.isDesktop() && { color: "#051426" },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            {`Read this  ${data.newsletter ? "Newsletter " : "Story "}For Free`}
            {helpers.isDesktop() && <PageBreak darkMode={darkMode} />}
          </Text>
        </View>
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1000,
            helpers.isDesktop() && Padding.ph_2,
            { flexDirection: helpers.isDesktop() ? "row" : "column" },
            helpers.isDesktop() && {
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
        >
          <View
            style={[
              helpers.isDesktop() && Margin.mb_1,
              helpers.isDesktop() && Padding.pl_3,
              { width: helpers.isDesktop() ? "100%" : "100%" },
              {
                order: 1,
                padding: helpers.isDesktop() ? 25 : 15,
                textAlign: "left",
              },
              !helpers.isDesktop() && { marginTop: 20 },
            ]}
          >
            <View
              style={{
                backgroundColor: "#fff",
                borderWidth: 2,
                borderColor: darkMode ? Colors.lightWhite : "#6C7AF6",
                padding: 20,
              }}
            >
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 36 : 22,
                    lineHeight: helpers.isDesktop() ? 50 : 33,
                    fontFamily: FontFamily.abrilFatface,
                    color: darkMode ? Colors.lightWhite : "#051462",
                    marginBottom: 10,
                  },
                ]}
              >
                Sign Up for Free!
              </Text>
              <View style={[Flex.row, Flex.alignCenter]}>
                <View>
                  <Svg
                    width="21"
                    height="15"
                    viewBox="0 0 21 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <Path
                      d="M7.61865 14.0499C7.85899 14.0521 8.09042 13.9599 8.26167 13.7936C8.26178 13.7935 8.26189 13.7934 8.262 13.7933L20.2655 2.52619L20.2658 2.52595C20.6339 2.17562 20.646 1.59904 20.2929 1.23428L20.2929 1.23423C19.9397 0.870256 19.3544 0.85371 18.9803 1.19694L18.9799 1.19733L7.6281 11.852L2.53624 6.88015C2.53623 6.88014 2.53621 6.88013 2.5362 6.88011C2.17336 6.52387 1.5856 6.5239 1.22252 6.88014L1.22249 6.88017C0.859171 7.23721 0.859171 7.81594 1.22249 8.17298L1.22256 8.17306L6.95719 13.7846C6.95723 13.7846 6.95726 13.7847 6.9573 13.7847C7.13224 13.9573 7.37086 14.0529 7.61865 14.0499ZM7.61865 14.0499C7.61847 14.0499 7.61828 14.0499 7.6181 14.0499L7.61857 13.9999L7.61921 14.0499C7.61902 14.0499 7.61884 14.0499 7.61865 14.0499Z"
                      fill="#2CB680"
                      stroke="#2CB680"
                      stroke-width="0.1"
                    />
                  </Svg>
                </View>
                <Text
                  style={[
                    Margin.ml_1,
                    {
                      fontSize: helpers.isDesktop() ? 19 : 16,
                      lineHeight: helpers.isDesktop() ? 32.3 : 27,
                      fontFamily: FontFamily.light,
                      color: darkMode ? Colors.lightWhite : "#000000",
                    },
                  ]}
                >
                  Get FREE 30 Starter Credits
                </Text>
              </View>
              <View style={[Flex.row, Flex.alignCenter]}>
                <View>
                  <Svg
                    width="21"
                    height="15"
                    viewBox="0 0 21 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/S"
                  >
                    <Path
                      d="M7.61865 14.0499C7.85899 14.0521 8.09042 13.9599 8.26167 13.7936C8.26178 13.7935 8.26189 13.7934 8.262 13.7933L20.2655 2.52619L20.2658 2.52595C20.6339 2.17562 20.646 1.59904 20.2929 1.23428L20.2929 1.23423C19.9397 0.870256 19.3544 0.85371 18.9803 1.19694L18.9799 1.19733L7.6281 11.852L2.53624 6.88015C2.53623 6.88014 2.53621 6.88013 2.5362 6.88011C2.17336 6.52387 1.5856 6.5239 1.22252 6.88014L1.22249 6.88017C0.859171 7.23721 0.859171 7.81594 1.22249 8.17298L1.22256 8.17306L6.95719 13.7846C6.95723 13.7846 6.95726 13.7847 6.9573 13.7847C7.13224 13.9573 7.37086 14.0529 7.61865 14.0499ZM7.61865 14.0499C7.61847 14.0499 7.61828 14.0499 7.6181 14.0499L7.61857 13.9999L7.61921 14.0499C7.61902 14.0499 7.61884 14.0499 7.61865 14.0499Z"
                      fill="#2CB680"
                      stroke="#2CB680"
                      stroke-width="0.1"
                    />
                  </Svg>
                </View>
                <Text
                  style={[
                    Margin.ml_1,
                    {
                      fontSize: helpers.isDesktop() ? 19 : 16,
                      lineHeight: helpers.isDesktop() ? 32.3 : 28,
                      fontFamily: FontFamily.light,
                      color: darkMode ? Colors.lightWhite : "#000000",
                    },
                  ]}
                >
                  Use Starter Credits to unlock some of the best stories,
                  newsletters and long reads from our Starter Credits Collection{" "}
                </Text>
              </View>
              <View style={[Flex.row, Flex.alignCenter]}>
                <View>
                  <Svg
                    width="21"
                    height="15"
                    viewBox="0 0 21 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <Path
                      d="M7.61865 14.0499C7.85899 14.0521 8.09042 13.9599 8.26167 13.7936C8.26178 13.7935 8.26189 13.7934 8.262 13.7933L20.2655 2.52619L20.2658 2.52595C20.6339 2.17562 20.646 1.59904 20.2929 1.23428L20.2929 1.23423C19.9397 0.870256 19.3544 0.85371 18.9803 1.19694L18.9799 1.19733L7.6281 11.852L2.53624 6.88015C2.53623 6.88014 2.53621 6.88013 2.5362 6.88011C2.17336 6.52387 1.5856 6.5239 1.22252 6.88014L1.22249 6.88017C0.859171 7.23721 0.859171 7.81594 1.22249 8.17298L1.22256 8.17306L6.95719 13.7846C6.95723 13.7846 6.95726 13.7847 6.9573 13.7847C7.13224 13.9573 7.37086 14.0529 7.61865 14.0499ZM7.61865 14.0499C7.61847 14.0499 7.61828 14.0499 7.6181 14.0499L7.61857 13.9999L7.61921 14.0499C7.61902 14.0499 7.61884 14.0499 7.61865 14.0499Z"
                      fill="#2CB680"
                      stroke="#2CB680"
                      stroke-width="0.1"
                    />
                  </Svg>
                </View>
                <Text
                  style={[
                    Margin.ml_1,
                    {
                      fontSize: helpers.isDesktop() ? 19 : 16,
                      lineHeight: helpers.isDesktop() ? 32.3 : 28,
                      fontFamily: FontFamily.light,
                      color: darkMode ? Colors.lightWhite : "#000000",
                    },
                  ]}
                >
                  Starter Credits{" "}
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontWeight: helpers.isDesktop() ? "700" : "200",
                    }}
                  >
                    do not expire
                  </Text>{" "}
                  – use them on stories and newsletters of your choice
                </Text>
              </View>

              <View style={[Margin.mt_1, Flex.row, Flex.justifyStart]}>
                <View
                  style={{
                    width: helpers.isDesktop() ? 200 : "100%",
                    marginTop: 10,
                  }}
                >
                  <ButtonGradient
                    title="SIGN UP FOR FREE"
                    rootStyle={{
                      btnWrapper: button.primaryGradient,
                      btnText: button.primaryGradientText,
                    }}
                    onClick={this.signUp}
                    loader={false}
                    disable={false}
                  />
                </View>
              </View>
            </View>
            <View style={[Flex.row, Flex.alignCenter, { margin: "auto" }]}>
              <Text
                style={[
                  Margin.ml_1,
                  {
                    fontSize: helpers.isDesktop() ? 19 : 20,
                    lineHeight: helpers.isDesktop() ? 32.3 : 28,
                    fontFamily: FontFamily.light,
                    color: darkMode ? "#FC876D" : "#FC876D",
                    marginTop: 10,
                    fontWeight: "700",
                  },
                ]}
              >
                {" "}
                <Text
                  style={{
                    fontFamily: FontFamily.regular,
                    fontWeight: helpers.isDesktop() ? "700" : "200",
                    color: "#000000",
                  }}
                >
                  Already a subscriber?
                </Text>{" "}
                <Text
                  style={{
                    borderBottomColor: "red",
                    textDecorationLine: "underline",
                    cursor: 'pointer'
                  }}
                  onClick={this.signUp}
                >
                  Sign in
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default FreeReads;
