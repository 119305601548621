import React, { Component } from 'react';
import { Text, View, TextInput, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, pageContent, FontFamily, input, Colors, Messages } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';


import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Button from '../button';

const studentBlueIcon = getImagePath('icons/studentBlueIcon.png');
const checkBoxIcon = getImagePath('icons/checkedBox.png');

const corporatesBlueIcon = getImagePath('icons/corporatesBlueIcon.png');
const giftBlueIcon = getImagePath('icons/giftBlueIcon.png');

export class ViewPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    pushViewPlanClickToDataLayer = (headerTitle) => {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "view_plans",
          custom_user_id: this.props.custom_user_id || "NA",
          user_status: this.props.custom_user_id ? "logged in" : "guest",
          header_title: headerTitle.toLowerCase(),
          scroll_depth: helpers.getScrollDepth(),
        });
      }
    

    render() {
        let { darkMode } = this.props;

        return (
            <View style={{ backgroundColor: darkMode ? '' : '#FFF1EE', paddingBottom: 60, paddingTop: 60, paddingLeft: 20, paddingRight: 20 }}>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000, helpers.isDesktop() && Padding.ph_2,]}>
                    <TouchableOpacity style={[{ flexDirection: helpers.isDesktop() ? 'row' : 'column', alignItems: helpers.isDesktop() ? 'center' : 'flex-start', justifyContent: 'space-between', marginBottom: 50 }]} onPress={() => {
                        window.open
                            ('/student-subscription', "_blank")
                    }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }} >
                            <CustomImage
                                source={require('../../../../assets/icons/studentBlueIcon.png')}
                                require={true}
                                resizeMode={'cover'}
                                style={{ width: 40, height: 40, marginRight: 20, }}
                                webStyle={{ width: helpers.isDesktop() ? 65 : 40, height: helpers.isDesktop() ? 65 : 40, objectFit: 'contain', marginRight: helpers.isDesktop() ? 30 : 20 }}
                            />
                            <View style={{ flex: 1, maxWidth: 560 }}>
                                <Text style={{ fontSize: helpers.isDesktop() ? 26 : 18, fontFamily: FontFamily.semiBold, lineHeight: helpers.isDesktop() ? 44 : 24, marginBottom: 8, color: darkMode ? Colors.lightWhite : '#000000' }}>TMC for students</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000000' }}>Get <Text style={{ fontFamily: FontFamily.bold }}>40% discount</Text> on our annual subscription and access to our exclusive student community on Telegram.</Text>
                            </View>
                        </View>
                        <View style={[{ marginTop: helpers.isDesktop() ? 0 : 30, }, !helpers.isDesktop() && { marginLeft: 60 }]}>
                            <Button
                                title={'VIEW PLANS'}
                                rootStyle={{
                                    btnWrapper: [{}],
                                    btnText: [{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 20 : 14, lineHeight: helpers.isDesktop() ? 20 : 18, color: darkMode ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }]
                                }}
                                onClick={() => {
                                    this.pushViewPlanClickToDataLayer("TMC for students");
                                    window.open
                                        ('/student-subscription', "_blank")
                                }}
                                rightIcon={true}
                            />
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity style={[{ flexDirection: helpers.isDesktop() ? 'row' : 'column', alignItems: helpers.isDesktop() ? 'center' : 'flex-start', justifyContent: 'space-between', marginBottom: 50 }]} onPress={() => { window.open('/team-subscription', "_blank") }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <CustomImage
                                source={require('../../../../assets/icons/corporatesBlueIcon.png')}
                                require={true}
                                style={{ width: 40, height: 40, resizeMode: 'contain', marginRight: 20, }}
                                webStyle={{ width: helpers.isDesktop() ? 65 : 40, height: helpers.isDesktop() ? 65 : 40, objectFit: 'contain', marginRight: helpers.isDesktop() ? 30 : 20 }}
                            />
                            <View style={{ flex: 1, maxWidth: 560 }}>
                                <Text style={{ fontSize: helpers.isDesktop() ? 26 : 18, fontFamily: FontFamily.semiBold, lineHeight: helpers.isDesktop() ? 44 : 24, marginBottom: 8, color: darkMode ? Colors.lightWhite : '#000000' }}>TMC for corporates</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000000' }}>We offer seat based and privilege credit based plans for teams. Check out our plans to know more</Text>
                            </View>
                        </View>
                        <View style={[{ marginTop: helpers.isDesktop() ? 0 : 30, }, !helpers.isDesktop() && { marginLeft: 60 }]}>
                            <Button
                                title={'VIEW PLANS'}
                                rootStyle={{
                                    btnWrapper: [{}],
                                    btnText: [{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 20 : 14, lineHeight: helpers.isDesktop() ? 20 : 18, color: darkMode ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }]
                                }}
                                onClick={() => 
                                    { 
                                        this.pushViewPlanClickToDataLayer("TMC for corporates");
                                        window.open('/team-subscription', "_blank") 
                                    }
                                }
                                rightIcon={true}
                            />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={[{ flexDirection: helpers.isDesktop() ? 'row' : 'column', alignItems: helpers.isDesktop() ? 'center' : 'flex-start', justifyContent: 'space-between' }]} onPress={() => { window.open('/gifting', "_blank") }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <CustomImage
                                source={require('../../../../assets/icons/giftBlueIcon.png')}
                                require={true}
                                style={{ width: 40, height: 40, resizeMode: 'contain', marginRight: 20 }}
                                webStyle={{ width: helpers.isDesktop() ? 65 : 40, height: helpers.isDesktop() ? 65 : 40, objectFit: 'contain', marginRight: helpers.isDesktop() ? 30 : 20 }}
                            />
                            <View style={{ flex: 1, maxWidth: 560 }}>
                                <Text style={{ fontSize: helpers.isDesktop() ? 26 : 18, fontFamily: FontFamily.semiBold, lineHeight: helpers.isDesktop() ? 44 : 24, marginBottom: 8, color: darkMode ? Colors.lightWhite : '#000000' }}>Gift a Subscription</Text>
                                <Text style={{ fontSize: helpers.isDesktop() ? 18 : 14, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000000' }}>Give the gift of in-depth and beautifully written stories to your family and friends.</Text>
                            </View>
                        </View>
                        <View style={[{ marginTop: helpers.isDesktop() ? 0 : 30, }, !helpers.isDesktop() && { marginLeft: 60 }]}>
                            <Button
                                title={'VIEW PLANS'}
                                rootStyle={{
                                    btnWrapper: [{}],
                                    btnText: [{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 20 : 14, lineHeight: helpers.isDesktop() ? 20 : 18, color: darkMode ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }]
                                }}
                                onClick={() => 
                                    { 
                                        this.pushViewPlanClickToDataLayer("Gift a subscription");
                                        window.open('/gifting', "_blank") 
                                    }
                                }
                                rightIcon={true}
                            />
                        </View>
                    </TouchableOpacity>

                </View>

            </View>
        );
    }
}

export default ViewPlans;