import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, button, FontFamily, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import apiHelpers from "../../utils/api-helpers";
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';

const cookies = new Cookies();

const goldCoin = getImagePath('icons/goldCoin.png');

export class BuyCredits extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        this.state = {
            enableBtnLoader: false,
            ordersData: [],
            pageLoader: false,
        }
    }

    componentDidMount = () => {

    }

    renderPageContent = () => {
        let { ordersData, enableBtnLoader } = this.state;
        return (
            <View style={{ width: 400, marginBottom: helpers.isDesktop() ? 150 : 50 }}>
                <View style={[Padding.ph_2, Padding.pv_2]}>
                    <View style={[Flex.row, Flex.justifyBetween, Flex.alignCenter, Padding.pv_2]}>
                        <View style={[Flex.row, Flex.alignCenter]}>
                            <CustomImage
                                source={goldCoin}
                                style={{ width: 26, height: 26 }}
                                webStyle={{ width: 26, height: 26 }}
                                require={true}
                                altText={'Credits'}
                            />
                            <Text style={[Margin.ml_2, { fontSize: 18, lineHeight: 27, color: '#000000', fontWeight: FontWeight.medium, fontFamily: FontFamily.medium }]}>25 Credits</Text>
                        </View>
                        <View>
                            <ButtonGradient
                                title={'₹499'}
                                onClick={() => this.subscribeToPlan()}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradient, { height: 30 }],
                                    btnText: [button.primaryGradientText, { fontSize: 12, lineheight: 14, paddingHorizontal: 15 }],
                                }}
                                smallBtn={true}
                                rounded={false}
                                onClick={this.signUp}
                            />
                        </View>
                    </View>
                    <View style={[Flex.row, Flex.justifyBetween, Flex.alignCenter, Padding.pv_2, { borderTopWidth: 1, borderTopColor: '#DDDDDD' }]}>
                        <View style={[Flex.row, Flex.alignCenter]}>
                            <CustomImage
                                source={goldCoin}
                                style={{ width: 26, height: 26 }}
                                webStyle={{ width: 26, height: 26 }}
                                require={true}
                                altText={'Credits'}
                            />
                            <Text style={[Margin.ml_2, { fontSize: 18, lineHeight: 27, color: '#000000', fontWeight: FontWeight.medium, fontFamily: FontFamily.medium }]}>25 Credits</Text>
                        </View>
                        <View>
                            <ButtonGradient
                                title={'₹499'}
                                onClick={() => this.subscribeToPlan()}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradient, { height: 30 }],
                                    btnText: [button.primaryGradientText, { fontSize: 12, lineheight: 14, paddingHorizontal: 15 }],
                                }}
                                smallBtn={true}
                                rounded={false}
                                onClick={this.signUp}
                            />
                        </View>
                    </View>
                    <View style={[Margin.mt_4]}>
                        <Text style={{ fontSize: 14, lineheight: 21, textAlign: 'center', color: 'rgba(0, 0, 0, .7)', letterSpacing: 0.2 }}>More credit plans coming soon!</Text>
                    </View>
                </View>
            </View>
        )
    }


    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {
                    pageLoader ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }

}

export default BuyCredits;