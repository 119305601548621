import React, { Component } from 'react';
import { helpers } from '../../native/utils/helpers';
import HomeExclusiveContent from '../../native/components/home-exclusive-content';
import apiHelpers from '../../native/utils/api-helpers';
import HomeChooseSubscription from '../../native/components/home-choose-subscription';
import  AppHeader  from '../../native/components/app-header';
import AppFooter from '../../native/components/app-footer';
import { connect } from 'react-redux';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Cookies from "universal-cookie";
import { Mixpanel } from '../../native/utils/mixpanel';
import {DownloadApp} from './DownloadApp'

const cookies = new Cookies();

export class AppDownload extends Component {
    constructor(props) {
        super(props);
        let postData = (process.browser) ? document.getElementById("postData") : null;
        let footerData = (process.browser) ? document.getElementById("footerData") : null;
        this.state = {
            exclusiveContent:false,            
            homeContent: this.props.homeData && this.props.homeData.homeContent ? this.props.homeData.homeContent : false,
            subscriptionRefreshKey:false,
            footerData: this.props.footerData ? this.props.footerData : false,
            showErrorModal:false,
            userSubscriptionDetails:false,
            pageLoader:true,
            searchQuery: "",
            locationHash: "",
            archiveSlug: this.props.match.params.archive_slug,
            
        }
        if(process.browser) {
            if(postData) {
                postData.parentNode.removeChild(postData);
            }
            if(footerData) {
                footerData.parentNode.removeChild(footerData);
            }
            try {
                ReactGA.pageview(this.props.history.location.pathname);
            }
            catch(error) {
                console.log("GA tracking error =>", error);
            }
        }
    }

    componentDidMount =async () => {
        this.getData()
    }
    
    async getData(){
        await this.getExclusiveContent()
        await this.getHomeContent(["newsletters"]);
    }

    getExclusiveContent = () => {
        this.setState({ showErrorModal: false });
        let endpoint = `/stories`;
        let body = {
            limit: 1,
            filters: [{ key: "is_featured", value: true }, { key: "single_category", value: true }]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, false)
            .then((res) => {
                if (res.success && res.code == 'story_found') {                    
                    this.setState({
                        exclusiveContent: res.data.story,
                    });
                } 
            })
            .catch((error) => {
                
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getHomeContent = (content) => {
            const { token } = this.state;
            let endpoint = `/static-content`;
            let body = {
                content: content
            }
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, false)
                .then((res) => {
                    if (res.success && res.code == 'content_found') {
                        this.setState({ 
                            homeContent: res.data,
                            pageLoader:false 
                        });
                    }
                    else {
                        console.log('Error=>>', JSON.stringify(res));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log('Error=>> down', JSON.stringify(error));
                });
    }
    
    viewMoreArticles = () => {
        const { viewAllArticles } = this.props;
        viewAllArticles()
    }

    viewArticle = (catSlug, slug, postData) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.navigate('post-single', { categorySlug: catSlug, postSlug: slug, postData: postData });
        }
    }

    viewAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    viewCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    handleClick = (title, data, index) => {
        try {
            if (this.props.trackStoryClick) {
                this.props.trackStoryClick(title, data, index);
            }
        }
        catch (error) {
            console.log("check mix panel track error ==>", error);
        }
    }

    navigateScreen = (pushProp) => {
        if (helpers.isWeb()) {
            this.props.history.push(pushProp)
        }
        else {
            if (pushProp == '/') {
                this.getData()
            }
            else {
                this.props.navigation.navigate(pushProp)
            }
        }
    }

    trackSubscriptionClick = (subscribtion) => {
        try {
            Mixpanel.track('click_subscription', { plan: subscribtion.name });
        }
        catch (error) {
            console.log("mix panel =>", error);
        }
    }

    goToHome = () => {
        if (helpers.isWeb()) {
            this.props.navigateScreen('/')
        }
        else {
            this.getData();
            this.refs.header.getUserInfo();
        }
    }
    handleUserSubscriptionDetails = (userSubscriptionDetailsValue) => {
        this.setState({userSubscriptionDetails: userSubscriptionDetailsValue});
    }
    showPageLoader = () => {
        return(
            <View style={[styles.container]}>
                <ActivityIndicator size="large" 
                style = {styles.activityIndicator}/>
          
            </View>
        )
    }
    componentDidUpdate = () => {
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
        if(this.state.archiveSlug != this.props.match.params.archive_slug) {
            this.setState({
                archiveSlug: this.props.match.params.archive_slug,
                archiveData: null,
                footerData: null
            })
        }
    }
    navigateToScreen = (route, params) => {
        let routeArr = route.split('?')
        const { history } = this.props;
        history.push({
          pathname: routeArr[0],
          search: routeArr[1] ? ('?' + routeArr[1]) : '',
          state: params
        });
    }

    render() {
        const { showErrorModal,pageLoader,exclusiveContent, homeContent } = this.state
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const { archiveSlug, archiveData, footerData, searchQuery, locationHash } = this.state;
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }
        return (
            <>
               {pageLoader ? this.showPageLoader() :
               <>
                <DownloadApp history={this.props.history} searchQuery={searchQuery} locationHash={locationHash} key={archiveSlug} navigateToScreen={this.navigateToScreen} archiveData={archiveData} archiveSlug={archiveSlug} footerData={footerData} params={this.props.params} exclusiveContent={exclusiveContent} homeContent={homeContent} token={this.state.token} />
               
            <AppFooter
                freeUser={this.props.freeUser} 
                navigateWeb={(slug) => this.props.navigateToScreen(slug)} 
                navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} 
                footerData={this.state.footerData} Breadcrumbs={Breadcrumbs} />
            
            {
            showErrorModal && 
            <ErrorModal 
                goToHome={() => this.props.navigateToScreen('/')} 
                tryAgain={() => webView ? this.getData() : this.goToHome()} show={showErrorModal} 
            />}
               </>
               }
                

            </> 
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}


export default connect(mapStateToProps)(AppDownload);


let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    }
]
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center"   
  },
  activityIndicator: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 80
 }
});