import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList, Dimensions } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, pageCover, pageContent, investorCard, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import AuthorItem from '../../components/author-item';
import ContentLoader from "react-native-easy-content-loader";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const upArrowIcon = getImagePath('icons/uparrow.png');
const cookies = new Cookies();

const dualCircleBlue = getImagePath('icons/dualCircleBlue.png');
const blueDots = getImagePath('icons/purple-dots-b.png');


const teamOne = getImagePath('img/teamBannerOne.png');
const teamTwo = getImagePath('img/teamBannertwo.png');
const teamThree = getImagePath('img/teamBannerThree.png');
const teamFour = getImagePath('img/teamBannerFour.png');
const windowWidth = Dimensions.get('window').width;

export class Team extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        let writerData, contributorData;
        if (this.props.postData) {
            // writerData = this.sortWriter(this.props.writerData.author)
            let allWriters = this.segregateWriters(this.props.postData.writerData);
            writerData = allWriters['writers'];
            contributorData = allWriters['contributors'];
        }
        this.state = {
            writerData: writerData ? writerData : false,
            contributorData: contributorData ? contributorData : false,
            writerDataSSR: writerData ? true : false,
            pageLoader: writerData ? false : true,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false
        }

        this.alphFilterOffset = [];
        this.alphFilterOffsetMain = 0;
        this.alphFilterOffsetSub = 0;
        this.alphFilterOffsetTitle = 0;
    }

    componentDidMount = () => {
        if (!this.state.writerDataSSR) {
            this.getWriters();
        }
    }

    componentWillUnmount = () => {
        if (this.scrollRef) {
            this.scrollRef();
        }
    }

    getWriters = () => {
        this.setState({
            showErrorModal: false,
            pageLoader: true,
        });
        const { token } = this.state;
        let endpoint = `/authors`;
        let body = {
            sort_on: 'name',
            sort_by: 'asc',
            filters: [
                {
                    key: "no_posts",
                    value: true
                }
            ]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'author_found') {
                    // let writerData = this.sortWriter(res.data.author);
                    let allWriters = this.segregateWriters(res.data.author);
                    this.setState({
                        writerData: allWriters['writers'],
                        contributorData: allWriters['contributors'],
                        pageLoader: false
                    });
                } else {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    segregateWriters = (writerList) => {
        let writers = [], contributors = [];
        writerList.map((writer) => {
            if (writer.roles.includes("editor")) {
                writers.push(writer);
            } else {
                contributors.push(writer);
            }
        })
        return { writers: writers, contributors: contributors };
    }

    sortWriter = (writerList) => {
        let writers = {}
        writerList.map((writer) => {
            let firstLetter = writer.name.substr(0, 1).toUpperCase();
            if (writers[firstLetter]) {
                writers[firstLetter].push(writer);
            } else {
                writers[firstLetter] = [writer];
            }
        })
        return writers;
    }

    getModalClickEvent = () => {

    }

    getScrollOffset = (event) => {
        let scrollCheck;
        if (helpers.isWeb()) {
            scrollCheck = window.pageYOffset;
        } else {
            scrollCheck = event.nativeEvent.contentOffset.y;
        }
        if (scrollCheck > 500) {
            this.setState({
                showUparrow: true,
            })
        } else {
            this.setState({
                showUparrow: false,
            })
        }
    }

    applyRef = (ref) => {
        if (helpers.isWeb()) {
            this.scrollRef = window.addEventListener('scroll', this.getScrollOffset);
        }
    }

    scrollToTop = () => {
        if (helpers.isWeb()) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(0)
        }
    }

    scrollToSection = (element) => {
        let offsetPosition = this.alphFilterOffset[element] + this.alphFilterOffsetMain + this.alphFilterOffsetSub - this.alphFilterOffsetTitle;

        if (helpers.isWeb()) {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(offsetPosition)
        }

    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                <View style={[helpers.isDesktop() && Margin.mb_7, Helpers.textCenter, { backgroundColor: darkMode ? Colors.darkBlackColor : '#F9F8FF', marginTop: helpers.isDesktop() ? 130 : 75, paddingHorizontal: helpers.isDesktop() ? 20 : 0, width: '100%' }]}>
                    <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, { position: 'relative', top: helpers.isDesktop() ? -38 : -19 }, darkMode && { color: Colors.lightWhite }, !helpers.isWeb() && { top: -15 }]}>Team</Text>
                    <Text style={[{ marginBottom: helpers.isDesktop() ? 90 : 50 }, pageContent.subTitle, helpers.isDesktop() && pageContent.subTitleDesktop, { maxWidth: helpers.isDesktop() ? 900 : '100%' }, !helpers.isWeb() && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>We are in the people's business. Which means that we have some of the best journalists on the team, and we take good care of them.</Text>
                    <CustomImage
                        source={blueDots}
                        require={true}
                        style={{ width: 50, height: 20.3, position: 'absolute', right: helpers.isDesktop() ? -15 : 20, top: helpers.isDesktop() ? -10 : -10 }}
                        webStyle={{ width: helpers.isDesktop() ? 60 : 45, height: helpers.isDesktop() ? 25 : 20, position: 'absolute', right: helpers.isDesktop() ? -15 : 20, top: helpers.isDesktop() ? -10 : -10 }}
                    />
                    <CustomImage
                        source={dualCircleBlue}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 50 : 30, height: helpers.isDesktop() ? 50 : 30, position: 'absolute', left: helpers.isDesktop() ? -30 : 0, bottom: helpers.isDesktop() ? 36 : 20 }}
                        webStyle={{ width: helpers.isDesktop() ? 50 : 30, height: helpers.isDesktop() ? 50 : 30, position: 'absolute', left: helpers.isDesktop() ? -30 : 0, bottom: helpers.isDesktop() ? 36 : 20 }}
                    />
                </View>
            </View>
        )
    }

    renderTeamBanner = () => {
        return (
            <View style={{ marginBottom: helpers.isDesktop() ? 150 : 50, marginTop: helpers.isDesktop() ? 80 : 20, paddingHorizontal: helpers.isDesktop() ? 0 : 15 }}>
                <View style={[helpers.isDesktop() ? Flex.row : Flex.column, Flex.alignEnd, { marginBottom: 15 }]}>
                    <CustomImage
                        source={teamOne}
                        require={true}
                        style={{ maxWidth: helpers.isDesktop() ? 670 : '100%', width: windowWidth, height: 215, marginRight: helpers.isDesktop() ? 15 : 0, marginBottom: 15 }}
                        webStyle={{ maxWidth: helpers.isDesktop() ? 670 : '100%', width: '100%', height: 'auto', marginRight: helpers.isDesktop() ? 15 : 0, marginBottom: helpers.isDesktop() ? 0 : 15 }}
                        resizeMode={'cover'}
                        altText={'Team'}
                    />
                    <CustomImage
                        source={teamTwo}
                        require={true}
                        style={{ maxWidth: helpers.isDesktop() ? 340 : '100%', width: windowWidth, height: 330 }}
                        webStyle={{ maxWidth: helpers.isDesktop() ? 340 : '100%', width: '100%', height: 'auto' }}
                        resizeMode={'cover'}
                        altText={'Team'}
                    />
                </View>
                <View style={[helpers.isDesktop() ? Flex.row : Flex.column, Flex.alignStart, Flex.justifyEnd, { marginBottom: 15 }]}>
                    <CustomImage
                        source={teamThree}
                        require={true}
                        style={{ maxWidth: helpers.isDesktop() ? 340 : '100%', width: windowWidth, height: 320, marginRight: helpers.isDesktop() ? 15 : 0, marginBottom: 15 }}
                        webStyle={{ maxWidth: helpers.isDesktop() ? 340 : '100%', width: '100%', height: 'auto', marginRight: helpers.isDesktop() ? 15 : 0, marginBottom: helpers.isDesktop() ? 0 : 15 }}
                        resizeMode={'cover'}
                        altText={'Team'}
                    />
                    <CustomImage
                        source={teamFour}
                        require={true}
                        style={{ maxWidth: helpers.isDesktop() ? 670 : '100%', width: windowWidth, height: 220 }}
                        webStyle={{ maxWidth: helpers.isDesktop() ? 670 : '100%', width: '100%', height: 'auto' }}
                        resizeMode={'cover'}
                        altText={'Team'}
                    />

                </View>
            </View>
        )
    }

    viewAuthorDetails = (Slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + Slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: Slug });
        }
    }

    renderPageContent = () => {
        const { writerData, contributorData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>

                {this.renderTeamBanner()}

                <View style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter]}>
                    <View style={[!helpers.isDesktop() && { backgroundColor: darkMode ? Colors.darkBlackColorBg : 'white', zIndex: 1, paddingHorizontal: 20 }]}>
                        <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }, darkMode && { borderBottomColor: Colors.lineColor }]}>
                            <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, helpers.isDesktop() && { marginRight: 0 }, !helpers.isWeb() && { marginBottom: 1 }, darkMode && { color: Colors.lightWhite }]}>Meet the team</Text>
                        </View>
                    </View>
                    {!helpers.isDesktop() && <View style={[Helpers.titleLine, darkMode && { backgroundColor: Colors.lineColor }]}></View>}
                    {helpers.isDesktop() && <View style={{ width: '100%', flex: 1 }}>
                        <View style={[Helpers.titleLine, darkMode && { backgroundColor: Colors.lineColor }]}></View>
                    </View>}
                </View>
                <View style={[Flex.row, Flex.FlexWrap, helpers.isDesktop() && Margin.mt_3]}>
                    {writerData && writerData.map((citem, index) => {
                        return (
                            <AuthorItem viewAuthor={(slug) => this.viewAuthorDetails(slug)} single={index == 0 ? true : false} key={`authorItem${index}`} data={citem} team={true} about={true} showArrow={true} contributor={false} itemsInRow={3} teamWriters={true} require={false} darkMode={darkMode} />
                        )
                    })
                    }
                </View>
            </View>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/team',
            native: { redirect: '/team', verifyRedirect: '/team' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/team',
            native: { redirect: '/team', verifyRedirect: '/team' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={seoObj['team']} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/team" />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }
                    {showUparrow &&
                        <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                            <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                <CustomImage
                                    source={upArrowIcon}
                                    require={true}
                                    style={{ width: 60, height: 60 }}
                                    webStyle={{ width: 60, height: 60 }}
                                />
                            </TouchableOpacity>
                        </View>
                    }</>
                }

                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(Team));

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Team'
    }
]

