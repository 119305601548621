import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-community/async-storage';
import AppReducer from "./App/app.reducer";
import UserReducer from "./User/user.reducer";

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: ['app', 'user']
};

const rootReducer = combineReducers({
    app:AppReducer,
    user:UserReducer
});

export default persistReducer(persistConfig, rootReducer);