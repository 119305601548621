import { SAVE_TOKEN, REMOVE_TOKEN, USER_SUBSCRIPTION, SAVE_STATUS } from './user.types';

export const setUserToken = (userToken) => {
    return {
        type: SAVE_TOKEN,
        data: userToken
    }
}

export const removeUserToken = () => {
    return {
        type: REMOVE_TOKEN
    }
}

export const storeUserSubData = (data) => {
    return {
        type: USER_SUBSCRIPTION,
        data: data
    }
}

export const setUserStatus = (userStatus) => {
    return {
        type: SAVE_STATUS,
        data: userStatus
    }
}