import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Badges,
  Helpers,
  postContentTags,
  postContentClasses,
  postTags,
  modalStyle,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import Modal from "../modal";
import ImageViewer from "react-native-image-zoom-viewer";

const gradientLeft = getImagePath("img/gradientLeft.png");
const gradientRight = getImagePath("img/gradientRight.png");
const closeIcon = getImagePath("icons/close-icon-white.png");
const windowHeight = Dimensions.get("window").height;

export class ZoomImageNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImageModal: this.props.showImageModal,
    };
  }

  componentDidMount = () => { };

  renderCrossIcon = (showMargin, hide) => {
    const { enableLoader } = this.state;
    return (
      <View
        style={[
          {
            position: "absolute",
            right: 8,
            top: 20,
            zIndex: 10,
            backgroundColor: "#000",
            borderRadius: 30,
          },
        ]}
      >
        <TouchableOpacity
          activeOpacity={0.8}
          style={[modalStyle.closeButton]}
          onPress={() => {
            this.setState({ showImageModal: false });
            this.props.setShowImageModal(false);
          }}
        >
          <CustomImage
            source={closeIcon}
            style={{ width: 10, height: 10 }}
            webStyle={{ width: 10, height: 10 }}
            require={true}
          />
        </TouchableOpacity>
      </View>
    );
  };

  renderImageModalContent = () => {
    const { featuredImage } = this.props;
    let imgSrc = featuredImage.url;
    let sizes = false
    if(!imgSrc){
      imgSrc = featuredImage
      sizes = true
    }
    // let imgSrc = featuredImage?.sizes ? featuredImage.sizes["1x"] : "";
    let { darkMode } = this.props;
    const images = [
      {
        url: imgSrc,
      },
    ];
    return (
      <View style={{ height: "100%", backgroundColor: "white" }}>
        {this.renderCrossIcon()}
        <CustomImage
          webStyle={{
            height: "80%",
            width: "90%",
            objectFit: "cover",
            marginTop: helpers.isDesktop() ? "20%" : "0%",
            margin: !helpers.isDesktop() ? "5%" : 0,
          }}
          style={postSingle.postThumbnail}
          source={imgSrc}
          srcSet={false}
        />
        {/* <ImageViewer
                    renderHeader={this.renderCrossIcon}
                    imageUrls={images}
                    renderIndicator={() => { }}
                    maxOverflow={0}
                    saveToLocalByLongPress={false}
                    backgroundColor={darkMode ? 'black' : 'white'}
                /> */}
      </View>
    );
  };

  render() {
    const { featuredImage, lazyLoad, darkMode, featuredTitle } = this.props;
    const { showImageModal } = this.state;
    let imgSrc = featuredImage?.url;
    let sizes = false;
    if(!imgSrc){
      imgSrc = featuredImage
      sizes = true
    }
    console.log('featuredImage:', featuredImage);
    // let imgSrc = featuredImage?.sizes ? featuredImage.sizes["1x"] : "";

    return (
      <>
        {featuredImage?.sizes || sizes ? (
          <View
            style={{
              backgroundColor: this.props.darkMode ? "#121212" : "#ffffff",
            }}
          >
            <TouchableHighlight
              onPress={() => {
                this.setState({ showImageModal: true });
                this.props.setShowImageModal(true);
              }}
            >
              <View
                style={[
                  {
                    position: "relative",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    height: helpers.isDesktop() ? "auto" : "auto",
                    overflow: "hidden",
                  },
                  helpers.isDesktop() && { justifyContent: "center" },
                ]}
              >
                <CustomImage
                  webStyle={{
                    height: helpers.isDesktop() ? '42vw' : "auto",
                    width: helpers.isDesktop() ? '84vw' : 500,
                    maxWidth: "100%",
                    objectFit: "cover",
                    marginTop: helpers.isDesktop() ? "20%" : "0%",
                    marginTop: !helpers.isDesktop() ? 0 : 0,
                    paddingBottom: !helpers.isDesktop() ? "5%" : 0,
                    alignSelf: 'center'
                  }}
                  style={postSingle.postThumbnail}
                  source={imgSrc}
                  // lazyLoad={lazyLoad}
                  altText={
                    featuredImage.alt ? featuredImage.alt : featuredTitle
                  }
                  srcSet={false}
                />
              </View>
            </TouchableHighlight>
          </View>
        ) : (
          <View
            style={[
              postSingle.postThumbnailEmpty,
              { backgroundColor: "#eeeeee" },
            ]}
          />
        )}
        {showImageModal && (
          <>
            <Modal
              ref="child"
              renderContent={() => this.renderImageModalContent()}
              paddingHorizontal={true}
              topPadding={false}
              imageZoom={true}
              subscription={true}
              imagePadding={true}
              darkMode={darkMode}
            />
          </>
        )}
      </>
    );
  }
}

export default ZoomImageNews;
