import React, { Component } from 'react';
import { Animated, Text, TouchableOpacity, View } from 'react-native';
import Cookies from "universal-cookie";
import { appHeader, Flex, FontFamily, FontWeight, Helpers } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import TouchableOpacityLink from '../touchable-opacity-link';

const closeMsgIcon = getImagePath('icons/close-icon-white.png');
const cookies = new Cookies();

export class PlanAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnDisable: true,
            email: '',
            showStatus: new Animated.Value(1),
            showPlanAlert:true,
            referralMsg: {
                enable: false,
                type: '', // error / success
                msg: ''
            },
        }
    }

    componentDidMount = () => {
        this.setState({
            showPlanAlert:this.props.showPlanAlertBox
        })
    }

    componentDidUpdate=()=>{
        Animated.timing(this.state.showStatus, {
            toValue: this.props.hideHeader ? 0 : 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }
    closeAlert=(title,userId=0)=>{
        var date = new Date();
        // expires in 1 minutes for loggedout user and free loggedin user
        if(title =='Upgrade'){
            // expires in 12 hrs fo user who have plan
            date.setTime(date.getTime() + (12*60*60 * 1000));
        }
        else{
            date.setTime(date.getTime() + (60 * 1000));
        }
        
        cookies.set(`closeBand-${userId}`,true,{expires:date})
        this.setState({showPlanAlert:false})
    }


    render() {
        let { showStatus,showPlanAlert} = this.state;
        let webView = helpers.isWeb();
        let { title,showPlanAlertBox,userId} = this.props;
        let closeBand = cookies.get(`closeBand-${userId}`)
        if(closeBand && (userId == closeBand.split('-')[1])){
            this.setState({showPlanAlert:true})
            
        }
        

        let btnClick;
        if (webView) {
            btnClick = {
                to: '/pricing'
            }
        } else {
            btnClick = {
                onPress: () => this.props.navigateApp('/pricing')
            }
        }
        {/* will see aletr box on 
                        1.if user looged out
                        2.free login user
                        3.for paid user
                        */}
        return (
            <>
            {(showPlanAlert && !closeBand) &&
                 <Animated.View style={[helpers.isDesktop ? Flex.row : Flex.column,  { opacity: showStatus }, appHeader.paymentSuccess, helpers.isDesktop() && appHeader.paymentSuccessDesktop,  Flex.alignCenter, { position: 'fixed', left: 0, right: 0, top: helpers.isDesktop() ? 66 : 54, zIndex: 2, paddingBottom: 12, paddingTop: 12 }, Flex.justifyCenter, {    width: "100%"}]}>
                     <View style={[helpers.isDesktop ? Flex.row : Flex.column,Flex.alignCenter,Flex.justfiyCenter, { flexDirection: helpers.isDesktop() ? 'row' : 'column', flexWrap: 'wrap',position:"relative"},helpers.isDesktop() && {paddingRight: 30 },{width: '100%' }]}>
                        <View style={[Flex.row, Flex.alignCenter,{position:"static"}, !helpers.isDesktop && {    width:  "100%"}  ]}
                        >
                            <View style={[!helpers.isDesktop && Flex.fill, { flexDirection: 'row', alignItems: 'center' ,position:"static" }, !helpers.isDesktop() && {width: '100%' }]}>

                                <View style={[Flex.fill, { flexDirection: helpers.isDesktop() ? 'row' : 'column', flexWrap: 'wrap', width:"100%" }]}>
                                    {/* <Text style={[appHeader.alertMsg, !helpers.isDesktop() && { fontSize: 16 }]}>Your gift was sent to {giftReceiver}</Text> */}
                                    <Text style={[appHeader.msgText, {marginRight: helpers.isDesktop() ? 10: 20}, helpers.isDesktop() && appHeader.msgTextDesktop, { fontWeight: '700', fontFamily: FontFamily.bold, textDecorationLine: 'none', position: 'relative', top: 2 }]}>
                                        {`We’ve turned two. ${title} to our discounted two year plan that saves you 25% compared to our annual plan.`}
    
                                    </Text>
                                </View>

                            </View>
                        <TouchableOpacity style={[helpers.isDesktop && {position:"absolute", right:0}]} onPress={() => this.closeAlert(title,userId)}>
                            <CustomImage
                                source={closeMsgIcon}
                                require={true}
                                style={{ width: 14, height: 14 }}
                                webStyle={{ width: 14, height: 14 }}
                            />
                        </TouchableOpacity>
                        </View>
            
                        {helpers.isDesktop() ?
                    <View style={{marginLeft:15}}>
                        <TouchableOpacityLink {...btnClick}>
                            <Text
                                style={[
                                    Helpers.textUppercase,
                                    {
                                        color: "#907CFF",
                                        fontSize: 15,
                                        fontFamily: FontFamily.regular,
                                        fontWeight: '600',
                                        whiteSpace: "nowrap",
                                        backgroundColor: '#fff',
                                        paddingHorizontal: helpers.isDesktop() ?  10 : 20,
                                        paddingVertical: helpers.isDesktop() ? 5 : 10,
                                 },
                                ]}
                            >
                                {this.props.title}
                            </Text>
                        </TouchableOpacityLink>
                    </View>
                    :
                    <View style={{ marginVertical: 10, marginHorizontal: 'auto' }}>
                        <TouchableOpacityLink {...btnClick}>
                            <Text
                                style={[
                                    Helpers.textUppercase,
                                    {
                                        color: "#907CFF",
                                        fontSize: 10,
                                        fontFamily: FontFamily.medium,
                                        fontWeight: FontWeight.regular,
                                        whiteSpace: "nowrap",
                                        backgroundColor: '#fff',
                                        borderWidth: 1,
                                        borderColor: "#907CFF",
                                        paddingHorizontal: 20,
                                        paddingVertical: 10,
                                    },
                                ]}
                            >
                                {this.props.title}
                            </Text>
                        </TouchableOpacityLink>
                    </View>
                }
                </View>
            
            
            
            
            </Animated.View>
            }
            </>
        );
    }
}

export default PlanAlert;