import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList, Dimensions } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, article, FontFamily, FontWeight, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageBreak from '../../components/page-break';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import ContentLoader from "react-native-easy-content-loader";
import ButtonGradient from '../../components/button-gradient';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const cookies = new Cookies();

const arrowRightIcon = getImagePath('img/arrow-right-round.png');
const arrowRightActiveIcon = getImagePath('img/arrow-right-round-active.png');
const arrowRightWhiteIcon = getImagePath('icons/arrow-right-white.png');
const windowWidth = Dimensions.get('window').width;

export class UserInterests extends Component {
    constructor(props) {
        super(props);
        let initialSave = null, pageRedirect = null, redirectUrl = null;
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth;
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        if (webView && process.browser) {
            if (this.props.params) {
                initialSave = this.props.params.initialSave;
            }
            pageRedirect = this.props.pageRedirect;
            if (window.location.search.includes("redirect=")) {
                let searchArr = window.location.search.replace("?", "").split("&");
                let foundSearch = searchArr.find((searchParam) => {
                    return searchParam.includes("redirect=");
                });
                if (foundSearch) {
                    let search = foundSearch.replace("redirect=", "");
                    redirectUrl = (search != '') ? search : null;
                }
            }
        } else if (!webView) {
            initialSave = this.props.initialSave;
            pageRedirect = (this.props.route.params.pageRedirect) ? this.props.route.params.pageRedirect : null;
            redirectUrl = (this.props.route.params.redirect) ? this.props.route.params.redirect : null;
        }
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            pageLoader: true,
            showErrorModal: false,
            btnSubmitDisable: true,
            interestList: false,
            interestSelected: [],
            interestInitial: [],
            redirectUrl: redirectUrl,
            initialSave: initialSave ? initialSave : false,
            pageRedirect: pageRedirect ? pageRedirect : false
        }
    }

    componentDidMount = () => {
        this.getUserInterestList();
    }

    getUserInterestList = () => {
        this.setState({
            showErrorModal: false,
            pageLoader: true,
        });
        let endpoint = `/entities`;
        let body = {
            fields: ["tag", "storyplay"],
            limit: 10,
            filters: [{
                key: "user_interests",
                value: true
            }]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'entity_found') {
                    let tags = res.data.tag.map((tag) => {
                        tag.type = 'tag'
                        return tag;
                    })
                    let storyplays = res.data.storyplay.map((storyplay) => {
                        storyplay.type = 'storyplay'
                        return storyplay;
                    })
                    // tags = [...tags, ...storyplays ]
                    this.setState({ interestList: [...tags, ...storyplays] });
                    this.getUserInterest([...tags, ...storyplays]);
                } else {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getUserInterest = (interestList) => {
        if (this.state.pageRedirect) {
            this.setState({
                interestList: interestList,
                pageLoader: false
            });
        } else {
            const { token } = this.state;
            let endpoint = `/profile`;
            let body = {
                request_group: ["topic_interested"]
            }
            apiHelpers
                .apiRequest(endpoint, body, "POST", true, token)
                .then((res) => {
                    if (res.success && res.code == 'profile_info') {
                        let btnSubmitDisable = true, topicsInterested = [];
                        if (res.data.topic_interested) {
                            let response = this.preSelectFilters(res.data.topic_interested, interestList);
                            interestList = response.interestList;
                            btnSubmitDisable = response.selectedCount >= 3 ? false : true;
                            topicsInterested = response.topicsInterested;
                        }
                        this.setState({
                            interestList: interestList,
                            btnSubmitDisable: btnSubmitDisable,
                            interestSelected: topicsInterested,
                            interestInitial: topicsInterested,
                            pageLoader: false
                        });
                    } else {
                        this.setState({
                            showErrorModal: true,
                            pageLoader: false
                        });
                        console.log('Error=>>', JSON.stringify(res));
                    }
                })
                .catch((error) => {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    console.log('Error=>> down', JSON.stringify(error));
                });
        }
    }

    preSelectFilters = (topicsInterested, interestList) => {
        let selectedCount = 0;
        topicsInterested = topicsInterested.map((topic) => {
            return topic.slug;
        })
        interestList = interestList.map((interest) => {
            if (topicsInterested.includes(interest.slug)) {
                interest.selected = true;
                selectedCount++;
            }
            return interest;
        });
        return { interestList, selectedCount, topicsInterested }
    }

    saveUserInterests = () => {
        const { token, interestList, interestSelected } = this.state;
        this.setState({ saveLoader: true });
        let tag_array = [], storyplay_array = []
        for (let item of interestSelected) {
            let item_details = interestList.find((interest) => { return interest.slug == item })
            if (item_details && item_details.type == 'tag') {
                tag_array.push(item);
            }
            else if (item_details && item_details.type == 'storyplay') {
                storyplay_array.push(item);
            }
        }

        let endpoint = `/topic-interested`;
        let body = {
            topics_interested: []
        }
        if (tag_array.length > 0) {
            body.topics_interested.push(
                {
                    type: 'tag',
                    topics: tag_array
                }
            )
        }
        if (storyplay_array.length > 0) {
            body.topics_interested.push(
                {
                    type: 'storyplay',
                    topics: storyplay_array
                }
            )
        }

        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'interested_topics') {
                    this.goToNextPage();
                }
                else {
                    this.setState({ saveLoader: false });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({ saveLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                </View>
            </View>
        )
    }

    toggleInterestList = (index, key) => {
        let { interestList } = this.state;
        interestList[index].selected = !interestList[index].selected;

        let selectedCount = 0, interestSelected = [];
        interestList.map((interest) => {
            if (interest.selected != 'undefined' && interest.selected) {
                selectedCount++;
                interestSelected.push(interest.slug);
            }
            return interest;
        });

        this.setState({
            interestList,
            btnSubmitDisable: selectedCount >= 3 ? false : true,
            interestSelected: interestSelected
        })
    }

    renderPageContent = () => {
        const { btnSubmitDisable, interestList, saveLoader } = this.state;

        return (
            <View style={[Margin.mt_5, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, Padding.ph_2]}>
                <Text accessibilityRole="header" aria-level="1" style={[Typo.h2, Helpers.textCenter, Padding.pb_15, !helpers.isDesktop() && { marginTop: 40, marginBottom: 10 }, { fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 60 : 28, lineHeight: helpers.isDesktop() ? 81 : 37, letterSpacing: 0.3, fontWeight: FontWeight.regular }]}>What are your interests?</Text>
                <Text style={[Typo.text16, Helpers.textCenter, Padding.pb_2, { fontSize: helpers.isDesktop() ? 26 : 14, lineHeight: helpers.isDesktop() ? 42 : 17, color: helpers.isDesktop() ? 'rgba(0, 0, 0, 0.8)' : '#626262' }]}>Select at least 3 topics you’d like to read about.</Text>
                <View style={[Flex.row, Flex.FlexWrap, helpers.isDesktop() && Padding.ph_2, Margin.mt_5, Margin.mb_3]}>
                    {interestList &&
                        interestList.map((item, index) => {
                            return (
                                <TouchableOpacity
                                    onPress={() => this.toggleInterestList(index, item.key)}
                                    activeOpacity={0.8} key={`ui_${index}`} style={{ marginRight: helpers.isDesktop() ? 33 : 10, marginBottom: 20 }}>
                                    <View style={[Helpers.filterBtnWrap, helpers.isDesktop() && Helpers.filterBtnWrapDesktop, item.selected && Helpers.filterBtnWrapActive]}>
                                        <Text style={[Helpers.filterBtnText, Helpers.textUppercase, helpers.isDesktop() && Helpers.filterBtnTextDesktop, item.selected && Helpers.filterBtnTextActive]}>{item.name}</Text>
                                    </View>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>
                <View style={[helpers.isDesktop() && Margin.mt_4, Margin.mb_6, { position: 'relative', marginHorizontal: 20 }, !helpers.isDesktop() && { marginLeft: 'auto', marginRight: 'auto' }]}>
                    <ButtonGradient
                        title="Continue"
                        rootStyle={{
                            btnWrapper: [button.primaryGradient, { maxWidth: helpers.isDesktop() ? 300 : 320, height: 50 }, !helpers.isWeb() && {width: windowWidth}],
                            btnText: [button.primaryGradientText, { letterSpacing: helpers.isWeb() ? '0.05em' : 0.05 }, !helpers.isDesktop() && { paddingHorizontal: 113 }, { fontWeight: '400', fontSize: 16 }],
                        }}
                        onClick={() => this.saveUserInterests()}
                        disable={btnSubmitDisable}
                        loader={saveLoader}
                    />
                    <CustomImage
                        source={arrowRightWhiteIcon}
                        require={true}
                        style={{ width: 15, height: 12, position: 'absolute', right: 85, bottom: 18.5 }}
                        webStyle={{ width: helpers.isDesktop() ? 19 : 15, height: helpers.isDesktop() ? 15 : 12, position: 'absolute', right: helpers.isDesktop() ? 280 : 80, bottom: helpers.isDesktop() ? 18 : 19 }}
                    />
                </View>
            </View>
        )
    }


    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, btnSubmitDisable, initialSave, interestInitial } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }
        return (
            <ScrollView>
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
            </ScrollView>
        )
    }

    goToNextPage = () => {
        const { pageRedirect, initialSave, redirectUrl } = this.state;
        let convertedArr = helpers.convertWebToMobileRoute(redirectUrl);
        if (pageRedirect) {
            if (helpers.isWeb()) {
                this.props.navigateToScreen(redirectUrl);
            } else {
                this.props.navigation.replace(convertedArr.path, convertedArr.params);
            }
        } else if (initialSave) {
            if (helpers.isWeb()) {
                this.props.navigateToScreen(redirectUrl ? redirectUrl : '/');
            } else {
                this.props.navigation.replace(convertedArr.path, convertedArr.params);
            }
        } else {
            if (helpers.isWeb()) {
                this.props.navigateToScreen('/');
            } else {
                this.props.navigation.replace('/');
            }
        }
    }

    render() {
        const { pageLoader, showErrorModal, btnSubmitDisable, initialSave, interestInitial } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }

        let btnClick;
        if (webView) {
            btnClick = {
                onPress: () => this.saveUserInterests()
            }
        }
        else {
            btnClick = {
                onPress: () => this.saveUserInterests()
            }
        }

        btnClick = btnSubmitDisable ? '' : btnClick;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        let data = {
            pageName : 'User Interests'
        }

        return (
            <View style={[appStyles.appContainer, helpers.isDesktop() && appStyles.appContainerDesktop]}>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true}  />
                {(webView && process.browser && initialSave && (interestInitial.length != 0)) && <Redirect to="/" />}
                {pageLoader ? this.showPageLoader() :
                    <>{(webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )}
                    </>
                }
                
                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(UserInterests));
