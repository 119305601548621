import React, { Component } from "react";
import {
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { button, Colors, FontFamily } from "../../styles/appStyles";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import _ from "lodash";
import Button from "../button";
import moment from "moment";
import { ChargebeeController } from "../../utils/chargebee";

const checkWhiteIcon = getImagePath("icons/checkWhite.png");
const closeicon = getImagePath("icons/close-icon.png");
const infopng = getImagePath("icons/info.png");

const checkIcon = getImagePath("icons/check.png");
const groupelipse = getImagePath("icons/groupelipse.png");
const groupelipsetick = getImagePath("icons/groupelipsetick.png");

class SubscriptionApiCall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      features: [],
      selectedPlan: [],
      currentPlan: [],
      isPlanChanged: false,
      planList: [],

      // isButtonClicked: this.props.getCheckoutLoaderState(),
      cb_handle: null,
      locationInfo: this.props.country?.code || "IN",
    };

    this.selectPlanData = this.selectPlanData.bind(this);
  }
  componentDidMount() {
    ChargebeeController.activatedController().then((cb_handle) => {
      this.setState({ cb_handle: cb_handle });
      // Get country info as per billing account and not the geo location
      let countryInfo = null;
      if (
        cb_handle?.instance?.billingInfo?.billing_platform === "chargebee_india"
      ) {
        countryInfo = {
          code: "IN",
          name: "India",
        };
      } else if (cb_handle?.instance?.billingInfo?.billing_platform) {
        countryInfo = {
          code: "US",
          name: "USA",
        };
      }

      if (!countryInfo) {
        countryInfo = this.props.country;
      }

      this.setState({
        locationInfo: { countryInfo: countryInfo },
      });

      // Once we fetch the location, then we need to set the plan data, hence
      // all the code is moved here

      let billingCountry = this.state.locationInfo?.countryInfo?.code || "IN";

      // End point now reflects billing country
      let endpoint = `/plans/${this.props.planType}/${billingCountry}`;
      let highestPrice =
        this.props.data.length &&
        Math.max(...this.props.data.map((o) => o.price));

      let selectedPlan =
        this.props.data.length &&
        this.props.data.filter((d, i) => d.price == highestPrice);

      let currentPlan =
        this.props.data.length &&
        this.props.data.filter(
          (d, i) => d.id === this.props.userSubscription?.plan?.id
        );

      let freePlan =
        this.props.data.length &&
        this.props.data.filter((d, i) => d.tmc_group === "free");

      // For a user with no plan or free plan, show annual plan as the selected plan
      if (freePlan[0] || !currentPlan[0]) {
        selectedPlan =
          this.props.data.length &&
          this.props.data.filter((d, i) => d.id.includes("annual"));
      }
      this.setState(
        {
          selectedPlan: selectedPlan,
          currentPlan: currentPlan.length > 0 ? currentPlan : freePlan,
        },
        () => {
          this.setState({
            isPlanChanged:
              !this.state.currentPlan[0] ||
              selectedPlan[0].id !== this.state.currentPlan[0]?.id
                ? true
                : false,
          });
        }
      );

      apiHelpers
        .apiRequest(endpoint, {}, "POST", true, this.props.token)
        .then((res) => {
          let plansData = Object.keys(res.plans).map((item) => {
            this.setState({ currency: res.plans[item].currency });
            return {
              id: item,
              plan_name: res.plans[item].plan_name,
              // We don't have price in the data, so mapping price to amount
              price: parseInt(res.plans[item].amount) / 100,
            };
          });

          plansData.sort((planA, planB) => {
            let planAAmount = res.plans[planA.id]?.amount
              ? parseInt(res.plans[planA.id].amount)
              : null;
            let planBAmount = res.plans[planB.id]?.amount
              ? parseInt(res.plans[planB.id].amount)
              : null;

            if (planAAmount === null && planBAmount === null) {
              return 0;
            }

            if (planAAmount === null) {
              return -1;
            }

            if (planBAmount === null) {
              return -1;
            }

            if (planAAmount > planBAmount) {
              return 1;
            } else {
              return -1;
            }
          });

          // This part of code seems to be faulty where we are replacing
          // plan name and price with values from props.data

          // Uncommenting this block as we now need some data from the
          // default plan, only using this block if we have a trial plan

          for (var j = 0; j < plansData.length; j++) {
            for (var i = 0; i < this.props.data.length; i++) {
              if (
                plansData[j].id === this.props.data[i].id &&
                this.props.data[i].trial?.has_trial
              ) {
                plansData[j].trial = this.props.data[i].trial
                plansData[j].price = this.props.data[i].trial?.trial_price;
                plansData[j].plan_name =
                  plansData[j].plan_name +
                  "\n" +
                  "(" +
                  this.props.data[i].trial?.trial_period +
                  " days)";
                break;
              }
            }
          }

          let planList = plansData.filter(
            (d, i) =>
              !this.state.currentPlan[0] ||
              d.price >= this.state.currentPlan[0]?.price
          );
          let objPlan = {};
          for (let i = 0; i < plansData.length; i++) {
            objPlan[plansData[i].id] = plansData[i].price;
          }
          let featureList = Object.values(res.features);
          let planFeature = {
            display_name: "Subscription Price",
            id: featureList.length,
            identifer: "subscription-price",
            plans_map: objPlan,
          };
          featureList.unshift(planFeature);

          this.setState({
            features: featureList,
            plans: plansData,
            planList: planList,
          });
        })

        // let data = this.state.features.json()
        .catch((error) => {
          console.log("Error=>>", error);
        });
    });
  }

  /**
   * Select plan for subscription
   */
  selectPlanData = (params) => {
    let _dd = [];
    if (params.plan_name !== "Free") {
      _dd = this.props.data.filter((d, i) => d.id === params.id);
    } else {
      _dd = this.props.data.filter((d, i) => d.tmc_group === "free");
    }
    this.setState({
      selectedPlan: _dd,
      isPlanChanged:
        !this.state.currentPlan[0] ||
        params.id !== this.state.currentPlan[0]?.id
          ? true
          : false,
    });
  };

  cancelPlan = () => {
    this.props.navigateScreen("accounts/cancel-subscription", {
      plan:
        this.props.userSubscription &&
        this.props.userSubscription.is_cancelled == false
          ? true
          : false,
    });
  };

  isSubscriptionUnderDunning() {
    let { userSubscription } = this.props;
    if (userSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  /**
   * Generate Button Title
   */
  createButtonName = () => {
    let { darkMode, userSubscription, token, country, auth, subscribeToPlan } =
      this.props;
    let {
      features,
      plans,
      selectedPlan,
      isPlanChanged,
      currentPlan,
      planList,
      cb_handle,
    } = this.state;
    if (
      this.props.getCheckoutLoaderState() &&
      cb_handle?.instance?.siteConfig?.enableRedirectMode == true
    ) {
      return (
        <>
          <ActivityIndicator size={25} color={"#FFFFFF"} />
        </>
      );
    } else {
      if (currentPlan[0]?.id === selectedPlan[0]?.id)
        return this.isSubscriptionUnderDunning()
          ? "RENEW"
          : "YOUR CURRENT PLAN";
      else if (!userSubscription) {
        return "SUBSCRIBE";
      } else return "UPGRADE PLAN";
    }
  };

  goToSignUp = (email) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(
        "/sign-up?redirect=/pricing" + (email ? "&email=" + email : "")
      );
    } else {
      this.props.navigateScreen("/sign-up", {
        redirect: "/pricing",
        verifyRedirect: "/pricing",
        email: email,
      });
    }
  };
  render() {
    let {
      darkMode,
      userSubscription,
      token,
      country,
      auth,
      subscribeToPlan,
      sidePanel,
    } = this.props;

    let checkDesktop = sidePanel ? false : !helpers.isDesktop() ? true : false;

    let {
      features,
      plans,
      selectedPlan,
      isPlanChanged,
      currentPlan,
      planList,
    } = this.state;
    let isUserLoggedIn = auth.isAuthenticated;
    let btnClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: "/pricing",
      };
    } else {
      btnClick = {
        onPress: () => this.props.navigateScreen("/pricing"),
      };
    }

    const trialPlan = planList.filter((plan) => plan.trial?.has_trial)
   
    return features && planList?.length > 0 ? (
      <View
        style={[
          {
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: checkDesktop ? -20 : 12,
          },
          !darkMode && { backgroundColor: "#fff" },
          darkMode && { backgroundColor: "black" },
        ]}
      >
        {!isUserLoggedIn ? (
          <View
            style={{
              // borderWidth:5,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontFamily: FontFamily.abrilFatface,
                fontSize: 24,
                fontWeight: "700",
                textAlign: "center",
                color: darkMode ? "white" : "#051462",
                marginTop: helpers.isDesktop() ? 0 : 0,
                marginBottom: helpers.isDesktop() ? 0 : 30,
              }}
            >
              Pick the plan that’s right for you. Cancel or upgrade anytime
            </Text>
          </View>
        ) : null}
        <ScrollView
          style={{ width: "100%", flexDirection: "column" }}
          horizontal={true}
        >
          <View style={{ width: "100%", flexDirection: "column" }}>
            <View
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginLeft: 0,
                marginBottom: 30,
              }}
            >
              {/* <ScrollView horizontal={true} style={{ paddingBottom: 20 }}> */}
              {planList.map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => this.selectPlanData(item)}
                    // onPress={() => isUserLoggedIn && this.selectPlanData(item)}
                    style={{
                      width: `calc((100% - 20px) / ${planList.length})`,
                      minWidth: `85px`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginRight: 5,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          minHeight: 70,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          padding: 10,
                          backgroundColor:
                            selectedPlan[0]?.id === item.id
                              ? "#0f8d5c"
                              : "#49a27e",
                        }}
                      >
                        {/* {isUserLoggedIn ? (
                        <TouchableOpacity onPress={() => this.selectPlanData(item)}>
                          <CustomImage
                            source={
                              selectedPlan[0]?.id === item.id ||
                              selectedPlan[0]?.name?.replace(" Plan", "") ===
                                item.plan_name
                                ? groupelipsetick
                                : groupelipse
                            }
                            require={true}
                            style={{
                              width: 20,
                              height: 20,
                              resizeMode: "contain",
                              marginTop: 3,
                              marginBottom: 10,
                            }}
                            webStyle={{
                              width: 20,
                              height: 20,
                              objectFit: "contain",
                              marginBottom: 10,
                            }}
                          />
                        </TouchableOpacity>
                      ) : null} */}
                        <>
                          <Text
                            style={{
                              fontFamily: FontFamily.medium,
                              fontSize: 13,
                              fontWeight: "700",
                              textAlign: "center",
                              // color: darkMode ? "white" : "#051462",
                              color: "white",
                              textTransform: "uppercase",
                            }}
                          >
                            {item.plan_name}
                          </Text>
                          {/* {item.plan_name == "Free"
                            ? !userSubscription &&
                            !isUserLoggedIn && (
                              <Text
                                style={{
                                  fontFamily: FontFamily.medium,
                                  textAlign: "center",
                                  color: "#ffffff",
                                  fontSize: 8,
                                  textTransform: "capitalize",
                                }}
                              >
                                CREDIT CARD {"\n"} NOT REQUIRED
                              </Text>
                            )
                            : null} */}
                          {currentPlan[0]?.id === item.id && isUserLoggedIn && (
                            <Text
                              style={{
                                fontFamily: FontFamily.medium,
                                textAlign: "center",
                                color: "#ffffff",
                                fontSize: 8,
                                textTransform: "uppercase",
                              }}
                            >
                              Current Plan
                            </Text>
                          )}
                        </>
                      </View>

                      {selectedPlan[0]?.id === item.id ? (
                        <View
                          style={{
                            position: "absolute",
                            bottom: "-9px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderTopWidth: "10px",
                            borderRightWidth: "10px",
                            borderBottomWidth: "0",
                            borderLeftWidth: "10px",
                            borderTopColor: "#0f8d5c",
                            borderRightColor: "transparent",
                            borderBottomColor: "transparent",
                            borderLeftColor: "transparent",
                            marginTop: "-1px",
                          }}
                        ></View>
                      ) : null}
                    </View>
                  </TouchableOpacity>
                );
              })}
              {/* </ScrollView> */}
            </View>
            <View style={{ width: "100%", marginLeft: 0 }}>
              {console.log("features:", features)}
              {features.map((item, index) => {
                let val = JSON.stringify(item?.plans_map);
                // convert back string to object
                let newVal = JSON.parse(val);
                return (
                  <>
                    <View
                      style={{
                        marginVertical: 3,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "PlayfairDisplay-Bold",
                          fontSize: 16,
                          lineHeight: 17,
                          fontWeight: 600,
                          color: darkMode ? "white" : "#000000",
                          marginBottom: 4,
                          marginTop: 2,
                          textAlign: "center",
                        }}
                      >
                        {item.display_name}
                      </Text>
                    </View>

                    <View
                      style={[
                        {
                          width: "100%",
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        },
                      ]}
                    >
                      {/* <ScrollView horizontal={true}> */}
                      {planList.map((plan) => {
                        return (
                          <View
                            style={{
                              // width: 100 / planList.length + "%",
                              display: "flex",
                              width: `calc((100% - 20px) / ${planList.length})`,
                              borderBottom: "1px solid #d9d9d9",
                              minWidth: `90px`,
                              height: 15,
                              justifyContent: "center",
                              alignItems: "center",
                              // paddingRight: 5,
                              marginTop: 10,
                              paddingBottom: 15,
                            }}
                          >
                            {
                              newVal[plan.id] ? (
                                // newVal[plan.id] != true ? (
                                <Text
                                  style={{
                                    fontFamily: FontFamily.regular,
                                    textAlign: "center",
                                    color: darkMode
                                      ? "white"
                                      : selectedPlan[0]?.id === plan.id
                                      ? "#0f8d5c"
                                      : "#000000",
                                    fontSize: 14,
                                    fontWeight:
                                      selectedPlan[0]?.id === plan.id
                                        ? 800
                                        : 400,
                                    // lineHeight: 17,
                                    letterSpacing: 1.03,
                                    marginTop: !userSubscription
                                      ? isUserLoggedIn
                                        ? "0px"
                                        : "0px"
                                      : "0px",
                                  }}
                                >
                                  {newVal[plan.id] == true
                                    ? "Yes"
                                    : typeof newVal[plan.id] == "number"
                                    ? this.state.currency == "INR"
                                      ? `₹${newVal[plan.id]}`
                                      : `$${newVal[plan.id]}`
                                    : newVal[plan.id]}
                                </Text>
                              ) : (
                                // ) : null
                                // (
                                //   <CustomImage
                                //     source={checkIcon}
                                //     require={true}
                                //     style={{
                                //       width: 16,
                                //       height: 14,
                                //       resizeMode: "contain",
                                //       marginTop: 3,
                                //     }}
                                //     webStyle={{
                                //       width: 21,
                                //       height: 14,
                                //       objectFit: "contain",
                                //       marginTop: !userSubscription
                                //         ? isUserLoggedIn
                                //           ? "0px"
                                //           : "0px"
                                //         : "0px",
                                //     }}
                                //   />
                                // )
                                // newVal[plan.id] !== false ? (
                                <Text
                                  style={{
                                    fontFamily: FontFamily.Matteo,
                                    textAlign: "center",
                                    color: darkMode
                                      ? "white"
                                      : selectedPlan[0]?.id === plan.id
                                      ? "#0f8d5c"
                                      : "#051462",
                                    fontSize: 12,
                                    fontWeight:
                                      selectedPlan[0]?.id === plan.id
                                        ? 800
                                        : 400,
                                    // lineHeight: 17,
                                    letterSpacing: 1.03,
                                    marginTop: !userSubscription
                                      ? isUserLoggedIn
                                        ? "0px"
                                        : "0px"
                                      : "0px",
                                  }}
                                >
                                  {newVal[plan.id] === false
                                    ? "No"
                                    : newVal[plan.id]}
                                </Text>
                              )
                              // ) :
                              // (
                              //   <CustomImage
                              //     source={closeicon}
                              //     require={true}
                              //     style={{
                              //       width: 14,
                              //       height: 14,
                              //       resizeMode: "contain",
                              //       marginTop: 3,
                              //     }}
                              //     webStyle={{
                              //       width: 14,
                              //       height: 14,
                              //       objectFit: "contain",
                              //       marginTop: !userSubscription
                              //         ? isUserLoggedIn
                              //           ? "0px"
                              //           : "0px"
                              //         : "0px",
                              //     }}
                              //   />
                              // )
                            }
                          </View>
                        );
                      })}
                      {/* </ScrollView> */}
                    </View>
                  </>
                );
              })}
            </View>
          </View>
        </ScrollView>
        {/* <View style={{ width: "94%", flexDirection: "row", marginLeft: 0 }}>
          <View style={{ width: "25%" }}>
            <View style={{ padding: 10, height: 90 }}></View>

            {features.map((item, index) => {
              return (
                <View
                  style={{
                    height: 92,
                    marginVertical: 3,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontSize: 14,
                      lineHeight: 17,
                      color: darkMode ? "white" : "#051462",
                      marginBottom: 4,
                      marginTop: 2,
                    }}
                  >
                    {item.display_name}
                  </Text>
                </View>
              );
            })}
          </View>

          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              marginEnd: 100,
              width: "100%",
            }}
          >
            <View style={{ width: "100%" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  marginTop: "14%",
                  // height: 92,
                  // marginVertical: 3,
                  // justifyContent: "center",
                }}
              >
                {planList.map((item, index) => {
                  return (
                    <View
                      style={{
                        // width: 100 / planList.length + "%",
                        width: 80,
                        //   justifyContent: "center",
                        alignItems: "center",
                        //   padding: 10,
                        flexDirection: "column",
                        // marginLeft: 10, marginRight: 10
                        //   height: 90,
                      }}
                    >
                      {isUserLoggedIn ? (
                        <TouchableOpacity
                          onPress={() => this.selectPlanData(item)}
                        >
                          <CustomImage
                            source={
                              selectedPlan[0]?.id === item.id ||
                                selectedPlan[0]?.name?.replace(" Plan", "") ===
                                item.plan_name
                                ? groupelipsetick
                                : groupelipse
                            }
                            require={true}
                            style={{
                              width: 20,
                              height: 20,
                              resizeMode: "contain",
                              marginTop: 3,
                              marginBottom: 10,
                            }}
                            webStyle={{
                              width: 20,
                              height: 20,
                              objectFit: "contain",
                              marginBottom: 10,
                              // marginTop: !userSubscription && !token ? 20 : 58,
                            }}
                          />
                        </TouchableOpacity>
                      ) : null}
                      <>
                        <Text
                          style={{
                            fontFamily: FontFamily.medium,
                            textAlign: "center",
                            color: darkMode ? "white" : "#051462",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.plan_name}
                        </Text>
                        {item.plan_name == "Free"
                          ? !userSubscription &&
                          !isUserLoggedIn && (
                            <Text
                              style={{
                                fontFamily: FontFamily.medium,
                                textAlign: "center",
                                color: "#FC876D",
                                fontSize: 8,
                                textTransform: "uppercase",
                              }}
                            >
                              CREDIT CARD {"\n"} NOT REQUIRED
                            </Text>
                          )
                          : null}
                        {currentPlan[0].name.replace(" Plan", "") ===
                          item.plan_name &&
                          isUserLoggedIn && (
                            <Text
                              style={{
                                fontFamily: FontFamily.medium,
                                textAlign: "center",
                                color: "#907CFF",
                                fontSize: 8,
                                textTransform: "uppercase",
                              }}
                            >
                              Current Plan
                            </Text>
                          )}
                      </>
                    </View>
                  );
                })}
              </View>

              {features.map((item, index) => {
                // convert object to string as resposne has '-' in it
                let val = JSON.stringify(item?.plans_map);
                // convert back string to object
                let newVal = JSON.parse(val);

                return (
                  <>
                    <View
                      style={[
                        {
                          width: "100%",
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        },
                      ]}
                    >
                      {planList.map((plan) => {
                        return (
                          <View
                            style={{
                              height: 92,
                              // width: 100 / planList.length + "%",
                              width: 80,
                              justifyContent: "center",
                              alignItems: "center",
                              marginVertical: 3,
                            }}
                          >
                            {newVal[plan.id] ? (
                              newVal[plan.id] == "Limited" ? (
                                <Text
                                  style={{
                                    fontFamily: FontFamily.Matteo,
                                    textAlign: "center",
                                    color: darkMode ? "white" : "#051462",
                                    fontSize: 10,
                                    // fontWeight: 400,
                                    // lineHeight: 17,
                                    letterSpacing: 0.03,
                                    marginTop: !userSubscription
                                      ? isUserLoggedIn
                                        ? "-10px"
                                        : "40px"
                                      : "0px",
                                  }}
                                >
                                  Limited
                                </Text>
                              ) : (
                                <CustomImage
                                  source={checkIcon}
                                  require={true}
                                  style={{
                                    width: 20,
                                    height: 14,
                                    resizeMode: "contain",
                                    marginTop: 3,
                                  }}
                                  webStyle={{
                                    width: 20,
                                    height: 14,
                                    objectFit: "contain",
                                    marginTop: !userSubscription
                                      ? isUserLoggedIn
                                        ? "-10px"
                                        : "40px"
                                      : "0px",
                                  }}
                                />
                              )
                            ) : null}
                          </View>
                        );
                      })}
                    </View>
                  </>
                );
              })}
            </View>
          </ScrollView>
        </View> */}

        {this.props.showIosMessage && (
          <View
            style={[
              {
                backgroundColor: darkMode ? Colors.darkBlackColor1 : "#FFFAEB",
                paddingHorizontal: 25,
                paddingTop: 15,
                minHeight: 100,
                marginTop: 20,
              },
              // !userSubscription &&
              //   !teamSubscription && { marginBottom: 20 },
              // (userSubscription || teamSubscription) && {
              //   marginBottom: -15,
              // },
            ]}
          >
            <Text
              style={{
                fontSize: 16,
                lineHeight: 27,
                letterSpacing: 0.3,
                color: darkMode ? Colors.lightWhite : "#2D2D2D",
                fontFamily: FontFamily.merriweather,
                textAlign: "center",
              }}
            >
              You can’t upgrade in the app. We know it’s a hassle.
            </Text>
          </View>
        )}
        {!this.props.token && !isUserLoggedIn ? (
          <View style={{ marginTop: 30, marginBottom: 30, width: "75%" }}>
            <ButtonGradient
              title={"SUBSCRIBE"}
              onClick={() => {
                subscribeToPlan(selectedPlan[0]);
                // this.props.navigateScreen("/sign-up?redirect=/pricing");
              }}
              rootStyle={{
                btnWrapper: [
                  button.primaryGradientBtn,
                  {
                    height: 46,
                    borderRadius: 0,
                    flex: 1,
                    position: "relative",
                    right: helpers.isDesktop() ? 1.5 : 0,
                  },
                ],
                btnText: [
                  button.primaryGradientText,
                  {
                    fontSize: helpers.isDesktop() ? 17 : 16,
                    fontWeight: "600",
                    paddingLeft: 35,
                    paddingRight: 35,
                    paddingTop: helpers.isDesktop() ? 0 : 15,
                    paddingBottom: helpers.isDesktop() ? 0 : 15,
                  },
                ],
              }}
              loader={false}
              disable={false}
              darkMode={darkMode}
              isNewGradiant={true}
            />
          </View>
        ) : (
          <View style={{ marginTop: 40, marginBottom: 40, width: "100%" }}>
            {checkDesktop ||
            currentPlan[0]?.id !== selectedPlan[0]?.id ||
            !userSubscription ? (
              <>
                <ButtonGradient
                  title={this.createButtonName()}
                  onClick={() => {
                    // this.setState({ isButtonClicked: true });
                    // this.props.setCheckoutLoaderState(true)
                    if (currentPlan[0]?.id === selectedPlan[0]?.id) {
                      if (this.isSubscriptionUnderDunning()) {
                        ChargebeeController.activatedController().then(
                          (cb_handle) => {
                            cb_handle.hosted_page.collect_now();
                          }
                        );
                      }
                    } else {
                      subscribeToPlan(selectedPlan[0]);
                    }
                  }}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradientBtn,
                      {
                        height: 46,
                        borderRadius: 0,
                        flex: 1,
                        position: "relative",
                        right: helpers.isDesktop() ? 1.5 : 0,
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        fontWeight: "600",
                        paddingLeft: 35,
                        paddingRight: 35,
                        paddingTop: helpers.isDesktop() ? 0 : 15,
                        paddingBottom: helpers.isDesktop() ? 0 : 15,
                      },
                    ],
                  }}
                  loader={false}
                  disable={
                    this.isSubscriptionUnderDunning() ? false : !isPlanChanged
                  }
                  darkMode={darkMode}
                  isNewGradiant={true}
                />

                {/* <Button
                  title={this.createButtonName()}
                  onClick={() => {
                    subscribeToPlan(selectedPlan[0]);
                  }}
                  disable={!isPlanChanged}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradient,
                      {
                        backgroundColor: "#ffffff",
                        borderWidth: 1,
                        borderColor: "#907CFF",
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      button.primaryGradientTextViewlogout,
                      { fontWeight: "600", fontSize: 17 },
                    ],
                  }}
                  loader={false}
                  darkMode={darkMode}
                /> */}
              </>
            ) : userSubscription?.end_date &&
              currentPlan[0]?.id === selectedPlan[0]?.id ? (
              <View
                style={{
                  lineHeight: 5,
                }}
              >
                <ButtonGradient
                  title={this.createButtonName()}
                  onClick={() => {
                    // this.setState({ isButtonClicked: true });
                    this.props.setCheckoutLoaderState(true);
                    if (this.isSubscriptionUnderDunning()) {
                      ChargebeeController.activatedController().then(
                        (cb_handle) => {
                          cb_handle.hosted_page.collect_now();
                        }
                      );
                    } else {
                      subscribeToPlan(selectedPlan[0]);
                    }
                  }}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradientBtn,
                      {
                        height: 46,
                        borderRadius: 0,
                        flex: 1,
                        position: "relative",
                        right: helpers.isDesktop() ? 1.5 : 0,
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        fontWeight: "600",
                        paddingLeft: 35,
                        paddingRight: 35,
                        paddingTop: helpers.isDesktop() ? 0 : 15,
                        paddingBottom: helpers.isDesktop() ? 0 : 15,
                      },
                    ],
                  }}
                  loader={false}
                  disable={
                    this.isSubscriptionUnderDunning() ? false : !isPlanChanged
                  }
                  darkMode={darkMode}
                  isNewGradiant={true}
                />
                <Text
                  style={{
                    color: darkMode ? "#FFF" : "#000",
                    fontWeight: 500,
                    fontFamily: "Matteo-Medium",
                    textAlign: "center",
                  }}
                >
                  {this.isSubscriptionUnderDunning() &&
                  userSubscription?.due_since !== undefined
                    ? `Your plan expired on ${moment(
                        userSubscription.due_since
                      ).format(
                        "Do MMMM YYYY"
                      )}. Renew to continue subscription.`
                    : `Your current plan will expire on ${moment(
                        userSubscription.end_date
                      ).format("Do MMMM YYYY")}`}
                </Text>

                <TouchableOpacity
                  style={{
                    lineHeight: 5,
                    paddingTop: 13,
                    paddingBottom: 13,
                    paddingLeft: 45,
                    paddingRight: 45,
                    textDecorationLine: "underline",
                    color: darkMode ? "#FFF" : "#999999",
                    fontWeight: 500,
                    fontFamily: "Matteo-Medium",
                    cursor: "pointer",
                  }}
                  onPress={() => this.cancelPlan()}
                >
                  <Text
                    style={{
                      color: darkMode ? "#FFF" : "#999999",
                      fontWeight: 500,
                      fontFamily: "Matteo-Medium",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    Cancel Subscription
                  </Text>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
        )}
        {!isUserLoggedIn || trialPlan || currentPlan[0]?.name === "Free" ? (
          <View>
            <View
              style={{
                // paddingLeft: 14,
              }}
            >
              {trialPlan?.length > 0 && (
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                <CustomImage
                  source={infopng}
                  require={true}
                  style={{
                    width: 16,
                    height: 14,
                    resizeMode: "contain",
                    marginTop: 3,
                  }}
                  webStyle={{
                    width: 21,
                    height: 14,
                    objectFit: "contain",
                    paddingRight: 4,
                    marginTop: 3,
                    // marginTop: !userSubscription
                    //   ? isUserLoggedIn
                    //     ? "0px"
                    //     : "0px"
                    //   : "0px",
                  }}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "PlayfairDisplay-Bold",
                    lineHeight: 17,
                    fontWeight: 600,
                    color: darkMode ? "white" : "#000000",
                    marginBottom: 4,
                    marginTop: 2,
                    // paddingRight: 10
                  }}
                >
                After the 30 days trial, renews automatically at $9.99/month. 
                </Text>
                </View>
              )}
              
            </View>
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <CustomImage
                source={infopng}
                require={true}
                style={{
                  width: 16,
                  height: 14,
                  resizeMode: "contain",
                  marginTop: 3,
                }}
                webStyle={{
                  width: 21,
                  height: 14,
                  objectFit: "contain",
                  paddingRight: 4,
                  // marginTop: !userSubscription
                  //   ? isUserLoggedIn
                  //     ? "0px"
                  //     : "0px"
                  //   : "0px",
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: "PlayfairDisplay-Bold",
                  lineHeight: 17,
                  fontWeight: 600,
                  color: darkMode ? "white" : "#000000",
                  marginBottom: 4,
                  marginTop: "-2px",
                }}
              >
                All payments are secure and we do not store your card details.
                Auto-renewals can be easily turned off by accessing your
                account.
              </Text>
            </View>
          </View>
        ) : null}
      </View>
    ) : null;
  }
}

export default SubscriptionApiCall;
