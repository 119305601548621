import React, { Component } from 'react';
import { ActivityIndicator } from 'react-native';
import { Colors } from '../../styles/appStyles';
import {
    Menu,
    MenuItem,
    MenuButton
} from '@szhsin/react-menu';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';

const dots = getImagePath('icons/dark-dots.png');

class MenuPopup extends Component {
    render() {
        const { showInvite, resendInvitation, removeMember, darkMode} = this.props;
        return (
            showInvite ?
                <Menu menuButton={
                    <MenuButton styles={{backgroundColor: darkMode ? Colors.darkBlackColorBg : '#FFF'}}>
                        <CustomImage
                            source={dots}
                            require={true}
                            style={{ width: 'auto', height: 24, opacity: 0.6, paddingLeft: 0, paddingRight: 0, paddingTop: 3, paddingBottom: 3 }}
                            webStyle={{ width: 'auto', height: 24, opacity: 0.6, paddingLeft: 1, paddingRight: 1, paddingTop: 3, paddingBottom: 3 }}
                        />
                    </MenuButton>
                    }>
                    <MenuItem styles={{backgroundColor: darkMode ? Colors.darkBlackColor1 : '#FFF', color: darkMode ? Colors.lightWhite : Colors.black }} onClick={() => resendInvitation()}>Resend Invite</MenuItem>
                    <MenuItem styles={{backgroundColor: darkMode ? Colors.darkBlackColor1 : '#FFF', color: darkMode ? Colors.lightWhite : Colors.black }} onClick={() => removeMember()}>Remove</MenuItem>
                </Menu>
                :
                <Menu menuButton={
                    <MenuButton  styles={{backgroundColor: darkMode ? '#121212' : '#FFF'}}>
                        <CustomImage
                            source={dots}
                            require={true}
                            style={{ width: 'auto', height: 24, opacity: 0.6, paddingLeft: 0, paddingRight: 0, paddingTop: 3, paddingBottom: 3 }}
                            webStyle={{ width: 'auto', height: 24, opacity: 0.6, paddingLeft: 1, paddingRight: 1, paddingTop: 3, paddingBottom: 3 }}
                        />
                    </MenuButton>
                    }>
                    <MenuItem styles={{backgroundColor: darkMode ? Colors.darkBlackColor1 : '#FFF', color: darkMode ? Colors.lightWhite : Colors.black }} onClick={() => removeMember()}>Remove</MenuItem>
                </Menu>
        );
    }
}
 
export default MenuPopup;