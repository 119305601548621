import { createStore, combineReducers } from 'redux';
import AppReducer from './App/app.reducer';
import UserReducer from './User/user.reducer';

const rootReducer = combineReducers({
    app: AppReducer,
    user: UserReducer
})

const configureStore = () => createStore(rootReducer);

export default configureStore;