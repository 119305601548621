import { withAuth0, useAuth0 } from "@auth0/auth0-react";
  import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";
import Loader from "../loader/index.js";
import { helpers } from "../../utils/helpers";

function AuthenticationWrapper(WrappedComponent) {
  const Authentication = withAuth0(
    class Auth extends React.Component {
      constructor(props) {
        super(props);
      }

      componentDidMount() {}

      componentDidUpdate(prevProps, prevState, snapshot) {
        const isTrackingStarted =
          window.localStorage.getItem("tracking_started");

        if (
          !isTrackingStarted &&
          prevProps.auth0.isAuthenticated == false &&
          this.props.auth0.isAuthenticated == true
        ) {
          this.timeout = setTimeout(() => {
            /**
             * Commented below line temporarily
             */
            // this.trackUserLogin();
          }, 10000);
        }
      }

      componentWillUnmount() {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      }

      trackUserLogin = async () => {
        try {
          const { getIdTokenClaims } = this.props.auth0;

          const idToken = await getIdTokenClaims();

          let method = idToken["sub"].split("|")[0];
          let loginCount =
            idToken["https://themorningcontext.com"]["userInfo"]["stats"][
              "loginsCount"
            ];
          let { id, full_name } =
            idToken["https://themorningcontext.com"]["userInfo"];
          let email = idToken["email"];

          window.webengage.user.login(id);
          // console.log("system login", id);

          window.localStorage.setItem("tracking_started", true);

          window.webengage.user.setAttribute(
            "we_first_name",
            full_name.split(" ")[0]
          );
          if (full_name.split(" ")[1]) {
            window.webengage.user.setAttribute(
              "we_last_name",
              full_name.split(" ")[1]
            );
          }
          window.webengage.user.setAttribute("we_email", email);
          // console.log(
          //   "we_first_name",
          //   full_name.split(" ")[0],
          //   "we_last_name",
          //   full_name.split(" ")[1],
          //   "we_email",
          //   email
          // );

          if (loginCount == 1) {
            window.webengage.track("Sign up", {
              Method: method,
            });
            // console.log("track ", "Sign up", {
            //   Method: method,
            // });
          } else {
            window.webengage.track("Login", {
              Method: method,
            });
            // console.log("track ", "Login", {
            //   Method: method,
            // });
          }
        } catch (error) {
          console.log("error ==>", error);
        }
      };

      setAuthToken = async () => {
        try {
          const {
            isAuthenticated,
            getAccessTokenSilently,
            getIdTokenClaims,
            isLoading,
          } = this.props.auth0;
          const accessToken = await getAccessTokenSilently();
          const idToken = await getIdTokenClaims();
          const token = {
            accessToken: accessToken,
            idToken: idToken,
          };
          return token;
        } catch (err) {
          console.log("auth err: ", err);
        }
      };

      webLogout = (logoutData = {}) => {
        const { logout } = this.props.auth0;
        if (window.chargebeeInstance) {
          try {
            window.chargebeeInstance.logout();
          } catch (err) {
            console.log("chargebee:portalSession:logout:error:", err);
          }
        }
        try {
          window.webengage?.user?.logout();
          window.localStorage.removeItem("tracking_started");
          console.log("webengage logout");
        } catch (error) {}

        logout({
          ...logoutData,
          localOnly: true
        })
        this.props.history.push('/logout')
      };

      legacyLogout = () => {
        const auth0LogoutUrl = `https://${process.env.LEGACY_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}&client_id=${process.env.LEGACY_AUTH0_CLIENT_ID}`
        window.location.assign(auth0LogoutUrl)
      }

      _webAuth = () => {
        const { isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims } =
          this.props.auth0;

        if (isLoading) {
          return (
            <View
              style={{
                flexDirection: "row",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                width: "100%",
              }}
            >
              <Loader title={"We are almost there ..."} />
            </View>
          );
        }

        const auth = {
          isAuthenticated: isAuthenticated,
          fetchToken: isAuthenticated ? this.setAuthToken : false,
          login: loginWithRedirect,
          logout: this.webLogout,
          getIdTokenClaims: getIdTokenClaims,
          auth0Logout: this.legacyLogout,
          socialConnectionURL: (provider, type) => {
            const oauth2Hostname = new URL(`https://${process.env.OATH2_ISSUER}`).hostname
            if(type == 'connect'){
              return `https://${oauth2Hostname}/accounts/${provider}/login/?process=connect`
            }
            return `https://${oauth2Hostname}/accounts/social/connections`
          }
        };

        const { forwardedRef, ...rest } = this.props;
        return <WrappedComponent ref={forwardedRef} {...rest} auth={auth} />;
      };

      _nativeAuth = () => {
        const { forwardedRef, ...rest } = this.props;
        const auth = {
          isAuthenticated: false,
          fetchToken: null,
        };
        return <WrappedComponent ref={forwardedRef} {...rest} auth={auth} />;
      };

      render() {
        if (helpers.isWeb()) {
          return this._webAuth();
        }
        return this._nativeAuth();
      }
    }
  );

  return React.forwardRef((props, ref) => {
    return <Authentication {...props} forwardedRef={ref} />;
  });
}

export default AuthenticationWrapper;
