import getImagePath from "./image-helper";

const teamSeoImage = getImagePath("img/extras/team-seo.png");
const studentSeoImage = getImagePath("img/extras/student-seo.png");
const bookmarksSeoImage = getImagePath("img/extras/app-seo.png");
const allStoriesSeoImage = getImagePath("img/extras/app-seo.png");
const freeReadsSeoImage = getImagePath("img/extras/app-seo.png");
const unlockedStoriesSeoImage = getImagePath("img/extras/app-seo.png");
const aboutUsSeoImage = getImagePath("img/extras/app-seo.png");
const investorsSeoImage = getImagePath("img/extras/app-seo.png");
const leadershipSeoImage = getImagePath("img/extras/app-seo.png");
const teamPageSeoImage = getImagePath("img/extras/app-seo.png");
const editorialSeoImage = getImagePath("img/extras/app-seo.png");
const subscriptionSeoImage = getImagePath("img/extras/app-seo.png");
const starterCollectionSeoImage = getImagePath("img/extras/app-seo.png");
const premiumCollectionSeoImage = getImagePath("img/extras/app-seo.png");
const myTeamSeoImage = getImagePath("img/extras/app-seo.png");
const homeSeoImage = getImagePath("img/extras/app-seo.png");
const yesterdaySeoImage = getImagePath("img/extras/yesterdayBG.png");
const he_parser = require("he");

const seoObj = {
  "team-subscription": {
    title: "Team Subscription | Corporate Plans | The Morning Context",
    description:
      "Give your team the knowledge of independent, deeply researched and well-written stories with our unique team subscription plans and get access to exclusive events.",
    keywords:
      "corporate subscription, team subscription, privilege credits plan, seat plan",
    og: {
      title: "Team Subscription | Corporate Plans | The Morning Context",
      url: "/",
      description:
        "Give your team the knowledge of independent, deeply researched and well-written stories with our unique team subscription plans and get access to exclusive events.",
      image: teamSeoImage,
    },
    twitter: {
      title: "Team Subscription | Corporate Plans | The Morning Context",
      description:
        "Give your team the knowledge of independent, deeply researched and well-written stories with our unique team subscription plans and get access to exclusive events.",
      image: teamSeoImage,
    },
  },

  "student-subscription": {
    title: "Student Subscription | Special Offer | The Morning Context",
    description:
      "Subscribe here to access our best stories and join the exclusive student community of The Morning Context. Get up to 50% off on our normal subscription plans here.",
    keywords: "student subscription, price, subscription plan",
    og: {
      title: "Student Subscription | Special Offer | The Morning Context",
      url: "/",
      description:
        "Subscribe here to access our best stories and join the exclusive student community of The Morning Context. Get up to 50% off on our normal subscription plans here.",
      image: studentSeoImage,
    },
    twitter: {
      title: "Student Subscription | Special Offer | The Morning Context",
      description:
        "Subscribe here to access our best stories and join the exclusive student community of The Morning Context. Get up to 50% off on our normal subscription plans here.",
      image: studentSeoImage,
    },
  },

  bookmarks: {
    title: "Bookmarks | Stories You Have Saved | The Morning Context",
    description:
      "Curate your own reading list with in-depth and timely stories on technology, business, and chaos. Subscribe to The Morning Context for access to latest stories.",
    keywords: "Bookmarks, Stories you have saved ",
    og: {
      title: "Bookmarks | Stories You Have Saved | The Morning Context",
      url: "/",
      description:
        "Curate your own reading list with in-depth and timely stories on technology, business, and chaos. Subscribe to The Morning Context for access to latest stories.",
      image: bookmarksSeoImage,
    },
    twitter: {
      title: "Bookmarks | Stories You Have Saved | The Morning Context",
      description:
        "Curate your own reading list with in-depth and timely stories on technology, business, and chaos. Subscribe to The Morning Context for access to latest stories.",
      image: bookmarksSeoImage,
    },
  },

  "archive-all": {
    title: "All Stories | Peruse Our Archives | The Morning Context",
    description:
      "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up, subscribe and only read stories that matter.",
    keywords: "Internet, Business, Chaos, newsletters, Archives",
    og: {
      title: "All Stories | Peruse Our Archives | The Morning Context",
      url: "/",
      description:
        "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up, subscribe and only read stories that matter.",
      image: allStoriesSeoImage,
    },
    twitter: {
      title: "All Stories | Peruse Our Archives | The Morning Context",
      description:
        "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up, subscribe and only read stories that matter.",
      image: allStoriesSeoImage,
    },
  },

  "free-reads": {
    title: "Free Reads | No Subscription Needed | The Morning Context",
    description:
      "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up and read our rich archive of free stories.",
    keywords:
      "Free stories, archives, newsletters, tech coverage, public markets",
    og: {
      title: "Free Reads | No Subscription Needed | The Morning Context",
      url: "/",
      description:
        "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up and read our rich archive of free stories.",
      image: freeReadsSeoImage,
    },
    twitter: {
      title: "Free Reads | No Subscription Needed | The Morning Context",
      description:
        "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up and read our rich archive of free stories.",
      image: freeReadsSeoImage,
    },
  },

  "unlocked-stories": {
    title: "Unlocked Stories | All Your Reads | The Morning Context",
    description:
      "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up and read our rich archive of free stories.",
    keywords: "Free reads, free stories, Archives, newsletters, tech coverage",
    og: {
      title: "Unlocked Stories | All Your Reads | The Morning Context",
      url: "/",
      description:
        "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up and read our rich archive of free stories.",
      image: unlockedStoriesSeoImage,
    },
    twitter: {
      title: "Unlocked Stories | All Your Reads | The Morning Context",
      description:
        "The Morning Context tells the most ambitious, timely, and in-depth stories on technology, business and chaos. Sign up and read our rich archive of free stories.",
      image: unlockedStoriesSeoImage,
    },
  },

  about: {
    title: "About Us | The Morning Context | Business And Tech In India",
    description:
      "The Morning Context is a research and media company, which only tells stories that matter. On the internet, business and chaos. Paid for primarily by readers.",
    keywords:
      "founders, founding team, Priya Bubna, Harveen Ahluwalia, Pranav Srivilasan, Pradip Saha, Ashish K Mishra",
    og: {
      title: "About Us | The Morning Context | Business And Tech In India",
      url: "/",
      description:
        "The Morning Context is a research and media company, which only tells stories that matter. On the internet, business and chaos. Paid for primarily by readers.",
      image: aboutUsSeoImage,
    },
    twitter: {
      title: "About Us | The Morning Context | Business And Tech In India",
      description:
        "The Morning Context is a research and media company, which only tells stories that matter. On the internet, business and chaos. Paid for primarily by readers.",
      image: aboutUsSeoImage,
    },
  },

  investors: {
    title: "Investors | Meet The People Backing Us | The Morning Context",
    description:
      "Our investors include several noteworthy individuals and institutions, who are helping us build a subscription-led digital media company. Meet the investors here.",
    keywords:
      "Investors, Google News Initiative, Joji Philip Thomas, Kapil Chopra, Jacob Puthenparambil, Parag Dhol",
    og: {
      title: "Investors | Meet The People Backing Us | The Morning Context",
      url: "/",
      description:
        "Our investors include several noteworthy individuals and institutions, who are helping us build a subscription-led digital media company. Meet the investors here.",
      image: investorsSeoImage,
    },
    twitter: {
      title: "Investors | Meet The People Backing Us | The Morning Context",
      description:
        "Our investors include several noteworthy individuals and institutions, who are helping us build a subscription-led digital media company. Meet the investors here.",
      image: investorsSeoImage,
    },
  },

  leadership: {
    title: "Leadership | Our Board Of Directors | The Morning Context",
    description:
      "Priya Bubna is the chief executive officer and promoter of Slowform Holdings Ltd, the publisher of The Morning Context. Meet The Morning Context's management team here.",
    keywords:
      "CEO, board of directors, editor-in-chief, Ashish K. Mishra, Priya Bubna",
    og: {
      title: "Leadership | Our Board Of Directors | The Morning Context",
      url: "/",
      description:
        "Priya Bubna is the chief executive officer and promoter of Slowform Holdings Ltd, the publisher of The Morning Context. Meet The Morning Context's management team here.",
      image: leadershipSeoImage,
    },
    twitter: {
      title: "Leadership | Our Board Of Directors | The Morning Context",
      description:
        "Priya Bubna is the chief executive officer and promoter of Slowform Holdings Ltd, the publisher of The Morning Context. Meet The Morning Context's management team here.",
      image: leadershipSeoImage,
    },
  },

  team: {
    title: "Team | Meet Our Writers And Staff | The Morning Context",
    description:
      "We work with some of the best writers and subject experts, both in-house and external, who only tell stories that matter on technology, business and chaos.",
    keywords:
      "writers, journalists, Arti Singh, Akshay Deshmane, Jayshree P. Upadhyay, Harveen Ahluwalia, Roshni P. Nair, T Surendar",
    og: {
      title: "Team | Meet Our Writers And Staff | The Morning Context",
      url: "/",
      description:
        "We work with some of the best writers and subject experts, both in-house and external, who only tell stories that matter on technology, business and chaos.",
      image: teamPageSeoImage,
    },
    twitter: {
      title: "Team | Meet Our Writers And Staff | The Morning Context",
      description:
        "We work with some of the best writers and subject experts, both in-house and external, who only tell stories that matter on technology, business and chaos.",
      image: teamPageSeoImage,
    },
  },

  "editorial-code-of-conduct": {
    title: "Editorial Code | Writer Guidelines | The Morning Context",
    description:
      "To ensure accuracy, integrity and credibility across The Morning Context newsroom, we hold ourselves accountable to certain standards and journalistic practices.",
    keywords: "Himanshu Bagai, editorial code, code of conduct",
    og: {
      title: "Editorial Code | Writer Guidelines | The Morning Context",
      url: "/",
      description:
        "To ensure accuracy, integrity and credibility across The Morning Context newsroom, we hold ourselves accountable to certain standards and journalistic practices.",
      image: editorialSeoImage,
    },
    twitter: {
      title: "Editorial Code | Writer Guidelines | The Morning Context",
      description:
        "To ensure accuracy, integrity and credibility across The Morning Context newsroom, we hold ourselves accountable to certain standards and journalistic practices.",
      image: editorialSeoImage,
    },
  },

  subscriptions: {
    title: "Pricing | Subscriptions And Credits | The Morning Context",
    description:
      "Choose your most-suited subscription plan or pay only for what you want to read. Become a TMC subscriber and start reading stories on tech, business and chaos.",
    keywords: "subscription, subscription plans, pricing, price",
    og: {
      title: "Pricing | Subscriptions And Credits | The Morning Context",
      url: "/",
      description:
        "Choose your most-suited subscription plan or pay only for what you want to read. Become a TMC subscriber and start reading stories on tech, business and chaos.",
      image: subscriptionSeoImage,
    },
    twitter: {
      title: "Pricing | Subscriptions And Credits | The Morning Context",
      description:
        "Choose your most-suited subscription plan or pay only for what you want to read. Become a TMC subscriber and start reading stories on tech, business and chaos.",
      image: subscriptionSeoImage,
    },
  },

  startercollection: {
    title: "Starter Collection | Sample Stories | The Morning Context",
    description:
      "Check out our hand-picked collection of stories which you can read for free, using your starter credits. Sign up here to claim 100 free credits to get you started.",
    keywords: "free credits, starter collection, sample stories",
    og: {
      title: "Starter Collection | Sample Stories | The Morning Context",
      url: "/",
      description:
        "Check out our hand-picked collection of stories which you can read for free, using your starter credits. Sign up here to claim 100 free credits to get you started.",
      image: starterCollectionSeoImage,
    },
    twitter: {
      title: "Starter Collection | Sample Stories | The Morning Context",
      description:
        "Check out our hand-picked collection of stories which you can read for free, using your starter credits. Sign up here to claim 100 free credits to get you started.",
      image: starterCollectionSeoImage,
    },
  },

  premiumcollection: {
    title: "Premium Collection | Our Best Reads | The Morning Context",
    description:
      "Pay only for what you want to read with our privilege credits. You can purchase privilege credits here to unlock any of the stories, newsletters or long reads.",
    keywords: "privilege credits, archives, premium collection",
    og: {
      title: "Premium Collection | Our Best Reads | The Morning Context",
      url: "/",
      description:
        "Pay only for what you want to read with our privilege credits. You can purchase privilege credits here to unlock any of the stories, newsletters or long reads.",
      image: premiumCollectionSeoImage,
    },
    twitter: {
      title: "Premium Collection | Our Best Reads | The Morning Context",
      description:
        "Pay only for what you want to read with our privilege credits. You can purchase privilege credits here to unlock any of the stories, newsletters or long reads.",
      image: premiumCollectionSeoImage,
    },
  },

  "my-team": {
    title: "Manage Your Team | Add Modify Members | The Morning Context",
    description:
      "Manage and add your team members to The Morning Context subscription plan on our easy-to-use and hassle-free dashboard.  Each member gets full access to TMC stories",
    keywords: "team subscription, manage team, corporate subscription",
    og: {
      title: "Manage Your Team | Add Modify Members | The Morning Context",
      url: "/",
      description:
        "Manage and add your team members to The Morning Context subscription plan on our easy-to-use and hassle-free dashboard.  Each member gets full access to TMC stories",
      image: myTeamSeoImage,
    },
    twitter: {
      title: "Manage Your Team | Add Modify Members | The Morning Context",
      description:
        "Manage and add your team members to The Morning Context subscription plan on our easy-to-use and hassle-free dashboard.  Each member gets full access to TMC stories",
      image: myTeamSeoImage,
    },
  },

  home: {
    title: "The Morning Context: Internet, Business, Chaos",
    description:
      "The Morning Context publishes the best reportage and analysis on technology, business and chaos in India. Subscribe to get timely, in-depth stories every day.",
    keywords: "the morning context",
    og: {
      title: "The Morning Context: Internet, Business, Chaos",
      url: "/",
      description:
        "The Morning Context publishes the best reportage and analysis on technology, business and chaos in India. Subscribe to get timely, in-depth stories every day.",
      image: homeSeoImage,
    },
    twitter: {
      title: "The Morning Context: Internet, Business, Chaos",
      description:
        "The Morning Context publishes the best reportage and analysis on technology, business and chaos in India. Subscribe to get timely, in-depth stories every day.",
      image: homeSeoImage,
    },
  },

  "category-business": {
    title: "Business Archives | The Morning Context",
    description:
      "The Morning Context chronicles the stories of businesses and the people who run them, across industries and countries. Check out our business archives.",
    keywords: "business archives",
    og: {
      title: "Business Archives | The Morning Context",
      url: "/",
      description:
        "The Morning Context chronicles the stories of businesses and the people who run them, across industries and countries. Check out our business archives.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Business Archives | The Morning Context",
      description:
        "The Morning Context chronicles the stories of businesses and the people who run them, across industries and countries. Check out our business archives.",
      image: homeSeoImage,
    },
  },

  "category-internet": {
    title: "Internet Archives | The Morning Context",
    description:
      "The Morning Context unravels the world at the intersection of need, the internet and companies. Subscribe now to read stories on the internet that matter.",
    keywords: "internet archives",
    og: {
      title: "Internet Archives | The Morning Context",
      url: "/",
      description:
        "The Morning Context unravels the world at the intersection of need, the internet and companies. Subscribe now to read stories on the internet that matter.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Internet Archives | The Morning Context",
      description:
        "The Morning Context unravels the world at the intersection of need, the internet and companies. Subscribe now to read stories on the internet that matter.",
      image: homeSeoImage,
    },
  },

  "category-chaos": {
    title: "Chaos Archives | The Morning Context",
    description:
      "The Morning Context's chaos stories cover everything from our apathy on global warming to the future of automobiles. Subscribe to get full access.",
    keywords: "chaos archives",
    og: {
      title: "Chaos Archives | The Morning Context",
      url: "/",
      description:
        "The Morning Context's chaos stories cover everything from our apathy on global warming to the future of automobiles. Subscribe to get full access.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Chaos Archives | The Morning Context",
      description:
        "The Morning Context's chaos stories cover everything from our apathy on global warming to the future of automobiles. Subscribe to get full access.",
      image: homeSeoImage,
    },
  },

  writers: {
    title: "Writers At The Morning Context",
    description:
      "The Morning Context's writers are among the most talented business journalists in Asia. Each with a knack for telling original, deeply researched stories.",
    keywords: "business journalist",
    og: {
      title: "Writers At The Morning Context",
      url: "/",
      description:
        "The Morning Context's writers are among the most talented business journalists in Asia. Each with a knack for telling original, deeply researched stories.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Writers At The Morning Context",
      description:
        "The Morning Context's writers are among the most talented business journalists in Asia. Each with a knack for telling original, deeply researched stories.",
      image: homeSeoImage,
    },
  },

  blog: {
    title: "Housekeeping | The Morning Context blog",
    description:
      "Updates, announcements and editorial learnings at The Morning Context. Check out our stories on tech, business and chaos and subscribe.",
    keywords: "the morning context blog",
    og: {
      title: "Housekeeping | The Morning Context blog",
      url: "/",
      description:
        "Updates, announcements and editorial learnings at The Morning Context. Check out our stories on tech, business and chaos and subscribe.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Housekeeping | The Morning Context blog",
      description:
        "Updates, announcements and editorial learnings at The Morning Context. Check out our stories on tech, business and chaos and subscribe.",
      image: homeSeoImage,
    },
  },

  company: {
    title: "Company | The Morning Context",
    description:
      "Forty publicly listed companies from the core of our business coverage. Here’s a selection of stories on them divided by theme.",
    keywords: "the morning context",
    og: {
      title: "Company | The Morning Context",
      url: "/",
      description:
        "Forty publicly listed companies from the core of our business coverage. Here’s a selection of stories on them divided by theme.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Company | The Morning Context",
      description:
        "Forty publicly listed companies from the core of our business coverage. Here’s a selection of stories on them divided by theme.",
      image: homeSeoImage,
    },
  },

  newsletters: {
    title: "Newsletters | The Morning Context",
    description:
      "Our weekly newsletters deliver sharp, original insights on recent news and developments from the best writers and subject experts.",
    keywords: "newsletter newsletters weekly fortnightly",
    og: {
      title: "Newsletters | The Morning Context",
      url: "/",
      description:
        "Our weekly newsletters deliver sharp, original insights on recent news and developments from the best writers and subject experts.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Newsletters | The Morning Context",
      description:
        "Our weekly newsletters deliver sharp, original insights on recent news and developments from the best writers and subject experts.",
      image: homeSeoImage,
    },
  },

  yesterday: {
    title: "Yesterday | The Morning Context",
    description:
      "News without context is noise. Understand the most important news developments in technology, business, financial markets and current affairs. Curated and explained by us daily.",
    keywords: "Yesterday",
    og: {
      title: "Yesterday | The Morning Context",
      url: "/",
      description:
        "News without context is noise. Understand the most important news developments in technology, business, financial markets and current affairs. Curated and explained by us daily.",
      image: homeSeoImage,
    },
    twitter: {
      title: "Yesterday | The Morning Context",
      description:
        "News without context is noise. Understand the most important news developments in technology, business, financial markets and current affairs. Curated and explained by us daily.",
      image: homeSeoImage,
    },
  },

  getDefaultSeo: (data) => {
    return {
      title: `${data.pageName} | The Morning Context`,
      description:
        "The Morning Context provides in-depth reportage and considered opinion from journalists and industry experts around the world, on tech, business and chaos.",
      keywords: "the morning context",
      og: {
        title: `${data.pageName} | The Morning Context`,
        url: "/",
        description:
          "The Morning Context provides in-depth reportage and considered opinion from journalists and industry experts around the world, on tech, business and chaos.",
        image: homeSeoImage,
      },
      twitter: {
        title: `${data.pageName} | The Morning Context`,
        description:
          "The Morning Context provides in-depth reportage and considered opinion from journalists and industry experts around the world, on tech, business and chaos.",
        image: homeSeoImage,
      },
    };
  },

  getWriterSingleSeo: (writerData) => {
    return {
      title: `${writerData.name} Archives | The Morning Context`,
      description:
        "Our writers comprise of some of the most talented journalists in business journalism. Everyone with a knack for telling original, deeply researched stories.",
      keywords: "business journalist",
      og: {
        title: `${writerData.name} Archives | The Morning Context`,
        url: "/",
        description:
          "Our writers comprise of some of the most talented journalists in business journalism. Everyone with a knack for telling original, deeply researched stories.",
        image: homeSeoImage,
      },
      twitter: {
        title: `${writerData.name} Archives | The Morning Context`,
        description:
          "Our writers comprise of some of the most talented journalists in business journalism. Everyone with a knack for telling original, deeply researched stories.",
        image: homeSeoImage,
      },
    };
  },

  getNewsletterSingleSeo: (newsLetterDetails) => {
    return {
      title: `${newsLetterDetails.newsletter.name} Archives | ${newsLetterDetails.newsletter.available} Newsletter | The Morning Context`,
      description: newsLetterDetails.newsletter.description,
      keywords: "weekly fortnightly newsletter",
      og: {
        title: `${newsLetterDetails.newsletter.name} Archives | ${newsLetterDetails.newsletter.available} Newsletter | The Morning Context`,
        url: "/",
        description: newsLetterDetails.newsletter.description,
        image: homeSeoImage,
      },
      twitter: {
        title: `${newsLetterDetails.newsletter.name} Archives | ${newsLetterDetails.newsletter.available} Newsletter | The Morning Context`,
        description: newsLetterDetails.newsletter.description,
        image: homeSeoImage,
      },
    };
  },

  getListingPageSeo: (archiveType, archiveSlug, archiveData) => {
    return {
      title: `${getPageTitle(
        false,
        false,
        archiveType,
        archiveSlug,
        archiveData
      )} Archives | The Morning Context`,
      description:
        "The Morning Context provides in-depth reportage and considered opinion from journalists and industry experts around the world, on tech, business and chaos.",
      keywords: getPageTitle(
        true,
        false,
        archiveType,
        archiveSlug,
        archiveData
      ),
      og: {
        title: `${getPageTitle(
          false,
          false,
          archiveType,
          archiveSlug,
          archiveData
        )} Archives | The Morning Context`,
        url: "/",
        description:
          "The Morning Context provides in-depth reportage and considered opinion from journalists and industry experts around the world, on tech, business and chaos.",
        image: homeSeoImage,
      },
      twitter: {
        title: `${getPageTitle(
          false,
          false,
          archiveType,
          archiveSlug,
          archiveData
        )} Archives | The Morning Context`,
        description:
          "The Morning Context provides in-depth reportage and considered opinion from journalists and industry experts around the world, on tech, business and chaos.",
        image: homeSeoImage,
      },
    };
  },

  getYesterdayEditionSeo: (storyData) => {
    let desription = storyData?.strapline || "";
    let title = he_parser.decode(storyData.title);
    return {
      title: `${title} | Yesterday | The Morning Context`,
      description: desription,
      keywords: "yesterday",
      og: {
        title: `${title} | Yesterday | The Morning Context`,
        url: "/",
        description: desription,
        image: storyData.featured_image
          ? storyData.featured_image
          : homeSeoImage,
      },
      twitter: {
        title: `${title} | Yesterday | The Morning Context`,
        description: desription,
        image: storyData.featured_image
          ? storyData.featured_image
          : homeSeoImage,
      },
    };
  },
};

const getPageTitle = (
  lower,
  placeholder = false,
  archiveType,
  archiveSlug,
  archiveData
) => {
  let title,
    pageTitle = placeholder ? "All" : "All Stories";
  if (archiveType == "category") {
    title = archiveData.filters?.category;
    pageTitle =
      title == undefined
        ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
        : title.name;
  } else if (archiveType == "company") {
    title = archiveData.filters?.company?.find(
      (company) => company.slug == archiveSlug
    );
    pageTitle =
      title == undefined
        ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
        : title.name;
  } else if (archiveType == "tag") {
    title = archiveData.filters?.tag?.find((tag) => tag.slug == archiveSlug);
    pageTitle =
      title == undefined
        ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
        : title.name;
  } else if (archiveType == "theme") {
    title = archiveData.filters?.storyplay;
    pageTitle =
      title == undefined
        ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
        : title.name;
  } else if (archiveType == "category-archive") {
    title = archiveData.filters?.category;
    pageTitle =
      (title == undefined
        ? archiveSlug.charAt(0).toUpperCase() + archiveSlug.slice(1)
        : title.name) + " Archive";
  } else if (archiveType == "archive") {
    pageTitle = "Archived";
  } else if (archiveType == "/yesterday") {
    pageTitle = "Yesterday";
  }
  return lower ? pageTitle.toLowerCase() : pageTitle;
};

export default seoObj;
