"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isValidTagOrAttributeName;
var VALID_TAG_REGEX = /^[a-zA-Z][a-zA-Z:_\.\-\d]*$/;

var nameCache = {};

function isValidTagOrAttributeName(tagName) {
  if (!nameCache.hasOwnProperty(tagName)) {
    nameCache[tagName] = VALID_TAG_REGEX.test(tagName);
  }
  return nameCache[tagName];
}