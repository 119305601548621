import React, { Component } from "react";
import { Text, TouchableOpacity } from "react-native";
import { View } from "react-native";
import ButtonGradient from "../../native/components/button-gradient";
import { button } from "../../native/styles/appStyles";
import { helpers } from "../../native/utils/helpers";
import AuthenticationWrapper from "../../native/components/authentication-wrapper";
import Cookies from "universal-cookie";
import { Mixpanel } from "../../native/utils/mixpanel";
import { GoogleAnalytics } from "../../native/utils/google-analytics";
import { removeUserToken } from "../../redux/User/user.actions";

const cookies = new Cookies()


export class ClaimSubError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWrongUser: window.location.href.includes('wrong_user'),
      isLinkExpired: window.location.href.includes('link_expired'),
      isAdminLinkExpired: window.location.href.includes('invalid_admin_link'),
      existingPlanActive: window.location.href.includes('admin_existing_plan'),
      userEmail: this.props?.auth0?.user?.legacy_claims?.account_email || 'this user'
    }
    
  }

  componentDidMount = () => {
    console.log("Props ----->>>", this.props)
  };

  componentDidUpdate = () => {
   
  };

  navigateToScreen = (route, params) => {
    let routeArr = route.split("?");
    const { history } = this.props;
    history.push({
      pathname: routeArr[0],
      search: routeArr[1] ? "?" + routeArr[1] : "",
      state: params,
    });
    console.log("params:", params);
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      console.log("Props ----->>>", this.props)
        this.props.auth.logout({
          returnTo:
            window.location.origin +
            "/sign-in?redirect=" +
            (this.props.redirectSlugs ? this.props.redirectSlugs.web : "/"),
        });
    } else {
        console.log("Not web!")
        removeUserToken()
    }
    try {
        Mixpanel.reset();
    }
    catch (error) {
        console.log('mix panel error =>', error);
    }
    try {
        GoogleAnalytics.event('Account', 'logout');
    }
    catch (error) {
        console.log("google analytics error ==>", error);
    }
}

logout = () => {
    this.authTokenExpired()
}

  handleHomePageRedirect = () => {
    this.navigateToScreen('/')
  }

  handleClick = () => {
    if(this.state.isWrongUser){
      /**
       * Handle sign out
       */
      this.logout()
    }else if(this.state.existingPlanActive){
      this.navigateToScreen('/')
    }else{
      /**
       * Redirect to pricing page
       */
      this.navigateToScreen('/pricing')
    }
  }
  
  render() {
    console.log("Rendering the screen")
    return (
      <View style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <View style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Text style={{fontSize: helpers.isDesktop() ? 28 : 24, fontWeight: 700, fontFamily: "Abril Fatface"}}>{this.state.isWrongUser ? "GIFT NOT FOR THIS USER" : "We are sorry :("}</Text>
        </View>
        <View style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', margin: helpers.isDesktop() ? 20 : 10}}>
          <View style={{borderBottomWidth: 1, width: helpers.isDesktop() ? '5%': '0%'}} />
         {helpers.isDesktop() ?
         <> 
         <View style={{borderBottomWidth: 3, width: '5%'}} />
          <View style={{borderBottomWidth: 1, width: '5%'}} /> 
          </> : null}
        </View>
        <View style={{width: 400, height: 100, display: 'flex', justifyContent: 'center', alignItems:'center'}}>
          <Text style={{fontSize: 20, fontWeight: 400, fontFamily: 'Matteo', width: helpers.isDesktop() ? '100%': '80%', textAlign: 'center'}}>
            {this.state.isWrongUser ? `The gift you are trying to claim is not for ${this.state.userEmail}. Please sign in to TMC using the email id under which the gift offer was sent to.` : this.state.isLinkExpired ? "This link has reached the maximum number of redemptions." : this.state.isAdminLinkExpired ? "This link is not active anymore. Kindly contact the sender or support@themorningcontext.com for further assistance." : this.state.existingPlanActive ? "It seems you already have an active subscription to The Morning Context. Please continue to your account to start reading." : "This link is not active anymore. Kindly contact the sender or support@themorningcontext.com for further assistance."}
          </Text>
        </View>
        <View style={{width: 150, marginTop: 20}}>
        <ButtonGradient
          title={this.state.isWrongUser ? "SIGN OUT" : this.state.existingPlanActive ? "Continue" : "SUBSCRIBE"}
          rootStyle={{
              btnWrapper: [button.primaryGradient],
              btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
              paddingHorizontal: 5
          }}
          // loader={(enableBtnLoader && enableBtnLoader == 'email') ? true : false}
          // disable={((enableBtnLoader && enableBtnLoader != 'email') || (email == '' || password == '')) ? true : false}
          onClick={this.handleClick}
          fullWidth={true}
      />
        </View>
        <TouchableOpacity onPress={this.handleHomePageRedirect} style={{width: '100%'}}><Text style={{textAlign: 'center', textDecorationLine: 'underline', marginTop: 30}}>{"Continue to the The Morning Context homepage >"}</Text></TouchableOpacity>
      </View>
    );
  }
}

export default AuthenticationWrapper(ClaimSubError);
