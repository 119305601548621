import React, { Component } from "react";
import { Text, TextInput, View, Picker, StyleSheet } from "react-native";
import { button } from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import ButtonGradient from "../button-gradient";
import * as Yup from "yup";
import { Formik } from "formik";
import CustomPicker from "../custom-picker";
import CustomCountryPicker from "../custom-country-picker";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export class CustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showPicker: false };
  }
  getValueOfQuantityFromRequirement = (requirements) => {
    if (requirements.includes("and above")) {
      const lower = parseInt(requirements.split(" ")[0]);
      const upper = null;
      return { lower, upper };
    } else {
      const [lower, upper] = requirements.split("-").map((x) => parseInt(x));
      return { lower, upper: upper + 1 };
    }
  };
  render() {
    const darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      businessEmail: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
      phoneNumber: Yup.string()
        .test("is-number", "Invalid phone number", (value) => !isNaN(value))
        .required("Phone number is required"),
      jobTitle: Yup.string().required("Job Title is required"),
      company: Yup.string().required("Company is required"),
      requirements: Yup.string().required("Please select a option"),
      countryCode: Yup.string().required("Please select a option"),
    });
    return (
      <View style={styles.parentContainer}>
        <Text style={styles.formTitle}>
          Please enter your information below and we will contact you shortly.
        </Text>
        <Formik
          initialValues={{
            name: "",
            lastName: "",
            businessEmail: "",
            phoneNumber: "",
            jobTitle: "",
            company: "",
            requirements: "",
            countryCode: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("Values =", values);
            const data = {
              name: values.name,
              business_email: values.businessEmail,
              phone_number:
                "+" +
                phoneUtil.getCountryCodeForRegion(values.countryCode) +
                values.phoneNumber,
              job_title: values.jobTitle,
              company_name: values.company,
              quantity: this.getValueOfQuantityFromRequirement(
                values.requirements
              ),
            };
            console.log("Data =", data);
            this.props.onSubmit(data);
          }}
        >
          {(props) => (
            <View style={styles.container}>
              <View style={styles.formRow}>
                <View style={styles.formItem}>
                  <TextInput
                    placeholder="Name*"
                    onChangeText={props.handleChange("name")}
                    onBlur={props.handleBlur("name")}
                    value={props.values.name}
                    style={[
                      styles.input,
                      { borderBottom: "1px solid #bbbbbb" },
                    ]}
                  />
                  {props.errors.name && props.touched.name && (
                    <Text style={styles.formError}>{props.errors.name}</Text>
                  )}
                </View>

                <View style={styles.formItem}>
                  <TextInput
                    placeholder="Business Email*"
                    onChangeText={props.handleChange("businessEmail")}
                    onBlur={props.handleBlur("businessEmail")}
                    value={props.values.businessEmail}
                    style={[
                      styles.input,
                      { borderBottom: "1px solid #bbbbbb" },
                    ]}
                  />
                  {props.errors.businessEmail &&
                    props.touched.businessEmail && (
                      <Text style={styles.formError}>
                        {props.errors.businessEmail}
                      </Text>
                    )}
                </View>
              </View>
              <View style={styles.formRow}>
                <View style={styles.formItem}>
                  <TextInput
                    placeholder="Phone Number*"
                    onChangeText={props.handleChange("phoneNumber")}
                    onBlur={props.handleBlur("phoneNumber")}
                    value={props.values.phoneNumber}
                    style={[
                      styles.input,
                      { borderBottom: "1px solid #bbbbbb" },
                    ]}
                  />
                  {props.errors.phoneNumber && props.touched.phoneNumber && (
                    <Text style={styles.formError}>
                      {props.errors.phoneNumber}
                    </Text>
                  )}
                </View>
                <View style={styles.formItem}>
                  <CustomCountryPicker
                    name="countryCode"
                    selectedValue={props.values.countryCode}
                    field={{
                      value: props.values.countryCode,
                      error: props.errors.countryCode,
                      touched: props.touched.countryCode,
                    }}
                    onChange={(value) => {
                      props.setFieldValue("countryCode", value);
                    }}
                  />
                  {props.errors.countryCode && props.touched.countryCode && (
                    <Text style={styles.formError}>
                      {props.errors.countryCode}
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  zIndex: -5,
                }}
              >
                <View style={styles.formRow}>
                  <View style={styles.formItem}>
                    <TextInput
                      placeholder="Job Title*"
                      onChangeText={props.handleChange("jobTitle")}
                      onBlur={props.handleBlur("jobTitle")}
                      value={props.values.jobTitle}
                      style={[
                        styles.input,
                        { borderBottom: "1px solid #bbbbbb" },
                      ]}
                    />
                    {props.errors.jobTitle && props.touched.jobTitle && (
                      <Text style={styles.formError}>
                        {props.errors.jobTitle}
                      </Text>
                    )}
                  </View>
                  <View style={styles.formItem}>
                    <TextInput
                      placeholder="Company*"
                      onChangeText={props.handleChange("company")}
                      onBlur={props.handleBlur("company")}
                      value={props.values.company}
                      style={[
                        styles.input,
                        { borderBottom: "1px solid #bbbbbb" },
                      ]}
                    />
                    {props.errors.company && props.touched.company && (
                      <Text style={styles.formError}>
                        {props.errors.company}
                      </Text>
                    )}
                  </View>
                </View>
                <View style={styles.formRow}>
                  <View style={styles.formItem}>
                    <CustomPicker
                      name="requirements"
                      selectedValue={props.values.requirements}
                      field={{
                        value: props.values.requirements,
                        error: props.errors.requirements,
                        touched: props.touched.requirements,
                      }}
                      onChange={(value) => {
                        props.setFieldValue("requirements", value);
                      }}
                    />
                    {props.errors.requirements && props.touched.requirements ? (
                      <Text style={styles.formError}>
                        {props.errors.requirements}
                      </Text>
                    ) : null}
                  </View>
                  <View style={styles.formItem}></View>
                </View>
                <View
                  style={{
                    zIndex: -5,
                  }}
                >
                  <View style={{ marginTop: helpers.isDesktop() ? 30 : 15 }}>
                    <ButtonGradient
                      title={"SUBMIT"}
                      rootStyle={{
                        btnWrapper: [
                          button.primaryGradientBtn,
                          { height: 46, borderRadius: 0, flex: 1 },
                        ],
                        btnText: [
                          button.primaryGradientText,
                          {
                            fontSize: helpers.isDesktop() ? 16 : 14,
                            paddingLeft: 50,
                            paddingRight: 50,
                            paddingTop: 13,
                            paddingBottom: 13,
                            fontWeight: "400",
                          },
                        ],
                      }}
                      onClick={() => {
                        props.handleSubmit();
                      }}
                      darkMode={darkMode}
                      smallBtn={true}
                    />
                  </View>
                </View>
              </View>
            </View>
          )}
        </Formik>
        {this.props.showErrorMessage && (
          <View style={styles.formmainError}>
            <Text style={styles.formmainerrorText}>
              {this.props.serverSideErrorMessage}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    padding: helpers.isDesktop() ? 20 : 11,
  },
  formRow: {
    marginVertical: 14,
    display: "flex",
    flexDirection: helpers.isDesktop() ? "row" : "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    columnGap: "20px",
    rowGap: "20px",
  },
  formRowleft: {
    marginVertical: 14,
    display: "flex",
    flexDirection: helpers.isDesktop() ? "row" : "column",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    columnGap: "20px",
    rowGap: "20px",
  },
  formSingleRow: {
    marginVertical: 14,
    display: "flex",
    flexDirection: helpers.isDesktop() ? "row" : "column",
    justifyContent: "start",
    alignContent: "start",
    alignItems: "start",
    columnGap: "20px",
    rowGap: "20px",
  },
  formLabel: {
    fontWeight: "bold",
  },
  formInput: {
    borderWidth: 1,
    borderColor: "gray",
    padding: 5,
    flex: 1,
  },
  formItem: {
    // flexDirection: "column",
    // justifyContent: "space-between",
    // marginVertical: 10,
    width: helpers.isDesktop() ? "50%" : "100%",
    position: "relative",
  },
  formPicker: {
    borderWidth: 1,
    borderColor: "gray",
  },
  parentContainer: {
    margin: "0 auto",
    height: helpers.isDesktop() ? "auto" : "auto",
    width: helpers.isDesktop() ? "50%" : "96%",
    padding: helpers.isDesktop() ? 20 : 11,
    backgroundColor: "#ffffff",
    boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
    position: "relative",
  },
  formTitle: {
    fontFamily: "Merriweather-Regular",
    fontSize: helpers.isDesktop() ? "20px" : "16px",
    fontWeight: "400",
    color: "rgb(0, 0, 0)",
    textAlign: "center",
    marginTop: 12,
  },
  input: {
    fontSize: "16px",
    lineHeight: "32px",
    fontFamily: "Matteo-Regular",
  },
  formSelect: {
    fontSize: "16px",
    lineHeight: "32px",
    fontFamily: "Matteo-Regular",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  formError: {
    color: "#fc0808",
    position: "absolute",
    bottom: "-18px",
    fontFamily: "Matteo-Regular",
    fontSize: "13px",
  },
  formmainError: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#bd0707",
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  formmainerrorText: {
    color: "#ffffff",
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "Matteo-Regular",
  },
});
export default CustomerForm;
