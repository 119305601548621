import React, { Component } from 'react';
import { withRouter, Redirect, Link } from "react-router-dom";
import { Text, View, Image, ScrollView, Platform } from 'react-native';
import UnlockCollection from '../../native/screens/unlock-collection'; 
import ReactGA from 'react-ga';

export class UnlockCollectionScreen extends Component {    
    constructor(props){
        super(props);
        this.state = {
            searchQuery: "",
            locationHash: ""
        }

        if(process.browser) {
            try {
                ReactGA.pageview(this.props.history.location.pathname);
            }
            catch(error) {
                console.log("GA tracking error =>", error);
            }
        }
    }

    componentDidMount = () => {
        setTimeout(function () {
            window.history.scrollRestoration = "manual";
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            setTimeout(function () {
                document.body.style.overflow = 'initial';
                document.body.style.position = 'initial';
            }, 20);
        }, 20);
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }

    componentDidUpdate = () => {
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }
    
    navigateToScreen = (route, params) => {
        let routeArr = route.split('?')
        const { history } = this.props;
        history.push({
          pathname: routeArr[0],
          search: routeArr[1] ? ('?' + routeArr[1]) : '',
          state: params
        });
    }

    render(){
        const { searchQuery, locationHash } = this.state;
        
        return(
           <UnlockCollection history={this.props.history} searchQuery={searchQuery} locationHash={locationHash} navigateToScreen={this.navigateToScreen} params={this.props.params} />
        );
    }
}

export default withRouter(UnlockCollectionScreen);