import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  articleItem,
  postActionBar,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");
const bookmarkIcon = getImagePath("icons/bookmark.png");
const bookmarkIconSelected = getImagePath("icons/bookmark-selected.png");

export class ArticleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.data.title);
    }
  };

  render() {
    const {
      single,
      data,
      viewArticle,
      viewAuthor,
      userStoryAction,
      applyStoryActions,
      userCredits,
      hideImage,
      horizontalLayout,
      home,
      category,
      marginTrue,
      homereadinglist,
      hideDate,
      darkMode,
    } = this.props;
    let showImage = hideImage ? false : true;
    let checkDesktop = category ? false : helpers.isDesktop() ? true : false;
    let btnClick,
      authorBtnClick,
      author2BtnClick,
      btnClickBookmark,
      btnClickLike;
    if (helpers.isWeb()) {
      btnClick = {
        to: {
          pathname: "/" + data.category.slug + "/" + data.slug,
          postData: data,
        },
      };
      authorBtnClick = {
        to: "/writers/" + data.author[0].slug,
      };
      if (data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
    } else {
      btnClick = {
        onPress: () => viewArticle(data.category.slug, data.slug, data),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author[0].slug);
        },
      };
      if (data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
    }
    return (
      <TouchableOpacityLink
        {...btnClick}
        handleClick={() => this.handleClick()}
      >
        {/*style={[single ? articleItem.removeBorder : articleItem.borderTop, Flex.fill]}*/}

        {horizontalLayout ? (
          <View style={[Flex.fill]}>
            {userCredits && (
              <View style={[articleItem.wrapAbove]}>
                <Text
                  style={[
                    articleItem.dateTop,
                    darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                  ]}
                >
                  {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </View>
            )}
            <View
              style={[
                helpers.isDesktop() && Flex.row,
                userCredits && { flex: 1, paddingTop: 0 },
              ]}
            >
              {showImage && (
                <View style={{ width: helpers.isDesktop() ? "48%" : "100%" }}>
                  <View style={[Margin.mb_2, helpers.isDesktop() && Flex.row]}>
                    <CustomImage
                      source={data.featured_image.url}
                      require={false}
                      style={{ width: "100%", height: 230 }}
                      webStyle={{
                        width: "100%",
                        height: 230,
                        objectFit: "cover",
                      }}
                      resizeMode={"cover"}
                      altText={
                        data.featured_image.alt && data.featured_image.alt != ""
                          ? data.featured_image.alt
                          : data.title
                            ? data.title
                            : data.story?.title
                              ? data.story.title
                              : data.featured_image.title
                      }
                    />
                  </View>
                </View>
              )}
              <View
                style={[
                  articleItem.colRight,
                  {
                    maxWidth: helpers.isDesktop() ? "48%" : "100%",
                    width: helpers.isDesktop() ? "50%" : "100%",
                    height: "auto",
                    borderBottomWidth: helpers.isDesktop() ? 0 : 0,
                    borderColor: "rgba(0,0,0,0.25)",
                    marginLeft: helpers.isDesktop() ? 20 : 0,
                    marginBottom: helpers.isDesktop() ? 21 : 0,
                  },
                ]}
              >
                <Text
                  style={[
                    articleItem.articleTitle,
                    helpers.isDesktop() && articleItem.articleTitleDesktop,
                    {
                      colo: "#000",
                      width: helpers.isDesktop() ? 300 : "100%",
                      fontWeight: home ? "400" : "600",
                      fontSize: helpers.isDesktop() ? 28 : 24,
                      lineHeight: helpers.isDesktop() ? 38 : 34,
                    },
                    helpers.isDesktop() && { maxHeight: 75 },
                    !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                  numberOfLines={2}
                >
                  {data.title}
                </Text>
                {userCredits ? (
                  <>
                    {data.credits && (
                      <View style={[Flex.row]}>
                        <Text style={postSingle.creditBox}>
                          {data.credits.unlock} Credits
                        </Text>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Text style={[articleItem.desc]}>{(data.strapline != '') ? data.strapline : helpers.trimWord(data.excerpt)}</Text> */}
                    <View
                      style={[articleItem.lineBreak, Margin.mt_1, Margin.mb_15]}
                    ></View>
                    <View
                      style={[
                        {
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: 0,
                        },
                        Flex.alignCenter,
                      ]}
                    >
                      <View style={[Flex.row, Flex.alignCenter, Flex.FlexWrap]}>
                        <TouchableOpacityLink {...authorBtnClick}>
                          <Text
                            style={[
                              articleItem.authorTitle,
                              helpers.isDesktop() &&
                              articleItem.authorTitleDesktop,
                              { marginRight: 0 },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            {data.author[0].name}
                          </Text>
                        </TouchableOpacityLink>
                        {data.author[1] && (
                          <>
                            <Text
                              style={[
                                articleItem.authorTitle,
                                helpers.isDesktop() &&
                                articleItem.authorTitleDesktop,
                                articleItem.date,
                                { fontWeight: home ? "700" : "500" },
                                darkMode && {
                                  color: "rgba(255, 255, 255, 0.89)",
                                },
                              ]}
                            >
                              {" "}
                              and{" "}
                            </Text>
                            <TouchableOpacityLink {...author2BtnClick}>
                              <Text
                                style={[
                                  articleItem.authorTitle,
                                  helpers.isDesktop() &&
                                  articleItem.authorTitleDesktop,
                                  { fontWeight: home ? "700" : "500" },
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {data.author[1].name}
                              </Text>
                            </TouchableOpacityLink>
                          </>
                        )}
                      </View>
                      <View style={[articleItem.dot]}></View>
                      <Text
                        style={[
                          articleItem.articleDate,
                          helpers.isDesktop() && articleItem.articleDateDesktop,
                          { fontWeight: home ? "700" : "300" },
                          darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                        ]}
                      >
                        {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={[
              Flex.fill,
              helpers.isDesktop() && Flex.row,
              category && { width: helpers.isDesktop() ? 250 : "100%" },
              { marginBottom: helpers.isDesktop() ? 30 : 0 },
              homereadinglist && { marginBottom: 20, paddingTop: 20 },
            ]}
          >
            {userCredits && (
              <View style={[articleItem.wrapAbove]}>
                <Text
                  style={[
                    articleItem.dateTop,
                    darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                  ]}
                >
                  {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </View>
            )}
            <View
              style={[
                articleItem.wrap,
                Flex.fill,
                userCredits && { paddingTop: 0 },
                { flexDirection: !checkDesktop ? "row" : "column" },
              ]}
            >
              {showImage && (
                <View style={[articleItem.colLeft]}>
                  {!home && (
                    <View style={[Margin.mb_2]}>
                      <CustomImage
                        source={data.featured_image.url}
                        require={false}
                        style={{
                          width: !checkDesktop ? 134 : "100%",
                          height: !checkDesktop ? 75 : 230,
                          marginRight: !checkDesktop ? 15 : 0,
                        }}
                        webStyle={{
                          width: !checkDesktop ? 134 : "100%",
                          height: !checkDesktop ? 75 : 230,
                          objectFit: "cover",
                          marginRight: !checkDesktop ? 15 : 0,
                        }}
                        resizeMode={"cover"}
                        altText={
                          data.featured_image.alt &&
                            data.featured_image.alt != ""
                            ? data.featured_image.alt
                            : data.title
                              ? data.title
                              : data.story?.title
                                ? data.story.title
                                : data.featured_image.title
                        }
                      />
                    </View>
                  )}
                  {userStoryAction && (
                    <View style={[Flex.row, Margin.mt_1]}>
                      <TouchableOpacity
                        {...btnClickLike}
                        style={[postActionBar.actionBtn, { marginRight: 3 }]}
                      >
                        <CustomImage
                          source={
                            data.user_story_action &&
                              data.user_story_action.like
                              ? likeIconSelected
                              : likeIcon
                          }
                          require={true}
                          style={{ width: 15, height: 13 }}
                          webStyle={{ width: 15, height: 13 }}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        {...btnClickBookmark}
                        style={[postActionBar.actionBtn]}
                      >
                        <CustomImage
                          source={
                            data.user_story_action &&
                              data.user_story_action.bookmark
                              ? bookmarkIconSelected
                              : bookmarkIcon
                          }
                          require={true}
                          style={{ width: 20, height: 20 }}
                          webStyle={{
                            width: helpers.isDesktop() ? 26 : 21,
                            height: helpers.isDesktop() ? 25 : "auto",
                            marginLeft: -5,
                            marginRight: -5,
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              )}
              <View style={[articleItem.colRight]}>
                <Text
                  style={[
                    articleItem.articleTitle,
                    helpers.isDesktop() && articleItem.articleTitleDesktop,
                    Flex.fill,
                    !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                  numberOfLines={2}
                >
                  {data.title}
                </Text>
                {userCredits ? (
                  <>
                    {data.credits && (
                      <View style={[Flex.row]}>
                        <Text style={postSingle.creditBox}>
                          {data.credits.unlock} Credits
                        </Text>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {/* <Text style={[articleItem.desc]}>{(data.strapline != '') ? data.strapline : helpers.trimWord(data.excerpt)}</Text> */}
                    <View
                      style={[
                        articleItem.lineBreak,
                        Margin.mt_1,
                        Margin.mb_15,
                        !checkDesktop && {
                          marginTop: helpers.isDesktop() ? 10 : 15,
                          marginBottom: helpers.isDesktop() ? 15 : 8,
                        },
                      ]}
                    ></View>
                    <View
                      style={[
                        {
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: 0,
                        },
                        Flex.alignCenter,
                      ]}
                    >
                      <View
                        style={[Flex.row, { flexShrink: 1, flexWrap: "wrap" }]}
                      >
                        <TouchableOpacityLink {...authorBtnClick}>
                          <Text
                            style={[
                              articleItem.authorTitle,
                              helpers.isDesktop() &&
                              articleItem.authorTitleDesktop,
                              { marginRight: 0 },
                              !checkDesktop && {
                                fontSize: helpers.isDesktop() ? 16 : 11,
                              },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            {data.author[0].name}
                          </Text>
                        </TouchableOpacityLink>
                        {data.author[1] && (
                          <>
                            <Text
                              style={[
                                articleItem.authorTitle,
                                helpers.isDesktop() &&
                                articleItem.authorTitleDesktop,
                                articleItem.date,
                                {
                                  fontFamily: FontFamily.light,
                                  marginRight: 0,
                                },
                                !checkDesktop && {
                                  fontSize: helpers.isDesktop() ? 16 : 11,
                                },
                                darkMode && {
                                  color: "rgba(255, 255, 255, 0.89)",
                                },
                              ]}
                            >
                              {" "}
                              and{" "}
                            </Text>
                            <TouchableOpacityLink {...author2BtnClick}>
                              <Text
                                style={[
                                  articleItem.authorTitle,
                                  helpers.isDesktop() &&
                                  articleItem.authorTitleDesktop,
                                  !checkDesktop && {
                                    fontSize: helpers.isDesktop() ? 16 : 11,
                                  },
                                  { marginRight: 0 },
                                  darkMode && {
                                    color: "rgba(255, 255, 255, 0.89)",
                                  },
                                ]}
                              >
                                {data.author[1].name}
                              </Text>
                            </TouchableOpacityLink>
                          </>
                        )}
                      </View>
                      {!hideDate && <View style={[articleItem.dot]}></View>}
                      {!hideDate && (
                        <Text
                          style={[
                            articleItem.articleDate,
                            helpers.isDesktop() &&
                            articleItem.articleDateDesktop,
                            helpers.isWeb() && { marginTop: 3 },
                            !checkDesktop && {
                              fontSize: helpers.isDesktop() ? 16 : 11,
                            },
                            darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                          ]}
                        >
                          {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                        </Text>
                      )}
                    </View>
                  </>
                )}
              </View>
            </View>
          </View>
        )}
      </TouchableOpacityLink>
    );
  }
}

export default ArticleItem;
