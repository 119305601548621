// import React, { Component } from 'react';
// import { StyleSheet, View, ScrollView, Dimensions, Image } from "react-native";

// const DEVICE_WIDTH = Dimensions.get("window").width;

// export class PremiumSlider extends React.Component {
//   scrollRef = React.createRef();
//   constructor(props) {
//     super(props);

//     this.state = {
//       selectedIndex: 0
//     };
//     this.scrollRef = React.createRef();
//   }

//   componentDidMount = () => {
//     setInterval(() => {
//       this.setState(
//         prev => ({
//           selectedIndex:
//             prev.selectedIndex === this.props.data.length - 1
//               ? 0
//               : prev.selectedIndex + 1
//         }),
//         () => {
//           this.scrollRef.current.scrollTo({
//             animated: true,
//             x: DEVICE_WIDTH * this.state.selectedIndex,
//             y: 0
//           });
//         }
//       );
//     }, 10000);
//   };

//   setSelectedIndex = event => {
//     const contentOffset = event.nativeEvent.contentOffset;
//     const viewSize = event.nativeEvent.layoutMeasurement;

//     // Divide the horizontal offset by the width of the view to see which page is visible
//     const selectedIndex = Math.floor(contentOffset.x / viewSize.width);
//     this.setState({ selectedIndex });
//   };

//   render() {
//     const { data } = this.props;
//     const { selectedIndex } = this.state;
//     return (
//       <View style={{ height: "100%", width: "20%" }}>
//         <ScrollView
//           horizontal
//           pagingEnabled
//           onMomentumScrollEnd={this.setSelectedIndex}
//           ref={this.scrollRef}
//         >
//           {data && data.map((item, index) => {
//             return(
//               <Image
//               style={styles.backgroundImage}
//               source={{ uri: item.logo }}
//               key={item}
//             />
//             )
//           }
//           )}
//         </ScrollView>
//         {/* <View style={styles.circleDiv}>
//           {data && data.map((image, i) => (
//             <View
//               style={[
//                 styles.whiteCircle,
//                 { opacity: i === selectedIndex ? 0.5 : 1 }
//               ]}
//               key={image.logo}
//               active={i === selectedIndex}
//             />
//           ))}
//         </View> */}
//       </View>
//     );
//   }
// }

// const styles = StyleSheet.create({
//   backgroundImage: {
//     height: "100%",
//     width: Dimensions.get("window").width
//   },
//   circleDiv: {
//     position: "absolute",
//     bottom: 15,
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "100%",
//     height: 10
//   },
//   whiteCircle: {
//     width: 6,
//     height: 6,
//     borderRadius: 3,
//     margin: 5,
//     backgroundColor: "#fff"
//   }
// });

// export default PremiumSlider ;

import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  TouchableOpacity,
  Button,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  sliderStyle,
} from "../../styles/appStyles";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import Cookies from "universal-cookie";
import { Mixpanel } from "../../utils/mixpanel";
import {
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import Swiper from "react-native-web-swiper";
import TmcDialogue from "../tmc-dialogue";
import TmcAppSlide from "../tmc-app-slide";
import ExclusiveSlider from "../home-exclusive-content/ExclusiveSlider";
import ScrollContainerView from "../scroll-container-view";
import apiHelpers from "../../utils/api-helpers";
import AuthenticationWrapper from "../authentication-wrapper";

// import Carousel from "react-native-anchor-carousel";
// import Carousel from "../carousel";
import Carousel from "../../carousel/Carousel";

import { CardView } from "react-native-simple-card-view";

const arrowLeft = getImagePath("icons/arrow-left-dark.png");
const arrowRight = getImagePath("icons/arrow-right-dark.png");

const quoteLeft = getImagePath("icons/quotegreen.png");
const quoteRight = getImagePath("icons/quoteImageR.png");

const cookies = new Cookies();
const { width: windowWidth, height: windowHeight } = Dimensions.get("window");
const SLIDER_WIDTH = Dimensions.get("window").width;
const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 0.7);
const ITEM_HEIGHT = Math.round((ITEM_WIDTH * 3) / 4);
export class PremiumSlider extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    this.appIntroSlider = React.createRef();
    this.checkScroll = true;
    // const windowWidth = Dimensions.get("window").width;
    let free = [];
    this.notPremiumTagsDict = {
      Techlawlogy: true,
      "Notes from Natasha": true,
      Antiloquy: true,
      "The Crucial Years": true,
    };
    // Creative Destruction, The World Revealed, From Farm to Fork, and Game Point.
    // this.premiumTagsDict['Game Point']

    this.state = {
      activeSlide: 3,
      PlatformWeb: helpers.isDesktop(),
      slideItemWidth: 180 - 40,
      showToken: !helpers.isWeb() || process.browser ? true : false,
      isfree: [],
      intervalId: 0,
    };
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");
  }
  trackNewsletterClick = (data, index) => {
    // try {
    //     Mixpanel.track('click_newsletter', { title : data.name, position: index });
    // }
    // catch(error) {
    //     console.log("check mix panel track error ==>", error);
    // }
  };
  componentDidMount() {
    // var intervalId = setInterval(this.timer, 2000);
    // this.setState({ intervalId: intervalId });
    // let webView = helpers.isWeb();
    // const {isAuthenticated, fetchToken} = this.props.auth
    // let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
    // if (helpers.isWeb()) {
    //     this.handleResize();
    //     window.addEventListener('resize', this.handleResize);
    //     {this.props.data && this.props.data.map((item, index) => {
    //     let endpoint = `/newsletter/${item.slug}`
    //     console.log("api endpoint",endpoint)
    //     apiHelpers
    //     .apiRequest(endpoint, {}, "GET", true,token)
    //     .then((res) => {
    //         if (res.success && res.code == 'newsletter_found') {
    //             apiHelpers
    //             .apiRequest(`/story/${res.data.featured_post.slug}`, {}, "GET", true,token)
    //             .then((res) => {
    //                 if (res.success) {
    //                     this.setState({ isfree: [...this.state.isfree, res.data.is_free] })
    //                     // this.setState({is_free:
    //                     //     [...is_free,res.data.is_free]})
    //                 } else {
    //                     console.log('Error=>>', JSON.stringify(res));
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log('Error=>> down', JSON.stringify(error));
    //             });
    //         } else {
    //             console.log('Error=>>', JSON.stringify(res));
    //         }
    //     })
    //     .catch((error) => {
    //         console.log('Error=>> down', JSON.stringify(error));
    //     });
    // })}
    // }
  }

  timer = () => {
    this.numberCarousel.snapToNext();
    // if (this.state.activeSlide != this.props.data?.length - 1) {
    // this.setState({ activeSlide: this.state.activeSlide + 1 }, () => {});
    // } else {
    // this.numberCarousel.snapToNext();
    // this.setState({ activeSlide: 0 }, () => {});
    // }
  };

  componentWillUnmount() {
    if (helpers.isWeb()) {
      window.removeEventListener("resize", this.handleResize);
      // clearInterval(this.state.intervalId);
    }
  }

  handleResize = () => {
    setTimeout(() => {
      const windowWidth = Dimensions.get("window").width;
      if (windowWidth < 992) {
        this.setState({
          PlatformWeb: false,
          slideItemWidth: windowWidth - 40,
        });
      } else {
        this.setState({
          PlatformWeb: true,
          slideItemWidth: windowWidth - 40,
        });
      }
    }, 200);
  };

  getSlideIndex = (offset, width) => {
    const { data } = this.props;
    let intervals = data.length;

    for (let i = 1; i <= intervals; i++) {
      let index = Math.round((width / intervals) * i);
      if (Math.round(offset) < index) {
        return i - 1;
      }
      if (i == intervals) {
        return i - 1;
      }
    }
  };

  getSlideOffset = (offset, width) => {
    if (this.checkScroll) {
      let slideIndex = this.getSlideIndex(offset, 180);

      this.setState({
        activeSlide: slideIndex,
      });
      // if(this.props.subscriptionQuote) {
      //     if(helpers.isWeb()) {
      //         Mixpanel.track('swipe_reader_quotes', { position: slideIndex });
      //     }
      // }
    }
  };

  prevSlide = () => {
    const { data } = this.props;
    const { activeSlide, slideItemWidth } = this.state;
    let slideLength = data.length;
    // clearInterval(this.state.intervalId);
    this.numberCarousel.stopAutoplay();
    this.numberCarousel.snapToPrev();

    setTimeout(() => {
      this.numberCarousel.startAutoplay();
    }, 100);

    // this.setState({ activeSlide: activeSlide - 1 });
    // let totalSlideWidth = slideItemWidth - 180;

    // let totalWidth = Math.round(slideLength * totalSlideWidth);

    // let scrollIndex;

    // if (activeSlide > 2) {
    //   scrollIndex = Math.round(slideItemWidth * (activeSlide - 2));
    // } else {
    //   scrollIndex = 0;
    // }

    // this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true });
  };

  nextSlide = () => {
    const { data } = this.props;
    const { activeSlide, slideItemWidth } = this.state;
    let slideLength = data.length;
    // clearInterval(this.state.intervalId);

    this.numberCarousel.stopAutoplay();
    this.numberCarousel.snapToNext();

    setTimeout(() => {
      this.numberCarousel.startAutoplay();
    }, 100);

    // this.setState({ activeSlide: activeSlide + 1 });
    // setTimeout(() => {
    //   var intervalId = setInterval(this.timer, 2000);
    //   this.setState({ intervalId: intervalId });
    // }, 4000);

    // let scrollIndex = Math.round(slideItemWidth * activeSlide);

    // this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true });
  };

  slideTo = (index) => {
    // index = index === 0 ? 0 : index+1;
    this.checkScroll = false;
    const { data } = this.props;
    const { slideItemWidth } = this.state;
    let slideLength = data.length;

    let scrollIndex = Math.round(slideItemWidth * index);
    this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true });
    this.setState({ activeSlide: index }, () => {
      setTimeout(() => {
        this.checkScroll = true;
      }, 2000);
    });
  };

  /**
   * Slider card board : Dynamic contents will add it here
   * @param {Object} param : Slide wise data
   * @returns
   */
  addSlideContent = (param) => {
    // console.log("param:", param);
    const darkMode = this.props.darkMode;
    let btnClickNewsletter;

    if (helpers.isWeb()) {
      btnClickNewsletter = {
        to: "/newsletters/" + param.item.slug,
      };
    } else {
      btnClickNewsletter = {
        onPress: () => this.props.getNewsletterClick(param.item.slug),
      };
    }
    const miniCardStyle = {
      shadowColor: "#000000",
      shadowOffsetWidth: 2,
      shadowOffsetHeight: 2,
      shadowOpacity: 0.5,
      shadowRadius: 5,
      bgColor: "#ffffff",
      padding: 0,
      margin: 5,
      borderRadius: 5,
      elevation: 3,
      width: 240,
    };

    return (
      <CardView
        style={this.state.activeSlide === param.index ? miniCardStyle : {}}
      >
        <TouchableOpacityLink
          key={param.item.id}
          {...btnClickNewsletter}
          handleClick={() => {
            this.trackNewsletterClick(param.item, param.index);
          }}
          // onPress={() => {
          //   this.numberCarousel.scrollToIndex(param.index);
          //   this.setState({
          //     activeSlide: param.index,
          //   });
          // }}
        >
          <View
          // {...btnClickNewsletter}
          // handleClick={() => {
          //   this.trackNewsletterClick(param.item, param.index);
          // }}
          >
            <View>
              <View
                style={{
                  marginLeft: helpers.isDesktop ? 10 : 0,
                  marginRight: helpers.isDesktop ? 10 : 0,
                  marginTop: helpers.isDesktop ? 10 : 0,
                  marginBottom: helpers.isDesktop ? 10 : 0,
                  justifyContent: "center",
                }}
              >
                <CustomImage
                  source={param.item.logo}
                  webStyle={{
                    width: "192",
                    height: "192",
                    maxWidth: "100%",
                    objectFit: "contain",
                    marginHorizontal: helpers.isDesktop() ? 0 : 0,
                  }}
                  style={{ width: 180, height: 180, resizeMode: "contain" }}
                  require={false}
                  className={""}
                  altText={param.item.name}
                  resizeMode={"contain"}
                />
                {darkMode && (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(18, 18, 18, 0.05)",
                    }}
                  ></View>
                )}
              </View>
              {this.state.activeSlide === param.index && (
                <>
                  {this.isUserSubscriptionActive == "0" ||
                  this.isUserSubscriptionActive == null ? (
                    <>
                      {this.notPremiumTagsDict[param.item.name] ===
                      true ? null : (
                        <View
                          style={{
                            position: "absolute",
                            top: helpers.isDesktop() ? 10 : 50,
                            right: 0,
                          }}
                        >
                          <Image
                            style={{ width: 85, height: 25 }}
                            source={require("../../../../assets/img/premium-tag.png")}
                          />
                        </View>
                      )}
                    </>
                  ) : null}
                </>
              )}
              {this.state.activeSlide === param.index ? (
                <View
                  style={{
                    marginLeft: helpers.isDesktop ? 10 : 0,
                    marginRight: helpers.isDesktop ? 10 : 0,
                    paddingBottom: 15,
                  }}
                >
                  <Text
                    numberOfLines={0}
                    style={[
                      { fontSize: 24 },
                      { fontFamily: FontFamily.abrilFatface, flexWrap: "wrap" },
                    ]}
                  >
                    {param.item.name}
                  </Text>
                  <View
                    style={{
                      backgroundColor: "#907CFF",
                      width: "50%",
                      height: 1,
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  ></View>

                  <View style={{ flexDirection: "row" }}>
                    <Image
                      source={require("../../../../assets/icons/calendarIcon.png")}
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 16,
                        alignSelf: "center",
                      }}
                    />
                    <Text
                      style={[
                        {
                          fontFamily: FontFamily.regular,
                          fontSize: helpers.isDesktop() ? 16 : 14,
                          lineHeight: helpers.isDesktop() ? 27 : 20,
                          color: "#000000",
                          marginBottom: helpers.isDesktop() ? 0 : 0,
                          letterSpacing: 0.3,
                        },
                        darkMode && { color: "black" },
                      ]}
                    >
                      {param.item.available === "Completed"
                        ? param.item.available
                        : `Published ${param.item.available}`}
                    </Text>
                  </View>
                </View>
              ) : null}
            </View>
            {this.state.activeSlide === param.index ? (
              <View
                style={{
                  flexDirection: "column",
                  marginLeft: 10,
                  marginRight: 10,
                  justifyContent: "space-between",
                }}
              >
                <Text
                  numberOfLines={5}
                  style={[
                    {
                      marginLeft: 0,
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 16 : 14,
                      color: "#292929",
                      marginBottom: helpers.isDesktop() ? 16 : 0,
                      letterSpacing: 0.3,
                      marginTop: 0,
                    },
                    { flexWrap: "wrap" },
                    darkMode && { color: "#292929" },
                  ]}
                >
                  {param.item.description}
                </Text>
              </View>
            ) : null}
          </View>
        </TouchableOpacityLink>
      </CardView>
    );
  };

  changePage = (nextIndex, isLast) => {
    this.setState({ activeSlide: nextIndex });
  };

  render() {
    const {
      wdata,
      subscriptionQuote,
      teamSubscribers,
      teamSubscribersMob,
      darkMode,
    } = this.props;
    const { activeSlide, slideItemWidth } = this.state;
    const { height, width } = Dimensions.get("window");
    const { data } = this.props;
    let webView = helpers.isWeb();
    // let intervals = data.length;
    let isWeb = helpers.isWeb();
    let btnClickNewsletter;

    let dotClick;
    let slide_num = parseInt(data.length / 2 + (data.length % 2)); // Find the number of slides
    let slide_num_arr = [...Array(slide_num).keys()];
    let itemWidth = 100 / 5 + "%";
    return (
      // <View style={{ flex: 1 }}>
      // <View style={{ flex: 1,flexDirection:'row',justifyContent:'space-between'}}>
      //   {/* <Swiper
      //    controlsProps={{
      //     dotsPos: 'bottom',
      //     nextTitle: "",
      //     prevTitle: "",
      //     dotActiveStyle: { backgroundColor: '#907CFF' },
      //     dotsTouchable: "true"

      //     // DotComponent: ({ index, isActive, onPress}) => <Text onPress={onPress}>{index+1}</Text>
      // }}
      //   index={1}>
      //     <SwiperItem />
      //     <SwiperItem />
      //     <SwiperItem />
      //     <SwiperItem />
      //     <SwiperItem />
      //     <SwiperItem />
      //   </Swiper> */}
      // </View>
      helpers.isDesktop() ? (
        <View style={{ flex: 1 }}>
          <View
            style={[article.headerWrapper, helpers.isDesktop() && Margin.mb_4]}
          >
            {/* <View style={[Flex.alignCenter, Margin.mb_5, !helpers.isDesktop() && Margin.mb_4]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap]}>
                            <Text style={[Helpers.textCenter,Helpers.textUppercase, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, Helpers.borderBottom, {fontSize: 18}, {fontFamily:FontFamily.regular} ,{fontSize: helpers.isDesktop() ? 30 : 18} ]}>Premium Newsletters 11</Text>
                        </View>
                        <View style={[Helpers.titleLine]}></View>
                    </View>  */}
            <View
              style={[
                Flex.alignCenter,
                Margin.mb_2,
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWeb1000,
                {
                  marginTop: helpers.isDesktop()
                    ? this.props.token
                      ? 80
                      : 30
                    : 40,
                },
              ]}
            >
              <View
                style={[
                  Helpers.titleBgWrap,
                  Padding.pl_2,
                  Padding.pr_2,
                  darkMode && { backgroundColor: Colors.darkBlackColorBg },
                  { backgroundColor: darkMode ? "black" : "white" },
                ]}
              >
                <View
                  style={[
                    Helpers.borderBottom,
                    darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      { fontSize: helpers.isDesktop() ? 34 : 24 },
                      { fontFamily: FontFamily.abrilFatface },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {" "}
                    {this.props.token
                      ? "Our Newsletters"
                      : `Free ${"&"} Premium Newsletters`}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  Helpers.titleLine,
                  helpers.isDesktop() && { width: "110%" },
                  {
                    backgroundColor: darkMode
                      ? "rgba(255,255,255,0.6)"
                      : "rgba(102, 102, 102, 0.6)",
                  },
                ]}
              ></View>
            </View>
            <View
              style={[
                !helpers.isDesktop() && Padding.pb_2,
                !helpers.isDesktop() && Padding.ph_2,
              ]}
            >
              <Text
                style={[
                  {
                    fontFamily: FontFamily.regular,
                    fontSize: helpers.isDesktop() ? 18 : 14,
                    lineHeight: helpers.isDesktop() ? 27 : 20,
                    color: "#000000",
                    textAlign: "center",
                    marginBottom: helpers.isDesktop() ? 24 : 0,
                    letterSpacing: 0.3,
                  },
                  darkMode && { color: "rgba(255,255,255,0.87)" },
                ]}
              >
                A wealth of knowledge from India's top writers curated for you
                by The Morning Context.
              </Text>
            </View>
          </View>
          <View style={{ height: "100%", maxHeight: "76%" }}>
            <View
              style={[
                {
                  position: "absolute",
                  top: "30%",
                  left: 10,
                  // { transform: [{ translateY: isWeb ? "-50%" : -30 }] },
                },
                activeSlide == 0 && { opacity: 1 },
              ]}
            >
              <TouchableOpacity
                style={[sliderStyle.arrowBtn]}
                onPress={() => this.prevSlide()}
                disable={activeSlide == 0 ? true : false}
              >
                <CustomImage
                  source={arrowLeft}
                  require={true}
                  style={{ width: 9, height: 15 }}
                  webStyle={{ width: 9, height: 15 }}
                />
              </TouchableOpacity>
            </View>

            <View
              style={[
                {
                  position: "absolute",
                  top: "30%",
                  right: 10,
                  // { transform: [{ translateY: isWeb ? "-50%" : -30 }] },
                },
                // { transform: [{ translateY: isWeb ? "-50%" : -30 }] },
                activeSlide == data.length && { opacity: 1 },
              ]}
            >
              <TouchableOpacity
                style={[sliderStyle.arrowBtn]}
                onPress={() => this.nextSlide()}
                disable={activeSlide == data.length ? true : false}
              >
                <CustomImage
                  source={arrowRight}
                  require={true}
                  style={{ width: 9, height: 15 }}
                  webStyle={{ width: 9, height: 15 }}
                />
              </TouchableOpacity>
            </View>

            <View
              style={{
                flexDirection: "row",
                marginLeft: 0,
                marginRight: 0,
                maxWidth: 1250,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Carousel
                style={styles.carousel}
                layout={"default"}
                activeSlideOffset={20}
                ref={(c) => {
                  this.numberCarousel = c;
                }}
                data={data}
                renderItem={this.addSlideContent}
                sliderWidth={1250}
                itemWidth={250}
                sliderHeight={500}
                itemHeight={510}
                containerWidth={1250}
                inActiveOpacity={0.7}
                activeSlideAlignment={"center"}
                onSnapToItem={(index) => {
                  this.setState({ activeSlide: index + 3 });
                }}
                // currentIndex={this.state.activeSlide}
                inactiveSlideOpacity={0.5}
                inactiveSlideScale={0.5}
                useScrollView={false}
                autoplay={true}
                enableMomentum={false}
                lockScrollWhileSnapping={true}
                autoplayInterval={2000}
                autoplayDelay={2000}
                loop={true}
                // loopClonesPerSide={2}
                slideStyle={{
                  width: 250,
                  overflow: "hidden",
                }}
              />
            </View>
          </View>

          {/* <Swiper
            // direction={"column"}
            loop
            timeout={3}
            controlsProps={{
              dotsPos: "bottom",
              nextTitle: "→",
              nextTitleStyle: {
                marginTop: 300,
                marginRight: 20,
                fontSize: 25,
                color: "#FC876D",
              },
              prevTitleStyle: {
                marginTop: 300,
                marginLeft: 20,
                fontSize: 25,
                color: "#FC876D",
              },
              nextPos: "top-right",
              prevPos: "top-left",
              prevTitle: "←",
              dotActiveStyle: { backgroundColor: "#907CFF" },
              dotsWrapperStyle: { marginBottom: "-2px", marginTop: 30 },
              dotsTouchable: "true",

              // DotComponent: ({ index, isActive, onPress}) => <Text onPress={onPress}>{index+1}</Text>
            }}
            autoplayTimeout={-2.5}
            overRangeButtonsOpacity={0.3}
            controlsEnabled={true}
            minDistanceForAction={0.15}
            springConfig={{ speed: 100 }}
          >
            {slide_num_arr.map((d, i) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    marginLeft: 60,
                    marginRight: 60,
                  }}
                  key={`landingSlider_${i}`}
                >
                  {data &&
                    data
                      .slice(d * 2, d * 2 + 2)
                      .map((item, index) => this.addSlideContent(item, index))}
                </View>
              );
            })}

            
          </Swiper> */}
        </View>
      ) : (
        <>
          <Text
            style={[
              Helpers.textCenter,
              { marginTop: !helpers.isDesktop ? 80 : 50 },
              Typo.h3_R,
              helpers.isDesktop() && Typo.h3_RDesktop,
              Margin.mb_0,
              { fontSize: helpers.isDesktop() ? 34 : 34 },
              { fontFamily: FontFamily.abrilFatface },
              { color: darkMode ? "rgba(255,255,255,0.87)" : "#051462" },
            ]}
          >
            {this.props.token
              ? "Our Newsletters"
              : `Free ${"&"} Premium Newsletters`}
          </Text>
          <View
            style={[!helpers.isDesktop() && Padding.ph_2, { marginTop: 16 }]}
          >
            <Text
              style={[
                {
                  fontFamily: FontFamily.regular,
                  fontSize: helpers.isDesktop() ? 18 : 16,
                  lineHeight: helpers.isDesktop() ? 27 : 20,
                  color: "#000000",
                  textAlign: "center",
                  marginBottom: helpers.isDesktop() ? 24 : 0,
                  letterSpacing: 0.3,
                },
                darkMode && { color: "rgba(255,255,255,0.87)" },
              ]}
            >
              A wealth of knowledge from India's top writers curated for you by
              The Morning Context.
            </Text>
          </View>
          <ScrollContainerView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
          >
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                !process.browser && { overflow: "hidden" },
                !webView && { width: "100%" },
              ]}
            >
              {data &&
                data.map((item, index) => {
                  if (helpers.isWeb()) {
                    btnClickNewsletter = {
                      to: "/newsletters/" + item.slug,
                    };
                  } else {
                    btnClickNewsletter = {
                      onPress: () => this.props.getNewsletterClick(item.slug),
                    };
                  }
                  return (
                    <View style={[!helpers.isDesktop() && { marginTop: 40 }]}>
                      <TouchableOpacityLink
                        {...btnClickNewsletter}
                        handleClick={() => {
                          this.trackNewsletterClick(item, index);
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <View
                            style={{ flexDirection: "row", marginRight: 20 }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <CustomImage
                                source={item.logo}
                                webStyle={{
                                  width: 110,
                                  height: 77,
                                  objectFit: "contain",
                                }}
                                style={{ width: 110, height: 110 }}
                                require={false}
                                className={""}
                                altText={item.name}
                                resizeMode={"contain"}
                              />
                              {darkMode && (
                                <View
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(18, 18, 18, 0.05)",
                                  }}
                                ></View>
                              )}
                            </View>

                            {/* !this.state.isfree[index]&& */}
                            {this.isUserSubscriptionActive == "0" ||
                            this.isUserSubscriptionActive == null ? (
                              <>
                                {this.notPremiumTagsDict[item.name] ===
                                true ? null : (
                                  <View
                                    style={{
                                      position: "absolute",
                                      top: helpers.isDesktop() ? 10 : 0,
                                      right: 0,
                                    }}
                                  >
                                    <Image
                                      style={{ width: 85, height: 25 }}
                                      source={require("../../../../assets/img/premium-tag.png")}
                                    />
                                  </View>
                                )}
                              </>
                            ) : null}

                            <View style={{ marginTop: 20, marginLeft: 20 }}>
                              <Text
                                style={[
                                  { fontSize: 18 },
                                  { fontFamily: FontFamily.abrilFatface },
                                ]}
                              >
                                {item.name}
                              </Text>
                              <View
                                style={{
                                  backgroundColor: "#907CFF",
                                  width: "50%",
                                  height: 1,
                                  marginTop: 16,
                                  marginBottom: 16,
                                }}
                              ></View>
                              <View style={{ flexDirection: "row" }}>
                                <Image
                                  source={require("../../../../assets/icons/calendarIcon.png")}
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginRight: 16,
                                    alignSelf: "center",
                                  }}
                                />
                                <Text
                                  style={[
                                    {
                                      fontFamily: FontFamily.regular,
                                      fontSize: helpers.isDesktop() ? 22 : 16,
                                      lineHeight: helpers.isDesktop() ? 27 : 20,
                                      color: "#000000",
                                      marginBottom: helpers.isDesktop() ? 0 : 0,
                                      letterSpacing: 0.3,
                                    },
                                    darkMode && { color: "black" },
                                  ]}
                                >
                                  {item.available === "Completed"
                                    ? item.available
                                    : `Published ${item.available}`}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              marginTop: 20,
                              width: 300,
                            }}
                          >
                            <Text
                              style={[
                                {
                                  marginLeft: 14,
                                  fontFamily: FontFamily.regular,
                                  fontSize: helpers.isDesktop() ? 25 : 14,
                                  color: "#292929",
                                  flex: 1,
                                  flexWrap: "wrap",
                                  marginBottom: helpers.isDesktop() ? 16 : 0,
                                  letterSpacing: 0.3,
                                  marginTop: 0,
                                },
                                { flexWrap: "wrap" },
                                darkMode && { color: "#292929" },
                              ]}
                            >
                              {item.description}
                            </Text>
                          </View>
                          <Text
                            style={{
                              marginLeft: 15,
                              fontSize: 30,
                              color: "#FC876D",
                              marginTop: 10,
                            }}
                          >
                            →
                          </Text>
                        </View>
                      </TouchableOpacityLink>
                    </View>
                  );
                })}
            </View>
          </ScrollContainerView>
        </>
      )
    );
    // return (
    //   <>
    //   <Button title="<-" onPress={() => {
    //                         console.log("left button pressed")
    //                         {data && data.map((item, index) => {
    //                           this.slideTo(index+1) })}
    //                     }}>

    //                     </Button>
    //     <View style={[sliderStyle.wrapper, helpers.isDesktop() && { maxWidth: 980, marginLeft: 'auto', marginRight: 'auto' }, { backgroundColor: 'transparent' }, teamSubscribers && { maxWidth: helpers.isDesktop() ? 520 : '100%' }, !isWeb && { width: 'auto' }]}>
    //         <ScrollView
    //             contentContainerStyle={{ width: `${100 * intervals}%` ,backgroundColor:'red'}}
    //             horizontal={true}
    //             showsHorizontalScrollIndicator={false}
    //             pagingEnabled={true}
    //             onScroll={data => {
    //                 this.getSlideOffset(data.nativeEvent.contentOffset.x, data.nativeEvent.contentSize.width)
    //                 this.getSlideIndex()
    //             }}
    //             scrollEventThrottle={200}
    //             decelerationRate="fast"
    //             ref={ref => this.scrollView = ref}
    //         >
    //             {data && data.map((item, index) => {
    //                if (helpers.isWeb()) {
    //                 btnClickNewsletter = {
    //                     to: "/newsletters/" + item.slug
    //                 }
    //             } else {
    //                 btnClickNewsletter = {
    //                     onPress: () => this.props.getNewsletterClick(item.slug)
    //                 }
    //             }
    //                 return (
    //                   <View style={[articleItem.col4, helpers.isDesktop() && articleItem.col4Desktop, Flex.justfiyCenter, !(index == 0) && { borderLeftWidth: darkMode ? 0 : 0.5, borderColor: '#999999', height: 180, paddingHorizontal: 10 }, (darkMode && (index == 0)) && { paddingLeft: 10, paddingRight: 10 }]}>
    //                   <TouchableOpacityLink {...btnClickNewsletter} handleClick={() => { this.trackNewsletterClick(item, index) }} >
    //                       <View style={{ alignItems: 'center', position: 'relative' }}>
    //                           <CustomImage
    //                               source={item.logo}
    //                               webStyle={{ width: 'auto', height: 'auto', maxWidth: '100%', objectFit: 'contain', marginHorizontal: helpers.isDesktop() ? 35 : 0 }}
    //                               style={{ width: 180, height: 180, resizeMode: 'contain' }}
    //                               require={false}
    //                               className={''}
    //                               altText={item.name}
    //                               resizeMode={'contain'}
    //                           />
    //                           {
    //                               darkMode &&
    //                               <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>
    //                               </View>
    //                           }
    //                       </View>
    //                   </TouchableOpacityLink>
    //               </View>
    //                     // <View key={`slide_${index}`} style={[sliderStyle.sliderOuterItem, helpers.isDesktop() && sliderStyle.sliderOuterItemDesktop, { width: slideItemWidth, paddingTop: 25 }, !helpers.isDesktop() && !teamSubscribers && { marginRight: 20 }, !isWeb && { marginRight: 0, paddingHorizontal: 5 }]}>
    //                     //     <View style={[{ maxWidth: helpers.isDesktop() ? 980 : '100%' }, { backgroundColor: '#ebfbed', borderWidth: 0, borderColor: '#55C79A' }, subscriptionQuote && { borderWidth: 0 }, !helpers.isDesktop() && { height: 402 }, teamSubscribers && { borderWidth: 0, maxWidth: helpers.isDesktop() ? 520 : '100%' }, teamSubscribers && !helpers.isDesktop() && { height: 'auto' }, darkMode && { backgroundColor: Colors.darkBlackColor1, borderColor: Colors.darkBlackColor1 }]}>
    //                     //         <View style={[!teamSubscribersMob && sliderStyle.sliderItem, helpers.isDesktop() && sliderStyle.sliderItemDesktop, teamSubscribers && helpers.isDesktop() && { minHeight: 385, paddingTop: 60 }]}>
    //                     //             {!teamSubscribersMob && <View style={[helpers.isDesktop() ? Padding.pl_0 : Padding.pl_15, { position: 'absolute', left: helpers.isDesktop() ? 50 : 0, top: helpers.isDesktop() ? -20 : -10 }]}>
    //                     //                 <CustomImage
    //                     //                     source={data.logo}
    //                     //                     require={true}
    //                     //                     style={{ width: 30, height: 25, resizeMode: 'contain' }}
    //                     //                     webStyle={helpers.isDesktop() ? { width: 70, height: 57, objectFit: 'contain' } : { width: 30, height: 25, objectFit: 'contain' }}
    //                     //                 />
    //                     //             </View>}
    //                     //             {/* {!teamSubscribers ? */}
    //                     //                 <View style={[{ paddingHorizontal: helpers.isDesktop() ? 120 : 25 }]}>
    //                     //                     <Text style={[sliderStyle.desc, helpers.isDesktop() && sliderStyle.descDesktop, darkMode && { color: Colors.lightWhite }]}>{item.description}</Text>
    //                     //                     {/* <Text>
    //                     //                         <Text style={[sliderStyle.name, helpers.isDesktop() && sliderStyle.nameDesktop, darkMode && { color: Colors.green }, !helpers.isWeb() && { fontFamily: FontFamily.medium, lineHeight: 24 }]}>{item.by},</Text>
    //                     //                         <Text style={[sliderStyle.position, helpers.isDesktop() && sliderStyle.positionDesktop, { fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 20 : 13, lineHeight: helpers.isDesktop() ? 32 : 24, alignItems: 'baseline', marginLeft: 0 }, !helpers.isWeb() && { fontFamily: FontFamily.medium, lineHeight: 24 }, darkMode && { color: Colors.green }]}> {item.designation}</Text>
    //                     //                     </Text> */}
    //                     //                 </View>
    //                     //                 {/* :
    //                     //                 <View style={[helpers.isDesktop() && Flex.row, !helpers.isDesktop() && { flexDirection: 'column-reverse' }]}>
    //                     //                     <View style={[{ paddingHorizontal: helpers.isDesktop() ? 25 : 0, textAlign: 'left', width: '100%' }]}>
    //                     //                         <Text style={[sliderStyle.desc, helpers.isDesktop() && sliderStyle.descDesktop, !helpers.isDesktop() && { paddingTop: 0, paddingBottom: 0, fontSize: 18, lineHeight: 24 }, darkMode && { color: Colors.lightWhite }]}>{item.text}</Text>
    //                     //                         <Text>

    //                     //                             <Text style={[sliderStyle.name, helpers.isDesktop() && sliderStyle.nameDesktop, !helpers.isDesktop() && { fontFamily: FontFamily.regular, fontWeight: '600', fontSize: 18, lineHeight: 24, color: 'rgba(0,0,0,0.9)' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.green }]}>{item.by},</Text>
    //                     //                             <Text style={[sliderStyle.position, helpers.isDesktop() && sliderStyle.positionDesktop, { fontFamily: FontFamily.regular, fontWeight: helpers.isDesktop() ? '400' : '600', fontSize: helpers.isDesktop() ? 20 : 18, lineHeight: helpers.isDesktop() ? 32 : 24, alignItems: 'baseline', marginLeft: 0 }, !helpers.isDesktop() && { color: 'rgba(0, 0, 0, 0.9)' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.green }]}> {item.designation}</Text>
    //                     //                         </Text>
    //                     //                     </View>
    //                     //                 </View>
    //                     //             } */}
    //                     //         </View>
    //                     //     </View>
    //                     // </View>
    //                 )
    //             })}
    //         </ScrollView>

    //         {/* { helpers.isDesktop() &&
    //             <View style={[sliderStyle.arrowLeft, { transform: [{ translateY: isWeb ? '-50%' : -30 }] }, activeSlide == 1 && { opacity: 1 }]}>
    //                 <TouchableOpacity
    //                     style={[sliderStyle.arrowBtn]}
    //                     onPress={() => this.prevSlide()}
    //                     disable={activeSlide == 1 ? true : false}
    //                 >
    //                     <CustomImage
    //                         source={arrowLeft}
    //                         require={true}
    //                         style={{ width: 9, height: 15 }}
    //                         webStyle={{ width: 9, height: 15 }}
    //                     />
    //                 </TouchableOpacity>
    //             </View>
    //         }
    //         { helpers.isDesktop() &&
    //             <View style={[sliderStyle.arrowRight, { transform: [{ translateY: isWeb ? '-50%' : -30 }] }, activeSlide == data.length && { opacity: 1 }]}>
    //                 <TouchableOpacity
    //                     style={[sliderStyle.arrowBtn]}
    //                     onPress={() => this.nextSlide()}
    //                     disable={activeSlide == data.length ? true : false}
    //                 >
    //                     <CustomImage
    //                         source={arrowRight}
    //                         require={true}
    //                         style={{ width: 9, height: 15 }}
    //                         webStyle={{ width: 9, height: 15 }}
    //                     />
    //                 </TouchableOpacity>
    //             </View>
    //         } */}
    //         <View style={[sliderStyle.dotWrapper, { bottom: teamSubscribers ? (helpers.isDesktop() ? 60 : -10) : helpers.isDesktop() ? 60 : 40 }, teamSubscribers && { justifyContent: 'flex-start', paddingLeft: helpers.isDesktop() ? 50 : 0 }]}>
    //             {data && data.map((item, index) => {
    //                 if (isWeb) {
    //                     dotClick = {
    //                         onPress: (event) => {
    //                             this.slideTo(index)
    //                         }
    //                     }
    //                 } else {
    //                     dotClick = {
    //                         onPress: (event) => {
    //                             this.slideTo(index)
    //                         }
    //                     }
    //                 }
    //                 return (
    //                     <TouchableOpacity {...dotClick}>
    //                         {!darkMode ?
    //                             <View key={`dot_${index}`} style={[sliderStyle.dot, (index) == activeSlide && sliderStyle.activeDot]} />
    //                             :
    //                             <View key={`dot_${index}`} style={[sliderStyle.dotDark, (index) == activeSlide && sliderStyle.activeDotDark]} />
    //                         }
    //                     </TouchableOpacity>
    //                 )
    //             })}
    //         </View>
    //     </View>
    //     <Button title="->" onPress={() => {
    //                         {data && data.map((item, index) => {
    //                           this.slideTo(index+1)
    //                          console.log("right button pressed") })}

    //                      }}>

    //         </Button>
    //     </>
    // );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    flex: 1,
    width: 200,
    height: 200,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#dbf3fa",
  },
  carousel: {
    flexGrow: 0,
    // height: 150,
  },
});

export default AuthenticationWrapper(PremiumSlider);
