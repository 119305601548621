import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
  Animated,
  TouchableOpacity,
  Linking,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  button,
  Typo,
  siteFooter,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";
import ContentLoader from "react-native-easy-content-loader";
import EmailLink from "../email-link";
import { Mixpanel } from "../../utils/mixpanel";
import { Link } from "react-router-dom";
import AuthenticationWrapper from "../authentication-wrapper";

const footerFB = getImagePath("icons/footerFB.png");
const footerTwitter = getImagePath("icons/footerTwitter.png");
const footerIn = getImagePath("icons/footerIn.png");
const footerInsta = getImagePath("icons/instagram.png");
const footerYoutube = getImagePath("icons/youtube.png");
const footerTelegram = getImagePath("icons/footerTelegram.png");
const footerWhatsApp = getImagePath('icons/whatsapp-icon-white.svg');
const footerLogo = getImagePath("icons/footer-logo-icon.svg");
const homeIcon = getImagePath("icons/homeIcon.png");

const greyQuoteLeft = getImagePath("icons/greyQuoteLeft.png");
const greyQuoteRight = getImagePath("icons/greyQuoteRight.png");

const footerLogoLight = getImagePath("icons/tmc-footer-logo.png");

import FooterAccordian from "../../components/footer-accordian";
const YesterdayLogoWjote = getImagePath("img/YesterdayLogoWjote.png");
import ButtonGradient from "../../components/button-gradient";
const yesterdayBadge = getImagePath("img/yesterdayBadge.png");

let mail = "";

export class AppFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: this.props.footerData ? this.props.footerData.menu : false,
      copyright: this.props.footerData
        ? this.props.footerData.copyright
        : false,
      loadSSR: this.props.footerData ? true : false,
      current_path: window.location.href.split("/"),
      custom_user_id: false
    };
  }

  componentDidMount = () => {
    console.log(this.props.freeUser, "free user status in footer page");
    if (!this.state.loadSSR) {
      this.getFooter();
    }
    if (!this.state.custom_user_id) {
      this.setAuthToken();
    }
  };

  getFooter = () => {
    let endpoint = `/footer-menu`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true)
      .then((res) => {
        if (res.success && res.code == "footer_menu") {
          this.setState({
            menu: res.data.menu,
            copyright: res.data.copyright,
            current_path: window.location.href.split("/"),
          });
          // this.state.menu.append([{name:"TMC Subscriber App"}])
          // console.log("menu items",this.state.menu)
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  footerLoader = () => {
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
        ]}
      >
        <ContentLoader
          containerStyles={{ marginBottom: 15 }}
          active={true}
          pHeight={[20]}
          title={false}
          pRows={1}
          pWidth={[140]}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[10]}
          title={false}
          pRows={1}
          pWidth={[120]}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[10]}
          title={false}
          pRows={1}
          pWidth={[120]}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[10]}
          title={false}
          pRows={1}
          pWidth={[120]}
          animationDuration={400}
        />
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[10]}
          title={false}
          pRows={1}
          pWidth={[120]}
          animationDuration={400}
        />
      </View>
    );
  };

  pushBottomNavigationEventToDataLayer = (item, sectionName) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "bottom_navigation_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      element_title: item.name.toLowerCase(),
      header_title: sectionName.toLowerCase()
    });
  };

  pushSocialMediaEventToDataLayer = (socialMediaName) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "social_media_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      element_title: socialMediaName.toLowerCase(),
      header_title: "follow us on",
      scrollDepth: helpers.getScrollDepth()
    });
  }


  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false,
        });
      } else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id,
        });
      }
    });
  };

  trackCateoryClick = (item, sectionName) => {
    try {
      this.pushBottomNavigationEventToDataLayer(item, sectionName)
      if (item.link.includes("/category")) {
        Mixpanel.track("category", {
          category_slug: item.link,
          source_section_type: "footer",
        });
      }
    } catch (error) {
      console.log("mix panel error =>", error);
    }
  };

  returnItemName = (itemName, index) => {
    const itemColors = {
      Internet: "#FC876D",
      Business: "#2CB680",
      Chaos: "#907CFF",
      Opinion: "#ECB731",
      "All Stories": "#FFFFFF",
      Yesterday: "#907CFF",
    };
    let selectedColor = itemColors[itemName] || "#FFFFFF";
    return (
      <View style={{ marginBottom: 8 }}>
        <Text
          style={[
            siteFooter.menuListItem,
            { color: selectedColor },
            helpers.isDesktop() && siteFooter.menuListItemDesktop,
          ]}
        >
          {itemName.toUpperCase()}
        </Text>
      </View>
    );
  };

  /**
   * Prevent click event to navigate on click of Email
   * @param {*} e : Event
   */
  stopNavigation = (e) => {
    e.preventDefault();
  };

  render() {
    const { menu, copyright, current_path } = this.state;
    const webView = helpers.isWeb();
    let btnClickFB,
      btnClickTwitter,
      btnClickLinkdin,
      btnClickTerms,
      btnClickPrivacy,
      btnClickFaq,
      btnClickYoutube,
      btnClickTelegram,
      btnClickWhatsApp,
      btnClickInsta;
    // let btnClickFB, btnClickTwitter, btnClickLinkdin, btnClickTerms, btnClickPrivacy, btnClickFaq;
    let { Breadcrumbs } = this.props;
    // // let footerData = this.state.menu
    // // footerData.append([{name:"TMC Subscriber App"}])
    // let newItem = [{name:"TMC Subscriber App"}]
    // this.setState({menu:[...menu,newItem]})
    if (webView) {
      btnClickFB = {
        href: "https://www.facebook.com/Morningcontext/",
        handleClick: () => this.pushSocialMediaEventToDataLayer("Facebook")
      };
      btnClickTwitter = {
        href: "https://twitter.com/MorningContext",
        handleClick: () => this.pushSocialMediaEventToDataLayer("Twitter")
      };
      btnClickLinkdin = {
        href: "https://www.linkedin.com/company/the-morning-context",
        handleClick: () => this.pushSocialMediaEventToDataLayer("LinkedIn")
      };
      btnClickYoutube = {
        href: "https://www.youtube.com/channel/UCYAZQvp_LMnL_IAVB8L-rOQ",
        handleClick: () => this.pushSocialMediaEventToDataLayer("Youtube")
      };
      btnClickTelegram = {
        href: "https://t.me/+hicP5Chbr0MxYTI1",
        handleClick: () => this.pushSocialMediaEventToDataLayer("Telegram")
      };
      btnClickWhatsApp = {
        href: "https://www.whatsapp.com/channel/0029VaBON51InlqTO0if5g1f",
        handleClick: () => this.pushSocialMediaEventToDataLayer("WhatsApp")
      };
      btnClickInsta = {
        href: "https://www.instagram.com/themorningcontext/",
        handleClick: () => this.pushSocialMediaEventToDataLayer("Instagram")
      };
      btnClickTerms = {
        to: "/terms-and-conditions",
      };
      btnClickPrivacy = {
        to: "/privacy-policy",
      };
      btnClickFaq = {
        to: "/faq",
      };
    } else {
      btnClickFB = {
        onPress: () => navigateApp("/"),
      };
      btnClickTwitter = {
        onPress: () => navigateApp("/"),
      };
      btnClickLinkdin = {
        onPress: () => navigateApp("/"),
      };
      btnClickInsta = {
        onPress: () => navigateApp("/"),
      };
      btnClickYoutube = {
        onPress: () => navigateApp("/"),
      };
      btnClickTelegram = {
        onPress: () => navigateApp("/"),
      };
      btnClickWhatsApp = {
        onPress: () => navigateApp("/"),
      };
      btnClickTerms = {
        onPress: () => navigateApp("/"),
      };
      btnClickPrivacy = {
        onPress: () => navigateApp("/"),
      };
      btnClickFaq = {
        onPress: () => navigateApp("/"),
      };
    }

    return (
      // <View style={[siteFooter.wrapper, Padding.pt_7, Padding.pb_7, !helpers.isDesktop() && Padding.pt_4]}>
      //     <View style={[Padding.ph_2, helpers.isDesktop() && Flex.row, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull]}>
      //         <View style={[siteFooter.col_1, helpers.isDesktop() && siteFooter.col_1Desktop, siteFooter.borderRightCol, helpers.isDesktop() && siteFooter.borderRightColDesktop]}>
      //             <View style={[]}>
      //                 <Text style={[Typo.h3_M, helpers.isDesktop() && Typo.h3_MDesktop, Margin.mb_4, { color: '#ffffff' }, !helpers.isDesktop() && [Helpers.textCenter, Margin.mb_2]]}>Follow us on</Text>
      //             </View>

      //             <View style={[Flex.row, Padding.pb_9, !helpers.isDesktop() && [Flex.justfiyCenter, Padding.pb_4, { borderBottomWidth: 1, borderBottomColor: 'rgba(255, 255, 255, 0.5)', marginBottom: 35 }]]}>
      //                 <TouchableOpacityLinkExternal {...btnClickFB} newTab={true}>
      //                     <CustomImage
      //                         source={footerFB}
      //                         require={true}
      //                         style={{ width: 21, height: 22, marginRight: 40 }}
      //                         webStyle={{ width: 21, height: 22, marginRight: 40 }}
      //                         altText={'Facebook'}
      //                     />
      //                 </TouchableOpacityLinkExternal>
      //                 <TouchableOpacityLinkExternal {...btnClickTwitter} newTab={true}>
      //                     <CustomImage
      //                         source={footerTwitter}
      //                         require={true}
      //                         style={{ width: 29, height: 22, marginRight: 40 }}
      //                         webStyle={{ width: 29, height: 22, marginRight: 40 }}
      //                         altText={'Twitter'}
      //                     />
      //                 </TouchableOpacityLinkExternal>
      //                 <TouchableOpacityLinkExternal {...btnClickLinkdin} newTab={true}>
      //                     <CustomImage
      //                         source={footerIn}
      //                         require={true}
      //                         style={{ width: 21, height: 22 }}
      //                         webStyle={{ width: 21, height: 22 }}
      //                         altText={'Linkedin'}
      //                     />
      //                 </TouchableOpacityLinkExternal>
      //             </View>
      //             {
      //                 helpers.isDesktop() &&
      //                 <View>
      //                     <CustomImage
      //                         source={footerLogo}
      //                         require={true}
      //                         style={{ width: 160, height: 65, resizeMode: 'contain' }}
      //                         webStyle={{ width: 160, height: 62, objectFit: 'contain' }}
      //                         altText={'The Morning Context'}
      //                     />
      //                 </View>
      //             }
      //         </View>
      //         <View style={[Flex.row, Flex.fill, Flex.FlexWrap]}>
      //             {menu ?
      //                 menu.map((section, secIndex) => {
      //                     return <View key={secIndex} style={[siteFooter.menu, helpers.isDesktop() && siteFooter.menuDesktop]}>
      //                         <Text style={[siteFooter.menuTitle]}>{section.name}</Text>
      //                         <View style={[siteFooter.menuList]}>
      //                             {section['sub-menu'].map((item, itemIndex) => {
      //                                 let btnClick;
      //                                 if (webView) {
      //                                     btnClick = {
      //                                         to: item.link
      //                                     }
      //                                     if(item.type == 'email') {
      //                                         btnClick = {
      //                                             href:`mailto:${item.link}`
      //                                         }
      //                                     }
      //                                 }
      //                                 else {
      //                                     btnClick = {
      //                                         onPress: () => navigateApp(data.category.slug)
      //                                     }
      //                                 }
      //                                 if (item.type == 'internal' || item.type == 'external') {
      //                                     return <TouchableOpacityLink key={itemIndex} {...btnClick} handleClick={() => this.trackCateoryClick(item)}>
      //                                         <View style={{}}>
      //                                             <Text style={[siteFooter.menuListItem, helpers.isDesktop() && siteFooter.menuListItemDesktop]}>{item.name}</Text>
      //                                         </View>
      //                                     </TouchableOpacityLink>
      //                                 }
      //                                 else if (item.type == 'email') {
      //                                     return (
      //                                         <EmailLink {...btnClick}>
      //                                             <View>
      //                                                 <Text style={[siteFooter.menuListItem, helpers.isDesktop() && siteFooter.menuListItemDesktop]}>{item.name}</Text>
      //                                             </View>
      //                                         </EmailLink>
      //                                     )
      //                                 }
      //                             })
      //                             }
      //                         </View>
      //                     </View>
      //                 })
      //                 :
      //                 [0, 1, 2, 3, 4, 5].map((item, index) => {
      //                     return (
      //                         <View key={index} style={[siteFooter.menu, helpers.isDesktop() && siteFooter.menuDesktop]}>
      //                             {this.footerLoader()}
      //                         </View>
      //                     )
      //                 })
      //             }
      //         </View>
      //     </View>
      //     <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000, Flex.row, Flex.alignCenter, Margin.mt_7, Flex.justifyBetween, !helpers.isDesktop() && [Margin.mt_0, Flex.column]]}>
      //         <View style={[Flex.row, Flex.alignCenter, !helpers.isDesktop() && [Flex.column, { width: '100%', borderTopWidth: 1, borderBottomWidth: 1, borderTopColor: 'rgba(255, 255, 255, 0.5)', borderBottomColor: 'rgba(255, 255, 255, 0.5)', paddingVertical: 25 }]]}>
      //             <Text style={[siteFooter.footerTextRegular, { marginRight: 15 }, !helpers.isDesktop() && { marginBottom: 7 }]}>©{copyright} Slowform Holdings Limited </Text><Text style={[siteFooter.footerTextLight]}>68 Circular Road, #02-01, 049422, Singapore</Text>
      //         </View>
      //         <View style={[Flex.row, Flex.alignCenter, !helpers.isDesktop() && [Flex.justfiyCenter, Padding.pt_2]]}>
      //             <TouchableOpacityLink {...btnClickTerms}><Text style={[siteFooter.footerLinks]}>Terms of service</Text></TouchableOpacityLink>
      //             <View style={[siteFooter.dotSeprator]} />
      //             <TouchableOpacityLink {...btnClickPrivacy}><Text style={[siteFooter.footerLinks]}>Privacy policy</Text></TouchableOpacityLink>
      //         </View>
      //     </View>
      // </View>
      <View style={{ backgroundColor: "#363839", overflowX: "hidden" }}>
        {/* {Breadcrumbs && Breadcrumbs.length > 0 &&
                    <View style={{ backgroundColor: '#363839', paddingVertical: 25, paddingHorizontal: 20 }}>
                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, { flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }]}>
                            <View style={{ flexDirection: 'row', alignItems: helpers.isDesktop() ? 'center' : 'flex-start', flex: 1, flexWrap: 'wrap' }}>
                                <CustomImage
                                    source={homeIcon}
                                    require={true}
                                    style={{ width: 17, height: 16, resizeMode: 'contain' }}
                                    webStyle={{ width: 17, height: 16, objectFit: 'contain', marginRight: 10, position: 'relative', top: helpers.isDesktop() ? -2 : 0 }}
                                    altText={'The Morning Context'}
                                />
                                <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, flexWrap: 'wrap' }}>
                                    {
                                        Breadcrumbs.map((item, index) => {
                                            let link = {};
                                            if (item.link) {
                                                link = {
                                                    to: item.link
                                                }
                                            }
                                            return (
                                                <TouchableOpacityLink key={item.name} {...link}>
                                                    <Text style={{ fontSize: helpers.isDesktop() ? 18 : 15, fontFamily: FontFamily.merriweather, color: '#DDDDDD', lineHeight: helpers.isDesktop() ? 27 : 22 }}>{item.name} {index < Breadcrumbs.length - 1 && <Text style={{ marginHorizontal: 10 }}>></Text>}</Text>
                                                </TouchableOpacityLink>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                } */}

        <View style={{ position: "relative" }}>
          <View
            style={{
              backgroundColor: "#363839",
              paddingTop: 25,
              paddingBottom: 0,
              paddingHorizontal: 20,
            }}
          >
            <View
              style={[
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWebFull,
                { position: "relative", zIndex: 1 },
              ]}
            >
              {helpers.isDesktop() && (
                <View
                  style={[
                    Flex.row,
                    Flex.justifyBetween,
                    Flex.alignCenter,
                    {
                      paddingBottom: 40,
                      marginBottom: 40,
                      borderBottomWidth: 0.3,
                      borderColor: "#ffffff",
                    },
                  ]}
                >
                </View>
              )}
              <View
                style={{
                  flexDirection: helpers.isDesktop() ? "row" : "column",
                }}
              >
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "20%" : "100%",
                      maxWidth: helpers.isDesktop() ? "100%" : "100%",
                      marginRight: helpers.isDesktop() ? "auto" : "auto",
                      marginLeft: helpers.isDesktop() ? 0 : 0,
                    },
                  ]}
                >
                  {helpers.isDesktop() ? (
                    <View
                      style={{
                        marginLeft: 0,
                        paddingTop: helpers.isDesktop() ? 50 : 0,
                      }}
                    >
                      <Text style={[siteFooter.followTitle]}>Follow us on</Text>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: helpers.isDesktop() ? 0 : 30,
                          marginTop: helpers.isDesktop() ? 30 : 0,
                        }}
                      >
                        <TouchableOpacityLinkExternal
                          {...btnClickFB}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerFB}
                            require={true}
                            style={{ width: 21, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 21,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Facebook"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickTwitter}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerTwitter}
                            require={true}
                            style={{ width: 29, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 29,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Twitter"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickLinkdin}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerIn}
                            require={true}
                            style={{ width: 21, height: 22 }}
                            webStyle={{ width: 21, height: 22 }}
                            altText={"Linkedin"}
                          />
                        </TouchableOpacityLinkExternal>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: helpers.isDesktop() ? 50 : 30,
                          marginTop: helpers.isDesktop() ? 30 : 0,
                        }}
                      >
                        <TouchableOpacityLinkExternal
                          {...btnClickInsta}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerInsta}
                            require={true}
                            style={{ width: 24, height: 24, marginRight: 30 }}
                            webStyle={{
                              width: 24,
                              height: 24,
                              marginRight: 30,
                            }}
                            altText={"Instagram"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickYoutube}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerYoutube}
                            require={true}
                            style={{ width: 29, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 29,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Youtube"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickWhatsApp}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerWhatsApp}
                            require={true}
                            style={{ width: 29, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 29,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"WhatsApp"}
                          />
                        </TouchableOpacityLinkExternal>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: helpers.isDesktop() ? 50 : 30,
                        }}
                      >
                        <CustomImage
                          source={footerLogo}
                          require={true}
                          style={{
                            width: 160,
                            height: 62,
                            resizeMode: "contain",
                          }}
                          webStyle={{
                            width: 160,
                            height: 62,
                            objectFit: "contain",
                            marginRight: 12,
                          }}
                          altText={"The Morning Context"}
                        />
                      </View>
                      {/* <View><Text style={{ fontSize: 20, fontWeight: '600', fontFamily: FontFamily.regular, color: '#ffffff', lineHeight: 35, textTransform: 'uppercase', letterSpacing: 0.6 }}><Text style={{ color: '#FC876D' }}>Context</Text> is</Text></View>
                                                <View><Text style={{ fontSize: 20, fontWeight: '600', fontFamily: FontFamily.regular, color: '#ffffff', lineHeight: 35, textTransform: 'uppercase', letterSpacing: 0.6 }}>everything.</Text></View>
                                                <View><Text style={{ fontSize: 20, fontWeight: '600', fontFamily: FontFamily.regular, color: '#ffffff', lineHeight: 35, textTransform: 'uppercase', letterSpacing: 0.6 }}>Only read stories</Text></View>
                                                <View><Text style={{ fontSize: 20, fontWeight: '600', fontFamily: FontFamily.regular, color: '#ffffff', lineHeight: 35, textTransform: 'uppercase', letterSpacing: 0.6 }}>that <Text style={{ color: '#FC876D' }}>matter</Text>.</Text></View> */}
                    </View>
                  ) : (
                    <View
                      style={{
                        marginLeft: 0,
                        padding: helpers.isDesktop() ? 50 : 0,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: helpers.isDesktop() ? 50 : 30,
                          alignSelf: "center",
                        }}
                      >
                        <CustomImage
                          source={footerLogo}
                          require={true}
                          style={{
                            width: 160,
                            height: 62,
                            resizeMode: "contain",
                          }}
                          webStyle={{
                            width: 160,
                            height: 62,
                            objectFit: "contain",
                            marginRight: 12,
                          }}
                          altText={"The Morning Context"}
                        />
                      </View>
                      <Text
                        style={[
                          siteFooter.followTitle,
                          { alignSelf: "center" },
                          { color: "#FFFFFF" },
                          { fontSize: 18 },
                        ]}
                      >
                        Follow us on
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: helpers.isDesktop() ? 0 : 20,
                          marginTop: helpers.isDesktop() ? 30 : 20,
                          alignSelf: "center",
                          marginLeft: 40,
                        }}
                      >
                        <TouchableOpacityLinkExternal
                          {...btnClickFB}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerFB}
                            require={true}
                            style={{ width: 21, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 21,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Facebook"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickTwitter}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerTwitter}
                            require={true}
                            style={{ width: 29, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 29,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Twitter"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickLinkdin}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerIn}
                            require={true}
                            style={{ width: 21, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 21,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Linkedin"}
                          />
                        </TouchableOpacityLinkExternal>
                        {/* </View> */}
                        {/* <View style={{ flexDirection: 'row', marginBottom: helpers.isDesktop() ? 50 : 30, marginTop: helpers.isDesktop() ? 30 : 0 }}> */}
                        <TouchableOpacityLinkExternal
                          {...btnClickInsta}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerInsta}
                            require={true}
                            style={{ width: 24, height: 24, marginRight: 30 }}
                            webStyle={{
                              width: 24,
                              height: 24,
                              marginRight: 30,
                            }}
                            altText={"Instagram"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickYoutube}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerYoutube}
                            require={true}
                            style={{ width: 29, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 29,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"Youtube"}
                          />
                        </TouchableOpacityLinkExternal>
                        <TouchableOpacityLinkExternal
                          {...btnClickWhatsApp}
                          newTab={true}
                        >
                          <CustomImage
                            source={footerWhatsApp}
                            require={true}
                            style={{ width: 29, height: 22, marginRight: 30 }}
                            webStyle={{
                              width: 29,
                              height: 22,
                              marginRight: 30,
                            }}
                            altText={"WhatsApp"}
                          />
                        </TouchableOpacityLinkExternal>
                      </View>

                      <View
                        style={[
                          Flex.column,
                          Flex.justfiyCenter,
                          Flex.alignCenter,
                          {
                            paddingTop: 20,
                            marginTop: 20,
                            borderTopWidth: 0.3,
                            borderColor: "#ffffff",
                          },
                        ]}
                      >
                        {current_path[current_path.length - 1] !=
                          "yesterday" ? (
                          null
                        ) : null}
                      </View>
                    </View>
                  )}
                </View>

                <View
                  style={[
                    { width: helpers.isDesktop() ? "80%" : "100%" },
                    helpers.isDesktop() && {
                      borderLeftWidth: "0.3px",
                      borderLeftColor: "#FFF",
                      paddingLeft: 23,
                      paddingTop: 4,
                    },
                  ]}
                >
                  {
                    <View style={[siteFooter.menuParent]}>
                      {menu
                        ? menu.slice(0, 1).map((section, secIndex) => {
                          return (
                            <View
                              key={secIndex}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                                { marginBottom: 25, marginTop: 50 },
                                menu.length - 1 == secIndex && {
                                  marginBottom: 0,
                                },
                              ]}
                            >
                              <View style={[siteFooter.menuList]}>
                                <Text
                                  style={[
                                    siteFooter.menuTitle,
                                    { color: "white", fontWeight: "500" },
                                  ]}
                                >
                                  {section.name}
                                </Text>
                                {section["sub-menu"].map(
                                  (item, itemIndex) => {
                                    let btnClick;
                                    if (webView) {
                                      btnClick = {
                                        to: item.link,
                                      };
                                      if (item.type == "email") {
                                        mail = helpers.extractEmails(
                                          item.link
                                        );
                                        btnClick = {
                                          onPress: () => {
                                            let mailUrl =
                                              "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                            Linking.canOpenURL(item.link)
                                              .then((supported) =>
                                                supported
                                                  ? Linking.openURL(
                                                    `mailto:${mail}`
                                                  )
                                                  : window.open(
                                                    `${mailUrl}${mail}`,
                                                    "_blank"
                                                  )
                                              )
                                              .catch((err) =>
                                                window.open(
                                                  `${mailUrl}${mail}`,
                                                  "_blank"
                                                )
                                              );
                                          },
                                        };
                                      }
                                    } else {
                                      btnClick = {
                                        onPress: () =>
                                          navigateApp(data.category.slug),
                                      };
                                    }
                                    if (
                                      item.type == "internal" ||
                                      item.type == "external" ||
                                      item.type == ""
                                    ) {
                                      return (
                                        <>
                                          <TouchableOpacityLink
                                            key={itemIndex}
                                            {...btnClick}
                                            handleClick={() =>
                                              this.trackCateoryClick(item, section.name)
                                            }
                                          >
                                            {this.returnItemName(
                                              item.name,
                                              itemIndex
                                            )}
                                          </TouchableOpacityLink>
                                          {/* {
                                                                            ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                            <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                        } */}
                                        </>
                                      );
                                    } else if (item.type == "email") {
                                      return (
                                        <>
                                          <EmailLink {...btnClick}>
                                            <View>
                                              <Link
                                                to={{
                                                  pathname: `mailto:${mail}`,
                                                }}
                                                onClick={this.stopNavigation}
                                              >
                                                <Text
                                                  style={[
                                                    siteFooter.menuListItem,
                                                    helpers.isDesktop() &&
                                                    siteFooter.menuListItemDesktop,
                                                  ]}
                                                >
                                                  {item.name}
                                                </Text>
                                              </Link>
                                            </View>
                                          </EmailLink>
                                          {section["sub-menu"].length - 1 !=
                                            itemIndex && (
                                              <View
                                                style={{
                                                  width: 1,
                                                  height: 14,
                                                  backgroundColor: "#C4C4C4",
                                                  marginHorizontal: 8,
                                                  position: "relative",
                                                  top: -1,
                                                }}
                                              ></View>
                                            )}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </View>
                            </View>
                          );
                        })
                        : [0, 1, 2, 3, 4, 5].map((item, index) => {
                          return (
                            <View
                              key={index}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                              ]}
                            >
                              {this.footerLoader()}
                            </View>
                          );
                        })}

                      {/* subcscription menu */}
                      {menu
                        ? menu
                          .slice(1, helpers.isDesktop() ? 3 : 2)
                          .map((section, secIndex) => {
                            return (
                              <View
                                key={secIndex}
                                style={[
                                  siteFooter.menu,
                                  helpers.isDesktop() &&
                                  siteFooter.menuDesktop,
                                  {
                                    marginBottom: 25,
                                    marginTop: 50,
                                    marginRight: helpers.isDesktop() ? 0 : 0,
                                    width: helpers.isDesktop() ? null : '40%',
                                  },
                                  menu.length - 1 == secIndex && {
                                    marginBottom: 0,
                                  },
                                ]}
                              >
                                <View style={[siteFooter.menuList]}>
                                  <Text
                                    style={[
                                      siteFooter.menuTitle,
                                      { color: "white", fontWeight: "500" },
                                    ]}
                                  >
                                    {section.name}
                                  </Text>
                                  {section["sub-menu"].map(
                                    (item, itemIndex) => {
                                      let btnClick;
                                      if (webView) {
                                        btnClick = {
                                          to: item.link,
                                        };
                                        if (item.type == "email") {
                                          mail = helpers.extractEmails(
                                            item.link
                                          );
                                          btnClick = {
                                            onPress: () => {
                                              let mailUrl =
                                                "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                              Linking.canOpenURL(item.link)
                                                .then((supported) =>
                                                  supported
                                                    ? Linking.openURL(
                                                      `mailto:${mail}`
                                                    )
                                                    : window.open(
                                                      `${mailUrl}${mail}`,
                                                      "_blank"
                                                    )
                                                )
                                                .catch((err) =>
                                                  window.open(
                                                    `${mailUrl}${mail}`,
                                                    "_blank"
                                                  )
                                                );
                                            },
                                          };
                                        }
                                      } else {
                                        btnClick = {
                                          onPress: () =>
                                            navigateApp(data.category.slug),
                                        };
                                      }
                                      if (
                                        item.type == "internal" ||
                                        item.type == "external"
                                      ) {
                                        return (
                                          <>
                                            <TouchableOpacityLink
                                              key={itemIndex}
                                              {...btnClick}
                                              handleClick={() =>
                                                this.trackCateoryClick(item, section.name)
                                              }
                                            >
                                              <View
                                                style={{ marginBottom: 8 }}
                                              >
                                                <Text
                                                  style={[
                                                    siteFooter.menuListItem,
                                                    helpers.isDesktop() &&
                                                    siteFooter.menuListItemDesktop,
                                                    {
                                                      color: "white",
                                                      fontWeight: "500",
                                                    },
                                                  ]}
                                                >
                                                  {item.name}
                                                </Text>
                                              </View>
                                            </TouchableOpacityLink>
                                            {/* {
                                                                            ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                            <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                        } */}
                                          </>
                                        );
                                      } else if (item.type == "email") {
                                        return (
                                          <>
                                            <EmailLink {...btnClick}>
                                              <View>
                                                <Link
                                                  to={{
                                                    pathname: `mailto:${mail}`,
                                                  }}
                                                  onClick={
                                                    this.stopNavigation
                                                  }
                                                >
                                                  <Text
                                                    style={[
                                                      siteFooter.menuListItem,
                                                      helpers.isDesktop() &&
                                                      siteFooter.menuListItemDesktop,
                                                    ]}
                                                  >
                                                    {item.name}
                                                  </Text>
                                                </Link>
                                              </View>
                                            </EmailLink>
                                            {section["sub-menu"].length - 1 !=
                                              itemIndex && (
                                                <View
                                                  style={{
                                                    width: 1,
                                                    height: 14,
                                                    backgroundColor: "#C4C4C4",
                                                    marginHorizontal: 8,
                                                    position: "relative",
                                                    top: -1,
                                                  }}
                                                ></View>
                                              )}
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </View>
                              </View>
                            );
                          })
                        : [0, 1, 2, 3, 4, 5].map((item, index) => {
                          return (
                            <View
                              key={index}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                              ]}
                            >
                              {this.footerLoader()}
                            </View>
                          );
                        })}
                    </View>
                  }
                  <View style={[!helpers.isDesktop() && siteFooter.menuParent]}>
                    {!helpers.isDesktop() && (
                      <View style={[siteFooter.menuParent]}>
                        {/* company menu */}
                        {menu
                          ? menu.slice(2, 3).map((section, secIndex) => {
                            return (
                              <View
                                key={secIndex}
                                style={[
                                  siteFooter.menu,
                                  helpers.isDesktop() &&
                                  siteFooter.menuDesktop,
                                  {
                                    marginBottom: 25,
                                    marginTop: helpers.isDesktop() ? 50 : 25,
                                  },
                                  menu.length - 1 == secIndex && {
                                    marginBottom: 0,
                                  },
                                ]}
                              >
                                <View style={[siteFooter.menuList]}>
                                  <Text
                                    style={[
                                      siteFooter.menuTitle,
                                      { color: "white", fontWeight: "500" },
                                    ]}
                                  >
                                    {section.name}
                                  </Text>
                                  {section["sub-menu"].map(
                                    (item, itemIndex) => {
                                      let btnClick;
                                      if (webView) {
                                        btnClick = {
                                          to: item.link,
                                        };
                                        if (item.type == "email") {
                                          mail = helpers.extractEmails(
                                            item.link
                                          );
                                          btnClick = {
                                            onPress: () => {
                                              let mailUrl =
                                                "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                              Linking.canOpenURL(item.link)
                                                .then((supported) =>
                                                  supported
                                                    ? Linking.openURL(
                                                      `mailto:${mail}`
                                                    )
                                                    : window.open(
                                                      `${mailUrl}${mail}`,
                                                      "_blank"
                                                    )
                                                )
                                                .catch((err) =>
                                                  window.open(
                                                    `${mailUrl}${mail}`,
                                                    "_blank"
                                                  )
                                                );
                                            },
                                          };
                                        }
                                      } else {
                                        btnClick = {
                                          onPress: () =>
                                            navigateApp(data.category.slug),
                                        };
                                      }
                                      if (
                                        item.type == "internal" ||
                                        item.type == "external"
                                      ) {
                                        return (
                                          <>
                                            <TouchableOpacityLink
                                              key={itemIndex}
                                              {...btnClick}
                                              handleClick={() =>
                                                this.trackCateoryClick(item, section.name)
                                              }
                                            >
                                              <View
                                                style={{ marginBottom: 8 }}
                                              >
                                                <Text
                                                  style={[
                                                    siteFooter.menuListItem,
                                                    helpers.isDesktop() &&
                                                    siteFooter.menuListItemDesktop,
                                                    {
                                                      color: "white",
                                                      fontWeight: "500",
                                                    },
                                                  ]}
                                                >
                                                  {item.name}
                                                </Text>
                                              </View>
                                            </TouchableOpacityLink>
                                            {/* {
                                                                        ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                        <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                    } */}
                                          </>
                                        );
                                      } else if (item.type == "email") {
                                        return (
                                          <>
                                            <EmailLink {...btnClick}>
                                              <View>
                                                <Link
                                                  to={{
                                                    pathname: `mailto:${mail}`,
                                                  }}
                                                  onClick={
                                                    this.stopNavigation
                                                  }
                                                >
                                                  <Text
                                                    style={[
                                                      siteFooter.menuListItem,
                                                      helpers.isDesktop() &&
                                                      siteFooter.menuListItemDesktop,
                                                    ]}
                                                  >
                                                    {item.name}
                                                  </Text>
                                                </Link>
                                              </View>
                                            </EmailLink>
                                            {section["sub-menu"].length - 1 !=
                                              itemIndex && (
                                                <View
                                                  style={{
                                                    width: 1,
                                                    height: 14,
                                                    backgroundColor: "#C4C4C4",
                                                    marginHorizontal: 8,
                                                    position: "relative",
                                                    top: -1,
                                                  }}
                                                ></View>
                                              )}
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                </View>
                              </View>
                            );
                          })
                          : [0, 1, 2, 3, 4, 5].map((item, index) => {
                            return (
                              <View
                                key={index}
                                style={[
                                  siteFooter.menu,
                                  helpers.isDesktop() &&
                                  siteFooter.menuDesktop,
                                ]}
                              >
                                {this.footerLoader()}
                              </View>
                            );
                          })}
                      </View>
                    )}
                    {/* { */}
                    <View
                      style={[
                        siteFooter.menuParent,
                        !helpers.isDesktop() && { flexDirection: "column" },
                        {
                          width: helpers.isDesktop() ? null : '40%'
                        }
                      ]}
                    >
                      {menu
                        ? menu.slice(4, 5).map((section, secIndex) => {
                          return (
                            <View
                              key={secIndex}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                                { marginBottom: 25 },
                                menu.length - 1 == secIndex && {
                                  marginBottom: 0,
                                },
                              ]}
                            >
                              <View
                                style={[
                                  siteFooter.menuList,
                                  !helpers.isDesktop() && { marginTop: 25 },
                                ]}
                              >
                                {/* <Text style={[siteFooter.menuListItem, helpers.isDesktop() && siteFooter.menuListItemDesktop]}>{section.name}</Text> */}
                                {section["sub-menu"].map(
                                  (item, itemIndex) => {
                                    let btnClick;
                                    if (webView) {
                                      btnClick = {
                                        to: item.link,
                                      };
                                      if (item.type == "email") {
                                        mail = helpers.extractEmails(
                                          item.link
                                        );
                                        btnClick = {
                                          // href: `mailto:support@themorningcontext.com`,
                                          onPress: () => {
                                            let mailUrl =
                                              "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                            Linking.canOpenURL(item.link)
                                              .then((supported) =>
                                                supported
                                                  ? Linking.openURL(
                                                    `mailto:${mail}`
                                                  )
                                                  : window.open(
                                                    `${mailUrl}${mail}`,
                                                    "_blank"
                                                  )
                                              )
                                              .catch((err) =>
                                                window.open(
                                                  `${mailUrl}${mail}`,
                                                  "_blank"
                                                )
                                              );
                                          },
                                        };
                                      }
                                    } else {
                                      btnClick = {
                                        onPress: () =>
                                          navigateApp(data.category.slug),
                                      };
                                    }

                                    let appClick;
                                    if (webView) {
                                      appClick = {
                                        to: "/app-download",
                                      };
                                    } else {
                                      btnClick = {
                                        onPress: () =>
                                          navigateApp("/app-download"),
                                      };
                                    }

                                    if (
                                      item.type == "internal" ||
                                      item.type == "external"
                                    ) {
                                      return (
                                        <>
                                          <TouchableOpacityLink
                                            key={itemIndex}
                                            {...btnClick}
                                            handleClick={() =>
                                              this.trackCateoryClick(item, section.name)
                                            }
                                          >
                                            <View style={{ marginTop: 8 }}>
                                              <Text
                                                style={[
                                                  siteFooter.menuListItem,
                                                  helpers.isDesktop() &&
                                                  siteFooter.menuListItemDesktop,
                                                  {
                                                    color: "white",
                                                    fontWeight: "500",
                                                  },
                                                ]}
                                              >
                                                {"TMC Blog"}
                                              </Text>
                                            </View>
                                          </TouchableOpacityLink>

                                          <TouchableOpacityLink
                                            key={itemIndex}
                                            {...appClick}
                                            handleClick={() =>
                                              this.trackCateoryClick(item, section.name)
                                            }
                                          >
                                            <View style={{ marginTop: 8 }}>
                                              <Text
                                                style={[
                                                  siteFooter.menuListItem,
                                                  helpers.isDesktop() &&
                                                  siteFooter.menuListItemDesktop,
                                                  {
                                                    color: "white",
                                                    fontWeight: "500",
                                                  },
                                                ]}
                                              >
                                                Download the App
                                              </Text>
                                            </View>
                                          </TouchableOpacityLink>
                                          {/* {
                                                                            ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                            <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                        } */}
                                        </>
                                      );
                                    } else if (item.type == "email") {
                                      return (
                                        <>
                                          <EmailLink {...btnClick}>
                                            <View>
                                              <Link
                                                to={{
                                                  pathname: `mailto:${mail}`,
                                                }}
                                                onClick={this.stopNavigation}
                                              >
                                                <Text
                                                  style={[
                                                    siteFooter.menuListItem,
                                                    helpers.isDesktop() &&
                                                    siteFooter.menuListItemDesktop,
                                                  ]}
                                                >
                                                  {item.name}
                                                </Text>
                                              </Link>
                                            </View>
                                          </EmailLink>
                                          {/* {
                                                                                ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                                <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                            } */}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </View>
                            </View>
                          );
                        })
                        : [0, 1, 2, 3, 4, 5].map((item, index) => {
                          return (
                            <View
                              key={index}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                              ]}
                            >
                              {this.footerLoader()}
                            </View>
                          );
                        })}
                      {/* Colloborate menu */}
                      {menu
                        ? menu.slice(3, 4).map((section, secIndex) => {
                          return (
                            <View
                              key={secIndex}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                                { marginBottom: 25 },
                                menu.length - 1 == secIndex && {
                                  marginBottom: 0,
                                },
                                {
                                  marginTop: helpers.isDesktop() ? -10 : 0,
                                  paddingLeft: helpers.isDesktop() ? 113 : 0,
                                },
                              ]}
                            >
                              <View
                                style={[
                                  siteFooter.menuList,
                                  { left: helpers.isDesktop() ? -12 : 0 },
                                ]}
                              >
                                {section["sub-menu"]
                                  .slice(0, 3)
                                  .map((item, itemIndex) => {
                                    let btnClick;
                                    if (webView) {
                                      btnClick = {
                                        to: item.link,
                                      };
                                      if (item.type == "email") {
                                        // Working
                                        mail = helpers.extractEmails(
                                          item.link
                                        );
                                        btnClick = {
                                          // href: `mailto:support@themorningcontext.com`,
                                          onPress: () => {
                                            let mailUrl =
                                              "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                            Linking.canOpenURL(item.link)
                                              .then((supported) =>
                                                supported
                                                  ? Linking.openURL(
                                                    `mailto:${mail}`
                                                  )
                                                  : window.open(
                                                    `${mailUrl}${mail}`,
                                                    "_blank"
                                                  )
                                              )
                                              .catch((err) =>
                                                window.open(
                                                  `${mailUrl}${mail}`,
                                                  "_blank"
                                                )
                                              );
                                          },
                                        };
                                      }
                                    } else {
                                      btnClick = {
                                        onPress: () =>
                                          navigateApp(data.category.slug),
                                      };
                                    }
                                    if (
                                      item.type == "internal" ||
                                      item.type == "external"
                                    ) {
                                      return (
                                        <>
                                          <TouchableOpacityLink
                                            key={itemIndex}
                                            {...btnClick}
                                            handleClick={() =>
                                              this.trackCateoryClick(item, section.name)
                                            }
                                          >
                                            <View
                                              style={{
                                                marginBottom: 8,
                                                marginTop: 8,
                                              }}
                                            >
                                              <Text
                                                style={[
                                                  siteFooter.menuListItem,
                                                  helpers.isDesktop() &&
                                                  siteFooter.menuListItemDesktop,
                                                  {
                                                    color: "white",
                                                    fontWeight: "500",
                                                  },
                                                ]}
                                              >
                                                {item.name}
                                              </Text>
                                            </View>
                                          </TouchableOpacityLink>
                                          {/* {
                                                                            ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                            <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                        } */}
                                        </>
                                      );
                                    } else if (item.type == "email") {
                                      return (
                                        <>
                                          <EmailLink {...btnClick}>
                                            <View>
                                              <Link
                                                to={{
                                                  pathname: `mailto:${mail}`,
                                                }}
                                                onClick={this.stopNavigation}
                                              >
                                                <Text
                                                  // numberOfLines={2}
                                                  style={[
                                                    siteFooter.menuListItem,
                                                    helpers.isDesktop() &&
                                                    siteFooter.menuListItemDesktop,
                                                    {
                                                      color: "white",
                                                      fontWeight: "500",
                                                    },
                                                  ]}
                                                >
                                                  {item.name}
                                                </Text>
                                              </Link>
                                            </View>
                                          </EmailLink>
                                          {/* {
                                                                                ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                                <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                            } */}
                                        </>
                                      );
                                    }
                                  })}
                              </View>
                            </View>
                          );
                        })
                        : [0, 1, 2, 3, 4, 5].map((item, index) => {
                          return (
                            <View
                              key={index}
                              style={[
                                siteFooter.menu,
                                helpers.isDesktop() && siteFooter.menuDesktop,
                              ]}
                            >
                              {this.footerLoader()}
                            </View>
                          );
                        })}
                      {helpers.isDesktop() && (
                        <View>
                          {menu
                            ? menu.slice(3, 4).map((section, secIndex) => {
                              return (
                                <View
                                  key={secIndex}
                                  style={[
                                    siteFooter.menu,
                                    helpers.isDesktop() &&
                                    siteFooter.menuDesktop,
                                    {
                                      marginBottom: 25,
                                      justifyContent: "flex-start",
                                    },
                                    menu.length - 1 == secIndex && {
                                      marginBottom: 0,
                                    },
                                  ]}
                                >
                                  <View style={[siteFooter.menuList]}>
                                    {section["sub-menu"]
                                      .slice(3, 6)
                                      .map((item, itemIndex) => {
                                        let btnClick;
                                        if (webView) {
                                          btnClick = {
                                            to: item.link,
                                          };
                                          if (item.type == "email") {
                                            mail = helpers.extractEmails(
                                              item.link
                                            );
                                            btnClick = {
                                              // href: `mailto:${item.link}`,
                                              onPress: () => {
                                                let mailUrl =
                                                  "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                                Linking.canOpenURL(item.link)
                                                  .then((supported) =>
                                                    supported
                                                      ? Linking.openURL(
                                                        `mailto:${mail}`
                                                      )
                                                      : window.open(
                                                        `${mailUrl}${mail}`,
                                                        "_blank"
                                                      )
                                                  )
                                                  .catch((err) =>
                                                    window.open(
                                                      `${mailUrl}${mail}`,
                                                      "_blank"
                                                    )
                                                  );
                                              },
                                            };
                                          }
                                        } else {
                                          btnClick = {
                                            onPress: () =>
                                              navigateApp(data.category.slug),
                                          };
                                        }
                                        if (
                                          item.type == "internal" ||
                                          item.type == "external"
                                        ) {
                                          return (
                                            <>
                                              <TouchableOpacityLink
                                                key={itemIndex}
                                                {...btnClick}
                                                handleClick={() =>
                                                  this.trackCateoryClick(item, section.name)
                                                }
                                              >
                                                <View
                                                  style={{
                                                    marginBottom: 8,
                                                    left: helpers.isDesktop()
                                                      ? 60
                                                      : 0,
                                                  }}
                                                >
                                                  <Text
                                                    style={[
                                                      siteFooter.menuListItem,
                                                      helpers.isDesktop() &&
                                                      siteFooter.menuListItemDesktop,
                                                      {
                                                        color: "white",
                                                        fontWeight: "500",
                                                      },
                                                      { textAlign: "left" },
                                                    ]}
                                                  >
                                                    {item.name}
                                                  </Text>
                                                </View>
                                              </TouchableOpacityLink>
                                              {/* {
                                                                            ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                            <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                        } */}
                                            </>
                                          );
                                        } else if (item.type == "email") {
                                          return (
                                            <>
                                              <EmailLink {...btnClick}>
                                                <View>
                                                  <Link
                                                    to={{
                                                      pathname: `mailto:${mail}`,
                                                    }}
                                                    onClick={
                                                      this.stopNavigation
                                                    }
                                                  >
                                                    <Text
                                                      style={[
                                                        siteFooter.menuListItem,
                                                        helpers.isDesktop() &&
                                                        siteFooter.menuListItemDesktop,
                                                      ]}
                                                    >
                                                      {item.name}
                                                    </Text>
                                                  </Link>
                                                </View>
                                              </EmailLink>
                                              {/* {
                                                                                ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                                <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                            } */}
                                            </>
                                          );
                                        }
                                      })}
                                  </View>
                                </View>
                              );
                            })
                            : [0, 1, 2, 3, 4, 5].map((item, index) => {
                              return (
                                <View
                                  key={index}
                                  style={[
                                    siteFooter.menu,
                                    helpers.isDesktop() &&
                                    siteFooter.menuDesktop,
                                  ]}
                                >
                                  {this.footerLoader()}
                                </View>
                              );
                            })}
                        </View>
                      )}
                    </View>
                  </View>

                  {/* } */}
                  {/* {
                                        !helpers.isDesktop() &&
                                        <View style={{ marginTop: 10, marginBottom: 10 }}>
                                            {
                                                menu ?
                                                    menu.map((section, secIndex) => {
                                                        return (
                                                            <FooterAccordian title={section.name} index={secIndex} darkMode={true} footer={true}>
                                                                <View style={[siteFooter.menuList, !helpers.isDesktop() && { flexDirection: 'column', alignItems: 'flex-start' }]}>
                                                                    {section['sub-menu'].map((item, itemIndex) => {
                                                                        let btnClick;
                                                                        if (webView) {
                                                                            btnClick = {
                                                                                to: item.link
                                                                            }
                                                                            if (item.type == 'email') {
                                                                                btnClick = {
                                                                                    href: `mailto:${item.link}`
                                                                                }
                                                                            }
                                                                        }
                                                                        else {
                                                                            btnClick = {
                                                                                onPress: () => navigateApp(data.category.slug)
                                                                            }
                                                                        }
                                                                        if (item.type == 'internal' || item.type == 'external') {
                                                                            return <><TouchableOpacityLink key={itemIndex} {...btnClick} handleClick={() => this.trackCateoryClick(item)}>
                                                                                <View style={[{ marginBottom: ((section['sub-menu'].length - 1) == itemIndex) ? 0 : 20 }]}>
                                                                                    <Text style={[siteFooter.menuListItem, helpers.isDesktop() && siteFooter.menuListItemDesktop]}>{item.name}</Text>
                                                                                </View>
                                                                            </TouchableOpacityLink>
                                                                            </>
                                                                        }
                                                                        else if (item.type == 'email') {
                                                                            return (
                                                                                <>
                                                                                    <EmailLink {...btnClick}>
                                                                                        <View style={[{ marginBottom: ((section['sub-menu'].length - 1) == itemIndex) ? 0 : 20 }]}>
                                                                                            <Text style={[siteFooter.menuListItem, helpers.isDesktop() && siteFooter.menuListItemDesktop]}>{item.name}</Text>
                                                                                        </View>
                                                                                    </EmailLink>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                    }
                                                                </View>
                                                            </FooterAccordian>
                                                        )
                                                    })
                                                    :
                                                    [0, 1, 2, 3, 4, 5].map((item, index) => {
                                                        return (
                                                            <View key={index} style={[siteFooter.menu, helpers.isDesktop() && siteFooter.menuDesktop]}>
                                                                {this.footerLoader()}
                                                            </View>
                                                        )
                                                    })
                                            }
                                        </View>
                                    } */}
                </View>
              </View>

              {helpers.isDesktop() ? (
                <View
                  style={{
                    display: "flex",
                    marginBottom: "48px",
                    marginTop: "32px",
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    marginVertical: helpers.isDesktop() ? 0 : 10,
                  }}
                >
                  <Text
                    style={[
                      siteFooter.footerTextRegular,
                      {
                        fontFamily: FontFamily.light,
                        fontSize: "14px",
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: 7,
                        textAlign: helpers.isDesktop() ? "left" : "center",
                      },
                    ]}
                  >
                    ©{copyright} Slowform Holdings Limited
                  </Text>
                  <Text
                    style={[
                      siteFooter.footerTextRegular,
                      {
                        fontFamily: FontFamily.light,
                        fontSize: "12px",
                        marginBottom: 7,
                        marginLeft: "17px",
                        textAlign: helpers.isDesktop() ? "left" : "center",
                      },
                    ]}
                  >
                    86-90 Paul Street, London, EC2A 4NE.{" "}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      let mailUrl =
                        "https://mail.google.com/mail/?view=cm&fs=1&to=";

                      Linking.canOpenURL(mail)
                        .then((supported) =>
                          supported
                            ? Linking.openURL(`mailto:${mail}`)
                            : window.open(`${mailUrl}${mail}`, "_blank")
                        )
                        .catch((err) =>
                          window.open(`${mailUrl}${mail}`, "_blank")
                        );
                    }}
                  >
                    <Text
                      style={[
                        siteFooter.footerTextRegular,
                        {
                          fontFamily: FontFamily.light,
                          fontSize: "12px",
                          marginBottom: 7,
                          marginLeft: "17px",
                          textAlign: helpers.isDesktop() ? "left" : "center",
                        },
                      ]}
                    >
                      {mail}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: "#363839",
          }}
        >
          <View style={[Helpers.conatinerWeb]}>
            <View
              style={{
                flexDirection: helpers.isDesktop() ? "row" : "column",
                justifyContent: "space-between",
                alignItems: helpers.isDesktop() ? "flex-end" : "center",
                paddingLeft: helpers.isDesktop() ? 150 : 0,
              }}
            >
              {!helpers.isDesktop() && (
                <View
                  style={{
                    backgroundColor: "#FFFFFF",
                    height: 1,
                    opacity: 0.5,
                    width: "100%",
                    marginBottom: 25,
                    marginTop: 0,
                  }}
                />
              )}
              {!helpers.isDesktop() ? (
                <View
                  style={{
                    display: "flex",
                    marginBottom: "40px",
                    marginTop: "auto",
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    marginVertical: helpers.isDesktop() ? 0 : 10,
                  }}
                >
                  <Text
                    style={[
                      siteFooter.footerTextRegular,
                      {
                        fontFamily: FontFamily.light,
                        fontSize: "14px",
                        fontWeight: "400",
                        fontSize: "14px",
                        marginBottom: 7,
                        textAlign: helpers.isDesktop() ? "left" : "center",
                      },
                    ]}
                  >
                    ©{copyright} Slowform Holdings Ltd.
                  </Text>
                  <Text
                    style={[
                      siteFooter.footerTextRegular,
                      {
                        fontFamily: FontFamily.light,
                        fontSize: "12px",
                        marginBottom: 7,
                        marginLeft: "17px",
                        textAlign: helpers.isDesktop() ? "left" : "center",
                      },
                    ]}
                  >
                    86-90 Paul Street, London, EC2A 4NE.{" "}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      let mailUrl =
                        "https://mail.google.com/mail/?view=cm&fs=1&to=";

                      Linking.canOpenURL(mail)
                        .then((supported) =>
                          supported
                            ? Linking.openURL(`mailto:${mail}`)
                            : window.open(`${mailUrl}${mail}`, "_blank")
                        )
                        .catch((err) =>
                          window.open(`${mailUrl}${mail}`, "_blank")
                        );
                    }}
                  >
                    <Text
                      style={[
                        siteFooter.footerTextRegular,
                        {
                          fontFamily: FontFamily.light,
                          fontSize: "12px",
                          marginBottom: 7,
                          marginLeft: "17px",
                          textAlign: helpers.isDesktop() ? "left" : "center",
                        },
                      ]}
                    >
                      {mail}
                    </Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <></>
              )}
              {!helpers.isDesktop() && (
                <View
                  style={{
                    backgroundColor: "#FFFFFF",
                    height: 1,
                    opacity: 0.5,
                    width: "100%",
                    marginBottom: 25,
                    marginTop: !helpers.isDesktop() ? -20 : 0,
                  }}
                />
              )}
              {/* <View style={{ marginBottom: helpers.isDesktop() ? 0 : 25 }}>
                               {!helpers.isDesktop && <Text style={[siteFooter.footerTextRegular, { marginBottom: 7, textAlign: helpers.isDesktop() ? 'left' : 'center' }]}>©{copyright} Slowform Holdings Limited,{"\n "}68 Circular Road, #02-01, 049422, Singapore </Text> }
                               {helpers.isDesktop && <Text style={[siteFooter.footerTextRegular, { marginBottom: 7, textAlign: helpers.isDesktop() ? 'left' : 'center' }]}>©{copyright} Slowform Holdings Limited,   68 Circular Road, #02-01, 049422, Singapore </Text> } */}

              {/* {!helpers.isDesktop() &&   <View style={{backgroundColor:'#FFFFFF',height:1,opacity:0.5,width:'100%',marginTop:25}} /> } */}
              {/* <Text style={[siteFooter.footerTextLight, { textAlign: helpers.isDesktop() ? 'left' : 'center' }]}>68 Circular Road, #02-01, 049422, Singapore</Text> */}
              {/* </View> */}
              {/* <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <TouchableOpacityLink {...btnClickFaq}><Text style={[siteFooter.footerLinks]}>FAQ</Text></TouchableOpacityLink>
                                <View style={[siteFooter.dotSeprator]} />
                                <TouchableOpacityLink {...btnClickTerms}><Text style={[siteFooter.footerLinks]}>Terms of service</Text></TouchableOpacityLink>
                                <View style={[siteFooter.dotSeprator]} />
                                <TouchableOpacityLink {...btnClickPrivacy}><Text style={[siteFooter.footerLinks]}>Privacy policy</Text></TouchableOpacityLink>
                            </View> */}
            </View>
          </View>
        </View>
        {!helpers.isDesktop() && (
          <View style={[Helpers.conatinerWeb]}>
            {menu
              ? menu.slice(3, 4).map((section, secIndex) => {
                return (
                  <View
                    key={secIndex}
                    style={[
                      siteFooter.menu,
                      helpers.isDesktop() && siteFooter.menuDesktop,
                      { marginBottom: 25, justifyContent: 'center' },
                      menu.length - 1 == secIndex && { marginBottom: 30 },
                      { backgroundColor: "#363839" },
                    ]}
                  >
                    <View
                      style={{
                        flexDirection: "row-reverse",
                        backgroundColor: "#363839",
                      }}
                    >
                      {section["sub-menu"]
                        .slice(3, 6)
                        .map((item, itemIndex) => {
                          let btnClick;
                          if (webView) {
                            btnClick = {
                              to: item.link,
                            };
                            if (item.type == "email") {
                              mail = helpers.extractEmails(item.link);
                              btnClick = {
                                // href: `mailto:${item.link}`,
                                onPress: () => {
                                  let mailUrl =
                                    "https://mail.google.com/mail/?view=cm&fs=1&to=";

                                  Linking.canOpenURL(item.link)
                                    .then((supported) =>
                                      supported
                                        ? Linking.openURL(`mailto:${mail}`)
                                        : window.open(
                                          `${mailUrl}${mail}`,
                                          "_blank"
                                        )
                                    )
                                    .catch((err) =>
                                      window.open(
                                        `${mailUrl}${mail}`,
                                        "_blank"
                                      )
                                    );
                                },
                              };
                            }
                          } else {
                            btnClick = {
                              onPress: () => navigateApp(data.category.slug),
                            };
                          }
                          if (
                            item.type == "internal" ||
                            item.type == "external"
                          ) {
                            return (
                              <>
                                <TouchableOpacityLink
                                  key={itemIndex}
                                  {...btnClick}
                                  handleClick={() =>
                                    this.trackCateoryClick(item, section.name)
                                  }
                                >
                                  <View
                                    style={{
                                      marginBottom: 8,
                                      left: helpers.isDesktop() ? 60 : 0,
                                    }}
                                  >
                                    <Text
                                      style={
                                        ([
                                          siteFooter.menuListItem,
                                          helpers.isDesktop() &&
                                          siteFooter.menuListItemDesktop,
                                        ],
                                        {
                                          marginLeft: helpers.isDesktop() ? 0 : 10,
                                          marginRight: helpers.isDesktop() ? 20 : 10,
                                          color: "white",
                                          textDecorationLine: "underline",
                                        })
                                      }
                                    >
                                      {item.name}
                                    </Text>
                                    {itemIndex === 0 && (
                                      <View
                                        style={{
                                          backgroundColor: "white",
                                          borderRadius: 1,
                                          width: 2,
                                          height: 2,
                                          alignSelf: "flex-start",
                                          marginLeft: -1,
                                          marginTop: -8,
                                        }}
                                      ></View>
                                    )}
                                  </View>
                                </TouchableOpacityLink>
                                {/* {
                                                                            ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                            <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                        } */}
                              </>
                            );
                          } else if (item.type == "email") {
                            return (
                              <>
                                <EmailLink {...btnClick}>
                                  <View>
                                    <Link
                                      to={{ pathname: `mailto:${mail}` }}
                                      onClick={this.stopNavigation}
                                    >
                                      <Text
                                        style={[
                                          siteFooter.menuListItem,
                                          helpers.isDesktop() &&
                                          siteFooter.menuListItemDesktop,
                                        ]}
                                      >
                                        {item.name}
                                      </Text>
                                    </Link>
                                  </View>
                                </EmailLink>
                                {/* {
                                                                                ((section['sub-menu'].length - 1) != itemIndex) &&
                                                                                <View style={{ width: 1, height: 14, backgroundColor: '#C4C4C4', marginHorizontal: 8, position: 'relative', top: -1 }}></View>
                                                                            } */}
                              </>
                            );
                          }
                        })}
                    </View>
                  </View>
                );
              })
              : [0, 1, 2, 3, 4, 5].map((item, index) => {
                return (
                  <View
                    key={index}
                    style={[
                      siteFooter.menu,
                      helpers.isDesktop() && siteFooter.menuDesktop,
                    ]}
                  >
                    {this.footerLoader()}
                  </View>
                );
              })}
          </View>
        )}
      </View>
    );
  }
}

export default AuthenticationWrapper(AppFooter);
