'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = htmlAttributesToReact;

var _BooleanAttributes = require('../dom/attributes/BooleanAttributes');

var _BooleanAttributes2 = _interopRequireDefault(_BooleanAttributes);

var _ReactAttributes = require('../dom/attributes/ReactAttributes');

var _ReactAttributes2 = _interopRequireDefault(_ReactAttributes);

var _isValidTagOrAttributeName = require('./isValidTagOrAttributeName');

var _isValidTagOrAttributeName2 = _interopRequireDefault(_isValidTagOrAttributeName);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Returns the parsed attribute value taking into account things like boolean attributes
 *
 * @param {String} attribute The name of the attribute
 * @param {*} value The value of the attribute from the HTML
 * @returns {*} The parsed attribute value
 */
var getParsedAttributeValue = function getParsedAttributeValue(attribute, value) {

  // if the attribute if a boolean then it's value should be the same as it's name
  // e.g. disabled="disabled"
  var lowerBooleanAttributes = _BooleanAttributes2.default.map(function (attr) {
    return attr.toLowerCase();
  });
  if (lowerBooleanAttributes.indexOf(attribute.toLowerCase()) >= 0) {
    value = attribute;
  }

  return value;
};

/**
 * Takes an object of standard HTML property names and converts them to their React counterpart. If the react
 * version does not exist for an attribute then just use it as it is
 *
 * @param {Object} attributes The HTML attributes to convert
 * @returns {Object} The React attributes
 */
function htmlAttributesToReact(attributes) {

  return Object.keys(attributes).filter(function (attr) {
    return (0, _isValidTagOrAttributeName2.default)(attr);
  }).reduce(function (mappedAttributes, attribute) {

    // lowercase the attribute name and find it in the react attribute map
    var lowerCaseAttribute = attribute.toLowerCase();

    // format the attribute name
    var name = _ReactAttributes2.default[lowerCaseAttribute] || lowerCaseAttribute;

    // add the parsed attribute value to the mapped attributes
    mappedAttributes[name] = getParsedAttributeValue(name, attributes[attribute]);

    return mappedAttributes;
  }, {});
}