import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, button, formStyle, unlockBox, signUpPage, Colors, modalStyle, forgotPassword, FontFamily } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import SignUpForm from '../../components/sign-up-form';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import AppHeaderView from '../../components/app-header-view';
import ButtonGradient from '../../components/button-gradient';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import apiHelpers from "../../utils/api-helpers";
import Cookies from 'universal-cookie';
import Seo from "../../components/seo-meta";
import { Mixpanel } from '../../utils/mixpanel';
import { GoogleAnalytics } from '../../utils/google-analytics';
import { saveDarkMode, darkModeToggle } from '../../../redux/App/app.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserToken } from '../../../redux/User/user.actions';
import seoObj from "../../utils/seo-helper";

const cookies = new Cookies();
const checkIcon = getImagePath('icons/check.png');
const starterIcon = getImagePath('icons/starter-cerdits.png');
const forgotPasswordImg = getImagePath('img/forgotPassword.png');
const closeIcon = getImagePath('icons/grey-close-in.png');


export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        let token = null, gift_token = null;
        let allParams = null;
        let webView = helpers.isWeb();
        let searchArr;
        if (webView && process.browser) {
            if (window.location.search.includes("token=")) {
                searchArr = window.location.search.replace("?", "").split("&");
            }
        }
        else if (!webView && this.props.route?.params?.query) {
            searchArr = this.props.route.params.query.split("&");
        }

        if (searchArr) {
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("token=");
            });
            if (foundSearch) {
                let search = foundSearch.replace("token=", "");
                search = search.replace("redirect-params=", "");
                token = (search != '') ? search : null;
            }

            searchArr.map((searchParam) => {
                if (searchParam.includes("gift-token=")) {
                    gift_token = searchParam.replace("gift-token=", "");
                }
                return searchParam;
            });


        }

        this.state = {
            token: token ? token : '',
            showVerifyLoader: true,
            linkExpired: false,
            passwordToggle: true,
            confirmPasswordToggle: true,
            giftToken: gift_token
        }
    }

    componentDidMount() {
        this.verifyLink()
    }

    resetPassword = () => {
        this.setState({ passwordError: false, confirmPasswordError: false });
        const { password, confirmPassword } = this.state;

        if (password && password.length < 6) {
            this.setState({ passwordError: 'Password should contain atleast 6 letters', confirmPasswordError: false });
            return;
        }
        else if (password !== confirmPassword) {
            this.setState({ confirmPasswordError: 'Password do not match', passwordError: false });
            return;
        }
        this.reserPasswordApiCall();
    }

    reserPasswordApiCall = () => {
        let { token, password, confirmPassword, redirectUrl, email, giftToken } = this.state;
        let endpoint = `/reset-password`;
        let body = {
            token: token,
            new_password: password,
            confirm_password: confirmPassword
        }
        this.setState({ enableBtnLoader: true });
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success) {
                    if (giftToken) {
                        // login user
                        this.loginUser(res);
                        if (helpers.isWeb()) {
                            this.props.navigateToScreen(`/claim-gift?token=${giftToken}`);
                        } else {
                            this.props.navigation.replace('/claim-gift', { query: `token=${giftToken}` });
                        }
                    }
                    else {
                        let link = `/sign-in?redirect=${redirectUrl}&email=${email}`
                        this.navigateTo(link, email);
                    }
                }
                else {
                    let confirmPasswordError = res.message ? res.message : res.data && res.data.message ? res.data.message : 'Something went wrong. Please try again.'
                    let passwordError = false;
                    if (res.data && res.data.error && res.data.error.confirm_password) {
                        confirmPasswordError = res.data.error.confirm_password;
                        passwordError = false;
                    }
                    else if (res.data && res.data.error && res.data.error.new_password) {
                        confirmPasswordError = false;
                        passwordError = res.data.error.new_password;
                    }
                    this.setState({ enableBtnLoader: false, confirmPasswordError, passwordError })
                }
            })
            .catch((error) => {
                let error_msg = error.message ? error.message : error.data && error.data.message ? error.data.message : 'Something went wrong. Please try again.'
                this.setState({ enableBtnLoader: false, confirmPasswordError: error_msg })
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    verifyLink = () => {
        let { token } = this.state;
        let endpoint = `/verify-reset-password`;
        let body = {
            token: token
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'verified') {
                    this.setState({ showVerifyLoader: false, linkExpired: false, email: res.data.email, redirectUrl: res.data.redirect_url })
                }
                else {
                    this.setState({ showVerifyLoader: false, linkExpired: true })
                }
            })
            .catch((error) => {
                this.setState({ showVerifyLoader: false, linkExpired: true })
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    loginUser = (res) => {
        try {
            try {
                Mixpanel.identify(res.data.reference_id);
                Mixpanel.people.set({
                    name: res.data.full_name,
                    "$email": res.data.account_email
                });
                Mixpanel.track('login', { method: 'email' }, true);
            }
            catch (error) {
                console.log("mix panel error ==>", error);
            }
            try {
                GoogleAnalytics.event('Account', 'login', 'email');
            }
            catch (error) {
                console.log("google analytics error ==>", error);
            }

            if (helpers.isWeb()) {
                let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
                cookies.set('token', res.data.token, { path: '/', expires: cookieDate });
                
                if (res.data.dark_mode) {
                    this.setDarkMode((res.data.dark_mode ? res.data.dark_mode.web : 'default'), (res.data.dark_mode?.webSaved != true));
                }
            } else {
                const userdata = {
                    data: res.data
                };
                this.props.setUserToken(userdata);
                if (res.data.dark_mode) {
                    this.setDarkMode((res.data.dark_mode ? res.data.dark_mode.app : 'default'), (res.data.dark_mode?.appSaved != true));
                }
            }
        }
        catch (error) {
            console.log("reset password screen login error ==>", error);
        }
    }

    setDarkMode = (darkMode, toggle) => {
        this.props.saveDarkMode(darkMode);
        this.props.darkModeToggle(toggle ? true : false);
        if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
            cookies.set('dark_mode', darkMode, { path: '/', expires: cookieDate });
        }
    }

    navigateToHome = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/')
        }
        else {
            this.props.navigation.navigate('/');
        }
    }

    navigateToLogin = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-in?redirect=/')
        }
        else {
            this.props.navigation.navigate('/sign-in', { redirect: '/', verifyRedirect: '/' });
        }
    }

    navigateTo = (link, email) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen(link)
        }
        else {
            this.props.navigation.replace('/sign-in', { email: email });
        }
    }

    renderSetNewPassword() {
        let { email, enableBtnLoader, password, confirmPassword, confirmPasswordError, passwordError, passwordToggle, confirmPasswordToggle, giftToken } = this.state;
        let closeBtnProp = {};
        closeBtnProp = {
            onPress: this.navigateToHome
        }
        return (
            <View style={[helpers.isDesktop() && Margin.mt_3, helpers.isDesktop() && Margin.mb_3, { paddingHorizontal: helpers.isDesktop() ? 50 : 0 }, { backgroundColor: 'white' }]}>
                {helpers.isWeb() ?
                    <View style={[!helpers.isDesktop() && Padding.ph_2, Flex.fill, !helpers.isDesktop() && Padding.pt_5, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        {!helpers.isDesktop() && <TouchableOpacityLink {...closeBtnProp}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{}}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        /></TouchableOpacityLink>}
                        <Text style={[forgotPassword.title, !helpers.isDesktop() && { paddingTop: 20, textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Set up new password</Text>
                    </View>
                    :
                    <View style={[!helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && Padding.pt_2, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        <TouchableOpacityLink {...closeBtnProp}><View style={[Flex.alignEnd]}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        /></View></TouchableOpacityLink>
                        <Text style={[forgotPassword.title, !helpers.isDesktop() && { paddingTop: 20, textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Set up new password</Text>
                    </View>
                }
                <View style={[!helpers.isDesktop() && Padding.ph_2, helpers.isDesktop() && { maxWidth: 400 }, !helpers.isWeb() && { height: '100%' }]}>
                    <View style={[Flex.row]}><Text style={[forgotPassword.text, Padding.pb_5, !helpers.isDesktop() && Padding.pt_4, { fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 22 : 18 }]}>Enter a new password for <Text style={{ color: '#000' }}>{email}</Text></Text></View>
                    <View style={[Margin.mt_2]}>
                        <TextInput
                            placeholder={'New password'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[formStyle.inputBoxStyle, passwordError && formStyle.inputBoxError, { marginBottom: 22 }, passwordError && { marginBottom: 0 }]}
                            value={password}
                            onChangeText={(value) => this.setState({ password: value, passwordError: false })}
                            secureTextEntry={passwordToggle}
                        />
                        {password &&
                            <TouchableOpacity onPress={() => this.setState({ passwordToggle: !passwordToggle })} style={[formStyle.showPassBtn]}>
                                <Text style={[formStyle.showPassBtnText]}>{passwordToggle ? 'SHOW' : 'HIDE'}</Text>
                            </TouchableOpacity>
                        }
                    </View>

                    {passwordError && <View>
                        <Text style={[Typo.h4, Margin.mb_0, unlockBox.errorText]}>{passwordError}</Text>
                    </View>
                    }

                    <View style={[Margin.mt_2]}>
                        <TextInput
                            placeholder={'Confirm new password'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[formStyle.inputBoxStyle, confirmPasswordError && formStyle.inputBoxError, { marginBottom: 22 }, confirmPasswordError && { marginBottom: 0 }]}
                            value={confirmPassword}
                            onChangeText={(value) => this.setState({ confirmPassword: value, confirmPasswordError: false })}
                            secureTextEntry={confirmPasswordToggle}
                        />
                        {confirmPassword &&
                            <TouchableOpacity onPress={() => this.setState({ confirmPasswordToggle: !confirmPasswordToggle })} style={[formStyle.showPassBtn]}>
                                <Text style={[formStyle.showPassBtnText]}>{confirmPasswordToggle ? 'SHOW' : 'HIDE'}</Text>
                            </TouchableOpacity>
                        }
                    </View>

                    {confirmPasswordError && <View>
                        <Text style={[Typo.h4, Margin.mb_0, unlockBox.errorText]}>{confirmPasswordError}</Text>
                    </View>
                    }

                    <View style={[Margin.mt_5]}>
                        <ButtonGradient
                            title={giftToken ? 'SET PASSWORD' : 'UPDATE PASSWORD'}
                            rounded={false}
                            fullWidth={true}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, { height: 50 }],
                                btnText: [button.primaryGradientText, { paddingHorizontal: helpers.isDesktop() ? 70 : 0, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, fontSize: helpers.isDesktop() ? 17 : 14 }],
                            }}
                            onClick={() => this.resetPassword()}
                            disable={!email || enableBtnLoader || !password || !confirmPassword}
                            loader={enableBtnLoader}
                        />
                    </View>
                </View>
            </View>
        )
    }


    renderLinkExpired() {
        let { email } = this.state;
        let closeBtnProp = {};
        closeBtnProp = {
            onPress: this.navigateToHome
        }
        return (
            <View style={[helpers.isDesktop() && Margin.mt_3, helpers.isDesktop() && Margin.mb_3, { paddingHorizontal: helpers.isDesktop() ? 50 : 0 }, { backgroundColor: 'white' }]}>
                {helpers.isWeb() ?
                    <View style={[!helpers.isDesktop() && Padding.ph_2, helpers.isWeb() && Flex.fill, !helpers.isDesktop() && Padding.pt_5, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        {!helpers.isDesktop() && <TouchableOpacityLink {...closeBtnProp}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        /></TouchableOpacityLink>}
                        <Text style={[forgotPassword.title, !helpers.isDesktop() && { paddingTop: 20, textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Link Expired!</Text>
                    </View>
                    :
                    <View style={[!helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && Padding.pt_2, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        <TouchableOpacityLink {...closeBtnProp}><View style={[Flex.alignEnd]}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        /></View></TouchableOpacityLink>
                        <Text style={[forgotPassword.title, !helpers.isDesktop() && { paddingTop: 20, textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Link Expired!</Text>
                    </View>
                }
                <View style={[!helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && Padding.pt_4, helpers.isDesktop() && { maxWidth: 400 }, !helpers.isWeb() && { height: '100%' }]}>
                    <Text style={[forgotPassword.text, Padding.pb_2, { fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 22 : 18 }]}>Your reset password link has expired. If you still want to reset your password, please click on 'Forgot password' again from the sign in section</Text>
                    <View style={[!helpers.isDesktop() && Margin.mt_3]}>
                        <ButtonGradient
                            title={'RETURN TO SIGN IN'}
                            rounded={false}
                            fullWidth={true}
                            rootStyle={{
                                btnWrapper: button.primaryGradient,
                                btnText: [button.primaryGradientText, { paddingHorizontal: helpers.isDesktop() ? 70 : 0, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, fontSize: helpers.isDesktop() ? 17 : 14 }],
                            }}
                            onClick={() => this.navigateToLogin()}
                        />
                    </View>
                </View>
            </View>
        )
    }

    verifyLinkLoader() {
        return (
            <View>
                <ActivityIndicator size={75} color={'#907CFF'} />
                <Text style={[Margin.mt_3, { fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 28.8 : 24, color: '#907CFF' }]}>Verifying link</Text>
            </View>
        )
    }

    render() {
        const webView = helpers.isWeb();
        const desktopView = webView && helpers.isDesktop();
        let { showVerifyLoader, linkExpired } = this.state;
        let closeBtnProp = {};
        if (helpers.isWeb()) {
            closeBtnProp = {
                onPress: this.navigateToHome
            }
        }
        else {
            closeBtnProp = {
                onPress: this.navigateToHome
            }
        }

        let data = {
            pageName : 'Reset Password'
        }

        return (
            <View style={{ height: '100%' }}>
                {(webView && process.browser && cookies.get("token") != undefined) && <Redirect to="/" />}
                {helpers.isDesktop() && <AppHeaderView />}
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />

                <View style={[signUpPage.wrapper, helpers.isDesktop() && Flex.justfiyCenter, { backgroundColor: desktopView ? '#E5E5E5' : 'white' }, helpers.isWeb() && { height: '100vh' }, helpers.isDesktop() && { paddingLeft: 25, paddingRight: 25, paddingTop: 70, paddingBottom: 10, flex: 1 }]}>
                    {!showVerifyLoader ?
                        <View style={[Flex.row, webView && { maxWidth: '1400px', margin: desktopView ? 'auto' : 0, height: helpers.isDesktop() ? 530 : '100%' }]}>
                            <View style={[{ width: desktopView ? '50%' : '100%', backgroundColor: 'white' }, helpers.isDesktop() && Flex.justfiyCenter]}>
                                {webView
                                    ? linkExpired ? this.renderLinkExpired() : this.renderSetNewPassword()
                                    : <ScrollView style={{ height: '100%' }}>{linkExpired ? this.renderLinkExpired() : this.renderSetNewPassword()}</ScrollView>
                                }

                            </View>
                            {desktopView &&
                                <>
                                    <View style={{ backgroundColor: 'white', paddingTop: 10, paddingBottom: 3, zIndex: -1 }}><View style={[Margin.mt_2, Margin.mb_2, { borderLeftWidth: 1, borderColor: '#DEDEDE', height: '94%' }]} /></View>
                                    <View style={[{ width: '50%', backgroundColor: 'white', paddingHorizontal: helpers.isDesktop() ? 45 : 0, zIndex: -1 }, Flex.justfiyCenter, Flex.alignCenter]}>
                                        <View style={[Flex.row, { alignItems: 'end' }]}>
                                            <CustomImage
                                                source={forgotPasswordImg}
                                                require={true}
                                                style={{ width: 400, height: 400, resizeMode: 'contain' }}
                                                webStyle={{ width: 400, height: 400, objectFit: 'contain' }}
                                            />
                                        </View>
                                        {/* <TouchableOpacityLink {...closeBtnProp}><CustomImage
                                            source={closeIcon}
                                            require={true}
                                            style={{ width: 28, height: 28, position: 'absolute', right: 35, top: 30, zIndex: 1 }}
                                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 35, top: 30, zIndex: 1 }}
                                        /></TouchableOpacityLink> */}
                                    </View>
                                </>
                            }
                        </View>
                        :
                        <View style={[Flex.row, { width: helpers.isDesktop() ? '1400px' : '100%', margin: desktopView ? 'auto' : 0, height: helpers.isDesktop() ? 530 : '100%', justfiyCenter: 'center', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }]}>
                            {this.verifyLinkLoader()}
                        </View>
                    }
                </View>
            </View>
        );
    }
}


const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUserToken, saveDarkMode, darkModeToggle }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);