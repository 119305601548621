import React, { useState } from "react";
import { View, Text, TextInput, TouchableOpacity } from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Colors,
  FontFamily,
  FontWeight,
  button,
  modalStyle,
  Typo,
  formStyle,
  unlockBox,
} from "../../styles/appStyles";
import CustomImage from '../custom-image';
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import TouchableOpacityLink from "../touchable-opacity-link";
const closeIconWhite = getImagePath("icons/close-icon-white.png");
const googleIcon = getImagePath("icons/popup-google.png");

const ExitIntentPopupWeb = (props) => {
  const goToLogin = () => {
    if (helpers.isWeb()) {
      props.navigateToScreen("/sign-in?redirect=" + props.redirectSlugs.web);
    } else {
      props.navigation.navigate("/sign-in", props.redirectSlugs.native);
    }
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign in",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "NA",
    });
    props.onClose();
  };
  const goToGoogleLogin = () => {
    props.navigateToScreen(
      "/sign-in?redirect=" +
        props.redirectSlugs.web +
        "&providerHint=google&utm_source=Website&utm_medium=Popup&utm_campaign=Exit_Popup"
    );
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    props.onClose();
  };

  const handleCloseButtonClick = () => {
    const utmSource = "Website";
    const utmMedium = "Popup";
    const utmCampaign = "Close_Popup";

    // Add UTM parameters to URL
    const url = new URL(window.location.href);
    url.searchParams.set("utm_source", utmSource);
    url.searchParams.set("utm_medium", utmMedium);
    url.searchParams.set("utm_campaign", utmCampaign);
    const newUrl = url.toString();
    window.history.pushState({}, "", newUrl);

    props.onClose();
    // Reset URL to remove UTM parameters
    window.history.replaceState({}, "", window.location.pathname);
  };

  // provider parameter - google
  return (
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        <Text style={styles.modalText}>
          Start your free
          <br />
          trial today.
        </Text>
        <Text style={styles.subtitle}>
          Sign up to read our Daily Edition in your inbox and all our free
          stories.
        </Text>
        <TouchableOpacity style={styles.container} onPress={goToGoogleLogin}>
          <View style={styles.button}>
            <CustomImage
              source={googleIcon}
              require={true}
              webStyle={{
                width: 30,
                height: 30,
                marginRight: 10,
                marginLeft: 6,
              }}
              style={{ width: 30, height: 30, marginRight: 10, marginLeft: 6 }}
            />
            <Text style={styles.text}>Sign up with Google</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.closeButton}
          onPress={handleCloseButtonClick}
        >
          <Text style={styles.closeButtonText}>
            <CustomImage
              source={closeIconWhite}
              require={true}
              webStyle={{ width: 15, height: 15, marginBottom: 3 }}
              style={{ width: 15, height: 15, marginBottom: 3 }}
            />
          </Text>
        </TouchableOpacity>
        <View style={styles.tcText}>
          <Text style={styles.tcTextTitle}>*</Text>
          <TouchableOpacity
            onPress={() => navigation.navigate("/terms-and-conditions")}
          >
            <Text style={styles.tcTextTitlelink}>T&Cs</Text>
          </TouchableOpacity>
          <Text style={styles.tcTextTitle}> and </Text>
          <TouchableOpacity
            onPress={() => navigation.navigate("/privacy-policy")}
          >
            <Text style={styles.tcTextTitlelink}>Privacy Policy</Text>
          </TouchableOpacity>{" "}
          <Text style={styles.tcTextTitle}> apply.</Text>
        </View>
        <View style={styles.signupText}>
          <Text style={styles.signupTitle}>Already a subscriber? </Text>
          <TouchableOpacity onPress={goToLogin}>
            <Text style={styles.signupTitlelink}> Sign in</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = {
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: '#A190FF',
    width: 650,
    borderRadius: 0,
    padding: 40,
    alignItems: "left",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    elevation: 5,
  },
  modalText: {
    lineHeight: "70px",
    color: "#ffffff",
    fontWeight: 800,
    fontSize: 60,
    fontFamily: "PlayfairDisplay-Bold",
    marginBottom: 15,
  },
  subtitle: {
    marginBottom: 20,
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 40,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
  },
  container: {
    flexDirection: "row",
    
  },
  button: {
    backgroundColor: "white",
    padding: 10,
    width: 240,
    alignItems: "left",
    flexDirection: "row",
    marginBottom: 10,
    padding: 8,
   
  },
  text: {
    color: "#292929",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 30,
    fontFamily: FontFamily.regular,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "white",
  },
  closeButtonText: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 10,
    fontSize: 20,
    fontWeight: "bold",
  },
  closeIconWhite: {
    width: 20,
    height: 20,
  },
  signupText: {
    position: "absolute",
    bottom: 15,
    right: 24,
    fontWeight: 400,
    fontSize: 14,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
    flexDirection: "row",
  },
  signupTitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 20,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
  },
  signupTitlelink: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 20,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
    textDecoration: "underline",
  },
  tcText: {
    display: 'flex',
    marginBottom: 40,
    flexDirection: "row",
  }, 
  tcTextTitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 20,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
  },
  tcTextTitlelink: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 20,
    color: "#ffffff",
    fontFamily: FontFamily.regular,
    textDecoration: "underline",
  },
  
};
export default ExitIntentPopupWeb;
