import React, { Component } from 'react';
import { withRouter, Redirect, Link } from "react-router-dom";
import { Text, View, Image, ScrollView, Platform } from 'react-native';
import PrivacyPolicy from '../../native/screens/privacy-policy';
import ReactGA from 'react-ga';

export class PrivacyPolicyScreen extends Component {
    constructor(props) {
        super(props);
        let postData = (process.browser) ? document.getElementById("postData") : null;
        let footerData = (process.browser) ? document.getElementById("footerData") : null;
        this.state = {
            searchQuery: "",
            locationHash: "",
            footerData: (footerData) ? JSON.parse(footerData.getAttribute('data')) : ((JSON.stringify(this.props.footerData) != '{}') ? this.props.footerData : null),
            postData: (postData) ? JSON.parse(postData.getAttribute('data')) : ((JSON.stringify(this.props.postData) != '{}') ? this.props.postData : null),
            token: this.props.token,
        }

        if(process.browser) {
            if(postData) {
                postData.parentNode.removeChild(postData);
            }
            if(footerData) {
                footerData.parentNode.removeChild(footerData);
            }

            try {
                ReactGA.pageview(this.props.history.location.pathname);
            }
            catch(error) {
                console.log("GA tracking error =>", error);
            }
        }
    }

    componentDidMount = () => {
        setTimeout(function () {
            window.history.scrollRestoration = "manual";
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            setTimeout(function () {
                document.body.style.overflow = 'initial';
                document.body.style.position = 'initial';
            }, 20);
        }, 20);
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }

    componentDidUpdate = () => {
        if(window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if(window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }

    navigateToScreen = (route, params) => {
        let routeArr = route.split('?')
        const { history } = this.props;
        history.push({
          pathname: routeArr[0],
          search: routeArr[1] ? ('?' + routeArr[1]) : '',
          state: params
        });
    }

    render() {
        const { searchQuery, locationHash, postData, footerData, token } = this.state;
        
        return (
            <PrivacyPolicy history={this.props.history} searchQuery={searchQuery} locationHash={locationHash} navigateToScreen={this.navigateToScreen} postData={postData} token={token} footerData={footerData}/>
        );
    }
}

export default withRouter(PrivacyPolicyScreen);