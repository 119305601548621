import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, FontFamily, FontWeight, button } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import { Mixpanel } from '../../utils/mixpanel';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';

const customized_subscription = getImagePath('img/custom-subscriptions.png');

export class SubscriptionDashboardInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount() {

    }


    render() {
        let { isOwner, navigateToTeamPage, darkMode } = this.props;
        return (
            <View style={[Padding.pl_2, Padding.pr_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000]}>
                <View style={[!helpers.isDesktop() && Margin.mb_4, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Flex.alignCenter, Margin.mb_2, Margin.mt_5]}>
                        <Text style={[{fontSize: helpers.isDesktop() ? 36 : 30, lineHeight: helpers.isDesktop() ? 43 : 38, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, color: '#000'}, darkMode && {color: 'rgba(255, 255, 255, 0.89)'},!helpers.isWeb() && {textAlign: 'center'}]}>You are a part of a team subscription</Text>
                        <PageBreak style={[Margin.mt_1, {width: helpers.isDesktop() ? '30%' : '70%'}]} darkMode={darkMode}/>
                    </View>
                    <Text style={[{ fontSize: 18, lineHeight: 25, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 },darkMode && {color: 'rgba(255, 255, 255, 0.89)'}, !helpers.isWeb() && {textAlign: 'center'}]}>{isOwner ? 'You are the owner of this team subscription. You can go to My team section and assign credits to your team members. Ensure that your team never misses out on the latest stories and newsletters from TMC' : 'You are a member of a team subscription. If you are short on privilege credits, you can request the owner of your team to assign credits to you.'}</Text>
                    <CustomImage
                        source={customized_subscription}
                        require={true}
                        style={{ width: 300, height: 200, marginLeft: 'auto', marginRight: 'auto'}}
                        webStyle={{ width: '100%', height: 'auto', maxWidth: helpers.isDesktop() ? 520 : 270, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, marginBottom: 20 }}
                        resizeMode="contain"
                        altText={'Subscription'}
                    />
                    {isOwner && <View style={[Margin.mt_2]}>
                        <ButtonGradient
                            title={'MY TEAM'}
                            onClick={() => this.props.navigateToTeamPage()}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, !helpers.isDesktop() && { marginTop: 15 }],
                                btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular }, !helpers.isDesktop() && { fontSize: 16 }],
                            }}
                            rounded={false}
                            darkMode={darkMode}
                        />
                    </View>}
                </View>
            </View>
        );
    }
}

export default SubscriptionDashboardInfo;