import { Mix_v1 } from "../../mixin-builder";
import { SubscriptionAPI } from "./abstract";
import { SubscriptionAPI__Factory__I } from "./interface";
import { SubscriptionAPIMixins_v1 } from "./mixins";



export class SubscriptionAPI__v1 extends Mix_v1(SubscriptionAPI).with(...SubscriptionAPIMixins_v1) {}



export const SubscriptionAPI__Factory__v1: SubscriptionAPI__Factory__I = {
    createInstance: (tmcAuthToken?: any) => {
        return new SubscriptionAPI__v1(tmcAuthToken)
    }
}

