import dynamic from "next/dist/next-server/lib/dynamic";
import React, { Component } from "react";
import { View, Text, TouchableOpacity, Platform, Linking, Dimensions, TextInput } from "react-native";
import { styles } from './grievanceReportStyles.js'
import ErrorModal from "../../native/components/error-modal/index.js";
import { helpers } from "../../native/utils/helpers.js";
import { ActivityIndicator } from "react-native";
import { Colors, Flex, FontFamily, Helpers, Margin, Padding, appStyles, button, editor, pageCover } from "../../native/styles/appStyles.web.js";
import Seo from "../../native/components/seo-meta/index.js";
import seoObj from "../../native/utils/seo-helper.js";
import AppHeader from "../../native/components/app-header";
import TouchableOpacityLinkExternal from "../../native/components/touchable-opacity-link-external/index.js";
import CustomImage from "../../native/components/custom-image/index.js";
import getImagePath from "../../native/utils/image-helper.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeUserToken } from '../../redux/User/user.actions.js';
import { Dropdown } from "react-native-element-dropdown";
import ButtonGradient from "../../native/components/button-gradient/index.js";
import apiHelpers from "../../native/utils/api-helpers.js";
import { AppFooter } from "../../native/components/app-footer/index.web.js";
import Button from "../../native/components/button/index.js";


const { width } = Dimensions.get('window');

const emailIcon = getImagePath('icons/email.png');
const Months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 }
]
const Years = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2021", value: 2021 },
]


export class GrievanceReportsComponent extends Component {

    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        this.state = {
            pageLoader: false,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false,
            selectedMonth: Months[0],
            selectedYear: Years[0],
            isFocussed: false,
            grievanceReportData: {},
            searchLoading: false
        }
    }

    componentDidMount = () => {
        // this.scrollToTop()
    }

    renderPageHeader = () => {
        return (
            <View style={[helpers.isDesktop() ? Margin.mb_7 : Margin.mb_3, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, Flex.alignCenter, { marginTop: helpers.isDesktop() ? 80 : 55, paddingHorizontal: helpers.isDesktop() ? 20 : 20 }]}>
                <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_2, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, !helpers.isDesktop() && { fontSize: 34 }, false && { color: Colors.lightWhite }]}>Grievance Redressal</Text>
            </View>
        )
    }

    onButtonClick = async () => {
        this.setState({ searchLoading: true })
        console.log("Month: ", this.state.selectedMonth, " and year: ", this.state.selectedYear)
        const endpoint = `/grievance/reports/${this.state.selectedYear.value}/${this.state.selectedMonth.value}/`
        apiHelpers
            .apiRequest(endpoint, {}, "POST", true)
            .then((res) => {
                if (res.success) {
                    this.setState({
                        grievanceReportData: res.data
                    });
                    console.log("debugging Res:>>>>", res.data)
                    // this.state.menu.append([{name:"TMC Subscriber App"}])
                    // console.log("menu items",this.state.menu)
                } else {
                    console.log("Error=>>", JSON.stringify(res));
                    this.setState({ grievanceReportData: {} })
                }
                this.setState({ searchLoading: false })
            })
            .catch((error) => {
                console.log("Error=>> down", JSON.stringify(error));
                this.setState({ searchLoading: false })
            });
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
      }


    renderPageContent = () => {
        let btnClickEmail;
        if (helpers.isWeb()) {
            btnClickEmail = {
                href: `mailto:grievances@themorningcontext.com`
            }
        }
        else {
            btnClickEmail = {
                onPress: () => Linking.openURL(`mailto:grievances@themorningcontext.com`)
            }
        }
        return (
            <>
                {/* <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Flex.alignCenter, Margin.mb_7, { marginTop: 0 }]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap]}>
                            <View style={[Helpers.borderBottom]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 24 }, { fontFamily: FontFamily.abrilFatface }]}>Our code of Conduct</Text>
                            </View>
                        </View>
                        <View style={[Helpers.titleLine, { backgroundColor: 'rgba(0, 0, 0, 0.6)' }]}></View>
                    </View>
                </View> */}
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb900]}>
                    <View style={[Margin.mb_4, Padding.ph_2]}>
                        <View style={[Margin.mt_2, Margin.mb_4, Flex.row, Flex.alignCenter]}>
                            <View style={[helpers.isDesktop() && Helpers.borderBottom, false && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[{ fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 22 }, { width: helpers.isDesktop() ? 900 : width - 20, color: '#000' }, false && { color: Colors.lightWhite }]}>India Monthly Report under the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 </Text>
                            </View>
                            {helpers.isDesktop() && <View style={{ height: 0.3, backgroundColor: false ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)', flex: 1, marginHorizontal: 15 }}></View>}
                        </View>
                        {!helpers.isDesktop() && <View style={{ height: 1, borderBottomWidth: 1, borderBottomColor: 'black', width: '80vw', alignSelf: 'flex-start', marginTop: -30, marginBottom: 30 }}></View>}
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black }}>The following report is published in accordance with Rule 4(1)(d) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021.
                        </Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black }}>Business news in India faces three key challenges:</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black, marginLeft: 20 }}>1. Newspapers continue to churn out voluminous, thin, shallow and poorly written articles.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black, marginLeft: 20 }}>2. Social media has amplified echo chambers and fake news creating harmful misinformation campaigns.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black, marginLeft: 20 }}>3. Advertising-funded business models have created an environment of bias and favoritism in media coverage which has lowered the quality of journalism.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black }}>Launched in September 2019, The Morning Context is the answer to the above trends. With original, deeply reported, fewer stories and dependent on subscriptions or reader revenue, TMC has only the interest of its reader or subscriber in mind. Over the years, TMC has already developed a loyal base of readers across the world.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black }}>To best serve our readers, we hold ourselves accountable to the highest standards of journalistic practices, ensuring accuracy, integrity and credibility in everything we publish. Our Editorial Code can be accessed <Text onPress={() => Linking.openURL('/editorial-code-of-conduct')} style={{ textDecorationLine: 'underline', color: 'blue' }}>here</Text>.</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black }}>If we slip up, we are committed to making improvements, as long as they are in the interest of our readers. Every month, The Morning Context publishes a report on the complaints raised by our readers, in accordance with the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021.</Text>
                    </View>
                    <View style={[Margin.mb_4, Padding.ph_2]}>
                        <View style={[Margin.mt_2, Margin.mb_4, Flex.row, Flex.alignCenter]}>
                            <View style={[helpers.isDesktop() && Helpers.borderBottom, false && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[{ fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 22 }, { width: helpers.isDesktop() ? 900 : width - 20, color: '#000' }, false && { color: Colors.lightWhite }]}>Our monthly reports can be accessed here: </Text>
                            </View>
                            {helpers.isDesktop() && <View style={{ height: 0.3, backgroundColor: false ? Colors.lineColor : 'rgba(0, 0, 0, 0.3)', flex: 1, marginHorizontal: 15 }}></View>}
                        </View>
                        {!helpers.isDesktop() && <View style={{ height: 1, borderBottomWidth: 1, borderBottomColor: 'black', width: '80vw', alignSelf: 'flex-start', marginTop: -30, marginBottom: 30 }}></View>}
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', rowGap: '3vh', paddingHorizontal: '3vw' }}>
                        <View>
                            <Dropdown
                                data={Months}
                                placeholder="Select month"
                                searchPlaceholder="Search..."
                                style={[styles.dropdown,
                                this.state.isFocussed && { borderColor: 'blue' }
                                ]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                search
                                maxHeight={300}
                                labelField="label"
                                valueField="value"
                                value={Months[0]}
                                onFocus={() => {
                                    this.setState({ isFocussed: true })
                                }}
                                onBlur={() => {
                                    this.setState({ isFocussed: false })
                                }}
                                onChange={(item) => {
                                    this.setState({ isFocussed: false, selectedMonth: item })
                                }}
                            />
                        </View>
                        <View>
                            <Dropdown
                                data={Years}
                                placeholder="Select year"
                                searchPlaceholder="Search..."
                                style={[styles.dropdown,
                                this.state.isFocussed && { borderColor: 'blue' }
                                ]}
                                placeholderStyle={styles.placeholderStyle}
                                selectedTextStyle={styles.selectedTextStyle}
                                inputSearchStyle={styles.inputSearchStyle}
                                iconStyle={styles.iconStyle}
                                search
                                maxHeight={300}
                                labelField="label"
                                valueField="value"
                                value={Years[0]}
                                onFocus={() => {
                                    this.setState({ isFocussed: true })
                                }}
                                onBlur={() => {
                                    this.setState({ isFocussed: false })
                                }}
                                onChange={(item) => {
                                    this.setState({ isFocussed: false, selectedYear: item })
                                }}
                            />
                        </View>
                    </View>
                    <View style={{ display: 'flex', marginTop: '5vh', paddingHorizontal: '3vw' }}>
                        <TouchableOpacity onPress={() => {
                            this.onButtonClick()
                        }} style={{ borderWidth: 1, borderColor: '#051462', width: helpers.isDesktop() ? '30vw' : '60vw', padding: 8, backgroundColor: '#1E1B4B', alignSelf: 'center', opacity: this.state.searchLoading ? 0.5 : null }}>
                            <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: 'white' }}>
                                {"SEARCH"}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ display: 'flex', marginTop: '10vh', width: helpers.isDesktop() ? 900 : width - 20, alignSelf: 'center' }}>
                        <View style={[Margin.mb_4, Padding.ph_2]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black, fontWeight: 700 }}>Complaints received: </Text>
                            <TextInput multiline numberOfLines={5} value={this.state.grievanceReportData?.complaints_received || "NA"} style={{ marginBottom: '3vh', fontSize: 18, borderWidth: 1, padding: 5 }} />
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black, fontWeight: 700 }}>Complaint reasons: </Text>
                            <TextInput multiline numberOfLines={5} value={this.state.grievanceReportData?.complaint_reasons || "NA"} style={{ marginBottom: '3vh', fontSize: 18, borderWidth: 1, padding: 5 }} />
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black, fontWeight: 700 }}>Action taken: </Text>
                            <TextInput multiline numberOfLines={5} value={this.state.grievanceReportData?.action_taken || "NA"} style={{ marginBottom: '3vh', fontSize: 18, borderWidth: 1, padding: 5 }} />
                        </View>
                    </View>
                    <View style={{ display: 'flex', width: helpers.isDesktop() ? 900 : width - 20, alignSelf: 'center' }}>
                        <View style={[Margin.mb_4, Padding.ph_2]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: 30, color: false ? Colors.lightWhite : Colors.black }}>Any person residing in India can register a complaint related to the content of the website within 30 days of the publication of the content. If you need to reach out to our grievance redressal officer, download the form <Text onPress={() => Linking.openURL(`${helpers.staticEndpoint}/docs/grievance-redressal-form.pdf`)} style={{ textDecorationLine: 'underline', color: 'blue' }}>here</Text>. Any section left blank or anonymous or fake submissions will not be accepted. Our Grievance Officer is Thirumurthy Surendar and can be reached at <Text onPress={() => Linking.openURL(`mailto:grievances@themorningcontext.com`)} style={{ textDecorationLine: 'underline', color: 'blue' }}>grievances@themorningcontext.com</Text>.</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <AppFooter />
                </View>
            </>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/editorial-code-of-conduct',
            native: { redirect: '/editorial-code-of-conduct', verifyRedirect: '/editorial-code-of-conduct' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/editorial-code-of-conduct',
            native: { redirect: '/editorial-code-of-conduct', verifyRedirect: '/editorial-code-of-conduct' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, false && appStyles.appBgDark]}>
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.push(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.getPost()} goBack={() => this.props.navigation.goBack()} darkMode={false} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }
                    {showUparrow &&
                        <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                            <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                <CustomImage
                                    source={upArrowIcon}
                                    require={true}
                                    style={{ width: 60, height: 60 }}
                                    webStyle={{ width: 60, height: 60 }}
                                />
                            </TouchableOpacity>
                        </View>
                    }</>
                }

                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}


const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

const ConnectedGrievanceReportsComponent = connect(mapStateToProps, mapDispatchToProps)(GrievanceReportsComponent);

// Use Next.js dynamic import to ensure client-side rendering
const ClientSideGrievanceReports = dynamic(() => Promise.resolve(ConnectedGrievanceReportsComponent), {
    ssr: false,
});

function GrievanceReports() {
    return (
        <ClientSideGrievanceReports />
    );
}

export default GrievanceReports;

let Breadcrumbs = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "FAQ",
    },
];