import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, article, pageCover, FontFamily } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const imageOverlay = getImagePath('img/imgeOverlay.png');
export class PageCoverMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
    }

    render() {
        const { title, coverImage, content, topRightContent, pageCoverOverlay, require, lazyLoad, category } = this.props;
        let ShowImageOverlay = pageCoverOverlay ? true : false;
        return (
            <View style={[Padding.ph_2, Helpers.conatinerWeb, category && helpers.isDesktop() && Helpers.conatinerWebFull, {marginBottom: helpers.isDesktop() ? 60 : 30 }, !helpers.isDesktop() && {marginBottom: 40}]}>
                <View style={[Flex.row, Flex.justifyBetween, Flex.alignCenter, helpers.isDesktop() && Margin.mb_4, {flexDirection: 'column'}]}>
                    {title && <View style={[Flex.fill]}>
                        <Text style={[Margin.mt_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, {marginBottom: helpers.isDesktop() ? 0 : 30, fontFamily: FontFamily.regular, color: '#907CFF'}]}>{title}</Text>
                    </View>
                    }
                    <View>
                        {topRightContent}
                    </View>
                </View>
                {content != '' &&
                    <View>
                        <Text style={[pageCover.content, helpers.isDesktop() && pageCover.contentDesktop,{fontFamily: FontFamily.merriweather, letterSpacing: 1, fontWeight: '500'}]}>{content}</Text>
                    </View>
                }
            </View>
        );
    }
}

export default PageCoverMobile;