import React, { Component } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

export class ScrollContainerView extends Component {
    render() {
        return (
            <ScrollContainer {...this.props} />
        )
    }
}

export default ScrollContainerView;