import { useEffect, useState } from "react";

const Timer = ({ endTime = "" }) => {
    const [timeLeft, setTimeLeft] = useState(0);
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const end = new Date(endTime.replace(" ", "T")).getTime();
      const difference = end - now;
      return difference > 0 ? difference : 0;
    };
  
    const formatTime = (timeInMilliseconds) => {
      const totalSeconds = Math.floor(timeInMilliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
  
      return `${hours.toString().padStart(2, "0")}h : ${minutes
        .toString()
        .padStart(2, "0")}m : ${seconds.toString().padStart(2, "0")}s left`;
    };
  
    useEffect(() => {
      const initialTimeLeft = calculateTimeLeft();
      setTimeLeft(initialTimeLeft);
  
      const timerInterval = setInterval(() => {
        const remainingTime = calculateTimeLeft();
        setTimeLeft(remainingTime);
  
        if (remainingTime <= 0) {
          clearInterval(timerInterval);
        }
      }, 1000);
  
      // Cleanup the interval when the component is unmounted or endTime changes
      return () => clearInterval(timerInterval);
    }, [endTime]); 
  
    return (
      <div className="timer-container">
        <div>{formatTime(timeLeft)}</div>
      </div>
    );
  };

  export default Timer;