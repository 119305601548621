import React, { useEffect, useRef, useState } from 'react';
import { View, Text, FlatList, Dimensions } from 'react-native';
import { WHAT_OUR_READERS_SAY } from '../../utils/constants';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';

const carosuelSelected = getImagePath("icons/carousel-selected.png");
const carosuelUnselected = getImagePath("icons/carousel-unselected.png");
const windowWidth = Dimensions.get('window').width;

export const TestimonialCarousel = (props) => {

    const [selectedSlide, setSelectedSlide] = useState(0);
    const carouselRef = useRef(null);
    const numberOfSlides = 9;
    const componentStyles = {
        display: 'flex',
        width: '100vw',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: 'red',
        height: '30vh'
    }

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.scrollLeft = windowWidth * selectedSlide - (5 * selectedSlide * windowWidth / 100);
        }
    }, [selectedSlide])
    return <View>
        <View ref={carouselRef} style={{ display: 'flex', width: '100vw', overflowX: 'scroll', flexDirection: 'row', alignItems: 'flex-start', columnGap: '5vw', paddingHorizontal: '5vw', }}>
            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '90vw', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[0].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'spotify-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[0].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[0].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[0].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[0].organization}</View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '90vw', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[1].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[1].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[1].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[1].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[1].organization}</View>
            </View>
            <View style={{ display: 'flex', alignItems: 'center', width: '90vw', minHeight: 400, borderWidth: 1, height: 600, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: 160, backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[2].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[2].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 25 }}>{WHAT_OUR_READERS_SAY[2].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[2].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[2].organization}</View>
            </View>

            <View style={{ display: 'flex', alignItems: 'center', width: '90vw', minHeight: 400, borderWidth: 1, height: 400, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: 160, backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[5].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[5].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 20 }}>{WHAT_OUR_READERS_SAY[5].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[5].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[5].organization}</View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '90VW', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[3].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'spotify-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[3].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[3].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[3].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[3].organization}</View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '90vw', height: 600, borderWidth: 1, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[6].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[6].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[6].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[6].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[6].organization}</View>
            </View>
            <View style={{ display: 'flex', alignItems: 'center', width: '90vw', minHeight: 200, borderWidth: 1, height: 300, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[8].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 25 }}>{WHAT_OUR_READERS_SAY[8].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[8].designation}</View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '90vw', height: 450, borderWidth: 1, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[4].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[4].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[4].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[4].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[4].organization}</View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '90vw', height: 450, borderWidth: 1, borderColor: '#E7D6C9' }}>
                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                        source={WHAT_OUR_READERS_SAY[7].logo}
                        style={{ width: 111, height: 'auto' }}
                        webStyle={{ width: 111, height: 'auto' }}
                        className={'times-internet-img'}
                        altText={name}
                    />
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                        {WHAT_OUR_READERS_SAY[7].review}
                    </Text>
                </View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[7].name}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[7].designation}</View>
                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[7].organization}</View>
            </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 0, width: '100vw', columnGap: '3vw' }}>
            {
                Array(9).fill(0).map((_, index) => {
                    return <CustomImage
                        source={index === selectedSlide ? carosuelSelected : carosuelUnselected}
                        style={{ width: 5, height: 5 }}
                        webStyle={{ width: 15, height: 15 }}
                        className={'carousel-select-status'}
                        altText={name}
                        onClick={() => setSelectedSlide(index)}
                    />
                })
            }
        </View>
    </View>
}