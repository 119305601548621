'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = convertNodeToElement;

var _elementTypes = require('./elementTypes');

var _elementTypes2 = _interopRequireDefault(_elementTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Converts a htmlparser2 node to a React element
 *
 * @param {Object} node The htmlparser2 node to convert
 * @param {Number} index The index of the current node
 * @param {Function} transform Transform function to apply to children of the node
 * @returns {React.Element}
 */
function convertNodeToElement(node, index, transform) {
  return _elementTypes2.default[node.type](node, index, transform);
}