import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  postActionBar,
  articleItemHorizontal,
  articleItem,
  articleLists,
  FontFamily,
  FontWeight,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import getImagePath from "../../utils/image-helper";
import { Mixpanel } from "../../utils/mixpanel";

const likeIcon = getImagePath("icons/like-icon.png");
const likeIconSelected = getImagePath("icons/like-icon-selected.png");
const removeIcon = getImagePath("icons/removeIcon.png");
const removeIconBlue = getImagePath("icons/removeIconBlue.png");
const authorImage = getImagePath("img/userpic.png");

export class YesterdayArticleorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => { };

  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.data.title);
    }
  };

  pushNewsletterCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "newsletter_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      newsletter_section: "Don't Stop Reading",
      newsletter_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushArticleCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "Don't Stop Reading",
      article_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  trackCateoryClick = (item) => {
    // try {
    //     if (this.props.exclusiveHome) {
    //         Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: 'home_exclusive_content', section_value: { title: item.name, slug: item.slug } });
    //     }
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  render() {
    const {
      single,
      home,
      homepage,
      data,
      viewCategory,
      viewArticle,
      viewAuthor,
      userStoryAction,
      applyStoryActions,
      userCredits,
      category,
      archive,
      exclusiveHome,
      readingList,
      readingListBtnClick,
      singleNewsletter,
      listingStory,
      hideDate,
      latestStory,
      darkMode,
    } = this.props;

    let btnClick,
      authorBtnClick,
      author2BtnClick,
      btnClickBookmark,
      btnClickLike,
      btnCategoryClick;
    if (helpers.isWeb()) {
      btnClick = {
        to: {
          pathname: "/" + data.category.slug + "/" + data.slug,
          postData: data,
        },
      };
      authorBtnClick = {
        to: "/writers/" + data.author[0].slug,
      };
      if (data.author[1]) {
        author2BtnClick = {
          to: "/writers/" + data.author[1].slug,
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          event.preventDefault();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
      const typesArray = data?.types?.map(item => {
        return item.name.toLowerCase();
      })
  
      btnCategoryClick = {
        to: "/category/" + data.category.slug,
        handleClick: data?.newsletter?.name
          ? () =>
              this.pushNewsletterCategoryClickToDataLayer(
                data?.newsletter?.slug,
                typesArray.join(",")
              )
          : () =>
              this.pushArticleCategoryClickToDataLayer(
                data?.category?.slug,
                typesArray.join(",")
              ),
      };
    } else {
      btnClick = {
        onPress: () => viewArticle(data.category.slug, data.slug, data),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author[0].slug);
        },
      };
      if (data.author[1]) {
        author2BtnClick = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
      btnClickBookmark = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "bookmark",
            data.user_story_action && data.user_story_action.bookmark
              ? false
              : true
          );
        },
      };
      btnClickLike = {
        onPress: (event) => {
          event.stopPropagation();
          applyStoryActions(
            Number(data.id),
            "like",
            data.user_story_action && data.user_story_action.like ? false : true
          );
        },
      };
      btnCategoryClick = {
        onPress: () => viewCategory(data.category.slug),
      };
    }
    return (
      <>
        <TouchableOpacityLink
          style={{
            padding: helpers.isDesktop() ? 16 : 20,
            paddingTop: 0,
            width: helpers.isDesktop() ? "33.33%" : "100%",
          }}
          {...btnClick}
          handleClick={() => this.handleClick()}
        >
          <View style={[!archive && Flex.fill]}>
            {category && (
              <TouchableOpacityLink {...btnCategoryClick}>
                <View>
                  {!exclusiveHome && (
                    <Text
                      style={[
                        {
                          fontSize: helpers.isDesktop() ? 19 : 16,
                          lineHeight: helpers.isDesktop() ? 22.8 : 19.5,
                          fontFamily: FontFamily.medium,
                          color: "#907CFF",
                          paddingBottom: 3,
                          textAlign: "left",
                          FontWeight: 600,
                          textTransform: "uppercase",
                        },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      {data.category.name}
                    </Text>
                  )}
                </View>
              </TouchableOpacityLink>
            )}
            <View
              style={[
                archive && Flex.row,
                archive && Margin.mb_4,
                { width: "320px" },
              ]}
            >
              <View style={[]}>
                {/* {(exclusiveHome && helpers.isDesktop()) && */}
                <TouchableOpacityLink
                  {...btnCategoryClick}
                >
                  <View>
                    <Text
                      style={[
                        {
                          fontSize: helpers.isDesktop() ? 19 : 16,
                          lineHeight: helpers.isDesktop() ? 22.8 : 19.5,
                          fontFamily: FontFamily.medium,
                          FontWeight: 600,
                          textTransform: "uppercase",
                          color:
                            data.category.name === "Business"
                              ? "#2CB680"
                              : data.category.name === "Chaos"
                                ? "#907cff"
                                : data.category.name === "Internet"
                                  ? "#FC876D"
                                  : "#ECB731",
                          fontFamily: "HaasGrotesk-Medium",
                          fontWeight: 500,
                        },
                        { paddingBottom: 3, textAlign: "left" },
                        darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                      ]}
                    >
                      {data.category.name}
                    </Text>
                  </View>
                </TouchableOpacityLink>
                {/* } */}
                {exclusiveHome && data.featured_image && (
                  <CustomImage
                    source={data.featured_image.url}
                    require={false}
                    style={{ width: "100%", height: 200 }}
                    webStyle={{
                      width: "100%",
                      height: 243,
                      objectFit: "cover",
                    }}
                    resizeMode={"cover"}
                    altText={
                      data.featured_image.alt && data.featured_image.alt != ""
                        ? data.featured_image.alt
                        : data.title
                          ? data.title
                          : data.story?.title
                            ? data.story.title
                            : data.featured_image.title
                    }
                  />
                )}
              </View>
              <View
                style={[
                  archive && Padding.pl_2,
                  Padding.pr_2,
                  archive && Flex.fill,
                ]}
              >
                <Text
                  accessibilityRole="header"
                  aria-level={archive ? "3" : "4"}
                  style={[
                    home && { fontSize: 30, lineHeight: 42 },
                    articleItemHorizontal.articleTitle,
                    !archive && Margin.mt_1,
                    archive && Margin.mb_2,
                    !helpers.isWeb() && articleItemHorizontal.articleTitleApp,
                    home && {
                      fontWeight: helpers.isDesktop() ? "600" : "500",
                      fontFamily: helpers.isDesktop()
                        ? FontFamily.regular
                        : FontFamily.medium,
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: 32,
                    },
                    homepage && {
                      fontWeight: "600",
                      fontFamily: FontFamily.medium,
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: helpers.isDesktop() ? 45 : 34,
                      height: 80,
                    },
                    exclusiveHome && {
                      fontSize: helpers.isDesktop() ? 24 : 22,
                      fontWeight: "400",
                      fontFamily: FontFamily.abrilFatface,
                      marginBottom: helpers.isDesktop() ? 4 : 10,
                      lineHeight: helpers.isDesktop() ? 35.52 : 32.56,
                    },
                    readingList && {
                      height: "auto",
                      marginBottom: 0,
                      fontSize: helpers.isDesktop() ? 26 : 16,
                    },
                    archive && {
                      height: "auto",
                      marginBottom: 6,
                      fontWeight: "600",
                      fontSize: helpers.isDesktop() ? 26 : 18,
                      lineHeight: helpers.isDesktop() ? 36 : 24,
                    },
                    category && {
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: helpers.isDesktop() ? 42 : 34,
                      fontWeight: "600",
                    },
                    homepage && { height: helpers.isDesktop() ? 84 : 70 },
                    !helpers.isWeb() && { fontFamily: FontFamily.bold },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {data.title}
                </Text>

                {archive && (
                  <Text
                    accessibilityRole="header"
                    aria-level="4"
                    style={[
                      articleItemHorizontal.desc,
                      helpers.isDesktop() && articleItemHorizontal.descDesktop,
                      { marginBottom: helpers.isDesktop() ? 0 : 10 },
                      {
                        maxWidth: helpers.isDesktop() ? 700 : "100%",
                        color: "#292929",
                      },
                      homepage && { fontFamily: FontFamily.regular },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    {data.strapline != ""
                      ? data.strapline
                      : helpers.trimWord(data.excerpt)}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </TouchableOpacityLink>
      </>
    );
  }
}

export default YesterdayArticleorizontal;
