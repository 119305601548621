import React, { useState, useRef } from 'react';
import { accordion, Padding, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
const arrowplus = getImagePath('icons/greyPlusIcon.png');
const arrowpNegative = getImagePath('icons/greyNegativeIcon.png');

import {
    View,
    Text,
    TouchableWithoutFeedback,
    StyleSheet,
    Animated,
    Easing,
    Platform,
} from 'react-native';

const FooterAccordian = ({ title, children, teamSubscription, index, darkMode }) => {
    const [open, setOpen] = useState(false);
    const animatedController = useRef(new Animated.Value(0)).current;
    const [bodySectionHeight, setBodySectionHeight] = useState();

    const bodyHeight = animatedController.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: Platform.OS === 'ios' ? [0, 350, bodySectionHeight] : [0, 0.5, bodySectionHeight],
    });

    const arrowAngle = animatedController.interpolate({
        inputRange: [0, 1],
        outputRange: ['0rad', `${Math.PI}rad`],
    });

    const toggleListItem = () => {
        if (open) {
            Animated.timing(animatedController, {
                duration: 300,
                toValue: 0,
                easing: Easing.bezier(0.4, 0.0, 0.2, 1),
                useNativeDriver: false,
            }).start();
        } else {
            Animated.timing(animatedController, {
                duration: 300,
                toValue: 1,
                easing: Easing.bezier(0.4, 0.0, 0.2, 1),
                useNativeDriver: false,
            }).start();
        }
        setOpen(!open);
    };


    return (
        <View style={[{ paddingVertical: teamSubscription ? (helpers.isDesktop() ? 15 : 10) : helpers.isDesktop() ? 25 : 10, cursor: 'pointer' }, { borderBottomColor: 'rgba(196, 196, 196, 0.6)', borderBottomWidth: 1 }]}>
            <TouchableWithoutFeedback onPress={() => toggleListItem()}>
                <View style={styles.titleContainer}>
                    <Text style={[open ? accordion.titleActive : accordion.title, { color: '#C4C4C4', fontSize: teamSubscription ? (helpers.isDesktop() ? 24 : 16) : helpers.isDesktop() ? 28 : 16, lineHeight: helpers.isDesktop() ? 34 : 22 }, Padding.pr_2, { paddingVertical: 10 }]}>{title}</Text>
                    <Animated.View style={[!helpers.isWeb() && { position: 'absolute', top: 24, right: 0 }]}>
                        <CustomImage
                            source={open ? arrowpNegative : arrowplus}
                            require={true}
                            style={{ width: 15, height: open ? 2 : 15 }}
                            webStyle={{ width: 15, height: open ? 2 : 15 }}
                        />
                    </Animated.View>
                </View>
            </TouchableWithoutFeedback>
            <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
                <View
                    onLayout={event =>
                        setBodySectionHeight(event.nativeEvent.layout.height)
                    }>
                    <Text style={[accordion.content, { fontSize: teamSubscription ? (helpers.isDesktop() ? 18 : 14) : helpers.isDesktop() ? 20 : 14, lineHeight: helpers.isDesktop() ? 32 : 22 }]}>
                        {children}
                    </Text>
                </View>
            </Animated.View>
        </View>
    );
};
export default FooterAccordian;

const styles = StyleSheet.create({
    bodyBackground: {
        backgroundColor: 'transparent',
        overflow: 'hidden',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 0,
    },
    bodyContainer: {
        padding: '1rem',
        paddingLeft: 0,
        position: 'absolute',
        bottom: 0,
    },
});
