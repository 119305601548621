import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Button from '../button';

class FacebookLogoutButton extends Component {

    render() {
        return (
            <FacebookLogin
                appId={this.props.appId}
                autoLoad={false}
                fields="email,name,first_name,middle_name,last_name,picture"
                callback={this.props.onLoginSuccess}
                onFailure={this.props.onFailure}
                render={this.renderComponent}
            />
        );
    }


    renderComponent = (renderProps) => {
        return <Button
            onClick={renderProps.onClick}
            {...this.props}
        />

    }
}

export default FacebookLogoutButton;