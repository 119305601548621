import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableOpacity, Platform, ActivityIndicator } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';

const btnBG = getImagePath('icons/buttonBG.png');
const btnBGDark = getImagePath('icons/buttonBgDark.png');

export class ButtonGradientView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() {
        let { title, rootStyle, onClick, disable, loader, smallBtn, rounded, fullWidth, paddingOverride, leftAligned, darkMode, darkModeOption, introBtn } = this.props;
        let btnClick;

        if (helpers.isWeb()) {
            btnClick = {
                onPress: () => onClick()
            }
        } else {
            btnClick = {
                onPress: () => onClick()
            }
        }



        let btnDisabled = disable ? disable : false;
        let btnLoader = loader ? loader : false;
        btnClick = (btnDisabled || btnLoader) ? '' : btnClick;

        let paddingClass = true;
        if (paddingOverride) {
            paddingClass = false;
        }
        return (
            <TouchableOpacity disabled={btnDisabled} {...btnClick} style={[rootStyle?.btnWrapper, (btnDisabled || btnLoader) && button.disableBtn, helpers.isDesktop() && { maxWidth: smallBtn ? '200px' : '700px',  marginRight: 'auto', width: '100%', borderRadius: 0 }, !rounded && { borderRadius: 3 }, fullWidth && { maxWidth: '100%' },this.props.managenews&&{width:250} ,leftAligned && { marginLeft: 0 }, { outline: 0 }, (darkMode && (btnDisabled || btnLoader)) && {backgroundColor: '#434343', borderWidth: 1, borderColor: '#434343'}]} activeOpacity={0.8} >

                {/* {!btnDisabled && btnBG && !btnLoader &&
                    <CustomImage
                        source={darkMode ? btnBGDark : (introBtn ? btnBGDark : btnBG)}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%'
                        }}
                        webStyle={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            // left: 0,
                            // right: 0,
                            // top: 0,
                            // bottom: 0,
                            zIndex: 1,
                        }}
                        require={true} />
                } */}
                {!btnLoader && <Text style={[paddingClass && Padding.pl_05, paddingClass && Padding.pr_05, (btnDisabled || btnLoader) && button.disableBtnText, rootStyle?.btnText, (darkMode && (btnDisabled || btnLoader)) && {color: 'rgba(255,255,255,0.3)'} ]}>{title}</Text>}
                {btnLoader && <ActivityIndicator size={25} color={'#787676'} />}
            </TouchableOpacity>
        );
    }
}

export default ButtonGradientView;