import React, { Component } from 'react';
import config from "react-native-config";
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, TextInput } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, button, formStyle, unlockBox, signUpPage, FontFamily } from '../../styles/appStyles';
import GoogleSocialButton from '../google-social-button';
import FacebookSocialButton from '../facebook-social-button';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { Mixpanel } from '../../utils/mixpanel';
import { GoogleAnalytics } from '../../utils/google-analytics';
import TouchableOpacityLink from '../touchable-opacity-link';

export class SignInSocial extends Component {
    constructor(props) {
        super(props);
        this.nodes = {}
        this.state = {}
    }

    setNodeRef(provider, node) {
        if (node) {
            this.nodes[provider] = node
        }
    }

    handleGoogleLogin = (user) => {
        this.props.handleSocialSetState({
            error: {
                social: false,
                name: false,
                email: false,
                password: false
            },
            enableBtnLoader: 'google',
        });
        let type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/social-sign-up`;
        let body = {
            provider: 'google',
            provider_id: user.profileObj.googleId,
            full_name: user.profileObj.name,
            account_email: user.profileObj.email,
            picture: user.profileObj.imageUrl,
            provider_data: JSON.parse(JSON.stringify(user)),
            type: type,
            device_data: {}
        }
        this.refs.child.triggerLogout();
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'login_success') {
                    try {
                        Mixpanel.identify(res.data.reference_id);
                        Mixpanel.people.set({
                            name: res.data.name,
                            "$email": res.data.email
                        });
                        if (res.data.is_new_user) {
                            Mixpanel.track('signup', { method: 'google' }, true);
                        }
                        else {
                            Mixpanel.track('login', { method: 'google' }, true);
                        }
                    }
                    catch (error) {
                        console.log("mix panel error =>", error);
                    }
                    try {
                        if (res.data.is_new_user) {
                            GoogleAnalytics.event('Account', 'signup', 'google');
                        }
                        else {
                            GoogleAnalytics.event('Account', 'login', 'google');
                        }
                    }
                    catch (error) {
                        console.log("google analytics error ==>", error);
                    }

                    this.props.handleSocialSetState({
                        enableBtnLoader: false
                    });

                    this.props.handleSocialRedirect(res.data);
                } else {
                    this.props.handleSocialSetState({
                        enableBtnLoader: false,
                        error: {
                            social: (res.code == 'error' && res.data.error.account_email) ? res.data.error.account_email : false,
                            name: false,
                            email: false,
                            password: false
                        }
                    });
                }
            })
            .catch((error) => {
                this.props.handleSocialSetState({
                    enableBtnLoader: false,
                    error: {
                        social: "Network Error",
                        name: false,
                        email: false,
                        password: false
                    }
                });
                console.log('Error=>>', error);
            });
    }

    handleFailure = (error) => {
        console.error("check google error =>", error);
        if (error.error == "idpiframe_initialization_failed" && error.details == "Cookies are not enabled in current environment.") {
            this.setState({ cookieError: true });
            global.cookieError = true;
        }
        else {
            this.setState({ googleLoginError: error.details ? error.details : false, cookieError: false })
            global.cookieError = false;
        }
    }

    handleGoogleLogout = () => {
        // console.log("Logout")
    }

    handleFacebookLogin = (user) => {
        this.props.handleSocialSetState({
            error: {
                social: false,
                name: false,
                email: false,
                password: false
            },
            enableBtnLoader: 'facebook',
        });
        let type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/social-sign-up`;
        let body = {
            provider: 'facebook',
            provider_id: user.userID,
            full_name: user.name,
            account_email: user.email,
            picture: user.picture.data.url,
            provider_data: JSON.parse(JSON.stringify(user)),
            type: type,
            device_data: {}
        }
        window.FB.logout();
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'login_success') {
                    try {
                        Mixpanel.identify(res.data.reference_id);
                        Mixpanel.people.set({
                            name: res.data.name,
                            "$email": res.data.email
                        });
                        if (res.data.is_new_user) {
                            Mixpanel.track('signup', { method: 'facebook' }, true);
                        }
                        else {
                            Mixpanel.track('login', { method: 'facebook' }, true);
                        }
                    }
                    catch (error) {
                        console.log("mix panel error ==>", error);
                    }
                    try {
                        if (res.data.is_new_user) {
                            GoogleAnalytics.event('Account', 'signup', 'google');
                        }
                        else {
                            GoogleAnalytics.event('Account', 'login', 'google');
                        }
                    }
                    catch (error) {
                        console.log("google analytics error ==>", error);
                    }

                    this.props.handleSocialSetState({
                        enableBtnLoader: false
                    });

                    this.props.handleSocialRedirect(res.data);
                } else {
                    this.props.handleSocialSetState({
                        enableBtnLoader: false,
                        error: {
                            social: (res.code == 'error' && res.data.error.account_email) ? res.data.error.account_email : false,
                            name: false,
                            email: false,
                            password: false
                        }
                    });
                }
            })
            .catch((error) => {
                this.props.handleSocialSetState({
                    enableBtnLoader: false,
                    error: {
                        social: "Network Error",
                        name: false,
                        email: false,
                        password: false
                    }
                });
                console.log('Error=>>', error);
            });
    }

    render() {
        const { enableBtnLoader, btnText } = this.props;
        let { cookieError, googleLoginError } = this.state;
        let cookieErrorBtnProps;
        const webView = helpers.isWeb();
        if (webView) {
            cookieErrorBtnProps = {
                to: "/enable-third-party-cookies"
            }            
        }
        return (
            <View>
                <View>
                    <GoogleSocialButton
                        ref="child"
                        provider='google'
                        appId={helpers.isWeb() ? process.env.GOOGLE_API_KEY : config.GOOGLE_API_KEY}
                        onLoginSuccess={this.handleGoogleLogin}
                        onFailure={this.handleFailure}
                        onLogoutSuccess={this.handleGoogleLogout}
                        // scope={'https://www.googleapis.com/auth/user.gender.read'}
                        rootStyle={{
                            btnWrapper: [button.btnGoogle, (enableBtnLoader && enableBtnLoader != 'google') && { opacity: 0.4 }],
                            btnText: button.btnGoogleText,
                            image: { width: 19, height: 19 }
                        }}
                        disable={(enableBtnLoader && enableBtnLoader != 'google' || global.cookieError || cookieError) ? true : false}
                        loader={(enableBtnLoader && enableBtnLoader == 'google') ? true : false}
                    // indicatorColor={''}
                    >
                        {btnText}
                    </GoogleSocialButton>
                    {(global.cookieError || cookieError ) &&
                        <Text style={{fontSize: 14, lineHeight: 22, color: '#626262', textAlign: 'center', marginTop: 10, fontFamily: FontFamily.regular}}>To use Google Login, you need to enable 3rd party cookies in your browser. 
                            <TouchableOpacityLink {...cookieErrorBtnProps}>
                                 <Text style={{color: '#907CFF', textDecorationLine:'underline', paddingLeft: 5}}>Click here to know how</Text>
                            </TouchableOpacityLink>
                        </Text>
                    }
                    {googleLoginError &&
                        <Text style={[Typo.h4, Margin.mt_0, Margin.mb_0, unlockBox.errorText, Helpers.textCenter]}>{googleLoginError}</Text>
                    }
                </View>
                <View style={Margin.mt_2}>
                    <FacebookSocialButton
                        provider='facebook'
                        appId={helpers.isWeb() ? process.env.FACEBOOK_API_KEY : config.FACEBOOK_API_KEY}
                        onLoginSuccess={(user) => this.handleFacebookLogin(user)}
                        onFailure={(err) => this.handleFailure(err)}
                        rootStyle={{
                            btnWrapper: [button.btnFB, (enableBtnLoader && enableBtnLoader != 'facebook') && { opacity: 0.4 }],
                            btnText: button.btnFBText,
                            image: { width: 19, height: 19, paddingLeft: 20 }
                        }}
                        disable={(enableBtnLoader && enableBtnLoader != 'facebook') ? true : false}
                        loader={(enableBtnLoader && enableBtnLoader == 'facebook') ? true : false}
                    // indicatorColor={'#ffffff'}
                    >
                        {btnText}
                    </FacebookSocialButton>
                </View>
            </View>
        );
    }
}

export default SignInSocial;