import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookButton from './facebook-button';
import Button from '../button';

class FacebookSocialButton extends Component {

    render() {
        return (
            <FacebookLogin
                appId={this.props.appId}
                autoLoad={false}
                fields="email,name,first_name,middle_name,last_name,picture"
                callback={this.props.onLoginSuccess}
                onFailure={this.props.onFailure}
                render={this.renderComponent}
                disableMobileRedirect={true}
            />
        );
    }


    renderComponent = (renderProps) => {
        let { custom } = this.props;
        if (custom) {
            return <Button
                onClick={renderProps.onClick}
                {...this.props}
                loader={this.props.loader}
                disable={this.props.disable}
            />
        }
        else {
            return (
                <FacebookButton
                    title={this.props.children + "Facebook"}
                    onClick={renderProps.onClick}
                    {...this.props}
                    loader={this.props.loader}
                    disable={this.props.disable}
                    indicatorColor={this.props.indicatorColor}
                />
            )
        }
    }
}

export default FacebookSocialButton;