import ReactGA from 'react-ga';

let actions = {
    event: (category, action, label) => {
    	let eventObj = {
          category: category,
          action: action
        }
        if (label) {
        	eventObj.label = label;
        }
        ReactGA.event(eventObj);
    }
};

export let GoogleAnalytics = actions;