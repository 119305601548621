import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList, TextInput } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, teamSubscription, FontFamily, FontWeight, pageCover, pageContent, Colors, input, bannerStyles, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import ContentLoader from "react-native-easy-content-loader";
import moment from 'moment';
import ButtonGradient from '../../components/button-gradient';
import InviteMember from '../../components/invite-member';
import AddCredits from '../../components/add-credits';
import { Menu, Divider, Provider } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import Button from '../../components/button';
import MenuPopup from '../../components/menu-popup';
import Seo from "../../components/seo-meta";
import MemberConfirmation from '../../components/member-confirmation';
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const cookies = new Cookies();

const dualCircleBlue = getImagePath('icons/dualCircleBlue.png');
const blueDots = getImagePath('icons/purple-dots-b.png');
const searchIcon = getImagePath('icons/searchbar.png');
const dots = getImagePath('icons/dark-dots.png');
const arrow = getImagePath('icons/arrow-down-dark.png');
const greenArrow = getImagePath('icons/arrowGreenUp.png');
const addMember = getImagePath('icons/add-member.png');
const noaccess = getImagePath('img/noAccess.png');

const checkBoxSelected = getImagePath('icons/checked.png');
const checkBoxUnSelected = getImagePath('icons/uncheck.png');
const checkBoxSelectedAll = getImagePath('icons/check-minus.png');
const noaccessDark = getImagePath('img/noaccessDark.png');

export class MyTeam extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;

        this.state = {
            pageLoader: true,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false,
            token: token,
            creditTransactionType: 'credit',
            credits: '0',
            inviteCredits: '4'
        }

    }

    componentDidMount = () => {
        this.getSubscriptionDetails();
    }

    componentWillUnmount = () => {
        if (this.scrollRef) {
            this.scrollRef();
        }
    }

    getSubscriptionDetails = (saveCredits, inviteCredits) => {
        this.setState({
            showErrorModal: false,
            pageLoader: true,
            inviteCredits: saveCredits ? inviteCredits : this.state.inviteCredits
        });
        const { token } = this.state;
        let endpoint = `/profile`;
        let body = {
            request_group: ["subscription"],
            team_members: true
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    this.setState({ pageLoader: false });
                    return false;
                }
                let teamSubscriptionData = (!res.data.team_subscription || JSON.stringify(res.data.team_subscription) == '{}') ? false : res.data.team_subscription;
                let checkObj = {}
                if (teamSubscriptionData?.team_details?.team_members) {
                    teamSubscriptionData.team_details.team_members.map((member) => {
                        checkObj[member.member_email] = false;
                    })
                }

                if (res.success) {
                    this.setState({
                        teamSubscriptionData: teamSubscriptionData,
                        checkObj: checkObj,
                        pageLoader: false
                    });
                }
                else {
                    this.setState({ showErrorModal: true, pageLoader: false });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getModalClickEvent = () => {

    }

    scrollToTop = () => {
        if (helpers.isWeb()) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(0)
        }
    }

    setCredits = (value, membersSelected, maxDebit) => {
        let key = value.split('');
        let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        for (var i = key.length - 1; i >= 0; i--) {
            if (keyList.indexOf(key[i]) == -1) {
                return false;
            }
        }
        let { teamSubscriptionData, credits, creditTransactionType } = this.state;
        if ((parseInt(value) > 0 && creditTransactionType == 'credit' && credits.split('').length <= key.length) && ((parseInt(teamSubscriptionData.team_details?.credits_available) / membersSelected) < parseInt(value))) {
            return false;
        } else if (parseInt(value) > 0 && creditTransactionType == 'debit' && parseInt(value) > maxDebit) {
            return false;
        }
        this.setState({ credits: value });
    }

    openOrderDetails = () => {
        let pushProp = JSON.parse(JSON.stringify(window.location));
        pushProp.hash = "accounts/orders";
        this.props.history.push(pushProp);
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                <View style={[helpers.isDesktop() && Margin.mb_7, Helpers.textCenter, { backgroundColor: darkMode ? Colors.darkBlackColor : '#F9F8FF', marginTop: helpers.isDesktop() ? 130 : 75, paddingHorizontal: helpers.isDesktop() ? 20 : 0, width: '100%' }]}>
                    <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, { position: 'relative', top: helpers.isDesktop() ? -38 : -19 }, darkMode && { color: Colors.lightWhite }]}>My Team</Text>
                    <Text style={[{ marginBottom: helpers.isDesktop() ? 90 : 50 }, pageContent.subTitle, helpers.isDesktop() && pageContent.subTitleDesktop, { maxWidth: helpers.isDesktop() ? 900 : '100%' }, !helpers.isWeb() && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>Manage and add your team members to TMC. Each member will get full access to all of TMC stories and newsletters.</Text>
                    <CustomImage
                        source={blueDots}
                        require={true}
                        style={{ width: 50, height: 20.5, position: 'absolute', right: helpers.isDesktop() ? -15 : 20, top: helpers.isDesktop() ? -10 : -10 }}
                        webStyle={{ width: helpers.isDesktop() ? 60 : 45, height: helpers.isDesktop() ? 25 : 20, position: 'absolute', right: helpers.isDesktop() ? -15 : 20, top: helpers.isDesktop() ? -10 : -10 }}
                    />
                    <CustomImage
                        source={dualCircleBlue}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 50 : 30, height: helpers.isDesktop() ? 50 : 30, position: 'absolute', left: helpers.isDesktop() ? -30 : 0, bottom: 15 }}
                        webStyle={{ width: helpers.isDesktop() ? 50 : 30, height: helpers.isDesktop() ? 50 : 30, position: 'absolute', left: helpers.isDesktop() ? -20 : 0, bottom: 36 }}
                    />
                </View>
            </View>
        )
    }

    renderGoogleInc = () => {
        let { teamSubscriptionData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const defaultCompany = darkMode ? getImagePath('icons/default-company-logo-white.png') : getImagePath('icons/default-company-logo.png');
        if (teamSubscriptionData) {
            return (
                <>
                    <View style={[Helpers.alignCenter, { marginBottom: 10, marginTop: 40, justifyContent: 'space-between', flexDirection: helpers.isDesktop() ? 'row' : 'column', alignItems: 'flex-start' }, !helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                        <View style={[helpers.isDesktop() && Flex.row, helpers.isDesktop() && Flex.alignCenter]}>
                            <View>
                                {(teamSubscriptionData.plan.tmc_group == 'team') ?
                                    <>
                                        <View style={[helpers.isDesktop() && { borderWidth: 2, borderColor: '#C4C4C4', borderRadius: 4, padding: 20 }, !helpers.isDesktop() && [Flex.row, Flex.alignCenter]]}>
                                            <CustomImage
                                                source={teamSubscriptionData.user_info.company_logo ? teamSubscriptionData.user_info.company_logo : defaultCompany}
                                                require={true}
                                                style={{ width: helpers.isDesktop() ? 134 : 40, height: helpers.isDesktop() ? 134 : 40, resizeMode: 'cover' }}
                                                webStyle={{ width: helpers.isDesktop() ? 134 : 40, height: helpers.isDesktop() ? 134 : 40, objectFit: 'cover' }}
                                                altText={teamSubscriptionData.user_info.company_name ? teamSubscriptionData.user_info.company_name : `${(teamSubscriptionData.user_info.first_name != '' ? teamSubscriptionData.user_info.first_name : teamSubscriptionData.user_info.last_name)} Team`}
                                            />
                                            {!helpers.isDesktop() && <Text style={[teamSubscription.googleText, helpers.isDesktop() && teamSubscription.googleTextDesktop, helpers.isDesktop() && { paddingBottom: 25 }, !helpers.isDesktop() && { paddingVertical: 10, paddingLeft: 11, fontSize: 20 }, { color: darkMode ? Colors.lightWhite : Colors.black }]}>{teamSubscriptionData.user_info.company_name ? teamSubscriptionData.user_info.company_name : `${(teamSubscriptionData.user_info.first_name != '' ? teamSubscriptionData.user_info.first_name : teamSubscriptionData.user_info.last_name)} Team`}</Text>}
                                        </View>
                                        {/*{helpers.isDesktop() && <Button
                                        title={'UPLOAD NEW'}
                                        rootStyle={{
                                            btnWrapper: [button.uploadButton],
                                            btnText: [button.uploadButtonText, { paddingHorizontal: 30 }]
                                        }}
                                        //onClick={() => this.goToLogin()}
                                    />}*/}
                                    </>
                                    :
                                    <>
                                        <View style={[Flex.row, Flex.alignCenter]}>
                                            <CustomImage
                                                source={teamSubscriptionData.user_info.company_logo ? teamSubscriptionData.user_info.company_logo : defaultCompany}
                                                require={true}
                                                style={{ width: helpers.isDesktop() ? 150 : 40, height: helpers.isDesktop() ? 150 : 40, resizeMode: 'cover' }}
                                                webStyle={{ width: helpers.isDesktop() ? 150 : 40, height: helpers.isDesktop() ? 150 : 40, objectFit: 'cover' }}
                                                altText={teamSubscriptionData.user_info.company_name ? teamSubscriptionData.user_info.company_name : `${(teamSubscriptionData.user_info.first_name != '' ? teamSubscriptionData.user_info.first_name : teamSubscriptionData.user_info.last_name)} Team`}
                                            />
                                            {!helpers.isDesktop() && <Text style={[teamSubscription.googleText, helpers.isDesktop() && teamSubscription.googleTextDesktop, { paddingVertical: 20, marginLeft: 15 }, !helpers.isDesktop() && { fontSize: 20 }, { color: darkMode ? Colors.lightWhite : Colors.black }]}>{teamSubscriptionData.user_info.company_name ? teamSubscriptionData.user_info.company_name : `${(teamSubscriptionData.user_info.first_name != '' ? teamSubscriptionData.user_info.first_name : teamSubscriptionData.user_info.last_name)} Team`}</Text>}
                                        </View>
                                    </>
                                }
                            </View>
                            <View style={[Helpers.alignCenter, helpers.isDesktop() && { paddingLeft: 40 }, !helpers.isDesktop() && { paddingVertical: 15 }]}>
                                {helpers.isDesktop() && <Text style={[teamSubscription.googleText, helpers.isDesktop() && teamSubscription.googleTextDesktop, helpers.isDesktop() && { paddingBottom: 25 }, !helpers.isDesktop() && { paddingVertical: 20 }, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData.user_info.company_name ? teamSubscriptionData.user_info.company_name : `${(teamSubscriptionData.user_info.first_name != '' ? teamSubscriptionData.user_info.first_name : teamSubscriptionData.user_info.last_name)} Team`}</Text>}
                                <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop, { color: '#4D4D4D' }, !helpers.isDesktop() && { marginBottom: 8, fontSize: 14 }, darkMode && { color: Colors.whiteColor }]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop, !helpers.isDesktop() && { fontSize: 12 }, darkMode && { color: Colors.lightWhite }]}>Plan: </Text>{teamSubscriptionData.plan.name}</Text>
                                {(teamSubscriptionData.plan.tmc_group == 'team') ?
                                    <>
                                        <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop, { color: '#4D4D4D' }, !helpers.isDesktop() && { marginBottom: 8, fontSize: 14 }, darkMode && { color: Colors.whiteColor }]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop, !helpers.isDesktop() && { marginBottom: 10, fontSize: 12 }, darkMode && { color: Colors.lightWhite }]}>No. of seats purchased: </Text>{teamSubscriptionData?.team_details?.seats_purchased}</Text>
                                        {teamSubscriptionData.next_billing_at && <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop, { color: '#4D4D4D' }, !helpers.isDesktop() && { fontSize: 14 }, darkMode && { color: Colors.whiteColor }]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop, !helpers.isDesktop() && { marginBottom: 10, fontSize: 12 }, darkMode && { color: Colors.lightWhite }]}>Next renewal on: </Text>{moment(teamSubscriptionData.next_billing_at).format('Do MMMM YYYY')}</Text>}
                                    </>
                                    :
                                    <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop, { color: '#4D4D4D' }, !helpers.isDesktop() && { fontSize: 14 }, darkMode && { color: Colors.whiteColor }]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop, !helpers.isDesktop() && { fontSize: 12 }, darkMode && { color: Colors.lightWhite }]}>Date of Purchase: </Text> {moment(teamSubscriptionData.purchase_date).format('Do MMMM YYYY')}</Text>
                                }
                                {helpers.isDesktop() && <TouchableOpacity onPress={() => this.openOrderDetails()}><Text style={[teamSubscription.link, { fontSize: 22, lineHeight: 44 }]}>View order details</Text></TouchableOpacity>}
                            </View>
                        </View>
                        {(teamSubscriptionData.plan.tmc_group == 'team_credit' && teamSubscriptionData.is_team_owner && Platform.OS != 'ios') &&
                            <Button
                                title={(teamSubscriptionData.plan.tmc_group == 'team') ? 'Upgrade Seats' : 'Top-up Credits'}
                                rootStyle={{
                                    btnWrapper: [darkMode ? button.primaryOutlineSmallDark : button.primaryOutlineSmall, { height: helpers.isDesktop() ? 46 : 40 }],
                                    btnText: [darkMode ? button.primaryOutlineTextSmallDark : button.primaryOutlineTextSmall, { paddingHorizontal: helpers.isDesktop() ? 30 : 15, fontWeight: '400', fontSize: helpers.isDesktop() ? 16 : 12 }]
                                }}
                                onClick={() => this.navigateToTeamPlan()}
                            />
                        }

                        {/* <View>
                            {(teamSubscriptionData.plan.tmc_group == 'team') ?
                                <>
                                    <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop]}>Total amount paid: </Text>{teamSubscriptionData.plan.currency_code == 'INR' ? '₹' : '$'} {teamSubscriptionData.purchase_amount}</Text>
                                    {teamSubscriptionData.next_billing_at && <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop]}>Next renewal on: </Text>{moment(teamSubscriptionData.next_billing_at).format('Do MMMM YYYY')}</Text>}
                                </>
                                :
                                <>
                                    <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop]}>No. of credits purchased: </Text>{teamSubscriptionData.team_details.credits_purchased}</Text>
                                    <Text style={[teamSubscription.planValue, helpers.isDesktop() && teamSubscription.planValueDesktop]}><Text style={[teamSubscription.planText, helpers.isDesktop() && teamSubscription.planTextDesktop]}>Amount paid: </Text>{teamSubscriptionData.plan.currency_code == 'INR' ? '₹' : '$'} {teamSubscriptionData.plan.price}</Text>
                                </>
                            }
                        </View> */}
                    </View>
                </>
            )
        }
    }

    renderTeamMembers = () => {
        let { teamSubscriptionData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        if (teamSubscriptionData) {
            return (
                <View style={{ marginBottom: helpers.isDesktop() ? 60 : 0, marginTop: helpers.isDesktop() ? 80 : 20 }}>

                    <View style={[Margin.mb_3, Flex.row, Flex.justifyBetween, !helpers.isDesktop() && Flex.justfiyCenter]}>
                        <View style={[!helpers.isDesktop() && { backgroundColor: darkMode ? Colors.darkBlackColorBg : 'white', zIndex: 1, paddingHorizontal: 20 }]}>
                            <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, helpers.isDesktop() && { marginRight: 0 }, !helpers.isWeb() && { marginBottom: 1 }, darkMode && { color: Colors.lightWhite }]}>Manage team members</Text>
                            </View>
                        </View>
                        {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>}
                        {helpers.isDesktop() && <View style={{ width: 230 }}>
                            <ButtonGradient
                                title={'INVITE MEMBER'}
                                rounded={true}
                                fullWidth={true}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, margin: 0 }],
                                    btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400', paddingTop: helpers.isDesktop() ? 0 : 15, paddingBottom: helpers.isDesktop() ? 0 : 15 }]
                                }}
                                onClick={() => this.setState({ showInviteMember: true })}
                                darkMode={darkMode}
                            />
                        </View>}
                    </View>

                    {(teamSubscriptionData.plan.tmc_group == 'team') ?
                        <>
                            {helpers.isDesktop() ?
                                <View style={[Flex.row, !helpers.isDesktop() && { paddingHorizontal: 20, marginBottom: 20 }, { alignItems: helpers.isDesktop() ? 'center' : 'flex-start' }]}>
                                    <View style={{ marginRight: helpers.isDesktop() ? 30 : 25 }}>
                                        <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular }, darkMode && { color: Colors.lineColor }]}>Seats Available</Text>
                                        <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData?.team_details?.seats_available}</Text>
                                    </View>
                                    <View style={{ marginRight: helpers.isDesktop() ? 30 : 25, backgroundColor: '#EAEBEF', width: 1, height: helpers.isDesktop() ? 100 : 65 }} />
                                    <View>
                                        <View style={{ marginRight: helpers.isDesktop() ? 30 : 25 }}>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left' }, darkMode && { color: Colors.lineColor }]}>Invited:
                                                <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, { fontSize: 20, paddingLeft: 5, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData?.team_details?.pending_invites}</Text></Text>
                                        </View>
                                        <View style={{ marginRight: helpers.isDesktop() ? 300 : 25 }}>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left' }, darkMode && { color: Colors.lineColor }]}>Members:
                                                <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, { fontSize: 20, paddingLeft: 5, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData?.team_details?.total_members}</Text></Text>
                                        </View>
                                    </View>
                                </View>
                                :
                                <>
                                    <View style={[Flex.row, { paddingHorizontal: 20, justifyContent: 'space-between', borderBottomWidth: 1, borderColor: darkMode ? Colors.lineColor1 : '#EAEBEF', paddingVertical: 15 }]}>
                                        <View style={[Flex.row, { alignItems: 'center' }]}>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, fontSize: 12, paddingRight: 10 }, darkMode && { color: Colors.lineColor }]}>Seats Available</Text>
                                            <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, { fontSize: 20, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData?.team_details?.seats_available}</Text>
                                        </View>
                                        {!helpers.isDesktop() && <View style={{ width: 140 }}>
                                            <ButtonGradient
                                                title={'INVITE MEMBER'}
                                                rounded={true}
                                                fullWidth={true}
                                                rootStyle={{
                                                    btnWrapper: [button.primaryGradientBtn, { height: helpers.isDesktop() ? 46 : 40, borderRadius: 0, }],
                                                    btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 12, fontWeight: '400', paddingTop: helpers.isDesktop() ? 0 : 15, paddingBottom: helpers.isDesktop() ? 0 : 15 }]
                                                }}
                                                onClick={() => this.setState({ showInviteMember: true })}
                                                darkMode={darkMode}
                                            />
                                        </View>}
                                    </View>
                                    <View style={[Flex.row, { paddingHorizontal: 20, justifyContent: 'space-between', paddingVertical: 15 }]}>
                                        <View style={[Flex.row, Flex.justifyBetween, { width: '100%' }]}>
                                            <View style={{ marginRight: helpers.isDesktop() ? 30 : 0 }}>
                                                <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left', fontSize: 12, lineHeight: 14 }]}>Invited:
                                                    <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, { fontSize: 16, lineHeight: 22, paddingLeft: 5, color: darkMode ? Colors.lightWhite : Colors.black }]}>{teamSubscriptionData?.team_details?.pending_invites}</Text></Text>
                                            </View>
                                            <View style={{ marginRight: helpers.isDesktop() ? 300 : 0 }}>
                                                <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left', fontSize: 12, lineHeight: 14 }]}>Members:
                                                    <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, { fontSize: 16, lineHeight: 22, paddingLeft: 5, color: darkMode ? Colors.lightWhite : Colors.black }]}>{teamSubscriptionData?.team_details?.total_members}</Text></Text>
                                            </View>
                                        </View>
                                    </View>
                                </>
                            }
                        </>
                        :
                        <>
                            {helpers.isDesktop() ?
                                <View style={[Flex.row, !helpers.isDesktop() && { paddingHorizontal: 20, flexWrap: 'wrap' }]}>
                                    <View style={{ marginRight: helpers.isDesktop() ? 60 : 30 }}>
                                        <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular }, darkMode && { color: Colors.lineColor }]}>Members</Text>
                                        <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData?.team_details?.total_members}</Text>
                                    </View>
                                    <View style={{ marginRight: helpers.isDesktop() ? 60 : 30, backgroundColor: '#EAEBEF', width: 1, height: 75 }} />
                                    <View style={[!helpers.isDesktop() && { marginBottom: 20 }, { marginRight: helpers.isDesktop() ? 80 : 30 }]}>
                                        <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular }, darkMode && { color: Colors.lineColor }]}>Credits Available</Text>
                                        <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData.team_details?.credits_available}</Text>
                                    </View>
                                    <View style={[{ marginRight: helpers.isDesktop() ? 100 : 30 }]}>
                                        <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left', lineHeight: 27 }, darkMode && { color: Colors.lineColor }]}>Used Credits: <Text style={[{ color: '#000', fontWeight: '700' }, darkMode && { color: Colors.lightWhite }]}>{(teamSubscriptionData.team_details.credits_spent)}</Text></Text>
                                        <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left', lineHeight: 27, marginTop: helpers.isDesktop() ? 18 : 8 }, darkMode && { color: Colors.lineColor }]}>Total Credits: <Text style={[{ color: '#000', fontWeight: '700' }, darkMode && { color: Colors.lightWhite }]}>{teamSubscriptionData.team_details?.credits_purchased}</Text></Text>
                                    </View>
                                </View>
                                :
                                <>
                                    <View style={[Flex.row, { paddingHorizontal: 20, justifyContent: 'space-between', borderBottomWidth: 1, borderColor: darkMode ? Colors.lineColor1 : '#EAEBEF', paddingVertical: 15 }]}>
                                        <View style={[Flex.row, { alignItems: 'center' }]}>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular }, { fontSize: 12, lineHeight: 15, paddingRight: 10, width: 75, textAlign: 'left' }]}>Members</Text>
                                            <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, { fontSize: 20, lineHeight: 20 }, { color: darkMode ? Colors.lightWhite : Colors.black }]}>{teamSubscriptionData?.team_details?.total_members}</Text>
                                        </View>
                                        <View style={{ width: 140 }}>
                                            <ButtonGradient
                                                title={'INVITE MEMBER'}
                                                rounded={true}
                                                fullWidth={true}
                                                rootStyle={{
                                                    btnWrapper: [button.primaryGradientBtn, { height: helpers.isDesktop() ? 46 : 40, borderRadius: 0 }],
                                                    btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 12, fontWeight: '400' }]
                                                }}
                                                onClick={() => this.setState({ showInviteMember: true })}
                                                darkMode={darkMode}
                                            />
                                        </View>
                                    </View>
                                    <View style={[Flex.row, { paddingHorizontal: 20, justifyContent: 'space-between', paddingVertical: 15 }]}>
                                        <View style={[Flex.row, { alignItems: 'center' }]}>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular }, !helpers.isDesktop() && { fontSize: 12, lineHeight: 15, paddingRight: 10, width: 75, textAlign: 'left' }]}>Credits Available</Text>
                                            <Text style={[teamSubscription.value1, helpers.isDesktop() && teamSubscription.valueDesktop1, !helpers.isDesktop() && { fontSize: 20, lineHeight: 20 }, { color: darkMode ? Colors.lightWhite : Colors.black }]}>{teamSubscriptionData.team_details?.credits_available}</Text>
                                        </View>
                                        <View style={[{ alignItems: 'flex-end' }]}>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left', lineHeight: 17, fontSize: 12 }]}>Used Credits: <Text style={{ color: darkMode ? Colors.lightWhite : '#000', fontWeight: '700', fontSize: 16 }}>{(teamSubscriptionData.team_details.credits_spent)}</Text></Text>
                                            <Text style={[teamSubscription.text, helpers.isDesktop() && teamSubscription.textDesktop, { color: '#636363', fontFamily: FontFamily.regular, textAlign: 'left', lineHeight: 17, fontSize: 12 }]}>Total Credits: <Text style={{ color: darkMode ? Colors.lightWhite : '#000', fontWeight: '700', fontSize: 16 }}>{teamSubscriptionData.team_details?.credits_purchased}</Text></Text>
                                        </View>
                                    </View>
                                </>
                            }
                        </>
                    }
                </View>
            )
        }
    }

    openMenu = () => {
        this.setState({ visible: true });
    }


    closeMenu = () => {
        this.setState({ visible: false });
    }


    renderMembersDetails = () => {
        let { teamSubscriptionData, visible, checkObj, creditTransactionType, credits } = this.state;
        if (teamSubscriptionData && teamSubscriptionData.team_details) {
            let checkBoxCheckedTrue, checkBoxCheckedFalse, membersSelected = 0, maxDebit, teamObj = {}
            teamSubscriptionData.team_details.team_members.map((member) => {
                teamObj[member.member_email] = member.credits_available;
            });
            Object.entries(checkObj).map((checked) => {
                if (checked[1]) {
                    checkBoxCheckedTrue = true;
                    membersSelected++;
                    if (maxDebit == undefined || maxDebit > teamObj[checked[0]]) {
                        maxDebit = teamObj[checked[0]];
                    }
                } else {
                    checkBoxCheckedFalse = true;
                }
            })
            if (maxDebit == undefined) {
                maxDebit = 0;
            }
            let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
            return (
                <>
                    <View style={{ marginBottom: helpers.isDesktop() ? 20 : 25, marginTop: helpers.isDesktop() ? 20 : 0 }}>
                        {((teamSubscriptionData.plan.tmc_group == 'team_credit') && helpers.isDesktop()) &&
                            <View style={[Flex.row, Flex.alignCenter, !helpers.isDesktop() && { flexWrap: 'wrap', paddingHorizontal: 20 }, helpers.isDesktop() && { height: 68 }]}>
                                <Text style={[{ fontSize: helpers.isDesktop() ? 22 : 16, lineHeight: helpers.isDesktop() ? 26 : 22, fontFamily: FontFamily.regular, fontWeight: '500' }, !helpers.isDesktop() && { marginTop: 10, marginBottom: 10 }, darkMode && { color: Colors.lightWhite }]}>{membersSelected + ((membersSelected == 1) ? ' member' : ' members')} selected</Text>
                                {(membersSelected > 0) &&
                                    <>
                                        <Text style={[{ fontSize: helpers.isDesktop() ? 22 : 16, lineHeight: helpers.isDesktop() ? 26 : 22, fontFamily: FontFamily.regular, fontWeight: '500' }, !helpers.isDesktop() && { marginTop: 10, marginBottom: 10 }, darkMode && { color: Colors.lightWhite }]}>  |  </Text>
                                        <View style={[{ marginRight: helpers.isDesktop() ? 27 : 18, height: 40 }, !helpers.isDesktop() && { marginTop: 10, marginBottom: 10, justifyContent: 'center' }]}>
                                            <Picker
                                                selectedValue={creditTransactionType}
                                                style={[teamSubscription.pickerStyles, (creditTransactionType == 'debit') && teamSubscription.pickerStylesDebit]}
                                                onValueChange={(itemValue, itemIndex) =>
                                                    this.setState({ creditTransactionType: itemValue })
                                                }>
                                                <Picker.Item color='#6a6a6a' label="Add Credits" value="credit" />
                                                <Picker.Item color='#6a6a6a' label="Remove Credits" value="debit" />
                                            </Picker>
                                        </View>

                                        <TextInput
                                            placeholderTextColor={[Colors.placeholderTextColor, darkMode && { color: Colors.lightWhite }]}
                                            style={[{ width: 130, textAlign: 'center', borderWidth: 1, borderColor: '#C4C4C4', fontSize: helpers.isDesktop() ? 20 : 18, lineHeight: helpers.isDesktop() ? 32 : 28, fontFamily: FontFamily.regular, fontWeight: '600', paddingVertical: helpers.isDesktop() ? 2 : 5, paddingHorizontal: 22, marginRight: helpers.isDesktop() ? 27 : 18, height: 40 }, !helpers.isDesktop() && { marginTop: 10, marginBottom: 10 }, darkMode && { color: Colors.lightWhite }]}
                                            onChangeText={(value) => this.setCredits(value, membersSelected, maxDebit)}
                                            value={credits}
                                        />
                                        <View style={[{ width: 200 }, !helpers.isDesktop() && { marginTop: 10, marginBottom: 10 }]}>
                                            <ButtonGradient
                                                title={'APPLY'}
                                                rounded={true}
                                                fullWidth={true}
                                                disable={(parseInt(credits) > 0 && !(creditTransactionType == 'credit' && ((parseInt(teamSubscriptionData.team_details?.credits_available) / membersSelected) < parseInt(credits))) && !(creditTransactionType == 'debit' && parseInt(credits) > maxDebit)) ? false : true}
                                                rootStyle={{
                                                    btnWrapper: [button.primaryGradientBtn, { height: 40, borderRadius: 0, margin: 0 }],
                                                    btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400', paddingTop: helpers.isDesktop() ? 0 : 15, paddingBottom: helpers.isDesktop() ? 0 : 15 }]
                                                }}
                                                onClick={() => this.setState({ showAddCredits: true, creditSet: true })}
                                                darkMode={darkMode}
                                            />
                                        </View>
                                    </>
                                }
                            </View>
                        }
                        {(teamSubscriptionData.plan.tmc_group == 'team') ?
                            <>
                                <View style={[Flex.row, Flex.alignCenter, !helpers.isDesktop() && Flex.justifyBetween, { height: helpers.isDesktop() ? 60 : 45, backgroundColor: '#FFFCF0', paddingHorizontal: helpers.isDesktop() ? 30 : 20 }, darkMode && { backgroundColor: Colors.darkBlackColor }]}>
                                    <View style={[Flex.row, Flex.alignCenter, { flex: 0.5 }]}>
                                        <Text style={[teamSubscription.tableHead, helpers.isDesktop() && teamSubscription.tableHeadDesktop, !helpers.isDesktop() && { fontSize: 10 }, darkMode && { color: Colors.lightWhite }]}>Name</Text>
                                    </View>
                                    {helpers.isDesktop() && <Text style={[teamSubscription.tableHead, helpers.isDesktop() && teamSubscription.tableHeadDesktop, { flex: 0.5 }, darkMode && { color: Colors.lightWhite }]}>Type</Text>}
                                    <Text style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 3, paddingBottom: 3 }}></Text>
                                </View>
                                {/* <TouchableOpacity onPress={() => this.setState({ showInviteMember: true })}>
                                    <View style={[Flex.row, Flex.alignCenter, Margin.mt_3, Margin.mb_15]}>
                                        <CustomImage
                                            source={addMember}
                                            require={true}
                                            style={{ width: 'auto', height: 38, marginRight: 15 }}
                                            webStyle={{ width: 'auto', height: helpers.isDesktop() ? 38 : 30, marginRight: helpers.isDesktop() ? 15 : 10 }}
                                        />
                                        <Text style={[teamSubscription.addMemberText]}>Add Member</Text>
                                    </View>
                                </TouchableOpacity> */}

                                {
                                    teamSubscriptionData.team_details.team_members.map((member, mindex) => {
                                        return (
                                            <View key={mindex} style={[Flex.row, Flex.alignCenter, !helpers.isDesktop() && Flex.justifyBetween, Helpers.mb_2, { borderBottomWidth: 1, borderColor: darkMode ? Colors.lineColor1 : '#EAEBEF', paddingVertical: 16, paddingHorizontal: helpers.isDesktop() ? 30 : 20 }, (mindex === teamSubscriptionData.team_details.team_members.length - 1) && { borderBottomWidth: 0 }, { zIndex: teamSubscriptionData.team_details.team_members.length - mindex }]}>
                                                <View style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, !helpers.isDesktop() && { flex: 1 }, helpers.isDesktop() && { flex: 0.5 }]}>
                                                    <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, !helpers.isDesktop() && { fontSize: 14, fontWeight: '600' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.lightWhite }]}>{member.member_name ? member.member_name : member.member_email}</Text>
                                                    <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { color: '#606060', fontSize: helpers.isDesktop() ? 16 : 12, lineHeight: helpers.isDesktop() ? 19 : 16, paddingTop: 6 }, !helpers.isDesktop() && { fontFamily: FontFamily.medium, fontWeight: '500' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.lineColor }]}>{member.member_email}</Text>
                                                    {!helpers.isDesktop() && <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { textTransform: 'capitalize', color: '#606060', fontSize: 12 }]}>{member.member_role} {member.invited && !member.invitation_accepted ? '(Invited)' : ''}</Text>}
                                                </View>
                                                {helpers.isDesktop() && <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { textTransform: 'capitalize', flex: 0.5 }, darkMode && { color: Colors.lightWhite }]}>{member.member_role} {member.invited && !member.invitation_accepted ? '(Invited)' : ''}</Text>}
                                                {(member.member_role == 'owner') ?
                                                    <View style={{ marginHorizontal: helpers.isDesktop() ? 15 : 25 }} />
                                                    :
                                                    <MenuPopup showInvite={(member.invited && !member.invitation_accepted) ? true : false} resendInvitation={() => this.resendInvitation(member)} removeMember={() => this.setState({ showMemberConfirmation: true, removeMemberDetails: member })} darkMode={darkMode} />
                                                }
                                            </View>
                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                <View style={[Flex.row, Flex.alignCenter, Flex.justifyBetween, { height: helpers.isDesktop() ? 60 : 45, backgroundColor: '#FFFCF0', marginTop: 20, borderRadius: 2 }, darkMode && { backgroundColor: helpers.isDesktop() ? Colors.darkBlackColor : '#FFF5CF' }]}>
                                    <TouchableOpacity onPress={() => {
                                        Object.keys(checkObj).map((key) => {
                                            checkObj[key] = (checkBoxCheckedTrue && checkBoxCheckedFalse) ? true : (checkBoxCheckedTrue ? false : true);
                                        })
                                        this.setState({ checkObj: checkObj })
                                    }}
                                        style={[Padding.pr_1, Padding.pl_2, !helpers.isDesktop() && Padding.pl_1, Platform.OS == 'ios' && { paddingRight: 0 }]}>
                                        <View style={[Platform.OS == 'ios' && { paddingVertical: 5, paddingHorizontal: 5 }]}>
                                            <CustomImage
                                                source={(checkBoxCheckedTrue && checkBoxCheckedFalse) ? checkBoxSelectedAll : (checkBoxCheckedTrue ? checkBoxSelected : checkBoxUnSelected)}
                                                require={true}
                                                style={{ width: 16, height: 16 }}
                                                webStyle={{ width: 16, height: 16 }}
                                            />
                                        </View>
                                    </TouchableOpacity>
                                    <Text style={[teamSubscription.tableHead, helpers.isDesktop() && teamSubscription.tableHeadDesktop, !helpers.isDesktop() && { fontSize: 10 }, { flex: helpers.isDesktop() ? 0.3 : 0.5 }, darkMode && { color: !helpers.isDesktop() ? '#6A6A6A' : Colors.lightWhite }]}>Name</Text>
                                    {helpers.isDesktop() && <Text style={[teamSubscription.tableHead, helpers.isDesktop() && teamSubscription.tableHeadDesktop, { flex: 0.3 }, darkMode && { color: !helpers.isDesktop() ? '#6A6A6A' : Colors.lightWhite }]}>Type</Text>}
                                    <Text style={[teamSubscription.tableHead, helpers.isDesktop() && teamSubscription.tableHeadDesktop, !helpers.isDesktop() && { textAlign: 'right', fontSize: 10 }, { flex: 0.3 }, darkMode && { color: !helpers.isDesktop() ? '#6A6A6A' : Colors.lightWhite }]}>Credits Available</Text>
                                    <Text style={{ paddingLeft: 25, paddingRight: helpers.isDesktop() ? 25 : 16, paddingTop: 3, paddingBottom: 3 }}></Text>
                                </View>
                                {
                                    teamSubscriptionData.team_details.team_members.map((member, mindex) => {
                                        return (
                                            <View key={mindex} style={[Flex.row, Flex.alignCenter, Flex.justifyBetween, Helpers.mb_2, { borderBottomWidth: 1, borderColor: darkMode ? Colors.lineColor1 : '#EAEBEF', paddingVertical: 16 }, (mindex === teamSubscriptionData.team_details.team_members.length - 1) && { borderBottomWidth: 0 }, { zIndex: teamSubscriptionData.team_details.team_members.length - mindex }]}>
                                                <TouchableOpacity onPress={() => {
                                                    checkObj[member.member_email] = !checkObj[member.member_email];
                                                    this.setState({ checkObj: checkObj })
                                                }}
                                                    style={[Padding.pr_1, Padding.pl_2, !helpers.isDesktop() && Padding.pl_1, Platform.OS == 'ios' && { paddingRight: 0 }]}>
                                                    <View style={[Platform.OS == 'ios' && { paddingVertical: 5, paddingHorizontal: 5 }]}>
                                                        <CustomImage
                                                            source={(checkObj[member.member_email]) ? checkBoxSelected : checkBoxUnSelected}
                                                            require={true}
                                                            style={{ width: 16, height: 16 }}
                                                            webStyle={{ width: 16, height: 16 }}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                                <View style={{ flex: helpers.isDesktop() ? 0.3 : 0.5 }}>
                                                    <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, !helpers.isDesktop() && { fontSize: helpers.isDesktop() ? 16 : 14, fontWeight: '600' }, darkMode && { color: Colors.lightWhite }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }]}>{member.member_name ? member.member_name : member.member_email}</Text>
                                                    <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { fontSize: helpers.isDesktop() ? 16 : 12, color: '#606060' }, !helpers.isDesktop() && { fontFamily: FontFamily.medium, fontWeight: '500' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.lineColor }]}>{member.member_email}</Text>
                                                    {!helpers.isDesktop() && <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { textTransform: 'capitalize', fontSize: 12, color: '#606060' }, darkMode && { color: Colors.lightWhite }]}>{member.member_role} {member.invited && !member.invitation_accepted ? '(Invited)' : ''}</Text>}
                                                </View>
                                                {helpers.isDesktop() && <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { textTransform: 'capitalize' }, darkMode && { color: Colors.lightWhite }]}>{member.member_role} {member.invited && !member.invitation_accepted ? '(Invited)' : ''}</Text>}
                                                <Text style={[teamSubscription.tableValue, helpers.isDesktop() && teamSubscription.tableValueDesktop, { color: '#55C79A' }, !helpers.isDesktop() && { textAlign: 'right', fontSize: 16 }]}>{member.credits_available}</Text>
                                                {(member.member_role == 'owner') ?
                                                    <View style={{ marginHorizontal: helpers.isDesktop() ? 25 : 19.5 }} />
                                                    :
                                                    <MenuPopup showInvite={(member.invited && !member.invitation_accepted) ? true : false} resendInvitation={() => this.resendInvitation(member)} removeMember={() => this.setState({ showMemberConfirmation: true, removeMemberDetails: member })} darkMode={darkMode} />
                                                }
                                            </View>
                                        )
                                    })
                                }
                            </>
                        }
                        {/* <View>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}>
                                <Menu
                                    visible={visible}
                                    onDismiss={this.closeMenu}
                                    anchor={<Button onPress={this.openMenu}>Show menu</Button>}>
                                    <Menu.Item onPress={() => { }} title="Item 1" />
                                    <Menu.Item onPress={() => { }} title="Item 2" />
                                </Menu>
                            </View>
                        </View> */}
                    </View>
                    <>
                        {((teamSubscriptionData.plan.tmc_group == 'team_credit') && !helpers.isDesktop() && (membersSelected > 0)) && helpers.isWeb() &&
                            <View style={[teamSubscription.wrapper, Flex.row, Flex.alignCenter, Flex.justifyBetween, { paddingTop: 15, paddingBottom: 15 }, { backgroundColor: darkMode ? Colors.darkBlackColor : '#fff' }]}>
                                <TouchableOpacity onPress={() => this.setState({ creditTransactionType: 'debit', showAddCredits: true, creditSet: false })}>
                                    <Text style={[teamSubscription.buttons, { color: darkMode ? '#000' : 'white', backgroundColor: '#FC876D', borderRadius: 0.3, paddingVertical: 8, paddingHorizontal: 10 }]}> Remove Credits </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ creditTransactionType: 'credit', showAddCredits: true, creditSet: false })}>
                                    <Text style={[teamSubscription.buttons, { color: darkMode ? '#000' : 'white', backgroundColor: '#55C79A', borderRadius: 0.3, paddingVertical: 8, paddingHorizontal: 24 }]}>Add Credits</Text>
                                </TouchableOpacity>
                            </View>
                        }
                    </>
                </>
            )
        }
    }

    resendInvitation = (member) => {
        let endpoint = `/invite-team-member`;
        let body = {
            account_email: member.member_email,
            name: member.member_name
        }
        let { token } = this.state;
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    return;
                }
                if (res.success && res.code == 'Send_invite') {

                } else {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    renderPageContent = () => {
        let { email, referralMsg, teamSubscriptionData } = this.state;
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }, { marginBottom: helpers.isDesktop() ? 90 : 40 }]}>
                {teamSubscriptionData && teamSubscriptionData.is_team_owner ?
                    <>
                        {this.renderGoogleInc()}
                        {this.renderTeamMembers()}
                        {/* {helpers.isDesktop() && <View style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter, !helpers.isDesktop() && { marginLeft: 20, marginRight: 20 }]}>
                            <TextInput
                                placeholderTextColor={Colors.placeholderTextColor}
                                style={[Padding.pl_4, { borderWidth: 1, borderColor: '#907CFF', borderRadius: 2, height: helpers.isDesktop() ? 46 : 40, paddingRight: helpers.isDesktop() ? 80 : 60, width: '100%', fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: 22 }]}
                                placeholder={'Search by name or email'}
                            />
                            <CustomImage
                                source={searchIcon}
                                require={true}
                                style={{ width: 20, height: 20, position: 'absolute', left: 10, top: 13 }}
                                webStyle={{ width: 20, height: 20, position: 'absolute', left: 10, top: 13 }}
                            />
                        </View>}*/}
                        {this.renderMembersDetails()}
                    </>
                    :
                    this.renderNoTeamView()
                }
            </View>
        )
    }

    renderNoTeamView = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Flex.alignCenter, Margin.mb_7, { paddingHorizontal: 20 }, !helpers.isDesktop() && { marginTop: 40 }]}>
                <CustomImage
                    source={darkMode ? noaccessDark : noaccess}
                    require={true}
                    style={{ width: 400, height: 400 }}
                    webStyle={{ width: 'auto', height: helpers.isDesktop() ? 430 : 250 }}
                    altText={'No Access'}
                />
                <Text style={[teamSubscription.noAccessText, { fontSize: helpers.isDesktop() ? 36 : 24, lineHeight: helpers.isDesktop() ? 43 : 29, paddingBottom: helpers.isDesktop() ? 30 : 20, textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>Sorry, you do not have access to this page</Text>
                <Text style={[{ fontSize: helpers.isDesktop() ? 24 : 17, lineHeight: helpers.isDesktop() ? 29 : 24, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0, 0, 0, 0.7)', paddingBottom: helpers.isDesktop() ? 65 : 40, maxWidth: helpers.isDesktop() ? 500 : '100%', textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>To manage your team you need to be the owner of a team plan.</Text>
                <View style={[{ width: helpers.isDesktop() ? 300 : 'auto' }, !helpers.isWeb() && { width: 200 }]}>
                    <ButtonGradient
                        title={'VIEW TEAM PLANS'}
                        fullWidth={true}
                        rootStyle={{
                            btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1, width: helpers.isWeb() ? 'fit-content' : '100%' }],
                            btnText: [button.primaryGradientText, { lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 13.5 : 11, paddingBottom: helpers.isDesktop() ? 13.5 : 11 }]
                        }}
                        onClick={() => this.navigateToTeamPlan()}
                        darkMode={darkMode}
                    />
                </View>
                <TouchableOpacity onPress={() => { this.navigateBack() }}><Text style={[{ fontSize: helpers.isDesktop() ? 17 : 13, lineHeight: helpers.isDesktop() ? 20 : 16, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0, 0, 0, 0.7)', textDecorationLine: 'underline', marginTop: 20 }, darkMode && { color: Colors.lightWhite }]}>Go back</Text></TouchableOpacity>
            </View>
        )
    }

    navigateToTeamPlan = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/team-subscription');
        } else {
            this.props.navigation.push('/team-subscription', { redirect: '/' });
        }
    }

    navigateBack = () => {
        if (helpers.isWeb()) {
            this.props.history.goBack();
        } else {
            this.props.navigation.goBack();
        }
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {

        // const { pageLoader, showErrorModal, showUparrow, footerData, teamSubscriptionData, showInviteMember, showAddCredits, creditTransactionType, credits, inviteCredits, creditSet, checkObj, token, showMemberConfirmation, removeMemberDetails } = this.state;


        let { teamSubscriptionData, visible, checkObj, creditTransactionType, credits, showAddCredits, showInviteMember } = this.state;
        if (teamSubscriptionData && teamSubscriptionData.team_details) {
            let checkBoxCheckedTrue, checkBoxCheckedFalse, membersSelected = 0, maxDebit, teamObj = {}
            teamSubscriptionData.team_details.team_members.map((member) => {
                teamObj[member.member_email] = member.credits_available;
            });
            Object.entries(checkObj).map((checked) => {
                if (checked[1]) {
                    checkBoxCheckedTrue = true;
                    membersSelected++;
                    if (maxDebit == undefined || maxDebit > teamObj[checked[0]]) {
                        maxDebit = teamObj[checked[0]];
                    }
                } else {
                    checkBoxCheckedFalse = true;
                }
            })
            if (maxDebit == undefined) {
                maxDebit = 0;
            }
            let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
            return (
                <>
                    <ScrollView>
                        {this.renderPageHeader()}
                        {this.renderPageContent()}
                        <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
                    </ScrollView>
                    <>
                        {((teamSubscriptionData.plan.tmc_group == 'team_credit') && !helpers.isDesktop() && (membersSelected > 0)) &&
                            <View style={[teamSubscription.wrapper, Flex.row, Flex.alignCenter, Flex.justifyBetween, { paddingTop: 15, paddingBottom: 15 }, showAddCredits && { opacity: 0 }, showInviteMember && { opacity: 0 }, { backgroundColor: darkMode ? Colors.darkBlackColor : '#fff' }]}>
                                <TouchableOpacity onPress={() => this.setState({ creditTransactionType: 'debit', showAddCredits: true, creditSet: false })}>
                                    <Text style={[teamSubscription.buttons, { color: darkMode ? '#000' : 'white', backgroundColor: '#FC876D', borderRadius: 0.3, paddingVertical: 8, paddingHorizontal: 10 }]}> Remove Credits </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this.setState({ creditTransactionType: 'credit', showAddCredits: true, creditSet: false })}>
                                    <Text style={[teamSubscription.buttons, { color: darkMode ? '#000' : 'white', backgroundColor: '#55C79A', borderRadius: 0.3, paddingVertical: 8, paddingHorizontal: 24 }]}>Add Credits</Text>
                                </TouchableOpacity>
                            </View>
                        }
                    </>
                </>
            )
        }
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData, teamSubscriptionData, showInviteMember, showAddCredits, creditTransactionType, credits, inviteCredits, creditSet, checkObj, token, showMemberConfirmation, removeMemberDetails } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/my-team',
            native: { redirect: '/my-team', verifyRedirect: '/my-team' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                <Provider>
                    <Seo data={seoObj['my-team']} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/my-team" />
                    <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.getSubscriptionDetails()} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                    {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                    }
                        {showUparrow &&
                            <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                                <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                    <CustomImage
                                        source={upArrowIcon}
                                        require={true}
                                        style={{ width: 60, height: 60 }}
                                        webStyle={{ width: 60, height: 60 }}
                                    />
                                </TouchableOpacity>
                            </View>
                        }</>
                    }
                    {helpers.isDesktop() && <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />}
                    {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}

                    {helpers.isWeb ?
                        <>
                            {showInviteMember && <InviteMember closeModal={() => this.setState({ showInviteMember: false })} token={token} refreshPage={(saveCredits, inviteCredits) => this.getSubscriptionDetails(saveCredits, inviteCredits)} withCredits={teamSubscriptionData.plan.tmc_group == 'team_credit'} maxCredits={teamSubscriptionData.team_details.credits_available} teamMembers={teamSubscriptionData.team_details.team_members} inviteCredits={inviteCredits} darkMode={darkMode} />}

                            {showAddCredits && <AddCredits creditTransactionType={creditTransactionType} credits={credits} creditSet={creditSet} checkObj={checkObj} maxCredits={teamSubscriptionData.team_details.credits_available} teamMembers={teamSubscriptionData.team_details.team_members} closeModal={() => this.setState({ showAddCredits: false })} token={token} refreshPage={() => this.getSubscriptionDetails()} darkMode={darkMode} />}

                            {showMemberConfirmation && <MemberConfirmation closeModal={() => this.setState({ showMemberConfirmation: false })} token={token} removeMemberDetails={removeMemberDetails} refreshPage={() => this.getSubscriptionDetails()} darkMode={darkMode} />}
                        </>
                        :
                        <>
                            {teamSubscriptionData && <InviteMember closeModal={() => this.setState({ showInviteMember: false })} token={token} refreshPage={(saveCredits, inviteCredits) => this.getSubscriptionDetails(saveCredits, inviteCredits)} withCredits={teamSubscriptionData.plan.tmc_group == 'team_credit'} maxCredits={teamSubscriptionData.team_details.credits_available} teamMembers={teamSubscriptionData.team_details.team_members} inviteCredits={inviteCredits} darkMode={darkMode} showInviteMember={showInviteMember} />}

                            {teamSubscriptionData && <AddCredits creditTransactionType={creditTransactionType} credits={credits} creditSet={creditSet} checkObj={checkObj} maxCredits={teamSubscriptionData.team_details.credits_available} teamMembers={teamSubscriptionData.team_details.team_members} closeModal={() => this.setState({ showAddCredits: false })} token={token} refreshPage={() => this.getSubscriptionDetails()} darkMode={darkMode} showAddCredits={showAddCredits} />}

                            {teamSubscriptionData && <MemberConfirmation closeModal={() => this.setState({ showMemberConfirmation: false })} token={token} removeMemberDetails={removeMemberDetails} refreshPage={() => this.getSubscriptionDetails()} darkMode={darkMode} showMemberConfirmation={showMemberConfirmation} />}
                        </>
                    }
                </Provider>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(MyTeam));

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'My Team'
    }
]