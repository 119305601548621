import React, { Component, PureComponent, useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Platform,
  Animated,
  TextInput,
  Linking,
  Alert,
  Modal as ReactModal,
  TouchableWithoutFeedback,
  Modal,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  button,
  appHeader,
  Typo,
  FontWeight,
  FontFamily,
  Colors,
  formStyle,
  teamSubscription,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import SubscriptionPlanModal from "../subscription-plan-modal";
import CheckoutModal from "../checkout-modal/index.web";
import SidebarCheck from "../sidebar-check";
import CustomImage from "../custom-image";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  removeUserToken,
  setUserStatus
} from "../../../redux/User/user.actions";
import HomeBanner from "../../components/home-banner";
import { saveDarkMode, darkModeToggle } from "../../../redux/App/app.actions";
import getImagePath from "../../utils/image-helper";
import Cookies from "universal-cookie";
import ButtonGradient from "../button-gradient";
import Button from "../button";
import { Mixpanel } from "../../utils/mixpanel";
import { GoogleAnalytics } from "../../utils/google-analytics";
import VerifyDocumentModal from "../verify-document-modal";
import GiftCardModal from "../gift-card-modal";
import { withAuth0 } from "@auth0/auth0-react";
import AuthenticationWrapper from "../authentication-wrapper";
import PlanAlert from "../plan-alert";
import config from "react-native-config";
import GeneralNotificationBand from "../../components/general-notification-band";
import { getPlanInfoObject } from "../../utils/retentionBandContext/plansConfig";
import Bowser from "bowser";
import ExitIntentPopupWeb from "../../components/exit-intent";
import ExitIntentPopupMobile from "../../components/exit-intent-mobile";
import CreditExpiryBand from "../../components/credit-expiry-band";
import { styles } from './newAppHeaderStyles';

const cookies = new Cookies();

const bookmarkMultiple = getImagePath("icons/bookmark-multiple-light.png");
const bookmarkMultipleDark = getImagePath("icons/bookmark-multiple-white.png");

const menuIconDesk = getImagePath("icons/hamburger-menu-desk.png");
const menuIconMob = getImagePath("icons/hamburger-menu-mob.png");
const menuIconDeskDark = getImagePath("icons/hamburger-menu-deskDark.png");
const menuIconMobDark = getImagePath("icons/hamburger-menu-mobDark.png");

const searchIcon = getImagePath("icons/search.png");
const appLogo = getImagePath("img/tmc-header-logo.png");

const closeIcon = getImagePath("icons/close-icon.png");
const closeBoxIcon = getImagePath("icons/close-round.png");
const userIcon = getImagePath("icons/userIcon.png");
const userIconLight = getImagePath("icons/search.png");
const userIconOffWhite = getImagePath("icons/userIconOffWhite.png");
const userIconFilledWhite = getImagePath("icons/userIconFilledLight.png");

const closeMsgIcon = getImagePath("icons/close-icon-white.png");
const whiteTick = getImagePath("icons/whiteTick.png");
const whiteClock = getImagePath("icons/whiteClock.png");

const searchBox = getImagePath("icons/search-icon-sidebar.svg");
const editProfile = getImagePath("icons/editProfile.png");

const Facebook = getImagePath("icons/facebook-b.png");
const Twitter = getImagePath("icons/twitterGrey.png");
const Linkedin = getImagePath("icons/linkedlngrey.png");
const WhatsApp = getImagePath('icons/whatsapp-icon.svg');

const closeIconWhite = getImagePath("icons/close-icon-white.png");

const notificationClose = getImagePath("icons/fileRemoveIcon.png");
const notificationCloseDark = getImagePath("icons/fileRemoveIconDark.png");

const successIcon = getImagePath("icons/success.png");
const text_in_circle = getImagePath("icons/text_in_circle.png");

const windowWidth = Dimensions.get("window").width;

const hamburgerMenu = getImagePath("icons/hamburger-menu-desk.svg")
const tmcLogo = getImagePath("img/tmc-header-logo.png");
const sliderArrowIcon = getImagePath("icons/slider-arrow-icon.svg")
const expandArrowIcon = getImagePath("icons/expand-arrow-icon.svg")


const HEADER_SUBSECTION_BUTTONS = [
  {
    name: "Premium",
    subSections: [
      {
        name: "Longreads",
        link: '/longreads'
      },
      {
        name: "Opinion",
        link: '/newsletters'
      },
      {
        name: "News Explainers",
        link: '/yesterday'
      },

    ]
  },
  {
    name: "News Briefs",
    link: '/news-briefs'
  },
  {
    name: "Startups",
    link: '/category/internet/all'
  },
  {
    name: "Stock Markets",
    link: '/category/business/all'

  },
  {
    name: "Free Stories",
    link: '/free-reads'
  },
  {
    name: "Renewables",
    link: "/tag/renewables"
  },
  {
    name: "Current Affairs",
    link: '/category/chaos/all'
  },
  {
    name: "Who reads us",
    link: '/who-reads-us'
  },
  // {
  //     name: "State of the unicorns",
  //     link: '/'
  // },
  // {
  //     name: "Sponsorship",
  //     link: '/'
  // },
  {
    name: "Corporate",
    link: '/pricing?idx=1'
  },
  {
    "name": "Company-wise Coverage",
    link: "/company"
  }

]


const SubsectionButtonCard = (props) => {
  const { name, idx, subSections, link, dropdownVisible, toggleDropdown } = props;

  const componentRef = useRef(null);

  const handlePressOutside = (e) => {
    if (componentRef.current && !componentRef.current.contains(e.target)) {
      // Clicked outside the component, close it
      toggleDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handlePressOutside);

    return () => {
      document.removeEventListener('mousedown', handlePressOutside);
    };
  }, []);

  const navigateToLink = () => {
    window.location.href = link
  }

  return (
    <View>
      <TouchableOpacity onPress={idx === 0 ? toggleDropdown : navigateToLink} style={[idx === 0 ? { position: 'relative' } : null]}>
        <View style={[idx === 0 && styles.highlightedBackground, { alignSelf: 'center', paddingHorizontal: 10, paddingVertical: 5 }, idx === 0 && { flexDirection: 'row', justifyContet: 'center', alignItems: 'center', columnGap: 5 }]}>
          <Text style={[styles.headerSubSectionButtonText]}>
            {name === "Company-wise Coverage" ? "Companies" : name}
          </Text>
          {idx === 0 && <CustomImage source={expandArrowIcon} style={{ width: 16, height: 16, opacity: 0.5 }} webStyle={{ width: 16, height: 16, opacity: 0.5 }} altText="expand-arrow-icon" />}
        </View>
        {idx === 0 && dropdownVisible && (
          <View style={styles.dropdown} ref={componentRef}>
            {subSections.map((section) => (
              <TouchableOpacity onPress={() => {
                window.location.href = section.link
                console.log("debugging :", section.link)
              }}>
                <Text key={section.name} style={styles.dropdownItem}>{section.name}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

import { ChargebeeController } from "../../utils/chargebee";
import { bandCategoryHandle } from "../../utils/retentionBandContext/bandDetails";
import { getPlanFromCurrency } from "../../utils/retentionBandContext/plansConfig";
import GiftSubModal from "../gift-sub-modal";

const POPUP_DELAY = 5000; // 5 seconds
const POPUP_INTERVAL = 2 * 60 * 1000; // 2 minutes

export class AppHeader extends Component {
  constructor(props) {
    super(props);
    console.log("Debugging: Constructor")
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = process.browser
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.token;
    let tokenSet = token == undefined || token == "" ? false : true;

    if (process.browser && window.location.search) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let redirectParams;
      redirectParams = searchArr.find((searchParam) => {
        return searchParam.includes("redirect-params=");
      });
      if (redirectParams) {
        redirectParams = redirectParams.replace("redirect-params=", "");
      }
      if (redirectParams) {
        let reaplaceProp = JSON.parse(JSON.stringify(window.location));
        reaplaceProp.search = redirectParams + "&close-modal=true";
        this.props.history.replace(reaplaceProp);
      }
    }

    this.state = {
      slideMenu: new Animated.Value(-(windowWidth * 2)),
      searchbar: new Animated.Value(-(windowWidth * 2)),
      openSlideMenu: false,
      token: tokenSet ? token : false,
      showToken: process.browser ? true : false,
      siteText: "The Morning Context",
      userProfile: false,
      showLogin: tokenSet ? false : true,
      showUser: false,
      showSubscribe: false,
      selectedSubscribtionID: false,
      paymentConfig: {},
      billingPlatform: false,
      chargebeeSite: false,
      chargebeeSiteKey: false,
      chargebeePublishableKey: false,
      // subscriptionStatus: false,
      querySubscribtionID: false,
      selectedSubscribtion: false,
      subscribeEmail: false,
      country: false,
      locationHash: "",
      searchText: "",
      quantity: 1,
      copyright: this.props.footerData
        ? this.props.footerData.copyright
        : false,
      pressedBtn: false,
      showModal: new Animated.Value(0),
      showStatus: new Animated.Value(1),
      readingValue: new Animated.Value(0),
      showStatus: new Animated.Value(1),
      hideHeader: this.props.hideHeader,
      documentStatus: "default",
      submittedFiles: [],
      openStudentModal: false,
      documentUploadDate: "",
      openSearch: false,
      chargebeeSiteIN: helpers.isWeb()
        ? process.env.CHARGEBEE_SITE_IN
        : config.CHARGEBEE_SITE_IN,
      chargebeeSiteKeyIN: helpers.isWeb()
        ? process.env.CHARGEBEE_API_KEY_IN
        : config.CHARGEBEE_API_KEY_IN,
      chargebeeSiteOT: helpers.isWeb()
        ? process.env.CHARGEBEE_SITE_OT
        : config.CHARGEBEE_SITE_OT,
      chargebeeSiteKeyOT: helpers.isWeb()
        ? process.env.CHARGEBEE_API_KEY_OT
        : config.CHARGEBEE_API_KEY_OT,
      chargebeePubKeyIN: helpers.isWeb()
        ? process.env.CHARGEBEE_API_KEY_IN
        : config.CHARGEBEE_API_KEY_IN,
      chargebeePubKeyOT: helpers.isWeb()
        ? process.env.CHARGEBEE_API_KEY_OT
        : config.CHARGEBEE_API_KEY_OT,
      inAppNotify: !helpers.isDesktop()
        ? sessionStorage.getItem("inAppNotify") != "0"
          ? true
          : false
        : false,
      subscriptionDetails: {},
      freeUser: false,
      unpaidInvoiceLoader: false,
      expiredSubscription: {},
      locationInfo: null,
      currentPath: "home",
      userPaymentInfo: undefined,
      userPaymentInfoFetched: false,
      showCheckoutOptions: false,
      showSubscriptionPlanModal: false,
      paymentMethod: null,
      bankName: null,
      isSafaribrowser: false,
      showExitIntent: false,
      custom_user_id: false,
      bandDetails: null,
      showHeaderSearch: false,
      dropdownVisible: false
      // {text: "You are missing out on 95% of the stories we publish every month?", ctaText: 'UPGRADE', ctaRedirection: '/pricing'}
    };

    // this.wrapperRef = React.createRef();
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  // handleClickOutside(event) {
  //   console.log("event:", event);

  //   this.toggleSlideMenu(false);
  // }

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false,
        });
      } else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id,
        });
      }
    });
  };

  triggerSigninEvent = () => {
    const signInTrackerKey = helpers.userSignInEventTrackingKey;
    const userSignedIn = localStorage.getItem(signInTrackerKey);
    if (userSignedIn != undefined) {
      this.props.auth
        ?.getIdTokenClaims()
        .then((tokenClaims) => {
          if (tokenClaims == undefined) {
            return;
          }
          const dataLayer = window.dataLayer || [];
          this.setState({
            custom_user_id: tokenClaims?.legacy_claims?.id,
          });
          dataLayer.push({
            event: "signup_success",
            custom_user_id: tokenClaims?.legacy_claims?.id || "NA",
            user_status: "active",
          });
          localStorage.removeItem(signInTrackerKey);
        })
        .catch((err) => {
          console.error("GTM:triggerSigninEvent:ERROR:", err);
        });
    }
  };

  componentDidMount = () => {
    console.log("Debugging: Mount")
    window.onscroll = () => {
      this.scrollFunction();
    };
    const browser = Bowser.getParser(window.navigator.userAgent);
    // Check if it's safari browser to hide the open app nudge
    const isSafari = browser.satisfies({
      macos: {
        safari: ">4.1",
      },
      mobile: {
        safari: ">=4",
      },
    });
    this.setState({
      isSafaribrowser: isSafari,
    });
    // if (isSafari) {
    //   this.setState({ inAppNotify: false });
    // }
    this.triggerSigninEvent();
    if (!this.state.custom_user_id) {
      this.setAuthToken();
    }
    if (this.state.token) {
      this.getUserInfo();
      ChargebeeController.activatedController().then((cb_handle) => {
        let countryInfo = null;
        if (
          cb_handle?.instance?.billingInfo?.billing_platform ===
          "chargebee_india"
        ) {
          countryInfo = {
            code: "IN",
            name: "India",
          };
        } else if (cb_handle?.instance?.billingInfo) {
          countryInfo = {
            code: "US",
            name: "USA",
          };
        }

        if (countryInfo) {
          this.setState({
            locationInfo: { countryInfo: countryInfo },
          });
        }
      });
      // call the endpoint to get band details
      this.getHeaderBandDetails()

    } else {
      this.getHeaderBandDetails()
      this.setDarkMode("off");
      this.checkQuery();
      // call the endpoint to get logged out band details

    }
    this.checkHash();
    this.getHeaderMenu();
    /* Commented because an uneccessary page reload is triggered */
    // this.refreshPlans()
    // this.showChargbeePage(null);
    this.checkAdvanceRenewal();
    // Only if it's not safari browser add the padding
    if (this.state.inAppNotify) {
      sessionStorage.setItem("inAppNotify", "1");
      let content = document.getElementById("__next");
      content.firstElementChild.style.paddingTop = "58px";
    }
    if (!this.state.token) {
      this.getLocationInfo();
      // If user is not logged in and it's not a story modal, then add the exit intent
      if (!this.props.singlePost) {
        // this.showSignUpPopup();
      }
    }
    let curPath = window.location.pathname.split("/").pop();
    this.setState({ currentPath: curPath ? curPath : "home" });
    this.props.auth?.getIdTokenClaims().then((data) => {
      this.setState({ idTokenClaims: data })
    })
  };

  getHeaderBandDetails = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    let body = {}
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
            if (!token) {
              endpoint = '/header-band-log-out'
              if (res.data?.countryInfo?.code !== "IN") {
                body = {
                  "is_overseas_user": true
                }
                apiHelpers.apiRequest(endpoint, body, "POST", true, token).then((res) => {
                  if (res.for_web) {
                    this.setState({
                      bandDetails: {
                        text: res.banner_text,
                        ctaRedirection: res.web_link,
                        ctaText: res.cta_text
                      }
                    })
                  }
                })
              } else {
                apiHelpers.apiRequest(endpoint, body, "POST", true, token).then((res) => {
                  if (res.for_web) {
                    this.setState({
                      bandDetails: {
                        text: res.banner_text,
                        ctaRedirection: res.web_link,
                        ctaText: res.cta_text
                      }
                    })
                  }
                })
              }
            } else {
              endpoint = '/header-band'
              if (res.data?.countryInfo?.code !== "IN") {
                body = {
                  "is_overseas_user": true
                }
                apiHelpers.apiRequest(endpoint, body, "POST", true, token).then((res) => {
                  if (res.for_web) {
                    this.setState({
                      bandDetails: {
                        text: res.banner_text,
                        ctaRedirection: res.web_link,
                        ctaText: res.cta_text
                      }
                    })
                  }
                })
              } else {
                apiHelpers.apiRequest(endpoint, body, "POST", true, token).then((res) => {
                  if (res.for_web) {
                    this.setState({
                      bandDetails: {
                        text: res.banner_text,
                        ctaRedirection: res.web_link,
                        ctaText: res.cta_text
                      }
                    })
                  }
                })
              }
            }
          }
        } else if (token) {
          endpoint = '/header-band'
          if (true) {
            body = {
              "is_overseas_user": true
            }
            apiHelpers.apiRequest(endpoint, body, "POST", true, token).then((res) => {
              if (res.for_web) {
                this.setState({
                  bandDetails: {
                    text: res.banner_text,
                    ctaRedirection: res.web_link,
                    ctaText: res.cta_text
                  }
                })
              }
            })
          }
        } else {
          endpoint = '/header-band-log-out'
          if (true) {
            body = {
              "is_overseas_user": true
            }
            apiHelpers.apiRequest(endpoint, body, "POST", true, token).then((res) => {
              if (res.for_web) {
                this.setState({
                  bandDetails: {
                    text: res.banner_text,
                    ctaRedirection: res.web_link,
                    ctaText: res.cta_text
                  }
                })
              }
            })
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  }

  getLocationInfo = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };

  handleCloseExitIntent = () => {
    this.setState({ showExitIntent: false });
    this.timeout && clearTimeout(this.timeout);
    this.updateLastActiveTime();
  };

  componentWillUnmount = () => {
    this.timeout && clearTimeout(this.timeout);
    this.activityTimer && clearInterval(this.activityTimer);
    window.localStorage.setItem("lastActive", new Date());
    document.removeEventListener("visibilitychange", this.checkActivity);
    window.removeEventListener("beforeunload", this.updateLastActiveTime);
    // Update the last active time in localStorage
    this.updateLastActiveTime();
  };

  componentDidUpdate = () => {
    let token = this.props.auth.fetchToken;
    if (token != this.state.token) {
      let tokenSet = token == undefined || token == "" ? false : true;
      this.setState(
        {
          token: tokenSet ? token : false,
          showLogin: tokenSet ? false : true,
          userProfile: false,
          showUser: false,
          showSubscribe: false,
        },
        () => {
          if (this.state.token) {
            /* Duplicate or redundant call for user info */
            // this.getUserInfo();
          }
          if (this.state.selectedSubscribtionID) {
            this.refreshPlans();
          }
        }
      );
    }
    if (this.state.token == false || this.state.userProfile) {
      this.checkQuery();
    }
    this.checkHash();
    if (this.state.hideHeader != this.props.hideHeader) {
      this.setState({ hideHeader: this.props.hideHeader });
      Animated.timing(this.state.showModal, {
        toValue: this.props.hideHeader ? -70 : 0,
        duration: 500,
        delay: 0,
        useNativeDriver: true,
      }).start();
      Animated.timing(this.state.showStatus, {
        toValue: this.props.hideHeader ? 0 : 1,
        duration: 500,
        delay: 0,
        useNativeDriver: true,
      }).start();
    }
  };

  checkAdvanceRenewal = () => {
    const urlParams = helpers.getURLParamDict(window.location.href);
    if (!(urlParams["advance-renew"] === "true")) {
      return;
    }
    if (this.loginRequired()) {
      console.log("chargebee: login-check-1");
      return;
    }

    ChargebeeController.activatedController().then((cb_handle) => {
      if (
        !ChargebeeController.hosted_page.parameterHandle.getParam(
          "subscription.id"
        )
      ) {
        console.log("chargebee: advance_renewal not allowed: ");
        return;
      }

      if (!helpers.canAdvanceRenew(cb_handle.activeSubscription)) {
        console.log("chargebee: advance_renewal not allowed: ");
        return;
      }

      const couponIdList = urlParams.coupon_ids
        ? urlParams.coupon_ids.split(",")
        : [];
      cb_handle.hosted_page.checkout_existing(
        {
          terms_to_charge: 1,
          invoice_immediately: true,
          coupon_ids: couponIdList,
        },
        {
          close: [
            () => {
              let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
              this.props.navigateWeb(window.location.pathname);
            },
          ],
        },
        false
      );
    });
  };

  loginRequired = () => {
    if (!this.state.token) {
      let encodedRedirectURL = `${this.props.redirectSlugs.web}${window.location.search}`;
      encodedRedirectURL = encodeURIComponent(encodedRedirectURL);
      let redirect_url = `/sign-in?encoded=1&redirect=${encodedRedirectURL}`;
      console.log(
        "chargebee: forced-login: ",
        window.location.search.substring(1)
      );

      this.props.navigateWeb(redirect_url);

      return true;
    }
    return false;
  };
  animateReadingList = () => {
    this.setState({ readingValue: new Animated.Value(0) }, () => {
      Animated.timing(this.state.readingValue, {
        toValue: 60,
        duration: 800,
      }).start();
    });
  };

  setDarkMode = (darkMode, toggle) => {
    this.setState({
      darkMode: darkMode,
    });
    this.props.saveDarkMode(darkMode);
    this.props.darkModeToggle(toggle ? true : false);
    let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
    cookies.set("dark_mode", darkMode, { path: "/", expires: cookieDate });
  };

  getHeaderMenu = () => {
    const { token } = this.state;
    let endpoint = `/header-menu`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, false)
      .then((res) => {
        if (res.success && res.code == "header_menu") {
          this.setState({
            menuItems: res.data.menu,
            copyright: res.data.copyright,
          });
        } else {
          console.log("Error=>>", res);
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
      });
  };

  refreshPlans = () => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    let body = {
      filters: {
        tmc_group: [
          "individual",
          "credit",
          "team",
          "team_credit",
          "student",
          "gift_plan",
          "gift_credit",
          "friends-of-tmc",
          "exclusive"
        ],
      },
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.code == "access_denied") {
          this.authTokenExpired();
          return false;
        }
        if (res.success) {
          this.setState({
            country: res.data.country,
          });
          if (this.state.selectedSubscribtionID == "credits") {
            this.setState({
              selectedSubscribtion: res.data.plans.credit[0],
              country: res.data.country,
            });
            return false;
          }
          let queryPlan =
            res.data.plans.individual.find(
              (plan) => plan.id == this.state.selectedSubscribtionID
            ) ||
            res.data.plans.team.find(
              (plan) => plan.id == this.state.selectedSubscribtionID
            ) ||
            res.data.plans.student.find(
              (plan) => plan.id == this.state.selectedSubscribtionID
            ) ||
            res.data.plans.team_credit.find(
              (plan) => plan.id == this.state.selectedSubscribtionID
            ) ||
            res.data.plans.gift_credit.find(
              (plan) => plan.id == this.state.selectedSubscribtionID
            ) ||
            res.data.plans.gift_plan.find(
              (plan) => plan.id == this.state.selectedSubscribtionID
            );

          if (queryPlan) {
            this.setState({
              selectedSubscribtion: queryPlan,
              country: res.data.country,
            });
          } else {
            this.closeSubscriptionPlanModal();
            console.log("Plan not found");
          }
        } else {
          console.log("Error=>>", res);
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
      });
  };

  checkHash = () => {
    if (this.props.locationHash != this.state.locationHash) {
      this.setState(
        {
          locationHash: this.props.locationHash,
        },
        () => {
          if (this.props.locationHash == "") {
            if (this.props.refreshPage) {
              this.props.refreshPage();
            }
          }
        }
      );
    }

    this.yesterdayScreen = window.location.pathname.split("/");
    this.isYesterdayScreenEnable = false;
    if (
      this.yesterdayScreen.length >= 3 &&
      this.yesterdayScreen[1] == "yesterday"
    ) {
      this.isYesterdayScreenEnable = true;
    } else this.isYesterdayScreenEnable = false;

    localStorage.setItem(
      "isYesterdayScreenEnable",
      this.isYesterdayScreenEnable ? "1" : "0"
    );
  };

  checkQuery = () => {
    const { token } = this.state;
    if (
      this.props.searchQuery &&
      (this.props.searchQuery.includes("subscribe=") ||
        this.props.searchQuery.includes("buy-credits=true")) &&
      this.state.querySubscribtionID == false
    ) {
      if (!token) {
        let encodedRedirectURL = `${this.props.redirectSlugs.web}${window.location.search}`;
        encodedRedirectURL = encodeURIComponent(encodedRedirectURL);
        let redirect_url = `/sign-in?encoded=1&redirect=${encodedRedirectURL}`;

        this.props.navigateWeb(redirect_url);

        return;
      }

      let searchArr = this.props.searchQuery.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        if (searchParam == "buy-credits=true") {
          return true;
        }
        return searchParam.includes("subscribe=");
      });
      let quantity = searchArr.find((searchParam) => {
        return searchParam.includes("quantity=");
      });
      if (quantity) {
        quantity = quantity.replace("quantity=", "") || 1;
      }
      if (foundSearch) {
        let search;
        if (foundSearch == "buy-credits=true") {
          search = "credits";
        } else {
          search = foundSearch.replace("subscribe=", "");
        }
        if (search != "") {
          if (
            (search == "renew-plan" &&
              (this.state.userProfile == false ||
                JSON.stringify(this.state.userProfile.subscription) == "{}" ||
                !this.state.userProfile.subscription.can_renew)) ||
            (search == "renew-team-plan" &&
              (this.state.userProfile == false ||
                JSON.stringify(this.state.userProfile.team_subscription) ==
                "{}" ||
                !this.state.userProfile.team_subscription.can_renew ||
                !this.state.userProfile.team_subscription.is_team_owner))
          ) {
            return false;
          }
          if (
            search == "credits" &&
            (this.state.userProfile?.subscription?.disable_buy_credits ||
              (this.state.userProfile?.team_subscription?.is_team_member &&
                this.state.userProfile?.team_subscription?.disable_buy_credits))
          ) {
            return false;
          }
          if (
            this.state.userProfile?.team_subscription?.is_team_owner == false
          ) {
            return false;
          }
          if (
            this.state.userProfile?.team_subscription?.plan?.tmc_group == "team"
          ) {
            return false;
          }
          this.setState({
            querySubscribtionID: search,
          });
          if (this.state.selectedSubscribtionID == search) {
            return false;
          }
          let endpoint = `/plans/default`;
          let body = {
            filters: {
              tmc_group: [
                "individual",
                "credit",
                "team",
                "team_credit",
                "student",
                "gift_plan",
                "gift_credit",
                "friends-of-tmc",
                "exclusive"
              ],
            },
          };
          apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
              if (res.success) {
                if (search == "credits") {
                  this.showSubscriptionPlan(
                    search,
                    res.data.plans.credit[0],
                    res.data.country
                  );
                  return false;
                }
                if (search == "renew-plan") {
                  this.showSubscriptionPlan(
                    search,
                    this.state.userProfile.subscription.plan,
                    res.data.country
                  );
                  return false;
                }
                if (search == "renew-team-plan") {
                  this.showSubscriptionPlan(
                    search,
                    this.state.userProfile.team_subscription.plan,
                    res.data.country
                  );
                  return false;
                }
                let queryPlan =
                  res.data.plans.individual.find((plan) => plan.id == search) ||
                  res.data.plans.team.find((plan) => plan.id == search) ||
                  res.data.plans.student.find((plan) => plan.id == search) ||
                  res.data.plans.team_credit.find(
                    (plan) => plan.id == search
                  ) ||
                  res.data.plans.gift_credit.find(
                    (plan) => plan.id == search
                  ) ||
                  res.data.plans.gift_plan.find((plan) => plan.id == search) ||
                  res.data.plans["friends-of-tmc"].find((plan) => plan.id == search) ||
                  res.data.plans["exclusive"].find((plan) => plan.id == search);
                if (queryPlan) {
                  if (
                    this.state.userProfile.subscription?.plan &&
                    (queryPlan.tmc_group == "team" ||
                      queryPlan.tmc_group == "team_credit" ||
                      queryPlan.tmc_group == "student")
                  ) {
                    return false;
                  }
                  this.showSubscriptionPlan(
                    search,
                    queryPlan,
                    res.data.country,
                    "",
                    quantity
                  );
                } else {
                  console.log("Plan not found");
                }
              } else {
                console.log("Error=>>", res);
              }
            })
            .catch((error) => {
              console.log("Error=>>", error);
            });
        }
      }
    } else if (
      ((this.props.searchQuery &&
        !(
          this.props.searchQuery.includes("subscribe=") ||
          this.props.searchQuery.includes("buy-credits=true")
        )) ||
        this.props.searchQuery == "") &&
      this.state.querySubscribtionID
    ) {
      this.setState({
        querySubscribtionID: false,
      });
      if (this.state.selectedSubscribtionID == false) {
        return false;
      }
      if (this.refs.submodal) {
        this.refs.submodal.closePlanModal();
      }
    }
  };

  // Define a function to check the user's activity and show the popup
  checkActivity = () => {
    const lastActive = window.localStorage.getItem("lastActive");
    const currentTime = new Date().getTime();

    // Check if the user is inactive or reading for 15 minutes
    const isInactive =
      lastActive &&
      currentTime - new Date(lastActive).getTime() > POPUP_INTERVAL;

    if (isInactive) {
      this.setState({ showExitIntent: true });
      this.updateLastActiveTime();
    }
  };

  updateLastActiveTime = () => {
    window.localStorage.setItem("lastActive", new Date());
  };

  showSignUpPopup() {
    // Check if the 15-second delay popup has been shown during the current session
    const modalShown = sessionStorage.getItem("modalShown");

    document.addEventListener("visibilitychange", this.checkActivity);

    const lastActive = window.localStorage.getItem("lastActive");

    if (!lastActive) {
      // Set the lastActive time to the current time
      this.updateLastActiveTime();
    }

    if (!modalShown) {
      // Delay the popup for 15 seconds and show it
      this.timeout = setTimeout(() => {
        this.setState({ showExitIntent: true });
        sessionStorage.setItem("modalShown", true);
      }, POPUP_DELAY);
    }
    // The 15-second delay popup has already been shown during the current session,
    // so check the user's activity every 15 minutes
    this.activityTimer = setInterval(this.checkActivity, POPUP_INTERVAL);

    window.addEventListener("beforeunload", this.updateLastActiveTime);
  }

  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getUserInfo = async (showSubscribtion, subscribtionData) => {
    const { token, documentStatus, documentUploadDate } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["profile", "subscription", "credits"],
    };
    await apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          this.authTokenExpired();
          return false;
        }
        if (res.success) {
          // console.log("userprofile", res.data);
          this.props.setUserStatus(res.data.user_status);
          let userSubscription =
            !res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}"
              ? false
              : res.data.subscription;
          let teamSubscription =
            !res.data.team_subscription ||
              JSON.stringify(res.data.team_subscription) == "{}"
              ? false
              : res.data.team_subscription;
          let showSubscribe =
            (userSubscription && userSubscription.status != "expired") ||
              (teamSubscription &&
                teamSubscription.is_team_member &&
                teamSubscription.status != "expired")
              ? false
              : true;
          let showVerificationModal =
            userSubscription &&
            userSubscription.status == "active" &&
            userSubscription.plan.tmc_group == "student" &&
            (!res.data.document_status ||
              res.data.document_status != "success");

          let userInfo = res.data;
          let privilegedCredits = res.data.credits.privileged_credits;
          let nonPrivilegedCredits = res.data.credits.non_privileged_credits;
          let isUserSubscriptionActive =
            (res.data.subscription &&
              JSON.stringify(res.data.subscription) != "{}" &&
              res.data.subscription.status != "expired") ||
              (res.data.team_subscription &&
                JSON.stringify(res.data.team_subscription) != "{}" &&
                res.data.team_subscription.status != "expired" &&
                res.data.team_subscription.is_team_member)
              ? true
              : false;
          let userSubscriptionStatus =
            !res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}"
              ? false
              : res.data.subscription.status;
          let freeCreditsAvailable = res.data.free_credits_available;
          // console.log("--->dd", isUserSubscriptionActive, userSubscription);

          localStorage.setItem(
            "isUserSubscriptionActive",
            isUserSubscriptionActive == false ||
              String(isUserSubscriptionActive) == "false"
              ? "0"
              : "1"
          );
          localStorage.setItem("userSubscription", userSubscription);

          helpers.setSubscriptionData(
            userInfo,
            privilegedCredits,
            nonPrivilegedCredits,
            isUserSubscriptionActive,
            userSubscriptionStatus,
            userSubscription,
            teamSubscription,
            freeCreditsAvailable
          );
          if (this.props.onLoadData) this.props.onLoadData(userSubscription);
          try {
            Mixpanel.identify(res.data.reference_id);
            let mixpanelObj = {};
            if (userSubscription || teamSubscription) {
              let selectedSubscribtion = userSubscription
                ? userSubscription
                : teamSubscription;
              mixpanelObj["Plan name"] = selectedSubscribtion.plan.name;
              mixpanelObj["Expiry date"] = selectedSubscribtion.end_date;
            }
            if (res.data.credits?.non_privileged_credits) {
              mixpanelObj["Starter credits total"] =
                res.data.credits.non_privileged_credits.total_credits;
              mixpanelObj["Starter credits pending"] =
                res.data.credits.non_privileged_credits.available;
            }
            if (res.data.credits?.privileged_credits) {
              mixpanelObj["Privilege credits total"] =
                res.data.credits.privileged_credits.total_credits;
              mixpanelObj["Privilege credits pending"] =
                res.data.credits.privileged_credits.available;
            }
            if (teamSubscription) {
              mixpanelObj["Corporate user type"] =
                teamSubscription.is_team_owner &&
                  teamSubscription.is_team_member
                  ? "Owner and Member"
                  : teamSubscription.is_team_owner
                    ? "Owner"
                    : "Member";
              if (teamSubscription.is_team_owner) {
                if (teamSubscription.plan.tmc_group == "team") {
                  mixpanelObj["Corporate seats purchased"] =
                    teamSubscription.team_details.seats_purchased;
                  mixpanelObj["Corporate seats used"] =
                    teamSubscription.team_details.seats_purchased -
                    teamSubscription.team_details.seats_available;
                } else {
                  mixpanelObj["Corporate credits total"] =
                    teamSubscription.team_details.credits_purchased;
                  mixpanelObj["Corporate credits pending"] =
                    teamSubscription.team_details.credits_available;
                }
              }
            }
            if (res.data.dark_mode) {
              mixpanelObj["Dark mode web"] = res.data.dark_mode.web;
              mixpanelObj["Dark mode app"] = res.data.dark_mode.app;
            }
            if (JSON.stringify(mixpanelObj) != "{}") {
              Mixpanel.people.set(mixpanelObj);
            }
          } catch (error) {
            console.log("mix panel error =>", error);
          }
          this.setState({
            freeUser:
              (res.data.team_subscription &&
                !this.isObjectEmpty(res.data.team_subscription)) ||
                (res.data.subscription &&
                  !this.isObjectEmpty(res.data.subscription))
                ? false
                : true,
            userInfoFetched: true,
            showUser: true,
            showSubscribe: showSubscribe,
            userProfile: res.data,
            documentUploadDate: res.data.document_uploaded_on,
            subscriptionDetails:
              res.data.team_subscription &&
                res.data.team_subscription.is_team_owner
                ? res.data.team_subscription
                : res.data.subscription,
            expiredSubscription: res.data.expired_subscription,
            credits: res.data.credits,
          });
          // this.setDarkMode(
          //   res.data.dark_mode ? res.data.dark_mode.web : "default",
          //   res.data.dark_mode?.webSaved != true
          // );

          this.advanceRenewalChecks();
          this.setDarkMode("off");
          if (showSubscribtion) {
            const {
              subscribtionID,
              selectedSubscribtion,
              country,
              email,
              quantity,
            } = subscribtionData;
            if (subscribtionID == "renew-plan") {
              if (
                res.data.subscription &&
                JSON.stringify(res.data.subscription) != "{}" &&
                res.data.subscription.can_renew
              ) {
                this.openSubscriptionPlan(
                  subscribtionID,
                  res.data.subscription.plan,
                  country
                );
              }
              return false;
            }
            if (subscribtionID == "renew-team-plan") {
              if (
                res.data.team_subscription &&
                JSON.stringify(res.data.team_subscription) != "{}" &&
                res.data.team_subscription.can_renew &&
                res.data.team_subscription.is_team_owner
              ) {
                this.openSubscriptionPlan(
                  subscribtionID,
                  res.data.team_subscription.plan,
                  country
                );
              }
              return false;
            }
            if (
              subscribtionID == "credits" &&
              (res.data?.subscription?.disable_buy_credits ||
                (res.data?.team_subscription?.is_team_member &&
                  res.data?.team_subscription?.disable_buy_credits))
            ) {
              return false;
            }
            if (
              selectedSubscribtion &&
              selectedSubscribtion.tmc_group == "team" &&
              parseInt(quantity) < 2
            ) {
              return false;
            }
            this.openSubscriptionPlan(
              subscribtionID,
              selectedSubscribtion,
              country,
              email,
              quantity
            );
          } else {
            this.checkQuery();
          }
          if (showVerificationModal) {
            console.log("inside show verification !!!!!!!!!!!!!!");
            this.setState({
              documentStatus: res.data.document_status,
              submittedFiles:
                res.data.document_status &&
                  res.data.document_status == "rejected"
                  ? []
                  : res.data.documents,
              openStudentModal:
                res.data.document_status == false ||
                res.data.document_status == "rejected",
            });
          }
          if (this.props.setCredits) {
            this.props.setCredits(res.data.credits);
          }
        } else {
          this.setState({ userInfoFetched: true });
          console.log("Error=>>", res);
        }
      })
      .catch((error) => {
        this.setState({ userInfoFetched: true });
        console.log("Error=>>", error);
      });
  };

  scrollFunction = () => {
    if (
      document.body.scrollTop > 70 ||
      document.documentElement.scrollTop > 70
    ) {
      this.setState({
        siteText: "TMC",
      });
    } else {
      this.setState({
        siteText: "The Morning Context",
      });
    }
    // Store scroll depth in local storage
    if (helpers.getScrollDepth() > 0) {
      localStorage.setItem("scrollDepth", helpers.getScrollDepth());
    }
  };

  toggleSlideMenu = (show) => {
    console.log("Debugging: Functions sliderMenu")
    this.setState({ openSlideMenu: show });
    if (show) {
      Animated.timing(this.state.slideMenu, {
        toValue: 0,
        duration: 500,
        delay: 0,
        useNativeDriver: true,
      }).start(() => {
        console.log("Debugging: Animation completed")
      });
    } else {
      Animated.timing(this.state.slideMenu, {
        toValue: -(windowWidth * 2),
        duration: 300,
        delay: 0,
        useNativeDriver: true,
      }).start();
    }
  };

  toggleDropdown = () => {
    this.setState({ dropdownVisible: !this.state.dropdownVisible })
  }

  toggleSearch = (show) => {
    this.setState({ openSearch: show });
    if (show) {
      Animated.timing(this.state.searchbar, {
        toValue: 0,
        duration: 500,
        delay: 0,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(this.state.searchbar, {
        toValue: -(windowWidth * 2),
        duration: 300,
        delay: 0,
        useNativeDriver: true,
      }).start();
    }
  };
  // longreadscreen = () => {
  //   // alert("news")
  //   if (helpers.isWeb()) {
  //     this.props.navigateWeb("/all-stories");
  //   } else {
  //     this.props.navigateWeb("/all-stories");
  //   }
  // };

  navigateScreen = (url) => {
    // alert("news")
    if (helpers.isWeb()) {
      this.props.navigateWeb(url);
    } else {
      this.props.navigateWeb(url);
    }
  };

  goToCategories = (url) => {
    if (helpers.isWeb()) {
      this.props.navigateWeb(`/category/${url}`);
    } else {
      this.props.navigateWeb(`/category/${url}`);
    }
  };

  // goToNewsletter = () => {
  //   if (helpers.isWeb()) {
  //     this.props.navigateWeb("/newsletters");
  //   } else {
  //     this.props.navigateWeb("/newsletters");
  //   }
  // };

  // newsletterscreen = () => {
  //   // alert("news")
  //   if (helpers.isWeb()) {
  //     this.props.navigateWeb("/free-reads");
  //   } else {
  //     this.props.navigateWeb("/free-reads");
  //   }
  // };

  // newsletterscreen = () => {
  //   // alert("news")
  //   if (helpers.isWeb()) {
  //     this.props.navigateWeb("/newsletters-for-you");
  //   } else {
  //     this.props.navigateWeb("/newsletters-for-you");
  //   }
  // };

  viewAllStories = () => {
    this.props.navigateApp("/all-stories");
  };

  longformscreen = () => {
    if (helpers.isWeb()) {
      btnClick = {
        to: "/all-stories",
      };
    } else {
      btnClick = {
        onPress: () => this.viewAllStories(),
      };
    }
  };

  viewScreen = (slug) => {
    Animated.timing(this.state.slideMenu, {
      toValue: -(windowWidth * 2),
      duration: 0,
      delay: 0,
      useNativeDriver: true,
    }).start();

    this.props.navigateWeb("/" + slug.web);
  };

  authTokenExpired = (popup) => {
    let cookieDate = new Date(Date.now() - 1);
    cookies.set("token", "", { path: "/", expires: cookieDate });
    this.props.removeUserToken();
    try {
      Mixpanel.reset();
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    try {
      GoogleAnalytics.event("Account", "logout");
    } catch (error) {
      console.log("google analytics error ==>", error);
    }
    this.props.auth.logout({
      returnTo:
        window.location.origin +
        "/sign-in?redirect=" +
        (this.props.redirectSlugs ? this.props?.redirectSlugs?.web : "/"),
    });
  };

  logout = (popup) => {
    this.authTokenExpired(popup);
  };

  login = () => {
    Animated.timing(this.state.slideMenu, {
      toValue: -(windowWidth * 2),
      duration: 0,
      delay: 0,
      useNativeDriver: true,
    }).start();

    this.props.navigateWeb("/sign-in?redirect=" + this.props?.redirectSlugs?.web);
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "login",
      header_title: "NA",
      CTA_position: "top",
      article_name: "NA",
      signup_method: "NA",
    });
  };

  goToSubscribe = () => {
    if (this.props.isPricing) {
      return false;
    }
    try {
      Mixpanel.track("Clicked subscribe from header");
      this.pushTopNavigationEventToDataLayer("subscribe");
      this.pushSubscribeEventToDataLayer();
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    try {
      console.log("try");
      window.webengage.track("Click Subscribe", {
        "Type of User": this.state.token ? "Free" : "non-logged in",
        "Component Name": "AppHeader",
      });
      console.log("success");
    } catch (err) { }
    this.props.navigateWeb("/pricing?header=true");
  };

  showSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null
  ) => {
    const { token } = this.state;
    const planInfo = getPlanInfoObject(selectedSubscribtion.id);
    let showPaymentModal = false;

    if (!token) {
      return this.showSubscriptionOptionsCore(
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
        themeData
      );
    }

    ChargebeeController.activatedController().then((cb_handle) => {
      let countryFromBillingPlatform = country.code;

      if (cb_handle?.instance?.billingInfo?.billing_platform) {
        countryFromBillingPlatform =
          cb_handle?.instance?.billingInfo?.billing_platform ===
            "chargebee_india"
            ? "IN"
            : "OT";
      }

      if (
        planInfo?.billingFrequency === "annual" &&
        countryFromBillingPlatform === "IN" &&
        selectedSubscribtion.tmc_group === "individual"
      ) {
        showPaymentModal = true;
      }
      if (showPaymentModal) {
        this.setState({ showCheckoutOptions: true });
        this.setState({
          selectedSubscribtionID: subscribtionID,
          selectedSubscribtion: selectedSubscribtion,
          country: country,
          subscribeEmail: email ? email : "",
          quantity: quantity,
          themeData: themeData,
        });

        if (!helpers.isDesktop()) {
          this.closeSideBar();
        }
      } else {
        this.showSubscriptionOptionsCore(
          subscribtionID,
          selectedSubscribtion,
          country,
          email,
          quantity,
          themeData
        );
      }
    });
  };
  /*
        showSubscriptionPlan wrapper function
    */

  showSubscriptionOptionsCore = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null,
    paymentMethod = null,
    bankName = null
  ) => {
    const { token } = this.state;
    if (!token) {
      this._showSubscriptionPlan(
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
        themeData,
        {}
      );
      return;
    }

    this.props.setCheckoutLoaderState(true);

    const urlParams = helpers.getURLParamDict(window.location.href);
    const forcePaymentGateway = urlParams.force_payment_gateway;
    const forceCheckoutType = urlParams.force_checkout_type;

    let endpoint = `/get-checkout-options`;

    let body = {
      planName: selectedSubscribtion.id,
      force_payment_gateway: forcePaymentGateway,
      force_checkout_type: forceCheckoutType,
      paymentMethod: paymentMethod,
      bankName: bankName,
    };
    try {
      console.log("try");
      window.webengage.track("Purchase Initiated", {
        "Type of subscription": selectedSubscribtion?.id,
        Amount: selectedSubscribtion?.price,
      });
      console.log("Purchase Initiated", {
        "Type of subscription": selectedSubscribtion?.id,
        Amount: selectedSubscribtion?.price,
      });
    } catch (err) { }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code === "access_denied") {
          this._showSubscriptionPlan(
            subscribtionID,
            selectedSubscribtion,
            country,
            email,
            quantity,
            themeData,
            {}
          );
          return;
        }
        if (!res.success || res.code !== "checkout_options") {
          console.log(
            "Assert failed (showSubscriptionPlan:/get-checkout-options):",
            res.success,
            res.code
          );
          return false;
        }
        const paymentConfig = res.data;

        /*
            Case 1: upgrade
        */
        if (
          this.state.userProfile?.subscription?.subscription_id &&
          selectedSubscribtion.billing_enitity_type === "plan"
        ) {
          let existingSubscriptionId =
            this.state.userProfile.subscription.subscription_id;
          if (paymentConfig.checkoutType === "CB") {
            // // apply only for advance renewal condition
            // let advance_renew = {
            //     terms_to_charge: 1,
            //     // force_term_reset: true,
            //     invoice_immediately: true,
            //     subscription: {
            //         plan_quantity: quantity,
            //     },
            // }

            let upgrade_plan = {
              subscription: {
                plan_id: selectedSubscribtion.id,
                plan_quantity: quantity,
              },
            };

            this.showChargbeePage(
              this.CB__HostedPage,
              "checkout_existing",
              existingSubscriptionId,
              selectedSubscribtion.id,
              quantity,
              upgrade_plan
            );
          } else {
            this._showSubscriptionPlan(
              subscribtionID,
              selectedSubscribtion,
              country,
              email,
              quantity,
              themeData,
              paymentConfig
            );
          }
        } else if (paymentConfig.checkoutType === "CB") {
          /*
              Case 2: new sub checkout using CB
          */
          this.showChargbeePage(
            this.CB__HostedPage,
            "checkout_new",
            null,
            selectedSubscribtion.id,
            quantity
          );
        } else if (paymentConfig.checkoutType === "TMC") {
          /*
              Case 3: new sub checkout using TMC
          */
          this._showSubscriptionPlan(
            subscribtionID,
            selectedSubscribtion,
            country,
            email,
            quantity,
            themeData,
            paymentConfig
          );
        }
      })
      .catch((error) => {
        console.log("Error-catch(showPayment:/get-checkout-options):", error);
      });
  };

  /*
        
    */

  initChargebeeInstance = () => {
    const { chargebeeSite, chargebeeSiteIN, chargebeePublishableKey } =
      this.state;

    if (!chargebeeSite) {
      console.log("CB portal err(chargebeeSite not set)");
      return;
    }
    const CB_Options = {
      site: chargebeeSite,
      publishableKey: chargebeePublishableKey,
    };

    /*
            Open the checkout/portal iframe as redirection in phone view
        */
    if (!helpers.isDesktop()) {
      CB_Options.enableRedirectMode = true;
    }

    /*
            Enable custom domain only for mailtmc CB live site
        */
    if (chargebeeSite == chargebeeSiteIN && process.env.IS_PROD === "True") {
      CB_Options.domain = helpers.CB_CustomDomainIndia;
    }

    window.chargebeeInstance = Chargebee.init(CB_Options);
    window.chargebeeInstance = Chargebee.getInstance();
  };

  /*
        
    */

  setChargebeeSite = () => {
    let {
      billingPlatform,
      chargebeeSiteIN,
      chargebeeSiteKeyIN,
      chargebeeSiteOT,
      chargebeeSiteKeyOT,
      chargebeePubKeyIN,
      chargebeePubKeyOT,
    } = this.state;

    if (!billingPlatform) {
      console.log("No billing platform code to get CB site");
      return;
    }

    const chargebeeSite =
      billingPlatform == "chargebee_india" ? chargebeeSiteIN : chargebeeSiteOT;
    const ChargebeeSiteKey =
      chargebeeSite == chargebeeSiteIN
        ? chargebeeSiteKeyIN
        : chargebeeSiteKeyOT;

    const chargebeePublishableKey =
      chargebeeSite == chargebeeSiteIN ? chargebeePubKeyIN : chargebeePubKeyOT;

    this.setState({
      chargebeeSite: chargebeeSite,
      ChargebeeSiteKey: ChargebeeSiteKey,
      chargebeePublishableKey: chargebeePublishableKey,
    });
  };

  showChargbeePage = (callback, ...params) => {
    if (callback) callback(...params);
  };

  CB__Portal = (
    portal_section,
    existingSubscriptionId,
    subscribtionID,
    quantity
  ) => /*
        Opens chargebee self-serve portal using /create_portal_session endpoint.
        The response of /create_portal_session is valid for 1 hour,
        and no repeatative calls are made when the portal is closed and opened multiple times
        in the 1 hour window.
        
    */ {
    ChargebeeController.activatedController().then((cb_handle) => {
      let forwardOptions = null;
      if (portal_section && Chargebee.getPortalSections()[portal_section]) {
        forwardOptions = {};
        forwardOptions.sectionType =
          Chargebee.getPortalSections()[portal_section];
        forwardOptions.params = {};
        forwardOptions.params["subscriptionId"] =
          ChargebeeController.customerInfo.subscription.subscription_id;
      }
      console.log("chargebee: _control: ", cb_handle);
      if (!cb_handle) {
        throw new Error("CB Handle not inited");
      }
      cb_handle.portal.open(forwardOptions);
    });

    return true;
  };

  isEmptyDict = (obj) => {
    return Object.keys(obj).length === 0;
  };

  CB__HostedPage = (
    actionType,
    existingSubscriptionId,
    selectedSubscribtionSlug,
    quantity,
    hostedObjectParams = null
  ) => {
    let shouldUpdateUrl =
      ["/pricing", "/team-subscription", "/student-subscription"].includes(
        window.location.pathname
      ) && helpers.isDesktop()
        ? true
        : false;
    const urlParams = helpers.getURLParamDict(window.location.href);
    const couponIdList = urlParams.coupon_ids
      ? urlParams.coupon_ids.split(",")
      : [];

    const extraParams = {
      coupon_ids: couponIdList,
    };

    ChargebeeController.activatedController().then((cb_handle) => {
      if (cb_handle?.instance?.siteConfig?.enableRedirectMode === true) {
        window.history.pushState(
          { path: `${window.location.pathname}${window.location.hash}` },
          "",
          `${window.location.pathname}${window.location.hash}`
        );
      }

      if (actionType == "checkout_new") {
        cb_handle.hosted_page.checkout_new(
          {
            plan_id: selectedSubscribtionSlug,
            plan_quantity: quantity,
            ...extraParams,
          },
          {},
          shouldUpdateUrl
        );
      } else if (actionType == "collect_now") {
        cb_handle.hosted_page.collect_now();
      } else if (actionType == "checkout_existing") {
        cb_handle.hosted_page.checkout_existing(
          { ...hostedObjectParams, ...extraParams },
          {},
          shouldUpdateUrl
        );
      }
    });

    return;
  };

  /*
        wrap showSubscriptionPlan in new method to add paymentConfig data
    */
  _showSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null,
    paymentConfig = {}
  ) => {
    const urlParams = helpers.getURLParamDict(window.location.href);

    const couponId = urlParams.coupon_ids;

    if (!helpers.isDesktop()) {
      this.closeSideBar();
    }

    this.props.setCheckoutLoaderState(false);

    if (this.state.token && this.state.userProfile == false) {
      this.getUserInfo(true, {
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
      });
      return false;
    }
    if (subscribtionID == "renew-plan") {
      if (
        JSON.stringify(this.state.userProfile.subscription) != "{}" &&
        this.state.userProfile.subscription.can_renew
      ) {
        this.openSubscriptionPlan(
          subscribtionID,
          this.state.userProfile.subscription.plan,
          country,
          paymentConfig
        );
      }
      return false;
    }
    if (subscribtionID == "renew-team-plan") {
      if (
        JSON.stringify(this.state.userProfile.team_subscription) != "{}" &&
        this.state.userProfile.team_subscription.can_renew &&
        this.state.userProfile.team_subscription.is_team_owner
      ) {
        this.openSubscriptionPlan(
          subscribtionID,
          this.state.userProfile.team_subscription.plan,
          country
        ),
          paymentConfig;
      }
      return false;
    }
    if (
      subscribtionID == "credits" &&
      (this.state.userProfile?.subscription?.disable_buy_credits ||
        (this.state.userProfile?.team_subscription?.is_team_member &&
          this.state.userProfile?.team_subscription?.disable_buy_credits))
    ) {
      return false;
    }
    if (
      selectedSubscribtion &&
      selectedSubscribtion.tmc_group == "team" &&
      parseInt(quantity) < 2
    ) {
      return false;
    }

    if (window && window.ReactNativeWebView && !themeData) {
      try {
        themeData = cookies.get("themeData");
        if (typeof themeData == "string") {
          themeData = JSON.parse(themeData);
        }
      } catch (error) {
        this.setState({ themeDataError: error });
      }
    }
    if (
      selectedSubscribtion &&
      (selectedSubscribtion.tmc_group == "gift_plan" ||
        selectedSubscribtion.tmc_group == "gift_credit") &&
      !themeData
    ) {
      this.openGiftCardModal(
        subscribtionID,
        selectedSubscribtion,
        country,
        email
      );
      return false;
    }
    this.openSubscriptionPlan(
      subscribtionID,
      selectedSubscribtion,
      country,
      email,
      quantity,
      themeData,
      paymentConfig,
      couponId
    );
  };

  openSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity,
    themeData,
    paymentConfig = {},
    couponId = null
  ) => {
    this.setState({
      showSubscriptionPlanModal: true,
      selectedSubscribtionID: subscribtionID,
      paymentConfig: paymentConfig,
      couponId: couponId,
      selectedSubscribtion: selectedSubscribtion,
      country: country,
      subscribeEmail: email ? email : "",
      quantity: quantity,
      themeData: themeData,
    });
    if (
      !window.location.search.includes("subscribe=") &&
      !window.location.search.includes("buy-credits=true")
    ) {
      let pushProp = JSON.parse(JSON.stringify(window.location));
      let searchArr = window.location.search.replace("?", "").split("&");
      let queryParam =
        subscribtionID == "credits"
          ? "buy-credits=true"
          : subscribtionID == "renew-plan" ||
            subscribtionID == "renew-team-plan" ||
            selectedSubscribtion.tmc_group != "team"
            ? `subscribe=${subscribtionID}`
            : `subscribe=${subscribtionID}&quantity=${quantity}`;
      if (searchArr.length > 0 && searchArr[0] != "") {
        pushProp.search += "&" + queryParam;
      } else {
        pushProp.search = "?" + queryParam;
      }
      this.props.history.push(pushProp);
    }
  };

  closeCheckoutModal = () => {
    this.setState({ showCheckoutOptions: false });
    // Clear any search parameters
    let searchArr = window.location.search.replace("?", "").split("&");
    let searchIndex = searchArr.findIndex((searchParam) => {
      if (searchParam == "buy-credits=true") {
        return true;
      }
      return searchParam.includes("subscribe=");
    });
    let close_modal = searchArr.includes("close-modal=true");
    if (close_modal) {
      let reaplaceProp = JSON.parse(JSON.stringify(window.location));
      reaplaceProp.search = "";
      this.props.history.replace(reaplaceProp);
    } else if (searchIndex >= 0) {
      this.props.history.push("/pricing");
    }
  };

  proceedToCheckoutScreen = (paymentMode, bankName) => {
    this.setState({ showCheckoutOptions: false });
    this.setState({ paymentMethod: paymentMode, bankName: bankName });
    // Passing paymentMode and bankName to this API because the set state
    // does not happen immediately so we can't be sure if the values will be set
    this.showSubscriptionOptionsCore(
      this.state.selectedSubscribtionID,
      this.state.selectedSubscribtion,
      this.state.country,
      this.state.email,
      this.state.quantity,
      this.state.themeData,
      paymentMode,
      bankName
    );
  };

  closeSubscriptionPlanModal = (subscriptionStatus, giftData) => {
    let showGiftSuccess = false;
    let giftReceiver = false;
    if (subscriptionStatus == "success" && giftData) {
      showGiftSuccess = true;
      giftReceiver = giftData.first_name;
    }
    this.setState({
      showSubscriptionPlanModal: false,
      selectedSubscribtionID: false,
      subscribeEmail: "",
      showGiftSuccess,
      giftReceiver,
      // subscriptionStatus: subscriptionStatus ? subscriptionStatus : false
    });
    let pushProp = JSON.parse(JSON.stringify(window.location));
    let searchArr = window.location.search.replace("?", "").split("&");
    // let searchArr = searchArr.filter((searchParam) => {
    //     return ((searchParam != "buy-credits=true") && !searchParam.includes("subscribe=")) ? true : false;
    // });
    // if (searchArr.length > 0) {
    //     pushProp.search = "?" + searchArr.join("&");
    // } else {
    //     pushProp.search = "";
    // }
    // this.props.history.push(pushProp)

    let searchIndex = searchArr.findIndex((searchParam) => {
      if (searchParam == "buy-credits=true") {
        return true;
      }
      return searchParam.includes("subscribe=");
    });
    let close_modal = searchArr.includes("close-modal=true");
    if (close_modal) {
      let reaplaceProp = JSON.parse(JSON.stringify(window.location));
      reaplaceProp.search = "";
      this.props.history.replace(reaplaceProp);
    } else if (searchIndex >= 0) {
      this.props.history.push("/pricing");
    }
    if ((subscriptionStatus = "success")) {
      if (this.state.token) {
        this.getUserInfo();
      }
      if (this.props.refreshPage) {
        this.props.refreshPage();
      }
      if (this.refs.sidebar) {
        this.refs.sidebar.refreshPlans();
      }
    }
  };

  closeGiftSuccessBox = () => {
    this.setState({ showGiftSuccess: false });
  };

  openAccounts = () => {
    let pushProp = JSON.parse(JSON.stringify(window.location));
    pushProp.hash = "accounts";
    this.props.history.push(pushProp);
  };

  changeHash = (hash) => {
    let pushProp = JSON.parse(JSON.stringify(window.location));
    pushProp.hash = hash;
    this.props.history.push(pushProp);
  };

  navigateToScreen = (pushProp) => {
    this.props.history.push(pushProp);
  };

  closeSideBar = () => {
    let pushProp = JSON.parse(JSON.stringify(window.location));
    pushProp.hash = "";
    this.props.history.push(pushProp);
  };

  seachStory = () => {
    let { searchText } = this.state;
    if (searchText) {
      if (this.props.allStories) {
        console.log("Debugging 1: ", searchText)
        this.toggleSlideMenu(false);
        this.setState({ searchText: "" });
        this.props.setSearchText(searchText);
      } else {
        console.log("Debugging 2: ", searchText)
        this.props.navigateWeb(`/all-stories?search=${searchText}`);
      }
      this.pushHamburgerMenuClickEventToDataLayer(
        "search",
        "The Morning Context"
      );
      this.pushSearchInteractionEventToDataLayer(
        searchText
      );
    }
  };

  trackCateoryClick = (item) => {
    // try {
    //     if (item.link.includes('/category')) {
    //         Mixpanel.track('category', { category_slug: item.link, source_section_type: 'hamberger_menu', });
    //     }
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  trackBookmarkClick = () => {
    try {
      Mixpanel.track("Clicked reading list from header");
    } catch (error) {
      console.log("mix panel error =>", error);
    }
  };

  openStudentModal = () => {
    this.setState({ openStudentModal: true });
  };

  openGiftCardModal = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    story = null,
    giftCredits
  ) => {
    this.setState({
      showGiftCardModal: true,
      subscriptionData: {
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        userProfile: this.state.userProfile,
        story,
        giftCredits,
      },
    });
  };

  openGiftSubModal = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    story = null,
    giftCredits
  ) => {
    this.setState({
      showGiftSubModal: true,
      subscriptionData: {
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        userProfile: this.state.userProfile,
        story,
        giftCredits,
      },
    });
  };

  /**
   * Smart app banner on click of open
   */
  convertLinks(link) {
    const os = helpers.getMobileOperatingSystem();
    const appPackage = os === "iOS" ? "com.ios.tmc" : "com.app.themorningcontext";
    let baseUrl = "https://themorningcontext.page.link/?link=https://themorningcontext.page.link/open-app"
    let convertedLink = "";

    if (link.includes("/category/")) {
      const paramOne = link.split("/category/")[1];
      convertedLink += `?paramOne=${paramOne}`;
    } else if (link.includes(".com/")) {
      const path = link.split(".com/")[1];
      const pathParts = path.split("/");

      if (pathParts.length === 1) {
        convertedLink += `?paramOne=${pathParts[0]}`;
      } else if (pathParts.length > 1) {
        convertedLink += `?paramOne=${pathParts[0]}&paramTwo=${pathParts.slice(1).join("/")}`;
      }
    }

    return `${baseUrl}${encodeURIComponent(convertedLink)}&apn=${appPackage}&efr=1`
  }

  openApp = async () => {
    let url = this.convertLinks(window.location.href)
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    } else {
      // Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  };

  closeGiftCardModal = () => {
    this.setState({ showGiftCardModal: false });
  };

  closeGiftSubModal = () => {
    this.setState({ showGiftSubModal: false });
  };

  closeStudentModal = () => {
    const { token } = this.state;
    let endpoint = `/edit-profile`;
    let body = {
      viewed_document_status: true,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          this.setState({
            openStudentModal: false,
            documentStatus: "success",
          });
        } else {
          console.log("Error=>>", res);
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
      });
  };

  closeVerificationModal = (filesSubmitted) => {
    this.setState({
      openStudentModal: false,
    });
    if (this.state.token && filesSubmitted) {
      this.getUserInfo();
    }
    if (this.props.refreshPage) {
      this.props.refreshPage();
    }
    if (this.refs.sidebar) {
      this.refs.sidebar.refreshPlans();
    }
  };

  openAppLink = () => {
    let os = helpers.getMobileOperatingSystem();
    if (os == "Android" || os == "iOS") {
      window.location.href = "https://tmcstageapp.page.link/open-app";
    } else {
      console.log("OS not supported for dynamic linking");
    }
  };

  setGiftSuccess = (giftData) => {
    let showGiftSuccess = false;
    let giftReceiver = false;
    if (giftData) {
      showGiftSuccess = true;
      giftReceiver = giftData.first_name;
    }
    this.setState({ showGiftSuccess, giftReceiver });
    if (giftData.gift_credits && this.props.updateFreeCreditAvailable) {
      this.props.updateFreeCreditAvailable(giftData.gift_credits);
    }
    if (this.refs.sidebar) {
      this.refs.sidebar.refreshPlans();
    }
  };

  goBack = () => {
    if (this.state.locationHash == "#accounts") {
      this.closeSideBar();
      return;
    } else if (this.state.locationHash == "#accounts/subscriptions") {
      this.navigateToScreen("#accounts");
      return;
    }
    this.props.history.goBack();
  };

  /**
   * Smart app banner on click of close icon
   * @param {boolean} param - true / false
   */
  toggleInAppNotify = (param) => {
    this.setState({ inAppNotify: param }, () => {
      let content = document.getElementById("__next");
      content.firstElementChild.style.paddingTop = "0px";
    });

    sessionStorage.setItem("inAppNotify", "0");
  };

  renderuserStarterCollection() {
    let { userStarterCollection, credits, token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View style={[helpers.isDesktop() ? Flex.row : Flex.column]}>
          <View
            style={{
              width: helpers.isDesktop() ? "70%" : "100%",
            }}
          >
            <HomeStarterCollection
              data={userStarterCollection}
              token={token}
              viewArticle={(catSlug, slug, postData) =>
                this.viewArticle(catSlug, slug, postData)
              }
              viewAuthor={(slug) => this.viewAuthor(slug)}
              viewCategory={(slug) => this.viewCategory(slug)}
              credits={credits}
              trackStoryClick={(title, data, position) => {
                this.trackStoryClick(
                  "click_starter_story",
                  title,
                  data,
                  position
                );
              }}
              darkMode={darkMode}
              viewStarterCollection={() => {
                this.viewStarterCollection();
              }}
              freeUser={this.state.freeUser}
            />
          </View>
          {this.renderYesterdayStory(darkMode)}
        </View>
      </>
    );
  }

  CB__HostedPage__CollectNow = () => /*
      Opens CB hosted page URL(redirection) to collect view/pay unpaid invoices
  */ {
    const { token } = this.state;
    if (!token) {
      console.log(
        "unpaidInvoiceBand:CB__HostedPage__CollectNow: not authenticated user"
      );
      return false;
    }
    this.setState({ unpaidInvoiceLoader: true });
    ChargebeeController.activatedController().then((cb_handle) => {
      cb_handle.hosted_page.collect_now();
    });
  };

  //start GeneralNotificationBand

  renderUnpaidInvoiceBand() {
    console.log("Debugging 1")
    const { token } = this.state
    try {
      return (
        <GeneralNotificationBand
          buttonLoader={unpaidInvoiceLoader}
          // ctaHandle={() => {
          //   this.CB__HostedPage__CollectNow();
          // }}
          token={token}
          notificationText={this.state.bandDetails.text}
          redirectionPath={this.state.bandDetails.ctaRedirection}
          ctaText={this.state.bandDetails.ctaText}
          backgroundColor="#0d7680"
        />
      );
    } catch (err) {
      console.log("renderUnpaidInvoiceBand:Error:", err);
      return null;
    }
  }

  getPrivilegedCreditsBandCTAURL() {
    console.log("Debugging 2")

    return this.state.bandDetails
  }

  getFreeUserBandCTAURL() {
    console.log("Debugging 3", this.state.bandDetails)



    return this.state.bandDetails;
  }

  getLoggedoutFreeUserBand() {
    console.log("Debugging 4")
    /**
     * Logged out Indian user band
    */


    return this.state.bandDetails
  }
  getOverseasFreeUserBand() {
    console.log("Debugging 5")
    /**
     * Logged out overseas band
    */

    return this.state.bandDetails
  }

  renderOverseasUserBand() {
    console.log("Debugging 6")
    const { token } = this.state


    return (
      <GeneralNotificationBand
        token={token}
        notificationText={this.state.bandDetails.text}
        redirectionPath={this.state.bandDetails.ctaRedirection}
        ctaText={this.state.bandDetails.ctaText}
        backgroundColor="#0d7680"
        closeIconDisplay={false}
      />
    );
  }

  renderSubscriptionExpiryBand() {
    const { token } = this.state

    console.log("Debugging 7")

    return (
      <GeneralNotificationBand
        token={token}
        notificationText={this.state.bandDetails.text}
        redirectionPath={this.state.bandDetails.ctaRedirection}
        ctaText={this.state.bandDetails.ctaText}
        backgroundColor="#0d7680"
        closeIconDisplay={false}
      />
    );
  }
  renderSubscriptionFreeuserBand() {
    console.log("Debugging 8")
    let { token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <GeneralNotificationBand
        token={token}
        notificationText={this.state.bandDetails.text}
        redirectionPath={this.state.bandDetails.ctaRedirection}
        ctaText={this.state.bandDetails.ctaText}
        backgroundColor="#0d7680"
        darkMode={darkMode}
        closeIconDisplay={false}
      />
    );
  }

  renderPrivilegedCreditsBand() {
    console.log("Debugging 9")
    let { token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <GeneralNotificationBand
        token={token}
        notificationText={this.state.bandDetails.text}
        redirectionPath={this.state.bandDetails.ctaRedirection}
        ctaText={this.state.bandDetails.ctaText}
        backgroundColor="#0d7680"
        darkMode={darkMode}
        closeIconDisplay={false}
      />
    );
  }
  getAndShowTheNotificationBand(
    token,
    isOverseasUser,
    userInfoFetched,
    subscriptionDetails,
    freeUser,
    expiredSubscription,
    credits,
    locationInfo,
    currentPath
  ) {
    if (isOverseasUser) {
      if (token) {
        if (
          userInfoFetched &&
          !freeUser &&
          subscriptionDetails &&
          this.isSubscriptionUnderDunning()
        ) {
          return this.renderUnpaidInvoiceBand();
        }
        // For a logged in user if the user is a free user, and has no subscription show the band
        if (userInfoFetched && freeUser && !expiredSubscription) {
          return this.renderOverseasUserBand();
        }
        if (userInfoFetched && freeUser && expiredSubscription) {
          return this.renderSubscriptionExpiryBand();
        }
        if (!this.isSubscriptionUnderDunning()) {
          return this.paymentBandChain();
        }
        if (freeUser && currentPath == "home") {
          return this.renderHomeBannerSection();
        }
      } else {
        // For a logged out user, if we have the location info render the overseas band

        return this.renderOverseasUserBand();

      }
    } else {
      if (token) {
        if (
          userInfoFetched &&
          freeUser &&
          !expiredSubscription &&
          credits?.non_privileged_credits?.available === 0
        ) {
          return this.renderCreditExpiryBand();
        }
        if (
          userInfoFetched &&
          freeUser &&
          !expiredSubscription &&
          credits?.privileged_credits?.available > 0
        ) {
          return this.renderPrivilegedCreditsBand();
        }
        if (
          userInfoFetched &&
          !freeUser &&
          subscriptionDetails &&
          this.isSubscriptionUnderDunning()
        ) {
          return this.renderUnpaidInvoiceBand();
        }
        if (userInfoFetched && freeUser && !expiredSubscription) {
          return this.renderSubscriptionFreeuserBand();
        }
        if (userInfoFetched && freeUser && expiredSubscription) {
          return this.renderSubscriptionExpiryBand();
        }
        if (!this.isSubscriptionUnderDunning()) {
          return this.paymentBandChain();
        }
        if (freeUser && currentPath == "home") {
          return this.renderHomeBannerSection();
        }
      } else {

        return this.renderPromotionBand();

      }
    }
  }
  advanceRenewalChecks() {
    let canRenew = this.canAdvanceRenew();
    if (!canRenew) {
      return;
    }
    ChargebeeController.activatedController().then((handle) => {
      handle.API.subscription
        .payment_source({
          subscription: {
            id: handle.activeSubscription.subscription_id,
          },
        })
        .then((res) => {
          this.setState({
            userPaymentInfo: res,
            userPaymentInfoFetched: true,
          });
        })
        .catch((err) => {
          console.log("chargebee: API:payment_source:Error: ", err);
          this.setState({
            userPaymentInfoFetched: true,
          });
        });
    });
  }

  canAdvanceRenew() {
    let { subscriptionDetails, token } = this.state;

    if (!token) {
      return false;
    }

    return helpers.canAdvanceRenew(subscriptionDetails);
  }

  hasPaymentMandate() {
    let { subscriptionDetails, userPaymentInfo } = this.state;
    if (!userPaymentInfo) {
      return false;
    }
    const hasMandates = userPaymentInfo.mandates ? true : false;
    if (!hasMandates) {
      return false;
    }
    const mandateList = userPaymentInfo.mandates;
    console.log(mandateList, "mandateList");
    return mandateList.some((manadateObject) => {
      if (
        manadateObject.subscription_id === subscriptionDetails.subscription_id
      ) {
        return true;
      }
      return false;
    });
  }

  isSubscriptionUnderDunning() {
    let { subscriptionDetails } = this.state;
    if (subscriptionDetails.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  paymentBandChain() {
    let { subscriptionDetails, userPaymentInfoFetched, userPaymentInfo } =
      this.state;

    try {
      if (!subscriptionDetails) {
        return;
      }
      if (subscriptionDetails.is_cancelled) {
        return this.showCancellationRemovalBand();
      } else {
        const cancellationRemovalEventKey = `scheduled-cancellation-removed:${subscriptionDetails.subscription_id}`;
        localStorage.removeItem(cancellationRemovalEventKey);
      }

      // if has annual plan and it expires in 90 days
      if (this.isAnnualPlanUpgradable()) {
        return this.renderAnnualPlanUpgradeBand();
      }

      if (!this.canAdvanceRenew()) {
        return;
      }

      if (!userPaymentInfoFetched) {
        return;
      }

      if (this.hasPaymentMandate()) {
        return this.showMandateExistsBand();
      }

      return this.showAdvanceRenewalBand();
      // if(userPaymentInfo){
      //   // return this.showMandateBand(subscriptionDetails, this.state.userPaymentInfo)
      //   return this.showAdvanceRenewalBand()
      // }
    } catch (err) {
      console.log("home:paymentBandChain:Error: ", err);
    }

    return;
  }

  showCancellationRemovalBand() {
    let { subscriptionDetails, token, canShowBandLoader } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    if (!subscriptionDetails?.end_date) {
      return;
    }
    const cancellationRemovalEventKey = `scheduled-cancellation-removed:${subscriptionDetails.subscription_id}`;
    const cancellationRemovalEventObject = localStorage.getItem(
      cancellationRemovalEventKey
    );
    if (cancellationRemovalEventObject) {
      try {
        const cancellationData = JSON.parse(cancellationRemovalEventObject);
        if (cancellationData.expiresAt) {
          let cancellationRemovalEventExpiry = new Date(
            cancellationData.expiresAt
          );
          if (new Date() <= cancellationRemovalEventExpiry) {
            return;
          }
        }
      } catch (err) {
        console.log("removeCancellation:eventObject:Error: ", err);
      }
    }
    const removeCancellation = () => {
      this.setState({ canShowBandLoader: true });
      ChargebeeController.activatedController().then((handle) => {
        handle.API.subscription
          .remove_scheduled_cancellation({
            subscription: {
              id: handle.activeSubscription.subscription_id,
            },
          })
          .then((res) => {
            try {
              const currentDate = new Date();
              const eventData = {
                value: true,
                expiresAt: currentDate.setMinutes(currentDate.getMinutes() + 2),
              };
              localStorage.setItem(
                cancellationRemovalEventKey,
                JSON.stringify(eventData)
              );
            } catch (err) {
              console.log("removeCancellation:Error: ", err);
            }
            window.location.reload();
          })
          .catch((err) => {
            console.log(
              "chargebee: API:remove_scheduled_cancellation:Error: ",
              err
            );
          });
      });
    };

    return (
      <GeneralNotificationBand
        buttonLoader={canShowBandLoader}
        token={token}
        notificationText={`Your subscription has been cancelled.`}
        redirectionPath={`/pricing`}
        ctaText="Resume Your Subscription"
        ctaHandle={removeCancellation}
        backgroundColor="#0d7680"
        darkMode={darkMode}
      />
    );
  }

  showAdvanceRenewalBand() {
    return this.renderSubscriptionRenewalBand();
  }
  showMandateBand(subscriptionDetails, paymentInfo) {
    return this.renderMandateRequestBand();
  }
  renderMandateRequestBand() {
    let { subscriptionDetails, userProfile, token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    if (!subscriptionDetails) {
      return;
    }
    const openPortal = () => {
      let forwardOptions = {
        sectionType: "sub_details",
        params: {
          subscriptionId: subscriptionDetails.subscription_id,
        },
      };
      ChargebeeController.activatedController().then((cb_handle) => {
        if (!cb_handle) {
          throw new Error("CB Handle not inited");
        }
        cb_handle.portal.open(forwardOptions);
      });
    };

    return (
      <GeneralNotificationBand
        token={token}
        notificationText={`${userProfile.name}, here’s a chance to upgrade to our 2-year plan at a 10% discount`}
        redirectionPath={`/pricing`}
        ctaText="RENEW"
        ctaHandle={openPortal}
        backgroundColor="#0d7680"
        darkMode={darkMode}
      />
    );
  }
  showMandateExistsBand() {
    try {
      let { userProfile, subscriptionDetails, token } = this.state;
      let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

      const mandateExistsBandDetails = bandCategoryHandle.getBandDetails(
        userProfile,
        subscriptionDetails,
        "mandateExists"
      );
      return (
        <GeneralNotificationBand
          token={token}
          notificationText={mandateExistsBandDetails.text}
          redirectionPath={mandateExistsBandDetails.ctaRedirection}
          ctaText={mandateExistsBandDetails.ctaText}
          backgroundColor="#0d7680"
          darkMode={darkMode}
        />
      );
    } catch (err) {
      console.log("showMandateExistsBand:Error: ", err);
    }
  }
  getFormattedDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(date);
    let year = d.getFullYear();
    // let month = (1 + d.getMonth()).toString().padStart(2, "0");
    let month = d.getMonth();
    let day = d.getDate().toString().padStart(2, "0");

    return day + "/" + monthNames[month] + "/" + year;
  }
  renderSubscriptionRenewalBand() {
    try {
      let { subscriptionDetails, token, userProfile } = this.state;
      let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

      const mandateDoesNotExistsBandDetails = bandCategoryHandle.getBandDetails(
        userProfile,
        subscriptionDetails,
        "mandateDoesNotExists"
      );
      const openAdvanceRenewal = () => {
        ChargebeeController.activatedController().then((cb_handle) => {
          cb_handle.hosted_page.checkout_existing(
            {
              terms_to_charge: 1,
              invoice_immediately: true,
            },
            {},
            false
          );
        });
      };

      return (
        <GeneralNotificationBand
          token={token}
          notificationText={mandateDoesNotExistsBandDetails.text}
          redirectionPath={mandateDoesNotExistsBandDetails.ctaRedirection}
          ctaText={mandateDoesNotExistsBandDetails.ctaText}
          // ctaHandle={openAdvanceRenewal}

          backgroundColor="#0d7680"
          darkMode={darkMode}
        />
      );
    } catch (err) {
      console.log("renderSubscriptionRenewalBand:Err: ", err);
    }
  }

  isAnnualPlanUpgradable() {
    let { subscriptionDetails, token, userProfile } = this.state;
    try {
      if (!subscriptionDetails) {
        return false;
      }

      let planId = getPlanFromCurrency("INR", "annual");

      if (!subscriptionDetails?.plan?.id || !planId) {
        return false;
      }

      if (subscriptionDetails?.plan?.id !== planId) {
        return false;
      }

      let subscriptionEndDate = subscriptionDetails?.end_date
        ? new Date(subscriptionDetails.end_date)
        : undefined;

      if (!subscriptionEndDate) {
        return false;
      }

      let rightLimitDate = new Date();
      rightLimitDate.setDate(new Date().getDate() + 90);

      if (subscriptionEndDate < rightLimitDate) {
        return true;
      }
    } catch (err) {
      console.log("isAnnualPlanUpgradable:Err: ", err);
    }

    return false;
  }

  renderAnnualPlanUpgradeBand() {
    try {
      let { subscriptionDetails, token, userProfile } = this.state;
      let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

      const twoYearPlanPromotionBandDetails = bandCategoryHandle.getBandDetails(
        userProfile,
        subscriptionDetails,
        "twoYearPlanPromotion"
      );

      return (
        <GeneralNotificationBand
          token={token}
          notificationText={twoYearPlanPromotionBandDetails.text}
          redirectionPath={twoYearPlanPromotionBandDetails.ctaRedirection}
          ctaText={twoYearPlanPromotionBandDetails.ctaText}
          backgroundColor="#0d7680"
          darkMode={darkMode}
        />
      );
    } catch (err) {
      console.log("renderSubscriptionRenewalBand:Err: ", err);
    }
  }

  renderCreditExpiryBand() {
    let { userStarterCollection, credits, token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <CreditExpiryBand
        token={token}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        credits={credits}
        trackStoryClick={(title, data, position) => {
          this.trackStoryClick("click_starter_story", title, data, position);
        }}
        darkMode={darkMode}
        viewStarterCollection={() => {
          this.viewStarterCollection();
        }}
      />
    );
  }

  renderPaidExpiryBand() {
    console.log("renderPaidExpiryBand");
    let { subscriptionDetails, credits, token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <PaidExpiryBand
        subscription={subscriptionDetails}
        token={token}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        credits={credits}
        trackStoryClick={(title, data, position) => {
          this.trackStoryClick("click_starter_story", title, data, position);
        }}
        darkMode={darkMode}
        viewStarterCollection={() => {
          this.viewStarterCollection();
        }}
      />
    );
  }

  renderPromotionBand() {
    let { token, locationInfo } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const bandInfoObject = this.getLoggedoutFreeUserBand();

    if (!bandInfoObject) {
      return;
    }
    // let bandText = "Create an account to read all free stories from our archive and a free longread every week";
    return (
      <GeneralNotificationBand
        token={token}
        notificationText={bandInfoObject.text}
        redirectionPath={bandInfoObject.ctaRedirection}
        ctaText={bandInfoObject.ctaText}
        backgroundColor="#0d7680"
        darkMode={darkMode}
        closeIconDisplay={false}
      />
    );
  }
  trackSignUp = (email, position) => {
    Mixpanel.track("click_signup", { email: email });
    // Mixpanel.track('click_signup', {email: email, position: position });
  };

  /** Home Banner Section **/
  renderHomeBannerSection() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <HomeBanner
        navigateWeb={(slug) => this.props.navigateToScreen(slug)}
        navigateApp={(slug, params) =>
          this.props.navigation.navigate(slug, params)
        }
        trackSignUp={(email, position) => {
          this.trackSignUp(email, position);
        }}
        darkMode={darkMode}
        auth={this.props.auth}
        freeUser={this.state.freeUser}
        subscriptionDetails={this.state.subscriptionDetails}
        custom_user_id={this.state.custom_user_id}
      />
    );
  }

  pushSearchInteractionEventToDataLayer = (searchText) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "search_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      search_text: searchText,
      category_type: "NA"
    });
  }

  pushHamburgerMenuClickEventToDataLayer = (elementTitle, headerTitle) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "hamburger_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      element_title: elementTitle?.toLowerCase(),
      header_title: headerTitle?.toLowerCase(),
    });
  };

  pushLogoClickEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "logo_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
    });
  };

  pushTopNavigationEventToDataLayer = (elementTitle) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "top_navigation_bar",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      element_title: elementTitle,
    });
  };

  pushSubscribeEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "subscribe_initiate",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      header_title: "NA",
      banner_name: "NA",
      CTA_text: "subscribe",
      CTA_position: "top",
      scroll_depth: helpers.getScrollDepth(),
      category_type: "NA",
    });
  };

  // End GeneralNotificationBand

  render() {
    // console.log('uiconnect', this.state.isSafaribrowser);
    let longform;
    if (helpers.isWeb()) {
      longform = {
        to: "/longform",
      };
    } else {
      longform = {
        onPress: () => this.viewAllStories(),
      };
    }
    const {
      showToken,
      token,
      userProfile,
      showLogin,
      showUser,
      showSubscribe,
      selectedSubscribtionID,
      selectedSubscribtion,
      subscriptionStatus,
      subscribeEmail,
      country,
      locationHash,
      menuItems,
      searchText,
      quantity,
      copyright,
      showModal,
      showStatus,
      readingValue,
      documentStatus,
      submittedFiles,
      openStudentModal,
      documentUploadDate,
      showGiftCardModal,
      showGiftSubModal,
      subscriptionData,
      themeData,
      showGiftSuccess,
      giftReceiver,
      paymentConfig,
      inAppNotify,
      credits,
      userInfoFetched,
      freeUser,
      subscriptionDetails,
      expiredSubscription,
      locationInfo,
      currentPath,
      couponId,
      showCheckoutOptions,
      showSubscriptionPlanModal,
    } = this.state;
    let { hideMenu, hideHeader, darkMode, isAdmin, referenceId } = this.props;
    let btnClickFB,
      btnClickTwitter,
      btnClickLinkdin,
      btnClickTelegram,
      btnClickWhatsApp,
      btnClickTerms,
      btnClickPrivacy,
      searchClose,
      searchBtnProps,
      btnHomeClick,
      btnBookmarkClick;
    btnClickFB = {
      href: "https://www.facebook.com/Morningcontext/",
    };
    btnClickTwitter = {
      href: "https://twitter.com/MorningContext",
    };
    btnClickLinkdin = {
      href: "https://www.linkedin.com/company/the-morning-context",
    };
    btnClickTelegram = {
      href: "https://t.me/+hicP5Chbr0MxYTI1",
    };
    btnClickWhatsApp = {
      href: "https://www.whatsapp.com/channel/0029VaBON51InlqTO0if5g1f"
    };
    btnClickTerms = {
      to: "/terms-and-conditions",
    };
    btnClickPrivacy = {
      to: "/privacy-policy",
    };
    searchBtnProps = {
      onPress: () => this.seachStory(),
    };
    searchClose = {
      onPress: () => this.toggleSearch(false),
    };
    btnHomeClick = {
      to: "/",
      handleClick: () => {
        this.pushLogoClickEventToDataLayer();
      },
    };
    btnBookmarkClick = {
      to: "/bookmarks",
      handleClick: () => {
        this.trackBookmarkClick();
        this.pushTopNavigationEventToDataLayer("bookmarks");
      },
    };
    let isOverseasUser = false;
    if (token) {
      isOverseasUser = locationInfo?.countryInfo?.code !== "IN";
    } else {
      isOverseasUser = locationInfo?.countryInfo?.code !== "IN";
    }
    return (
      <>
        {/* <ReactModal
          visible={this.state.showExitIntent && !this.state.token}
          animationType="fade"
          transparent={true}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "flex-end",
            }}
            activeOpacity={1}
          >
            <TouchableWithoutFeedback>
              {helpers.isDesktop() ? (
                <ExitIntentPopupWeb
                  onClose={this.handleCloseExitIntent}
                  navigateToScreen={(pushProp) =>
                    this.navigateToScreen(pushProp)
                  }
                  navigation={this.props.navigation}
                  redirectSlugs={this.props.redirectSlugs}
                />
              ) : (
                <ExitIntentPopupMobile
                  onClose={this.handleCloseExitIntent}
                  navigateToScreen={(pushProp) =>
                    this.navigateToScreen(pushProp)
                  }
                  navigation={this.props.navigation}
                  redirectSlugs={this.props.redirectSlugs}
                />
              )}
            </TouchableWithoutFeedback>
          </TouchableOpacity>
        </ReactModal> */}
        {showGiftCardModal && (
          <GiftCardModal
            darkMode={darkMode}
            closeGiftCardModal={() => {
              this.closeGiftCardModal();
            }}
            token={token}
            subscriptionData={subscriptionData}
            showSubscriptionPlan={(
              subscribtionId,
              subscribtion,
              country,
              email,
              quantity,
              themeInfo
            ) =>
              this.showSubscriptionPlan(
                subscribtionId,
                subscribtion,
                country,
                email,
                quantity,
                themeInfo
              )
            }
            setGiftSuccess={this.setGiftSuccess}
          />
        )}

        {showGiftSubModal && (
          <GiftSubModal
            darkMode={darkMode}
            closeGiftSubModal={() => {
              this.closeGiftSubModal();
            }}
            token={token}
            subscriptionData={subscriptionData}
            showSubscriptionPlan={(
              subscribtionId,
              subscribtion,
              country,
              email,
              quantity,
              themeInfo
            ) =>
              this.showSubscriptionPlan(
                subscribtionId,
                subscribtion,
                country,
                email,
                quantity,
                themeInfo
              )
            }
            setGiftSuccess={this.setGiftSuccess}
          />
        )}

        {openStudentModal && (
          <VerifyDocumentModal
            documentStatus={documentStatus}
            submittedFiles={submittedFiles}
            token={token}
            closeModal={() => { }}
            openStudentModal={openStudentModal}
            closeVerificationModal={(filesSubmitted) =>
              this.closeVerificationModal(filesSubmitted)
            }
            userProfile={userProfile}
            navigateToScreen={(pushProp) => this.navigateToScreen(pushProp)}
            documentUploadDate={documentUploadDate}
            darkMode={darkMode}
            logout={this.logout}
          />
        )}

        {locationHash != undefined && locationHash != "" && (
          <SidebarCheck
            ref="sidebar"
            showCBPortal={(
              portal_section,
              existingSubscriptionId,
              subscribtionID,
              quantity
            ) => {
              this.showChargbeePage(
                this.CB__Portal,
                portal_section,
                existingSubscriptionId,
                subscribtionID,
                quantity
              );
            }}
            closeSideBar={() => this.closeSideBar()}
            hash={locationHash.replace("#", "")}
            changeHash={(hash) => this.changeHash(hash)}
            navigateToScreen={(pushProp) => this.navigateToScreen(pushProp)}
            goBack={this.goBack}
            showSubscriptionPlan={(subscribtionId, subscribtion, country) =>
              this.showSubscriptionPlan(subscribtionId, subscribtion, country)
            }
            logout={this.logout}
            isAdmin={isAdmin ? true : false}
            referenceId={referenceId}
            darkMode={darkMode}
            openGiftCardModal={(
              subscribtionID,
              selectedSubscribtion,
              country
            ) =>
              this.openGiftCardModal(
                subscribtionID,
                selectedSubscribtion,
                country
              )
            }
            history={this.props.history}
            loginRequired={this.loginRequired}
            setCheckoutLoaderState={(loaderState) =>
              this.props.setCheckoutLoaderState(loaderState)
            }
            getCheckoutLoaderState={this.props.getCheckoutLoaderState}
          />
        )}

        {showSubscriptionPlanModal && (
          <SubscriptionPlanModal
            ref="submodal"
            planData={selectedSubscribtion}
            paymentConfig={paymentConfig}
            couponId={couponId}
            email={subscribeEmail}
            country={country}
            userProfile={userProfile}
            closeModal={(status, giftData) => {
              this.props.setCheckoutLoaderState(false);
              this.closeSubscriptionPlanModal(status, giftData);
            }}
            logout={() => this.logout(true)}
            renewPlan={
              selectedSubscribtionID == "renew-plan" ||
                selectedSubscribtionID == "renew-team-plan"
                ? true
                : false
            }
            buyCredits={
              selectedSubscribtionID == "credits" ||
                selectedSubscribtion.tmc_group == "team_credit"
                ? true
                : false
            }
            isTeam={
              selectedSubscribtion.tmc_group == "team" ||
                selectedSubscribtion.tmc_group == "team_credit"
                ? true
                : false
            }
            navigateWeb={(slug) => this.props.navigateWeb(slug)}
            navigateApp={(slug, params) => this.props.navigateApp(slug, params)}
            quantity={quantity}
            giftPlan={
              selectedSubscribtion.tmc_group == "gift_plan" ||
              selectedSubscribtion.tmc_group == "gift_credit"
            }
            themeData={themeData}
            custom_user_id={this.state.custom_user_id}
          />
        )}

        {showCheckoutOptions && (
          <CheckoutModal
            closeModal={() => this.closeCheckoutModal()}
            proceedToCheckoutScreen={(paymentMode, bankName) => {
              this.proceedToCheckoutScreen(paymentMode, bankName);
            }}
          />
        )}

        {/* Start of the Header view */}
        {/* Open in app banner */}

        {!helpers.isDesktop() && inAppNotify && (
          <View
            style={[
              { transform: [{ translateY: showModal }] },
              appHeader.wrapper,
              helpers.isDesktop() && appHeader.wrapperDesktop,
              { position: "fixed", left: 0, right: 0, top: 0, zIndex: 999 },
              !helpers.isDesktop() && { height: 56 },
              darkMode && { backgroundColor: Colors.darkHeaderColor },
              {
                display: "flex",
                flexDirection: "row",
                height: 58,
                paddingHorizontal: 10,
                borderBottomColor: "#907cff",
                borderBottomWidth: 1,
                borderStyle: "solid",
              },
            ]}
          >
            <View style={{ width: "20px" }}>
              <TouchableOpacity
                style={{ alignSelf: "center" }}
                onPress={() => {
                  this.toggleInAppNotify(false);
                }}
              >
                <View style={[formStyle.searchBox]}>
                  <CustomImage
                    source={darkMode ? closeIconWhite : closeIcon}
                    require={true}
                    style={{ width: 10, height: 10 }}
                    webStyle={{
                      width: 10,
                      height: 10,
                    }}
                  />
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "calc(100% - 100px)",
                flexDirection: "row",
              }}
            >
              <CustomImage
                require={true}
                source={text_in_circle}
                altText={"The Morning Context"}
                webStyle={{
                  width: 50,
                  height: 50,
                  position: "relative",
                  zIndex: 99,
                  marginRight: 5,
                }}
                style={[appHeader.logo]}
              />
              <View>
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: "12px",
                    fontWeight: 500,
                    fontFamily: "PlayfairDisplay-Bold",
                    marginBottom: 2,
                  }}
                >
                  The Morning Context
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: "1.2",
                    color: "#a1a1a1",
                    fontFamily: "PlayfairDisplay-Regular",
                    fontWeight: 600,
                  }}
                >
                  Open in app
                </Text>
              </View>
            </View>

            <View style={{ width: "80px" }}>
              <ButtonGradient
                title={"OPEN"}
                rootStyle={{
                  btnWrapper: [button.primaryGradient, { height: 36 }],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: 14,
                      paddingHorizontal: 20,
                      fontWeight: "500",
                      fontFamily: "PlayfairDisplay-Regular",
                    },
                  ],
                }}
                paddingOverride={true}
                onClick={() => this.openApp()}
                darkMode={darkMode}
                isNewGradiant={true}
              />
            </View>
          </View>
        )}
        {!helpers.isDesktop() && <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingVertical: 10, alignItems: 'center', paddingHorizontal: 10, shadowColor: 'rgba(0, 0, 0, 0.25)', shadowOffset: { width: 0, height: 1 }, shadowOpacity: 1, shadowRadius: 1, elevation: 1, zIndex: 2 }}>
          <TouchableOpacity
            style={{ paddingVertical: 10 }}
            onPress={() => this.toggleSlideMenu(true)}
            activeOpacity={0.8}
          >
            <CustomImage
              require={true}
              source={darkMode ? menuIconMobDark : menuIconMob}
              webStyle={{
                width: 24,
                height: 24,
              }}
              style={[appHeader.menuIcon]}
            />
          </TouchableOpacity>
          {!helpers.isDesktop() && (
            <TouchableOpacity onPress={() => {
              window.location.href = '/'
            }} style={[Flex.row, Flex.alignCenter, { left: 10, marginLeft: 10 }]}>
              <CustomImage
                require={true}
                source={appLogo}
                altText={"The Morning Context"}
                webStyle={{
                  width: 107,
                  height: 36,
                  position: "relative",
                  zIndex: 99,
                }}
                style={[appHeader.logo]}
              />
              {/* Mobilewebview */}
              <View
                style={{
                  alignItems: "center",
                  marginLeft: 5,
                  width: helpers.isDesktop() ? 60 : 0,
                  height: 30,
                }}
              >
                <TouchableOpacityLink {...btnHomeClick}>
                  {helpers.isDesktop() ? (
                    <Image
                      style={{ width: 60, height: 27 }}
                      source={require("../../../../assets/icons/TMCLogo@3x.png")}
                    />
                  ) : (
                    <>
                    </>
                  )}
                </TouchableOpacityLink>
              </View>
            </TouchableOpacity>
          )}
          {showToken && showLogin && (
            <>
              <TouchableOpacity
                style={{ alignSelf: "center", marginLeft: '18vw', marginRight: 10 }}
                onPress={() => this.toggleSlideMenu(true)}
                activeOpacity={0.8}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Margin.ml_1,
                    helpers.isDesktop() && {
                      borderColor: "#DDDDDD",
                      borderWidth: 1,
                      paddingHorizontal: 11,
                      paddingVertical: 11,
                    },
                  ]}
                >
                  <CustomImage
                    require={true}
                    source={
                      darkMode ? userIconFilledWhite : userIconLight
                    }
                    webStyle={{
                      width: helpers.isDesktop()
                        ? "auto"
                        : darkMode
                          ? "auto"
                          : 21,
                      height: helpers.isDesktop()
                        ? 16
                        : darkMode
                          ? 18
                          : "auto",
                    }}
                  />
                  {helpers.isDesktop() && (
                    <Text
                      style={[
                        Helpers.textUppercase,
                        Margin.ml_1,
                        { fontSize: 13, fontFamily: FontFamily.regular },
                        darkMode && { color: Colors.lightWhite },
                      ]}
                    >
                      {userProfile.name.substring(0, 1)}
                    </Text>
                  )}
                </View>
              </TouchableOpacity>
              <View style={{ flexDirection: "row" }}>
                {/* <View style={[Flex.row, Flex.alignCenter, Margin.ml_1, Padding.ph_1, {}]}>
                                      <TouchableOpacity
                                          style={{ padding: 5 }}
                                          onPress={() => this.toggleSlideMenu(true)}
                                          activeOpacity={0.8}>
                                          <CustomImage
                                              require={true}
                                              source={darkMode ? searchIcon : searchIcon}
                                              webStyle={{
                                                  width: helpers.isDesktop() ? 'auto' : (darkMode ? 'auto' : 21),
                                                  height: helpers.isDesktop() ? 16 : (darkMode ? 18 : 'auto')
                                              }}
                                              style={[appHeader.menuIcon]} />
                                      </TouchableOpacity>
                                  </View> */}
                <ButtonGradient
                  title={"SIGN IN"}
                  rootStyle={{
                    btnWrapper: [button.primaryGradient, { height: 40 }],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: 14,
                        paddingHorizontal: 25,
                        fontWeight: "500",
                        fontFamily: FontFamily.regular,
                      },
                    ],
                  }}
                  paddingOverride={true}
                  onClick={() => this.login()}
                  darkMode={darkMode}
                  isNewGradiant={true}
                />
              </View>
            </>
          )}

          {showUser && (
            <View style={{ marginRight: 15, position: 'absolute', right: '10vw' }}>
              <TouchableOpacityLink {...btnBookmarkClick}>
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    helpers.isDesktop() && { paddingVertical: 6 },
                    !helpers.isDesktop() && { padding: 4 },
                  ]}
                >
                  <CustomImage
                    require={true}
                    source={
                      darkMode ? bookmarkMultipleDark : bookmarkMultiple
                    }
                    webStyle={{
                      width: helpers.isDesktop() ? "auto" : 15,
                      height: helpers.isDesktop() ? 28 : "auto",
                    }}
                  />
                  <Animated.View
                    style={{
                      position: "absolute",
                      height: readingValue.interpolate({
                        inputRange: [0, 60],
                        outputRange: helpers.isDesktop() ? [1, 70] : [1, 60],
                      }),
                      width: readingValue.interpolate({
                        inputRange: [0, 60],
                        outputRange: helpers.isDesktop() ? [1, 70] : [1, 60],
                      }),
                      borderRadius: "50%",
                      backgroundColor: "#C4C4C4",
                      zIndex: -1,
                      left: readingValue.interpolate({
                        inputRange: [0, 60],
                        outputRange: helpers.isDesktop()
                          ? [8, -25]
                          : [7, -23],
                      }),
                      opacity: readingValue.interpolate({
                        inputRange: [0, 17, 55, 60],
                        outputRange: [0, 1, 1, 0],
                      }),
                    }}
                  ></Animated.View>
                </View>
              </TouchableOpacityLink>
            </View>
          )}
          {/* {!helpers.isDesktop() &&
                            <View style={{ marginRight: helpers.isDesktop() ? 30 : 15 }}>
                                <ButtonGradient
                                    title={'Use App'}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradient, { height: 30 }],
                                        btnText: [button.primaryGradientText, { fontSize: 14, paddingHorizontal: 7, fontWeight: '700', fontFamily: FontFamily.bold }]
                                    }}
                                    paddingOverride={true}
                                    onClick={() => { this.openAppLink() }}
                                    darkMode={darkMode}
                                />
                            </View>
                        } */}

          {showUser ? (
            <View style={{ marginRight: 15, position: 'absolute', right: '0vw' }}>
              <TouchableOpacity
                onPress={() => this.openAccounts()}
                activeOpacity={0.8}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    {
                      backgroundColor: "#FC876D",
                      paddingHorizontal: helpers.isDesktop() ? 13 : 8,
                      paddingVertical: helpers.isDesktop() ? 6 : 3.5,
                      borderRadius: 30,
                    },
                  ]}
                >
                  {/* {!helpers.isDesktop() && <CustomImage
                                            require={true}
                                            source={darkMode ? userIconFilledWhite : userIcon}
                                            webStyle={{
                                                width: helpers.isDesktop() ? 'auto' : 16,
                                                height: helpers.isDesktop() ? 16 : 'auto', marginTop: 1
                                            }} />} */}

                  <Text
                    style={[
                      Helpers.textUppercase,
                      {
                        fontFamily: FontFamily.abrilFatface,
                        fontWeight: "400",
                        color: "#000",
                        fontSize: helpers.isDesktop() ? 22 : 18,
                        lineHeight: helpers.isDesktop() ? 29 : 22,
                      },
                    ]}
                  >
                    {userProfile.name.substring(0, 1)}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={[{ width: "auto", height: 19 }]}></View>
          )}
        </View>}
        {
          helpers.isDesktop() ?
            <TouchableWithoutFeedback onPress={() => this.setState({ openSlideMenu: false, dropdownVisible: false })}>
              <View style={styles.wrapper}>
                <View style={styles.logoSection}>
                  <TouchableOpacity style={styles.menuCard} onPress={() => {
                    this.setState({ openSlideMenu: true })
                    this.toggleSlideMenu(true)
                  }}>
                    <CustomImage
                      source={hamburgerMenu}
                      style={{
                        width: 16,
                        height: 'auto'
                      }}
                      webStyle={{
                        width: 16,
                        height: 'auto'
                      }}
                    />
                    <Text style={styles.menuText}>MENU</Text>
                  </TouchableOpacity>

                  {this.state.showHeaderSearch ? (
                    <Animated.View
                      style={[
                        appHeader.searchWrapper,
                        helpers.isDesktop() && appHeader.menuWrapperDesktop,
                        {
                          maxWidth: 450,
                          marginLeft: 10,
                          position: 'relative',
                          left: 0,
                          paddingHorizontal: 0
                        },
                        !helpers.isDesktop() && { position: "fixed" },
                      ]}
                    >
                      {this.state.searchText !== "" ? (
                        <TouchableOpacity
                          style={{ alignSelf: "center" }}
                          onPress={this.seachStory}
                        >
                          <View style={[formStyle.searchBox]}>
                            <CustomImage
                              source={searchBox}
                              require={true}
                              style={{ width: 45, height: 40 }}
                              webStyle={{ width: 45, height: 40 }}
                            />
                          </View>
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity
                          style={{ alignSelf: "center" }}
                          onPress={() => {
                            this.setState({ showHeaderSearch: false })
                          }}
                        >
                          <View style={[formStyle.searchBox]}>
                            <CustomImage
                              source={closeIcon}
                              require={true}
                              style={{ width: 13, height: 13 }}
                              webStyle={{
                                width: 13,
                                height: 13,
                                marginLeft: -20,
                              }}
                            />
                          </View>
                        </TouchableOpacity>
                      )}
                      <TextInput
                        placeholder={"Search"}
                        placeholderTextColor={Colors.placeholderTextColor}
                        style={[
                          Padding.pl_15,
                          {
                            borderWidth: 1,
                            borderColor: "#907CFF",
                            height: 40,
                            flex: 1,
                            paddingRight: 45,
                            fontSize: 14,
                            fontFamily: FontFamily.regular,
                          },
                        ]}
                        onChangeText={(value) => {
                          this.setState({ searchText: value });
                        }}
                        value={this.state.searchText}
                        onSubmitEditing={this.seachStory}
                      />
                    </Animated.View>
                  ) : (
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ showHeaderSearch: true })
                        }
                        }
                        style={styles.searchCard}>
                        <View>
                          <CustomImage
                            source={searchIcon}
                            style={{
                              width: 15,
                              height: 'auto'
                            }}
                            webStyle={{
                              width: 15,
                              height: 'auto'
                            }}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => {
                        window.location.href = '/about-us'
                      }} style={styles.aboutUsCard}>
                        <Text style={styles.aboutUsText}>ABOUT US</Text>
                      </TouchableOpacity>
                    </View>
                  )}

                  <TouchableOpacity onPress={() => {
                    window.location.href = '/'
                  }}
                    style={[styles.tmcLogoCard, showUser ? { marginRight: '0' } : {}]}
                  >
                    <CustomImage
                      source={tmcLogo}
                      style={{
                        width: 107,
                        height: 36
                      }}
                      webStyle={{
                        width: 107,
                        height: 36
                      }}
                    />

                  </TouchableOpacity>
                  <View
                    style={[
                      Flex.row,
                      helpers.isDesktop() && {
                        justifyContent: "flex-end",
                        columnGap: showUser ? 0 : '2vw',
                        position: 'absolute',
                        right: '2vw'
                      },
                      !helpers.isDesktop() && { width: 50, justifyContent: "flex-end" },
                    ]}
                  >
                    {helpers.isDesktop() ? (
                      <>
                        {((showToken && showLogin) || showSubscribe) &&
                          !this.props.showSubscribeApp && (
                            <View style={[{ alignSelf: 'center', marginRight: showUser ? 20 : null }]}>
                              <ButtonGradient
                                title={"Subscribe"}
                                rootStyle={{
                                  btnWrapper: [button.primaryGradient, { height: 36 }],
                                  btnText: [
                                    button.primaryGradientText,
                                    {
                                      fontSize: 13,
                                      paddingHorizontal: 25,
                                      fontWeight: "500",
                                      fontFamily: FontFamily.regular,
                                      letterSpacing: 0.39,
                                      fontFamily: "HaasGrotesk-Medium"
                                    },
                                  ],
                                }}
                                paddingOverride={true}
                                onClick={() => this.goToSubscribe()}
                                darkMode={darkMode}
                                isNewGradiant={true}
                              />
                            </View>
                          )}
                        {showToken && showLogin && (
                          <Button
                            title={"LOGIN"}
                            rootStyle={{
                              btnWrapper: [
                                darkMode
                                  ? button.primaryOutlineSmallDark
                                  : button.primaryOutlineSmall,
                                { height: 36, cursor: "pointer", alignSelf: 'center' },
                              ],
                              btnText: [
                                darkMode
                                  ? button.primaryOutlineTextSmallDark
                                  : button.primaryOutlineTextSmall,
                                { fontWeight: "500", paddingHorizontal: 15, color: "#1E1B4B", fontSize: 13, letterSpacing: 0.8, fontWeight: 500, fontFamily: "HaasGrotesk-Medium" },
                              ],
                            }}
                            onClick={() => this.login()}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {showToken && showLogin && (
                          <>
                            <TouchableOpacity
                              style={{ alignSelf: "center", marginRight: 10 }}
                              onPress={() => this.toggleSlideMenu(true)}
                              activeOpacity={0.8}
                            >
                              <View
                                style={[
                                  Flex.row,
                                  Flex.alignCenter,
                                  Margin.ml_1,
                                  helpers.isDesktop() && {
                                    borderColor: "#DDDDDD",
                                    borderWidth: 1,
                                    paddingHorizontal: 11,
                                    paddingVertical: 11,
                                  },
                                ]}
                              >
                                <CustomImage
                                  require={true}
                                  source={
                                    darkMode ? userIconFilledWhite : userIconLight
                                  }
                                  webStyle={{
                                    width: helpers.isDesktop()
                                      ? "auto"
                                      : darkMode
                                        ? "auto"
                                        : 21,
                                    height: helpers.isDesktop()
                                      ? 16
                                      : darkMode
                                        ? 18
                                        : "auto",
                                  }}
                                />
                                {helpers.isDesktop() && (
                                  <Text
                                    style={[
                                      Helpers.textUppercase,
                                      Margin.ml_1,
                                      { fontSize: 13, fontFamily: FontFamily.regular },
                                      darkMode && { color: Colors.lightWhite },
                                    ]}
                                  >
                                    {userProfile.name.substring(0, 1)}
                                  </Text>
                                )}
                              </View>
                            </TouchableOpacity>
                            <View style={{ flexDirection: "row" }}>
                              {/* <View style={[Flex.row, Flex.alignCenter, Margin.ml_1, Padding.ph_1, {}]}>
                                            <TouchableOpacity
                                                style={{ padding: 5 }}
                                                onPress={() => this.toggleSlideMenu(true)}
                                                activeOpacity={0.8}>
                                                <CustomImage
                                                    require={true}
                                                    source={darkMode ? searchIcon : searchIcon}
                                                    webStyle={{
                                                        width: helpers.isDesktop() ? 'auto' : (darkMode ? 'auto' : 21),
                                                        height: helpers.isDesktop() ? 16 : (darkMode ? 18 : 'auto')
                                                    }}
                                                    style={[appHeader.menuIcon]} />
                                            </TouchableOpacity>
                                        </View> */}
                              <ButtonGradient
                                title={"SIGN IN"}
                                rootStyle={{
                                  btnWrapper: [button.primaryGradient, { height: 40 }],
                                  btnText: [
                                    button.primaryGradientText,
                                    {
                                      fontSize: 14,
                                      paddingHorizontal: 25,
                                      fontWeight: "500",
                                      fontFamily: FontFamily.regular,
                                    },
                                  ],
                                }}
                                paddingOverride={true}
                                onClick={() => this.login()}
                                darkMode={darkMode}
                                isNewGradiant={true}
                              />
                            </View>
                          </>
                        )}
                      </>
                    )}
                    {showUser && (
                      <View style={{ marginRight: helpers.isDesktop() ? 30 : 15 }}>
                        <TouchableOpacityLink {...btnBookmarkClick}>
                          <View
                            style={[
                              Flex.row,
                              Flex.alignCenter,
                              helpers.isDesktop() && { paddingVertical: 6 },
                              !helpers.isDesktop() && { padding: 4 },
                            ]}
                          >
                            <CustomImage
                              require={true}
                              source={
                                darkMode ? bookmarkMultipleDark : bookmarkMultiple
                              }
                              webStyle={{
                                width: helpers.isDesktop() ? "auto" : 15,
                                height: helpers.isDesktop() ? 28 : "auto",
                              }}
                            />
                            <Animated.View
                              style={{
                                position: "absolute",
                                height: readingValue.interpolate({
                                  inputRange: [0, 60],
                                  outputRange: helpers.isDesktop() ? [1, 70] : [1, 60],
                                }),
                                width: readingValue.interpolate({
                                  inputRange: [0, 60],
                                  outputRange: helpers.isDesktop() ? [1, 70] : [1, 60],
                                }),
                                borderRadius: "50%",
                                backgroundColor: "#C4C4C4",
                                zIndex: -1,
                                left: readingValue.interpolate({
                                  inputRange: [0, 60],
                                  outputRange: helpers.isDesktop()
                                    ? [8, -25]
                                    : [7, -23],
                                }),
                                opacity: readingValue.interpolate({
                                  inputRange: [0, 17, 55, 60],
                                  outputRange: [0, 1, 1, 0],
                                }),
                              }}
                            ></Animated.View>
                          </View>
                        </TouchableOpacityLink>
                      </View>
                    )}

                    {showUser ? (
                      <View>
                        <TouchableOpacity
                          onPress={() => this.openAccounts()}
                          activeOpacity={0.8}
                        >
                          <View
                            style={[
                              Flex.row,
                              Flex.alignCenter,
                              {
                                backgroundColor: "#FC876D",
                                paddingHorizontal: helpers.isDesktop() ? 13 : 8,
                                paddingVertical: helpers.isDesktop() ? 6 : 3.5,
                                borderRadius: 30,
                              },
                            ]}
                          >
                            {/* {!helpers.isDesktop() && <CustomImage
                                            require={true}
                                            source={darkMode ? userIconFilledWhite : userIcon}
                                            webStyle={{
                                                width: helpers.isDesktop() ? 'auto' : 16,
                                                height: helpers.isDesktop() ? 16 : 'auto', marginTop: 1
                                            }} />} */}

                            <Text
                              style={[
                                Helpers.textUppercase,
                                {
                                  fontFamily: FontFamily.abrilFatface,
                                  fontWeight: "400",
                                  color: "#000",
                                  fontSize: helpers.isDesktop() ? 22 : 18,
                                  lineHeight: helpers.isDesktop() ? 29 : 22,
                                },
                              ]}
                            >
                              {userProfile.name.substring(0, 1)}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View style={[{ width: "auto", height: 19 }]}></View>
                    )}
                  </View>
                </View>
                <View style={styles.separator} />
                <View style={styles.headerSubSection}>
                  {
                    HEADER_SUBSECTION_BUTTONS.map((button, idx) => <SubsectionButtonCard {...button} idx={idx} dropdownVisible={this.state.dropdownVisible} toggleDropdown={this.toggleDropdown} />)
                  }
                </View>
                {/* Start of the hamburger menu */}

                {this.state.openSlideMenu && (
                  <Modal
                    animationType="none"
                    transparent={true}
                    visible={true}
                    onRequestClose={() => this.toggleSlideMenu(false)}
                  >
                    <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
                      {process.browser && (
                        <Animated.View
                          style={[
                            appHeader.menuWrapper,
                            helpers.isDesktop() && appHeader.menuWrapperDesktop,
                            {
                              transform: [{ translateX: this.state.slideMenu }],
                              width: helpers.isDesktop() ? 450 : "100%",
                              // maxWidth: 450,
                              maxWidth: helpers.isDesktop() ? 450 : "100%",
                              paddingHorizontal: 0
                            },
                            !helpers.isDesktop() && { position: "fixed" },
                          ]}
                        >
                          <View
                            style={[
                              Flex.row,
                              Flex.justifyBetween,
                              Flex.alignCenter,
                              Padding.pb_2,
                              { paddingHorizontal: 20 }
                            ]}
                          >
                            <View style={[
                              Flex.row,
                              Flex.justifyBetween,
                              Flex.alignCenter,
                            ]}>
                              <CustomImage
                                source={tmcLogo}
                                style={{
                                  width: 143,
                                  height: 48
                                }}
                                webStyle={{
                                  width: 143,
                                  height: 48
                                }}
                              />

                            </View>
                            <TouchableOpacity
                              onPress={() => this.toggleSlideMenu(false)}
                              activeOpacity={0.8}
                              style={{ paddingLeft: 15 }}
                            >
                              <CustomImage
                                source={closeIcon}
                                require={true}
                                webStyle={{ width: 15, height: 15, marginBottom: 3 }}
                                style={{ width: 15, height: 15, marginBottom: 3 }}
                              />
                            </TouchableOpacity>
                          </View>
                          <View
                            style={[Margin.mt_2, Margin.mb_4, { position: "relative", paddingHorizontal: 20 }]}
                          >
                            <TextInput
                              placeholder={"Search"}
                              placeholderTextColor={Colors.placeholderTextColor}
                              style={[
                                Padding.pl_15,
                                {
                                  borderWidth: 1,
                                  borderColor: "#6C7AF6",
                                  height: 40,
                                  width: "100%",
                                  paddingRight: 45,
                                  fontSize: 14,
                                  fontFamily: FontFamily.regular,
                                },
                                false && { color: Colors.lightWhite },
                              ]}
                              onChangeText={(value) =>
                                this.setState({ searchText: value })
                              }
                              value={this.state.searchText}
                              onSubmitEditing={this.seachStory}
                            />
                            <TouchableOpacity onPress={() => this.seachStory()}>
                              <View style={[{ backgroundColor: '#1E1B4B' }]}>
                                <CustomImage
                                  source={searchBox}
                                  require={true}
                                  style={{
                                    width: 45,
                                    height: 40,
                                    position: helpers.isDesktop()
                                      ? "absolute"
                                      : "relative",
                                    right: 0,
                                    top: -46,
                                  }}
                                  webStyle={{
                                    width: 45,
                                    height: 40,
                                    position: "absolute",
                                    right: 0,
                                    top: -40,
                                  }}
                                />
                              </View>
                            </TouchableOpacity>
                          </View>
                          {HEADER_SUBSECTION_BUTTONS &&
                            HEADER_SUBSECTION_BUTTONS.length != 0 &&
                            HEADER_SUBSECTION_BUTTONS.map((menu, index) => {
                              return (
                                <View style={[menu.subSections && { backgroundColor: '#FFF9F1', width: '100%', paddingVertical: 10 }]}>
                                  <TouchableOpacity onPress={index === 0 ? null : () => { window.location.href = menu.link }} style={[index != 0 && { marginTop: 30 }, { paddingHorizontal: 20 }]}>
                                    <View style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                      <Text
                                        style={[
                                          Helpers.textUppercase,
                                          Margin.mb_15,
                                          {
                                            fontFamily: 'HaasGrotesk-Medium',
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(0,0,0,0.3)",
                                            paddingBottom: 8,
                                            fontSize: 18,
                                            fontWeight: "500",
                                            lineHeight: 28,
                                            color: "#292929"
                                          },
                                          !menu.subSections && {
                                            fontFamily: "HaasGrotesk-Medium",
                                            fontSize: 18,
                                            fontWeight: "500",
                                            lineHeight: 28,
                                            color: "#292929",
                                            textTransform: menu.name === "Company-wise Coverage" ? null : 'capitalize',
                                            borderBottomWidth: 0,
                                            paddingBottom: 0,
                                            marginBottom: 5
                                          },
                                          index === 0 && { width: '100%', opacity: 0.6, color: '#1E1B4B', fontFamily: 'HaasGrotesk-Roman', fontSize: 14, fontWeight: 400, letterSpacing: 1 }
                                        ]}
                                      >
                                        {menu.name}
                                      </Text>
                                      {index !== 0 && <CustomImage
                                        source={sliderArrowIcon}
                                        require={true}
                                        style={{ width: 10, height: 10, opacity: 0.5 }}
                                        webStyle={{ width: 10, height: 10, opacity: 0.5 }}
                                        altText={"Slider Arrow"}
                                        resizeMode="contain"
                                      />}
                                    </View>

                                    <View style={[Flex.fill]}>
                                      {menu?.subSections?.map((item, index) => {
                                        let btnClick;
                                        btnClick = {
                                          to:
                                            item.link == "/starter-collection"
                                              ? "/free-reads"
                                              : item.link,
                                        };
                                        if (true ||
                                          (token && item.access == "private") ||
                                          item.access == "public"
                                        ) {
                                          return (
                                            <TouchableOpacityLink
                                              {...btnClick}
                                              key={`itemmenu_${index}`}
                                            >
                                              <View
                                                style={[
                                                  appHeader.menuItem,
                                                  Flex.row,
                                                  Flex.justifyBetween,
                                                  Flex.alignCenter,
                                                ]}
                                              >
                                                <View
                                                  style={[
                                                    Padding.pb_15,
                                                    {
                                                      width: helpers.isDesktop()
                                                        ? 345
                                                        : "100%",
                                                    },
                                                    !helpers.isDesktop() && {
                                                      paddingRight: 20,
                                                    },
                                                  ]}
                                                >
                                                  <Text
                                                    style={[
                                                      appHeader.menuItemText,
                                                      helpers.isDesktop() &&
                                                      appHeader.menuItemTextDesktop,
                                                      {
                                                        textTransform: 'capitalize',
                                                        fontFamily: 'HaasGrotesk-Medium'
                                                      }
                                                    ]}
                                                  >
                                                    {item.name}
                                                  </Text>
                                                  <Text
                                                    style={[
                                                      appHeader.menuItemTextDesc,
                                                      helpers.isDesktop() &&
                                                      appHeader.menuItemTextDescDesktop,
                                                      false && {
                                                        color: Colors.lineColor,
                                                      },
                                                    ]}
                                                  >
                                                    {/* {item.description} */}
                                                  </Text>
                                                </View>
                                                <CustomImage
                                                  source={sliderArrowIcon}
                                                  require={true}
                                                  style={{
                                                    width: 10,
                                                    height: 10,
                                                    marginLeft: helpers.isDesktop()
                                                      ? 0
                                                      : -15,
                                                    opacity: 0.5
                                                  }}
                                                  webStyle={{
                                                    width: 10,
                                                    height: 10,
                                                    marginLeft: helpers.isDesktop()
                                                      ? 0
                                                      : -15,
                                                    opacity: 0.5
                                                  }}
                                                />
                                              </View>
                                            </TouchableOpacityLink>
                                          );
                                        }
                                      })}
                                    </View>
                                  </TouchableOpacity>
                                </View>
                              );
                            })}
                          <View style={[Margin.mt_5, { paddingHorizontal: 20 }]}>
                            <View>
                              <Text
                                style={[
                                  Helpers.textUppercase,
                                  Margin.mb_15,
                                  {
                                    color: "#999999",
                                    fontSize: 14,
                                    lineHeight: 17,
                                    fontFamily: FontFamily.regular,
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(0,0,0,0.3)",
                                    paddingBottom: 8,
                                  },
                                  false && { borderColor: Colors.lineColor1 },
                                ]}
                              >
                                Follow us on
                              </Text>
                              <View style={[Flex.row, Flex.alignCenter]}>
                                <TouchableOpacityLinkExternal
                                  {...btnClickFB}
                                  handleClick={() => { }}
                                  newTab={true}
                                >
                                  <View style={[Flex.alignCenter]}>
                                    <CustomImage
                                      source={Facebook}
                                      require={true}
                                      style={{ width: 23, height: 23 }}
                                      webStyle={{ width: 23, height: 23 }}
                                      altText={"Facebook"}
                                      resizeMode="contain"
                                    />
                                    <Text
                                      style={[
                                        {
                                          color: "rgba(63, 63, 63, 0.8)",
                                          fontSize: 13,
                                          lineHeight: 16,
                                          fontFamily: FontFamily.regular,
                                          marginTop: 10,
                                        },
                                        false && { color: Colors.lineColor },
                                      ]}
                                    >
                                      Facebook
                                    </Text>
                                  </View>
                                </TouchableOpacityLinkExternal>
                                <View
                                  style={[
                                    {
                                      backgroundColor: "rgba(0, 0, 0, 0.25)",
                                      height: 58,
                                      width: 0.5,
                                      marginHorizontal: helpers.isDesktop() ? 28 : "6%",
                                    },
                                    false && { backgroundColor: Colors.lineColor1 },
                                  ]}
                                />
                                <TouchableOpacityLinkExternal
                                  {...btnClickTwitter}
                                  handleClick={() => { }}
                                  newTab={true}
                                >
                                  <View style={[Flex.alignCenter]}>
                                    <CustomImage
                                      source={Twitter}
                                      require={true}
                                      style={{ width: 30, height: 21 }}
                                      webStyle={{ width: 30, height: 21 }}
                                      altText={"Twitter"}
                                      resizeMode="contain"
                                    />
                                    <Text
                                      style={[
                                        {
                                          color: "rgba(63, 63, 63, 0.8)",
                                          fontSize: 13,
                                          lineHeight: 16,
                                          fontFamily: FontFamily.regular,
                                          marginTop: 10,
                                        },
                                        false && { color: Colors.lineColor },
                                      ]}
                                    >
                                      Twitter
                                    </Text>
                                  </View>
                                </TouchableOpacityLinkExternal>
                                <View
                                  style={[
                                    {
                                      backgroundColor: "rgba(0, 0, 0, 0.25)",
                                      height: 58,
                                      width: 0.5,
                                      marginHorizontal: helpers.isDesktop() ? 28 : "6%",
                                    },
                                    false && { backgroundColor: Colors.lineColor1 },
                                  ]}
                                />
                                <TouchableOpacityLinkExternal
                                  {...btnClickLinkdin}
                                  handleClick={() => { }}
                                  newTab={true}
                                >
                                  <View style={[Flex.alignCenter]}>
                                    <CustomImage
                                      source={Linkedin}
                                      require={true}
                                      style={{ width: 23, height: 23 }}
                                      webStyle={{ width: 23, height: 23 }}
                                      altText={"Linkedin"}
                                      resizeMode="contain"
                                    />
                                    <Text
                                      style={[
                                        {
                                          color: "rgba(63, 63, 63, 0.8)",
                                          fontSize: 13,
                                          lineHeight: 16,
                                          fontFamily: FontFamily.regular,
                                          marginTop: 10,
                                        },
                                        false && { color: Colors.lineColor },
                                      ]}
                                    >
                                      Linkedin
                                    </Text>
                                  </View>
                                </TouchableOpacityLinkExternal>
                                <View
                                  style={[
                                    {
                                      backgroundColor: "rgba(0, 0, 0, 0.25)",
                                      height: 58,
                                      width: 0.5,
                                      // marginHorizontal: 28,
                                      marginHorizontal: helpers.isDesktop() ? 28 : "6%",
                                    },
                                    false && { backgroundColor: Colors.lineColor1 },
                                  ]}
                                />
                                <TouchableOpacityLinkExternal
                                  {...btnClickWhatsApp}
                                  handleClick={() => { }}
                                  newTab={true}
                                >
                                  <View style={[Flex.alignCenter]}>
                                    <CustomImage
                                      source={WhatsApp}
                                      require={true}
                                      style={{ width: 23, height: 23 }}
                                      webStyle={{ width: 23, height: 23 }}
                                      altText={"WhatsApp"}
                                      resizeMode="contain"
                                    />
                                    <Text
                                      style={[
                                        {
                                          color: "rgba(63, 63, 63, 0.8)",
                                          fontSize: 13,
                                          lineHeight: 16,
                                          fontFamily: FontFamily.regular,
                                          marginTop: 10,
                                        },
                                        false && { color: Colors.lineColor },
                                      ]}
                                    >
                                      WhatsApp
                                    </Text>
                                  </View>
                                </TouchableOpacityLinkExternal>
                              </View>
                            </View>
                            {/* <Text style={[Margin.mt_5, { color: '#3F3F3F', fontSize: 18, fontFamily: FontFamily.regular, lineHeight: 22 }, false && { color: Colors.lightWhite }]}>©{copyright} Slowform Holdings Limited </Text>
                            <Text style={[appHeader.text, { color: '#6A6A6A' }, false && { color: Colors.lineColor }]}>68 Circular Road, #02-01, 049422, Singapore</Text> */}

                            {/* <View style={[Flex.row, Margin.mt_3, Margin.mb_1, Flex.alignCenter]}>
                            <TouchableOpacityLink {...btnClickTerms}><Text style={[appHeader.text, { color: 'rgba(63, 63, 63, 0.8)', textDecorationLine: 'underline' }]}>Terms of service</Text></TouchableOpacityLink>
                            <View style={[appHeader.dot]}></View>
                            <TouchableOpacityLink {...btnClickPrivacy}><Text style={[appHeader.text, { color: 'rgba(63, 63, 63, 0.8)', textDecorationLine: 'underline' }]}>Privacy policy</Text></TouchableOpacityLink>
                        </View> */}
                          </View>
                        </Animated.View>
                      )}
                    </TouchableWithoutFeedback>
                  </Modal>
                )}

                {/* End of the hamburger menu */}
              </View>
            </TouchableWithoutFeedback>
            :
            <>
            </>
        }

        {!helpers.isDesktop() && this.state.openSlideMenu && (
          <Modal
            animationType="none"
            transparent={true}
            visible={true}
            onRequestClose={() => this.toggleSlideMenu(false)}
          >
            <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
              {process.browser && (
                <Animated.View
                  style={[
                    appHeader.menuWrapper,
                    helpers.isDesktop() && appHeader.menuWrapperDesktop,
                    {
                      transform: [{ translateX: this.state.slideMenu }],
                      width: helpers.isDesktop() ? 450 : "100%",
                      // maxWidth: 450,
                      maxWidth: helpers.isDesktop() ? 450 : "100%",
                      paddingHorizontal: 0
                    },
                    !helpers.isDesktop() && { position: "fixed" },
                  ]}
                >
                  <View
                    style={[
                      Flex.row,
                      Flex.justifyBetween,
                      Flex.alignCenter,
                      Padding.pb_2,
                      { paddingHorizontal: 20 }
                    ]}
                  >
                    <View style={[
                      Flex.row,
                      Flex.justifyBetween,
                      Flex.alignCenter,
                    ]}>
                      <CustomImage
                        source={tmcLogo}
                        style={{
                          width: 143,
                          height: 48
                        }}
                        webStyle={{
                          width: 143,
                          height: 48
                        }}
                      />
                    </View>
                    <TouchableOpacity
                      onPress={() => this.toggleSlideMenu(false)}
                      activeOpacity={0.8}
                      style={{ paddingLeft: 15 }}
                    >
                      <CustomImage
                        source={closeIcon}
                        require={true}
                        webStyle={{ width: 15, height: 15, marginBottom: 3 }}
                        style={{ width: 15, height: 15, marginBottom: 3 }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={[Margin.mt_2, Margin.mb_4, { position: "relative", paddingHorizontal: 20 }]}
                  >
                    <TextInput
                      placeholder={"Search"}
                      placeholderTextColor={Colors.placeholderTextColor}
                      style={[
                        Padding.pl_15,
                        {
                          borderWidth: 1,
                          borderColor: "#6C7AF6",
                          height: 40,
                          width: "100%",
                          paddingRight: 45,
                          fontSize: 14,
                          fontFamily: FontFamily.regular,
                        },
                        false && { color: Colors.lightWhite },
                      ]}
                      onChangeText={(value) =>
                        this.setState({ searchText: value })
                      }
                      value={this.state.searchText}
                      onSubmitEditing={this.seachStory}
                    />
                    <TouchableOpacity onPress={() => this.seachStory()}>
                      <View style={[{ backgroundColor: '#1E1B4B' }]}>
                        <CustomImage
                          source={searchBox}
                          require={true}
                          style={{
                            width: 45,
                            height: 40,
                            position: helpers.isDesktop()
                              ? "absolute"
                              : "relative",
                            right: 0,
                            top: -46,
                          }}
                          webStyle={{
                            width: 45,
                            height: 40,
                            position: "absolute",
                            right: 0,
                            top: -40,
                          }}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                  {HEADER_SUBSECTION_BUTTONS &&
                    HEADER_SUBSECTION_BUTTONS.length != 0 &&
                    HEADER_SUBSECTION_BUTTONS.map((menu, index) => {
                      return (
                        <View style={[menu.subSections && { backgroundColor: '#FFF9F1', width: '100%', paddingVertical: 10 }]}>
                          <TouchableOpacity onPress={index === 0 ? null : () => { window.location.href = menu.link }} style={[index != 0 && { marginTop: 30 }, { paddingHorizontal: 20 }]}>
                            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Text
                                style={[
                                  Helpers.textUppercase,
                                  Margin.mb_15,
                                  {
                                    fontFamily: 'HaasGrotesk-Medium',
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(0,0,0,0.3)",
                                    paddingBottom: 8,
                                    fontSize: 18,
                                    fontWeight: "500",
                                    lineHeight: 28,
                                    color: "#292929"
                                  },
                                  !menu.subSections && {
                                    fontFamily: "HaasGrotesk-Medium",
                                    fontSize: 18,
                                    fontWeight: "500",
                                    lineHeight: 28,
                                    color: "#292929",
                                    textTransform: menu.name === "Company-wise Coverage" ? null : 'capitalize',
                                    borderBottomWidth: 0,
                                    paddingBottom: 0,
                                    marginBottom: 5
                                  },
                                  index === 0 && { width: '100%', opacity: 0.6, color: '#1E1B4B', fontFamily: 'HaasGrotesk-Roman', fontSize: 14, fontWeight: 400, letterSpacing: 1  }
                                ]}
                              >
                                {menu.name}
                              </Text>
                              {index !== 0 && <CustomImage
                                source={sliderArrowIcon}
                                require={true}
                                style={{ width: 10, height: 10, opacity: 0.5 }}
                                webStyle={{ width: 10, height: 10, opacity: 0.5 }}
                                altText={"Slider Arrow"}
                                resizeMode="contain"
                              />}
                            </View>

                            <View style={[Flex.fill]}>
                              {menu?.subSections?.map((item, index) => {
                                let btnClick;
                                btnClick = {
                                  to:
                                    item.link == "/starter-collection"
                                      ? "/free-reads"
                                      : item.link,
                                };
                                if (true ||
                                  (token && item.access == "private") ||
                                  item.access == "public"
                                ) {
                                  return (
                                    <TouchableOpacityLink
                                      {...btnClick}
                                      key={`itemmenu_${index}`}
                                    >
                                      <View
                                        style={[
                                          appHeader.menuItem,
                                          Flex.row,
                                          Flex.justifyBetween,
                                          Flex.alignCenter,
                                        ]}
                                      >
                                        <View
                                          style={[
                                            Padding.pb_15,
                                            {
                                              width: helpers.isDesktop()
                                                ? 345
                                                : "100%",
                                            },
                                            !helpers.isDesktop() && {
                                              paddingRight: 20,
                                            },
                                          ]}
                                        >
                                          <Text
                                            style={[
                                              appHeader.menuItemText,
                                              helpers.isDesktop() &&
                                              appHeader.menuItemTextDesktop,
                                              {
                                                textTransform: 'capitalize',
                                                fontFamily: 'HaasGrotesk-Medium'
                                              }
                                            ]}
                                          >
                                            {item.name}
                                          </Text>
                                          <Text
                                            style={[
                                              appHeader.menuItemTextDesc,
                                              helpers.isDesktop() &&
                                              appHeader.menuItemTextDescDesktop,
                                              false && {
                                                color: Colors.lineColor,
                                              },
                                            ]}
                                          >
                                            {/* {item.description} */}
                                          </Text>
                                        </View>
                                        <CustomImage
                                          source={sliderArrowIcon}
                                          require={true}
                                          style={{
                                            width: 10,
                                            height: 10,
                                            marginLeft: helpers.isDesktop()
                                              ? 0
                                              : -15,
                                            opacity: 0.5
                                          }}
                                          webStyle={{
                                            width: 10,
                                            height: 10,
                                            marginLeft: helpers.isDesktop()
                                              ? 0
                                              : -15,
                                            opacity: 0.5
                                          }}
                                        />
                                      </View>
                                    </TouchableOpacityLink>
                                  );
                                }
                              })}
                            </View>
                          </TouchableOpacity>
                        </View>
                      );
                    })}
                  <View style={[Margin.mt_5, { paddingHorizontal: 20 }]}>
                    <View>
                      <Text
                        style={[
                          Helpers.textUppercase,
                          Margin.mb_15,
                          {
                            color: "#999999",
                            fontSize: 14,
                            lineHeight: 17,
                            fontFamily: FontFamily.regular,
                            borderBottomWidth: 1,
                            borderColor: "rgba(0,0,0,0.3)",
                            paddingBottom: 8,
                          },
                          false && { borderColor: Colors.lineColor1 },
                        ]}
                      >
                        Follow us on
                      </Text>
                      <View style={[Flex.row, Flex.alignCenter]}>
                        <TouchableOpacityLinkExternal
                          {...btnClickFB}
                          handleClick={() => { }}
                          newTab={true}
                        >
                          <View style={[Flex.alignCenter]}>
                            <CustomImage
                              source={Facebook}
                              require={true}
                              style={{ width: 23, height: 23 }}
                              webStyle={{ width: 23, height: 23 }}
                              altText={"Facebook"}
                              resizeMode="contain"
                            />
                            <Text
                              style={[
                                {
                                  color: "rgba(63, 63, 63, 0.8)",
                                  fontSize: 13,
                                  lineHeight: 16,
                                  fontFamily: FontFamily.regular,
                                  marginTop: 10,
                                },
                                false && { color: Colors.lineColor },
                              ]}
                            >
                              Facebook
                            </Text>
                          </View>
                        </TouchableOpacityLinkExternal>
                        <View
                          style={[
                            {
                              backgroundColor: "rgba(0, 0, 0, 0.25)",
                              height: 58,
                              width: 0.5,
                              marginHorizontal: helpers.isDesktop() ? 28 : "6%",
                            },
                            false && { backgroundColor: Colors.lineColor1 },
                          ]}
                        />
                        <TouchableOpacityLinkExternal
                          {...btnClickTwitter}
                          handleClick={() => { }}
                          newTab={true}
                        >
                          <View style={[Flex.alignCenter]}>
                            <CustomImage
                              source={Twitter}
                              require={true}
                              style={{ width: 30, height: 21 }}
                              webStyle={{ width: 30, height: 21 }}
                              altText={"Twitter"}
                              resizeMode="contain"
                            />
                            <Text
                              style={[
                                {
                                  color: "rgba(63, 63, 63, 0.8)",
                                  fontSize: 13,
                                  lineHeight: 16,
                                  fontFamily: FontFamily.regular,
                                  marginTop: 10,
                                },
                                false && { color: Colors.lineColor },
                              ]}
                            >
                              Twitter
                            </Text>
                          </View>
                        </TouchableOpacityLinkExternal>
                        <View
                          style={[
                            {
                              backgroundColor: "rgba(0, 0, 0, 0.25)",
                              height: 58,
                              width: 0.5,
                              marginHorizontal: helpers.isDesktop() ? 28 : "6%",
                            },
                            false && { backgroundColor: Colors.lineColor1 },
                          ]}
                        />
                        <TouchableOpacityLinkExternal
                          {...btnClickLinkdin}
                          handleClick={() => { }}
                          newTab={true}
                        >
                          <View style={[Flex.alignCenter]}>
                            <CustomImage
                              source={Linkedin}
                              require={true}
                              style={{ width: 23, height: 23 }}
                              webStyle={{ width: 23, height: 23 }}
                              altText={"Linkedin"}
                              resizeMode="contain"
                            />
                            <Text
                              style={[
                                {
                                  color: "rgba(63, 63, 63, 0.8)",
                                  fontSize: 13,
                                  lineHeight: 16,
                                  fontFamily: FontFamily.regular,
                                  marginTop: 10,
                                },
                                false && { color: Colors.lineColor },
                              ]}
                            >
                              Linkedin
                            </Text>
                          </View>
                        </TouchableOpacityLinkExternal>
                        <View
                          style={[
                            {
                              backgroundColor: "rgba(0, 0, 0, 0.25)",
                              height: 58,
                              width: 0.5,
                              // marginHorizontal: 28,
                              marginHorizontal: helpers.isDesktop() ? 28 : "6%",
                            },
                            false && { backgroundColor: Colors.lineColor1 },
                          ]}
                        />
                        <TouchableOpacityLinkExternal
                          {...btnClickWhatsApp}
                          handleClick={() => { }}
                          newTab={true}
                        >
                          <View style={[Flex.alignCenter]}>
                            <CustomImage
                              source={WhatsApp}
                              require={true}
                              style={{ width: 23, height: 23 }}
                              webStyle={{ width: 23, height: 23 }}
                              altText={"WhatsApp"}
                              resizeMode="contain"
                            />
                            <Text
                              style={[
                                {
                                  color: "rgba(63, 63, 63, 0.8)",
                                  fontSize: 13,
                                  lineHeight: 16,
                                  fontFamily: FontFamily.regular,
                                  marginTop: 10,
                                },
                                false && { color: Colors.lineColor },
                              ]}
                            >
                              WhatsApp
                            </Text>
                          </View>
                        </TouchableOpacityLinkExternal>
                      </View>
                    </View>
                    {/* <Text style={[Margin.mt_5, { color: '#3F3F3F', fontSize: 18, fontFamily: FontFamily.regular, lineHeight: 22 }, false && { color: Colors.lightWhite }]}>©{copyright} Slowform Holdings Limited </Text>
                            <Text style={[appHeader.text, { color: '#6A6A6A' }, false && { color: Colors.lineColor }]}>68 Circular Road, #02-01, 049422, Singapore</Text> */}

                    {/* <View style={[Flex.row, Margin.mt_3, Margin.mb_1, Flex.alignCenter]}>
                            <TouchableOpacityLink {...btnClickTerms}><Text style={[appHeader.text, { color: 'rgba(63, 63, 63, 0.8)', textDecorationLine: 'underline' }]}>Terms of service</Text></TouchableOpacityLink>
                            <View style={[appHeader.dot]}></View>
                            <TouchableOpacityLink {...btnClickPrivacy}><Text style={[appHeader.text, { color: 'rgba(63, 63, 63, 0.8)', textDecorationLine: 'underline' }]}>Privacy policy</Text></TouchableOpacityLink>
                        </View> */}
                  </View>
                </Animated.View>
              )}
            </TouchableWithoutFeedback>
          </Modal>
        )}



        {documentStatus == "approved" && (
          <Animated.View
            style={[
              { opacity: showStatus },
              appHeader.paymentSuccess,
              helpers.isDesktop() && appHeader.paymentSuccessDesktop,
              Flex.row,
              Flex.justifyCenter,
              Flex.alignCenter,
              {
                position: "fixed",
                left: 0,
                right: 0,
                top: helpers.isDesktop() ? 66 : 54,
                zIndex: 2,
              },
            ]}
          >
            <View
              style={[
                !helpers.isDesktop && Flex.fill,
                { flexDirection: "row", alignItems: "center" },
                !helpers.isDesktop() && { width: "100%" },
              ]}
            >
              <CustomImage
                source={whiteTick}
                require={true}
                style={{
                  width: 24,
                  height: 24,
                  objectFit: "contain",
                  marginRight: 10,
                }}
                webStyle={{
                  width: 24,
                  height: 24,
                  objectFit: "contain",
                  marginRight: 10,
                }}
              />

              <View
                style={[
                  Flex.fill,
                  {
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    flexWrap: "wrap",
                  },
                ]}
              >
                <TouchableOpacity
                  onPress={() => this.openStudentModal()}
                  style={{}}
                  activeOpacity={1}
                >
                  <Text
                    style={[
                      appHeader.msgText,
                      helpers.isDesktop() && appHeader.msgTextDesktop,
                      { fontWeight: "700", fontFamily: FontFamily.bold },
                    ]}
                  >
                    Your student identity documents has been verified
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => this.closeStudentModal()}
              style={{
                position: helpers.isDesktop() ? "absolute" : "absolute",
                right: 18,
              }}
            >
              <CustomImage
                source={closeMsgIcon}
                require={true}
                style={{ width: 14, height: 14 }}
                webStyle={{ width: 14, height: 14 }}
              />
            </TouchableOpacity>
          </Animated.View>
        )}

        {documentStatus == "submitted" && (
          <TouchableOpacity
            onPress={() => this.openStudentModal()}
            activeOpacity={1}
            style={{ position: "relative", zIndex: 1 }}
          >
            <Animated.View
              style={[
                { opacity: showStatus },
                appHeader.paymentFailure,
                helpers.isDesktop() && appHeader.paymentFailureDesktop,
                Flex.row,
                Flex.justifyCenter,
                Flex.alignCenter,
                Flex.fill,
                {
                  position: "fixed",
                  left: 0,
                  right: 0,
                  top: helpers.isDesktop() ? 66 : 54,
                  zIndex: 2,
                },
              ]}
            >
              <View style={[Flex.fill]}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <CustomImage
                    source={whiteClock}
                    require={true}
                    style={{
                      width: 24,
                      height: 24,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    webStyle={{
                      width: 24,
                      height: 24,
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                  />
                  <View
                    style={[
                      Flex.fill,
                      {
                        flexDirection: helpers.isDesktop() ? "row" : "column",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        appHeader.msgText,
                        helpers.isDesktop() && appHeader.msgTextDesktop,
                        { fontWeight: "700", fontFamily: FontFamily.bold },
                      ]}
                    >
                      Your student identity documents are under verification
                    </Text>
                  </View>
                </View>
              </View>
            </Animated.View>
          </TouchableOpacity>
        )}

        {showGiftSuccess && (
          <Animated.View
            style={[
              { opacity: showStatus },
              appHeader.paymentSuccess,
              helpers.isDesktop() && appHeader.paymentSuccessDesktop,
              Flex.row,
              Flex.justifyCenter,
              Flex.alignCenter,
              {
                position: "fixed",
                left: 0,
                right: 0,
                top: helpers.isDesktop() ? 66 : 54,
                zIndex: 2,
                paddingBottom: 12,
                paddingTop: 12,
              },
            ]}
          >
            <View
              style={[
                !helpers.isDesktop && Flex.fill,
                { flexDirection: "row", alignItems: "center" },
                !helpers.isDesktop() && { width: "100%" },
              ]}
            >
              <CustomImage
                source={whiteTick}
                require={true}
                style={{
                  width: 24,
                  height: 24,
                  objectFit: "contain",
                  marginRight: 10,
                }}
                webStyle={{
                  width: 24,
                  height: 24,
                  objectFit: "contain",
                  marginRight: 10,
                }}
              />

              <View
                style={[
                  Flex.fill,
                  {
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    flexWrap: "wrap",
                  },
                ]}
              >
                {/* <Text style={[appHeader.alertMsg, !helpers.isDesktop() && { fontSize: 16 }]}>Your gift was sent to {giftReceiver}</Text> */}
                <Text
                  style={[
                    appHeader.msgText,
                    helpers.isDesktop() && appHeader.msgTextDesktop,
                    {
                      fontWeight: "700",
                      fontFamily: FontFamily.bold,
                      textDecorationLine: "none",
                      position: "relative",
                      top: 2,
                    },
                  ]}
                >
                  Your gift was sent to {giftReceiver}
                </Text>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => this.closeGiftSuccessBox()}
              style={{
                position: helpers.isDesktop() ? "absolute" : "absolute",
                right: 18,
              }}
            >
              <CustomImage
                source={closeMsgIcon}
                require={true}
                style={{ width: 14, height: 14 }}
                webStyle={{ width: 14, height: 14 }}
              />
            </TouchableOpacity>
          </Animated.View>
        )}
        {/*  code will be uncooment when we add band feature to paid User */}
        {/* { (country && country.code == 'IN') ?
                token ? userProfile ?
                <PlanAlert 
                    showPlanAlertBox={userProfile?.subscription?.plan?.id == 'anniversary-special-plan-inr'||userProfile?.subscription?.plan?.id == 'anniversary-special-plan-4' ?false:true} 
                    title={userProfile?.subscription?.subscription_id? "Upgrade":"Subscribe"}
                    userId={userProfile?.reference_id?userProfile?.reference_id:0}
                    />:
                    '':
                    <PlanAlert 
                    showPlanAlertBox={userProfile?.subscription?.plan?.id == 'anniversary-special-plan-inr'||userProfile?.subscription?.plan?.id == 'anniversary-special-plan-4' ?false:true} 
                    title={userProfile?.subscription?.subscription_id? "Upgrade":"Subscribe"}
                    userId={userProfile?.reference_id ?userProfile?.reference_id:0}
                    />
                :""}                 */}

        <View>
          {this.state.bandDetails && this.getAndShowTheNotificationBand(
            token,
            isOverseasUser,
            userInfoFetched,
            subscriptionDetails,
            freeUser,
            expiredSubscription,
            credits,
            locationInfo,
            currentPath
          )}
          {token &&
            freeUser &&
            currentPath == "home" &&
            this.renderHomeBannerSection()}
        </View>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { removeUserToken, setUserStatus, saveDarkMode, darkModeToggle },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AuthenticationWrapper(AppHeader));