import { chargebeeInstance__Interface, chargebeeInstanceConfig, BillingInfo } from './interface'
import { ChargebeeSiteConfig } from '../site'
import apiHelpers from '../../api-helpers'
import { CHARGEBEE_SETTINGS } from '../settings'
import { HTTP_Fetch } from '../api_fetch'
import { helpers } from '../helper'
declare const window;

/**
 * The Chargebee module available from the chargebee.js script.
 */
export declare const Chargebee;

/**
 * The abstract which implements {@link chargebeeInstance__Interface } interface.
 */
export abstract class ChargebeeInstance implements chargebeeInstance__Interface {
    siteConfig?: ChargebeeSiteConfig
    instance?: any
    tmcAuthToken?: any
    billingInfo?: BillingInfo
    constructor(){
    }
    useToken = (token: any) => {
        this.tmcAuthToken = token
        return this
    }
    /**
     * 
     * @param cb_site - The name(string) of the Chargebee Site 
     * @returns {@link ChargebeeSiteConfig} object
     */
    getConfigFromSite = (cb_site: string): ChargebeeSiteConfig => {
        switch(cb_site){
            case CHARGEBEE_SETTINGS.CB_SITE_IN: {
                let config = {
                    site: CHARGEBEE_SETTINGS.CB_SITE_IN,
                    siteKey: CHARGEBEE_SETTINGS.CB_SITE_IN_API_KEY,
                    publishableKey: CHARGEBEE_SETTINGS.CB_SITE_IN_API_KEY,
                    domain: CHARGEBEE_SETTINGS.CB_SITE_IN_DOMAIN
                }
                return config
            }
            case CHARGEBEE_SETTINGS.CB_SITE_OT: {
                let config = {
                    site: CHARGEBEE_SETTINGS.CB_SITE_OT,
                    siteKey: CHARGEBEE_SETTINGS.CB_SITE_OT_API_KEY,
                    publishableKey: CHARGEBEE_SETTINGS.CB_SITE_OT_API_KEY,
                    domain: CHARGEBEE_SETTINGS.CB_SITE_OT_DOMAIN
                }
                return config
            }
        }

        throw new Error(`No site exists with :${cb_site}`)
    }

    /**
     * 
     * @param cb_platform - The string used by the application code to represent the actual Chargebee Site
     * @returns {@link ChargebeeSiteConfig}
     */

    getConfigFromPlatform = (cb_platform: string): ChargebeeSiteConfig => {
        switch(cb_platform){
            case CHARGEBEE_SETTINGS.CB_BILLING_PATFORM_IN: {
                return this.getConfigFromSite(CHARGEBEE_SETTINGS.CB_SITE_IN)

            }
            case CHARGEBEE_SETTINGS.CB_BILLING_PATFORM_OT: {
                return this.getConfigFromSite(CHARGEBEE_SETTINGS.CB_SITE_OT)
            }
        }

        throw new Error(`No site exists with :${cb_platform}`)
    }

    /**
     * This method is a wrapper of an HTTP fetch which gives Chargebee configurations specific to the user.
     * @returns A `Promise` which resolves to an object containing Chargebee Site config
     */

    getBillingPlatform = (): Promise<any> => {
        if(!this.tmcAuthToken){
            throw new Error("Error: ChargebeeInstance:getBillingPlatform: auth token is required for init")
        }
        return HTTP_Fetch(CHARGEBEE_SETTINGS.CB_ACCOUNT_SITE_ENDPOINT, {}, 'POST', true, this.tmcAuthToken)
    }

    /**
     * @returns A `Promise` which resolves to an object containing Chargebee Site config
     */
    getInstanceConfig = (): Promise<ChargebeeSiteConfig> => {
        if(!this.tmcAuthToken){
            throw new Error("Error: ChargebeeInstance:getInstanceConfig: auth token is required for init")
        }

        return new Promise((resolve, reject) => {
            this.getBillingPlatform().then(res => {
                if(!res.success){
                    reject('chargebee: failed instance config')
                    return false;
                }
                this.billingInfo = {
                    billing_platform: res.data.billing_platform,
                    billing_platform_user_id: res.data.billing_platform_user_id
                }
                const billing_config = this.getConfigFromPlatform(this.billingInfo.billing_platform)

                if(!helpers.isDesktop()){
                    billing_config.enableRedirectMode = true
                }

                resolve(billing_config)
                return true
            }).catch(err => {
                reject(err);
            })
        })
    }


    init = (token: any): Promise<this> => {

        if(!token){
            throw new Error("Error: ChargebeeInstance:init: auth token is required for init")
        }
        this.tmcAuthToken = token

        return new Promise((resolve, reject) => {
            this.getInstanceConfig().then(config => {
                this.siteConfig = config
                this.instance = Chargebee.init(this.siteConfig)
                this.instance = Chargebee.getInstance()
                window.chargebeeInstance = this.instance
                return resolve(this)
            }).catch(err => {
                reject(err);
            })
        })
    }

}
