import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  ScrollView,
  PermissionsAndroid,
  Platform,
  TextInput,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Colors,
  FontFamily,
  FontWeight,
  button,
  modalStyle,
  Typo,
  formStyle,
  unlockBox,
} from "../../styles/appStyles";
import Modal from "../modal";
import UploadFile from "../upload-file";
import FileCard from "../../components/file-card";
import { helpers } from "../../utils/helpers";
import docHelpers from "../../utils/download-doc";
import ButtonGradient from "../../components/button-gradient";
import apiHelpers from "../../utils/api-helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import { ProgressCircle } from "react-native-material-indicators";
import ImageViewer from "../image-viewer";
import PdfViewer from "../pdf-viewer";
import CustomCamera from "../custom-camera";
import ContentLoader from "react-native-easy-content-loader";

const windowHeight = Dimensions.get("window").height;

const closeIcon = getImagePath("icons/close-round.png");

const themeIconOne = getImagePath("icons/theme_icon_one.png");
const themeIconTwo = getImagePath("icons/theme_icon_two.png");
const dummyEmail = getImagePath("icons/dummyEmail.png");

const emailBanner = getImagePath("icons/giftEmailBanner.png");
const emailQuote = getImagePath("icons/emailQuote.png");
const emailQuoteSecond = getImagePath("icons/emailQuoteSecond.png");
const annualGiftSub = getImagePath("icons/annualGiftSub.png");
const creditGiftIcon = getImagePath("icons/gift_pl_one.png");

const maxMessageLength = 500;

class GiftCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: "",
      btnDisabled: true,
      enableLoader: true,
      emailThemes: [],
      selectedTheme: {
        "id": "gifting",
        "thumbnail": "https://tmcassets.ajency.in/img/extras/gifting_thumbnail.png",
        "image_url": "https://tmcassets.ajency.in/img/extras/gifting_banner.png"
    },
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      giftMsgErrorMsg: false,
    };
  }

  componentDidMount = () => {
    this.getEmailThemes();
  };

  componentDidUpdate = () => {};

  componentWillUnmount() {}

  getEmailThemes = () => {
    let endpoint = `/get-email-themes`;
    let { subscriptionData } = this.props;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, false)
      .then((res) => {
        if (res.success && res.code == "email themes") {
          let selectedTheme = res.data.themes[0];
          if (
            subscriptionData.selectedSubscribtion.is_story_gift &&
            res.data.story_themes
          ) {
            selectedTheme = res.data.story_themes.find((theme) => {
              return theme.id == subscriptionData.story?.category?.slug;
            });
            if(!selectedTheme){
              selectedTheme = res.data.themes.find((theme)=>{
                return theme.id == 'gifting'
              })
            }
          }
          this.setState({
            emailThemes: res.data.themes,
            storyThemes: res.data.story_themes,
            selectedTheme,
            enableLoader: false,
          });
        } else {
          console.log("Error=>>", res);
          this.setState({ emailThemes: [], enableLoader: false });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({ emailThemes: [], enableLoader: false });
      });
  };

  closeModal = () => {
    this.props.closeGiftCardModal();
  };

  closeModalNativeModal = () => {};

  setEmailTheme = (theme) => {
    this.setState({ selectedTheme: theme });
  };

  handleOnChange = (key, value) => {
    if (key == "message" && value.length > maxMessageLength) {
      this.setState({
        giftMsgErrorMsg: "Cannot enter a message over 500 characters",
      });
      return;
    }
    this.setState({ [key]: value, errorMsg: false, giftMsgErrorMsg: false });
  };

  validateForm = () => {
    let { email } = this.state;
    if (!helpers.validateEmail(email)) {
      this.setState({ errorMsg: "Please enter valid Email Id" });
      return;
    }

    this.validateGift();
  };

  validateGift = () => {
    this.setState({ enableBtnLoader: true });

    let { email, firstName, lastName, message, selectedTheme } = this.state;
    let { token } = this.props;
    let { subscriptionData } = this.props;
    let endpoint = `/user-check-gift`;
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      message: message,
      theme_id: selectedTheme.id,
      gift_id: subscriptionData.subscribtionID,
      tmc_group: subscriptionData.selectedSubscribtion.tmc_group,
    };

    if (
      subscriptionData.selectedSubscribtion.is_free_privilege_credits ||
      subscriptionData.selectedSubscribtion.is_story_gift
    ) {
      endpoint = "/user-gift-free-credits";
      data.gift_credits = subscriptionData.giftCredits;
    }
    let author = null
    if (subscriptionData.selectedSubscribtion.is_story_gift) {
      author = subscriptionData.story.author?.length ? subscriptionData.story.author.map((auth) => {
        return auth.name;
      }) : 
      subscriptionData.story.authors.map((auth) => {
        return auth.name;
      })
      data.story = {
        id: subscriptionData.story?.story?.id || subscriptionData.story.id,
        slug: subscriptionData.story?.story?.slug || subscriptionData.story.slug,
        title: subscriptionData.story?.story?.title || subscriptionData.story.title,
        featured_image: subscriptionData?.story?.featured_image?.url || subscriptionData?.story?.featured_image,
        author: author,
        category: subscriptionData.story?.category?.slug || 'yesterday',
        date: subscriptionData.story?.story?.published_date || subscriptionData.story.published_date,
      };
    }
    apiHelpers
      .apiRequest(endpoint, data, "POST", true, token)
      .then((res) => {
        if (res.success && res.code == "credits_gifted") {
          this.props.setGiftSuccess(data);
          this.props.closeGiftCardModal();
          try {
            console.log("gifting story");
            if (data.story?.title) {
              window.webengage.track("Story Gifted", {
                "Title of story": data.story.title,
                Email: data.email,
              });
            }
            console.log({
              "Title of story": data.story.title,
              Email: data.email,
            });
          } catch (error) {}
        } else if (res.success && res.code == "can_gift") {
          this.props.showSubscriptionPlan(
            subscriptionData.subscribtionID,
            subscriptionData.selectedSubscribtion,
            subscriptionData.country,
            subscriptionData.email,
            1,
            data
          );
          this.props.closeGiftCardModal();
        } else {
          this.setState({ enableBtnLoader: false, errorMsg: res.message });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          enableBtnLoader: false,
          errorMsg: "Something unexpected happend. Please try again",
        });
      });
  };

  renderDummyLoader = () => {
    let darkMode = this.props.darkMode;
    return (
      <>
        <View style={{ paddingHorizontal: 10, marginBottom: 20 }}>
          <View
            style={{
              height: 10,
              width: "100%",
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: "100%",
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: 150,
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 0,
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: darkMode ? "#434343" : "#f5f5f5",
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: "row",
          }}
        >
          <View style={{ width: "33%", alignItems: "center" }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 8,
              }}
            ></View>
            <View
              style={{
                height: 12,
                width: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 0,
              }}
            ></View>
          </View>
          <View style={{ width: "33%", alignItems: "center" }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 8,
              }}
            ></View>
            <View
              style={{
                height: 12,
                width: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 0,
              }}
            ></View>
          </View>
          <View style={{ width: "33%", alignItems: "center" }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 8,
              }}
            ></View>
            <View
              style={{
                height: 12,
                width: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 0,
              }}
            ></View>
          </View>
        </View>
        <View
          style={{ paddingHorizontal: 10, marginBottom: 20, marginTop: 20 }}
        >
          <View
            style={{
              height: 10,
              width: "100%",
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: 100,
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: 150,
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 0,
            }}
          ></View>
        </View>
      </>
    );
  };

  renderSubscriptionModalContent = () => {
    let {
      enableLoader,
      emailThemes,
      selectedTheme,
      firstName,
      lastName,
      email,
      message,
      errorMsg,
      enableBtnLoader,
      giftMsgErrorMsg,
    } = this.state;
    let { subscriptionData, token } = this.props;
    let userName = subscriptionData?.userProfile?.first_name
      ? subscriptionData.userProfile.first_name
      : subscriptionData.userProfile.last_name
      ? subscriptionData.userProfile.last_name
      : "‘Name’";
    let darkMode = this.props.darkMode;
    return (
      <>
        {enableLoader ? (
          <View
            style={{
              position: "relative",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: darkMode ? Colors.darkBlackColorBg1 : "#ffffff",
              zIndex: 999,
            }}
          >
            <View
              style={[
                Flex.justfiyCenter,
                Flex.alignCenter,
                helpers.isWeb() && {
                  height: "calc(100vh - 189px)",
                },
                {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg1
                    : "#ffffff",
                },
                !helpers.isWeb() && { height: Dimensions.get("window").height },
              ]}
            >
              <View style={{ marginTop: !helpers.isWeb() ? -20 : 0 }}>
                <ProgressCircle
                  color={"#907cff"}
                  size={186}
                  widthOfBorder={25}
                />
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{ flexDirection: helpers.isDesktop() ? "row" : "column" }}
          >
            <View
              style={{
                width: helpers.isDesktop() ? "60%" : "100%",
                paddingRight: helpers.isDesktop() ? 40 : 20,
                paddingLeft: helpers.isDesktop() ? 40 : 20,
                paddingTop: 40,
                paddingBottom: 40,
                backgroundColor: darkMode ? Colors.darkBlackColor1 : "#ffffff",
              }}
            >
              <View
                style={[
                  Margin.mb_4,
                  !helpers.isDesktop() && Margin.mb_2,
                  { alignItems: helpers.isDesktop() ? "flex-start" : "center" },
                ]}
              >
                <View
                  style={[
                    Helpers.borderBottom,
                    darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      { fontSize: 18 },
                      {
                        fontFamily: FontFamily.abrilFatface,
                        color: "#000",
                        textTransform: "capitalize",
                      },
                      { fontSize: helpers.isDesktop() ? 34 : 20 },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {subscriptionData.selectedSubscribtion.invoice_name}
                  </Text>
                </View>
              </View>
              {!subscriptionData?.selectedSubscribtion?.is_story_gift && (
                <>
                  <View>
                    <Text
                      style={{
                        fontSize: helpers.isDesktop() ? 20 : 16,
                        fontFamily: helpers.isWeb()
                          ? FontFamily.regular
                          : FontFamily.bold,
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 10,
                        fontWeight: helpers.isWeb() ? "600" : "700",
                      }}
                    >
                      Pick a design for your gift email
                    </Text>
                  </View>
                  <View
                    style={[{ marginBottom: helpers.isDesktop() ? 40 : 10 }]}
                  >
                    <ScrollView horizontal={true}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: helpers.isDesktop() ? 20 : 20,
                        }}
                      >
                        {emailThemes.map((theme) => {
                          return (
                            <TouchableOpacity
                              onPress={() => this.setEmailTheme(theme)}
                            >
                              <CustomImage
                                source={theme.thumbnail}
                                style={{
                                  width: 70,
                                  height: 70,
                                  resizeMode: "contain",
                                  marginRight: 13,
                                }}
                                webStyle={{
                                  width: helpers.isDesktop() ? 94 : 70,
                                  height: helpers.isDesktop() ? 94 : 70,
                                  objectFit: "contain",
                                  marginRight: 13,
                                }}
                              />
                            </TouchableOpacity>
                          );
                        })}
                      </View>
                    </ScrollView>
                  </View>
                </>
              )}
              <View>
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 20 : 16,
                    fontFamily: helpers.isWeb()
                      ? FontFamily.regular
                      : FontFamily.bold,
                    color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
                    marginBottom: 20,
                    fontWeight: helpers.isWeb() ? "600" : "700",
                  }}
                >
                  Enter Recipient’s Information
                </Text>
                <View
                  style={{
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={[
                      Margin.mb_2,
                      formStyle.inputIconWrap,
                      { width: helpers.isDesktop() ? "48%" : "100%" },
                    ]}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.regular,
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 8,
                      }}
                    >
                      Recipient’s First Name *
                    </Text>
                    <TextInput
                      placeholder={""}
                      placeholderTextColor={[Colors.placeholderTextColor]}
                      style={[
                        formStyle.inputBorderBox,
                        darkMode && {
                          color: Colors.lightWhite,
                          borderColor: Colors.lineColor1,
                        },
                      ]}
                      value={firstName}
                      onChangeText={(value) =>
                        this.handleOnChange("firstName", value)
                      }
                    />
                  </View>
                  <View
                    style={[
                      Margin.mb_2,
                      formStyle.inputIconWrap,
                      { width: helpers.isDesktop() ? "48%" : "100%" },
                    ]}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.regular,
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 8,
                      }}
                    >
                      Recipient’s Last Name *
                    </Text>
                    <TextInput
                      placeholder={""}
                      placeholderTextColor={[Colors.placeholderTextColor]}
                      style={[
                        formStyle.inputBorderBox,
                        darkMode && {
                          color: Colors.lightWhite,
                          borderColor: Colors.lineColor1,
                        },
                      ]}
                      value={lastName}
                      onChangeText={(value) =>
                        this.handleOnChange("lastName", value)
                      }
                    />
                  </View>
                </View>
                <View style={[Margin.mb_2, formStyle.inputIconWrap]}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.regular,
                      color: darkMode
                        ? Colors.lightWhite
                        : "rgba(0, 0, 0, 0.7)",
                      marginBottom: 8,
                    }}
                  >
                    Recipient’s Email id *
                  </Text>
                  <TextInput
                    placeholder={""}
                    placeholderTextColor={[Colors.placeholderTextColor]}
                    style={[
                      formStyle.inputBorderBox,
                      darkMode && {
                        color: Colors.lightWhite,
                        borderColor: Colors.lineColor1,
                      },
                    ]}
                    value={email}
                    onChangeText={(value) =>
                      this.handleOnChange("email", value)
                    }
                  />
                </View>
                <View style={[Margin.mb_0, formStyle.inputIconWrap]}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.regular,
                      color: darkMode
                        ? Colors.lightWhite
                        : "rgba(0, 0, 0, 0.7)",
                      marginBottom: 8,
                    }}
                  >
                    Write a personal message (optional)
                  </Text>
                  <TextInput
                    placeholder={"Write your message here"}
                    placeholderTextColor={
                      darkMode ? "rgba(255, 255, 255, 0.5)" : "#8A8A8A"
                    }
                    style={[
                      formStyle.textArea,
                      { borderColor: "#c4c4c4" },
                      darkMode && {
                        color: Colors.lightWhite,
                        borderColor: Colors.lineColor1,
                      },
                      helpers.isWeb() && { minHeight: 155, maxHeight: 155 },
                    ]}
                    value={message}
                    onChangeText={(value) =>
                      this.handleOnChange("message", value)
                    }
                    multiline={true}
                  />

                  <View
                    style={[
                      { marginTop: 5, marginBottom: 10 },
                      giftMsgErrorMsg && {
                        flexDirection: "row",
                        justifyContent: "space-between",
                      },
                    ]}
                  >
                    {giftMsgErrorMsg && (
                      <Text style={[Typo.h4, unlockBox.errorText]}>
                        {giftMsgErrorMsg}
                      </Text>
                    )}
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 14,
                        fontFamily: FontFamily.regular,
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      {message.length}/{maxMessageLength}
                    </Text>
                  </View>
                </View>
                <View>
                  <ButtonGradient
                    title={
                      subscriptionData?.selectedSubscribtion
                        ?.is_free_privilege_credits
                        ? "GIFT NOW"
                        : subscriptionData?.selectedSubscribtion?.is_story_gift
                        ? "GIFT STORY"
                        : "PROCEED TO CHECKOUT"
                    }
                    rootStyle={{
                      btnWrapper: button.primaryGradient,
                      btnText: button.primaryGradientText,
                    }}
                    onClick={() => {
                      this.validateForm();
                    }}
                    disable={!firstName || !lastName || !email}
                    fullWidth={false}
                    darkMode={darkMode}
                    leftAligned={true}
                    loader={enableBtnLoader}
                  />
                </View>
                {errorMsg && (
                  <View>
                    <Text
                      style={[
                        Typo.h4,
                        Margin.mt_0,
                        Margin.mb_0,
                        unlockBox.errorText,
                      ]}
                    >
                      {errorMsg}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View
              style={{
                width: helpers.isDesktop() ? "40%" : "100%",
                backgroundColor: darkMode ? Colors.darkBlackColor1 : "#fafafa",
                paddingRight: helpers.isDesktop() ? 40 : 20,
                paddingLeft: helpers.isDesktop() ? 40 : 20,
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Text
                style={{
                  fontSize: helpers.isDesktop() ? 20 : 16,
                  fontFamily: helpers.isWeb()
                    ? FontFamily.regular
                    : FontFamily.bold,
                  color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
                  marginBottom: helpers.isDesktop() ? 10 : 20,
                  textAlign: helpers.isDesktop() ? "left" : "center",
                  fontWeight: helpers.isWeb() ? "600" : "700",
                }}
              >
                Preview your gift here
              </Text>

              {!token && (
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: FontFamily.regular,
                    color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
                    lineHeight: 20,
                    marginBottom: 10,
                  }}
                >
                  *Your first name will replace ‘Name’ once you sign in to TMC
                  on the next screen
                </Text>
              )}

              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#6F6F6F",
                    backgroundColor: darkMode ? "#121212" : "#fafafa",
                  },
                  !subscriptionData?.selectedSubscribtion?.is_story_gift && {
                    borderTopWidth: 0,
                  },
                ]}
              >
                <CustomImage
                  source={selectedTheme.image_url}
                  style={{ width: "100%", height: 122, resizeMode: "cover" }}
                  webStyle={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                <View
                  style={[
                    {
                      paddingBottom: 20,
                      paddingTop: 20,
                      paddingRight: 10,
                      paddingleft: 10,
                      maxWidth: 306,
                      marginLeft: "auto",
                      marginRight: "auto",
                    },
                    !subscriptionData?.selectedSubscribtion?.is_story_gift && {
                      alignItems: "center",
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: FontFamily.regular,
                      fontWeight: helpers.isWeb() ? "600" : "700",
                      color: darkMode ? Colors.lightWhite : "#000000",
                      lineHeight: 27,
                      marginBottom: 10,
                    }}
                  >
                    {subscriptionData?.selectedSubscribtion?.is_story_gift
                      ? "Dear"
                      : "Hi"}{" "}
                    {firstName ? `${firstName},` : ""}
                  </Text>

                  {subscriptionData?.selectedSubscribtion
                    ?.is_free_privilege_credits ? (
                    <Text
                      style={{
                        fontSize: 13,
                        fontFamily: FontFamily.regular,
                        color: darkMode ? Colors.lightWhite : "#000000",
                        lineHeight: 20,
                        textAlign: "center",
                      }}
                    >
                      {userName} has gifted you{" "}
                      <Text
                        style={{ fontWeight: helpers.isWeb() ? "600" : "700" }}
                      >
                        {subscriptionData.giftCredits} Privilege Credit
                        {parseInt(subscriptionData.giftCredits) > 1 ? "s" : ""}
                      </Text>{" "}
                      to read The Morning Context. You can use these credits to
                      unlock any story, newsletter, or long read of your choice.
                    </Text>
                  ) : subscriptionData?.selectedSubscribtion?.is_story_gift ? (
                    <Text
                      style={{
                        fontSize: 13,
                        fontFamily: FontFamily.regular,
                        color: darkMode ? Colors.lightWhite : "#000000",
                        lineHeight: 20,
                      }}
                    >
                      {userName} has invited you to read a subscriber only story
                      published by The Morning Context. To unlock and read it,
                      all you have to do is claim the gift.
                    </Text>
                  ) : (
                    <>
                      {subscriptionData?.selectedSubscribtion?.tmc_group ==
                      "gift_plan" ? (
                        <Text
                          style={{
                            fontSize: 13,
                            fontFamily: FontFamily.regular,
                            color: darkMode ? Colors.lightWhite : "#000000",
                            lineHeight: 20,
                            textAlign: "center",
                          }}
                        >
                          {userName} has gifted you{" "}
                          {subscriptionData.selectedSubscribtion.invoice_name.includes(
                            "Annual"
                          )
                            ? "an"
                            : ""}{" "}
                          <Text
                            style={{
                              fontWeight: helpers.isWeb() ? "600" : "700",
                            }}
                          >
                            {subscriptionData.selectedSubscribtion.invoice_name}
                          </Text>{" "}
                          to The Morning Context. You are now part of an
                          exclusive set of readers who love deeply researched,
                          well written long-form journalism. This subscription
                          is your key to read more than 500 stories from our
                          archives, all of our current long-form articles and
                          newsletters. You also get exclusive access to The
                          Morning Context Slack channel, and early invites to
                          TMC Dialogues, our fortnightly AMA + Webinar.
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontSize: 13,
                            fontFamily: FontFamily.regular,
                            color: darkMode ? Colors.lightWhite : "#000000",
                            lineHeight: 20,
                            textAlign: "center",
                          }}
                        >
                          {userName} has gifted you{" "}
                          {subscriptionData.selectedSubscribtion.invoice_name.includes(
                            "Annual"
                          )
                            ? "an"
                            : ""}{" "}
                          <Text
                            style={{
                              fontWeight: helpers.isWeb() ? "600" : "700",
                            }}
                          >
                            {subscriptionData.selectedSubscribtion.invoice_name}
                          </Text>{" "}
                          to read The Morning Context. You are now part of an
                          exclusive set of readers who love deeply researched,
                          well written long-form journalism. Your privilege
                          credits will unlock more than 500 stories from our
                          archives, all of our current long-form articles and
                          newsletters.
                        </Text>
                      )}
                    </>
                  )}
                </View>
                {!!message &&
                  !subscriptionData?.selectedSubscribtion?.is_story_gift && (
                    <View
                      style={{
                        backgroundColor: "rgba(169, 125, 241, 0.05)",
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        position: "relative",
                      }}
                    >
                      <CustomImage
                        source={emailQuote}
                        require={true}
                        style={{
                          width: 12,
                          height: 9,
                          resizeMode: "contain",
                          position: "absolute",
                          top: -3,
                          left: 5,
                        }}
                        webStyle={{
                          width: 12,
                          height: 9,
                          objectFit: "contain",
                          position: "absolute",
                          top: -3,
                          left: 5,
                        }}
                      />
                      <CustomImage
                        source={emailQuoteSecond}
                        require={true}
                        style={{
                          width: 12,
                          height: 9,
                          resizeMode: "contain",
                          position: "absolute",
                          bottom: -3,
                          right: 4,
                        }}
                        webStyle={{
                          width: 12,
                          height: 9,
                          objectFit: "contain",
                          position: "absolute",
                          bottom: -4,
                          right: 4,
                        }}
                      />

                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: FontFamily.regular,
                          color: darkMode ? Colors.lightWhite : "#000000",
                          lineHeight: 16,
                          textAlign: "center",
                          color: darkMode ? Colors.lightWhite : "#000000",
                          marginBottom: 2,
                        }}
                      >
                        {message}
                      </Text>
                    </View>
                  )}

                {subscriptionData?.selectedSubscribtion?.is_story_gift ? (
                  <View
                    style={{
                      marginBottom: 30,
                      marginTop: 20,
                      paddingHorizontal: 10,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        borderWidth: 2,
                        borderColor: "#FF9302",
                        marginLeft: "auto",
                        marginRight: "auto",
                        flexDirection: "row",
                        paddingVertical: 10,
                        paddingHorizontal: 10,
                      }}
                    >
                      <View style={{}}>
                        <CustomImage
                          source={subscriptionData?.story?.featured_image?.url}
                          style={{
                            width: 63,
                            height: 36,
                            resizeMode: "contain",
                          }}
                          webStyle={{ width: 63, height: 36 }}
                        />
                      </View>
                      <View
                        style={{ marginLeft: 10, alignItems: "flex-start" }}
                      >
                        <Text
                          style={[
                            Helpers.textCenter,
                            Typo.h3_R,
                            helpers.isDesktop() && Typo.h3_RDesktop,
                            Margin.mb_0,
                            { fontFamily: FontFamily.bold, fontWeight: "700" },
                            { fontSize: 12 },
                            darkMode && { color: Colors.lightWhite },
                          ]}
                        >
                          {subscriptionData.story?.title ? 
                          (subscriptionData.story.title.length < 30 
                            ? subscriptionData.story.title  :  `${subscriptionData.story.title.substring(0, 30)}...`) : 
                            subscriptionData.story.story.title.length < 30
                            ? subscriptionData.story.story.title
                            : `${subscriptionData.story.story.title.substring(
                                0,
                                30
                              )}...`}
                        </Text>

                        <View
                          style={{
                            height: 1,
                            width: 95,
                            backgroundColor: darkMode ? "#bb26c8" : "#907CFF",
                            marginBottom: 5,
                            marginTop: 5,
                          }}
                        ></View>

                        <View
                          style={[
                            { flexDirection: "row", justifyContent: "center" },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              fontFamily: FontFamily.regular,
                              color: darkMode ? Colors.lightWhite : "#000000",
                              textAlign: "center",
                            }}
                          >
                            {subscriptionData.story?.author?.length ? subscriptionData.story.author[0].name: subscriptionData.story.authors[0].name}
                          </Text>

                          <View
                            style={[
                              {
                                width: 2,
                                height: 2,
                                borderRadius: 100,
                                backgroundColor: "#6A6A6A",
                                marginHorizontal: 5,
                                marginTop: 1,
                                position: "relative",
                                top: 6,
                              },
                            ]}
                          ></View>

                          <Text
                            style={[
                              {
                                fontFamily: FontFamily.light,
                                fontWeight: FontWeight.light,
                                fontSize: 12,
                                color: "#000000",
                              },
                              darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                            ]}
                          >
                            {moment(
                              subscriptionData.story.published_date
                            ).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={{ marginBottom: 30, marginTop: 60 }}>
                    <View
                      style={{
                        maxWidth: 210,
                        width: "100%",
                        borderWidth: 2,
                        borderColor: "#FF9302",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: "rgba(255, 147, 2, 0.14)",
                          height: 56,
                          position: "relative",
                          alignItems: "center",
                        }}
                      >
                        <CustomImage
                          source={
                            subscriptionData.selectedSubscribtion.tmc_group ==
                            "gift_plan"
                              ? annualGiftSub
                              : creditGiftIcon
                          }
                          require={true}
                          style={{
                            width: 120,
                            height: 80,
                            position: "relative",
                            top: -29,
                            resizeMode: "contain",
                          }}
                          webStyle={{
                            width: 120,
                            height: 80,
                            position: "relative",
                            top: -29,
                          }}
                        />
                      </View>
                      <View style={{ paddingBottom: 15, paddingTop: 15 }}>
                        <Text
                          style={[
                            Helpers.textCenter,
                            Typo.h3_R,
                            helpers.isDesktop() && Typo.h3_RDesktop,
                            Margin.mb_0,
                            {
                              fontFamily: FontFamily.abrilFatface,
                              textTransform: "capitalize",
                            },
                            { fontSize: helpers.isDesktop() ? 15 : 15 },
                            darkMode && { color: Colors.lightWhite },
                          ]}
                        >
                          {subscriptionData.selectedSubscribtion.invoice_name}
                        </Text>
                        <View
                          style={{
                            height: 1,
                            width: 95,
                            marginRight: "auto",
                            marginLeft: "auto",
                            backgroundColor: darkMode ? "#bb26c8" : "#907CFF",
                            marginBottom: 5,
                            marginTop: 5,
                          }}
                        ></View>
                        <Text
                          style={{
                            fontSize: 12,
                            fontFamily: FontFamily.regular,
                            color: darkMode ? Colors.lightWhite : "#000000",
                            textAlign: "center",
                          }}
                        >
                          From:{" "}
                          <Text style={{ fontFamily: FontFamily.medium }}>
                            {userName}
                          </Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                )}

                {!subscriptionData?.selectedSubscribtion?.is_story_gift && (
                  <View
                    style={{
                      paddingHorizontal: 10,
                      marginBottom: 20,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        height: 10,
                        width: "100%",
                        backgroundColor: darkMode
                          ? "#2D302D"
                          : "rgba(196, 196, 196, 0.6)",
                        marginBottom: 5,
                      }}
                    ></View>
                  </View>
                )}

                <View
                  style={{
                    marginBottom: 25,
                    paddingLeft: helpers.isDesktop() ? 0 : 15,
                    paddingRight: helpers.isDesktop() ? 0 : 15,
                  }}
                >
                  <View style={{ alignItems: "center" }}>
                    <View
                      style={{
                        height: 46,
                        width: 200,
                        backgroundColor: darkMode ? "#434343" : "#C4C4C4",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 17,
                          fontFamily: FontFamily.regular,
                          fontWeight: helpers.isWeb() ? "600" : "700",
                          color: darkMode
                            ? "rgba(255, 255, 255, 0.3)"
                            : "#FFFFFF",
                        }}
                      >
                        CLAIM GIFT
                      </Text>
                    </View>
                  </View>
                </View>

                {!subscriptionData?.selectedSubscribtion?.is_story_gift && (
                  <View
                    style={{
                      paddingHorizontal: 10,
                      marginBottom: 20,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        height: 10,
                        width: "100%",
                        backgroundColor: darkMode
                          ? "#2D302D"
                          : "rgba(196, 196, 196, 0.6)",
                        marginBottom: 5,
                      }}
                    ></View>
                    <View
                      style={{
                        height: 10,
                        width: "100%",
                        backgroundColor: darkMode
                          ? "#2D302D"
                          : "rgba(196, 196, 196, 0.6)",
                        marginBottom: 5,
                      }}
                    ></View>
                    <View
                      style={{
                        height: 10,
                        width: 200,
                        backgroundColor: darkMode
                          ? "#2D302D"
                          : "rgba(196, 196, 196, 0.6)",
                        marginBottom: 0,
                      }}
                    ></View>
                  </View>
                )}

                {!!message &&
                  subscriptionData?.selectedSubscribtion?.is_story_gift && (
                    <View
                      style={{
                        backgroundColor: "rgba(169, 125, 241, 0.05)",
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        position: "relative",
                        marginBottom: 25,
                      }}
                    >
                      <CustomImage
                        source={emailQuote}
                        require={true}
                        style={{
                          width: 12,
                          height: 9,
                          resizeMode: "contain",
                          position: "absolute",
                          top: -3,
                          left: 5,
                        }}
                        webStyle={{
                          width: 12,
                          height: 9,
                          objectFit: "contain",
                          position: "absolute",
                          top: -3,
                          left: 5,
                        }}
                      />
                      <CustomImage
                        source={emailQuoteSecond}
                        require={true}
                        style={{
                          width: 12,
                          height: 9,
                          resizeMode: "contain",
                          position: "absolute",
                          bottom: -3,
                          right: 4,
                        }}
                        webStyle={{
                          width: 12,
                          height: 9,
                          objectFit: "contain",
                          position: "absolute",
                          bottom: -4,
                          right: 4,
                        }}
                      />

                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: FontFamily.regular,
                          color: darkMode ? Colors.lightWhite : "#000000",
                          lineHeight: 16,
                          textAlign: "center",
                          color: darkMode ? Colors.lightWhite : "#000000",
                          marginBottom: 2,
                        }}
                      >
                        {message}
                      </Text>
                      <Text
                        style={{
                          fontSize: 11,
                          fontFamily: FontFamily.regular,
                          color: darkMode ? Colors.lightWhite : "#000000",
                          lineHeight: 16,
                          textAlign: "center",
                          color: darkMode ? Colors.lightWhite : "#000000",
                          marginBottom: 2,
                        }}
                      >
                        -{userName}
                      </Text>
                    </View>
                  )}

                {subscriptionData?.selectedSubscribtion?.is_story_gift &&
                  this.renderDummyLoader()}
              </View>
            </View>
          </View>
        )}
      </>
    );
  };

  render() {
    let darkMode = this.props.darkMode;
    return (
      <View
        style={[
          { zIndex: 99999 },
          !helpers.isWeb() && {
            height: "100%",
            backgroundColor: darkMode ? Colors.darkBlackColor1 : "#ffffff",
          },
        ]}
      >
        {helpers.isWeb() ? (
          <Modal
            renderContent={() => this.renderSubscriptionModalContent()}
            closeModal={() => this.closeModal()}
            paddingHorizontal={true}
            topPadding={false}
            giftCardModal={true}
          />
        ) : (
          <>
            <View
              style={[{ position: "absolute", right: 0, top: 0, zIndex: 1 }]}
            >
              <TouchableOpacity
                activeOpacity={0.8}
                style={[modalStyle.closeButton]}
                onPress={() => this.closeModal()}
              >
                <CustomImage
                  source={closeIcon}
                  style={{ width: 28, height: 28 }}
                  webStyle={{ width: 28, height: 28 }}
                  require={true}
                />
              </TouchableOpacity>
            </View>
            <ScrollView
              ref={(ref) => (this.appscrollview = ref)}
              style={[{ flex: 1 }]}
            >
              {this.renderSubscriptionModalContent()}
            </ScrollView>
          </>
        )}
      </View>
    );
  }
}

export default GiftCardModal;
