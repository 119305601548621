import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { Flex, Padding, Margin, Helpers, } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import { Timeline, Tweet } from "react-twitter-widgets";


export class TwitterFeeds extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        let { UserName, darkMode } = this.props;
        return (
            <>
                <div className="writer_twitter_container">
                    <Timeline
                        dataSource={{
                            sourceType: 'profile',
                            screenName: UserName
                        }}
                        options={{
                            // height: '30%',
                            tweetLimit: '3',
                            chrome: "nofooter noheader",
                            theme: darkMode ? "dark" : "light"
                        }}

                    />
                </div>
            </>
        );
    }
}

export default TwitterFeeds;