import React, { Component } from "react";
import {
  Text,
  TextInput,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
  Platform,
  Linking,
  Picker,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  banner,
  pageContent,
  FontFamily,
  FontWeight,
  pageCover,
  button,
  Colors,
  subscribePlans,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import seoObj from "../../utils/seo-helper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import moment from "moment";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import ContentLoader from "react-native-easy-content-loader";
import CustomImage from "../../components/custom-image";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../../components/button-gradient";
import Banner from "../../components/banner";
import CreditsInfo from "../../components/credits-info";
import BenefitsPlan from "../../components/coorporate-benefits-plan";
import PageBreak from "../../components/page-break";
import SeatBasedSubscription from "../../components/seat-based-subscription";
import AccordionListItem from "../../components/accordian";
import SubscriptionSponsers from "../../components/subscription-sponsers";
import HomeReadersQuote from "../../components/home-readers-quote";
import CreditWorkFlow from "../../components/credit-work-flow";
import TouchableOpacityLinkExternal from "../../components/touchable-opacity-link-external";
import WorkEmailPopup from "../../components/work-email-popup";
import ActionBox from "../../components/action-box";
import Seo from "../../components/seo-meta";
import VimeoVideo from "../../components/vimeo-video";
import { Mixpanel } from "../../utils/mixpanel";
import Button from "../../components/button";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import { ChargebeeController } from "../../utils/chargebee";
import CustomerForm from "../../components/customer-form";
import ThankYouComponent from "../../components/customer-thank-you";
import axios from "axios";

const cookies = new Cookies();
const noSubscription = getImagePath("img/team_members.png");
const customized_subscription = getImagePath("img/custom-subscriptions.png");
const star = getImagePath("icons/star.png");
const goldCoin = getImagePath("icons/goldcoincredit.png");
const quoteLeft = getImagePath("icons/quotegreen.png");
const defaultCompany = getImagePath("icons/default-company-logo.png");
const defaultCompanyWhite = getImagePath(
  "icons/default-company-logo-white.png"
);
const checkIcon = getImagePath("icons/check.png");
const infoIcon = getImagePath("icons/info-red.png");
const chooseCredits = getImagePath("icons/choose-credits.png");

const windowWidth = Dimensions.get("window").width;

const SECTIONS = [
  {
    title: "How does a Group Subscription differ from consumer subscriptions?",
    content:
      "A Group Subscription allows you to purchase for your chosen number of employees based on a scalable discounted rate.",
  },
  {
    title: "How will we get billed?",
    content:
      "By invoice for 12 months in advance. If you are maintaining print or a group of individual logins, this will be covered in a separate invoice.",
  },
  {
    title: "Are there limits on usage?",
    content:
      "An Enterprise Licence can be used by all staff on any device in any location. There are no article limits.",
  },
  {
    title: "How is access implemented with a team or business?",
    content:
      "We will provide you all the tools needed to set up and manage your own subscription where you will be able to add, remove and manage users via self service. We will also offer dedicated customer service for any subscription queries. Once you have a corporate account, access to the websites is granted via your work email address.",
  },
  {
    title: "Can I add more users to my Corporate Subscription?",
    content:
      "Yes, your subscription is flexible so if you would like to add more users and manage the payment via your dashboard.",
  },
  {
    title: "What if I have access issues?",
    content:
      "As a Corporate Subscriber you have access to exclusive Customer support, who are available to answer any questions you have throughout your subscription. You can also write to ",
  },
  {
    title: "Are credits transferable?",
    content:
      "The admin will have rights to move around the credits. Individual members cannot transfer these.",
  },
  {
    title: "Will I have to pay a different pricing if I wanted extra credits?",
    content:
      "These prices are introductory, they might be subject to change in the future. Write to us at priya@mailtmc.com with what you have in mind, we will make something work out.",
  },
];

export class TeamSubscription extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    this.state = {
      token: tokenSet ? token : false,
      userSubscription: false,
      teamSubscription: false,
      teamPlan: false,
      teamCreditPlans: [],
      country: false,
      seats: 5,
      pageLoader: true,
      enableLoader: true,
      totalAmount: 0,
      fullAmount: 0,
      discountPer: 0,
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      isButtonClicked: false,
      cb_handle: null,
      showButtonLoader: false,
      planCheckoutLoaderState: false,
      isCustomerDataSubmitted: false,
      isServerSideError: false,
      serverSideErrorMessage: "",
    };
  }

  componentDidMount = () => {
    const { token } = this.state;
    if (token) {
      this.getUserInfo();
      ChargebeeController.activatedController().then((cb_handle) => {
        this.setState({ cb_handle: cb_handle });
      });
    } else {
      this.getSubscriptionPlans();
    }
    this.getStaticContent();
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidUpdate = (prevProps) => {
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    if (
      (!(
        this.props.searchQuery &&
        (this.props.searchQuery.includes("subscribe=") ||
          this.props.searchQuery.includes("buy-credits=true"))
      ) &&
        ((token && token != this.state.token) ||
          ((token == undefined || token == "") &&
            this.state.token != false))) ||
      (!helpers.isWeb() &&
        prevProps.app.refreshScreen != this.props.app.refreshScreen)
    ) {
      this.setState(
        {
          token: token == undefined || token == "" ? false : token,
          pageLoader: true,
        },
        () => {
          if (this.state.token) {
            this.getUserInfo(true);
          } else {
            this.getSubscriptionPlans();
          }
        }
      );
    }
  };
  setPlanButtonLoaderState = (state) => {
    this.setState({
      planCheckoutLoaderState: state,
    });
  };

  getPlanButtonLoaderState = () => {
    return this.state.planCheckoutLoaderState;
  };
  refreshPage = () => {
    if (this.state.token) {
      this.getUserInfo();
    }
  };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      }
    }
  };

  getUserInfo = (scroll) => {
    this.scrollToTop(scroll);
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["profile", "subscription"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          return false;
        }
        if (res.success) {
          this.setState({
            profileData: res.data,
            userSubscription:
              !res.data.subscription ||
                JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription,
            teamSubscription:
              !res.data.team_subscription ||
                JSON.stringify(res.data.team_subscription) == "{}"
                ? false
                : res.data.team_subscription,
          });
          this.getSubscriptionPlans(res.data);
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  getSubscriptionPlans = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    let body = {
      filters: {
        tmc_group: ["team", "team_credit"],
      },
    };
    if (profileData && profileData?.subscription?.plan) {
      body.filters.plan_gte = profileData.subscription.plan.id;
    }
    if (profileData && profileData?.team_subscription?.plan) {
      body.team_plan_id = profileData.team_subscription.plan.id;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          this.setState(
            {
              teamPlan:
                res.data.plans?.team && res.data.plans.team.length >= 1
                  ? res.data.plans.team[0]
                  : false,
              teamCreditPlans: res.data.plans?.team_credit
                ? res.data.plans.team_credit
                : [],
              country: res.data.country,
              pricingPlanRefreshKey: !this.state.pricingPlanRefreshKey,
              pageLoader: false,
            },
            () => this.getEstimate()
          );
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  getStaticContent = () => {
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: ["readers_quotes"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "content_found") {
          this.setState({ staticContent: res.data });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  logout = () => {
    this.refs.header.authTokenExpired();
  };

  isSubscriptionUnderDunning() {
    let { cb_handle } = this.state;
    if (cb_handle?.activeSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  renewTeamPlan = () => {
    this.refs.header.showSubscriptionPlan(
      "renew-team-plan",
      false,
      this.state.country
    );
  };

  cancelTeamPlan = () => {
    let pushProp = JSON.parse(JSON.stringify(window.location));
    pushProp.hash = "accounts/cancel-team-subscription";
    this.props.history.push(pushProp);
  };

  navigateToTeamPage = () => {
    this.props.navigateToScreen("/my-team");
  };

  renderCurrentTeamPlan = () => {
    const { teamSubscription } = this.state;
    let userPlan = teamSubscription.plan;
    let ownerDesc = userPlan.description.owner_desc
      ? userPlan.description.owner_desc
      : userPlan.description.access_desc;
    let memberDesc = userPlan.description.member_desc
      ? userPlan.description.member_desc
      : userPlan.description.access_desc;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View>
        {teamSubscription && teamSubscription.is_team_owner ? (
          <View>
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Flex.justfiyCenter,
                Margin.mb_3,
              ]}
            >
              <CustomImage
                source={
                  teamSubscription.user_info.company_logo
                    ? teamSubscription.user_info.company_logo
                    : darkMode
                      ? defaultCompanyWhite
                      : defaultCompany
                }
                require={true}
                style={{
                  width: 28,
                  height: 28,
                  resizeMode: "cover",
                  marginRight: 10,
                }}
                webStyle={{
                  width: 28,
                  height: 28,
                  objectFit: "cover",
                  marginRight: 10,
                }}
                altText={
                  teamSubscription.user_info.company_name
                    ? teamSubscription.user_info.company_name
                    : `${teamSubscription.user_info.first_name != ""
                      ? teamSubscription.user_info.first_name
                      : teamSubscription.user_info.last_name
                    } Team`
                }
              />
              <Text
                style={[
                  {
                    fontFamily: FontFamily.abrilFatface,
                    fontSize: 20,
                    lineHeight: 24,
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {teamSubscription.user_info.company_name
                  ? teamSubscription.user_info.company_name
                  : `${teamSubscription.user_info.first_name != ""
                    ? teamSubscription.user_info.first_name
                    : teamSubscription.user_info.last_name
                  } Team`}
              </Text>
            </View>

            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Margin.mb_3,
                { justifyContent: "space-evenly" },
              ]}
            >
              <View style={{ marginRight: 20, textAlign: "center" }}>
                <Text
                  style={[
                    {
                      fontFamily: FontFamily.merriweather,
                      fontSize: 24,
                      lineHeight: 48,
                      color: "rgba(0, 0, 0, 0.7)",
                      letterSpacing: 0.3,
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {teamSubscription?.plan.tmc_group == "team_credit"
                    ? teamSubscription?.team_details?.credits_available
                    : teamSubscription?.team_details?.seats_purchased}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.merriweather,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {teamSubscription?.plan.tmc_group == "team_credit"
                    ? "Credits Available"
                    : "Seats Purchased"}
                </Text>
              </View>
              <View style={{ marginRight: 20, textAlign: "center" }}>
                <Text
                  style={[
                    {
                      fontFamily: FontFamily.merriweather,
                      fontSize: 24,
                      lineHeight: 48,
                      color: "rgba(0, 0, 0, 0.7)",
                      letterSpacing: 0.3,
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {teamSubscription?.team_details?.total_members}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.merriweather,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Members
                </Text>
              </View>
              <View style={{ marginRight: 20, textAlign: "center" }}>
                <Text
                  style={[
                    {
                      fontFamily: FontFamily.merriweather,
                      fontSize: 24,
                      lineHeight: 48,
                      color: "rgba(0, 0, 0, 0.7)",
                      letterSpacing: 0.3,
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  {teamSubscription?.team_details?.pending_invites}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.merriweather,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Invited
                </Text>
              </View>
            </View>

            {ownerDesc && (
              <View style={[Margin.mb_3]}>
                {ownerDesc.map((itemElement, index) => {
                  return (
                    <View style={[Margin.mt_1, Margin.mb_0]}>
                      <View style={[Flex.row, { alignItems: "flex-start" }]}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                            subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                            subscribePlans.planDescPanel,
                            Margin.ml_1,
                            { textAlign: "left", marginBottom: 0 },
                            darkMode && { color: Colors.lightWhite },
                          ]}
                        >
                          {itemElement}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            )}

            <View>
              <ButtonGradient
                title={"MANAGE MEMBERS"}
                rounded={true}
                fullWidth={true}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradientBtn,
                    { height: 46, borderRadius: 0, margin: 0 },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: helpers.isDesktop() ? 17 : 16,
                      fontWeight: "400",
                      paddingTop: helpers.isDesktop() ? 0 : 15,
                      paddingBottom: helpers.isDesktop() ? 0 : 15,
                    },
                  ],
                }}
                onClick={() => this.navigateToTeamPage()}
                darkMode={darkMode}
              />

              {this.isSubscriptionUnderDunning() && (
                <ButtonGradient
                  title={
                    this.getPlanButtonLoaderState() &&
                      (this.state.showButtonLoader ||
                        this.state.cb_handle?.instance?.siteConfig
                          ?.enableRedirectMode) == true ? (
                      <>
                        <ActivityIndicator
                          size={25}
                          color={
                            this.state.showButtonLoader ? "#000000" : "#FFFFFF"
                          }
                        />
                      </>
                    ) : (
                      "Renew"
                    )
                  }
                  rounded={true}
                  fullWidth={true}
                  disable={false}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradientBtn,
                      { height: 46, borderRadius: 0, margin: 0 },
                      {
                        marginTop: 5,
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        fontWeight: "400",
                        paddingTop: helpers.isDesktop() ? 0 : 15,
                        paddingBottom: helpers.isDesktop() ? 0 : 15,
                      },
                    ],
                  }}
                  onClick={() => {
                    this.setState({ isButtonClicked: true });
                    this.setPlanButtonLoaderState(true);
                    ChargebeeController.activatedController().then(
                      (cb_handle) => {
                        cb_handle.hosted_page.collect_now();
                        this.setState({ showButtonLoader: true });
                      }
                    );
                  }}
                  darkMode={darkMode}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            {memberDesc &&
              memberDesc.map((itemElement, index) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <CustomImage
                        source={checkIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 14,
                          resizeMode: "contain",
                          marginTop: 3,
                        }}
                        webStyle={{
                          width: 20,
                          height: 14,
                          objectFit: "contain",
                          marginTop: 3,
                        }}
                      />
                      <Text
                        style={[
                          subscribePlans.planDesc,
                          helpers.isDesktop() && subscribePlans.planDescDesktop,
                          this.props.sidePanel && subscribePlans.planDescPanel,
                          Margin.ml_1,
                          { textAlign: "left", marginBottom: 0 },
                          darkMode && { color: Colors.lightWhite },
                        ]}
                      >
                        {itemElement}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        )}
      </View>
    );
  };

  renderTeamBox = () => {
    const { teamSubscription } = this.state;
    let currentTeamPlan = {
      title:
        teamSubscription.plan?.tmc_group == "team_credit"
          ? "Corporate - Privilege Credits"
          : "Corporate - Seat based plan",
      // footerTextBefore: 'Want to cancel your subscription?',
      footerButtonText:
        teamSubscription.is_team_owner &&
          teamSubscription.is_cancelled == false &&
          teamSubscription.can_cancel
          ? "Cancel Subscription"
          : false,
      buttonText: false,
      footerTextAfter: teamSubscription.is_cancelled ? (
        <Text>
          This plan was cancelled and will continue to be active till{" "}
          <Text>
            {moment(teamSubscription.end_date).format("Do MMMM YYYY")}
          </Text>
          .
        </Text>
      ) : teamSubscription.is_team_owner ? (
        teamSubscription.is_team_member ? (
          false
        ) : (
          <Text>
            You are not a member of this plan but you can manage this plan for
            your organization.
          </Text>
        )
      ) : (
        <Text>
          Only the owner can update, cancel or remove you from this plan.
        </Text>
      ),
    };
    let checkDesktop = this.props.sidePanel
      ? false
      : helpers.isDesktop()
        ? true
        : false;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      teamSubscription && (
        <View
          style={[
            { maxWidth: checkDesktop ? "41.5%" : "100%", width: "100%" },
            { marginBottom: 20 },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <View
              style={[
                Margin.mt_2,
                !checkDesktop && Margin.mt_5,
                {
                  backgroundColor:
                    teamSubscription.status == "expired" ||
                      teamSubscription.is_cancelled
                      ? "#4C4C4C"
                      : "#4C4C4C",
                  marginHorizontal: helpers.isDesktop() ? 20 : 0,
                },
                { marginTop: 0 },
                darkMode && { backgroundColor: Colors.purple },
              ]}
            >
              <Text
                style={[
                  Helpers.textUppercase,
                  Helpers.textCenter,
                  Margin.mt_1,
                  {
                    fontSize: 12,
                    fontFamily: FontFamily.regular,
                    fontWeight: FontWeight.semiBold,
                    marginTop: 12,
                    marginBottom: 12,
                    color: "white",
                  },
                ]}
              >
                {(teamSubscription.status == "expired"
                  ? "Expired"
                  : teamSubscription.is_cancelled
                    ? "Cancelled"
                    : "Active") +
                  " - " +
                  (teamSubscription.is_team_owner ? "Admin" : "Member")}
              </Text>
              {(teamSubscription.auto_collection == "on" &&
                !teamSubscription.is_cancelled &&
                teamSubscription.next_billing_at) ||
                teamSubscription.end_date ? (
                <Text
                  style={[
                    Helpers.textCenter,
                    {
                      fontSize: 14,
                      lineHeight: 21,
                      fontFamily: FontFamily.regular,
                      letterSpacing: 0.3,
                      marginBottom: 10,
                      color: "rgba(255,255,255,0.85)",
                      fontWeight: "400",
                    },
                  ]}
                >
                  {(teamSubscription.auto_collection == "on" &&
                    !teamSubscription.is_cancelled
                    ? "Renews on: "
                    : teamSubscription.status == "expired"
                      ? ""
                      : "Expires on: ") +
                    moment(
                      teamSubscription.auto_collection == "on" &&
                        !teamSubscription.is_cancelled
                        ? teamSubscription.next_billing_at
                        : teamSubscription.end_date
                    ).format("Do MMMM YYYY")}
                </Text>
              ) : (
                <> </>
              )}
            </View>
            <View
              style={[
                helpers.isDesktop() && Padding.ph_2,
                Padding.pb_2,
                { flex: 1 },
              ]}
            >
              <ActionBox
                data={currentTeamPlan}
                renderDesc={() => this.renderCurrentTeamPlan()}
                footerClick={() => this.cancelTeamPlan()}
                onButtonClick={() => this.renewTeamPlan()}
                singleLine={true}
                boxColor={
                  teamSubscription.status == "expired" ||
                    teamSubscription.is_cancelled
                    ? "#4C4C4C"
                    : darkMode
                      ? Colors.purple
                      : "#4C4C4C"
                }
                subscriptionPage={true}
                darkMode={darkMode}
              />
            </View>
          </View>
        </View>
      )
    );
  };

  seatBasedPlan = () => {
    let {
      userSubscription,
      teamCreditPlans,
      teamPlan,
      seats,
      totalAmount,
      fullAmount,
      discountPer,
      enableLoader,
      teamSubscription,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        <View style={[Flex.alignCenter, Margin.mb_4]}>
          <View
            style={[
              Flex.alignCenter,
              !helpers.isDesktop() && Margin.mb_4,
              Helpers.textCenter,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
            ]}
          >
            {userSubscription &&
              userSubscription.plan.tmc_group != "individual" && (
                <View
                  style={[
                    Flex.row,
                    Flex.justfiyCenter,
                    Flex.alignCenter,
                    Margin.mb_4,
                    Padding.pv_2,
                    Padding.ph_4,
                    { borderWidth: 1, borderColor: "#FF3D00" },
                  ]}
                >
                  <CustomImage
                    source={infoIcon}
                    require={true}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      marginTop: 3,
                    }}
                    webStyle={{
                      width: 20,
                      height: 20,
                      objectFit: "contain",
                      marginTop: 3,
                    }}
                  />
                  <Text style={[Padding.pl_2, { color: "#FF3D00" }]}>
                    You cannot purchase corporate plans as you are already
                    subscribed to an {userSubscription.plan.tmc_group} plan.
                  </Text>
                </View>
              )}
          </View>
          <Text
            style={[
              Padding.pb_2,
              {
                fontSize: helpers.isDesktop() ? 34 : 30,
                lineHeight: helpers.isDesktop() ? 43 : 38,
                fontFamily: FontFamily.abrilFatface,
                fontWeight: FontWeight.regular,
                color: "#000",
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            Seat Based Plan
          </Text>
          {helpers.isDesktop() ? (
            <View
              style={{
                borderWidth: helpers.isDesktop() ? 2 : 2,
                borderColor: "#5869EA",
                width: helpers.isDesktop() ? 500 : "100%",
                backgroundColor: "#5869EA",
              }}
            />
          ) : (
            <PageBreak
              style={[
                Margin.mt_1,
                { width: "50%" },
                !helpers.isWeb() && { width: 200 },
              ]}
              darkMode={darkMode}
            />
          )}
        </View>
        {this.teamSubscriptionPlan()}
        {!helpers.isDesktop() ? (
          this.renderCreditWorkFlow()
        ) : (
          <SeatBasedSubscription darkMode={darkMode} />
        )}
      </>
    );
    // 25th.05
  };

  privilegeCreditPlan = () => {
    let { userSubscription, teamCreditPlans, teamSubscription } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Padding.pl_2,
          Padding.pr_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
        ]}
      >
        <View
          style={[Flex.justfiyCenter, Flex.alignCenter, Helpers.textCenter]}
        >
          {userSubscription &&
            userSubscription.plan.tmc_group != "individual" ? (
            <View
              style={[
                Flex.row,
                Flex.justfiyCenter,
                Flex.alignCenter,
                Margin.mb_4,
                Padding.pv_2,
                Padding.ph_4,
                { borderWidth: 1, borderColor: "#FF3D00" },
              ]}
            >
              <CustomImage
                source={infoIcon}
                require={true}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: "contain",
                  marginTop: 3,
                }}
                webStyle={{
                  width: 20,
                  height: 20,
                  objectFit: "contain",
                  marginTop: 3,
                }}
              />
              <Text style={[Padding.pl_2, { color: "#FF3D00" }]}>
                You cannot purchase corporate plans as you are already
                subscribed to an {userSubscription.plan.tmc_group} plan.
              </Text>
            </View>
          ) : null}

          {teamSubscription ? (
            <View style={[Flex.alignCenter, Margin.mb_4]}>
              <Text
                style={[
                  Padding.pb_2,
                  {
                    fontSize: helpers.isDesktop() ? 34 : 30,
                    lineHeight: helpers.isDesktop() ? 43 : 38,
                    fontFamily: FontFamily.abrilFatface,
                    fontWeight: FontWeight.regular,
                    color: "#000",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Privilege Credits Plan
              </Text>
              {helpers.isDesktop() ? (
                <View
                  style={{
                    borderWidth: helpers.isDesktop() ? 2 : 2,
                    borderColor: "#5869EA",
                    width: helpers.isDesktop() ? 500 : "100%",
                    backgroundColor: "#5869EA",
                  }}
                />
              ) : (
                <PageBreak
                  style={[
                    Margin.mt_1,
                    { width: "50%" },
                    !helpers.isWeb() && { width: 200 },
                  ]}
                  darkMode={darkMode}
                />
              )}
            </View>
          ) : (
            <View style={[Flex.alignCenter, Margin.mb_4]}>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 36 : 30,
                    lineHeight: helpers.isDesktop() ? 43 : 38,
                    fontFamily: FontFamily.abrilFatface,
                    fontWeight: FontWeight.regular,
                    color: "#000",
                  },
                  !helpers.isWeb() && { textAlign: "center" },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Don’t like seat based? Buy a privilege credit plan instead!
              </Text>
              <PageBreak
                style={[
                  Margin.mt_3,
                  { width: helpers.isDesktop() ? "12%" : "50%" },
                  !helpers.isWeb() && { width: 200 },
                ]}
                darkMode={darkMode}
              />
            </View>
          )}

          <View
            style={[
              Margin.mb_1,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb800,
              !helpers.isDesktop() && { marginBottom: 20 },
            ]}
          >
            {teamSubscription ? (
              <>
                {teamSubscription?.is_team_owner ? (
                  <Text
                    style={[
                      {
                        fontSize: 18,
                        lineHeight: 28,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.9)",
                        opacity: 0.85,
                      },
                      !helpers.isWeb() && Helpers.textCenter,
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    You are currently on our privelge credits plan. With this
                    plan you can freely distribute credits however you want to
                    your team. Go to the dashboard and assign a number of
                    credits to each member. Credits never expire so you own them
                    forever. Use them at free will to unlock stories that
                    interest you and the team.
                  </Text>
                ) : (
                  <Text
                    style={[
                      {
                        fontSize: 18,
                        lineHeight: 28,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.9)",
                        opacity: 0.85,
                      },
                      !helpers.isWeb() && Helpers.textCenter,
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    You are currently a member of a team that has subscribed to
                    our privelge credits plan. With this plan the credits are
                    assigned to you by your team owner. You can use the credits
                    to unlock stories that interest you and the team.
                  </Text>
                )}
              </>
            ) : (
              <>
                <Text
                  style={[
                    Padding.pb_2,
                    {
                      fontSize: 18,
                      lineHeight: 28,
                      letterSpacing: 0.3,
                      fontFamily: helpers.isWeb()
                        ? FontFamily.regular
                        : FontFamily.semiBold,
                      fontWeight: "600",
                      color: "rgba(0,0,0,0.9)",
                      opacity: 0.85,
                    },
                    !helpers.isWeb() && Helpers.textCenter,
                    darkMode && { color: Colors.lineColor },
                  ]}
                >
                  Pay for only what you read!
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 18,
                      lineHeight: 28,
                      letterSpacing: 0.3,
                      fontFamily: FontFamily.regular,
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.9)",
                      opacity: 0.85,
                    },
                    !helpers.isWeb() && Helpers.textCenter,
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Introducing Team Privilege credits. Purchase privilege credits
                  for your organisation and enjoy a reading experience tailored
                  for your reading habit. Once you run out of credits, simply
                  recharge and keep reading.
                </Text>
              </>
            )}
          </View>

          <View
            style={[
              Margin.mt_3,
              Margin.mb_2,
              !helpers.isDesktop() && Margin.mb_4,
              Helpers.textCenter,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb800,
            ]}
          >
            <View
              style={[
                Flex.row,
                Flex.FlexWrap,
                Flex.justfiyCenter,
                !helpers.isDesktop() && Padding.pb_15,
                { paddingHorizontal: 20, margin: "auto" },
              ]}
            >
              <View
                style={[
                  Flex.row,
                  Flex.alignStart,
                  Margin.mb_4,
                  helpers.isDesktop() && Padding.pl_3,
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  { order: 1, textAlign: "left", alignItems: "center" },
                ]}
              >
                <CustomImage
                  source={chooseCredits}
                  require={true}
                  style={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                  webStyle={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                />
                <View style={{ paddingLeft: 20, flex: 1 }}>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 20 : 20,
                        lineHeight: helpers.isDesktop() ? 24 : 24,
                        fontFamily: FontFamily.regular,
                        fontWeight: FontWeight.regular,
                        color: "#000",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Pay only for what you read
                  </Text>
                </View>
              </View>
              <View
                style={[
                  Flex.row,
                  Flex.alignStart,
                  Margin.mb_4,
                  helpers.isDesktop() && Padding.pl_3,
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  { order: 1, textAlign: "left", alignItems: "center" },
                ]}
              >
                <CustomImage
                  source={chooseCredits}
                  require={true}
                  style={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                  webStyle={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                />
                <View style={{ paddingLeft: 20, flex: 1 }}>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 20 : 20,
                        lineHeight: helpers.isDesktop() ? 24 : 24,
                        fontFamily: FontFamily.regular,
                        fontWeight: FontWeight.regular,
                        color: "#000",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Zero renewal hassle
                  </Text>
                </View>
              </View>
              <View
                style={[
                  Flex.row,
                  Flex.alignStart,
                  Margin.mb_4,
                  helpers.isDesktop() && Padding.pl_3,
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  { order: 1, textAlign: "left", alignItems: "center" },
                ]}
              >
                <CustomImage
                  source={chooseCredits}
                  require={true}
                  style={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                  webStyle={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                />
                <View style={{ paddingLeft: 20, flex: 1 }}>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 20 : 20,
                        lineHeight: helpers.isDesktop() ? 24 : 24,
                        fontFamily: FontFamily.regular,
                        fontWeight: FontWeight.regular,
                        color: "#000",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Large team size
                  </Text>
                </View>
              </View>
              <View
                style={[
                  Flex.row,
                  Flex.alignStart,
                  Margin.mb_4,
                  helpers.isDesktop() && Padding.pl_3,
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  { order: 1, textAlign: "left", alignItems: "flex-start" },
                ]}
              >
                <CustomImage
                  source={chooseCredits}
                  require={true}
                  style={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                  webStyle={{
                    width: helpers.isDesktop() ? 30 : 30,
                    height: helpers.isDesktop() ? 30 : 30,
                  }}
                />
                <View style={{ paddingLeft: 20, flex: 1 }}>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 20 : 20,
                        lineHeight: helpers.isDesktop() ? 24 : 24,
                        fontFamily: FontFamily.regular,
                        fontWeight: FontWeight.regular,
                        color: "#000",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Monthly reading summary
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 18,
                        lineHeight: helpers.isDesktop() ? 27 : 27,
                        letterSpacing: 0.3,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.9)",
                        opacity: 0.85,
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    (coming soon)
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {teamSubscription?.is_team_owner && (
            <Text
              style={[
                Margin.mt_2,
                Margin.mb_5,
                {
                  fontSize: 24,
                  lineHeight: 29,
                  fontFamily: helpers.isWeb()
                    ? FontFamily.regular
                    : FontFamily.semiBold,
                  fontWeight: FontWeight.semiBold,
                  color: "#000",
                  textAlign: "center",
                },
                !helpers.isDesktop() && { paddingBottom: 30 },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              Current available credits:{" "}
              <Text
                style={{
                  fontSize: 36,
                  lineHeight: 38,
                  fontFamily: helpers.isWeb()
                    ? FontFamily.medium
                    : FontFamily.bold,
                  fontWeight: FontWeight.semiBold,
                  color: "#FAB720",
                }}
              >
                {teamSubscription.team_details.credits_available}
              </Text>
            </Text>
          )}

          {teamSubscription?.is_team_owner == false ? (
            this.renderTeamBox()
          ) : (
            <View
              style={[
                helpers.isDesktop() && Flex.row,
                Flex.justfiyCenter,
                !helpers.isWeb() && { marginLeft: 15, marginRight: 15 },
              ]}
            >
              {teamCreditPlans &&
                teamCreditPlans.map((plan, pindex) => {
                  return (
                    <>
                      <View
                        key={pindex}
                        style={[
                          Flex.alignCenter,
                          helpers.isDesktop() && Margin.mt_2,
                          Margin.mb_1,
                          {
                            maxWidth: helpers.isDesktop() ? "30.33%" : "100%",
                            marginLeft: helpers.isDesktop() ? 10 : 0,
                            marginRight: helpers.isDesktop() ? 10 : 0,
                          },
                          helpers.isDesktop() && {
                            transform: [{ scale: pindex == 1 ? 1.1 : 0.92 }],
                          },
                        ]}
                      >
                        <View style={{ width: "100%" }}>
                          {teamSubscription?.plan?.id == plan.id ? (
                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                                {
                                  backgroundColor: "#3AACFF",
                                  paddingVertical: 10,
                                  paddingHorizontal: 20,
                                  position: "absolute",
                                  top: -15,
                                  left: 0,
                                  right: 0,
                                },
                                !helpers.isWeb() && {
                                  top: 0,
                                  marginLeft: -10,
                                  marginRight: -10,
                                },
                              ]}
                            >
                              <Text
                                style={{
                                  width: "100%",
                                  fontSize: 14,
                                  lineHeight: 17,
                                  fontFamily: FontFamily.semiBold,
                                  fontWeight: FontWeight.semiBold,
                                  color: "white",
                                  textTransform: "uppercase",
                                  textAlign: "center",
                                }}
                              >
                                Purchased on:{" "}
                                {moment(teamSubscription.purchase_date).format(
                                  "Do MMMM YYYY"
                                )}
                              </Text>
                            </View>
                          ) : (
                            <View style={[Flex.alignCenter, { flex: 1 }]}>
                              {plan.recommended_plan && (
                                <View
                                  style={[
                                    Flex.row,
                                    Flex.alignEnd,
                                    {
                                      backgroundColor: "#FAB720",
                                      paddingVertical: 10,
                                      paddingHorizontal: 26,
                                      position: "absolute",
                                    },
                                    !helpers.isDesktop() && { top: -15 },
                                  ]}
                                >
                                  <CustomImage
                                    source={star}
                                    require={true}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 6,
                                    }}
                                    webStyle={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 6,
                                    }}
                                    resizeMode="contain"
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 17,
                                      fontFamily: FontFamily.regular,
                                      fontWeight: FontWeight.semiBold,
                                      color: "#000",
                                    }}
                                  >
                                    Recommended
                                  </Text>
                                </View>
                              )}
                            </View>
                          )}
                        </View>
                        <View
                          style={[
                            Flex.alignCenter,
                            helpers.isDesktop() && Margin.mt_2,
                            Margin.mb_3,
                            {
                              borderWidth: 1.4,
                              borderColor: "#737BF6",
                              backgroundColor: "#fff",
                              paddingHorizontal: 25,
                              paddingTop: helpers.isWeb() ? 40 : 45,
                              paddingBottom: 20,
                              zIndex: -1,
                            },
                            darkMode && {
                              backgroundColor: Colors.darkBlackColor,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 24,
                                lineHeight: 29,
                                fontFamily: helpers.isWeb()
                                  ? FontFamily.medium
                                  : FontFamily.bold,
                                fontWeight: FontWeight.semiBold,
                                color: "#000",
                              },
                              darkMode && { color: Colors.lightWhite },
                            ]}
                          >
                            {plan.invoice_name}
                          </Text>
                          {Platform.OS != "ios" && (
                            <View
                              style={[
                                helpers.isDesktop() && Padding.pt_3,
                                !helpers.isDesktop() && Padding.pt_1,
                                Flex.row,
                                Flex.alignStart,
                              ]}
                            >
                              <Text
                                style={{
                                  fontSize: 24,
                                  lineHeight: 40,
                                  fontFamily: FontFamily.medium,
                                  fontWeight: FontWeight.medium,
                                  color: "#FC876D",
                                }}
                              >
                                {plan.currency_code == "INR" ? "₹" : "$"}{" "}
                              </Text>
                              <Text
                                style={{
                                  fontSize: 36,
                                  lineHeight: 43,
                                  fontFamily: helpers.isWeb()
                                    ? FontFamily.regular
                                    : FontFamily.semiBold,
                                  fontWeight: FontWeight.semiBold,
                                  color: "#FC876D",
                                }}
                              >
                                {plan.tax_exclusive_price}
                              </Text>
                            </View>
                          )}
                          <View
                            style={[
                              Flex.row,
                              Padding.pt_3,
                              { alignItems: "flex-start", width: "100%" },
                            ]}
                          >
                            <View style={{ minWidth: 14 }}>
                              <CustomImage
                                source={checkIcon}
                                require={true}
                                style={{
                                  width: 20,
                                  height: 14,
                                  resizeMode: "contain",
                                  marginTop: 6,
                                }}
                                webStyle={{
                                  width: 20,
                                  height: 14,
                                  objectFit: "contain",
                                  marginTop: 6,
                                }}
                              />
                            </View>
                            <View
                              style={[
                                Padding.pb_1,
                                Flex.row,
                                {
                                  alignItems: helpers.isWeb()
                                    ? "end"
                                    : "center",
                                  paddingLeft: 10,
                                },
                                !helpers.isWeb() && {
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: 0,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: 16,
                                    lineHeight: 28,
                                    fontFamily: helpers.isWeb()
                                      ? FontFamily.medium
                                      : FontFamily.bold,
                                    color: "#000",
                                    marginRight: 10,
                                  },
                                  helpers.isWeb() && { fontWeight: "700" },
                                  darkMode && { color: Colors.lightWhite },
                                ]}
                              >
                                <Text
                                  style={[
                                    {
                                      fontFamily: FontFamily.regular,
                                      fontWeight: "400",
                                      color: "rgba(0,0,0,0.9)",
                                      opacity: 0.85,
                                    },
                                    darkMode && { color: Colors.lightWhite },
                                  ]}
                                >
                                  Get a total of
                                </Text>{" "}
                                {plan?.description?.unlock_desc
                                  ? plan.description.unlock_desc
                                  : 0}{" "}
                                Credits
                              </Text>
                              <CustomImage
                                source={goldCoin}
                                require={true}
                                style={{ width: 20, height: 20 }}
                                webStyle={{ width: 20, height: 20 }}
                                resizeMode="contain"
                              />
                            </View>
                          </View>
                          {plan?.description?.access_desc && (
                            <View
                              style={[
                                !helpers.isDesktop() && Padding.pt_1,
                                Padding.pb_3,
                              ]}
                            >
                              {plan.description.access_desc.map(
                                (desc, dindex) => {
                                  return (
                                    <View
                                      style={[
                                        Flex.row,
                                        { alignItems: "flex-start" },
                                      ]}
                                    >
                                      <View style={{ minWidth: 14 }}>
                                        <CustomImage
                                          source={checkIcon}
                                          require={true}
                                          style={{
                                            width: 20,
                                            height: 14,
                                            resizeMode: "contain",
                                            marginTop: 6,
                                          }}
                                          webStyle={{
                                            width: 20,
                                            height: 14,
                                            objectFit: "contain",
                                            marginTop: 6,
                                          }}
                                        />
                                      </View>
                                      <Text
                                        key={dindex}
                                        style={[
                                          {
                                            fontSize: 16,
                                            lineHeight: 28,
                                            letterSpacing: 0.3,
                                            fontFamily: FontFamily.regular,
                                            fontWeight: "400",
                                            color: "rgba(0,0,0,0.9)",
                                            opacity: 0.85,
                                            textAlign: "left",
                                            paddingLeft: 10,
                                            paddingBottom: 10,
                                          },
                                          !helpers.isWeb() && {
                                            paddingHorizontal: 20,
                                          },
                                          darkMode && {
                                            color: Colors.lightWhite,
                                          },
                                        ]}
                                      >
                                        {desc}
                                      </Text>
                                    </View>
                                  );
                                }
                              )}
                            </View>
                          )}
                          {Platform.OS != "ios" && (
                            <>
                              {pindex == 1 ? (
                                <ButtonGradient
                                  title={
                                    teamSubscription?.plan?.tmc_group ==
                                    "team_credit"
                                      ? "BUY MORE"
                                      : "SELECT PLAN"
                                  }
                                  rounded={true}
                                  fullWidth={true}
                                  rootStyle={{
                                    btnWrapper: [
                                      button.primaryGradientBtn,
                                      {
                                        height: 46,
                                        borderRadius: 0,
                                        margin: 0,
                                      },
                                      !helpers.isDesktop() && { width: "100%" },
                                      !helpers.isWeb() && {
                                        width: windowWidth - 40,
                                      },
                                    ],
                                    btnText: [
                                      button.primaryGradientText,
                                      {
                                        fontSize: helpers.isDesktop() ? 17 : 16,
                                        fontWeight: "400",
                                        paddingTop: helpers.isDesktop()
                                          ? 0
                                          : 15,
                                        paddingBottom: helpers.isDesktop()
                                          ? 0
                                          : 15,
                                      },
                                    ],
                                  }}
                                  disable={userSubscription ? true : false}
                                  onClick={() =>
                                    this.subscribeToPlan(
                                      plan.id,
                                      plan,
                                      teamSubscription?.plan?.tmc_group ==
                                        "team_credit"
                                        ? "buy more"
                                        : "select plan"
                                    )
                                  }
                                  darkMode={darkMode}
                                />
                              ) : (
                                <Button
                                  title={
                                    teamSubscription?.plan?.tmc_group ==
                                    "team_credit"
                                      ? "BUY MORE"
                                      : "SELECT PLAN"
                                  }
                                  rounded={true}
                                  fullWidth={true}
                                  rootStyle={{
                                    btnWrapper: [
                                      button.primaryOutline,
                                      helpers.isWeb() && { width: "100%" },
                                      !helpers.isWeb() && {
                                        width: windowWidth - 40,
                                        position: "relative",
                                        overflow: "hidden",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      },
                                    ],
                                    btnText: [
                                      button.primaryOutlineText,
                                      {
                                        fontWeight: "400",
                                        fontSize: 17,
                                        paddingVertical: 5,
                                      },
                                      !helpers.isWeb() && { top: 3 },
                                    ],
                                  }}
                                  disable={userSubscription ? true : false}
                                  onClick={() =>
                                    this.subscribeToPlan(plan.id, plan)
                                  }
                                  darkMode={darkMode}
                                />
                              )}
                            </>
                          )}
                        </View>
                      </View>
                    </>
                  );
                })}
            </View>
          )}
        </View>
      </View>
    );
  };

  subscribersQuotes = () => {
    let { staticContent } = this.state;
    const videoId = "495152664?&portrait=0&byline=0&title=0&background=1";
    const videoIdUrl =
      "https://player.vimeo.com/video/495152664?&portrait=0&byline=0&title=0&background=1";
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          !helpers.isDesktop() && { marginTop: 30 },
        ]}
      >
        <View
          style={[
            !helpers.isDesktop() && Margin.mb_4,
            Helpers.textCenter,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          <View
            style={[Padding.pl_2, Padding.pr_2, Flex.alignCenter, Margin.mb_2]}
          >
            <Text
              style={[
                {
                  fontSize: helpers.isDesktop() ? 34 : 30,
                  lineHeight: helpers.isDesktop() ? 43 : 38,
                  fontFamily: FontFamily.abrilFatface,
                  fontWeight: FontWeight.regular,
                  color: "#000",
                },
                Helpers.textCenter,
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              Hear from our subscribers
            </Text>
            <PageBreak
              style={[
                Margin.mt_3,
                { width: helpers.isDesktop() ? "20%" : "70%" },
              ]}
              darkMode={darkMode}
            />
          </View>
          {/* <Text style={[{ fontSize: helpers.isDesktop() ? 18 : 15, lineHeight: helpers.isDesktop() ? 25 : 20, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0,0,0,0.9)', opacity: 0.85 }]}>What they love about TMC team plan</Text> */}
          {!helpers.isDesktop() && (
            <VimeoVideo
              videoId={videoId}
              videoIdUrl={videoIdUrl}
              darkMode={darkMode}
            />
          )}
          <View
            style={[
              helpers.isDesktop() && Flex.row,
              { width: "100%", alignItems: "center" },
            ]}
          >
            <View
              style={[
                Padding.pl_2,
                Padding.pr_2,
                !helpers.isDesktop() && {
                  backgroundColor: darkMode
                    ? Colors.darkBlackColor1
                    : "#EBFBED",
                  paddingTop: 15,
                },
                { width: helpers.isDesktop() ? "50%" : "100%" },
                !helpers.isWeb() && { marginTop: 20 },
              ]}
            >
              {!helpers.isDesktop() && (
                <View
                  style={{
                    position: "absolute",
                    top: -14,
                    left: 0,
                    right: 0,
                    alignItems: "center",
                  }}
                >
                  <CustomImage
                    source={quoteLeft}
                    require={true}
                    style={{ width: 40, height: 35, resizeMode: "contain" }}
                    webStyle={{ width: 40, height: 35, objectFit: "contain" }}
                  />
                </View>
              )}
              <HomeReadersQuote
                data={staticContent.readers_quotes}
                viewArticle={(catSlug, slug, postData) =>
                  this.viewArticle(catSlug, slug, postData)
                }
                viewAuthor={(slug) => this.viewAuthor(slug)}
                viewCategory={(slug) => this.viewCategory(slug)}
                teamSubscribers={true}
                darkMode={darkMode}
              />
            </View>
            {helpers.isDesktop() && (
              <View style={{ width: "50%", height: 385 }}>
                <VimeoVideo videoId={videoId} darkMode={darkMode} />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  stayStepAhead = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Padding.pl_2,
          Padding.pr_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1000,
        ]}
      >
        <View
          style={[
            !helpers.isDesktop() && Margin.mb_4,
            Helpers.textCenter,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          <View style={[Flex.alignCenter, Margin.mb_4, Margin.mt_2]}>
            <Text
              style={[
                {
                  fontSize: helpers.isDesktop() ? 34 : 30,
                  lineHeight: helpers.isDesktop() ? 43 : 38,
                  fontFamily: FontFamily.abrilFatface,
                  fontWeight: FontWeight.regular,
                  color: "#000",
                },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              Stay a step ahead
            </Text>
            <PageBreak
              style={[
                Margin.mt_3,
                { width: helpers.isDesktop() ? "12%" : "70%" },
              ]}
              darkMode={darkMode}
            />
          </View>
          <Text
            style={[
              {
                fontSize: 18,
                lineHeight: 25,
                letterSpacing: 0.3,
                fontFamily: FontFamily.regular,
                fontWeight: "400",
                color: "rgba(0,0,0,0.9)",
                opacity: 0.85,
              },
              !helpers.isWeb() && { textAlign: "center" },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            Need a customised subscription plan for your organisation? Write to
            priya@mailtmc.com. Our Team subscription is designed to be cost
            effective and reduce the time spent on administration, so you can
            spend your valuable time in reading us.
          </Text>
          <CustomImage
            source={customized_subscription}
            require={true}
            style={{
              width: 250,
              height: 150,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            webStyle={{
              width: "100%",
              height: "auto",
              maxWidth: helpers.isDesktop() ? 520 : 270,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 20,
              marginBottom: 20,
            }}
            resizeMode="contain"
            altText={"Subscription"}
          />
        </View>
      </View>
    );
  };

  setSeats = (value) => {
    let key = value.split("");
    let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    for (var i = key.length - 1; i >= 0; i--) {
      if (keyList.indexOf(key[i]) == -1) {
        return false;
      }
    }
    this.setState({ seats: value }, () => {
      if (value && parseInt(value) > 1) {
        this.getEstimate();
      }
    });
  };

  abortRequest = () => {
    if (this.controller) {
      this.controller.abort();
    }
  };

  getEstimate = () => {
    this.abortRequest();
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    let signal = this.signal;

    let { teamPlan, token, seats, teamSubscription } = this.state;
    let endpoint = `/subscription-estimate`;
    let body = {
      plan_id: teamPlan.id,
      quantity:
        parseInt(seats) +
        (teamSubscription?.plan?.tmc_group == "team"
          ? parseInt(teamSubscription.team_details?.seats_purchased)
          : 0),
    };
    this.setState({ enableLoader: true });
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token, signal)
      .then((res) => {
        if (res.success && res.code == "subscription_estimate") {
          let discountPer =
            res.data.discount_provided > 0 &&
              res.data.individual_plan_amount > 0
              ? (res.data.discount_provided / res.data.individual_plan_amount) *
              100
              : 0;
          this.setState({
            totalAmount: res.data.amount_due - res.data.total_tax_amount,
            fullAmount: res.data.individual_plan_amount,
            discountPer: Math.round(discountPer),
            enableLoader: false,
          });
        } else {
          this.setState({ enableLoader: false });
          console.log("ResError=>>", res);
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        if (error && error.aborted) {
          // Do nothing
        } else {
          this.setState({ enableLoader: false });
        }
      });
  };

  pushPurchasePlanInteractionEventToDataLayer = (subscription, btnTitle) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "purchase_plan_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in " : "guest",
      plan_price: subscription.price,
      plan_name: subscription.name?.toLowerCase(),
      CTA_text: btnTitle.toLowerCase(),
      scroll_depth: helpers.getScrollDepth(),
    });
  };

  pushPurchasePlanInteractionEventToDataLayer = (subscription, btnTitle) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "purchase_plan_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in " : "guest",
      plan_price: subscription.price,
      plan_name: subscription.name?.toLowerCase(),
      CTA_text: btnTitle.toLowerCase(),
      scroll_depth: helpers.getScrollDepth(),
    });
  };


  subscribeToPlan = (subscribtionId, subscribtion, btnTitle) => {
    try {
      console.log("try");
      this.setPlanButtonLoaderState(true);
      this.pushPurchasePlanInteractionEventToDataLayer(subscribtion, btnTitle)
      window.webengage.track("Click Select Plan", {
        "Type of User": this.state?.token
          ? this.state?.freeUser
            ? "Free"
            : "Paid"
          : "non-logged in",
        "Component Name": "TeamSubscription",
        "Plan Name": subscribtion?.name,
      });
      console.log("success");
    } catch (err) { }
    let { country, seats, profileData, token } = this.state;
    if (token && profileData) {
      if (profileData?.email && helpers.isFreeEmailId(profileData.email)) {
        this.setState({ showWorkEmailPopup: true });
        return;
      }
    }
    try {
      Mixpanel.track("click_select_plan_btn", {
        plan: subscribtion.name,
        price: subscribtion.price,
        discount:
          subscribtion.discount && subscribtion.discount.amount ? "yes" : "no",
      });
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    if (subscribtion.tmc_group == "team") {
      this.refs.header.showSubscriptionPlan(
        subscribtionId,
        subscribtion,
        country,
        "",
        seats
      );
    } else {
      this.refs.header.showSubscriptionPlan(
        subscribtionId,
        subscribtion,
        country
      );
    }
  };

  teamSubscriptionPlan = () => {
    let {
      userSubscription,
      teamPlan,
      seats,
      totalAmount,
      fullAmount,
      discountPer,
      enableLoader,
      teamSubscription,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (teamSubscription) {
      return (
        <View
          style={[
            Padding.pl_2,
            Padding.pr_2,
            Margin.mb_1,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1000,
          ]}
        >
          <View
            style={[
              Flex.alignCenter,
              !helpers.isDesktop() && Margin.mb_4,
              Helpers.textCenter,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
            ]}
          >
            <View style={[Flex.alignCenter, Margin.mb_4]}>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 36 : 30,
                    lineHeight: helpers.isDesktop() ? 43 : 38,
                    fontFamily: FontFamily.abrilFatface,
                    fontWeight: FontWeight.regular,
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                Seat based Plan
              </Text>
              <PageBreak
                style={[
                  Margin.mt_3,
                  { width: helpers.isDesktop() ? "12%" : "50%" },
                  !helpers.isWeb() && { width: 150 },
                ]}
                darkMode={darkMode}
              />
            </View>
            {teamSubscription?.is_team_owner ? (
              <>
                <Text
                  style={[
                    {
                      fontSize: 18,
                      lineHeight: 25,
                      letterSpacing: 0.3,
                      fontFamily: FontFamily.regular,
                      fontWeight: "400",
                      color: "rgba(0,0,0,0.9)",
                      opacity: 0.85,
                    },
                    !helpers.isWeb() && Helpers.textCenter,
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  You are currently on our seat based plan. With this, your team
                  members get unlimited access to all the stories and
                  newsletters on TMC. This is a yearly subscription.
                </Text>
                <Text
                  style={[
                    Margin.mt_2,
                    Margin.mb_2,
                    {
                      fontSize: 24,
                      lineHeight: 29,
                      fontFamily: FontFamily.regular,
                      fontWeight: FontWeight.semiBold,
                      color: "#000",
                    },
                    !helpers.isDesktop() && { paddingBottom: 30 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Current available seats:{" "}
                  <Text
                    style={{
                      fontSize: 36,
                      lineHeight: 38,
                      fontFamily: FontFamily.medium,
                      fontWeight: FontWeight.semiBold,
                      color: "#FAB720",
                    }}
                  >
                    {teamSubscription.team_details.seats_available}
                  </Text>
                </Text>
              </>
            ) : (
              <Text
                style={[
                  {
                    fontSize: 18,
                    lineHeight: 25,
                    letterSpacing: 0.3,
                    fontFamily: FontFamily.regular,
                    fontWeight: "400",
                    color: "rgba(0,0,0,0.9)",
                    opacity: 0.85,
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                You are currently a member of a team that has subscribed to our
                seat based plan. As member, youget unlimited access to all the
                stories and newsletters on TMC.
              </Text>
            )}
            {this.renderTeamBox()}
          </View>
        </View>
      );
    }
    return (
      <View
        style={[
          Padding.pl_2,
          Padding.pr_2,
          Margin.mb_1,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1000,
        ]}
      >
        <View
          style={[
            Flex.alignCenter,
            !helpers.isDesktop() && Margin.mb_4,
            Helpers.textCenter,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
          ]}
        >
          {/* {userSubscription &&
                        <View style={[Flex.row, Flex.justfiyCenter, Flex.alignCenter, Margin.mb_4, Padding.pv_2, Padding.ph_4, { borderWidth: 1, borderColor: '#FF3D00' }]}>
                            <CustomImage
                                source={infoIcon}
                                require={true}
                                style={{ width: 20, height: 20, resizeMode: 'contain', marginTop: 3 }}
                                webStyle={{ width: 20, height: 20, objectFit: 'contain', marginTop: 3 }}
                            />
                            <Text style={[Padding.pl_2, { color: '#FF3D00' }]}>You cannot purchase corporate plans as you are already subscribed to an {userSubscription.plan.tmc_group} plan.</Text>
                        </View>
                    } */}
          {/* <View style={[Flex.alignCenter, Margin.mb_4]}>
                        <Text style={[{ fontSize: helpers.isDesktop() ? 36 : 30, lineHeight: helpers.isDesktop() ? 43 : 38, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, color: '#000' }, !helpers.isWeb() && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>Don’t like Privilege Credits? Buy a seat based plan instead!</Text>
                        <PageBreak style={[Margin.mt_3, { width: helpers.isDesktop() ? '12%' : '50%' }, !helpers.isWeb() && { width: 200 }]} darkMode={darkMode} />
                    </View> */}

          <View
            style={[
              Flex.alignCenter,
              Margin.mb_4,
              {
                borderWidth: 1.4,
                borderColor: "#737BF6",
                backgroundColor: "#fff",
                paddingHorizontal: helpers.isDesktop() ? 40 : 20,
                paddingVertical: 30,
                width: helpers.isDesktop() ? 700 : "100%",
              },
              darkMode && { backgroundColor: Colors.darkBlackColor },
            ]}
          >
            <Text
              style={[
                {
                  paddingBottom: 20,
                  fontSize: helpers.isDesktop() ? 20 : 16,
                  lineHeight: 28,
                  letterSpacing: 0.3,
                  fontFamily: FontFamily.light,
                  fontWeight: "300",
                  color: "rgba(0,0,0,0.9)",
                  opacity: 0.85,
                },
                !helpers.isWeb() && { textAlign: "center" },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              We understand the unique needs of all of our readers. Purchase
              seats for your team, which gives them unlimited access to all our
              stories and newsletters. A seat based plan is a yearly
              subscription and best for a team with frequent readers.
            </Text>
            {Platform.OS != "ios" && (
              <>
                <View style={[Flex.row, Flex.alignCenter]}>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 20 : 16,
                        lineHeight: 29,
                        fontFamily: FontFamily.regular,
                        fontWeight: FontWeight.regular,
                        color: "#000",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Enter the number of seats
                  </Text>
                </View>
                <TextInput
                  placeholderTextColor={Colors.placeholderTextColor}
                  style={[
                    {
                      fontSize: 36,
                      lineHeight: 43,
                      fontFamily: FontFamily.medium,
                      fontWeight: FontWeight.semiBold,
                      borderWidth: 2,
                      borderColor: "#6F6F6F",
                      width: 250,
                      marginTop: 10,
                      marginBottom: 20,
                      textAlign: "center",
                      color: "#000",
                    },
                    !helpers.isWeb() && { height: 50 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                  onChangeText={(value) => this.setSeats(value)}
                  value={seats}
                  keyboardType={"numeric"}
                  defaultValue="5"
                />
                {parseInt(seats) >= 5 ? (
                  enableLoader ? (
                    <View
                      style={[{ marginBottom: helpers.isDesktop() ? 18 : 35 }]}
                    >
                      <ActivityIndicator size={25} color={"#787676"} />
                    </View>
                  ) : (
                    <>
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          Margin.mt_1,
                          Margin.mb_2,
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                            lineHeight: 40,
                            fontFamily: FontFamily.medium,
                            fontWeight: FontWeight.medium,
                            color: "#FC876D",
                          }}
                        >
                          {teamPlan.currency_code == "INR" ? "₹" : "$"}{" "}
                        </Text>
                        <Text
                          style={{
                            fontSize: 40,
                            lineHeight: 43,
                            fontFamily: FontFamily.medium,
                            fontWeight: FontWeight.semiBold,
                            color: "#FC876D",
                          }}
                        >
                          {totalAmount}
                        </Text>
                      </View>
                      {discountPer > 0 && (
                        <>
                          {helpers.isDesktop() ? (
                            <>
                              <View
                                style={[
                                  Flex.row,
                                  {
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  },
                                ]}
                              >
                                <>
                                  <View
                                    style={[
                                      Flex.row,
                                      Flex.alignStart,
                                      { marginBottom: 25 },
                                    ]}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 20,
                                        lineHeight: 40,
                                        fontFamily: FontFamily.medium,
                                        fontWeight: FontWeight.semiBold,
                                        color: "#BBBBBB",
                                      }}
                                    >
                                      {teamPlan.currency_code == "INR"
                                        ? "₹"
                                        : "$"}{" "}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: helpers.isDesktop() ? 30 : 18,
                                        lineHeight: 35,
                                        fontFamily: FontFamily.medium,
                                        fontWeight: FontWeight.semiBold,
                                        color: "#BBBBBB",
                                      }}
                                    >
                                      {fullAmount}
                                    </Text>
                                    <View
                                      style={{
                                        backgroundColor: "#BBBBBB",
                                        height: 2,
                                        width: "100%",
                                        position: "absolute",
                                        top: 18,
                                      }}
                                    />
                                  </View>
                                  <Text
                                    style={[
                                      {
                                        paddingHorizontal: 10,
                                        paddingBottom: 25,
                                        borderRadius: 30,
                                        fontSize: helpers.isDesktop() ? 22 : 18,
                                        lineHeight: 35,
                                        letterSpacing: 0.3,
                                        fontFamily: FontFamily.regular,
                                        fontWeight: "600",
                                        color: "#55C79A",
                                        opacity: 0.85,
                                      },
                                    ]}
                                  >
                                    ({discountPer}% OFF)
                                  </Text>
                                </>
                              </View>
                            </>
                          ) : (
                            <View
                              style={[
                                Flex.row,
                                {
                                  width: "100%",
                                  justifyContent: "center",
                                  marginBottom: 15,
                                },
                              ]}
                            >
                              <>
                                <Text
                                  style={[
                                    {
                                      marginVertical: 10,
                                      paddingHorizontal: 10,
                                      paddingVertical: 14,
                                      backgroundColor: "#61BA08",
                                      borderRadius: 30,
                                      fontSize: 18,
                                      lineHeight: 27,
                                      letterSpacing: 0.3,
                                      fontFamily: FontFamily.regular,
                                      fontWeight: "600",
                                      color: "white",
                                      opacity: 0.85,
                                      marginRight: 10,
                                    },
                                  ]}
                                >
                                  {discountPer}%
                                </Text>
                                <View
                                  style={[
                                    Flex.row,
                                    Flex.alignCenter,
                                    { marginVertical: 15 },
                                  ]}
                                >
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      lineHeight: 40,
                                      fontFamily: FontFamily.medium,
                                      fontWeight: FontWeight.semiBold,
                                      color: "#BBBBBB",
                                    }}
                                  >
                                    {teamPlan.currency_code == "INR"
                                      ? "₹"
                                      : "$"}{" "}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 36,
                                      lineHeight: 43,
                                      fontFamily: FontFamily.medium,
                                      fontWeight: FontWeight.semiBold,
                                      color: "#BBBBBB",
                                    }}
                                  >
                                    {fullAmount}
                                  </Text>
                                  <View
                                    style={{
                                      backgroundColor: "#BBBBBB",
                                      height: 2,
                                      width: "100%",
                                      position: "absolute",
                                      top: 21,
                                    }}
                                  />
                                </View>
                              </>
                            </View>
                          )}
                        </>
                      )}
                    </>
                  )
                ) : (
                  <Text
                    style={{
                      fontFamily: FontFamily.regular,
                      fontSize: helpers.isDesktop() ? 18 : 16,
                      lineHeight: 27,
                      color: "#F02121",
                      textAlign: "center",
                      marginBottom: helpers.isDesktop() ? 16 : 35,
                      letterSpacing: 0.3,
                      maxWidth: 550,
                      marginLeft: "auto",
                      marginRight: "auto",
                      paddingHorizontal: helpers.isDesktop() ? 30 : 0,
                    }}
                  >
                    *Minimum number of seats is 5
                  </Text>
                )}
                <ButtonGradient
                  title={"PROCEED TO CHECKOUT"}
                  rounded={true}
                  fullWidth={true}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradientBtn,
                      { height: 46, borderRadius: 0, margin: 0 },
                      !helpers.isDesktop() && { width: "100%" },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        fontWeight: "400",
                        paddingTop: helpers.isDesktop() ? 0 : 15,
                        paddingBottom: helpers.isDesktop() ? 0 : 15,
                      },
                    ],
                  }}
                  disable={
                    (userSubscription &&
                      userSubscription.plan.tmc_group != "individual") ||
                      !(parseInt(seats) >= 5 && enableLoader != true)
                      ? true
                      : false
                  }
                  onClick={() => this.subscribeToPlan(teamPlan.id, teamPlan, 'proceed to checkout')}
                  darkMode={darkMode}
                />
              </>
            )}
          </View>
        </View>
      </View>
    );
  };

  faq = () => {
    let webView = helpers.isWeb();
    let btnClickEmail;

    if (helpers.isWeb()) {
      btnClickEmail = {
        href: `mailto:priya@mailtmc.com`,
      };
    } else {
      btnClickEmail = {
        onPress: () => Linking.openURL(`mailto:priya@mailtmc.com`),
      };
    }
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          { paddingVertical: helpers.isDesktop() ? 70 : 20 },
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1000,
        ]}
      >
        <View style={[Flex.alignCenter, Margin.mb_4]}>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 34 : 24,
                lineHeight: helpers.isDesktop() ? 43 : 28,
                fontFamily: FontFamily.abrilFatface,
                fontWeight: FontWeight.regular,
                color: "#000",
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            FAQS
          </Text>
          <PageBreak
            style={[
              Margin.mt_3,
              { width: helpers.isDesktop() ? "12%" : "50%" },
            ]}
            darkMode={darkMode}
          />
        </View>
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            Padding.ph_2,
          ]}
        >
          {SECTIONS.map((citem, index) => {
            return (
              <AccordionListItem
                title={citem.title}
                index={index}
                teamSubscription={true}
                darkMode={darkMode}
              >
                <Text>{citem.content} </Text>
                {index == 5 && (
                  <View style={{ width: 200, height: 15 }}>
                    <TouchableOpacityLinkExternal {...btnClickEmail}>
                      <Text
                        style={[
                          {
                            textDecorationLine: "underline",
                            color: darkMode ? Colors.lightWhite : "#000",
                            fontFamily: FontFamily.merriweather,
                          },
                        ]}
                      >
                        priya@mailtmc.com
                      </Text>
                    </TouchableOpacityLinkExternal>
                  </View>
                )}
              </AccordionListItem>
            );
          })}
        </View>
      </View>
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderCreditWorkFlow() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View
          style={[
            Flex.alignCenter,
            { marginTop: helpers.isDesktop() ? 40 : 10 },
          ]}
        >
          <View
            style={[
              Flex.alignCenter,
              Margin.mb_4,
              Padding.pl_2,
              Padding.pr_2,
              { textAlign: "center" },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 24,
                  lineHeight: 29,
                  fontFamily: helpers.isWeb()
                    ? FontFamily.medium
                    : FontFamily.semiBold,
                  fontWeight: FontWeight.semiBold,
                  color: "#000",
                },
                !helpers.isWeb() && { textAlign: "center" },
                darkMode && { color: Colors.lightWhite },
              ]}
            >
              How seat based subscription works?
            </Text>
          </View>
        </View>
        <View>
          <CreditWorkFlow darkMode={darkMode} />
        </View>
      </>
    );
  }

  onSubmitCustomerForm = (values) => {
    console.log("Values is: ", values);
    const endpoint = "/forms/corporate-enquiry/submit/";
    apiHelpers
      .apiRequest(endpoint, values, "POST", true, false)
      .then((res) => {
        if (res.status == "success") {
          this.setState({ isCustomerDataSubmitted: true });
        }
      })
      .catch((error) => {
        this.setState({ isServerSideError: true });

        const res = error?.data;

        const errorMessage = Object.values(res).join(",");
        this.setState({ serverSideErrorMessage: errorMessage });
        console.error(error);
      });
  };

  renderPageContent = () => {
    const { teamPlan, staticContent, token, teamSubscription } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        <View
          style={[
            banner.Wrapper,
            {
              marginTop: helpers.isDesktop() ? -5 : 0,
              backgroundColor: "#FFFCF0",
            },
            darkMode && { backgroundColor: Colors.darkBlackColor },
          ]}
        >
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb940,
              {
                paddingTop: helpers.isDesktop() ? 70 : 40,
                paddingBottom: helpers.isDesktop() ? 70 : 40,
              },
            ]}
          >
            <View
              style={[
                helpers.isDesktop() && { flexDirection: "row" },
                Flex.alignCenter,
              ]}
            >
              <View
                style={[
                  { paddingHorizontal: 20 },
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  !helpers.isDesktop() && Flex.alignCenter,
                ]}
              >
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "100%" : "100%",
                      alignItems: helpers.isDesktop() ? "flex-end" : "center",
                    },
                    // helpers.isDesktop() && {
                    //   position: "absolute",
                    //   bottom: 0,
                    //   right: 0,
                    // },
                  ]}
                >
                  <CustomImage
                    source={noSubscription}
                    require={true}
                    style={{ width: "100%", height: 170 }}
                    webStyle={{
                      width: helpers.isDesktop() ? "100%" : "100%",
                      height: helpers.isDesktop() ? "auto" : "auto",
                    }}
                    resizeMode="contain"
                    altText={"Team Subscription"}
                  />
                </View>
                <View
                  style={[
                    !helpers.isDesktop() && {
                      flex: 1,
                      textAlign: "center",
                      alignItems: "center",
                    },
                    Padding.pb_2,
                    Padding.pt_2,
                  ]}
                >
                  <Text
                    accessibilityRole="header"
                    aria-level="1"
                    style={[
                      pageContent.sectionTitle,
                      helpers.isDesktop() && pageContent.sectionTitleDesktop,
                      helpers.isDesktop() &&
                      helpers.isWeb() && { whiteSpace: "nowrap" },
                      {
                        fontSize: helpers.isDesktop() ? 36 : 36,
                        lineHeight: helpers.isDesktop() ? 43 : 49,
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    Power your people!
                  </Text>
                  <View
                    style={[
                      {
                        height: 2,
                        backgroundColor: "#000000",
                        width: helpers.isDesktop() ? 160 : 50,
                        marginTop: 15,
                      },
                      darkMode && { backgroundColor: Colors.lineColor },
                    ]}
                  ></View>
                </View>
                <Text
                  style={[
                    pageContent.text,
                    helpers.isDesktop() && pageContent.textDesktop,
                    { marginBottom: helpers.isDesktop() ? 0 : 24 },
                    !helpers.isDesktop() && Helpers.textCenter,
                    helpers.isDesktop() && { fontSize: 20, lineHeight: 36 },
                    !helpers.isDesktop() && { fontSize: 16, lineHeight: 28 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Give your team the knowledge of independent, deeply researched
                  and well written stories with our unique team subscription
                  plans.
                </Text>
              </View>
              {!this.state.isCustomerDataSubmitted && (
                <CustomerForm
                  onSubmit={this.onSubmitCustomerForm}
                  showErrorMessage={this.state.isServerSideError}
                  serverSideErrorMessage={this.state.serverSideErrorMessage}
                />
              )}
              {this.state.isCustomerDataSubmitted && <ThankYouComponent />}
            </View>
          </View>
        </View>
        {teamSubscription == false && (
          <View
            style={[
              {
                backgroundColor: "#FAFAFA",
                paddingTop: helpers.isDesktop() ? 70 : 50,
                marginBottom: helpers.isDesktop() ? 0 : 40,
                zIndex: -1,
              },
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            {teamPlan && this.seatBasedPlan()}
          </View>
        )}

        {console.log("teamSubscription:", teamSubscription)}
        {console.log("teamPlan:", teamPlan)}
        {teamSubscription && (
          <View
            style={[
              {
                backgroundColor: "#FAFAFA",
                paddingTop: helpers.isDesktop() ? 70 : 50,
                marginBottom: helpers.isDesktop() ? 0 : 40,
              },
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            {teamPlan ? (
              this.teamSubscriptionPlan()
            ) : (
              <>
                {this.privilegeCreditPlan()}
                <CreditsInfo darkMode={darkMode} />
              </>
            )}
          </View>
        )}
        <View
          style={{
            backgroundColor: darkMode ? Colors.darkBlackColor : "#FAFAFA",
            paddingTop: 40,
          }}
        >
          <BenefitsPlan darkMode={darkMode} />
        </View>
        <View
          style={[
            {
              backgroundColor: "#FAFAFA",
              paddingTop: helpers.isDesktop() ? 50 : 25,
              paddingBottom: helpers.isDesktop() ? 10 : 15,
              marginBottom: helpers.isDesktop() ? 50 : 0,
            },
            !helpers.isDesktop() && { paddingTop: 40 },
            darkMode && { backgroundColor: Colors.darkBlackColorBg },
          ]}
        >
          <SubscriptionSponsers darkMode={darkMode} />
        </View>
        <View style={[{ marginBottom: helpers.isDesktop() ? 20 : 20 }]}>
          {staticContent &&
            staticContent.readers_quotes &&
            this.subscribersQuotes()}
        </View>
        {this.stayStepAhead()}
        {teamSubscription == false && (
          <View
            style={[
              {
                backgroundColor: "#FAFAFA",
                paddingTop: helpers.isDesktop() ? 70 : 50,
                paddingBottom: helpers.isDesktop() ? 50 : 20,
                marginBottom: 30,
              },
              darkMode && { backgroundColor: Colors.darkBlackColorBg },
            ]}
          >
            {this.privilegeCreditPlan()}
            <CreditsInfo darkMode={darkMode} />
          </View>
        )}
        {this.faq()}
      </>
    );
  };

  renderWebPage = () => {
    return <>{this.renderPageContent()}</>;
  };

  renderAppPage = () => {
    const { pageLoader, showErrorModal, footerData, showWorkEmailPopup } =
      this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/team-subscription",
      native: {
        redirect: "/team-subscription",
        verifyRedirect: "/team-subscription",
      },
    };
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <ScrollView>
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const { pageLoader, showErrorModal, footerData, showWorkEmailPopup } =
      this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/team-subscription",
      native: {
        redirect: "/team-subscription",
        verifyRedirect: "/team-subscription",
      },
    };
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && { backgroundColor: Colors.darkBlackColorBg },
        ]}
      >
        <Seo
          data={seoObj["team-subscription"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/team-subscription"
        />
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          refreshPage={() => this.refreshPage()}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
          setCheckoutLoaderState={(loaderState) =>
            this.setPlanButtonLoaderState(loaderState)
          }
          getCheckoutLoaderState={this.getPlanButtonLoaderState}
        />
        {pageLoader
          ? this.showPageLoader()
          : webView
            ? this.renderWebPage()
            : this.renderAppPage()}

        {showWorkEmailPopup && (
          <WorkEmailPopup
            closeModal={() => this.setState({ showWorkEmailPopup: false })}
            logout={() => this.logout()}
          />
        )}
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  container: {
    backgroundColor: "white",
    flex: 1,
    padding: 20,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  formLabel: {
    fontWeight: "bold",
  },
  formInput: {
    borderWidth: 1,
    borderColor: "gray",
    padding: 5,
    flex: 1,
  },
  formItem: {
    flexDirection: "column",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  formPicker: {
    borderWidth: 1,
    borderColor: "gray",
  },
  parentContainer: { boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px" },
});

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(TeamSubscription));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Team Subscription",
  },
];
