import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Dimensions,
  TextInput,
  Modal,
  StyleSheet,
} from "react-native";
import { FontFamily, button } from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";

const lockIcon = getImagePath("icons/lock_icon.png");


function CommonNudgeMobile(props) {
  const [modalVisible, setModalVisible] = useState(true);

  useEffect(() => {
    if (!modalVisible) {
      document.body.style.overflow = "visible";
      props.closeModal();
    }
  }, [modalVisible]);

  const openApp = () => {
    let os = helpers.getMobileOperatingSystem();
    console.log("os:", os);
    let url = "";
    if (os == "Android") {
      url =
        "https://play.google.com/store/apps/details?id=com.app.themorningcontext";
    } else if (os == "iOS") {
      url = "https://apps.apple.com/in/app/the-morning-context/id1551672701";
    } else {
      console.log("OS not supported for dynamic linking");
    }
    window.open(url, "_blank");
  };

  let darkMode = props.darkMode;
  let encodedRedirectURL = `${window.location.pathname}`;
  encodedRedirectURL = encodeURIComponent(encodedRedirectURL);

  let isOverseasUser = false;

  // logged in condition
  if (props.locationInfo?.countryInfo?.code !== "IN") {
    if (!props.isLoggedin) {
      isOverseasUser = true;
    }
    // If a logged in user has no  subscription
    else if (!props.userSubscriptionExpired) {
      isOverseasUser = true;
    }
  }

  const getBuyCreditsComponent = () => {
    return props.type == "non_privileged" ? (
      <View></View>
    ) : (
      null
    );
  };

  return (
    <View style={styles.centeredView}>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: "flex-end",
          }}
          activeOpacity={1}
          onPressOut={() => {
            setModalVisible(false);
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <TouchableWithoutFeedback>
              <View
                style={[
                  {
                    minHeight: "auto",
                    marginTop: "auto",
                    backgroundColor: "#FFF",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    zIndex: 9999,
                    paddingHorizontal: 16,
                    paddingTop: 60,
                    paddingBottom: 30,
                    cursor: "default",
                    position: "relative",
                  },
                ]}
              >
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    backgroundColor: "#0d7680",
                    height: 40,
                    marginBottom: 30,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#ffffff",
                      fontWeight: 600,
                      letterSpacing: "0.015em",
                      lineHeight: 40,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <CustomImage
                      source={lockIcon}
                      require={true}
                      style={{ width: 25, height: 25 }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        marginTop: 0,
                        verticalAlign: "bottom",
                        marginBottom: 4,
                        marginRight: 9,
                      }}
                    />
                    {props.getModalHeaderText()}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <View style={{ width: "100%" }}>
                    {props.getModalContentHeader(props.modalText, isOverseasUser)}
                    {props.getModalSubText(isOverseasUser)}
                    {props.getSubscribeComponent()}
                    {getBuyCreditsComponent()}
                    {getLoggedOutUserContent()}
                  </View>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );

  function getLoggedOutUserContent() {
    if (!props.isLoggedin) {
      return (
        <View
          style={{
            flexDirection: "row",
            marginTop: "15px",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              lineHeight: 16,
              color: "#52524f",
              fontFamily: "PlayfairDisplay-Regular",
            }}
          >
            Already have an account?&nbsp;
          </Text>
          <TouchableOpacity onPress={() => props.goToLogin()}>
            <Text
              style={{
                fontSize: 16,
                lineHeight: 16,
                fontWeight: 600,
                fontFamily: "PlayfairDisplay-Regular",
                textDecoration: "underline",
                color: "#52524f",
              }}
            >
              Log In
            </Text>
          </TouchableOpacity>
          <Text
            style={{
              fontFamily: "PlayfairDisplay-Regular",
              fontSize: 16,
              lineHeight: 16,
              color: "#52524f",
            }}
          >
            &nbsp;or&nbsp;
          </Text>
          <TouchableOpacity onPress={() => openApp()}>
            <Text
              style={{
                fontSize: 16,
                lineHeight: 16,
                fontWeight: 600,
                fontFamily: "PlayfairDisplay-Regular",
                textDecoration: "underline",
                color: "#52524f",
              }}
            >
              Open in app
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View></View>;
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#98B3B7",
    justifyContent: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerText: {
    color: "black",
    fontSize: 18,
    padding: 26,
  },
  noteHeader: {
    backgroundColor: "#42f5aa",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
  },
  footer: {
    flex: 1,
    backgroundColor: "#ddd",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  textInput: {
    alignSelf: "stretch",
    color: "black",
    padding: 20,
    backgroundColor: "#ddd",
    borderTopWidth: 2,
    borderTopColor: "#ddd",
  },
  addButton: {
    position: "absolute",
    zIndex: 11,
    right: 10,
    top: 8,
    backgroundColor: "#98B3B7",
    width: 25,
    height: 25,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    elevation: 8,
  },
  addButtonText: {
    color: "#fff",
    fontSize: 11,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    flex: 1,
    justifyContent: "flex-end",
  },
  centeredView: {
    position: "absolute",
    top: 100,
  },
});

export default CommonNudgeMobile;
