import React, { Component } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, FontFamily, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const fileNameIcon = getImagePath('icons/fileNameIcon.png');
const fileRemoveIcon = getImagePath('icons/fileRemoveIcon.png');

const fileNameIconDark = getImagePath('icons/fileNameIconDark.png');
const fileRemoveIconDark = getImagePath('icons/fileRemoveIconDark.png');

export class FileCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let webView = helpers.isWeb();

        let { name, removeCard, showDownload, darkMode, disableDownload, downloadFile } = this.props;

        return (
            <View style={[Flex.row, Flex.alignCenter, Flex.justifyBetween, { padding: 10, borderWidth: 2, borderColor: '#C4C4C4', borderRadius: 4, flexWrap: 'wrap' }]}>
                <View style={[Flex.row, Flex.alignCenter, Flex.fill, Padding.pr_2]}>
                    <CustomImage
                        source={darkMode ? fileNameIconDark : fileNameIcon}
                        require={true}
                        style={{ width: 30, height: 34, resizeMode: 'contain' }}
                        webStyle={{ width: 30, height: 34, objectFit: 'contain' }}
                    />
                    <Text style={[{ fontSize: helpers.isDesktop() ? 16 : 14, fontFamily: FontFamily.merriweather, color: darkMode ? Colors.lightWhite : '#2d2d2d', marginTop: 2, marginLeft: helpers.isDesktop() ? 20 : 10, width: '80%' }]}>{name}</Text>
                </View>
                <View>
                    {
                        showDownload ?
                            <TouchableOpacity
                                activeOpacity={0.8}
                                onPress={() => disableDownload ? {} : downloadFile(name)}
                            >
                                <Text style={{ fontSize: helpers.isDesktop() ? 16 : 16, fontWeight: '700', fontFamily: FontFamily.bold, color: '#FC876D', textDecorationLine: 'underline', opacity: (disableDownload ? 0.3 : 1) }}>{!helpers.isWeb() || (helpers.isWeb() && window && window.ReactNativeWebView) ? 'View' : 'Download'}</Text>
                            </TouchableOpacity>
                            :
                            <TouchableOpacity
                                activeOpacity={0.8}
                                onPress={() => removeCard(name)}
                            >
                                <CustomImage
                                    source={darkMode ? fileRemoveIconDark : fileRemoveIcon}
                                    require={true}
                                    style={{ width: 20, height: 20, resizeMode: 'contain' }}
                                    webStyle={{ width: 20, height: 20, objectFit: 'contain' }}
                                />
                            </TouchableOpacity>
                    }

                </View>
            </View>
        );
    }
}

export default FileCard;