import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator, FlatList } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, appStyles, article, FontFamily, pageContent, FontWeight, Colors, banner } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import getImagePath from '../../utils/image-helper';
import moment from 'moment';
import PageBreak from '../../components/page-break';
import CustomImage from '../../components/custom-image';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import ContentLoader from "react-native-easy-content-loader";
import { Mixpanel } from '../../utils/mixpanel';
import Seo from "../../components/seo-meta";
import UploadAdminDocsModal from '../../components/upload-admin-docs-modal';
import AuthenticationWrapper from '../../components/authentication-wrapper';

const cookies = new Cookies();

export class DocumentApproval extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            pageLoader: true,
            documentSlug: this.props.documentSlug
        }
    }

    componentDidMount = () => {

    }

    componentDidUpdate = (prevProps) => {

    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 20 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
            </View>
        )
    }

    renderPageContent = () => {
        const { documentSlug, token, pageLoader } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <View style={[!pageLoader && { height: '100%' }]}>

                </View>
                {documentSlug &&
                    <UploadAdminDocsModal closeModal={() => { }} documentSlug={documentSlug} token={token} darkMode={darkMode} hideLoader={() => this.setState({ pageLoader: false })} navigateToScreen={(slug) => { this.props.navigateToScreen(slug) }} />
                }
            </>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {

        const { pageLoader, showErrorModal, enableUpgradeModal, enableCancelSubModal, documentSlug } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/student-documents/' + documentSlug,
            native: { redirect: '/student-documents', documentSlug: documentSlug, verifyRedirect: '/student-documents' }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                    <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
                </ScrollView>
            </>
        )
    }

    render() {
        const { pageLoader, showErrorModal, enableUpgradeModal, enableCancelSubModal, documentSlug } = this.state;
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/student-documents/' + documentSlug,
            native: { redirect: '/student-documents', documentSlug: documentSlug, verifyRedirect: '/student-documents' }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={{}} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.refreshPage()} isPricing={true} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} documentSlug={documentSlug} showFullHeader={true} />
                {webView ?
                    this.renderWebPage()
                    :
                    this.renderAppPage()
                }
                {pageLoader && this.showPageLoader()}

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

export default connect(mapStateToProps)(AuthenticationWrapper(DocumentApproval));
