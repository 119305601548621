import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, TouchableOpacity, TextInput, Modal } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, FontFamily, input, Colors, teamSubscription } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import apiHelpers from "../../utils/api-helpers";


const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export class MemberConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowHeight * 2)),
            modalOpacity: new Animated.Value(0),
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        if (helpers.isWeb()) {
            Animated.timing(this.state.showModal, {
                toValue: (windowHeight * 2),
                duration: 300,
                delay: 0,
                useNativeDriver: true
            }).start();
            Animated.timing(this.state.modalOpacity, {
                toValue: 0,
                duration: 300,
                delay: 0,
                useNativeDriver: true
            }).start();

            if (helpers.isWeb()) {
                document.body.style.overflow = 'initial';
            }
            const { closeModal } = this.props;
            setTimeout(function () {
                closeModal();
            }, 300);
        }
        else {
            this.props.closeModal();
        }
    }

    removeMember = () => {
        const { removeMemberDetails } = this.props;
        this.setState({ disableBtn: true, showLoader: true });
        let endpoint = `/remove-team-member`;
        let body = {
            account_email: removeMemberDetails.member_email,
            name: removeMemberDetails.member_name
        }
        let { token } = this.props;
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return;
                }

                if (res.success && res.code == 'team_member_removed') {
                    this.setState({ showLoader: false });
                    this.closeModal();
                    this.props.refreshPage();
                }
                else {
                    this.setState({ showLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ showLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    renderPageContent = () => {
        const { showModal, modalOpacity, credits, disableBtn, showLoader } = this.state;
        const { topPadding, removeMemberDetails, darkMode } = this.props;

        return (
            <View style={[modalStyle.modalContainer, { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, { paddingBottom: 30 }, !helpers.isDesktop() && { flex: 0.7, padding: 16, width: '100%', backgroundColor: 'transparent' }, darkMode && { backgroundColor: helpers.isDesktop() ? Colors.darkBlackColor1 : 'transparent' }, !helpers.isWeb() && { width: windowWidth, flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.50)', justifyContent: 'center', alignItems: 'center' }]}>
                <View style={[!helpers.isDesktop() && { backgroundColor: 'white', paddingBottom: 30, margin: 20 }, { paddingHorizontal: 20 }, darkMode && { backgroundColor: Colors.darkBlackColor1 }, !helpers.isWeb() && { width: windowWidth - 40 }]}>
                    <Text style={[Padding.pt_2, Padding.pb_1, teamSubscription.title, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28, fontWeight: '600' }, darkMode && { color: Colors.lightWhite }]}>Confirm removal of <Text style={{ fontWeight: '600' }}>{removeMemberDetails.member_email}</Text></Text>
                    <Text style={[Padding.pb_3, { fontSize: 16, lineHeight: 20, fontFamily: FontFamily.regular, fontWeight: '400', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }, darkMode && { color: Colors.lightWhite }]}>Are you sure you want to remove the selected team member?</Text>
                    <View style={[Flex.row, Flex.justifyEnd, { alignItems: 'baseline' }, { alignItems: 'flex-end' }]}>
                        <Button
                            title={'Cancel'}
                            rootStyle={{
                                btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 100, backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }],
                                btnText: [button.whiteButtonText, { color: '#A97DF1', lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }],
                            }}
                            onClick={() => this.closeModal()}
                        />
                        <View style={{ width: helpers.isDesktop() ? 130 : 100, marginLeft: 30 }}>
                            <ButtonGradient
                                title={'Remove'}
                                fullWidth={true}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, flex: 1, width: helpers.isWeb() ? 'fit-content' : '100%' }],
                                    btnText: [button.primaryGradientText, { lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, paddingLeft: helpers.isDesktop() ? 25 : 18, paddingRight: helpers.isDesktop() ? 25 : 18, paddingTop: helpers.isDesktop() ? 13.5 : 11, paddingBottom: helpers.isDesktop() ? 13.5 : 11 }, Platform.OS == 'ios' && { paddingTop: 9, paddingBottom: 9 }]
                                }}
                                onClick={() => this.removeMember()}
                                disable={disableBtn}
                                loader={showLoader}
                                darkMode={darkMode}
                            />
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    render() {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity, credits, disableBtn, showLoader } = this.state;
        const { topPadding, removeMemberDetails, darkMode, showMemberConfirmation } = this.props;

        return (
            <>
                {helpers.isWeb() ?
                    <>
                        <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }]}>
                        </Animated.View>
                        <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { transform: [{ translateY: showModal }], backgroundColor: 'transparent' }]}>
                            {this.renderPageContent()}
                        </Animated.View>
                    </>
                    :
                    <>
                        <View style={[{ flex: 1, justifyContent: 'center', alignItems: 'center', width: windowWidth }]}>
                            <Modal animationType='slide' transparent={true} visible={showMemberConfirmation}>
                                {this.renderPageContent()}
                            </Modal>
                        </View>
                    </>
                }
            </>
        );
    }
}

export default MemberConfirmation;