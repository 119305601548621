import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform,Linking } from 'react-native';
import { postSingle, Flex, Padding, Margin, button, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors, input, bannerStyles, Messages, Typo, newsletter } from '../../styles/appStyles';

import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';
import Slider from '../slider/index-newsletter.js';
export class WriteForus extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    goToSubscribe = () => {
        let btnClickEmail;
        if (helpers.isWeb()) {
            btnClickEmail = {
                href: `mailto:grievances@themorningcontext.com`
            }
        }
        else {
            btnClickEmail = {
                onPress: () => Linking.openURL(`mailto:grievances@themorningcontext.com`)
            }
        }

    }

    render() {
        let webView = helpers.isWeb();
        const joinyoutubebannerweb = getImagePath("img/footerlogo.png")
        const joinyoutubebannermobile = getImagePath("img/illustration.png")
        let btnClickEmail;
        if (helpers.isWeb()) {
            btnClickEmail = {
                href: `mailto:pradip@mailtmc.com`
            }
        }
        else {
            btnClickEmail = {
                onPress: () => Linking.openURL(`mailto:pradip@mailtmc.com`)
            }
        }
        let {  darkMode,  data } = this.props;
        return (
            <View style={{ flexDirection: helpers.isDesktop() ? 'row' : 'column', justifyContent: 'space-evenly', backgroundColor: darkMode?'#121212':'#FFFFFF', alignContent: 'center', padding: helpers.isDesktop() ? 0 : 20, width: '100%' }}>
                {helpers.isDesktop() ?
                    <>
                        <View style={[{ zIndex: 2, width: '60%' }]}>
                            <Text accessibilityRole="header" aria-level="1" style={[bannerStyles.heading, { color:darkMode?'white': '#000000', fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 44 : 16, marginTop: 30, top: 5 }, helpers.isWeb() && { fontWeight: FontWeight.bold }]}>Write for Us</Text>
                            <View style={[newsletter.lineBreakwrite, Margin.mt_0, Margin.mb_1, helpers.isDesktop() && [Margin.mb_2, Margin.mt_1]]}></View>
                            <Text style={[bannerStyles.heading, { color:darkMode?'white': '#000000', width: helpers.isDesktop ? "60%" : 'auto', fontSize: helpers.isDesktop() ? 28 : 13, marginTop: 30, top: 5 }]}>
                                Have an idea for a newsletter that you think we can explore? Write to us.
                            </Text>
                            <View style={[helpers.isDesktop() && Flex.row, Margin.mt_2], { alignSelf: 'flex-start', marginTop: 20 }}>
                            <TouchableOpacityLinkExternal {...btnClickEmail}>
                                <ButtonGradient
                                    title={'SEND US YOUR PITCH'}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradient, { height: 50 }],
                                        btnText: [button.primaryGradientText, { fontSize: 14, paddingHorizontal: 25, paddingVertical: 10, fontWeight: '500', fontFamily: FontFamily.regular }]
                                    }}
                                    paddingOverride={true}
                                    onClick={null}
                                    darkMode={darkMode}
                                />
                            </TouchableOpacityLinkExternal>
                            </View>
                        </View>
                        <View style={[{ marginTop: helpers.isDesktop() ? 20 : 0 }, !helpers.isDesktop() && { backgroundColor: 'transparent' }]}>
                            {helpers.isDesktop() ?
                                <Slider data={data} teamSubscribers={true} darkMode={darkMode} />
                                :
                                <Slider data={data} teamSubscribers={true} teamSubscribersMob={true} darkMode={darkMode} />
                            }
                        </View>
                    </>
                    :
                    <>
                        <View style={[{ zIndex: 2, alignItems: "center" }]}>
                            <Text accessibilityRole="header" aria-level="1" style={[bannerStyles.heading, { color: helpers.isDesktop() ? darkMode?'white':'#000000' : '#051462', fontFamily: FontFamily.abrilFatface, fontSize: helpers.isDesktop() ? 44 : 34, marginTop: helpers.isDesktop() ? 30 : 5, top: 5 }, helpers.isWeb() && { fontWeight: FontWeight.bold, textAlign: 'center' }]}>Write for Us</Text>
                            <Text style={[bannerStyles.heading, { color: darkMode?'white':'#000000', width: '100%', fontSize: helpers.isDesktop() ? 28 : 18, marginTop: 30, top: 5, textAlign: "center" }]}>
                                Have an idea for a newsletter that you think we can explore? Write to us.
                            </Text>

                            <View style={[helpers.isDesktop() && Flex.row, Margin.mt_2], { marginTop: 20 }}>
                            <TouchableOpacityLinkExternal {...btnClickEmail}>
                                <ButtonGradient
                                    title={'SEND US YOUR PITCH'}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradient, { height: 40 }],
                                        btnText: [button.primaryGradientText, { fontSize: 14, paddingHorizontal: 25, fontWeight: '500', fontFamily: FontFamily.regular }]
                                    }}
                                    paddingOverride={true}
                                    onClick={null}
                                    darkMode={darkMode}
                                />
                                </TouchableOpacityLinkExternal>
                            </View>
                        </View>

                        <Slider data={data} homeQuote={true} darkMode={darkMode} isFromHome={true} />
                        {/* <View style={[Padding.ph_2, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginTop: 70, marginBottom: 30 }, !helpers.isDesktop() && { marginTop: 30, marginBottom: 60 }, { zIndex: 9, padding: 20, backgroundColor: '#FFFBF1' }]}>
                            {helpers.isDesktop() ?
                                <Slider data={data} teamSubscribers={true} darkMode={darkMode} />
                                :
                                <Slider data={data} teamSubscribers={true} teamSubscribersMob={true} darkMode={darkMode} />
                            }
                        </View> */}
                    </>
                }
            </View >
        );
    }
}

export default WriteForus;