import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  ScrollView,
  PermissionsAndroid,
  Platform,
  TextInput,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Colors,
  FontFamily,
  FontWeight,
  button,
  modalStyle,
  Typo,
  formStyle,
  unlockBox,
  article,
} from "../../styles/appStyles";
import Modal from "../modal";
import { helpers } from "../../utils/helpers";
import docHelpers from "../../utils/download-doc";
import ButtonGradient from "../../components/button-gradient";
import apiHelpers from "../../utils/api-helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import PageBreak from "../../components/page-break";

const windowHeight = Dimensions.get("window").height;

const closeIcon = getImagePath("icons/close-round.png");

class GiftClaimModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  componentDidUpdate = () => {};

  componentWillUnmount() {}

  closeModal = () => {
    this.props.closeGiftClaimModal();
  };

  closeModalNativeModal = () => {};

  renderModalContent = () => {
    let { giftData, darkMode } = this.props;
    let story = giftData.story;
    return (
      <View
        style={{
          paddingHorizontal: helpers.isDesktop() ? 40 : 20,
          paddingTop: 40,
          paddingBottom: 40,
          backgroundColor: darkMode ? Colors.darkBlackColor1 : "#ffffff",
        }}
      >
        <View
          style={[
            Margin.mb_2,
            !helpers.isDesktop() && Margin.mb_2,
            { alignItems: "center" },
          ]}
        >
          <View
            style={[
              Helpers.borderBottom,
              darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
            ]}
          >
            <Text
              style={[
                Helpers.textCenter,
                Typo.h3_R,
                helpers.isDesktop() && Typo.h3_RDesktop,
                Margin.mb_0,
                { fontSize: 18 },
                {
                  fontFamily: FontFamily.abrilFatface,
                  color: "#000",
                  textTransform: "capitalize",
                },
                { fontSize: helpers.isDesktop() ? 36 : 20 },
                darkMode && { color: "rgba(255,255,255,0.87)" },
              ]}
            ></Text>
          </View>
          <View style={[article.headerWrapper]}>
            <View style={[Flex.alignCenter]}>
              <View
                style={[
                  Padding.ph_2,
                  Margin.mb_2,
                  Helpers.titleBgWrap,
                  {
                    backgroundColor: darkMode
                      ? "#2d2d2d"
                      : "rgba(250 250 250,1)",
                  },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontSize: 18 },
                    { fontFamily: FontFamily.abrilFatface },
                    { fontSize: helpers.isDesktop() ? 30 : 18 },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {story.previously_unlocked
                    ? "Story already unlocked!"
                    : giftData?.already_claimed
                    ? "Gift already claimed"
                    : "You have claimed this gift"}
                </Text>
              </View>
              <PageBreak size={"small"} darkMode={darkMode} />
            </View>
          </View>
        </View>

        {!story.previously_unlocked && (
          <View style={[{ marginBottom: 30 }]}>
            <Text
              style={{
                fontSize: 14,
                lineHeight: 17,
                fontFamily: FontFamily.regular,
                fontWeight: helpers.isWeb() ? "500" : "500",
                color: darkMode ? Colors.lightWhite : "#000000",
                textAlign: "center",
              }}
            >
              {giftData?.already_claimed
                ? "You have previously claimed this story gift. Continue to read this story again."
                : "You can now read the below story that has been gifted to you."}
            </Text>
          </View>
        )}

        <View style={{ marginBottom: 30 }}>
          <View
            style={{
              maxWidth: 610,
              width: "100%",
              borderWidth: 2,
              borderColor: "#FF9302",
              marginLeft: "auto",
              marginRight: "auto",
              flexDirection: "row",
              paddingHorizontal: helpers.isDesktop() ? 20 : 15,
              paddingBottom: helpers.isDesktop() ? 25 : 15,
              paddingTop: helpers.isDesktop() ? 25 : 15,
            }}
          >
            <View style={{}}>
              <CustomImage
                source={story.featured_image}
                style={{ width: 63, height: 36, resizeMode: "contain" }}
                webStyle={{
                  width: helpers.isDesktop() ? 120 : 80,
                  height: helpers.isDesktop() ? 70 : 50,
                  objectFit: "contain",
                }}
              />
            </View>
            <View
              style={{
                marginLeft: helpers.isDesktop() ? 30 : 10,
                alignItems: "flex-start",
                flex: 1,
              }}
            >
              <Text
                style={[
                  Typo.h3_R,
                  helpers.isDesktop() && Typo.h3_RDesktop,
                  Margin.mb_0,
                  { fontFamily: FontFamily.regular },
                  {
                    fontSize: helpers.isDesktop() ? 18 : 14,
                    fontWeight: helpers.isWeb() ? "600" : "700",
                  },
                  darkMode && { color: Colors.lightWhite },
                ]}
              >
                {story.title.length < 30
                  ? story.title
                  : `${story.title.substring(0, 30)}...`}
              </Text>

              <View
                style={{
                  height: 1,
                  width: 95,
                  backgroundColor: darkMode ? "#bb26c8" : "#907CFF",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              ></View>

              <View
                style={[
                  {
                    flexDirection: "row",
                    justifyContent: helpers.isDesktop()
                      ? "center"
                      : "flex-start",
                    flexWrap: "wrap",
                  },
                ]}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: FontFamily.regular,
                    fontWeight: "500",
                    color: darkMode ? Colors.lightWhite : "#000000",
                    textAlign: helpers.isDesktop() ? "center" : "left",
                  }}
                >
                  {story.author[0]}
                </Text>

                <View
                  style={[
                    {
                      width: 2,
                      height: 2,
                      borderRadius: 100,
                      backgroundColor: "#6A6A6A",
                      marginHorizontal: 8,
                      marginTop: 1,
                      position: "relative",
                      top: 6,
                    },
                  ]}
                ></View>

                <Text
                  style={[
                    {
                      fontFamily: FontFamily.light,
                      fontWeight: FontWeight.light,
                      fontSize: 14,
                      color: "#000000",
                    },
                    darkMode && { color: "rgba(255, 255, 255, 0.6)" },
                  ]}
                >
                  {moment(story.date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {!story.previously_unlocked && (
          <Text
            style={{
              fontSize: 17,
              lineHeight: 20,
              fontFamily: FontFamily.regular,
              color: darkMode ? Colors.lightWhite : "#000000",
              textAlign: "center",
              marginBottom: 30,
            }}
          >
            Gifted by:{" "}
            <Text style={{ fontFamily: FontFamily.medium }}>
              {giftData.from}
            </Text>
          </Text>
        )}

        {story.previously_unlocked && (
          <View
            style={{
              maxWidth: 610,
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Text
              style={{
                fontSize: 14,
                lineHeight: 17,
                fontFamily: FontFamily.regular,
                fontWeight: helpers.isWeb() ? "500" : "500",
                color: darkMode ? Colors.lightWhite : "#000000",
                textAlign: "center",
              }}
            >
              {`Since this story is already unlocked, you can use the gifted ${
                story.credits
              } privilege ${
                parseInt(story.credits) == 1 ? "credit" : "credits"
              } that were allocated for this story to unlock and read any other story of your choice.`}
            </Text>
          </View>
        )}

        <View style={[{ marginTop: 20 }]}>
          <ButtonGradient
            title={story.previously_unlocked ? "CONTINUE" : "READ STORY"}
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: {
                color: "#ffffff",
                fontSize: 17,
                fontFamily: FontFamily.medium,
                fontWeight: "500",
                textAlign: "center",
                textTransform: "uppercase",
                position: "relative",
                zIndex: 2,
                letterSpacing: 0.5,
              },
            }}
            onClick={() => {
              this.closeModal();
            }}
            fullWidth={false}
            darkMode={darkMode}
          />
        </View>
      </View>
    );
  };

  render() {
    let darkMode = this.props.darkMode;
    return (
      <View
        style={[{ zIndex: 99999 }, !helpers.isWeb() && modalStyle.giftModalBg]}
      >
        {helpers.isWeb() ? (
          <Modal
            renderContent={() => this.renderModalContent()}
            closeModal={() => this.closeModal()}
            paddingHorizontal={true}
            topPadding={false}
            giftCardModal={true}
            subscription={true}
          />
        ) : (
          <>
            <View style={[{ height: "80%", position: "relative", top: 30 }]}>
              <ScrollView
                ref={(ref) => (this.appscrollview = ref)}
                style={[{ flex: 1 }]}
              >
                {this.renderModalContent()}
              </ScrollView>
            </View>
          </>
        )}
      </View>
    );
  }
}

export default GiftClaimModal;
