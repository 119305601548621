import React, { Component } from 'react';
import { Text, View, ScrollView, Platform, ActivityIndicator, Image, Modal, Linking, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, FontFamily, appStyles, actionBox, accessContent, exclusiveContent, textBlock, articleLoader, articleLists, FontWeight, button } from '../../styles/appStyles';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import PostContent from '../../components/post-content';
import PostTags from '../../components/post-tags';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import ErrorModal from '../../components/error-modal';
import Seo from "../../components/seo-meta";
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import AboutAuthor from '../../components/about-author';
import PageBreak from '../../components/page-break';
import ArticleList from '../../components/articles-list';
import NoteDisclosures from '../../components/note-disclosure';
import PostActionBar from '../../components/post-actionbar'
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from '../../utils/image-helper';
import DynamicPageHeader from '../../components/dynamic-page-header';
import NotFound from "../../components/not-found";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const overlayImage = getImagePath('img/excerpt_overlay.png');

const cookies = new Cookies();

export class DynamicPage extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        let tokenSet = (token == undefined || token == '') ? false : true;
        let postSlug = '';
        if (webView && this.props.postSlug) {
            postSlug = this.props.postSlug;
        }
        else if (!webView) {
            postSlug = this.props.route.params.postSlug;
        }
        this.state = {
            token: tokenSet ? token : false,
            postData: this.props.postData ? this.props.postData : '',
            postDataSSR: this.props.postData ? true : false,
            pageLoader: this.props.postData ? false : true,
            noPageFound: (this.props.postData?.api_code == 'no_page_found') ? true : false,
            postSlug: postSlug,
            userLoggedin: tokenSet,
            messageBox: {
                type: '',
                title: '',
                subTitle: '',
            },
            showErrorModal: (this.props.postData && this.props.postData.error) ? true : false,
            postUnlockError: false,
            footerData: this.props.footerData ? this.props.footerData : false,
            country: false,
            redirectSlugs: {
                web: '/' + postSlug,
                native: { redirect: 'dynamic-page', postSlug: postSlug, verifyRedirect: '/' + postSlug }
            }
        }

        this.subscriptionLayout = 0;
    }

    componentDidMount = () => {
        if (!this.state.postDataSSR) {
            this.getPost(this.props.scrollTop ? true : false);
        }
        else {
            this.setBreadcrumbs(this.state.postData);
        }
    }

    componentDidUpdate = () => {
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        let postSlug = webView ? this.props.postSlug : this.props.route.params.postSlug;
        if (!(this.props.searchQuery && (this.props.searchQuery.includes("subscribe=") || this.props.searchQuery.includes("buy-credits=true"))) && (((token && token != this.state.token) || ((token == undefined || token == '') && this.state.token != false)) || (postSlug != this.state.postSlug))) {
            let categorySlug = webView ? this.props.categorySlug : this.props.route.params.categorySlug;
            this.setState({
                token: (token == undefined || token == '') ? false : token,
                userLoggedin: (token == undefined || token == '') ? false : true,
                categorySlug: categorySlug,
                postSlug: postSlug
            }, () => this.getPost(true));
        }
    }

    setBreadcrumbs = (data) => {
        Breadcrumbs = [
            {
                name: 'Home',
                link: '/'
            },
            {
                name: data.story.title
            }
        ]
    }

    getPost = (scroll) => {
        this.setState({ showErrorModal: false, postUnlockError: false, pageLoader: true }, () => this.scrollToTop(scroll));
        const { token } = this.state;
        let endpoint = `/page/${this.state.postSlug}`;
        apiHelpers
            .apiRequest(endpoint, {}, "GET", true, token)
            .then((res) => {
                if (res.success && res.code == 'page_found') {
                    res.data.story = res.data.page;
                    this.setState({
                        postData: res.data,
                        pageLoader: false,
                    });
                    this.setBreadcrumbs(res.data);
                } else if (res.code == 'no_page_found') {
                    this.setState({ noPageFound: true });
                } else {
                    this.setState({ showErrorModal: true, pageLoader: false });
                }
            })
            .catch((error) => {
                this.setState({ showErrorModal: true, pageLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    scrollToTop = (scroll) => {
        if (scroll) {
            if (helpers.isWeb()) {
                setTimeout(function () {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }, 2);
            } else {
                if (this.appscrollview) {
                    this.appscrollview.scrollTo({ y: 0, animated: true })
                }
                if (this.contentscrollview) {
                    this.contentscrollview.scrollTo({ y: 0, animated: true })
                }
            }
        }
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    getModalClickEvent = () => {
        this.getPost();
    }

    goToSignUp = (email) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-up?redirect=/' + this.state.categorySlug + '/' + this.state.postSlug + (email ? ('&email=' + email) : ''));
        } else {
            this.props.navigation.push('sign-up', { redirect: 'post-single', categorySlug: this.state.categorySlug, postSlug: this.state.postSlug, verifyRedirect: '/' + this.state.categorySlug + '/' + this.state.postSlug, email: email });
        }
    }

    goToLogin = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/sign-in?redirect=/' + this.state.categorySlug + '/' + this.state.postSlug);
        } else {
            this.props.navigation.push('sign-in', { redirect: 'post-single', categorySlug: this.state.categorySlug, postSlug: this.state.postSlug, verifyRedirect: '/' + this.state.categorySlug + '/' + this.state.postSlug });
        }
    }

    changeFontSize = () => {
        console.log('FontSize Changed')
    }

    navigateToHome = () => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/');
        } else {
            this.props.navigation.navigate('homescreen');
        }
    }

    navigateToPost = (catSlug, slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.push('post-single', { categorySlug: catSlug, postSlug: slug });
        }
    }

    navigateToTag = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/tags/' + slug);
        } else {
            this.props.navigation.push('tags-archive', { tagSlug: slug });
        }
    }

    navigateToCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.push('category', { categorySlug: slug });
        }
    }

    navigateToAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.push('writer-single', { writerSlug: slug });
        }
    }

    openSocialMedia = (link) => {
        if (helpers.isWeb()) {
            window.open(link, '_blank');
        } else {
            // Linking.openURL(link);
            // this.props.navigation.navigate('in-app-browser', { url: link });
            this.refs.header.openLink(link);
        }
    }

    scrollToSection = (position) => {
        console.log("scrollToSection ==>", position)
        let offsetPosition = position
        if (helpers.isWeb()) {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(offsetPosition)
        }

    }

    renderPageContent = () => {

        const { postData, postDataSSR, pageLoader, userLoggedin, creditPlan, plansData, postUnlocked, userSubscriptionActive, userCredit, enableMessageBox, messageBox, userSubscriptionStatus, storyActions, storyStats, postUnlockError, userLoader, plansLoader, unlockLoader, insufficientCredits, userSubscription, country, writerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let postSummary;
        if (postData && postData.story) {
            postSummary = {
                postTitle: postData.story.title,
                author: postData.story.author,
                story: postData.story,
                lazyLoad: !postDataSSR
            }
        }

        let overlayStyle = { width: '100%', position: 'absolute', bottom: 0, height: helpers.isDesktop() ? '18%' : '9%' }

        return (
            <>
                <View style={{ marginBottom: helpers.isDesktop() ? 0 : 0 }}>
                    <DynamicPageHeader data={postData.story} darkMode={darkMode} />
                </View>
                <View style={[appStyles.appBg, darkMode && appStyles.appBgDark]}>
                    {postData.story.content &&
                        <View style={Padding.ph_2}>
                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull]}>
                                <PostContent data={postData.story.content} dynamicPage={true} darkMode={darkMode} openLink={this.openSocialMedia} />
                            </View>
                        </View>
                    }
                </View>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const { pageLoader, postData, showErrorModal, token, footerData, redirectSlugs, showInsufficientCreditsPopup, creditPlan, country, noPageFound } = this.state;

        return (
            <View style={[webView && null, { flex: 1, backgroundColor: 'white' }, darkMode && appStyles.appBgDark]}>
                <Seo data={(postData.seo_metadata) ? postData.seo_metadata : {}} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/:page_slug" pageData={postData} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.push(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.getPost()} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {noPageFound ?
                    <NotFound navigateToScreen={() => { this.props.navigateToScreen('/') }} darkMode={darkMode} />
                    :
                    <>{!showErrorModal && <>
                        {pageLoader ?
                            (webView
                                ? this.showPageLoader()
                                : <ScrollView ref={ref => this.contentscrollview = ref} style={{ flex: 1 }}>{this.showPageLoader()}</ScrollView>
                            )
                            :
                            (webView
                                ? this.renderPageContent()
                                : <ScrollView ref={ref => this.appscrollview = ref} style={{ flex: 1 }}>{this.renderPageContent()}
                                    <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.push(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
                                </ScrollView>
                            )
                        }
                    </>
                    }</>
                }


                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(DynamicPage));

let Breadcrumbs = [];