import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TextInput } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, FontFamily, FontWeight, Colors, article, Typo } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';

const slackImage = getImagePath('img/slackImage.png');
const tmcApp = getImagePath('img/tmcApp.png');
const tmcAppMobile = getImagePath('img/tmcAppMobile.png');
const windowWidth = Dimensions.get('window').width;

export class TmcAppSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {
        let webView = helpers.isWeb();
        let { darkMode } = this.props;
        return (
            <>
                <View style={!darkMode&&{ backgroundColor: '#F3F5FF' }}>
                    <View style={[Margin.mb_5, Margin.mb_5, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginTop: helpers.isDesktop() ? 50 : 20, paddingHorizontal:helpers.isDesktop() ? 0 : 20, width: helpers.isDesktop() ? '85%' : '100%' }]}>
                        <View style={[helpers.isDesktop() && Margin.mb_5, { display: 'flex', alignItems: 'center' }]}>
                            <Text style={{ fontFamily: FontFamily.regular,fontWeight:'500', fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: 38, color: darkMode ? Colors.lightWhite : '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 }}>The Morning Context App</Text>
                            <Text style={{ fontFamily: FontFamily.regular,fontWeight:'500', width: helpers.isDesktop() ? '50%' : '100%', textAlign: 'center', fontSize: helpers.isDesktop() ? 18 : 18, lineHeight: 28, color: darkMode ? Colors.lightWhite : '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3,marginTop:helpers.isDesktop() ? -10 : 0 }}>Subscribe and read TMC on the go, on our subscriber-only app</Text>
                        </View>
                        <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, !helpers.isDesktop() && Margin.mt_2, !helpers.isWeb() && { marginTop: 0 }]}>
                            {
                                helpers.isDesktop() ?
                                    <CustomImage
                                        source={tmcApp}
                                        require={true}
                                        style={{ width: 'auto', height: 300, resizeMode: 'contain' }}
                                        webStyle={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', objectFit: 'contain' }}
                                        altText={'TMC dialogues'}
                                    />
                                    :
                                    <CustomImage
                                        source={tmcAppMobile}
                                        require={true}
                                        style={{ width: 'auto', height: 300, resizeMode: 'contain' }}
                                        webStyle={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', objectFit: 'contain' }}
                                        altText={'TMC dialogues'}
                                    />
                            }
                        </View>
                    </View>
                </View>
            </>
        );
    }
}

export default TmcAppSlide;