import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';

export class TouchableOpacityLinkExternal extends Component {
    render() {
        return (
            <TouchableOpacity activeOpacity={0.8} {...this.props} />
        );
    }
}

export default TouchableOpacityLinkExternal;