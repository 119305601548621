import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, pageCover, button, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import Seo from "../../components/seo-meta";
import NotFound from "../../components/not-found";
import seoObj from "../../utils/seo-helper";
export class NotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerData: this.props.footerData ? this.props.footerData : false,
        }
    }

    componentDidMount = () => {

    }

    render() {
        const { footerData, redirectSlugs } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        let data = {
            pageName: 'Not Found'
        }

        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} darkMode={darkMode} showFullHeader={true} />
                <NotFound navigateToScreen={() => { this.props.navigateToScreen('/') }} darkMode={darkMode} />
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} />
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);