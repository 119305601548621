import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  TextInput,
  Modal,
  StyleSheet,
} from "react-native";
import { FontFamily, button } from "../../styles/appStyles";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import CommonNudgeWeb from "../common-nudge-web";


const twitterIcon = getImagePath("icons/story-twitter.png");
const linkedinIcon = getImagePath("icons/story-linkedin.png");
const googleIcon = getImagePath("icons/story-google.png");
const appleIcon = getImagePath("icons/story-apple.png");

const windowHeight = Dimensions.get("window").height;

function YesterdayModalWeb(props) {
  const [email, setEmail] = useState("");

  const goToLoginPricing = (encodedRedirectURL) => {
    if (props.postData?.story?.type == "non_privileged") {
      props.goToLogin();
    } else {
      props.navigateToScreen(
        "/pricing?header=true&success_redirect=" + encodedRedirectURL
      );
    }
  };

  const goToSubscribe = (encodedRedirectURL) => {
    props.navigateToScreen(
        "/pricing?subscribe=monthly-plan-usd&quantity=1" + encodedRedirectURL
      );
  }

  let darkMode = props.darkMode;
  let encodedRedirectURL = `${window.location.pathname}`;
  encodedRedirectURL = encodeURIComponent(encodedRedirectURL);

  let isOverseasUser = false;

  // logged in condition
  if (props.locationInfo?.countryInfo?.code !== "IN") {
    if (!props.isLoggedin) {
      isOverseasUser = true;
    }
    // If a logged in user has no  subscription
    else if (!props.userSubscriptionExpired) {
      isOverseasUser = true;
    }
  }

  const modalText = isOverseasUser
    ? "Get 30 days of unlimited access for $9.99."
    : `Clutter-free news with a quick, to-the-point perspective.`;


  const getSubscribeComponent = () => {
    if (props.postData?.story?.type === "non_privileged") {
      return (
        <View>
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",

                alignItems: "center",
                width: "450px",
                marginVertical: 15,
              }}
            >
              <TextInput
                style={{
                  borderColor: "#47ccc2",
                  borderWidth: 1,
                  width: "70%",
                  height: 36,
                  outline: "none",
                  paddingHorizontal: 10,
                  fontFamily: FontFamily.regular,
                  fontSize: 15,
                }}
                onChangeText={(value) => setEmail(value)}
                placeholder="Enter your email"
              />
              <View
                style={{
                  width: "30%",
                  display: "block",
                }}
              >
                <ButtonGradient
                  title={"SIGN ME UP"}
                  style={{
                    borderRadius: "0px !important",
                    outLine: "none",
                  }}
                  rootStyle={{
                    btnWrapper: [{ height: 36, borderRadius: 0, flex: 1 }],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: 16,
                        lineHeight: 36,
                        paddingHorizontal: 0,
                        fontWeight: "700",
                        fontFamily: FontFamily.regular,
                        borderRadius: 0,
                        textTransform: "uppecase",
                        textShadow: "0px 0px 2px rgba(255,255,255,0.6)",
                      },
                    ],
                  }}
                  paddingOverride={true}
                  onClick={() => props.goToSignUp(email)}
                  darkMode={darkMode}
                  isNewGradiant={true}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: FontFamily.bold,
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#000000",
                    marginRight: "20px",
                  }}
                >
                  Start your free trial with
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity onPress={() => props.goToGoogleLoginWeb()}>
                  <CustomImage
                    source={googleIcon}
                    require={true}
                    style={{ width: 25, height: 25 }}
                    webStyle={{
                      width: 30,
                      height: 30,
                      marginTop: 0,
                      verticalAlign: "bottom",
                      marginBottom: 4,
                      marginRight: 9,
                    }}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => props.goToAppleLoginWeb()}>
                  <CustomImage
                    source={appleIcon}
                    require={true}
                    style={{ width: 25, height: 25 }}
                    webStyle={{
                      width: 30,
                      height: 30,
                      marginTop: 0,
                      verticalAlign: "bottom",
                      marginBottom: 4,
                      marginRight: 9,
                    }}
                  />
                </TouchableOpacity>
                {/* <TouchableOpacity onPress={() => props.goToTwitterLoginWeb()}>
                  <CustomImage
                    source={twitterIcon}
                    require={true}
                    style={{ width: 25, height: 25 }}
                    webStyle={{
                      width: 30,
                      height: 30,
                      marginTop: 0,
                      verticalAlign: "bottom",
                      marginBottom: 4,
                      marginRight: 9,
                    }}
                  />
                </TouchableOpacity> */}
                <TouchableOpacity onPress={() => props.goToLinkedInLoginWeb()}>
                  <CustomImage
                    source={linkedinIcon}
                    require={true}
                    style={{ width: 25, height: 25 }}
                    webStyle={{
                      width: 30,
                      height: 30,
                      marginTop: 0,
                      verticalAlign: "bottom",
                      marginBottom: 4,
                      marginRight: 9,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View></View>;
    }
  };

  const getModalSubText = (isOverseasUser) => {
    if (props.postData?.story?.type === "non_privileged") {
      return (
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontSize: "24px",
              lineHeight: "28px",
              color: "#000",
              fontWeight: 400,
              fontFamily: "PlayfairDisplay",
              textAlign: "left",
              marginTop: 10,
            }}
          >
            {
              "100,000 people start their day with The Morning Context online newspaper. Read our Daily Edition and free stories every morning in your inbox."
            }
          </Text>
          <Text
            style={{
              fontSize: "24px",
              lineHeight: "29px",
              color: "#000",
              fontWeight: 400,
              fontFamily: "PlayfairDisplay",
              textAlign: "left",
              marginTop: 15,
            }}
          >
            {
              "Writing original and deeply reported stories, The Morning Context is India’s premier business publication."
            }
          </Text>
        </View>
      );
    } else if (!isOverseasUser) {
      return (
        <View>
          <Text
            style={{
              fontSize: "24px",
              lineHeight: "39px",
              color: "#000",
              fontWeight: 700,
              fontFamily: "PlayfairDisplay-Bold",
              textAlign: "left",
              marginTop: 20,
            }}
          >
            {`At ${props.locationInfo?.countryInfo?.code !== "IN" ? "$" : "INR "}${
              props.locationInfo?.countryInfo?.code == "IN" ? "240" : "8"
            } per month`}
          </Text>
          <View
            style={{
              alignItems: "flex-start",
              marginTop: 20,
              width: 325,
              marginLeft: "",
              marginRight: "",
            }}
          >
            <ButtonGradient
              title={"Explore subscription plans"}
              rootStyle={{
                btnWrapper: [
                  button.primaryGradient,
                  {
                    width: "100%",
                    paddingHorizontal: "16px",
                    paddingTop: "16px",
                    paddingBottom: "18px",
                    height: "auto",
                    backgroundColor: "transparent",
                  },
                ],
                btnText: [
                  button.primaryGradientText,
                  {
                    fontSize: "20px",
                    textTransform: "capitalize",
                    fontFamily: "PlayfairDisplay-Regular",
                    fontWeight: 600,
                  },
                ],
              }}
              onClick={() => {
                props.pushStoryNudgeInteractionToDataLayer(props.postData, "explore subscription plans", "monthly plan",  props.locationInfo?.countryInfo?.code == "IN" ? 240 : 8)
                goToLoginPricing(encodedRedirectURL);
              }}
              fullWidth={false}
              darkMode={darkMode}
              leftAligned={true}
              isNewGradiant={true}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={{
            alignItems: "flex-start",
            marginTop: 20,
            width: 325,
            marginLeft: "",
            marginRight: "",
          }}
        >
          <ButtonGradient
            title={"SUBSCRIBE NOW"}
            rootStyle={{
              btnWrapper: [
                button.primaryGradient,
                {
                  width: "100%",
                  paddingHorizontal: "16px",
                  paddingTop: "16px",
                  paddingBottom: "18px",
                  height: "auto",
                  backgroundColor: "transparent",
                },
              ],
              btnText: [
                button.primaryGradientText,
                {
                  fontSize: "20px",
                  textTransform: "capitalize",
                  fontFamily: "PlayfairDisplay-Regular",
                  fontWeight: 600,
                },
              ],
            }}
            onClick={() => {
              props.pushStoryNudgeInteractionToDataLayer(props.postData, "subscribe now", "Monthly Plan", 3)
              goToSubscribe(encodedRedirectURL);
            }}
            fullWidth={false}
            darkMode={darkMode}
            leftAligned={true}
            isNewGradiant={true}
          />
        </View>
      );
    }
  };

  const getModalContentHeader = (modalText, isOverseasUser) => {
    if (props.postData?.story?.type === "non_privileged") {
      return (
        <Text
          style={{
            fontSize: "35px",
            lineHeight: "39px",
            color: "#000",
            fontWeight: 700,
            fontFamily: "PlayfairDisplay-Bold",
            textAlign: "left",
          }}
        >
          {"Trusted Financial News"}
        </Text>
      );
    } else {
      return (
        <View>
          <Text
            style={{
              fontSize: "30px",
              lineHeight: "39px",
              color: "#000",
              fontWeight: 700,
              fontFamily: "PlayfairDisplay-Bold",
              textAlign: "left",
            }}
          >
            {modalText}
          </Text>
          {isOverseasUser && (
            <Text
              style={{
                fontSize: "30px",
                lineHeight: "39px",
                color: "#000",
                fontFamily: "PlayfairDisplay",
                textAlign: "left",
                marginTop: 10,
              }}
            >
              {props.isLoggedin
                ? "Clutter-free news with a quick, to-the-point perspective."
                : "Exclusive coverage on the Indian technology and business landscape."}
            </Text>
          )}
        </View>
      );
    }
  };


  const getModalHeaderText = () => {
    return props.postData?.story?.type == "non_privileged"
      ? "Sign up to read for free"
      : `Subscribe for unlimited access`;
  };

  return (
    <View>
      <CommonNudgeWeb
        {...props}
        getModalHeaderText={getModalHeaderText}
        getModalContentHeader={getModalContentHeader}
        type={props.postData?.access_type}
        getModalSubText={getModalSubText}
        modalText={modalText}
        getSubscribeComponent={getSubscribeComponent}
      />
    </View>
  );
}

export default YesterdayModalWeb;
