import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, TouchableOpacity, TextInput, Modal } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, FontFamily, input, Colors, teamSubscription } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import apiHelpers from "../../utils/api-helpers";

const closeIcon = getImagePath('icons/close-round.png');
const emailVerifyIcon = getImagePath('icons/email-verify.png');
const warningIcon = getImagePath('icons/unsuccessful.png');
const goldCoin = getImagePath('icons/coin.png');

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export class InviteMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowHeight * 2)),
            modalOpacity: new Animated.Value(0),
            email: '',
            name: '',
            credits: (parseInt(this.props.inviteCredits) > parseInt(this.props.maxCredits)) ? this.props.maxCredits : this.props.inviteCredits
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        if (helpers.isWeb()) {
            Animated.timing(this.state.showModal, {
                toValue: (windowHeight * 2),
                duration: 300,
                delay: 0,
                useNativeDriver: true
            }).start();
            Animated.timing(this.state.modalOpacity, {
                toValue: 0,
                duration: 300,
                delay: 0,
                useNativeDriver: true
            }).start();

            if (helpers.isWeb()) {
                document.body.style.overflow = 'initial';
            }
            const { closeModal } = this.props;
            setTimeout(function () {
                closeModal();
            }, 300);
        }
        else {
            this.props.closeModal();
        }
    }

    onChange = (value, field_key) => {
        const { teamMembers } = this.props;
        if (field_key == 'email') {
            let foundMember = teamMembers.find((member) => {
                return (member.member_email == value)
            })
            this.setState({ [field_key]: value, emailError: foundMember ? 'Email Id already invited' : false, disableBtn: foundMember ? true : false });
            return;
        }
        this.setState({ [field_key]: value });
    }

    setCredits = (value, field_key) => {
        this.setState({ creditsError: false, disableBtn: false });
        let key = value.split('');
        let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        for (var i = key.length - 1; i >= 0; i--) {
            if (keyList.indexOf(key[i]) == -1) {
                return false;
            }
        }
        if (value == '' || parseInt(value) <= this.props.maxCredits) {
            this.setState({ [field_key]: value });
        }
    }

    inviteMember = () => {
        let { email, name, credits } = this.state;
        let { withCredits, maxCredits } = this.props;

        if (!helpers.validateEmail(email)) {
            this.setState({ disableBtn: true, emailError: 'Please enter valid Email Id' })
            return;
        }
        this.setState({ disableBtn: true, showLoader: true });

        let endpoint = `/invite-team-member`;
        let body = {
            account_email: email,
            name: name
        }
        if (withCredits) {
            body.member_credits = (parseInt(credits) > 0) ? parseInt(credits) : 0;
        }
        let { token } = this.props;
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return;
                }

                if (res.success && res.code == 'Send_invite') {
                    this.setState({ showLoader: false });
                    this.closeModal();
                    if (withCredits) {
                        this.props.refreshPage(true, (parseInt(credits) > 0) ? credits : '0');
                    } else {
                        this.props.refreshPage();
                    }
                }
                else {
                    this.setState({ showLoader: false, emailError: res?.error?.account_email, creditsError: res?.error?.member_credits });
                }
            })
            .catch((error) => {
                this.setState({ showLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    renderPageContent = () => {
        const { showModal, modalOpacity, name, email, credits, emailError, creditsError, disableBtn, showLoader } = this.state;
        const { topPadding, withCredits, maxCredits, darkMode, showInviteMember } = this.props;

        return (
            <View style={[modalStyle.modalContainer, { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, { paddingBottom: 30 }, !helpers.isDesktop() && { flex: 0.7, padding: 16, width: '100%', backgroundColor: 'transparent' }, darkMode && { backgroundColor: helpers.isDesktop() ? Colors.darkBlackColor1 : 'transparent' }, !helpers.isWeb() && { width: windowWidth, flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.50)', justifyContent: 'center', alignItems: 'center' }]}>
                <View style={[!helpers.isDesktop() && { backgroundColor: 'white', paddingBottom: 30, margin: 20 }, { paddingHorizontal: 20 }, darkMode && { backgroundColor: Colors.darkBlackColor1 }, !helpers.isWeb() && { width: windowWidth - 40 }]}>
                    <Text style={[Padding.pt_2, Padding.pb_2, teamSubscription.title, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28 }, darkMode && { color: Colors.lightWhite }]}>Invite Member</Text>

                    <Text style={{ fontSize: helpers.isDesktop() ? 13 : 11, lineHeight: helpers.isDesktop() ? 16 : 13, fontFamily: FontFamily.regular, fontWeight: '400', color: '#A97DF1', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }}>Email</Text>
                    <TextInput
                        placeholder={'Email id'}
                        placeholderTextColor={Colors.placeholderTextColor1}
                        style={[input.Text, input.borderBottom, { fontSize: helpers.isDesktop() ? 17 : 14, paddingVertical: 8, borderColor: '#A97DF1', boderWidth: 0.3 }, darkMode && { color: Colors.lightWhite }]}
                        value={email}
                        onChangeText={(value) => this.onChange(value, 'email')}
                    />
                    {emailError &&
                        <Text style={[{ color: '#F02121', fontSize: 12, lineHeight: 14, fontFamily: FontFamily.regular, marginTop: 8 }]}>{emailError}</Text>
                    }

                    {withCredits &&
                        <>
                            <Text style={{ fontSize: helpers.isDesktop() ? 13 : 11, lineHeight: helpers.isDesktop() ? 16 : 13, fontFamily: FontFamily.regular, fontWeight: '400', color: '#979797', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, marginTop: 35 }}>{'Assign Credits (Max: ' + maxCredits + ')'}</Text>
                            <TextInput
                                placeholder={'Assign Credits (Max: ' + maxCredits + ')'}
                                placeholderTextColor={Colors.placeholderTextColor1}
                                style={[input.Text, input.borderBottom, { fontSize: helpers.isDesktop() ? 17 : 14, paddingVertical: 8, borderColor: 'rgba(153, 153, 153, 0.5)', boderWidth: 0.3 }, darkMode && { color: Colors.lightWhite }]}
                                value={credits}
                                onChangeText={(value) => this.setCredits(value, 'credits')}
                                keyboardType={'numeric'}
                            />
                            {creditsError &&
                                <Text style={[{ color: '#F02121', fontSize: 12, lineHeight: 14, fontFamily: FontFamily.regular, marginTop: 8 }]}>{creditsError}</Text>
                            }
                        </>
                    }

                    <Text style={{ fontSize: helpers.isDesktop() ? 13 : 11, lineHeight: helpers.isDesktop() ? 16 : 13, fontFamily: FontFamily.regular, fontWeight: '400', color: '#979797', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, marginTop: 35 }}>Name (optional)</Text>
                    <TextInput
                        placeholder={'Name'}
                        placeholderTextColor={Colors.placeholderTextColor1}
                        style={[input.Text, input.borderBottom, { fontSize: helpers.isDesktop() ? 17 : 14, paddingVertical: 8, borderColor: 'rgba(153, 153, 153, 0.5)', boderWidth: 0.3 }, darkMode && { color: Colors.lightWhite }]}
                        value={name}
                        onChangeText={(value) => this.onChange(value, 'name')}
                    />

                    <View style={[Flex.row, Flex.justifyEnd, Margin.mt_4, { alignItems: 'baseline' }, !helpers.isWeb() && { paddingBottom: 5 }]}>
                        <Button
                            title={'CANCEL'}
                            rootStyle={{
                                btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 90, backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }],
                                btnText: [button.whiteButtonText, { color: '#A97DF1', lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }],
                            }}
                            onClick={() => this.closeModal()}
                        />
                        <View style={[{ marginLeft: 30 }, Platform.OS != 'ios' && { width: helpers.isDesktop() ? 130 : 100 }]}>
                            <ButtonGradient
                                title={'INVITE'}
                                fullWidth={true}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradientBtn, Platform.OS != 'ios' && { height: 46, borderRadius: 0, width: helpers.isWeb() ? 'fit-content' : 100 }, helpers.isWeb() && { flex: 1 }],
                                    btnText: [button.primaryGradientText, { lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, paddingLeft: helpers.isWeb() ? 25 : 15, paddingRight: helpers.isWeb() ? 25 : 15, paddingTop: helpers.isDesktop() ? 13.5 : 11, paddingBottom: helpers.isDesktop() ? 13.5 : 11 }]
                                }}
                                onClick={() => this.inviteMember()}
                                disable={!email || disableBtn}
                                loader={showLoader}
                                darkMode={darkMode}
                            />
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    render() {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity, name, email, credits, emailError, creditsError, disableBtn, showLoader } = this.state;
        const { topPadding, withCredits, maxCredits, darkMode, showInviteMember } = this.props;

        return (
            <>
                {helpers.isWeb() ?
                    <>
                        <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }]}>
                        </Animated.View>
                        <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { transform: [{ translateY: showModal }], backgroundColor: 'transparent' }]}>
                            {this.renderPageContent()}
                        </Animated.View>
                    </>
                    :
                    <View style={[{ flex: 1, justifyContent: 'center', alignItems: 'center', width: windowWidth }]}>
                        <Modal animationType='slide' transparent={true} visible={showInviteMember}>
                            {this.renderPageContent()}
                        </Modal>
                    </View>
                }
            </>
        );
    }
}

export default InviteMember;