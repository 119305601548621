import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
  Modal,
  TouchableWithoutFeedback,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";

import Button from "../../components/button";
import ButtonGradient from "../../components/button-gradient";
import CustomImage from "../../components/custom-image";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import HomePopularCategories from "../../components/home-popular-categories";
import HomeReadersQuote from "../../components/home-newreader-quote";
import ErrorModal from "../../components/error-modal";
import HomeBanner from "../../components/home-banner";
import DarkModeSwitch from "../../components/darkmode-switch";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeUserToken } from "../../../redux/User/user.actions";
import { storeFcmToken } from "../../../redux/App/app.actions";
import { Mixpanel } from "../../utils/mixpanel";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import JoinYoutube from "../../components/joinYoutube";
import CreditExpiryBand from "../../components/credit-expiry-band";
import GeneralNotificationBand from "../../components/general-notification-band";
import HomeStarterCollection from "../../components/home-starter-collection";


import getImagePath from "../../utils/image-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import { HomeNewsletterCategories } from "../../components/home-newsletter-categories";
import { HomeYesterdayStory } from "../../components/home-yesterday-story";
import CorporateSubscriptionMini from "../../components/corporate-subscription-mini";
import GiftingMini from "../../components/gifting-mini";
import DynamicPageHeader from "../../components/dynamic-page-header";

import BreakingNews from "../../components/breaking-news";
import SectionTitle from "../../components/section-title";
import TodayEdition from "../../components/today-edition";
import PageLoader from "../../components/page-loader";
import { staticData } from "../../utils/static-data";

import moment from "moment";
import { ChargebeeController } from "../../utils/chargebee";
import GiftSubModal from "../../components/gift-sub-modal";
import SignUpAcquisition from "../sign-up-acquisition";


const cookies = new Cookies();

// Lazy components added here
const VerticalStory = React.lazy(() =>
  import("../../components/vertical-story")
);
const LatestStories = React.lazy(() =>
  import("../../components/latest-stories")
);
const TwoGridStory = React.lazy(() =>
  import("../../components/two-grid-story")
);
const LatestNewsletter = React.lazy(() =>
  import("../../components/latest-newsletter")
);
const HomeArchievedContent = React.lazy(() =>
  import("../../components/home-archived-content")
);
const YesterdaySection = React.lazy(() =>
  import("../../components/yesterday-section")
);
const InternetCoverage = React.lazy(() =>
  import("../../components/internet-coverage")
);
const StoryCoverage = React.lazy(() =>
  import("../../components/story-coverage")
);
const MultiCoverageStory = React.lazy(() =>
  import("../../components/multi-coverage-story")
);

export class Home extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      unpaidInvoiceLoader: false,
      canShowBandLoader: false,
      userSubscription: false,
      whatsTrendingContent:
        this.props.homeData && this.props.homeData.whatsTrendingContent
          ? this.props.homeData.whatsTrendingContent
          : false,
      latestStories:
        this.props.homeData && this.props.homeData.latestStories
          ? this.props.homeData.latestStories
          : false,
      featuredStory:
        this.props.homeData && this.props.homeData.featuredStory
          ? this.props.homeData.featuredStory
          : false,
      premiumStories:
        this.props.homeData && this.props.homeData.premiumStories
          ? this.props.homeData.premiumStories
          : false,
      userStarterCollection:
        this.props.homeData && this.props.homeData.userStarterCollection
          ? this.props.homeData.userStarterCollection
          : false,
      storiesForYou: false,
      readingList: false,
      subscriptionDetails: {},
      expiredSubscription: {},
      latestPremiumStories: this.props.homeData?.latestPremiumStories
        ? this.props.homeData.latestPremiumStories
        : [],
      trendingStoriesContent:
        this.props.homeData && this.props.homeData.trendingStoriesContent
          ? this.props.homeData.trendingStoriesContent
          : false,
      pageLoader: this.props.homeData ? false : true,
      pageLoaderTodaysEdition: true,
      pageLoaderTopStories: true,
      pageLoaderLatestStories: true,
      pageLoaderLatestNews: true,
      pageLoaderStarter: true,
      pageLoaderInternet: true,
      pageLoaderChaos: true,
      pageLoaderBusiness: true,
      pageLoaderStartups: true,
      pageLoaderEdtech: true,
      pageLoaderBanking: true,
      pageLoaderEnvt: true,
      pageLoaderCorporate: true,
      pageLoaderSpotlight: true,
      pageLoaderShorts: true,
      footerData: this.props.footerData ? this.props.footerData : false,
      showErrorModal: false,
      token: token == undefined || token == "" ? false : token,
      userLoggedin: token == undefined || token == "" ? false : true,
      homeContent:
        this.props.homeData && this.props.homeData.homeContent
          ? this.props.homeData.homeContent
          : false,
      exclusiveContent:
        this.props.homeData && this.props.homeData.exclusiveContent
          ? this.props.homeData.exclusiveContent
          : false,
      archivedStories: false,
      subscriptionRefreshKey: false,
      userHasSubscription:
        this.props.homeData && this.props.homeData.userHasSubscription
          ? this.props.homeData.userHasSubscription
          : false,
      writers: false,
      storyData: [],
      userPaymentInfo: null,
      latestNewsData: [],
      categoryData: [],
      categoryChaosData: [],
      categoryBusinessData: [],
      tagStartupData: [],
      tagEdtechData: [],
      tagBankingData: [],
      tagEnvtData: [],
      tagCorporateData: [],
      spotlightData: [],
      breakingNewsData: [],
      freeUser: false,
      giftedData: null,
      locationInfo: null,
      planCheckoutLoaderState: false,
      custom_user_id: false,
      open_gift_sub_modal: window.location.href.includes('show_gift_modal=true'),
      yesterdayStories: [],
      editionsLast24Hours: [],
      editionsLast24HoursLoading: true,
      breakingNewsDataLast24Hours: [],
      breakingNewsDataLast24HoursLoading: true
    };
    this.apiFetchRecord = {
      forLatestNews: 0,
      forSpotLight: 0,
      forYesterdayEdition: 0,
      forInternetCov: 0,
      forPublicMarketCov: 0,
      forChaosCov: 0,
      forStartups: 0,
      forEdtech: 0,
      forConglomerates: 0,
      forGiftingMini: 0,
    };
    this.topstories = React.createRef();
  }

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }


  componentDidMount = () => {
    const { token } = this.state;
    this.getData();
    if (!token) {
      this.getLocationInfo();
    }

    if (token) {
      ChargebeeController.activatedController().then((cb_handle) => {
        console.log("home:handle: ", cb_handle.activeSubscription);
        if (cb_handle?.activeSubscription) {
          this.setState({
            userSubscription: cb_handle.activeSubscription,
          });
        }
      });
    }
    this.getStaticContent();
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };

  setPlanButtonLoaderState = (state) => {
    this.setState({
      planCheckoutLoaderState: state,
    });
  };

  getPlanButtonLoaderState = () => {
    return this.state.planCheckoutLoaderState;
  };

  // componentDidUpdate = (prevProps) => {
  //   let webView = helpers.isWeb();
  //   let token = webView
  //     ? this.props.auth.fetchToken
  //     : this.props.user?.userData?.data?.token;
  //   if (
  //     (token && token != this.state.token) ||
  //     ((token == undefined || token == "") && this.state.token != false) ||
  //     (!helpers.isWeb() &&
  //       prevProps.app.refreshScreen != this.props.app.refreshScreen &&
  //       !this.props.navigation.isFocused())
  //   ) {
  //     let tokenSet = token == undefined || token == "" ? false : true;
  //     this.setState(
  //       {
  //         token: tokenSet ? token : false,
  //         userLoggedin: tokenSet,
  //         pageLoader: true,
  //         whatsTrendingContent: false,
  //         latestStories: false,
  //         featuredStory: false,
  //         premiumStories: false,
  //         userStarterCollection: false,
  //         storiesForYou: false,
  //         trendingLoader: true,
  //         freeUser: false,
  //         userInfoFetched: false,
  //         readingList: false,
  //         trendingStoriesContent: false,
  //         showErrorModal: false,
  //         homeContent: false,
  //         exclusiveContent: false,
  //         archivedStories: false,
  //         subscriptionRefreshKey: false,
  //         userHasSubscription: false,
  //       },
  //       () => {
  //         this.getData();
  //       }
  //     );
  //   }
  // };

  /**
   * API calls when navigating this page
   */
  getData = () => {
    // Breaking news
    this.getShorts();
    // Todays edition
    if (this.state.latestPremiumStories.length === 0) {
      this.getPremiumStories();
    }
    // News Explainers
    this.getEditions()
    // News Explainers -> Last 24 hours
    this.getEditionsLast24Hours()
    // News Briefs -> Last 24 hours
    this.getShortsLast24Hours()
    // User info
    this.getUserInfo();
    // Top stories
    if (
      this.state.featuredStory.length == 0 ||
      this.state.featuredStory == false
    ) {
      this.getFeaturedStories();
      // this.getTrending();
    }
    // Lazy load for latest newsletter
    if (this.state.latestNewsData.length === 0) {
      window.addEventListener("scroll", this.handleOnScroll);
    }
  };

  getGiftedData = () => {
    const { token } = this.state;
    let endpoint = `/page/gifting`;
    let body = {};

    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (
          res.success &&
          (res.code == "page_found" || res.code == "no_page_found")
        ) {
          console.log("gifting:", res.data);
          if (res.data) {
            this.setState({ giftedData: res.data.page });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down", error);
      });
  };
  getStaticContent = () => {
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: ["readers_quotes"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "content_found") {
          this.setState({ staticContent: res.data });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getLocationInfo = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
  }

  handleOnScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    // console.log('scroll:', scrollTop);
    // For latest newsletter
    if (
      scrollTop >= 400 &&
      scrollTop <= 2800 &&
      this.apiFetchRecord.forLatestNews == 0
    ) {
      this.getNewsletterStories();
      this.apiFetchRecord.forLatestNews++;
    }
    // spotlight and starter collection
    else if (
      scrollTop >= 1800 &&
      scrollTop <= 4500 &&
      this.apiFetchRecord.forSpotLight == 0
    ) {
      this.getSpotLight();
      if (!this.state.exclusiveContent) {
        this.getExclusiveContent();
      } else if (!this.state.archivedStories) {
        this.getArchivedStories(this.state.exclusiveContent);
      }
      this.apiFetchRecord.forSpotLight++;
    }
    // yesterday section & internet coverage
    else if (
      scrollTop >= 3200 &&
      scrollTop <= 6500 &&
      this.apiFetchRecord.forYesterdayEdition == 0
    ) {
      this.getEditions();
      this.getOtherArticles("internet");
      this.apiFetchRecord.forYesterdayEdition++;
    }
    // Public market and chaos coverage
    else if (
      scrollTop >= 5000 &&
      scrollTop <= 8500 &&
      this.apiFetchRecord.forPublicMarketCov == 0
    ) {
      this.getOtherArticles("business");
      this.getOtherArticles("chaos");
      this.apiFetchRecord.forPublicMarketCov++;
    }
    // startups and banking-finance
    else if (
      scrollTop >= 7800 &&
      scrollTop <= 12600 &&
      this.apiFetchRecord.forStartups == 0
    ) {
      this.getTagArticle("startups");
      this.getTagArticle("banking-finance");
      this.apiFetchRecord.forStartups++;
    }
    // edtech and environment
    else if (
      scrollTop >= 11000 &&
      scrollTop <= 15500 &&
      this.apiFetchRecord.forEdtech == 0
    ) {
      this.getTagArticle("edtech");
      this.getGiftedData();
      this.getTagArticle("environment");
      this.apiFetchRecord.forEdtech++;
    }
    // corporate
    else if (
      scrollTop >= 14500 &&
      scrollTop <= 16700 &&
      this.apiFetchRecord.forConglomerates == 0
    ) {
      this.getTagArticle("conglomerates");
      this.apiFetchRecord.forConglomerates++;
    }
  };

  getTagArticle = (tag) => {
    if (tag == "startups") {
      this.setState({ pageLoaderStartups: true });
    } else if (tag == "edtech") {
      this.setState({ pageLoaderEdtech: true });
    } else if (tag == "banking-finance") {
      this.setState({ pageLoaderBanking: true });
    } else if (tag == "environment") {
      this.setState({ pageLoaderEnvt: true });
    } else if (tag == "conglomerates") {
      this.setState({ pageLoaderCorporate: true });
    }
    const { token } = this.state;
    let endpoint = `/stories`;
    let body = {
      limit: 15,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      search_string: "",
      filters: [
        {
          key: "newsletter",
          value: ["include"],
        },
        { key: "storyplay", value: [tag] },
      ],
    };

    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let stories = res.data.story;
          if (tag == "startups") {
            this.setState({
              pageLoaderStartups: false,
              tagStartupData: stories,
            });
          } else if (tag == "edtech") {
            this.setState({ pageLoaderEdtech: false, tagEdtechData: stories });
          } else if (tag == "banking-finance") {
            this.setState({
              pageLoaderBanking: false,
              tagBankingData: stories,
            });
          } else if (tag == "environment") {
            this.setState({
              pageLoaderEnvt: false,
              tagEnvtData: stories,
            });
          } else if (tag == "conglomerates") {
            this.setState({
              pageLoaderCorporate: false,
              tagCorporateData: stories,
            });
          }
        } else {
          this.setState({
            pageLoaderStartups: false,
            pageLoaderEdtech: false,
            pageLoaderBanking: false,
            pageLoaderEnvt: false,
            pageLoaderCorporate: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        if (!error.aborted) {
          this.setState({
            // showErrorModal: true,
            pageLoaderStartups: false,
            pageLoaderEdtech: false,
            pageLoaderBanking: false,
            pageLoaderEnvt: false,
          });
        }
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getPost = () => {
    this.setState({
      // showErrorModal: false,
      pageLoaderInternet: true,
    });
    const { token } = this.state;
    let endpoint = `/category/internet`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success && res.code == "category_found") {
          this.setState(
            {
              categoryData: [res.data],
              pageLoaderInternet: false,
            },
            () => {}
          );
          this.getOtherArticles(res.data);
        } else {
          this.setState({
            // showErrorModal: true,
            pageLoaderInternet: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          // showErrorModal: true,
          pageLoaderInternet: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getOtherArticles = (categoryName) => {
    if (categoryName == "internet") {
      this.setState({
        pageLoaderInternet: true,
      });
    } else if (categoryName == "chaos") {
      this.setState({ pageLoaderChaos: true });
    } else if (categoryName == "business") {
      this.setState({ pageLoaderBusiness: true });
    }
    let endpoint = `/stories`;
    let body = {
      limit: 10,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      // exclude: [cData.featured_post.id],
      filters: [
        {
          key: "category",
          value: [categoryName],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          if (categoryName == "internet") {
            this.setState({
              categoryData: res.data.story,
              pageLoaderInternet: false,
            });
          } else if (categoryName == "chaos") {
            this.setState({
              categoryChaosData: res.data.story,
              pageLoaderChaos: false,
            });
          } else if (categoryName == "business") {
            this.setState(
              {
                categoryBusinessData: res.data.story,
                pageLoaderBusiness: false,
              },
              () => {
                console.log(
                  "categoryBusinessData:",
                  this.state.categoryBusinessData
                );
              }
            );
          }
        } else {
          this.setState({
            // showErrorModal: true,
            pageLoaderInternet: false,
            pageLoaderChaos: false,
            pageLoaderBusiness: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          // showErrorModal: true,
          pageLoaderInternet: false,
          pageLoaderChaos: false,
          pageLoaderBusiness: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getSpotLight = () => {
    this.setState({ pageLoaderSpotlight: true });
    let endpoint = `/stories`;
    let body = {
      limit: 5,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      // exclude: [cData.featured_post.id],
      filters: [
        {
          key: "spotlight",
          value: "enable",
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            spotlightData: res.data.story,
            pageLoaderSpotlight: false,
          });
        } else {
          this.setState({
            // showErrorModal: true,
            pageLoaderSpotlight: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          // showErrorModal: true,
          pageLoaderSpotlight: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getEditions = () => {
    let endpoint = `/yesterday/editions`;
    let body = {};
    body = {
      sort_on: "DESC",
      sort_by: "published_date",
      // "published_date": moment(new Date()).format("DD-MM-YYYY"),
      start_date: moment(new Date()).subtract(300, "days").format("DD-MM-YYYY"),
      end_date: moment(new Date()).format("DD-MM-YYYY"),
      limit: 1,
      truncate: 1,
      count: 200,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, null)
      .then((res) => {
        this.setState({
          storyData: res.data.editions[0]
        });
      })
      .catch((error) => {
        this.setState({
          storyDataLastWeek: error.ok,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getEditionsLast24Hours = () => {
    let endpoint = `/yesterday/editions`;
    let body = {};
    body = {
      sort_on: "DESC",
      sort_by: "published_date",
      // "published_date": moment(new Date()).format("DD-MM-YYYY"),
      start_date: moment(new Date()).subtract(1, "days").format("DD-MM-YYYY"),
      end_date: moment(new Date()).format("DD-MM-YYYY")
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, null)
      .then((res) => {
        this.setState({
          editionsLast24Hours: res.data.editions,
          editionsLast24HoursLoading: false
        });
      })
      .catch((error) => {
        this.setState({
          storyDataLastWeek: error.ok,
          editionsLast24HoursLoading: false
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  }

  getShorts = () => {
    this.setState({
      // showErrorModal: false,
      pageLoaderShorts: true,
    });
    let endpoint = `/stories`;
    let body = {
      sort_on: "date",
      start_date: moment(new Date()).subtract(300, "days").format("YYYY-MM-DD"),
      end_date: moment(new Date()).subtract(2,"days").format("YYYY-MM-DD"),
      limit: 15,
      page: 1,
      filters: [{ key: "type", value: ["short"] }],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            breakingNewsData: res.data.story,
            pageLoaderShorts: false,
          });
        } else {
          this.setState({
            // showErrorModal: true,
            pageLoaderShorts: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          // showErrorModal: true,
          pageLoaderShorts: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getShortsLast24Hours = () => {
    this.setState({
      // showErrorModal: false,
      pageLoaderShorts: true,
    });
    let endpoint = `/stories`;
    let body = {
      sort_on: "date",
      start_date: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
      end_date: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
      filters: [{ key: "type", value: ["short"] }],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            breakingNewsDataLast24Hours: res.data.story,
            breakingNewsDataLast24HoursLoading: false,
          });
        } else {
          this.setState({
            // showErrorModal: true,
            breakingNewsDataLast24HoursLoading: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          // showErrorModal: true,
          breakingNewsDataLast24HoursLoading: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getExclusiveContent = () => {
    this.getArchivedStories()
  };

  getArchivedStories = (excludeData = []) => {
    this.setState({
      // showErrorModal: false,
      pageLoaderStarter: true,
    });
    let excludeList = excludeData.map((td) => {
      return td.id;
    });
    let endpoint = `/stories`;
    let body = {
      limit: 3,
      // exclude: excludeList,
      // sort_on: "date",
      // sort_by: "desc",
      // filters: [{ key: "single_category", value: true }]
      filters: [
        { key: "is_privileged", value: false },
        // { key: "single_category", value: true },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        console.log("dara:", res.data);
        if (res.success && res.code == "story_found") {
          this.setState({
            archivedStories: res.data.story,
            pageLoader: false,
            pageLoaderStarter: false,
          });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getUserInfo = () => {
    return new Promise((resolve, reject) => {
      const { token } = this.state;
      if (!token) {
        this.setState({ userInfoFetched: true });
        resolve(false);
      } else {
        let endpoint = `/profile`;
        let body = {
          request_group: ["profile", "subscription"],
        };
        apiHelpers
          .apiRequest(endpoint, body, "POST", true, token)
          .then((res) => {
            let userSubscription =
              !res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription;
            if (res.success) {
              this.setState({
                freeUser:
                  (res.data.team_subscription &&
                    !this.isObjectEmpty(res.data.team_subscription)) ||
                  (res.data.subscription &&
                    !this.isObjectEmpty(res.data.subscription))
                    ? false
                    : true,
                userInfoFetched: true,
                subscriptionDetails:
                  res.data.team_subscription &&
                  res.data.team_subscription.is_team_owner
                    ? res.data.team_subscription
                    : res.data.subscription,
                expiredSubscription: res.data.expired_subscription,
                userSubscription: userSubscription,
                userProfile: res.data,
              });
              this.advanceRenewalChecks();
              resolve(res.data);
            } else {
              this.setState({ userInfoFetched: true });
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("Error=>>", error);
            this.setState({ userInfoFetched: true });
            resolve(false);
          });
      }
    });
  };

  advanceRenewalChecks() {
    let canRenew = this.canAdvanceRenew();
    if (!canRenew) {
      return;
    }
    ChargebeeController.activatedController().then((handle) => {
      handle.API.subscription
        .payment_source({
          subscription: {
            id: handle.activeSubscription.subscription_id,
          },
        })
        .then((res) => {
          this.setState({
            userPaymentInfo: res,
          });
        })
        .catch((err) => {
          console.log("chargebee: API:payment_source:Error: ", err);
        });
    });
  }

  canAdvanceRenew() {
    let { subscriptionDetails, token } = this.state;

    if (!token) {
      return false;
    }

    return helpers.canAdvanceRenew(subscriptionDetails);
  }

  getNewsletterStories = () => {
    let { token, newsletterSlug, newsLetterDetails } = this.state;
    this.setState({ pageLoaderLatestNews: true });

    let endpoint = `/stories`;
    // let exclude_arr = [newsLetterDetails.featured_post.id]
    let body = {
      limit: 2,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      // exclude: exclude_arr,
      filters: [
        {
          key: "type",
          value: ["newsletter"],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let recentAdditions = res.data.story.filter((item) => {
            return item.newsletter;
          });
          this.setState({
            latestNewsData: [...recentAdditions],
            pageLoader: false,
            pageLoaderLatestNews: false,
          });
        } else if (res.code == "no_story_found") {
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          // showErrorModal: true,
          pageLoader: false,
          pageLoaderLatestNews: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getPremiumStories = () => {
    this.setState({
      // showErrorModal: false,
      pageLoaderTodaysEdition: true,
    });
    let endpoint = `/stories`;
    let body = {
      limit: 20,
      filters: [
        { key: "newsletter", value: ["include"] },
        { key: "", value: ["include"] },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            pageLoaderTodaysEdition: false,
            latestPremiumStories: res.data.story,
          });
        } else {
          this.setState({ pageLoaderTodaysEdition: false });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ pageLoaderTodaysEdition: false });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getFeaturedStories = () => {
    this.setState({
      // showErrorModal: false,
      pageLoaderTopStories: true,
    });
    const { token } = this.state;
    let endpoint = `/stories/popular`;
    let body = {
      archive_days: 0,
      limit: 10,
      // exclude: excludeList,
      days_before: 30,
    };

    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let latestStoriesData = res.data.story;
          this.setState(
            {
              featuredStory: latestStoriesData,
              pageLoader: false,
              pageLoaderTopStories: false,
            },
            () => {
              // this.getUserStories()
            }
          );
        } else {
          console.log("Error=>>", JSON.stringify(res));
          this.getUserStories();
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  goToHome = () => {
    if (helpers.isWeb()) {
      this.props.navigateScreen("/");
    } else {
      this.getData();
      // this.refs.header.getUserInfo();
    }
  };

  trackSignUp = (email, position) => {
    Mixpanel.track("click_signup", { email: email });
    // Mixpanel.track('click_signup', {email: email, position: position });
  };

  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  viewAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };

  viewCompany = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/company/" + slug);
    } else {
      this.props.navigation.navigate("company", { companySlug: slug });
    }
  };

  viewCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  trackThemeClick = (theme) => {};

  navigateToTag = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/tags/" + slug);
    } else {
      this.props.navigation.navigate("tags-archive", { archiveSlug: slug });
    }
  };

  isSubscriptionUnderDunning() {
    let { subscriptionDetails } = this.state;
    if (subscriptionDetails.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  viewStarterCollection = () => {
    if (helpers.isWeb()) {
      // this.props.navigateToScreen('/newsletters/' + slug);
    } else {
      this.props.navigation.navigate("/free-reads", {
        collectionType: "starter",
      });
    }
  };

  // CB__HostedPage__CollectNow = () => /*
  //     Opens CB hosted page URL(redirection) to collect view/pay unpaid invoices
  // */ {
  //   const { token } = this.state;
  //   if (!token) {
  //     console.log(
  //       "unpaidInvoiceBand:CB__HostedPage__CollectNow: not authenticated user"
  //     );
  //     return false;
  //   }
  //   this.setState({ unpaidInvoiceLoader: true });
  //   ChargebeeController.activatedController().then((cb_handle) => {
  //     cb_handle.hosted_page.collect_now();
  //   });
  // };

  // paymentBandChain() {
  //   let { subscriptionDetails, userPaymentInfo } = this.state;

  //   try {
  //     if (subscriptionDetails.is_cancelled) {
  //       return this.showCancellationRemovalBand();
  //     } else {
  //       const cancellationRemovalEventKey = `scheduled-cancellation-removed:${subscriptionDetails.subscription_id}`;
  //       localStorage.removeItem(cancellationRemovalEventKey);
  //     }

  //     if (!this.canAdvanceRenew()) {
  //       return;
  //     }

  //     if (this.hasPaymentMandate()) {
  //       return;
  //     }

  //     return this.showAdvanceRenewalBand();
  //     // if(userPaymentInfo){
  //     //   // return this.showMandateBand(subscriptionDetails, this.state.userPaymentInfo)
  //     //   return this.showAdvanceRenewalBand()
  //     // }
  //   } catch (err) {
  //     console.log("home:paymentBandChain:Error: ", err);
  //   }

  //   return;
  // }

  // showAdvanceRenewalBand() {
  //   return this.renderSubscriptionRenewalBand();
  // }

  // renderSubscriptionRenewalBand() {
  //   let { subscriptionDetails, token } = this.state;
  //   let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

  //   if (!subscriptionDetails?.end_date) {
  //     return;
  //   }
  //   const openAdvanceRenewal = () => {
  //     ChargebeeController.activatedController().then((cb_handle) => {
  //       cb_handle.hosted_page.checkout_existing(
  //         {
  //           terms_to_charge: 1,
  //           invoice_immediately: true,
  //         },
  //         {},
  //         false
  //       );
  //     });
  //   };

  //   return (
  //     <GeneralNotificationBand
  //       token={token}
  //       notificationText={`Keep reading the best of independent, financial journalism.`}
  //       redirectionPath={`/pricing?advance-renew=true`}
  //       ctaText="RENEW"
  //       ctaHandle={openAdvanceRenewal}
  //       backgroundColor="#000"
  //       darkMode={darkMode}
  //     />
  //   );
  // }

  // hasPaymentMandate() {
  //   let { subscriptionDetails, userPaymentInfo } = this.state;
  //   if (!userPaymentInfo) {
  //     return false;
  //   }
  //   const hasMandates = userPaymentInfo.mandates ? true : false;
  //   if (!hasMandates) {
  //     return false;
  //   }
  //   const mandateList = userPaymentInfo.mandates;
  //   return mandateList.some((manadateObject) => {
  //     if (
  //       manadateObject.subscription_id === subscriptionDetails.subscription_id
  //     ) {
  //       return true;
  //     }
  //     return false;
  //   });
  // }

  // showCancellationRemovalBand() {
  //   let { subscriptionDetails, token, canShowBandLoader } = this.state;
  //   let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

  //   if (!subscriptionDetails?.end_date) {
  //     return;
  //   }
  //   const cancellationRemovalEventKey = `scheduled-cancellation-removed:${subscriptionDetails.subscription_id}`;
  //   const cancellationRemovalEventObject = localStorage.getItem(
  //     cancellationRemovalEventKey
  //   );
  //   if (cancellationRemovalEventObject) {
  //     try {
  //       const cancellationData = JSON.parse(cancellationRemovalEventObject);
  //       if (cancellationData.expiresAt) {
  //         let cancellationRemovalEventExpiry = new Date(
  //           cancellationData.expiresAt
  //         );
  //         if (new Date() <= cancellationRemovalEventExpiry) {
  //           return;
  //         }
  //       }
  //     } catch (err) {
  //       console.log("removeCancellation:eventObject:Error: ", err);
  //     }
  //   }
  //   const removeCancellation = () => {
  //     this.setState({ canShowBandLoader: true });
  //     ChargebeeController.activatedController().then((handle) => {
  //       handle.API.subscription
  //         .remove_scheduled_cancellation({
  //           subscription: {
  //             id: handle.activeSubscription.subscription_id,
  //           },
  //         })
  //         .then((res) => {
  //           try {
  //             const currentDate = new Date();
  //             const eventData = {
  //               value: true,
  //               expiresAt: currentDate.setMinutes(currentDate.getMinutes() + 2),
  //             };
  //             localStorage.setItem(
  //               cancellationRemovalEventKey,
  //               JSON.stringify(eventData)
  //             );
  //           } catch (err) {
  //             console.log("removeCancellation:Error: ", err);
  //           }
  //           window.location.reload();
  //         })
  //         .catch((err) => {
  //           console.log(
  //             "chargebee: API:remove_scheduled_cancellation:Error: ",
  //             err
  //           );
  //         });
  //     });
  //   };

  //   return (
  //     <GeneralNotificationBand
  //       buttonLoader={canShowBandLoader}
  //       token={token}
  //       notificationText={`Your subscription has been cancelled.`}
  //       redirectionPath={`/pricing`}
  //       ctaText="CONTINUE READING"
  //       ctaHandle={removeCancellation}
  //       backgroundColor="#000"
  //       darkMode={darkMode}
  //     />
  //   );
  // }

  /*********************** Business logic Ends here **************/

  /*********************** DOM begins **************/

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return <PageLoader darkMode={darkMode} />;
  };

  renderWebPage = () => {
    return <>{this.renderPageContent()}</>;
  };

  renderAppPage = () => {
    return (
      <ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView>
    );
  };

  goToSignUpByEmail = (email) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign me up",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "NA",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=/" +
        "&providerHint=tmc-passwordless" +
        (email ? "&emailHint=" + email : "")
    );
  };
  goToGoogleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        "/" +
        "&providerHint=google"
    );
  };

  goToGoogleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        "/" +
        "&providerHint=google"
    );
  };

  renderPageContent = () => {
    let {
      whatsTrendingContent,
      latestPremiumStories,
      userSubscription,
      subscriptionDetails,
      credits,
      token,
      freeUser,
      userInfoFetched,
      readingList,
      userHasSubscription,
      trendingStoriesContent,
      premiumStories,
      storiesForYou,
      featuredStory,
      userStarterCollection,
      homeContent,
      exclusiveContent,
      archivedStories,
      expiredSubscription,
      pageLoaderCorporate,
      tagCorporateData,
      locationInfo,
      staticContent,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        {token ? (
          <>
            {/* {userInfoFetched &&
              freeUser &&
              !expiredSubscription &&
              credits?.non_privileged_credits?.available === 0 &&
              this.renderCreditExpiryBand()}

            {userInfoFetched &&
              !freeUser &&
              subscriptionDetails &&
              this.isSubscriptionUnderDunning() &&
              this.renderUnpaidInvoiceBand()}
            {userInfoFetched &&
              freeUser &&
              expiredSubscription &&
              this.renderSubscriptionExpiryBand()}
            {/* {freeUser &&
                userStarterCollection &&
                this.renderuserStarterCollection()} }
            {!this.isSubscriptionUnderDunning() && this.paymentBandChain()} */}
            {/* {freeUser && this.renderHomeBannerSection()} */}
          </>
        ) : (
          <>
            {/* {locationInfo && this.renderPromotionBand()} */}

            {this.renderHomeBannerSection()} 
          </>
        )}

        {this.state.breakingNewsData.length > 0
          ? this.renderBreakingNewsSection()
          : null}

        {this.state.open_gift_sub_modal && <GiftSubModal closeGiftSubModalHome = {()=>this.setState({open_gift_sub_modal: false})} token={this.state.token} />}

        {this.renderTodaysEdition()}

        {this.renderTopStories()}

        {!token  && 
        <>
          {this.renderSignUpAcquisitionSection()}
        </>
        }

        {this.renderLatestStories()}


        {this.renderLatestNewsletter()}

        {this.renderNewsletterCategory(token ? "token" : "no-token")}

        {this.renderSpotLight()}

        {this.renderArchivedStories()}

        {this.renderYesterdaySection()}

        {this.renderInternetCoverage()}

        {this.renderPopularCategory()}

        {this.renderPublicMarketCoverage()}

        {this.renderChaosCoverage()}

        {this.renderCorporateSubscription()}

        {this.renderStartups()}

        {this.renderBankingFinance()}

        {this.renderEducationTech()}

        {this.renderGiftingMini()}

        {this.renderEnvironmentConflict()}

        {this.renderCorporate()}

        {this.renderJoinYoutube()}
      </>
    );
  };

  /**
   * Render credit expire band
   * @returns
   */
  // renderCreditExpiryBand() {
  //   let { userStarterCollection, credits, token } = this.state;
  //   let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
  //   return (
  //     <CreditExpiryBand
  //       token={token}
  //       viewArticle={(catSlug, slug, postData) =>
  //         this.viewArticle(catSlug, slug, postData)
  //       }
  //       viewAuthor={(slug) => this.viewAuthor(slug)}
  //       viewCategory={(slug) => this.viewCategory(slug)}
  //       credits={credits}
  //       trackStoryClick={(title, data, position) => {
  //         this.trackStoryClick("click_starter_story", title, data, position);
  //       }}
  //       darkMode={darkMode}
  //       viewStarterCollection={() => {
  //         this.viewStarterCollection();
  //       }}
  //     />
  //   );
  // }

  // /**
  //  * Render unpaid invoice band
  //  * @returns
  //  */
  // renderUnpaidInvoiceBand() {
  //   let { token, unpaidInvoiceLoader } = this.state;
  //   let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
  //   return (
  //     <GeneralNotificationBand
  //       buttonLoader={unpaidInvoiceLoader}
  //       ctaHandle={() => {
  //         this.CB__HostedPage__CollectNow();
  //       }}
  //       token={token}
  //       notificationText="You will soon lose access. Keep reading the best of independent, financial journalism."
  //       redirectionPath="#accounts"
  //       ctaText="RENEW"
  //       backgroundColor="#000"
  //       darkMode={darkMode}
  //     />
  //   );
  // }

  // renderSubscriptionExpiryBand() {
  //   let { token } = this.state;
  //   let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
  //   return (
  //     <GeneralNotificationBand
  //       token={token}
  //       notificationText="Your subscription with The Morning Context has ended."
  //       redirectionPath="/pricing"
  //       ctaText="RENEW SUBSCRIPTION"
  //       backgroundColor="#000"
  //       darkMode={darkMode}
  //     />
  //   );
  // }

  // renderPromotionBand() {
  //   let { token, locationInfo } = this.state;
  //   let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

  //   let countryCode = locationInfo?.countryInfo?.code;
  //   let bandText =
  //     "Access quality technology and financial journalism for less than $1 per story";
  //   if (countryCode == "IN") {
  //     bandText =
  //       "Access quality technology and financial journalism for less than Rs 25 per story";
  //   }
  //   return (
  //     <GeneralNotificationBand
  //       token={token}
  //       notificationText={bandText}
  //       redirectionPath="/pricing"
  //       ctaText="GET"
  //       backgroundColor="#000"
  //       darkMode={darkMode}
  //     />
  //   );
  // }

  renderuserStarterCollection() {
    let { userStarterCollection, credits, token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View style={[helpers.isDesktop() ? Flex.row : Flex.column]}>
          <View
            style={{
              width: helpers.isDesktop() ? "70%" : "100%",
            }}
          >
            <HomeStarterCollection
              data={userStarterCollection}
              token={token}
              viewArticle={(catSlug, slug, postData) =>
                this.viewArticle(catSlug, slug, postData)
              }
              viewAuthor={(slug) => this.viewAuthor(slug)}
              viewCategory={(slug) => this.viewCategory(slug)}
              credits={credits}
              trackStoryClick={(title, data, position) => {
                this.trackStoryClick(
                  "click_starter_story",
                  title,
                  data,
                  position
                );
              }}
              darkMode={darkMode}
              viewStarterCollection={() => {
                this.viewStarterCollection();
              }}
              freeUser={this.state.freeUser}
            />
          </View>
          {this.renderYesterdayStory(darkMode)}
        </View>
      </>
    );
  }

  /** Home Banner Section **/
  renderHomeBannerSection() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <HomeBanner
        navigateWeb={(slug) => this.props.navigateToScreen(slug)}
        navigateApp={(slug, params) =>
          this.props.navigation.navigate(slug, params)
        }
        trackSignUp={(email, position) => {
          this.trackSignUp(email, position);
        }}
        darkMode={darkMode}
        auth={this.props.auth}
        freeUser={this.state.freeUser}
        subscriptionDetails={this.state.subscriptionDetails}
      />
    );
  }

  /** Breaking News Section **/
  renderBreakingNewsSection = () => {
    let { breakingNewsData, pageLoaderShorts } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={{
          minHeight: helpers.isDesktop() ? 200 : 170,
          backgroundColor: "#FFF9F1",
        }}
      >
        <View>
          <View
            style={[
              Flex.alignCenter,
              {
                marginTop: helpers.isDesktop() ? 10 : 20,
              },
            ]}
          >
            <View
              style={[
                // Padding.ph_2,
                Helpers.titleBgWrap,
                {
                  marginTop: helpers.isDesktop() ? 20 : 0,
                  marginBottom: helpers.isDesktop() ? 0 : 10,
                  backgroundColor: "#fff9f1",
                  maxWidth: "1216px",
                  width: "100%",
                  marginHorizontal: "auto",
                  paddingHorizontal: helpers.isDesktop() ? 0 : 16,
                },
              ]}
            >
              <View>
                <Text
                  style={[
                    Margin.mt_0,
                    Margin.mb_0,
                    helpers.isDesktop() && { marginBottom: 10 },
                    Helpers.textCenter,
                    {
                      position: "relative",
                      color: "#292929",
                      fontSize: helpers.isDesktop() ? "16px" : "14px",
                      lineHeight: "18px",
                      fontFamily: "PlayfairDisplay-Bold",
                      fontWeight: "700",
                      textAlign: "left",
                      textTransform: "uppercase",
                      // borderBottomWidth: helpers.isDesktop() ? "2px" : 0,
                      // borderBottomColor: "#292929",
                      // borderStyle: "solid",
                    },
                  ]}
                >
                  News Brief
                </Text>
              </View>
            </View>
            {helpers.isDesktop() && (
              <View
                style={[
                  {
                    maxWidth: "1216px",
                    width: "100%",
                    height: 0.3,
                    backgroundColor: "rgba(102, 102, 102, 0.6)",
                    width: "100%",
                    // position: "absolute",
                    // top: "50%",
                    // transform: [{ translateY: helpers.isWeb() ? "-50%" : 0 }],
                  },
                ]}
              ></View>
            )}
          </View>
        </View>
        <BreakingNews
          data={breakingNewsData}
          darkMode={darkMode}
          viewArticle={(catSlug, slug, postData) =>
            this.viewArticle(catSlug, slug, postData)
          }
        />
      </View>
    );
  };

  /** Today's Edition Section **/
  renderTodaysEdition = () => {
    let { latestPremiumStories, featuredStory, pageLoaderTodaysEdition } =
      this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let style = {
      backgroundColor: "#FFF9F1",
    };
    return (
      <>
        {pageLoaderTodaysEdition && latestPremiumStories.length == 0  ? (
          this.showPageLoader()
        ) : (
          <>
            {latestPremiumStories.length > 0 && !this.state.editionsLast24HoursLoading && !this.state.breakingNewsDataLast24HoursLoading && (
              <TodayEdition
                data={latestPremiumStories.slice(0, 8)}
                darkMode={darkMode}
                navigateToScreen={this.props.navigateToScreen}
                viewAuthor={(slug) => this.viewAuthor(slug)}
                app={this.props.app}
                token={this.state.token}
                custom_user_id={this.state.custom_user_id}
                navigateApp={(slug, params) =>
                  this.props.navigation.navigate(slug, params)
                }
                isBannerShown={this.state.token ? false : true}
                viewArticle={(catSlug, slug, postData) =>
                  this.viewArticle(catSlug, slug, postData)
                }
                breakingNewsData={this.state.breakingNewsDataLast24Hours}
                yesterdayStories={this.state.editionsLast24Hours}
              />
            )}
          </>
        )}
      </>
    );
  };

  /** Top stories Section **/
  renderTopStories = () => {
    let {
      freeUser,
      latestPremiumStories,
      token,
      readingList,
      featuredStory,
      pageLoaderTopStories,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    let style = {
      backgroundColor: "#ffffff",
    };
    return (
      <>
        <View
          style={{
            paddingVertical: !this.state.token ? 10 : 40,
          }}
        ></View>
        {pageLoaderTopStories ? (
          this.showPageLoader()
        ) : (
          <>
            <SectionTitle
              app={this.props.app}
              title={`Top Stories`}
              style={style}
            />
            <React.Suspense fallback={<Text>Loadig...</Text>}>
              <>
                {featuredStory.length > 0 ? (
                  <VerticalStory
                    data={featuredStory}
                    viewArticle={(catSlug, slug, postData) =>
                      this.viewArticle(catSlug, slug, postData)
                    }
                    viewAuthor={(slug) => this.viewAuthor(slug)}
                    viewCategory={(slug) => this.viewCategory(slug)}
                    viewCompany={(slug) => this.viewCompany(slug)}
                    trackStoryClick={(title, data, position) => {
                      /*this.trackStoryClick('click_top_stories', title, data, position)*/
                    }}
                    darkMode={darkMode}
                    navigateToScreen={this.props.navigateToScreen}
                    viewAllInCategory={(slug) =>
                      this.props.navigation.navigate("categories", {
                        archiveType: "category",
                        archiveSlug: slug,
                      })
                    }
                    custom_user_id={this.state.custom_user_id}
                  />
                ) : null}
              </>
            </React.Suspense>
          </>
        )}
      </>
    );
  };
  /** ReadersQuotes Section **/
  renderReadersQuotes() {
    let { staticContent, userSubscription } = this.state;

    if (userSubscription && !helpers.isEmptyDict(userSubscription)) {
      return null;
    }

    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
   
    return (
      <HomeReadersQuote
        data={JSON.parse(JSON.stringify(staticData.reader_quotes))}
        custom_user_id={this.state.custom_user_id}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        teamSubscribers={true}
        darkMode={darkMode}
      />
    );
  }

  renderSignUpAcquisitionSection() {
    const redirectSlugs = {
      web: "/",
      native: { redirect: "/", verifyRedirect: "/" },
    };
    return (
      <SignUpAcquisition
        app={this.props.app}
        navigateWeb={(slug) => this.props.navigateToScreen(slug)}
        redirectSlugs={redirectSlugs}
        navigateToScreen={this.props.navigateToScreen}
      />
    );
  }

  /** Latest stories Section **/
  renderLatestStories = () => {
    let {
      freeUser,
      latestPremiumStories,
      token,
      readingList,
      featuredStory,
      pageLoaderTodaysEdition,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    let style = {
      backgroundColor: "#ffffff",
    };
    return (
      <>
        {pageLoaderTodaysEdition ? (
          this.showPageLoader()
        ) : (
          <>
            <SectionTitle
              app={this.props.app}
              title={`Latest Stories`}
              style={style}
            />
            <React.Suspense fallback={<Text>Loadig...</Text>}>
              <>
                {latestPremiumStories.length > 0 ? (
                  <LatestStories
                    // data={featuredStory}
                    data={latestPremiumStories.filter(
                      (story) => !story.newsletter
                    )}
                    viewArticle={(catSlug, slug, postData) =>
                      this.viewArticle(catSlug, slug, postData)
                    }
                    viewAuthor={(slug) => this.viewAuthor(slug)}
                    viewCategory={(slug) => this.viewCategory(slug)}
                    viewCompany={(slug) => this.viewCompany(slug)}
                    trackStoryClick={(title, data, position) => {
                      /*this.trackStoryClick('click_top_stories', title, data, position)*/
                    }}
                    darkMode={darkMode}
                    navigateToScreen={this.props.navigateToScreen}
                    viewAllInCategory={(slug) =>
                      this.props.navigation.navigate("categories", {
                        archiveType: "category",
                        archiveSlug: slug,
                      })
                    }
                    custom_user_id={this.state.custom_user_id}
                  />
                ) : null}
              </>
            </React.Suspense>
          </>
        )}
      </>
    );
  };
  renderYesterdayStory(darkMode) {
    return (
      <View
        style={{
          width: helpers.isDesktop() ? "calc(100% - 832px)" : "100%",
          marginTop: helpers.isDesktop() ? 0 : 30,
          marginLeft: helpers.isDesktop() ? 32 : 0,
        }}
      >
        <HomeYesterdayStory
          token={this.state.token}
          custom_user_id={this.state.custom_user_id}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          darkMode={darkMode}
        />
      </View>
    );
  }

  /** Latest News Section **/
  renderLatestNewsletter = () => {
    let { latestNewsData, token, pageLoaderLatestNews } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        {pageLoaderLatestNews ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {latestNewsData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Latest Newsletter`}
                  style={{ backgroundColor: "#ffffff", marginBottom: "24px" }}
                />
                <LatestNewsletter
                  darkMode={darkMode}
                  latestNewsData={latestNewsData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /**
   * Render newsletter section
   * @param {string} token - user token
   * @returns
   */
  renderNewsletterCategory(token) {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let sectionTitle = {
      title: "Explore Newsletters on",
    };
    if (token === "token") {
      return (
        <HomeNewsletterCategories
          loggedOut={false}
          data={sectionTitle}
          trackThemeClick={(theme) => this.trackThemeClick(theme)}
          getTagClick={(slug) => this.navigateToTag(slug)}
          darkMode={darkMode}
          freeUser={this.state.freeUser}
          custom_user_id={this.state.custom_user_id}
        />
      );
    } else {
      return (
        <HomeNewsletterCategories
          loggedOut={true}
          data={sectionTitle}
          trackThemeClick={(theme) => this.trackThemeClick(theme)}
          getTagClick={(slug) => this.navigateToTag(slug)}
          darkMode={darkMode}
          freeUser={this.state.freeUser}
          custom_user_id={this.state.custom_user_id}
        />
      );
    }
  }

  /** Latest News Section **/
  renderSpotLight = () => {
    let { spotlightData, token, pageLoaderSpotlight } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        {pageLoaderSpotlight ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {spotlightData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Spotlight`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <View
                  style={{
                    maxWidth: "1216px",
                    width: "100%",
                    marginHorizontal: "auto",
                  }}
                >
                  <TwoGridStory
                    darkMode={darkMode}
                    title={"spotlight"}
                    latestNewsData={spotlightData.slice(0, 2)}
                    viewArticle={(catSlug, slug, postData) =>
                      this.viewArticle(catSlug, slug, postData)
                    }
                    viewAuthor={(slug) => this.viewAuthor(slug)}
                    viewCategory={(slug) => this.viewCategory(slug)}
                    viewCompany={(slug) => this.viewCompany(slug)}
                    custom_user_id={this.state.custom_user_id}
                  />
                </View>
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /**
   * Render
   * @returns
   */
  renderArchivedStories() {
    let { archivedStories, pageLoaderStarter, freeUser, userSubscription } =
      this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    // Don't show this for user with subscription

    if (userSubscription && !helpers.isEmptyDict(userSubscription)) {
      return null;
    }
    return (
      <>
        {pageLoaderStarter ? (
          // this.showPageLoader();
          console.log('loader:renderArchivedStories')
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            <HomeArchievedContent
              data={archivedStories}
              viewArticle={(catSlug, slug, postData) =>
                this.viewArticle(catSlug, slug, postData)
              }
              viewAuthor={(slug) => this.viewAuthor(slug)}
              viewCategory={(slug) => this.viewCategory(slug)}
              navigateWeb={(slug) => this.props.navigateToScreen(slug)}
              navigateApp={(slug, params) =>
                this.props.navigation.navigate(slug, params)
              }
              trackSignUp={(email, position) => {
                this.trackSignUp(email, position);
              }}
              trackStoryClick={(title, data, position) => {
                this.trackStoryClick(
                  "click_starter_collection",
                  title,
                  data,
                  position
                );
              }}
              darkMode={darkMode}
              freeUser={freeUser}
              homesection={true}
            />
          </React.Suspense>
        )}
      </>
    );
  }

  renderYesterdaySection() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let { storyData } = this.state;
    return (
      <React.Suspense fallback={<Text>Loadig...</Text>}>
        <YesterdaySection
          storyData={storyData}
          darkMode={darkMode}
          navigateToScreen={this.props.navigateToScreen}
          custom_user_id={this.state.custom_user_id}
        />
      </React.Suspense>
    );
  }

  /** Internet coverage Section **/
  renderInternetCoverage = () => {
    let { categoryData, token, pageLoaderInternet } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        <View
          style={{ marginTop: helpers.isDesktop() ? "89px" : "80px" }}
        ></View>

        {pageLoaderInternet ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {categoryData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Best of Internet coverage`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <InternetCoverage
                  darkMode={darkMode}
                  latestNewsData={categoryData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Popular theme section **/
  renderPopularCategory() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let sectionTitle = {
      title: "Explore by Theme",
    };
    return (
      <HomePopularCategories
        data={sectionTitle}
        trackThemeClick={(theme) => this.trackThemeClick(theme)}
        getTagClick={(slug) => this.navigateToTag(slug)}
        darkMode={darkMode}
        custom_user_id={this.state.custom_user_id}
      />
    );
  }

  /** Public Market Coverage section **/
  renderPublicMarketCoverage = () => {
    let { categoryBusinessData, token, pageLoaderChaos, pageLoaderBusiness } =
      this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        <View style={{ marginTop: helpers.isDesktop() ? 128 : 80 }}></View>

        {pageLoaderBusiness ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loading...</Text>}>
            {categoryBusinessData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Best of Public markets coverage`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <StoryCoverage
                  darkMode={darkMode}
                  data={categoryBusinessData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  title={`Best of Public markets coverage`}
                  id={"publicMarket"}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Chaos Coverage Section **/
  renderChaosCoverage = () => {
    let { categoryChaosData, token, pageLoaderChaos } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        {pageLoaderChaos ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {categoryChaosData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Best of Chaos coverage`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <InternetCoverage
                  darkMode={darkMode}
                  latestNewsData={categoryChaosData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Startup Section **/
  renderStartups = () => {
    let { tagStartupData, token, pageLoaderChaos, pageLoaderStartups } =
      this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        <View style={{ marginTop: helpers.isDesktop() ? 128 : 80 }}></View>
        {pageLoaderStartups ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {tagStartupData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Startups`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <StoryCoverage
                  darkMode={darkMode}
                  data={tagStartupData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  title={`Startups`}
                  id={"startups"}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Banking n Finance Section **/
  renderBankingFinance = () => {
    let { pageLoaderBanking, tagBankingData, token } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <View style={{ marginTop: helpers.isDesktop() ? 96 : 39 }}></View>
        {pageLoaderBanking ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {tagBankingData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Banking & Finance`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <MultiCoverageStory
                  darkMode={darkMode}
                  data={tagBankingData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  custom_user_id={this.state.custom_user_id}
                  title={`Banking & Finance`}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Education Technology Section **/
  renderEducationTech = () => {
    let { tagEdtechData, token, pageLoaderChaos, pageLoaderEdtech } =
      this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        {pageLoaderEdtech ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {tagEdtechData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Education Technology`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <StoryCoverage
                  darkMode={darkMode}
                  data={tagEdtechData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  title={`Education Technology`}
                  id={"education"}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Environment n conflict Section **/
  renderEnvironmentConflict = () => {
    let { tagEnvtData, token, pageLoaderEnvt, pageLoaderBusiness } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        {pageLoaderEnvt ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {tagEnvtData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Environment & Conflict`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <MultiCoverageStory
                  darkMode={darkMode}
                  data={tagEnvtData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  custom_user_id={this.state.custom_user_id}
                  title={`Environment & Conflict`}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Corporate Section **/
  renderCorporate = () => {
    let { tagCorporateData, token, pageLoaderChaos, pageLoaderCorporate } =
      this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        {pageLoaderCorporate ? (
          this.showPageLoader()
        ) : (
          <React.Suspense fallback={<Text>Loadig...</Text>}>
            {tagCorporateData.length > 0 ? (
              <>
                <SectionTitle
                  app={this.props.app}
                  title={`Conglomerates`}
                  style={{ backgroundColor: "#ffffff" }}
                />
                <StoryCoverage
                  darkMode={darkMode}
                  data={tagCorporateData}
                  viewArticle={(catSlug, slug, postData) =>
                    this.viewArticle(catSlug, slug, postData)
                  }
                  viewAuthor={(slug) => this.viewAuthor(slug)}
                  viewCategory={(slug) => this.viewCategory(slug)}
                  viewCompany={(slug) => this.viewCompany(slug)}
                  title={`Corporate`}
                  id={"corporate"}
                  custom_user_id={this.state.custom_user_id}
                />
              </>
            ) : null}
          </React.Suspense>
        )}
      </>
    );
  };

  /** Render Corporate subscription Section **/
  renderCorporateSubscription = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <CorporateSubscriptionMini
        darkMode={darkMode}
        navigateToScreen={(slug) => this.props.navigateToScreen(slug)}
        custom_user_id={this.state.custom_user_id}
      />
    );
  };

  /** Render Gifted Section **/
  renderGiftingMini = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        {this.state.giftedData !== null ? (
          <View style={{ marginBottom: helpers.isDesktop() ? 0 : 0 }}>
            <GiftingMini
              data={this.state.giftedData}
              darkMode={darkMode}
              style={{ backgroundColor: "#fff9f1" }}
              navigateToScreen={(slug) => this.props.navigateToScreen(slug)}
              custom_user_id={this.state.custom_user_id}
            />
          </View>
        ) : null}
      </>
      // <GiftingMini
      //   darkMode={darkMode}
      //   navigateToScreen={(slug) => this.props.navigateToScreen(slug)}
      // />
    );
  };

  /** Render Youtube Section **/
  renderJoinYoutube() {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let text;
    if (this.state.token) {
      text = "Catch up on all the past events";
      return <JoinYoutube catchUp={text} home={true} darkMode={darkMode}  custom_user_id={this.state.custom_user_id}/>;
    } else {
      return <JoinYoutube catchUp={false} home={true} darkMode={darkMode} custom_user_id={this.state.custom_user_id}/>;
    }
  }

  render() {
    const {
      pageLoader,
      // userInfoFetched,
      trendingStoriesContent,
      showErrorModal,
      footerData,
      subscriptionRefreshKey,
      token,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const { freeUser } = this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/",
      native: { redirect: "/", verifyRedirect: "/" },
    };

    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj["home"]}
          page="/"
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
        />
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateToScreen={this.props.navigateToScreen}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          refreshPage={() =>
            this.setState({ subscriptionRefreshKey: !subscriptionRefreshKey })
          }
          setCredits={(credits) => this.setState({ credits: credits })}
          navigateToSubscriptionScreen
          darkMode={darkMode}
          showFullHeader={true}
          setCheckoutLoaderState={(loaderState) =>
            this.setPlanButtonLoaderState(loaderState)
          }
          getCheckoutLoaderState={this.getPlanButtonLoaderState}
        />

        {!showErrorModal && (
          <>
            {
              // pageLoader || !trendingStoriesContent
              //   ? this.showPageLoader()
              //   :
              webView ? this.renderWebPage() : this.renderAppPage()
            }
          </>
        )}
        <AppFooter
          freeUser={false}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.goToHome()}
            tryAgain={() => (webView ? this.getData() : this.goToHome())}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken, storeFcmToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(Home));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
];
