import { portalParameterManager__Interface, chargebeePortal__Interface, portalParameterManager__Factory } from './interface'
import { Dictionary, DVal_Dictionary } from '../global-types';
import { HTTP_Fetch } from '../api_fetch'
import { CHARGEBEE_SETTINGS } from '../settings'
import { chargebeeInstance__Interface } from '../cb-instance'
import { syncObject } from '../helper';

import { portalParameterSettings, SSO_Action__Create, portalSettings, SSO_Action, portalForwardOptions } from './interface'
import { EventEmitter } from "events"

export abstract class portalParameterManager implements portalParameterManager__Interface {
    settingsMap: portalParameterSettings
    status: 'initialized' | 'not-initialized' = 'not-initialized'
    constructor(params: portalParameterSettings){
        this.settingsMap = params
        this.status = 'initialized'
    }

    create(): SSO_Action__Create {

        let paramsObject: SSO_Action__Create = {
            action: 'create',
            params: this.settingsMap
        }
        return paramsObject;
    }

    customSettings(newSettings: portalParameterSettings & {customer ?: { id: string }}): boolean {
        try{

            this.settingsMap = syncObject(this.settingsMap, newSettings)
        }
        catch(err){
            console.log('chargebeeHandler:portalParameterManager:customSettings:Error: ', err)
            return false
        }

        return true
    }

}


export abstract class chargebeePortal extends EventEmitter implements chargebeePortal__Interface {
    chargebeeInstance: chargebeeInstance__Interface
    portalInstance?: any
    callBacks: Dictionary = {}
    settingsMap: portalSettings
    abstract parameterHandleFactory: portalParameterManager__Factory
    parameterHandle ?: portalParameterManager__Interface
    constructor(chargebeeInstance: chargebeeInstance__Interface){
        super()
        this.chargebeeInstance = chargebeeInstance
        this.settingsMap = {
            SSO_ENDPOINT: CHARGEBEE_SETTINGS.PORTAL_SESSION_ENDPOINT,
            ALLOWED_PORTAL_ACTIONS: CHARGEBEE_SETTINGS.PORTAL_SESSION_ALLOWED_ACTIONS,

        }
    }

    initParam(params: portalParameterSettings): this {
        this.parameterHandle = this.parameterHandleFactory.createHandle(params)
        return this
    }

    setParam (params: portalParameterSettings): this{
        if(!this.parameterHandle){
            throw new Error('Error: attribute parameterHandle not initialized')
        }
        this.parameterHandle.customSettings(params) 
        return this
    }

    _SSO_ActionMap = (action: SSO_Action) =>{
        if(!this.parameterHandle){
            throw new Error('parameterHandle not initialized')
        }

        try{
            let actionParams = this.parameterHandle[action]()
            return actionParams
        }
        catch(err){
            console.error('chargebeePortal:_SSO_ActionMap:Error: ', err)
            throw new Error("Invalid action for CB portal")
        }

    }

    _portalSSO = (action: SSO_Action): Promise<any> => {

        if(!this.chargebeeInstance.tmcAuthToken){
            throw new Error("Auth token not set for chargebeeInstance")
        }
        return HTTP_Fetch(this.settingsMap.SSO_ENDPOINT, this._SSO_ActionMap(action), 'POST', true, this.chargebeeInstance.tmcAuthToken)
    }

    createPortalInstance = () =>{
        this.portalInstance = this.chargebeeInstance.instance.createChargebeePortal()
        return true
    }

    _openNew = (): boolean => {

        this.chargebeeInstance.instance.setPortalSession(() => {
            return this._portalSSO('create')
        })
        return true
    }
    open = (forwardOptions: portalForwardOptions|null): boolean => {
        this.emit('open')
        try{

            /**
             * Create a new Portal instance if it does not exist.
             */
            if(!this.portalInstance){
                this.createPortalInstance()
            }

            /**
             * The Chargebee Portal session which uses SSO, is only for 1 hour. The `authenticated` attribute of the Chargebee instance can be used to check if a Portal sesssion is valid or not.  
             */
            if(!this.chargebeeInstance.instance.authenticated){
                this._openNew()
            }
            
            if(forwardOptions){
                this.portalInstance.open(this.callBacks, forwardOptions)
            }
            else{
                this.portalInstance.open()
            }

        }
        catch(err){
            console.log('chargebeeHandler:Portal:Error: ', err)
            return false
        }

        return true
    }

    logout = () =>{
        this.emit('logout')
        this.chargebeeInstance.instance.logout()
        return false
    }

    close = () => {
        this.emit('close')
        this.chargebeeInstance.instance.closeAll()
        return false
    }
}
