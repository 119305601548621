import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator, FlatList } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, appStyles, article, FontFamily, pageContent, subscribePlans, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import ActionBox from '../../components/action-box';
import SubscriptionPlans from '../../components/subscription-plans';
import getImagePath from '../../utils/image-helper';
import moment from 'moment';
import SubscriptionPricingPlan from '../../components/subscription-pricing-plan';
import WhyTMCSection from '../../components/why-choose-tmc';
import HomePopularCategories from '../../components/home-popular-categories';
import PageBreak from '../../components/page-break';
import CustomImage from '../../components/custom-image';
import Banner from '../../components/banner';
import ButtonGradient from '../../components/button-gradient';
import SubscriptionInfoBox from '../../components/subscription-info-box';
import ReadyToSubscribe from '../../components/ready-to-subscribe';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import HomeReadersQuote from '../../components/home-readers-quote';
import AuthorItem from '../../components/author-item';
import ContentLoader from "react-native-easy-content-loader";
import { Mixpanel } from '../../utils/mixpanel';
import BulkTeamSubscription from '../../components/bulk-team-subscription';
import WorkEmailPopup from '../../components/work-email-popup';
import Seo from "../../components/seo-meta";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const cookies = new Cookies();
const elementBG = getImagePath('img/welcome_bg.png');
const laptop = getImagePath('img/laptop.png');
const checkIcon = getImagePath('icons/check.png');

export class NoSubscription extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        let tokenSet = (token == undefined || token == '') ? false : true;
        let list = null;
        if (webView && process.browser) {
            if (this.props.params) {
                list = this.props.params.list;
            }
        } else if (!webView) {
            list = (this.props.route.params.list) ? this.props.route.params.list : null;
        }
        this.state = {
            token: tokenSet ? token : false,
            pageLoader: this.props.postData ? false : true,
            showErrorModal: false,
            enableUpgradeModal: false,
            enableCancelSubModal: false,
            plansData: false,
            userSubscription: false,
            teamSubscription: false,
            activeSubscription: false,
            country: false,
            list: list ? list : false,
            pricingPlanRefreshKey: false,
            bannerTitle: 'Become a TMC subscriber',
            plansTitle: 'Choose a subscription plan'
        }

        this.subscriptionLayout = 0;
    }

    componentDidMount = () => {
        const { token } = this.state;
        if (token) {
            this.getUserInfo();
        } else {
            this.getSubscriptionPlans();
        }
        this.getStaticContent();
        this.getWriters();
    }

    componentDidUpdate = () => {
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        if (!(this.props.searchQuery && (this.props.searchQuery.includes("subscribe=") || this.props.searchQuery.includes("buy-credits=true"))) && ((token && token != this.state.token) || ((token == undefined || token == '') && this.state.token != false))) {
            this.setState({
                token: (token == undefined || token == '') ? false : token,
                pageLoader: true
            }, () => {
                if (this.state.token) {
                    this.getUserInfo(true);
                } else {
                    this.getSubscriptionPlans();
                }
            });
        }
    }

    refreshPage = () => {
        if (this.state.token) {
            this.getUserInfo();
        }
    }

    scrollToTop = (scroll) => {
        if (scroll) {
            if (helpers.isWeb()) {
                setTimeout(function () {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }, 2);
            }
        }
    }

    getWriters = () => {
        this.setState({ showErrorModal: false });
        const { token } = this.state;
        let endpoint = `/authors`;
        let body = {
            limit: 3,
            sort_on: 'posts',
            sort_by: 'desc'
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'author_found') {
                    this.setState({ writerData: res.data.author });
                }
                else {
                    // this.setState({ showErrorModal: true, pageLoader: false });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                // this.setState({ showErrorModal: true, pageLoader: false });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getUserInfo = (scroll) => {
        this.scrollToTop(scroll);
        const { token } = this.state;
        let endpoint = `/profile`;
        let body = {
            request_group: ["profile", "subscription"]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    // this.authTokenExpired();
                    return false;
                }
                let userSubscription = (!res.data.subscription || JSON.stringify(res.data.subscription) == '{}') ? false : res.data.subscription;
                let teamSubscription = (!res.data.team_subscription || JSON.stringify(res.data.team_subscription) == '{}') ? false : res.data.team_subscription;
                let activeSubscription = ((userSubscription && userSubscription.status != 'expired') || (teamSubscription && teamSubscription.is_team_member && teamSubscription.status != 'expired')) ? true : false;
                if (res.success) {
                    this.setState({
                        userSubscription: userSubscription,
                        teamSubscription: teamSubscription,
                        activeSubscription: activeSubscription,
                        bannerTitle: activeSubscription ? 'Thanks for being a TMC subscriber!' : 'Become a TMC subscriber',
                        plansTitle: activeSubscription ? 'Active subscription plans' : 'Choose your subscription',
                        profileData: res.data
                    });
                    this.getSubscriptionPlans(res.data)
                } else {
                    console.log('Error=>>', res);
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false,
                    });
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
                this.setState({
                    showErrorModal: true,
                    pageLoader: false,
                });
            });
    }

    getSubscriptionPlans = (profileData) => {
        const { token } = this.state;
        let endpoint = `/plans/default`;
        let body = {
            filters: {
                tmc_group: ["individual", "team"]
            }
        }
        if (profileData && profileData?.subscription?.plan) {
            body.filters.plan_gte = profileData.subscription.plan.id;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    this.setState({
                        plansData: (profileData && profileData?.team_subscription?.is_team_member) ? { individual: [] } : res.data.plans,
                        country: res.data.country,
                        pricingPlanRefreshKey: !this.state.pricingPlanRefreshKey,
                        pageLoader: false
                    });
                } else {
                    console.log('Error=>>', res);
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false,
                    });
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
                this.setState({
                    showErrorModal: true,
                    pageLoader: false,
                });
            });
    }

    authTokenExpired = () => {
        const { list } = this.state;
        if (helpers.isWeb()) {
            let cookieDate = new Date(Date.now() - 1);
            cookies.set('token', '', { path: '/', expires: cookieDate });
            this.props.navigateToScreen('/sign-in?redirect=' + list ? '/team-subscription' : '/');
        } else {
            this.props.removeUserToken();
            this.props.navigation.navigate('sign-in', { redirect: list ? 'subscriptions' : 'homescreen', verifyRedirect: list ? '/team-subscription' : '/', list: list ? true : false });
        }
    }


    navigateToTag = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/tags/' + slug);
        } else {
            this.props.navigation.navigate('tags-archive', { archiveSlug: slug });
        }
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                        />
                    </View>
                </View>
            </View>
        )
    }

    getStaticContent = () => {
        const { token } = this.state;
        let endpoint = `/static-content`;
        let body = {
            content: ["readers_quotes"]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, false)
            .then((res) => {
                if (res.success && res.code == 'content_found') {
                    this.setState({ staticContent: res.data });
                }
                else {
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    viewAuthorDetails = (Slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + Slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: Slug });
        }
    }

    subscribeToPlan = (subscribtionId, subscribtion, country, quantity) => {
        let { profileData, token } = this.state;
        if (token && profileData) {
            if (profileData?.email && helpers.isFreeEmailId(profileData.email)) {
                this.setState({ showWorkEmailPopup: true });
                return;
            }
        }
        this.refs.header.showSubscriptionPlan(subscribtionId, subscribtion, country, '', quantity);
    }

    logout = () => {
        this.refs.header.authTokenExpired();
    }

    trackReadTerms = () => {
        // try {
        //     if (helpers.isWeb()) {
        //         Mixpanel.track('click_read_tc');
        //     }
        // }
        // catch (error) {
        //     console.log('mix panel error =>', error);
        // }
    }

    trackThemeClick = (theme) => {
        // try {
        //     if (helpers.isWeb()) {
        //         Mixpanel.track('click_theme', { theme: theme });
        //     }
        // }
        // catch (error) {
        //     console.log('mix panel error =>', error);
        // }
    }

    renderPageContent = () => {
        const { plansData, userSubscription, teamSubscription, activeSubscription, country, pricingPlanRefreshKey, staticContent, writerData, bannerTitle, plansTitle, token } = this.state;

        let sectionTitle = {
            title: 'Get all the news from our categories'
        }

        let btnClickTerms;
        const { navigation } = this.props;
        if (helpers.isWeb()) {
            btnClickTerms = {
                to: "/terms-and-conditions"
            }
        } else {
            btnClickTerms = {
                onPress: () => navigation.navigate('/terms-and-conditions')
            }
        }

        let subInfoBox = {
            title: 'Get upto date with the latest stories',
            subtitle: 'Subscribe to TMC now',
            btnText: 'SUBSCRIBE'
        }
        if (activeSubscription) {
            subInfoBox.subtitle = null;
            subInfoBox.btnText = 'VIEW LATEST STORIES'
        }
        return (
            <View>
                {/* <View style={{ marginBottom: helpers.isDesktop() ? 0 : 0 }}>
                    <Banner bgColor={'#FFF4F1'} title={bannerTitle} />
                </View> */}
                {/* <View
                    onLayout={event => {
                        const layout = event.nativeEvent.layout;
                        this.subscriptionLayout = layout.y;
                    }}
                > */}
                {/* <View style={{ backgroundColor: '#FAFAFA', height: 418, position: 'absolute', left: 0, right: 0, top: 0 }} />
                    <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginBottom: helpers.isDesktop() ? 150 : 50 }, helpers.isDesktop() && { paddingTop: 60 }]}> */}
                {/* <View style={[article.headerWrapper]}>
                            <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_2, !helpers.isDesktop() && Padding.pt_3, { width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                                <View style={[Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                                    <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, { fontSize: 18, textTransform: 'regular', marginBottom: 2 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 34 : 20 }]}>{plansTitle}</Text>
                                </View>
                                <PageBreak />
                            </View>
                        </View> */}

                {/* <View style={[!helpers.isDesktop() && Padding.pt_0, !helpers.isDesktop() && Padding.pb_2, { maxWidth: 960, marginLeft: 'auto', marginRight: 'auto' }, !helpers.isDesktop() && { paddingHorizontal: 20 }, helpers.isDesktop() && Margin.mb_4 ]}>

                            <View style={[ !helpers.isDesktop() && Padding.pb_2, Flex.alignCenter, !helpers.isDesktop() && Flex.alignStart ]}>
                                <View style={[!helpers.isDesktop() && Padding.pb_2]}>                  
                                    <Text style={{fontFamily:FontFamily.regular, fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 27 : 22, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 }}>Common benefits across all subscription plans</Text>
                                </View>
                               <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>              
                                    <CustomImage
                                        source={checkIcon}
                                        require={true}
                                        style={{ width: 20, height: 19, resizeMode: 'contain', marginTop: 3, marginRight: 15 }}
                                        webStyle={{ width: 20, height: 19, objectFit: 'contain', marginTop: 3, marginRight: 15 }}
                                    /><Text style={{fontFamily:FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3 }}>Get access to our daily long reads as they’re published</Text>
                                </View>
                                <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>                  
                                    <CustomImage
                                        source={checkIcon}
                                        require={true}
                                        style={{ width: 20, height: 19, resizeMode: 'contain', marginTop: 3,marginRight: 15 }}
                                        webStyle={{ width: 20, height: 19, objectFit: 'contain', marginTop: 3, marginRight: 15 }}
                                    /><Text style={{fontFamily:FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3 }}>TMC’s paid newsletters: Things Change, Oversize, Friction and more</Text>
                                </View>
                                <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>                 
                                    <CustomImage
                                        source={checkIcon}
                                        require={true}
                                        style={{ width: 20, height: 19, resizeMode: 'contain', marginTop: 3, marginRight: 15 }}
                                        webStyle={{ width: 20, height: 19, objectFit: 'contain', marginTop: 3, marginRight: 15 }}
                                    /><Text style={{fontFamily:FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3 }}>Gift stories to friends and family (coming soon)</Text>
                                </View>
                            </View>

                        </View> */}

                {/* {plansData && <SubscriptionPricingPlan key={pricingPlanRefreshKey} planPage={true} plansData={plansData} userSubscription={userSubscription} teamSubscription={teamSubscription} country={country} showSubscriptionPlan={(subscribtionId, subscribtion, country) => this.subscribeToPlan(subscribtionId, subscribtion, country)} hidePagebreak={true} sidePanel={false} navigateScreen={(pushProp) => this.props.history.push(pushProp)} subscriptionPage={true} />}

                        <View style={[{ paddingHorizontal: 20 }, helpers.isDesktop() && Margin.mt_1]}>
                            <Text style={{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 16 : 13, lineHeight: helpers.isDesktop() ? 22 : 20, color: 'rgba(106, 106, 106, 1)', textAlign: 'center' }}>Full <TouchableOpacityLink {...btnClickTerms} handleClick={() => this.trackReadTerms()}><Text style={{ textDecorationLine: 'underline', color: '#6A6A6A' }}>terms and conditions</Text></TouchableOpacityLink> apply to all subscriptions.</Text>
                        </View> */}

                {/* </View>
                </View> */}

                {plansData?.team &&
                    <BulkTeamSubscription planData={plansData.team[0]} token={token} country={country} showSubscriptionPlan={(subscribtionId, subscribtion, country, quantity) => this.subscribeToPlan(subscribtionId, subscribtion, country, quantity)} />
                }

                {/* {(plansData?.credit && (activeSubscription == false)) && <View style={{ marginBottom: helpers.isDesktop() ? 150 : 60, }}>
                    <ReadyToSubscribe plan={plansData.credit} country={country} subscribeToPlan={(subscribtionId, subscribtion, country) => this.subscribeToPlan(subscribtionId, subscribtion, country)} />
                </View>
                } */}

                {/* <WhyTMCSection subscription={true} /> */}

                {/* <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                        <HomePopularCategories transparentBg={true} data={sectionTitle} subscription={true} trackThemeClick={(theme) => this.trackThemeClick(theme)}  getTagClick={(slug) => this.navigateToTag(slug)}/>
                    </View>
                </View> */}

                {/* {writerData && writerData.length > 0 &&

                    <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }, !helpers.isDesktop() && { marginTop: 40 }]}>
                        <View style={[Flex.alignCenter, Padding.pt_4, Padding.pb_4, {width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft:'auto', marginRight: 'auto'}]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 34 : 20, lineHeight: helpers.isDesktop() ? 40 : 15, fontFamily: FontFamily.abrilFatface, paddingBottom: 8}}>Written by leading  journalists</Text>
                            <PageBreak/>
                        </View>

                        {helpers.isDesktop() ? 
                            <View style={[Flex.row, Flex.FlexWrap]}>
                                {writerData.map((citem, index) => {
                                    return (
                                        <AuthorItem viewAuthor={(slug) => this.viewAuthorDetails(slug)} single={index == 0 ? true : false} key={`authorItem${index}`} data={citem} subscription={true} about={true} showArrow={true} teamWriters={true} require={false} />
                                    )
                                })
                                }
                            </View>
                            :
                            <View style={[Flex.row, Flex.FlexWrap]}>
                                {writerData.map((citem, index) => {
                                    return (
                                        <AuthorItem viewAuthor={(slug) => this.viewAuthorDetails(slug)} single={index == 0 ? true : false} key={`authorItem${index}`} data={citem} subscription={true} about={true} showArrow={false} teamWriters={true} require={false} />
                                    )
                                })
                                }
                            </View>
                        }
                    </View>
                } */}

                <View style={[{ marginBottom: helpers.isDesktop() ? 150 : 50 }]}>
                    {
                        staticContent && staticContent.readers_quotes && this.renderReadersQuotes()
                    }
                </View>

                {/* <View style={{ marginBottom: helpers.isDesktop() ? 190 : 50 }}>
                    <SubscriptionInfoBox data={subInfoBox} handleClick={() => this.handleInfoBoxClick()} />
                </View> */}


            </View>
        )
    }

    renderReadersQuotes() {
        let { staticContent } = this.state;
        return (
            <HomeReadersQuote data={staticContent.readers_quotes} viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} viewCategory={(slug) => this.viewCategory(slug)} subscriptionQuotes={true} />
        )
    }

    handleInfoBoxClick = () => {
        let { activeSubscription } = this.state;
        if (activeSubscription) {
            if (helpers.isWeb()) {
                this.props.navigateToScreen('/all-stories')
            }
            else {
                //TODO
            }
        }
        else {
            this.scrollToSection(this.subscriptionLayout);
            try {
                Mixpanel.track('click_subscribe_btn');
            }
            catch (error) {
                console.log("mix panel error =>", error);
            }
        }
    }

    scrollToSection = (position) => {
        console.log("scrollToSection ==>", position)
        let offsetPosition = position
        if (helpers.isWeb()) {
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        } else {
            this.appscrollview.scrollTo(offsetPosition)
        }

    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {

        const { pageLoader, showErrorModal, enableUpgradeModal, enableCancelSubModal, list, showWorkEmailPopup } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: list ? '/team-subscription' : '/',
            native: { redirect: list ? 'subscriptions' : 'homescreen', verifyRedirect: list ? '/team-subscription' : '/', list: list ? true : false }
        }
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                    <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
                </ScrollView>
            </>
        )
    }

    render() {
        const { pageLoader, showErrorModal, enableUpgradeModal, enableCancelSubModal, list, showWorkEmailPopup } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: list ? '/team-subscription' : '/',
            native: { redirect: list ? 'subscriptions' : 'homescreen', verifyRedirect: list ? '/team-subscription' : '/', list: list ? true : false }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill]}>
                <Seo data={{}} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} refreshPage={() => this.refreshPage()} isPricing={true} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }

                {showWorkEmailPopup && <WorkEmailPopup closeModal={() => this.setState({ showWorkEmailPopup: false })} logout={() => this.logout()} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(NoSubscription));
