import React, { Component } from 'react';
import { Text, View, TextInput } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, article, button, pageContent, FontFamily, input, Colors, Messages } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Button from '../button';

const everythingImage = getImagePath('img/everythingImage.png');

export class EverythingTmc extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let { darkMode } = this.props;
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { flexDirection: helpers.isDesktop() ? 'row' : 'column', alignItems: 'flex-end', paddingTop: helpers.isDesktop() ? 60 : 50, paddingBottom: 50 }]}>
                <View style={{ width: helpers.isDesktop() ? '50%' : '100%', paddingRight: helpers.isDesktop() ? 40 : 0 }}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 34 : 20, fontFamily: FontFamily.abrilFatface, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: helpers.isDesktop() ? 50 : 30 }}>Everything you need to know. Only with TMC.</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 18 : 14, fontWeight: '400', fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 35 }}>The Morning Context’s  mission is to build an independent, credible media company, which tells stories that matter. On the internet, business and chaos. </Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 18 : 14, fontWeight: '400', fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000000', marginBottom: 30 }}>We ensure that our stories has an original point of view, covers new ground in critical analysis of the business, is an investigation into why something happened and makes sense of the complex world around us. Check out what else we have to offer.
                    </Text>
                </View>
                <View style={{ width: helpers.isDesktop() ? '50%' : '100%', paddingLeft: helpers.isDesktop() ? 30 : 0 }}>
                    <CustomImage
                        source={everythingImage}
                        require={true}
                        style={{ width: 250, height: 250, resizeMode: 'contain', marginRight: 0 }}
                        webStyle={{ width: '100%', height: helpers.isDesktop() ? 310 : 250, objectFit: 'contain' }}
                    />
                </View>
            </View>
        );
    }
}

export default EverythingTmc;