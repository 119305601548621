import React, { Component } from 'react';
import { Text, View, ScrollView, TouchableOpacity, TextInput, Animated } from 'react-native';
import { Colors, Flex, Padding, Margin, Helpers, FontFamily, appStyles, articleLists, article, articleItem, formStyle, FontWeight, button } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageCover from '../../components/page-cover';
import InfiniteScrollComponent from '../../components/infinite-scroll';
import ArticleItemHorizontal from '../../components/article-item-horizontal';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import ButtonGradient from '../../components/button-gradient';
import { Mixpanel } from '../../utils/mixpanel';
import NewsletterCategory from '../../components/newsletter-category';
import Seo from "../../components/seo-meta";
import { refreshScreen } from '../../../redux/App/app.actions';
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from '../../components/authentication-wrapper';

const checkBoxSelected = getImagePath('icons/checkedIconSq.png');
const checkBoxUnSelected = getImagePath('icons/unCheckedIconSq.png');
const searchBox = getImagePath('icons/searchbox.png');
const readingList = getImagePath('img/reading-list-back-image.png');
const readingListDark = getImagePath('img/reading-list-back-image-dark.png');

const bookmarkIcon = getImagePath('icons/bookmark.png');
const bookmarkIconLight = getImagePath('icons/bookmark-light.png');
const bookmarkIconSelected = getImagePath('icons/bookmark-selected.png');

const noArticles = getImagePath('img/no-articles.png');
const readingListEmpty = getImagePath('img/reading-list-empty.png');
const bookmark = getImagePath('icons/bookmark.png');

const cookies = new Cookies();
let resetTimeout = 0;

export class ReadingList extends Component {
    constructor(props) {
        super(props);
        let search = '';
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (isAuthenticated ? fetchToken : false) : this.props.user?.userData?.data?.token;
        if (webView && process.browser && window.location.search) {
            let searchArr = window.location.search.replace("?", "").split("&");
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("search=");
            });
            if (foundSearch) {
                search = foundSearch.replace("search=", "");
                search = search.replace(/%20/g, ' ');
            }

        } else if (!webView && this.props.route.params) {
            search = (this.props.route.params.redirect) ? this.props.route.params.search : '';
        }
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            pageLoader: true,
            postPage: false,
            postLists: false,
            searchText: search,
            animatedIndex: 0,
            animatedOpacity: new Animated.Value(1)
        }
    }

    componentDidMount = () => {
        if (this.state.token) {
            this.getUserActionStories(1);
        } else {
            this.setState({ pageLoader: false, postLists: [] })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!helpers.isWeb() && prevProps.app.refreshScreen != this.props.app.refreshScreen && !this.props.navigation.isFocused()) {
            this.getUserActionStories(1);
        }
    }

    getUserActionStories = (page, append) => {
        const { token, searchText } = this.state;
        let endpoint = `/user-action-story-list`;
        let body = {
            limit: 10,
            page: page,
            type: "bookmark"
        }
        if (searchText != '') {
            body['search_string'] = searchText;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    if (res.code == 'story_found') {
                        this.setState({
                            postPage: res.data.page,
                            postLists: append ? this.state.postLists.concat(res.data.story) : res.data.story,
                            pageLoader: false
                        });
                    } else {
                        if (page == 1) {
                            this.setState({
                                postPage: false,
                                postLists: [],
                                pageLoader: false
                            });
                        } else {
                            let postPage = this.state.postPage;
                            postPage.has_next = false;
                            this.setState({
                                postPage: postPage,
                                pageLoader: false
                            });
                        }
                    }
                } else {
                    if (page == 1) {
                        this.setState({
                            postPage: false,
                            postLists: [],
                            pageLoader: false
                        });
                    } else {
                        let postPage = this.state.postPage;
                        postPage.has_next = false;
                        this.setState({
                            postPage: postPage,
                            pageLoader: false
                        });
                    }
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getMoreArticles = () => {
        this.getUserActionStories(this.state.postPage.current + 1, true)
    }

    applyStoryActions = (id, index) => {
        let { postLists } = this.state;
        postLists[index].disableRemoveBtn = true;
        this.setState({ postLists })
        const { token } = this.state;
        let endpoint = `/user-story-action`;
        let body = {
            story_id: id,
            type: "bookmark",
            value: false
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'user_story_action') {
                    let { postLists, postPage } = this.state;
                    this.setState({ animatedIndex: index, animatedOpacity: new Animated.Value(1) }, () => {
                        Animated.timing(this.state.animatedOpacity, {
                            toValue: 0,
                            duration: 400
                        }).start();
                        setTimeout(() => {
                            postLists.splice(index, 1);
                            postPage.total_count--;
                            this.setState({
                                postLists: postLists,
                                postPage: postPage
                            });
                            this.refs.header.animateReadingList();
                            Animated.timing(this.state.animatedOpacity, {
                                toValue: 1,
                                duration: 0
                            }).start();
                        }, 400);
                    })
                    this.props.refreshScreen(!this.props.app.refreshScreen);
                } else {
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    searchFieldOnChange = (value) => {
        this.setState({
            searchText: value
        }, () => {
            if (resetTimeout != 0) {
                clearTimeout(resetTimeout);
            }
            let th = this;
            resetTimeout = setTimeout(() => {
                this.getUserActionStories(1)
                if (helpers.isWeb()) {
                    let pushProp = JSON.parse(JSON.stringify(window.location));

                    if (value != '')
                        pushProp.search = "?search=" + value;
                    else
                        pushProp.search = '';

                    this.props.history.replace(pushProp)
                }
            }, 500);
        })


    }

    viewArticle = (catSlug, slug, postData) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.navigate('post-single', { categorySlug: catSlug, postSlug: slug, postData: postData });
        }
    }

    viewAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    viewCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    navigateToNewsletter = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/newsletters/' + slug);
        } else {
            this.props.navigation.navigate('single-newsletter', { newsletterSlug: slug });
        }
    }

    showPageLoader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[20]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 50 }}
                    active={true}
                    pHeight={[100]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 10, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderPageCoverRightSection = () => {
        const { postPage } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                {postPage ?
                    <View style={[article.countWrap, { flexDirection: 'row' }]}>
                        <Text style={[article.count, helpers.isDesktop() && article.countDesktop, darkMode && { color: Colors.lightWhite }]}>{postPage.total_count}</Text>
                        <Text style={[article.countLabel, helpers.isDesktop() && article.countLabelDesktop, { paddingLeft: 5 }, darkMode && { color: Colors.lightWhite }]}>{postPage && postPage.total_count == 1 ? 'Article' : 'Articles'}</Text>
                    </View>
                    :
                    <></>
                }
            </>
        )
    }

    renderPageHeader = () => {
        let isWeb = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[article.headerWrapper, !isWeb && { marginTop: 40 }]}>
                <PageCover
                    title={'Bookmarks'}
                    pageCoverOverlay={true}
                    topRightContent={this.renderPageCoverRightSection()}
                    darkMode={darkMode}
                />
                {this.renderSearchField()}
                <CustomImage
                    source={darkMode ? readingListDark : readingList}
                    require={true}
                    style={{ width: 180, height: 134, position: 'absolute', left: '45%', top: -35, zIndex: -1, resizeMode: 'contain' }}
                    webStyle={{ width: helpers.isDesktop() ? 310 : 180, height: helpers.isDesktop() ? 219 : 121, position: 'absolute', left: helpers.isDesktop() ? '45.5%' : '40.5%', top: helpers.isDesktop() ? -68 : -40, zIndex: -1 }}
                    altText={'Bookmarks'}
                />
            </View>
        )
    }

    renderSearchField = () => {
        let { searchText, checkBoxChecked, userLoggedin } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let btnClickCheckBox;

        if (helpers.isWeb()) {
            btnClickCheckBox = {
                onPress: (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                        checkBoxChecked: !checkBoxChecked
                    }, () => {
                        this.getArticles(1)
                        let pushProp = JSON.parse(JSON.stringify(window.location));
                        let searchArr = window.location.search.replace("?", "").split("&");
                        if (searchArr.length > 0 && searchArr[0] != "" && searchArr[0].startsWith('?search')) {
                            pushProp.search += "&search=" + value;
                        } else {
                            if (!this.state.checkBoxChecked) {
                                pushProp.search = this.state.searchText ? "?search=" + this.state.searchText + "&exclude_read=true" : "?exclude_read=true";
                            }
                            else {
                                pushProp.search = this.state.searchText ? "?search=" + this.state.searchText : "";

                            }
                        }
                        this.props.history.push(pushProp)
                    })
                }
            }
        } else {

            btnClickCheckBox = {
                onPress: (event) => {
                    event.stopPropagation();
                    this.setState({
                        checkBoxChecked: !checkBoxChecked
                    }, () => this.getArticles(1))
                }
            }
        }

        return (
            <View style={[helpers.isDesktop() && Flex.row, !helpers.isDesktop() && Flex.column, Flex.justfiyCenter, Margin.mb_7, { alignItems: helpers.isDesktop() ? 'baseline' : 'center' }]}>
                <View style={[{ position: 'relative' }, !helpers.isDesktop() && { width: '100%', paddingHorizontal: 20 }]}>
                    <TextInput
                        placeholder={'Search for stories'}
                        placeholderTextColor={Colors.placeholderTextColor}
                        value={searchText}
                        onChangeText={(search) => { this.searchFieldOnChange(search) }}
                        style={[helpers.isDesktop() && Padding.pl_2, !helpers.isDesktop() && Padding.pl_1, { color: '#000', borderWidth: 1, borderColor: '#907CFF', height: helpers.isDesktop() ? 46 : 40, width: helpers.isDesktop() ? 400 : '100%', paddingRight: 80 }, darkMode && { color: Colors.lightWhite }]}
                    />
                    <View style={[formStyle.searchBox]}>
                        <CustomImage
                            source={searchBox}
                            require={true}
                            style={{ width: 50, height: 40, position: 'absolute', right: 0, top: -40 }}
                            webStyle={{ width: helpers.isDesktop() ? 50 : 43, height: helpers.isDesktop() ? 46 : 40, position: 'absolute', right: 0, top: helpers.isDesktop() ? -46 : -40 }}
                        />
                    </View>
                </View>
            </View>
        )
    }

    renderEmptyList = () => {
        const { searchText } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Flex.alignCenter, { marginBottom: 200 }]}>
                <CustomImage
                    source={readingListEmpty}
                    require={true}
                    style={{ width: 500, height: 200, resizeMode: 'contain' }}
                    webStyle={{ maxWidth: 600, width: '100%', height: 'auto' }}
                    altText={'Match not found'}
                />
                <Text style={{ fontSize: helpers.isDesktop() ? 36 : 20, lineHeight: helpers.isDesktop() ? 42 : 26, color: darkMode ? Colors.lightWhite : '#000000', fontWeight: FontWeight.medium, fontFamily: FontFamily.medium, marginBottom: 25, textAlign: 'center' }}>{(searchText == '') ? 'You haven’t saved anything yet' : 'We couldn’t find a match for "' + searchText + '"'}</Text>
                {helpers.isWeb() ?
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 28 : 22, color: darkMode ? Colors.lineColor : 'rgba(0,0,0,0.7)', fontWeight: FontWeight.regular, fontFamily: FontFamily.regular, marginBottom: 35, textAlign: 'center' }}>Tap the <CustomImage
                        source={darkMode ? bookmarkIconLight : bookmark}
                        require={true}
                        style={{ width: 12, height: 18, marginLeft: 5, marginRight: 5, marginBottom: 10 }}
                        webStyle={{ maxWidth: helpers.isDesktop() ? 15 : 11, width: '100%', height: 'auto', marginLeft: 3, marginRight: 3 }}
                    /> icon on stories to save them for later</Text>
                    :
                    <View style={[Flex.row,]}>
                        <Text style={{ fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 28 : 22, color: darkMode ? Colors.lineColor : 'rgba(0,0,0,0.7)', fontWeight: FontWeight.regular, fontFamily: FontFamily.regular, marginBottom: 35, textAlign: 'center' }}>Tap the</Text>
                        <CustomImage
                            source={darkMode ? bookmarkIconLight : bookmark}
                            require={true}
                            style={{ width: 12, height: 18, marginLeft: 5, marginRight: 5 }}
                            webStyle={{ maxWidth: helpers.isDesktop() ? 15 : 11, width: '100%', height: 'auto', marginLeft: 3, marginRight: 3 }}
                        />
                        <Text style={{ fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 28 : 22, color: darkMode ? Colors.lineColor : 'rgba(0,0,0,0.7)', fontWeight: FontWeight.regular, fontFamily: FontFamily.regular, marginBottom: 35, textAlign: 'center' }}>icon on stories to save them for later</Text>
                    </View>
                }
                <ButtonGradient
                    title={'VIEW TOP STORIES'}
                    rootStyle={{
                        btnWrapper: button.primaryGradient,
                        btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400', lineHeight: 17, paddingLeft: 40, paddingRight: 40 }],
                    }}
                    onClick={() => this.navigateToScreen('/all-stories')}
                    darkMode={darkMode}
                />
            </View>
        )
    }

    navigateToScreen = (link) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen(link);
        }
        else {
            this.props.navigation.navigate('/all-stories', { categorySlug: 'all' });
        }
    }

    trackCateoryClick = (item) => {
        // try {
        //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: `reading list`, section_value: { title: item.name, slug: item.slug } });
        // }
        // catch (error) {
        //     console.log("mix panel error =>", error);
        // }
    }

    renderPageContent = () => {

        let { bookmarkChecked, data, searchText, postPage, postLists, animatedIndex, animatedOpacity } = this.state;
        const { userStoryAction } = this.props;
        let btnClickCategory, btnClickBookmark;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let items = postLists.map((item, index) => {
            if (helpers.isWeb()) {
                btnClickCategory = {
                    to: "/category/" + item.category.slug
                }
                btnClickBookmark = {
                    onPress: (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.applyStoryActions(Number(item.id), index)
                    }
                }
            } else {
                btnClickCategory = {
                    onPress: () => this.viewCategory(item.category.slug)
                }
                btnClickBookmark = {
                    onPress: (event) => {
                        event.stopPropagation();
                        this.applyStoryActions(Number(item.id), index)
                    }
                }
            }
            return (
                <Animated.View style={[articleItem.col6, Flex.row, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { opacity: (index == animatedIndex) ? animatedOpacity : 1 }]}>
                    <View style={Flex.fill}>
                        {item.newsletter
                            ?
                            <NewsletterCategory newsletter={item.newsletter} getNewsletterClick={(slug) => this.navigateToNewsletter(slug)} darkMode={darkMode} />
                            :
                            <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)} style={{ width: helpers.isWeb() ? 'fit-content' : '100%' }}>
                            </TouchableOpacityLink>
                        }
                        {/* <ArticleItemHorizontal viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} viewCategory={(slug) => this.viewCategory(slug)} data={item} single={index == 0 ? true : false} archive={helpers.isDesktop()?true:false}  listingStory={true} darkMode={darkMode} /> */}
                        <ArticleItemHorizontal viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} viewCategory={(slug) => this.viewCategory(slug)} getNewsletterClick={(slug) => this.navigateToNewsletter(slug)} data={item} single={index == 0 ? true : false} archive={true} listingStory={true} darkMode={darkMode} hideBookMark={helpers.isWeb() ? true : false} readingList={true} readingListBtnClick={btnClickBookmark} sectionName="Reading list" />
                    </View>
                </Animated.View>
            )
        })
        return (
            <View style={[Padding.ph_2, Margin.mb_3]}>

                {postLists.length > 0 ? <InfiniteScrollComponent
                    pageStart={postPage.current}
                    loadMore={() => this.getMoreArticles()}
                    hasMore={postPage.has_next}
                    items={items}
                /> :
                    <View>
                        {this.renderEmptyList()}
                    </View>
                }

            </View>
        )
    }

    renderWebPage = () => {
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && Margin.mt_9, !helpers.isDesktop() && { marginTop: 43 }]}>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </View>
        )
    }

    renderAppPage = () => {

        const { pageLoader, showErrorModal, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/bookmarks',
            native: { redirect: '/bookmarks', verifyRedirect: '/bookmarks' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/bookmarks',
            native: { redirect: '/bookmarks', verifyRedirect: '/bookmarks' }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={seoObj['bookmarks']} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/bookmarks" />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }</>
                }
                {helpers.isDesktop() && <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />}
                {showErrorModal && <ErrorModal goToHome={() => this.props.navigateToScreen('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken, refreshScreen }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(ReadingList));

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Bookmarks'
    }
]