import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, Colors, appHeader, FontFamily } from '../../styles/appStyles';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
// import { Colors } from 'react-native/Libraries/NewAppScreen';
import { Layer } from 'grommet'

const closeIcon = getImagePath('icons/close-icon.png');
const backArrow = getImagePath('icons/back-arrow.png');
const backArrowWhite = getImagePath('icons/back-arrow-white.png');

const windowWidth = Dimensions.get('window').width;

export class SidebarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowWidth * 2)),
            modalOpacity: new Animated.Value(0),
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 800,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: (windowWidth * 2),
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 0,
            duration: 300,
            delay: 0,
            useNativeDriver: true
        }).start();

        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
        const { closeModal } = this.props;
        setTimeout(function () {
            closeModal();
        }, 300);
    }

    goBack = () => {
        // window.history.back();
        this.props.goBack();
    }

    render() {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity } = this.state;
        const { renderContent, paddingHorizontal, topPadding, showBack, showClose, title, titleMain, showSave, isAdmin } = this.props;
        let backBtnProps;

        if (helpers.isWeb()) {
            backBtnProps = {
                onPress: this.goBack
            }
        }
        else {
            backBtnProps = {
                onPress: this.goBack
            }
        }
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }]}>
                </Animated.View>
                <Layer
                    position="right"
                    full={'vertical'}
                    animation="none"
                    plain="true"
                    onClickOutside={() => this.closeModal()}
                >
                    <Animated.View style={[modalStyle.backdropContainer, {
                        maxWidth: '100%',
                        minWidth: '0px',
                        minHeight: '0px',
                        flexDirection: 'column',
                        height: '100%', position: 'relative !important',
                    }, { transform: [{ translateX: showModal }], backgroundColor: 'transparent' }, { width: helpers.isWeb() ? 500 : '100%', }]}>
                        <View style={[modalStyle.modalContainer500, modalStyle.sidebarModal, { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, darkMode && { backgroundColor: Colors.darkBlackColorBg, borderColor: 'transparent', paddingBottom: 0 }]}>
                            {isAdmin &&
                                <View style={[appHeader.paymentFailure, Flex.row, Flex.justifyCenter, Flex.alignCenter]}>
                                    <View style={[Flex.fill, Flex.justifyCenter, Flex.alignCenter]}>
                                        <Text style={[appHeader.msgText, { fontWeight: '700', fontFamily: FontFamily.bold, textDecorationLine: 'none' }]}>Admin Mode</Text>
                                    </View>
                                </View>
                            }
                            <View style={[Padding.pl_2, Padding.pr_2, darkMode && { backgroundColor: Colors.darkBlackColorBg }, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderColor: Colors.lineColor1 }, !helpers.isDesktop() && { height: 56 }, (title == "Edit Profile") && { borderBottomWidth: 0, backgroundColor: darkMode ? Colors.darkBlackColor : '#FAFAFA' }, (title == "Change Password") && { borderBottomWidth: 0, backgroundColor: darkMode ? Colors.darkBlackColor : '#FAFAFA' }]}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <View style={[Margin.mr_1]}>
                                        {showBack ?
                                            <TouchableOpacity {...backBtnProps}>
                                                <CustomImage
                                                    source={darkMode ? backArrowWhite : backArrow}
                                                    require={true}
                                                    style={{ width: 30, height: 15, marginRight: 10 }}
                                                    webStyle={{ width: 30, height: 15, marginRight: 10 }}
                                                />
                                            </TouchableOpacity>
                                            :
                                            <CustomImage
                                                source={darkMode ? backArrowWhite : backArrow}
                                                require={true}
                                                style={{ width: 30, height: 15, opacity: 0 }}
                                                webStyle={{ width: 30, height: 15, opacity: 0 }}
                                            />
                                        }
                                    </View>
                                    <View>
                                        {helpers.isDesktop() ?
                                            <Text style={[(titleMain) ? modalStyle.sidebarHeaderDesktop : modalStyle.sidebarSubHeaderDesktop, darkMode && { color: Colors.lightWhite }]}>{title}</Text>
                                            :
                                            <Text style={[(titleMain) ? modalStyle.sidebarHeader : modalStyle.sidebarSubHeader, darkMode && { color: Colors.lightWhite }]}>{title}</Text>
                                        }
                                    </View>
                                </View>
                                <View>
                                    {showClose ?
                                        <TouchableOpacity
                                            onPress={() => this.closeModal()}
                                        >
                                            <CustomImage
                                                source={closeIcon}
                                                require={true}
                                                style={{ width: 12, height: 12 }}
                                                webStyle={{ width: 12, height: 12 }}
                                            />
                                        </TouchableOpacity>
                                        :
                                        <CustomImage
                                            source={closeIcon}
                                            require={true}
                                            style={{ width: 12, height: 12, opacity: 0 }}
                                            webStyle={{ width: 12, height: 12, opacity: 0 }}
                                        />
                                    }
                                </View>
                            </View>
                            <ScrollView>
                                {renderContent()}
                            </ScrollView>
                        </View>
                    </Animated.View>
                </Layer>
            </>
        );
    }
}

export default SidebarModal;