import { chargebeeHostedPage, hostedPageParameterManager } from "./abstract";
import { chargebeeHostedPageFactory__Interface, HostedPageCallbacks } from "./interface";
import { chargebeeInstance__Interface } from "../cb-instance";
import { syncObject, deepCopyObject } from '../helper'
import { CHARGEBEE_SETTINGS } from '../settings'
import { close } from "fs";
import { Mix_v1 } from '../mixin-builder'
import { HostedPageMixins } from './mixins'
declare const window


export class hostedPageParameterManager__General extends hostedPageParameterManager{
    constructor(params: any){
        super(params)
    }
}


export class chargebeeHostedPage__General extends chargebeeHostedPage{
    constructor(chargebeeInstance: chargebeeInstance__Interface){
        super(chargebeeInstance)
    }

    initParam(params: any): this {
        this.parameterHandle = new hostedPageParameterManager__General(params)
        return this
    }
}

export class hostedPageParameterManager__v1 extends hostedPageParameterManager{
    constructor(params: any){
        super(params)
    }
}

export class chargebeeHostedPage__v1 extends Mix_v1(chargebeeHostedPage).with(...HostedPageMixins){
    constructor(chargebeeInstance: chargebeeInstance__Interface){
        super(chargebeeInstance)
    }

    initParam(params: any): this {
        this.parameterHandle = new hostedPageParameterManager__v1(params)
        return this

    }
}


export const chargebeeHostedPage__v1__Factory: chargebeeHostedPageFactory__Interface = {
    createInstance: (cb_instance: chargebeeInstance__Interface) => {
        return new chargebeeHostedPage__v1(cb_instance)
    }
}