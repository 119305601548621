import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  Colors,
  FontFamily,
  FontWeight,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import Cookies from "universal-cookie";
import apiHelpers from "../../utils/api-helpers";
import { Mixpanel } from "../../utils/mixpanel";
import ContentLoader from "../content-loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Radio from "../../components/radio";
import { Picker } from "@react-native-picker/picker";
import { saveDarkMode, darkModeToggle } from "../../../redux/App/app.actions";
import AuthenticationWrapper from "../authentication-wrapper";

const cookies = new Cookies();

export class LightDarkMode extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    let selectionOptions = [
      {
        title: "Light Mode",
        selected: false,
        value: "off",
      },
      {
        title: "Dark Mode",
        selected: false,
        value: "on",
      },
    ];
    if (!webView) {
      selectionOptions.push({
        title: "System Default",
        selected: false,
        value: "default",
      });
    }
    this.state = {
      token: token == undefined || token == "" ? false : token,
      enableBtnLoader: false,
      pageLoader: true,
      darkMode: false,
      options: selectionOptions,
    };
  }

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return <ContentLoader darkMode={darkMode} />;
  };

  componentDidMount = () => {
    if (this.state.token) {
      let dark_mode = helpers.isWeb()
        ? cookies.get("dark_mode")
        : this.props?.app?.DarkMode;
      let { options } = this.state;
      for (let item of options) {
        if (item.value === dark_mode) {
          item.selected = true;
        }
      }
      this.setState({ darkMode: dark_mode, options });
    }
  };

  toggleSelected = (index) => {
    let { options, darkMode } = this.state;
    if (options[index].value != darkMode) {
      options[index].selected = !options[index].selected;
      for (let item of options) {
        if (item.value != options[index].value) {
          item.selected = false;
        }
      }
      try {
        if (options[index].title == "Light Mode")
          window.webengage.track("Click Light Mode", {
            Count: 1,
          });
        else
          window.webengage.track("Click Dark Mode", {
            Count: 1,
          });
      } catch (error) {}

      this.setState({ options });
      this.setDarkMode(options[index].value, true);
    }
  };

  setDarkMode = (darkMode, saveProfile) => {
    this.setState({
      darkMode: darkMode,
    });
    this.props.saveDarkMode(darkMode);
    this.props.darkModeToggle(false);
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
      cookies.set("dark_mode", darkMode, { path: "/", expires: cookieDate });
    }
    const { token } = this.state;
    if (saveProfile && token) {
      let endpoint = `/edit-profile`;
      let body = helpers.isWeb()
        ? {
            web_dark_mode: darkMode,
          }
        : {
            app_dark_mode: darkMode,
          };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.success) {
            //
          } else {
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
        });
    }
  };

  renderPageContent = () => {
    const { darkMode, options } = this.state;
    let { modeOptions, isAdmin, referenceId } = this.props;
    let darkModeOption = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (isAdmin) {
      return (
        <View style={[Padding.ph_2, Margin.mt_1]}>
          <View style={[Flex.justfiyCenter]}>
            <Text
              style={[
                Padding.pt_2,
                {
                  fontSize: 14,
                  lineHeight: 22,
                  color: "#666",
                  fontFamily: FontFamily.regular,
                },
                Margin.mb_3,
                darkModeOption && { color: Colors.lightWhite },
              ]}
            >
              No Access
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View style={[Padding.ph_2, Margin.mt_2]}>
        {darkMode && (
          <>
            {options &&
              options.map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => this.toggleSelected(index)}
                    activeOpacity={0.8}
                    style={[Margin.mb_2]}
                    key={`feedbackOption_${index}`}
                  >
                    <View
                      style={[
                        Flex.row,
                        Flex.alignCenter,
                        Platform.OS == "ios" && { paddingVertical: 3 },
                      ]}
                    >
                      <Radio
                        selected={item.selected}
                        modeOptions={modeOptions}
                        darkModeOption={darkModeOption}
                      />
                      <Text
                        style={[
                          Margin.ml_2,
                          {
                            fontFamily: FontFamily.regular,
                            fontSize: 18,
                            lineHeight: 27,
                            color: darkModeOption
                              ? Colors.lightWhite
                              : Colors.black,
                          },
                        ]}
                      >
                        {item.title}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
          </>
        )}
      </View>
    );
  };

  renderWebPage = () => {
    return <>{this.renderPageContent()}</>;
  };

  renderAppPage = () => {
    return (
      <>
        <ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView>
      </>
    );
  };

  render() {
    const webView = helpers.isWeb();
    let { showErrorModal, pageLoader } = this.state;
    return (
      <View>
        {!pageLoader
          ? this.showPageLoader()
          : webView
          ? this.renderWebPage()
          : this.renderAppPage()}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ saveDarkMode, darkModeToggle }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(LightDarkMode));
