import React, { Component } from 'react';
import { Text, View } from 'react-native';
import { Flex, Margin, articleLists, FontFamily } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../custom-image';

const newsletterIcon = getImagePath('icons/newsletter_icons.png');

export class NewsletterCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
    }

    render() {
        const { newsletter, title } = this.props;
        let btnClickCategory;
        if (helpers.isWeb()) {
            btnClickCategory = {
                to: "/newsletters/" + newsletter.slug
            }
        } else {
            btnClickCategory = {
                onPress: () => this.props.getNewsletterClick(newsletter.slug)
            }
        }

        return (
            <TouchableOpacityLink {...btnClickCategory} style={{ width: helpers.isWeb() ? 'fit-content' : '100%' }}>
                <View style={[Flex.row, Flex.alignCenter, Margin.mb_1]}>
                    <CustomImage
                        source={newsletterIcon}
                        require={true}
                        style={{ height: 22, width: 22 }}
                        webStyle={{ height: 22, width: 'auto' }}
                        resizeMode={'contain'}
                    />
                    <Text style={[articleLists.badge, { width: 250, marginLeft: 4, marginTop: helpers.isDesktop() ? 4 : 2, textAlign: 'left', fontFamily: FontFamily.regular, color: '#0094FF', fontSize: helpers.isDesktop() ? title == "startercollection" || title == "allstories" ? 18 : 16 : title == "startercollection" || title == "allstories" ? 13 : 14, lineHeight: 18, fontWeight: '600', textTransform: title == "startercollection" || title == "allstories" ? 'uppercase' : 'capitalize' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }]}>{newsletter.name}</Text>
                </View>
            </TouchableOpacityLink>
        );
    }
}

export default NewsletterCategory;