import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  TextInput,
  StatusBar,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  findNodeHandle,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  FontFamily,
  FontWeight,
  pageCover,
  button,
  Colors,
} from "../../styles/appStyles";
import ReactHtmlParser from "react-html-parser";
import { helpers } from "../../utils/helpers";
// import LinearGradient from 'react-native-web-linear-gradient';
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import CustomImage from "../../components/custom-image";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import WhyYesterDay from "../../components/why-yesterday";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
// const LinearGradient = React.lazy(() => (Platform.OS == 'web') ? import('react-native-web-linear-gradient') : import('react-native-linear-gradient'));
import truncate from "truncate-html";

const yesterdayBG = getImagePath("img/yesterdayBG.png");

const yesterdayImage2 = getImagePath("img/yesterdayImage2.png");
const yesterdayImageDesktop3 = getImagePath("img/yesterdayImageDesktop3.png");
const yesterdayImageMobile3 = getImagePath("img/yesterdayImageMobile3.png");

const NotificationsFlatline = getImagePath("img/NotificationsFlatline.png");
const notificationsFlatlineMobile = getImagePath(
  "img/notificationsFlatlineMobile.png"
);

const business = getImagePath("icons/business.png");
const covid = getImagePath("icons/covid-19.png");
const envoroment = getImagePath("icons/envoroment.png");
const markets = getImagePath("icons/markets.png");
const sports = getImagePath("icons/sports.png");
const world = getImagePath("icons/world.png");
const economy = getImagePath("icons/economy.png");
const choas = getImagePath("icons/choas.png");
const politics = getImagePath("icons/politics.png");
const accountContentBoxbg = getImagePath("img/accountItemBg.png");
const rightArrow = getImagePath("icons/rightArrow.png");
const healthcare = getImagePath("icons/healthcare.png");
const yesterdayLogo = getImagePath("icons/yesterdayLogo.png");
const arrowDownYesterdayIcon = getImagePath("icons/arrowDownYesterdayIcon.png");
const yesterdayBadge = getImagePath("img/yesterdayBadge.png");

import YesterdayStoryBanner from "../../components/yesterday-story-banner";
import SmartReaders from "../../components/smart-readers";
import ButtonGradient from "../../components/button-gradient";
import YesterdayHomeSection from "../../components/yesterday-home-section";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import PostContent from "../../components/post-content";
import {
  renderBorderColor,
  categoryImage,
  paddingCategory,
} from "../../screens/yesterday/renderBorderColor";
import InfiniteScrollComponent from "../../components/infinite-scroll";

const editionTabs = [
  {
    name: "Last Week",
    tabId: 1,
  },
  {
    name: "Days 8-14",
    tabId: 2,
    startday: 14,
    endday: 8,
  },
  {
    name: "Days 15-21",
    tabId: 3,
    startday: 28,
    endday: 15,
  },
  {
    name: "Days 22 -30",
    tabId: 4,
    startday: 30,
    endday: 21,
  },
  {
    name: "Days > 30",
    tabId: 5,
    startday: 10000,
    endday: 31
  }
];
export class Yesterday extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;

    this.state = {
      pageLoader: false,
      editionLoder: false,
      storyData: [],
      showErrorModal: false,
      showUparrow: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      isUserLoggedIn: token == undefined || token == "" ? false : true,
      token: token == undefined || token == "" ? false : token,
      storyDataLastWeek: [],
      selectedTab: 1,
      fromPageReader: "",
      subscriptionRefreshKey: false,
      yesterdayPageNumber: 1,
      yesterdayLoadMore: true,
      userSubscriptionActive:
      this.props.postData && this.props.postData.api_code == "story_full_read"
        ? true
        : false
    };
    this._nodes = new Map();
  }

  componentDidMount = () => {
    this.getEditions();
    /**
     * for loggied user: second API call for Explore previous Yesterday editions
     * default call for last 7 days data
     */
    if (this.state.token) {
      let currentDate = new Date();
      let endDate = new Date(currentDate);
      let startDate = undefined;
      // if the current date is the beginning of the week then do not consider end_date
      if (endDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() - 1);
      } else {
        startDate = moment(new Date()).subtract(7, "days").format("DD-MM-YYYY");
      }
      this.getEditions(startDate, moment(endDate).add(5, 'hours').add(30, 'minutes').format("DD-MM-YYYY"));
      this.getUserInfo()
    }
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
    } else {
      this.props.removeUserToken();
    }
    this.setState(
      {
        token: false,
        userLoggedin: false,
      },
      () => this.getPost()
    );
  };

  getUserInfo = () => {
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["subscription"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          this.authTokenExpired();
          this.setState({
            userLoader: false,
          });
          return false;
        }
        if (res.success) {
          this.setState({
            userSubscriptionActive:
              (res.data.subscription &&
                JSON.stringify(res.data.subscription) != "{}" &&
                res.data.subscription.status != "expired") ||
              (res.data.team_subscription &&
                JSON.stringify(res.data.team_subscription) != "{}" &&
                res.data.team_subscription.status != "expired" &&
                res.data.team_subscription.is_team_member)
                ? true
                : false
          });
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            userLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          userLoader: false,
        });
      });
  };

  getEditions = (start_date, end_date) => {
    this.setState({ editionLoder: true });
    // console.log('yogi',moment(new Date()).subtract(1, 'days').format("DD-MM-YYYY"));

    const { token, isUserLoggedIn } = this.state;
    let endpoint = `/yesterday/editions`;
    let body = {};
    if (start_date) {
      body = {
        sort_on: "published_date",
        sort_by: "DESC",
        start_date: start_date,
        end_date: end_date,
      };
    } else {
      body = {
        sort_on: "DESC",
        sort_by: "published_date",
        // "published_date": moment(new Date()).format("DD-MM-YYYY"),
        start_date: moment(new Date())
          .subtract(3000, "days")
          .format("DD-MM-YYYY"),
        end_date: moment(new Date()).format("DD-MM-YYYY"),
        limit: 1,
      };
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        try {
          console.log("test");
          if (res.data.editions?.[week]?.slug && btnName && week) {
            window.webengage.track("Yesterday - Prev Edition", {
              "Slug of edition": res.data.editions?.[week]?.slug,
              "Name of the button": btnName,
            });
          }
          console.log("success");
        } catch (err) { }

        let pageRead = this.props?.history?.location?.state?.fromModule;
        if (pageRead !== undefined) {
          this.scrollToElement(1);
        }
        // for storying all data
        if (start_date) {
          this.setState({
            storyDataLastWeek: res.data.editions,
            editionLoder: false,
          });
        } else {
          this.setState({
            storyData: res.data.editions[0],
            editionLoder: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          storyDataLastWeek: error.ok,
          editionLoder: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  fetchMoreArticles = (start_date = moment(new Date()).subtract(10000, "days").format("DD-MM-YYYY"), end_date = moment(new Date()).subtract(31, "days").format("DD-MM-YYYY")) => {
    this.setState({yesterdayLoadMore: false, editionLoder: true})
    // console.log('yogi',moment(new Date()).subtract(1, 'days').format("DD-MM-YYYY"));

    const { token, isUserLoggedIn } = this.state;
    let endpoint = `/yesterday/editions`;
    let body = {
      limit: 10,
      page: this.state.yesterdayPageNumber + 1,
      sort_on: 'published_date',
      sort_by: 'desc',
      start_date: start_date,
      end_date: end_date
  }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        try {
          console.log("test");
          if (res.data.editions?.[week]?.slug && btnName && week) {
            window.webengage.track("Yesterday - Prev Edition", {
              "Slug of edition": res.data.editions?.[week]?.slug,
              "Name of the button": btnName,
            });
          }
          console.log("success");
        } catch (err) { }

        let pageRead = this.props?.history?.location?.state?.fromModule;
        if (pageRead !== undefined) {
          this.scrollToElement(1);
        }
        // for storying all data
        if (start_date) {
          this.setState({
            storyDataLastWeek: [...this.state.storyDataLastWeek,...res.data.editions],
            editionLoder: false,
            yesterdayLoadMore: res?.data?.page?.has_next,
            yesterdayPageNumber: res?.data?.page?.current + 1
          });
        } else {
          this.setState({
            storyData: res.data.editions[0],
            editionLoder: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          storyDataLastWeek: error.ok,
          editionLoder: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  }

  /**
   * Scroll to the specific element
   * @param {Number} indexOf : index number
   */
  scrollToElement = (indexOf) => {
    const node = this._nodes.get(indexOf);
    const position = findNodeHandle(node);
    this.state.storyData.length !== 0
      ? window.scroll(0, position.offsetTop + 500)
      : window.scroll(0, position.offsetTop + 8);
    // window.scrollTo({ top: 0, behavior: "smooth" });

    // window.scroll(0, position.offsetTop + 500); //position.offsetWidth
  };

  renderPageHeader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <YesterdayHomeSection
        darkMode={darkMode}
        isUserLoggedIn={this.state.isUserLoggedIn}
        navigateWeb={(slug) => this.props.navigateToScreen(slug)}
        navigateApp={(slug, params) =>
          this.props.navigation.navigate(slug, params)
        }
      />
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    return (
      <View
        style={[
          Flex.justfiyCenter,
          Flex.alignCenter,
          {
            position: webView ? "fixed" : "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "#ffffff",
          },
        ]}
      >
        <ActivityIndicator size={25} color={"#000000"} />
      </View>
    );
  };

  trackYesterdayStoryClick = (slug) => {
    try {
      console.log("Yesterday - Click Story");
      window.webengage.track("Yesterday - Click Story", {
        "No of clicks": 1,
        "Story slug": slug,
      });
      console.log({
        "No of clicks": 1,
        "Story slug": slug,
      });
    } catch (error) { }
  };

  trackPrevEditionsClick = (slug) => {
    try {
      const { selectedTab } = this.state;
      console.log("Yesterday - Prev Edition");
      const btnIdx = editionTabs.findIndex(
        (edition) => edition.tabId == selectedTab
      );
      const btnName = editionTabs[btnIdx]["name"];
      window.webengage.track("Yesterday - Prev Edition", {
        "Slug of edition": slug,
        "Name of the button": btnName,
      });
      console.log({
        "Slug of edition": slug,
        "Name of the button": btnName,
      });
    } catch (err) { }
  };

  YesterdayData = (darkMode) => {
    let { isUserLoggedIn, storyData, storyDataLastWeek, selectedTab } =
      this.state;
    return (
      <View>
        {storyData?.published_date ? (
          <View
            style={[
              helpers.isDesktop() && {
                backgroundColor: isUserLoggedIn ? "#F3F5FF" : "#ffffff",
              },
              {
                padding: helpers.isDesktop() ? 40 : 16,
                paddingBottom: helpers.isDesktop() ? 0 : 40,
              },
              darkMode && { backgroundColor: Colors.darkPurple },
            ]}
          >
            <View
              style={[
                helpers.isDesktop() && {
                  backgroundColor: isUserLoggedIn ? "#ffffff" : "#ffffff",
                },
                {
                  paddingLeft: isUserLoggedIn
                    ? helpers.isDesktop()
                      ? 20
                      : 0
                    : 0,
                  paddingRight: isUserLoggedIn
                    ? helpers.isDesktop()
                      ? 20
                      : 0
                    : 0,
                  paddingTop: isUserLoggedIn
                    ? helpers.isDesktop()
                      ? 20
                      : 16
                    : 0,
                  paddingBottom: isUserLoggedIn
                    ? helpers.isDesktop()
                      ? 20
                      : 16
                    : 0,
                  maxWidth: helpers.isDesktop() ? "70%" : "100%",
                  width: "100%",
                  margin: "auto",
                },
              ]}
            >
              {isUserLoggedIn ? (
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 36 : 22,
                      lineHeight: helpers.isDesktop() ? 39.6 : 32.56,
                      fontFamily: FontFamily.bold,
                      color: "#051462",
                      fontWeight: "600",
                    },
                    { paddingBottom: 2, textAlign: "left" },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {" "}
                  {moment(storyData?.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              ) : (
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 24 : 16,
                      lineHeight: helpers.isDesktop() ? 35.5 : 23,
                      fontFamily: FontFamily.medium,
                      color: "rgba(5, 20, 98, 0.4)",
                    },
                    { paddingBottom: 3, textAlign: "left" },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  {moment(storyData?.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                </Text>
              )}
              {!isUserLoggedIn && (
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? 374.8 : 210.89,
                      marginBottom: 30,
                      position: "relative",
                    },
                  ]}
                >
                  {/* <CustomImage
                    resizeMode={"contain"}
                    source={yesterdayLogo}
                    require={true}
                    style={[
                      helpers.isWeb()
                        ? {
                          width: helpers.isDesktop() ? 116 : 80,
                          aspectRatio: 1 / 10,
                        }
                        : { width: 200, height: 20, backgroundColor: "yellow" },
                    ]}
                    webStyle={{
                      width: helpers.isDesktop() ? 344 : 195,
                      height: helpers.isDesktop() ? "auto" : 26,
                    }}
                    altText={"Yesterday Logo"}
                  /> */}

                  <View
                    style={{
                      clipPath:
                        "polygon(0 0, 100% 0, 100% 100%, 50% 83%, 0 100%)",
                      background:
                        "linear-gradient(17.24deg, #55C79A 9.08%, #907CFF 58.44%, #FC876D 103.84%)",
                      width: helpers.isDesktop() ? 15 : 8.46,
                      height: helpers.isDesktop() ? 31 : 17.71,
                      position: "absolute",
                      left: 0,
                      top: "13%",
                    }}
                  ></View>

                  <Text
                    style={{
                      fontSize: helpers.isDesktop() ? 36 : 20,
                      fontWeight: "700",
                      color: "#051462",
                      paddingLeft: helpers.isDesktop() ? 29.38 : 16.56,
                      fontFamily: "Matteo-bold",
                    }}
                  >
                    NEWS EXPLAINER
                  </Text>
                </View>
              )}

              {storyData &&
                storyData.posts &&
                storyData.posts.map((item, index) => {
                  return (
                    <View
                      style={[
                        {
                          backgroundColor: isUserLoggedIn ? "white" : "#F3F5FF",
                        },
                        !isUserLoggedIn && {
                          marginBottom: 30,
                          borderLeftWidth: helpers.isDesktop() ? 8 : 3,
                          paddingLeft: helpers.isDesktop() ? 40 : 16,
                          paddingRight: helpers.isDesktop() ? 40 : 8,
                          paddingTop: helpers.isDesktop() ? 10 : 16,
                          paddingBottom: helpers.isDesktop() ? 0 : 16,
                          borderColor: renderBorderColor(item?.category[0]),
                          // borderColor: "#ECB731",
                        },
                        isUserLoggedIn && {
                          paddingLeft: helpers.isDesktop() ? 10 : 6,
                          paddingRight: helpers.isDesktop() ? 10 : 6,
                          paddingTop: helpers.isDesktop() ? 10 : 16,
                          paddingBottom: helpers.isDesktop() ? 0 : 16,
                        },
                        darkMode && { backgroundColor: Colors.darkPurple },
                      ]}
                    >
                      <View
                        style={[
                          {
                            marginBottom: 23,

                            width: helpers.isDesktop() ? "100%" : "100%",
                          },
                        ]}
                      >
                        {item.category.map((value, index) => {
                          return (
                            <View>
                              <Text
                                style={[
                                  {
                                    color: renderBorderColor(value),
                                    fontFamily: FontFamily.bold,
                                    textTransform: "uppercase",
                                    fontSize: 15,
                                    lineHeight: 16,
                                    backgroundImage: `url(${categoryImage(
                                      value
                                    )})`,
                                    backgroundRepeat: "no-repeat",
                                  },
                                  paddingCategory(value),
                                ]}
                              >
                                {value}
                              </Text>
                            </View>
                            // <View>
                            //   <Text
                            //     style={{
                            //       color: renderBorderColor(value),
                            //       fontFamily: FontFamily.bold,
                            //       textTransform: "uppercase",
                            //       fontSize: 20,
                            //     }}
                            //   >
                            //     {value}
                            //   </Text>
                            // </View>
                            // <CustomImage
                            //   source={categoryImage(value)}
                            //   require={true}
                            //   style={[
                            //     helpers.isWeb()
                            //       ? {
                            //         width: "fit-content",
                            //         height: 32,
                            //       }
                            //       : {
                            //         width: "fit-content",
                            //         height: helpers.isDesktop() ? 32 : 20,
                            //       },
                            //   ]}
                            //   webStyle={{
                            //     width: "fit-content",
                            //     height: helpers.isDesktop() ? 43 : 36,
                            //   }}
                            //   altText={"The Morning Context"}
                            // />
                          );
                        })}
                      </View>
                      <View
                        style={[
                          isUserLoggedIn && {
                            marginBottom: helpers.isDesktop() ? 30 : 0,
                            borderLeftWidth: 4,
                            // borderColor: "#ECB731",
                            borderColor: renderBorderColor(item?.category[0]),
                            paddingLeft: 8,
                          },
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.props.navigateToScreen(
                              `/yesterday/${storyData?.slug}`
                            );
                            this.trackYesterdayStoryClick(storyData?.slug);
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: helpers.isDesktop() ? 24 : 18,
                                lineHeight: helpers.isDesktop() ? 36 : 26,
                                fontFamily: FontFamily.bold,
                                color: "#051462",
                              },
                              {
                                paddingBottom: helpers.isDesktop() ? 6 : 0,
                                textAlign: "left",
                                marginBottom: helpers.isDesktop() ? "auto" : -6,
                              },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            {item.title}
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: helpers.isDesktop() ? 18 : 16,
                                lineHeight: helpers.isDesktop() ? 26 : 23,
                                marginBottom: helpers.isDesktop()
                                  ? -14
                                  : "auto",
                                fontFamily: FontFamily.regular,
                                // marginTop: helpers.isDesktop() ? 16 : 8,
                                color: "#051462",
                              },
                              { paddingBottom: 0, textAlign: "left" },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            {/* <PostContent data={truncate(item.content, 50, { byWords: true })} darkMode={darkMode} openLink={(link) => this.openSocialMedia(link)} /> */}
                            {/* {ReactHtmlParser(truncate(item.content, 50, { byWords: true }))} */}
                            <View
                              className={
                                "postsingle-content postsingle-bodyfont"
                              }
                            >
                              {ReactHtmlParser(
                                truncate(item.content, 50, { byWords: true })
                              )}
                            </View>
                            {/* {console.log('content',truncate(item.content, 50, { byWords: true }))}
                    {console.log('content ori',item.content)} */}
                          </Text>
                        </TouchableOpacity>
                        {!isUserLoggedIn ? (
                          <TouchableOpacity
                            onPress={() => {
                              this.props.navigateToScreen(
                                `/yesterday/${storyData?.slug}`
                              );
                              this.trackYesterdayStoryClick(storyData?.slug);
                            }}
                            style={[
                              Flex.row,
                              Flex.alignCenter,
                              !helpers.isDesktop() && {
                                marginTop: helpers.isDesktop() ? 16 : -6,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontSize: helpers.isDesktop() ? 18 : 15,
                                  lineHeight: helpers.isDesktop() ? 26 : 22,
                                  fontFamily: FontFamily.bold,
                                  color: "#907CFF",
                                  marginVertical: 16,
                                },
                                {
                                  textAlign: "left",
                                  paddingRight: 10,
                                },
                                darkMode && {
                                  color: "rgba(255, 255, 255, 0.89)",
                                },
                              ]}
                            >
                              LEARN MORE
                            </Text>
                            <View>
                              <CustomImage
                                source={rightArrow}
                                require={true}
                                resizeMode={"contain"}
                                style={[{ width: 14.8, height: 18 }]}
                                altText={"The Morning Context"}
                              />
                            </View>
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  );
                })}

              <View style={[{ paddingVertical: 20 }]}>
                <ButtonGradient
                  title={
                    isUserLoggedIn
                      ? this.state.userSubscriptionActive
                        ? "Read more"
                        : "SUBSCRIBE TO READ"
                      : "SUBSCRIBE TO READ"
                  }
                  rootStyle={{
                    btnWrapper: [button.primaryGradient, { width: "100%" }],
                    btnText: [
                      button.primaryGradientText,
                      { fontSize: 16, fontWeight: "600" },
                    ],
                  }}
                  onClick={() => {
                    isUserLoggedIn
                      ? this.state.userSubscriptionActive
                        ? this.readMoreOfEdition(storyData)
                        : this.goToPricingPage()
                      :  this.goToPricingPage()
                  }}
                  darkMode={darkMode}
                />
              </View>
            </View>
          </View>
        ) : (
          <View style={[styles.container]}>
            <ActivityIndicator size="large" style={styles.activityIndicator} />
          </View>
        )}

        {/*  Explore previous Yesterday editions section */}
        <View
          style={[
            { backgroundColor: isUserLoggedIn ? "#F3F5FF" : "#ffffff" },
            { padding: helpers.isDesktop() ? 40 : 30, paddingTop: 0 },
            darkMode && { backgroundColor: Colors.darkPurple },
          ]}
          ref={(ref) => this._nodes.set(1, ref)}
        >
          {isUserLoggedIn && (
            <View
              style={[
                {
                  maxWidth: helpers.isDesktop() ? "70%" : "100%",
                  width: "100%",
                  margin: "auto",
                  paddingTop: helpers.isDesktop() ? 30 : 20,
                },
              ]}
            >
              {true ? (
                <View>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 36 : 22,
                        lineHeight: helpers.isDesktop() ? 39.6 : 32.56,
                        fontFamily: FontFamily.bold,
                        color: "#051462",
                      },
                      { marginBottom: 15, marginTop: 15, textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Explore Previous News Explainers Editions
                  </Text>
                  <SafeAreaView style={customStyle.container}>
                    <ScrollView
                      horizontal={true}
                      style={customStyle.scrollView}
                    >
                      <View style={[Flex.row, { marginBottom: 15 }]}>
                        {/* <LinearGradient
                                                start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                                colors={['#6C7AF6', '#A97DF1']}
                                                style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, { marginRight: 10, marginBottom: 15, width: helpers.isDesktop() ? 93 : 111 }, { padding: 8, borderRadius: 20, borderColor: "#7B8FFE", backgroundColor: "linear-gradient(90.97deg, #6C7AF7 0%, #A97DF1 99.9%)", borderWidth: 2, }]}
                                            >
                                                <Text style={[{ fontSize: helpers.isDesktop() ? 14 : 16, lineHeight: helpers.isDesktop() ? 16 : 23, fontFamily: FontFamily.regular, color: "#ffffff" }]}>Last Week</Text>
                                            </LinearGradient> */}
                        {editionTabs &&
                          editionTabs.map((d, i) => {
                            return (
                              <TouchableOpacity
                                key={`editiontab_${d.tabId}`}
                                onPress={() => {
                                  if (d.tabId == 1) {
                                    this.componentDidMount();
                                  } else {
                                    this.getEditions(
                                      moment(new Date())
                                        .subtract(d.startday, "days")
                                        .format("DD-MM-YYYY"),
                                      moment(new Date())
                                        .subtract(d.endday, "days")
                                        .format("DD-MM-YYYY")
                                    );
                                  }

                                  this.setState({ selectedTab: d.tabId, yesterdayPageNumber: 1 });
                                }}
                                style={[
                                  Flex.row,
                                  Flex.alignCenter,
                                  Flex.justfiyCenter,
                                  {
                                    marginRight: 10,
                                    marginBottom: 15,
                                    width: helpers.isDesktop() ? 100 : 111,
                                  },
                                  {
                                    padding: 8,
                                    borderRadius: 20,
                                    borderColor: "#7B8FFE",
                                    background:
                                      selectedTab == d.tabId
                                        ? "#7B8FFE"
                                        : "#ffffff",
                                    borderWidth: 2,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    {
                                      fontSize: helpers.isDesktop() ? 14 : 16,
                                      lineHeight: helpers.isDesktop() ? 16 : 23,
                                      fontFamily: FontFamily.regular,
                                      color:
                                        selectedTab == d.tabId
                                          ? "#ffffff"
                                          : "#7B8FFE",
                                    },
                                  ]}
                                >
                                  {d.name}{" "}
                                </Text>
                              </TouchableOpacity>
                            );
                          })}
                      </View>
                    </ScrollView>
                  </SafeAreaView>
                </View>
              ) : null}

              {/* for bad api resposne */}
              {storyDataLastWeek === false && (
                <View
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 50,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: 22,
                      color: "#6C7AF6",
                    }}
                  >
                    Please select a valid date range
                  </Text>
                </View>
              )}
              {/* {this.state.editionLoder ? (
                <View
                >
                  <ActivityIndicator size={25} color={"#000000"} />
                </View>
              ) : (
                )} */}
              {/* {console.log("storyDataLastWeek:", storyDataLastWeek)} */}

              {this.state.selectedTab !== 5 && storyDataLastWeek &&
                storyDataLastWeek.map((item, index) => {
                  const today = new Date().toISOString();
                  if (
                    item.published_date.split(" ")[0] != today.split("T")[0]
                  ) {
                    return (
                      <View
                        style={[
                          {
                            backgroundColor: isUserLoggedIn
                              ? "white"
                              : "#F3F5FF",
                          },
                          !isUserLoggedIn && {
                            marginBottom: 30,
                            borderLeftWidth: 8,
                            borderColor: "#ECB731",
                          },
                          {
                            marginBottom: 20,
                            padding: helpers.isDesktop() ? 20 : 10,
                          },
                          darkMode && { backgroundColor: Colors.darkPurple },
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.props.history.push({
                              pathname: `/yesterday/${item.slug}`,
                              state: item,
                            });
                            this.trackPrevEditionsClick(item.slug);
                            this.trackYesterdayStoryClick(item.slug);
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: helpers.isDesktop() ? 18 : 16,
                                lineHeight: helpers.isDesktop() ? 26 : 23,
                                fontFamily: FontFamily.bold,
                                color: "#051462",
                              },
                              { textAlign: "left", marginBottom: 15 },
                              darkMode && {
                                color: "rgba(255, 255, 255, 0.89)",
                              },
                            ]}
                          >
                            {moment(item?.published_date).add(5, 'hours').add(30, 'minutes').format(
                              "DD MMMM, YYYY"
                            )}
                          </Text>
                        </TouchableOpacity>
                        {item.posts.map((value, idx) => {
                          return (
                            <TouchableOpacity
                              onPress={() => {
                                this.props.history.push({
                                  pathname: `/yesterday/${item.slug}`,
                                  state: item,
                                });
                                this.trackPrevEditionsClick(item.slug);
                                this.trackYesterdayStoryClick(item.slug);
                              }}
                            >
                              <View
                                style={[
                                  isUserLoggedIn && {
                                    marginBottom: 15,
                                    borderLeftWidth: 4,
                                    borderColor: renderBorderColor(
                                      value?.category[0]
                                    ),
                                    paddingLeft: 10,
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    {
                                      fontSize: helpers.isDesktop() ? 18 : 16,
                                      lineHeight: helpers.isDesktop() ? 26 : 23,
                                      fontFamily: FontFamily.regular,
                                      color: "#051462",
                                    },
                                    { textAlign: "left" },
                                    darkMode && {
                                      color: "rgba(255, 255, 255, 0.89)",
                                    },
                                  ]}
                                >
                                  {value.title}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          );
                        })}
                        {/* <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#F7A34B",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#DE0046",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#A97DF1",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#ECB731",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View> */}
                      </View>
                    );
                  }
                })}

                         {this.state.selectedTab === 5 && this.state.storyDataLastWeek &&  <InfiniteScrollComponent
                            pageStart={this.state.yesterdayPageNumber}
                            loadMore={() => this.fetchMoreArticles()}
                            hasMore={this.state.yesterdayLoadMore}
                            items={this.state.storyDataLastWeek.map((item, index) => {
                                return (
                                  <View
                                  key={index}
                                    style={[
                                      {
                                        backgroundColor: isUserLoggedIn
                                          ? "white"
                                          : "#F3F5FF",
                                      },
                                      !isUserLoggedIn && {
                                        marginBottom: 30,
                                        borderLeftWidth: 8,
                                        borderColor: "#ECB731",
                                      },
                                      {
                                        marginBottom: 20,
                                        padding: helpers.isDesktop() ? 20 : 10,
                                      },
                                      darkMode && { backgroundColor: Colors.darkPurple },
                                    ]}
                                  >
                                    <TouchableOpacity
                                      onPress={() => {
                                        this.props.history.push({
                                          pathname: `/yesterday/${item.slug}`,
                                          state: item,
                                        });
                                        this.trackPrevEditionsClick(item.slug);
                                        this.trackYesterdayStoryClick(item.slug);
                                      }}
                                    >
                                      <Text
                                        style={[
                                          {
                                            fontSize: helpers.isDesktop() ? 18 : 16,
                                            lineHeight: helpers.isDesktop() ? 26 : 23,
                                            fontFamily: FontFamily.bold,
                                            color: "#051462",
                                          },
                                          { textAlign: "left", marginBottom: 15 },
                                          darkMode && {
                                            color: "rgba(255, 255, 255, 0.89)",
                                          },
                                        ]}
                                      >
                                        {moment(item?.published_date).add(5, 'hours').add(30, 'minutes').format(
                                          "DD MMMM, YYYY"
                                        )}
                                      </Text>
                                    </TouchableOpacity>
                                    {item.posts.map((value, idx) => {
                                      return (
                                        <TouchableOpacity
                                        key={idx}
                                          onPress={() => {
                                            this.props.history.push({
                                              pathname: `/yesterday/${item.slug}`,
                                              state: item,
                                            });
                                            this.trackPrevEditionsClick(item.slug);
                                            this.trackYesterdayStoryClick(item.slug);
                                          }}
                                        >
                                          <View
                                            style={[
                                              isUserLoggedIn && {
                                                marginBottom: 15,
                                                borderLeftWidth: 4,
                                                borderColor: renderBorderColor(
                                                  value?.category[0]
                                                ),
                                                paddingLeft: 10,
                                              },
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                {
                                                  fontSize: helpers.isDesktop() ? 18 : 16,
                                                  lineHeight: helpers.isDesktop() ? 26 : 23,
                                                  fontFamily: FontFamily.regular,
                                                  color: "#051462",
                                                },
                                                { textAlign: "left" },
                                                darkMode && {
                                                  color: "rgba(255, 255, 255, 0.89)",
                                                },
                                              ]}
                                            >
                                              {value.title}
                                            </Text>
                                          </View>
                                        </TouchableOpacity>
                                      );
                                    })}
                                  </View>
                                );
                              })}
                          />}

              {/* <View
                style={[
                  { backgroundColor: isUserLoggedIn ? "white" : "#F3F5FF" },
                  !isUserLoggedIn && {
                    marginBottom: 30,
                    borderLeftWidth: 8,
                    borderColor: "#ECB731",
                  },
                  { marginBottom: 20, padding: helpers.isDesktop() ? 20 : 10 },
                  darkMode && { backgroundColor: Colors.darkPurple },
                ]}
              >
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 18 : 16,
                      lineHeight: helpers.isDesktop() ? 26 : 23,
                      fontFamily: FontFamily.bold,
                      color: "#051462",
                    },
                    { textAlign: "left", marginBottom: 15 },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  23 August, 2021
                </Text>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#6C7AF6",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#F7A34B",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#DE0046",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#A97DF1",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
                <View
                  style={[
                    isUserLoggedIn && {
                      marginBottom: 15,
                      borderLeftWidth: 4,
                      borderColor: "#ECB731",
                      paddingLeft: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: helpers.isDesktop() ? 26 : 23,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      { textAlign: "left" },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Mumbai saw its first Delta COVID-19 variant death; a fully
                    vaccinated woman died
                  </Text>
                </View>
              </View> */}
              {/* <TouchableOpacity
                style={[
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  !helpers.isDesktop() && { width: "100%" },
                  {
                    padding: 30,
                    paddingLeft: helpers.isDesktop() ? 30 : 0,
                    paddingRight: helpers.isDesktop() ? 30 : 0,
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 20 : 16,
                      lineHeight: helpers.isDesktop() ? 24 : 23,
                      fontFamily: FontFamily.regular,
                      color: "#907CFF",
                      textTransform: "uppercase",
                      padding: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 4,
                      borderColor: "#907CFF",
                      borderWidth: 2,
                      width: helpers.isDesktop() ? "auto" : "100%",
                      textAlign: "center",
                    },
                  ]}
                >
                  show more
                </Text>
              </TouchableOpacity> */}
            </View>
          )}
        </View>
      </View>
    );
  };

  renderPageContent = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        {!this.state.isUserLoggedIn ? (
          <>
            <YesterdayStoryBanner />

            {/* yesterday -api block */}

            {this.YesterdayData(darkMode)}

            {/* Start : why yesterday block */}
            <View
              style={[
                { background: "#F3F5FF" },
                {
                  padding: helpers.isDesktop() ? 40 : 16,
                  paddingTop: helpers.isDesktop() ? 40 : 20,
                  paddingBottom: helpers.isDesktop() ? 0 : 20,
                },
                darkMode && { backgroundColor: Colors.darkPurple },
              ]}
            >
              <View
                style={[
                  helpers.isDesktop() ? Flex.row : Flex.column,
                  //   Flex.alignCenter,
                  { alignItems: "space-between" },
                  Flex.justfiyCenter,
                  {
                    maxWidth: helpers.isDesktop() ? "95%" : "100%",
                    width: "100%",
                    margin: "auto",
                  },
                ]}
              >
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "48%" : "100%",
                      paddingVertical: helpers.isDesktop() ? 0 : 40,
                      //   padding: 10,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 60 : 27,
                        lineHeight: helpers.isDesktop() ? 80 : 35,
                        fontFamily: FontFamily.abrilFatface,
                        marginTop: helpers.isDesktop() ? 70 : 0,
                        color: "#051462",
                      },
                      {
                        paddingBottom: 3,
                        textAlign: "left",
                      },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Why News Explainer?
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 24 : 16,
                        lineHeight: helpers.isDesktop() ? 36 : 23.5,
                        fontFamily: FontFamily.regular,
                        color: "#051462",
                      },
                      {
                        textAlign: "left",
                        marginTop: helpers.isDesktop() ? 34 : 10,
                        marginBottom: helpers.isDesktop() ? 0 : 10,
                      },
                      darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                    ]}
                  >
                    Everything you need to know along with a cup of tea in the
                    morning for an informed start to your day.<br/><br/>We identify
                    and report on the most important news developments of the
                    day presented in a crisp and concise yet thoroughly
                    informative manner, and filtered for misinformation and
                    hearsay.
                    {/* Don’t scroll anymore, let Yesterday
                    {helpers.isDesktop() ? " " : "\n"}bring the news to you.
                    Every weekday in your inbox at 8 am IST. */}
                  </Text>
                </View>
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "52%" : "100%",
                      alignItems: "center",
                    },
                    !helpers.isDesktop() && {
                      alignSelf: "center",
                      paddingBottom: 50,
                    },
                  ]}
                >
                  <CustomImage
                    source={
                      helpers.isDesktop()
                        ? yesterdayImageDesktop3
                        : yesterdayImageMobile3
                    }
                    require={true}
                    style={[
                      helpers.isWeb()
                        ? { width: "100%", height: "auto" }
                        : { height: 400, width: "100%" },
                    ]}
                    webStyle={{
                      width: helpers.isDesktop() ? 553 : "100%",
                      height: helpers.isDesktop() ? 483 : "100%",
                    }}
                    altText={"The Morning Context"}
                    resizeMode={"contain"}
                  />
                </View>
              </View>
            </View>
            {/* End */}

            {/* Start: read smarter Block */}
            <View
              style={[
                { background: "#ffffff" },
                {
                  padding: helpers.isDesktop() ? 60 : 0,
                  paddingTop: helpers.isDesktop() ? 60 : 0,
                  paddingBottom: helpers.isDesktop() ? 60 : 0,
                },
                darkMode && { backgroundColor: Colors.darkPurple },
              ]}
            >
              <ImageBackground
                style={[
                  helpers.isDesktop() ? Flex.row : Flex.column,
                  helpers.isDesktop() ? Flex.alignCenter : Flex.alignStart,
                  Flex.justfiyCenter,
                  {
                    maxWidth: helpers.isDesktop() ? "94%" : "100%",
                    width: "100%",
                    background: "#ccc",
                  },
                ]}
                source={accountContentBoxbg}
                resizeMode="cover"
              >
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "35%" : "100%",
                      padding: helpers.isDesktop() ? 15 : 30,
                      paddingTop: helpers.isDesktop() ? 15 : 40,
                    },
                  ]}
                >
                  <View style={[!helpers.isDesktop() && { width: 100 }]}>
                    <CustomImage
                      source={
                        helpers.isDesktop()
                          ? NotificationsFlatline
                          : notificationsFlatlineMobile
                      }
                      require={true}
                      style={[
                        helpers.isWeb()
                          ? { width: "100%", height: "auto" }
                          : { width: 100, height: 150 },
                      ]}
                      altText={"The Morning Context"}
                      resizeMode={"contain"}
                    />
                  </View>
                </View>
                <View
                  style={[
                    {
                      width: helpers.isDesktop() ? "50%" : "100%",
                      padding: helpers.isDesktop() ? 15 : 16,
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 72 : 32,
                        lineHeight: helpers.isDesktop() ? 86 : 38.4,
                        fontFamily: FontFamily.abrilFatface,
                        color: "#ffffff",
                      },
                      { textAlign: "left" },
                    ]}
                  >
                    Read Smarter
                  </Text>
                  <Text
                    style={[
                      {
                        fontSize: helpers.isDesktop() ? 24 : 16,
                        lineHeight: helpers.isDesktop() ? 36 : 24,
                        fontFamily: FontFamily.light,
                        color: "#ffffff",
                      },
                      {
                        textAlign: "left",
                        marginTop: 10,
                        marginBottom: helpers.isDesktop() ? 20 : 15,
                      },
                    ]}
                  >
                    With our News Explainer, you can trust that you are receiving accurate and reliable news to kickstart your day.
                  </Text>
                  <View
                    style={[
                      helpers.isDesktop() ? Flex.row : Flex.column,
                      Flex.alignCenter,
                      { width: "100%" },
                      !helpers.isDesktop() && { paddingVertical: 40 },
                    ]}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        const dataLayer = window.dataLayer || [];
                        dataLayer.push({
                          event: "signup_initiate",
                          custom_user_id: "NA",
                          user_status: "guest",
                          CTA_text: "sign up",
                          header_title: "read smarter",
                          CTA_position: "bottom",
                          article_name: "NA",
                          signup_method: "NA",
                        });
                        dataLayer.push({
                          event: "banner_interaction",
                          custom_user_id: "NA",
                          user_status: "guest",
                          category_type: "NA",
                          banner_name: "Read smarter",
                          banner_position: "bottom",
                          CTA_text: "Sign up",
                          scroll_depth: helpers.getScrollDepth(),
                        });
                        this.props.navigateToScreen("/sign-up?redirect=/&");
                      }}
                      style={[
                        Flex.row,
                        Flex.alignCenter,
                        Flex.justfiyCenter,
                        !helpers.isDesktop() && { width: "100%" },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            backgroundColor: "rgb(255, 255, 255)",
                            borderColor: "#FFFFFF",
                            borderWidth: 1,
                            borderRadius: 4,
                            height: 56,
                            padding: 15,
                            fontFamily: FontFamily.medium,
                            fontSize: helpers.isDesktop() ? 20 : 16,
                            lineHeight: helpers.isDesktop() ? 24 : 24,
                            color: "#051462",
                            textAlign: "center",
                          },
                          !helpers.isDesktop() && { width: "100%" },
                        ]}
                      >
                        {" "}
                        SIGN UP
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </ImageBackground>
            </View>
            {/* End */}
          </>
        ) : (
          <>
            {/* yesterday -api block */}
            {this.YesterdayData(darkMode)}
          </>
        )}
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
    return (
      <ScrollView>
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  goToPricingPage = () => {
    let encodedRedirectURL = `${window.location.pathname}`;
    encodedRedirectURL = encodeURIComponent(encodedRedirectURL);
    this.props.navigateToScreen(
      "/pricing?header=true&success_redirect=" + encodedRedirectURL
    );
  }
 
  readMoreOfEdition(storyData) {
    this.props.navigateToScreen(
      `/yesterday/${storyData?.slug}`
    );
    this.trackYesterdayStoryClick(storyData?.slug);
  }

  render() {
    const {
      pageLoader,
      showErrorModal,
      showUparrow,
      footerData,
      subscriptionRefreshKey,
    } = this.state;
    let { archiveSlug } = this.props;
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/",
      native: { redirect: "/", verifyRedirect: "/" },
    };
    return (
      <React.Suspense fallback={null}>
        <View
          style={[
            webView && null,
            appStyles.appBg,
            Flex.fill,
            darkMode && { backgroundColor: Colors.darkBlackColorBg },
          ]}
        >
          {/* header : we will be new component for this module */}
          {/* <Seo data={this.getSeoObject()} page={this.getSeoPage()} pageData={{ pageTitle: this.getPageTitle(), pageSlug: archiveSlug }} /> */}
          <Seo
            data={seoObj["yesterday"]}
            url={
              process.browser
                ? window.location.origin + window.location.pathname
                : ""
            }
            page="/yesterday"
          />
          <AppHeader
            ref="header"
            history={this.props.history}
            searchQuery={this.props.searchQuery}
            locationHash={this.props.locationHash}
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
            redirectSlugs={redirectSlugs}
            refreshPage={() =>
              this.setState({ subscriptionRefreshKey: !subscriptionRefreshKey })
            }
            setCredits={(credits) => this.setState({ credits: credits })}
            navigateToSubscriptionScreen
            darkMode={darkMode}
            showFullHeader={true}
          />
          {!showErrorModal && (
            <>
              {pageLoader
                ? this.showPageLoader()
                : webView
                  ? this.renderWebPage()
                  : this.renderAppPage()}
            </>
          )}
          <AppFooter
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
            footerData={footerData}
            Breadcrumbs={Breadcrumbs}
          />

          {showErrorModal && (
            <ErrorModal
              goToHome={() => this.props.navigateToScreen("/")}
              tryAgain={() => this.getModalClickEvent()}
              show={showErrorModal}
            />
          )}
        </View>
      </React.Suspense>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(Yesterday));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Yesterday",
  },
];

const customStyle = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },
  scrollView: {},
  text: {
    fontSize: 42,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 80,
  },
});
