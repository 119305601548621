import React, { Component } from "react";
import { Text, View, ScrollView, TouchableOpacity } from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  postSingle,
  article,
  articleItem,
  Badges,
  articleLists,
  FontFamily,
  FontWeight,
  pageContent,
  Colors,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import PageCover from "../../components/page-cover";
import FeaturedArticle from "../../components/featured-article";
import Articles from "../../components/articles";
import ArticleItem from "../../components/article-item";
// import getImagePath from '../../utils/image-helper';
import ContentLoader from "react-native-easy-content-loader";
import PopularStories from "../../components/popular-stories";
import FeaturedJournalist from "../../components/featured-journalist";
import { Mixpanel } from "../../utils/mixpanel";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const recommendedPlan = {
  id: "annual-plan-inr",
  name: "Annual Plan (IN)",
  invoice_name: "Annual Plan (IN)",
  description: {
    access_desc:
      "100 long reads + paid newsletters + complete access to TMC 1 newsletter & daily stories",
  },
  currency_code: "INR",
  price: "249900",
  is_recurring: true,
  is_popular: true,
  recommended_plan: true,
  discount: {},
};

// const tmc40image = getImagePath('img/tmc40.png');
const cookies = new Cookies();

export class Category extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let categorySlug = "";
    if (webView && this.props.categorySlug) {
      categorySlug = this.props.categorySlug;
    } else if (!webView) {
      categorySlug = this.props.route.params.categorySlug;
    }
    let tmc40 =
      this.props.categoryData && this.props.categoryData.tmc40
        ? this.props.categoryData.tmc40.posts
        : false;
    let featuredAuthors =
      this.props.categoryData && this.props.categoryData.featuredAuthors
        ? this.props.categoryData.featuredAuthors
        : false;
    let popularStories =
      this.props.categoryData && this.props.categoryData.popularArticles
        ? this.props.categoryData.popularArticles
        : false;

    this.state = {
      categoryData: this.props.categoryData ? this.props.categoryData : "",
      categoryDataSSR: this.props.categoryData ? true : false,
      categorySlug: categorySlug,
      pageLoader: this.props.categoryData ? false : true,
      showErrorModal: false,
      otherArticleLists: this.props.categoryData
        ? this.props.categoryData?.postLists?.posts
        : false,
      otherArticleSortValue: "date",
      tmc40: tmc40,
      sortDisabled: this.props.categoryData ? false : true,
      token: token == undefined || token == "" ? false : token,
      userLoggedin: token == undefined || token == "" ? false : true,
      showSubscribeModel: false,
      featuredAuthors: featuredAuthors,
      popularStories: popularStories,
      showTmc40: tmc40 && featuredAuthors && popularStories ? true : false,
      showPopularStories:
        tmc40 && featuredAuthors && popularStories ? true : false,
      showFeaturedAuthors:
        tmc40 && featuredAuthors && popularStories ? true : false,
      footerData: this.props.footerData ? this.props.footerData : false,
    };
  }

  
  getUserInfo = () => {
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = { request_group: ["profile"] };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
        } else if (res.success) {
          this.setState({ userProfile: res.data });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        resolve(false);
        // this.setState({ showErrorModal: true, pageLoader: false });
      });
  };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidMount = () => {
    if (!this.state.categoryDataSSR) {
      this.getPost(this.props.scrollTop ? true : false);
    } else {
      this.setBreadcrumbs(this.state.categoryData);
    }
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
    try {
      if ((helpers.isWeb() && process.browser) || !helpers.isWeb()) {
        if(this.state.token) {
          this.getUserInfo()
        }
        Mixpanel.track("single_category_load", {
          category_slug: this.state.categorySlug,
        });
        window.webengage.track("Long Form Category Viewed", {
          "Category slug": this.state.categorySlug,
        });
        console.log({
          "Category slug": this.state.categorySlug,
        });
      }
    } catch (error) {
      console.log("mix panel error ==>", error);
    }
  };

  componentDidUpdate = () => {
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    if (
      (token && token != this.state.token) ||
      ((token == undefined || token == "") && this.state.token != false)
    ) {
      this.setState({
        token: token == undefined || token == "" ? false : token,
        userLoggedin: token == undefined || token == "" ? false : true,
      });
    }
    let categorySlug = webView
      ? this.props.categorySlug
      : this.props.route.params.categorySlug;
    if (categorySlug != this.state.categorySlug) {
      this.setState(
        {
          categorySlug: categorySlug,
          featuredAuthors: false,
        },
        () => this.getPost(true)
      );
    }
  };

  setBreadcrumbs = (data) => {
    Breadcrumbs = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: data.category.name,
      },
    ];
  };

  getPost = (scroll) => {
    this.setState(
      {
        showErrorModal: false,
        pageLoader: true,
      },
      () => this.scrollToTop(scroll)
    );
    const { token } = this.state;
    let endpoint = `/category/${this.state.categorySlug}`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success && res.code == "category_found") {
          this.setState({
            categoryData: res.data,
            otherArticleLists: false,
            pageLoader: false,
          });
          this.getOtherArticles(res.data);
          if (!this.state.featuredAuthors) {
            this.getFeaturedAuthors();
          }
          this.setBreadcrumbs(res.data);
        } else {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getOtherArticles = async (categoryData) => {
    const { otherArticleSortValue } = this.state;
    this.setState({
      sortDisabled: true,
    });
    let endpoint = `/stories`;
    let body = {
      limit: 3,
      page: 1,
      sort_on: otherArticleSortValue,
      sort_by: otherArticleSortValue == "date" ? "desc" : "asc",
      exclude: [categoryData.featured_post.id],
      filters: [
        {
          key: "category",
          value: [this.state.categorySlug],
        },
        {
          key:"type",
          value: ["regular", "yesterday", "newsletter"]
        }
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            otherArticleLists: res.data.story,
            sortDisabled: false,
          });
          if (categoryData.category.tmc40) {
            this.getTMC40(categoryData, res.data.story);
          }
        } else {
          this.setState({
            showErrorModal: true,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getTMC40 = async (categoryData, otherArticleLists) => {
    this.setState({
      tmc40: false,
    });
    let excludeArr = otherArticleLists.map((article) => {
      return article.id;
    });
    excludeArr.push(categoryData.featured_post.id);
    let endpoint = `/storyplays`;
    let body = {
      sort_on: "date",
      sort_by: "desc",
      exclude: excludeArr,
      filters: [
        {
          key: "category",
          value: [this.state.categorySlug],
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            tmc40: res.data,
            showTmc40: true,
            sortDisabled: false,
          });

          this.getPopularStories(excludeArr, res.data);
        } else {
          this.setState({
            showTmc40: true,
            // showErrorModal: true
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
        });
        console.log("Error=>> down", JSON.stringify(error));
        console.log(error);
      });
  };

  getPopularStories = (excludeArr, tmc40Data) => {
    tmc40Data[0].storyplays.filter((story) => {
      story.company.filter((companydata) => {
        companydata.posts.filter((post) => {
          excludeArr.push(post.id);
        });
      });
    });

    let endpoint = `/stories/popular`;
    let body = {
      limit: 4,
      sort_on: "date",
      sort_by: "desc",
      exclude: excludeArr,
      filters: [
        {
          key: "category",
          value: [this.state.categorySlug],
        },
        {
          key:"type",
          value: ["regular", "yesterday", "newsletter"]
        }
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            popularStories: res.data.story,
            showPopularStories: true,
          });
        } else {
          this.setState({
            showPopularStories: true,
            // showErrorModal: true
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getFeaturedAuthors = () => {
    let endpoint = `/authors`;
    let body = {
      limit: 2,
      page: 1,
      sort_on: "posts",
      sort_by: "desc",
      filters: [
        {
          key: "category",
          value: [this.state.categorySlug],
        },
        { key: "roles", value: ["editor"] },
      ],
    };

    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          this.setState({
            featuredAuthors: res.data.author,
            showFeaturedAuthors: true,
          });
        } else {
          this.setState({
            showFeaturedAuthors: true,
            // showErrorModal: true
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      } else {
        if (this.appscrollview) {
          this.appscrollview.scrollTo({ y: 0, animated: true });
        }
      }
    }
  };

  getModalClickEvent = () => {};

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1200,
          helpers.isDesktop() && { marginBottom: 70 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[20]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 50,
          }}
          active={true}
          pHeight={[100]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 10, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  sortArticles = (slug) => {
    const { categoryData, otherArticleSortValue, sortDisabled } = this.state;
    if (!sortDisabled && otherArticleSortValue != slug) {
      this.setState({ otherArticleSortValue: slug }, () =>
        this.getOtherArticles(categoryData)
      );
    }
  };

  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  viewAllArticles = () => {
    const { categorySlug } = this.state;
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + categorySlug + "/all");
    } else {
      this.props.navigation.navigate("category-archive", {
        archiveSlug: categorySlug,
      });
    }
  };

  viewAllCatArticles = () => {
    const { categorySlug } = this.state;
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + categorySlug + "/all");
    } else {
      this.props.navigation.navigate("categories", {
        archiveSlug: categorySlug,
      });
    }
  };

  viewAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };

  viewCompany = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/company/" + slug);
    } else {
      this.props.navigation.navigate("company-archive", { archiveSlug: slug });
    }
  };

  viewCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  goToSignUp = (email) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(
        "/sign-up?redirect=/category/" +
          this.state.categorySlug +
          (email ? "&email=" + email : "")
      );
    } else {
      this.props.navigation.navigate("/sign-up", {
        redirect: "category",
        categorySlug: this.state.categorySlug,
        verifyRedirect: "/category/" + this.state.categorySlug,
        email: email,
      });
    }
  };

  goToSignUpByEmail = (email) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign me up",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "NA",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=/category/" +
        this.state.categorySlug +
        "&providerHint=tmc-passwordless" +
        (email ? "&emailHint=" + email : "")
    );
  };
  goToGoogleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with Google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        "/category/" +
        this.state.categorySlug +
        "&providerHint=google"
    );
  };

  goToGoogleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "sign up with Google",
      header_title: "start your free trial today",
      CTA_position: "middle",
      article_name: "NA",
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
        "/category/" +
        this.state.categorySlug +
        "&providerHint=google"
    );
  };

  renderPageCoverRightSection = () => {
    const { categoryData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[article.countWrap, { flexDirection: "row" }]}>
        <Text
          style={[
            article.count,
            helpers.isDesktop() && article.countDesktop,
            { marginRight: 5 },
            !helpers.isDesktop() && {
              fontSize: 18,
              lineHeight: 21,
              marginBottom: 2,
            },
            darkMode && { color: "rgba(255,255,255,0.87)" },
          ]}
        >
          {categoryData.story_count}
        </Text>
        <Text
          style={[
            article.countLabel,
            helpers.isDesktop() && article.countLabelDesktop,
            !helpers.isDesktop() && { fontSize: 12, lineHeight: 19 },
            darkMode && { color: "rgba(255,255,255,0.87)" },
          ]}
        >
          {categoryData && categoryData.story_count == 1
            ? "Article"
            : "Articles"}
        </Text>
      </View>
    );
  };

  trackStoryClick = (position, title) => {
    // try {
    //     Mixpanel.track('story_clicked', { 'position': position, 'title': title });
    // }
    // catch (error) {
    //     console.log("mix panel error =>", error);
    // }
  };

  renderPageHeader = () => {
    const { categoryData, categoryDataSSR, token } = this.state;
    let checkDesktop = this.props.hideImageSection
      ? false
      : helpers.isDesktop()
      ? true
      : false;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[Margin.mt_4, helpers.isDesktop() && Margin.mb_4]}>
        <PageCover
          title={categoryData.category.name}
          coverImage={categoryData.category.image.url}
          topRightContent={this.renderPageCoverRightSection()}
          content={categoryData.category.description}
          lazyLoad={!categoryDataSSR}
          category={true}
          darkMode={darkMode}
        />

        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWebFull,
            { marginBottom: helpers.isDesktop() ? 0 : 0 },
          ]}
        >
          <View
            style={[
              Margin.mb_1,
              !helpers.isDesktop() && Margin.mb_3,
              Flex.row,
              !helpers.isDesktop() && Flex.justfiyCenter,
              helpers.isDesktop() && Padding.ph_2,
            ]}
          >
            <View
              style={[
                !helpers.isDesktop() && {
                  backgroundColor: darkMode ? Colors.darkBlackColorBg : "white",
                  zIndex: 1,
                  paddingHorizontal: 20,
                },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  helpers.isDesktop() && {
                    marginRight: 25,
                  },
                  {
                    borderBottomWidth: helpers.isDesktop() ? 2 : 0,
                  },
                  darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
                ]}
              >
                <Text
                  style={[
                    // pageContent.sectionTitle,
                    pageContent.sectionTitleDesktop,
                    { color: "#000", marginRight: 0 },
                    // helpers.isDesktop() && { marginRight: 0 },
                    helpers.isDesktop() && { paddingBottom: 5 },
                    darkMode && {
                      borderBottomColor: "rgba(255,255,255,0.6)",
                      color: "rgba(255,255,255,0.87)",
                    },
                  ]}
                >
                  Featured Story
                </Text>
              </View>
            </View>
            {!helpers.isDesktop() && (
              <View
                style={[
                  !Helpers.titleLine,
                  darkMode && { backgroundColor: "rgba(255,255,255,0.6)" },
                ]}
              ></View>
            )}
            {helpers.isDesktop() && (
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={[
                    Helpers.titleLineLight,
                    darkMode && { backgroundColor: "rgba(255,255,255,0.6)" },
                  ]}
                ></View>
              </View>
            )}
          </View>
        </View>
        <FeaturedArticle
          data={categoryData.featured_post}
          category={categoryData.category}
          viewArticle={(catSlug, slug, postData) =>
            this.viewArticle(catSlug, slug, postData)
          }
          viewAuthor={(slug) => this.viewAuthor(slug)}
          lazyLoad={!categoryDataSSR}
          trackStoryClick={(title) => this.trackStoryClick("hero story", title)}
          darkMode={darkMode}
          isShowBanner={!token}
        />
      </View>
    );
  };

  renderPageContent = () => {
    const {
      otherArticleLists,
      otherArticleSortValue,
      sortDisabled,
      tmc40,
      categorySlug,
      categoryDataSSR,
      featuredAuthors,
      popularStories,
      userLoggedin,
      token,
      showTmc40,
      showPopularStories,
      showFeaturedAuthors,
    } = this.state;
    let checkDesktop = this.props.hideSection
      ? false
      : helpers.isDesktop()
      ? true
      : false;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/category/" + this.state.categorySlug,
      native: {
        redirect: "category",
        categorySlug: this.state.categorySlug,
        verifyRedirect: "/category/" + this.state.categorySlug,
      },
    };
    return (
      <>
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWebFull,
          ]}
        >
          <View>
            {otherArticleLists && (
              <Articles
                data={otherArticleLists}
                redirectSlugs={redirectSlugs}
                navigation={this.props.navigation}
                sortValue={otherArticleSortValue}
                sortArticles={(slug) => this.sortArticles(slug)}
                viewArticle={(catSlug, slug, postData) =>
                  this.viewArticle(catSlug, slug, postData)
                }
                viewAllArticles={() => this.viewAllArticles()}
                viewAllCatArticles={() => this.viewAllCatArticles()}
                categorySlug={categorySlug}
                viewCategory={(catSlug, slug) =>
                  this.viewCategory(catSlug, slug)
                }
                viewAuthor={(slug) => this.viewAuthor(slug)}
                category={true}
                trackStoryClick={(title) =>
                  this.trackStoryClick("latest story", title)
                }
                darkMode={darkMode}
                userProfile={this.state.userProfile}
              />
            )}
          </View>
        </View>
        {checkDesktop && (
          <View>
            <View
              style={[
                Helpers.titleLine,
                darkMode && { backgroundColor: "rgba(255,255,255,0.6)" },
              ]}
            ></View>
          </View>
        )}
        {showTmc40 && showPopularStories && showFeaturedAuthors && (
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWebFull,
            ]}
          >
            <View style={[Padding.ph_0, helpers.isDesktop() && Flex.row]}>
              {!checkDesktop && popularStories && (
                <View
                  style={[
                    Flex.row,
                    { width: helpers.isDesktop() ? "35%" : "100%" },
                    {
                      flexWrap: "wrap",
                      marginTop: helpers.isDesktop() ? 50 : 40,
                      height: helpers.isWeb() ? "fit-content" : "auto",
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                    !helpers.isDesktop() && Margin.mb_4,
                  ]}
                >
                  {popularStories && (
                    <PopularStories
                      viewArticle={(catSlug, slug, postData) =>
                        this.viewArticle(catSlug, slug, postData)
                      }
                      viewAuthor={(slug) => this.viewAuthor(slug)}
                      data={popularStories}
                      viewAllArticles={() => this.viewAllArticles()}
                      categorySlug={categorySlug}
                      token={token}
                      darkMode={darkMode}
                      custom_user_id={this.state.custom_user_id}
                    />
                  )}
                </View>
              )}
              {tmc40 && (
                <View
                  style={[
                    Margin.mt_5,
                    Padding.ph_2,
                    { width: helpers.isDesktop() ? "60%" : "100%" },
                  ]}
                >
                  {tmc40[0].storyplays.map((item, index) => {
                    let postIndex = 0;
                    return (
                      <View
                        key={`compItem${index}`}
                        style={[Margin.mt_0, Margin.mb_2]}
                      >
                        <View
                          style={[
                            {
                              width:
                                helpers.isDesktop() && helpers.isWeb()
                                  ? "fit-content"
                                  : "100%",
                            },
                            !helpers.isDesktop() && Flex.row,
                          ]}
                        >
                          <View>
                            <Text
                              style={[
                                Helpers.textLeft,
                                helpers.isDesktop() && Helpers.textUppercase,
                                Typo.h3_M,
                                helpers.isDesktop() && Typo.h3_MDesktop,
                                Margin.mb_3,
                                { fontSize: 30, fontWeight: "400" },
                                {
                                  fontSize: helpers.isDesktop() ? 30 : 20,
                                  color: "#000",
                                },
                                !helpers.isDesktop() && {
                                  fontFamily: FontFamily.abrilFatface,
                                },
                                darkMode && { color: "rgba(255,255,255,0.87)" },
                              ]}
                            >
                              {item.name}
                            </Text>
                            <View
                              style={[
                                Helpers.titleLine,
                                {
                                  height: 2,
                                  backgroundColor: darkMode
                                    ? "rgba(255,255,255,0.6)"
                                    : "black",
                                },
                              ]}
                            ></View>
                          </View>
                          {/* {!checkDesktop && <View style={{ width: '100%', flex: 1 }}>
                                                    <View style={[Helpers.line, { borderColor: 'rgba(0,0,0,0.14)' }]}></View>
                                                </View>} */}
                        </View>
                        <View key={`comp${index}`} style={{ width: "100%" }}>
                          <View
                            style={[
                              helpers.isDesktop() && Flex.row,
                              { flexWrap: "wrap" },
                            ]}
                          >
                            {item.company.map((citem, cindex) => {
                              let btnClickCompany;
                              if (helpers.isWeb()) {
                                btnClickCompany = {
                                  to: "/company/" + citem.slug,
                                };
                              } else {
                                btnClickCompany = {
                                  onPress: () => this.viewCompany(citem.slug),
                                };
                              }
                              return (
                                <>
                                  {citem.posts &&
                                    citem.posts.map((pitem, pindex) => {
                                      postIndex++;
                                      return (
                                        <>
                                          {helpers.isWeb() ? (
                                            <View
                                              style={[
                                                Margin.mb_3,
                                                {
                                                  paddingRight:
                                                    helpers.isDesktop()
                                                      ? 20
                                                      : 0,
                                                },
                                                !(index == 1) && {
                                                  width: helpers.isDesktop()
                                                    ? "50%"
                                                    : "100%",
                                                },
                                                index == 1 &&
                                                  postIndex != 1 && {
                                                    width: helpers.isDesktop()
                                                      ? "50%"
                                                      : "100%",
                                                  },
                                                index == 1 &&
                                                  postIndex != 1 && {
                                                    width: helpers.isDesktop()
                                                      ? "50%"
                                                      : "100%",
                                                  },
                                                index == 1 &&
                                                  postIndex == 1 && {
                                                    width: "100%",
                                                  },
                                              ]}
                                            >
                                              <TouchableOpacityLink
                                                {...btnClickCompany}
                                              >
                                                <View>
                                                  <Text
                                                    style={[
                                                      articleLists.badge,
                                                      {
                                                        width: "auto",
                                                        marginBottom:
                                                          helpers.isDesktop()
                                                            ? 15
                                                            : 10,
                                                        textAlign: "left",
                                                        fontFamily:
                                                          FontFamily.regular,
                                                        color: "#907cff",
                                                        textTransform:
                                                          "capitalize",
                                                      },
                                                      !helpers.isDesktop() && {
                                                        fontSize: 14,
                                                      },
                                                    ]}
                                                  >
                                                    {citem.name}
                                                  </Text>
                                                </View>
                                              </TouchableOpacityLink>
                                              <ArticleItem
                                                viewArticle={(
                                                  catSlug,
                                                  slug,
                                                  postData
                                                ) =>
                                                  this.viewArticle(
                                                    catSlug,
                                                    slug,
                                                    postData
                                                  )
                                                }
                                                viewAuthor={(slug) =>
                                                  this.viewAuthor(slug)
                                                }
                                                data={pitem}
                                                marginTrue={true}
                                                single={
                                                  postIndex == 1 ? true : false
                                                }
                                                hideImage={
                                                  index == 2 ||
                                                  (index == 1 && postIndex != 1)
                                                }
                                                horizontalLayout={
                                                  index == 1 && postIndex == 1
                                                }
                                                handleClick={(title) => {
                                                  this.trackStoryClick(
                                                    item.name,
                                                    title
                                                  );
                                                }}
                                                darkMode={darkMode}
                                              />
                                            </View>
                                          ) : (
                                            <View style={[{ width: "100%" }]}>
                                              <TouchableOpacityLink
                                                {...btnClickCompany}
                                              >
                                                <View
                                                  style={[
                                                    articleItem.col33,
                                                    helpers.isDesktop() &&
                                                      articleItem.col33Desktop,
                                                    helpers.isDesktop() &&
                                                      articleLists.col33Desktop,
                                                  ]}
                                                >
                                                  <Text
                                                    style={[
                                                      Badges.infoBadgeOutline,
                                                      helpers.isDesktop() &&
                                                        Badges.infoBadgeOutlineDesktop,
                                                      articleLists.badge,
                                                      {
                                                        width: "auto",
                                                        marginBottom: 15,
                                                        textTransform:
                                                          "capitalize",
                                                      },
                                                      !webView && {
                                                        borderWidth: 0,
                                                        textAlign: "left",
                                                        paddingLeft: 0,
                                                        marginBottom: 5,
                                                      },
                                                    ]}
                                                  >
                                                    {citem.name}
                                                  </Text>
                                                </View>
                                              </TouchableOpacityLink>
                                              <ArticleItem
                                                viewArticle={(
                                                  catSlug,
                                                  slug,
                                                  postData
                                                ) =>
                                                  this.viewArticle(
                                                    catSlug,
                                                    slug,
                                                    postData
                                                  )
                                                }
                                                viewAuthor={(slug) =>
                                                  this.viewAuthor(slug)
                                                }
                                                data={pitem}
                                                single={
                                                  postIndex == 1 ? true : false
                                                }
                                                hideImage={
                                                  index == 2 ||
                                                  (index == 1 && postIndex != 1)
                                                }
                                                horizontalLayout={
                                                  index == 1 && postIndex == 1
                                                }
                                                category={true}
                                                handleClick={(title) => {
                                                  this.trackStoryClick(
                                                    item.name,
                                                    title
                                                  );
                                                }}
                                                darkMode={darkMode}
                                              />
                                            </View>
                                          )}
                                        </>
                                      );
                                    })}
                                </>
                              );
                            })}
                            {item.others.posts.map((pitem, pindex) => {
                              postIndex++;
                              return (
                                <>
                                  <View
                                    style={[
                                      Margin.mb_3,
                                      {
                                        paddingRight: helpers.isDesktop()
                                          ? 20
                                          : 0,
                                      },
                                      !(index == 1) && {
                                        width: helpers.isDesktop()
                                          ? "50%"
                                          : "100%",
                                      },
                                      index == 1 &&
                                        postIndex != 1 && {
                                          width: helpers.isDesktop()
                                            ? "50%"
                                            : "100%",
                                        },
                                      index == 1 &&
                                        postIndex != 1 && {
                                          width: helpers.isDesktop()
                                            ? "50%"
                                            : "100%",
                                        },
                                      index == 1 &&
                                        postIndex == 1 && { width: "100%" },
                                    ]}
                                  >
                                    <TouchableOpacity>
                                      {helpers.isDesktop() && (
                                        <View>
                                          <Text
                                            style={[
                                              articleLists.badge,
                                              {
                                                width: "auto",
                                                marginBottom:
                                                  helpers.isDesktop() ? 15 : 10,
                                                textAlign: "left",
                                                fontFamily: FontFamily.regular,
                                                color: "#907cff",
                                                textTransform: "capitalize",
                                              },
                                              !helpers.isDesktop() && {
                                                fontSize: 14,
                                              },
                                            ]}
                                          >
                                            {" "}
                                          </Text>
                                        </View>
                                      )}
                                    </TouchableOpacity>
                                    <ArticleItem
                                      viewArticle={(catSlug, slug, postData) =>
                                        this.viewArticle(
                                          catSlug,
                                          slug,
                                          postData
                                        )
                                      }
                                      viewAuthor={(slug) =>
                                        this.viewAuthor(slug)
                                      }
                                      data={pitem}
                                      single={postIndex == 1 ? true : false}
                                      hideImage={
                                        index == 2 ||
                                        (index == 1 && postIndex != 1)
                                      }
                                      horizontalLayout={
                                        index == 1 && postIndex == 1
                                      }
                                      handleClick={(title) => {
                                        this.trackStoryClick(item.name, title);
                                      }}
                                      darkMode={darkMode}
                                    />
                                  </View>
                                </>
                              );
                            })}
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              )}
              {(popularStories || featuredAuthors) && (
                <View
                  style={[
                    Flex.column,
                    { width: helpers.isDesktop() ? "40%" : "100%" },
                    {
                      flexWrap: "wrap",
                      marginTop: helpers.isDesktop() ? 50 : 0,
                      height: helpers.isWeb() ? "fit-content" : "auto",
                    },
                  ]}
                >
                  <View style={[{ paddingLeft: 20, paddingRight: 20 }]}>
                    {popularStories && checkDesktop && (
                      <PopularStories
                        viewArticle={(catSlug, slug, postData) =>
                          this.viewArticle(catSlug, slug, postData)
                        }
                        viewAuthor={(slug) => this.viewAuthor(slug)}
                        data={popularStories}
                        viewAllArticles={() => this.viewAllArticles()}
                        categorySlug={categorySlug}
                        token={token}
                        gotoSignUp={(email) => {
                          this.goToSignUp(email);
                        }}
                        darkMode={darkMode}
                        custom_user_id={this.state.custom_user_id}
                      />
                    )}
                  </View>
                  <View>
                    {featuredAuthors && (
                      <FeaturedJournalist
                        viewAuthor={(slug) => this.viewAuthor(slug)}
                        data={featuredAuthors}
                        darkMode={darkMode}
                      />
                    )}
                  </View>
                </View>
              )}
            </View>
          </View>
        )}
      </>
    );
  };

  renderWebPage = () => {
    return (
      <>
        {this.renderPageHeader()}
        {this.renderPageContent()}
      </>
    );
  };

  renderAppPage = () => {
    const {
      pageLoader,
      showErrorModal,
      footerData,
      categoryData,
      categorySlug,
    } = this.state;
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/category/" + this.state.categorySlug,
      native: {
        redirect: "category",
        categorySlug: this.state.categorySlug,
        verifyRedirect: "/category/" + this.state.categorySlug,
      },
    };

    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={(ref) => (this.appscrollview = ref)}
      >
        {this.renderPageHeader()}
        {this.renderPageContent()}
        <AppFooter
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          footerData={footerData}
          Breadcrumbs={Breadcrumbs}
        />
      </ScrollView>
    );
  };

  render() {
    const {
      pageLoader,
      showErrorModal,
      footerData,
      categoryData,
      categorySlug,
    } = this.state;
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let redirectSlugs = {
      web: "/category/" + this.state.categorySlug,
      native: {
        redirect: "category",
        categorySlug: this.state.categorySlug,
        verifyRedirect: "/category/" + this.state.categorySlug,
      },
    };
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj[`category-${categorySlug}`]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/category/:category_slug"
          pageData={categoryData}
        />

        <AppHeader
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
        />
        {!showErrorModal && (
          <>
            {pageLoader
              ? this.showPageLoader()
              : webView
              ? this.renderWebPage()
              : this.renderAppPage()}
          </>
        )}
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(Category));

let Breadcrumbs = [];
