import { chargebeeHostedPageFactory__Interface, chargebeeHostedPage__Interface, chargebeeHostedPage__v1, chargebeeHostedPage__v1__Factory } from "../hosted-page";
import { chargebeeController__I, ControllerStatusType } from "./interface";
import { chargebeePortalFactory__Interface, chargebeePortal__Interface, chargebeePortal__v1__Factory, chargebeePortal__v1 } from "../portal";
import { chargebeeInstanceFactory__Interface, chargebeeInstance__Interface, ChargebeeInstance__v1, chargebeeInstance__v1__Factory } from "../cb-instance";
import { Dictionary } from "../global-types";
import { EventEmitter } from "events"


export abstract class chargbeeController extends EventEmitter implements chargebeeController__I{
    instance: chargebeeInstance__Interface;
    tmcAuthToken?: any;
    customerInfo?: Dictionary;
    activeSubscription?: Dictionary;
    status: ControllerStatusType = 'not-initialized';
    instanceFactory: chargebeeInstanceFactory__Interface;
    _controller?: this | null | Promise<this> | Promise<null>
    constructor(instanceFactory: chargebeeInstanceFactory__Interface, tmcAuthToken?: any | null){
        super()
        console.log('chargebee: crontroller: inited')
        this.instanceFactory = instanceFactory
        this.tmcAuthToken = tmcAuthToken
        this.instance = this.instanceFactory.createInstance()
    }


    activatedController(): this | Promise<this> | null | Promise<null> {
        return Promise.resolve(this._controller)
    }

    init(tmcAuthToken: any): Promise<any> {
        this.tmcAuthToken = tmcAuthToken
        return new Promise((resolve, reject) => {
            /**
             * Initialize instance with auth token
             */
            this.instance.init(tmcAuthToken).
                then(cb_instance => {
                /**
                 * A successfully initialized cb-instance has user's billing platform info
                 * @param cb_instance - {@link chargebeeInstance__Interface}
                 */
                    this.status = 'initialized'
                    this.initParam({
                        customer: {
                            id: cb_instance.billingInfo?.billing_platform_user_id
                        }
                    })

                    resolve(this)
                }).catch(er => {
                    reject(er)
                })
        })
    }

    initParam(params: any): any {
        return this.emit('initParam', params)
    }

    setParam(params: any): any {
        return this.emit('setParam', params)

    }

    activateController(activator: Promise<ControllerStatusType>): boolean {
        this.status = 'pending-activation'
        if(!this.tmcAuthToken){
            throw new Error("chargbeeController:activateController:Error: No tmcAuthToken attribute set for controller instance")
        }
        let activationPromise: Promise<this> = new Promise((resolve, reject) => {
            this.init(this.tmcAuthToken).then((_handle) =>{
                activator.then(activationStatus => {
                    this.status = activationStatus
                    console.log('chargebee: activator success: ', this)
                    resolve(this)
                }).catch(err => {
                    this.status = 'error'
                    reject(err)
                })
            })

        })
        this._controller = activationPromise
        return true
    }

    setToken(tmcAuthToken: any): boolean {
        this.tmcAuthToken = tmcAuthToken
        this.instance.useToken(tmcAuthToken)
        return true
    }
    /**
     * @param info - The output of /profile API to store.
     */
    setCustomerInfo(info: Dictionary): any{
        this.customerInfo = info
        return this
    }
}