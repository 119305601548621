import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  FlatList,
  Linking,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  article,
  FontFamily,
  pageContent,
  FontWeight,
  Colors,
  banner,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../../components/error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import SubscriptionPricingPlan from "../../components/subscription-pricing-plan";
import WhyTMCSection from "../../components/why-choose-tmc";
import PageBreak from "../../components/page-break";
import CustomImage from "../../components/custom-image";
import SubscriptionInfoBox from "../../components/subscription-info-box";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import HomeReadersQuote from "../../components/home-readers-quote";
import ContentLoader from "react-native-easy-content-loader";
import { Mixpanel } from "../../utils/mixpanel";
import Seo from "../../components/seo-meta";
import AccordionListItem from "../../components/accordian";
import TouchableOpacityLinkExternal from "../../components/touchable-opacity-link-external";
import SubscriptionPlanBox from "../../components/subscription-plan-box";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";

const cookies = new Cookies();
const checkIcon = getImagePath("icons/check.png");
const studentBanner = getImagePath("img/studentSubscriptionbanner.png");
const infoIcon = getImagePath("icons/info-red.png");

const SECTIONS = [
  {
    title: "How do I qualify for this?",
    content:
      "We offer up to 40% off our normal plans. You’ll be asked to upload a scan of your student ID, clearly showing your name, institute, name of the course and the validity of the ID to get institutional access. The subscription must be in the name and institute email address of the student. As per company policy, we do not accept ID cards of students who are less than 18 years of age.",
  },
  {
    title: "I’m graduating this year, am I still eligible?",
    content: "Yes, you are.",
  },
  {
    title: "What if I don’t have a student ID?",
    content:
      "If your institution hasn’t issued an ID card, or the card doesn’t have a validity/start date, you can upload a scan of any other document showing that you’re currently enrolled. Examples: acceptance letters, end-of-term marksheets.",
  },
  {
    title: "I’m enrolled in a professional course, do I qualify?",
    content:
      "No, the discount is only applicable to full-time students at educational institutions.",
  },
  {
    title: "Can I subscribe on behalf of a student?",
    content:
      "No, you cannot subscribe on behalf of your relative, child, brother, sister, friend, spouse etc.",
  },
  {
    title: "I am already a student subscriber. Should I prove my student status once again when I renew my subscription?",
    content:
      "Yes, we verify the status once again before granting access to the student subscription application. You have to upload a scan of your student ID, clearly showing your name, institute, name of the course, and the validity of the ID to get institutional access.",
  },
  {
    title: "Is there an option for a refund?",
    content: "We don’t issue refunds. Write to us at ",
  },
  {
    title: "Can I transfer my student subscription to another ID?",
    content:
      "No, we verify your student status before granting access to the student subscription plan. Both, during the time of verification as well as post verification, the subscription cannot be transferred to another account.",
  },
  {
    title: "I want all my students to have access, can I have a better plan?",
    content: "Write to ",
  },
];

export class TeamSubscription extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    this.state = {
      token: tokenSet ? token : false,
      pageLoader: this.props.postData ? false : true,
      showErrorModal: false,
      enableUpgradeModal: false,
      enableCancelSubModal: false,
      plansData: false,
      userSubscription: false,
      teamSubscription: false,
      activeSubscription: false,
      country: false,
      pricingPlanRefreshKey: false,
      bannerTitle: "Impact Journalism for you.",
      plansTitle: "Choose your subscription",
      planCheckoutLoaderState: false,
    };

    this.subscriptionLayout = 0;
  }

  componentDidMount = () => {
    const { token } = this.state;
    if (token) {
      this.getUserInfo();
    } else {
      this.getSubscriptionPlans();
    }
    this.getStaticContent();
    this.getWriters();
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }


  componentDidUpdate = (prevProps) => {
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    if (
      (!(
        this.props.searchQuery &&
        (this.props.searchQuery.includes("subscribe=") ||
          this.props.searchQuery.includes("buy-credits=true"))
      ) &&
        ((token && token != this.state.token) ||
          ((token == undefined || token == "") &&
            this.state.token != false))) ||
      (!helpers.isWeb() &&
        prevProps.app.refreshScreen != this.props.app.refreshScreen)
    ) {
      this.setState(
        {
          token: token == undefined || token == "" ? false : token,
          pageLoader: true,
        },
        () => {
          if (this.state.token) {
            this.getUserInfo(true);
            this.refs.header.getUserInfo();
          } else {
            this.getSubscriptionPlans();
          }
        }
      );
    }
  };


  setPlanButtonLoaderState = (state) => {
    this.setState({
        planCheckoutLoaderState: state
    })
  }

  getPlanButtonLoaderState = () => {
      return this.state.planCheckoutLoaderState
  }

  refreshPage = () => {
    if (this.state.token) {
      this.getUserInfo();
    }
  };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      }
    }
  };

  getWriters = () => {
    this.setState({ showErrorModal: false });
    const { token } = this.state;
    let endpoint = `/authors`;
    let body = {
      limit: 3,
      sort_on: "posts",
      sort_by: "desc",
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          this.setState({ writerData: res.data.author });
        } else {
          // this.setState({ showErrorModal: true, pageLoader: false });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        // this.setState({ showErrorModal: true, pageLoader: false });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getUserInfo = (scroll) => {
    this.scrollToTop(scroll);
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["subscription"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          return false;
        }
        let userSubscription =
          !res.data.subscription ||
            JSON.stringify(res.data.subscription) == "{}"
            ? false
            : res.data.subscription;
        let teamSubscription =
          !res.data.team_subscription ||
            JSON.stringify(res.data.team_subscription) == "{}"
            ? false
            : res.data.team_subscription;
        let activeSubscription =
          (userSubscription && userSubscription.status != "expired") ||
            (teamSubscription &&
              teamSubscription.is_team_member &&
              teamSubscription.status != "expired")
            ? true
            : false;
        if (res.success) {
          this.setState({
            userSubscription: userSubscription,
            teamSubscription: teamSubscription,
            activeSubscription: activeSubscription,
            bannerTitle: activeSubscription
              ? "Thanks for being a TMC subscriber!"
              : "Become a TMC subscriber",
            plansTitle: activeSubscription
              ? "Active subscription plans"
              : "Choose your subscription",
          });
          this.getSubscriptionPlans(res.data);
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  getSubscriptionPlans = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    let body = {
      filters: {
        tmc_group: ["student"],
      },
    };
    if (profileData && profileData?.subscription?.plan) {
      body.filters.plan_gte = profileData.subscription.plan.id;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          this.setState({
            plansData:
              profileData &&
                (profileData?.team_subscription?.is_team_member ||
                  profileData?.subscription?.plan)
                ? { student: [] }
                : res.data.plans,
            country: res.data.country,
            pricingPlanRefreshKey: !this.state.pricingPlanRefreshKey,
            pageLoader: false,
          });
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
      this.props.navigateToScreen("/sign-in?redirect=/student-subscription");
    } else {
      this.props.removeUserToken();
      this.props.navigation.navigate("/sign-in", {
        redirect: "subscriptions",
        verifyRedirect: "/student-subscription",
      });
    }
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWebFull,
          helpers.isDesktop() && { marginBottom: 100 },
        ]}
      >
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[400]}
          title={false}
          pRows={5}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[50]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <ContentLoader
          containerStyles={{
            paddingHorizontal: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
          active={true}
          pHeight={[70]}
          title={false}
          pRows={3}
          pWidth={["100%"]}
          paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
        <View>
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 40,
            }}
            active={true}
            pHeight={[80]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[30]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
        <View style={[Flex.row]}>
          <View style={{ flex: 0.4 }}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[200]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={[Margin.ml_2, { flex: 0.6 }]}>
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[50]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <ContentLoader
              containerStyles={{
                paddingHorizontal: 0,
                marginTop: 0,
                marginBottom: 0,
              }}
              active={true}
              pHeight={[32]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  getStaticContent = () => {
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: ["readers_quotes"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "content_found") {
          this.setState({ staticContent: res.data });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  viewAuthorDetails = (Slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + Slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: Slug });
    }
  };

  navigateToTag = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/tags/" + slug);
    } else {
      this.props.navigation.navigate("tags-archive", { archiveSlug: slug });
    }
  };

  pushSubscribeEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "subscribe_initiate",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      header_title: "stay upto date with the latest stories",
      banner_name: "subscribe to TMC now",
      CTA_text: "subscribe",
      CTA_position: "bottom",
      scroll_depth: helpers.getScrollDepth(),
      category_type: "student subscription",
    });
  }

  pushPurchasePlanInteractionEventToDataLayer = (subscription, btnTitle) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "purchase_plan_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in " : "guest",
      plan_price: subscription.price,
      plan_name: subscription.name?.toLowerCase(),
      CTA_text: btnTitle.toLowerCase(),
      scroll_depth: helpers.getScrollDepth(),
    });
  };


  subscribeToPlan = (subscribtion, btnTitle) => {
    console.log("subscribtion:", subscribtion);
    this.pushPurchasePlanInteractionEventToDataLayer(subscribtion, btnTitle)
    try {
      Mixpanel.track("click_select_plan_btn", {
        plan: subscribtion.name,
        price: subscribtion.price,
        discount:
          subscribtion.discount && subscribtion.discount.amount ? "yes" : "no",
      });
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    this.refs.header.showSubscriptionPlan(
      subscribtion.id,
      subscribtion,
      this.state.country
    );
  };

  goToSignUp = (email) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(
        "/sign-up?redirect=/student-subscription" +
        (email ? "&email=" + email : "")
      );
    } else {
      this.props.navigation.navigate("/sign-up", {
        redirect: "/student-subscription",
        verifyRedirect: "/student-subscription",
        email: email,
      });
    }
  };

  trackReadTerms = () => {
    // try {
    //     if(helpers.isWeb()) {
    //         Mixpanel.track('click_read_tc');
    //     }
    // }
    // catch(error) {
    //     console.log('mix panel error =>', error);
    // }
  };

  faq = () => {
    let webView = helpers.isWeb();
    let btnClickEmail;

    if (helpers.isWeb()) {
      btnClickEmail = {
        href: `mailto:priya@mailtmc.com`,
      };
    } else {
      btnClickEmail = {
        onPress: () => Linking.openURL(`mailto:priya@mailtmc.com`),
      };
    }
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          { paddingVertical: helpers.isDesktop() ? 70 : 20 },
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1000,
        ]}
      >
        <View style={[Flex.alignCenter, Margin.mb_4]}>
          <Text
            style={[
              {
                fontSize: helpers.isDesktop() ? 34 : 24,
                lineHeight: helpers.isDesktop() ? 43 : 28,
                fontFamily: FontFamily.abrilFatface,
                fontWeight: FontWeight.regular,
                color: "#000",
              },
              darkMode && { color: Colors.lightWhite },
            ]}
          >
            FAQ
          </Text>
          <PageBreak
            style={[
              Margin.mt_3,
              { width: helpers.isDesktop() ? "12%" : "50%" },
            ]}
            darkMode={darkMode}
          />
        </View>
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            Padding.ph_2,
          ]}
        >
          {SECTIONS.map((citem, index) => {
            return (
              <AccordionListItem
                title={citem.title}
                index={index}
                teamSubscription={true}
                darkMode={darkMode}
              >
                <Text>{citem.content}</Text>
                {index == 6 && (
                  <>
                    <Text
                      style={[{ textDecorationLine: "underline" }]}
                      onPress={() => {
                        let link = "support@themorningcontext.com";
                        let mailUrl =
                          "https://mail.google.com/mail/?view=cm&fs=1&to=";

                        Linking.canOpenURL(link)
                          .then((supported) =>
                            supported
                              ? Linking.openURL(`mailto:${link}`)
                              : window.open(`${mailUrl}${link}`, "_blank")
                          )
                          .catch((err) =>
                            window.open(`${mailUrl}${link}`, "_blank")
                          );
                      }}
                    >
                      support@themorningcontext.com
                    </Text>
                    <Text> if there are any issues.</Text>
                  </>
                )}
                {index == 8 && (
                  <>
                    {helpers.isWeb() ? (
                      <View
                        style={{
                          width: helpers.isWeb() ? null : 130,
                          height: 15,
                          flexDirection: "row",
                        }}
                      >
                        <TouchableOpacityLinkExternal {...btnClickEmail}>
                          <Text
                            style={[
                              {
                                textDecorationLine: "underline",
                                color: darkMode ? Colors.lightWhite : "#000",
                              },
                            ]}
                          >
                            priya@mailtmc.com
                          </Text>
                        </TouchableOpacityLinkExternal>
                      </View>
                    ) : (
                      <>
                        <Text
                          style={[
                            {
                              textDecorationLine: "underline",
                              color: darkMode ? Colors.lightWhite : "#000",
                            },
                          ]}
                          {...btnClickEmail}
                        >
                          priya@mailtmc.com{" "}
                        </Text>
                      </>
                    )}
                    <Text>
                      {" "}
                      to get institutional access for all students and faculty
                      members.
                    </Text>
                  </>
                )}
              </AccordionListItem>
            );
          })}
        </View>
      </View>
    );
  };

  renderPageContent = () => {
    const {
      plansData,
      userSubscription,
      teamSubscription,
      activeSubscription,
      country,
      pricingPlanRefreshKey,
      staticContent,
      writerData,
      bannerTitle,
      plansTitle,
      token,
    } = this.state;
    console.log("plansData", plansData);
    let webView = helpers.isWeb();
    let sectionTitle = {
      title: "Get all the news from our categories",
    };

    let btnTitle;

    let btnClickTerms;
    const { navigation } = this.props;
    if (helpers.isWeb()) {
      btnClickTerms = {
        to: "/terms-and-conditions",
      };
    } else {
      btnClickTerms = {
        onPress: () => navigation.navigate("/terms-and-conditions"),
      };
    }

    let subInfoBox = {
      title: "Stay upto date with the latest stories",
      subtitle: "Subscribe to TMC now",
      btnText: "SUBSCRIBE",
    };
    if (activeSubscription) {
      subInfoBox.subtitle = "Subscribe to TMC now";
      subInfoBox.btnText = "SUBSCRIBE";
    }

    let btnClickEmail;
    if (helpers.isWeb()) {
      btnClickEmail = {
        href: `mailto:priya@mailtmc.com`,
      };
    } else {
      btnClickEmail = {
        onPress: () => Linking.openURL(`mailto:priya@mailtmc.com`),
      };
    }

    // navigateToTeamSubscription = () => {
    //     if (this.props.sidePanel) {
    //         this.props.navigatePage('/team-subscription')
    //     } else {
    //         this.props.navigateScreen('/team-subscription')
    //     }
    // }

    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    console.log("plansData11", plansData);
    return (
      <View>
        <View
          style={[
            banner.Wrapper,
            {
              marginTop: helpers.isDesktop() ? -5 : 0,
              backgroundColor: "#fffcf1",
            },
            darkMode && { backgroundColor: Colors.darkBlackColor },
          ]}
        >
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              {
                paddingTop: helpers.isDesktop() ? 70 : 40,
                paddingBottom: helpers.isDesktop() ? 70 : 0,
              },
            ]}
          >
            <View
              style={[
                helpers.isDesktop() && { flexDirection: "row" },
                Flex.alignCenter,
              ]}
            >
              <View
                style={[
                  { paddingHorizontal: 20 },
                  { width: helpers.isDesktop() ? "50%" : "100%" },
                  !helpers.isDesktop() && Flex.alignCenter,
                ]}
              >
                <View
                  style={[
                    !helpers.isDesktop() && {
                      flex: 1,
                      textAlign: "center",
                      alignItems: "center",
                    },
                    Padding.pb_2,
                  ]}
                >
                  <Text
                    accessibilityRole="header"
                    aria-level="1"
                    style={[
                      pageContent.sectionTitle,
                      helpers.isDesktop() && pageContent.sectionTitleDesktop,
                      helpers.isDesktop() &&
                        helpers.isWeb() && { whiteSpace: "nowrap" },
                      {
                        fontSize: helpers.isDesktop() ? 40 : 36,
                        lineHeight: helpers.isDesktop() ? 43 : 49,
                      },
                      darkMode && { color: Colors.lightWhite },
                      !helpers.isWeb() && { textAlign: "center" },
                    ]}
                  >
                    Student subscription
                  </Text>
                  <View
                    style={[
                      {
                        height: 2,
                        backgroundColor: "#000000",
                        width: helpers.isDesktop() ? 160 : 50,
                        marginTop: 15,
                      },
                      darkMode && { backgroundColor: Colors.lineColor },
                    ]}
                  ></View>
                </View>
                <Text
                  style={[
                    pageContent.text,
                    helpers.isDesktop() && pageContent.textDesktop,
                    { marginBottom: helpers.isDesktop() ? 0 : 24 },
                    !helpers.isDesktop() && Helpers.textCenter,
                    helpers.isDesktop() && { fontSize: 20, lineHeight: 36 },
                    !helpers.isDesktop() && { fontSize: 16, lineHeight: 28 },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Read the best long form stories on internet companies,
                  publicly listed entities and our celebrated public interest
                  journalism.
                </Text>
              </View>
            </View>
            <View
              style={[
                {
                  width: helpers.isDesktop() ? "50%" : "100%",
                  alignItems: helpers.isDesktop() ? "flex-end" : "center",
                },
                helpers.isDesktop() && {
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                },
              ]}
            >
              <CustomImage
                source={studentBanner}
                require={true}
                style={{ width: "100%", height: 170 }}
                webStyle={{
                  width: helpers.isDesktop() ? "auto" : "100%",
                  height: helpers.isDesktop() ? 250 : "auto",
                }}
                resizeMode="contain"
                altText={"Student Subscription"}
              />
            </View>
          </View>
        </View>
        <View
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            this.subscriptionLayout = layout.y;
          }}
          style={{
            backgroundColor: darkMode
              ? Colors.darkBlackColor
              : Colors.lightGray,
            marginBottom: helpers.isDesktop() ? 120 : 50,
          }}
        >
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              { marginBottom: 60 },
              helpers.isDesktop() && { paddingTop: 60 },
            ]}
          >
            <View style={[article.headerWrapper]}>
              <View
                style={[
                  Flex.alignCenter,
                  helpers.isDesktop() && Margin.mb_8,
                  !helpers.isDesktop() && Margin.mb_2,
                  !helpers.isDesktop() && Padding.pt_3,
                  {
                    width: helpers.isWeb() ? "fit-content" : "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                ]}
              >
                <View
                  style={[
                    Helpers.titleBgWrap,
                    { backgroundColor: "rgba(250 250 250,1)" },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textCenter,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      { fontSize: 18, marginBottom: 2 },
                      { fontFamily: FontFamily.abrilFatface },
                      {
                        fontSize: helpers.isDesktop() ? 34 : 20,
                        color: "#000000",
                      },
                      webView && { textTransform: "regular" },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    {plansTitle}
                  </Text>
                </View>
                <PageBreak
                  style={[!webView && { width: "60%" }]}
                  darkMode={darkMode}
                />
              </View>
            </View>

            <View
            style={[
              {
                paddingHorizontal: 20,
                display: "block",
                textAlign: "center",
                maxWidth: 915,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: helpers.isDesktop() ? -40 : 13,
                marginBottom: helpers.isDesktop() ? 40 : 40,
              },
             
              Flex.row,
              Flex.alignCenter,
              Flex.justfiyCenter,
              Flex.FlexWrap,
            ]}
          >
            <Text
              style={{
                fontFamily: FontFamily.regular,
                fontSize: helpers.isDesktop() ? 16 : 15,
                lineHeight: helpers.isDesktop() ? 22 : 20,
                color: darkMode ? Colors.lineColor : "rgba(106, 106, 106, 1)",
                textAlign: "center",
              
              }}
            >
            You will need to submit a valid ID which specifically identifies you as a student. The name on this ID and details of your subscription purchased need to match. Student IDs of minors who are below 18 years is not acceptable. <br></br>This discounted plan is designed for the benefit of genuine students. Please note, we do not offer refunds if your student ID verification fails.
            </Text>
            
            
          </View>
            <View
              style={[
                Margin.mb_5,
                Flex.justfiyCenter,
                { paddingHorizontal: 20 },
                helpers.isDesktop() && { flexDirection: "row" },
              ]}
            >
              {plansData.student &&
                plansData.student.map((item, index) => {
                  return (
                    <>
                      <SubscriptionPlanBox
                        darkMode={this.props.darkMode}
                        item={item}
                        btnText={btnTitle ? btnTitle : "Select Plan"}
                        index={index}
                        subscribeToPlan={() => this.subscribeToPlan(item, btnTitle ? btnTitle : "Select Plan")}
                        token={"ygh"}
                        navigateApp={(slug, params) =>
                          this.props.navigation.navigate(slug, params)
                        }
                        auth={this.props.auth}
                        numberOfDatas={plansData.length}
                        data={plansData}
                        setCheckoutLoaderState={(loaderState) =>
                          this.setPlanButtonLoaderState(loaderState)
                        }
                        getCheckoutLoaderState={this.getPlanButtonLoaderState}
                      />
                    </>
                  );
                })}
            </View>

            {plansData.student.length == 0 && (
              <View
                style={[
                  Padding.ph_2,
                  { maxWidth: 700, marginLeft: "auto", marginRight: "auto" },
                ]}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.justfiyCenter,
                    Flex.alignCenter,
                    Margin.mb_4,
                    Padding.pv_2,
                    Padding.ph_4,
                    { borderWidth: 1, borderColor: "#FF3D00" },
                  ]}
                >
                  <CustomImage
                    source={infoIcon}
                    require={true}
                    style={{
                      width: 20,
                      height: 20,
                      resizeMode: "contain",
                      marginTop: 3,
                    }}
                    webStyle={{
                      width: 20,
                      height: 20,
                      objectFit: "contain",
                      marginTop: 3,
                    }}
                  />
                  {teamSubscription?.is_team_member ||
                  userSubscription?.plan ? (
                    <Text style={[Padding.pl_2, { color: "#FF3D00" }]}>
                      You cannot purchase student plans as you are already
                      subscribed to a{" "}
                      {userSubscription?.plan
                        ? userSubscription.plan.tmc_group
                        : "team"}{" "}
                      plan.
                    </Text>
                  ) : (
                    <Text style={[Padding.pl_2, { color: "#FF3D00" }]}>
                      You cannot purchase student plans.
                    </Text>
                  )}
                </View>
              </View>
            )}

            <View style={{ marginTop: helpers.isDesktop() ? 50 : 25 }}>
              <View
                style={[
                  Flex.alignCenter,
                  !helpers.isDesktop() && {
                    marginTop: 15,
                    textAlign: "center",
                    paddingRight: 10,
                  },
                  { maxWidth: 650, marginLeft: "auto", marginRight: "auto" },
                ]}
              >
                <TouchableOpacityLinkExternal
                  {...btnClickEmail}
                  activeOpacity={0.8}
                  style={{ textAlign: "center" }}
                >
                  <Text
                    style={[
                      Padding.pl_2,
                      Padding.pr_2,
                      Margin.mb_3,
                      !helpers.isDesktop() && { marginTop: 20 },
                      {
                        textAlign: "center",
                        fontSize: helpers.isDesktop() ? 18 : 16,
                        lineHeight: 22,
                        letterSpacing: 0.3,
                        color: "rgba(0, 0, 0, 0.9)",
                        opacity: 0.85,
                        fontFamily: FontFamily.regular,
                        fontWeight: "400",
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    If you are a faculty member & would like a dedicated plan
                    for your campus write to us here -{" "}
                    <Text style={{ fontWeight: "700" }}>priya@mailtmc.com</Text>
                  </Text>
                </TouchableOpacityLinkExternal>
              </View>
            </View>
          </View>
        </View>

        <WhyTMCSection
          subscription={true}
          darkMode={darkMode}
          studentSubscription={true}
        />

        <View style={[{ marginBottom: helpers.isDesktop() ? 150 : 50 }]}>
          {staticContent &&
            staticContent.readers_quotes &&
            this.renderReadersQuotes()}
        </View>

        <View style={{ marginBottom: helpers.isDesktop() ? 190 : 50 }}>
          <SubscriptionInfoBox
            data={subInfoBox}
            handleClick={() => this.handleInfoBoxClick()}
            darkMode={darkMode}
          />
        </View>

        {this.faq()}
      </View>
    );
  };

  navigateScreen = (pushProp) => {
    if (helpers.isWeb()) {
      this.props.history.push(pushProp);
    } else {
      this.props.navigation.navigate("/team-subscription");
    }
  };

  renderReadersQuotes() {
    let { staticContent } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <HomeReadersQuote
        data={staticContent.readers_quotes}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        subscriptionQuotes={true}
        darkMode={darkMode}
      />
    );
  }

  handleInfoBoxClick = () => {
    this.pushSubscribeEventToDataLayer();
    let { activeSubscription } = this.state;
    if (activeSubscription) {
      if (helpers.isWeb()) {
        this.props.navigateToScreen("/student-subscription");
      } else {
        //TODO
      }
    } else {
      this.scrollToSection(this.subscriptionLayout);
      try {
        if (helpers.isWeb()) {
          Mixpanel.track("click_subscribe_btn");
        }
      } catch (error) {
        console.log("mix panel error =>", error);
      }
    }
  };

  scrollToSection = (position) => {
    let offsetPosition = position;
    if (helpers.isWeb()) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(offsetPosition);
    }
  };

  renderWebPage = () => {
    return <>{this.renderPageContent()}</>;
  };

  renderAppPage = () => {
    const {
      pageLoader,
      showErrorModal,
      enableUpgradeModal,
      enableCancelSubModal,
    } = this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/student-subscription",
      native: {
        redirect: "/student-subscription",
        verifyRedirect: "/student-subscription",
      },
    };
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

    return (
      <>
        <ScrollView
          style={{ flex: 1 }}
          ref={(ref) => (this.appscrollview = ref)}
        >
          {this.renderPageContent()}
          <AppFooter
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
            Breadcrumbs={Breadcrumbs}
          />
        </ScrollView>
      </>
    );
  };

  render() {
    const {
      pageLoader,
      showErrorModal,
      enableUpgradeModal,
      enableCancelSubModal,
    } = this.state;
    const webView = helpers.isWeb();
    let redirectSlugs = {
      web: "/student-subscription",
      native: {
        redirect: "/student-subscription",
        verifyRedirect: "/student-subscription",
      },
    };
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          webView && null,
          appStyles.appBg,
          Flex.fill,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={seoObj["student-subscription"]}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/student-subscription"
        />
        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          refreshPage={() => this.refreshPage()}
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          showFullHeader={true}
          setCheckoutLoaderState={(loaderState) => this.setPlanButtonLoaderState(loaderState)}
          getCheckoutLoaderState={this.getPlanButtonLoaderState}
        />
        {pageLoader
          ? this.showPageLoader()
          : webView
            ? this.renderWebPage()
            : this.renderAppPage()}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(TeamSubscription));

let Breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Student Subscription",
  },
];
