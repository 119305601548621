import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, button, appStyles, article, FontFamily, FontWeight } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ButtonGradient from '../../components/button-gradient';
import CustomImage from '../../components/custom-image';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import getImagePath from '../../utils/image-helper';
import Seo from "../../components/seo-meta";
import { setUserToken } from '../../../redux/User/user.actions';
import PageBreak from '../../components/page-break';
import { Mixpanel } from '../../utils/mixpanel';
import seoObj from "../../utils/seo-helper";

const cookies = new Cookies();
const emailVerifyIcon = getImagePath('icons/emailVerified.png');
const emailWarningIcon = getImagePath('icons/email-warning.png');

export class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        let token = null;
        let searchArr, type;
        let webView = helpers.isWeb();
        if (webView && process.browser && window.location.search.includes("token=")) {
            searchArr = window.location.search.replace("?", "").split("&");
        } else if (!webView && this.props.route.params.query) {
            searchArr = this.props.route.params.query.split("&");
        }

        if (searchArr) {
            let foundSearch = searchArr.find((searchParam) => {
                return searchParam.includes("token=");
            });
            if (foundSearch) {
                let search = foundSearch.replace("token=", "");
                token = (search != '') ? search : null;
            }
        }

        if (webView && process.browser) {
            if (this.props.params) {
                type = this.props.params.type;
            }
        } else if(!webView && this.props?.type) {
            type = this.props.type;
        }

        this.state = {
            token: token,
            enableBtnLoader: true,
            error: false,
            buttonCode: false,
            redirectUrl: '/',
            email: '',
            type: type ? type : false
        }
    }

    componentDidMount() {
        const { token, type } = this.state;
        let app_type = helpers.isWeb() ? 'web' : 'app';
        let endpoint = `/activate-account`;
        let body = {
            token: token,
            type: app_type,
            device_data: {}
        }
        if (type == 'email-change') {
            endpoint = `/verify-new-email`;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'account_verified') {
                    if (helpers.isWeb()) {
                        let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
                        cookies.set('token', res.data.token, { path: '/', expires: cookieDate });
                        try {
                            Mixpanel.identify(res.data.reference_id);
                            Mixpanel.people.set({
                                name: res.data.full_name,
                                "$email": res.data.account_email
                            })
                        }
                        catch (error) {
                            console.log("mix panel error =>", error);
                        }
                    }
                    else {
                        // let redirectUrl, postSlug;
                        // if (res.data.redirect_url) {
                        //     let arr = res.data.redirect_url.split("/");
                        //     redirectUrl = (arr[1] == 'story') ? 'post-single' : arr[1];
                        //     postSlug = arr[2];
                        // }
                        const userdata = {
                            data: res.data
                        };
                        this.props.setUserToken(userdata);
                        // this.props.navigation.navigate(((redirectUrl && redirectUrl != '') ? redirectUrl : '/'), postSlug ? { postSlug: postSlug } : {});
                    }
                    this.setState({ enableBtnLoader: false, buttonCode: res.code, redirectUrl: res.data.redirect_url ? res.data.redirect_url : '/', email: res.data.account_email });
                    this.checkTeamInvitation(res.data.redirect_url);
                }
                else if (type == 'email-change' && res.code == 'email_changed') {
                    this.setState({ enableBtnLoader: false, buttonCode: 'account_verified', redirectUrl: (res.data && res.data.redirect_url) ? res.data.redirect_url : '/' });
                }
                else {
                    this.setState({
                        enableBtnLoader: false,
                        error: res.message,
                        buttonCode: res.code,
                        redirectUrl: res.data.redirect_url ? res.data.redirect_url : '/',
                        email: res.data.account_email ? res.data.account_email : ''
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    enableBtnLoader: false,
                    error: '',
                });
                console.log('Error=>>', error);
            });
    }

    checkTeamInvitation = (redirectUrl) => {
        if(redirectUrl && (redirectUrl.includes('accept-team-invitation?token=') || redirectUrl.includes('accept-team-invitation?redirect-params=token='))) {
            this.props.navigateToScreen(redirectUrl);
        }
    }

    goToNextPage = () => {
        let { type } = this.state;
        if (helpers.isWeb()) {
            if (type && type == 'email-change') {
                this.props.navigateToScreen(this.state.redirectUrl);
            }
            else {
                this.props.navigateToScreen('/save-interests?redirect=' + this.state.redirectUrl, { pageRedirect: true });
            }
        } else {
            let convertedArr = helpers.convertWebToMobileRoute(this.state.redirectUrl);
            if (type && type == 'email-change') {
                this.props.navigation.replace(convertedArr.path, convertedArr.params);
            }
            else {
                this.props.navigation.replace('/save-interests', { redirect: this.state.redirectUrl, pageRedirect: true });
            }
        }
    }

    resendVerificationLink = () => {
        this.setState({
            enableBtnLoader: true,
            error: '',
        });
        const { email, redirectUrl } = this.state;
        let endpoint = `/resend-verification-link`;
        let body = {
            account_email: email,
            redirect_url: redirectUrl
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, '')
            .then((res) => {
                if (res.code == 'verification_token') {
                    if (helpers.isWeb()) {
                        this.props.navigateToScreen('/sign-up-success?email=' + email)
                    } else {
                        this.props.navigation.replace('/sign-up-success', { email: email });
                    }
                } else {
                    this.setState({
                        enableBtnLoader: false,
                        error: res.message,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    enableBtnLoader: false,
                    error: '',
                });
                console.log('Error=>>', error);
            });
    }

    navigateToScreen = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen(slug);
        } else {
            this.props.navigation.navigate(slug)
        }
    }

    render() {
        const { enableBtnLoader, error, buttonCode, email } = this.state;
        const webView = helpers.isWeb();
        const desktopView = webView && helpers.isDesktop();
        let redirectSlugs = {
            web: '/',
            native: { redirect: '/', verifyRedirect: '/' }
        }

        let data = {
            pageName : 'Verify Email'
        }

        return (
            <>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()}  showFullHeader={true} />
                <ScrollView contentContainerStyle={[appStyles.appContainer, helpers.isDesktop() && appStyles.appContainerDesktop, { backgroundColor: '#F6F6F6' }]}>
                    {enableBtnLoader ?
                        <View style={[Flex.row, { width: helpers.isDesktop() ? '1400px' : '100%', margin: desktopView ? 'auto' : 0, height: helpers.isDesktop() ? 530 : '100%', justfiyCenter: 'center', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center' }]}>
                            <View>
                                <ActivityIndicator size={75} color={'#907CFF'} />
                                <Text style={[Margin.mt_3, { fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 28.8 : 24, color: '#907CFF' }]}>Verifying link</Text>
                            </View>
                        </View>
                        :
                        <View style={{ maxWidth: helpers.isDesktop() ? 880 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                            <View style={[Helpers.bg_white, Margin.mb_4, { marginTop: helpers.isDesktop() ? 150 : 100 }, { paddingVertical: helpers.isDesktop() ? 70 : 35, paddingHorizontal: 20 }, { marginHorizontal: 15, marginBottom: helpers.isDesktop() ? 50 : 40 }]}>
                                <View style={[Margin.mt_0]}>
                                    {(buttonCode == 'account_verified') ?
                                        <View style={[Padding.ph_2, Margin.mt_0]}>
                                            <View style={[article.headerWrapper]}>
                                                <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_4]}>
                                                    <View style={[Padding.ph_2, Margin.mb_1, Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 18 }, webView && { textTransform: 'regular' }]}>Your email is verified</Text>
                                                    </View>
                                                    <PageBreak size={'small'} />
                                                </View>
                                            </View>
                                            <View style={[Margin.mb_3, Flex.alignCenter]}>
                                                <CustomImage
                                                    source={emailVerifyIcon}
                                                    require={true}
                                                    style={{ width: 112, height: 86 }}
                                                    webStyle={{ width: 112, height: 86 }}
                                                />
                                            </View>
                                            <View>
                                                <Text style={{ fontFamily: FontFamily.light, fontWeight: FontWeight.light, fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 28 : 26, letterSpacing: 0.3, textAlign: 'center' }}>The address {email} is now a verified account.</Text>
                                            </View>
                                        </View>
                                        :
                                        <View style={[Padding.ph_2, Margin.mt_0]}>
                                            <View style={[article.headerWrapper]}>
                                                <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_4]}>
                                                    <View style={[Padding.ph_2, Margin.mb_1, Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 30 : 18 }, webView && { textTransform: 'regular' }]}>Verification Expired!</Text>
                                                    </View>
                                                    <PageBreak size={'small'} />
                                                </View>
                                            </View>
                                            <View style={{ maxWidth: helpers.isDesktop() ? 450 : '100%', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                                                {(buttonCode == 'link_expired') ?
                                                    <>
                                                        <Text style={{ fontFamily: FontFamily.light, fontWeight: FontWeight.light, fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 28 : 26, letterSpacing: 0.3, textAlign: 'center' }}>Your verification code seems to have expired. Click resend to get new verification email.</Text>
                                                    </>
                                                    :
                                                    <Text style={{ fontFamily: FontFamily.light, fontWeight: FontWeight.light, fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 28 : 26, letterSpacing: 0.3, textAlign: 'center' }}>Your verification code is already used or is invalid. Please signup again.</Text>
                                                }
                                            </View>
                                        </View>
                                    }
                                </View>

                                <View style={[Padding.ph_2, Padding.pt_4]}>
                                    {(buttonCode == 'account_verified') ?
                                        <ButtonGradient
                                            title="CONTINUE"
                                            rootStyle={{
                                                btnWrapper: button.primaryGradient,
                                                btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular, fontSize: 16 }],
                                            }}
                                            loader={false}
                                            disable={false}
                                            onClick={() => this.goToNextPage()}
                                        />
                                        :
                                        <>
                                            {(buttonCode == 'link_expired') ?
                                                <ButtonGradient
                                                    title="RESEND VERIFICATION EMAIL"
                                                    rootStyle={{
                                                        btnWrapper: button.primaryGradient,
                                                        btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular, fontSize: 16 }],
                                                    }}
                                                    loader={false}
                                                    disable={false}
                                                    onClick={() => this.resendVerificationLink()}
                                                />
                                                :
                                                <ButtonGradient
                                                    title="GO TO SIGN IN"
                                                    rootStyle={{
                                                        btnWrapper: button.primaryGradient,
                                                        btnText: [button.primaryGradientText, { fontWeight: '400', fontFamily: FontFamily.regular, fontSize: 16 }],
                                                    }}
                                                    loader={false}
                                                    disable={false}
                                                    onClick={() => this.navigateToScreen('/sign-in')}
                                                />
                                            }
                                        </>
                                    }
                                </View>
                            </View>
                            <View style={[Flex.row, Flex.justfiyCenter, { marginBottom: helpers.isDesktop() ? 100 : 30 }]}>
                                <Text style={{ fontSize: 14, fontFamily: FontFamily.regular, fontWeight: FontWeight.regular, lineHeight: 18, borderBottomWidth: 1, borderBottomColor: '#000000' }} onPress={() => this.navigateToScreen('/')}>Skip and go to the The Morning Context homepage</Text>
                            </View>
                        </View>
                    }
<AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} />
                </ScrollView>

                
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ setUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);