import apiHelpers from '../api-helpers'
import { Dictionary } from './global-types'

export type RequestMethod = 'GET' | 'POST'

/**
 * A wrapper to fetch HTTP requests.
 * @param endpoint - HTTP endpoint
 * @param body - The body of the HTTP request
 * @param method - THe method of the request
 * @param form_url - Refer {@link apiHelpers.apiRequest}
 * @param authToken - Refer {@link apiHelpers.apiRequest}
 * @returns 
 */
export function HTTP_Fetch(endpoint: string, body: Dictionary, method: RequestMethod,form_url: boolean, authToken: any){

    return apiHelpers.apiRequest(endpoint, body, method, form_url, authToken)
}