import { StyleSheet, Text, View, TouchableOpacity } from "react-native"
import Svg, { Rect, Defs, LinearGradient, Stop } from 'react-native-svg';
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";
import { useState } from "react";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import BottomModal from "../bottom-modal";

const copyLinkIcon = getImagePath("icons/copy-white-icon.svg")
const emailIcon = getImagePath("icons/email-white-icon.svg")
const whatsappIcon = getImagePath("icons/whatsapp-white-icon.svg")
const upwardArrow = getImagePath("icons/upward-arrow-darkblue.svg")
const starterCreditCoin = getImagePath("icons/starter-credit-coin-icon.svg")

const OutlineContainer = ({ text }) => (
    <View style={{ position: 'relative', borderRadius: 4, overflow: 'hidden', width: '100%' }}>
        <Svg height="100%" width="100%" style={styles.svgContainer}>
            <Defs>
                <LinearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
                    <Stop offset="0%" stopColor="#55C79A" stopOpacity="1" />
                    <Stop offset="50%" stopColor="#907CFF" stopOpacity="1" />
                    <Stop offset="100%" stopColor="#FC876D" stopOpacity="1" />
                </LinearGradient>
            </Defs>
            <Rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="transparent" // Transparent fill for the background
                stroke="url(#grad)" // Apply gradient to the outline
                strokeWidth="2" // Customize the thickness of the border
                rx="4" // Add rounded corners (same as borderRadius)
                ry="4"
            />
        </Svg>
        <View style={styles.outlineContainer}>
            <View style={styles.contentWrapper}>
                <Text style={styles.giftSubContainer}>{text}</Text>
            </View>
        </View>
    </View>
);

const GiftBox = (props) => {
    const { token, openGiftSubModal, giftCredits = 0 } = props
    let isPaidUser = false
    if (props?.profileInfo?.subscription && JSON.stringify(props.profileInfo?.subscription) != '{}') {
        isPaidUser = true
    }
    if ((props.profileInfo?.subscription?.plan?.id == 'student-plan-inr' || props.profileInfo?.subscription?.plan?.id == 'student-plan-usd') && ['submitted', 'rejected'].includes(props.profileInfo?.document_status)) {
        isPaidUser = false
    }

    const [copyButtonText, setCopyButtonText] = useState("Copy Link")
    const [openGiftModal, setOpenGiftModal] = useState(false)

    const onCopyButtonClick = async () => {
        if (copyButtonText === "Copied") {
            return;
        }
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data?.story?.id || props.data?.id,
            slug: props.data?.story?.slug || props.data?.slug
        }

        if (typeof ClipboardItem !== "undefined" && navigator.clipboard.write) {
            const dataPromise = apiHelpers.apiRequest(endpoint, data, "POST", true, token);
            const textPromise = dataPromise.then(res => `${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`);

            Promise.all([dataPromise, textPromise])
                .then(([res, text]) => {
                    const blob = new Blob([text], { type: "text/plain" });
                    const clipboardItem = new ClipboardItem({ "text/plain": blob });
                    return navigator.clipboard.write([clipboardItem]);
                })
                .then(() => {
                    setCopyButtonText("Copied ✓")
                    setTimeout(() => {
                        setCopyButtonText("Copy Link")
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error occurred:", error);
                });
        } else {
            apiHelpers.apiRequest(endpoint, data, "POST", true, token)
                .then(res => `${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n ${document.location.href + '?giftCode=' + res.data.gift_code}`)
                .then((text) => {
                    navigator.clipboard.writeText(text);
                    setCopyButtonText("Copied ✓")
                    setTimeout(() => {
                        setCopyButtonText("Copy Link")
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error occurred:", error);
                });
        }
    }

    const handleWhatsappShare = () => {
        console.log("Clicked on gifting via Whatsapp option")
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data?.story?.id || props.data?.id,
            slug: props.data?.story?.slug || props.data?.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
            console.log(res)
            if (res.success) {
                const expression = /(iPhone|iPod|iPad)/i;

                if (expression.test(navigator.platform)) {
                    // console.log('Apple device detected!');
                    window.location.href = encodeURI(`https://wa.me/?text=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode=' + res.data.gift_code}`)
                } else {
                    // console.log('Other device detected!');
                    window.open(encodeURI(`https://wa.me/?text=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.\n\n${document.location.href + '?giftCode=' + res.data.gift_code}`))
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    const handleEmailShare = () => {
        console.log("Clicked on gifting via Email option")
        let { token } = props;
        let endpoint = `/generate-gift-link`;
        let data = {};
        data.story = {
            id: props.data.story.id,
            slug: props.data.story.slug
        }
        apiHelpers.apiRequest(endpoint, data, "POST", true, token).then((res) => {
            console.log(res)
            if (res.success) {
                window.location.href = `mailto:?subject=Gift from ${props.profileInfo.name || props.profileInfo.email}&body=${props.profileInfo.name || props.profileInfo.email} gifted you a subscriber-only story published by The Morning Context. You will be able to access this premium story for free just by signing up for The Morning Context.
                ${document.location.href + '?giftCode=' + res.data.gift_code}`
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGiftButtonClickMob = () => {
        if (openGiftModal) {
            setOpenGiftModal(false)
        } else {
            setOpenGiftModal(true)
        }
    }

    const handleLogin = () => {
        window.location.href = '/sign-in'
    }

    const handleSubscribe = () => {
        window.location.href = '/pricing'
    }

    if (!helpers.isDesktop()) {
        return <BottomModal
            token={token}
            profileInfo={props.profileInfo}
            openGiftSubModal={openGiftSubModal}
            data={props.data}
            giftCredits={props.profileInfo?.free_credits_available}
        />
    }

    return <View style={styles.wrapper}>
        <View style={{ display: 'flex', width: '90%', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center' }}>
            <Text style={styles.titleText}>
                Gift This Story
            </Text>
            {token ? <View style={{ display: 'flex', flexDirection: 'row', columnGap: '4px' }}>
                <Text style={styles.titleText}>
                    {giftCredits}/3
                </Text>
                <CustomImage
                    source={starterCreditCoin}
                    require={true}
                    style={{ width: 16, height: 16 }}
                    webStyle={{
                        width: 16,
                        height: 16,
                    }}
                    altText={"Gift Credit"}
                />
            </View> : null}
        </View>
        <Text style={styles.bodyText}>
            {token && isPaidUser && giftCredits ? "Give this subscriber-only story to a friend and help them read it for free." : token && giftCredits === 0 ? "You have exhausted your gifting credits for this month. You'll receive 3 new credits next month." : "Morning Context subscribers can gift stories that anyone can read."}
        </Text>
        <View style={styles.actionButtonsContainer}>
            {
                token && isPaidUser && giftCredits ? <>
                    <TouchableOpacity style={styles.copyLinkContainer} onPress={onCopyButtonClick}>
                        <CustomImage
                            source={copyLinkIcon}
                            require={true}
                            style={{ width: 16, height: 16 }}
                            webStyle={{
                                width: 16,
                                height: 16,
                            }}
                            altText={"Copy Link"}
                        />
                        <Text style={styles.copyLinkText}>
                            {copyButtonText}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.emailContainer} onPress={handleEmailShare}>
                        <CustomImage
                            source={emailIcon}
                            require={true}
                            style={{ width: 16, height: 16 }}
                            webStyle={{
                                width: 16,
                                height: 16,
                            }}
                            altText={"Email"}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.whatsappContainer} onPress={handleWhatsappShare}>
                        <CustomImage
                            source={whatsappIcon}
                            require={true}
                            style={{ width: 16, height: 16 }}
                            webStyle={{
                                width: 16,
                                height: 16,
                            }}
                            altText={"WhatsApp"}
                        />
                    </TouchableOpacity>
                </> :
                    <View style={styles.actionButtonInnerContainer}>
                        {token ? null : <TouchableOpacity onPress={handleLogin} style={styles.loginContainer}>
                            Log in
                        </TouchableOpacity>}
                        {token ? null : <TouchableOpacity onPress={handleSubscribe} style={[styles.subscribeContainer]}>
                            Subscribe
                        </TouchableOpacity>}
                        {
                            token && !isPaidUser ? <TouchableOpacity style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onPress={handleSubscribe}> <OutlineContainer text={"Subscribe"} />  </TouchableOpacity> : null
                        }
                    </View>
            }

        </View>
        {token && isPaidUser ? <TouchableOpacity onPress={openGiftSubModal} style={styles.giftSubWrapper}>
            <OutlineContainer text="Gift a 30-day subscription" />
        </TouchableOpacity> : null}
    </View>
}

const styles = StyleSheet.create({
    wrapper: {
        paddingHorizontal: 8,
        paddingVertical: 10,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 8,
        borderRadius: 8,
        backgroundColor: '#1E1B4B',
        shadowColor: 'rgba(14, 63, 126, 0.04)',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 2, // for Android shadow,
        width: helpers.isDesktop() ? '171px' : '30vw',
        padding: helpers.isDesktop() ? '12px' : null,
        position: 'absolute',
        zIndex: 1,
        top: '4vh',
        right: '-5vw'
    },
    titleText: {
        color: '#F3F4F6',
        fontFamily: 'HaasGrotesk-Medium',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 16,
        width: '90%',
        alignSelf: 'center'
    },
    bodyText: {
        color: '#F3F4F6',
        fontFamily: 'HaasGrotesk-Roman',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 16,
        width: '90%',
        alignSelf: 'center'
    },
    actionButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '90%',
        alignSelf: 'center',
    },
    copyLinkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
        paddingRight: '0.5vw'
    },
    emailContainer: {
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(255, 255, 255, 0.30)',
        paddingHorizontal: '0.5vw'
    },
    whatsappContainer: {
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(255, 255, 255, 0.30)',
        paddingLeft: '0.5vw'
    },
    borderBoxSub: {
        width: '100%'
    },
    giftSubContainer: {
        color: '#FFF9F1',
        textAlign: 'center',
        fontFamily: 'HaasGrotesk-Roman',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 16,
        width: '100%',
        alignSelf: 'center'
    },
    outlineContainer: {
        paddingVertical: 2,
        paddingHorizontal: 4,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        borderRadius: 4
    },
    svgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 4,
    },
    contentWrapper: {
        position: 'relative',
        zIndex: 1, // Ensures the text is above the gradient
    },
    giftSubWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: '1vh',
    },
    loginContainer: {
        color: '#FFF',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 16
    },
    subscribeContainer: {
        color: '#FFF',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 16,
        borderLeftWidth: 1,
        borderLeftColor: 'rgba(255, 255, 255, 0.30)',
        paddingLeft: '1vw'
    },
    actionButtonInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        columnGap: '1vw'
    },
    copyLinkText: {
        color: '#FFF',
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 16
    }
});


export default GiftBox