import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, Animated, TouchableOpacity, TextInput, Modal } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, modalStyle, button, FontFamily, input, Colors, teamSubscription } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import apiHelpers from "../../utils/api-helpers";

const closeIcon = getImagePath('icons/close-round.png');
const emailVerifyIcon = getImagePath('icons/email-verify.png');
const warningIcon = getImagePath('icons/unsuccessful.png');
const goldCoin = getImagePath('icons/coin.png');
const checkBoxSelected = getImagePath('icons/checkedIconSq.png');
const checkBoxUnSelected = getImagePath('icons/unCheckedIconSq.png');
const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export class AddCredits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: new Animated.Value((windowHeight * 2)),
            modalOpacity: new Animated.Value(0),
            credits: this.props.creditSet ? this.props.credits : '',
            creditSet: this.props.creditSet,
            creditsError: false,
            sendEmail: false
        }
    }

    componentDidMount = () => {
        this.enableDisableModal()
    }

    componentWillUnmount = () => {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'initial';
        }
    }

    enableDisableModal = () => {
        Animated.timing(this.state.showModal, {
            toValue: 0,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
        Animated.timing(this.state.modalOpacity, {
            toValue: 1,
            duration: 500,
            delay: 0,
            useNativeDriver: true
        }).start();
    }

    closeModal = () => {
        if (helpers.isWeb()) {
            Animated.timing(this.state.showModal, {
                toValue: (windowHeight * 2),
                duration: 300,
                delay: 0,
                useNativeDriver: true
            }).start();
            Animated.timing(this.state.modalOpacity, {
                toValue: 0,
                duration: 300,
                delay: 0,
                useNativeDriver: true
            }).start();

            if (helpers.isWeb()) {
                document.body.style.overflow = 'initial';
            }
            const { closeModal } = this.props;
            setTimeout(function () {
                closeModal();
            }, 300);
        }
        else {
            this.props.closeModal();
        }
    }

    onChange = (value, field_key) => {
        if (field_key == 'email') {
            this.setState({ [field_key]: value, emailError: false, disableBtn: false });
            return;
        }
        this.setState({ [field_key]: value });
    }

    memberCredits = () => {
        let { credits, sendEmail } = this.state;
        let { checkObj, creditTransactionType } = this.props;
        this.setState({ disableBtn: true, showLoader: true });
        let accEmails = []
        Object.entries(checkObj).map((entry) => {
            if (entry[1]) {
                accEmails.push(entry[0]);
            }
        })

        let endpoint = `/team-member-credits`;
        let body = {
            account_email: accEmails,
            credits: parseInt(credits),
            transaction_type: creditTransactionType,
            send_email: sendEmail
        }
        // return false;
        let { token } = this.props;
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.code == 'access_denied') {
                    return;
                }

                if (res.success && res.code == 'credits_updated') {
                    this.setState({ showLoader: false });
                    this.closeModal();
                    this.props.refreshPage();
                }
                else {
                    this.setState({ showLoader: false, creditsError: (res?.error?.message || res?.error?.member_credits) });
                }
            })
            .catch((error) => {
                this.setState({ showLoader: false, creditsError: res?.error?.message });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    setCredits = (value, membersSelected, maxDebit) => {
        let key = value.split('');
        let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        for (var i = key.length - 1; i >= 0; i--) {
            if (keyList.indexOf(key[i]) == -1) {
                return false;
            }
        }
        let { credits } = this.state;
        let { maxCredits, creditTransactionType } = this.props;
        if ((parseInt(value) > 0 && creditTransactionType == 'credit' && credits.split('').length <= key.length) && ((parseInt(maxCredits) / membersSelected) < parseInt(value))) {
            return false;
        } else if (parseInt(value) > 0 && creditTransactionType == 'debit' && parseInt(value) > maxDebit) {
            return false;
        }
        this.setState({ credits: value });
    }

    renderPageContent = () => {
        const { showModal, modalOpacity, disableBtn, showLoader, checkBoxChecked, creditSet, credits, sendEmail, creditsError } = this.state;
        const { topPadding, checkObj, maxCredits, creditTransactionType, teamMembers, darkMode } = this.props;
        let maxDebit, membersSelected = 0, teamObj = {}
        teamMembers.map((member) => {
            teamObj[member.member_email] = member.credits_available;
        });
        Object.entries(checkObj).map((checked) => {
            if (checked[1]) {
                membersSelected++;
                if (maxDebit == undefined || maxDebit > teamObj[checked[0]]) {
                    maxDebit = teamObj[checked[0]];
                }
            }
        })
        if (maxDebit == undefined) {
            maxDebit = 0;
        }

        return (
            <View style={[modalStyle.modalContainer, { paddingHorizontal: 0 }, !topPadding && { paddingTop: 0 }, { paddingBottom: 30 }, !helpers.isDesktop() && { flex: 0.7, padding: 16, width: '100%', backgroundColor: 'transparent' }, darkMode && { backgroundColor: helpers.isDesktop() ? Colors.darkBlackColor1 : 'transparent' }, !helpers.isWeb() && { width: windowWidth, flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.50)', justifyContent: 'center', alignItems: 'center' }]}>
                {creditSet ?
                    <View style={[!helpers.isDesktop() && { backgroundColor: darkMode ? Colors.darkBlackColor : 'white', paddingBottom: 30, margin: 20 }, { paddingHorizontal: 20 }, !helpers.isWeb() && { width: windowWidth - 40 }]}>
                        <Text style={[Padding.pt_2, Padding.pb_1, teamSubscription.title, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28, fontWeight: '600' }, !helpers.isWeb() && { fontFamily: FontFamily.bold }, darkMode && { color: Colors.lightWhite }]}>Confirm {(creditTransactionType == 'credit') ? 'addition' : 'removal'} of credits</Text>
                        <Text style={[Padding.pb_3, { fontSize: 16, lineHeight: 20, fontFamily: FontFamily.regular, fontWeight: '400', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }, { color: '#000' }, darkMode && { color: Colors.lightWhite }]}>Are you sure you want to {(creditTransactionType == 'credit') ? 'add' : 'remove'} <Text style={{ color: (creditTransactionType == 'credit') ? '#55C79A' : '#FC876D', fontWeight: '600' }}>{credits} credits</Text> each {(creditTransactionType == 'credit') ? 'to' : 'from'} {(membersSelected == 1) ? 'the selected team member' : ('the ' + membersSelected + ' selected team members')}?</Text>
                        <Text style={[(creditsError == false) && Padding.pb_3, { fontSize: 16, lineHeight: 20, fontFamily: FontFamily.regular, fontWeight: '400', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }, { color: '#000' }, darkMode && { color: Colors.lightWhite }]}>*Your team’s available credits will be: <Text style={[{ color: '#000', fontWeight: '600' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }, darkMode && { color: Colors.lightWhite }]}> {((creditTransactionType == 'credit') ? (parseInt(maxCredits) - (membersSelected * credits)) : (parseInt(maxCredits) + (membersSelected * credits)))} credits</Text></Text>
                        {creditsError &&
                            <Text style={[Padding.pb_3, { color: '#F02121', fontSize: 12, lineHeight: 14, fontFamily: FontFamily.regular, marginTop: 8 }]}>{creditsError}</Text>
                        }
                        {(creditTransactionType == 'credit') && <View style={[Margin.mb_2, Flex.row, !helpers.isDesktop() && Margin.mt_2]}>
                            <TouchableOpacity onPress={() => this.setState({ sendEmail: !sendEmail })}
                                style={[Padding.pr_1]}>
                                <CustomImage
                                    source={(sendEmail) ? checkBoxSelected : checkBoxUnSelected}
                                    require={true}
                                    style={{ width: 16, height: 16, marginTop: 3 }}
                                    webStyle={{ width: 16, height: 16 }}
                                />
                            </TouchableOpacity>
                            <Text style={[{ color: 'rgba(0, 0, 0, 0.7)', fontSize: 14, lineHeight: 17, fontFamily: FontFamily.regular }, !helpers.isWeb() && { paddingRight: 15 }, darkMode && { color: Colors.lineColor }]}>Notify members about updated credits via email</Text>
                        </View>}

                        <View style={[Flex.row, Flex.justifyEnd, Margin.mt_4, !helpers.isWeb() && { alignItems: 'center' }]}>
                            <Button
                                title={'CANCEL'}
                                rootStyle={{
                                    btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 100, backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }],
                                    btnText: [button.whiteButtonText, { color: '#A97DF1', lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }],
                                }}
                                onClick={() => this.closeModal()}
                            />
                            <View style={{ width: helpers.isDesktop() ? 130 : 100, marginLeft: 30 }}>
                                <ButtonGradient
                                    title={'Apply'}
                                    fullWidth={true}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, width: helpers.isWeb() ? 'fit-content' : 300 }, helpers.isWeb() && { flex: 1 }],
                                        btnText: [button.primaryGradientText, { lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 13.5 : 11, paddingBottom: helpers.isDesktop() ? 13.5 : 11 }]
                                    }}
                                    onClick={() => this.memberCredits()}
                                    disable={disableBtn}
                                    loader={showLoader}
                                    darkMode={darkMode}
                                />
                            </View>
                        </View>
                    </View>
                    :
                    <View style={[!helpers.isDesktop() && { backgroundColor: darkMode ? Colors.darkBlackColor1 : 'white', paddingBottom: 30, margin: 20 }, { paddingHorizontal: 20 }, !helpers.isWeb() && { width: windowWidth - 40 }]}>
                        <Text style={[Padding.pt_2, Padding.pb_1, teamSubscription.title, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 28, fontWeight: '600', color: '#000' }, !helpers.isWeb() && { fontFamily: FontFamily.bold }, darkMode && { color: Colors.lightWhite }]}>{(creditTransactionType == 'credit') ? 'Add' : 'Remove'} Credits</Text>
                        <Text style={[Padding.pb_3, { fontSize: 14, lineHeight: 16, fontFamily: FontFamily.regular, fontWeight: '400', color: 'rgba(0, 0, 0, 0.7)', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }, darkMode && { color: Colors.lineColor }]}>Enter no. of credits you want to apply to {(membersSelected == 1) ? 'the selected team member' : ('each of the ' + membersSelected + ' selected team members')}</Text>
                        <Text style={{ fontSize: helpers.isDesktop() ? 13 : 11, lineHeight: helpers.isDesktop() ? 16 : 13, fontFamily: FontFamily.regular, fontWeight: '400', color: '#979797', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }}>Enter credits</Text>
                        <TextInput
                            placeholder={'Enter credits'}
                            placeholderTextColor={Colors.placeholderTextColor1}
                            style={[input.Text, input.borderBottom, { fontSize: helpers.isDesktop() ? 22 : 18, paddingVertical: 8, borderColor: 'rgba(153, 153, 153, 0.5)', boderWidth: 0.3, color: darkMode ? Colors.lightWhite : '#000' }]}
                            value={credits}
                            onChangeText={(value) => this.setCredits(value, membersSelected, maxDebit)}
                            keyboardType={'numeric'}
                        />

                        <View style={[Flex.row, Flex.justifyEnd, Margin.mt_4, !helpers.isWeb() && { alignItems: 'center' }]}>
                            <Button
                                title={'CANCEL'}
                                rootStyle={{
                                    btnWrapper: [button.whiteButton, { borderColor: darkMode ? 'transparent' : '#ffffff', width: helpers.isWeb() ? 'fit-content' : 100, backgroundColor: darkMode ? 'transparent' : '#ffffff', marginTop: 0 }],
                                    btnText: [button.whiteButtonText, { color: '#A97DF1', lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005 }],
                                }}
                                onClick={() => this.closeModal()}
                                darkMode={darkMode}
                            />
                            <View style={{ width: helpers.isDesktop() ? 130 : 100, marginLeft: 30 }}>
                                <ButtonGradient
                                    title={'Next'}
                                    fullWidth={true}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradientBtn, { height: 46, borderRadius: 0, width: helpers.isWeb() ? 'fit-content' : 300 }, helpers.isWeb() && { flex: 1 }],
                                        btnText: [button.primaryGradientText, { lineHeight: helpers.isDesktop() ? 20 : 16, paddingRight: 0, fontSize: helpers.isDesktop() ? 17 : 13, fontFamily: FontFamily.regular, fontWeight: '600', letterSpacing: helpers.isWeb() ? '0.005em' : 0.005, paddingLeft: 25, paddingRight: 25, paddingTop: helpers.isDesktop() ? 13.5 : 11, paddingBottom: helpers.isDesktop() ? 13.5 : 11 }, !helpers.isWeb() && { paddingLeft: 15, paddingRight: 15 }]
                                    }}
                                    onClick={() => this.setState({ creditSet: true })}
                                    disable={(parseInt(credits) > 0 && !(creditTransactionType == 'credit' && ((parseInt(maxCredits) / membersSelected) < parseInt(credits))) && !(creditTransactionType == 'debit' && parseInt(credits) > maxDebit)) ? false : true}
                                    darkMode={darkMode}
                                />
                            </View>
                        </View>
                    </View>
                }
            </View>
        )
    }

    render() {
        if (helpers.isWeb()) {
            document.body.style.overflow = 'hidden';
        }
        const { showModal, modalOpacity, disableBtn, showLoader, checkBoxChecked, creditSet, credits, sendEmail, creditsError } = this.state;
        const { topPadding, checkObj, maxCredits, creditTransactionType, teamMembers, darkMode, showAddCredits } = this.props;
        let maxDebit, membersSelected = 0, teamObj = {}
        teamMembers.map((member) => {
            teamObj[member.member_email] = member.credits_available;
        });
        Object.entries(checkObj).map((checked) => {
            if (checked[1]) {
                membersSelected++;
                if (maxDebit == undefined || maxDebit > teamObj[checked[0]]) {
                    maxDebit = teamObj[checked[0]];
                }
            }
        })
        if (maxDebit == undefined) {
            maxDebit = 0;
        }
        return (
            <>
                {helpers.isWeb() ?
                    <>
                        <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { opacity: modalOpacity }]}>
                        </Animated.View>
                        <Animated.View style={[modalStyle.backdropContainer, helpers.isDesktop() && modalStyle.backdropContainerDesktop, { transform: [{ translateY: showModal }], backgroundColor: 'transparent' }]}>
                            {this.renderPageContent()}
                        </Animated.View>
                    </>
                    :
                    <>
                        <View style={[{ flex: 1, justifyContent: 'center', alignItems: 'center', width: windowWidth }]}>
                            <Modal animationType='slide' transparent={true} visible={showAddCredits}>
                                {this.renderPageContent()}
                            </Modal>
                        </View>
                    </>
                }
            </>
        );
    }
}

export default AddCredits;