import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import ScrollContainerView from "../scroll-container-view";
import { CardView } from "react-native-simple-card-view";
import { relativeTimeRounding } from "moment";
import Swiper from "react-native-web-swiper";
import {
  renderBorderColor,
  categoryImage,
  paddingCategory,
} from "../../screens/yesterday/renderBorderColor";
import TwoGridStory from "../../components/two-grid-story";
import ThreeGridStory from "../../components/three-grid-story";

export class LatestStories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeSecondAuthor: [],
    };
  }
  componentDidMount = () => {
    let { data } = this.props;
    let isAuthorEnable = [];
    for (let i = 0; i < data.length; i++) {
      isAuthorEnable[i] = false;
    }
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };

  setSecondAuth = (index) => {
    let isAuthorEnable = [...this.state.seeSecondAuthor];
    isAuthorEnable[index] = true;
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };
  goToLongreads = () => {
    this.props.navigateToScreen("/longreads");
  };
  goToAllStories = () => {
    this.props.navigateToScreen("/all-stories");
  };

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "latest stories",
      newsletter_section: "NA",
      CTA_text: "read all stories",
      CTA_position: "middle",
      category_type: "longreads",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    let webView = helpers.isWeb();
    let { darkMode, data, viewArticle, viewAuthor, viewCategory, viewCompany } =
      this.props;
    let { seeSecondAuthor } = this.state;
    let sliceData = [{ from: 2, to: 5 }];
    return (
      <>
        {/* <View
          style={{
            maxWidth: "calc(1216px - 30px)",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 40,
            paddingHorizontal: helpers.isDesktop() ? "15px" : "20px",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: helpers.isDesktop() ? 18 : 14,
              lineHeight: helpers.isDesktop() ? "27px" : "21px",
              letterSpacing: "0.3px",
              color: "#292929",
              fontFamily: FontFamily.regular,
              fontWeight: 400,
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </Text>
        </View>*/}
        <View
          style={{
            maxWidth: "1216px",
            width: "100%",
            marginHorizontal: "auto",
          }}
        >
          <TwoGridStory
            darkMode={darkMode}
            title={"Latest Stories"}
            latestNewsData={data.slice(0, 2)}
            viewArticle={(catSlug, slug, postData) =>
              viewArticle(catSlug, slug, postData)
            }
            viewAuthor={(slug) => viewAuthor(slug)}
            viewCategory={(slug) => viewCategory(slug)}
            viewCompany={(slug) => viewCompany(slug)}
            custom_user_id={this.props.custom_user_id}
          />
          <ThreeGridStory
            darkMode={darkMode}
            latestNewsData={data}
            viewArticle={(catSlug, slug, postData) =>
              viewArticle(catSlug, slug, postData)
            }
            title={"Latest Stories"}
            viewAuthor={(slug) => viewAuthor(slug)}
            viewCategory={(slug) => viewCategory(slug)}
            viewCompany={(slug) => viewCompany(slug)}
            numberOfRow={1}
            sliceObj={sliceData}
            custom_user_id={this.props.custom_user_id}
          />

        </View>
        {!this.props.moreStoriesSection && <View
          style={
            ([helpers.isDesktop() && Flex.row, Margin.mh_5],
              { alignSelf: "center", marginTop: 28, marginBottom: 32, })
          }
        >
          <TouchableOpacityLinkExternal >
            <ButtonGradient
              rounded={true}
              title={"Read All Stories"}
              rootStyle={{
                btnWrapper: [button.primaryGradient, { height: 50 }],
                btnText: [
                  button.primaryGradientText,
                  {
                    fontSize: 16,
                    paddingHorizontal: 45,
                    fontWeight: "600",
                    fontFamily: FontFamily.regular,
                  },
                ],
              }}
              paddingOverride={true}
              onClick={() => {
                this.pushInformationToDataLayer()
                this.goToAllStories();
              }
              }
              darkMode={darkMode}
              isNewGradiant={true}
            />
          </TouchableOpacityLinkExternal>
        </View>}
      </>
    );
  }
}
const styles = StyleSheet.create({
  miniCardStyle: {
    backgroundColor: "#ffffff",
    paddingHorizontal: "20px",
    borderRadius: 0,
    elevation: 3,
    width: 295,
  },
});
export default LatestStories;
