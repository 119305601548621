import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';


export class PostStrapline extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        const { data, darkMode } = this.props;
        return (
            <View>
                <div className={"postsingle-content postsingle-bodyfont" + (darkMode ? " dark-mode" : "")}><Text style={[helpers.isDesktop() ? postSingle.straplineDesktop : postSingle.strapline, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]} >{data}</Text></div>
            </View>
        );
    }
}

export default PostStrapline;