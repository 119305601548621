import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableOpacity, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, subscribePlans, actionBox, button, Colors } from '../../styles/appStyles';
import ButtonGradient from '../button-gradient';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';

const infoIcon = getImagePath('icons/info.png');
const giftPlanIcon = getImagePath('icons/gift-plan-icon.png');

export class ActionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {

        const { data, onButtonClick, footerClick, renderDesc, singleLine, boxColor, subscriptionPage = false, darkMode, disableActions, giftPlan } = this.props;

        return (
            <View style={[subscriptionPage && { flex: 1 }]}>
                <View style={[actionBox.box, helpers.isDesktop() && actionBox.boxDesktop, Padding.ph_2, helpers.isDesktop() && Margin.pb_3, boxColor && { borderColor: boxColor }, subscriptionPage && { flex: 1, paddingTop: helpers.isDesktop() ? 15 : 35, paddingBottom: helpers.isDesktop() ? 20 : 25 }, darkMode && { backgroundColor: Colors.darkBlackColor }]}>
                    {giftPlan &&
                        <View style={[{ alignItems: 'center' }]}>
                            <CustomImage
                                source={giftPlanIcon}
                                require={true}
                                style={{ width: 83, height: 89, resizeMode: 'cover', marginBottom: 15, }}
                                webStyle={{ width: 83, height: 89, objectFit: 'cover', marginBottom: 15, }}
                            />
                        </View>
                    }
                    <Text style={[actionBox.title, helpers.isDesktop() && actionBox.titleDesktop, darkMode && { color: Colors.lightWhite }]}>{data.title}</Text>

                    {singleLine ?
                        <View style={[subscribePlans.textUnderline, { marginTop: 0, marginBottom: 10 }, darkMode && { borderTopColor: Colors.lineColor }]} />
                        :
                        <>
                            <View style={{ borderTopWidth: 1, maxWidth: 75, width: '100%', borderTopColor: '#000000', opacity: 0.85, marginLeft: 'auto', marginRight: 'auto', marginTop: 11, marginBottom: 7, }} />
                            <View style={{ borderTopWidth: 1, maxWidth: 40, width: '100%', borderTopColor: '#000000', opacity: 0.85, marginLeft: 'auto', marginRight: 'auto', marginBottom: 22 }} />
                        </>
                    }

                    {data.desc ?
                        <Text style={[actionBox.Desc, helpers.isDesktop() && actionBox.DescDesktop, { marginBottom: 20 }, darkMode && { color: Colors.lightWhite }]}>{data.desc}</Text>
                        :
                        renderDesc()
                    }

                    {data.buttonText && Platform.OS != 'ios' &&
                        <View style={Margin.mt_5}>
                            <ButtonGradient
                                title={data.buttonText}
                                disable={disableActions}
                                rootStyle={{
                                    btnWrapper: button.primaryGradient,
                                    btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '400' }]
                                }}
                                onClick={() => onButtonClick()}
                                darkMode={darkMode}
                            />
                        </View>
                    }
                    <View style={[{ marginTop: (data.buttonText == 'Renew Plan') ? 0 : 30 }, Flex.alignCenter, { flex: 1, justifyContent: 'flex-end' }]}>

                        <Text style={[Helpers.textCenter, { marginBottom: (data.buttonText == 'Renew Plan') ? 5 : 20 }]}>
                            {data.footerTextBefore && <Text style={[actionBox.footerText, helpers.isDesktop() && actionBox.footerTextDesktop]}>{data.footerTextBefore} </Text>}
                        </Text>
                        {data.footerButtonText && Platform.OS != 'ios' && <Text onPress={() => footerClick()} style={[actionBox.footerBtnTextCancel, helpers.isDesktop() && actionBox.footerBtnTextCancelDesktop, helpers.isDesktop() && actionBox.footerBtnTextDesktop, { paddingVertical: 13, paddingHorizontal: 45, lineHeight: 5 }]}>{data.footerButtonText}</Text>}
                        {data.footerTextAfter && <View style={[Helpers.textCenter, Flex.row, Padding.pt_1, { borderTopWidth: 1, borderTopColor: '#DDDDDD' }]}>
                            <CustomImage
                                source={infoIcon}
                                require={true}
                                style={{ width: 20, height: 14, resizeMode: 'contain' }}
                                webStyle={{ width: 20, height: 14, objectFit: 'contain', marginTop: 3 }}
                            /><Text style={[actionBox.footerText, helpers.isDesktop() && actionBox.footerTextDesktop, Helpers.textItalic, { fontSize: 14, lineHeight: 19, color: '#6A6A6A' }]}>{data.footerTextAfter}</Text>
                        </View>}
                    </View>
                </View>
            </View>
        );
    }
}

export default ActionBox;