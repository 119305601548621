import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import ScrollContainerView from "../scroll-container-view";
import { CardView } from "react-native-simple-card-view";
import { relativeTimeRounding } from "moment";
import Swiper from "react-native-web-swiper";
import {
  renderBorderColor,
  categoryImage,
  paddingCategory,
} from "../../screens/yesterday/renderBorderColor";

export class TwoGridStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeSecondAuthor: [],
    };
  }
  componentDidMount = () => {
    let { latestNewsData } = this.props;
    let isAuthorEnable = [];
    for (let i = 0; i < latestNewsData.length; i++) {
      isAuthorEnable[i] = false;
    }
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };

  setSecondAuth = (index) => {
    let isAuthorEnable = [...this.state.seeSecondAuthor];
    isAuthorEnable[index] = true;
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };

  pushNewsletterCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "newsletter_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      newsletter_section: this.props.title || "NA",
      newsletter_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushArticleCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: this.props.title || "NA",
      article_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    let webView = helpers.isWeb();
    let {
      darkMode,
      title,
      latestNewsData,
      viewArticle,
      viewAuthor,
      viewCategory,
      viewCompany,
    } = this.props;
    let { seeSecondAuthor } = this.state;
    return (
      <View style={{ flexDirection: helpers.isDesktop() ? "row" : "column" }}>
        {latestNewsData &&
          latestNewsData.map((item, index) => {
            let btnClick, authorBtnClick, author2BtnClick;
            if (helpers.isWeb()) {
              btnClick = {
                to: {
                  pathname: "/" + item.category.slug + "/" + item.slug,
                  postData: item,
                },
              };
              authorBtnClick = {
                to: "/writers/" + item.author[0].slug,
              };
              if (item.author[1]) {
                author2BtnClick = {
                  to: "/writers/" + item.author[1].slug,
                };
              }
            } else {
              btnClick = {
                onPress: () => viewArticle(item.category.slug, item.slug, item),
              };
              authorBtnClick = {
                onPress: (event) => {
                  event.stopPropagation();
                  viewAuthor(item.author[0].slug);
                },
              };
              if (item.author[1]) {
                author2BtnClick = {
                  onPress: (event) => {
                    event.stopPropagation();
                    viewAuthor(item.author[1].slug);
                  },
                };
              }
            }

            const typesArray = item?.types?.map((item) => {
              return item.name.toLowerCase();
            });

            let btnCategoryClick = {
              to:
              title == "newsletter"
                ? "/newsletters/" + item?.newsletter?.slug
                : "/category/" + item.category.slug,
              handleClick: item?.newsletter?.name
                ? () =>
                    this.pushNewsletterCategoryClickToDataLayer(
                      item?.newsletter?.slug,
                      typesArray.join(",")
                    )
                : () =>
                    this.pushArticleCategoryClickToDataLayer(
                      item?.category?.slug,
                      typesArray.join(",")
                    ),
            };

            return (
              <View
                style={{ width: helpers.isDesktop() ? "50%" : "100%" }}
                key={`latestnews_${index}`}
              >
                <>
                  <View
                    style={[
                      {
                        // maxWidth: helpers.isDesktop() ? "54%" : "100%",
                        paddingLeft: helpers.isDesktop() ? 16 : 20,
                        paddingRight: helpers.isDesktop() ? 16 : 20,
                        marginBottom: helpers.isDesktop() ? 8 : 0,
                      },
                    ]}
                  >
                    {/* {helpers.isDesktop() ? ( */}
                    <TouchableOpacityLink
                      {...btnCategoryClick}
                      activeOpacity={0.8}
                    >
                      <Text
                        style={[
                          {
                            marginBottom: helpers.isDesktop()
                              ? 0
                              : index + 1 == 1 || title == "spotlight"
                              ? 8
                              : 4,
                            textAlign: "left",
                            textTransform: "uppercase",
                            fontFamily: FontFamily.regular,
                            fontStyle: "normal",
                            fontWeight: "600",
                            color:
                              item.category.name === "Business"
                                ? "#2CB680"
                                : item.category.name === "Chaos"
                                ? "#907cff"
                                : item.category.name === "Internet"
                                ? "#FC876D"
                                : "#ECB731",
                            fontSize: helpers.isDesktop() ? 19 : 13,
                            lineHeight: helpers.isDesktop() ? 22.8 : 16,
                            letterSpacing: 1,
                            fontFamily: "HaasGrotesk-Medium",
                            fontWeight: 500,
                          },
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.regular,
                          },
                          darkMode && { color: "#5263F4" },
                        ]}
                      >
                        {/* {item.category.name} */}
                        {title == "newsletter"
                          ? item?.newsletter?.name
                          : item.category.name}
                      </Text>
                    </TouchableOpacityLink>
                    {/* ) : null} */}
                  </View>
                  <View
                    style={[
                      // helpers.isDesktop() ? Flex.row : Flex.column,
                      Flex.FlexWrap,
                      { width: "100%", marginBottom: 34 },
                      !helpers.isDesktop() && {
                        paddingLeft: helpers.isDesktop() ? 20 : 20,
                        paddingRight: helpers.isDesktop() ? 20 : 20,
                        paddingTop: helpers.isDesktop() ? 15 : 0,
                        paddingBottom: helpers.isDesktop() ? 15 : 0,
                      },
                      helpers.isDesktop() && {
                        paddingLeft: 16,
                        paddingRight: 16,
                      },
                    ]}
                  >
                    <TouchableOpacityLink {...btnClick} activeOpacity={0.8}>
                      {helpers.isDesktop() ? (
                        <View
                          style={[
                            {
                              // maxWidth: helpers.isDesktop() ? "46%" : "100%",
                              width: "100%",
                              marginBottom: helpers.isDesktop() ? 15 : 8,
                            },
                          ]}
                        >
                          <CustomImage
                            source={item?.featured_image?.url}
                            require={true}
                            resizeMode={"cover"}
                            style={{
                              width: "100%",
                              maxWidth: "100%",
                              height: helpers.isDesktop() ? "320px" : "210px",
                              objectFit: "cover",
                            }}
                            webStyle={{
                              maxWidth: "100%",
                              height: helpers.isDesktop() ? "320px" : "210px",
                              margin: 0,
                              objectFit: "cover",
                            }}
                            // resizeMode="stretch"
                          />
                        </View>
                      ) : (
                        <>
                          {index + 1 == 1 || title == "spotlight" ? (
                            <View
                              style={[
                                {
                                  // maxWidth: helpers.isDesktop() ? "46%" : "100%",
                                  width: "100%",
                                  marginBottom: helpers.isDesktop() ? 15 : 8,
                                },
                              ]}
                            >
                              <CustomImage
                                source={item?.featured_image?.url}
                                require={true}
                                resizeMode={"cover"}
                                style={{
                                  width: "100%",
                                  maxWidth: "100%",
                                  height: helpers.isDesktop()
                                    ? "320px"
                                    : "210px",
                                  objectFit: "cover",
                                }}
                                webStyle={{
                                  maxWidth: "100%",
                                  height: helpers.isDesktop()
                                    ? "320px"
                                    : "210px",
                                  margin: 0,
                                  objectFit: "cover",
                                }}
                                // resizeMode="stretch"
                              />
                            </View>
                          ) : null}
                        </>
                      )}
                    </TouchableOpacityLink>
                    <View>
                      <TouchableOpacityLink {...btnClick} activeOpacity={0.8}>
                        <Text
                          style={[
                            {
                              display: "block",
                              width: "100%",
                              fontWeight: "700",
                              fontSize: helpers.isDesktop()
                                ? 30
                                : index + 1 == 1 || title == "spotlight"
                                ? 24
                                : 16,
                              lineHeight: helpers.isDesktop()
                                ? 42
                                : index + 1 == 1 || title == "spotlight"
                                ? 35.5
                                : 22.4,
                              color: "#292929",
                            },
                            darkMode && { color: "rgba(255,255,255,0.87)" },
                            {
                              marginBottom: helpers.isDesktop()
                                ? 16
                                : index + 1 == 1 || title == "spotlight"
                                ? 8
                                : 0,
                              fontFamily: "PlayfairDisplay-Bold",
                            },
                          ]}
                        >
                          {item.title}
                        </Text>
                      </TouchableOpacityLink>
                      {helpers.isDesktop() ? (
                        <TouchableOpacityLink {...btnClick} activeOpacity={0.8}>
                          <Text
                            style={[
                              // articleLists.badge,
                              {
                                width: "100%",
                                marginBottom: helpers.isDesktop() ? 0 : 10,
                                textAlign: "left",
                                fontFamily: FontFamily.regular,
                                color: "#292929",
                                fontSize: helpers.isDesktop() ? 18 : 14,
                                lineHeight: helpers.isDesktop() ? 27 : 21,
                                letterSpacing: helpers.isDesktop() ? 0.3 : 0,
                              },
                              // !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                              darkMode && { color: "#5263F4" },
                            ]}
                          >
                            {item.strapline}
                          </Text>
                        </TouchableOpacityLink>
                      ) : (
                        <>
                          {index + 1 == 1 || title == "spotlight" ? (
                            <TouchableOpacityLink
                              {...btnClick}
                              activeOpacity={0.8}
                            >
                              <Text
                                style={[
                                  // articleLists.badge,
                                  {
                                    width: "100%",
                                    marginBottom: helpers.isDesktop() ? 16 : 10,
                                    textAlign: "left",
                                    fontFamily: FontFamily.regular,
                                    color: "#292929",
                                    fontSize: helpers.isDesktop() ? 18 : 14,
                                    lineHeight: helpers.isDesktop() ? 27 : 21,
                                    letterSpacing: helpers.isDesktop()
                                      ? 0.3
                                      : 0,
                                  },
                                  // !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                                  darkMode && { color: "#5263F4" },
                                ]}
                              >
                                {item.strapline}
                              </Text>
                            </TouchableOpacityLink>
                          ) : null}
                        </>
                      )}

                      <View
                        style={{
                          width: 100,
                          height: 1,
                          backgroundColor: "#907CFF",
                          marginTop: helpers.isDesktop() ? 16 : 8,
                          marginBottom: helpers.isDesktop() ? 18 : 10,
                        }}
                      ></View>
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          { width: "100%", flexWrap: "wrap" },
                        ]}
                      >
                        <TouchableOpacityLink {...authorBtnClick}>
                          <View
                            style={[
                              Flex.row,
                              Flex.alignCenter,
                              { marginRight: 15 },
                            ]}
                          >
                            <View
                              style={{
                                width: 34,
                                height: 34,
                                overflow: "hidden",
                                marginRight: 10,
                                borderRadius: "50%",
                                padding: 1,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: "#FC876D",
                              }}
                            >
                              <View
                                style={{
                                  width: 30,
                                  height: 30,
                                  overflow: "hidden",
                                  borderRadius: "100%",
                                }}
                              >
                                <CustomImage
                                  source={item.author[0].profile_image}
                                  require={true}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  webStyle={{
                                    width: "100%",
                                    height: "auto",
                                  }}
                                />
                              </View>
                            </View>
                            <Text
                              style={[
                                {
                                  fontWeight: "400",
                                  fontSize: helpers.isDesktop()
                                    ? 16
                                    : index + 1 == 1 || title == "spotlight"
                                    ? 16
                                    : 14,
                                  lineHeight: helpers.isDesktop()
                                    ? 19
                                    : index + 1 == 1 || title == "spotlight"
                                    ? 22
                                    : 16.8,
                                },
                                darkMode && {
                                  color: "rgba(255,255,255,0.87)",
                                },
                                { fontFamily: FontFamily.regular },
                              ]}
                            >
                              {item.author[0].name}
                            </Text>
                          </View>

                          {/* <View
                                                    style={[
                                                        Flex.row,
                                                        Flex.alignCenter,
                                                        { marginRight: 15 },
                                                    ]}
                                                >
                                                    <View
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                            overflow: "hidden",
                                                            marginRight: 10,
                                                            borderRadius: 40,
                                                            padding: 3,
                                                            borderStyle: "solid",
                                                            borderWidth: 1,
                                                            borderColor: "#FC876D",
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                width: 34,
                                                                height: 34,
                                                                overflow: "hidden",
                                                                borderRadius: 36,
                                                            }}
                                                        >
                                                            <CustomImage
                                                                source={yesterdayLogo}
                                                                require={true}
                                                            />
                                                        </View>
                                                    </View>
                                                    <Text
                                                        style={[
                                                            {
                                                                fontWeight: "400",
                                                                fontSize: helpers.isDesktop()
                                                                    ? 16
                                                                    : (index+1) == 1 || title == "spotlight"
                                                                        ? 16
                                                                        : 14,
                                                                lineHeight: helpers.isDesktop()
                                                                    ? 19
                                                                    : (index+1) == 1 || title == "spotlight"
                                                                        ? 22
                                                                        : 16.8,
                                                            },
                                                            darkMode && {
                                                                color: "rgba(255,255,255,0.87)",
                                                            },
                                                            { fontFamily: FontFamily.regular },
                                                        ]}
                                                    >
                                                        Firstname Lastname
                                                    </Text>
                                                </View> */}
                        </TouchableOpacityLink>
                        {seeSecondAuthor[index] && item.author[1] && (
                          <TouchableOpacityLink {...author2BtnClick}>
                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                                { marginRight: 15 },
                              ]}
                            >
                              <View
                                style={{
                                  width: 34,
                                  height: 34,
                                  overflow: "hidden",
                                  marginRight: 12,
                                  borderRadius: "50%",
                                  padding: 1,
                                  borderStyle: "solid",
                                  borderWidth: "0.45px",
                                  borderColor: "#FC876D",
                                }}
                              >
                                <View
                                  style={{
                                    width: 30,
                                    height: 30,
                                    overflow: "hidden",
                                    borderRadius: "100%",
                                  }}
                                >
                                  <CustomImage
                                    source={item.author[1].profile_image}
                                    require={true}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    webStyle={{
                                      width: "100%",
                                      height: "auto",
                                    }}
                                  />
                                </View>
                              </View>
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontSize: helpers.isDesktop() ? 16 : 14,
                                    lineHeight: helpers.isDesktop() ? 19 : 16.8,
                                  },
                                  darkMode && {
                                    color: "rgba(255,255,255,0.87)",
                                  },
                                  { fontFamily: FontFamily.regular },
                                ]}
                              >
                                {item.author[1].name}
                              </Text>
                            </View>
                          </TouchableOpacityLink>
                        )}
                        {item.author.length > 1 && !seeSecondAuthor[index] && (
                          <TouchableOpacity
                            onPress={() => {
                              this.setSecondAuth(index);
                            }}
                          >
                            <View
                              style={[
                                {
                                  borderStyle: "solid",
                                  fontFamily: FontFamily.regular,
                                  fontSize: 16,
                                  lineHeight: 19,
                                  borderWidth: 1,
                                  backgroundColor: "#F3F5FF",
                                  borderColor: "#907CFF",
                                  width: 24,
                                  height: 24,
                                  borderRadius: "50%",
                                },
                                Flex.alignCenter,
                                Flex.row,
                                Flex.justfiyCenter,
                              ]}
                            >
                              +1
                            </View>
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                  </View>
                </>
              </View>
            );
          })}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  miniCardStyle: {
    backgroundColor: "#ffffff",
    paddingHorizontal: "20px",
    borderRadius: 0,
    elevation: 3,
    width: 295,
  },
});
export default TwoGridStory;
