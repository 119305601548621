import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, article, articleItem, authorItem, FontFamily, investorCard, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';

export class InvestorCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    onClick = () => {

    }

    render() {
        let { investor, darkMode } = this.props;

        return (
            <View style={[{ marginBottom: helpers.isDesktop() ? 60 : 30 }, { width: helpers.isDesktop() ? '33.33%' : '100%' }, { paddingLeft: helpers.isDesktop() ? 20 : 0 }, { paddingRight: helpers.isDesktop() ? 20 : 0 }]}>
                <View style={[Flex.fill, !helpers.isDesktop() && investorCard.wrapBorder, !helpers.isDesktop() && { paddingTop: 0, paddingBottom: helpers.isDesktop() ? 6 : 0 }]}>
                    <View style={[investorCard.wrap, helpers.isDesktop() && investorCard.wrapBorder, !helpers.isWeb() && investorCard.cardApp, darkMode && {backgroundColor: Colors.darkBlackColor, shadowColor: "rgba(0, 0, 0, 0.42)"}]}>
                        <Text style={[investorCard.title, { fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 38.4 : 30 , color: darkMode ? Colors.lightWhite : '#000'}]}>
                            {investor.name}
                        </Text>
                        <Text style={[investorCard.designation, { fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: helpers.isDesktop() ? 24 : 23, fontFamily: FontFamily.regular, color: darkMode ? Colors.lightWhite : '#000' }]}>
                            {investor.designation}, {investor.company}
                        </Text>
                    </View>
                </View>
            </View>
        );
    }
}

export default InvestorCard;