export const CHARGEBEE_SETTINGS = {
    DEBUG: (process.env.IS_LOCAL == 'true')? true: false,
    CB_BILLING_PATFORM_IN: 'chargebee_india',
    CB_BILLING_PATFORM_OT: 'chargebee_worldwide',
    CB_ACCOUNT_SITE_ENDPOINT: '/cb-account-site',
    CB_SITE_IN: process.env.CHARGEBEE_SITE_IN,
    CB_SITE_IN_API_KEY: process.env.CHARGEBEE_API_KEY_IN,
    CB_SITE_IN_PUB_KEY: process.env.CHARGEBEE_API_KEY_IN,
    CB_SITE_IN_DOMAIN: process.env.CHARGEBEE_SITE_IN_DOMAIN,
    CB_SITE_OT: process.env.CHARGEBEE_SITE_OT,
    CB_SITE_OT_API_KEY: process.env.CHARGEBEE_API_KEY_OT,
    CB_SITE_OT_PUB_KEY: process.env.CHARGEBEE_API_KEY_OT,
    CB_SITE_OT_DOMAIN: process.env.CHARGEBEE_SITE_OT_DOMAIN,


    SITE_URL: process.env.SITE_URL_FULL,
    PORTAL_SESSION_ENDPOINT: '/cb-portal-object',
    PORTAL_SESSION_ALLOWED_ACTIONS: {
        create: true
    },
    PORTAL_SESSION_DEFAULT_REDIRECT_URL: 'https://themorningcontext.com/#accounts',

    REMOVE_SHEDULED_CANCELLATION_ENDPOINT: '/cb-remove-subscription-cancellation',
    PAYMENT_SOURCE_ENDPOINT: '/cb-payment-info',
    HOSTED_OBJECT_ENDPOINT: '/cb-hosted-object',
    HOSTED_OBJECT_REDIRECT: '/payment-redirect',
    HOSTED_OBJECT_REDIRECT_PARAMETER: 'hostedpage_id',
} as const