import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, newsletter, FontFamily, Colors,Typo ,featuredArticle} from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import moment from 'moment';

const newsletterImg = getImagePath('img/things-change.png');
const arrowRight = getImagePath('icons/arrow-right-orange.png');

export class NewsletterListing extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {
        let { newsLetter, darkMode } = this.props;
        let webView = helpers.isWeb();
        let viewBtnProps;
        if (webView) {
            viewBtnProps = {
                to: "/newsletters/" + newsLetter.slug
            }
        } else {
            viewBtnProps = {
                onPress: () => this.props.getNewsletterClick(newsLetter.slug)
            }
        }
        return (
            <>
             
                   
            <View style={[helpers.isDesktop() && Flex.row, helpers.isDesktop() && Flex.alignCenter, helpers.isDesktop() && Margin.mb_9,  !helpers.isDesktop() && Margin.mb_6, { paddingHorizontal: 20 }, , !helpers.isWeb() && newsletter.appWrapper, darkMode && { backgroundColor: Colors.darkBlackColor, shadowColor: "rgba(0, 0, 0, 0.80)" }]}>
                <View style={[!helpers.isDesktop() && { margin: 'auto' }, helpers.isDesktop() && { width: 350, margin: 'auto', alignItems: 'center' }, !helpers.isWeb() && { alignItems: 'center' }]}>
                  { ( !helpers.isDesktop())&&     <Text style={[featuredArticle.newslettermain, helpers.isDesktop() &&  featuredArticle.titleDesktop,  {fontFamily: FontFamily.medium, letterSpacing: 0.3, fontWeight: '600', fontSize: 20, lineHeight: 42 },  {textAlign:'left', lineHeight: 35}, (!helpers.isWeb() ) && {fontFamily: FontFamily.bold}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{newsLetter.name}</Text>}
  
                    <TouchableOpacityLink {...viewBtnProps} >
                        <CustomImage
                            source={newsLetter.logo}
                            require={false}
                            style={{ width: 250, height: 130, resizeMode: 'contain', margin: 'auto' }}
                            webStyle={{  height: helpers.isDesktop() ? 'auto' : 'auto', objectFit: 'contain', margin: 'auto' }}
                            altText={newsLetter.name}
                        />
                        {
                            darkMode &&
                            <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(18, 18, 18, 0.05)' }}>

                            </View>
                        }
                    </TouchableOpacityLink>
                </View>
                <View style={[{ flex: 1 }, helpers.isDesktop() && { paddingLeft: 45 }, !helpers.isDesktop() && { marginTop: 20 }]}>
                    <View style={[ !helpers.isDesktop() && Padding.pb_2]}>
                { ( helpers.isDesktop())&&     <Text style={[featuredArticle.newslettermain, helpers.isDesktop() &&  featuredArticle.titleDesktop,  {fontFamily: FontFamily.medium, letterSpacing: 0.3, fontWeight: '600', fontSize: 20, lineHeight: 42 },  {textAlign:'left', lineHeight: 35}, (!helpers.isWeb() ) && {fontFamily: FontFamily.bold}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{newsLetter.name}</Text>}
                  
                     <Text style={[featuredArticle.newslettertitle, helpers.isDesktop() &&  featuredArticle.titleDesktop,  {fontFamily: FontFamily.medium, letterSpacing: 0.3, fontWeight: '600', fontSize: 30, lineHeight: 42 },  {textAlign:'left', lineHeight: 35}, (!helpers.isWeb() ) && {fontFamily: FontFamily.bold}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>Some Title</Text>
                              
                        <Text style={[newsletter.title, !helpers.isDesktop() && { paddingBottom: 10, fontSize: 16 }, helpers.isDesktop() && { fontSize: 18, lineHeight: 30 }, darkMode && { color: Colors.lightWhite }]}>{newsLetter.description}</Text>
                        <View style={[newsletter.lineBreak, Margin.mt_0, Margin.mb_1, helpers.isDesktop() && [Margin.mb_2, Margin.mt_1]]}></View>
                         </View>
                    {/* {helpers.isWeb() ?
                        <TouchableOpacityLink {...viewBtnProps}>
                            <Text style={[newsletter.linkText]}>View this newsletter</Text>
                            <CustomImage
                                source={arrowRight}
                                require={true}
                                style={{ width: 25, height: 15, resizeMode: 'cover', marginLeft: 9 }}
                                webStyle={{ width: 17, height: 'auto', objectFit: 'cover', marginLeft: 9 }}
                            />
                        </TouchableOpacityLink>
                        :
                        <TouchableOpacityLink {...viewBtnProps}>
                            <View style={[Flex.row, Flex.alignCenter]}>
                                <Text style={[newsletter.linkText]}>View this newsletter</Text>
                                <CustomImage
                                    source={arrowRight}
                                    require={true}
                                    style={{ width: 20, height: 10, resizeMode: 'cover', marginLeft: 9 }}
                                    webStyle={{ width: 17, height: 'auto', objectFit: 'cover', marginLeft: 9 }}
                                />
                            </View>
                        </TouchableOpacityLink>
                    } */}
                     <View>
                                <View style={[postSingle.authorDetailsWrap, Flex.alignCenter, helpers.isDesktop() && Flex.justfiyCenter, helpers.isDesktop() && Flex.justifyStart, Flex.FlexWrap]}>
                                    { <TouchableOpacityLink
                                       >
                                        <View style={[postSingle.authorDetailsWrap, helpers.isDesktop() && Flex.alignCenter, Flex.justfiyCenter, helpers.isDesktop() && Flex.justifyStart]}>
                                            <View style={[postSingle.authorPicWrap, { width: 35, height: 35 }]}>
                                                <CustomImage
                                                    source={'https://tmcprod-media.s3.ap-south-1.amazonaws.com/2020/11/14062542/Ashish-final-1-210x210.jpg'}
                                                    require={false}
                                                    webStyle={{
                                                        width: 31,
                                                        height: 31,
                                                        borderRadius: 100,
                                                    }}
                                                    resizeMode={'contain'}
                                                    style={{width: 31, height: 31, borderRadius: 100}}
                                                    altText={'name'}
                                                />
                                            </View> 
                                            <View style={[Padding.pl_1]}> 
                                                <Text style={[postSingle.authorName, helpers.isDesktop() && postSingle.authorNameDesktop, ( !helpers.isDesktop()) && {fontSize: 15}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{'Author Name'}</Text>
                                            </View>
                                        </View>
                                    </TouchableOpacityLink>}
                                    { ( helpers.isDesktop())&& <>
                                        <View style={[featuredArticle.dotSeprator,{marginBottom: 0}]} />
                                        <TouchableOpacityLink
                                         >
                                        <View style={[postSingle.authorDetailsWrap, Flex.alignCenter, Flex.justfiyCenter, helpers.isDesktop() && Flex.justifyStart]}>
                                            <View style={[postSingle.authorPicWrap, { width: 35, height: 35 }]}>
                                                <CustomImage
                                                    source={'https://tmcprod-media.s3.ap-south-1.amazonaws.com/2020/11/14062542/Ashish-final-1-210x210.jpg'}
                                                    require={false}
                                                    webStyle={{
                                                        width: 31,
                                                        height: 31,
                                                        borderRadius: 100,
                                                    }}
                                                    resizeMode={'contain'}
                                                    style={{width: 31, height: 31, borderRadius: 100}}
                                                    altText={'name'}
                                                />
                                            </View>
                                            <View style={[Padding.pl_1]}>
                                                <Text style={[postSingle.authorName, helpers.isDesktop() && postSingle.authorNameDesktop, ( !helpers.isDesktop()) && {fontSize: 15}, darkMode && {color: 'rgba(255,255,255,0.87)'}]}>{'Author Name'}</Text>
                                            </View>
                                        </View>
                                    </TouchableOpacityLink></>}
                                        { ( helpers.isDesktop()) && <View style={[featuredArticle.dotSeprator, {marginTop: 0}]} />}
                                         </View>
                            </View>
                </View>
 { ( helpers.isDesktop())&&<View style={{alignSelf:'flex-start'}}>
<View style={[ { width: 35, height: 35 }]}>
                                                <CustomImage
                                                    source={'https://user-images.githubusercontent.com/62637513/130729234-f6ce13f8-d7ab-4411-bf13-fd100ea3cc5d.png'}
                                                    require={false}
                                                    webStyle={{
                                                        width: 30,
                                                        height: 30,
                                                       
                                                    }}
                                                    resizeMode={'contain'}
                                                    style={{width: 30, height: 30}}
                                                    altText={'name'}
                                                />
                                            </View>
                                            </View>}


            </View>
            </>
        );
    }
}

export default NewsletterListing;