import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Cookies from "universal-cookie";
import { helpers } from "../../utils/helpers";
const cookies = new Cookies();

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.OATH2_ISSUER;
  const clientId = process.env.AUTH0_CLIENT_ID;

  const history = useHistory();
  const onRedirectCallback = (appState) => {
    localStorage.setItem(helpers.userSignInEventTrackingKey, true);
    history.push(appState?.returnTo || window.location.pathname);
    history.go(0);
  };

  const legacyLogout = () => {
    const auth0LogoutUrl = `https://${process.env.LEGACY_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}&client_id=${process.env.LEGACY_AUTH0_CLIENT_ID}`
    window.location.assign(auth0LogoutUrl)
  }

  const clearAuth0LoginSession = () => {

    const allCookies = cookies.getAll()

    console.log('OAUTH2DEBUG:ALL:', allCookies)

    const requiredKey = `@@auth0spajs@@::${process.env.LEGACY_AUTH0_CLIENT_ID}::https://api.themorningcontext.com/api/v1::openid profile email offline_access`;

    const auth0Data = localStorage.getItem(requiredKey)
    const isLegacySession = auth0Data? true: false
    if(!isLegacySession){
      return
    }
    console.log('OAUTH2DEBUG:old-session', auth0Data)
    // 1. Remove Auth0 localstorage dta
    localStorage.removeItem(requiredKey)
    // 2. Clear Auth0 cookies
    const auth0Cookies = [
      'auth0.is.authenticated',
      '_legacy_auth0.is.authenticated',
      `auth0.${process.env.AUTH0_CLIENT_ID}.is.authenticated`,
      `_legacy_auth0.${process.env.AUTH0_CLIENT_ID}.is.authenticated`,
    ]
    console.log('OAUTH2DEBUG:KEYS', auth0Cookies)
    auth0Cookies.forEach((cookieName) => {
      try{
        cookies.remove(cookieName)
      }catch(err){
        console.log('OAUTH2DEBUG:cookieDelete:error: ', err)
      }
    })

    // 3. Logout from Auth0
    legacyLogout()
  }

  if(process.browser){
    clearAuth0LoginSession()
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={"localstorage"}
      useRefreshTokens={true}
      scope="openid profile email offline_access"
      sessionCheckExpiryDays={180}
      useCookiesForTransactions={true}
      history={history}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;