import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';
import PageBreak from '../page-break';

const chooseCredits = getImagePath('icons/choose-credits.png');

const buyCredits = getImagePath('icons/buy-plan.png');
const inviteMember = getImagePath('icons/invite-members.png');
const unlockContent = getImagePath('icons/unlock-content.png');


export class SeatBasedSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }


    seatbasedsubscription = () => {
        let {darkMode} = this.props;
        return (
            <View style={[Padding.pl_2, Padding.pr_2]}>
                <View style={[!helpers.isDesktop() && Margin.mb_4, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[Flex.alignCenter, Margin.mb_4]}>
                        <Text style={[{fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: 29, fontFamily: FontFamily.medium, fontWeight: FontWeight.semiBold},!helpers.isWeb() && {textAlign: 'center'}, darkMode && {color: Colors.lightWhite}]}>How seat based subscription works?</Text>
                    </View>
                    <View style={[helpers.isDesktop() && [Flex.row,  Flex.justfiyCenter] , Padding.pb_2, !helpers.isDesktop() && Padding.pb_3, { paddingHorizontal: 20, margin: 'auto'}]}>
                        <View style={[Flex.alignCenter, Margin.mb_4, helpers.isDesktop() && {marginHorizontal: 10}, {paddingHorizontal: 10, paddingVertical: helpers.isDesktop() ? 30 : 20}, {width:  helpers.isDesktop() ? '23%' : '100%', backgroundColor: 'white'}, darkMode && {backgroundColor: Colors.darkPurple}]}>
                            <CustomImage
                                source={buyCredits}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 55 : 30, height: helpers.isDesktop() ? 55 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 55 : 30 }}
                                altText={'Purchase Seats'}
                            />
                            <Text style={[Padding.pt_2,Padding.pb_1,{ fontSize: 18, lineHeight: 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }, darkMode && {color: Colors.lightWhite}]}>Purchase Seats</Text>
                            <Text style={{ fontSize: 16, lineHeight: 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.9)', opacity: 0.85 }}>The more seats, the bigger the discount you can get.</Text>
                        </View>
                        <View style={[Flex.alignCenter, Margin.mb_4, helpers.isDesktop() && {marginHorizontal: 10}, {paddingHorizontal: 10, paddingVertical: helpers.isDesktop() ? 30 : 20}, {width:  helpers.isDesktop() ? '23%' : '100%', backgroundColor: 'white'}, darkMode && {backgroundColor: Colors.darkPurple}]}>
                            <CustomImage
                                source={inviteMember}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 55 : 30, height: helpers.isDesktop() ? 55 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 55 : 30 }}
                                altText={'Invite Members'}
                            />
                            <Text style={[Padding.pt_2,Padding.pb_1,{ fontSize: 18, lineHeight: 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }, darkMode && {color: Colors.lightWhite}]}>Invite Members</Text>
                            <Text style={{ fontSize: 16, lineHeight: 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.9)', opacity: 0.85 }}>Post-purchase you can access the admin panel and invite members from your organization.</Text>
                        </View>
                        <View style={[Flex.alignCenter, Margin.mb_4, helpers.isDesktop() && {marginHorizontal: 10}, {paddingHorizontal: 10, paddingVertical: helpers.isDesktop() ? 30 : 20}, {width:  helpers.isDesktop() ? '23%' : '100%', backgroundColor: 'white'}, darkMode && {backgroundColor: Colors.darkPurple}]}>
                            <CustomImage
                                source={unlockContent}
                                require={true}
                                style={{ width: helpers.isDesktop() ? 55 : 30, height: helpers.isDesktop() ? 55 : 30 }}
                                webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 55 : 30 }}
                                altText={'Start Reading'}
                            />
                            <Text style={[Padding.pt_2,Padding.pb_1,{ fontSize: 18, lineHeight: 24, fontFamily: FontFamily.regular, fontWeight: FontWeight.semiBold }, darkMode && {color: Colors.lightWhite}]}>Start Reading</Text>
                            <Text style={{ fontSize: 16, lineHeight: 27, letterSpacing: 0.3, fontFamily: FontFamily.regular, fontWeight: '400', color: darkMode ? Colors.lightWhite : 'rgba(0,0,0,0.9)', opacity: 0.85 }}>Member can sign in and start accessing all the stories & newsletters on TMC</Text>
                        </View>
                    </View>
                </View>
            </View>
        );
    }

    render() {
        let webView = helpers.isWeb();
        return(
            <>
                {this.seatbasedsubscription()}
            </>
        )
    }
}

export default SeatBasedSubscription;