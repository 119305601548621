import React, { Component } from 'react';
import { Text, View, ScrollView, Platform, ActivityIndicator, Image, Modal, Linking, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, FontFamily, appStyles, actionBox, accessContent, pageCover, textBlock, articleLoader, articleLists, FontWeight, button, pageContent } from '../../styles/appStyles';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import ErrorModal from '../../components/error-modal';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import PageBreak from '../../components/page-break';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";

const overlayImage = getImagePath('img/excerpt_overlay.png');

const cookies = new Cookies();

const Method1a = getImagePath('img/method1.png');
const Method2a = getImagePath('img/method-2a.png');
const Method2b = getImagePath('img/method-2b.png');
const Method3a = getImagePath('img/method-3a.png');
const Method3b = getImagePath('img/method-3b.png');
const Method3c = getImagePath('img/method-3c.png');

const Method1aMob = getImagePath('img/method-1a-mob.png');
const Method2aMob = getImagePath('img/method-2a-mob.png');
const Method3aMob = getImagePath('img/method-3a-mob.png');
const Method3bMob = getImagePath('img/method-3b-mob.png');
const Method3cMob = getImagePath('img/method-3c-mob.png');
const Method3dMob = getImagePath('img/method-3d-mob.png');


export class CookiesInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    renderPageHeader = () => {
        return (
            <View style={[helpers.isDesktop() ? Margin.mb_7 : Margin.mb_3, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, Flex.alignCenter, { marginTop: helpers.isDesktop() ? 80 : 55, paddingHorizontal: helpers.isDesktop() ? 20 : 20 }]}>
                <Text style={[Margin.mt_0, Margin.mb_2, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, !helpers.isDesktop() && { fontSize: 34, lineHeight: 44 }]}>Enable 3rd Party Cookies in Chrome</Text>
            </View>
        )
    }

    renderPageContent = () => {
        return (
            <View style={[Margin.mb_3, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, Helpers.textCenter, { marginTop: helpers.isDesktop() ? 80 : 10, paddingHorizontal: helpers.isDesktop() ? 20 : 20 }]}>
                <View style={[Margin.mb_1, !helpers.isDesktop() && Margin.mb_3, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter, helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && { marginLeft: -20, marginRight: -20 }]}>
                    <View style={[!helpers.isDesktop() && { backgroundColor: 'white', zIndex: 1, paddingHorizontal: 20 }]}>
                        <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }]}>
                            <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, helpers.isDesktop() && { marginRight: 0 }, helpers.isDesktop() && { paddingBottom: 5, fontSize: 30 }]}>Chrome Desktop</Text>
                        </View>
                    </View>
                    {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>}
                    {helpers.isDesktop() && <View style={{ width: '100%', flex: 1 }}>
                        <View style={[Helpers.titleLineLight]}></View>
                    </View>}
                </View>
                <View style={[Padding.ph_2, helpers.isDesktop() && Margin.mt_4, !helpers.isDesktop() && Margin.mt_2, Margin.mb_3, { alignItems: 'start' }]}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.abrilFatface }}>Method 1</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 15, paddingBottom: 20 }}>Chrome by default blocks access to 3rd party cookies when in incognito mode. If you are in incognito mode, you can easily enable it by toggle off the “Block third-party cookies” in the incognito tab</Text>
                    <CustomImage
                        source={Method1a}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                </View>

                <View style={[Padding.ph_2, Margin.mt_4, { alignItems: 'start' }]}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.abrilFatface }}>Method 2</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 15 }}>If you already open up TMC, then in the top-right corner of your address bar you can enable third-party cookies for the site. Just follow the instructions below.</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 35 }}>1. Click the eye icon on the top right side of the address bar</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 0, paddingBottom: 20 }}>2. Click on “Site not working?</Text>
                    <CustomImage
                        source={Method2a}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: 'auto', objectFit: 'contain', margin: 'auto', paddingBottom: 25 }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 0, paddingBottom: 20 }}>3. Click on “Allow Cookies”</Text>
                    <CustomImage
                        source={Method2b}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: 'auto', objectFit: 'contain', margin: 'auto', paddingBottom: 25 }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 20, paddingBottom: 20 }}>This will automatically reload the page and enable cookies on the site.</Text>

                </View>

                <View style={[Padding.ph_2, Margin.mt_4, { alignItems: 'start' }]}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.abrilFatface }}>Method 3</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 15 }}>You can change the default options in the setting of your chrome browser</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 35, paddingBottom: 20 }}>1. Access the “Settings” menu by clicking on the 3 dots on the extreme end of your browser, besides the address bar.</Text>
                    <CustomImage
                        source={Method3a}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: 'auto', objectFit: 'contain', margin: 'auto', paddingBottom: 25 }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 0, paddingBottom: 20 }}>2. Click “Privacy and Security” and then “Cookies and other site data”</Text>
                    <CustomImage
                        source={Method3b}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: 'auto', objectFit: 'contain', margin: 'auto', paddingBottom: 25 }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 0, paddingBottom: 20 }}>3. Click on “Allow Cookies”</Text>
                    <CustomImage
                        source={Method3c}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: 'auto', objectFit: 'contain', margin: 'auto', paddingBottom: 25 }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 20, paddingBottom: 20 }}>4. Exit the setting tab and reload the site.</Text>
                </View>

                <View style={[Margin.mb_1, Margin.mt_7, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter, helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && { marginLeft: -20, marginRight: -20 }]}>
                    <View style={[!helpers.isDesktop() && { backgroundColor: 'white', zIndex: 1, paddingHorizontal: 20 }]}>
                        <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }]}>
                            <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, helpers.isDesktop() && { marginRight: 0 }, helpers.isDesktop() && { paddingBottom: 5, fontSize: 30 }]}>Chrome Android</Text>
                        </View>
                    </View>
                    {!helpers.isDesktop() && <View style={[Helpers.titleLine]}></View>}
                    {helpers.isDesktop() && <View style={{ width: '100%', flex: 1 }}>
                        <View style={[Helpers.titleLineLight]}></View>
                    </View>}
                </View>
                <View style={[Padding.ph_2, helpers.isDesktop() && Margin.mt_4, !helpers.isDesktop() && Margin.mt_4, { alignItems: 'start' }]}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.abrilFatface }}>Method 1</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 15, paddingBottom: 20 }}>Chrome by default blocks access to 3rd party cookies when in incognito mode. If you are in incognito mode, you can easily enable it by toggle off the “Block third-party cookies” in the incognito tab</Text>
                    <CustomImage
                        source={Method1aMob}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: helpers.isDesktop() ? 400 : 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: helpers.isDesktop() ? 400 : 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                </View>

                <View style={[Padding.ph_2, Margin.mt_4, { alignItems: 'start' }]}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.abrilFatface }}>Method 2</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 15, paddingBottom: 20 }}>If you already open up TMC, click the lock icon in the address bar. This will open up the connection popup. Here turn off “Block third-party cookie” via the toggle and the site will automatically reload.</Text>
                    <CustomImage
                        source={Method2aMob}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: helpers.isDesktop() ? 400 : 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: helpers.isDesktop() ? 400 : 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                </View>

                <View style={[Padding.ph_2, Margin.mt_4, { alignItems: 'start' }]}>
                    <Text style={{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 34 : 28, fontFamily: FontFamily.abrilFatface }}>Method 3</Text>
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 15, paddingBottom: 20 }}>You can change the default options in the setting of your chrome browser</Text>
                    <CustomImage
                        source={Method3aMob}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: helpers.isDesktop() ? 400 : 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: helpers.isDesktop() ? 400 : 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 35, paddingBottom: 20 }}>1. Access the “Settings” menu by clicking on the 3 dots on the extreme end of your browser, besides the address bar.</Text>
                    <CustomImage
                        source={Method3bMob}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: helpers.isDesktop() ? 400 : 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: helpers.isDesktop() ? 400 : 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 20, paddingBottom: 20 }}>2. Under Advanced, click on “Site settings”</Text>
                    <CustomImage
                        source={Method3cMob}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: helpers.isDesktop() ? 400 : 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: helpers.isDesktop() ? 400 : 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 20, paddingBottom: 20 }}>3. Go to “Cookies”</Text>
                    <CustomImage
                        source={Method3dMob}
                        style={{ width: helpers.isDesktop() ? 'auto' : '100%', height: helpers.isDesktop() ? 400 : 'auto', resizeMode: 'contain' }}
                        webStyle={{ width: helpers.isDesktop() ? 600 : '100%', height: helpers.isDesktop() ? 400 : 'auto', objectFit: 'contain', margin: 'auto' }}
                        require={true}
                        resizeMode={'contain'}
                    />
                    <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 26, fontFamily: FontFamily.merriweather, textAlign: 'left', paddingTop: 20, paddingBottom: 20 }}>4. Exit the setting tab and reload the site.</Text>
                </View>
            </View>
        )
    }


    render() {
        const webView = helpers.isWeb();

        const { showErrorModal, footerData } = this.state;

        let data = {
            pageName: 'Cookie Info'
        }

        return (
            <View style={[webView && null, { flex: 1, backgroundColor: 'white' }]}>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />
                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} darkMode={darkMode} showFullHeader={true} />

                <View>
                    {this.renderPageHeader()}
                    {this.renderPageContent()}
                </View>

                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} />

                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}

            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesInfo);