import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Dimensions,
  TextInput,
  Modal,
  StyleSheet,
} from "react-native";
import { FontFamily, button } from "../../styles/appStyles";
import ButtonGradient from "../button-gradient";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";

const lockIcon = getImagePath("icons/lock_icon.png");

const windowHeight = Dimensions.get("window").height;

function CommonNudgeWeb(props) {
  const [modalVisible, setModalVisible] = useState(true);
  
  useEffect(() => {
    if (!modalVisible) {
      document.body.style.overflow = "visible";
      props.closeModal();
    }
  }, [modalVisible]);

  let darkMode = props.darkMode;
  let encodedRedirectURL = `${window.location.pathname}`;
  encodedRedirectURL = encodeURIComponent(encodedRedirectURL);

  let isOverseasUser = false;

  // logged in condition
  if (props.locationInfo?.countryInfo?.code !== "IN") {
    if (!props.isLoggedin) {
      isOverseasUser = true;
    }
    // If a logged in user has no  subscription
    else if (!props.userSubscriptionExpired) {
      isOverseasUser = true;
    }
  }

  const getLoggedOutUserContent = () => {
    if (!props.isLoggedin) {
      return (
        <View
          style={{
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: "15px",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                color: "#000000",
                lineHeight: 22,
                fontFamily: "PlayfairDisplay-Regular",
              }}
            >
              Already have an account?
              <TouchableOpacity onPress={() => props.goToLogin()}>
                <Text
                  style={{
                    fontWeight: 400,
                    fontFamily: "PlayfairDisplay-Bold",
                    marginLeft: 5,
                    textDecorationLine: "underline",
                  }}
                >
                  Log In
                </Text>
              </TouchableOpacity>
            </Text>

            <Text
              style={{
                fontSize: 18,
                color: "#000000",
                lineHeight: 22,
                fontFamily: "PlayfairDisplay-Regular",
                display: "block",
              }}
            >
              &nbsp; or
              <TouchableOpacity
                onPress={() => {
                  props.pushStoryNudgeInteractionToDataLayer(props.postData, "explore subscription plans", "monthly plan", isOverseasUser ? 3: props.locationInfo?.countryInfo?.code !== "IN" ? 8: 240)
                  props.navigateToScreen("/pricing?header=true")
                }
              }
              >
                <Text
                  style={{
                    fontWeight: 400,
                    fontFamily: "PlayfairDisplay-Bold",
                    marginLeft: 5,
                    textDecorationLine: "underline",
                  }}
                >
                  Explore subscription plans
                </Text>
              </TouchableOpacity>
            </Text>
          </View>
        </View>
      );
    } else {
      return <View></View>;
    }
  };

  return (
    <View style={styles.centeredView}>
      <Modal animationType="slide" transparent={true} visible={modalVisible}>
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: "flex-end",
          }}
          activeOpacity={1}
          onPressOut={() => {
            setModalVisible(false);
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <TouchableWithoutFeedback>
              <View
                style={[
                  {
                    minHeight: windowHeight * 0.1,
                    marginTop: "auto",
                    backgroundColor: "#FFF",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    zIndex: 9999,
                    paddingHorizontal: 56,
                    paddingTop: 54,
                    paddingBottom: 20,
                    cursor: "default",
                    position: "relative",
                  },
                ]}
              >
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    backgroundColor: "#0d7680",
                    height: 40,
                    marginBottom: 30,
                    textAlign: 'center'
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#ffffff",
                      fontWeight: 600,
                      letterSpacing: "0.015em",
                      lineHeight: 40,
                      marginLeft: 50,
                      marginRight: 0,
                    }}
                  >
                    <CustomImage
                      source={lockIcon}
                      require={true}
                      style={{ width: 25, height: 25 }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        marginTop: 0,
                        verticalAlign: "bottom",
                        marginBottom: 4,
                        marginRight: 9,
                      }}
                    />
                    {props.getModalHeaderText()}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {getContentView()}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );

  function getContentView() {
    if (props.type == "non_privileged") {
      return (
        <View style={{ width: "100%" }}>
          {props.getModalContentHeader(props.modalText, isOverseasUser)}
          {props.getModalSubText(isOverseasUser)}
          {props.getSubscribeComponent()}
          {getLoggedOutUserContent()}
        </View>
      );
    } else {
      return (
        <View style={{ width: "100%" }}>
          {props.getModalContentHeader(props.modalText, isOverseasUser)}
          {props.getModalSubText(isOverseasUser)}
          {props.getSubscribeComponent()}
          {getLoggedOutUserContent()}
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#98B3B7",
    justifyContent: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerText: {
    color: "black",
    fontSize: 18,
    padding: 26,
  },
  noteHeader: {
    backgroundColor: "#42f5aa",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
  },
  footer: {
    flex: 1,
    backgroundColor: "#ddd",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  textInput: {
    alignSelf: "stretch",
    color: "black",
    padding: 20,
    backgroundColor: "#ddd",
    borderTopWidth: 2,
    borderTopColor: "#ddd",
  },
  addButton: {
    position: "absolute",
    zIndex: 11,
    right: 20,
    top: 20,
    backgroundColor: "#98B3B7",
    width: 40,
    height: 40,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    elevation: 8,
  },
  addButtonText: {
    color: "#fff",
    fontSize: 18,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    flex: 1,
    justifyContent: "flex-end",
  },
  centeredView: {
    position: "absolute",
    top: 100,
  },
});

export default CommonNudgeWeb;
