import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, Helpers, appStyles, pageCover, pageContent, FontFamily, button, FontWeight, Colors } from '../../styles/appStyles';
import { connect } from 'react-redux';

export class UserInactiveCheck extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.setState({
            userStatus: this.props?.user?.userStatus
        });
        this.props.setUserInactive(this.props?.user?.userStatus == 'inactive');
    }

    componentDidUpdate = () => {
        if (this.state.userStatus != this.props?.user?.userStatus) {
            this.setState({
                userStatus: this.props?.user?.userStatus
            });
            this.props.setUserInactive(this.props?.user?.userStatus == 'inactive');
        }
    }

    render() {
        return (
            <></>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

export default connect(mapStateToProps)(UserInactiveCheck);