import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers } from '../../styles/appStyles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';

export class CustomImage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() {
        const { style, source, webStyle, resizeMode, require, className, lazyLoad, altText, srcSet, onClick = ()=>{} } = this.props;

        let frame = {};
        frame = webStyle;

        // let srcSetProp, srcSetPropImg = {};
        // if (srcSet) {
        //     srcSetProp = {
        //         srcSet: `${srcSet['3x']} 1x, ${srcSet['2x']} 2x, ${srcSet['1x']} 3x`
        //     }
        //     srcSetPropImg = {
        //         srcset: `${srcSet['3x']} 1x, ${srcSet['2x']} 2x, ${srcSet['1x']} 3x`
        //     }
        // }

        return (
            <>
                {process.browser ?
                    lazyLoad ?
                        <div onClick={onClick}>
                            <LazyLoadImage
                                style={frame}
                                effect="blur"
                                src={source}
                                alt={altText ? altText : ''}
                                title={altText ? altText : ''}
                                // {...srcSetProp}
                            />
                        </div>
                        :
                        <img onClick={onClick} style={frame} alt={altText ? altText : ''} title={altText ? altText : ''} className={className} src={source} />
                    :
                    <img onClick={onClick} style={frame} alt={altText ? altText : ''} title={altText ? altText : ''} className={className} src={source} />
                }
            </>
        );
    }
}

export default CustomImage;