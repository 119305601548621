import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, article, pageCover, FontFamily, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const imageOverlay = getImagePath('img/imgeOverlay.png');
export class PageCover extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
    }

    render() {
        const { title, coverImage, content, topRightContent, pageCoverOverlay, require, lazyLoad, category, blog, company, darkMode } = this.props;
        let ShowImageOverlay = pageCoverOverlay ? true : false;
        return (
            <View style={[Padding.ph_2, Helpers.conatinerWeb, category && helpers.isDesktop() && Helpers.conatinerWebFull, { marginBottom: helpers.isDesktop() ? 60 : 30 }]}>
                <View style={[Flex.row, Flex.justifyBetween, Flex.alignCenter, helpers.isDesktop() && Margin.mb_4, { flexDirection: 'column' }]}>
                    {title && <View style={[Flex.fill]}>
                        <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, { marginBottom: helpers.isDesktop() ? 0 : 10 }, blog && { color: '#907cff' }, company && !helpers.isDesktop() && { marginTop: 10 }, !helpers.isDesktop() && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>{title}</Text>
                    </View>
                    }
                    <View>
                        {topRightContent}
                    </View>
                </View>
                {coverImage &&
                    <View style={[Margin.mb_3, { position: 'relative' }, !helpers.isDesktop() && { marginTop: 10, marginBottom: 20 }]}>
                        <CustomImage
                            source={coverImage}
                            require={require}
                            style={{
                                width: '100%',
                                height: 295,
                            }}
                            webStyle={{
                                width: '100%',
                                height: helpers.isDesktop() ? 500 : 295,
                                objectFit: 'cover'
                            }}
                            resizeMode={'cover'}
                            altText={title}
                            lazyLoad={lazyLoad ? true : false}
                        />
                        {ShowImageOverlay &&
                            <CustomImage
                                source={imageOverlay}
                                require={true}
                                style={{
                                    width: '100%',
                                    height: 295,
                                    position: 'absolute',
                                    bottom: 0,
                                }}
                                webStyle={{
                                    width: '101%',
                                    height: helpers.isDesktop() ? 500 : 295,
                                    position: 'absolute',
                                    bottom: -1,
                                    left: 0,
                                    right: 0
                                }}
                                resizeMode={'cover'}
                            />
                        }
                    </View>
                }
                {content != '' &&
                    <View>
                        <Text style={[pageCover.content, helpers.isDesktop() && pageCover.contentDesktop, company && { fontSize: helpers.isDesktop() ? 24 : 16, paddingTop: helpers.isDesktop() ? 0 : 10 }, darkMode && { color: 'rgba(255,255,255,0.87)' }]}>{content}</Text>
                    </View>
                }
            </View>
        );
    }
}

export default PageCover;