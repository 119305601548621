import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, testimonialStyl, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import Slider from '../slider';
import PageBreak from '../page-break';
import { helpers } from '../../utils/helpers';

export class Testimonial extends Component {
    constructor(props) {
        super(props);
        let testimonials = this.sortTestimonials(this.props.data);
        this.state = {
            testimonials: testimonials
        }
    }

    componentDidMount = () => {

    }

    sortTestimonials = (testimonials) => {
        testimonials.sort((a, b) => a.order - b.order);
        return testimonials;
    }

    render() {
        const { testimonials } = this.state;
        let { singlePost, darkMode } = this.props;
        return (
            <View>

                {
                    helpers.isDesktop() ? <View>
                        <Text style={[Helpers.textCenter, Typo.h3_M, helpers.isDesktop() && Typo.h3_MDesktop, Margin.mb_1, { fontSize: helpers.isDesktop() ? 34 : 18, color: '#000' }, { fontFamily: FontFamily.abrilFatface }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>Reader’s quotes</Text>
                        <PageBreak size={'small'} style={[Margin.mb_2]}  darkMode={darkMode}  />
                    </View>
                        :
                        <View style={[Flex.alignCenter, Margin.mb_3, { marginRight: -20, marginLeft: -20 }]}>
                            <View style={[Padding.ph_2, Helpers.titleBgWrap, singlePost && { backgroundColor: '#f5f3ff' },  darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                <View style={[Helpers.borderBottom, darkMode && {borderBottomColor: 'rgba(255, 255, 255, 0.6)'}]}>
                                    <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 34 : 20, color: '#000' }, { fontFamily: FontFamily.abrilFatface,color: '#000' }, darkMode && {color: 'rgba(255, 255, 255, 0.89)', borderColor: 'rgba(255, 255, 255, 0.6)'}]}>Reader’s quotes</Text>
                                </View>
                            </View>
                            <View style={[Helpers.titleLine, { backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.89)' : 'rgba(0, 0, 0, 0.6)' }]}></View>
                        </View>
                }
                <Text style={[Helpers.textCenter, Typo.descText, helpers.isDesktop() ? Margin.mb_4 : Margin.mb_2, { fontSize: helpers.isDesktop() ? 18 : 16 }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>What our reader’s love about TMC.</Text>

                <Slider data={testimonials} darkMode={darkMode} />
            </View>
        );
    }
}

export default Testimonial;