import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  ScrollView,
  PermissionsAndroid,
  Platform,
  TextInput,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Colors,
  FontFamily,
  FontWeight,
  button,
  modalStyle,
  Typo,
  formStyle,
  unlockBox,
} from "../../styles/appStyles";
import Modal from "../modal";
import UploadFile from "../upload-file";
import FileCard from "../../components/file-card";
import { helpers } from "../../utils/helpers";
import docHelpers from "../../utils/download-doc";
import ButtonGradient from "../../components/button-gradient";
import apiHelpers from "../../utils/api-helpers";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import { ProgressCircle } from "react-native-material-indicators";
import ImageViewer from "../image-viewer";
import PdfViewer from "../pdf-viewer";
import CustomCamera from "../custom-camera";
import ContentLoader from "react-native-easy-content-loader";

const windowHeight = Dimensions.get("window").height;

const closeIcon = getImagePath("icons/close-round.png");

const themeIconOne = getImagePath("icons/theme_icon_one.png");
const themeIconTwo = getImagePath("icons/theme_icon_two.png");
const dummyEmail = getImagePath("icons/dummyEmail.png");

const emailBanner = getImagePath("icons/giftEmailBanner.png");
const emailQuote = getImagePath("icons/emailQuote.png");
const emailQuoteSecond = getImagePath("icons/emailQuoteSecond.png");
const annualGiftSub = getImagePath("icons/annualGiftSub.png");
const creditGiftIcon = getImagePath("icons/gift_pl_one.png");
const tmcLogoWithText = getImagePath("icons/tmc_logo_with_text.png");
const referralGiftImage = getImagePath("img/referral-gift.svg");

const maxMessageLength = 500;

class GiftSubModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: "",
      btnDisabled: true,
      enableLoader: false,
      emailThemes: [],
      selectedTheme: null,
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      giftMsgErrorMsg: false,
      senderName: ""
    };
  }

  componentDidMount = () => {
  };

  componentDidUpdate = () => {};

  componentWillUnmount() {}

  closeModal = () => {
    if(window.location.search && window.location.search.includes('show_gift_modal=true')){
      window.history.replaceState({}, document.title, window.location.pathname)
      window.location.reload()
      this.props.closeGiftSubModalHome()
    }
    this.props.closeGiftSubModal();
  };

  closeModalNativeModal = () => {};

  setEmailTheme = (theme) => {
    this.setState({ selectedTheme: theme });
  };

  handleOnChange = (key, value) => {
    if (key == "message" && value.length > maxMessageLength) {
      this.setState({
        giftMsgErrorMsg: "Cannot enter a message over 500 characters",
      });
      return;
    }
    this.setState({ [key]: value, errorMsg: false, giftMsgErrorMsg: false });
  };

  validateForm = () => {
    let { email } = this.state;
    if (!helpers.validateEmail(email)) {
      this.setState({ errorMsg: "Please enter valid Email Id" });
      return;
    }

    this.validateGift();
  };

  validateGift = () => {
    this.setState({ enableBtnLoader: true });

    let { email, firstName, lastName, senderName } = this.state;
    let { token } = this.props;
    let endpoint = `/gift-subscription`;
    let data = {
      receiver: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        sender_name: senderName
      }
    };

    apiHelpers
      .apiRequest(endpoint, data, "POST", true, token)
      .then((res) => {
        if (res.success && res.code == "sub_link_created") {
          if(this.props.setGiftSuccess){
            this.props.setGiftSuccess({...data, first_name: firstName});
          }
          // this.props.closeGiftSubModal();
          this.closeModal()
        } else {
          this.setState({ enableBtnLoader: false, errorMsg: res.message });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          enableBtnLoader: false,
          errorMsg: "Something unexpected happend. Please try again",
        });
      });
  };

  renderDummyLoader = () => {
    let darkMode = this.props.darkMode;
    return (
      <>
        <View style={{ paddingHorizontal: 10, marginBottom: 20 }}>
          <View
            style={{
              height: 10,
              width: "100%",
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: "100%",
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: 150,
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 0,
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: darkMode ? "#434343" : "#f5f5f5",
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: "row",
          }}
        >
          <View style={{ width: "33%", alignItems: "center" }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 8,
              }}
            ></View>
            <View
              style={{
                height: 12,
                width: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 0,
              }}
            ></View>
          </View>
          <View style={{ width: "33%", alignItems: "center" }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 8,
              }}
            ></View>
            <View
              style={{
                height: 12,
                width: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 0,
              }}
            ></View>
          </View>
          <View style={{ width: "33%", alignItems: "center" }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 8,
              }}
            ></View>
            <View
              style={{
                height: 12,
                width: 50,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 5,
              }}
            ></View>
            <View
              style={{
                height: 10,
                width: 60,
                backgroundColor: darkMode
                  ? "#2D302D"
                  : "rgba(196, 196, 196, 0.6)",
                marginBottom: 0,
              }}
            ></View>
          </View>
        </View>
        <View
          style={{ paddingHorizontal: 10, marginBottom: 20, marginTop: 20 }}
        >
          <View
            style={{
              height: 10,
              width: "100%",
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: 100,
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              height: 10,
              width: 150,
              backgroundColor: darkMode
                ? "#2D302D"
                : "rgba(196, 196, 196, 0.6)",
              marginBottom: 0,
            }}
          ></View>
        </View>
      </>
    );
  };

  renderSubscriptionModalContent = () => {
    let {
      enableLoader,
      emailThemes,
      selectedTheme,
      firstName,
      lastName,
      email,
      message,
      errorMsg,
      enableBtnLoader,
      giftMsgErrorMsg,
    } = this.state;
    let { subscriptionData, token } = this.props;
    let darkMode = this.props.darkMode;
    return (
      <>
        {enableLoader ? (
          <View
            style={{
              position: "relative",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: darkMode ? Colors.darkBlackColorBg1 : "#ffffff",
              zIndex: 999,
            }}
          >
            <View
              style={[
                Flex.justfiyCenter,
                Flex.alignCenter,
                helpers.isWeb() && {
                  height: "calc(100vh - 189px)",
                },
                {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: darkMode
                    ? Colors.darkBlackColorBg1
                    : "#ffffff",
                },
                !helpers.isWeb() && { height: Dimensions.get("window").height },
              ]}
            >
              <View style={{ marginTop: !helpers.isWeb() ? -20 : 0 }}>
                <ProgressCircle
                  color={"#907cff"}
                  size={186}
                  widthOfBorder={25}
                />
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{ flexDirection: helpers.isDesktop() ? "row" : "column" }}
          >
            <View
              style={{
                width: helpers.isDesktop() ? "60%" : "100%",
                paddingRight: helpers.isDesktop() ? 40 : 20,
                paddingLeft: helpers.isDesktop() ? 40 : 20,
                paddingTop: 40,
                paddingBottom: 40,
                backgroundColor: darkMode ? Colors.darkBlackColor1 : "#ffffff",
              }}
            >
              <View
                style={[
                  Margin.mb_4,
                  !helpers.isDesktop() && Margin.mb_2,
                  { alignItems: helpers.isDesktop() ? "flex-start" : "center" },
                ]}
              >
                <View
                  style={[
                    Helpers.borderBottom,
                    darkMode && { borderBottomColor: "rgba(255,255,255,0.6)" },
                  ]}
                >
                  <Text
                    style={[
                      Helpers.textLeft,
                      Typo.h3_R,
                      helpers.isDesktop() && Typo.h3_RDesktop,
                      Margin.mb_0,
                      { fontSize: 18 },
                      {
                        fontFamily: FontFamily.abrilFatface,
                        color: "#000",
                        textTransform: "capitalize",
                      },
                      { fontSize: helpers.isDesktop() ? 34 : 20 },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {"One free month for you and your friends"}
                  </Text>
                </View>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: helpers.isDesktop() ? 20 : 16,
                    fontFamily: helpers.isWeb()
                      ? FontFamily.regular
                      : FontFamily.bold,
                    color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
                    marginBottom: 20,
                    fontWeight: helpers.isWeb() ? "600" : "700",
                  }}
                >
                  Let us send the invite for you
                </Text>
                <View
                  style={{
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={[
                      Margin.mb_2,
                      formStyle.inputIconWrap,
                      {width: '100%'}
                    ]}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: "PlayfairDisplay-Bold",
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 8,
                        fontWeight: 600
                      }}
                    >
                      FROM
                    </Text>
                    <TextInput
                      placeholder={"Your Name"}
                      placeholderTextColor={[Colors.placeholderTextColor]}
                      style={[
                        formStyle.inputBorderBox,
                        darkMode && {
                          color: Colors.lightWhite,
                          borderColor: Colors.lineColor1,
                        },
                      ]
                    }
                      value={this.state.senderName}
                      onChangeText={(value) =>
                        this.handleOnChange("senderName", value)
                      }
                    />
                  </View>
                </View>
                <View style={{width: '10%', borderTopWidth: 1, marginBottom: 10}} />
                <View  
                style={[
                    formStyle.inputIconWrap,
                    {width: '100%'}
                  ]}>
                    <Text
                       style={{
                        fontSize: 16,
                        fontFamily: "PlayfairDisplay-Bold",
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 8,
                        fontWeight: 600
                      }}
                    >
                      TO
                    </Text>
                </View>
                <View
                  style={{
                    flexDirection: helpers.isDesktop() ? "row" : "column",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={[
                      Margin.mb_2,
                      formStyle.inputIconWrap,
                      { width: helpers.isDesktop() ? "48%" : "100%" },
                    ]}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.regular,
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 8,
                      }}
                    >
                      Recipient’s First Name *
                    </Text>
                    <TextInput
                      placeholder={""}
                      placeholderTextColor={[Colors.placeholderTextColor]}
                      style={[
                        formStyle.inputBorderBox,
                        darkMode && {
                          color: Colors.lightWhite,
                          borderColor: Colors.lineColor1,
                        },
                      ]}
                      value={firstName}
                      onChangeText={(value) =>
                        this.handleOnChange("firstName", value)
                      }
                    />
                  </View>
                  <View
                    style={[
                      Margin.mb_2,
                      formStyle.inputIconWrap,
                      { width: helpers.isDesktop() ? "48%" : "100%" },
                    ]}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: FontFamily.regular,
                        color: darkMode
                          ? Colors.lightWhite
                          : "rgba(0, 0, 0, 0.7)",
                        marginBottom: 8,
                      }}
                    >
                      Recipient’s Last Name *
                    </Text>
                    <TextInput
                      placeholder={""}
                      placeholderTextColor={[Colors.placeholderTextColor]}
                      style={[
                        formStyle.inputBorderBox,
                        darkMode && {
                          color: Colors.lightWhite,
                          borderColor: Colors.lineColor1,
                        },
                      ]}
                      value={lastName}
                      onChangeText={(value) =>
                        this.handleOnChange("lastName", value)
                      }
                    />
                  </View>
                </View>
                <View style={[Margin.mb_2, formStyle.inputIconWrap]}>
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: FontFamily.regular,
                      color: darkMode
                        ? Colors.lightWhite
                        : "rgba(0, 0, 0, 0.7)",
                      marginBottom: 8,
                    }}
                  >
                    Recipient’s Email id *
                  </Text>
                  <TextInput
                    placeholder={""}
                    placeholderTextColor={[Colors.placeholderTextColor]}
                    style={[
                      formStyle.inputBorderBox,
                      darkMode && {
                        color: Colors.lightWhite,
                        borderColor: Colors.lineColor1,
                      },
                    ]}
                    value={email}
                    onChangeText={(value) =>
                      this.handleOnChange("email", value)
                    }
                  />
                </View>

                <View style={{ width: '40%', marginTop: 20 }}>
                  <ButtonGradient
                    title={
                      "SEND"
                    }
                    rootStyle={{
                      btnWrapper: button.primaryGradient,
                      btnText: button.primaryGradientText
                    }}
                    onClick={() => {
                      this.validateForm();
                    }}
                    disable={!firstName || !lastName || !email}
                    fullWidth={false}
                    darkMode={darkMode}
                    leftAligned={true}
                    loader={enableBtnLoader}
                  />
                </View>
                <View style={{marginTop: 40}}>
                    <Text style={{color: '#F1443B', fontSize: 12, lineHeight: 20}}>
                        Note: An additional month will be added to your subscription only when the two gift claimants 
                        have claimed the gift and signed up for their free subscription.
                    </Text>
                </View>
                {errorMsg && (
                  <View>
                    <Text
                      style={[
                        Typo.h4,
                        Margin.mt_0,
                        Margin.mb_0,
                        unlockBox.errorText,
                      ]}
                    >
                      {errorMsg}
                    </Text>
                  </View>
                )}
              </View>
            </View>
            <View
              style={{
                width: helpers.isDesktop() ? "40%" : "100%",
                backgroundColor: darkMode ? Colors.darkBlackColor1 : "#fafafa",
                paddingRight: helpers.isDesktop() ? 40 : 20,
                paddingLeft: helpers.isDesktop() ? 40 : 20,
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Text
                style={{
                  fontSize: helpers.isDesktop() ? 20 : 16,
                  fontFamily: helpers.isWeb()
                    ? FontFamily.regular
                    : FontFamily.bold,
                  color: darkMode ? Colors.lightWhite : "rgba(0, 0, 0, 0.7)",
                  marginBottom: helpers.isDesktop() ? 10 : 20,
                  textAlign: helpers.isDesktop() ? "left" : "center",
                  fontWeight: helpers.isWeb() ? "600" : "700",
                }}
              >
                Preview your invite email here
              </Text>
              <View
                style={[
                  {
                    borderWidth: 1,
                    borderColor: "#6F6F6F",
                    backgroundColor: darkMode ? "#121212" : "#fafafa",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20
                  }
                ]}
              >
                <CustomImage
                  source={tmcLogoWithText}
                  style={{ width: "30%", height: 122, resizeMode: "cover" }}
                  webStyle={{
                    width: "30%",
                    height: "auto",
                    objectFit: "contain",
                    marginTop: 20
                  }}
                />
                <CustomImage
                  source={referralGiftImage}
                  style={{ width: "50%", height: 122, resizeMode: "cover" }}
                  webStyle={{
                    width: "50%",
                    height: "auto",
                    objectFit: "contain",
                    marginTop: 40
                  }}
                />
                <View
                  style={[
                    {
                      paddingBottom: 20,
                      paddingTop: 20,
                      paddingRight: 10,
                      paddingleft: 10,
                      maxWidth: 306,
                      marginLeft: "auto",
                      marginRight: "auto",
                    },
                    !subscriptionData?.selectedSubscribtion?.is_story_gift && {
                      alignItems: "center",
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: FontFamily.regular,
                      fontWeight: helpers.isWeb() ? "600" : "700",
                      color: darkMode ? Colors.lightWhite : "#000000",
                      lineHeight: 27,
                      marginBottom: 10,
                      textAlign: 'center'
                    }}
                  >
                    {subscriptionData?.selectedSubscribtion?.is_story_gift
                      ? "Hey"
                      : "Hey"}{" "}
                    {firstName ? `${firstName},` : ""}
                  </Text>
                    <Text
                      style={{
                        fontSize: 13,
                        fontFamily: FontFamily.regular,
                        color: darkMode ? Colors.lightWhite : "#000000",
                        lineHeight: 20,
                        textAlign: 'center'
                      }}
                    >
                      {this.state.senderName} gifted you a 30-day free subscription to The Morning Context.
                    </Text>
                </View>

                {true && (
                  <View
                    style={{
                      paddingHorizontal: 10,
                      marginBottom: 20,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        height: 10,
                        width: "100%",
                        backgroundColor: darkMode
                          ? "#2D302D"
                          : "rgba(196, 196, 196, 0.6)",
                        marginBottom: 5,
                      }}
                    ></View>
                  </View>
                )}

                <View
                  style={{
                    marginBottom: 25,
                    paddingLeft: helpers.isDesktop() ? 0 : 15,
                    paddingRight: helpers.isDesktop() ? 0 : 15,
                  }}
                >
                  <View style={{ alignItems: "center" }}>
                    <View
                      style={{
                        height: 46,
                        width: 200,
                        backgroundColor: darkMode ? "#434343" : "#C4C4C4",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                    </View>
                  </View>
                </View>


                {true &&
                  this.renderDummyLoader()}
              </View>
            </View>
          </View>
        )}
      </>
    );
  };

  render() {
    let darkMode = this.props.darkMode;
    return (
      <View
        style={[
          { zIndex: 99999 },
          !helpers.isWeb() && {
            height: "100%",
            backgroundColor: darkMode ? Colors.darkBlackColor1 : "#ffffff",
          },
        ]}
      >
        {helpers.isWeb() ? (
          <Modal
            renderContent={() => this.renderSubscriptionModalContent()}
            closeModal={() => {
              this.closeModal()
            }}
            paddingHorizontal={true}
            topPadding={false}
            giftCardModal={true}
          />
        ) : (
          <>
            <View
              style={[{ position: "absolute", right: 0, top: 0, zIndex: 1, borderWidth: 1 }]}
            >
              <TouchableOpacity
                activeOpacity={0.8}
                style={[modalStyle.closeButton]}
                onPress={() =>{
                  this.closeModal()
                }}
              >
                <CustomImage
                  source={closeIcon}
                  style={{ width: 28, height: 28 }}
                  webStyle={{ width: 28, height: 28 }}
                  require={true}
                />
              </TouchableOpacity>
            </View>
            <ScrollView
              ref={(ref) => (this.appscrollview = ref)}
              style={[{ flex: 1 }]}
            >
              {this.renderSubscriptionModalContent()}
            </ScrollView>
          </>
        )}
      </View>
    );
  }
}

export default GiftSubModal;
