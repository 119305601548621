import React, { Component } from "react";
import { Text, View, ActivityIndicator, TouchableOpacity } from "react-native";
import {
    postSingle,
    Flex,
    Padding,
    Margin,
    Helpers,
    aboutAuthor,
    pageContent,
    input,
    Colors,
    bannerStyles,
    Messages,
    FontFamily,
    FontWeight,
    button,
    Typo,
    appHeader,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";

import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import ButtonGradient from "../button-gradient";
import Button from "../button";
import CustomImage from "../custom-image";
const closeIcon = getImagePath("icons/close-icon.png");
const closeBoxIcon = getImagePath("icons/close-round.png");
const closeIconWhite = getImagePath("icons/close-icon-white.png");

export class GeneralNotificationBand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationText: this.props.notificationText,
            redirectionPath: this.props.redirectionPath,
            ctaText: this.props.ctaText,
            bgColor: this.props.backgroundColor,
            buttonLoader: this.props.buttonLoader ? this.props.buttonLoader : false,
            btnDisable: true,
            closeGeneralnotification: false,
            email: "",
            referralMsg: {
                enable: false,
                type: "", // error / success
                msg: "",
            },
        };
    }

    componentDidMount = () => { };
    closefunction = () => {
        this.setState({ closeGeneralnotification: true });
    };
    render() {
        let { notificationText, redirectionPath, ctaText, bgColor } = this.state;
        let webView = helpers.isWeb();
        let { darkMode } = this.props;
        let buttonLoader = this.props.buttonLoader;

        let btnClick;
        if (webView) {
            btnClick = this.props.ctaHandle
                ? {
                    handleClick: this.props.ctaHandle,
                }
                : {
                    to: redirectionPath,
                };
        } else {
            btnClick = {
                onPress: () => this.props.navigateApp(redirectionPath),
            };
        }

        return (
            <>
                {this.state.closeGeneralnotification !== true && notificationText && (
                    <View
                        style={{
                            backgroundColor: bgColor,
                            paddingVertical: helpers.isDesktop() ? 10 : 10,
                            paddingLeft: helpers.isDesktop() ? 45 : 10,
                            paddingRight: helpers.isDesktop() ? 45 : 20,
                            // flexDirection: helpers.isDesktop() ? "row" : "column",
                            alignItems: "center",
                            position: "relative",
                        }}
                    >
                        <View
                            style={{
                                marginLeft: "auto",
                                marginRight: helpers.isDesktop() ? "auto" : "auto",
                            }}
                        >
                            <Text
                                style={[
                                    Helpers.textCenter,
                                    helpers.isDesktop() && Typo.h3_RDesktop,
                                    !helpers.isDesktop() && { paddingHorizontal: 10 },
                                    Margin.mb_0,
                                    { color: "#fff", fontFamily: FontFamily.medium, fontWeight: 500 },
                                    { fontSize: helpers.isDesktop() ? 18 : 13 },
                                    { lineHeight: helpers.isDesktop() ? 25 : 18 },
                                    !helpers.isWeb() && { marginBottom: 1 },
                                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                                ]}
                            >
                                {notificationText}

                                {helpers.isDesktop() ? (
                                    <TouchableOpacityLink style={{
                                        marginLeft: 10,
                                    }}
                                        {...btnClick}>
                                        {buttonLoader ? (
                                            <ActivityIndicator
                                                color={"#FFFFFF"}
                                                style={{
                                                    flex: 1,
                                                }}
                                            />
                                        ) : (
                                            <Text
                                                style={[
                                                    Helpers.textUppercase,
                                                    {
                                                        color: "#907CFF",
                                                        fontSize: 15,
                                                        fontFamily: FontFamily.regular,
                                                        fontWeight: "600",
                                                        whiteSpace: "nowrap",
                                                        backgroundColor: "#fff",
                                                        // borderWidth: 1,
                                                        // borderColor: "#907CFF",
                                                        paddingHorizontal: helpers.isDesktop() ? 10 : 30,
                                                        paddingVertical: helpers.isDesktop() ? 5 : 10,
                                                    },
                                                ]}
                                            >
                                                {ctaText}
                                            </Text>
                                        )}
                                    </TouchableOpacityLink>
                                ) : (
                                    <TouchableOpacityLink style={{
                                        marginLeft: 10,
                                    }}{...btnClick}>
                                        {buttonLoader ? (
                                            <ActivityIndicator
                                                color={"#FFFFFF"}
                                                style={{
                                                    flex: 1,
                                                }}
                                            />
                                        ) : (
                                            <Text
                                                style={[
                                                    Helpers.textUppercase,
                                                    {
                                                        color: "#907CFF",
                                                        fontSize: 10,
                                                        fontFamily: FontFamily.medium,
                                                        fontWeight: FontWeight.regular,
                                                        whiteSpace: "nowrap",
                                                        backgroundColor: "#fff",
                                                        // borderWidth: 1,
                                                        // borderColor: "#907CFF",
                                                        paddingHorizontal: 8,
                                                        paddingVertical: 2,
                                                    },
                                                ]}
                                            >
                                                {ctaText}
                                            </Text>
                                        )}
                                    </TouchableOpacityLink>
                                )}
                            </Text>
                        </View>


                        {this.props.closeIconDisplay !== false && (
                            <TouchableOpacity
                                style={{
                                    padding: 5,
                                    marginRight: helpers.isDesktop() ? 20 : 7,
                                    position: "absolute",
                                    right: 0,
                                    top: helpers.isDesktop() ? "auto" : 4,
                                }}
                                activeOpacity={0.8}
                                onPress={this.closefunction}
                            >
                                <CustomImage
                                    source={closeIconWhite}
                                    require={true}
                                    style={{ width: 16, height: 16 }}
                                    webStyle={{ width: 16, height: 16 }}
                                />
                            </TouchableOpacity>
                        )}
                    </View>
                )}
            </>
        );
    }
}

export default GeneralNotificationBand;
