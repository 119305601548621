import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Picker,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  button,
  articleItem,
  FontFamily,
  articleLists,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import ScrollContainerView from "../scroll-container-view";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import SubscriptionPricingPlan from "../subscription-pricing-plan";
import PageBreak from "../page-break";
import CustomImage from "../custom-image";
import TouchableOpacityLink from "../touchable-opacity-link";

const viewMore = getImagePath("icons/view-more.png");
const checkIcon = getImagePath("icons/check.png");
const dotHorizontal = getImagePath("icons/purple-dots-b.png");
const dotVertical = getImagePath("icons/purple-dots-a.png");

export class HomeChooseSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, navigation, navigateWeb, darkMode, freeUser } = this.props;
    let btnClickTerms;
    if (helpers.isWeb()) {
      btnClickTerms = {
        to: "/terms-and-conditions",
      };
    } else {
      btnClickTerms = {
        onPress: () => navigation.navigate("/terms-and-conditions"),
      };
    }
    return (
      <View
        style={[
          { paddingBottom: 0 },
          helpers.isDesktop() && Margin.mt_7,
          { backgroundColor: darkMode ? Colors.darkBlackColor : "white" },
        ]}
      >
        <View
          style={[
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWebFull,
            helpers.isDesktop() && { marginTop: 0 },
            helpers.isDesktop() && { maxWidth: 1350, width: "1350px" },
          ]}
        >

          {helpers.isDesktop() && (
            <View
              style={[
                Flex.alignCenter,
                Padding.pt_4,
                Padding.pb_2,
                {
                  width: helpers.isWeb() ? "fit-content" : "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                },
              ]}
            >
              {helpers.isDesktop() && (
                <Text
                  style={[
                    {
                      fontSize: helpers.isDesktop() ? 34 : 20,
                      lineHeight: helpers.isDesktop() ? 40 : 24,
                      fontFamily: FontFamily.abrilFatface,
                      marginBottom: 5,
                      color: "#000000",
                    },
                    darkMode && { color: Colors.lightWhite },
                  ]}
                >
                  Choose your subscription
                </Text>
              )}
              {helpers.isDesktop() && (
                <PageBreak
                  style={{
                    width: "60%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  darkMode={darkMode}
                />
              )}
            </View>
          )}
          {/* <View style={[!helpers.isDesktop() && Padding.pb_2, Flex.alignCenter, !helpers.isDesktop() && Flex.alignStart]}>
                        <View style={[!helpers.isDesktop() && Padding.pb_2]}>
                            <Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 27 : 22, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 24 : 0, letterSpacing: 0.3 }, darkMode && { color: Colors.lightWhite }]}>Common benefits across all subscription plans</Text>
                        </View>
                        <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                            <CustomImage
                                source={checkIcon}
                                require={true}
                                style={{ width: 20, height: 19, resizeMode: 'contain', marginTop: 3, marginRight: 15 }}
                                webStyle={{ width: 20, height: 19, objectFit: 'contain', marginTop: 3, marginRight: 15 }}
                            /><Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3, color: '#000000' }, darkMode && { color: Colors.lightWhite }]}>Get access to our daily long reads as they’re published</Text>
                        </View>
                        <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                            <CustomImage
                                source={checkIcon}
                                require={true}
                                style={{ width: 20, height: 19, resizeMode: 'contain', marginTop: 3, marginRight: 15 }}
                                webStyle={{ width: 20, height: 19, objectFit: 'contain', marginTop: 3, marginRight: 15 }}
                            /><Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3, color: '#000000' }, darkMode && { color: Colors.lightWhite }]}>TMC’s paid newsletters: Things Change, Oversize, Friction and more</Text>
                        </View>
                        <View style={[Flex.row, Flex.alignStart, Margin.mr_2]}>
                            <CustomImage
                                source={checkIcon}
                                require={true}
                                style={{ width: 20, height: 19, resizeMode: 'contain', marginTop: 3, marginRight: 15 }}
                                webStyle={{ width: 20, height: 19, objectFit: 'contain', marginTop: 3, marginRight: 15 }}
                            /><Text style={[{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, marginBottom: helpers.isDesktop() ? 10 : 0, letterSpacing: 0.3, color: '#000000' }, darkMode && { color: Colors.lightWhite }]}>Gift stories to friends and family</Text>
                        </View>
                    </View> */}
          <View
            style={[
              Margin.mt_6,
              Margin.mb_4,
              !helpers.isDesktop() && { marginTop: 0, marginBottom: 0 },
            ]}
          >
            {helpers.isDesktop() ? (
              <View>
                <SubscriptionPricingPlan
                  freeUser={freeUser}
                  navigateWeb={navigateWeb}
                  homepage={true}
                  navigateScreen={(pushProp) =>
                    this.props.navigateScreenHistory(pushProp)
                  }
                  showSubscriptionPlan={(
                    subscribtionId,
                    subscribtion,
                    country
                  ) =>
                    this.props.showSubscriptionPlan(
                      subscribtionId,
                      subscribtion,
                      country
                    )
                  }
                  darkMode={darkMode}
                  showCreditPlan={true}
                  showExploreMore={true}
                  setCheckoutLoaderState={(loaderState) => this.props.setCheckoutLoaderState(loaderState)}
                  getCheckoutLoaderState={this.props.getCheckoutLoaderState}
                />
              </View>
            ) : (
              <View>
                <SubscriptionPricingPlan
                  freeUser={freeUser}
                  homepage={true}
                  navigateScreen={(pushProp) =>
                    this.props.navigateScreenHistory(pushProp)
                  }
                  showSubscriptionPlan={(
                    subscribtionId,
                    subscribtion,
                    country
                  ) =>
                    this.props.showSubscriptionPlan(
                      subscribtionId,
                      subscribtion,
                      country
                    )
                  }
                  darkMode={darkMode}
                  showCreditPlan={true}
                  showExploreMore={true}
                  setCheckoutLoaderState={(loaderState) => this.props.setCheckoutLoaderState(loaderState)}
                  getCheckoutLoaderState={this.props.getCheckoutLoaderState}
                />
              </View>
            )}
          </View>
        </View>
        {helpers.isDesktop() && (
          <>
            {/* <CustomImage
                            source={dotVertical}
                            require={true}
                            style={{ width: 85, height: 88, resizeMode: 'contain', marginTop: 3, marginRight: 15, position: 'absolute', left: 0, top: 150 }}
                            webStyle={{ width: 85, height: 88, objectFit: 'contain', marginTop: 3, marginRight: 15, position: 'absolute', left: 0, top: 150 }}
                        />
                        <CustomImage
                            source={dotHorizontal}
                            require={true}
                            style={{ width: 77, height: 30, resizeMode: 'contain', marginTop: 3, marginRight: 15, position: 'absolute', right: 0, bottom: 100, zIndex: 9 }}
                            webStyle={{ width: 77, height: 30, objectFit: 'contain', marginTop: 3, marginRight: 15, position: 'absolute', right: 0, bottom: 100, zIndex: 9 }}
                        /> */}
          </>
        )}
      </View>
    );
  }
}

export default HomeChooseSubscription;
