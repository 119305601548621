import { StyleSheet } from "react-native"
import { helpers } from "../../native/utils/helpers"

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    readByDecisionMakersWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#FFF9F1'
    },
    ourReadersImageStyle: {
        width: '10vw',
        height: 'auto'
    },
    titleText: {
        fontSize: 48,
        fontWeight: 900,
        lineHeight: 72,
        fontFamily: 'PlayfairDisplay-Black',
        marginTop: '10vh'
    },
    subTitleText: {
        fontSize: 19,
        fontWeight: 400,
        lineHeight: 28.5,
        fontFamily: 'HaasGrotesk-Roman',
        marginTop: '5vh',
        width: '60%',
        alignSelf: 'center',
        textAlign: 'center',
        letterSpacing:1.5
    },
    journalismThatHelpsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFFFFF'
    },
    secondaryTitleText: {
        fontSize: 34,
        fontWeight: 700,
        lineHieght: 51,
        fontFamily: 'PlayfairDisplay-Bold',
        marginTop: '10vh'
    },
    statCardTextStyle: {
        fontSize: 19,
        fontWeight: 500,
        lineHeight: 28.5,
        fontFamily: 'HaasGrotesk-Medium'
    },
    readerStatsContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '2vh',
        width: '100%',
        paddingHorizontal: '5vw',
        marginTop: '5vh'
    },
    statPercentageStyle: {
        fontSize: 104.05,
        fontWeight: 900,
        fontFamily: 'PlayfairDisplay-Regular',
        lineHeight: 156.07,
        alignSelf: 'flex-end',
        opacity: 0.1
    },
    statSourceTextStyle: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 21,
        fontFamily: 'HaasGrotesk-Roman',
        letterSpacing: '0.3px',
        color: '#292929'
    },
    stateSourceContainer: {
        width: '100%',
        paddingLeft: '5vw',
        textAlign: 'left',
        marginTop: '2vh',
        marginBottom: '5vh'
    },
    whereAreReadersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF9F1'
    },
    topReaderCountriesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3vh',
        columnGap: '1vw',
        maxWidth: 375
    },
    topReaderCountriesCardContainer: {
        borderWidth: 1,
        borderColor: '#E7D6C9',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        columnGap: 2
    },
    topReaderCountryNameTextStyle: {
        fontSize: 16, 
        fontWeight: 500,
        lineHeight: 24
    },
    dataSourceTextStyle: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 21,
        fontFamily: 'HaasGrotesk-Roman',
        marginVertical: '5vh',
        color: '#292929'
    },
    ourReadersSectorWrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF9F1',
        paddingBottom: '5vh'
    },
    topSectorsCardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85%',
        flexDirection: 'row',
        columnGap: '1.5vw',
        marginTop: '5vh',
        rowGap: '2vh'
    },
    topSectorCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '1vw',
        borderLeftWidth: 4,
        backgroundColor: 'white',
    },
    topSectorCardText: {
        width: 'auto',
        fontSize: 19,
        fontWeight: 400,
        lineHeight: 28.5,
        fontFamily: 'HaasGrotesk-Medium',
        alignSelf: 'center',
        textAlign: 'center',
        paddingVertical: '1vh',
        paddingRight: '2vw',
        paddingLeft: '1vw'
    },
    contactUsSectionWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF9F1',
        height: '48vh',
        paddingTop: '10vh',
        paddingBottom: '10vh'
    },
    contactUsTitleSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        height: '100%',
        paddingLeft: '10vw',
        rowGap: '1vh'
    },
    contactUsFormSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '50%'
    },
    getInTouchText: {
        fontSize: '34px',
        fontWeight: 700,
        lineHeight: '150%',
        fontFamily: 'PlayfairDisplay-Bold'
    },
    getInTouchSubText: {
        fontSize: '19px',
        fontWeight: 400,
        lineHeight: '150%', /* 28.5px */
        letterSpacing: '0.3px',
        fontFamily: 'HaasGrotesk-Roman'
    },
    container: {
        backgroundColor: "white",
        padding: helpers.isDesktop() ? 20 : 11,
        width: '90%'
      },
      formRow: {
        marginVertical: 14,
        display: "flex",
        flexDirection: helpers.isDesktop() ? "row" : "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        columnGap: "20px",
        rowGap: "20px",
      },
      formRowleft: {
        marginVertical: 14,
        display: "flex",
        flexDirection: helpers.isDesktop() ? "row" : "column",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        columnGap: "20px",
        rowGap: "20px",
      },
      formSingleRow: {
        marginVertical: 14,
        display: "flex",
        flexDirection: helpers.isDesktop() ? "row" : "column",
        justifyContent: "start",
        alignContent: "start",
        alignItems: "start",
        columnGap: "20px",
        rowGap: "20px",
      },
      formLabel: {
        fontWeight: "bold",
      },
      formInput: {
        borderWidth: 1,
        borderColor: "gray",
        padding: 5,
        flex: 1,
      },
      formItem: {
        // flexDirection: "column",
        // justifyContent: "space-between",
        // marginVertical: 10,
        width: helpers.isDesktop() ? "100%" : "100%",
        position: "relative",
        marginVertical: helpers.isDesktop() ? 8 : 12
      },
      formPicker: {
        borderWidth: 1,
        borderColor: "gray",
      },
      parentContainer: {
        margin: "0 auto",
        height: helpers.isDesktop() ? "auto" : "auto",
        width: helpers.isDesktop() ? "50%" : "96%",
        padding: helpers.isDesktop() ? 20 : 11,
        backgroundColor: "#ffffff",
        boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
        position: "relative",
      },
      formTitle: {
        fontFamily: "Merriweather-Regular",
        fontSize: helpers.isDesktop() ? "20px" : "16px",
        fontWeight: "400",
        color: "rgb(0, 0, 0)",
        textAlign: "center",
        marginTop: 12,
      },
      input: {
        fontSize: "16px",
        lineHeight: "32px",
        fontFamily: "Matteo-Regular",
      },
      formSelect: {
        fontSize: "16px",
        lineHeight: "32px",
        fontFamily: "Matteo-Regular",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
      },
      formError: {
        color: "#fc0808",
        position: "absolute",
        bottom: "-18px",
        fontFamily: "Matteo-Regular",
        fontSize: "13px",
      },
      formmainError: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#bd0707",
        paddingHorizontal: 10,
        paddingVertical: 10,
      },
      formmainerrorText: {
        color: "#ffffff",
        fontSize: "14px",
        lineHeight: "18px",
        fontFamily: "Matteo-Regular",
      },
})

export { styles }