import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, TextInput } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, button, formStyle, unlockBox, signUpPage, Colors, modalStyle, forgotPassword } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import SignUpForm from '../../components/sign-up-form';
import getImagePath from '../../utils/image-helper';
import CustomImage from '../../components/custom-image';
import AppHeaderView from '../../components/app-header-view';
import ButtonGradient from '../../components/button-gradient';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import apiHelpers from "../../utils/api-helpers";
import Cookies from 'universal-cookie';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";

const cookies = new Cookies();
const checkIcon = getImagePath('icons/check.png');
const starterIcon = getImagePath('icons/starter-cerdits.png');
const forgotPasswordImg = getImagePath('img/forgotPassword.png');
const closeIcon = getImagePath('icons/grey-close-in.png');


export class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        let email = null;
        let allParams = null;
        let webView = helpers.isWeb();
        let redirectUrl = null;
        if (webView && process.browser) {
            if (window.location.search.includes("redirect=") || window.location.search.includes("email=")) {
                let searchArr = window.location.search.replace("?", "").split("&");
                let search = null;
                searchArr.map((searchParam) => {
                    if (searchParam.includes("redirect=")) {
                        redirectUrl = searchParam.replace("redirect=", "");
                    }
                    if (searchParam.includes("email=")) {
                        email = searchParam.replace("email=", "");
                    }
                    return searchParam;
                });
            }
        } else if (!webView && this.props.route.params) {
            email = (this.props.route.params.email) ? this.props.route.params.email : null;
            redirectUrl = (this.props.route.params.redirect) ? this.props.route.params.redirect : null;
            allParams = this.props.route.params;
        }

        this.state = {
            email: email ? email : '',
            redirectUrl: redirectUrl ? redirectUrl : '/'

        }
    }

    resetPassword = () => {
        this.setState({ enableBtnLoader: true, emailError: null });

        const { email } = this.state;
        let emailID = helpers.validateEmail(email);

        if (emailID) {
            this.resetPasswordApiCall();
        }
        else {
            this.setState({ emailError: "Please enter a valid email", enableBtnLoader: false })
        }
    }

    resetPasswordApiCall = () => {
        let { email, redirectUrl } = this.state;
        let endpoint = `/request-reset-password`;
        let body = {
            account_email: email,
            redirect_url: redirectUrl
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success) {
                    this.setState({ enableBtnLoader: false, showResetSuccessView: true })
                }
                else {
                    let msg = res.message ? res.message : 'Something went wrong. Please try again.'
                    this.setState({ enableBtnLoader: false, emailError: msg });
                }
            })
            .catch((error) => {
                let msg = error && error.message ? error.message : 'Something went wrong. Please try again.'
                this.setState({ enableBtnLoader: false, emailError: msg });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    navigateBack = () => {
        if (helpers.isWeb()) {
            this.props.history.goBack();
        }
        else {
            this.props.navigation.goBack();
        }
    }

    renderForgotPassworForm() {
        let { email, enableBtnLoader, emailError } = this.state;
        let backToLoginProp = {};
        backToLoginProp = {
            onPress: this.navigateBack
        }
        return (
            <View style={[helpers.isDesktop() && Margin.mt_3, helpers.isDesktop() && Margin.mb_3, { paddingHorizontal: helpers.isDesktop() ? 50 : 0 }, { backgroundColor: 'white' }]}>
                <View style={[!helpers.isDesktop() && Padding.ph_2, helpers.isWeb() && Flex.fill, !helpers.isDesktop() && Padding.pt_5, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                    {/* {!helpers.isDesktop() && <TouchableOpacityLink {...backToLoginProp}><CustomImage
                        source={closeIcon}
                        require={true}
                        style={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                    /></TouchableOpacityLink>} */}
                    {/* {!helpers.isDesktop() &&
                        <TouchableOpacity activeOpacity={0.8} {...backToLoginProp}>
                            <Text style={[forgotPassword.link, Helpers.textCenter, { position: 'absolute', left: 0, top: -38, zIndex: 1 }]}>Back</Text>
                        </TouchableOpacity>} */}
                    <Text style={[forgotPassword.title, !helpers.isDesktop() && { textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Forgot your password?</Text>
                </View>
                <View style={[!helpers.isDesktop() && Padding.ph_2, helpers.isDesktop() && { maxWidth: 400 }, !helpers.isWeb() && {height: '100%'}]}>
                    <Text style={[forgotPassword.text, Padding.pb_3, !helpers.isDesktop() && Padding.pt_4, { fontSize: helpers.isDesktop() ? 16 : 14, lineHeight: helpers.isDesktop() ? 20 : 18 }]}>Enter your email address you registered with TMC and we will send a link to reset your password</Text>
                    <View style={[Margin.mt_0]}>
                        <TextInput
                            placeholder={'Email id'}
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[formStyle.inputBoxStyle, emailError && formStyle.inputBoxError, { marginBottom: 22 }, emailError && { marginBottom: 0 }, { fontSize: helpers.isDesktop() ? 18 : 16 }]}
                            value={email}
                            onChangeText={(email) => this.setState({ email: email, emailError: false })}
                        />
                    </View>

                    {emailError && <View>
                        <Text style={[Typo.h4, Margin.mb_0, unlockBox.errorText]}>{emailError}</Text>
                    </View>
                    }

                    <View style={[Margin.mt_2]}>
                        <ButtonGradient
                            title={'Get a Reset Link'}
                            rounded={false}
                            fullWidth={true}
                            rootStyle={{
                                btnWrapper: [button.primaryGradient, { height: 50 }],
                                btnText: [button.primaryGradientText, { paddingHorizontal: helpers.isDesktop() ? 70 : 0, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
                            }}
                            onClick={() => this.resetPassword()}
                            disable={!email || enableBtnLoader}
                            loader={enableBtnLoader}
                        />
                    </View>
                    <View style={[Margin.mt_2, Flex.alignCenter]}>
                        <TouchableOpacity activeOpacity={0.8} {...backToLoginProp}>
                            <Text style={[forgotPassword.link, Helpers.textCenter]}>Back to Sign in</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }

    renderResetLink() {
        let { email } = this.state;
        let backToLoginProp = {};
        backToLoginProp = {
            onPress: this.navigateBack
        }
        return (
            <View style={[helpers.isDesktop() && Margin.mt_3, helpers.isDesktop() && Margin.mb_3, { paddingHorizontal: helpers.isDesktop() ? 50 : 0 }, { backgroundColor: 'white' }]}>
                {helpers.isWeb() ?
                    <View style={[!helpers.isDesktop() && Padding.ph_2, Flex.fill, !helpers.isDesktop() && Padding.pt_5, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        {!helpers.isDesktop() && <TouchableOpacityLink {...backToLoginProp}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        /></TouchableOpacityLink>}
                        <Text style={[forgotPassword.title, !helpers.isDesktop() && { paddingTop: 20, textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Reset link sent!</Text>
                    </View>
                :
                    <View style={[!helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && Padding.pt_2, !helpers.isDesktop() && { backgroundColor: '#FAFAFA', paddingBottom: 10 }]}>
                        <TouchableOpacityLink {...backToLoginProp}><View style={[Flex.alignEnd]}><CustomImage
                            source={closeIcon}
                            require={true}
                            style={{ width: 28, height: 28 }}
                            webStyle={{ width: 28, height: 28, position: 'absolute', right: 24, top: 15, zIndex: 1 }}
                        /></View></TouchableOpacityLink>
                        <Text style={[forgotPassword.title, !helpers.isDesktop() && { paddingTop: 20, textAlign: 'center' }, { fontSize: helpers.isDesktop() ? 30 : 24, lineHeight: helpers.isDesktop() ? 40 : 34, paddingBottom: helpers.isDesktop() ? 30 : 10 }]}>Reset link sent!</Text>
                    </View>
                }
                <View style={[!helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && Padding.pt_4, helpers.isDesktop() && { maxWidth: 400 }, !helpers.isWeb() && {height: '100%'}]}>
                    <Text style={[forgotPassword.text, Padding.pb_2, { fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 22 : 18 }]}>We've sent an email to <Text style={{ fontWeight: '600' }}>{email}</Text> with password reset instructions.</Text>
                    <Text style={[forgotPassword.text, Padding.pb_5, { fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 22 : 18 }]}>If the email doesn't show up soon, check your spam folder. We sent it from help@tmcsupport.com.</Text>
                    <View>
                        <ButtonGradient
                            title={'RETURN TO SIGN IN'}
                            rounded={false}
                            fullWidth={true}
                            rootStyle={{
                                btnWrapper: button.primaryGradient,
                                btnText: [button.primaryGradientText, { paddingHorizontal: helpers.isDesktop() ? 70 : 0, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
                            }}
                            onClick={() => this.navigateBack()}
                        />
                    </View>
                </View>
            </View>
        )
    }

    render() {
        const webView = helpers.isWeb();
        const desktopView = webView && helpers.isDesktop();
        let { showResetSuccessView } = this.state;
        let data = {
            pageName : 'Forgot Password'
        }

        return (
            <View style={{ height: '100%' }}>
                {(webView && process.browser && cookies.get("token") != undefined) && <Redirect to="/" />}
                {helpers.isDesktop() && <AppHeaderView />}

                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} />

                <View style={[signUpPage.wrapper, helpers.isDesktop() && Flex.justfiyCenter, { backgroundColor: desktopView ? '#E5E5E5' : 'white' }, helpers.isWeb() && { height: '100vh' }, helpers.isDesktop() && { paddingLeft: 25, paddingRight: 25, paddingTop: 70, paddingBottom: 10, flex: 1 }]}>
                    <View style={[Flex.row, webView && { maxWidth: '1400px', margin: desktopView ? 'auto' : 0, height: helpers.isDesktop() ? 530 : '100%' }]}>
                        <View style={[{ width: desktopView ? '50%' : '100%', backgroundColor: 'white' }, helpers.isDesktop() && Flex.justfiyCenter]}>

                         {showResetSuccessView ? this.renderResetLink() : this.renderForgotPassworForm()}

                        </View>
                        {desktopView &&
                            <>
                                <View style={{ backgroundColor: 'white', paddingTop: 10, paddingBottom: 3, zIndex: -1 }}><View style={[Margin.mt_2, Margin.mb_2, { borderLeftWidth: 1, borderColor: '#DEDEDE', height: '94%' }]} /></View>
                                <View style={[{ width: '50%', backgroundColor: 'white', paddingHorizontal: helpers.isDesktop() ? 45 : 0, zIndex: -1 }, Flex.justfiyCenter, Flex.alignCenter]}>
                                    <View style={[Flex.row, { alignItems: 'end' }]}>
                                        <CustomImage
                                            source={forgotPasswordImg}
                                            require={true}
                                            style={{ width: 400, height: 400, resizeMode: 'contain' }}
                                            webStyle={{ width: 400, height: 400, objectFit: 'contain' }}
                                        />
                                    </View>
                                </View>
                            </>
                        }
                    </View>
                </View>
            </View>
        );
    }
}

export default ForgotPassword;