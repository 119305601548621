import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, sliderStyle, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Cookies from "universal-cookie";
import TouchableOpacityLink from '../touchable-opacity-link';
import AuthenticationWrapper from '../authentication-wrapper';

const newsletterImg = getImagePath('img/things-change.png');

const windowWidth = Dimensions.get('window').width;

const cookies = new Cookies();

export class NewsletterSlider extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.appIntroSlider = React.createRef();
        this.checkScroll = true;
        const windowWidth = Dimensions.get('window').width;
        this.state = {
            activeSlide: 0,
            PlatformWeb: helpers.isDesktop(),
            slideItemWidth: windowWidth - 40,
            showToken: (!helpers.isWeb() || process.browser) ? true : false,
        }
    }

    componentDidMount() {
        if (helpers.isWeb()) {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    }

    componentWillUnmount() {
        if (helpers.isWeb()) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    handleResize = () => {
        setTimeout(() => {
            const windowWidth = Dimensions.get('window').width
            if (windowWidth < 992) {
                this.setState({
                    PlatformWeb: false,
                    slideItemWidth: windowWidth - 40,
                })
            } else {
                this.setState({
                    PlatformWeb: true,
                    slideItemWidth: windowWidth - 40,
                })
            }
        }, 200)
    }

    getSlideIndex = (offset, width) => {
        const { data } = this.props;
        let intervals = data.length;

        for (let i = 1; i <= intervals; i++) {
            let index = Math.round((width / intervals/2) * i);
            if (Math.round(offset) < index) {
                return i - 1;
            }
            if (i == intervals) {
                return i - 1;
            }
        }
    }

    getSlideOffset = (offset, width) => {
        if (this.checkScroll) {
            let slideIndex = this.getSlideIndex(offset, width);

            this.setState({
                activeSlide: slideIndex
            })
        }
    }

    prevSlide = () => {
        const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        //let slideLength = data.length;


        let totalSlideWidth = slideItemWidth - 80

        let totalWidth = Math.round(slideLength * totalSlideWidth);


        let scrollIndex;

        if (activeSlide > 2) {
            scrollIndex = Math.round(slideItemWidth * (activeSlide - 2));
        } else {
            scrollIndex = 0;
        }

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
    }

    nextSlide = () => {
        const { data } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        //let slideLength = data.length;

        let scrollIndex = Math.round(slideItemWidth * (activeSlide));

        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })

    }

    slideTo = (index) => {
        // index = index === 0 ? 0 : index+1;
        this.checkScroll = false;
        const { data } = this.props;
        const { slideItemWidth } = this.state;
        //let slideLength = data.length;

        let scrollIndex = Math.round(slideItemWidth * (index));
        this.scrollView.scrollTo({ x: scrollIndex, y: 0, animated: true })
        this.setState({ activeSlide: index }, () => {
            setTimeout(() => {
                this.checkScroll = true;
            }, 2000)
        })
    }

    render() {
        const { data, darkMode } = this.props;
        const { activeSlide, slideItemWidth } = this.state;
        //let intervals = data.length;
        let isWeb = helpers.isWeb();

        let dotClick;


        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                
                <ScrollView 
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled={true}
                    onScroll={data => {
                        this.getSlideOffset(data.nativeEvent.contentOffset.x, data.nativeEvent.contentSize.width)
                    }}
                    scrollEventThrottle={200}
                    decelerationRate="fast"
                    ref={ref => this.scrollView = ref}
                >
                    {
                        data && data.map((item, index) => {
                            return (
                                <View style={[{width: '100%'},Flex.row, Margin.mt_4, !helpers.isDesktop() && { width: '100%', margin: 'auto' }, !isWeb && { width: '100%', marginTop: 0 }]}>
                                  
                                    {
                                        item.map((letter) => {
                                            if (letter) {
                                                let imageProps;
                                                if (helpers.isWeb()) {
                                                    imageProps = {
                                                        to: "/newsletters/" + letter.slug
                                                    }
                                                }
                                                else {
                                                    imageProps = {
                                                        onPress: () => this.props.getNewsletterClick(letter.slug)
                                                    }
                                                }
                                                return (
                                                    <View style={[sliderStyle.sliderOuterItem, helpers.isDesktop() && sliderStyle.sliderOuterItemDesktop, { paddingTop: 25 }, helpers.isDesktop() && { maxWidth: '33.33%', flex:"0 0 33.33%", paddingLeft: 10, paddingRight: 10 }, !helpers.isDesktop() && { width: 150, marginRight: 10 }, !isWeb && { width: 155, marginRight: 5 }, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                                        <TouchableOpacityLink {...imageProps}>
                                                            <View style={[{ maxWidth: '100%' }, !isWeb && { width: 'auto', marginLeft: 'auto', marginRight: 'auto' }]}>
                                                                <View style={{display:"flex",flexFlow:"row"}}>
                                                                <CustomImage
                                                                    source={letter.logo}
                                                                    require={false}
                                                                    style={{ width: 150, height: "auto",maxWidth:"100%", resizeMode: 'contain' }}
                                                                    webStyle={helpers.isDesktop() ? { width: 240, height: 177, objectFit: 'contain' } : { width: 'auto', height: 134, objectFit: 'contain' }}
                                                                    altText={letter.name}
                                                                />
                                                                    <View style={{width:"calc(100% - 150px"}}>
                                                                        <Text>{letter.name}</Text>
                                                                        <Text>{letter.available}</Text>
                                                                        
                                                                    </View>
                                                                </View>
                                                                <Text>{letter.description}</Text>
                                                            </View>
                                                        </TouchableOpacityLink>
                                                    </View>
                                                )
                                            }
                                        })
                                    }
                                </View>
                            )
                        })
                    }
                </ScrollView>

                <View style={[sliderStyle.dotWrapper, { bottom: helpers.isDesktop() ? -15 : -15 }]}>
                    {data && data.length > 1 && data.map((item, index) => {
                        if (isWeb) {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        } else {
                            dotClick = {
                                onPress: (event) => {
                                    this.slideTo(index)
                                }
                            }
                        }
                        return (
                            <TouchableOpacity {...dotClick}>
                                <View key={`dot_${index}`} style={[sliderStyle.greyDot, (index) == activeSlide && sliderStyle.greyActiveDot]} />
                            </TouchableOpacity>
                        )
                    })}
                </View>
            </View>
        );
    }
}

export default AuthenticationWrapper(NewsletterSlider);
