import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const radioIcon = getImagePath('icons/radio_circle.png');
const radioSelectedIcon = getImagePath('icons/radio_circle_checked.png');

export class Radio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }

    render() {
        const { selected, modeOptions, darkModeOption } = this.props
        return (
            <>
                {modeOptions ?
                    <View>
                        {selected ?
                            <View style={{borderWidth: 2, borderColor: '#55C79A', width: 20, height: 20, borderRadius: 30}}>
                                <View style={{backgroundColor: '#55C79A', width: 12, height: 12, borderRadius: 12, margin: 2}} />
                            </View>
                        :
                            <View style={{borderWidth: 2, borderColor: darkModeOption ? '#FFFFFF' :  '#000000', width: 20, height: 20, borderRadius: 30}}></View>
                        }
                    </View>
                :
                    <View>
                        {selected ?
                            <CustomImage
                                require={true}
                                source={radioSelectedIcon}
                                style={{ width: 16, height: 16 }}
                                webStyle={{ width: 16, height: 16 }}
                            />
                            :
                            <CustomImage
                                require={true}
                                source={radioIcon}
                                style={{ width: 16, height: 16 }}
                                webStyle={{ width: 16, height: 16 }}
                            />
                        }
                    </View>
                }
            </>
        );
    }
}

export default Radio;