import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, StyleSheet } from 'react-native';
import { Flex, Padding, Margin, Helpers, unlockBoxPost } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';

const greenIcon = getImagePath('icons/unlockNoteIcon.png');
const lockIconBlue = getImagePath('icons/lockIconBlue.png');
const giftIconBlue = getImagePath('icons/giftIconBlue.png');
const unlockIconWhite = getImagePath('icons/unlockIconWhite.png');

export class UnlockBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
    }

    render() {
        let { bottomIcon, borderColor, backgroundColor, unlockData, text, darkMode } = this.props;
        let webView = helpers.isWeb();
        if (unlockData) {
            switch (unlockData.type) {
                case 'subscription':
                    borderColor = "#55C79A";
                    backgroundColor = "rgba(238, 249, 245, 0.5)";
                    bottomIcon = greenIcon;
                    text = <Text style={[unlockBoxPost.text, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 25 }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>As a <Text style={{ fontWeight: '700' }}>TMC subscriber</Text> you are part of the select group who has access to this story. Hope you are enjoying your subscription of TMC.</Text>
                    break;

                case 'credits':
                    borderColor = "#3AACFF";
                    backgroundColor = "rgba(235, 247, 255, 0.5)";
                    bottomIcon = lockIconBlue;
                    text = <Text style={[unlockBoxPost.text, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 25 }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>You have <Text style={{ fontWeight: '700' }}>unlocked</Text> this story using <Text style={{ fontWeight: '700' }}>{unlockData.data.credits} Credits.</Text> View our subscription plans to get access to all the exclusive content on TMC</Text>
                    break

                case 'gift':
                    borderColor = "#907CFF";
                    backgroundColor = "rgba(244, 242, 255, 0.5)";
                    bottomIcon = giftIconBlue;
                    text = <Text style={[unlockBoxPost.text, { fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 25 }, darkMode && {color: 'rgba(255, 255, 255, 0.89)'}]}>This story is exclusively available to TMC subscribers. You have been <Text style={{ fontWeight: '700' }}>gifted</Text> this story by "<Text style={{ fontWeight: '700' }}>{unlockData.data.gifted_by}</Text>".</Text>
                    break
            }
        }
        return (
            <View style={[unlockBoxPost.wrapper, { backgroundColor: darkMode ? '#1E1E1E' : backgroundColor, borderColor: borderColor }]}>
                <View style={[webView && unlockBoxPost.topBox, {
                    transform: [{ rotate: "-45deg" }], margin: 'auto'
                }, !webView && unlockBoxPost.topBoxMobile]}>
                    <View style={[unlockBoxPost.topBoxInner, { backgroundColor: borderColor }]}>
                        <View style={[unlockBoxPost.box, {
                            transform: [{ rotate: "45deg" }]
                        }]}>
                            <CustomImage
                                webStyle={{ width: 'auto', height: 33, position: 'relative', top: -2}}
                                style={{ width: 25, height: 33, margin:  'auto' }}
                                source={unlockIconWhite}
                                require={true}
                                resizeMode="contain"
                            />
                        </View>
                    </View>
                </View>
                <View>
                    {text}
                </View>
                <View style={[unlockBoxPost.bottomIcon]}>
                    <CustomImage
                        webStyle={{ width: 100, height: 100 }}
                        style={{ width: 100, height: 100 }}
                        source={bottomIcon}
                        require={true}
                        resizeMode="contain"
                    />
                </View>
            </View>
        );
    }
}

export default UnlockBox;
