import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Modal, TouchableOpacity, ActivityIndicator } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, Typo, button, FontFamily, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import ButtonGradient from '../button-gradient';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import Cookies from "universal-cookie";
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { Mixpanel } from '../../utils/mixpanel';
import ContentLoader from '../content-loader';
import AuthenticationWrapper from '../authentication-wrapper';

const americanexpressIcon = getImagePath('icons/cards/americanexpress.png');
const defaultIcon = getImagePath('icons/cards/default-card.png');
const dinersIcon = getImagePath('icons/cards/diners.png');
const discoverIcon = getImagePath('icons/cards/discover.png');
const jcbIcon = getImagePath('icons/cards/jcb.png');
const mastercardIcon = getImagePath('icons/cards/mastercard.png');
const visaIcon = getImagePath('icons/cards/visa.png');

const cookies = new Cookies();

export class PaymentInfo extends Component {
    cardRef = React.createRef();
    constructor(props) {
        super(props);
        if (this.props?.params?.subscription?.user_info?.billing_address?.country) {
            let countryCode = this.props.params.subscription.user_info.billing_address.country;
            if (window.chargebeeInit) {
                //Get instance
                if ((window.chargebeeInitCode == "IN" && countryCode != "IN") || (window.chargebeeInitCode != "IN" && countryCode == "IN")) {
                    window.location.reload();
                    return false;
                }
            } else {
                let chargebeeSiteIn = helpers.isWeb() ? process.env.CHARGEBEE_SITE_IN : config.CHARGEBEE_SITE_IN;
                let chargebeeApiEndpointIN = helpers.isWeb() ? process.env.CHARGEBEE_API_KEY_IN : config.CHARGEBEE_API_KEY_IN;
                let chargebeeSiteOt = helpers.isWeb() ? process.env.CHARGEBEE_SITE_OT : config.CHARGEBEE_SITE_OT;
                let chargebeeApiEndpointOT = helpers.isWeb() ? process.env.CHARGEBEE_API_KEY_OT : config.CHARGEBEE_API_KEY_OT;
                window.chargebeeInit = Chargebee.init({
                    site: (countryCode == "IN") ? chargebeeSiteIn : chargebeeSiteOt,
                    publishableKey: (countryCode == "IN") ? chargebeeApiEndpointIN : chargebeeApiEndpointOT
                });
                window.chargebeeInitCode = (countryCode == "IN") ? "IN" : "OTH";
            }
        }
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            paymentSource: this.props.params ? (this.props.params.payment_source ? this.props.params.payment_source : false) : false,
            planData: this.props.params ? (this.props.params.plan ? this.props.params.plan : false) : false,
            addCard: false,
            error: {
                card: false
            },
            cardComplete: false,
            styles: {
                base: {
                    fontWeight: '600',
                    fontFamily: 'Lato, BlinkMacSystemFont, Segoe UI, Helvetica Neue, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    borderColor: '#BCBCBC',
                    ':focus': {
                        color: '#000000',
                    },
                    '::placeholder': {
                        color: '#D8D8D8',
                    },

                    ':focus::placeholder': {
                        color: '#D8D8D8',
                    },
                }
            }
        }
    }

    componentDidMount = () => {
        const { isAdmin, referenceId } = this.props;
        if (this.state.pageLoader && isAdmin != true) {
            this.getUserProfile();
        }
        // if(helpers.isWeb()) {
        //     try {
        //         Mixpanel.track('click_payment_info');
        //     }
        //     catch(error) {
        //         console.log("mix panel error =>", error);
        //     }
        // }
    }

    getUserProfile = () => {
        const { token } = this.state;
        let endpoint = `/profile`;
        let body = {
            request_group: ["subscription"]
        }
        apiHelpers
            .apiRequest(endpoint, {}, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'profile_info') {
                    let subscription = (!res.data.subscription || JSON.stringify(res.data.subscription) == '{}') ? false : res.data.subscription;
                    if (subscription && subscription?.user_info?.billing_address?.country) {
                        if (helpers.isWeb()) {
                            let countryCode = subscription.user_info.billing_address.country;
                            if (window.chargebeeInit) {
                                //Get instance
                                if ((window.chargebeeInitCode == "IN" && countryCode != "IN") || (window.chargebeeInitCode != "IN" && countryCode == "IN")) {
                                    window.location.reload();
                                    return false;
                                }
                            } else {
                                let chargebeeSiteIn = helpers.isWeb() ? process.env.CHARGEBEE_SITE_IN : config.CHARGEBEE_SITE_IN;
                                let chargebeeApiEndpointIN = helpers.isWeb() ? process.env.CHARGEBEE_API_KEY_IN : config.CHARGEBEE_API_KEY_IN;
                                let chargebeeSiteOt = helpers.isWeb() ? process.env.CHARGEBEE_SITE_OT : config.CHARGEBEE_SITE_OT;
                                let chargebeeApiEndpointOT = helpers.isWeb() ? process.env.CHARGEBEE_API_KEY_OT : config.CHARGEBEE_API_KEY_OT;
                                window.chargebeeInit = Chargebee.init({
                                    site: (countryCode == "IN") ? chargebeeSiteIn : chargebeeSiteOt,
                                    publishableKey: (countryCode == "IN") ? chargebeeApiEndpointIN : chargebeeApiEndpointOT
                                });
                                window.chargebeeInitCode = (countryCode == "IN") ? "IN" : "OTH";
                            }
                        }
                    }
                    this.setState({
                        paymentSource: (res.data?.payment_source && res.data.payment_source.length != 0) ? res.data.payment_source[0] : false,
                        planData: subscription ? subscription.plan : false,
                        subscription: subscription,
                        pageLoader: false
                    });
                } else {
                    this.setState({
                        paymentSource: false,
                        planData: false,
                        subscription: false,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    paymentSource: false,
                    planData: false,
                    subscription: false,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    submitCard = () => {
        this.setState({
            error: {
                card: false
            },
            enableLoader: true,
        });

        const { token, cardComplete } = this.state;

        if (cardComplete) {
            this.getIntent();
        } else {
            this.setState({
                error: {
                    card: true
                },
                enableLoader: false
            })
        }
    }

    getIntent = () => {
        const { token, planData } = this.state;
        let endpoint = `/payment-intent`;
        let body = {
            save_card: true
        }
        // if(planData) {
        //     body['plan_id'] = planData.id;
        // }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'payment_intent') {
                    this.cardSubmit(res.data);
                } else {
                    this.setState({
                        enableLoader: false,
                        error: {
                            card: true
                        }
                    });
                    console.log('ResError=>>', res);
                }
            })
            .catch((error) => {
                this.setState({
                    enableLoader: false,
                    error: {
                        card: true
                    }
                });
                console.log('Error=>>', error);
            });
    }

    cardSubmit = (paymentIntent) => {
        const { token } = this.state;
        this.cardRef.current.authorizeWith3ds(
            paymentIntent.payment_intent,
            {
                ...paymentIntent.billing_infomation, 
                mandate: {
                    requireMandate: true, description: planData.name
                }
            })
            .then((authorizedPaymentIntent) => {
                let endpoint = `/add-payment-source`;
                let body = {
                    payment_intent: {
                        id: authorizedPaymentIntent.id
                    }
                }
                apiHelpers
                    .apiRequest(endpoint, body, "POST", true, token)
                    .then((res) => {
                        if (res.success && res.code == 'payment_source') {
                            this.setState({
                                addCard: false,
                                paymentSource: res.data,
                                enableLoader: false
                            });
                            if (helpers.isWeb()) {
                                try {
                                    Mixpanel.track('payment_info_changed');
                                }
                                catch (error) {
                                    console.log("mix panel error =>", error);
                                }
                            }
                        }
                        else {
                            this.setState({
                                enableLoader: false,
                                error: {
                                    card: true
                                }
                            });
                            console.log('ResError=>>', res);
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            enableLoader: false,
                            error: {
                                card: true
                            }
                        });
                        console.log('Error=>>', error);
                    });
            }).catch(error => {
                this.setState({
                    enableLoader: false,
                    error: {
                        card: true
                    }
                });
                console.log('Error=>>', error);
            });
    }

    getCardIcon = (brand) => {
        let cardIcon;
        switch (brand) {
            case 'visa': cardIcon = visaIcon;
                break;
            case 'mastercard': cardIcon = mastercardIcon;
                break;
            case 'american_express': cardIcon = americanexpressIcon;
                break;
            case 'discover': cardIcon = discoverIcon;
                break;
            case 'jcb': cardIcon = jcbIcon;
                break;
            case 'diners_club': cardIcon = dinersIcon;
                break;
            default: cardIcon = defaultIcon;
                break;
        }
        return cardIcon;
    }

    renderPageContent = () => {
        let { paymentSource, planData, subscription, addCard, error, styles, enableLoader } = this.state;
        const { isAdmin, referenceId } = this.props;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        if (isAdmin) {
            return (
                <View style={[Padding.ph_2, Margin.mt_1]}>
                    <View style={[Flex.justfiyCenter]}>
                        <Text style={[Padding.pt_2, { fontSize: 14, lineHeight: 22, color: '#666', fontFamily: FontFamily.regular }, Margin.mb_3, darkMode && { color: Colors.lightWhite }]}>No Access</Text>
                    </View>
                </View>
            )
        }
        return (
            <View>
                {(enableLoader) &&
                    <View style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: '#ffffff',
                        zIndex: 999
                    }}>
                        <View style={[Flex.justfiyCenter, Flex.alignCenter,
                        {
                            height: 'calc(100vh - 189px)',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            backgroundColor: '#ffffff'
                        }]}
                        >
                            <ActivityIndicator size={25} color={'#000000'} />
                        </View>
                    </View>
                }
                <View style={[Padding.ph_2, Margin.mt_1]}>
                    <View style={[Flex.justfiyCenter]}>
                        <Text style={[Padding.pt_2, { fontSize: 14, lineHeight: 22, color: '#666', fontFamily: FontFamily.regular }, Margin.mb_3, darkMode && { color: Colors.lightWhite }]}>{(paymentSource == false && planData == false) ? 'Please subscribe to a plan first. If you choose to pay via credit / debit card, the card details will appear here.' : ((paymentSource == false) ? 'To ensure that your subscription automatically gets renewed, please add a Credit or a Debit Card to your account.' : 'Your subscription will automatically be renewed with the card below.')}</Text>
                        {(paymentSource == false && planData == false) &&
                            <ButtonGradient
                                title={'VIEW SUBSCRIPTION PLANS'}
                                rounded={false}
                                fullWidth={true}
                                rootStyle={{
                                    btnWrapper: [button.primaryGradient, { width: '100%' }],
                                    btnText: [button.primaryGradientText, { fontWeight: '400', fontSize: helpers.isDesktop() ? 17 : 16 }],
                                }}
                                onClick={() => this.props.navigateScreen("accounts/subscriptions")}
                                darkMode={darkMode}
                            />
                        }
                        {((paymentSource == false && planData) || addCard) &&
                            <>
                                <View style={[Flex.row, Flex.alignStart, Flex.justifyBetween, { marginBottom: 5 }]}>
                                    <Text style={[Typo.text16, Margin.mb_1,{color: '#000'}, darkMode && { color: Colors.lightWhite }]}>Credit / Debit card</Text>
                                </View>
                                <View style={[{ borderWidth: 1, borderColor: error.card ? '#c51720' : '#808080', paddingVertical: 10, paddingHorizontal: 10 }, Margin.mb_3]}>
                                    <CardComponent ref={this.cardRef} styles={styles} onChange={(field) => this.setState({ cardComplete: field.complete, error: { card: false } })} darkMode={darkMode} />
                                </View>
                                <ButtonGradient
                                    title={'SAVE'}
                                    rounded={false}
                                    fullWidth={true}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradient, { width: '100%' }],
                                        btnText: button.primaryGradientText,
                                    }}
                                    onClick={() => this.submitCard()}
                                    darkMode={darkMode}
                                />
                                {addCard &&
                                    <ButtonGradient
                                        title={'CANCEL'}
                                        rounded={false}
                                        fullWidth={true}
                                        rootStyle={{
                                            btnWrapper: [button.primaryGradient, { width: '100%', marginTop: 30 }],
                                            btnText: button.primaryGradientText,
                                        }}
                                        onClick={() => this.setState({ addCard: false })}
                                        darkMode={darkMode}
                                    />
                                }
                            </>
                        }
                        {(paymentSource && !addCard) &&
                            <>
                                <View style={[Flex.row, Flex.alignStart, Flex.justifyBetween, { marginBottom: 5 }]}>
                                    <Text style={[Typo.text16, Margin.mb_1, darkMode && { color: Colors.lightWhite }]}>Credit / Debit card</Text>
                                </View>
                                <View style={[{ borderWidth: 1, borderColor: '#808080', paddingVertical: 10, paddingHorizontal: 10 }, Flex.row, Flex.alignCenter, Margin.mb_3]}>
                                    <View style={[Margin.mr_1]}>
                                        <CustomImage
                                            source={this.getCardIcon(paymentSource.card.brand)}
                                            style={{ width: 40, height: 20 }}
                                            webStyle={{ width: 40, height: 20, objectFit: 'contain' }}
                                            require={true}
                                            resizeMode={'contain'}
                                        />
                                    </View>
                                    <View style={[Flex.row, Flex.fill, Flex.alignStart, Flex.justifyBetween]}>
                                        <Text style={[Typo.text16, darkMode && { color: Colors.lineColor }]}>{paymentSource.card.masked_number}</Text>
                                        <Text style={[Typo.text16, darkMode && { color: Colors.lineColor }]}>{paymentSource.card.expiry_month + "/" + paymentSource.card.expiry_year.toString().slice(-2)}</Text>
                                    </View>
                                </View>
                                {subscription && helpers.isWeb() &&
                                    <ButtonGradient
                                        title={'UPDATE DETAILS'}
                                        rounded={false}
                                        fullWidth={true}
                                        rootStyle={{
                                            btnWrapper: [button.primaryGradient, { width: '100%' }],
                                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
                                        }}
                                        onClick={() => this.setState({ addCard: true, cardComplete: false, error: { card: false } })}
                                        darkMode={darkMode}
                                    />
                                }
                            </>
                        }
                    </View>
                </View>
            </View>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ContentLoader darkMode={darkMode} />
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { pageLoader } = this.state;
        return (
            <View>
                {
                    pageLoader ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(PaymentInfo));
