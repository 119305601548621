import React, { Component } from 'react';
import Button from '../button';
import getImagePath from '../../utils/image-helper';

const googleIcon = getImagePath('icons/google-icon-new.png');

class GoogleButton extends Component {
 
    render() {
        let rootStyle = JSON.parse(JSON.stringify(this.props.rootStyle));
        if(this.props.disable) {
            rootStyle.btnWrapper.push({opacity: 0.4});
        }
        return (
        	<Button
                title={this.props.title}
                icon={googleIcon}
                onClick={this.props.triggerLogin}
                {...this.props}
                loader={this.props.loader}
                disable={this.props.disable}
                indicatorColor={this.props.indicatorColor}
                rootStyle={rootStyle}
            />
        );
    }
}
 
export default GoogleButton;