import React, { Component } from "react";
import ReactGA from "react-ga";
import { Text } from "react-native";
import { View } from "react-native";
import { withRouter } from "react-router-dom";
import CustomImage from "../../native/components/custom-image";
import getImagePath from "../../native/utils/image-helper";
import ButtonGradient from "../../native/components/button-gradient";
import { button } from "../../native/styles/appStyles";
import { helpers } from "../../native/utils/helpers";
import Cookies from "universal-cookie";
import apiHelpers from "../../native/utils/api-helpers";
import AuthenticationWrapper from "../../native/components/authentication-wrapper";
import ContentLoader from "react-native-easy-content-loader";
import PageContentLoader from "../../native/components/content-loader";

const giftSubImage = getImagePath('img/sub-gift.png');

const cookies = new Cookies()
export class ClaimSub extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    this.state = {
      token: tokenSet ? token : false,
      btnLoading: false,
      subCode: null,
      adminGiftCode: null
    }
  }

  componentDidMount = () => {
    /**
     * If token is present then on claiming the gift, it should go to the home screen
     * else it should login first and then go to the home screen if claim is successful
     */
    if(window.location.href.split('?')[1].includes('sub_code')){
      const sub_code = window.location.href.split('?')[1].split('=').pop();
      this.setState({subCode: sub_code});
    }else if(window.location.href.split('?')[1].includes('admin_gift_code')){
      const admin_gift_code = window.location.href.split('?')[1].split('=').pop();
      this.setState({adminGiftCode: admin_gift_code});
    }
  };

  componentDidUpdate = () => {

  };

  navigateToScreen = (route, params) => {
    let routeArr = route.split("?");
    const { history } = this.props;
    history.push({
      pathname: routeArr[0],
      search: routeArr[1] ? "?" + routeArr[1] : "",
      state: params,
    });
  };

  handleClaim = () => {
    this.setState({btnLoading: true})
    if(!this.state.token){
      /**
       * Redirect to login screen
       */
      this.navigateToScreen(
        `/sign-in?redirect=/claim-sub&redirect-params=sub_code=${this.state.subCode}`
        );
    }else{
      /**
       * Try claiming the gift
       */
      let endpoint = `/redeem-subscription`;
      let body = {
        sub_code: this.state.subCode
      };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, this.state.token)
        .then(async (res) => {
          if (res.success && res.code == "redeemed_sub") {
            await new Promise(resolve => setTimeout(resolve, 5000));
            window.location.href = '/'
          } else if(res.message == "Gift not for this user!"){
            /**
             * Redirect to claim-sub-error with parameter as gift not for this email id
             */
            this.navigateToScreen('/claim-sub-error?error_code=wrong_user')
          }
          else {
             this.navigateToScreen('/claim-sub-error?error_code=link_expired')
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error=>> down", JSON.stringify(error));
        }).finally(()=>this.setState({btnLoading: false}));

    }
  }

  handleAdminGiftClaim = () => {
    this.setState({btnLoading: true})
    if(!this.state.token){
      /**
       * Redirect to login screen
       */
      this.navigateToScreen(
        `/sign-in?redirect=/claim-sub&redirect-params=admin_gift_code=${this.state.adminGiftCode}`
        );
    }else{
      /**
       * Try claiming the gift
       */
      let endpoint = `/redeem-admin-subscription`;
      let body = {
        admin_sub_code: this.state.adminGiftCode
      };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, this.state.token)
        .then(async (res) => {
          if (res.success && res.code == "redeemed_sub") {
            await new Promise(resolve => setTimeout(resolve, 5000));
            window.location.href = '/'
          } else if(res.message == "Cannot gift this user. User already has an active plan."){
            /**
             * Redirect to claim-sub-error with parameter as gift not for this email id
             */
            this.navigateToScreen('/claim-sub-error?error_code=admin_existing_plan')
          }
          else {
             this.navigateToScreen('/claim-sub-error?error_code=invalid_admin_link')
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error=>> down", JSON.stringify(error));
        }).finally(()=>this.setState({btnLoading: false}));

    }
  }
  
  render() {
    return (
      this.state.btnLoading ?
        <PageContentLoader  /> : 
      <View style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <View style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Text style={{fontSize: 28, fontWeight: 700, fontFamily: "Abril Fatface"}}>REDEEM YOUR GIFT</Text>
        </View>
        <View style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', margin: 20}}>
          <View style={{borderBottomWidth: 1, width: helpers.isDesktop() ? '5%': '80%'}} />
         {helpers.isDesktop() ?
         <> 
         <View style={{borderBottomWidth: 3, width: '5%'}} />
          <View style={{borderBottomWidth: 1, width: '5%'}} /> 
          </> : null}
        </View>
        <View style={{borderWidth: 1, borderColor: '#907CFF', display: 'flex', justifyContent: 'center', alignItems: 'center', width: helpers.isDesktop() ? 450 : 300, height: helpers.isDesktop() ? 450 : 300, marginBottom: 20}}>
          <View>
          <CustomImage
                    source={giftSubImage}
                    style={{ width: 111, height: 111 }}
                    webStyle={{ width: 150, height: 150 }}
                    className={'gift-sub'}
                    altText={"Gift subscription image"}
                />
          </View>
          <View style={{borderBottomWidth: 1, width: 170, margin: 20}}></View>
          <View>
            <Text style={{fontSize: 18, fontWeight: 600}}>{this.state.subCode ? "30-day Subscription" : "Gift Subscription"}</Text>
          </View>
        </View>
        <View style={{width: 150}}>
        <ButtonGradient
          title="Claim Now"
          rootStyle={{
              btnWrapper: [button.primaryGradient],
              btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, fontWeight: '400' }],
              paddingHorizontal: 5
          }}
          // loader={(enableBtnLoader && enableBtnLoader == 'email') ? true : false}
          // disable={((enableBtnLoader && enableBtnLoader != 'email') || (email == '' || password == '')) ? true : false}
          onClick={ this.state.subCode ? this.handleClaim : this.handleAdminGiftClaim}
          fullWidth={true}
      />
        </View>
      </View>
    );
  }
}

export default AuthenticationWrapper(ClaimSub);
