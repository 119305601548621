import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  pageCover,
  FontFamily,
  popularStories,
  Colors,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import PageBreak from "../page-break";
import TouchableOpacityLink from "../touchable-opacity-link";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../custom-image";
import Cookies from "universal-cookie";
import AuthenticationWrapper from "../authentication-wrapper";

const Startup = getImagePath("img/startup-img.png");
const Fintech = getImagePath("img/fintech-img.png");
const Retail = getImagePath("img/retail-img.png");
const eccomerce = getImagePath("img/eccomerce-img.png");
const media = getImagePath("img/media-img.png");

//mobile
const StartupMobImg = getImagePath("img/m-startup-img.png");
const FintechMobImg = getImagePath("img/m-fintech-img.png");
const RetailMobImg = getImagePath("img/m-retail-img.png");
const eccomerceMobImg = getImagePath("img/m-ecomerce-img.png");
const mediaMobImg = getImagePath("img/m-media-img.png");

const cookies = new Cookies();

export class HomePopularCategories extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    this.state = {
      showToken: !helpers.isWeb() || process.browser ? true : false,
    };
  }

  componentDidMount = () => { };

  trackThemeClick = (theme) => {
    this.pushThemeClickToDataLayer(theme)
    if (this.props.trackThemeClick) {
      this.props.trackThemeClick(theme);
    }
  };

  pushThemeClickToDataLayer = (themeName) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "CTA_theme_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "explore by theme",
      newsletter_category: "NA",
      newsletter_section:'NA',
      author_name:'NA',
      theme_name: themeName.toLowerCase(),
      article_category:'NA',
      scroll_depth: helpers.getScrollDepth(),
    });
  }


  render() {
    const { transparentBg, data, subscription, getTagClick, darkMode } =
      this.props;
    let startUpProp,
      fintechProp,
      environmentProp,
      eCommProp,
      technologyProp,
      telecomProp,
      edtechProp,
      itProp,
      financeProp,
      retailProp,
      mediaProp;
    let webView = helpers.isWeb();
    if (helpers.isWeb()) {
      startUpProp = {
        to: "/tag/startups",
      };
      fintechProp = {
        to: "/tag/fintech",
      };
      eCommProp = {
        to: "/tag/ecommerce",
      };
      retailProp = {
        to: "/tag/retail",
      };
      mediaProp = {
        to: "/tag/media-entertainment",
      };
      technologyProp = {
        to: "/tag/aviation",
      };
      telecomProp = {
        to: "/tag/telecom",
      };
      edtechProp = {
        to: "/tag/edtech",
      };
      itProp = {
        to: "/tag/infotech",
      };
      environmentProp = {
        to: "/tag/environment",
      };
      financeProp = {
        to: "/tag/Banking & finance",
      };
    } else {
      startUpProp = {
        onPress: () => getTagClick("startups"),
      };
      fintechProp = {
        onPress: () => getTagClick("fintech"),
      };
      eCommProp = {
        onPress: () => getTagClick("ecommerce"),
      };
      retailProp = {
        onPress: () => getTagClick("retail"),
      };
      mediaProp = {
        onPress: () => getTagClick("media-entertainment"),
      };
      technologyProp = {
        onPress: () => getTagClick("aviation"),
      };
      telecomProp = {
        onPress: () => getTagClick("telecom"),
      };
      edtechProp = {
        onPress: () => getTagClick("edtech"),
      };
      itProp = {
        onPress: () => getTagClick("infotech"),
      };
      environmentProp = {
        onPress: () => getTagClick("environment"),
      };
      financeProp = {
        onPress: () => getTagClick("Banking finance"),
      };
    }
    return (
      <View
        style={[
          helpers.isDesktop() ? Flex.row : Flex.column,
          Flex.alignCenter,
          Flex.justfiyCenter,
          {
            paddingTop: "20px",
            paddingBottom: "40px",
            // marginTop: "100px",
          },
          !helpers.isDesktop() && {
            marginTop: "48px",
            paddingHorizontal: 20,
          },
          helpers.isDesktop() && Padding.ph_4,
          !helpers.isDesktop() && {
            alignItems: "center",
            paddingTop: 40,
            paddingBottom: 40,
          },
          {
            backgroundColor: transparentBg
              ? "transparent"
              : darkMode
                ? Colors.darkBlackColorBg
                : "#f3f5ff",
          },
        ]}
      >
        <View>
          <View
            style={[
              article.headerWrapper,
              !helpers.isDesktop() && { left: 0 },
              !helpers.isDesktop() && { right: 0 },
              !helpers.isDesktop() && { top: 0 },
            ]}
          >
            <View style={[Flex.alignCenter]}>
              <View
                style={[
                  Padding.ph_2,
                  Helpers.titleBgWrap,
                  {
                    marginTop: helpers.isDesktop() ? 20 : 0,
                    backgroundColor: "#f3f5ff",
                  },
                ]}
              >
                <View>
                  <Text
                    style={[
                      Margin.mt_0,
                      Margin.mb_0,
                      pageCover.title,
                      helpers.isDesktop() && pageCover.titleDesktop,
                      helpers.isDesktop() && { bottom: 15 },
                      Helpers.textCenter,
                      {
                        position: "relative",
                        color: "#292929",
                        fontSize: "34px",
                        lineHeight: "55px",
                        fontFamily: "PlayfairDisplay-Bold",
                        fontWeight: "700",
                        borderBottomWidth: helpers.isDesktop() ? "2px" : 0,
                        borderBottomColor: "#292929",
                        borderStyle: "solid",
                        marginBottom: helpers.isDesktop() ? 0 : 16,
                      },
                      darkMode && { color: Colors.lightWhite },
                    ]}
                  >
                    {data.title}
                  </Text>
                </View>
              </View>
              {helpers.isDesktop() && (
                <View
                  style={[
                    Helpers.titleLine,
                    darkMode && { backgroundColor: "#F3F5FF" },
                    {
                      maxWidth: "calc(1216px - 30px)",
                    },
                  ]}
                ></View>
              )}
            </View>
          </View>

          {/* <View
            style={[
              helpers.isDesktop() &&
              {
              },
            ]}
          >
            {!subscription && (
              <View style={[Flex.alignStart]}>
                <View
                  style={[
                    Helpers.titleBgWrap,
                    {
                      backgroundColor: "transparent",
                      width: helpers.isDesktop() ? "auto" : "100%",
                    },
                  ]}
                >
                  {!helpers.isDesktop() ? (
                    <Text
                      style={[
                        Helpers.textCenter,
                        Typo.h3_R,
                        helpers.isDesktop() && Typo.h3_RDesktop,
                        Margin.mb_0,
                        helpers.isWeb() && {
                          marginLeft: helpers.isDesktop() ? 50 : 0,
                        },
                        { fontFamily: FontFamily.abrilFatface },
                        {
                          fontSize: helpers.isDesktop() ? 34 : 34,
                          lineHeight: 51,
                          color: "#000",
                          fontWeight: 400,
                        },
                        !helpers.isDesktop() && {
                          paddingBottom: 22,
                          color: "#051462",
                        },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                      ]}
                    >
                      {data.title}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        Helpers.textCenter,
                        Typo.h3_R,
                        helpers.isDesktop() && Typo.h3_RDesktop,
                        Margin.mb_0,
                        { fontFamily: FontFamily.abrilFatface },
                        {
                          fontSize: helpers.isDesktop() ? 34 : 20,
                          color: "#000",
                        },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                      ]}
                    >
                      Popular Themes
                    </Text>
                  )}
                </View>
              </View>
            )}
          </View> */}
          <View
            style={[
              Helpers.conatinerWeb,
              {
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              },
              helpers.isDesktop() && { maxWidth: "1088px" },
              helpers.isDesktop() && { marginTop: 13 },
            ]}
          >
            {subscription && (
              <View
                style={[
                  {
                    borderWidth: 0.75,
                    borderColor: "#7B8FFE",
                    borderRadius: 40,
                    backgroundColor: "#fff",
                  },
                  helpers.isDesktop() && { margin: 12 },
                ]}
              >
                <View
                  style={[
                    Flex.alignCenter,
                    helpers.isDesktop() && Margin.mb_7,
                    !helpers.isDesktop() && Margin.mb_4,
                    {
                      width: helpers.isWeb() ? "fit-content" : "100%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_2,
                      Helpers.titleBgWrap,
                      { backgroundColor: "transparent" },
                    ]}
                  >
                    <Text
                      style={[
                        Helpers.textCenter,
                        Typo.h3_R,
                        helpers.isDesktop() && Typo.h3_RDesktop,
                        Margin.mb_0,
                        { fontFamily: FontFamily.abrilFatface },
                        {
                          fontSize: helpers.isDesktop() ? 24 : 18,
                          color: "#7B8FFE",
                        },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        { paddingHorizontal: 10, paddingVertical: 5 },
                      ]}
                    >
                      {data.title}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...startUpProp}
                handleClick={() => this.trackThemeClick("startups")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Startups
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>15 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase'}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...fintechProp}
                handleClick={() => this.trackThemeClick("fintech")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline", paddingHorizontal: 25 },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Fintech
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>5 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...retailProp}
                handleClick={() => this.trackThemeClick("retail")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Retail
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>2 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...eCommProp}
                handleClick={() => this.trackThemeClick("e-commerce")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      E-commerce
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>10 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...mediaProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Media
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...itProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      IT
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...technologyProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Aviation
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...telecomProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Telecom
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...edtechProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      EdTech
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...financeProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Banking & Finance
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            <View
              style={[
                !helpers.isDesktop() && { padding: 8 },
                helpers.isDesktop() && { margin: 12 },
              ]}
            >
              <TouchableOpacityLink
                {...environmentProp}
                handleClick={() => this.trackThemeClick("media")}
              >
                <View
                  style={[
                    {
                      borderWidth: 0.75,
                      borderColor: "#7B8FFE",
                      borderRadius: 40,
                      backgroundColor: "#fff",
                    },
                    !helpers.isDesktop() && {
                      paddingBottom: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      marginRight: 0,
                    },
                    !process.browser && { width: 300 },
                    !helpers.isWeb() && { width: "100%" },
                    darkMode && { backgroundColor: "#1E1E1E" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_3,
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      { alignItems: "baseline" },
                      !webView && { alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        popularStories.imageTitle,
                        helpers.isDesktop() && popularStories.imageTitleDesktop,
                        { color: "#7B8FFE" },
                        darkMode && { color: "rgba(255,255,255,0.87)" },
                        {
                          fontSize: helpers.isDesktop() ? 20 : 18,
                          lineHeight: helpers.isDesktop() ? 41 : 35,
                          color: "#7B8FFE",
                        },
                      ]}
                    >
                      Environment
                    </Text>
                    {/* {!helpers.isDesktop() && <Text style={[popularStories.articleCount]}>23 <Text style={{fontSize: 11, lineHeight: 13, textTransform: 'uppercase', position: 'relative', top: -1}}>articles</Text></Text>} */}
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default AuthenticationWrapper(HomePopularCategories);
