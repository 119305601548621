import { SAVE_TOKEN, REMOVE_TOKEN, USER_SUBSCRIPTION, SAVE_STATUS } from './user.types';

const initialState = {
    userData: null,
    userSubData: null
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TOKEN:
            return {
                ...state,
                userData: action.data
            }
        case REMOVE_TOKEN:
            return {
                ...state,
                userData: null,
                userSubData: null
            }
        case USER_SUBSCRIPTION:
            return {
                ...state,
                userSubData: action.data
            }
        case SAVE_STATUS:
            return {
                ...state,
                userStatus: action.data
            }
        default:
            return state;
    }
};

export default UserReducer;
