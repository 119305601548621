import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform } from 'react-native';
import { postSingle, Flex, Padding, Margin, Helpers, aboutAuthor, pageContent, pageCover, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import { helpers } from '../../utils/helpers';


const emailYesterDayOne = getImagePath('img/emailYesterDayOne.png');
const emailYesterDayTwo = getImagePath('img/emailYesterDayTwo.png');

export class WhyYesterDay extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
    }


    render() {
        let webView = helpers.isWeb();
        let { fulWidth, subscription, lightBlueBg, home, postSingle, darkMode, studentSubscription, whiteBg } = this.props;
        return (
            <View style={[postSingle && { paddingLeft: helpers.isDesktop() ? 20 : 0, paddingRight: helpers.isDesktop() ? 20 : 0 }]}>
                <View style={[Helpers.textCenter, Helpers.conatinerWeb, home && helpers.isDesktop() && Helpers.conatinerWeb1000, { backgroundColor: '#F9F8FF', }, fulWidth && { maxWidth: '100%' }, lightBlueBg && { backgroundColor: '#e5e5e5' }, darkMode && { backgroundColor: Colors.darkPurple }, whiteBg && { backgroundColor: '#ffffff' }, darkMode && { backgroundColor: Colors.darkPurple }, { paddingHorizontal: 20 }]}>
                    <View style={[Padding.pt_4, Padding.pb_7, !helpers.isDesktop() && Padding.pb_4, { width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                        <Text style={[{ fontSize: helpers.isDesktop() ? 34 : 24, lineHeight: 40, fontFamily: FontFamily.abrilFatface, color: '#051462' }, { paddingBottom: 3 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Why <Text accessibilityRole="header" aria-level="2">YESTERDAY?</Text></Text>
                        <Text style={[Padding.pb_2, { fontSize: helpers.isDesktop() ? 24 : 16, lineHeight: helpers.isDesktop() ? 33 : 18, letterSpacing: 0.3, fontFamily: FontFamily.regular, color: '#051462' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Don’t scroll anymore, let Yesterday bring the news to you</Text>
                    </View>
                    <View style={[Flex.row, Flex.FlexWrap, Padding.pb_5, !helpers.isDesktop() && Padding.pb_3, { paddingHorizontal: helpers.isDesktop() ? '12%' : 20, margin: 'auto', }]}>
                        <View style={[Margin.mb_4, helpers.isDesktop() && Padding.pl_3, { width: helpers.isDesktop() ? '50%' : '100%' }, { order: 1, textAlign: 'left', alignItems: 'flex-start' }]}>
                            <View style={{
                                zIndex: 2, borderRadius: 12, shadowColor: '#F9F8FF',
                                shadowOffset: {
                                    width: 0,
                                    height: -10,
                                },
                                shadowOpacity: 0.50,
                                shadowRadius: 20,
                                elevation: 1,
                            }}  >
                                <CustomImage
                                    source={emailYesterDayOne}
                                    require={true}
                                    style={{ width: 178, height: 383, padding: 20 }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 65 : 50 }}
                                />
                            </View>
                            <View style={{
                                position: "absolute", left: 120, top: 20, borderRadius: 12, borderRadius: 12, shadowColor: '#F9F8FF',
                                shadowOffset: {
                                    width: 0,
                                    height: -10,
                                },
                                shadowOpacity: 0.50,
                                shadowRadius: 20,

                                elevation: 1,
                            }} >
                                <CustomImage
                                    source={emailYesterDayTwo}
                                    require={true}
                                    style={{ width: 178, height: 383 }}
                                    webStyle={{ width: helpers.isDesktop() ? 'auto' : 'auto', height: helpers.isDesktop() ? 65 : 50 }}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </View >
        );
    }
}

export default WhyYesterDay;