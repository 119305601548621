import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, TouchableHighlight, TouchableOpacity } from 'react-native';
import { postSingle, Flex, Padding, Margin, Badges, Helpers, postContentTags, postContentClasses, postTags, modalStyle } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import getImagePath from '../../utils/image-helper';
import Zoom from 'react-medium-image-zoom';
import Modal from '../modal';

const gradientLeft = getImagePath('img/gradientLeft.png');
const gradientRight = getImagePath('img/gradientRight.png');
const closeIcon = getImagePath('icons/close-icon-white.png');

export class ZoomImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImageModal: this.props.showImageModal,
        }
    }

    componentDidMount = () => {
    }

    renderCrossIcon = (showMargin, hide) => {
        const { enableLoader } = this.state;
        return (
            <View style={[{ position: 'absolute', right: helpers.isDesktop() ? 15 : 8, top: helpers.isDesktop() ? 5 : 5, zIndex: 1, backgroundColor: '#000', borderRadius: '30px' }]}>
                <TouchableOpacity
                    activeOpacity={0.8}
                    style={[modalStyle.closeButton]}
                    onPress={() => {
                        this.setState({ showImageModal: false });
                        this.props.setShowImageModal(false);
                    }}
                >
                    <CustomImage
                        source={closeIcon}
                        style={{ width: 10, height: 10 }}
                        webStyle={{ width: 10, height: 10 }}
                        require={true}
                    />
                </TouchableOpacity>
            </View>
        )
    }

    renderImageModalContent = () => {

        const { featuredImage, featuredTitle } = this.props;
        const webView = helpers.isWeb();
        let imgSrc = featuredImage.url;
        // let imgSrc = featuredImage?.sizes ? featuredImage.sizes['1x'] : ''
        return (
            <>
                {this.renderCrossIcon()}
                <View style={{ backgroundColor: '#000000' }}>
                    <View style={[{ position: 'relative', width: '100%', marginLeft: 'auto', marginRight: 'auto', position: 'relative', height: 435, overflow: 'hidden' }, helpers.isWeb() && { maxWidth: '1440px' }]}>
                        <CustomImage webStyle={{ height: 435, width: '100%', objectFit: 'cover' }} style={postSingle.postThumbnail} source={imgSrc} srcSet={false} altText={featuredImage.alt ? featuredImage.alt : featuredTitle} />
                    </View>
                </View>
            </>
        )
    }

    render() {
        const { featuredImage, lazyLoad, darkMode, featuredTitle } = this.props;
        const { showImageModal } = this.state;
        let imgSrc = featuredImage.url;
        // helpers.isDesktop() ?
        //     imgSrc = featuredImage?.sizes ? featuredImage.sizes['3x'] : ''
        //     :
        //     imgSrc = featuredImage?.sizes ? featuredImage.sizes['1x'] : ''
        return (
            <>
                {featuredImage?.sizes ?
                    <View style={{ backgroundColor: '#000000' }}>
                        {helpers.isDesktop() ?
                            <Zoom overlayBgColorEnd={(darkMode ? 'rgba(0, 0, 0, 95)' : 'rgba(255, 255, 255, 0.95)')}>
                                <View style={[{ position: 'relative', width: '100%', marginLeft: 'auto', marginRight: 'auto', position: 'relative', height: 435, overflow: 'hidden' }, helpers.isWeb() && { maxWidth: '1440px' }]}>
                                    <CustomImage webStyle={{ position: 'absolute', left: -1, top: 0, bottom: 0, zIndex: 1, width: 150, height: '100%' }} source={gradientLeft} />
                                    <CustomImage webStyle={{ height: 435, width: '100%', objectFit: 'cover' }} style={postSingle.postThumbnail} source={imgSrc} altText={featuredImage.alt ? featuredImage.alt : featuredTitle} srcSet={false} />
                                    <CustomImage webStyle={{ position: 'absolute', right: -1, top: 0, bottom: 0, zIndex: 1, width: 150, height: '100%' }} source={gradientRight} />
                                </View>
                            </Zoom>
                            :
                            <TouchableHighlight onPress={() => {
                                this.setState({ showImageModal: true });
                                this.props.setShowImageModal(true);
                            }}>
                                <View style={[{ position: 'relative', width: '100%', marginLeft: 'auto', marginRight: 'auto', position: 'relative', height: 435, overflow: 'hidden' }, helpers.isWeb() && { maxWidth: '1440px' }]}>
                                    <CustomImage webStyle={{ height: 435, width: '100%', objectFit: 'cover' }} style={postSingle.postThumbnail} source={imgSrc} altText={featuredImage.alt ? featuredImage.alt : featuredTitle} srcSet={false} />
                                </View>
                            </TouchableHighlight>
                        }
                    </View>
                    :
                    <View style={[postSingle.postThumbnailEmpty, { backgroundColor: '#eeeeee' }]} />
                }
                {(showImageModal && !helpers.isDesktop()) &&
                    <View style={{ zIndex: 9999 }}>
                        <Modal ref="child" renderContent={() => this.renderImageModalContent()} paddingHorizontal={true} topPadding={false} imageZoom={true} subscription={true} />
                    </View>
                }
            </>
        );
    }
}

export default ZoomImage;