import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  Platform,
  ActivityIndicator,
  Image,
  Modal,
  Linking,
  Dimensions,
  Share,
  TouchableOpacity,
  StyleSheet,
  Animated,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  FontFamily,
  appStyles,
  actionBox,
  accessContent,
  exclusiveContent,
  textBlock,
  articleLoader,
  articleLists,
  FontWeight,
  button,
  Colors,
  article,
  articleItem,
  pageContent,
} from "../../styles/appStyles";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import ButtonGradient from "../../components/button-gradient";
import { bindActionCreators } from "redux";
import { removeUserToken } from "../../../redux/User/user.actions";
import CustomImage from "../../components/custom-image";
import PostContent from "../../components/post-content";
import PostExcerpt from "../../components/post-excerpt";
import PostStrapline from "../../components/post-strapline";
import HomeExclusiveContent from "../../components/home-exclusive-content";
import HomeReadersQuote from "../../components/home-readers-quote";
import OurJournalism from "../../components/ourJournalism";
import PostTags from "../../components/post-tags";
import WritersSlider from "../../components/home-news-letters/WritersSlider";
import { helpers } from "../../utils/helpers";
import HomeArchievedContent from "../../components/home-archived-content";
import apiHelpers from "../../utils/api-helpers";
import SubscriptionPricingPlan from "../../components/subscription-pricing-plan";
import Testimonial from "../../components/testimonial";
import Button from "../../components/button";
import ErrorModal from "../../components/error-modal";
import moment from "moment";
import Seo from "../../components/seo-meta";
import AppHeader from "../../components/app-header";
import AppFooter from "../../components/app-footer";
import AboutAuthor from "../../components/about-author";
import PageBreak from "../../components/page-break";
import ArticleList from "../../components/articles-list";
import NoteDisclosures from "../../components/note-disclosure";
import PostSummary from "../../components/post-summary";
import PostSummaryStory from "../../components/post-summary/indexstory";
import ActionBox from "../../components/action-box";
import ReferralBox from "../../components/referral-box";
import JoinUsInfoBox from "../../components/joinus-infobox";
import CreditSignUp from "../../components/credit-signup";
import ExclusiveContent from "../../components/exclusive-content";
import ContentLoader from "react-native-easy-content-loader";
import getImagePath from "../../utils/image-helper";
import UnlockBox from "../../components/unlock-box";
import SignUpBlock from "../../components/sign-up-block";
import Banner from "../../components/banner";
import WhyTMCSection from "../../components/why-choose-tmc";
import PostSingleNotReady from "../../components/post-single-not-ready-box";
import ReadyToSubscribe from "../../components/ready-to-subscribe";
import FreeReads from "../../components/free-reads";
import ReadFromArchives from "../../components/read-from-the-archives";
import MoreNewsletterPosts from "../../components/more-newsletter-posts";
import UnlockThisStory from "../../components/unlock-this-story";
import AuthorItem from "../../components/author-item";
import InsufficientCreditPopup from "../../components/insufficient-credit-popup";
import SubscriptionInfoBox from "../../components/subscription-info-box";
import NewsletterSlider from "../../components/newsletter-slider";
import { Mixpanel } from "../../utils/mixpanel";
import Sponsers from "../../components/sponsors";
import SubscriptionDashboardInfo from "../../components/subscription-dashboard-info";
import PostBookmarkedContainer from "../../components/post-bookmarked-container";
import PostShare from "../../components/post-share";
import NotFound from "../../components/not-found";
import IosLockedView from "../../components/ios-locked-view";
import { refreshScreen } from "../../../redux/App/app.actions";
import DarkModeSwitch from "../../components/darkmode-switch";
import SingleGiftStory from "../../components/single-gift-story";
import GiftClaimModal from "../../components/gift-claim-modal";
import EverythingTmc from "../../components/everything-tmc";
import UnlockStoryNew from "../../components/unlock-story-new";
import HomePopularCategories from "../../components/home-popular-categories";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import ArticleItemHorizontal from "../../components/article-item-horizontal";
import HomeChooseSubscription from "../../components/home-choose-subscription";
import StoryModal from "../../components/story-modal";
import { trackStoryOrNewsletter } from "../../utils/webengage/events";
import { Layer } from "grommet";
import { ChargebeeController } from "../../utils/chargebee";
import GiftStoryNudge from "../../components/gift-story-nudge";
import UpdatedFloatingNudge from "../../components/updated-floting-nudge";
import MoreStoriesSection from "../../components/more-stories";
import SubscribeToUnlock from "../../components/subscribe-to-unlock";

const overlayImage = getImagePath("img/post-excerpt-overlay.png");
const overlayImageDark = getImagePath("img/overlay-darkgrey.png");
const buttonTopArrow = getImagePath("icons/buttonTopArrow.png");
const freeStorySubscribeBand = getImagePath("img/free-story-subscribe-band.png");
const freeStorySubscribeBandMob = getImagePath("img/free-story-subscribe-band-mob.png");

const cookies = new Cookies();

const PrivilegeCreditGift = {
  id: "privilege_credits",
  name: "Gift a story",
  tmc_group: "",
  billing_enitity_type: "adhoc",
  invoice_name: "Gift a story",
  description: {
    access_desc: [
      "Can gift upto 3 privilege credits a month for free",
      "Can be used to unlock any long form story or newsletter of your choice",
    ],
  },
  is_popular: false,
  recommended_plan: false,
  discount: {},
  is_story_gift: true,
};

let Breadcrumbs = [];

export class PostSingle extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? process.browser
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.token
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    let postSlug = "",
      categorySlug = "",
      linkPostData,
      linkScreenName,
      redirectParams;
    if (webView && this.props.postSlug) {
      postSlug = this.props.postSlug;
      categorySlug = this.props.categorySlug;
      linkPostData = this.props.linkPostData;
      linkScreenName = this.props.linkScreenName;
    } else if (!webView) {
      postSlug = this.props.route.params.postSlug;
      categorySlug = this.props.route.params.categorySlug;
      linkPostData = this.props.route.params.postData;
    }

    if (webView && process.browser && window.location.search) {
      let searchArr = window.location.search.replace("?", "").split("&");
      redirectParams = searchArr.find((searchParam) => {
        return searchParam.includes("redirect-params=");
      });
      if (redirectParams) {
        redirectParams = redirectParams.replace("redirect-params=", "");
      }
    } else if (!webView && this.props.route.params) {
      let search = this.props.route.params.redirect
        ? this.props.route.params.search
        : null;
    }

    this.scrollY = new Animated.Value(0);

    // Calculate 20vh
    const viewportHeight = Dimensions.get('window').height;
    this.showNudgeAfterScroll = viewportHeight * 0.2; // 20vh

    this.state = {
      token: tokenSet ? token : false,
      postData: this.props.postData ? this.props.postData : "",
      postDataSSR: this.props.postData ? true : false,
      pageLoader: this.props.postData ? false : true,
      noPageFound:
        this.props.postData?.api_code == "invalid_slug" ? true : false,
      userLoader: tokenSet ? true : false,
      plansLoader:
        this.props.postData &&
          (this.props.postData.api_code == "story_partial_read" || !tokenSet)
          ? true
          : false,
      articlesLoader: false,
      statsLoader: false,
      privCredits: 0,
      archivedStories: [],
      writers: [],
      actionsLoader: true,
      postLists:
        this.props.postData && this.props.postData.postLists
          ? this.props.postData.postLists.posts
          : false,
      categorySlug: categorySlug,
      postSlug: postSlug,
      linkPostData: linkPostData ? linkPostData : false,
      linkScreenName: linkScreenName,
      userLoggedin: tokenSet,
      userSubscriptionActive:
        this.props.postData && this.props.postData.api_code == "story_full_read"
          ? true
          : false,
      userSubscriptionStatus: false,
      userSubscription: false,
      teamSubscription: false,
      userSubscriptionExpired: false,
      userCredit: 0,
      creditPlan: false,
      plansData: false,
      postUnlocked:
        this.props.postData && this.props.postData.api_code == "story_full_read"
          ? true
          : false,
      storyActions: false,
      oldStoryActions: false,
      storyStats:
        this.props.postData && this.props.postData.postStats
          ? this.props.postData.postStats.stats.label
          : false,
      enableMessageBox: false,
      messageBox: {
        type: "",
        title: "",
        subTitle: "",
      },
      showErrorModal:
        this.props.postData && this.props.postData.error ? true : false,
      postUnlockError: false,
      footerData: this.props.footerData ? this.props.footerData : false,
      country: false,
      redirectSlugs: {
        web: "/" + categorySlug + "/" + postSlug,
        native: {
          redirect: "post-single",
          categorySlug: categorySlug,
          postSlug: postSlug,
          verifyRedirect: "/" + categorySlug + "/" + postSlug,
        },
      },
      hideHeader: false,
      hideSideShareActionButton: true,
      showSubscribeModal: false,
      isPopupEnabled: false,
      scrollCheck: 0,
      scrollEnabled: true,
      bookmarkLoading: false,
      likeLoading: false,
      redirectParams: redirectParams,
      exclusiveContent: false,
      staticContent: false,
      homeContent:
        this.props.homeData && this.props.homeData.homeContent
          ? this.props.homeData.homeContent
          : false,
      starterCredits: 0,
      monthlyData: [],
      isShortEnabled: false,
      planCheckoutLoaderState: false,
      profileInfo: {},
      isGift: this.props.history.location.search.includes("giftCode"),
      giftRedeemed: this.props.history.location.search.includes("giftRedeemed"),
      subsLeft: 0,
      explainerNudgeStoryData: false,
      pageScrolled: false
    };
    this.displayPopup = false;

    if (redirectParams) {
      let reaplaceProp = JSON.parse(JSON.stringify(window.location));
      reaplaceProp.search = "";
      this.props.history.replace(reaplaceProp);
    }

    this.subscriptionLayout = 0;
    this.subscriptionExtraheight = 0;
    this.relatedStoriesOffset = 0;
    this.StoriesSummaryOffset = 0;
    this.viewOffset = 0;
    this.footerOffset = 0;
    this.percentStoryScrolled = 0;
    this.moreStoriesSectionOffset = 0;
    if (this.props.postData) {
      this.setBreadcrumbs(this.props.postData);
    }
  }

  setPlanButtonLoaderState = (state) => {
    this.setState({
      planCheckoutLoaderState: state,
    });
  };

  getPlanButtonLoaderState = () => {
    return this.state.planCheckoutLoaderState;
  };

  getStaticContent = () => {
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: ["readers_quotes"],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "content_found") {
          this.setState({ staticContent: res.data });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getHomeContent = (content) => {
    const { token } = this.state;
    let endpoint = `/static-content`;
    let body = {
      content: content,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "content_found") {
          this.setState({ homeContent: res.data.newsletters });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  getExclusiveContent = () => {
    let endpoint = `/stories`;
    let body = {
      limit: 1,
      filters: [
        { key: "is_featured", value: true },
        { key: "single_category", value: true },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            exclusiveContent: res.data.story,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getCurrentNewsExplainer = () => {
    const { token } = this.state
    let endpoint = `/yesterday/editions`;
    let body = {
      limit: 1,
      end_date: moment(this.state?.postData?.story?.published_date).add(1, 'day').format("DD-MM-YYYY"),
      sort_by: "DESC",
      sort_on: "published_date",
      start_date: moment(this.state?.postData?.story?.published_date).format("DD-MM-YYYY"),

    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            explainerNudgeStoryData: res.data.editions[0],
          });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  }

  getGiftSubData = () => {
    const { token } = this.state;
    let endpoint = `/check-subs-left`;
    let body = {
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          console.log("SUB DATA ---->>>>", res)
          this.setState({
            subsLeft: res.data.subs_left,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  }

  componentDidMount = async () => {
    ChargebeeController.activatedController().then((cb_handle) => {
      this.setState({ cb_handle: cb_handle });
      let countryInfo = null;
      if (
        cb_handle?.instance?.billingInfo?.billing_platform === "chargebee_india"
      ) {
        countryInfo = {
          code: "IN",
          name: "India",
        };
      } else if (cb_handle?.instance?.billingInfo) {
        countryInfo = {
          code: "US",
          name: "USA",
        };
      }

      if (countryInfo) {
        this.setState({
          locationInfo: { countryInfo: countryInfo },
        });
      }
    });
    let location = window.location.search;
    if (location === "?type=short") {
      this.setState({ isShortEnabled: true });
    }
    await this.getArchivedStories();
    await this.getExclusiveContent();
    this.getStaticContent();
    this.getGiftSubData()
    this.getWriters();
    this.getWritersD();
    this.getCurrentNewsExplainer()
    await this.getHomeContent(["newsletters"]);
    this.getTrending();
    try {
      Mixpanel.time_event("Story page scrolled");
    } catch (error) {
      console.log("mix panel error ==>", error);
    }
    this.getPost(this.props.scrollTop ? true : false, true);
    if (!this.state.postDataSSR) {
      // this.getPost(this.props.scrollTop ? true : false);
      ChargebeeController.activatedController().then((cb_handle) => {
        this.setState({ cb_handle: cb_handle });
        let countryInfo = null;
        if (
          cb_handle?.instance?.billingInfo?.billing_platform === "chargebee_india"
        ) {
          countryInfo = {
            code: "IN",
            name: "India",
          };
        } else if (cb_handle?.instance?.billingInfo) {
          countryInfo = {
            code: "US",
            name: "USA",
          };
        }

        if (countryInfo) {
          this.setState({
            locationInfo: { countryInfo: countryInfo },
          });
        }
        if (!this.state.token) {
          this.getLocationInfo()
        }
      });
    } else {
      this.trackStoryClick(this.state.postData);

      if (this.state.token) {
        this.getStoryActions(this.state.postData);
      } else {
        this.setState({
          actionsLoader: false,
        });
        this.getLocationInfo();
      }
      if (this.state.userLoader) {
        this.getUserInfo(this.state.postData);
      } else if (this.state.plansLoader) {
        this.getSubscriptionPlans();
      }
    }

    if (globalThis.giftStoryData) {
      setTimeout(() => {
        this.setState({
          giftStoryData: globalThis.giftStoryData,
          showGiftClaimModal: true,
        });
        globalThis.giftStoryData = null;
      }, 1000);
    }
    if (!this.state.custom_user_id) {
      this.setAuthToken()
    }
    if (helpers.isWeb()) {
      this.scrollRef = window.addEventListener("scroll", this.getScrollOffset);
    }
  };
  getDataLayerProps = (data) => {
    const typesArray = data?.type?.map(item => {
      return item.name;
    })
    const pageProps = {
      event: "virtualpageview",
      page_location: window.location.href,
      page_title: document.title,
      article_category: this.state.categorySlug,
      newsletter_category: data?.newsletter?.name || "NA",
      article_id: parseInt(data?.story?.id),
      category_type: typesArray?.join(",")
    }
    return pageProps
  }
  getArticleClickProps = (data) => {
    const typesArray = data?.type?.map((item) => {
      return item.name.toLowerCase();
    });
    const authorArray = data?.author?.map((item) => {
      return item.name.toLowerCase();
    });
    let curPath = this.state.linkScreenName?.split("/").pop();
    const screenName = curPath ? curPath : "home"
    // Create a Moment.js object
    const momentDate = moment(data.story?.published_date).add(5, 'hours').add(30, 'minutes');

    // Format the date
    const formattedDate = momentDate.format("DD/MM/YY");
    let pageProps = {
      event: "article_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_category: this.state.categorySlug?.toLowerCase(),
      article_section: screenName?.toLowerCase(),
      article_name: data?.story?.title?.toLowerCase(),
      author_name: authorArray?.join(", "),
      published_date: formattedDate,
      scroll_depth: localStorage.getItem('scrollDepth'),
      category_type: typesArray?.join(","),
      article_type: data.is_free ? "free" : "paid",
    };
    if (data?.newsletter?.name) {
      pageProps = {
        event: "newsletter_click",
        custom_user_id: this.state.custom_user_id || "NA",
        user_status: this.state.custom_user_id ? "logged in" : "guest",
        newsletter_category: data?.newsletter?.name?.toLowerCase() || "NA",
        newsletter_section: screenName.toLowerCase(),
        newsletter_name: data?.story?.title?.toLowerCase(),
        author_name: authorArray?.join(", "),
        published_date: formattedDate,
        scroll_depth: localStorage.getItem('scrollDepth'),
        category_type: typesArray?.join(","),
        article_type: data.is_free ? "free" : "paid",
      }
    }
    return pageProps;
  }

  pushStoryNudgeInteractionToDataLayer = (data, cta, plan_name, plan_price) => {
    const dataLayer = window.dataLayer || [];
    const authorArray = data?.author?.map((item) => {
      return item.name.toLowerCase();
    });
    dataLayer.push({
      event: "nudge_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      plan_name: plan_name.toLowerCase(),
      plan_price: plan_price,
      article_name: data?.story?.title?.toLowerCase(),
      article_category: this.state.categorySlug?.toLowerCase(),
      article_section: "NA",
      author_name: authorArray?.join(", "),
      newsletter_category: data?.newsletter?.name?.toLowerCase() || "NA",
      newsletter_section: "NA",
      newsletter_name: data?.story?.title?.toLowerCase(),
      CTA_text: cta.toLowerCase(),
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  getLocationInfo = () => {
    console.log("Location 4")
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };
  getTrending = () => {
    this.setState({
      showErrorModal: false,
      pageLoader: true,
    });
    const { token } = this.state;
    let endpoint = `/stories`;
    let body = {
      limit: 1,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let trendingData = res.data.story;
          this.setState({
            whatsTrendingContent: trendingData[0],
          });
          this.getHomeData(trendingData[0]);
        } else {
          this.setState({
            showErrorModal: true,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showErrorModal: true,
        });
        console.log("Error=>> down", JSON.stringify(error));
      })
      .finally(() => {
        if (this.state.postDataSSR) {
          this.setState({
            pageLoader: false,
          });
        }
      });
  };
  getHomeData = (whatsTrending) => {
    const { token } = this.state;
    let endpoint = `/stories`;
    let body = {
      limit: 1,
      exclude: [whatsTrending.id],
      filters: [
        {
          key: "single_category",
          value: true,
        },
        {
          key: "is_privileged",
          value: true,
        },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          let trendingData = res.data.story;
          this.setState({
            latestStories: trendingData,
          });
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  componentDidUpdate = (prevProps) => {
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    let postSlug = webView
      ? this.props.postSlug
      : this.props.route.params.postSlug;
    if (
      (!(
        this.props.searchQuery &&
        (this.props.searchQuery.includes("subscribe=") ||
          this.props.searchQuery.includes("buy-credits=true"))
      ) &&
        ((token && token != this.state.token) ||
          ((token == undefined || token == "") && this.state.token != false) ||
          postSlug != this.state.postSlug)) ||
      (!helpers.isWeb() &&
        prevProps.app.refreshScreen != this.props.app.refreshScreen &&
        !this.props.navigation.isFocused())
    ) {
      let categorySlug = webView
        ? this.props.categorySlug
        : this.props.route.params.categorySlug;
      this.setState(
        {
          token: token == undefined || token == "" ? false : token,
          userLoggedin: token == undefined || token == "" ? false : true,
          categorySlug: categorySlug,
          postSlug: postSlug,
        },
        () => this.getPost(true)
      );
    }
    if (this.state.postData && !this.state.explainerNudgeStoryData) {
      this.getCurrentNewsExplainer()
    }
  };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentWillUnmount = () => {
    this.trackStoryScrolled();
    if (this.scrollRef) {
      this.scrollRef();
    }
    if (helpers.isWeb()) {
      window.removeEventListener("scroll", this.getScrollOffset);
    }
  };

  trackStoryScrolled = () => {
    try {
      if (!this.state.postData?.story?.title || !this.state.postUnlocked) {
        console.log(" ==> ", this.state.postData, this.state.postUnlocked);
        return;
      }
      console.log("Story Page Scrolled", {
        "Title of Story": this.state.postData?.story?.title,
        Percent: this.percentStoryScrolled,
      });
      window.webengage.track("Story Page Scrolled", {
        "Title of Story": this.state.postData?.story?.title,
        Percent: this.percentStoryScrolled,
      });
    } catch (error) {
      console.log("error while story scroll event", error);
    }
  };

  trackStoryClick = (data) => {
    try {
      if ((helpers.isWeb() && process.browser) || !helpers.isWeb()) {
        Mixpanel.track("click_article", {
          title: data.story.title,
          type: data.story.type,
          credit: data.story.credits.unlock,
          author: data.author[0].name,
          category: data.category.name,
          published_date: data.story.published_date,
        });
      }
    } catch (error) {
      console.log("mix panel error =>", error);
    }
  };

  // componentWillUnmount = () => {
  //     this.setState({
  //         showErrorModal: false,
  //     })
  // }

  trackStoryCLickWE = (postData) => {
    console.log("hello", this.state.linkScreenName);
    if (this.state.linkScreenName) {
      trackStoryOrNewsletter(postData, this.state.linkScreenName);
    }
  };

  setBreadcrumbs = (data) => {
    Breadcrumbs = [
      {
        name: "Home",
        link: "/",
      },
      {
        name: data.category.name,
        link: `/category/${data.category.slug}`,
      },
      {
        name: data.story.title,
      },
    ];
  };

  getPost = (scroll, pushToDataLayer) => {
    this.setState(
      {
        showErrorModal: false,
        postUnlockError: false,
        pageLoader: true,
      },
      () => this.scrollToTop(scroll)
    );
    const { token } = this.state;
    //     if(this.state.isGift && token){
    //   apiHelpers.apiRequest(`/redeem-gift-link`, {giftCode: this.props.history.location.search.split('giftCode=')[1]}, 'POST', true, token).then((data)=>{
    //     if(data && data.message === "success"){
    //       //reload the page without query params
    //       window.location = window.location.href.split("?")[0] + '?giftRedeemed';
    //     }else{
    //       //show link expired error and navigate to pricing page
    //       window.location.pathname = '/pricing'
    //     }
    //   })
    //   return
    // }else if(this.state.isGift){
    //         this.props.navigateToScreen(
    //     `/sign-in?redirect=/${this.state.categorySlug}/${
    //       this.state.postSlug
    //     }&redirect-params=giftCode=${this.props.history.location.search.split('giftCode=')[1]}`
    //   );
    //   return
    // }
    let endpoint = `/story/${this.state.postSlug}`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          if (this.state.isGift) {
            this.props.navigateToScreen(
              `/sign-in?redirect=/${this.state.categorySlug}/${this.state.postSlug
              }&redirect-params=giftCode=${this.props.history.location.search.split('giftCode=')[1]}`
            );
          }
          return false;
        }
        if (res.success) {
          if (this.state.isGift && !token) {
            this.props.navigateToScreen(
              `/sign-in?redirect=/${this.state.categorySlug}/${this.state.postSlug
              }&redirect-params=giftCode=${this.props.history.location.search.split('giftCode=')[1]}`
            );
          }
          this.trackStoryClick(res.data);
        }
        if (pushToDataLayer) {
          try {
            const pageViewData = this.getDataLayerProps(res.data)

            console.log("Data layer parameters: " + JSON.stringify(pageViewData))
            // Push initial pageview data
            const dataLayer = window.dataLayer || [];

            if (pageViewData) {
              dataLayer.push(pageViewData);
            }

            if (this.state.linkScreenName) {
              // Only if we have an article click or newsleterr click we want to add this, we don't want to add this when the page is reloaded for an article
              const articleData = this.getArticleClickProps(res.data)
              const dataLayer = window.dataLayer || []
              if (articleData) {
                dataLayer.push(articleData);
                localStorage.setItem("scrollDepth", 0);
              }

            }
          } catch (error) {
            console.log("Error while getting story params", error);
          }
        }
        if (res.success && res.code == "story_full_read") {
          try {
            this.trackStoryCLickWE(res.data);
            Mixpanel.track("Story loaded", {
              title: res.data.story.title,
              access: "full",
              author: res.data.author[0].name,
              category: res.data.category.name,
              company: res.data.company ? res.data.company[0].name : false,
              tag: res.data.tag ? res.data.tag[0].name : false,
              theme: res.data.storyplay ? res.data.storyplay.name : false,
              published_date: res.data.story.published_date,
              newsletter: res.data.newsletter
                ? res.data.newsletter.name
                : false,
              unlock: res.data?.unlock_data?.type
                ? res.data?.unlock_data.type
                : false,
            });
          } catch (error) {
            console.log("mix panel error =>", error);
          }
          this.setState({
            postData: res.data,
            linkPostData: false,
            postLists: false,
            postUnlocked: true,
            userSubscriptionActive: true,
            userSubscriptionStatus: false,
            userSubscription: false,
            pageLoader: false,
            articlesLoader: true,
            statsLoader: true,
          });
          // this.getStoryStats(res.data);
          this.getOtherArticles(res.data, true);
          this.setBreadcrumbs(res.data);
          if (token) {
            this.getStoryActions(res.data);
            this.getUserInfo(res.data);
          } else {
            this.setState({
              actionsLoader: false,
            });
          }
        } else if (res.success && res.code == "story_partial_read") {
          /**
           * Checking if the given url is gift url
           */
          if (this.state.isGift && token) {
            apiHelpers.apiRequest(`/redeem-gift-link`, { giftCode: this.props.history.location.search.split('giftCode=')[1] }, 'POST', true, token).then((data) => {
              if (data && data.message === "success") {
                //reload the page without query params
                window.location = window.location.href.split("?")[0] + '?giftRedeemed';
              } else {
                //show link expired error and navigate to pricing page
                window.location.pathname = '/pricing'
              }
            })
            return
          }


          try {
            this.trackStoryCLickWE(res.data);
            Mixpanel.track("Story loaded", {
              title: res.data.story.title,
              access: "partial",
              author: res.data.author[0].name,
              category: res.data.category.name,
              company: res.data.company ? res.data.company[0].name : false,
              tag: res.data.tag ? res.data.tag[0].name : false,
              theme: res.data.storyplay ? res.data.storyplay.name : false,
              published_date: res.data.story.published_date,
              newsletter: res.data.newsletter
                ? res.data.newsletter.name
                : false,
            });
          } catch (error) {
            console.log("mix panel error =>", error);
          }
          if (token) {
            this.setState({
              postData: res.data,
              postUnlocked: false,
              postLists: false,
              pageLoader: false,
              userLoader: true,
              plansLoader: true,
              articlesLoader: true,
              statsLoader: true,
            });
            this.getStoryActions(res.data);
            this.getUserInfo(res.data);
          } else {
            this.setState({
              postData: res.data,
              postUnlocked: false,
              postLists: false,
              pageLoader: false,
              plansLoader: true,
              articlesLoader: true,
              statsLoader: true,
              actionsLoader: false,
            });
            // this.getSubscriptionPlans();
          }
          // this.getStoryStats(res.data);
          this.getOtherArticles(res.data, false);
          this.setBreadcrumbs(res.data);
        } else if (res.code == "invalid_slug") {
          this.setState({ noPageFound: true });
        } else {
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getOtherArticles = async (postData, isUnlocked) => {
    let endpoint = `/stories`;
    let body = {
      limit: 3,
      page: 1,
      sort_on: "date",
      sort_by: "desc",
      exclude: [postData.story.id],
    };
    if (postData.newsletter) {
      body["filters"] = [
        {
          key: "newsletter",
          value: [postData.newsletter.slug],
        },
      ];
    } else {
      body["filters"] = [
        {
          key: "related_stories",
          value: postData.story.id,
        },
      ];
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState({
            postLists: res.data.story,
            articlesLoader: false,
          });
        } else {
          this.setState({
            articlesLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          articlesLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getUserInfo = (postData) => {
    const { token } = this.state;
    let endpoint = `/profile`;
    let body = {
      request_group: ["subscription", "credits", "profile"],
    };
    let is_coin_reedemed = localStorage.getItem("is_coin_reedemed");
    console.log("is_coin_reedemed:", is_coin_reedemed);
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          this.authTokenExpired();
          this.setState({
            userLoader: false,
          });
          return false;
        }
        if (res.success) {
          this.setState({ profileInfo: res.data })
          let privilegedCredits = res.data.credits.privileged_credits;
          let nonPrivilegedCredits = res.data.credits.non_privileged_credits;
          console.log("credits:", res.data);
          let showSubscribeModal = false;
          if (
            (!res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}") &&
            is_coin_reedemed != "1" &&
            privilegedCredits.available == 0 &&
            !this.state.postUnlocked
          ) {
            showSubscribeModal = true;
          } else {
            showSubscribeModal = false;
            localStorage.setItem("is_coin_reedemed", "0");
          }
          this.setState({
            privCredits: privilegedCredits.available,
            starterCredits: nonPrivilegedCredits,
            userSubscriptionActive:
              (res.data.subscription &&
                JSON.stringify(res.data.subscription) != "{}" &&
                res.data.subscription.status != "expired") ||
                (res.data.team_subscription &&
                  JSON.stringify(res.data.team_subscription) != "{}" &&
                  res.data.team_subscription.status != "expired" &&
                  res.data.team_subscription.is_team_member)
                ? true
                : false,
            userSubscriptionStatus:
              !res.data.subscription ||
                JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription.status,
            userSubscription:
              !res.data.subscription ||
                JSON.stringify(res.data.subscription) == "{}"
                ? false
                : { ...res.data.subscription, document_status: res.data.document_status }, 
            showSubscribeModal: showSubscribeModal,
            teamSubscription:
              !res.data.team_subscription ||
                JSON.stringify(res.data.team_subscription) == "{}"
                ? false
                : res.data.team_subscription,
            userCredit:
              postData.story.type == "privileged"
                ? privilegedCredits.available
                : privilegedCredits.available + nonPrivilegedCredits.available,
            userLoader: false,
            freeCreditsAvailable: res.data.free_credits_available,
            userSubscriptionExpired:
              (res.data.team_subscription &&
                !this.isObjectEmpty(res.data.team_subscription)) ||
                (res.data.subscription &&
                  !this.isObjectEmpty(res.data.subscription)) ||
                (res.data.expired_subscription &&
                  !this.isObjectEmpty(res.data.team_subscription))
                ? true
                : false,
            userProfile: res.data
          });
          this.getSubscriptionPlans(res.data)
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            userLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          userLoader: false,
        });
      });
  };

  getSubscriptionPlans = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    let body = {
      filters: {
        tmc_group: ["individual", "free"],
        // billing_entity_id: ["credit-plan-usd","credit-plan-inr"]
      },
      rank_sort: "ASC",
    };
    // if (profileData && profileData?.subscription?.plan) {
    //   body.filters.plan_gte = profileData.subscription.plan.id;
    // }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          let plansData =
            profileData && profileData?.team_subscription?.is_team_member
              ? []
              : res.data.plans.individual;
          let monthly_price = plansData.filter(
            (item) => item?.name == "Annual Plan"
          );
          this.setState({
            creditPlan: null,
            plansData: plansData,
            country: res.data.country,
            plansLoader: false,
            monthlyData: monthly_price.length > 0 ? monthly_price : [],
          });
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            plansLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          plansLoader: false,
        });
      });
  };

  getStoryStats = async (postData) => {
    let endpoint = `/story-stats`;
    let body = {
      story_id: postData.story.id,
      story_slug: postData.story.slug,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "story_stats") {
          this.setState({
            storyStats: res.data.label,
            statsLoader: false,
          });
        } else {
          this.setState({
            statsLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          statsLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  getStoryActions = async (postData) => {
    const { redirectParams, token } = this.state;
    if (redirectParams && token) {
      this.splitParamsAndAppyAction(redirectParams);
      return false;
    }
    let endpoint = `/user-story-action-data`;
    let body = {
      story_id: postData.story.id,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success && res.code == "story_info") {
          this.setState({
            storyActions: JSON.parse(JSON.stringify(res.data)),
            actionsLoader: false,
            oldStoryActions: JSON.parse(JSON.stringify(res.data)),
          });
        } else {
          this.setState({
            actionsLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          actionsLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  applyStoryActions = (type, value, animation, animateFunc) => {
    const { postData, token, storyActions } = this.state;
    if (token) {
      if (storyActions) {
        let tempStoryActions = JSON.parse(JSON.stringify(storyActions));
        tempStoryActions[type] = value;
        let stateObj = {
          storyActions: tempStoryActions,
        };
        if (type == "bookmark" && animation) {
          stateObj["bookmarkLoading"] = value ? "on" : "off";
        }
        if (type == "like" && animation) {
          stateObj["likeLoading"] = true;
        }
        this.setState(stateObj, () => {
          if (animation) {
            animateFunc();
          }
        });
      }
      let endpoint = `/user-story-action`;
      let body = {
        story_id: postData.story.id,
        type: type,
        value: value,
      };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.success && res.code == "user_story_action") {
            try {
              if (type == "bookmark" && value === true) {
                console.log("Story Bookmarked", {
                  "Title of story": postData.story.title,
                });
                window.webengage.track("Story Bookmarked", {
                  "Title of story": postData.story.title,
                });
                Mixpanel.track("clicked_bookmark", {
                  story_title: postData.story.title,
                  story_slug: postData.story.slug,
                });
                if (animation) {
                  Mixpanel.track("clicked_bookmark_story", {
                    story_title: postData.story.title,
                    story_slug: postData.story.slug,
                  });
                }
              } else if (type == "like" && value === true) {
                console.log("Story Liked");
                window.webengage.track("Story Liked", {
                  "Title of story": postData.story.title,
                });
                console.log({
                  "Title of story": postData.story.title,
                });
                Mixpanel.track("clicked_like", {
                  story_title: postData.story.title,
                  story_slug: postData.story.slug,
                });
                if (animation) {
                  Mixpanel.track("clicked_like_story", {
                    story_title: postData.story.title,
                    story_slug: postData.story.slug,
                  });
                }
              }
            } catch (error) {
              console.log("mix panel error =>", error);
            }

            this.setState({
              storyActions: JSON.parse(JSON.stringify(res.data)),
              oldStoryActions: JSON.parse(JSON.stringify(res.data)),
              actionsLoader: false,
              likeLoading: false,
              bookmarkLoading: false,
            });
            if (type == "bookmark") {
              this.refs.header.animateReadingList();
            }
            this.props.refreshScreen(!this.props.app.refreshScreen);
          } else {
            this.setState({
              storyActions: JSON.parse(
                JSON.stringify(this.state.oldStoryActions)
              ),
              actionsLoader: false,
              likeLoading: false,
              bookmarkLoading: false,
            });
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          this.setState({
            storyActions: JSON.parse(
              JSON.stringify(this.state.oldStoryActions)
            ),
            actionsLoader: false,
            likeLoading: false,
            bookmarkLoading: false,
          });
          console.log("Error=>> down", JSON.stringify(error));
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          `/sign-in?redirect=/${this.state.categorySlug}/${this.state.postSlug
          }&redirect-params=action=${type}+value=${true}`
        );
      } else {
        this.props.navigation.push("/sign-in", {
          redirect: "post-single",
          categorySlug: this.state.categorySlug,
          postSlug: this.state.postSlug,
          verifyRedirect: `/sign-in?redirect=/${this.state.categorySlug}/${this.state.postSlug
            }&redirect-params=action=${type}+value=${true}`,
          "redirect-params": `action=${type}+value=${true}`,
        });
      }
    }
  };

  splitParamsAndAppyAction = (redirectParams) => {
    let paramsArr = redirectParams.split("+");
    console.log("check paramsArr ==>", paramsArr);
    let action = paramsArr.find((searchParam) => {
      return searchParam.includes("action=");
    });
    let value = paramsArr.find((searchParam) => {
      return searchParam.includes("value=");
    });

    if (action && value) {
      action = action.replace("action=", "");
      value = value.replace("value=", "");
      value = value === "true" ? true : false;
      this.applyStoryActions(action, value);
    }
    this.setState({
      redirectParams: false,
    });
  };

  getWritersD = () => {
    this.setState({ showErrorModal: false });
    const { token } = this.state;
    let endpoint = `/authors`;
    let body = {
      limit: 3,
      sort_on: "posts",
      sort_by: "desc",
      filters: [{ key: "roles", value: ["editor"] }],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          this.setState({ writerData: res.data.author });
        } else {
          // this.setState({ showErrorModal: true, pageLoader: false });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        // this.setState({ showErrorModal: true, pageLoader: false });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
    } else {
      this.props.removeUserToken();
    }
    this.setState(
      {
        token: false,
        userLoggedin: false,
      },
      () => this.getPost()
    );
  };

  pushShareStoryEventToDataLayer = (shareType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_share",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      share_type: shareType,
      article_name: this.state.postData?.story?.title,
      article_category: this.state.postData?.category?.slug,
      article_section: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  sharePost = (slug) => {
    if (helpers.isWeb()) {
      let path = window.location.origin + window.location.pathname;
      if (slug == "fb") {
        this.pushShareStoryEventToDataLayer("Facebook")
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${path}&quote=${this.state.postData.story.title}`,
          "_blank"
        );
      } else if (slug == "twitter") {
        this.pushShareStoryEventToDataLayer("twitter")
        window.open(
          `https://twitter.com/intent/tweet?text=${this.state.postData.story.title}&url=${path}`,
          "_blank"
        );
      } else if (slug == "linkedin") {
        this.pushShareStoryEventToDataLayer("linkedin")
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${path}&summary=${this.state.postData.story.title}`,
          "_blank"
        );
      } else {
        navigator
          .share({
            title: this.state.postData.story.title,
            text: this.state.postData.story.title,
            url: path,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      }
    } else {
      //console.log('Successful share')
      let { postData } = this.state;
      Share.share({
        message: `${helpers.siteURL}/${postData.category.slug}/${postData.story.slug}`,
      });
    }
  };

  getScrollOffset = (event) => {
    let scrollCheck, scrollInitCheck, hideHeader, hideSideShareActionButton;
    let isPopupEnabled;
    let scrollEnabled;

    if (window.scrollY <= 200) {
      document.body.style.overflow = "visible";
      isPopupEnabled = false;
      scrollEnabled = true;
    } else {
      scrollEnabled = false;
      isPopupEnabled = true;
      // }
    }
    this.setState({
      isPopupEnabled: isPopupEnabled,
      scrollEnabled: scrollEnabled,
    });

    // }
    // }

    if (helpers.isWeb()) {
      scrollInitCheck = window.pageYOffset;
      scrollCheck = window.pageYOffset + Dimensions.get("window").height;
    } else {
      scrollInitCheck = event.nativeEvent.contentOffset.y;
      scrollCheck =
        event.nativeEvent.contentOffset.y + Dimensions.get("window").height;
    }

    if (
      scrollInitCheck > 100 &&
      scrollCheck <
      this.viewOffset -
      (this.footerOffset + this.relatedStoriesOffset + 50) &&
      scrollCheck >= this.state.scrollCheck
    ) {
      hideHeader = true;
    } else {
      hideHeader = false;
    }

    if (
      scrollInitCheck < this.StoriesSummaryOffset ||
      scrollCheck >
      this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + this.moreStoriesSectionOffset + 50)
    ) {
      hideSideShareActionButton = true;
    } else {
      hideSideShareActionButton = false;
    }

    try {
      let scrollCheckValue =
        this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50);
      let storyLength =
        this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50);
      if (
        this.state.pageScrolled != true &&
        this.state.postData &&
        this.state.postData != "" &&
        scrollCheck > scrollCheckValue &&
        this.state.scrollCheck <= scrollCheckValue
      ) {
        this.setState(
          {
            pageScrolled: true,
          },
          () => {
            Mixpanel.track("Story page scrolled", {
              title: this.state.postData.story.title,
            });
          }
        );
      }
      if (
        this.state.postData &&
        this.state.postData != "" &&
        scrollCheck < storyLength
      ) {
        this.percentStoryScrolled =
          (scrollCheck / storyLength).toFixed(2) * 100;
      }
    } catch (error) {
      console.log("mix panel error =>", error);
    }

    this.setState({
      hideHeader: hideHeader,
      scrollCheck: scrollCheck,
      hideSideShareActionButton: hideSideShareActionButton,
    });
  };

  // getScrollOffset(event) {
  //   const isWeb = helpers.isWeb();
  //   const scrollInitCheck = isWeb ? window.pageYOffset : event.nativeEvent.contentOffset.y;
  //   const scrollCheck = scrollInitCheck + Dimensions.get("window").height;

  //   // Determine new values
  //   const newScrollEnabled = scrollInitCheck <= 200;
  //   const newIsPopupEnabled = !newScrollEnabled;
  //   const newHideHeader = scrollInitCheck > 100 &&
  //     scrollCheck < this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50) &&
  //     scrollCheck >= this.state.scrollCheck;
  //   const newHideSideShareActionButton = scrollInitCheck < this.StoriesSummaryOffset ||
  //     scrollCheck > this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50);

  //   // Handle pageScrolled and percentStoryScrolled
  //   let newPageScrolled = this.state?.pageScrolled;
  //   if (this.state?.pageScrolled !== true &&
  //     this.state.postData &&
  //     scrollCheck > this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50) &&
  //     this.state.scrollCheck <= this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50)) {
  //     newPageScrolled = true;
  //     Mixpanel.track("Story page scrolled", { title: this.state.postData.story.title });
  //   }

  //   let percentStoryScrolled = this.percentStoryScrolled;
  //   if (this.state.postData && scrollCheck < this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50)) {
  //     percentStoryScrolled = ((scrollCheck / (this.viewOffset - (this.footerOffset + this.relatedStoriesOffset + 50))) * 100).toFixed(2);
  //   }

  //   // Only update state if necessary
  //   this.setState(prevState => {
  //     const stateUpdate = {
  //       isPopupEnabled: newIsPopupEnabled,
  //       scrollEnabled: newScrollEnabled,
  //       hideHeader: newHideHeader,
  //       scrollCheck: scrollCheck,
  //       hideSideShareActionButton: newHideSideShareActionButton,
  //       pageScrolled: newPageScrolled,
  //     };

  //     // Check if there's any actual change in state
  //     if (JSON.stringify(prevState) === JSON.stringify(stateUpdate)) {
  //       return null;
  //     }

  //     return stateUpdate;
  //   });
  // }


  closeModal = () => {
    document.body.style.overflow = "visible";
    this.setState({ isPopupEnabled: false });
  };

  // applyRef = (ref) => {
  //   if (helpers.isWeb()) {
  //     this.scrollRef = window.addEventListener("scroll", this.getScrollOffset);
  //   }
  // };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      } else {
        if (this.appscrollview) {
          setTimeout(() => {
            this.appscrollview.scrollTo({ y: 0, animated: true });
          });
        }
        if (this.contentscrollview) {
          this.contentscrollview.scrollTo({ y: 0, animated: true });
        }
      }
    }
  };

  actionBoxLOader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let contentBg = darkMode ? "#121212" : "#ffffff";
    return (
      <View
        style={[
          actionBox.box,
          actionBox.boxDesktop,
          Padding.ph_2,
          { backgroundColor: contentBg },
        ]}
      >
        <ContentLoader
          containerStyles={{ marginBottom: 0 }}
          active={true}
          pHeight={[30]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />

        <View style={{ width: "30%", marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 15 }}
            active={true}
            pHeight={[2]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>

        <View style={{ marginBottom: 15 }}>
          <ContentLoader
            containerStyles={{ marginBottom: 0 }}
            active={true}
            pHeight={[10]}
            title={false}
            pRows={3}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[30]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
      </View>
    );
  };

  subscriptionBoxLOader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let contentBg = darkMode ? "#121212" : "#ffffff";
    return (
      <View
        style={[
          actionBox.box,
          actionBox.boxDesktop,
          Padding.ph_2,
          Flex.fill,
          Margin.mb_4,
          { backgroundColor: contentBg },
        ]}
      >
        <View style={{ width: 100, marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 0 }}
            active={true}
            pHeight={[20]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={{ width: 100, marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 15 }}
            active={true}
            pHeight={[45]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View style={{ width: 120, marginLeft: "auto", marginRight: "auto" }}>
          <ContentLoader
            containerStyles={{ marginBottom: 15 }}
            active={true}
            pHeight={[35]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>

        <View style={{ marginBottom: 15 }}>
          <ContentLoader
            containerStyles={{ marginBottom: 0 }}
            active={true}
            pHeight={[10]}
            title={false}
            pRows={3}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <ContentLoader
          containerStyles={{ marginBottom: 5 }}
          active={true}
          pHeight={[46]}
          title={false}
          pRows={1}
          pWidth={["100%"]}
          animationDuration={400}
          primaryColor={
            darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
          }
          secondaryColor={
            darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
          }
        />
      </View>
    );
  };

  actionSubscriptionBoxLOader = () => {
    return (
      <>
        {this.actionSubscriptionTopLOader()}
        {this.actionSubscriptionBottomLOader()}
      </>
    );
  };

  actionSubscriptionTopLOader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Padding.ph_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb700,
          { marginBottom: 50 },
        ]}
      >
        {this.actionBoxLOader()}
        <View
          style={[
            {
              marginTop: 20,
              width: 100,
              marginLeft: "auto",
              marginRight: "auto",
            },
            Flex.alignCenter,
          ]}
        >
          <ContentLoader
            containerStyles={{
              marginBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            active={true}
            pHeight={[10]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        <View
          style={[
            {
              marginTop: 10,
              width: 150,
              marginLeft: "auto",
              marginRight: "auto",
            },
            Flex.alignCenter,
          ]}
        >
          <ContentLoader
            containerStyles={{
              marginBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            active={true}
            pHeight={[45]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
      </View>
    );
  };

  actionSubscriptionBottomLOader = () => {
    return (
      <View
        style={[
          Padding.ph_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb800,
          { flexDirection: helpers.isDesktop() ? "row" : "column" },
        ]}
      >
        <View
          style={{ marginHorizontal: helpers.isDesktop() ? 10 : 0, flex: 1 }}
        >
          {this.subscriptionBoxLOader()}
        </View>
        <View
          style={{ marginHorizontal: helpers.isDesktop() ? 10 : 0, flex: 1 }}
        >
          {this.subscriptionBoxLOader()}
        </View>
      </View>
    );
  };

  articleListsLoader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View style={[Flex.fill, { marginBottom: 40 }]}>
        <View style={[articleLists.listWrap]}>
          <View style={{ width: 100 }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingLeft: 0 }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View>
            <ContentLoader
              containerStyles={{
                marginBottom: 0,
                paddingLeft: 0,
                marginTop: 0,
              }}
              active={true}
              pHeight={[300]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View style={{ width: 200, marginTop: 10 }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingLeft: 0 }}
              active={true}
              pHeight={[40]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          <View
            style={[articleLists.lineBreak, Margin.mt_1, Margin.mb_15]}
          ></View>
          <View style={{ marginTop: 0 }}>
            <ContentLoader
              containerStyles={{ marginBottom: 0, paddingLeft: 0 }}
              active={true}
              pHeight={[20]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
        </View>
      </View>
    );
  };

  articleListsWrapLoader = () => {
    return (
      <>
        {helpers.isDesktop() ? (
          <View style={{ flexDirection: "row" }}>
            {this.articleListsLoader()}
            {this.articleListsLoader()}
            {this.articleListsLoader()}
          </View>
        ) : (
          <View>{this.articleListsLoader()}</View>
        )}
      </>
    );
  };
  segregateWriters = (writerList) => {
    let writers = [],
      contributors = [];
    writerList.map((writer) => {
      if (writer.roles.includes("contributor")) {
        contributors.push(writer);
      } else {
        writers.push(writer);
      }
    });
    return { writers: writers, contributors: contributors };
  };
  getWriters = () => {
    this.setState({
      pageLoader: true,
    });

    let endpoint = `/authors`;
    let body = {
      sort_on: "name",
      sort_by: "asc",
      filters: [{ key: "roles", value: ["editor"] }],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true)
      .then((res) => {
        if (res.success && res.code == "author_found") {
          console.log("writers data", res.data.author);
          // let writerData = this.sortWriter(res.data.author);
          let allWriters = this.segregateWriters(res.data.author);
          console.log(allWriters, "allWriters");
          this.setState({
            writers: allWriters.writers,
          });
        } else {
          this.setState({
            pageLoader: false,
          });
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
    // .finally(() => {
    //   if(this.state.postDataSSR){
    //     this.setState({
    //       pageLoader: false,
    //     });
    //   }
    // });
  };
  renderWriters = () => {
    let { homeContent } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let data = this.state.writers;
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [],
      data5 = [],
      data6 = [];
    Array.from(data)
      .slice(0, 3)
      .map((item, index) => {
        data1.push(item);
      });
    Array.from(data)
      .slice(3, 6)
      .map((item, index) => {
        data2.push(item);
      });
    Array.from(data)
      .slice(6, 9)
      .map((item, index) => {
        data3.push(item);
      });
    Array.from(data)
      .slice(9, 12)
      .map((item, index) => {
        data4.push(item);
      });
    Array.from(data)
      .slice(12, 15)
      .map((item, index) => {
        data5.push(item);
      });
    Array.from(data)
      .slice(15, 18)
      .map((item, index) => {
        data6.push(item);
      });
    return (
      <WritersSlider
        categorySlug={"writers"}
        data={data}
        data1={data1}
        data2={data2}
        data3={data3}
        data4={data4}
        data5={data5}
        data6={data6}
        viewArticle={(catSlug, slug, postData) =>
          this.viewArticle(catSlug, slug, postData)
        }
        viewAuthor={(slug) => this.viewAuthor(slug)}
        viewCategory={(slug) => this.viewCategory(slug)}
        getNewsletterClick={(slug) => this.viewAuthor(slug)}
        home={true}
        darkMode={darkMode}
        userProfile={this.state.userProfile}
      />
    );
  };
  listsLoader = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Padding.ph_2,
          Helpers.conatinerWeb,
          helpers.isDesktop() && Helpers.conatinerWeb1000,
          articleLoader.LoaderWrapper,
        ]}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ContentLoader
            containerStyles={{ marginBottom: 40 }}
            active={true}
            pHeight={[30]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        </View>
        {this.articleListsWrapLoader()}
      </View>
    );
  };

  showPageLoader = () => {
    console.log("Debugging page loader..........1")

    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let contentBg = darkMode ? "#121212" : "#ffffff";
    const { linkPostData } = this.state;
    let postSummary;
    if (linkPostData) {
      postSummary = {
        featured_image: linkPostData.featured_image,
        category: linkPostData.category,
        newsletter: linkPostData.newsletter,
        postTitle: linkPostData.title,
        author: linkPostData.author,
        story: linkPostData,
        storyStats: false,
        statsLoader: true,
        lazyLoad: true,
        loaderSummary: true,
        type: linkPostData.type
      };
    }
    return (
      <View
        style={{ position: "relative", zIndex: 2, backgroundColor: contentBg }}
      >
        {postSummary ? (
          <PostSummary
            actionsLoader={true}
            data={postSummary}
            getCategoryClick={(slug) => this.navigateToCategory(slug)}
            getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
            getAuthorClick={(slug) => this.navigateToAuthor(slug)}
            darkMode={darkMode}
            setScrollEnabled={(value) => {
              this.setState({ scrollEnabled: value });
              if (this.appscrollview) {
                this.appscrollview.scrollTo({ y: 0, animated: true });
              }
              if (this.contentscrollview) {
                this.contentscrollview.scrollTo({ y: 0, animated: true });
              }
            }}
            token={this.state.token}
            profileInfo={this.state.profileInfo}
            openGiftSubModal={() =>
              this.refs.header.openGiftSubModal(
                PrivilegeCreditGift.id,
                PrivilegeCreditGift,
                null,
                null,
                postData,
                postData?.story?.credits?.unlock
              )
            }
          />
        ) : (
          <ContentLoader
            containerStyles={{
              paddingHorizontal: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
            active={true}
            pHeight={[400]}
            title={false}
            pRows={1}
            pWidth={["100%"]}
            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
            animationDuration={400}
            primaryColor={
              darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
            }
            secondaryColor={
              darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
            }
          />
        )}
        <View
          style={[
            postSingle.summaryWrapper,
            helpers.isDesktop() && postSingle.summaryWrapperDesktop,
            Helpers.conatinerWeb,
            helpers.isDesktop() && Helpers.conatinerWeb1200,
            { marginBottom: 0, paddingRight: 0, paddingLeft: 0 },
            linkPostData && { marginTop: 10 },
          ]}
        >
          {linkPostData == false && (
            <View
              style={{
                paddingTop: 20,
                backgroundColor: contentBg,
                maxWidth: helpers.isDesktop() ? "100%" : "90%",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {!helpers.isDesktop() && (
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: helpers.isDesktop() ? "140px" : "70%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <ContentLoader
                    containerStyles={{ marginBottom: 10 }}
                    active={true}
                    pHeight={[10]}
                    title={false}
                    pRows={1}
                    pWidth={["100%"]}
                    animationDuration={400}
                    primaryColor={
                      darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                    }
                    secondaryColor={
                      darkMode
                        ? "rgba(45, 48, 45,0.8)"
                        : "rgba(200, 200, 200, 1)"
                    }
                  />
                </View>
              )}

              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: helpers.isDesktop() ? "100px" : "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 15 }}
                  active={true}
                  pHeight={[16]}
                  title={false}
                  pRows={1}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 15 }}
                  active={true}
                  pHeight={[50]}
                  title={false}
                  pRows={1}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 15 }}
                  active={true}
                  pHeight={[20]}
                  title={false}
                  pRows={3}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                  { flexDirection: "row" },
                ]}
              >
                <View style={{ flex: 0.2 }}>
                  <ContentLoader
                    active
                    avatar
                    pRows={0}
                    primaryColor={
                      darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                    }
                    secondaryColor={
                      darkMode
                        ? "rgba(45, 48, 45,0.8)"
                        : "rgba(200, 200, 200, 1)"
                    }
                  />
                </View>
                <View style={{ flex: 0.6 }}>
                  <ContentLoader
                    containerStyles={{ marginBottom: 0 }}
                    active={true}
                    pHeight={[20]}
                    title={false}
                    pRows={1}
                    pWidth={["100%"]}
                    animationDuration={400}
                    primaryColor={
                      darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                    }
                    secondaryColor={
                      darkMode
                        ? "rgba(45, 48, 45,0.8)"
                        : "rgba(200, 200, 200, 1)"
                    }
                  />
                  <ContentLoader
                    containerStyles={{ marginBottom: 15 }}
                    active={true}
                    pHeight={[10]}
                    title={false}
                    pRows={1}
                    pWidth={[30]}
                    animationDuration={400}
                    primaryColor={
                      darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                    }
                    secondaryColor={
                      darkMode
                        ? "rgba(45, 48, 45,0.8)"
                        : "rgba(200, 200, 200, 1)"
                    }
                  />
                </View>
                <View style={{ flex: 0.2 }}>
                  <ContentLoader
                    containerStyles={{ marginBottom: 0 }}
                    active={true}
                    pHeight={[30]}
                    title={false}
                    pRows={1}
                    pWidth={["100%"]}
                    animationDuration={400}
                    primaryColor={
                      darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                    }
                    secondaryColor={
                      darkMode
                        ? "rgba(45, 48, 45,0.8)"
                        : "rgba(200, 200, 200, 1)"
                    }
                  />
                </View>
              </View>
              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                ]}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 40 }}
                  active={true}
                  pHeight={[20]}
                  title={false}
                  pRows={1}
                  pWidth={[50]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
            </View>
          )}

          <View
            style={[
              Padding.ph_2,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb680,
            ]}
          >
            <ContentLoader
              containerStyles={{
                marginBottom: 50,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              active={true}
              pHeight={[10]}
              title={false}
              pRows={15}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
          </View>
          {this.actionSubscriptionBoxLOader()}

          <View
            style={[
              Padding.ph_2,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb800,
              accessContent.LoaderWrapper,
            ]}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ContentLoader
                containerStyles={{ marginBottom: 10 }}
                active={true}
                pHeight={[20]}
                title={false}
                pRows={1}
                pWidth={["100%"]}
                animationDuration={400}
                primaryColor={
                  darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                }
                secondaryColor={
                  darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                }
              />
            </View>
            <ContentLoader
              containerStyles={{ marginBottom: 10 }}
              active={true}
              pHeight={[8]}
              title={false}
              pRows={1}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <View style={[Flex.row, Margin.mt_0]}>
              <View
                style={[
                  exclusiveContent.borderRight,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  Padding.pt_3,
                  Padding.pb_3,
                  Flex.col_5,
                  { width: "50%", alignItems: "center" },
                ]}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 0 }}
                  active={true}
                  pHeight={[40]}
                  title={false}
                  pRows={1}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
              <View
                style={[
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  Padding.pt_3,
                  Padding.pb_3,
                  Flex.col_5,
                  { width: "50%", alignItems: "center" },
                ]}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 0 }}
                  active={true}
                  pHeight={[40]}
                  title={false}
                  pRows={1}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
            </View>
            <View style={[Flex.row]}>
              <View
                style={[
                  exclusiveContent.borderTop,
                  exclusiveContent.borderRight,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  Padding.pt_3,
                  Padding.pb_3,
                  Flex.col_5,
                  { width: "50%" },
                ]}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 0 }}
                  active={true}
                  pHeight={[40]}
                  title={false}
                  pRows={1}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
              <View
                style={[
                  exclusiveContent.borderTop,
                  Flex.alignCenter,
                  Flex.justfiyCenter,
                  Padding.pt_3,
                  Padding.pb_3,
                  Flex.col_5,
                  { width: "50%" },
                ]}
              >
                <ContentLoader
                  containerStyles={{ marginBottom: 0 }}
                  active={true}
                  pHeight={[40]}
                  title={false}
                  pRows={1}
                  pWidth={["100%"]}
                  animationDuration={400}
                  primaryColor={
                    darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                  }
                  secondaryColor={
                    darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                  }
                />
              </View>
            </View>
          </View>
          <View
            style={[
              Padding.ph_2,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb500,
              textBlock.LoaderWrapper,
            ]}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ContentLoader
                containerStyles={{ marginBottom: 10 }}
                active={true}
                pHeight={[20]}
                title={false}
                pRows={1}
                pWidth={["100%"]}
                animationDuration={400}
                primaryColor={
                  darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                }
                secondaryColor={
                  darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                }
              />
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "30%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ContentLoader
                containerStyles={{ marginBottom: 10 }}
                active={true}
                pHeight={[30]}
                title={false}
                pRows={1}
                pWidth={["100%"]}
                animationDuration={400}
                primaryColor={
                  darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                }
                secondaryColor={
                  darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                }
              />
            </View>
            <ContentLoader
              containerStyles={{ marginBottom: 5 }}
              active={true}
              pHeight={[8]}
              title={false}
              pRows={2}
              pWidth={["100%"]}
              animationDuration={400}
              primaryColor={
                darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
              }
              secondaryColor={
                darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
              }
            />
            <View style={{ marginTop: 20 }}>
              <ContentLoader
                containerStyles={{ marginBottom: 0 }}
                active={true}
                pHeight={[40]}
                title={false}
                pRows={1}
                pWidth={["100%"]}
                animationDuration={400}
                primaryColor={
                  darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                }
                secondaryColor={
                  darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                }
              />
            </View>
          </View>
          <View
            style={[
              Padding.ph_2,
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1000,
              articleLoader.LoaderWrapper,
            ]}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ContentLoader
                containerStyles={{ marginBottom: 40 }}
                active={true}
                pHeight={[30]}
                title={false}
                pRows={1}
                pWidth={["100%"]}
                animationDuration={400}
                primaryColor={
                  darkMode ? "rgba(45, 48, 45,1)" : "rgba(220, 220, 220, 1)"
                }
                secondaryColor={
                  darkMode ? "rgba(45, 48, 45,0.8)" : "rgba(200, 200, 200, 1)"
                }
              />
            </View>
            {this.articleListsWrapLoader()}
          </View>
        </View>

        {/* <ActivityIndicator size={25} color={'#000000'} /> */}
      </View>
    );
  };

  unlockStory = () => {
    const { postData } = this.state;

    // let body = {
    //     story_slug: 'todays-post',
    //     credits_required: postData?.story?.credits ? postData?.story?.credits.unlock : 0,
    //     story_validity: 4500,
    //     condition: {}
    // }
    // const { token } = this.state;
    // let endpoint = `/unlock-story`;
    // apiHelpers
    //     .apiRequest(endpoint, body, "POST", true, token)
    //     .then((res) => {
    //         if(res.success) {
    //             this.setState({
    //                 postUnlocked: true,
    //                 creditMsg: message,
    //                 enableMessageBox: false
    //             });
    //         } else {
    //             this.setState({
    //                 postUnlocked: false,
    //                 creditMsg: message,
    //                 enableMessageBox: false
    //             });
    //         }
    //     })
    //     .catch((error) => {
    //         console.log('Error=>>', error);
    //     });

    this.setState({
      enableMessageBox: true,
      messageBox: {
        type: "error",
        title: "No Credit",
        subTitle:
          "You have run out of credits to read this story. Get more credits to continue reading",
      },
    });
  };

  getModalClickEvent = () => {
    this.getPost();
  };

  goToSignUp = (email) => {
    if (helpers.isWeb()) {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "signup_initiate",
        custom_user_id: "NA",
        user_status: "guest",
        CTA_text: "sign me up",
        header_title: "trusted financial news",
        CTA_position: "bottom",
        article_name: this.state.postData?.story?.title?.toLowerCase(),
        signup_method: "email",
      });
      this.props.navigateToScreen(
        "/sign-in?redirect=/" +
        this.state.categorySlug +
        "/" +
        this.state.postSlug +
        "&providerHint=tmc-passwordless" +
        (email ? "&emailHint=" + email : "")
      );
    }
  };

  goToGoogleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=google"
    );
  };

  goToGoogleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "google",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=google"
    );
  };

  goToAppleLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "apple",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=apple"
    );
  };

  goToAppleLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "apple",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=apple"
    );
  };

  goToTwitterLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "twitter",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=twitter"
    );
  };

  goToTwitterLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "twitter",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=twitter"
    );
  };

  goToLinkedInLoginWeb = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "linkedin",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=linkedin"
    );
  };

  goToLinkedInLoginMobile = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "start your free trial with",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title,
      signup_method: "linkedin",
    });
    this.props.navigateToScreen(
      "/sign-in?redirect=" +
      this.state.redirectSlugs.web +
      "&providerHint=linkedin"
    );
  };

  goToLogin = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "signup_initiate",
      custom_user_id: "NA",
      user_status: "guest",
      CTA_text: "login",
      header_title: "trusted financial news",
      CTA_position: "bottom",
      article_name: this.state.postData?.story?.title?.toLowerCase(),
      signup_method: "NA",
    });
    if (helpers.isWeb()) {
      this.props.navigateToScreen(
        "/sign-in?redirect=/" +
        this.state.categorySlug +
        "/" +
        this.state.postSlug
      );
    } else {
      this.props.navigation.navigate("/sign-in", {
        redirect: "post-single",
        categorySlug: this.state.categorySlug,
        postSlug: this.state.postSlug,
        verifyRedirect:
          "/" + this.state.categorySlug + "/" + this.state.postSlug,
      });
    }
  };

  changeFontSize = () => {
    console.log("FontSize Changed");
  };

  viewArticle = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  viewAllArticles = () => {
    let categorySlug = "business";
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + categorySlug + "/all");
    } else {
      this.props.navigation.navigate("category-archive", {
        archiveSlug: categorySlug,
      });
    }
  };
  getArchivedStories = (excludeData = []) => {
    this.setState({
      showErrorModal: false,
    });
    let excludeList = excludeData.map((td) => {
      return td.id;
    });
    let endpoint = `/stories`;
    let body = {
      limit: 1,
      exclude: excludeList,
      filters: [
        { key: "is_privileged", value: false },
        { key: "single_category", value: true },
      ],
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        if (res.success && res.code == "story_found") {
          this.setState(
            {
              archivedStories: res.data.story,
            },
            () => {
              console.log(this.state.archivedStories, "newsmews");
            }
          );
        } else {
          console.log("Error=>>", JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error=>> down", JSON.stringify(error));
      });
  };
  viewAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };
  viewCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  navigateToHome = () => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/");
    } else {
      this.props.navigation.navigate("/");
    }
  };

  navigateToPost = (catSlug, slug, postData) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/" + catSlug + "/" + slug);
    } else {
      this.props.navigation.navigate("post-single", {
        categorySlug: catSlug,
        postSlug: slug,
        postData: postData,
      });
    }
  };

  navigateToTag = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/tags/" + slug);
    } else {
      this.props.navigation.navigate("tags-archive", { archiveSlug: slug });
    }
  };

  trackThemeClick = (theme) => {
    // try {
    //     if(helpers.isWeb()) {
    //         Mixpanel.track('click_theme', { theme : theme });
    //     }
    // }
    // catch(error) {
    //     console.log('mix panel error =>', error);
    // }
  };

  navigateToCategory = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/category/" + slug);
    } else {
      this.props.navigation.navigate("category", { categorySlug: slug });
    }
  };

  navigateToNewsletter = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/newsletters/" + slug);
    } else {
      this.props.navigation.navigate("single-newsletter", {
        newsletterSlug: slug,
      });
    }
  };

  navigateToAuthor = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/writers/" + slug);
    } else {
      this.props.navigation.navigate("writer-single", { writerSlug: slug });
    }
  };

  navigateTo = (slug) => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen(slug);
    } else {
      this.props.navigation.replace(slug, { redirect: "/" });
    }
  };

  openSocialMedia = (link) => {
    if (helpers.isWeb()) {
      window.open(link, "_blank");
    } else {
      if (link == "/investors") {
        this.props.navigation.navigate("/investors");
      } else {
        // Linking.openURL(link);
        // this.props.navigation.navigate('in-app-browser', { url: link });
        this.refs.header.openLink(link);
      }
    }
  };

  pushUnlockStoryEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    const { postData } = this.state;
    dataLayer.push({
      event: "unlock_with_credit",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      article_name: postData.story?.title?.toLowerCase(),
      article_category: postData.category?.name?.toLowerCase(),
      credit_type: "privilege",
      credit_used: postData.story?.credits.unlock,
      credit_balance: this.state.privCredits,
      scroll_depth: helpers.getScrollDepth(),
    });
  }


  unlockPost = () => {
    try {
      Mixpanel.track("click_unlock_now_btn");
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    if (this.state.privCredits > 0) {
      this.pushUnlockStoryEventToDataLayer()
    }
    const { token } = this.state;
    if (token) {
      this.setState({
        showErrorModal: false,
        postUnlockError: false,
        unlockLoader: true,
      });
      const { postSlug, postData } = this.state;
      let body = {
        story: {
          slug: postSlug,
          id: postData.story.id,
        },
      };

      let endpoint = `/unlock-story`;
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.code == "unlock_success") {
            localStorage.setItem("is_coin_reedemed", "1");
            this.getPost();

            try {
              let userStarterCredits =
                this.state.userCredit - this.state.privCredits;
              let userPrivilegeCredits = this.state.privCredits;
              if (postData.story.type == "privileged") {
                userPrivilegeCredits -= postData.story.credits.unlock;
              } else {
                userStarterCredits -= postData.story.credits.unlock;
              }
              console.log("Story Unlocked");
              window.webengage.track("Story Unlocked", {
                "Starter credits pending": userStarterCredits,
                "Privilege credits pending": userPrivilegeCredits,
                "Slug of story": postSlug,
              });
              console.log({
                "Starter credits pending": userStarterCredits,
                "Privilege credits pending": userPrivilegeCredits,
                "Slug of story": postSlug,
              });
              Mixpanel.track("Story unlocked using credits", {
                title: postData.story.title,
                credits: postData.story.credits.unlock,
              });
            } catch (error) {
              console.log("mix panel error =>", error);
            }
          } else {
            console.log("Error=>>", res.message);
            this.setState({
              // postUnlockError: true,
              unlockLoader: false,
              // insufficientCredits: true,
              showInsufficientCreditsPopup: true,
            });
          }
        })
        .catch((error) => {
          console.log("Error=>>", error);
          this.setState({
            // postUnlockError: true,
            unlockLoader: false,
          });
        });
    } else {
      if (helpers.isWeb()) {
        this.props.navigateToScreen(
          "/sign-in?redirect=" + this.state.redirectSlugs.web
        );
      } else {
        this.props.navigation.navigate(
          "/sign-in",
          this.state.redirectSlugs.native
        );
      }
    }
  };

  viewPlans = () => {
    console.log("go to view plans");
  };

  navigateToTeamPage = () => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/my-team");
    } else {
      this.props.navigation.navigate("/my-team");
    }
  };

  subscribeToPlan = (subscribtionId, subscribtion, country) => {
    try {
      Mixpanel.track("click_select_plan_btn", {
        plan: subscribtion.name,
        price: subscribtion.price,
      });
    } catch (error) {
      console.log("mix panel error =>", error);
    }
    this.refs.header.showSubscriptionPlan(
      subscribtionId,
      subscribtion,
      country
    );
  };

  renderCreditInfo = () => {
    const { postData, userCredit } = this.state;

    return (
      <View style={[{ marginBottom: 20 }]}>
        <Text
          style={[actionBox.Desc, helpers.isDesktop() && actionBox.DescDesktop]}
        >
          You currently have{" "}
          <Text
            style={[
              actionBox.Strong,
              helpers.isDesktop() && actionBox.StrongDesktop,
            ]}
          >
            {userCredit} credits
          </Text>{" "}
          left
        </Text>
      </View>
    );
  };

  renderGuestDesc = () => {
    const { postData, userCredit, creditPlan, plansData } = this.state;

    return (
      <View style={[{ marginBottom: 30 }]}>
        <Text
          style={[actionBox.Desc, helpers.isDesktop() && actionBox.DescDesktop]}
        >
          Sign up now and get{" "}
          {creditPlan?.discount.value ? creditPlan.discount.value : 10} credits
          for just{" "}
          {creditPlan?.discount.offer_price && (
            <>
              <Text
                style={[
                  actionBox.Strong,
                  helpers.isDesktop() && actionBox.StrongDesktop,
                ]}
              >
                {(creditPlan?.currency_code == "INR" ? "₹" : "$") +
                  creditPlan?.discount.offer_price}
              </Text>{" "}
              instead of the usual{" "}
            </>
          )}
          <Text
            style={[
              actionBox.Strong,
              helpers.isDesktop() && actionBox.StrongDesktop,
            ]}
          >
            {(creditPlan?.currency_code == "INR" ? "₹" : "$") +
              creditPlan?.price}
            .{" "}
          </Text>
          You can use credits to unlock any story of your interest. Offer valid
          only on first time purchase
        </Text>
      </View>
    );
  };

  renderOtherNewsletter() {
    let { postData } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (
      postData.newsletter.other_newsletters &&
      postData.newsletter.other_newsletters.length > 0
    ) {
      let grouped_array = this.getGroupedLetterArray(
        postData.newsletter.other_newsletters
      );
      return (
        <>
          <View
            style={[
              Flex.alignCenter,
              { paddingVertical: helpers.isDesktop() ? 40 : 0 },
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb1200,
              { backgroundColor: "white" },
              darkMode && { backgroundColor: "#121212" },
              !helpers.isDesktop() && { zIndex: -1 },
            ]}
          >
            <View
              style={[
                Padding.ph_2,
                Helpers.titleBgWrap,
                darkMode && { backgroundColor: "#121212" },
              ]}
            >
              <View
                style={[
                  Helpers.borderBottom,
                  darkMode && { borderBottomColor: "rgba(255, 255, 255, 0.6)" },
                  !helpers.isWeb() && { marginBottom: 20 },
                ]}
              >
                <Text
                  style={[
                    Helpers.textCenter,
                    Typo.h3_R,
                    helpers.isDesktop() && Typo.h3_RDesktop,
                    Margin.mb_0,
                    { fontSize: helpers.isDesktop() ? 34 : 18, color: "#000" },
                    { fontFamily: FontFamily.abrilFatface },
                    darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                  ]}
                >
                  Read our other Newsletters
                </Text>
              </View>
            </View>
            <View
              style={[
                Helpers.titleLine,
                { backgroundColor: "rgba(0, 0, 0, 0.6)", height: 0.4 },
                darkMode && { backgroundColor: "rgba(255, 255, 255, 0.6)" },
              ]}
            ></View>
          </View>
          <View
            style={[
              { marginBottom: helpers.isDesktop() ? 60 : 40 },
              !helpers.isDesktop() && { zIndex: -2 },
              !helpers.isWeb() && {
                width: "auto",
                marginRight: "auto",
                marginLeft: "auto",
              },
            ]}
          >
            <NewsletterSlider
              data={grouped_array}
              getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
              darkMode={darkMode}
            />
          </View>
        </>
      );
    }
  }

  getGroupedLetterArray = (array) => {
    let grouped_array = [];
    if (helpers.isDesktop()) {
      for (let i = 0; i < array.length; i += 3) {
        grouped_array.push([array[i], array[i + 1], array[i + 2]]);
      }
    } else {
      for (let i = 0; i < array.length; i += 2) {
        grouped_array.push([array[i], array[i + 1]]);
      }
    }
    return grouped_array;
    //return grouped_array.concat(grouped_array);
  };

  scrollToSection = (position) => {
    let offsetPosition = position;
    if (helpers.isWeb()) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      this.appscrollview.scrollTo(offsetPosition);
    }
  };

  navigateScreen = (pushProp) => {
    if (helpers.isWeb()) {
      this.props.history.push(pushProp);
    } else {
      this.props.navigation.navigate(pushProp);
    }
  };

  navigateScreen = (pushProp) => {
    if (helpers.isWeb()) {
      this.props.history.push(pushProp);
    } else {
      if (pushProp == "/") {
        this.getData();
      } else {
        this.props.navigation.navigate(pushProp);
      }
    }
  };

  trackSubscriptionClick = (subscribtion) => {
    try {
      Mixpanel.track("click_subscription", { plan: subscribtion.name });
    } catch (error) {
      console.log("mix panel =>", error);
    }
  };

  handleFloatingScroll = Animated.event(
    [{ nativeEvent: { contentOffset: { y: this.scrollY } } }],
    { useNativeDriver: false }
  );


  renderPageContent = () => {

    const {
      postData,
      postDataSSR,
      postLists,
      pageLoader,
      userLoggedin,
      creditPlan,
      plansData,
      postUnlocked,
      userSubscriptionActive,
      userCredit,
      enableMessageBox,
      messageBox,
      userSubscriptionStatus,
      storyActions,
      actionsLoader,
      storyStats,
      postUnlockError,
      userLoader,
      articlesLoader,
      statsLoader,
      plansLoader,
      unlockLoader,
      insufficientCredits,
      userSubscription,
      teamSubscription,
      country,
      writerData,
      bookmarkLoading,
      likeLoading,
      hideSideShareActionButton,
      freeCreditsAvailable,
      staticContent,
      homeContent,
      starterCredits,
      showSubscribeModal,
      isPopupEnabled,
      privCredits,
    } = this.state;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);


    // Testing
    // const scrollY = useRef(new Animated.Value(0)).current;

    // const viewportHeight = Dimensions.get('window').height;
    // const showNudgeAfterScroll = viewportHeight * 0.2; // 20vh

    // const handleFloatingScroll = Animated.event(
    //   [{ nativeEvent: { contentOffset: { y: scrollY } } }],
    //   { useNativeDriver: false }
    // );


    let postSummary;
    if (postData && postData.story) {
      postSummary = {
        featured_image: postData.featured_image,
        category: postData.category,
        newsletter: postData.newsletter,
        postTitle: postData.story.title,
        author: postData.author,
        story: postData.story,
        storyStats: storyStats,
        statsLoader: statsLoader,
        lazyLoad: !postDataSSR,
        freeRead: postData?.unlock_data?.type == "free" ? true : false,
        is_free: postData.is_free,
        type: postData.type
      };
    }
    

    let overlayStyle = {
      width: "100%",
      position: "absolute",
      bottom: 0,
      height: helpers.isDesktop()
        ? "46%"
        : this.state.isShortEnabled
          ? "55%"
          : "20%",
    };

    let subInfoBox = {
      title: "Get upto date with the latest stories",
      subtitle:
        "Get access to all stories. No hassles or restrictions to read a story. Subscribe to TMC now",
      btnText: "VIEW PLANS",
    };

    if (userSubscriptionActive || Platform.OS == "ios") {
      subInfoBox = {
        title: "Have questions?",
        subtitle:
          "Check out our FAQ section to get most of your questions resolved",
        btnText: "VIEW FAQ",
      };
    }

    const floatingStyle = {
      transform: [
        {
          translateY: this.scrollY.interpolate({
            inputRange: [this.showNudgeAfterScroll, this.showNudgeAfterScroll + 1],
            outputRange: [0, 1],
            extrapolate: 'clamp',
          }),
        },
      ],
      opacity: this.scrollY.interpolate({
        inputRange: [this.showNudgeAfterScroll, this.showNudgeAfterScroll + 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      }),
    };

    let webView = helpers.isWeb();
    let btnClickCategory;
    let showGiftbox =
      userSubscription &&
      userSubscription.plan &&
      freeCreditsAvailable != null &&
      freeCreditsAvailable != undefined &&
      !postData.is_free &&
      !(
        (userSubscription.plan?.id == 'student-plan-inr' || userSubscription.plan?.id == 'student-plan-usd') &&
        (userSubscription.document_status && ['submitted', 'rejected'].includes(userSubscription.document_status))
      );
    let giftStory =
      showGiftbox &&
      freeCreditsAvailable + 1 > postData?.story?.credits?.unlock &&
      !postData.is_free;
    // let { data } = this.props;
    // postData.story.locked_content = "<p>“<span class='firstletter'>I</span>t saddens me to say this, but if things continue the way they are going, Cognizant will die by a thousand cuts.”</p>";

    if (
      !userLoggedin ||
      (userLoggedin &&
        teamSubscription?.plan?.tmc_group != "team_credit" &&
        teamSubscription?.plan?.tmc_group != "team" &&
        postData.story.type == "privileged" &&
        showSubscribeModal)
    )
      this.displayPopup = true;
    else this.displayPopup = false;

    return (
      <View
        style={[
          { backgroundColor: "#FFF" },
          darkMode && appStyles.appBgDark,
          !helpers.isWeb() && { paddingBottom: 56 },
          { margin: "0px auto" },
        ]}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          if (!helpers.isWeb()) {
            this.viewOffset = layout.height;
          }
        }}
      >
        {/* {
          console.log('teamSubscription:', userSubscription, teamSubscription, showSubscribeModal, privCredits)
        } */}
        {this.state.giftRedeemed ? helpers.isDesktop() ?
          <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 70, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(44, 182, 128)' }}>
            <Text style={{ color: 'white', fontWeight: '400', fontSize: 20, width: '80%', textAlign: 'center', paddingLeft: '10%' }}>The subscriber-only story has been unlocked for you.</Text><TouchableOpacity style={{ display: 'flex', height: '100%', width: '20%', justifyContent: 'center', alignItems: 'center' }} onPress={() => this.setState({ giftRedeemed: false })}><Text style={{ fontSize: 20, color: 'white' }}>X</Text></TouchableOpacity>
          </View> : null
          : null}
        {(!userLoggedin ||
          (userLoggedin &&
            teamSubscription?.plan?.tmc_group != "team_credit" &&
            teamSubscription?.plan?.tmc_group != "team" &&
            postData.story.type == "privileged" &&
            showSubscribeModal)) &&
          isPopupEnabled ? (<></>
        ) : null}
        {postSummary && (
          <View
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              this.StoriesSummaryOffset = layout.height;
            }}
          >
            {this.state.giftRedeemed && !helpers.isDesktop() ?
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 40, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(44, 182, 128)' }}>
                <Text style={{ color: 'white', fontWeight: '400', fontSize: 10, width: '80%', textAlign: 'center', paddingLeft: '10%' }}>The subscriber-only story has been unlocked for you.</Text><TouchableOpacity style={{ display: 'flex', height: '100%', width: '20%', justifyContent: 'center', alignItems: 'center' }} onPress={() => this.setState({ giftRedeemed: false })}><Text style={{ fontSize: 10, color: 'white' }}>X</Text></TouchableOpacity>
              </View>
              :
              null}
            <PostSummary
              storyActions={storyActions}
              actionsLoader={actionsLoader}
              data={postSummary}
              getCategoryClick={(slug) => this.navigateToCategory(slug)}
              getNewsletterClick={(slug) => this.navigateToNewsletter(slug)}
              getAuthorClick={(slug) => this.navigateToAuthor(slug)}
              unlockPost={this.unlockPost}
              unlockLoader={unlockLoader}
              postUnlocked={postUnlocked}
              darkMode={darkMode}
              applyStoryActions={(type, value) =>
                this.applyStoryActions(type, value)
              }
              sharePost={(slug) => this.sharePost(slug)}
              creditPlan={creditPlan}
              setScrollEnabled={(value) => {
                this.setState({ scrollEnabled: value });
                if (this.appscrollview) {
                  this.appscrollview.scrollTo({ y: 0, animated: true });
                }
                if (this.contentscrollview) {
                  this.contentscrollview.scrollTo({ y: 0, animated: true });
                }
              }}
              pushShareStoryEventToDataLayer={(name) => this.pushShareStoryEventToDataLayer(name)}
              token={this.state.token}
              profileInfo={this.state.profileInfo}
              openGiftSubModal={() =>
                this.refs.header.openGiftSubModal(
                  PrivilegeCreditGift.id,
                  PrivilegeCreditGift,
                  null,
                  null,
                  postData,
                  postData?.story?.credits?.unlock
                )
              }
            />
          </View>
        )}

        {/* {
                    !helpers.isDesktop() &&
                    (postData?.story?.strapline != undefined && postData.story.strapline != '') &&
                    <View style={[Padding.ph_2, Margin.mt_1,Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb680]}>
                        <PostStrapline data={postData.story.strapline} darkMode={darkMode} />
                    </View>
                } */}

        {/* {
                    !helpers.isDesktop() && <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb680]}>
                        <PageBreak style={[Margin.mt_3, Margin.mb_3, !helpers.isWeb() && Margin.mb_5]} darkMode={darkMode} />
                    </View>
                } */}

        {helpers.isDesktop() && (
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWeb680,
            ]}
          >
            <PageBreak style={[Margin.mt_5, Margin.mb_2]} darkMode={darkMode} />
          </View>
        )}

        {postData.sponsor && (
          <Sponsers data={postData.sponsor} darkMode={darkMode} />
        )}

        {/* {helpers.isDesktop() && helpers.isWeb() && (
          <View
            style={[
              Helpers.conatinerWeb,
              helpers.isDesktop() && Helpers.conatinerWebFull,
              Margin.mt_1,
              Margin.mb_5,
              { zIndex: 1 },
            ]}
          >
            <DarkModeSwitch darkMode={darkMode} singlePost={true} />
          </View>
        )} */}
        {postUnlocked && postData.story.content && (
          <View>
            <View
              style={[
                Padding.ph_2,
                Helpers.conatinerWeb,
                helpers.isDesktop() && Helpers.conatinerWeb1200,
                { marginHorizontal: "auto" },
              ]}
            >
              {true &&
                <Animated.View
                  style={[floatingStyle]}
                >
                  {/* Floating Nudge Content */}
                  <UpdatedFloatingNudge
                    profileInfo={this.state.profileInfo}
                    token={this.state.token}
                    data={this.props.data}
                    openGiftSubModal={this.props.openGiftSubModal}
                  />
                </Animated.View>}
              {!hideSideShareActionButton &&
                !actionsLoader &&
                helpers.isWeb() && (
                  <PostShare
                    token={this.state.token}
                    profileInfo={this.state.profileInfo}
                    data={postSummary}
                    postContent={true}
                    postTitle={postData.story.title}
                    storyActions={storyActions}
                    applyStoryActions={(type, value, animateFunc) =>
                      this.applyStoryActions(type, value, true, () =>
                        animateFunc()
                      )
                    }
                    sharePost={(slug) => this.sharePost(slug)}
                    shareTitle={postData.story.title}
                    bookmarkLoading={bookmarkLoading}
                    likeLoading={likeLoading}
                    darkMode={darkMode}
                    giftStory={giftStory}
                    openGiftCardModal={() =>
                      this.refs.header.openGiftCardModal(
                        PrivilegeCreditGift.id,
                        PrivilegeCreditGift,
                        null,
                        null,
                        postData,
                        postData.story?.credits?.unlock
                      )
                    }
                    openGiftSubModal={
                      () =>
                        this.refs.header.openGiftSubModal(
                          PrivilegeCreditGift.id,
                          PrivilegeCreditGift,
                          null,
                          null,
                          postData,
                          postData.story?.credits?.unlock
                        )
                    }
                    credits={postData.story.credits}
                    subsLeft={this.state.subsLeft}
                  />
                )}

              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                ]}
              >
                {postData?.story?.content && (
                  <PostContent
                    data={postData.story.content}
                    darkMode={darkMode}
                    openLink={(link) => this.openSocialMedia(link)}
                    relatedNudgeData={this.state.postData.story}
                    storyType={this.state.postData?.type[0]?.slug}
                  />
                )}
              </View>

              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                  Margin.mt_1,
                  Margin.mb_3,
                ]}
              >
                <View
                  style={[
                    {
                      borderTopWidth: 1,
                      borderTopColor: "rgba(5, 20, 98, 0.25)",
                    },
                    darkMode && { borderTopColor: "rgba(255, 255, 255, 0.3)" },
                  ]}
                ></View>
              </View>

              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                  Margin.mt_0,
                  Margin.mb_1,
                ]}
              >
                {postData.story && (
                  <NoteDisclosures
                    data={postData.story.note}
                    darkMode={darkMode}
                    openLink={(link) => this.openSocialMedia(link)}
                  />
                )}
              </View>

              {/*<View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb680, Margin.mt_0, Margin.mb_1]}>
                            {(storyActions && storyActions.bookmark && !actionsLoader && !bookmarkLoading) && <PostBookmarkedContainer openBookmarkList={() => { }} applyStoryActions={(type, value) => this.applyStoryActions(type, value)} bookmarkedDate={storyActions.bookmark_date} goToBookmarkList={() => this.navigateTo('/bookmarks')} />}
                        </View>*/}

              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                  Margin.mb_0,
                  !helpers.isDesktop() && Margin.mt_1,
                ]}
              >
                {postData.tag && postData.tag.length != 0 && (
                  <PostTags
                    data={postData.tag}
                    getLinkClick={(slug) => this.navigateToTag(slug)}
                    darkMode={darkMode}
                  />
                )}
              </View>

              <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                ]}
              >
                {postData.author && postData.author.length != 0 && (
                  <AboutAuthor
                    data={postData.author}
                    openSocialMedia={(link) => this.openSocialMedia(link)}
                    getAuthorClick={(slug) => this.navigateToAuthor(slug)}
                    darkMode={darkMode}
                  />
                )}
              </View>

              {/* <View
                style={[
                  Helpers.conatinerWeb,
                  helpers.isDesktop() && Helpers.conatinerWeb680,
                  Margin.mt_2,
                  Margin.mb_3,
                ]}
              >
                {postData.story && (
                  <NoteDisclosures
                    data={postData.story.disclosure}
                    title={"Disclaimers"}
                    darkMode={darkMode}
                    openLink={(link) => this.openSocialMedia(link)}
                  />
                )}
              </View> */}

              {postData.unlock_data != undefined &&
                postData.unlock_data.type != "free" && (
                  <View
                    style={[
                      Helpers.conatinerWeb,
                      helpers.isDesktop() && Helpers.conatinerWeb800,
                      {
                        marginBottom: helpers.isDesktop() ? 120 : 0,
                        marginTop: helpers.isDesktop() ? 80 : 50,
                      },
                    ]}
                  >
                    <UnlockBox
                      borderColor="#55C79A"
                      backgroundColor="rgba(238, 249, 245, 0.5)"
                      unlockData={postData.unlock_data}
                      darkMode={darkMode}
                    />
                    {/* <UnlockBox borderColor="#3AACFF" backgroundColor="rgba(235, 247, 255, 0.5)"/>
                            <UnlockBox borderColor="#907CFF" backgroundColor="rgba(244, 242, 255, 0.5)"/> */}
                  </View>
                )}

              {/* <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800]}>
                            <View style={[Margin.mt_6, Margin.mb_6]}>
                                <PageBreak />
                            </View>
                        </View> */}


            </View>
            {/* Updated <GiftStory /> */}
            {showGiftbox && (
              <View
                style={{
                  marginBottom: 60,
                  width: helpers.isDesktop() ? null : '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <GiftStoryNudge
                  darkMode={darkMode}
                  userSubscription={userSubscription}
                  freeCreditsAvailable={freeCreditsAvailable}
                  credits={postData.story.credits}
                  openGiftCardModal={(giftCredits) =>
                    this.refs.header.openGiftCardModal(
                      PrivilegeCreditGift.id,
                      PrivilegeCreditGift,
                      null,
                      null,
                      postData,
                      giftCredits
                    )
                  }
                  navigateToGiftingPage={this.navigateScreen}
                  custom_user_id={this.state.custom_user_id}
                  token={this.state.token}
                  profileInfo={this.state.profileInfo}
                  data={postSummary}
                  openGiftSubModal={
                    () =>
                      this.refs.header.openGiftSubModal(
                        PrivilegeCreditGift.id,
                        PrivilegeCreditGift,
                        null,
                        null,
                        postData,
                        postData.story?.credits?.unlock
                      )
                  }
                />
              </View>
            )}


            {
              postData.story.type === "non_privileged" && postUnlocked && <TouchableOpacity onPress={() => this.navigateScreen('/pricing')} style={{ marginVertical: '5vh' }}>
                <CustomImage source={helpers.isDesktop() ? freeStorySubscribeBand : freeStorySubscribeBandMob} style={{ width: "100%", height: 'auto' }} webStyle={{ width: "100%", height: 'auto' }} />
              </TouchableOpacity>
            }

            <View
              style={[
                helpers.isDesktop() && Padding.ph_2,
                Helpers.conatinerWeb,
                // helpers.isDesktop() && Helpers.conatinerWeb1200,
                { marginHorizontal: "auto" },
              ]}
            >

              {
                true && <View onLayout={(event) => {
                  const layout = event.nativeEvent.layout;
                  this.moreStoriesSectionOffset = layout.height;
                }}>
                  <MoreStoriesSection isStoryPage={true} category={postData?.category?.slug} story_type={postData?.type?.length && postData?.type[0].slug} token={this.state.token} story_id={postData?.story?.id} />
                </View>
              }
              {articlesLoader ? (
                this.listsLoader()
              ) : (
                <>
                  {postLists && (
                    <>
                      {postData.newsletter ? (
                        <View
                          onLayout={(event) => {
                            const layout = event.nativeEvent.layout;
                            this.relatedStoriesOffset = layout.height;
                          }}
                          style={[
                            helpers.isDesktop() && Padding.ph_2,
                            Margin.mt_5,
                            { backgroundColor: "white" },
                            darkMode && { backgroundColor: "#121212" },
                          ]}
                        >
                          <View
                            style={[
                              Helpers.conatinerWeb,
                              helpers.isDesktop() && Helpers.conatinerWeb1000,
                            ]}
                          >
                            <MoreNewsletterPosts
                              data={postLists}
                              viewArticle={(catSlug, slug, postData) =>
                                this.viewArticle(catSlug, slug, postData)
                              }
                              viewAuthor={(slug) => this.viewAuthor(slug)}
                              viewCategory={(slug) => this.viewCategory(slug)}
                              viewAllArticles={() => this.viewAllArticles()}
                              getNewsletterClick={(slug) =>
                                this.navigateToNewsletter(slug)
                              }
                              newsletter={postData.newsletter}
                              darkMode={darkMode}
                            />
                          </View>
                        </View>
                      ) : (
                        <View
                          onLayout={(event) => {
                            const layout = event.nativeEvent.layout;
                            this.relatedStoriesOffset = layout.height;
                          }}
                          style={[
                            Padding.ph_2,
                            Padding.mb_5,
                            helpers.isDesktop() && Margin.mt_5,
                            { backgroundColor: "white" },
                            darkMode && { backgroundColor: "#121212" },
                            // !helpers.isDesktop() && { paddingHorizontal: 0 },
                          ]}
                        >
                          <View
                            style={[
                              Helpers.conatinerWeb,
                              // helpers.isDesktop() && Helpers.conatinerWeb1000,
                              helpers.isDesktop() && { width: '90%' }
                            ]}
                          >
                            <ArticleList
                              title={helpers.isDesktop() ? "You may find interesting" : "You may also like"}
                              post={postLists}
                              getLinkClick={(catSlug, slug, postData) =>
                                this.navigateToPost(catSlug, slug, postData)
                              }
                              getCategoryClick={(slug) =>
                                this.navigateToCategory(slug)
                              }
                              getAuthorClick={(slug) =>
                                this.navigateToAuthor(slug)
                              }
                              darkMode={darkMode}
                              youMayFindInterestingSection={true}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  )}
                </>
              )}
            </View>

          </View>
        )}

        {(!userLoggedin || !this.state.userSubscriptionActive) &&
         (userLoggedin ? postData.story.type !== "non_privileged" : true)
          && (
            <>
              {postData.story && (
                <View style={{ position: "relative" }}>
                  <View
                    style={[
                      Helpers.conatinerWeb,
                      helpers.isDesktop() && Helpers.conatinerWeb1200,
                      { marginHorizontal: "auto" },
                    ]}
                  >
                    <View
                      style={[
                        Helpers.conatinerWeb,
                        helpers.isDesktop() && Helpers.conatinerWeb680,
                      ]}
                    >
                      <View style={[Padding.ph_2]}>
                        <PostExcerpt
                          data={postData.story.locked_content}
                          darkMode={darkMode}
                          openLink={(link) => this.openSocialMedia(link)}
                        />
                      </View>
                    </View>
                  </View>
                  <SubscribeToUnlock
                    navigateScreen={(pushProp) =>
                      this.navigateScreen(pushProp)
                    }
                    goToLogin={this.goToLogin}
                    data={postSummary}
                    unlockPost={this.unlockPost}
                    starterCredits={starterCredits}
                    isStarterCreditStory={false}
                    token={this.state.token}
                    locationInfo={this.state.locationInfo}
                    userLoggedIn={this.state.userLoggedin}
                    navigateWeb={(slug) => this.props.navigateToScreen(slug)}
                  />
                  {
                    true && <View onLayout={(event) => {
                      const layout = event.nativeEvent.layout;
                      this.moreStoriesSectionOffset = layout.height;
                    }}>
                      <MoreStoriesSection isStoryPage={true} category={postData?.category?.slug} story_type={postData?.type?.length && postData?.type[0].slug} token={false} story_id={postData?.story?.id} />
                    </View>
                  }

                  {articlesLoader ? (
                    this.listsLoader()
                  ) : (
                    <>
                      {postLists && (
                        <>
                          {postData.newsletter ? (
                            <View
                              onLayout={(event) => {
                                const layout = event.nativeEvent.layout;
                                this.relatedStoriesOffset = layout.height;
                              }}
                              style={[
                                helpers.isDesktop() && Padding.ph_2,
                                Margin.mt_5,
                                { backgroundColor: "white" },
                                darkMode && { backgroundColor: "#121212" },
                              ]}
                            >
                              <View
                                style={[
                                  Helpers.conatinerWeb
                                ]}
                              >
                                <MoreNewsletterPosts
                                  data={postLists}
                                  viewArticle={(catSlug, slug, postData) =>
                                    this.viewArticle(catSlug, slug, postData)
                                  }
                                  viewAuthor={(slug) => this.viewAuthor(slug)}
                                  viewCategory={(slug) => this.viewCategory(slug)}
                                  viewAllArticles={() => this.viewAllArticles()}
                                  getNewsletterClick={(slug) =>
                                    this.navigateToNewsletter(slug)
                                  }
                                  newsletter={postData.newsletter}
                                  darkMode={darkMode}
                                />
                              </View>
                            </View>
                          ) : (
                            <View
                              onLayout={(event) => {
                                const layout = event.nativeEvent.layout;
                                this.relatedStoriesOffset = layout.height;
                              }}
                              style={[
                                Padding.ph_2,
                                Padding.mb_5,
                                helpers.isDesktop() && Margin.mt_5,
                                { backgroundColor: "white" },
                                darkMode && { backgroundColor: "#121212" },
                                // !helpers.isDesktop() && { paddingLeft: 0 },
                              ]}
                            >
                              <View
                                style={[
                                  Helpers.conatinerWeb,
                                  // helpers.isDesktop() && Helpers.conatinerWeb1000,
                                  helpers.isDesktop() && { width: '90%' }
                                ]}
                              >
                                <ArticleList
                                  title={helpers.isDesktop() ? "You may find interesting" : "You may also like"}
                                  post={postLists}
                                  getLinkClick={(catSlug, slug, postData) =>
                                    this.navigateToPost(catSlug, slug, postData)
                                  }
                                  getCategoryClick={(slug) =>
                                    this.navigateToCategory(slug)
                                  }
                                  getAuthorClick={(slug) =>
                                    this.navigateToAuthor(slug)
                                  }
                                  darkMode={darkMode}
                                  youMayFindInterestingSection={true}
                                />
                              </View>
                            </View>
                          )}
                        </>
                      )}
                    </>
                  )}

                </View>
              )}

              <View style={{ backgroundColor: darkMode ? "black" : "#F4F2FF" }}>
              </View>
            </>
          )}

        {/* Partial Post Start :: code of stater credits */}
        {userLoggedin && !postUnlocked && (
          <>
            {postData.story && false && (
              <View style={{ position: "relative" }}>
                <View
                  style={[
                    Helpers.conatinerWeb,
                    helpers.isDesktop() && Helpers.conatinerWeb1200,
                    { marginHorizontal: "auto" },
                  ]}
                >
                  <View
                    style={[
                      Helpers.conatinerWeb,
                      helpers.isDesktop() && Helpers.conatinerWeb680,
                    ]}
                  >
                    <View style={[Padding.ph_2]}>
                      <PostExcerpt
                        data={postData.story.locked_content}
                        darkMode={darkMode}
                        openLink={(link) => this.openSocialMedia(link)}
                      />
                    </View>
                  </View>
                  <CustomImage
                    source={darkMode ? overlayImageDark : overlayImage}
                    style={overlayStyle}
                    webStyle={overlayStyle}
                    require={true}
                  />
                </View>
              </View>
            )}
            {!this.displayPopup && (
              <>
                {Platform.OS != "ios" ? (
                  <>
                    {teamSubscription?.plan?.tmc_group != "team_credit" &&
                      postData.story.type == "non_privileged" && (
                        <View
                          style={[
                            {
                              backgroundColor: darkMode ? "#121212" : "#fafafa",
                            },
                          ]}
                          onLayout={(event) => {
                            const layout = event.nativeEvent.layout;
                            this.subscriptionLayout = layout.y;
                          }}
                        >
                          <View
                            style={[
                              Helpers.conatinerWeb,
                              helpers.isDesktop() && Helpers.conatinerWeb680,
                            ]}
                          >
                            <View style={[Padding.ph_2]}>
                              <PostExcerpt
                                data={postData.story.locked_content}
                                darkMode={darkMode}
                                openLink={(link) => this.openSocialMedia(link)}
                              />
                            </View>
                          </View>
                          {userLoader || plansLoader ? (
                            this.actionSubscriptionBoxLOader()
                          ) : (
                            <>
                              <SubscribeToUnlock
                                navigateScreen={(pushProp) =>
                                  this.navigateScreen(pushProp)
                                }
                                goToLogin={this.goToLogin}
                                data={postSummary}
                                unlockPost={this.unlockPost}
                                starterCredits={starterCredits}
                                isStarterCreditStory={true}
                                token={this.state.token}
                                locationInfo={this.state.locationInfo}
                                navigateWeb={(slug) => this.props.navigateToScreen(slug)}
                              />

                              <View
                                style={[
                                  Padding.ph_2,
                                  postSingle.unlockSection,
                                  darkMode && { backgroundColor: "#121212" },
                                ]}
                              >
                                <View
                                  style={[
                                    Helpers.conatinerWeb,
                                    helpers.isDesktop() &&
                                    Helpers.conatinerWeb700,
                                    Padding.pb_4,
                                  ]}
                                >
                                  <Text
                                    style={[
                                      Helpers.textCenter,
                                      Typo.descTextL,
                                      helpers.isDesktop() && {
                                        lineHeight: 27,
                                        letterSpacing: 0.3,
                                        marginTop: 30,
                                      },
                                      {
                                        fontFamily: FontWeight.regular,
                                        fontWeight: "600",
                                      },
                                      {
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                      },
                                      darkMode && {
                                        color: "rgba(255, 255, 255, 0.89)",
                                      },
                                      !helpers.isDesktop() && {
                                        paddingTop: 40,
                                      },
                                    ]}
                                  ></Text>

                                  {helpers.isDesktop() ? (
                                    <View
                                      style={[
                                        Flex.alignCenter,
                                        Padding.pt_3,
                                        Padding.pb_3,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          {
                                            fontSize: helpers.isDesktop()
                                              ? 34
                                              : 20,
                                            lineHeight: 40,
                                            fontFamily: FontFamily.abrilFatface,
                                            marginBottom: 10,
                                            textAlign: "center",
                                          },
                                          darkMode && {
                                            color: "rgba(255, 255, 255, 0.89)",
                                          },
                                        ]}
                                      >
                                        {userSubscriptionActive
                                          ? `Upgrade to yearly plan to access this ${postData.newsletter
                                            ? "newsletter"
                                            : "story"
                                          }`
                                          : userSubscriptionStatus == "expired"
                                            ? `Renew your TMC subscription`
                                            : `Subscribe and get access to all ${postData.newsletter
                                              ? "Newsletters"
                                              : "Stories"
                                            }`}
                                      </Text>
                                      <PageBreak
                                        size={"small"}
                                        darkMode={darkMode}
                                      />
                                    </View>
                                  ) : (
                                    <View style={[Padding.pt_4]}>
                                      <View
                                        style={[
                                          Flex.alignCenter,
                                          Margin.mb_3,
                                          { marginRight: -20, marginLeft: -20 },
                                        ]}
                                      >
                                        <View
                                          style={[
                                            Padding.ph_1,
                                            Helpers.titleBgWrap,
                                            {
                                              backgroundColor: darkMode
                                                ? "#121212"
                                                : "#fafafa",
                                            },
                                          ]}
                                        >
                                          <View
                                            style={[
                                              Helpers.borderBottom,
                                              darkMode && {
                                                borderBottomColor:
                                                  "rgba(255, 255, 255, 0.6)",
                                              },
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                Helpers.textCenter,
                                                Typo.h3_R,
                                                helpers.isDesktop() &&
                                                Typo.h3_RDesktop,
                                                Margin.mb_0,
                                                {
                                                  fontSize: helpers.isDesktop()
                                                    ? 34
                                                    : 17,
                                                  color: "#000",
                                                },
                                                {
                                                  fontFamily:
                                                    FontFamily.abrilFatface,
                                                },
                                                darkMode && {
                                                  color:
                                                    "rgba(255, 255, 255, 0.89)",
                                                  borderColor:
                                                    "rgba(255, 255, 255, 0.6)",
                                                },
                                              ]}
                                            >
                                              {userSubscriptionActive
                                                ? `Upgrade to yearly plan to access this ${postData.newsletter
                                                  ? "newsletter"
                                                  : "story"
                                                }`
                                                : userSubscriptionStatus ==
                                                  "expired"
                                                  ? `Renew your TMC subscription`
                                                  : `Subscribe and get access to all ${postData.newsletter
                                                    ? "Newsletter"
                                                    : "Stories"
                                                  }`}
                                            </Text>
                                          </View>
                                        </View>
                                        <View
                                          style={[
                                            Helpers.titleLine,
                                            {
                                              backgroundColor: darkMode
                                                ? "rgba(255, 255, 255, 0.6)"
                                                : "rgba(0, 0, 0, 0.6)",
                                            },
                                          ]}
                                        ></View>
                                      </View>
                                    </View>
                                  )}

                                  <Text
                                    style={[
                                      Helpers.textCenter,
                                      Typo.descTextL,
                                      helpers.isDesktop() && {
                                        lineHeight: 27,
                                        letterSpacing: 0.3,
                                      },
                                      {
                                        fontWeight: FontWeight.regular,
                                        fontFamily: FontFamily.regular,
                                      },
                                      {
                                        fontSize: helpers.isDesktop() ? 18 : 16,
                                      },
                                      darkMode && {
                                        color: "rgba(255, 255, 255, 0.89)",
                                      },
                                    ]}
                                  >
                                    {userSubscriptionActive
                                      ? "You will get access to all our stories, newsletters. Upgrade now to enjoy benefits."
                                      : userSubscriptionStatus == "expired"
                                        ? `Your subscription to TMC has expired. Renew now to read this story. As a subscriber you will also get access to our other storeis, daily long reads, TMC’s paid newsletters and many more benefits. Choose from any of the below plans to subscribe now.`
                                        : `Along with access to this story, you will get access to our daily long reads, TMC’s paid newsletters and many more benefits. Choose from any of the below plans to subscribe now.`}
                                  </Text>
                                </View>

                                {plansData && (
                                  <View
                                    style={[
                                      Helpers.conatinerWeb,
                                      helpers.isDesktop() &&
                                      Helpers.conatinerWeb1200,
                                      !helpers.isDesktop() && {
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                      },
                                      helpers.isDesktop() && {
                                        maxWidth: 1350,
                                        width: "1350px",
                                      },
                                    ]}
                                  >
                                    <SubscriptionPricingPlan
                                      premium={"prelogout"}
                                      planPage={true}
                                      plansData={{ individual: plansData }}
                                      userSubscription={userSubscription}
                                      teamSubscription={teamSubscription}
                                      country={country}
                                      showSubscriptionPlan={(
                                        subscribtionId,
                                        subscribtion,
                                        country
                                      ) =>
                                        this.subscribeToPlan(
                                          subscribtionId,
                                          subscribtion,
                                          country
                                        )
                                      }
                                      hidePagebreak={true}
                                      sidePanel={false}
                                      navigateScreen={(pushProp) =>
                                        this.navigateScreen(pushProp)
                                      }
                                      subscriptionPage={true}
                                      lightBlueBg={false}
                                      singlePost={true}
                                      darkMode={darkMode}
                                      hideTeam={true}
                                      showTeamSection={true}
                                      showCreditPlan={true}
                                      creditPlan={creditPlan}
                                      showExploreMore={true}
                                      setCheckoutLoaderState={(loaderState) =>
                                        this.setPlanButtonLoaderState(
                                          loaderState
                                        )
                                      }
                                      getCheckoutLoaderState={
                                        this.getPlanButtonLoaderState
                                      }
                                      history={this.props.history}
                                    />
                                  </View>
                                )}
                                {/* <View style={helpers.isDesktop() && { height: "1000px" }}>
                              {this.state.postData && this.renderLatestStory()}
                            </View> */}
                              </View>
                            </>
                          )}
                        </View>
                      )}

                    {teamSubscription?.plan?.tmc_group != "team_credit" &&
                      postData.story.type == "privileged" && (
                        <View
                          onLayout={(event) => {
                            const layout = event.nativeEvent.layout;
                            this.subscriptionLayout = layout.y;
                          }}
                        >
                          {userLoader || plansLoader ? (
                            this.actionSubscriptionBoxLOader()
                          ) : (
                            <View
                              style={{
                                backgroundColor: darkMode
                                  ? "#121212"
                                  : "#fafafa",
                              }}
                            >
                              {this.state.privCredits > 0 ? (
                                <UnlockStoryNew
                                  darkMode={darkMode}
                                  navigateScreen={(pushProp) =>
                                    this.navigateScreen(pushProp)
                                  }
                                  goToLogin={this.goToLogin}
                                  privilege={true}
                                  data={postSummary}
                                  unlockPost={this.unlockPost}
                                  privCredits={this.state.privCredits}
                                  custom_user_id={this.state.custom_user_id}
                                />
                              ) : null}

                              <View
                                style={[
                                  Padding.ph_2,
                                  postSingle.unlockSection,
                                  darkMode && { backgroundColor: "#121212" },
                                ]}
                              >
                                <View
                                  style={[
                                    Helpers.conatinerWeb,
                                    helpers.isDesktop() &&
                                    Helpers.conatinerWeb700,
                                    Padding.pb_4,
                                  ]}
                                >
                                  <Text
                                    style={[
                                      Helpers.textCenter,
                                      Typo.descTextL,
                                      helpers.isDesktop() && {
                                        lineHeight: 27,
                                        letterSpacing: 0.3,
                                        marginTop: 30,
                                      },
                                      {
                                        fontFamily: FontWeight.regular,
                                        fontWeight: "600",
                                      },
                                      {
                                        fontSize: helpers.isDesktop() ? 16 : 14,
                                      },
                                      darkMode && {
                                        color: "rgba(255, 255, 255, 0.89)",
                                      },
                                      !helpers.isDesktop() && {
                                        paddingTop: 40,
                                      },
                                    ]}
                                  ></Text>

                                  {helpers.isDesktop() ? (
                                    <View
                                      style={[
                                        Flex.alignCenter,
                                        Padding.pt_3,
                                        Padding.pb_3,
                                      ]}
                                    >
                                      <Text
                                        style={[
                                          {
                                            fontSize: helpers.isDesktop()
                                              ? 34
                                              : 20,
                                            lineHeight: 40,
                                            fontFamily: FontFamily.abrilFatface,
                                            marginBottom: 10,
                                          },
                                          Helpers.textCenter,
                                          darkMode && {
                                            color: "rgba(255, 255, 255, 0.89)",
                                          },
                                        ]}
                                      >
                                        {userSubscriptionActive
                                          ? `Upgrade to yearly plan to access this ${postData.newsletter
                                            ? "newsletter"
                                            : "story"
                                          }`
                                          : userSubscriptionStatus == "expired"
                                            ? `Your subscription has expired!`
                                            : this.state.privCredits > 0
                                              ? `Or subscribe to unlock all ${postData.newsletter
                                                ? "Newsletters"
                                                : "Stories"
                                              } `
                                              : postData.story.type ==
                                                "non_privileged"
                                                ? `Or subscribe to unlock all ${postData.newsletter
                                                  ? "Newsletter"
                                                  : "Story"
                                                }`
                                                : null}
                                      </Text>
                                      <PageBreak
                                        size={"small"}
                                        darkMode={darkMode}
                                      />
                                    </View>
                                  ) : (
                                    <View style={[Padding.pt_4]}>
                                      <View
                                        style={[
                                          Flex.alignCenter,
                                          Margin.mb_3,
                                          { marginRight: -20, marginLeft: -20 },
                                        ]}
                                      >
                                        <View
                                          style={[
                                            Padding.ph_1,
                                            Helpers.titleBgWrap,
                                            { backgroundColor: "#fafafa" },
                                            darkMode && {
                                              backgroundColor: "#121212",
                                            },
                                          ]}
                                        >
                                          <View
                                            style={[
                                              Helpers.borderBottom,
                                              darkMode && {
                                                borderBottomColor:
                                                  "rgba(255, 255, 255, 0.6)",
                                              },
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                Helpers.textCenter,
                                                Typo.h3_R,
                                                helpers.isDesktop() &&
                                                Typo.h3_RDesktop,
                                                Margin.mb_0,
                                                {
                                                  fontSize: helpers.isDesktop()
                                                    ? 34
                                                    : 17,
                                                  color: "#000",
                                                },
                                                {
                                                  fontFamily:
                                                    FontFamily.abrilFatface,
                                                },
                                                darkMode && {
                                                  color:
                                                    "rgba(255, 255, 255, 0.89)",
                                                  borderColor:
                                                    "rgba(255, 255, 255, 0.6)",
                                                },
                                              ]}
                                            >
                                              {userSubscriptionActive
                                                ? `Upgrade to yearly plan to access this ${postData.newsletter
                                                  ? "newsletter"
                                                  : "story"
                                                }`
                                                : userSubscriptionStatus ==
                                                  "expired"
                                                  ? `Your subscription has expired!`
                                                  : this.state.privCredits > 0
                                                    ? `Subscribe and get access to all ${postData.newsletter
                                                      ? "Newsletter"
                                                      : "Stories"
                                                    }`
                                                    : null}
                                            </Text>
                                          </View>
                                        </View>
                                        <View
                                          style={[
                                            Helpers.titleLine,
                                            {
                                              backgroundColor:
                                                "rgba(0, 0, 0, 0.6)",
                                            },
                                            darkMode && {
                                              backgroundColor:
                                                "rgba(255, 255, 255, 0.6)",
                                            },
                                          ]}
                                        ></View>
                                      </View>
                                    </View>
                                  )}
                                </View>

                                {plansData && (
                                  <View
                                    style={[
                                      Helpers.conatinerWeb,
                                      helpers.isDesktop() &&
                                      Helpers.conatinerWeb1200,
                                      !helpers.isDesktop() && {
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                      },
                                      helpers.isDesktop() && {
                                        width: "1450px",
                                      },
                                    ]}
                                  >
                                    <SubscriptionPricingPlan
                                      premium={"prelogout"}
                                      planPage={true}
                                      plansData={{ individual: plansData }}
                                      userSubscription={userSubscription}
                                      teamSubscription={teamSubscription}
                                      country={country}
                                      showSubscriptionPlan={(
                                        subscribtionId,
                                        subscribtion,
                                        country
                                      ) =>
                                        this.subscribeToPlan(
                                          subscribtionId,
                                          subscribtion,
                                          country
                                        )
                                      }
                                      hidePagebreak={true}
                                      sidePanel={false}
                                      navigateScreen={(pushProp) =>
                                        this.navigateScreen(pushProp)
                                      }
                                      subscriptionPage={true}
                                      lightBlueBg={false}
                                      singlePost={true}
                                      darkMode={darkMode}
                                      hideTeam={true}
                                      showTeamSection={true}
                                      showCreditPlan={true}
                                      creditPlan={creditPlan}
                                      showExploreMore={true}
                                      setCheckoutLoaderState={(loaderState) =>
                                        this.setPlanButtonLoaderState(
                                          loaderState
                                        )
                                      }
                                      getCheckoutLoaderState={
                                        this.getPlanButtonLoaderState
                                      }
                                    />
                                  </View>
                                )}

                                {/* {this.state.postData && (
                              <View
                                style={
                                  helpers.isDesktop() && { height: "1000px" }
                                }
                              >
                                {this.renderLatestStory(
                                  "Exclusive benefits for subscribers"
                                )}
                              </View>
                            )} */}
                              </View>
                            </View>
                          )}
                        </View>
                      )}

                    {teamSubscription?.plan?.tmc_group == "team_credit" && (
                      <>
                        {userLoader ? (
                          this.actionSubscriptionBoxLOader()
                        ) : (
                          <View
                            style={{
                              paddingBottom: helpers.isDesktop() ? 100 : 50,
                              backgroundColor: darkMode ? "#121212" : "#fafafa",
                            }}
                          >
                            <View
                              style={{
                                paddingBottom: helpers.isDesktop() ? 100 : 50,
                                backgroundColor: darkMode
                                  ? "#121212"
                                  : "#fafafa",
                                paddingHorizontal: 20,
                              }}
                            >
                              <UnlockThisStory
                                credits={postData.story.credits.unlock}
                                unlockPost={this.unlockPost}
                                unlockLoader={unlockLoader}
                                teamCredits={true}
                                userCredit={userCredit}
                                isPrivileged={
                                  postData.story.type == "privileged"
                                }
                                darkMode={darkMode}
                                starterCredits={starterCredits}
                              />
                            </View>
                            <SubscriptionDashboardInfo
                              isOwner={teamSubscription.is_team_owner}
                              navigateToTeamPage={() =>
                                this.navigateToTeamPage()
                              }
                              darkMode={darkMode}
                            />
                          </View>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <View>
                    <IosLockedView darkMode={darkMode} />
                  </View>
                )}
              </>
            )}

            {/* <View style={[Padding.ph_2, Padding.pb_9, postSingle.unlockSection, { backgroundColor: darkMode ? 'black' : '#f5f3ff' }]}>
                            <EverythingTmc darkMode={darkMode} />

                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginTop: helpers.isDesktop() ? 90 : 70 }]}>
                                <WhyTMCSection fulWidth={true} postSingle={true} darkMode={darkMode} whiteBg={true} />
                            </View>
                        </View>

                        <View style={{ backgroundColor: darkMode ? 'black' : '#f5f3ff' }}>
                            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                                    <HomePopularCategories transparentBg={true} data={{ title: 'Get all the news from our categories' }} subscription={true} trackThemeClick={(theme) => this.trackThemeClick(theme)} getTagClick={(slug) => this.navigateToTag(slug)} darkMode={darkMode} />
                                </View>
                            </View>

                            {writerData && writerData.length > 0 &&

                                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                                    <View style={[Flex.alignCenter, Padding.pt_4, Padding.pb_4, { width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                                        <Text style={[{ fontSize: helpers.isDesktop() ? 34 : 20, lineHeight: 40, fontFamily: FontFamily.abrilFatface, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Written by leading  journalists</Text>
                                        <PageBreak style={[!webView && { width: '65%' }]} darkMode={darkMode} />
                                    </View>

                                    <View style={[Flex.row, Flex.FlexWrap]}>
                                        {writerData.map((citem, index) => {
                                            return (
                                                <AuthorItem viewAuthor={(slug) => this.navigateToAuthor(slug)} single={index == 0 ? true : false} key={`authorItem${index}`} data={citem} about={true} showArrow={true} subscription={true} darkMode={darkMode} require={false} singlePost={true} />
                                            )
                                        })
                                        }
                                    </View>
                                </View>
                            }

                            {postData.testimonial && <View>
                                <View style={[Padding.pt_3, Padding.ph_2, helpers.isDesktop() ? Padding.pb_4 : Padding.pb_2, { backgroundColor: darkMode ? '#121212' : '#f5f3ff' }]}>
                                    <Testimonial data={postData.testimonial} singlePost={true} darkMode={darkMode} />
                                </View>
                            </View>
                            }

                            {this.backToTopSection()}

                        </View> */}

            {/* <View style={[{ backgroundColor: '#fafafa' }, darkMode && { backgroundColor: '#121212' }]}>
                            {postData.testimonial && <View>
                                <View style={[Padding.pt_3, Padding.ph_2, Padding.pb_4]}>
                                    <Testimonial data={postData.testimonial} singlePost={true} darkMode={darkMode} />
                                </View>
                            </View>
                            }


                            {
                                (userSubscriptionActive || Platform.OS == 'ios') ?
                                    <View style={{ marginBottom: helpers.isDesktop() ? 190 : 50, marginTop: helpers.isDesktop() ? 100 : 50 }}>
                                        <SubscriptionInfoBox data={subInfoBox} handleClick={() => this.navigateTo('/faq')} darkMode={darkMode} />
                                    </View>

                                    :

                                    <View style={{ marginBottom: helpers.isDesktop() ? 190 : 50, marginTop: helpers.isDesktop() ? 100 : 50 }}>
                                        <SubscriptionInfoBox data={subInfoBox} handleClick={() => this.scrollToSection(this.subscriptionLayout + this.subscriptionExtraheight)} darkMode={darkMode} />
                                    </View>
                            }
                        </View> */}
          </>
        )}

        {/* Partial Post End  */}
      </View>
    );
  };
  renderLatestStory = (title) => {
    let { latestStories, featuredStory } = this.state.postData;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <HomeExclusiveContent
          data={this.state.latestStories}
          newsletterData={this.state.homeContent}
          viewArticle={(catSlug, slug, postData) =>
            this.viewArticle(catSlug, slug, postData)
          }
          viewAuthor={(slug) => this.viewAuthor(slug)}
          viewCategory={(slug) => this.viewCategory(slug)}
          viewAllArticles={() => this.viewAllArticles()}
          trackStoryClick={(title, data, position) => {
            /*this.trackStoryClick('click_latest_story', title, data, position)*/
          }}
          darkMode={darkMode}
          title={title}
          token={this.state.token}
        />
      </>
    );
  };
  backToTopSection = () => {
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          Padding.pt_4,
          Flex.alignCenter,
          Flex.justfiyCenter,
          { paddingBottom: 100 },
        ]}
      >
        <View>
          <Button
            title={"BACK TO TOP"}
            rootStyle={{
              btnWrapper: [
                {
                  backgroundColor: "#DDDDDD",
                  paddingBottom: 10,
                  paddingTop: 10,
                },
              ],
              btnText: [
                {
                  fontSize: 17,
                  fontFamily: FontFamily.regular,
                  fontWeight: "600",
                  color: "#000000",
                  lineHeight: 20,
                  letterSpacing: 1.2,
                },
                !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
              ],
            }}
            onClick={() => {
              this.scrollToSection(
                this.subscriptionLayout + this.subscriptionExtraheight
              );
            }}
            icon={buttonTopArrow}
            topIcon={true}
          />
          <Text
            style={{
              fontSize: 18,
              fontFamily: FontFamily.regular,
              fontWeight: "400",
              color: darkMode ? Colors.lightWhite : "#000000",
              marginTop: 40,
              marginBottom: 20,
            }}
          >
            More questions? Let us help
          </Text>
          <View>
            <Button
              title={"Contact us"}
              rootStyle={{
                btnWrapper: [{}],
                btnText: [
                  {
                    fontFamily: FontFamily.regular,
                    fontSize: helpers.isDesktop() ? 20 : 14,
                    lineHeight: helpers.isDesktop() ? 20 : 18,
                    color: darkMode ? Colors.lineColor : Colors.brandColor1,
                    textAlign: "center",
                  },
                ],
              }}
              onClick={() => {
                this.openContactUs();
              }}
              rightIcon={true}
            />
          </View>
        </View>
      </View>
    );
  };

  openContactUs = () => {
    if (helpers.isWeb()) {
      window.open("mailto:support@themorningcontext.com");
    } else {
      Linking.openURL(`mailto:support@themorningcontext.com`);
    }
  };

  render() {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    let postSummary;

    if (postData && postData.story) {
      postSummary = {
        featured_image: postData.featured_image,
        category: postData.category,
        newsletter: postData.newsletter,
        postTitle: postData.story.title,
        author: postData.author,
        story: postData.story,
        storyStats: storyStats,
        statsLoader: statsLoader,
        lazyLoad: !postDataSSR,
        freeRead: postData?.unlock_data?.type == "free" ? true : false,
        is_free: postData.is_free,
      };
    }
    const {
      pageLoader,
      postData,
      showErrorModal,
      token,
      footerData,
      redirectSlugs,
      showInsufficientCreditsPopup,
      creditPlan,
      country,
      teamSubscription,
      hideHeader,
      postUnlocked,
      scrollEnabled,
      storyActions,
      noPageFound,
      hideSideShareActionButton,
      userSubscription,
      freeCreditsAvailable,
      showGiftClaimModal,
      giftStoryData,
    } = this.state;

    let showGiftbox =
      userSubscription &&
      userSubscription.plan &&
      freeCreditsAvailable != null &&
      freeCreditsAvailable != undefined;
    let giftStory =
      showGiftbox &&
      freeCreditsAvailable + 1 > postData?.story?.credits?.unlock &&
      !postData.is_free;

    let giftData = {
      from: "Priya",
      story: {
        slug: "will-the-real-vijay-shekhar-sharma-please-stand-up",
        title: "Will the real Vijay Shekhar Sharma please stand up?",
        featured_image:
          "https://tmcstage-media.s3.ap-south-1.amazonaws.com/2020/06/09201316/23659246738_9a8f7e6b4b_o-315x210.jpg",
        author: ["Ashish K. Mishra"],
        category: "business",
        date: "2021-03-02 09:55:00",
      },
    };


    return (
      <View
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          if (webView) {
            this.viewOffset = layout.height;
          }
        }}
        ref={this.scrollRef}
        style={[
          webView && null,
          { flex: 1, backgroundColor: "#fff" },
          appStyles.appBg,
          darkMode && appStyles.appBgDark,
        ]}
      >
        <Seo
          data={postData.seo_metadata ? postData.seo_metadata : {}}
          url={
            process.browser
              ? window.location.origin + window.location.pathname
              : ""
          }
          page="/:category_slug/:post_slug"
          pageData={postData}
        />

        <AppHeader
          ref="header"
          history={this.props.history}
          searchQuery={this.props.searchQuery}
          locationHash={this.props.locationHash}
          navigateWeb={(slug) => this.props.navigateToScreen(slug)}
          navigateApp={(slug, params) =>
            this.props.navigation.navigate(slug, params)
          }
          redirectSlugs={redirectSlugs}
          refreshPage={() => this.getPost(true)}
          hideHeader={
            pageLoader || !postUnlocked
              ? false
              : hideHeader || showGiftClaimModal
          }
          goBack={() => this.props.navigation.goBack()}
          darkMode={darkMode}
          applyStoryActions={(type, value) =>
            this.applyStoryActions(type, value)
          }
          sharePost={(slug) => this.sharePost(slug)}
          singlePost={true}
          storyActions={storyActions}
          postUnlocked={postUnlocked}
          openGiftCardModal={() =>
            this.refs.header.openGiftCardModal(
              PrivilegeCreditGift.id,
              PrivilegeCreditGift,
              null,
              null,
              postData,
              postData?.story?.credits?.unlock
            )
          }
          openGiftSubModal={() =>
            this.refs.header.openGiftSubModal(
              PrivilegeCreditGift.id,
              PrivilegeCreditGift,
              null,
              null,
              postData,
              postData?.story?.credits?.unlock
            )
          }
          giftStory={giftStory}
          setCheckoutLoaderState={(loaderState) =>
            this.setPlanButtonLoaderState(loaderState)
          }
          getCheckoutLoaderState={this.getPlanButtonLoaderState}
        />

        {noPageFound ? (
          <NotFound
            navigateToScreen={() => {
              this.props.navigateToScreen("/");
            }}
            darkMode={darkMode}
          />
        ) : (
          <>
            {!showErrorModal && (
              <>
                {webView ? (
                  pageLoader ? (
                    this.showPageLoader()
                  ) : (
                    this.renderPageContent()
                  )
                ) : (
                  <>
                    <ScrollView
                      ref={(ref) => (this.appscrollview = ref)}
                      scrollEnabled={scrollEnabled}
                      style={[{ flex: 1 }, { paddingTop: 56 }]}
                      onScroll={this.getScrollOffset}
                    >
                      {pageLoader
                        ? this.showPageLoader()
                        : this.renderPageContent()}
                    </ScrollView>
                    {/* {!hideSideShareActionButton && postUnlocked && postData.story.content && <PostShare data={postSummary} postContent={true} postTitle={postData.story.title} storyActions={storyActions} applyStoryActions={(type, value) => this.applyStoryActions(type, value)} sharePost={(slug) => this.sharePost(slug)} shareTitle={postData.story.title} darkMode={darkMode} />} */}
                  </>
                )}
              </>
            )}
          </>
        )}
        <View
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            this.footerOffset = layout.height;
          }}
        >
          <AppFooter
            navigateWeb={(slug) => this.props.navigateToScreen(slug)}
            navigateApp={(slug, params) =>
              this.props.navigation.navigate(slug, params)
            }
            footerData={footerData}
            Breadcrumbs={Breadcrumbs}
          />
        </View>
        {/*{(token == false) && <StickyFooter goToLogin={() => this.goToLogin()} goToSignUp={() => this.goToSignUp()} />}*/}
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.navigateToHome()}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
        {showInsufficientCreditsPopup && (
          <InsufficientCreditPopup
            token={token}
            closeModal={() =>
              this.setState({ showInsufficientCreditsPopup: false })
            }
            plan={[creditPlan]}
            country={country}
            subscribeToPlan={(subscribtionId, subscribtion, country) =>
              this.subscribeToPlan(subscribtionId, subscribtion, country)
            }
            credits={postData.story.credits.unlock}
            teamSubscription={teamSubscription}
            navigateToTeamPage={() => this.navigateToTeamPage()}
          />
        )}
        {showGiftClaimModal && (
          <GiftClaimModal
            giftData={giftStoryData}
            darkMode={darkMode}
            closeGiftClaimModal={() =>
              this.setState({ showGiftClaimModal: false })
            }
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken, refreshScreen }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(PostSingle));