import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  TextInput,
} from "react-native";
import {
  PostList,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  button,
  formStyle,
  unlockBox,
  signUpPage,
  Colors,
  modalStyle,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import SignUpForm from "../../components/sign-up-form";
import getImagePath from "../../utils/image-helper";
import CustomImage from "../../components/custom-image";
import AppHeaderView from "../../components/app-header-view";
import TouchableOpacityLink from "../../components/touchable-opacity-link";
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";
import AuthenticationWrapper from "../../components/authentication-wrapper";
import ReactGA from "react-ga";

const checkIcon = getImagePath("icons/check.png");
const starterIcon = getImagePath("icons/starterCollection.png");
const closeIcon = getImagePath("icons/grey-close-in.png");

export class SignUp extends Component {
  constructor(props) {
    super(props);
    let redirectUrl = null;
    let verifyRedirectUrl = null;
    let email = null;
    let allParams = null;
    let webView = helpers.isWeb();
    let redirectParams = null;
    if (
      webView &&
      process.browser &&
      window.location.search.includes("redirect=")
    ) {
      let searchArr = window.location.search.replace("?", "").split("&");
      let foundSearch = searchArr.find((searchParam) => {
        return searchParam.includes("redirect=");
      });
      redirectParams = searchArr.find((searchParam) => {
        return searchParam.includes("redirect-params=");
      });
      if (foundSearch) {
        let search = foundSearch.replace("redirect=", "");
        redirectUrl = search != "" ? search : null;
        verifyRedirectUrl = redirectUrl;
      }
    } else if (!webView && this.props.route.params) {
      redirectUrl = this.props.route.params.redirect
        ? this.props.route.params.redirect
        : null;
      verifyRedirectUrl = this.props.route.params.verifyRedirect
        ? this.props.route.params.verifyRedirect
        : null;
      allParams = this.props.route.params;
    }
    if (redirectParams) {
      redirectUrl = redirectUrl + "?" + redirectParams;
    }

    this.state = {
      searchQuery: "",
      locationHash: "",
      userIpAddress: "",
      enableBtnLoader: false,
      redirectUrl: redirectUrl,
      verifyRedirectUrl: verifyRedirectUrl,
      allParams: allParams,
    };
  }

  // navigateBack = () => {
  //     let { redirectUrl } = this.state;
  //     if (helpers.isWeb()) {
  //         this.props.history.replace(redirectUrl ? redirectUrl : '/')
  //     }
  //     else {
  //         this.props.navigation.navigate(redirectUrl ? redirectUrl : '/');
  //     }
  // }

  componentDidMount = () => {
    const { login } = this.props.auth;
    const redirectTo = this.state.redirectUrl;
    console.log("redirectTosignup:", redirectTo);
    let defaultParams = { appState: { returnTo: redirectTo } };
    login({ ...defaultParams });
  };
  render() {
    // const webView = helpers.isWeb();
    // const desktopView = webView && helpers.isDesktop();

    // let navigateBackProp = {};
    // navigateBackProp = {
    //     onPress: this.navigateBack
    // }

    // let data = {
    //     pageName : 'Sign Up'
    // }

    return null;
    // return (
    //     <View style={{height: '100%'}}>
    //         {helpers.isDesktop() && <AppHeaderView />}
    //         <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/sign-up" />
    //         <View style={[signUpPage.wrapper, helpers.isDesktop() && Flex.justfiyCenter, {backgroundColor: desktopView ? '#E5E5E5' : 'white'}, helpers.isWeb() && { height: '100vh' }, helpers.isDesktop() && {paddingLeft: 25, paddingRight: 25, paddingTop: 90, paddingBottom: 20, flex: 1}]}>
    //             <View style={[Flex.row, helpers.isWeb() && { maxWidth: '1150px', margin: desktopView ? 'auto' : 0, width: '100%' }]}>
    //                 <View style={{width: desktopView ? '50%' : '100%'}}>
    //                     <SignUpForm navigateToScreen={this.props.navigateToScreen} navigation={this.props.navigation} goBack={() => this.props.history.goBack()} history={this.props.history} route={this.props.route}/>
    //                 </View>
    //                 {desktopView &&
    //                     <>
    //                         <View style={{backgroundColor: 'white', paddingTop: 10, paddingBottom: 3, zIndex: -1}}><View style={[Margin.mt_2, Margin.mb_2, {borderLeftWidth: 1, borderColor: '#DEDEDE', height: '94%'}]}/></View>
    //                         <View style={[{ width: '50%', backgroundColor: 'white', paddingHorizontal: helpers.isDesktop() ? 45 : 0,  zIndex: -1}, Flex.justfiyCenter, Flex.alignCenter]}>
    //                             <View style={[Flex.row,{alignItems:'end', paddingBottom: 15}]}>
    //                                 <CustomImage
    //                                     source={starterIcon}
    //                                     require={true}
    //                                     style={{ width: 180, height: 135, resizeMode: 'contain' }}
    //                                     webStyle={{ width: 180, height: 'auto', objectFit: 'contain' }}
    //                                     altText={'Credits'}
    //                                 />
    //                             </View>
    //                             <Text style={[signUpPage.planText, Margin.mt_1]}>Get <Text style={{color: '#FD953C'}}>10 free Starter</Text> Credits on Sign up</Text>
    //                             <View style={[Padding.pt_3]}>
    //                                 <View>
    //                                     <View style={[Flex.row,{alignItems:'flex-start'}]}>
    //                                         <CustomImage
    //                                             source={checkIcon}
    //                                             require={true}
    //                                             style={{ width: 15, height: 10, resizeMode: 'contain', marginTop: 5  }}
    //                                             webStyle={{ width: 15, height: 10, objectFit: 'contain', marginTop: 5 }}
    //                                         />
    //                                         <Text style={[signUpPage.infoText, Padding.pl_1]}>10 credits, which do not expire.</Text>
    //                                     </View>
    //                                     <View style={[Flex.row,{alignItems:'flex-start'}]}>
    //                                         <CustomImage
    //                                             source={checkIcon}
    //                                             require={true}
    //                                             style={{ width: 15, height: 10, resizeMode: 'contain', marginTop: 5  }}
    //                                             webStyle={{ width: 15, height: 10, objectFit: 'contain', marginTop: 5 }}
    //                                         />
    //                                         <Text style={[signUpPage.infoText, Padding.pl_1]}>Access to starter collection</Text>
    //                                     </View>
    //                                     <View style={[Flex.row,{alignItems:'flex-start'}]}>
    //                                         <CustomImage
    //                                             source={checkIcon}
    //                                             require={true}
    //                                             style={{ width: 15, height: 10, resizeMode: 'contain', marginTop: 5  }}
    //                                             webStyle={{ width: 15, height: 10, objectFit: 'contain', marginTop: 5 }}
    //                                         />
    //                                         <Text style={[signUpPage.infoText, Padding.pl_1]}>Unlock specific stories, newsletters and long reads</Text>
    //                                     </View>
    //                                 </View>
    //                             </View>
    //                         </View>
    //                          <TouchableOpacity {...navigateBackProp}>
    //                             <CustomImage
    //                                 source={closeIcon}
    //                                 require={true}
    //                                 style={{ width: 28, height: 28, position: 'absolute', right: 25, top: 20, zIndex: 1 }}
    //                                 webStyle={{ width: 28, height: 28, position: 'absolute', right: 25, top: 20, zIndex: 1 }}
    //                             />
    //                         </TouchableOpacity>
    //                     </>
    //                 }
    //             </View>
    //         </View>
    //     </View>
    // );
  }
}

export default AuthenticationWrapper(SignUp);
