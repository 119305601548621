import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator, Linking } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, pageContent, pageCover, button, list, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import TermsConditions from '../../components/terms-and-conditions';
import Seo from "../../components/seo-meta";
import seoObj from "../../utils/seo-helper";

const cookies = new Cookies();

export class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        this.state = {
            pageLoader: false,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false,
        }
    }

    componentDidMount = () => {

    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[helpers.isDesktop() ? Margin.mb_7 : Margin.mb_5, Helpers.textCenter, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, Flex.alignCenter, { marginTop: helpers.isDesktop() ? 80 : 30, paddingHorizontal: helpers.isDesktop() ? 20 : 20 }]}>
                <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_2, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, !helpers.isDesktop() && { fontSize: 34 }, darkMode && { color: Colors.lightWhite }]}>Privacy Policy</Text>
                <Text style={[{ fontSize: helpers.isDesktop() ? 24 : 18, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.regular }, darkMode && { color: Colors.lightWhite }]}>Last updated: 01 July 2024</Text>
            </View>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }

    renderEmailId = (emailId) => {
        return (
            <Text 
                onPress={() => Linking.openURL(`mailto:${emailId}`)} 
                style={{fontWeight: '500', color: 'blue', textDecorationLine: 'underline'}}
            >
                {emailId}
            </Text>
        );
    };

    renderHyperlink = ({link_text, link_url}) => {
        return (
            <Text 
                onPress={() => window.open(link_url, '_blank')} 
                style={{fontWeight: '500', color: 'blue', textDecorationLine: 'underline'}}
            >
                {link_text}
            </Text>
        );
    }

    renderPageContent = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        
        return (
            <>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb900]}>
                    <View style={[helpers.isDesktop() && Margin.mb_6, !helpers.isDesktop() && Margin.mb_3]}>

                        <View>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                This website is published by Slowform Holdings Ltd (“The Morning Context”) - CRN: 14752235. Our registered address is 86-90 Paul Street, London, England, EC2A 4NE.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                This privacy policy covers the processing of personal data of all individuals (“users”, “you” and “your”) who visit, register, or have registered on their behalf, their details (“data”) on this website. The Morning Context takes your privacy seriously, and prioritizes your informed consent over everything else. We want you to understand our business, the personal data we collect from you and the limited universe of how we use that data. Which is why it is important that you take time out to read our privacy policy—and also why we’ve done our best to make it readable.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                You can stop being a user at any time by emailing us at: {this.renderEmailId('support@themorningcontext.com')}, whereupon all benefits and privileges available to you as a user will cease also.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                This privacy policy also describes your data protection rights, including a right to object to some of the processing that The Morning Context carries out or where The Morning Context relies on consent and how to withdraw that consent. More information about your rights, and how to exercise them, is set out in the “Your Rights” section.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                            If you have any requests concerning your personal information or any queries regarding these practices, please contact us by e-mail at {this.renderEmailId('support@themorningcontext.com')}.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Personal data</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                First, when we say “personal data”, we mean anything you share with us that is personally identifiable. All the personal data we collect is kept confidential. We collect your email address to create your account, either when you sign up for free as a reader or when you subscribe to gain access to all our stories. That is all the personally identifiable data we need. We also have access to and store partial details of your payment methods, such as the last four digits and expiry date of your credit or debit card, some of your billing details which we keep in our transaction records.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                We use your email address to create an account and authenticate you as a reader or subscriber on our website, themorningcontext.com, and to send you our story emails and weekly newsletter, as well as updates and offers on our services. We also use your details to personalize our website and emails, as well as to collect payments and generate invoices.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                For corporate/institutional subscriptions and gift subscriptions, intermediaries (such as an institution, company or individual) may provide us with personal data of third-party individuals (such as employees, friends, family etc.). In these cases, the intermediary party undertakes that it has obtained the necessary consent from any such third-party individual on the collection and use of personal data by the Company.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                We will only collect, hold, process, use, communicate and/or disclose such personal data, in accordance with this policy.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Information The Morning Context collects automatically from your device</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                The Morning Context collects certain personal data directly from your device when you visit the website. This includes, in particular, information related to the browser or device you use to access The Morning Context’s site such as Internet Protocol (IP) addresses, the date and time that you visited the site, the type of Internet browser and computer operating system, referral source, length of visit, page views and website navigation paths.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Third parties</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                We may share your data with third-party service providers, a full list of which is given below, to process your payments, to manage and send out our newsletters, to monitor and analyse traffic on our website. All the third-party vendors we rely on to run our services are also bound to maintain the security and confidentiality of your personal data.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Google Analytics,</Text> a website analytics service, allows us to monitor and analyse traffic on our website, as well as record information such as time spent, stories read and completion rates;</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Mailchimp,</Text> an email marketing service, which we use to maintain our email subscriber list, to schedule and send out our morning emails and weekly newsletter, and to record statistics such as email delivery rates, open rates and click-through rates;</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Chargebee,</Text> a subscriptions management service, which we use to handle subscriptions and sales; and</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Stripe,</Text> a payment gateway, which we use to collect and process payments from customers; and</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}><Text style={{ fontFamily: FontFamily.merriweatherB }}>Razorpay,</Text> a payment gateway, which we use to collect and process payments from customers.</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                            Your data may be shared with third-party service providers who will process it on The Morning Context’s behalf for the purposes identified above. Such third parties include providers of website hosting, maintenance, research and analytics solutions, IT services, advertisement display functions, related analytics for The Morning Context’s sites and identity-checking payment solutions.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Change in Control</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                In the event The Morning Context goes through a sale, merger or business transition, such as being acquired by another company, or selling a portion of its assets, users’ personal information will, in most instances, be part of the assets transferred. Therefore, it may be disclosed to The Morning Context’s advisers and any prospective purchaser’s advisers and will be passed to the new owners of the business and designated service providers.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                            The Morning Context may share with other third parties information about users of {this.renderHyperlink({link_text: 'www.themorningcontext.com', link_url: '/'})}, such as your use of this site only on an anonymous and aggregate basis.
                            </Text>
                            
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>International transfer of data</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                Where your personal data is transferred outside of the EEA and Switzerland, and where this is to an affiliate or service provider in a country which is not subject to an adequacy decision by the EU Commission, The Morning Context will take steps to ensure your personal information is adequately protected either by EU Commission approved Standard Contractual Clauses, an appropriate Privacy Shield certification in the case of transfers to the US, Binding Corporate Rules, or other appropriate safeguard. A copy of the relevant mechanism can be obtained for your review on request by using the contact details in the “Your Rights” section.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Cookies and similar technologies</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                This policy also applies to any personal data we collect via our websites. Cookies may be used on some areas of our website. “Cookies” are small text files placed on your hard drive that assist us in providing a more customized website experience. Cookies are now used as a standard by many websites to improve users' navigational experience. If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our websites, however other functionality in the site may be impaired. After termination of the visit to our site, you can always delete the cookie from your system if you wish.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                In order to properly manage our website and related email services, we may anonymously log information on our operating systems about the number and duration of visits to our website and identify categories of visitors by items such as domains and browser types, and to track document identification number by way of email acknowledgement or return receipt. These statistics are reported in the aggregate to our webmaster and system administrators. In addition, site statistics are compiled by third parties, and therefore your IP address will be passed to third parties for statistical reporting only. This is to ensure that our website presents the best web experience for visitors and is an effective information resource.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                Whenever a registered member visits our website, they may be required to identify themselves in order to gain entry. This data is logged by Company primarily to measure website performance and in assisting to resolve any website technical difficulties.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                A link to The Morning Context’s full Cookie Policy is located here.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>How is this information secured?</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                The Company has implemented generally accepted standards of technology and operational security in order to protect your personal information from loss, misuse or destruction. In particular, the Company has put in place appropriate confidentiality obligations and technical and organizational security measures to prevent any unauthorized or unlawful disclosure or processing of such information and the accidental loss or destruction of or damage to such information.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>How long is information kept?</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                            We retain the majority of your personal information for as long as your account is active and therefore open; this allows you to continue accessing our content, products and services. To request us to delete your personal data submitted to company whether through our websites or otherwise, please send your request to {this.renderEmailId('support@themorningcontext.com')}.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Correction and Concerns</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                If you believe that information we hold about you is incorrect, if you have concerns about how we are handling your personal data, or if you changed your mind about our usage of your data, please email us at {this.renderEmailId('support@themorningcontext.com')} and we will try to resolve those concerns.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Your Rights</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                You have the right to ask us: For confirmation on whether The Morning Context processes your personal data, certain information in this regard and a copy of your personal data undergoing said processing; To correct, complete, delete or restrict (stop any active) processing of your personal data; To exercise your right to portability, i.e., to obtain the personal data you provide to us for a contract or with your consent in a structured, machine-readable format; To ask us to share (port) this data to another controller.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                You also have the right to lodge a complaint with a supervisory authority, in particular in the EU Member State of your residence, or the location where the issue that is the subject of the complaint occurred.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                Wherever The Morning Context relies on your consent, you will always be able to withdraw that consent, although The Morning Context may have other legal grounds for processing your data for other purposes, such as those set out in this privacy policy. These rights may be limited, for example, if fulfilling your request would reveal personal data about another person, or if you ask us to delete information which is required by law to keep or has compelling legitimate interests in keeping.
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                To exercise any of these rights or for other questions regarding this privacy policy, you can get in touch with us by email at {this.renderEmailId('support@themorningcontext.com')} or in writing to: Slowform Holdings Ltd (“The Morning Context”) - CRN: 14752235. Our registered address is 86-90 Paul Street, London, England, EC2A 4NE
                            </Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                We have appointed “EU Rep” as our Representative under Article 27 of the EU General Data Protection Regulation (“GDPR”). All GDPR queries from EU Data Subjects or Data Protection authorities should be submitted to {this.renderHyperlink({link_text: 'eurep.ie', link_url: 'http://eurep.ie'})} via their dedicated form. BizLegal Ltd trading as EU Rep have their registered office at 27 Cork Road, Midleton Co. Cork, Ireland. Company number 635921.
                            </Text>
                        </View>

                        <View style={[helpers.isDesktop() ? Margin.mt_5 : Margin.mt_3]}>
                            <Text style={{ fontSize: helpers.isDesktop() ? 24 : 20, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.5, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, paddingHorizontal: 20, marginBottom: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>Changes to this notice</Text>
                            <Text style={{ fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 32 : 24, letterSpacing: 0.3, fontFamily: FontFamily.merriweather, marginBottom: helpers.isDesktop() ? 30 : 20, paddingHorizontal: 20, color: darkMode ? Colors.lightWhite : Colors.black }}>
                                From time to time The Morning Context may make changes to this privacy policy to reflect any changes to The Morning Context’s privacy practices in accordance with changes to legislation, best practice or site enhancements. The Morning Context reserves the right to change this privacy policy at any time by posting revisions on this site, and notifying you of such changes when you visit the website.
                            </Text>
                        </View>

                    </View>
                </View>

            </>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/privacy-policy',
            native: { redirect: '/privacy-policy', verifyRedirect: '/privacy-policy' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/privacy-policy',
            native: { redirect: '/privacy-policy', verifyRedirect: '/privacy-policy' }
        }
        let hideHeader = this.props?.route?.params?.hideHeader;

        let data = {
            pageName: 'Privacy Policy'
        }

        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>
                <Seo data={seoObj.getDefaultSeo(data)} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/privacy-policy" />
                <AppHeader history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} hideHomeIcon={hideHeader} hideAccountIcon={hideHeader} showFullHeader={true} />
                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }
                    {showUparrow &&
                        <View style={{ position: webView ? 'fixed' : 'absolute', bottom: 20, right: 0, zIndex: 2, }}>
                            <TouchableOpacity onPress={() => this.scrollToTop()} activeOpacity={0.8} style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                                <CustomImage
                                    source={upArrowIcon}
                                    require={true}
                                    style={{ width: 60, height: 60 }}
                                    webStyle={{ width: 60, height: 60 }}
                                />
                            </TouchableOpacity>
                        </View>
                    }</>
                }

                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Privacy Policy'
    }
]