import React, { Component } from 'react';
import { Text, View, Image, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, FontFamily, FontWeight, pageCover, button, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';
import getImagePath from '../../utils/image-helper';

const notFoundBg = getImagePath('img/404-page.png');

export class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {

    }

    render() {
        const { darkMode } = this.props;
        return (
            <View style={[Helpers.conatinerWeb, Flex.alignCenter, Flex.justifyCenter, helpers.isDesktop() && Helpers.conatinerWeb900,{marginTop: helpers.isDesktop() ? 70 : 50}, {marginBottom: helpers.isDesktop() ? 70 : 50}]}>
                <CustomImage
                    source={notFoundBg}
                    require={true}
                    webStyle={{ width: helpers.isDesktop() ? 400 : 240, height: helpers.isDesktop() ? 400 : 'auto', marginBottom: 20}}
                    style={{ width: helpers.isDesktop() ? 400 : 240, height: helpers.isDesktop() ? 400 : 'auto', marginBottom: 20 }}
                    altText={'Page not found'}
                />
                <View style={[ Margin.mb_5]}>
                    <Text style={[{fontSize: helpers.isDesktop() ? 36 : 20, lineHeight: helpers.isDesktop() ? 46 : 32, fontFamily: FontFamily.medium, textAlign: 'center', paddingHorizontal: 40}, darkMode && {color: Colors.lightWhite}]}>Oops... The page you are looking for is not here</Text>
                </View>
                <View style={[{width: 300}]}>
                    <ButtonGradient
                        title={'GO TO HOME'}
                        fullWidth={true}
                        rootStyle={{
                            btnWrapper: [button.primaryGradient, { height: 46 }],
                            btnText: [button.primaryGradientText, { fontSize: helpers.isDesktop() ? 17 : 16, paddingHorizontal: 80, letterSpacing: helpers.isWeb() ? '0.05em' : 0.05, fontWeight: '400' }]
                        }}
                        paddingOverride={true}
                        onClick={() => {this.props.navigateToScreen()}}
                        darkMode={darkMode}
                    />
                </View>
            </View>
        );
    }
}

export default NotFound;