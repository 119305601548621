import { ManagementAPI__I } from "./interface";
import { syncObject } from "../helper";

export abstract class ManagementAPI implements ManagementAPI__I{
    billingData: any
    tmcAuthToken?: any
    constructor(){
        this.billingData = {}
    }

    init(initData: any){
        this.billingData = initData
        return true
    }
    update(updatedData: any){
        try{
            this.billingData = syncObject(this.billingData, updatedData)
        }
        catch(err){
            console.log('chargebeeHandler:ManagementAPI:update:Error: ', err)
            return false
        }
        return true
    }
    setToken(tmcAuthToken: any): any{
        this.tmcAuthToken = tmcAuthToken
    }

}


