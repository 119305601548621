'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * List of void elements
 * These elements are not allowed to have children
 * @type {Array}
 */
exports.default = ['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'];