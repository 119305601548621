import React, { Component } from 'react';
import { Text, View, TextInput, ActivityIndicator } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, button, article, FontFamily, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import ButtonGradient from '../button-gradient';
import PageBreak from '../page-break';
import apiHelpers from "../../utils/api-helpers";

class BulkTeamSubscription extends Component {
    controller;
    signal;
    constructor(props) {
        super(props);

        this.state = {
            seats: 10
        }
    }

    componentDidMount() {
        this.getEstimate()
    }

    render() {
        let { seats, totalAmount, enableLoader } = this.state;
        let { planData, country } = this.props;
        return (
            <View style={[Margin.mt_4]}>
                <View style={[article.headerWrapper]}>
                    <View style={[Flex.alignCenter, helpers.isDesktop() && Margin.mb_3, !helpers.isDesktop() && Margin.mb_2, !helpers.isDesktop() && Padding.pt_3, { width: helpers.isWeb() ? 'fit-content' : '100%', marginLeft: 'auto', marginRight: 'auto' }]}>
                        <View style={[Helpers.titleBgWrap, { backgroundColor: 'rgba(250 250 250,1)' }]}>
                            <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, { fontSize: 18, textTransform: 'regular', marginBottom: 2 }, { fontFamily: FontFamily.abrilFatface }, { fontSize: helpers.isDesktop() ? 34 : 20 }]}>Buy a bulk team  subscription</Text>
                        </View>
                        <PageBreak />
                    </View>
                </View>

                <View>
                    <Text style={{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 27, color: '#000000', textAlign: 'center', letterSpacing: 0.3, maxWidth: 550, marginLeft: 'auto', marginRight: 'auto', paddingHorizontal: helpers.isDesktop() ? 30 : 0 }}>How Many seats?</Text>

                    <View style={[Flex.alignCenter, Flex.justfiyCenter, Margin.mb_1]}>
                        <TextInput
                            placeholderTextColor={Colors.placeholderTextColor}
                            style={[{ borderWidth: 1, borderColor: '#907CFF', height: 40, width: 80, fontSize: 14, fontFamily: FontFamily.regular, paddingHorizontal: 10 }]}
                            onChangeText={(value) => this.setSeats(value)}
                            value={seats}
                        />
                    </View>

                    {
                        parseInt(seats) > 1
                            ?
                            enableLoader ?
                                <View style={[{ marginBottom: helpers.isDesktop() ? 35 : 35 }]}>
                                    <ActivityIndicator size={25} color={'#787676'} />
                                </View>
                                :
                                <Text style={{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 27, color: '#000000', textAlign: 'center', marginBottom: helpers.isDesktop() ? 35 : 35, letterSpacing: 0.3, maxWidth: 550, marginLeft: 'auto', marginRight: 'auto', paddingHorizontal: helpers.isDesktop() ? 30 : 0 }}>Total: {planData.currency_code == 'INR' ? '₹' : '$'} {totalAmount}</Text>
                            :
                            <Text style={{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 27, color: '#F02121', textAlign: 'center', marginBottom: helpers.isDesktop() ? 35 : 35, letterSpacing: 0.3, maxWidth: 550, marginLeft: 'auto', marginRight: 'auto', paddingHorizontal: helpers.isDesktop() ? 30 : 0 }}>*Minimum number of seats is 2</Text>
                    }
                    <ButtonGradient
                        title={'Procceed to checkout'}
                        rootStyle={{
                            btnWrapper: button.primaryGradient,
                            btnText: [button.primaryGradientText, { fontWeight: '400' }],
                        }}
                        disable={!seats || parseInt(seats) < 2 || enableLoader}
                        onClick={() => this.props.showSubscriptionPlan(planData.id, planData, country, seats)}
                    />
                </View>
            </View>
        )
    }

    setSeats = (value) => {
        let key = value.split('');
        let keyList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        for (var i = key.length - 1; i >= 0; i--) {
            if (keyList.indexOf(key[i]) == -1) {
                return false;
            }
        }
        this.setState({ seats: value }, () => {
            if (value && parseInt(value) > 1) {
                this.getEstimate()
            }
        });
    }

    abortRequest = () => {
        if (this.controller) {
            this.controller.abort();
        }
    }

    getEstimate = () => {
        this.abortRequest();
        this.controller = new AbortController();
        this.signal = this.controller.signal;
        let signal = this.signal;

        const { planData, token } = this.props;
        let { seats } = this.state;
        let endpoint = `/subscription-estimate`;
        let body = {
            plan_id: planData.id,
            quantity: seats
        }
        this.setState({ enableLoader: true })
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token, signal)
            .then((res) => {
                if (res.success && res.code == 'subscription_estimate') {
                    this.setState({
                        totalAmount: res.data.amount_due,
                        breakupDifference: res.data.credits_applied,
                        enableLoader: false
                    })
                } else {
                    this.setState({ enableLoader: false })
                    console.log('ResError=>>', res);
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
                if (error && error.aborted) {
                    // Do nothing
                }
                else {
                    this.setState({ enableLoader: false })
                }
            });
    }
}

export default BulkTeamSubscription;
