import { StyleSheet, StatusBar, Platform } from "react-native";

const Utility = StyleSheet.create({
  bannerBox: {
    border: "1px solid #ffffff",
    borderTopWidth: "0px",
    paddingTop: "33px",
    paddingBottom: "24px",
  },
  bannerTop: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    top: "-8px",
    width: "100%",
  },
  heading: {
    color: "#ffffff",
    textAlign: "center",
  },
});

export { Utility };
