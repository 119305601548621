import React, { Component } from "react";
import { View, Text } from "react-native";
import { styles } from './whoReadsUsStyles'
import CustomImage from '../../native/components/custom-image'
import getImagePath from '../../native/utils/image-helper';
import { WHAT_OUR_READERS_SAY } from '../../native/utils/constants'
import { helpers } from "../../native/utils/helpers";
import { TestimonialCarousel } from "../../native/components/testimonial-carousel/index.web";
import { Flex, appStyles, button } from "../../native/styles/appStyles";
import Button from "../../native/components/button";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "react-native";
import { AppFooter } from "../../native/components/app-footer/index.web";
import dynamic from 'next/dynamic';
import apiHelpers from "../../native/utils/api-helpers";
import ThankYouComponent from "../../native/components/customer-thank-you";
import AppHeader from "../../native/components/app-header";
import { withRouter } from "react-router-dom";
import flagUAEIcon from '../../../assets/icons/flag-UAE-icon.png'
import map from '../../../assets/img/map.png'


const ourReadersImage = getImagePath('img/our-readers-img.svg')
const sectorsOurReadersComeFromImage = getImagePath('img/sectors-our-readers-come-from-img.png')

const flagIndiaIcon = getImagePath('icons/flag-India-icon.png')
const flagUKIcon = getImagePath('icons/flag-UK-icon.png')
const flagSingaporeIcon = getImagePath('icons/flag-Singapore-icon.png')
const flagGermanyIcon = getImagePath('icons/flag-Germany-icon.png')
const flagUSAIcon = getImagePath('icons/flag-USA-icon.png')

const retailsIcon = getImagePath('icons/retails.svg')
const legalServicesIcon = getImagePath('icons/legal-services.svg')
const itTechIcon = getImagePath('icons/it-technology.svg')
const govtIcon = getImagePath('icons/govt.svg')
const bankingIcon = getImagePath('icons/banking.svg')
const accountancyIcon = getImagePath('icons/accountancy.svg')
const businessLeadersIcon = getImagePath('icons/business-leaders.svg')
const industrialGoodsAndServicesIcon = getImagePath('icons/industrial-goods-and-services.svg')
const startupsIcon = getImagePath('icons/startups.svg')
const aerospaceAndDefenceIcon = getImagePath('icons/aerospace-and-defence.svg')

const entrepreneursIcon = getImagePath('icons/entrepreneurs-icon.png')
const facultyIcon = getImagePath('icons/faculty-icon.png')
const privateEquityFirmsIcon = getImagePath('icons/private-equity-firms-icon.png')
const researchOrgIcon = getImagePath('icons/research-org-icon.png')
const seniorLeadershipIcon = getImagePath('icons/senior-leadership-icon.png')
const stockMarketIcon = getImagePath('icons/stock-market-icon.png')
const vcIcon = getImagePath('icons/vc-icon.png')

const READER_STATS = [
    {
        text: '70% of our readers read The Morning Context daily',
        statPercentage: 70,
        bgColor: '#ECFCCB',
        borderLeftColor: '#84CC16',
        statPercentageColor: '#3F6212'
    },
    {
        text: '86% of our readers say that The Morning Context is a part of their news routine',
        statPercentage: 86,
        bgColor: '#CFFAFE',
        borderLeftColor: '#06B6D4',
        statPercentageColor: '#155E75'
    },
    {
        text: '73% of our readers say reading The Morning Context helps them do their jobs better',
        statPercentage: 73,
        bgColor: '#DBEAFE',
        borderLeftColor: '#3B82F6',
        statPercentageColor: '#1E40AF'
    },
    {
        text: '97% of our readers says I trust the news I read in The Morning Context',
        statPercentage: 97,
        bgColor: '#EDE9FE',
        borderLeftColor: '#8B5CF6',
        statPercentageColor: '#5B21B6'
    },
    {
        text: '90% of our readers say they want The Morning Context to be financially independent',
        statPercentage: 90,
        bgColor: '#FAE8FF',
        borderLeftColor: '#D946EF',
        statPercentageColor: '#86198F'
    },

]

const TOP_READER_COUNTRIES = [
    {
        name: "India",
        image: flagIndiaIcon
    },
    {
        name: "UK",
        image: flagUKIcon
    },
    {
        name: "Singapore",
        image: flagSingaporeIcon
    },
    {
        name: "UAE",
        image: flagUAEIcon
    },
    {
        name: "USA",
        image: flagUSAIcon
    },

]

const TOP_SECTORS_CARD = [
    {
        name: "Aerospace & Defence",
        icon: aerospaceAndDefenceIcon,
        borderLeftColor: '#735AFF'
    },
    {
        name: "Startups",
        icon: startupsIcon,
        borderLeftColor: '#2CB680'
    },
    {
        name: "Industrial goods & services",
        icon: industrialGoodsAndServicesIcon,
        borderLeftColor: '#FC876D'
    },
    {
        name: "Business Leaders",
        icon: businessLeadersIcon,
        borderLeftColor: '#F59E0B'
    },
    {
        name: "Accountancy",
        icon: accountancyIcon,
        borderLeftColor: '#9D174D'
    },
    {
        name: "Banking",
        icon: bankingIcon,
        borderLeftColor: '#06B6D4'
    },
    {
        name: "Government / Public service / NGO",
        icon: govtIcon,
        borderLeftColor: '#3B82F6'
    },
    {
        name: "IT / Technology",
        icon: itTechIcon,
        borderLeftColor: '#D946EF'
    },
    {
        name: "Legal Services",
        icon: legalServicesIcon,
        borderLeftColor: '#F43F5E'
    },
    {
        name: "Retail",
        icon: retailsIcon,
        borderLeftColor: '#F472B6'
    },

]

const OUR_READERS = [
    {
        name: "Private equity firms",
        icon: privateEquityFirmsIcon,
        color: '#06B6D4'
    },
    {
        name: "Senior leadership across private and public companies",
        icon: seniorLeadershipIcon,
        color: '#3B82F6'
    },
    {
        name: "Stock market investors and analysts",
        icon: stockMarketIcon,
        color: '#8B5CF6'
    },
    {
        name: "Faculty at educational institutes and individual students",
        icon: facultyIcon,
        color: '#10B981'
    },
    {
        name: "India watchers across research and policy organizations",
        icon: researchOrgIcon,
        color: '#D946EF'
    },
    {
        name: "Entrepreneurs",
        icon: entrepreneursIcon,
        color: '#84CC16'
    },
    {
        name: "Venture capital investors",
        icon: vcIcon,
        color: '#F43F5E'
    }

]



const StatCard = (props) => {
    const {
        bgColor = '#ECFCCB',
        text = "",
        statPercentage = 100,
        borderLeftColor = "#84CC16",
        statPercentageColor = '#3F6212'
    } = props
    return <View style={{
        width: `calc(${statPercentage}%)`,
        backgroundColor: bgColor,
        borderLeftColor: borderLeftColor,
        borderLeftWidth: 4,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '3vw',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }}>
        <Text style={styles.statCardTextStyle}>
            {text}
        </Text>
        <Text style={[{ color: statPercentageColor }, styles.statPercentageStyle]}>{`${statPercentage}%`}</Text>
    </View>
}

const StatCardMob = (props) => {
    const {
        bgColor = '#ECFCCB',
        text = "",
        statPercentage = 100,
        borderLeftColor = "#84CC16",
        statPercentageColor = '#3F6212'
    } = props
    return <View style={{ display: 'flex', flexDirection: 'column', rowGap: '1vh' }}>
        <Text style={[styles.statCardTextStyle, { width: '90%', lineHeight: 24, fontWeight: 500, fontSize: 14, lineHeight: '150%', fontWeight: 500, color: '#292929' }]}>
            {text}
        </Text>
        <View style={{
            width: `calc(${statPercentage}%)`,
            backgroundColor: bgColor,
            borderLeftColor: borderLeftColor,
            borderLeftWidth: 4,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '3vw',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: '60px',
            rowGap: '1vh'
        }}>
            <Text style={[{ color: statPercentageColor }, styles.statPercentageStyle, { fontSize: '40px', lineHeight: null }]}>{`${statPercentage}%`}</Text>
        </View>
    </View>
}

const OurReadersCard = (props) => {
    const { name = "", icon, color } = props
    return <View style={{ display: 'flex', flexDirection: 'column', width: '85%', borderTopColor: color, borderTopWidth: 4, backgroundColor: 'white', padding: 16, rowGap: 16 }}>
        <CustomImage
            source={icon}
            style={{
                width: 25,
                height: 'auto'
            }}
            webStyle={{
                width: 25,
                height: 'auto'
            }}
        />
        <Text style={{
            color: '#292929',
            fontFamily: "HaasGrotesk-Medium",
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '150%',
            letterSpacing: '0.3px'
        }}>{name}</Text>
    </View>
}

export class WhoReadsUsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showThankYouComponent: false,
            locationHash: "",
            searchQuery: "",
        };
    }

    componentDidMount() {
        if (window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if (window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }

    componentDidUpdate() {
        if (window.location.search != this.state.searchQuery) {
            this.setState({
                searchQuery: window.location.search
            })
        }
        if (window.location.hash != this.state.locationHash) {
            this.setState({
                locationHash: window.location.hash
            })
        }
    }

    onSubmitCustomerForm = (data) => {
        let endpoint = "/forms/contact-us/who_reads_us/";
        apiHelpers
            .apiRequest(endpoint, data, "POST", true, false)
            .then((res) => {
                if (res.status == "success") {
                    // this.setState({ isCustomerDataSubmitted: true });
                    console.log("Success")
                    this.setState({ showThankYouComponent: true })
                }
            })
            .catch((error) => {
                // this.setState({ isServerSideError: true });

                const res = error?.data;

                const errorMessage = Object.values(res).join(",");
                // this.setState({ serverSideErrorMessage: errorMessage });
                console.error(error);
            });
    };

    closeContactUsForm = () => {
        this.setState({ showThankYouComponent: false })
    }

    navigateToScreen = (route, params) => {
        let routeArr = route.split('?')
        const { history } = this.props;
        history.push({
            pathname: routeArr[0],
            search: routeArr[1] ? ('?' + routeArr[1]) : '',
            state: params
        });
    }


    render() {
        const validationSchema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
            businessEmail: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
            message: Yup.string().required("Message is required"),
        });

        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: "/who-reads-us",
            native: { redirect: "/who-reads-us", verifyRedirect: "/who-reads-us" },
        };


        if (!helpers.isDesktop()) {
            return <View style={styles.wrapper}>
                {/* App Header */}
                <View style={{ display: 'flex', width: '100%', zIndex: 2 }}>
                    <AppHeader ref="header" history={this.props.history} redirectSlugs={redirectSlugs} searchQuery={this.state.searchQuery} locationHash={this.state.locationHash} navigateWeb={(slug) => this.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} isPricing={false} goBack={() => this.props.navigation.goBack()} />
                </View>
                {/* Section 1: Read by decision makers */}
                <View style={styles.readByDecisionMakersWrapper}>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.titleText, { textAlign: 'center', width: '85%', fontWeight: 700, fontSize: 34, lineHeight: '150%', color: '#051462' }]}>
                            Read by decision makers
                        </Text>
                    </View>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.subTitleText, { textAlign: 'center', fontSize: 19, width: '85%', lineHeight: '170%', color: '#051462' }]}>
                            The Morning Context is read by everyone who wants to stay on top of technology, business and current affairs in India.
                        </Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '3vh', paddingBottom: '5vh' }}>
                        <View style={{ width: '100%' }}>
                            <Text style={{ color: '#1E1B4B', textAlign: 'center', fontFamily: "PlayfairDisplay-Bold", fontSize: '24px', fontWeight: 700, lineHeight: '120%', marginBottom: '5vh', color: '#1E1B4B' }}>Our Readers</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', rowGap: '3vh' }}>
                            {
                                OUR_READERS.map((reader) => <OurReadersCard {...reader} />)
                            }
                        </View>
                    </View>
                </View>
                {/* Section 2: Journalism that helps you work better */}
                <View style={styles.journalismThatHelpsWrapper}>
                    <View>
                        <Text style={[styles.secondaryTitleText, { width: '100%', textAlign: 'center', paddingHorizontal: '7.5%', fontSize: '34px', fontWeight: 700, lineHeight: '150%', color: '#292929' }]}>Journalism that helps you work better</Text>
                    </View>
                    <View>
                        <Text style={[styles.subTitleText, { width: '85%', textAlign: 'center', alignSelf: 'center', fontSize: '19px', color: '#292929' }]}>
                            Readers turn to The Morning Context to understand companies and their strategies, so that they can bring this knowledge into their own work.
                        </Text>
                    </View>
                    <View style={styles.readerStatsContainer}>
                        {READER_STATS.map((readerStat) => <StatCardMob {...readerStat} />)}
                    </View>
                    <View style={styles.stateSourceContainer}>
                        <Text style={styles.statSourceTextStyle}>Source: The Morning Context survey with paying readers.</Text>
                    </View>
                </View>
                {/* Section 3: Where are our readers section */}
                <View style={styles.whereAreReadersWrapper}>
                    <View>
                        <Text style={[styles.secondaryTitleText, { width: '80%', alignSelf: 'center', textAlign: 'center' }]}>Where are our readers</Text>
                    </View>
                    <View style={{ width: '100%' }}>
                        <Text style={[styles.subTitleText, { width: '85%', textAlign: 'center', fontSize: '19px' }]}>Top 5 countries where the readers of TMC are:</Text>
                    </View>
                    <View style={[styles.topReaderCountriesContainer, { flexWrap: 'wrap', columnGap: '5vw', rowGap: '2vh' }]}>
                        {TOP_READER_COUNTRIES.map((topReaderCountry) => <View style={[styles.topReaderCountriesCardContainer]}>
                            <CustomImage
                                source={topReaderCountry.image}
                                style={{
                                    width: 25,
                                    height: 'auto'
                                }}
                                webStyle={{
                                    width: 25,
                                    height: 'auto'
                                }}
                            />
                            <Text style={styles.topReaderCountryNameTextStyle}>
                                {topReaderCountry?.name}
                            </Text>
                        </View>)}
                    </View>
                    <View>
                        <CustomImage
                            source={map}
                            style={{
                                width: '92vw',
                                height: 'auto',
                                marginTop: '5vh'
                            }}
                            webStyle={{
                                width: '92vw',
                                height: 'auto',
                                marginTop: '5vh'
                            }}
                        />
                    </View>
                    <View>
                        <Text style={[styles.dataSourceTextStyle, { fontSize: '14px' }]}>Data source: The Morning Context research</Text>
                    </View>
                </View>
                {/* Section 4: What our readers say */}
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#FFF', marginTop: 40, paddingVertical: 20 }}>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                        <Text style={{ fontSize: 34, fontFamily: 'PlayfairDisplay-Bold', fontWeight: 700, color: '#292929', textAlign: 'center' }}>Hear from our community</Text>
                    </View>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', borderColor: '#E7D6C9', height: 300, objectFit: 'cover', width: '90%', marginTop: 40 }}>
                        <View style={[{ width: '100%', height: '100%', objectFit: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }]}>
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/DVMpRRPLW3k?si=BqaDJ1I1jNf32EYa"
                                title="The Morning Context"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            />
                        </View>
                    </View>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40 }}>
                        <TestimonialCarousel />
                    </View>
                </View>
                {/* Section 5: Sectors our readers come from */}
                <View style={styles.ourReadersSectorWrapper}>
                    <View>
                        <Text style={[styles.secondaryTitleText, { textAlign: 'center', width: '85%', alignSelf: 'center' }]}>
                            Sectors our readers come from
                        </Text>
                    </View>
                    <View>
                        <CustomImage
                            source={sectorsOurReadersComeFromImage}
                            style={{
                                width: '100vw',
                                height: 'auto',
                                marginTop: '5vh'
                            }}
                            webStyle={{
                                width: '100vw',
                                height: 'auto',
                                marginTop: '5vh'
                            }}
                        />
                    </View>
                    <View style={{ width: "100%" }}>
                        <Text style={[styles.subTitleText, , { textAlign: 'left', justifyContent: 'flex-start', alignItems: 'flex-start', width: '90%' }]}>
                            Top sectors TMC readers come from:
                        </Text>
                    </View>
                    <View style={[styles.topSectorsCardsContainer, { justifyContent: 'flex-start', columnGap: '6vw' }]}>
                        {TOP_SECTORS_CARD.map((topSector) => {
                            return <View style={[styles.topSectorCard, { borderLeftColor: topSector.borderLeftColor }]}>
                                <CustomImage
                                    source={topSector.icon}
                                    style={{
                                        width: 25,
                                        height: 'auto',
                                        marginLeft: '2vw'
                                    }}
                                    webStyle={{
                                        width: 25,
                                        height: 'auto',
                                        marginLeft: '2vw'
                                    }}
                                />
                                <Text style={[styles.topSectorCardText, { fontWeight: 500, fontSize: 16, lineHeight: '150%', letterSpacing: '0.3px', marginLeft: '2vw' }]}>{topSector?.name}</Text>
                            </View>
                        })}
                    </View>
                </View>
                {/* Section 6: Get in touch section */}
                <View style={[styles.contactUsSectionWrapper, { flexDirection: 'column', rowGap: '5vh', height: null }]}>
                    <View style={[styles.contactUsTitleSection, { width: '100%', height: null }]}>
                        <Text style={styles.getInTouchText}>Get in touch</Text>
                        <Text style={styles.getInTouchSubText}>Can't find what you need or want to speak to a sales representative?</Text>
                    </View>
                    {this.state.showThankYouComponent ?
                        <View style={{ height: '60vh', width: '90%', backgroundColor: 'white', position: 'relative' }}>
                            <ThankYouComponent closeThankYouModal={this.closeContactUsForm} />
                        </View>
                        :
                        <View style={[styles.contactUsFormSection, { width: '85%', height: '60vh', justifyContent: 'center' }]}>
                            <View style={{ display: 'flex', alignItems: 'center', width: '100%', backgroundColor: 'white' }}>
                                <Formik
                                    initialValues={{
                                        name: "",
                                        lastName: "",
                                        businessEmail: "",
                                        message: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        console.log("Values =", values);
                                        const data = {
                                            name: values.name,
                                            email: values.businessEmail,
                                            requirements: values.message,
                                            job_title: "dummy",
                                            company: "dummy",
                                            phone: "+919876543210",
                                            position: "dummy",
                                            university: "dummy"
                                        };
                                        console.log("Data =", data);
                                        this.onSubmitCustomerForm(data);
                                    }}
                                >
                                    {(props) => (
                                        <View style={styles.container}>
                                            {/* <View style={styles.formRow}> */}
                                            <View style={styles.formItem}>
                                                <TextInput
                                                    placeholder="Name*"
                                                    onChangeText={props.handleChange("name")}
                                                    onBlur={props.handleBlur("name")}
                                                    value={props.values?.name}
                                                    style={[
                                                        styles.input,
                                                        { borderBottom: "1px solid #bbbbbb" },
                                                    ]}
                                                />
                                                {props.errors?.name && props.touched?.name && (
                                                    <Text style={styles.formError}>{props.errors?.name}</Text>
                                                )}
                                            </View>

                                            <View style={styles.formItem}>
                                                <TextInput
                                                    placeholder="Email*"
                                                    onChangeText={props.handleChange("businessEmail")}
                                                    onBlur={props.handleBlur("businessEmail")}
                                                    value={props.values.businessEmail}
                                                    style={[
                                                        styles.input,
                                                        { borderBottom: "1px solid #bbbbbb" },
                                                    ]}
                                                />
                                                {props.errors.businessEmail &&
                                                    props.touched.businessEmail && (
                                                        <Text style={styles.formError}>
                                                            {props.errors.businessEmail}
                                                        </Text>
                                                    )}
                                            </View>
                                            {/* </View> */}
                                            <View
                                                style={{
                                                    zIndex: -5,
                                                }}
                                            >
                                                {/* </View> */}
                                                {/* <View style={styles.formRow}> */}
                                                <View style={styles.formItem}>
                                                    <TextInput
                                                        placeholder={"Message*"}
                                                        onChangeText={props.handleChange("message")}
                                                        onBlur={props.handleBlur("message")}
                                                        value={props.values.message}
                                                        style={[
                                                            styles.input,
                                                            { borderBottom: "1px solid #bbbbbb" },
                                                        ]}
                                                    />
                                                    {props.errors.message && props.touched.message ? (
                                                        <Text style={styles.formError}>
                                                            {props.errors.message}
                                                        </Text>
                                                    ) : null}
                                                </View>
                                                <View style={styles.formItem}></View>
                                                {/* </View> */}
                                                <View
                                                    style={{
                                                        zIndex: -5,
                                                    }}
                                                >
                                                    <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Button
                                                            title={'SUBMIT'}
                                                            rootStyle={{
                                                                btnWrapper: [button.primary,
                                                                { height: 36, cursor: "pointer", marginTop: 25, width: '80%', zIndex: -5, alignItems: 'center', justifyContent: 'center' },
                                                                ],
                                                                btnText: [button.primaryText,
                                                                { fontWeight: "500", paddingHorizontal: 15, fontSize: 17 },
                                                                ],
                                                            }}
                                                            onClick={() => {
                                                                props.handleSubmit();
                                                            }}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    )}
                                </Formik>
                            </View>
                        </View>}
                </View>
                <View style={{ display: 'flex', width: '100%', backgroundColor: '#FFF9F1' }}>
                    <AppFooter />
                </View>
            </View>
        }


        return <View style={styles.wrapper}>
            {/* App Header */}
            <View style={{ display: 'flex', width: '100%', zIndex: 2 }}>
                <AppHeader ref="header" history={this.props.history} redirectSlugs={redirectSlugs} searchQuery={this.state.searchQuery} locationHash={this.state.locationHash} navigateWeb={(slug) => this.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} isPricing={false} goBack={() => this.props.navigation.goBack()} />
            </View>
            {/* Section 1: Read by decision makers */}
            <View style={styles.readByDecisionMakersWrapper}>
                <View>
                    <Text style={styles.titleText}>
                        Read by decision makers
                    </Text>
                </View>
                <View>
                    <Text style={styles.subTitleText}>
                        The Morning Context is read by everyone who wants to stay on top of technology, business and current affairs in India.
                    </Text>
                </View>
                <View>
                    <CustomImage
                        source={ourReadersImage}
                        style={{
                            width: '80vw',
                            height: 'auto',
                            marginTop: 5,
                            marginRight: 15,
                            marginBottom: '5vh',
                            marginTop: '5vh'
                        }}
                        webStyle={{
                            width: '80vw',
                            height: 'auto',
                            marginTop: 5,
                            marginRight: 15,
                            marginBottom: '5vh',
                            marginTop: '5vh'
                        }}
                    />
                </View>
            </View>
            {/* Section 2: Journalism that helps you work better */}
            <View style={styles.journalismThatHelpsWrapper}>
                <View>
                    <Text style={styles.secondaryTitleText}>Journalism that helps you work better</Text>
                </View>
                <View>
                    <Text style={styles.subTitleText}>
                        Readers turn to The Morning Context to understand companies and their strategies, so that they can bring this knowledge into their own work.
                    </Text>
                </View>
                <View style={styles.readerStatsContainer}>
                    {READER_STATS.map((readerStat) => <StatCard {...readerStat} />)}
                </View>
                <View style={styles.stateSourceContainer}>
                    <Text style={styles.statSourceTextStyle}>Source: The Morning Context survey with paying readers.</Text>
                </View>
            </View>
            {/* Section 3: Where are our readers section */}
            <View style={styles.whereAreReadersWrapper}>
                <View>
                    <Text style={styles.secondaryTitleText}>Where are our readers</Text>
                </View>
                <View style={{ width: '100%' }}>
                    <Text style={styles.subTitleText}>Top 5 countries where the readers of TMC are:</Text>
                </View>
                <View style={styles.topReaderCountriesContainer}>
                    {TOP_READER_COUNTRIES.map((topReaderCountry) => <View style={styles.topReaderCountriesCardContainer}>
                        <CustomImage
                            source={topReaderCountry.image}
                            style={{
                                width: 25,
                                height: 'auto'
                            }}
                            webStyle={{
                                width: 25,
                                height: 'auto'
                            }}
                        />
                        <Text style={styles.topReaderCountryNameTextStyle}>
                            {topReaderCountry?.name}
                        </Text>
                    </View>)}
                </View>
                <View>
                    <CustomImage
                        source={map}
                        style={{
                            width: '80vw',
                            height: 'auto',
                            marginTop: '5vh'
                        }}
                        webStyle={{
                            width: '80vw',
                            height: 'auto',
                            marginTop: '5vh'
                        }}
                    />
                </View>
                <View>
                    <Text style={styles.dataSourceTextStyle}>Data source: The Morning Context research</Text>
                </View>
            </View>
            {/* Section 4: What our readers say */}
            <View style={{ backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                <View style={{ paddingVertical: 40, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 80 }}>
                    <Text style={{ fontSize: 34, fontWeight: 600, fontFamily: 'PlayfairDisplay-Bold' }}>Hear from our community</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', marginBottom: 60, columnGap: 20 }}>
                    <View style={{ display: 'flex', width: '60%', rowGap: 20 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingLeft: 40, columnGap: 20 }}>
                            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomImage
                                        source={WHAT_OUR_READERS_SAY[0].logo}
                                        style={{ width: 111, height: 'auto' }}
                                        webStyle={{ width: 111, height: 'auto' }}
                                        className={'spotify-img'}
                                        altText={"spotify-img"}
                                    />
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                        {WHAT_OUR_READERS_SAY[0].review}
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[0]?.name}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[0].designation}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[0].organization}</View>
                            </View>
                            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomImage
                                        source={WHAT_OUR_READERS_SAY[1].logo}
                                        style={{ width: 111, height: 'auto' }}
                                        webStyle={{ width: 111, height: 'auto' }}
                                        className={'times-internet-img'}
                                        altText={"times-internet-img"}
                                    />
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                        {WHAT_OUR_READERS_SAY[1].review}
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[1]?.name}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[1].designation}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[1].organization}</View>
                            </View>
                        </View>
                        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', borderColor: '#E7D6C9', height: 400, objectFit: 'cover', }}>
                            <View style={[{ width: '88%', height: '100%', objectFit: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }]}>
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/DVMpRRPLW3k?si=BqaDJ1I1jNf32EYa"
                                    title="The Morning Context"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                />
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingLeft: 40, columnGap: 20 }}>
                            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomImage
                                        source={WHAT_OUR_READERS_SAY[3].logo}
                                        style={{ width: 111, height: 'auto' }}
                                        webStyle={{ width: 111, height: 'auto' }}
                                        className={'spotify-img'}
                                        altText={"spotify-img"}
                                    />
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                        {WHAT_OUR_READERS_SAY[3].review}
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[3]?.name}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[3].designation}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[3].organization}</View>
                            </View>
                            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomImage
                                        source={WHAT_OUR_READERS_SAY[4].logo}
                                        style={{ width: 111, height: 'auto' }}
                                        webStyle={{ width: 111, height: 'auto' }}
                                        className={'times-internet-img'}
                                        altText={"times-internet-img"}
                                    />
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                        {WHAT_OUR_READERS_SAY[4].review}
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[4]?.name}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[4].designation}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[4].organization}</View>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', paddingLeft: 40, columnGap: 20 }}>
                            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomImage
                                        source={WHAT_OUR_READERS_SAY[7].logo}
                                        style={{ width: 111, height: 'auto' }}
                                        webStyle={{ width: 111, height: 'auto' }}
                                        className={'spotify-img'}
                                        altText={"spotify-img"}
                                    />
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                        {WHAT_OUR_READERS_SAY[7].review}
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[7]?.name}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[7].designation}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[7].organization}</View>
                            </View>
                            <View style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '45%', height: 400, borderWidth: 1, borderColor: '#E7D6C9' }}>
                                <View style={{ display: 'flex', width: '100%', height: '40%', backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomImage
                                        source={WHAT_OUR_READERS_SAY[6].logo}
                                        style={{ width: 111, height: 'auto' }}
                                        webStyle={{ width: 111, height: 'auto' }}
                                        className={'times-internet-img'}
                                        altText={"times-internet-img"}
                                    />
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                    <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                        {WHAT_OUR_READERS_SAY[6].review}
                                    </Text>
                                </View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600 }}>{WHAT_OUR_READERS_SAY[6]?.name}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[6].designation}</View>
                                <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[6].organization}</View>
                            </View>
                        </View>
                    </View>
                    <View style={{ display: 'flex', alignItems: 'flex-start', rowGap: 20, width: '25%', marginLeft: '0%' }}>
                        <View style={{ display: 'flex', alignItems: 'center', width: '90%', minHeight: 400, borderWidth: 1, height: 600, borderColor: '#E7D6C9' }}>
                            <View style={{ display: 'flex', width: '100%', height: 160, backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomImage
                                    source={WHAT_OUR_READERS_SAY[2].logo}
                                    style={{ width: 111, height: 'auto' }}
                                    webStyle={{ width: 111, height: 'auto' }}
                                    className={'times-internet-img'}
                                    altText={"times-internet-img"}
                                />
                            </View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                    {WHAT_OUR_READERS_SAY[2].review}
                                </Text>
                            </View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 25 }}>{WHAT_OUR_READERS_SAY[2]?.name}</View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[2].designation}</View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[2].organization}</View>
                        </View>

                        <View style={{ display: 'flex', alignItems: 'center', width: '90%', minHeight: 200, borderWidth: 1, height: 200, borderColor: '#E7D6C9' }}>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                    {WHAT_OUR_READERS_SAY[8].review}
                                </Text>
                            </View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 5 }}>{WHAT_OUR_READERS_SAY[8]?.name}</View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[8].designation}</View>
                        </View>

                        <View style={{ display: 'flex', alignItems: 'center', width: '90%', minHeight: 400, borderWidth: 1, height: 400, borderColor: '#E7D6C9' }}>
                            <View style={{ display: 'flex', width: '100%', height: 160, backgroundColor: '#FFF9F1', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomImage
                                    source={WHAT_OUR_READERS_SAY[5].logo}
                                    style={{ width: 111, height: 'auto' }}
                                    webStyle={{ width: 111, height: 'auto' }}
                                    className={'times-internet-img'}
                                    altText={"times-internet-img"}
                                />
                            </View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 10, marginTop: 20 }}>
                                <Text style={{ fontSize: 16, lineHeight: '150%' }}>
                                    {WHAT_OUR_READERS_SAY[5].review}
                                </Text>
                            </View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14, fontWeight: 600, marginTop: 20 }}>{WHAT_OUR_READERS_SAY[5]?.name}</View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[5].designation}</View>
                            <View style={{ display: 'flex', width: '80%', justifyContent: 'center', marginVertical: 2, fontSize: 14 }}>{WHAT_OUR_READERS_SAY[5].organization}</View>
                        </View>
                    </View>
                </View>
            </View>
            {/* Section 5: Sectors our readers come from */}
            <View style={styles.ourReadersSectorWrapper}>
                <View>
                    <Text style={styles.secondaryTitleText}>
                        Sectors our readers come from
                    </Text>
                </View>
                <View>
                    <CustomImage
                        source={sectorsOurReadersComeFromImage}
                        style={{
                            width: '100vw',
                            height: 'auto',
                            marginTop: '5vh'
                        }}
                        webStyle={{
                            width: '100vw',
                            height: 'auto',
                            marginTop: '5vh'
                        }}
                    />
                </View>
                <View style={{ width: "100%" }}>
                    <Text style={styles.subTitleText}>
                        Top sectors TMC readers come from:
                    </Text>
                </View>
                <View style={styles.topSectorsCardsContainer}>
                    {TOP_SECTORS_CARD.map((topSector) => {
                        return <View style={[styles.topSectorCard, { borderLeftColor: topSector.borderLeftColor }]}>
                            <CustomImage
                                source={topSector.icon}
                                style={{
                                    width: 25,
                                    height: 'auto',
                                    marginLeft: '1vw'
                                }}
                                webStyle={{
                                    width: 25,
                                    height: 'auto',
                                    marginLeft: '1vw'
                                }}
                            />
                            <Text style={[styles.topSectorCardText, { fontWeight: 500 }]}>{topSector?.name}</Text>
                        </View>
                    })}
                </View>
            </View>
            {/* Section 6: Get in touch section */}
            <View style={styles.contactUsSectionWrapper}>
                <View style={styles.contactUsTitleSection}>
                    <Text style={styles.getInTouchText}>Get in touch</Text>
                    <Text style={styles.getInTouchSubText}>Can't find what you need or want to speak to a sales representative?</Text>
                </View>
                {this.state.showThankYouComponent ?
                    <View style={{ display: 'flex', alignItems: 'center', height: '100%', width: '40%', backgroundColor: 'white' }}>
                        <ThankYouComponent closeThankYouModal={this.closeContactUsForm} />
                    </View>
                    :
                    <View style={styles.contactUsFormSection}>
                        <View style={{ display: 'flex', alignItems: 'center', height: '100%', width: '80%', backgroundColor: 'white' }}>
                            <Formik
                                initialValues={{
                                    name: "",
                                    lastName: "",
                                    businessEmail: "",
                                    message: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    console.log("Values =", values);
                                    const data = {
                                        name: values.name,
                                        email: values.businessEmail,
                                        requirements: values.message,
                                        job_title: "dummy",
                                        company: "dummy",
                                        phone: "+919876543210",
                                        position: "dummy",
                                        university: "dummy"
                                    };
                                    console.log("Data =", data);
                                    this.onSubmitCustomerForm(data);
                                }}
                            >
                                {(props) => (
                                    <View style={styles.container}>
                                        {/* <View style={styles.formRow}> */}
                                        <View style={styles.formItem}>
                                            <TextInput
                                                placeholder="Name*"
                                                onChangeText={props.handleChange("name")}
                                                onBlur={props.handleBlur("name")}
                                                value={props.values?.name}
                                                style={[
                                                    styles.input,
                                                    { borderBottom: "1px solid #bbbbbb" },
                                                ]}
                                            />
                                            {props.errors?.name && props.touched?.name && (
                                                <Text style={styles.formError}>{props.errors?.name}</Text>
                                            )}
                                        </View>

                                        <View style={styles.formItem}>
                                            <TextInput
                                                placeholder="Email*"
                                                onChangeText={props.handleChange("businessEmail")}
                                                onBlur={props.handleBlur("businessEmail")}
                                                value={props.values.businessEmail}
                                                style={[
                                                    styles.input,
                                                    { borderBottom: "1px solid #bbbbbb" },
                                                ]}
                                            />
                                            {props.errors.businessEmail &&
                                                props.touched.businessEmail && (
                                                    <Text style={styles.formError}>
                                                        {props.errors.businessEmail}
                                                    </Text>
                                                )}
                                        </View>
                                        {/* </View> */}
                                        <View
                                            style={{
                                                zIndex: -5,
                                            }}
                                        >
                                            {/* </View> */}
                                            {/* <View style={styles.formRow}> */}
                                            <View style={styles.formItem}>
                                                {/* <CustomPicker
                                name="requirements"
                                selectedValue={props.values.requirements}
                                field={{
                                  value: props.values.requirements,
                                  error: props.errors.requirements,
                                  touched: props.touched.requirements,
                                }}
                                onChange={(value) => {
                                  props.setFieldValue("requirements", value);
                                }}
                              /> */}
                                                <TextInput
                                                    placeholder={"Message*"}
                                                    onChangeText={props.handleChange("message")}
                                                    onBlur={props.handleBlur("message")}
                                                    value={props.values.message}
                                                    style={[
                                                        styles.input,
                                                        { borderBottom: "1px solid #bbbbbb" },
                                                    ]}
                                                />
                                                {props.errors.message && props.touched.message ? (
                                                    <Text style={styles.formError}>
                                                        {props.errors.message}
                                                    </Text>
                                                ) : null}
                                            </View>
                                            <View style={styles.formItem}></View>
                                            {/* </View> */}
                                            <View
                                                style={{
                                                    zIndex: -5,
                                                }}
                                            >
                                                <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Button
                                                        title={'SUBMIT'}
                                                        rootStyle={{
                                                            btnWrapper: [button.primary,
                                                            { height: 36, cursor: "pointer", marginTop: 25, width: '80%', zIndex: -5, alignItems: 'center', justifyContent: 'center' },
                                                            ],
                                                            btnText: [button.primaryText,
                                                            { fontWeight: "500", paddingHorizontal: 15, fontSize: 17 },
                                                            ],
                                                        }}
                                                        onClick={() => {
                                                            props.handleSubmit();
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                )}
                            </Formik>
                        </View>
                    </View>}
            </View>
            <View style={{ display: 'flex', width: '100%' }}>
                <AppFooter />
            </View>
        </View>
    }
}


const ClientSideWhoReadsUs = dynamic(() => Promise.resolve(WhoReadsUsComponent), {
    ssr: false,
});

function WhoReadsUs(props) {
    return (
        <ClientSideWhoReadsUs {...props} />
    );
}

export default withRouter(WhoReadsUs);
