import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Dimensions, Platform, TouchableOpacity } from 'react-native';
import { Flex, Padding, Margin, Helpers, Typo, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import CustomImage from '../custom-image';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import { Mixpanel } from '../../utils/mixpanel';
import TouchableOpacityLink from '../touchable-opacity-link';
import TouchableOpacityLinkExternal from '../touchable-opacity-link-external';
import PageBreak from '../page-break';
// import { Colors } from 'react-native/Libraries/NewAppScreen';

const Tata = getImagePath('img/Tata-logo.png');
const Locus = getImagePath('img/Locus.png');
const Netflix = getImagePath('img/Netflix-Logo.png');
const spotify = getImagePath('img/spotify-img.png');
const firework = getImagePath('img/Firework.png');
const sparkcapital = getImagePath('img/spark-capital.png');
const flf = getImagePath('img/flf.png');
const axilor = getImagePath('img/axilor-logo.png');
const easydiner = getImagePath('img/eazydiner-logo.png');
const redhill = getImagePath('img/redhill-logo.png');
const razorpay = getImagePath('img/razorpay-logo.png');
const razorpayDark = getImagePath('img/razorpay-logo-white.png');

const LocusDark = getImagePath('img/locus-logo-dark.png');
const flfDark = getImagePath('img/flf-logo-dark.png');
const fireworkDark = getImagePath('img/firework-logo-dark.png');

const amazonLight = getImagePath('img/amazonLogoLight.png');
const amazonDark = getImagePath('img/amazonLogoDark.png');

const companyLogo = [
    {
        img: Tata,
        name: 'Tata',
    },
    {
        img: Locus,
        name: 'Locus',
    },
    {
        img: Netflix,
        name: 'Netflix',
    },
    {
        img: spotify,
        name: 'Spotify',
    },
    {
        img: firework,
        name: 'Firework',
    },
    {
        img: sparkcapital,
        name: 'Sparkcapital',
    },
    {
        img: flf,
        name: 'Future Lifestyle Fashion',
    },
    {
        img: axilor,
        name: 'Axilor',
    },
    {
        img: easydiner,
        name: 'Eazydiner',
    },
    {
        img: redhill,
        name: 'Redhill',
    },
    {
        img: razorpay,
        name: 'Razorpay',
    },
    {
        img: amazonLight,
        name: 'Amazon',
    },
];

const companyLogoDark = [
    {
        imgDark: Tata,
        name: 'Tata',
    },
    {
        imgDark: LocusDark,
        name: 'Locus',
    },
    {
        imgDark: Netflix,
        name: 'Netflix',
    },
    {
        imgDark: spotify,
        name: 'Spotify',
    },
    {
        imgDark: fireworkDark,
        name: 'Firework',
    },
    {
        imgDark: sparkcapital,
        name: 'Sparkcapital',
    },
    {
        imgDark: flfDark,
        name: 'Future Lifestyle Fashion',
    },
    {
        imgDark: axilor,
        name: 'Axilor',
    },
    {
        imgDark: easydiner,
        name: 'Eazydiner',
    },
    {
        imgDark: redhill,
        name: 'Redhill',
    },
    {
        imgDark: razorpayDark,
        name: 'Razorpay',
    },
    {
        imgDark: amazonDark,
        name: 'Amazon',
    },
];


export class SubscriptionSponsers extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount() {

    }


    render() {
        let { data, darkMode } = this.props;
        let webView = helpers.isWeb();

        return (
            <View style={[helpers.isDesktop() && Margin.mb_4, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1000]}>
                <View style={[Flex.alignCenter, Flex.justfiyCenter]}>
                    <View style={[Flex.alignCenter, Margin.mb_4]}>
                        <Text style={[Padding.pb_2, { textAlign: 'center', fontSize: helpers.isDesktop() ? 34 : 30, lineHeight: helpers.isDesktop() ? 43 : 38, fontFamily: FontFamily.abrilFatface, fontWeight: FontWeight.regular, color: '#000' }, darkMode && { color: Colors.lightWhite }]}>You will be in good company</Text>
                        <PageBreak style={[Margin.mt_1, { width: helpers.isDesktop() ? '25%' : '75%' }, !helpers.isWeb() && { width: 200 }]} darkMode={darkMode} />
                    </View>
                    {!darkMode ?
                        <View style={[Flex.row, Flex.alignCenter, { flexWrap: 'wrap', justifyContent: 'center' }, !helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                            {companyLogo && companyLogo.map((item, index) => {
                                return (
                                    <View style={[!helpers.isDesktop() && { width: '50%', alignItems: 'center', marginBottom: 30 }, { marginBottom: 20 }]}>
                                        <TouchableOpacityLinkExternal>
                                            <CustomImage
                                                source={item.img}
                                                require={true}
                                                style={{ width: helpers.isDesktop() ? 150 : 120, height: helpers.isDesktop() ? 90 : 65, resizeMode: 'contain' }}
                                                webStyle={{ width: helpers.isDesktop() ? 150 : 120, height: helpers.isDesktop() ? 90 : 65, objectFit: 'contain', marginLeft: 15, marginRight: 15 }}
                                                altText={item.name}
                                            />
                                        </TouchableOpacityLinkExternal>
                                    </View>
                                )
                            })}
                        </View>
                        :
                         <View style={[Flex.row, Flex.alignCenter, { flexWrap: 'wrap', justifyContent: 'center' }, !helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                            {companyLogoDark && companyLogoDark.map((item, index) => {
                                return (
                                    <View style={[!helpers.isDesktop() && { width: '50%', alignItems: 'center', marginBottom: 30 }, { marginBottom: 20 }]}>
                                        <TouchableOpacityLinkExternal>
                                            <CustomImage
                                                source={item.imgDark}
                                                require={true}
                                                style={{ width: helpers.isDesktop() ? 150 : 120, height: helpers.isDesktop() ? 90 : 65, resizeMode: 'contain' }}
                                                webStyle={{ width: helpers.isDesktop() ? 150 : 120, height: helpers.isDesktop() ? 90 : 65, objectFit: 'contain', marginLeft: 15, marginRight: 15 }}
                                                altText={item.name}
                                            />
                                        </TouchableOpacityLinkExternal>
                                    </View>
                                )
                            })}
                        </View>
                    }
                </View>
            </View>
        );
    }
}

export default SubscriptionSponsers;